import { Injectable } from '@angular/core';
import { SingleNotification } from '@qv-shared/classes/single-notification';

@Injectable({
  providedIn: 'root'
})
export class SingleNotificationService {
  public getInstance<T>(): SingleNotification<T> {
    return new SingleNotification<T>();
  }
}
