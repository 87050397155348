<header class="standard-header">
  <ng-container *ngIf="!(bidStateService.isViewMode() | async)">
    <mat-checkbox class="standard-header__scenarios-checker"
      [checked]="bidSelectService.allScenariosSelected() | async"
      [disabled]="bidStateService.isBidInvalid() | async"
      (change)="$event && bidSelectService.bidToggle($event)"
    ></mat-checkbox>
    <div class="drug-actions-container">
      <button class="drug-actions-button" mat-button (click)="bidEventBusService.drugActionsEvent.emit()"
        data-test-id="test-id-drug-action-button"
        [disabled]="(isBidInvalid$ | async)"
      >
        <span class="drug-actions-button__text">Actions</span>
        <mat-icon class="drug-actions-button__icon" [svgIcon]="svgIconName.ARROW_DOWN"></mat-icon>
      </button>
      <qv-drugs-menu class="drug-actions-container__drug-menu"
        [isReviewMode]="(bidStateService.isReviewMode | async)"
        [cbId]="cbId"
        [bidVersionId]="bidVersionId"
        [pharmaId]="pharmaId"
        [isBidInternal]="(bidStateService.bid$ | async)?.isInternal"
      ></qv-drugs-menu>
      </div>
  </ng-container>
  <!-- Filter input -->
  <qv-standard-drug-filter class="multi-select multi-select--drug-filter"
    [bidVersionId]="bidVersionId"
    [placeholder]="'Filter Drug/NDC list'"
    [disabled]="bidStateService.isBidInvalid() | async"
    (applyChanges)="standardDrugFilterService.applyChanges($event)"
  ></qv-standard-drug-filter>

  <!-- Filter checkboxes -->
  <div class="standard-header__filter-checkbox-container">
    <mat-checkbox class="qv-mat-checkbox qv-mat-checkbox--primary standard-header__filter-checkbox"
      *ngFor="let filter of (sectionFiltersService.sectionsFilters$ | async | keyvalue | orderBy:'value.order'); trackBy: trackByFn"
      [checked]="filter.value.checked"
      [disabled]="bidStateService.isBidInvalid() | async"
      (change)="filterChanged(filter.key, $event)">
      {{ filter.value.title }}
    </mat-checkbox>
  </div>
</header>
