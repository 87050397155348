import { CreateInternalBidModule, CreateInternalBidNgModule } from './create-internal-bid/create-internal-bid.module';
import { NgModule } from '@angular/core';

import { BiddingModule, BiddingNgModule } from './bidding/bidding.module';
import { CopyBidModule, CopyBidNgModule } from './copy-bid/copy-bid.module';
import { SharedModule, SharedNgModule } from './shared/shared.module';
import { UiViewWrapperModule, UiViewWrapperNgModule } from './ui-view-wrapper/ui-view-wrapper.module';
import { AdministratorModule, AdministratorNgModule } from './administrator/administrator.module';
import { MainModule, MainNgModule } from './main/main.module';
import { ManagementModule, ManagementNgModule } from './management/management.module';

declare let angular: angular.IAngularStatic;

export const ComponentsNgModule = angular.module('quantuvis.components', [
  BiddingNgModule.name,
  SharedNgModule.name,
  CopyBidNgModule.name,
  CreateInternalBidNgModule.name,
  UiViewWrapperNgModule.name,
  AdministratorNgModule.name,
  MainNgModule.name,
  ManagementNgModule.name
]);

@NgModule({
  imports: [
    BiddingModule,
    SharedModule,
    CopyBidModule,
    CreateInternalBidModule,
    UiViewWrapperModule,
    AdministratorModule,
    MainModule,
    ManagementModule
  ]
})
export class ComponentsModule {
}
