import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Bid } from '@qv-bid/entities';
import { SvgIconName } from '@qv-common/enums';
import { ViewPerspectiveService } from '@qv-common/services/auth';
import { resources } from '@qv-common/static';
import { BidViewStateService } from '@qv-bid/services/bid-view-state.service';
import { BidUtils } from '@qv-bid/utils';

@Component({
  selector: 'qv-bid-revision-comparison-toolbar',
  templateUrl: './bid-revision-comparison-toolbar.component.html',
  styleUrls: ['./bid-revision-comparison-toolbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BidRevisionComparisonToolbarComponent implements OnInit {
  @Input()
  public bid: Bid;
  @Input()
  public bidVersions: number[] = [];
  @Input()
  public startVersion: number;
  @Input()
  public endVersion: number;

  public bidId: number;
  public readonly tooltips = resources.TOOLTIPS;
  public readonly svgIconName = SvgIconName;
  public readonly bidUtils = BidUtils;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private viewPerspectiveService: ViewPerspectiveService,
    public bidViewStateService: BidViewStateService
  ) {}

  public ngOnInit(): void {
    this.bidId = this.bid.id;
  }

  public isPreviousVersionAvailable(): boolean {
    return Boolean(this.getIncrementVersion(this.startVersion, -1));
  }

  public isNextVersionAvailable(): boolean {
    return Boolean(this.getIncrementVersion(this.endVersion, 1));
  }

  public loadRevisionComparisonVersion(increment: number): void {
    this.reloadPage({
      bidId: this.bid.id,
      startVersion: this.getIncrementVersion(this.startVersion, increment),
      endVersion:  this.getIncrementVersion(this.endVersion, increment)
    });
  }

  public enrichParamsWithViewPerspective(params: Params, isInternal: boolean): Params {
    return this.viewPerspectiveService.enrichParamsWithViewPerspective(params, isInternal);
  }

  private reloadPage(queryParams: Params): void {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams,
      queryParamsHandling: 'merge'
    });
  }

  private getIncrementVersion(versionId: number, increment: number): number | null {
    const versionIndex = this.bidVersions.indexOf(versionId);
    return this.bidVersions[versionIndex + increment] || null;
  }
}
