import { BaseEntity } from '@qv-bid/entities';
import { HttpResponseWrapper } from '@qv-common/models/api';
import { ApiService } from '@qv-common/services/api';
import { JsonConvert } from 'json2typescript';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ViewPerspectiveService } from '@qv-common/services/auth';
import { HttpUtils } from '@qv-common/utils';
import { ApiUrlPrefix } from '@qv-common/enums';
import { Inject } from '@angular/core';
import { JSON_CONVERT_TOKEN } from 'quantuvis-angular-common/json-converter/services';

export abstract class BaseContractDao<T extends BaseEntity> {
  protected abstract type: new() => T;
  protected abstract restEntityName: string;

  protected constructor(
    protected apiService: ApiService,
    @Inject(JSON_CONVERT_TOKEN) private jsonConvert: JsonConvert,
    protected viewPerspectiveService: ViewPerspectiveService
  ) { }

  public get(contractedBusinessId: number): Observable<T[]> {
    return this.apiService
      .get(this.getRestUrl(contractedBusinessId))
      .pipe(map((response: HttpResponseWrapper<T[]>) =>
        this.jsonConvert.deserializeArray(response.body.data, this.type)
      ));
  }

  public update(contractedBusinessId: number, data: T, isBidInternal: boolean): Observable<T> {
    data = this.jsonConvert.serialize(data);
    return this.apiService.put(this.getRestUrl(contractedBusinessId, isBidInternal), data).pipe(
      map((response: HttpResponseWrapper<T>) =>
        this.jsonConvert.deserializeObject(response.body.data, this.type)
      )
    );
  }

  protected getRestUrl(contractedBusinessId: number, isBidInternal?: boolean): string {
    let url = `${ApiUrlPrefix.BIDS}/contracted-businesses/${contractedBusinessId}/${this.restEntityName}`;
    const perspective = this.viewPerspectiveService.getViewPerspective();
    url = HttpUtils.appendPerspectiveQueryParamToUrlIfBidIsInternal(url, perspective, isBidInternal);

    return url;
  }
}
