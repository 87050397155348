import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { TermTemplateStorageService } from '@qv-term/services';
import { SvgIconName } from '@qv-common/enums';
import { resources } from '@qv-common/static';
import { TermName } from '@qv-term/enums';
import { constants } from '@qv-common/static/constants';
import { FormControl } from '@angular/forms';
import { TermUtils } from '@qv-term/utils';
import { CoreUtils } from '@qv-common/utils';
import { AtNdcDirective } from '@qv-term/directives';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'qv-base-term',
  templateUrl: './base-term.component.html',
  styleUrls: ['./base-term.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BaseTermComponent implements OnInit {
  @ViewChild(AtNdcDirective)
  public atNdcDirective: AtNdcDirective;

  @Input()
  public termName: TermName;

  @Input()
  public termValue: any;

  @Input()
  public isLocked = false;

  @Input()
  public isEditMode = false;

  @Input()
  public isPending = false;

  @Input()
  public control?: FormControl;

  @Output()
  public blurEvent = new EventEmitter();

  public isOverrideMode = false;

  public readonly svgIconName = SvgIconName;
  public readonly resources = resources;
  public termConfig;
  public exitFromEditModeEvent = new EventEmitter();
  public disableNavEvent = new EventEmitter();

  public readonly constants = constants;

  constructor(protected termTemplateStorage: TermTemplateStorageService,
              protected changeDetectorRef: ChangeDetectorRef) {}

  public ngOnInit(): void {
    this.loadTermTemplate();
  }

  public isAtNdc(value: any): boolean {
    return TermUtils.isAtNdc(value);
  }

  public getTermValue(): any {
    if (this.termValue && CoreUtils.isDefined(this.termValue.isNdc)) {
      return this.termValue.isNdc ? this.constants.AT_NDC : this.termValue.value;
    }

    return this.termValue;
  }

  public overwriteValue(): void {
    this.isOverrideMode = true;
    this.resetIsNdc();
    this.control.setValue(this.termConfig.defaultValue);
    this.changeDetectorRef.detectChanges();
    this.focus();
  }

  public onBlur(): void {
    this.deactivateOverrideMode();
  }

  public deactivateOverrideMode(): void {
    this.isOverrideMode = false;
  }

  public onOpenModal(event: PointerEvent): void {
    if (CoreUtils.isClickEvent(event)) {
      this.openModal();
    }
  }

  public startEdit(): void {
    if (this.isAtNdc(this.termValue)) {
      const atNdcCmp = this.atNdcDirective.atNdcComponent.instance;
      atNdcCmp.showOverwriteValuesModal();

      atNdcCmp.overwriteValuesCanceled
        .pipe(untilDestroyed(this))
        .subscribe(() => this.exitFromEditModeEvent.emit());

      atNdcCmp.disableNavEvent
        .pipe(untilDestroyed(this))
        .subscribe(() => this.disableNavEvent.emit());
    } else {
      this.transferToEditMode();
    }
  }

  public isEditingAvailable(): boolean {
    return this.isEditMode;
  }

  // eslint-disable-next-line no-empty-function
  public focus(): void {}

  public isEditEnabled(): boolean {
    return this.isEditMode && (!this.isAtNdc(this.termValue) || this.isOverrideMode);
  }

  // eslint-disable-next-line no-empty-function
  protected openModal(): void {}

  // eslint-disable-next-line no-empty-function
  protected transferToEditMode(): void {}

  protected loadTermTemplate(): void {
    this.termConfig = this.termTemplateStorage.getTermTemplate(this.termName);
    if (!this.termConfig) {
      throw new Error(`Can't find term config for term with name: ${this.termName}`);
    }
  }

  private resetIsNdc(): void {
    if (this.termValue.isNdc) {
      this.termValue.isNdc = false;
    }
  }
}
