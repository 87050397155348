import { Pipe, PipeTransform } from '@angular/core';
import { Moment } from 'moment';
import { DateUtils } from '@qv-common/utils';
import { appConfig } from '@qv-common/configs';

@Pipe({
  name: 'dateTimeUtc'
})
export class DateTimeUtcPipe implements PipeTransform {

  public transform(value: Moment | string, format: string = appConfig.dateTimeFormat): string {
    if (!value) {
      return null;
    }

    if (typeof value === 'string') {
      return value;
    }

    return DateUtils.convertDateToUTC(value).format(format);
  }
}
