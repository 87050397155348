import { DictionaryItem } from 'quantuvis-angular-common/interfaces';

export enum PriorAuthAllowed {
  EMPTY = 67,
  YES,
  NO
}

export namespace PriorAuthAllowed {
  const valuesMap = new Map<PriorAuthAllowed, DictionaryItem>([
    [PriorAuthAllowed.EMPTY, { id: PriorAuthAllowed.EMPTY, name: '' }],
    [PriorAuthAllowed.NO, { id: PriorAuthAllowed.NO, name: 'No' }],
    [PriorAuthAllowed.YES, { id: PriorAuthAllowed.YES, name: 'Yes' }],
  ]);

  export function getValue(priorAuthAllowedProd: PriorAuthAllowed): DictionaryItem {
    return valuesMap.get(priorAuthAllowedProd);
  }

  export function getValues(): DictionaryItem[] {
    return Array.from(valuesMap.values());
  }
}
