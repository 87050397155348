/**
 * Application frame directive.
 * Using AngularJS templating system in order to render a complete frame.
 * The template HTML til located in templates/frametemplate.html
 */
import { SvgIconName } from '@qv-common/enums';

declare let $: any;

export const Expandable = ['$compile', '$rootScope', '$timeout', 'BidDetailsService', '$document', ($compile, $rootScope, $timeout, BidDetailsService, $document): any => {
  'ngInject';
  return {
    restrict: 'A',
    link: ($scope, elem, attr) => {
      elem.addClass('expandable');
      const heightPageToolbar = 52;
      const document = $document[0];
      const header = $($(elem).find('.header'));
      const content = $($(elem).find('.content'));
      let collapsed = false;
      let expanded = false;
      const wrapper = $('#wrapper');
      const nextElem = elem[0].nextElementSibling;
      const parent = elem[0].parentElement;
      let bidId;

      let toggle = $compile(
        `<qv-icon icon-name="${SvgIconName.ARROW_DOWN}" icon-class="arrow-down"></qv-icon>`)($scope);
      const iconName = expanded ? SvgIconName.COLLAPSE : SvgIconName.EXPAND;
      let expandElement = $compile(
        `<qv-icon icon-name="${iconName}" icon-class="expand-collapse"></qv-icon>`)($scope);

      $(header).append(expandElement);
      $(header).prepend(toggle);

      const toggleFunction = () => {
        collapsed = !collapsed;
        content.slideToggle(10, () => {
          $(toggle).removeAttr('icon-class');
          $(toggle).attr('icon-class', collapsed ? 'arrow-down arrow-down--left' : 'arrow-down');
          toggle = $compile(toggle)($scope);
        });
      };

      const updateHeaderPosition = () => {
        const data = {
          detail: {
            dependentElement: document.querySelector('.qv-page-toolbar'),
            dependentElementPosition: {
              top: document.querySelector('.site-header').offsetHeight
            },
            expanded
          },
          bubbles: true,
          cancelable: true
        };
      };

      const processExpandHtml = () => {
        if (collapsed) {
          toggleFunction();
        }

        if (expanded) {
          $(toggle).css('visibility', 'visible');
          wrapper.removeClass('expandedContainer');
          parent.insertBefore(elem[0], nextElem);
        } else {
          $(toggle).css('visibility', 'hidden');
          wrapper.addClass('expandedContainer');
          wrapper[0].appendChild(elem[0]);
        }
        $scope.$broadcast('$tinymce:refresh');
        elem.removeClass(expanded ? 'maximized backdown-body' : 'expandable');
        elem.addClass(!expanded ? 'maximized backdown-body' : 'expandable');
        $(expandElement).removeAttr('icon-name');
        $(expandElement).attr('icon-name', expanded ? SvgIconName.EXPAND : SvgIconName.COLLAPSE);
        expandElement = $compile(expandElement)($scope);

        const expandableList = $.find('.expandable');

        if (Array.isArray(expandableList)) {
          expandableList.forEach(elem => {
            if (expanded) {
              $(elem).show();
            } else {
              $(elem).hide();
            }
          });
        }

        expanded = !expanded;

        $timeout(() => {
          updateHeaderPosition();
          $rootScope.$broadcast('sizeChanged');
        }, 10);
      };

      const expandFunction = () => {
        processExpandHtml();

        // Save expander state for applying after reload.
        if (attr.expandableId && bidId) {
          BidDetailsService.setExpanderStates(bidId, attr.expandableId, expanded);
        }
      };

      toggle.on('click', toggleFunction);
      expandElement.on('click', expandFunction);

      const bidIdListener = $scope.$watch(attr.bidId, (newValue) => {
        if (newValue && attr.expandableId) {
          bidIdListener();
          bidId = newValue;
          // Restore expander state after reload.
          if (BidDetailsService.getExpanderStates(bidId, attr.expandableId)) {
            expandFunction();
          }
        }
      });

      function collapse(): void {
        if (expanded) {
          processExpandHtml();
        }
      }

      $scope.$on('$destroy', () => {
        collapse();
      });
    }
  };
}];

