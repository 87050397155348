<div class="list-panel market-basket-templates">
  <div class="market-basket-templates__header">Select Market Basket Template:</div>
  <div class="list-panel__content market-basket-templates__content">
    <div *ngIf="total" class="market-basket-templates__list">
      <div
        *ngFor="let template of templateList"
        class="market-basket-templates__list-item"
        [class.market-basket-templates__list-item--active]="template.id === selectedTemplate?.id"
        (click)="onListItemSelect(template)">
        {{ template.name }}
      </div>
    </div>
    <div *ngIf="!total" class="market-basket-templates__empty-message">
      There are no Market Baskets for this drug.<br/>
      Please create a new Market Basket.
    </div>

    <mat-paginator
      *ngIf="isPaginatorVisible"
      class="qv-paginator"
      [disabled]="isPaginationDisabled"
      [length]="total"
      [pageIndex]="pageIndex"
      [pageSize]="pageSize"
      (page)="onChangePage($event)">
    </mat-paginator>
  </div>
</div>
