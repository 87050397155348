import { ChangeDetectionStrategy, Component, HostBinding, Inject, OnInit } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

import { SnackBarData } from '../../models/snack-bar-config';

@Component({
  selector: 'qac-snack-bar',
  templateUrl: './snack-bar.component.html',
  styleUrls: ['./snack-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('snackBarAnimation', [
      transition(':enter', [style({ opacity: 0 }), animate(500)]),
      transition(':leave', animate(500, style({ opacity: 0 })))
    ])
  ],
})
export class SnackBarComponent implements OnInit {
  @HostBinding('@snackBarAnimation') public snackBarAnimation = '';
  @HostBinding('class') public stateClass = '';

  constructor(@Inject(MAT_SNACK_BAR_DATA) public snackBar: SnackBarData) {}

  public ngOnInit(): void {
    this.stateClass = this.snackBar.snackClass;
  }
}
