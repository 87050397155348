import { JsonObject, JsonProperty } from 'json2typescript';
import { CompanyBase as Company, LineOfBusiness } from 'quantuvis-core-entities';
import { MomentConverter } from 'quantuvis-angular-common/json-converter/serializers';
import { Moment } from 'moment/moment';
import { marketBasketTemplateStateConstants } from '../constants';

@JsonObject('MarketBasketTemplateBase')
export class MarketBasketTemplateBase {
  @JsonProperty('id', Number)
  public id: number = null;

  @JsonProperty('name', String)
  public name: string = null;

  @JsonProperty('lineOfBusinessId', Number)
  public lineOfBusiness: LineOfBusiness = null;

  @JsonProperty('startDate', MomentConverter)
  public startDate: Moment = null;

  @JsonProperty('endDate', MomentConverter)
  public endDate: Moment = null;

  @JsonProperty('tradingPartners', [Company])
  public tradingPartners: Company[] = [];

  @JsonProperty('isArchived', Boolean)
  public isArchived: boolean = null;

  public get nameWithState(): string {
    return this.isArchived
      ? `${ this.name } ${ marketBasketTemplateStateConstants.archivedStateDisplayValue }`
      : this.name;
  }
}
