import { BaseTerm } from './base.term';
import { SeverityLevel, TermTypes } from '@qv-common/enums';
import { AbstractControl, ValidatorFn } from '@angular/forms';
import { ValidationError } from '@qv-common/models';
import { TermName } from '@qv-term/enums';
import { CoreUtils } from '@qv-common/utils';

export class DateTerm extends BaseTerm {
  public editable: boolean;
  public value: Date;
  public placeholder: string;

  constructor(name: string,
              label: string,
              description: string,
              path: string,
              value: Date,
              defaultValue: Date,
              cssClass: string,
              editable: boolean) {
    super(name, label, description, path, value, defaultValue, cssClass);
    this.editable = editable;
    this.type = TermTypes.Date;
  }

  public getValueToSave(): number {
    return this.value.getTime();
  }

  public validatorInvalidFormat(name: string): ValidatorFn {
    return (control: AbstractControl): ValidationError =>
      control.errors && control.errors.invalidFormat
        ? new ValidationError(SeverityLevel.ERROR, `${name} should be in MM/DD/YY format`)
        : null;
  }

  public validatorEmptyDate(name: string): ValidatorFn {
    return (control: AbstractControl): ValidationError =>
      !control.errors && CoreUtils.isNull(control.value)
        ? new ValidationError(SeverityLevel.ERROR, `${name} should be specified`)
        : null;
  }

  public validatorScenarioEndDateGreaterStartDate(termName: TermName): ValidatorFn {
    return (control: AbstractControl): ValidationError => {
      if (!control.parent) {
        return null;
      }

      const controls = control.parent.controls as AbstractControl[];
      const startDate = controls[TermName.SCENARIO_START_DATE].value;
      const endDate = controls[TermName.SCENARIO_END_DATE].value;
      const message = 'Scenario End Date should be greater than Scenario Start Date';

      if (startDate && endDate && startDate > endDate) {
        return new ValidationError(SeverityLevel.ERROR, message);
      }

      if (control.valid) {
        DateTerm.updateSiblingScenarioDateValidity(termName, controls, message);
      }

      return null;
    };
  }

  public validatorContractEndDateGreaterStartDate(termName: TermName): ValidatorFn {
    return (control: AbstractControl): ValidationError => {
      if (!control.parent) {
        return null;
      }

      const controls = control.parent.controls as AbstractControl[];
      const startDate = controls[TermName.CONTRACT_START_DATE].value;
      const endDate = controls[TermName.CONTRACT_END_DATE].value;
      const message = 'Contract End Date should be greater than Contract Start Date';

      if (startDate && endDate && startDate > endDate) {
        return new ValidationError(SeverityLevel.ERROR, message);
      }

      if (control.valid) {
        DateTerm.updateSiblingScenarioDateValidity(termName, controls, message);
      }

      return null;
    };
  }

  private static updateSiblingScenarioDateValidity(termName: TermName, controls: AbstractControl[], msg: string): void {
    const siblingTermName = DateTerm.getSiblingTermName(termName);

    if (!siblingTermName) return;

    const siblingControl = controls[siblingTermName];

    if (siblingControl.invalid && siblingControl.errors.message === msg) {
      siblingControl.updateValueAndValidity({ onlySelf: true });
    }
  }

  private static getSiblingTermName(termName: TermName): string {
    switch (termName) {
      case TermName.SCENARIO_START_DATE:
        return TermName.SCENARIO_END_DATE;
      case TermName.SCENARIO_END_DATE:
        return TermName.SCENARIO_START_DATE;
      case TermName.CONTRACT_START_DATE:
        return TermName.CONTRACT_END_DATE;
      case TermName.CONTRACT_END_DATE:
        return TermName.CONTRACT_START_DATE;
    }
  }
}
