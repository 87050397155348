import { NdcValue } from '@qv-term/entities';
import { BidUtils } from '@qv-bid/utils';

export class DrugAddModalMeta {
  private static rxOTCIndicatorMappings = {
    R : 'Rx (single source)',
    S : 'Rx (multiple sources)',
    O : 'OTC (single source)',
    P : 'OTC (multiple sources)',
    '@NDC' : '@NDC',
    0: 'undefined'
  };
  private static nameTypeCodeMappings = {
    T : 'Trademarked',
    B : 'Branded Generic',
    G : 'Generic',
    '@NDC' : '@NDC',
    0: 'undefined'
  };

  public id: number;
  public name: string;
  public ndc: string;
  public description: string;
  public eligible: NdcValue<any> = null;
  public pharma: string;
  public drugClass: NdcValue<any> = null;
  public active: NdcValue<any> = null;
  public rxOTCIndicatorCode: NdcValue<any> = null;
  public nameTypeCode: NdcValue<any> = null;

  public isSelected: boolean;
  public isPresentInBid: boolean;
  public isGroup: boolean;
  public children: DrugAddModalMeta[] = [];
  public level;

  public setData(data: any): void {
    this.id = data.drugId;
    this.isGroup = data.isGroup;
    this.name = data.name;
    this.description = data.ndcDescription ? data.ndcDescription : '';
    this.ndc = data.ndc;
    this.eligible = this.parseAtNDC(data.eligible);
    this.pharma = data.pharma;
    this.drugClass = this.parseAtNDC(data.drugClass);
    this.active = this.parseAtNDC(data.active);
    this.rxOTCIndicatorCode = this.parseAtNDC(data.rxOTCIndicatorCode);
    this.nameTypeCode = this.parseAtNDC(data.nameTypeCode);
  }

  public getName(): string {
    return this.isGroup ? this.name : `${this.name} ${this.ndc} ${this.description}`;
  }

  public getEligibleText(): string {
    if (this.eligible.isNdc) {
      return this.eligible.value.toUpperCase();
    } else {
      return this.eligible.value ? 'YES' : 'NO';
    }
  }

  public getActiveText(): string {
    return this.active.value.toUpperCase();
  }

  public getNameTypeCodeText(): string {
    return DrugAddModalMeta.nameTypeCodeMappings[this.nameTypeCode.value];
  }

  public rxOTCIndicatorText(): string {
    return DrugAddModalMeta.rxOTCIndicatorMappings[this.rxOTCIndicatorCode.value];
  }

  private parseAtNDC(value: any): NdcValue<any> {
    return new NdcValue(value, BidUtils.isAtNdc(value));
  }
}
