import { ActiveToast } from 'ngx-toastr';
import { Observable, throwError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';

export class SingleNotification<T> {
  public notification: ActiveToast<T> | null;

  public toast(activeToast: ActiveToast<T>): void {
    this.clearDuplicate(activeToast);
    this.notification = activeToast;
    this.notification.portal.onDestroy(() => this.notification = null);
  }

  public clear(): void {
    if (this.notification) {
      this.notification.toastRef.manualClose();
      this.notification = null;
    }
  }

  public throwErrorToast(activeToast: ActiveToast<T>, errorResponse: HttpErrorResponse): Observable<never> {
    this.toast(activeToast);

    return throwError(errorResponse);
  }

  private clearDuplicate({ toastId }: ActiveToast<T>): void {
    if (this.notification && this.notification.toastId !== toastId) {
      this.clear();
    }
  }
}
