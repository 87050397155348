import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { BaseNdcHeader } from '@qv-bid/components/shared/base';
import { BidStateService, DrugAlertNotificationService } from '@qv-bid/services';
import { DrugAlertType } from '@qv-bid/enums';
import { DrugSelectService, DrugSelectUtilsService } from '@qv-bid/services/selects';
import { ScenariosDependencyService } from '@qv-bid/services/scenarios-dependency.service';
import { DrugAlertNotification } from '@qv-bid/models';
import { Observable } from 'rxjs';
import { UserService } from '@qv-common/services/auth/user.service';

@Component({
  selector: 'qv-standard-ndc-header',
  templateUrl: './standard-ndc-header.component.html',
  styleUrls: ['./standard-ndc-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StandardNdcHeaderComponent extends BaseNdcHeader implements OnInit {
  @Input()
  public ndcSelected: boolean;
  public readonly drugAlertType = DrugAlertType;

  constructor(
    userService: UserService,
    public bidStateService: BidStateService,
    public drugSelectService: DrugSelectService,
    public drugSelectUtilsService: DrugSelectUtilsService,
    private scenariosDependencyService: ScenariosDependencyService,
    private drugAlertNotificationService: DrugAlertNotificationService,
  ) {
    super(userService);
  }

  public ngOnInit(): void {
    this.drugAlertNotificationService.calculateNdcNotification(this.ndc);
  }

  public onOpenScenarioDependencyModal(): void {
    this.scenariosDependencyService.onOpenScenarioDependencyModal(this.scenario, false);
  }

  public getNdcDrugAlertNotification(): Observable<DrugAlertNotification> {
    return this.drugAlertNotificationService.getNdcDrugAlertNotification(this.ndc);
  }
}
