<header class="standard-scenario-header"
  [class.standard-scenario-header--has-error]="(drugAlertNotificationService.notification$ | async)?.id === drugAlertType.INCOMPLETE_VALUES">
  <div class="standard-scenario-header__left-block">
    <mat-checkbox
      class="standard-scenario-header__checker"
      *ngIf="!(bidStateService.isViewMode() | async)"
      [checked]="drugSelectService.scenarioSelected() | async"
      (change)="$event && drugSelectService.scenarioToggle($event)"
      [disabled]="drugSelectUtilsService.isScenarioCheckboxDisabled(bidStateService.isReviewMode | async, bidStateService.isBidInvalid() | async, scenario.drug.status)"
    ></mat-checkbox>
    <qv-scenario-name class="standard-scenario-header__name"
      [scenario]="scenario"
      [name]="scenario.name"></qv-scenario-name>
    <qv-scenario-dependency
      *ngIf="scenario.dependencyIndex"
      [dependencyIndex]="scenario.dependencyIndex"
      (click)="onOpenScenarioDependencyModal()">
    </qv-scenario-dependency>
    <button class="standard-scenario-header__toggler" mat-icon-button (click)="toggleNdcs($event)">
      <mat-icon class="standard-scenario-header__toggler-icon"
        [svgIcon]="getToggleIcon(scenarioStateConfig?.isExpanded$ | async)"
      ></mat-icon>
    </button>
  </div>
  <div class="standard-scenario-header__drug">
    <span class="standard-scenario-header__drug-name">{{ scenario.drugName }}</span>
    <qv-wac class="standard-scenario-header__wac" [data]="scenario.drug"></qv-wac>
    <qv-drug-status
      [value]="scenario.drug?.status"
      [companyName]="scenario.drug?.statusChangedBy"
      class="standard-scenario-header__status"
    ></qv-drug-status>
    <qv-standard-drug-alert-notification [notification]="drugAlertNotificationService.notification$ | async">
    </qv-standard-drug-alert-notification>
  </div>
</header>
