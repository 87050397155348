<div
  (click)="$event.stopPropagation()"
  (clickOutside)="onClickOutside()"
  cdkTrapFocus
  class="acc-menu textarea-menu"
  qacClickOutside
>
  <div class="acc-menu__label">{{ title }}</div>
  <textarea
    [formControl]="textareaControl"
    [maxLength]="maxLength"
    class="acc-input acc-input--primary textarea-menu__input"
    [placeholder]="placeholder"
    [accAutofocus]="isAutofocusEnabled">
  </textarea>

  <div class="acc-menu__footer textarea-menu__footer">
    <button (click)="cancel()" class="action-button action-button--secondary" mat-flat-button>Cancel</button>
    <button
      (click)="save()"
      *ngIf="isEditMode"
      [disabled]="textareaControl.invalid"
      class="action-button action-button--primary action-button--margin-0"
      mat-flat-button>
      Save
    </button>
  </div>
</div>
