import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('ContractedBusinessScenarioIds')
export class ContractedBusinessScenarioIds {
  @JsonProperty('cbId', Number)
  public cbId: number = null;

  @JsonProperty('scenarioIds')
  public scenarioIds: number[] = [];

  constructor(cbId: number, scenarioIds: number[]) {
    this.cbId = cbId;
    this.scenarioIds = scenarioIds;
  }
}
