// @dynamic

import { ValidateNumberOptions } from '../models';

export class NumberUtils {
  private static readonly numericStringRegex = new RegExp('^[+\\-]?\\d*\\.?\\d+$');

  public static toFixedDecimals(value: number, decimals = 5, minDecimals = 2, step = 0.0001): string {
    const roundedValue: string = (Math.round(value / step / .1) * step * .1).toFixed(decimals);

    return NumberUtils.formatToDecimal(Number.parseFloat(roundedValue), minDecimals);
  }

  public static formatToDecimal(numericValue: number | string, minDecimals: number): string {
    const parsedNumber: number = typeof numericValue === 'string' ? parseFloat(numericValue) : numericValue;
    const valueEnsuredEnoughDecimals: string = parsedNumber.toFixed(minDecimals);
    const hasEnoughDecimals: boolean = Number.parseFloat(valueEnsuredEnoughDecimals) !== parsedNumber;

    return hasEnoughDecimals ? parsedNumber.toString() : valueEnsuredEnoughDecimals;
  }

  public static correctNumber(options: ValidateNumberOptions, value, prevValue = ''): string | number {
    if (value === null || value === '') {
      return value;
    }

    if (isNaN(value)) {
      return prevValue;
    }

    const originalValue = NumberUtils.toFixedDecimals(value, options.decimals, options.minDecimals, options.step);
    const parsedValue = parseFloat(originalValue);

    if (options.min <= parsedValue && parsedValue <= options.max) {
      return originalValue;
    }

    return NumberUtils.formatToDecimal(
      parsedValue < options.min ? options.min : options.max,
      options.minDecimals
    );
  }

  public static isNumeric(value: string | number): boolean {
    return NumberUtils.numericStringRegex.test(`${value}`);
  }

  public static convertToNumber(value: string | number): null | number {
    return NumberUtils.isNumeric(value) ? Number(value) : null;
  }
}
