/* tslint:disable:no-var-requires */
import ManufacturerDrugsPickerController from './manufacturer-drugs-picker.controller';

// @ts-ignore
import templateUrl from './manufacturer-drugs-picker.html';

const ManufacturerDrugsPickerComponent = {
  template: templateUrl,
  bindings: {
    runValidation: '<',
    onValid: '&'
  },
  controller: ManufacturerDrugsPickerController
};

export default ManufacturerDrugsPickerComponent;
