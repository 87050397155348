<ng-container *ngIf="!isEditable(isEditMode, isUserPharma)">
  <div class="standard-scenario-state-select"
    *ngIf="isDisplayed(isUserPharma, state, sectionName)"
    [class.standard-scenario-state-select--locked]="isLocked(state, sectionName)"
    [class.standard-scenario-state-select--required]="isRequired(state, sectionName)"
    [class.standard-scenario-state-select--allowed]="isAllowed(state, sectionName)"
    [class.standard-scenario-state-select--hidden]="isHidden(state, sectionName)">
    {{state.name}}
  </div>
</ng-container>
<ng-container *ngIf="isEditable(isEditMode, isUserPharma)">
  <ng-select
    class="qv-select standard-scenario-state-select"
    [appendTo]="'qv-standard-scenario'"
    [class.standard-scenario-state-select--locked]="isLocked(state, sectionName)"
    [class.standard-scenario-state-select--required]="isRequired(state, sectionName)"
    [class.standard-scenario-state-select--allowed]="isAllowed(state, sectionName)"
    [class.standard-scenario-state-select--hidden]="isHidden(state, sectionName)"
    [clearable]="false"
    [searchable]="false"
    bindLabel="name"
    [formControl]="control"
    [items]="selectItems">
  </ng-select>
</ng-container>
