/* tslint:disable:no-invalid-this triple-equals */
import { constants } from '@qv-common/static';
import { CoreUtils } from '@qv-common/utils';
import { forkJoin, of } from 'rxjs';
import { catchError, filter, take, tap } from 'rxjs/operators';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { ApiUrlPrefix } from '@qv-common/enums';

// @ts-ignore
import templateUrl from './rights.html';
import { UserService } from '@qv-common/services/auth/user.service';
import { SpinnerService } from '@qv-common/services';

declare let angular: angular.IAngularStatic;
declare let $: any;

let RightsComponent = {
  template: templateUrl,
  bindings: {},
  controller: class {
    public static $inject = ['$scope', 'router', '$timeout', '$document', 'util', 'standards',
      'biddingUtilsService', 'userService', 'apiService', 'permissionService', 'spinnerService'];

    constructor($scope, router, $timeout, $document, util, standards,
                biddingUtilsService, userService: UserService, apiService, permissionService,
                spinnerService: SpinnerService) {
      'ngInject';
      let rightsMap, originalMap, selectedIndex;
      let updatedAccessRights = {};

      standards.apply($scope, {
        constructor: () => {
          spinnerService.start();
          permissionService.permissionsReady$.pipe(
            filter((isReady: boolean) => isReady),
            take(1),
          ).subscribe(() => {
            $scope.isCurrentUserQuantuvisAdmin = userService.isCurrentUserQuantuvisAdmin();
            const queryParams = router.routerState.snapshot.root.queryParams;
            const companyTypeParam = decodeURIComponent((queryParams.type) ? queryParams.type : '');
            const companyIdParam = decodeURIComponent((queryParams.id) ? queryParams.id : '');
            $scope.companyName = decodeURIComponent((queryParams.name) ? queryParams.name : '');
            let usersResource;
            let companiesResource;
            let restrictionsResource;
            if ($scope.isCurrentUserQuantuvisAdmin && companyTypeParam && companyIdParam) {
              $scope.isPayer = companyTypeParam === constants.COMPANY_TYPES.PAYER.value;
              $scope.isManufacturer = companyTypeParam === constants.COMPANY_TYPES.PHARMA.value;
              usersResource = `${ApiUrlPrefix.OLD}/users/${$scope.isPayer ? 'payer' : 'pharma'}/${companyIdParam}`;

              if ($scope.isManufacturer) {
                companiesResource = `${ApiUrlPrefix.OLD}/companies/get/${companyIdParam}/payers`;
              }
              restrictionsResource = `${ApiUrlPrefix.OLD}/restrictions/company/${companyIdParam}/${companyTypeParam}`;
            } else {
              const user = userService.user.getValue();
              $scope.isPayer = user.isPayer;
              $scope.isManufacturer = user.isManufacturer;
              usersResource = `${ApiUrlPrefix.OLD}/users/${userService.getUserType()}/all/true`;
              companiesResource = `${ApiUrlPrefix.OLD}/companies/othertype/false`;
              restrictionsResource = `${ApiUrlPrefix.OLD}/restrictions/forCompanyUsers`;
            }
            $scope.userRightsConstants = constants.UserRights;

            const getUsers = () => {
              return apiService.get(usersResource).pipe(tap((response: HttpResponse<any>) => {
                // Load users for access rights matrix
                $scope.users = response.body.responseObject;
              }));
            };

            const getCompanies = () => {
              if (!companiesResource) {
                return of(null);
              }

              // Load payer companies for access rights matrix
              return apiService.get(companiesResource, { companyId: companyIdParam })
                .pipe(tap((response: HttpResponse<any>) => {
                  $scope.companies = response.body.responseObject;
                }));
            };

            const getRestrictions = () => {
              return apiService.get(restrictionsResource, {}).pipe(tap((response: HttpResponse<any>) => {
                rightsMap = response.body.responseObject;
                originalMap = angular.copy(rightsMap);
                spinnerService.stop();
              }));
            };

            forkJoin([
              getUsers(),
              getCompanies(),
              getRestrictions()
            ]).subscribe(() => {
              if (!CoreUtils.isPositionStickySupported()) {
                const scrollContainer = $('.wrapper');
                const companyNameCell = $('.namerow');
                const headerEmptyCell = $('.whitespace');
                const headerContainer = $(scrollContainer.find('.rheader'));
                companyNameCell.css('transform', 'translateX(0px)');

                scrollContainer.scroll(() => {
                  headerContainer.css('transform', `translateY(${scrollContainer.scrollTop()}px)`);
                  companyNameCell.css('transform', `translateX(${scrollContainer.scrollLeft()}px)`);
                  headerEmptyCell.css('transform', `translateX(${scrollContainer.scrollLeft()}px)`);
                  headerEmptyCell.css('transform', `translateY(${scrollContainer.scrollTop()}px)`);
                });

                scrollContainer.show();
              }
            });
          });
        },
        variables: {
          response: {},
          users: [],
          companies: [],
          isPayer: false,
          ready: false
        },
        public(): any {

          this.selectRow = index => {
            selectedIndex = index;
          };

          this.getClassForRow = (index, last) => {
            let klass = 'stheight right-row';
            klass += index % 2 === 0 ? ' odd' : ' even';

            if (CoreUtils.isDefined(last) && last) {
              klass += ' border-bottom';
            }
            if (selectedIndex == index) {
              klass += ' selected';
            }

            return klass;
          };

          this.getClassForUserDataColumn = (index, last) => {
            let klass = 'cell namerow';
            klass += index % 2 === 0 ? ' odd' : ' even';

            if (CoreUtils.isDefined(last) && last) {
              klass += ' border-bottom';
            }
            if (selectedIndex === index) {
              klass += ' selected';
            }

            return klass;
          };

          this.cancel = () => {
            delete $scope.response;
            rightsMap = angular.copy(originalMap);
            clearUpdatedAccessRights();
          };

          this.save = () => {
            let restrictionsUpdateResource;
            if ($scope.isCurrentUserQuantuvisAdmin) {
              restrictionsUpdateResource = `/restrictions/update/${$scope.isPayer ? 'payer' : 'pharma'}`;
            } else {
              restrictionsUpdateResource = '/restrictions/update';
            }
            if ($scope.areAccessRightsChanges()) {
              apiService.post(ApiUrlPrefix.OLD + restrictionsUpdateResource, updatedAccessRights).pipe(
                tap((response: HttpResponse<any>) => {
                  originalMap = angular.copy(rightsMap);
                  $scope.response = response.body;
                  clearUpdatedAccessRights();
                  util.refreshScope($scope);
                }),
                catchError((error: HttpErrorResponse) => {
                  rightsMap = angular.copy(originalMap);
                  $scope.response = error;
                  clearUpdatedAccessRights();
                  util.refreshScope($scope);

                  return of(null);
                })
              ).subscribe();
            }
          };

          this.areAccessRightsChanges = () => CoreUtils.isDefined(updatedAccessRights)
            && !CoreUtils.isEmpty(updatedAccessRights);

          this.getClassForContainer = (userId, companyId) => {
            let klass = 'min-width width160';

            if (rightsMap && originalMap && rightsMap[userId][companyId] != originalMap[userId][companyId]) {
              klass += ' changed';
            }

            return klass;
          };

          this.getClassFor = (user, companyId, suffix, klass) => {
            let result = suffix, value;
            if (klass.toLowerCase() === constants.UserRights.NONE.toLowerCase()
              && (biddingUtilsService.isPharmaAdmin(user) || biddingUtilsService.isPayerAdmin(user))) {
              result += ' disabled';
            } else if (rightsMap && originalMap) {
              let companyMap = rightsMap[user.id];
              if (companyMap) {
                value = companyMap[companyId];
                if (klass.toUpperCase() === value) {
                  result += ' selected';
                }
              }

              companyMap = originalMap[user.id];
              if (companyMap) {
                value = companyMap[companyId];
                if (klass.toUpperCase() === value) {
                  result += ' original';
                }
              }
            }

            return result;
          };

          /**
           * Verifies if 'None' access is selected from access rights matrix for a Pharma Admin user.
           *
           * @param user - the user to be checked
           * @param event - the event
           * @returns {boolean} false if the selected value from the access rights matrix is equal to 'None' and
           *          the user role name is PHARMA_ADMIN; true otherwise
           */
          this.isNoneAccessAllowed = (user, event) => {
            let allowed = true;
            const target = $(event.target);
            if (target.hasClass('option')) {
              const accessRightValue = target.html();
              if (accessRightValue === constants.UserRights.NONE
                && (biddingUtilsService.isPharmaAdmin(user) || biddingUtilsService.isPayerAdmin(user))) {
                allowed = false;
              }
            }

            return allowed;
          };

          this.select = (userId, companyId, event) => {
            const target = $(event.target);
            if (rightsMap && originalMap && target.hasClass('option')) {
              let map = rightsMap[userId];
              if (!map) {
                rightsMap[userId] = map = {};
              }
              map[companyId] = target.html().toUpperCase();

              if (rightsMap[userId][companyId] === originalMap[userId][companyId]) {
                // If selected access right for a user and a company is the same as original value then data
                // related to this pair should be deleted from updatedAccessRights
                if (CoreUtils.isDefined(updatedAccessRights[userId])
                  && CoreUtils.isDefined(updatedAccessRights[userId][companyId])) {
                  delete updatedAccessRights[userId][companyId];

                  if (CoreUtils.isEmpty(updatedAccessRights[userId])) {
                    delete updatedAccessRights[userId];
                  }
                }
              } else {
                if (CoreUtils.isNotDefined(updatedAccessRights[userId])) {
                  updatedAccessRights[userId] = {};
                }
                updatedAccessRights[userId][companyId] = map[companyId];
              }

            }
          };

          this.isPharmaAdmin = user => biddingUtilsService.isPharmaAdmin(user);

          this.isPayerAdmin = user => biddingUtilsService.isPayerAdmin(user);

          this.isUserDisabled = user => biddingUtilsService.isUserDisabled(user);

          this.getClassForStatus = user => util.getClassForStatus(user);

          function clearUpdatedAccessRights(): void {
            updatedAccessRights = {};
          }

          return this;
        }
      });

    }

  }
};
export default RightsComponent;

