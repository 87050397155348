import { Directive, ElementRef, Injector } from '@angular/core';
import { ConfirmNavigationUpgrade } from '@qv-ng1-wrapper/classes';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'qv-create-internal-bid-wrapper'
})
export class CreateInternalBidWrapperDirective extends ConfirmNavigationUpgrade {
  constructor(elementRef: ElementRef, injector: Injector) {
    super('createInternalBid', elementRef, injector);
  }
}
