/* tslint:disable:triple-equals */

export const AutoFocus = ['$timeout', ($timeout): any => {
  'ngInject';
  const directive = {
    require: '?ngModel',
    restrict: 'A',
    scope: {
      autoFocus: '=',
      focusDelay: '='
    },

    link: linkFunc
  };

  return directive;

  function linkFunc($scope, $element, $attrs, $ngModel): void {
    const element = $element[0];
    if ($scope.autoFocus === undefined || $scope.autoFocus == true) {
      if ($ngModel) {
        $ngModel.$render = () => {
          element.value = $ngModel.$viewValue || '';
          $timeout(() => {
            element.focus();
          }, ($scope.focusDelay || 100));
        };
      } else {
        // Fallback for items without model
        $timeout(() => {
          element.focus();
        }, ($scope.focusDelay || 100));
      }
    }
  }
}];
