import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { HttpStatusCode } from 'quantuvis-angular-common/api';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { LocalStorageService } from 'ngx-webstorage';
import { NotificationService } from 'quantuvis-angular-common/notification';
import { constants } from '@qv-common/static';
import { BidDaoService } from '@qv-bid/services/dao';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { UserService } from '@qv-common/services/auth/user.service';
import { BidErrorResponseStatusUtil } from '@qv-bid/utils/bid-error-response-status.util';

@UntilDestroy()
@Injectable()
export class UndoRedoService {
  public readonly bidChange$ = new Subject<number>();
  public readonly undoVersionChange$ = new Subject<void>();
  public readonly redoVersionChange$ = new Subject<void>();

  constructor(
    private userService: UserService,
    private localStorageService: LocalStorageService,
    private notificationService: NotificationService,
    private bidDaoService: BidDaoService
  ) {
    this.initBidChangeHandler();
  }

  public undoBid(bidId: number, isInternal: boolean): Observable<void> {
    return this.bidDaoService.undoBid(bidId, isInternal).pipe(
      tap(() => this.popVersion(bidId, constants.UNDO_VERSIONS_STORAGE_KEY)),
      catchError((error: HttpErrorResponse) => {
        if (BidErrorResponseStatusUtil.isShowErrorInNotification(error)) {
          this.notificationService.showServerError(error);
        }
        this.clearVersionsOnBadRequest(error.status, bidId);

        return throwError(error);
      })
    );
  }

  public redoBid(bidId: number, isInternal: boolean): Observable<void> {
    return this.bidDaoService.redoBid(bidId, isInternal).pipe(
      tap(() => this.popVersion(bidId, constants.REDO_VERSIONS_STORAGE_KEY)),
      catchError((error: HttpErrorResponse) => {
        if (BidErrorResponseStatusUtil.isShowErrorInNotification(error)) {
          this.notificationService.showServerError(error);
        }
        this.clearVersionsOnBadRequest(error.status, bidId);

        return throwError(error);
      })
    );
  }

  public hasVersions(bidId: number, key: string): boolean {
    return Boolean(this.getVersions(bidId, key).length);
  }

  public clearVersions(bidId: number): void {
    this.setVersions(bidId, [], constants.UNDO_VERSIONS_STORAGE_KEY);
    this.setVersions(bidId, [], constants.REDO_VERSIONS_STORAGE_KEY);
  }

  private static getNextVersion(versions: number[] = []): number {
    return versions.length ? Math.max(...versions) + 1 : 1;
  }

  private static getOppositeVersionKey(key: string): string {
    if (key === constants.UNDO_VERSIONS_STORAGE_KEY) {
      return constants.REDO_VERSIONS_STORAGE_KEY;
    }
    if (key === constants.REDO_VERSIONS_STORAGE_KEY) {
      return constants.UNDO_VERSIONS_STORAGE_KEY;
    }
  }

  private clearVersionsOnBadRequest(status: HttpStatusCode, bidId: number): void {
    if (status === HttpStatusCode.BAD_REQUEST) {
      this.clearVersions(bidId);
    }
  }

  private initBidChangeHandler(): void {
    this.bidChange$
      .pipe(untilDestroyed(this))
      .subscribe((bidId: number) => {
        this.pushVersion(bidId, constants.UNDO_VERSIONS_STORAGE_KEY);
        this.setVersions(bidId, [], constants.REDO_VERSIONS_STORAGE_KEY);
      });
  }

  private getVersions(bidId: number, key: string): number[] {
    const store = this.localStorageService.retrieve(key) || {};
    const versions = store[bidId] || {};

    return versions[this.userService.user.getValue().id] || [];
  }

  private pushVersion(bidId: number, key: string): void {
    const versions = this.getVersions(bidId, key);
    const nextVersion = UndoRedoService.getNextVersion(versions);

    versions.unshift(nextVersion);
    versions.splice(constants.UNDO_REDO_VERSIONS_LIMIT);

    this.setVersions(bidId, versions, key);
  }

  private popVersion(bidId: number, key: string): number {
    const versions = this.getVersions(bidId, key);
    const version = versions.shift();

    if (version) {
      this.setVersions(bidId, versions, key);
      this.pushVersion(bidId, UndoRedoService.getOppositeVersionKey(key));
    }

    return version;
  }

  private setVersions(bidId: number, newVersions: number[], key: string): void {
    const store = this.localStorageService.retrieve(key) || {};
    const versions = store[bidId] || {};

    versions[this.userService.user.getValue().id] = newVersions;
    store[bidId] = versions;

    this.localStorageService.store(key, store);

    if (key === constants.UNDO_VERSIONS_STORAGE_KEY) {
      this.undoVersionChange$.next();
    } else if (key === constants.REDO_VERSIONS_STORAGE_KEY) {
      this.redoVersionChange$.next();
    }
  }
}
