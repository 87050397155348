import { Bid } from '@qv-bid/entities';
import { QvCache } from '@qv-common/decorators';
import { CompanyUtils } from '@qv-company/utils';
import { User } from '@qv-user/entities/user.entity';
import { constants } from '@qv-common/static';
import { BidAssignee, BidStatus, Company, CompanyType } from 'quantuvis-core-entities';
import { DrugScenarioStatus } from '@qv-bid/enums';
import { NdcValue } from '@qv-term/entities';
import { Moment } from 'moment';

export class BidUtils {
  public static isUserCompanyRelatedToBid(user: User, bid: Bid): boolean {
    return (bid.payer.id === user.company.id) || (bid.manufacturer.id === user.company.id);
  }

  public static isBidToCurrentUserAssigned(user: User, bid: Bid, perspective: string): boolean {
    if (bid.isAssignedToPayer() && perspective === CompanyType.PAYER
      || bid.isAssignedToPharma() && perspective === CompanyType.PHARMA) {
      return bid.editor && bid.editor.userId === user.id;
    }

    return false;
  }

  public static isBidLockableForUser(user: User, bid: Bid, perspective: string): boolean {
    if (bid.isAssignedToPharma() && user.company.companyType === CompanyType.PHARMA
      && perspective === CompanyType.PHARMA || bid.isAssignedToPayer()
      && (user.company.companyType === CompanyType.PAYER || perspective === CompanyType.PAYER)) {
      return !Boolean(bid.editor);
    }

    return false;
  }

  public static isBidEditableByUser(user: User, bid: Bid, perspective: string): boolean {
    return (bid.isStatusDraft() || bid.isStatusRfpNotSent())
      && bid.isBidToUserCompanyAssigned(user.company.id)
      && BidUtils.isBidToCurrentUserAssigned(user, bid, perspective);
  }

  public static isBidReviewedByUser(user: User, bid: Bid, perspective: string): boolean {
    return bid.inReview && bid.isStatusAcceptedWithChanges()
      && bid.isBidToUserCompanyAssigned(user.company.id)
      && BidUtils.isBidToCurrentUserAssigned(user, bid, perspective);
  }

  @QvCache()
  public static getAssignedCompanyName(bidAssignee: BidAssignee, manufacturerName: string, payerName: string): string {
    let companyName = '';
    if (bidAssignee === BidAssignee.PAYER && payerName) {
      companyName = payerName;
    } else if (bidAssignee === BidAssignee.PHARMA && manufacturerName) {
      companyName = manufacturerName;
    }

    return companyName;
  }

  public static getSenderCompanyName(bid: Bid): string {
    if (bid.assignee === BidAssignee.PAYER && bid.manufacturer) {
      return bid.manufacturer.name;
    } else if (bid.assignee === BidAssignee.PHARMA && bid.payer) {
      return bid.payer.name;
    }

    return '';
  }

  public static getManufacturerCompanyDisplayName(bid: Bid): string | null {
    return bid.manufacturer
      ? CompanyUtils.getCompanyFullName(
        bid.manufacturer.name, bid.manufacturer.isPharma(), bid.manufacturerCompanyOldName)
      : null;
  }

  public static isAtNdc(value: string | Moment | NdcValue<any>): boolean {
    return value instanceof NdcValue
      ? value.isNdc
      : value === constants.AT_NDC;
  }

  public static isDismissed(status: DrugScenarioStatus): boolean {
    return status && [DrugScenarioStatus.DISMISSED, DrugScenarioStatus.DISMISSED_IN_REVIEW].includes(status);
  }

  public static isBidEditingByOtherPerson(bid: Bid, userId: number): boolean {
    return bid.editor && bid.editor.userId !== userId;
  }

  public static isBidReviewingByOtherPerson(bid: Bid, userId: number): boolean {
    return bid.inReview && BidUtils.isBidEditingByOtherPerson(bid, userId);
  }

  public static getBidOppositeCompanyByCompanyId(bid: Bid, companyId: number): Company {
    return bid.payer.id === companyId ? bid.manufacturer : bid.payer;
  }

  public static getBidOppositeCompanyByType(bid: Bid, companyType: CompanyType): Company {
    return bid.manufacturer.companyType === companyType ? bid.payer : bid.manufacturer;
  }

  @QvCache()
  public static getOppositeCompanyName(isUserPharma: boolean, payerName: string, manufacturerName: string): string {
    return isUserPharma ? payerName : manufacturerName;
  }

  public static isUserPharmaOrPharmaPerspective(userIsPharma: boolean, isBidInternal: boolean,
                                                isPharmaPerspective: boolean): boolean {
    return userIsPharma && (!isBidInternal || isPharmaPerspective);
  }

  public static isUserPayerOrPayerPerspective(isCurrentUserPayer: boolean, isBidInternal: boolean,
                                              isPayerViewPerspective: boolean): boolean {
    return isCurrentUserPayer || (isBidInternal && isPayerViewPerspective);
  }

  public static isSendBidAvailable(bid: Bid, user: User, perspective: string): boolean {
    const statusDraft = bid.isStatusDraft();
    const statusRfpNotSent = bid.isStatusRfpNotSent();
    const userCompanyAssigned = bid.isBidToUserCompanyAssigned(user.company.id);
    const bidLockable = BidUtils.isBidLockableForUser(user, bid, perspective);
    const bidEditable = BidUtils.isBidEditableByUser(user, bid, perspective);

    return (statusDraft || statusRfpNotSent) && userCompanyAssigned && (bidLockable || bidEditable);
  }

  public static isAvailableRoleSwitcher(bid: Bid): boolean {
    return bid && bid.isInternal && (bid.status !== BidStatus.RFP_NOT_SENT);
  }

  public static isBidViewUrl(url: string): boolean {
    return /^\/((standard)|(grid))\/bid/.test(url);
  }
}
