<div class="report-modal report-feedback-modal">
  <mat-checkbox
      [formControl]="$any(form.get('rebateEligible'))"
      class="qv-mat-checkbox qv-mat-checkbox--sm"
  >Should be Rebate Eligible
  </mat-checkbox>
  <mat-checkbox
      [formControl]="$any(form.get('incorrect'))"
      class="qv-mat-checkbox qv-mat-checkbox--sm"
  >Drug Information is Incorrect
  </mat-checkbox>
  <mat-checkbox
      [formControl]="$any(form.get('notUnderCorrect'))"
      class="qv-mat-checkbox qv-mat-checkbox--sm"
  >Is Not Under The Correct Pharma
  </mat-checkbox>

  <div
      [class.report-modal__message-title--error]="isShowError()"
      class="report-modal__message-title report-feedback-modal__message-title"
  >Please add additional info regarding your request below* :
  </div>
  <textarea
      [formControl]="$any(form.get('feedbackMessage'))"
      class="report-modal__message"
      matInputAutofocus
  ></textarea>
</div>
