import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ContractedBusinessImportModalComponent } from '@qv-bid/components/shared/cb-import-modal/cb-import-modal.component';
import { ContractBusinessImportModalData } from '@qv-bid/components/shared/cb-import-modal/models/cb-import-modal-data';
import { ModalConfig, ModalSize } from 'quantuvis-angular-common/modal';

export class ContractedBusinessImportModalConfig
  extends ModalConfig<ContractedBusinessImportModalComponent, ContractBusinessImportModalData> {

  constructor(data: ContractBusinessImportModalData) {
    super(data, ModalSize.X_SMALL);
  }

  protected createDialogRef(dialog: MatDialog): MatDialogRef<ContractedBusinessImportModalComponent> {
    return dialog.open(ContractedBusinessImportModalComponent, this);
  }
}
