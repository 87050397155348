<section class="qv-modal-dialog market-basket-modal market-basket-modal--edit" *blockUI="'market-basket-edit-modal'">
  <mb-market-basket-template-modal-header [title]="getModalTitle(state)"></mb-market-basket-template-modal-header>

  <div class="qv-modal-dialog-content qv-modal-dialog-content--font-light">
    <div class="market-basket-info">
      <div class="market-basket-info__row">
        <div class="market-basket-info__column">
          <div class="form-field">
            <div class="form-field__label">Market Basket Name</div>
            <div class="form-field__value">
              <input
                class="qv-modal-dialog-content__field"
                data-test-id="modal-input-market-basket-name"
                matInput
                placeholder="Enter name"
                [autofocus]="true"
                [formControl]="marketBasketNameControl"
                (blur)="onMarketBasketNameBlur()"
              >
              <acc-form-field-errors
                class="qv-modal-dialog-content__error"
                [control]="marketBasketNameControl"
                [validationMessages]="validationMessages">
              </acc-form-field-errors>
            </div>
          </div>
        </div>
      </div>
    </div>

    <qv-market-basket-info *ngIf="marketBasket"
      [marketBasket]="marketBasket"
      [manufacturerCompanyOldName]="manufacturerCompanyOldName">
    </qv-market-basket-info>

    <div class="panels-row market-basket-modal__content">
      <div class="left-column">
        <mb-market-basket-drug-search
          class="market-basket-modal__drug-search"
          [disabledDrugName]="drugName"
          [disabledDrugClass]="drugClass"
          [selectedDrugs]="marketBasketDrugs$ | async"
          [searchControl]="searchControl"
          (selectionChanged)="onSelectionChanged($event)">
        </mb-market-basket-drug-search>
      </div>
      <div class="right-column">
        <mb-market-basket-drugs
          class="market-basket-modal__drugs"
          [isClearAllDisabled]="isClearAllDrugsDisabled$ | async"
          [clearAllDisabledTooltipText]="clearAllDisabledTooltipText"
          [disabledDrugName]="drugName"
          [disabledDrugClass]="drugClass"
          [isEditMode]="isEditMode"
          [marketBasketDrugs]="marketBasketDrugs$ | async"
          (selectionChanged)="onSelectionChanged($event)"
          (searchForDrug)="onSearchForDrug($event)">
        </mb-market-basket-drugs>
      </div>
    </div>
  </div>

  <footer mat-dialog-actions class="qv-modal-dialog-actions qv-modal-dialog-actions--two-column">
    <div class="left-block">
      <mat-checkbox
        class="qv-mat-checkbox qv-mat-checkbox--add-to-other"
        *ngIf="scenarioId"
        [(ngModel)]="addToOtherScenarios">
        Add Market Basket to all other drug scenarios
      </mat-checkbox>
    </div>
    <div class="right-block">
      <button mat-button
        class="qv-modal-dialog-actions__item cancel-btn"
        (click)="onClose()">
        Cancel
      </button>

      <button mat-button
        class="qv-modal-dialog-actions__item qv-modal-dialog-actions__item--primary save-btn"
        [disabled]="!(isSaveEnabled$ | async)"
        (click)="onSave()">
        Save
      </button>
    </div>
  </footer>
</section>
