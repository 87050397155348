import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input, QueryList,
  ViewChildren
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { TermTemplateStorageService } from '@qv-term/services';
import { NdcValue } from '@qv-term/entities';
import { BaseTermComponent } from '@qv-term/components/base-term/base-term.component';
import { debounceTime, filter, map, take } from 'rxjs/operators';
import { Subject, Subscription } from 'rxjs';
@Component({
  selector: 'qv-term-textarea',
  templateUrl: './term-textarea.component.html',
  styleUrls: ['./term-textarea.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TermTextareaComponent extends BaseTermComponent {
  @ViewChildren('termTextarea')
  public termTextAreaChildren: QueryList<ElementRef>;

  @Input()
  public termValue: string | NdcValue<string>;
  @Input()
  public maxCharCount: number;
  @Input()
  public debounceTime: number;
  @Input()
  public control: FormControl;
  public textAreaChanges$ = new Subject();
  private textAreaChanges$Subscribe: Subscription;

  constructor(protected termTemplateStorage: TermTemplateStorageService, changeDetectorRef: ChangeDetectorRef) {
    super(termTemplateStorage, changeDetectorRef);
  }

  public onBlur(): void {
    this.textAreaChanges$Subscribe.unsubscribe();
    this.control.setValue(this.control.value.trim(), { emitEvent: false });
    this.deactivateOverrideMode();
  }

  public initChangesHandler(): void {
    this.textAreaChanges$Subscribe = this.textAreaChanges$.pipe(
      map(({ target }: { target: HTMLTextAreaElement }) => target.value),
      debounceTime(this.debounceTime),
      map((value: string) => this.debounceTime ? value.trim() : value)
    ).subscribe((value: string) => this.control.setValue(value));
  }

  public focus(): void {
    this.termTextAreaChildren.changes.pipe(
      filter(() => Boolean(this.termTextAreaChildren.first)),
      take(1)
    ).subscribe(() => this.termTextAreaChildren.first.nativeElement.focus());
  }
}
