import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { TermName, TermSection } from '@qv-term/enums';
import { GridColumnState, GridSectionState } from '@qv-bid/enums';
import { EventEmitter, Injectable } from '@angular/core';
import { TermVisibilityChanges } from '@qv-bid/models';


@Injectable()
export class TermVisibilityService {
  public visibilityChanged$ = new EventEmitter<TermVisibilityChanges>();

  private groupTermMap = new Map([
    [TermName.REBATE, [TermName.MIN_BASE_REBATE, TermName.BASE_REBATE]],
    [TermName.COMPETITION_UM, [
      TermName.PRIOR_AUTHORIZATION_REQUIRED_ON_COMPETITORS_PRODUCTS,
      TermName.STEP_THERAPY_REQUIRED_ON_COMPETITORS_PRODUCTS,
    ]],
    [TermName.PRODUCT_UM, [
      TermName.PRIOR_AUTHORIZATION_ALLOWED_ON_PRODUCT,
      TermName.STEP_THERAPY_ALLOWED_ON_PRODUCT,
    ]],
  ]);

  private sectionsMap = new Map<TermSection, Map<TermName, BehaviorSubject<boolean>>>([
    [TermSection.REBATE, new Map([
      [TermName.REBATE, new BehaviorSubject(true)],
      [TermName.TIER_PLACEMENT, new BehaviorSubject(true)],
      [TermName.TIER_COMPETITION, new BehaviorSubject(true)],
      [TermName.COMPETITORS_TIER, new BehaviorSubject(true)],
      [TermName.COMPETITION_GROUP, new BehaviorSubject(true)],
      [TermName.MARKET_BASKET, new BehaviorSubject(true)],
      [TermName.NOTES, new BehaviorSubject(true)],
    ])],
    [TermSection.UM, new Map([
      [TermName.PRODUCT_UM, new BehaviorSubject(true)],
      [TermName.COMPETITION_UM, new BehaviorSubject(true)],
      [TermName.QUANTITY_LIMIT, new BehaviorSubject(true)],
      [TermName.OTHER_UM, new BehaviorSubject(true)],
      [TermName.UM_DETAILS, new BehaviorSubject(true)],
    ])],
    [TermSection.SCENARIO, new Map([
      [TermName.SCENARIO_START_DATE, new BehaviorSubject(true)],
      [TermName.SCENARIO_END_DATE, new BehaviorSubject(true)],
      [TermName.ADMINISTRATION_FEE, new BehaviorSubject(true)],
    ])],
    [TermSection.PRICE_PROTECTION, new Map([
      [TermName.NET_EFFECTIVE_PRICE, new BehaviorSubject(true)],
      [TermName.INDEX, new BehaviorSubject(true)],
      [TermName.THRESHOLD, new BehaviorSubject(true)],
      [TermName.RESET, new BehaviorSubject(true)],
      [TermName.BASELINE_START_DATE, new BehaviorSubject(true)],
      [TermName.BASELINE_WAC, new BehaviorSubject(true)],
      [TermName.NEW_PRICE_EFFECTIVE_DATE, new BehaviorSubject(true)],
    ])],
    [TermSection.MARKET_SHARE, new Map([
      [TermName.MARKET_SHARE_TIER, new BehaviorSubject(true)],
      [TermName.RANGE_OR_UNITS_AND_REBATES, new BehaviorSubject(true)],
    ])],
  ]);

  private columnsMap: Map<TermName, BehaviorSubject<boolean>>;
  private termGroupMap = new Map<TermName, TermName>();

  constructor() {
    this.prepareColumnsMap();
    this.prepareTermGroupMap();
  }

  public getLastVisibleTermOfSection(termSection: TermSection): TermName {
    const termName = Array.from(this.sectionsMap.get(termSection).entries())
      .filter((values: [TermName, BehaviorSubject<boolean>]) => values[1].value)
      .map((values: [TermName, BehaviorSubject<boolean>]) => values[0])
      .pop();

    return this.groupTermMap.has(termName) ? Array.from(this.groupTermMap.get(termName)).pop() : termName;
  }

  public getTermState(termName: TermName | TermSection): boolean {
    if (this.termGroupMap.has(termName as TermName)) {
      termName = this.termGroupMap.get(termName as TermName);
    }

    if (this.columnsMap.has(termName as TermName)) {
      return this.columnsMap.get(termName as TermName).value;
    } else if (this.sectionsMap.has(termName as TermSection)) {
      const terms = this.sectionsMap.get(termName as TermSection);

      return Array.from(terms.values()).some((state: BehaviorSubject<boolean>) => state.value === true);
    }
  }

  public isColumnVisible(termName: TermName): Observable<boolean> {
    return this.getColumnState(termName).pipe(map((state: GridColumnState) => state === GridColumnState.VISIBLE));
  }

  public isSectionVisible(termSection: TermSection): Observable<boolean> {
    return this.getSectionState(termSection).pipe(map((state: GridSectionState) => state !== GridSectionState.HIDDEN));
  }

  public toggleColumnState(termName: TermName): void {
    const columnState = this.columnsMap.get(termName);

    columnState.pipe(take(1)).subscribe((state: boolean) => {
      columnState.next(!state);
      this.triggerChanges(termName, !state);
    });
  }

  public changeSectionState(termSection: TermSection): void {
    this.getSectionState(termSection)
      .pipe(take(1))
      .subscribe((state: GridSectionState) => {
        if (state === GridSectionState.PARTIALLY_HIDDEN) {
          this.resetSectionState(termSection);
        } else {
          this.toggleSectionState(termSection);
        }
      });
  }

  public resetAll(): void {
    this.getSections().forEach((termSection: TermSection) => this.resetSectionState(termSection));
  }

  public getColumnState(termName: TermName): Observable<GridColumnState> {
    return this.columnsMap.get(termName)
      .asObservable()
      .pipe(map((state: boolean) => state ? GridColumnState.VISIBLE : GridColumnState.HIDDEN));
  }

  public getSectionState(termSection: TermSection): Observable<GridSectionState> {
    return combineLatest(Array.from(this.sectionsMap.get(termSection).values()))
      .pipe(map((columnsStates: boolean[]) => this.getSectionStateByColumnsStates(columnsStates)));
  }

  public getSections(): TermSection[] {
    return Array.from(this.sectionsMap.keys());
  }

  public getColumns(sectionName: TermSection): TermName[] {
    return Array.from(this.sectionsMap.get(sectionName).keys());
  }

  private toggleSectionState(termSection: TermSection): void {
    this.getColumns(termSection).forEach((termName: TermName) => this.toggleColumnState(termName));
  }

  private resetSectionState(termSection: TermSection): void {
    this.getColumns(termSection).forEach((termName: TermName) => this.resetColumnState(termName));
  }

  private resetColumnState(termName: TermName): void {
    const columnState = this.columnsMap.get(termName);

    columnState.pipe(take(1)).subscribe((state: boolean) => {
      columnState.next(true);

      if (!state) {
        this.triggerChanges(termName, true);
      }
    });
  }

  private prepareColumnsMap(): void {
    const columnsList = Array.from(this.sectionsMap.values()).map((section) => Array.from(section.entries()));

    this.columnsMap = new Map<TermName, BehaviorSubject<boolean>>([].concat(...columnsList));
  }

  private prepareTermGroupMap(): void {
    this.groupTermMap.forEach((terms: TermName[], key: TermName) => {
      terms.every((term: TermName) => this.termGroupMap.set(term, key));
    });
  }

  private getSectionStateByColumnsStates(states: boolean[]): GridSectionState {
    switch (true) {
      case states.every((state: boolean) => state === true):
        return GridSectionState.VISIBLE;
      case states.every((state: boolean) => state === false):
        return GridSectionState.HIDDEN;
      case states.some((state: boolean) => state === false):
        return GridSectionState.PARTIALLY_HIDDEN;
    }
  }

  private triggerChanges(termName: TermName, state: boolean): void {
    const terms = this.groupTermMap.has(termName) ? this.groupTermMap.get(termName) : [termName];

    this.visibilityChanged$.next(new TermVisibilityChanges(terms, state));
  }
}
