<header mat-dialog-title class="qv-modal-dialog-title qv-modal-dialog-title--no-margin">
  <h1 class="qv-modal-dialog-title__text">
    {{ title }}
    <span *ngIf="isArchived" class="qv-modal-dialog-title__archived-state"> {{ stateConstants.archivedStateDisplayValue }}
    </span>
  </h1>
  <button class="qv-modal-dialog-title__xbutton" mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</header>
