import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { ApiUrlPrefix } from '@qv-common/enums';
import { constants, resources } from '@qv-common/static';
import { DateUtils } from '@qv-common/utils/date.utils';
import {
  ContractDateConflictsModalConfig
} from '@qv-shared/components/contract-date-conflicts-modal/models/contract-date-conflicts-modal-config';
import {
  ContractDateConflictsModalData
} from '@qv-shared/components/contract-date-conflicts-modal/models/contract-date-conflicts-modal-data';
import { drugTermsConstants } from '@qv-term/constants';
import { TermName } from '@qv-term/enums';
import { ModalSize } from 'quantuvis-angular-common/modal';
import { of } from 'rxjs';
import { catchError, first, tap } from 'rxjs/operators';
import { biddingConstants } from '../../../constants';
import { UserService } from '@qv-common/services/auth/user.service';

declare let angular: angular.IAngularStatic;
declare let $: any;

export const BidDetailsService = [
  '$q', '$timeout', 'util', 'bidding', 'translations', 'drugTermGroupStateManager', 'biddingUtilsService',
  'localStorageService', 'viewPerspectiveService', 'modalService', 'apiService', 'userService', 'BaselineWacService',
  function($q, $timeout, util, bidding, translations, drugTermGroupStateManager, biddingUtilsService, localStorageService,
   viewPerspectiveService, modalService, apiService, userService: UserService, BaselineWacService): any {
  const context: any = {};
  'ngInject';
  let orderedSaveQueue = [];
  let dataObjects = {};
  let needRefreshDrugsTable = false;
  let metadata;
  let bidId, planTypeIndex, version;
  let bidInfo, bid, markBatchOperation, workingScope;
  let editingCompanyType;

  context.immediatelySaveEvents = Object.values(constants.IMMEDIATELY_SAVE_EVENTS);

  context.getCurrentPlanTypeIndex = () => parseInt(planTypeIndex) || 0;
  context.hasItemsInSaveQueue = () => orderedSaveQueue.length > 0;

  /**
   * Flag to block done editing call, and keep bid in edit mode after reloading
   *
   * @type {Boolean}
   */
  let blockDoneEditingCall;

  // Used to set default term values needed before drugs processing
  const defaultTerms = [
    {
      name: drugTermsConstants[TermName.RANGE_OR_UNITS_AND_REBATES].title,
      value: [{
        range_start: '',
        range_end: '',
        rebate: ''
      }]
    }];

  const copyValueEvent = { source: 'copyValues' };

  /********************* Private functions *********************/
  function getStatus(s?) {
    return biddingUtilsService.computeBidStatus(bidInfo, userService.user.getValue(), viewPerspectiveService.getViewPerspective());
  }

  function getBindingBid() {
    return biddingUtilsService.computeBindingBidType(bid);
  }

  /**
   * Returns the information required for importing one tab form another.
   *
   * @param currentPlanType
   *            - current plan type information required for importing one tab form another
   * @return Array containing the information required for importing one tab from another
   */
  function getInfoForPlanTypes(currentPlanType): void {
    const infoForPlanTypes: any = [
      {
        planTypeName: constants.BIDDING.IMPORT_PLAN_TYPE_SELECTION
      }
    ];

    bid.planTypes.forEach(planType => {
      if (util.isNotDefined(currentPlanType) || planType.planTypeId !== currentPlanType.planTypeId) {
        infoForPlanTypes.push({
          planTypeName: bidding.getPlanTypeName(planType),
          planTypeId: planType.planTypeId
        });
      }
    });

    return infoForPlanTypes;
  }

  function addToQueue(action?, path?, data?, scenario?, drugName?, meta?, event?) {
    let pathKey = path.join();

    if (meta) {
      pathKey = data.path;
    } else if (scenario) {
      pathKey += scenario.group + '.' + scenario.scenario;
    }

    if (action) {
      pathKey += `.${action}`;
    }

    if (!dataObjects[pathKey]) {
      const obj: any = {
        action: action,
        path: path,
        fullPath: pathKey
      };

      if (scenario) {
        obj.scenario = scenario;
      }

      if (drugName) {
        obj.drugName = drugName;
      }

      if (event && event.source) {
        obj.operation = event.source;
      }

      orderedSaveQueue.push(obj);
    }

    dataObjects[pathKey] = angular.copy(data);
  }

  function addToQueueWithTermValidation(termData?, action?, path?, data?, scenario?, drugName?, meta?, event?) {
    validateTerm(termData);
    addToQueue(action, path, data, scenario, drugName, meta, event);
  }

  function extractScenarios(data) {
    let scenarios;

    if (data.groupScenario || data.scenario) {
      scenarios = {};
      if (data.groupScenario) {
        scenarios.group = data.groupScenario.id;
      }
      if (data.scenario) {
        scenarios.scenario = data.scenario.id;
      }
    }

    return scenarios;

  }

  function getPathForNDC(data?, term?) {
    const path = [];
    path.push('drugList');

    if (term) {
      if (term.definition && term.definition.groupPath) {
        path.push(term.definition.groupPath[1]);
      }
      if (term.name) {
        path.push(term.name);
      }
      path.push(data.name);
    }
    path.push(data.ndc);

    return path;
  }

  function getBidManufacturer() {
    return bidInfo ? bidInfo.manufacturer : {};
  }

  function getBidPayer() {
    if (bidInfo) {
      return bidInfo.payer;
    } else if (biddingUtilsService.isPayer(userService.user.getValue())) {
      // At create RFP bidInfo doesn't exist, but current user is a payer, so we use that.
      return userService.user.getValue().company;
    } else {
      return {};
    }
  }

  function processAllowOrDisallowForValueChanged(data) {
    const termGroupPath = data.term.definition.groupPath;
    const renderData = data.drug.renderData;
    if (biddingUtilsService.isPayer(userService.user.getValue())
      && !biddingUtilsService.isNotDefined(data.term.definition.groupPath)) {
      const hiddenState = constants.DRUG_TERM_GROUPS_STATE_VALUES.HIDDEN_STATE.state;
      if (renderData[termGroupPath[termGroupPath.length - 1]].state === hiddenState) {
        if (renderData.isGroup) {
          drugTermGroupStateManager.manageGroupLevelStateForTermsGroup(data.drug, metadata, termGroupPath,
            workingScope, biddingConstants.drugGroupStateOptionsValuesMap.allowed);
        } else {
          drugTermGroupStateManager.manageNdcLevelStateForTermsGroup(data.drug, metadata, termGroupPath,
            workingScope, biddingConstants.drugGroupStateOptionsValuesMap.allowed, biddingConstants.drugGroupStateOptionsValuesMap);
        }
      }
    }
  }

  function isTermGroupStateAvailable(termGroupState?, isPharma?) {
    if (isPharma) {
      return termGroupState.state !== constants.DRUG_TERM_GROUPS_STATE_VALUES.HIDDEN_STATE.state;
    } else {
      return (termGroupState.state !== constants.DRUG_TERM_GROUPS_STATE_VALUES.HIDDEN_STATE.state
        && termGroupState.state !== constants.DRUG_TERM_GROUPS_STATE_VALUES.LOCKED_STATE.state);
    }
  }

  function processData(data) {
    context.disableBidActionsFlags = false;
    if (typeof context.scenarioChangeEditModeCallback === 'function') {
      context.scenarioChangeEditModeCallback();
    }
    let output: any = {
      allowPharmaOptions: {
        allowMarketShareRebate: {
          path: constants.BID_SECTIONS.ALLOW_MARKET_SHARE_REBATE_PATH,
          value: false
        },
        allowMarketBasket: {
          path: constants.BID_SECTIONS.ALLOW_MARKET_BASKET_PATH,
          value: false
        },
        allowContractedBusiness: {
          path: constants.BID_SECTIONS.ALLOW_CONTRACTED_BUSINESS_PATH,
          value: false
        },
        allowScenarios: {
          path: constants.BID_SECTIONS.ALLOW_CREATE_SCENARIOS_PATH,
          value: false
        },
        allowDependencies: {
          path: constants.BID_SECTIONS.ALLOW_DEPENDENCIES_PATH,
          value: false
        },
        lockNotes: {
          path: constants.BID_SECTIONS.LOCK_NOTES_PATH,
          value: false
        },
        allowOffersAtNdcLevel: {
          path: constants.BID_SECTIONS.ALLOW_NDC_OFFERS,
          value: false
        }
      }
    }, contractTerms, biddingInformation;

    /**
     * Set default values for drug terms if term doesn't exist
     *
     * @param drug
     * @returns {Object} drug
     */
    function forceDrugTermDefaults(drug): any {
      let termExistsForDrug;
      defaultTerms.forEach((defaultTerm) => {
        termExistsForDrug = drug.terms.find(term => term.name === defaultTerm.name);
        if (!termExistsForDrug) {
          // QU-5753: Make sure object and values are new objects, not references of the same one
          // If they are references, the watcher confusion reappears
          // TODO: reanalyse this bug, see if array being passed as references instead of new
          // instances was the cause all along, and maybe a fix can be done without these defaults
          drug.terms.push({
            name: angular.copy(defaultTerm.name),
            value: angular.copy(defaultTerm.value)
          });
        }
      });
      return drug;
    }

    function extractMetadata(meta, path) {
      output[path] = {};
      meta.forEach((m) => output[path][m.path] = m.flags);
    }

    /**
     * Propagate terms metadata to drugList
     *
     * for using this mechanism specify in druglist term a property with name 'copyFromBidPath'
     * and a term path from where metadata should be copied.
     *
     * @param bidData Object Bid data object for processing response from Back-End
     * @returns Object Bid metadata with propagated values
     */
    function propagateMetadataToDrugList(bidData) {
      let terms = bidding.BiddingData();
      terms = [...terms.summaryTerms, ...terms.contractTerms, ...terms.drugListTerms];
      const termsToPropagateMetadata = terms.filter(t => t.copyFromBidPath);
      const bidMetadata = bidData.bidMetadata;
      let drugMeta = bidData.metadata;

      drugMeta = drugMeta || {};

      termsToPropagateMetadata.forEach(term => {
        if (term.lockName) {
          const drugListPath = `${constants.BID_SECTIONS.DRUG_LIST_PATH}.${term.lockName}`;
          drugMeta[drugListPath] = bidMetadata[term.copyFromBidPath];
        } else {
          const drugListPath = `${constants.BID_SECTIONS.DRUG_LIST_PATH}.${term.name}`;
          if (bidMetadata[term.copyFromBidPath]) {
            drugMeta[drugListPath] = bidMetadata[term.copyFromBidPath];
          } else if (drugMeta[term.copyFromBidPath]) {
            drugMeta[drugListPath] = drugMeta[term.copyFromBidPath];
          }
        }
      });

      return drugMeta;
    }

    function setValueForPharmaOption(option) {
      const path = output.allowPharmaOptions[option].path;

      if (path === output.allowPharmaOptions.allowMarketBasket.path ||
        path === output.allowPharmaOptions.allowOffersAtNdcLevel.path) {
        setNotLockedState(option, path);
        return;
      }

      if (path === output.allowPharmaOptions.lockNotes.path) {
        setLockNotesValues(path);
        return;
      }

      output.allowPharmaOptions[option].value = !(output.bidMetadata && output.bidMetadata[path] &&
        (output.bidMetadata[path].state === constants.DRUG_TERM_GROUPS_STATE_VALUES.HIDDEN_STATE.state));
    }

    function setNotLockedState(option, path) {
      output.allowPharmaOptions[option].value = !output.bidMetadata[path] || output.bidMetadata[path] &&
        output.bidMetadata[path].state !== constants.DRUG_TERM_GROUPS_STATE_VALUES.LOCKED_STATE.state;
    }

    function setLockNotesValues(path) {
      output.allowPharmaOptions.lockNotes.value = output.bidMetadata && (output.bidMetadata[path] &&
        output.bidMetadata[constants.BID_SECTIONS.LOCK_NOTES_PATH].state === constants.DRUG_TERM_GROUPS_STATE_VALUES.LOCKED_STATE.state);
    }

    function extractBidInfo(bidDoc) {
      let companyName;

      // qu-5199 - attach old pharma company name in parantheses to be displayed everywhere in bid-details page
      if (!biddingUtilsService.isNotDefined(bidDoc.manufacturer.oldCompanyName)) {
        bidDoc.manufacturer.currentName = bidDoc.manufacturer.name;
        bidDoc.manufacturer.name = bidDoc.manufacturer.name + ' (' + bidDoc.manufacturer.oldCompanyName + ')';
      }
      output.bidInfo = bidInfo = {
        bidId: bidDoc.bidId,
        assignee: bidDoc.assignee,
        status: bidDoc.status,
        manufacturer: bidDoc.manufacturer,
        payer: bidDoc.payer,
        finalBid: bidDoc.finalBid,
        inReview: bidDoc.inReview,
        isInternal: bidDoc.isInternal,
      };

      // Calculate assigned to
      if (util.isNotDefined(output.bidInfo.assignee)) {
        return null;
      }
      if (output.bidInfo.assignee === constants.BidAssigneeTypes.PAYER && !util.isNotDefined(output.bidInfo.payer)) {
        companyName = output.bidInfo.payer.name;
      } else if (output.bidInfo.assignee === constants.BidAssigneeTypes.PHARMA && !util.isNotDefined(bidInfo.manufacturer)) {
        companyName = output.bidInfo.manufacturer.name;
      }

      output.assignedTo = companyName;
    }

    if (!version) {
      biddingInformation = data;
    } else {
      biddingInformation = data.bidDocumentContent;
    }
    extractBidInfo(biddingInformation);

    if (data.version && (output.bidInfo.status !== constants.BidStatuses.DRAFT
      || !biddingUtilsService.isUserCompanyAssigned(userService.user.getValue(), output.bidInfo))) {
      var tmpBidInfo: any = {
        revision: {
          version: data.version,
          date: data.bidRevisionMetadata.formattedDateModified,
          time: data.bidRevisionMetadata.formattedTimeModified,
          author: data.bidRevisionMetadata.createdBy.userCompanyName
        }
      };
      tmpBidInfo.revision.formattedVersionHtml = 'Version ' + tmpBidInfo.revision.version +
        ' (' + tmpBidInfo.revision.date + '&nbsp;&nbsp;' + tmpBidInfo.revision.time + ')';
      tmpBidInfo.revision.formattedVersionText = 'Version ' + tmpBidInfo.revision.version +
        ' (' + tmpBidInfo.revision.date + '  ' + tmpBidInfo.revision.time + ')';
    }

    // extractBidInfo() resets output.bidInfo, so we bring back the revision info if there is any.
    if (!util.isNotDefined(tmpBidInfo)) {
      output.bidInfo.revision = tmpBidInfo.revision;
    }
    if (!util.isNotDefined(biddingInformation.meta)) {
      extractMetadata(biddingInformation.meta, 'metadata');
      metadata = output.metadata;
    }

    if (!util.isNotDefined(biddingInformation.bidMeta)) {
      extractMetadata(biddingInformation.bidMeta, 'bidMetadata');
      if (output.allowPharmaOptions) {
        Object.keys(output.allowPharmaOptions).forEach(optionKey => setValueForPharmaOption(optionKey));
      }
    }

    output.metadata = propagateMetadataToDrugList(output);

    output.bid = bid = biddingInformation.bidCurrentVersion;
    output.bid.editable = biddingInformation.editable == true || biddingInformation.editable === 'true';
    output.bid.inReview = biddingInformation.inReview == true || biddingInformation.inReview === 'true';
    output.bid.editRfpSent = biddingInformation.editRfpSent;
    output.bid.editingUser = biddingInformation.editingUser;
    output.bid.isBinding = getBindingBid() === constants.BINDING_BID_TYPE.BINDING;
    output.bid.bidSignature = biddingInformation.bidCurrentVersion.signature;
    output.summaryTerms = bidding.prepareTerms(biddingInformation.bidCurrentVersion.summaryTerms, bidding.BiddingData().summaryTerms, {
      payerCompanyName: biddingInformation.payer.name,
      manufacturerCompanyName: biddingInformation.manufacturer.name,
      addMissingFields: true,
      viewAs: viewPerspectiveService.getViewPerspective()
    }, output.bidMetadata);

    const attachments = biddingUtilsService.getTermByName(constants.SUMMARY_TERMS.ATTACHMENTS.label, output.summaryTerms);
    bidInfo.lobTerm = biddingUtilsService.getTermByName(constants.SUMMARY_TERMS.LINE_OF_BUSINESS.label, output.summaryTerms);
    bidInfo.isBinding = getBindingBid() === constants.BINDING_BID_TYPE.BINDING;

    if (attachments) {
      attachments.definition.biddingInfo = {
        bidId: bid.id,
        manufacturerCompanyId: output.bidInfo.manufacturer.companyId,
        payerCompanyId: output.bidInfo.payer.companyId
      };
    }

    output.lineOfBusinessTerm = biddingUtilsService.getTermByName(constants.SUMMARY_TERMS.LINE_OF_BUSINESS.label, output.summaryTerms);
    output.lineOfBusinessTerm.definition.onChange(output.lineOfBusinessTerm);

    const rfpStartDateTerm = biddingUtilsService.getTermByName(constants.SUMMARY_TERMS.RFP_START_DATE.label, output.summaryTerms);

    // update RFP Start Date with current date if bid status is 'RFP Not Sent'
    // and the user enters the bid detail page
    if (biddingUtilsService.isBidDisplayedStatusRFPNotSent(getStatus(output.bidInfo))) {
      rfpStartDateTerm.value = new Date().getTime();
    }

    output.planTypeTerm = output.lineOfBusinessTerm.planTypeTerm;
    output.planTypeTerm.definition.prepareCustom(output);
    output.planTypeTerm.infoForPlanTypes = getInfoForPlanTypes(output.bid.planTypes);
    output.planTypeTerm.planTypeId = biddingInformation.bidCurrentVersion.planTypes[planTypeIndex].planTypeId;
    output.planTypeTerm.selectedIndex = planTypeIndex;

    // set to empty plan type term value and then add the plan types name and attributes from the bid
    output.planTypeTerm.value = [];
    output.bid.planTypes.forEach(planType => {
      output.planTypeTerm.value.push({
        name: planType.details.name
      });
    });

    output.contractedBusinessTerm = output.planTypeTerm.value[output.planTypeTerm.selectedIndex];
    output.planTypeDependencyCount = biddingInformation.bidCurrentVersion.planTypes[planTypeIndex].dependencyCount;
    if (!output.planTypeDependencyCount) {
      output.planTypeDependencyCount = 1;
    }

    // Prepare contract terms
    contractTerms = bidding.prepareTerms(biddingInformation.bidCurrentVersion.planTypes[planTypeIndex].contractTerms,
      bidding.BiddingData().contractTerms, {
        addMissingFields: true,
        payerCompanyName: biddingInformation.payer.name,
        manufacturerCompanyName: biddingInformation.manufacturer.name
      }, output.metadata);

    output.preparedContractTerms = [
      [], [], []
    ];

    for (let i = 0; i < 3; i++) {
      biddingConstants.contractTermGroups[i].forEach(elem => {
        output.preparedContractTerms[i].push(contractTerms.find(term => term.name === elem));
      });
    }


    output.preparedContractTerms[1].push(
      contractTerms.find(term => term.name === constants.BIDDING.LATE_PAYMENT_PENALTY_TERMS)
    );

    output.contractTerms = contractTerms;
    output.ndcList = {};
    output.manufacturer = biddingInformation.manufacturer;

    output.bid.planTypes[planTypeIndex].drugsList.forEach(drug => output.ndcList[drug.ndc] = drug.ndc);

    // Inject default terms to drugsList
    output.bid.planTypes[planTypeIndex].drugsList.map(drug => forceDrugTermDefaults(drug));

    return output;
  }

  function marketBasketCanBeAssigned(drugName, basket) {
    if (typeof basket === 'undefined') {
      return true;
    } else if (typeof basket !== 'undefined' && basket.drugsList.length > 0) {
      return basket.drugsList.some(drug => drug.name === drugName);
    }
  }

  function shouldUpdateMB(term, drug, copiedTerm) {
    return drug.renderData.isGroup && term.name === drugTermsConstants[TermName.MARKET_BASKET].title
      && marketBasketCanBeAssigned(drug.name, copiedTerm.value);
  }

  function validateContractDate(term, scenarioTerms) {
    if (typeof term.definition.validateDependentDate === 'function') {
      return term.definition.validateDependentDate(term, scenarioTerms);
    }
  }

  function validateDependentTerms(term, terms, pathTerm) {
    const currentPath = util.getPathFromArray(term.definition.path);
    if (currentPath === pathTerm && typeof term.definition.validate === 'function') {
      return term.definition.validate(term, terms);
    }
    return true;
  }

  function validateDrugListTerms(drug) {
    if (!(drug && drug.terms && Array.isArray(drug.terms))) return true;

    let valid = true;

    drug.terms.forEach(term => {
      if (term.name === constants.SUMMARY_TERMS.CONTRACT_START_DATE.label ||
        term.name === constants.SUMMARY_TERMS.CONTRACT_END_DATE.label ||
        term.name === drugTermsConstants[TermName.BASELINE_START_DATE].title) {

        validateContractDate(term, workingScope.summaryTerms);

        if (term.definition.validate &&
          typeof term.definition.validate === 'function') {

          const isTermValid = term.definition.validate(term, drug.terms);
          if (valid) {
            valid = isTermValid;
          }
        }
      }
    });

    return valid;
  }

  function validateTerm(data) {
    if (!(data.term && data.term.definition)) return true;

    const path = data.term.definition.path;
    if (!(Array.isArray(path) && path.length > 0)) return true;

    const pathTerm = util.getPathFromArray(path);

    const section = path[0];
    switch (section) {
      case constants.BID_SECTIONS.SUMMARY_TERMS:
        return validateDependentTerms(data.term, workingScope.summaryTerms, pathTerm);
      case constants.BID_SECTIONS.CONTRACT_TERMS:
        return validateDependentTerms(data.term, workingScope.contractTerms, pathTerm);
      case constants.BID_SECTIONS.DRUG_LIST_PATH:
        return validateDrugListTerms(data.drug);
      default:
        return true;
    }
  }

  /********************** Public functions **********************/
  context.addDrugsToBid = function(selectedNDCs, addToEntireBid, planTypeIndex) {
    const deferred = $q.defer();
    const viewAs = viewPerspectiveService.getViewPerspective();
    const url = `${ApiUrlPrefix.OLD}/bids/addDrugs/${bidId}/${addToEntireBid}/${planTypeIndex}?viewAs=${viewAs}`;
    apiService.post(url, selectedNDCs).pipe(
      tap((response: HttpResponse<any>) => {
        deferred.resolve(response.body);
      }),
      catchError((error: HttpErrorResponse) => {
        deferred.reject(error);

        return of(null);
      })
    ).subscribe();

    return deferred.promise;
  };

  context.cleanUpdateQueue = function() {
    orderedSaveQueue = [];
    dataObjects = {};
  };

  context.loadPlanTypeInformation = function(loadBidId, loadPlanTypeIndex, loadVersion, preloadedBid) {
    bidId = loadBidId;
    planTypeIndex = loadPlanTypeIndex;
    version = loadVersion;
    const request = loadVersion
      ? context.appendViewAs(`bids/bidHistory/${bidId}/${version}/${planTypeIndex}`)
      : context.appendViewAs(`bids/bid/${bidId}/${planTypeIndex}`);

    return new Promise((resolve, reject) => {
      if (preloadedBid && !version) {
        resolve(processData.bind(this)(preloadedBid));
      } else {
        apiService.get(ApiUrlPrefix.OLD + request).pipe(
          tap((response: HttpResponse<any>) => {
            const data = response.body;
            if (util.isNotDefined(data.responseObject)) {
              reject({
                error: true,
                message: 'There was an error while retrieving bid data. Please try again later.'
              });
            } else {
              resolve(processData.bind(this)(data.responseObject));
            }
          }),
          catchError((error: HttpErrorResponse) => {
            if (error.status && error.status === 403) {
              reject({
                message: translations.i18n.GENERAL.RESTRICTED_ACCESS_ERROR,
                error: true
              });
            } else {
              reject(error);
            }

            return of(null);
          })
        ).subscribe();
      }
    });
  };

  function getTermValue(term) {
    let result = '';
    if (term.definition.getValueToSave) {
      result = term.definition.getValueToSave(term);
    } else if (!util.isNotDefined(term.value)) {
      result = term.value;
    } else if (!util.isNotDefined(term.definition.defaultValue)) {
      result = term.definition.defaultValue;
    }
    if (util.isNotDefined(result)) {
      result = '';
    }
    return result;
  }

  function findFirstNonHiddenMarketShareSectionState(data) {
    const ndcList = data.drug.renderData.children;
    if (util.isNotDefined(ndcList) || !Array.isArray(ndcList)) {
      return biddingConstants.drugGroupStateOptionsValuesMap.allowed;
    }

    const firstNdcWithNonHiddenMarketShare = ndcList
      .find(ndc => ndc.renderData.marketShare.state !== constants.DRUG_TERM_GROUPS_STATE_VALUES.HIDDEN_STATE.state);

    if (util.isNotDefined(firstNdcWithNonHiddenMarketShare)) {
      return biddingConstants.drugGroupStateOptionsValuesMap.allowed;
    }

    return Object.values(biddingConstants.drugGroupStateOptionsValuesMap)
      .find(v => v.state === firstNdcWithNonHiddenMarketShare.renderData.marketShare.state);
  }

  function findConflictingScenarioDates(dateTerm, onNoConflictsCallback, onConflictsCallback) {
    const bidId = workingScope.bidInfo.bidId;
    const url = `${ApiUrlPrefix.OLD}/bids/validateContractDateRange/${bidId}`;
    apiService.post(url, dateTerm).pipe(first()).toPromise().then((response) => {
      if (response.body.error) {
        if (typeof onConflictsCallback === 'function') {
          onConflictsCallback(response.body.responseObject);
        }
      } else {
        if (typeof onNoConflictsCallback === 'function') {
          onNoConflictsCallback();
        }
      }
    }).catch(() => {});
  }

  function updateTerm(data) {
    addToQueueWithTermValidation(data, 'update', data.term.definition.path,
      getTermValue(data.term), extractScenarios(data));
    // Trigger bulk update for terms that should 'resetDrugList'
    if (util.isPathDefined(data.term, 'definition.resetDrugList') && data.term.definition.resetDrugList) {
      // Save and reload the page to update terms
      if (workingScope.isBidValid()) {
        needRefreshDrugsTable = false;
        workingScope.saveAndReload();
      } else {
        needRefreshDrugsTable = true;
      }
    }
  }

  context.storeValue = (event, data) => {

    let newMarketShareSectionState;

    if (data.from === 'term') {
      if (data.drug) {
        const renderData = data.drug.renderData;
        if (!event || event.source !== copyValueEvent.source) {
          processAllowOrDisallowForValueChanged(data);
        }
        if (renderData.isGroup) {
          if (data.term.name === drugTermsConstants[TermName.MARKET_BASKET].title && marketBasketCanBeAssigned(data.drug.name, data.term.value)) {
            const action = !data.term.value ? 'delete' : 'update';
            const path = ['drugList', 'marketBasket', data.drug.name, data.drug.scenario.id];
            const copiedData = context.getCopiedValues();
            const basketValue = copiedData.values.find(item => item.name === drugTermsConstants[TermName.MARKET_BASKET].title);
            if (basketValue && basketValue.techDetails) {
              const techDetails = basketValue.techDetails;
              if (techDetails.oldDrugName && techDetails.scenario.id && techDetails.planTypeIndex) {
                const data = {
                  scenario: techDetails.scenario.id,
                  sourcePlanTypeIndex: techDetails.planTypeIndex,
                  sourceBidId: techDetails.bidId
                };
                addToQueueWithTermValidation(data, action, path, data, null, null, null, event);
              }
            }

            return;
          }
          renderData.children.forEach(ndc => {
            if (biddingUtilsService.isTermInHiddenMarketShareSection(ndc, data.term)) {
              if (util.isNotDefined(newMarketShareSectionState)) {
                newMarketShareSectionState = findFirstNonHiddenMarketShareSectionState(data);
              }
              const termGroupPath = data.term.definition.groupPath;
              drugTermGroupStateManager.manageNdcLevelStateForTermsGroup(ndc, metadata, termGroupPath,
                workingScope, newMarketShareSectionState, biddingConstants.drugGroupStateOptionsValuesMap);
            }
            if (biddingUtilsService.isPharma(userService.user.getValue())) {
              const termGroupPath = (data.term.definition.path) ? data.term.definition.path[data.term.definition.path.length - 1]
                : (data.term.definition.groupPath) ? data.term.definition.groupPath[data.term.definition.groupPath.length - 1]
                  : '';
              if (!util.isNotEmpty(termGroupPath) || (isTermGroupStateAvailable(ndc.renderData[termGroupPath]))) {
                addToQueueWithTermValidation(data, 'update', getPathForNDC(ndc, data.term), getTermValue(data.term),
                  extractScenarios(ndc), null, null, event);
              }
            } else {
              if (data.term.definition && !data.term.definition.hasAtNDC) {
                addToQueueWithTermValidation(data, 'update', getPathForNDC(ndc, data.term), getTermValue(data.term), extractScenarios(ndc), null, null, event);
              }
            }
          });
        } else {
          addToQueueWithTermValidation(data, 'update', getPathForNDC(data.drug, data.term), getTermValue(data.term), extractScenarios(data.drug), null, null, event);
        }
      } else {
        let allowUpdate = true;

        if (typeof data.term.definition.isUpdateAllowed === 'function') {
          allowUpdate = data.term.definition.isUpdateAllowed(viewPerspectiveService.getViewPerspective());
        }
        if (allowUpdate) {
          if (data.term.name === constants.SUMMARY_TERMS.CONTRACT_START_DATE.label
            || data.term.name === constants.SUMMARY_TERMS.CONTRACT_END_DATE.label) {
            findConflictingScenarioDates.bind(this)(data.term, () => {
              updateTerm(data);
            }, (conflicts) => {
              const modalData = new ContractDateConflictsModalData('Invalid Values',
                `Populated ${data.term.name} cannot be saved because it becomes invalid for following scenarios:`,
                conflicts);

              const modalConfig = new ContractDateConflictsModalConfig(modalData, ModalSize.X_SMALL);

              modalService.openModal(modalConfig).afterClosed().subscribe(workingScope.refreshBidData);
            });
          } else {
            updateTerm(data);
          }
        }
      }
    } else if (data.from === 'dependencies') {
      if (data.ndc && data.ndc.ndc) {
        if (data.type === 'deleteProperty') {
          addToQueue(data.type, getPathForNDC(data.ndc), data.property, extractScenarios(data.ndc));
        } else {
          addToQueue(data.type, getPathForNDC(data.ndc), data.data, extractScenarios(data.ndc));
        }
      }
    } else if (data.from === 'deleteNotes') {
      if (data.ndc && data.ndc.ndc) {
        if (data.type === 'deleteProperty') {
          addToQueue(data.type, getPathForNDC(data.ndc), data.property, extractScenarios(data.ndc));
        }
      }
    } else if (data.from === 'dismiss') {
      if (data.ndc && data.ndc.ndc) {
        const drugName = data.ndc.name;
        if (data.type === 'deleteProperty') {
          addToQueue(data.type, getPathForNDC(data.ndc), data.property, extractScenarios(data.ndc), drugName);
        } else {
          addToQueue(data.type, getPathForNDC(data.ndc), data.data, extractScenarios(data.ndc), drugName);
        }
      }
    } else if (data.from === 'meta') {
      const event = data.event;
      data.dataChanges.forEach(metaChange => {
        metaChange.path.push(parseInt(planTypeIndex));
        if (!event || !event.source || event.source !== copyValueEvent.source) {
          addToQueue(metaChange.type, metaChange.path, metaChange.data, null, null, true, event);
        }
      });
    } else {
      addToQueue(data.type, data.path, data.data);
    }
  };

  context.setBidLockState = function(lock, viewPerspective) {
    const viewAs = viewPerspective ? viewPerspective : viewPerspectiveService.getViewPerspective();
    const deferred = $q.defer();
    const lockURL = lock ? `/bids/lockBid/${bidId}?viewAs=${viewAs}` : `/bids/unlockBid/${bidId}?viewAs=${viewAs}`;
    apiService.post(ApiUrlPrefix.OLD + lockURL, [bidId]).pipe(first()).toPromise()
      .then(response => {
        deferred.resolve(response.body);
      })
      .catch(error => {
        deferred.reject(error);
      });

    return deferred.promise;
  };

  context.setInReviewState = function(reviewFlag, viewPerspective) {
    const viewAs = viewPerspective ? viewPerspective : viewPerspectiveService.getViewPerspective();
    return apiService.post(`${ApiUrlPrefix.OLD}/bids/review/${bidId}/${reviewFlag}?viewAs=${viewAs}`).pipe(first()).toPromise();
  };

  context.appendViewAs = (url) => viewPerspectiveService.appendPerspectiveViewToUrl(url);

  context.createBidDraftVersion = function(action) {
    const deferred = $q.defer();
    const url = context.appendViewAs(`${ApiUrlPrefix.OLD}/bids/createDraft/${bidId}/${action}`);
    apiService.post(url).pipe(first()).toPromise()
      .then(response => {
        context.disableTermUpdateWatcher = true;
        deferred.resolve(response.body);
      })
      .catch(error => {
        deferred.reject(error);
      });

    return deferred.promise;
  };

  context.discardDraft = () => {
    return new Promise((resolve, reject) => {
      apiService.post(context.appendViewAs(`${ApiUrlPrefix.OLD}/bids/discardDraft/${bidId}`)).pipe(first()).toPromise()
        .then(response => {
          context.disableTermUpdateWatcher = true;
          resolve(response.body);
        })
        .catch(response => {
          reject(response);
        });
    });
  };

  context.disableBidActions = (prevValue, currentVale) => {
    if (!angular.equals(prevValue, currentVale)) {
      context.disableBidActionsFlags = true;
    }
  };

  context.setWorkingScope = ($scope) => {
    workingScope = $scope;
  };

  context.getWorkingScope = () => {
    return workingScope;
  };

  /**
   * Disable term watcher
   */
  context.disableTermUpdate = () => {
    context.disableTermUpdateWatcher = true;
  };

  /**
   * Enable term watcher
   *
   * @param {boolean} runInCurrentTask - If we need update flag in current task then set parameter to true
   */
  context.enableTermUpdate = (runInCurrentTask) => {
    if (runInCurrentTask) {
      context.disableTermUpdateWatcher = false;
    } else {
      $timeout(() => {
        context.disableTermUpdateWatcher = false;
      }, 0);
    }
  };

  // 'isBidValid' is a function that if defined will return the validity sate of the bid
  context.save = function(markBatchOperationData, hasUpdatesClosure, forcedClosure) {
    if (orderedSaveQueue.length > 0) {
      return new Promise((resolve, reject) => {
        markBatchOperation = markBatchOperationData;
        if (bid && bid.editable || bidInfo.inReview) {
          context.disableBidActionsFlags = true;
          if (hasUpdatesClosure) {
            hasUpdatesClosure();
          }

          orderedSaveQueue.forEach(elem => {
            elem.data = dataObjects[elem.fullPath];
            if (markBatchOperation) {
              elem.operation = markBatchOperation;
            }
            delete elem.fullPath;
          });

          const viewAs = context.editingCompanyType || viewPerspectiveService.getViewPerspective();

          apiService.post(`${ApiUrlPrefix.OLD}/bids/update/${bidId}/${planTypeIndex}?viewAs=${viewAs}`).pipe(first()).toPromise()
            .then(response => {
              if (forcedClosure) {
                forcedClosure();
              }
              context.disableBidActionsFlags = false;
              resolve(response.body);
              context.saveVersionForUndo(response.body.responseObject.version);

              if (needRefreshDrugsTable) {
                context.getWorkingScope().processGroup(context.getWorkingScope().group);
                needRefreshDrugsTable = false;
              }
            })
            .catch(error => {
              context.disableBidActionsFlags = false;
              needRefreshDrugsTable = false;
              reject(error);
            });

          orderedSaveQueue = [];
          dataObjects = {};
          markBatchOperation = null;
        }
      });
    } else if (forcedClosure) {
      forcedClosure();
    }
  };

  context.getNotRenderedDrugScenarioKeys = () => {
    const pairs = new Set();
    try {
      const drugGroups = workingScope.group.filter(drug => drug.renderData.isGroup && !drug.terms);
      drugGroups.forEach(group => {
        pairs.add(`${group.name}|${group.scenario.name}`);
      });
    } catch (e) {
      throw new Error(`Not rendered groups was not sent to scenario name validation. (${e.message})`);
    }
    return pairs;
  };

  /**
   * Setting flag to block done editing call, and keep bid in edit mode after reloading
   *
   * @param {Boolean} flag
   */
  function setBlockDoneEditingFlag(flag) {
    blockDoneEditingCall = flag;
  }

  /**
   * Getting flag to block done editing call, and keep bid in edit mode after reloading
   *
   * @returns {Boolean}
   */
  function getBlockDoneEditingFlag() {
    return blockDoneEditingCall;
  }

  context.isPharma = () => {
    return biddingUtilsService.isCurrentUserPharma(userService.user.getValue());
  };

  context.isPayer = () => {
    return biddingUtilsService.isCurrentUserPayer(userService.user.getValue());
  };

  context.isPayerInternal = () => {
    return biddingUtilsService.isPharma(userService.user.getValue())
      && biddingUtilsService.isPayerViewPerspective(viewPerspectiveService.getViewPerspective());
  };

  function getSummaryTerms() {
    return workingScope.summaryTerms;
  }

    /**
   * Check if only one Scenario/NDC was selected
   *
   * @param {Array} selected
   * @returns {Boolean}
   */
  context.isOnlyOneDrugSelected = (selected) => {
    if (selected.length === 1) {
      return !selected[0].renderData.isGroup;
    } else {
      const allCheckedDrugsGroups = selected.filter(item => item.renderData.isGroup);
      if (allCheckedDrugsGroups.length === 1) {
        const checkedDrugGroup = allCheckedDrugsGroups[0];

        return checkedDrugGroup.renderData.children.length === (selected.length - 1)
          && checkedDrugGroup.renderData.children.every(item => item.renderData.checked);
      } else {
        return false;
      }
    }
  };

  context.getCopiedBaselineDate = copiedValues => {
    if (!copiedValues) return null;
    const baselineDateTerm = copiedValues.find(term =>
      term.name === drugTermsConstants[TermName.BASELINE_START_DATE].title);
    return baselineDateTerm.value;
  };

  /**
   * Copy values from scenario/NDC.
   *
   * @param {Array} selected
   */
  context.copyValues = selected => {
    let sourceItem;
    const isPharmaViewPerspective = biddingUtilsService.isPharmaViewPerspective(viewPerspectiveService.getViewPerspective());
    const ndcSelected = selected.filter(item => !item.renderData.isGroup);
    const scenarioSelected = selected.filter(item => item.renderData.isGroup);
    const stateValuesToOmit = [
      'calculateValue', 'children', 'drugClass', 'fromCalculate',
      'groupName', 'checked', 'checkedClass', 'colSpan', 'displayName', 'isGroup', 'isInvalid',
      'offset', 'visible', 'wac', 'parent'
    ];

    if (scenarioSelected > 1 || (scenarioSelected === 0 && ndcSelected > 1)) {
      return;
    }

    context.clearCopyScenarioValues();

    if (scenarioSelected.length &&
      ndcSelected.length === scenarioSelected[0].renderData.children.length
    ) {
      sourceItem = scenarioSelected[0];
    } else {
      sourceItem = ndcSelected[0];
    }

    const termValues = sourceItem.terms.filter(shouldCopyTermValue).map(
      term => {
        const data: any = {
          name: term.name,
          value: getTermValueToCopy(term)
        };
        if (term.name === drugTermsConstants[TermName.MARKET_BASKET].title) {
          data.techDetails = {
            scenario: sourceItem.scenario,
            oldDrugName: sourceItem.name,
            planTypeIndex: planTypeIndex,
            bidId: bidId
          };
        }

        return data;
      }
    );

    const state = Object.assign({}, sourceItem.renderData);
    stateValuesToOmit.forEach(key => {
      delete state[key];
    });

    const data = {
      bidInfo: {
        isInternal: context.getWorkingScope().bidInfo.isInternal,
      },
      state: state,
      values: termValues,
    };

    context.setCopiedValues(data);

    function shouldCopyTermValue(term) {
      return !(isBaselineWacTerm(term.name) || (isMinimumBaseRebateTerm(term.name) && isPharmaViewPerspective));
    }

    function getTermValueToCopy(term: any) {
      let value = term.value;

      if (typeof term.value === 'string' && term.definition.hasAtNDC) {
        value = '';
      } else if (term.name === drugTermsConstants[TermName.UM_DETAILS].title) {
        const negativeValuesArray = ['No', ''];
        const clearUMDetails = Object.values(term.definition.list).every((dependency: any) =>
          dependency.definition.hasAtNDC || negativeValuesArray.indexOf(dependency.value) !== -1);
        if (clearUMDetails) {
          value = '';
        }
      }

      return value;
    }
  };

  /**
   * Paste copied values from scenario/NDC.
   *
   * @param {Array} selected
   */
  context.pasteValues = selected => {
    const copiedData = angular.copy(context.getCopiedValues());
    const viewAs = viewPerspectiveService.getViewPerspective();

    if (copiedData) {
      if (biddingUtilsService.isPharmaViewPerspective(viewAs)) {
        copiedData.values = copiedData.values.filter(term => !isMinimumBaseRebateTerm(term.name));
      }

      const copiedBaselineDate = context.getCopiedBaselineDate(copiedData.values);
      const groupsSelected = selected.filter(item => item.renderData.isGroup);
      let scenarioNDCs: any = [];
      groupsSelected.forEach(scenario => {
        scenarioNDCs.push(...scenario.renderData.children);
      });

      scenarioNDCs = new Set(scenarioNDCs);
      scenarioNDCs.forEach(scenarioNDC => {
        scenarioNDC.isEntireGroup = true;
      });
      const ndcsSelected = selected.filter(item => !item.renderData.isGroup && !scenarioNDCs.has(item));
      let allNDCs = [...scenarioNDCs, ...ndcsSelected];

      if (biddingUtilsService.isPharmaViewPerspective(viewAs)) {
        const DrugTermGroupsStateValues = constants.DRUG_TERM_GROUPS_STATE_VALUES;

        allNDCs = allNDCs.filter((ndc) => {
          return ndc.isEntireGroup
            || ndc.renderData.ndc.state !== DrugTermGroupsStateValues.LOCKED_STATE.state;
        });

        if (allNDCs.length === 0) {
          return Promise.resolve();
        }
      }

      const destinationItems = groupsSelected.concat(ndcsSelected);
      const savePastedValuesFunc = savePastedValues.bind(this);

      return savePastedValuesFunc(allNDCs, copiedData).then((data) => {
        // Resetting variable that was set above
        allNDCs.forEach(ndc => {
          delete ndc.isEntireGroup;
        });
        propagatePastedData.bind(this)(destinationItems, copiedData);
        BaselineWacService.updateBaselineWACForNDCs(allNDCs, copiedBaselineDate);
        context.saveVersionForUndo(data.responseObject.version);
        context.getWorkingScope().processGroup(context.getWorkingScope().group);
      }).catch((error) => {
        console.error(error);
        util.errorMessage(resources.BID_DETAILS.PASTE_VALUES_ERROR);
        $timeout(util.clearErrorMessage, constants.APP.MESSAGE_TIMEOUT);
      });
    }

    return Promise.resolve();
  };

  function propagatePastedData(destinationItems, data) {
    destinationItems.forEach(item => {
      const pasteValueAllowed = context.isPayer() || context.isPayerInternal() || (
        biddingUtilsService.isPharmaViewPerspective(viewPerspectiveService.getViewPerspective()) && (
          item.renderData.isGroup ||
          item.renderData.ndc.state !== constants.DRUG_TERM_GROUPS_STATE_VALUES.LOCKED_STATE.state
        )
      );

      // Fix missing terms in groups when checked all selected and drugs was not preloaded.
      if (!item.terms && item.renderData.prepareDrug) {
        item.renderData.prepareDrug();
      }

      if (context.isPayer()) {
        pasteMetadata(item, data.state);
      }

      if (pasteValueAllowed) {
        context.disableTermUpdateWatcher = true;
        pasteTermValues.bind(this)(item, data.values);
        if (item.renderData.isGroup) {
          item.renderData.calculateValue();
        } else {
          item.renderData.parent.renderData.calculateValue();
        }
      }
    });
    $timeout(() => { context.disableTermUpdateWatcher = false; }, 0);
  }

  /**
   * Paste copied term values into scenario/NDC
   *
   * @param {Object} drug
   * @param {Array} termValues
   */
  function pasteTermValues(drug, termValues) {
    drug.terms.forEach(term => {
      const copiedTerm = termValues.find(value => value.name === term.name);
      if (typeof copiedTerm === 'undefined') {
        return;
      }
      const termValue = prepareValue(copiedTerm, term);
      const pasteValueAllowed = isAllowedPasteValue.bind(this)(term, drug, copiedTerm);

      if (pasteValueAllowed) {
        term.value = termValue;
        if (term.name === drugTermsConstants[TermName.MARKET_BASKET].title) {
          const { marketBaskets } = context.getWorkingScope().bid.planTypes[planTypeIndex];
          const scenarioId = drug.scenario.id;
          if (marketBaskets && marketBaskets[scenarioId]) {
            delete marketBaskets[scenarioId];
          }
        } else if (term.name === drugTermsConstants[TermName.MARKET_SHARE_TIER].title) {
          term.definition.skipResettingRange = true;
          if (!drug.isEditMode && typeof term.definition.onChange === 'function') {
            term.definition.onChange(term);
          }
        }
        if (drug.renderData.isGroup) {
          drug.renderData.children.forEach(ndcToPropagateViewChanges => {
            const ndcTerm = ndcToPropagateViewChanges.terms.find(ndcTerm => ndcTerm.name === term.name);
            if (ndcTerm && isAllowedPasteValue.bind(this)(ndcTerm, ndcToPropagateViewChanges)) {
              ndcTerm.value = angular.copy(termValue);
            }
          });
        }
        if (!term.definition.doNotSave || shouldUpdateMB(term, drug, copiedTerm)) {
          const changeInfo = {
            from: 'term',
            term: term,
            drug: drug
          };

          biddingUtilsService.validateSections(changeInfo, viewPerspectiveService.getViewPerspective());
        }
      }
    });

    util.refreshScope(workingScope);

    function prepareValue(copiedTerm, term) {
      let value;

      if (copiedTerm.name === drugTermsConstants[TermName.NOTES].title) {
        if (typeof term.value === 'undefined') {
          drug.renderData.calculateValue();
        }
        value = {
          historic: term.value && term.value.historic ? term.value.historic.slice() : [],
          currentValue: copiedTerm.value.currentValue
        };
      } else if (copiedTerm.name === drugTermsConstants[TermName.MARKET_BASKET].title) {
        value = copiedTerm.value;
        if (value) {
          value.oldDrugName = value.drugName;
          value.drugName = drug.name;
        }
      } else {
        value = copiedTerm.value;
      }

      return value;
    }
  }

  function isAllowedPasteValue(term, drug, copiedTerm) {
    const viewAs = viewPerspectiveService.getViewPerspective();
    const isPharmaViewPerspective = biddingUtilsService.isPharmaViewPerspective(viewAs);
    if (isPharmaViewPerspective && isMinimumBaseRebateTerm(term.name)) {
      return false;
    }

    const pathKey = typeof term.definition.path !== 'undefined' ? 'path' : 'groupPath';
    const termSubPath = term.definition[pathKey][term.definition[pathKey].length - 1];
    const drugTermGroupsStateValues = constants.DRUG_TERM_GROUPS_STATE_VALUES;
    const isTermLocked = drug.renderData[termSubPath].state === drugTermGroupsStateValues.LOCKED_STATE.state;
    let allowUpdate = true;
    if (term.name === drugTermsConstants[TermName.MARKET_BASKET].title && copiedTerm) {
      allowUpdate = shouldUpdateMB(term, drug, copiedTerm);
    }

    return (context.isPayer() || context.isPayerInternal()
      || (isPharmaViewPerspective && !isTermLocked)) && allowUpdate;
  }

  /**
   * Paste copied metadata into scenario/NDC
   *
   * @param {Object} drug
   * @param {Object} state
   */
  function pasteMetadata(drug, state) {
    const metaTerms = {
      'Contract Start Date': constants.DRUG_TERM_PATHS.ALLOW_CONTRACT_START_DATE,
      'Contract End Date': constants.DRUG_TERM_PATHS.ALLOW_CONTRACT_END_DATE,
      'Administration Fee': constants.DRUG_TERM_PATHS.ALLOW_ADMINISTRATION_FEE,
      baseRebate: constants.DRUG_TERM_PATHS.ALLOW_BASE_REBATE,
      notes: constants.DRUG_TERM_PATHS.ALLOW_NOTES,
      tiers: constants.DRUG_TERM_PATHS.ALLOW_TIERS,
      marketBasket: constants.DRUG_TERM_PATHS.ALLOW_MARKET_BASKET,
      utilizationManagement: constants.DRUG_TERM_PATHS.ALLOW_UTILIZATION_MANAGEMENT,
      marketShare: constants.DRUG_TERM_PATHS.ALLOW_MARKET_SHARE_REBATE,
      priceProtection: constants.DRUG_TERM_PATHS.ALLOW_PRICE_PROTECTION,
      ndc: constants.DRUG_TERM_PATHS.ALLOW_NDC_OFFERS
    };
    const groupLevelTerms = ['marketBasket', 'ndc'];

    Object.keys(state).forEach(key => {
      if (shouldPasteState(key)) {
        pasteTermState(drug, metaTerms[key], state[key].state);
      }
    });

    function shouldPasteState(key) {
      return state[key].state !== constants.DRUG_TERM_GROUPS_STATE_VALUES.AT_NDC_STATE.state &&
        metaTerms[key] && (groupLevelTerms.indexOf(key) === -1 || drug.renderData.isGroup);
    }
  }

  /**
   * Restore terms metadata
   *
   * @param {Object} drug - the drug in question
   * @param {Array} termGroupPath - the group that need to be allowed or disallowed
   * @param {Object} state
   */
  function pasteTermState(drug, termGroupPath, state) {
    if (drug.renderData.isGroup) {
      drugTermGroupStateManager.manageGroupLevelStateForTermsGroup(drug, metadata, termGroupPath,
        workingScope, biddingConstants.drugGroupStateOptionsValuesMap[state.toLowerCase()], copyValueEvent);
    } else if (drug.renderData.parent) {
      drugTermGroupStateManager.manageNdcLevelStateForTermsGroup(drug, metadata, termGroupPath,
        workingScope, biddingConstants.drugGroupStateOptionsValuesMap[state.toLowerCase()],
        biddingConstants.drugGroupStateOptionsValuesMap, copyValueEvent);
    }

  }

  /**
   * Send paste scenario values data
   *
   * @param {Array} destinations - NDCs that are selected for pasting into
   * @param {Object} data - Object with term values and states to paste
   * @returns {Promise}
   */
  function savePastedValues(destinations, data) {
    const bidId = bidInfo.bidId;
    const dataToSend = angular.copy(data);
    const values = dataToSend.values.filter(term => term.name !== drugTermsConstants[TermName.MARKET_BASKET].title)
      .map(term => {
        if (term.name === drugTermsConstants[TermName.RANGE_OR_UNITS_AND_REBATES].title && term.value) {
          term.value.forEach(item => {
            delete item.$$hashKey;
          });
        } else if ((term.name === constants.SUMMARY_TERMS.CONTRACT_START_DATE.label
          || term.name === constants.SUMMARY_TERMS.CONTRACT_END_DATE.label
          || term.name === drugTermsConstants[TermName.BASELINE_START_DATE].title) && term.value) {
          term.value = typeof term.value === 'string' && !util.isEmpty(term.value) ? DateUtils.getDateBasedOnFormattedString(term.value).getTime()
            : term.value;
        }
        return term;
      });
    const states = Object.keys(dataToSend.state).map(key => {
      return {
        name: key,
        state: dataToSend.state[key].state
      };
    });
    const planTypeId = bid.planTypes[planTypeIndex].planTypeId;
    const marketBasket = dataToSend.values.find(term => term.name === drugTermsConstants[TermName.MARKET_BASKET].title).value;
    const ndcs = destinations.map(ndc => {
      return {
        drugName: ndc.name,
        ndc: ndc.ndc,
        scenarioId: ndc.scenario.id,
        isEntireGroup: ndc.isEntireGroup
      };
    });
    const requestData = { planTypeId, ndcs, marketBasket, values, states };
    const viewAs = viewPerspectiveService.getViewPerspective();

    return new Promise(
      (resolve, reject) => {
        apiService.post(`${ApiUrlPrefix.OLD}/bids/copyValues/${bidId}?viewAs=${viewAs}`, requestData).pipe(first()).toPromise()
          .then(response => resolve(response.body))
          .catch(error => reject(error));
      }
    );
  }

  /**
   * Verifies if the given term name is the {@link drugTermsConstants.MIN_BASE_REBATE.title}
   *
   * @param termName - drug term name
   * @returns {boolean} true, if term name is the {@link drugTermsConstants.MIN_BASE_REBATE.title},
   *                    false otherwise
   */
  function isMinimumBaseRebateTerm(termName) {
    return termName === drugTermsConstants[TermName.MIN_BASE_REBATE].title;
  }

  /**
   * Verifies if the given term name is the {@link drugTermsConstants.BASELINE_WAC.title}
   *
   * @param termName - drug term name
   * @returns {boolean} true, if term name is the {@link drugTermsConstants.BASELINE_WAC.title},
   *                    false otherwise
   */
  function isBaselineWacTerm(termName) {
    return termName === drugTermsConstants[TermName.BASELINE_WAC].title;
  }

  /**
   * Save Undo version for current user and bid
   *
   * @param {Number|String} version
   */
  context.saveVersionForUndo = (version) => {
    const user = userService.user.getValue();
    if (bidId && version && user && user.id) {
      context.pushUndoVersion(bidId, user.id, version);
      context.clearRedoVersions(bidId, user.id);
    }
  };

  /**
   * Set copied values from scenario/NDC.
   *
   * @param {Object} data
   */
  context.setCopiedValues = data => {
    localStorageService.store(constants.COPY_VALUES_STORAGE_KEY, data);
  };

  /**
   * Get copied values from scenario/NDC.
   *
   * @returns {Object}
   */
  context.getCopiedValues = () => {
    return localStorageService.retrieve(constants.COPY_VALUES_STORAGE_KEY);
  };

  /**
   * Has copied values from scenario/NDC.
   *
   * @returns {Boolean}
   */
  context.hasCopiedValues = () => {
    return !!localStorageService.retrieve(constants.COPY_VALUES_STORAGE_KEY);
  };

  /**
   * Check if copied values available for bid
   *
   * @param bidInfo
   * @returns {boolean}
   */
  context.isCopiedValuesAvailableForBid = (bidInfo) => {
    const copiedValues = localStorageService.retrieve(constants.COPY_VALUES_STORAGE_KEY);
    const isTheSameBidType = copiedValues.bidInfo.isInternal === bidInfo.isInternal;

    return copiedValues ? isTheSameBidType : false;
  };

  /**
   * Clear copied values from storage
   */
  context.clearCopyScenarioValues = () => {
    localStorageService.clear(constants.COPY_VALUES_STORAGE_KEY);
  };

  context.disableTermUpdateWatcher = false;
  context.disableBidActionsFlags = false;

  /**
   * Set expander states.
   *
   * @param {String} bidId
   * @param {String} expandableId
   * @param {Boolean} state
   */
  context.setExpanderStates = (bidId, expandableId, state) => {
    const expanderStates = localStorageService.retrieve(constants.EXPANDER_STATES_STORAGE_KEY) || {};
    const bidExpanderStates = expanderStates[bidId] || {};
    bidExpanderStates[expandableId] = state;
    expanderStates[bidId] = bidExpanderStates;
    localStorageService.store(constants.EXPANDER_STATES_STORAGE_KEY, expanderStates);
  };

  /**
   * Get expander states.
   *
   * @param {String} bidId
   * @param {String} expandableId
   * @returns {Boolean}
   */
  context.getExpanderStates = (bidId, expandableId) => {
    const expanderStates = localStorageService.retrieve(constants.EXPANDER_STATES_STORAGE_KEY) || {};
    const bidExpanderStates = expanderStates[bidId] || {};
    return bidExpanderStates[expandableId] || false;
  };

  /**
   * Set undo versions.
   *
   * @param {String} bidId
   * @param {String} userId
   * @param {Array} versions
   */
  context.setUndoVersions = (bidId, userId, versions) => {
    const undoVersions = localStorageService.retrieve(constants.UNDO_VERSIONS_STORAGE_KEY) || {};
    const bidVersions = undoVersions[bidId] || {};
    bidVersions[userId] = versions;
    undoVersions[bidId] = bidVersions;
    localStorageService.store(constants.UNDO_VERSIONS_STORAGE_KEY, undoVersions);
  };

  /**
   * Set redo versions.
   *
   * @param {String} bidId
   * @param {String} userId
   * @param {Array} versions
   */
  context.setRedoVersions = (bidId, userId, versions) => {
    const redoVersions = localStorageService.retrieve(constants.REDO_VERSIONS_STORAGE_KEY) || {};
    const bidVersions = redoVersions[bidId] || {};
    bidVersions[userId] = versions;
    redoVersions[bidId] = bidVersions;
    localStorageService.store(constants.REDO_VERSIONS_STORAGE_KEY, redoVersions);
  };

  /**
   * Get undo versions.
   *
   * @param {String} bidId
   * @param {String} userId
   * @returns {Array} versions
   */
  context.getUndoVersions = (bidId, userId) => {
    const undoVersions = localStorageService.retrieve(constants.UNDO_VERSIONS_STORAGE_KEY) || {};
    const bidVersions = undoVersions[bidId] || {};
    return bidVersions[userId] || [];
  };

  /**
   * Get redo versions.
   *
   * @param {String} bidId
   * @param {String} userId
   * @returns {Array} versions
   */
  context.getRedoVersions = (bidId, userId) => {
    const redoVersions = localStorageService.retrieve(constants.REDO_VERSIONS_STORAGE_KEY) || {};
    const bidVersions = redoVersions[bidId] || {};
    return bidVersions[userId] || [];
  };

  /**
   * Clear undo versions
   * @param {String} bidId
   * @param {String} userId
   */
  context.clearUndoVersions = (bidId, userId) => {
    context.setUndoVersions(bidId, userId, []);
  };

  /**
   * Clear redo versions
   * @param {String} bidId
   * @param {String} userId
   */
  context.clearRedoVersions = (bidId, userId) => {
    context.setRedoVersions(bidId, userId, []);
  };

  /**
   * Add undo version to stack
   * @param {String} bidId
   * @param {String} userId
   * @param {Number} version
   */
  context.pushUndoVersion = (bidId, userId, version) => {
    const versions = context.getUndoVersions(bidId, userId);
    versions.unshift(version);
    versions.splice(constants.UNDO_REDO_VERSIONS_LIMIT);
    context.setUndoVersions(bidId, userId, versions);
  };

  /**
   * Add redo version to stack
   * @param {String} bidId
   * @param {String} userId
   * @param {Number} version
   */
  context.pushRedoVersion = (bidId, userId, version) => {
    const versions = context.getRedoVersions(bidId, userId);
    versions.unshift(version);
    versions.splice(constants.UNDO_REDO_VERSIONS_LIMIT);
    context.setRedoVersions(bidId, userId, versions);
  };

  /**
   * Get last version for undo from stack
   * @param {String} bidId
   * @param {String} userId
   * @returns {Number|Null}
   */
  context.popUndoVersion = (bidId, userId) => {
    const versions = context.getUndoVersions(bidId, userId);
    const version = versions.shift();
    if (version) {
      context.setUndoVersions(bidId, userId, versions);
      context.pushRedoVersion(bidId, userId, version);
    }
    return version;
  };

  /**
   * Get last version for redo from stack
   * @param {String} bidId
   * @param {String} userId
   * @returns {Number|Null}
   */
  context.popRedoVersion = (bidId, userId) => {
    const versions = context.getRedoVersions(bidId, userId);
    const version = versions.shift();
    if (version) {
      context.setRedoVersions(bidId, userId, versions);
      context.pushUndoVersion(bidId, userId, version);
    }
    return version;
  };

  context.registerScenarioChangeEditModeCallback = (callback) => {
    context.scenarioChangeEditModeCallback = callback;
  };

  context.setEditingCompanyType = (companyType) => {
    context.editingCompanyType = companyType;
  };

  context.getEditingCompanyType = () => {
    return context.editingCompanyType;
  };

  context.getBidId = () => bidId;
  context.getPlanTypeId = () => bid.planTypes[context.getCurrentPlanTypeIndex()].planTypeId;
  context.isBidInEditMode = () => bid.editable == true || bid.editable === 'true';

  /****************** Exposure of private functions *********************/
  context.getStatus = getStatus;
  context.getBindingBid = getBindingBid;
  context.getBidManufacturer = getBidManufacturer;
  context.getBidPayer = getBidPayer;
  context.setBlockDoneEditingFlag = setBlockDoneEditingFlag;
  context.getBlockDoneEditingFlag = getBlockDoneEditingFlag;
  context.processData = processData;
  context.getSummaryTerms = getSummaryTerms;

  return context;
}];
