import { Injectable } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, FormArray, FormControl } from '@angular/forms';
import { DictionaryItem } from '@qv-common/entities';
import { MarketShareState } from '@qv-bid/enums';
import { TermTemplateStorageService } from '@qv-term/services';
import { resources } from '@qv-common/static';
import { TermName } from '@qv-term/enums';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { GeneralModalData, ModalService } from 'quantuvis-angular-common/modal';

@Injectable()
export class MarketShareService {
  private readonly overrideModalData = new GeneralModalData(
    resources.POPUPS.TITLES.RESET_VALUES,
    resources.POPUPS.RESET_TERM_VALUES_MSG,
    resources.Actions.YES,
    resources.Actions.NO
  );

  constructor(private termStorageService: TermTemplateStorageService, private modalService: ModalService) { }

  public confirmResetValueForSection(): AsyncValidatorFn {
    let prevValue: DictionaryItem = null;

    return (control: AbstractControl): Observable<null> => {
      if (!control.dirty || !control.value || control.value.id !== MarketShareState.HIDDEN) {
        prevValue = control.value;
        return of(null);
      }

      const mshControl = control.parent.controls[TermName.MARKET_SHARE_TIER];
      const rouarControl = control.parent.controls[TermName.RANGE_OR_UNITS_AND_REBATES];

      control.markAsPending();
      control.markAsPristine();

      return this.modalService.openConfirmModal(this.overrideModalData).pipe(
        tap((isConfirm: boolean) => this.resetSectionValues(isConfirm, control, mshControl, rouarControl, prevValue)),
        tap(() => prevValue = control.value),
        map(() => null)
      );
    };
  }

  private resetSectionValues(isConfirmed: boolean, control: AbstractControl, mshControl: FormControl,
                             rouarControl: FormArray, prevValue: DictionaryItem): void {
    if (isConfirmed) {
      mshControl.setValue(this.termStorageService.getTermTemplate(TermName.MARKET_SHARE_TIER).defaultValue);

      const abstractControls = rouarControl.controls.slice();
      abstractControls.forEach(() => rouarControl.removeAt(0));
      control.updateValueAndValidity();
    } else {
      control.setValue(prevValue, { emitEvent: false });
      control.updateValueAndValidity({ onlySelf: true });
    }
  }
}
