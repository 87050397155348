import { ChangeDetectionStrategy, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Signature } from '@qv-bid/entities';
import { resources } from '@qv-common/static';
import { SvgIconName } from '@qv-common/enums';
import { BidSignatureDaoService } from '@qv-bid/services/dao';
import { CompanyUtils } from '@qv-company/utils';
import { Subject } from 'rxjs';
import { QvCache } from '@qv-common/decorators';
import { appConfig } from '@qv-common/configs';

@Component({
  selector: 'qv-bid-signature',
  templateUrl: './bid-signature.component.html',
  styleUrls: ['./bid-signature.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BidSignatureComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  public bidVersionId: number;

  @Input()
  public manufacturerCompanyOldName: string;

  public readonly resources = resources;
  public readonly svgIconName = SvgIconName;
  public readonly dateFormat = appConfig.dateFormat;

  public signature$ = new Subject<Signature>();

  public signature: Signature;

  constructor(private bidSignatureDaoService: BidSignatureDaoService) {}

  public ngOnInit(): void {
    if (this.bidVersionId) {
      this.loadSignatureInfo();
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (!changes.bidVersionId.firstChange && changes.bidVersionId.currentValue) {
      this.loadSignatureInfo();
    }
  }

  public ngOnDestroy(): void {
    this.signature$.unsubscribe();
  }

  @QvCache()
  public getCompanyFullName(companyName: string, oldCompanyName?: string): string {
    return CompanyUtils.appendOldCompanyName(companyName, oldCompanyName);
  }

  private loadSignatureInfo(): void {
    this.bidSignatureDaoService.getSignatureInfo(this.bidVersionId)
      .subscribe((data: Signature) => this.signature$.next(data));
  }

}
