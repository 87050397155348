import { InputTerm } from '@qv-term/models/types';
import { Comparator } from '@qv-common/interfaces';
import { CoreUtils, StringUtils } from '@qv-common/utils';
import { constants, resources } from '@qv-common/static';

export class BaseRebateTerm extends InputTerm implements Comparator {
  // Do not copy term value for new scenario
  public doNotCopyForCreateScenario: boolean;

  constructor(name: string) {
    super(
      name,
      name,
      resources.TermsDescriptions.DRUG_LIST.BASE_REBATE,
      constants.DRUG_TERM_PATHS.ALLOW_BASE_REBATE[1],
      '',
      '',
      'term__base-rebate'
    );

    this.suffix = this.unitsService.percent;
    this.doNotCopyForCreateScenario = true;
  }

  public getTermValueForComparison(): string {
    return CoreUtils.isDefined(this.value) && !StringUtils.isEmpty(this.value) ? `${this.value} ${this.suffix}` : '';
  }
}
