import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { QuantuvisPlusFeature } from '@qv-company/enums';
import { SvgIconName } from '@qv-common/enums';
import { BidView } from '@qv-bid/enums';
import { BidViewStateService } from '@qv-bid/services';
import { SwitchButtonConfig } from '@qv-shared/models';

@Component({
  selector: 'qv-bid-view-switcher',
  templateUrl: './bid-view-switcher.component.html',
  styleUrls: ['./bid-view-switcher.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BidViewSwitcherComponent implements OnInit {
  public readonly svgIconName = SvgIconName;
  public readonly quantuvisPlusFeature = QuantuvisPlusFeature;
  public readonly primaryButtonConfig = new SwitchButtonConfig<BidView>(
    'Standard',
    'Bid Standard view',
    SvgIconName.TILES,
    BidView.STANDARD
  );
  public readonly secondaryButtonConfig = new SwitchButtonConfig<BidView>(
    'Grid',
    'Bid Grid view',
    SvgIconName.GRID,
    BidView.GRID
  );

  public viewMode: BidView;

  constructor(
    private router: Router,
    private bidViewStateService: BidViewStateService
  ) {}

  public ngOnInit(): void {
    this.initViewMode();
  }

  public initViewMode(): void {
    if (this.router.isActive('/grid', false)) {
      this.viewMode = BidView.GRID;
    } else {
      this.viewMode = BidView.STANDARD;
    }
    this.bidViewStateService.setBidView(this.viewMode);
  }

  public onChangeBidView(value: BidView): void {
    if (value === BidView.GRID) {
      this.bidViewStateService.setBidView(BidView.GRID);
      this.router.navigate(['/grid', 'bid'], { queryParamsHandling: 'preserve' });
    } else if (value === BidView.STANDARD) {
      this.bidViewStateService.setBidView(BidView.STANDARD);
      this.router.navigate(['/standard', 'bid'], { queryParamsHandling: 'merge' });
    }
  }
}
