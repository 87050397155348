import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('acceptMessageData')
export class AcceptMessageData {
  @JsonProperty('acceptedScenarios', Number)
  public acceptedScenarios: number = null;

  @JsonProperty('dismissedScenarios', Number)
  public dismissedScenarios: number = null;

  @JsonProperty('openScenarios', Number)
  public openScenarios: number = null;

  @JsonProperty('totalScenarios', Number)
  public totalScenarios: number = null;
}
