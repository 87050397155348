import { BaseEntity } from '@qv-bid/entities/base-entity';
import { JsonObject, JsonProperty } from 'json2typescript';
import { DateConverter } from '@qv-bid/serializers';
import { Moment } from 'moment';

@JsonObject('Summary')
export class Summary extends BaseEntity {
  @JsonProperty('id', Number)
  public id: number = null;

  @JsonProperty('bidVersionId', Number)
  public bidVersionId: number = null;

  @JsonProperty('rfpTitle', String)
  public rfpTitle: string = null;

  @JsonProperty('lineOfBusiness', String)
  public lineOfBusiness: string = null;

  @JsonProperty('rfpStartDate', DateConverter)
  public rfpStartDate: Moment = null;

  @JsonProperty('rfpDueDate', DateConverter)
  public rfpDueDate: Moment = null;

  @JsonProperty('contractStartDate', DateConverter)
  public contractStartDate: Moment = null;

  @JsonProperty('contractStartDateLock', Boolean)
  public contractStartDateLock = false;

  @JsonProperty('contractEndDate', DateConverter)
  public contractEndDate: Moment = null;

  @JsonProperty('contractEndDateLock', Boolean)
  public contractEndDateLock = false;

  @JsonProperty('contractEvergreen', Boolean)
  public contractEvergreen = false;

  @JsonProperty('contractEvergreenLock', Boolean)
  public contractEvergreenLock = false;

  @JsonProperty('instructions', String)
  public instructions: string = null;

  @JsonProperty('attachmentsLock', Boolean)
  public attachmentsLock = false;

  @JsonProperty('webSiteLinksLock', Boolean)
  public webSiteLinksLock = false;

  @JsonProperty('commentsLock', Boolean)
  public commentsLock = false;

  @JsonProperty('countUnreadComments', Number)
  public countUnreadComments = 0;

  @JsonProperty('bindingBid', Boolean)
  public bindingBid = false;

  @JsonProperty('versionNumber', Number)
  public versionNumber = 0;

  @JsonProperty('versionCreatedDate', DateConverter)
  public versionCreatedDate: Moment = null;

}
