import { TermName } from '@qv-term/enums';
import { DrugTerm } from '@qv-common/interfaces';

export const drugTermsConstants: { [termName: string]: DrugTerm } = {
  // Scenario Terms
  [TermName.SCENARIO_START_DATE]: {
    title: 'Scenario Start Date',
    displayNames: {
      START_DATE: 'Start Date'
    },
    key: 'D6'
  },
  [TermName.SCENARIO_END_DATE]: {
    title: 'Scenario End Date',
    displayNames: {
      END_DATE: 'End Date'
    },
    key: 'D7'
  },
  [TermName.ADMINISTRATION_FEE]: {
    title: 'Administration Fee',
    displayNames: {
      ADMIN_FEE: 'Admin Fee'
    },
    key: 'D26'
  },
  // Rebate
  [TermName.MIN_BASE_REBATE]: {
    title: 'Minimum Base Rebate',
    displayNames: {
      MIN_BASE_REBATE: 'Min Base Rebate'
    },
    key: 'D9'
  },
  [TermName.BASE_REBATE]: {
    title: 'Base Rebate',
    displayNames: {
      BASE_REBATE: 'Base Rebate'
    },
    key: 'D10'
  },
  [TermName.TIER_PLACEMENT]: {
    title: 'Tier Placement',
    displayNames: {
      TIER_PLACEMENT: 'Tier Placement'
    },
    key: 'D8'
  },
  [TermName.TIER_COMPETITION]: {
    title: 'Tier Competition',
    displayNames: {
      TIER_COMPETITION: 'Tier Competition'
    },
    key: 'D11'
  },
  [TermName.COMPETITION_GROUP]: {
    title: 'Competition Group',
    displayNames: {
      COMPETITION_GROUP: 'Competition Group'
    },
    key: 'D27'
  },
  [TermName.COMPETITORS_TIER]: {
    title: 'Competitor\'s Tier',
    displayNames: {
      COMPETITORS_TIER: 'Competitor\'s Tier'
    },
    key: 'D12'
  },
  [TermName.MARKET_BASKET]: {
    title: 'Basket',
    displayNames: {
      BASKET: 'Basket'
    },
    key: 'D13'
  },
  [TermName.NOTES]: {
    title: 'Notes',
    displayNames: {
      NOTES: 'Notes'
    },
    key: 'D14'
  },
  // UM
  [TermName.STEP_THERAPY_ALLOWED_ON_PRODUCT]: {
    title: 'Step Therapy Allowed on Product',
    displayNames: {
      ALLOWED_ON_PRODUCT: 'Allowed on Product'
    },
    key: 'D5'
  },
  [TermName.STEP_THERAPY_REQUIRED_ON_COMPETITORS_PRODUCTS]: {
    title: 'Step Therapy Required on Competitors Products',
    displayNames: {
      REQUIRED_ON_COMPETITORS_PRODUCTS: 'Required on Competitors Products'
    },
    key: 'D4'
  },
  [TermName.PRIOR_AUTHORIZATION_ALLOWED_ON_PRODUCT]: {
    title: 'Prior Authorization Allowed on Product',
    displayNames: {
      ALLOWED_ON_PRODUCT: 'Allowed on Product'
    },
    key: 'D2'
  },
  [TermName.PRIOR_AUTHORIZATION_REQUIRED_ON_COMPETITORS_PRODUCTS]: {
    title: 'Prior Authorization Required on Competitors Products',
    displayNames: {
      REQUIRED_ON_COMPETITORS_PRODUCTS: 'Required on Competitors Products'
    },
    key: 'D1'
  },
  [TermName.QUANTITY_LIMIT]: {
    title: 'Quantity Limit',
    displayNames: {
      QUANTITY_LIMIT: 'Quantity Limit'
    },
    key: 'D15'
  },
  [TermName.OTHER_UM]: {
    title: 'Other UM',
    displayNames: {
      OTHER_UM: 'Other UM'
    },
    key: 'D16'
  },
  [TermName.UM_DETAILS]: {
    title: 'UM Details',
    displayNames: {
      UM_DETAILS: 'UM Details'
    },
    key: 'D17'
  },
  // Market Share
  [TermName.MARKET_SHARE_TIER]: {
    title: 'Market Share Tier',
    displayNames: {
      MARKET_SHARE_TIER: 'Market Share Tier'
    },
    key: 'D18'
  },
  [TermName.RANGE_OR_UNITS_AND_REBATES]: {
    title: 'Range Or Units And Rebates',
    displayNames: {
      RANGE_OR_UNITS_AMPERSAND_REBATES: 'Range Or Units & Rebates'
    },
    key: 'D3'
  },
  // Price Protection
  [TermName.NET_EFFECTIVE_PRICE]: {
    title: 'Net Effective Price',
    displayNames: {
      NET_EFFECTIVE_PRICE: 'Net Effective Price'
    },
    key: 'D19'
  },
  [TermName.INDEX]: {
    title: 'Index',
    displayNames: {
      INDEX: 'Index'
    },
    key: 'D20'
  },
  [TermName.THRESHOLD]: {
    title: 'Threshold',
    displayNames: {
      THRESHOLD: 'Threshold'
    },
    key: 'D21'
  },
  [TermName.RESET]: {
    title: 'Reset',
    displayNames: {
      RESET: 'Reset'
    },
    key: 'D22'
  },
  [TermName.BASELINE_START_DATE]: {
    title: 'Baseline Start Date',
    displayNames: {
      BASELINE_START_DATE: 'Baseline Start Date'
    },
    key: 'D23'
  },
  [TermName.NEW_PRICE_EFFECTIVE_DATE]: {
    title: 'New Price Effective Date',
    displayNames: {
      NEW_PRICE_EFFECTIVE_DATE: 'New Price Effective Date'
    },
    key: 'D24'
  },
  [TermName.BASELINE_WAC]: {
    title: 'Baseline WAC',
    displayNames: {
      BASELINE_WAC: 'Baseline WAC'
    },
    key: 'D25'
  },
  [TermName.PRICE_PROTECTION_STATE]: {
    title: 'Price Protection'
  }
};
