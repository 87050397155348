import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AppRoute } from '@qv-common/enums';
import { Observable } from 'rxjs';
import { PermissionService } from '@qv-common/services/auth/permission.service';
import { map } from 'rxjs/operators';
import { UserState } from '@qv-user/entities/user-state.entity';
import { UserService } from '@qv-common/services/auth/user.service';

@Injectable({
  providedIn: 'root'
})
export class NoneAccessGuard implements CanActivate {

  constructor(private userService: UserService,
              private permissionService: PermissionService,
              private router: Router) {}

  public canActivate(): Observable<boolean> {

    return this.userService.loadUserData()
      .pipe(
        map((user: UserState) => {
          this.permissionService.updateUserRights(user);
          if (this.permissionService.isUserHasNoneAccess()) {
            this.router.navigate([AppRoute.ACCESS_DENIED]);
            return false;
          }
          return true;
        })
      );
  }
}
