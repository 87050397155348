/* tslint:disable:no-var-requires */
import CompanyController from './company.controller';

// @ts-ignore
import templateUrl from './editcompany.html';

const EditCompanyComponent = {
  template: templateUrl,
  bindings: {
    initConfirmNavigation: '&'
  },
  controller: CompanyController
};

export default EditCompanyComponent;
