import { ConfirmNavigation } from '@qv-common/interfaces';

export abstract class ConfirmNavigationInner implements ConfirmNavigation {
  public confirmNavigationMessage: string;

  public initConfirmNavigation: (data: ConfirmNavigation) => ConfirmNavigation;

  public abstract isNavigationNeedConfirmation(): boolean;

  protected emitConfirmNavigationCallback(): void {
    this.initConfirmNavigation({
      confirmNavigationMessage: this.confirmNavigationMessage,
      isNavigationNeedConfirmation: this.isNavigationNeedConfirmation.bind(this)
    });
  }
}
