<ng-container *ngIf="items">
  <div *ngIf="isSearchEnabled" class="search-control">
    <input [formControl]="searchControl" class="qv-input search-control__input" placeholder="Search">
  </div>
  <ul [ngClass]="getListClass(isSearchEnabled, listClass)" class="list">
    <div class="list__items-wrapper">
      <li *ngIf="isSelectAllEnabled">
        <mat-checkbox
            (change)="onSelectAll($event)"
            [checked]="isAllFilteredItemsChecked()"
            [disabled]="!isFilterResultCount()"
            [indeterminate]="isIndeterminateState()"
            class="qv-mat-checkbox qv-mat-checkbox--sm">
          Select All
        </mat-checkbox>
      </li>
      <li *ngFor="let item of filteredItems; last as isLast; let i = index; trackBy: trackById"
          attr.data-test-id="test-id-filter-item-{{i}}">
        <mat-checkbox
            (change)="onChange($event, item)"
            [checked]="item.checked"
            [class.qv-mat-checkbox--clear]="isLast"
            [disabled]="item.disabled"
            [name]="item.name"
            class="qv-mat-checkbox qv-mat-checkbox--sm">
          {{ item.name || "Empty" }}
        </mat-checkbox>
      </li>
    </div>
  </ul>
</ng-container>
