<div class="term-market-basket" [matTooltip]="getTooltipMessage(isEmptyMarketBasket)">
  <button mat-icon-button class="qv-button-icon qv-button-icon--center"
    [ngClass]="isEmptyMarketBasket ? 'qv-button-icon--empty' : 'qv-button-icon--full'"
    [disabled]="!isEnabled(isEmptyMarketBasket, this.isEditMode, isUserPharma, isLocked, isPending)"
    (click)="onOpenModal($any($event))">
    <mat-icon
      [svgIcon]="svgIconName.MARKET_BASKET">
    </mat-icon>
  </button>
</div>
