import { NdcDateValue, NdcValue } from '@qv-term/entities';
import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('Contract')
export class Contract {
  @JsonProperty('administrationFee', NdcValue)
  public administrationFee: NdcValue<number | string> = null;

  @JsonProperty('administrationFeeLock', Boolean)
  public administrationFeeLock = false;

  @JsonProperty('scenarioStartDate', NdcDateValue)
  public scenarioStartDate: NdcDateValue = null;

  @JsonProperty('scenarioStartDateLock', Boolean)
  public scenarioStartDateLock = false;

  @JsonProperty('scenarioEndDate', NdcDateValue)
  public scenarioEndDate: NdcDateValue = null;

  @JsonProperty('scenarioEndDateLock', Boolean)
  public scenarioEndDateLock = false;
}
