import { IconComponent } from '@qv-shared/components/icon/icon.component';
import { NgModule } from '@angular/core';

import DrugScenariosComponent from './create-bid/drug-scenarios/drug-scenarios.component';
import { DrugScenariosService } from './create-bid/drug-scenarios/drug-scenarios.service';
import { downgradeComponent } from '@angular/upgrade/static';
import { BidRevisionComparisonToolbarComponent, BidRevisionToolbarComponent, } from '@qv-bid/components/shared';
import { BidDetailsService } from './bid-details/biddetails.service';
import { BaselineWacService } from './bid-details/baselinewac.service';
import CreateBidComponent from './create-bid/createbid.component';
import RevisionComparisonComponent from './revision-comparison/revisioncomparison.component';

declare let angular: angular.IAngularStatic;

export const BiddingNgModule = angular.module('quantuvis.bidding', [])
  .component('drugScenarios', DrugScenariosComponent)
  .component('createBidComponent', CreateBidComponent)
  .component('revisionComparisonComponent', RevisionComparisonComponent)
  .service('drugScenariosService', DrugScenariosService)
  .service('BidDetailsService', BidDetailsService)
  .service('BaselineWacService', BaselineWacService)
  .directive('qvBidRevisionToolbar',
    downgradeComponent({ component: BidRevisionToolbarComponent }) as angular.IDirectiveFactory)
  .directive('qvBidRevisionComparisonToolbar',
    downgradeComponent({ component: BidRevisionComparisonToolbarComponent }) as angular.IDirectiveFactory)
  .directive('qvIcon',
    downgradeComponent({ component: IconComponent }) as angular.IDirectiveFactory);

@NgModule({})
export class BiddingModule {
}
