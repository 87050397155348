import { Injectable } from '@angular/core';
import { BaseTerm } from '@qv-term/models';
import { SummaryFactory } from './factories/summary.factory';
import { PaymentFactory } from './factories/payment.factory';
import { DrugListFactory } from './factories/drug-terms.factory';

@Injectable()
export class TermTemplateStorageService {

  private terms: Map<string, any> = new Map<string, any>();

  constructor(private summaryFactory: SummaryFactory,
              private paymentFactory: PaymentFactory,
              private drugListFactory: DrugListFactory) {
    this.init();
  }

  public getTermTemplate<T extends BaseTerm>(name: string): T {
    return this.terms.get(name);
  }

  private init(): void {
    const summaryTerms = this.summaryFactory.buildTerms();
    const paymentTerms = this.paymentFactory.buildTerms();
    const drugTerms = this.drugListFactory.buildTerms();
    this.terms = new Map([...Array.from(summaryTerms), ...Array.from(paymentTerms), ...Array.from(drugTerms)]);
  }
}
