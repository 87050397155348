import { JsonObject, JsonProperty } from 'json2typescript';
import { Company } from 'quantuvis-core-entities';

@JsonObject('BaseUser')
export class BaseUser {
  @JsonProperty('id', Number)
  public id: number = null;

  @JsonProperty('firstName', String)
  public firstName: string = null;

  @JsonProperty('lastName', String)
  public lastName: string = null;

  @JsonProperty('company', Company)
  public company: Company = null;
}
