declare let $: any;

export const Infinite = ['$timeout', 'translations', ($timeout, translations): any => {
  'ngInject';
  return {
    restrict: 'A',
    link: (scope, element, attrs) => {
      const loadingText = translations.i18n.GENERAL.LOADING_LIST;
      const preloadTriggerDistance = 100;
      let lastScrollTopPos = 0;

      // Function to be called in order to fetch more data
      let callbackFunction;

      // Watch when infinite attr is set
      scope.$watch(attrs.infinite, val => {

        // Callback to be executed in order to fetch data
        callbackFunction = attrs.infinite;

        // If infinite-load is defined and true then try to get data -> might not be populated in the first place
        if (scope.$eval(attrs.infiniteLoad)) {
          scrollFunction();
        }
      });

      scope.$on('sizeChanged', scrollFunction);

      // If document-infinite attribute is set then the on scroll function will be set on window not on the element
      let documentInfinite;

      // Block (bottom direction) flag in order to to bring more data if processing is in place
      let blockBottom = false;

      // Block (top direction) flag in order to to bring more data if processing is in place
      let blockTop = false;

      scope.$watch(attrs.documentInfinite, val => {
        documentInfinite = scope.$eval(attrs.documentInfinite);
        // Apply the scrolling function to the element/document
        $(documentInfinite ? window : element).scroll(scrollFunction);
      });

      // Add listener for unblock of infinite scroll
      scope.$on('unblockInfinite', event => {
        unblock(event);
      });

      /**
       * Scrolling function
       */
      function triggerScroll(event): void {
        const scrollTop = $(window).scrollTop();
        const elementScrollTop = $(element).scrollTop();
        const documentHeight = $(document).height();
        const windowHeight = $(window).height();
        const currentScrollTopPos = getOffsetTop(event.currentTarget);

        if (documentInfinite && scrollTop <= documentHeight - windowHeight
          && scrollTop >= documentHeight - windowHeight - preloadTriggerDistance
          && currentScrollTopPos > lastScrollTopPos) {
          runScrollCallback();
        } else {
          const nativeElement = $(element)[0];
          const outerHeight = Math.floor($(element).outerHeight());
          const scrollOnElement = !documentInfinite && nativeElement && nativeElement.scrollHeight !== 0;

          if (scrollOnElement && nativeElement.scrollHeight < scrollTop + outerHeight + preloadTriggerDistance) {
            runScrollCallback();
          } else if (scrollOnElement && scrollTop > 0 && elementScrollTop === 0) {
            // runScrollCallback(true); // revert performance issue
          } else if (scrollOnElement && nativeElement.scrollHeight <= elementScrollTop + outerHeight) {
            runScrollCallback();
          }
        }
      }

      /**
       * Block the scroll function and add loading message
       */
      function runScrollCallback(isScrollToTop = false): void {
        const loadingIconBlock = document.createElement('span');
        const loadingBlock = document.createElement('div');
        loadingIconBlock.className = 'icon qv-icon-gray fa fa-time';
        loadingBlock.classList.add('infinite');
        loadingBlock.innerText = loadingText;
        loadingBlock.appendChild(loadingIconBlock);

        if (isScrollToTop) {
          blockTop = true;
          blockBottom = false;
          loadingBlock.classList.add('infinite--top');
          $(element).parent().prepend(loadingBlock);
        } else {
          blockBottom = true;
          blockTop = false;
          $(element).parent().append(loadingBlock);
        }

        // Prevent trigger body scroll when user scrolled to top of the infinity scroll block
        document.documentElement.style.overflowY = 'hidden';
        $timeout(() => {
          scope[callbackFunction]().then(() => {
            unblock({});
            $('.infinite').remove();
          });
        }, 50);

        // Enable body scroll after loading data into scrollable block
        $timeout(() => {
          document.documentElement.style.overflowY = 'auto';
        }, 500);
      }

      /**
       * Unblock the scroll function and check if more data is needed
       */
      function unblock(event, needToTriggerScroll = true): void {
        blockTop = false;
        blockBottom = false;
        if (needToTriggerScroll) {
          triggerScroll(event);
        }
      }

      /**
       * The actual scrolling function
       */
      function scrollFunction(event?): void {
        const currentScrollTopPos = event ? getOffsetTop(event.currentTarget) : 0;
        if (currentScrollTopPos > lastScrollTopPos) {
          if (!blockBottom) triggerScroll(event);
        } else if (currentScrollTopPos < lastScrollTopPos) {
          if (!blockTop) triggerScroll(event);
        } else if (currentScrollTopPos === lastScrollTopPos) {
          return;
        }

        lastScrollTopPos = currentScrollTopPos;
      }

      // Remove the scrolling function on scope destroy
      scope.$on('$destroy', () => {
        $(documentInfinite ? window : element).off('scroll', scrollFunction);
      });

      function getOffsetTop(source): number {
        if (source instanceof Window) {
          return source.pageYOffset;
        } else if (source instanceof HTMLElement) {
          return source.scrollTop;
        }

        return 0;
      }

    }
  };
}];
