import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MarketShare } from '@qv-bid/entities/market-share.entity';
import { BidFormService } from '@qv-bid/services';
import { distinctUntilChanged, filter, map, switchMap, take, tap } from 'rxjs/operators';
import { BehaviorSubject, merge, Observable, Subject } from 'rxjs';
import { Drug, Ndc, Scenario } from '@qv-bid/entities';
import { TermName, TermSection } from '@qv-term/enums';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BidStateService } from '@qv-bid/services/bid-state.service';
import { RequestObject } from '@qv-bid/models/request-object.interface';
import { NotificationService } from 'quantuvis-angular-common/notification';
import { resources } from '@qv-common/static';
import { DrugSectionsFormService } from '@qv-bid/services/drug/drug-sections-form.service';
import { SingleNotification } from '@qv-shared/classes';
import { Toast } from 'ngx-toastr';
import { SingleNotificationService } from '@qv-shared/services';
import { UserService } from '@qv-common/services/auth/user.service';

@UntilDestroy()
export class DrugFormService {
  public readonly drugUpdated = new Subject();
  public readonly sectionChanged$ = new Subject<TermSection>();
  private editModeIsReadyForDrug$ = new BehaviorSubject<boolean>(false);
  private pendingChangedDrug: Record<string, any> = {};
  private drugForm: FormGroup;
  private drug: Scenario | Ndc;
  private singleNotification: SingleNotification<Toast>;
  private suspendedSectionChanges: Map<TermSection, boolean> = new Map<TermSection, boolean>();

  constructor(
    private formBuilder: FormBuilder,
    private drugSectionsFormService: DrugSectionsFormService,
    private bidStateService: BidStateService,
    private notificationService: NotificationService,
    private userService: UserService,
    private bidFormService: BidFormService,
    private singleNotificationService: SingleNotificationService
  ) {
    this.singleNotification = this.singleNotificationService.getInstance<Toast>();
  }

  public transitDrugToEditMode(): void {
    this.checkEditModeIsEnabledAndSetActiveDrug().subscribe(() => {
      if (!this.drugForm) {
        this.createForm(this.getDrug());
        this.handleUpdateNotifications();
      }
      this.bidFormService.setDrugFormToBidForm(this.drugForm);
      this.editModeIsReadyForDrug$.next(true);
    });
  }

  public setDrug(drug: Scenario | Ndc): void {
    this.drug = drug;
    this.handleActiveDrug();
  }

  public updateDrug(updateDrug: Scenario | Ndc): void {
    this.drug = updateDrug;
    if (this.drugForm) {
      const drug = this.getDrug();
      this.drugForm.patchValue({
        [TermSection.MARKET_BASKET]: this.drugSectionsFormService.patchMarketBasketFormGroup(this.getScenario()),
        [TermSection.REBATE]: this.drugSectionsFormService.patchRebateFormGroup(drug.rebate),
        [TermSection.UM]: this.drugSectionsFormService.pathUmSection(drug.utilizationManagement),
        [TermSection.SCENARIO]: this.drugSectionsFormService.pathContractSection(drug.contract),
        [TermSection.PRICE_PROTECTION]:
          this.drugSectionsFormService.pathPriceProtectionSection(drug.priceProtection)
      }, { emitEvent: false });
      this.drugUpdated.next();
    }
  }

  public updateSilentMarketShareForm(marketShare: MarketShare): void {
    if (this.drugForm) {
      const rouarForm = ((this.drugForm.controls[TermSection.MARKET_SHARE] as FormGroup)
        .controls[TermName.RANGE_OR_UNITS_AND_REBATES] as FormArray);

      this.suspendSectionChanges(TermSection.MARKET_SHARE);
      this.drugForm.patchValue({
        [TermSection.MARKET_SHARE]: this.drugSectionsFormService.patchMSHSection(marketShare, rouarForm),
      });
      this.resumeSectionChanges(TermSection.MARKET_SHARE);
    }
  }

  public formChangeHandler(sectionName: TermSection): Observable<Record<string, any>> {
    return this.isEditMode(sectionName).pipe(
      filter((isEditMode: boolean) => isEditMode),
      switchMap(() => merge(
        this.drugForm.controls[sectionName].valueChanges,
        this.pendingValueChanges(sectionName)
      )),
      filter(() => !this.suspendedSectionChanges.get(sectionName))
    );
  }

  public sectionChangeHandler(sectionName: TermSection): Observable<Record<string, any>> {
    return this.formChangeHandler(sectionName).pipe(
      tap((changes: Record<string, any>) => this.pendingChangedDrug = {
        ...this.pendingChangedDrug,
        [sectionName]: this.drugForm.valid ? null : changes
      }),
      filter(() => this.isDrugFormValid()),
      map(() => this.drugForm.getRawValue()[sectionName]),
      map((changes: Record<string, any>) => this.createRequestObjectForSection(sectionName, changes, this.getDrug()[sectionName])),
      tap(() => this.sectionChanged$.next(sectionName)),
      untilDestroyed(this)
    );
  }

  public getTermControl(termName: TermName, sectionName: TermSection): FormControl {
    if (!this.drugForm) {
      return null;
    }

    return (this.drugForm.controls[sectionName] as FormGroup).controls[termName] as FormControl;
  }

  public getTermFormArray(termName: TermName, sectionName: TermSection): FormArray {
    return (this.drugForm.controls[sectionName] as FormGroup).controls[termName] as FormArray;
  }

  public isEditMode(sectionName: TermSection): Observable<boolean> {
    return this.editModeIsReadyForDrug$.pipe(
      distinctUntilChanged(),
      map((isEditMode: boolean) =>
        isEditMode && this.drugSectionsFormService.editModeIsAvailableForSection(sectionName, this.getDrug())),
      untilDestroyed(this),
    );
  }

  public isEditModeReadyForDrug(): boolean {
    return this.editModeIsReadyForDrug$.getValue();
  }

  private suspendSectionChanges(sectionName: TermSection): void {
    this.suspendedSectionChanges.set(sectionName, true);
  }

  private resumeSectionChanges(sectionName: TermSection): void {
    this.suspendedSectionChanges.set(sectionName, false);
  }

  private pendingValueChanges(sectionName: TermSection): Observable<Record<string, any>> {
    return this.drugForm.valueChanges.pipe(
      filter(() => this.drugForm.valid),
      map(() => this.pendingChangedDrug[sectionName]),
      filter((section: Record<string, any>) => Boolean(section)),
      untilDestroyed(this),
    );
  }

  private handleActiveDrug(): void {
    this.bidStateService.activeDrugInEditMode.pipe(
      filter((activeDrugId: number) => activeDrugId !== this.getCurrentDrugId()),
      untilDestroyed(this),
    ).subscribe(() => this.editModeIsReadyForDrug$.next(false));
  }

  private handleUpdateNotifications(): void {
    this.drugForm.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
      if (this.isDrugFormValid()) {
        this.singleNotification.clear();
      } else if (!this.drugForm.pending && !this.drugForm.valid) {
        this.singleNotification.toast(
          this.notificationService.error(resources.BID_DETAILS.INVALID_FIELDS_SAVING_ERROR)
        );
      }
    });
  }

  private createForm(drug: Drug | Ndc): void {
    const fb = this.formBuilder;
    const um = fb.group(this.drugSectionsFormService.umSection(drug.utilizationManagement));
    const rebate = fb.group(this.drugSectionsFormService.createRebateFormGroup(drug.rebate));
    const msh = fb.group(this.drugSectionsFormService.marketShareSection(fb, drug.marketShare));
    const contract = fb.group(this.drugSectionsFormService.createContractSection(drug.contract));
    const mb = fb.group(this.drugSectionsFormService.createMarketBasketFormGroup(this.getScenario()));
    const priceProtection = fb.group(this.drugSectionsFormService.priceProtectionSection(drug.priceProtection));

    this.drugForm = fb.group({
      [TermSection.UM]: um,
      [TermSection.REBATE]: rebate,
      [TermSection.MARKET_SHARE]: msh,
      [TermSection.SCENARIO]: contract,
      [TermSection.MARKET_BASKET]: mb,
      [TermSection.PRICE_PROTECTION]: priceProtection
    });
  }

  private checkEditModeIsEnabledAndSetActiveDrug(): Observable<boolean> {
    return this.bidStateService.isEditMode.pipe(
      take(1),
      filter((isEditMode: boolean) => isEditMode),
      filter(() => !this.isEditModeReadyForDrug()),
      filter(() => !this.getDrug().isDismissed()),
      filter(() => !this.isLockedNdcForPharmaUser()),
      tap(() => this.bidStateService.activeDrugInEditMode.next(this.getCurrentDrugId()))
    );
  }

  private getScenario(): Scenario {
    return this.drug instanceof Scenario ? this.drug : null;
  }

  private getDrug(): Drug | Ndc {
    return (this.drug as Scenario).drug || this.drug as Ndc;
  }

  private getCurrentDrugId(): number {
    return (this.drug as Scenario).id || (this.drug as Ndc).drugId;
  }

  private createRequestObjectForSection(sectionName: TermSection, changes: { [key: string]: any },
                                        originalEntity?: Record<string, any>): RequestObject {
    return this.drugSectionsFormService.createRequestObjectForSection(sectionName, changes, originalEntity);
  }

  private isLockedNdcForPharmaUser(): boolean {
    return this.drug instanceof Ndc && this.drug.isLocked && this.userService.isCurrentUserPharma();
  }

  private isDrugFormValid(): boolean {
    this.drugForm.updateValueAndValidity({ emitEvent: false });

    return this.drugForm.valid && !this.drugForm.pending;
  }
}
