import { TermSection } from '@qv-term/enums/term-section.enum';

export enum TermName {
  RFP_TITLE = 'rfpTitle',
  LINE_OF_BUSINESS = 'lineOfBusiness',
  RFP_START_DATE = 'rfpStartDate',
  RFP_DUE_DATE = 'rfpDueDate',
  ATTACHMENTS = 'attachments',
  WEBSITE_LINKS = 'websiteLinks',
  WEBSITE_LINKS_LOCK = 'webSiteLinksLock',
  BID_ATTACHMENTS_LOCK = 'attachmentsLock',
  CONTRACT_START_DATE = 'contractStartDate',
  CONTRACT_START_DATE_LOCK = 'contractStartDateLock',
  CONTRACT_END_DATE = 'contractEndDate',
  CONTRACT_END_DATE_LOCK = 'contractEndDateLock',

  SCENARIO_START_DATE = 'scenarioStartDate',
  SCENARIO_START_DATE_LOCK = 'scenarioStartDateLock',

  SCENARIO_END_DATE = 'scenarioEndDate',
  SCENARIO_END_DATE_LOCK = 'scenarioEndDateLock',

  ADMINISTRATION_FEE = 'administrationFee',
  ADMINISTRATION_FEE_LOCK = 'administrationFeeLock',

  COMMENTS_LOCK = 'commentsLock',

  CONTRACT_EVERGREEN = 'contractEvergreen',
  INSTRUCTIONS = 'instructions',
  BINDING_BID = 'bindingBid',
  COMMENTS = 'COMMENTS',
  LEGAL_ATTESTATION = 'LEGAL_ATTESTATION',

  REBATE_ELIGIBILITY = 'rebateEligibility',
  PAYMENT_LAG = 'lag',
  PAYMENT_FREQUENCY = 'paymentFrequency',
  WHOLESALE_ACQUISITION_PRICE = 'wholesalePrice',
  LATE_PAYMENT_PENALTY_TERMS_BASIS = 'basis',
  LATE_PAYMENT_PENALTY_TERMS_FREQUENCY = 'penaltyFrequency',
  PHARMA_AUDIT_TERMS_AUDIT_FREQUENCY = 'pharmaAuditFrequency',
  NO_FAULT_TERMINATION_CLAUSE_WHO_MAY_INVOKE = 'whoMayInvoke',
  NO_FAULT_TERMINATION_CLAUSE_PRIOR_NOTICE_REQUIRED = 'terminationClausePriorNoticeRequired',
  PHARMA_AUDIT_TERMS_LOOK_BACK = 'lookBack',
  PHARMA_AUDIT_TERMS_PRIOR_NOTICE_REQUIRED = 'priorNoticeRequired',
  LATE_PAYMENT_PENALTY_TERMS_VALUE = 'value',

  // Rebate
  REBATE = 'rebateUnion',
  MIN_BASE_REBATE = 'minBaseRebate',
  BASE_REBATE = 'baseRebate',
  BASE_REBATE_LOCK = 'baseRebateLock',
  TIER_PLACEMENT = 'tierPlacement',
  TIER_PLACEMENT_LOCK = 'tierPlacementLock',
  TIER_COMPETITION = 'tierCompetition',
  COMPETITION_GROUP = 'competitionGroup',
  COMPETITORS_TIER = 'competitorsTier',
  NOTES = 'notes',
  NOTES_LOCK = 'notesLock',
  MARKET_BASKET = 'marketBasket',
  MARKET_BASKET_LOCK = 'marketBasketLock',

  // UM
  PRODUCT_UM = 'productUmUnion',
  COMPETITION_UM = 'competitionUmUnion',
  PRIOR_AUTHORIZATION_REQUIRED_ON_COMPETITORS_PRODUCTS = 'priorAuthorizationRequired',
  PRIOR_AUTHORIZATION_ALLOWED_ON_PRODUCT = 'priorAuthorizationAllowed',
  STEP_THERAPY_REQUIRED_ON_COMPETITORS_PRODUCTS = 'stepTherapyRequired',
  STEP_THERAPY_ALLOWED_ON_PRODUCT = 'stepTherapyAllowed',
  QUANTITY_LIMIT = 'quantityLimit',
  OTHER_UM = 'otherUM',
  UM_DETAILS = 'umDetails',

  // Market Share
  MARKET_SHARE_STATE = 'marketShareState',
  MARKET_SHARE_TIER = 'marketShareTier',
  RANGE_OR_UNITS_AND_REBATES = 'rangeOrUnitAndRebates',

  NET_EFFECTIVE_PRICE = 'netEffectivePrice',
  THRESHOLD = 'threshold',
  RESET = 'reset',
  BASELINE_START_DATE = 'baselineStartDate',
  NEW_PRICE_EFFECTIVE_DATE = 'newPriceEffectiveDate',
  BASELINE_WAC = 'baselineWac',
  INDEX = 'index',
  PRICE_PROTECTION_STATE = 'PRICE_PROTECTION_STATE',
  STATE = 'state',
}

export namespace TermName {
  export function getTermNameLock(termName: TermName | TermSection): string {
    return `${termName}Lock`;
  }
}
