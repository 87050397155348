import memoize from 'memoizee';

// tslint:disable: no-invalid-this

export function QvCache(config?: any): (target, key: string, descriptor) => void {
  return (target, key: string, descriptor): void => {
    const defaultCacheSize = { max: 100 };
    const oldFunction = descriptor.value;
    const newFunction = memoize(oldFunction, { ...defaultCacheSize, ...config });
    descriptor.value = function (): () => any {
      return newFunction.apply(this, arguments);
    };
  };
}
