import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { SvgIconName } from '@qv-common/enums';
import { MatSnackBarRef } from '@angular/material/snack-bar';

@Component({
  selector: 'qv-filter-snack-bar',
  templateUrl: './filter-snack-bar.component.html',
  styleUrls: ['./filter-snack-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilterSnackBarComponent {
  public readonly svgIconName = SvgIconName;
  @Input()
  public message: string;
  @Input()
  public clearFilterButtonText: string;
  @Input()
  public showClearButton: boolean;
  @Input()
  public showCloseButton: boolean;
  @Output()
  public clearFilters = new EventEmitter<void>();

  constructor(
    public snackBarRef: MatSnackBarRef<FilterSnackBarComponent>
  ) {}

  public onClearFiltersClick(): void {
    this.clearFilters.emit();
  }

  public onClose(): void {
    this.snackBarRef.dismiss();
  }
}
