import { Directive, EventEmitter, Input, Output } from '@angular/core';
import { ContractedBusinessManageAction } from '@qv-bid/components/shared/cb-manage-modal/enums';
import { BehaviorSubject } from 'rxjs';
import { ManageContractedBusinessesService } from '@qv-bid/services';
import { SvgIconName } from '@qv-common/enums';

import { ContractedBusiness } from 'quantuvis-core-entities';
import { take } from 'rxjs/operators';

@Directive()
export class BaseContractedBusinessManageMenu {
  @Input()
  public cbList: ContractedBusiness[];
  @Input()
  public isDisabled = false;

  @Output()
  public cbAddEvent = new EventEmitter<ContractedBusiness[]>();
  @Output()
  public cbDeleteEvent = new EventEmitter<ContractedBusiness[]>();
  @Output()
  public cbRenameEvent = new EventEmitter<ContractedBusiness[]>();
  @Output()
  public cbImportEvent = new EventEmitter<void>();

  public readonly icon = new BehaviorSubject(SvgIconName.CHEVRON_BOTTOM);

  public readonly menuItems = [
    {
      name: ContractedBusinessManageAction.RENAME,
      handler: this.renameButtonHandler.bind(this),
      visible: () => true
    },
    {
      name: ContractedBusinessManageAction.DUPLICATE,
      handler: this.duplicateButtonHandler.bind(this),
      visible: () => true
    },
    {
      name: ContractedBusinessManageAction.DELETE,
      handler: this.deleteButtonHandler.bind(this),
      visible: this.isCountGreaterThanOne.bind(this)
    },
    {
      name: ContractedBusinessManageAction.IMPORT,
      handler: this.importButtonHandler.bind(this),
      visible: this.isCountGreaterThanOne.bind(this)
    }
  ];

  constructor(
    protected manageCBService: ManageContractedBusinessesService,
  ) {}

  public menuOpenedHandler(): void {
    this.icon.next(SvgIconName.CHEVRON_TOP);
  }

  public menuClosedHandler(): void {
    this.icon.next(SvgIconName.CHEVRON_BOTTOM);
  }

  public onMenuItemClick(cb: ContractedBusiness, index: number): void {
    this.menuItems[index].handler.call(this, cb);
  }

  public isCountGreaterThanOne(): boolean {
    return this.cbList.length > 1;
  }

  private renameButtonHandler(cb: ContractedBusiness): void {
    this.manageCBService.openModal(ContractedBusinessManageAction.RENAME, cb)
      .pipe(take(1))
      .subscribe((cbList: ContractedBusiness[]) => this.cbRenameEvent.emit(cbList));
  }

  private duplicateButtonHandler(cb: ContractedBusiness): void {
    this.manageCBService.openModal(ContractedBusinessManageAction.DUPLICATE, cb)
      .pipe(take(1))
      .subscribe((cbList: ContractedBusiness[]) => this.cbAddEvent.emit(cbList));
  }

  private deleteButtonHandler(cb: ContractedBusiness): void {
    this.manageCBService.openModal(ContractedBusinessManageAction.DELETE, cb)
      .pipe(take(1))
      .subscribe((cbList: ContractedBusiness[]) => this.cbDeleteEvent.emit(cbList));
  }

  private importButtonHandler(cb: ContractedBusiness): void {
    this.manageCBService.openModal(ContractedBusinessManageAction.IMPORT, cb)
      .pipe(take(1))
      .subscribe(() => this.cbImportEvent.emit());
  }
}
