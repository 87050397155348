import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { AuthService } from 'quantuvis-angular-common/auth';

@Injectable({
  providedIn: 'root'
})
export class LogoutGuard implements CanActivate {

  constructor(private authService: AuthService) {}

  public canActivate(): boolean {
    this.authService.logout();

    return false;
  }
}
