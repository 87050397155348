import { DateTerm } from '@qv-term/models/types';
import { Comparator, KeepOriginalValue, Reset, Validate } from '@qv-common/interfaces';
import { CoreUtils } from '@qv-common/utils';
import { resources } from '@qv-common/static';

export class BaselineStartDateTerm extends DateTerm implements KeepOriginalValue, Reset, Comparator, Validate {
  public allowKeepOriginValue: boolean;
  public processKeepOriginalValue: boolean;
  public keepOriginalValue: boolean;
  // When scenario is copied, ignore the "getValueToSave" modifier
  public keepValueOnCopy: boolean;

  constructor(name: string) {
    super(
      name,
      name,
      resources.TermsDescriptions.DRUG_LIST.BASELINE_START_DATE,
      '',
      null,
      null,
      'term_baseline-start-date',
      true
    );

    this.keepOriginalValue = this.allowKeepOriginValue;
    this.processKeepOriginalValue = false;
    this.keepValueOnCopy = true;
  }

  public getValueToSave(): number {
    // TODO need add KeepOriginal logic
    return super.getValueToSave();
  }

  public resetValue(): void {
    this.value = this.defaultValue;
  }

  public getTermValueForComparison(): string {
    return CoreUtils.isDefined(this.value) ? this.value.getTime().toString() : '';
  }

  public validate(): boolean {
    return CoreUtils.isDefined(this.value);
  }
}
