import { TemplateRef } from '@angular/core';
import moment from 'moment';

export class CoreUtils {
  private static numericStringRegex = /^[\+\-]?\d*\.?\d+$/;

  // eslint-disable-next-line @typescript-eslint/ban-types
  public static copyDeep<T extends object>(source: T): T {
    if (typeof(source) !== 'object' || source === null) {
      return source;
    }

    if (moment.isMoment(source)) {
      return (source as any).clone();
    }

    const clone = Array.isArray(source)
      ? source.slice()
      : Object.assign(new (Object.getPrototypeOf(source).constructor)(), source);

    const keys = Object.keys(clone);

    keys.forEach((key: string) => {
      clone[key] = this.copyDeep(clone[key]);
    });

    return clone as T;
  }

  public static isNull(obj: any): boolean {
    return obj === null;
  }

  public static isDefined(obj: any): boolean {
    return typeof obj !== 'undefined';
  }

  public static isNotDefined(obj: any): boolean {
    return typeof obj === 'undefined';
  }

  public static isNumber(value: any): boolean {
    return typeof value === 'number';
  }

  public static isBoolean(value: any): boolean {
    return typeof value === 'boolean';
  }

  public static isTemplateRef(value: any): boolean {
    return value instanceof TemplateRef;
  }

  /**
   * Checks if value is an empty object, collection, map, or set.
   * Objects are considered empty if they have no own enumerable string keyed properties.
   *
   * @param obj
   */
  public static isEmpty(obj: any): boolean {
    if (obj === undefined || obj === null) return true;

    if (obj instanceof Map || obj instanceof Set) return !obj.size;

    return !Object.keys(obj).length;
  }

  public static isNotEmptyObject(value: any): boolean {
    return value && value.constructor === Object && Object.keys(value).length > 0;
  }

  public static isObjectHasNotEmptyValues<T>(source: T): boolean {
    // eslint-disable-next-line eqeqeq
    return source && source.constructor === Object && Object.values(source).some((val: unknown) => val != null);
  }

  public static isPathDefined(rootObject: any, path: string): boolean {
    if (CoreUtils.isNotDefined(rootObject) || CoreUtils.isNull(rootObject)) {
      return false;
    }

    let lastPath = rootObject;
    const checkPath = path.split('.');

    for (const pathItem of checkPath) {
      if (CoreUtils.isNotDefined(lastPath[pathItem]) || CoreUtils.isNull(lastPath[pathItem])) {
        return false;
      }
      lastPath = lastPath[pathItem];
    }
    return true;
  }

  public static isPositionStickySupported(): boolean {
    const element = document.createElement('div');
    element.style.cssText = 'position:sticky;position:-webkit-sticky;'; // -webkit-sticky for Safari
    return element.style.position.includes('sticky') && !(/edge\//i.test(window.navigator.userAgent));
  }

  public static canBeCastToNumber(value: any): boolean {
    return (typeof value === 'number') || (typeof value === 'string' && this.numericStringRegex.test(value));
  }

  public static isClickEvent(event: PointerEvent): boolean {
    return event && (event.detail === 1 || event.pointerType === 'mouse');
  }

  public static getMaxDisplayedValue(value: number, max: number = 99): number | string {
    return value > max ? `${max}+` : value;
  }
}
