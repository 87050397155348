import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BaseInsideModal } from 'quantuvis-angular-common/modal';

@Component({
  selector: 'qv-open-source-software',
  templateUrl: './open-source-software.component.html',
  styleUrls: ['./open-source-software.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OpenSourceSoftwareComponent extends BaseInsideModal {}
