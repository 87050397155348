import { DictionaryItem } from 'quantuvis-angular-common/interfaces';

export enum AspGuaranteeMethod {
  EMPTY = 108,
  METHOD_1,
  METHOD_2,
  METHOD_3,
  METHOD_4,
  METHOD_5,
  METHOD_6,
  METHOD_7,
  METHOD_8
}

export namespace AspGuaranteeMethod {
  const valuesMap = new Map<AspGuaranteeMethod, DictionaryItem>([
    [AspGuaranteeMethod.EMPTY, { id: AspGuaranteeMethod.EMPTY, name: '' }],
    [AspGuaranteeMethod.METHOD_1, { id: AspGuaranteeMethod.METHOD_1, name: 'Method 1' }],
    [AspGuaranteeMethod.METHOD_2, { id: AspGuaranteeMethod.METHOD_2, name: 'Method 2' }],
    [AspGuaranteeMethod.METHOD_3, { id: AspGuaranteeMethod.METHOD_3, name: 'Method 3' }],
    [AspGuaranteeMethod.METHOD_4, { id: AspGuaranteeMethod.METHOD_4, name: 'Method 4' }],
    [AspGuaranteeMethod.METHOD_5, { id: AspGuaranteeMethod.METHOD_5, name: 'Method 5' }],
    [AspGuaranteeMethod.METHOD_6, { id: AspGuaranteeMethod.METHOD_6, name: 'Method 6' }],
    [AspGuaranteeMethod.METHOD_7, { id: AspGuaranteeMethod.METHOD_7, name: 'Method 7' }],
    [AspGuaranteeMethod.METHOD_8, { id: AspGuaranteeMethod.METHOD_8, name: 'Method 8' }]
  ]);

  export function getValue(aspGuaranteeMethod: AspGuaranteeMethod): DictionaryItem {
    return valuesMap.get(aspGuaranteeMethod);
  }

  export function getValues(): DictionaryItem[] {
    return Array.from(valuesMap.values());
  }
}
