import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TermsAndConditionsEventBusService } from '../../services/terms-and-conditions-event-bus.service';
import { BaseInsideModal } from 'quantuvis-angular-common/modal';
import { UserService } from '@qv-common/services';

@Component({
  selector: 'qv-terms-of-service',
  templateUrl: './terms-of-service.component.html',
  styleUrls: ['./terms-of-service.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TermsOfServiceComponent extends BaseInsideModal {
  constructor(
    public userService: UserService,
    private termsAndConditionsEventBusService: TermsAndConditionsEventBusService
  ) {
    super();
  }

  public openOpenSourceSoftwareModal(): void {
    this.closeEvent.emit();
    this.termsAndConditionsEventBusService.openOpenSourceSoftwareModal$.next();
  }

  public openPrivacyPolicyModal(): void {
    this.closeEvent.emit();
    this.termsAndConditionsEventBusService.openPrivacyPolicyModal$.next();
  }
}
