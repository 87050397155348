import { DateTerm } from '@qv-term/models/types';
import { constants, resources } from '@qv-common/static';

export class RfpStartDateTerm extends DateTerm {

  constructor(name: string) {
    super(
      name,
      name,
      resources.TermsDescriptions.SUMMARY.RFP_START_DATE,
      constants.SUMMARY_TERMS.RFP_START_DATE.label,
      null,
      null,
      'term__rfp-start-date',
      false
    );
  }
}
