<block-ui>
  <div class="page">
    <acc-system-menu
        *ngIf="isSystemMenuAvailable$ | async"
        [menuConfig]="menuConfig"
        class="page__left-sidebar"
    ></acc-system-menu>
    <div [class.main-content--no-menu]="!(isHeaderAvailable$ | async)" class="page__main-content">
      <qv-header-main *ngIf="isHeaderAvailable$ | async" data-test-id="test-id-header-main"></qv-header-main>
      <div [class.no-header]="!(isHeaderAvailable$ | async)"
           id="wrapper">
        <section id="content">
          <router-outlet></router-outlet>
        </section>
      </div>
    </div>
  </div>
</block-ui>
