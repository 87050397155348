import { resources } from '@qv-common/static';
import { drugTermsConstants } from '@qv-term/constants';
import { TermName } from '@qv-term/enums';
import { SelectBoxTerm } from '@qv-term/models/types';
import { StepTherapyAllowed } from 'quantuvis-core-entities';

export class StepTherapyAllowedProductTerm extends SelectBoxTerm {
  constructor(name: string) {
    super(
      name,
      drugTermsConstants[TermName.STEP_THERAPY_ALLOWED_ON_PRODUCT].displayNames.ALLOWED_ON_PRODUCT,
      resources.TermsDescriptions.DRUG_LIST.STEP_THERAPY,
      '',
      '',
      StepTherapyAllowed.getValue(StepTherapyAllowed.NO),
      'term__step-threpy-allowed-product'
    );
    this.values = StepTherapyAllowed.getValues();
  }
}
