/* eslint-disable no-underscore-dangle */
import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class BidCommentFormService {
  public isFormReady$ = new BehaviorSubject(false);
  private _formControl: FormControl;

  public get formControl(): FormControl {
    return this._formControl;
  }

  public set formControl(value: FormControl) {
    this._formControl = value;
    this.isFormReady$.next(Boolean(value instanceof FormControl));
  }
}
