import { FieldDropdownOptions } from '../../entities/field-dropdown-options.entity';
import { createEntityAdapter } from '@ngrx/entity';

function selectField(fieldDropdownOptions: FieldDropdownOptions): string {
  return fieldDropdownOptions.id;
}

export const fieldsDropdownOptionsAdapter = createEntityAdapter<FieldDropdownOptions>({
  selectId: selectField
});
