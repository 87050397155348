<div class="section-toolbar">
  <div class="section-toolbar__title">
    <h2>Business Attachments</h2>
    <mat-icon
        [matTooltip]="resources.TermsDescriptions.SUMMARY.ATTACHMENTS"
        [svgIcon]="svgIconName.INFO_OUTLINE">
    </mat-icon>
  </div>
  <qv-lock
      [control]="lockControl"
      [isDisabled]="isBidInvalid"
      [isEditMode]="isEditMode"
      [locked]="lockControl.value"
      data-test-id="test-id-summary-attachments-lock"
  ></qv-lock>
</div>
<ul *ngIf="bidAttachments?.length" class="row-items">
  <li *ngFor="let bidAttachment of bidAttachments" class="row-item">
    <div class="row-item__content">
      <h4 class="row-item__title">{{bidAttachment.name}}</h4>
      <p>Created
        by {{ getCompanyFullName(bidAttachment.baseCompany.name, bidAttachment.baseCompany.isPharma(), manufacturerCompanyOldName) }}
        on {{bidAttachment.createdAt | dateTimeUserTz:dateFormat | async }}</p>
    </div>
    <div class="row-item__actions">
      <button (click)="downloadAttachment(bidAttachment.id)" mat-icon-button>
        <mat-icon [svgIcon]="svgIconName.DOWNLOAD" class="qv-icon qv-icon--18"
                  data-test-id="test-id-download-business-attachments-button"></mat-icon>
      </button>
      <button
          (click)="onDeleteBidAttachmentButtonHandler(bidAttachment)"
          *ngIf="isManageAvailable(isEditable(isEditMode, isUserPharma, summary.attachmentsLock), bidAttachment.baseCompany.id, currentCompanyId)"
          mat-icon-button
      >
        <mat-icon [svgIcon]="svgIconName.DELETE" class="qv-icon qv-icon--18"
                  data-test-id="test-id-delete-business-attachments-button"></mat-icon>
      </button>
    </div>
  </li>
</ul>
<p *ngIf="!bidAttachments?.length && !isEditable(isEditMode, isUserPharma, summary.webSiteLinksLock)"
   class="qv-sidebar-section__no-data">{{commonMessage.NO_DATA}}</p>
<div *ngIf="isEditable(isEditMode, isUserPharma, summary.attachmentsLock)" class="file-upload">
  <ngx-file-drop
      #fileDropZone
      (onFileDrop)="onFileDropHandler($event)"
      [disabled]="isBidInvalid"
      [multiple]="false"
      contentClassName="file-upload-drop-zone__content"
      dropZoneClassName="file-upload-drop-zone"
      dropZoneLabel=""
  >
    <ng-template let-openFileSelector="openFileSelector" ngx-file-drop-content-tmp>
      <button
          (click)="openFileSelector()"
          [disabled]="isBidInvalid"
          class="file-upload__button"
          data-test-id="test-id-summary-attachments-upload-btn"
          mat-raised-button>
        <mat-icon [svgIcon]="svgIconName.UPLOAD" class="qv-icon qv-icon--22"></mat-icon>
        <span class="file-upload__button-text">Choose File</span>
      </button>
      <span class="file-upload__text">Or drag and drop file here</span>
    </ng-template>
  </ngx-file-drop>
</div>
