import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { LegalAttestationComponent } from '@qv-company/components/legal-attestation/legal-attestation.component';
import { LegalAttestationGuard } from '@qv-company/guards/legal-attestation.guard';
import { PdfViewerModule } from '@qv-pdf-viewer/pdf-viewer.module';
import { SharedModule } from '@qv-shared/shared.module';
import { FileUploadModule } from 'primeng/fileupload';
import { LegalAttestationService } from '@qv-company/services';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CompanyDaoService, LegalAttestationDaoService } from '@qv-company/services/dao';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FileUploadModule,
    SharedModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatSelectModule,
    MatTooltipModule,
    MatFormFieldModule,
    PdfViewerModule
  ],
  providers: [
    LegalAttestationService,
    CompanyDaoService,
    LegalAttestationDaoService,
    LegalAttestationGuard,
  ],
  declarations: [
    LegalAttestationComponent
  ]
})
export class CompanyModule {}
