/* tslint:disable:no-invalid-this triple-equals */
import { ArrayUtils } from '@qv-common/utils';

/**
 * Multiple filter implementation. At the moment the filter is used in bids list table.
 * Requires - AngularJS $filter
 *
 * Filter parameters:
 *  items - array of object to filter
 *  keyObj - name of the key to apply the filter
 *  filters - parent filters
 *  possibleValues - possible values to select from
 */

declare let angular: angular.IAngularStatic;

export const FilterMultiple = ['$filter', ($filter): any => {
  'ngInject';
  return (items, keyObj, filters, possibleValues) => {
    let hasFilter;
    const filterObj = {
      data: items,
      filteredData: [],
      applyFilter(obj, key): any {
        let fData = [];
        if (this.filteredData.length == 0) {
          this.filteredData = this.data;
        }
        if (obj) {
          const fObj = {};
          if (!angular.isArray(obj)) {
            fObj[key] = obj;
            fData = ArrayUtils.union(fData, $filter('filter')(this.filteredData, fObj, true));
          } else if (angular.isArray(obj)) {
            if (obj.length > 0) {
              for (let i = 0; i < obj.length; i++) {
                if (angular.isDefined(obj[i])) {
                  fObj[key] = obj[i];
                  // skip filter if searchValue is empty, used for initial drugs table
                  fData = fObj[key] !== '' ?
                    ArrayUtils.union(fData, $filter('objectFilter')(this.filteredData, fObj[key], key)) :
                    this.filteredData;
                }
              }

            }
          }

          this.filteredData = fData;

        }
      }
    };

    if (keyObj) {
      hasFilter = false;

      angular.forEach(keyObj, (obj, key) => {

        if (possibleValues) {
          possibleValues[key] = $filter('unique')(hasFilter ? filterObj.filteredData : items, key, true);
        }
        hasFilter = true;
        filterObj.applyFilter(obj, key);
      });
    }

    if (filters && possibleValues) {
      if (!hasFilter) {
        filters.forEach(item => {
          possibleValues[item] = $filter('unique')(items, item, true);
        });
      } else {
        const filtered = Object.keys(keyObj);

        filters.forEach(item => {
          if (filtered.indexOf(item) == -1) {
            possibleValues[item] = $filter('unique')(filterObj.filteredData, item, true);
          }
        });

      }
    }
    return hasFilter ? filterObj.filteredData : items;
  };
}];
