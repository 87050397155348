import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { DrugDaoService } from '@qv-bid/services/dao';
import { DrugAddModalMeta } from '@qv-bid/components/shared/drug-add-modal/models/drug-add-modal-meta';
import { UserService } from '@qv-common/services/auth/user.service';

@Injectable()
export class DrugService {
  public drugs: Record<string, any>;

  constructor(
    private userService: UserService,
    private drugDaoService: DrugDaoService
  ) {}

  public getDrugAddData(pharmaId?: number, alreadyAddedDrugs: number[] = []): Observable<DrugAddModalMeta[]> {
    let drugs: Observable<Record<string, any>>;
    if (this.userService.isCurrentUserPayer()) {
      drugs = this.drugDaoService.searchByPharmaId(pharmaId);
    } else {
      drugs = this.drugDaoService.searchAllByDrugNameAndClass();
    }

    return drugs.pipe(
      tap((data: Record<string, any>) => this.drugs = data),
      map(() => this.prepareDrugGroups(alreadyAddedDrugs))
    );
  }

  public prepareDrugGroups(alreadyAddedDrugs: number[]): DrugAddModalMeta[] {
    const list = [];

    Object.keys(this.drugs).forEach((key) => {
      const ndcGroup = new DrugAddModalMeta();
      list.push(ndcGroup);

      this.drugs[key].forEach((item) => {
        if (item.isGroup) {
          ndcGroup.setData(item);
        } else {
          ndcGroup.children.push(this.createChild(item, alreadyAddedDrugs));
        }
      });
    });

    return list;
  }

  private createChild(item: any, existedDrugIdsList: number[]): DrugAddModalMeta {
    const child = new DrugAddModalMeta();

    child.setData(item);

    if (existedDrugIdsList.includes(child.id)) {
      child.isPresentInBid = true;
    }

    return child;
  }
}
