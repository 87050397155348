import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';

import { HeaderMainComponent, NavigationMainComponent, NotificationBellComponent } from '@qv-header/components';
import { SharedModule } from '@qv-shared/shared.module';
import { NotificationBellService } from '@qv-header/components/notification-bell/notification-bell.service';
import { NavigationMainService } from '@qv-header/services/navigation-main.service';
import { NavigationMobileComponent } from '@qv-header/components/navigation-mobile/navigation-mobile.component';
import { ProfileMenuComponent } from '@qv-header/components/profile-menu/profile-menu.component';
import { InsightsModule } from '@qv-insights/insights.module';
import { InsightsDaoService } from '@qv-insights/services/dao';
import { NotificationBellDaoService } from '@qv-header/services/dao';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MatButtonModule,
    MatDividerModule,
    MatIconModule,
    MatMenuModule,
    MatTooltipModule,
    SharedModule,
    InsightsModule,
    RouterModule
  ],
  declarations: [
    NavigationMainComponent,
    NotificationBellComponent,
    NavigationMobileComponent,
    ProfileMenuComponent,
    HeaderMainComponent,
  ],
  exports: [
    HeaderMainComponent,
  ],
  providers: [
    NavigationMainService,
    NotificationBellService,
    InsightsDaoService,
    NotificationBellDaoService
  ]
})
export class HeaderModule {}
