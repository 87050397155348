import { DictionaryItem } from 'quantuvis-angular-common/interfaces';

export enum OtherUm {
  EMPTY = 1,
  NO = 2,
  YES = 3
}

export namespace OtherUm {
  const valuesMap = new Map<OtherUm, DictionaryItem>([
    [OtherUm.EMPTY, { id: OtherUm.EMPTY, name: '' }],
    [OtherUm.NO, { id: OtherUm.NO, name: 'No' }],
    [OtherUm.YES, { id: OtherUm.YES, name: 'Yes' }],
  ]);

  export function getValue(otherUm: OtherUm): DictionaryItem {
    return valuesMap.get(otherUm);
  }

  export function getValues(): DictionaryItem[] {
    return Array.from(valuesMap.values());
  }
}
