import { UserType } from '@qv-user/enums/user-type.enum';
import { JsonObject, JsonProperty } from 'json2typescript';
import { Role } from '@qv-common/models/user/role.interface';
import { Company } from '@qv-common/models/user/company.interface';
import { DateConverter } from '@qv-bid/serializers';

@JsonObject('User')
export class User {
  @JsonProperty('accountCreationDate', Number)
  public accountCreationDate = null;
  @JsonProperty('company')
  public company: Company = null;
  @JsonProperty('companyId', Number)
  public companyId = null;
  @JsonProperty('companyKey')
  public companyKey = null;
  @JsonProperty('disabled', Boolean)
  public disabled = null;
  @JsonProperty('email', String)
  public email = null;
  @JsonProperty('emailFilter', String)
  public emailFilter = null;
  @JsonProperty('filterByMyAccounts', Boolean)
  public filterByMyAccounts = null;
  @JsonProperty('firstName', String)
  public firstName = null;
  @JsonProperty('lastName', String)
  public lastName = null;
  @JsonProperty('honorific', String)
  public honorific = null;
  @JsonProperty('id', Number)
  public id = null;
  @JsonProperty('loginToken', String)
  public loginToken = null;
  @JsonProperty('name', String)
  public name = null;
  @JsonProperty('origin', String)
  public origin = null;
  @JsonProperty('phone', String)
  public phone = null;
  @JsonProperty('role')
  public role: Role = null;
  @JsonProperty('timezone')
  public timezone = null;
  @JsonProperty('title', String)
  public title = null;
  @JsonProperty('tokenExpirationDate', DateConverter)
  public tokenExpirationDate = null;
  @JsonProperty('userType')
  public userType: UserType = null;

  public get isPayer(): boolean {
    return this.userType === UserType.PAYER;
  }

  public get isManufacturer(): boolean {
    return this.userType === UserType.PHARMA;
  }

  public get isAdmin(): boolean {
    return this.userType === UserType.ADMIN;
  }

  public get fullName(): string {
    return `${this.firstName} ${this.lastName}`;
  }
}
