<div [style.height.px]="fullHeight" [style.width.px]="fullWidth">
  <content-loader [height]="fullHeight" [primaryColor]="primaryColor" [width]="fullWidth">
    <ng-container *ngFor="let _ of items; let index = index">
      <svg:rect
          [attr.height]="checkbox.height"
          [attr.rx]="radius"
          [attr.ry]="radius"
          [attr.width]="checkbox.width"
          [attr.x]="checkbox.left"
          [attr.y]="calculateTop(checkbox.top, checkbox.height, margin, index)"
      />
      <svg:rect
          [attr.height]="label.height"
          [attr.rx]="radius"
          [attr.ry]="radius"
          [attr.width]="label.width"
          [attr.x]="label.left"
          [attr.y]="calculateTop(label.top, checkbox.height, margin, index)"
      />
    </ng-container>
  </content-loader>
</div>
