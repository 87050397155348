export class CompanyUtils {
  public static getCompanyFullName(companyName: string, isPharma: boolean, oldCompanyName?: string): string {
    return isPharma
      ? this.appendOldCompanyName(companyName, oldCompanyName)
      : companyName;
  }

  public static appendOldCompanyName(companyName: string, oldCompanyName?: string): string {
    return oldCompanyName ? `${companyName} (${oldCompanyName})` : companyName;
  }
}
