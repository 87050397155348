import { OAuthStorage } from 'angular-oauth2-oidc';

export class CustomOAuthStorage extends OAuthStorage {
  public static readonly NONCE_KEY = 'nonce';

  public getItem(key: string): string | null {
    return this.getStorageForKey(key).getItem(key);
  }

  public removeItem(key: string): void {
    this.getStorageForKey(key).removeItem(key);
  }

  public setItem(key: string, data: string): void {
    this.getStorageForKey(key).setItem(key, data);
  }

  private getStorageForKey(key: string): Storage {
    if (key === CustomOAuthStorage.NONCE_KEY) {
      return sessionStorage;
    }

    return localStorage;
  }
}
