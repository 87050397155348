import { ApiUrlPrefix } from '@qv-common/enums';
import { ApiService } from '@qv-common/services/api';
import { HttpResponseWrapper } from '@qv-common/models/api';
import { BidAttachment } from '@qv-bid/entities';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FeedbackDaoService {
  constructor(
    private apiService: ApiService
  ) {}

  public send(data: Record<string, unknown>): Observable<BidAttachment> {
    return this.apiService.post(`${ApiUrlPrefix.OLD}/feedback/send`, data).pipe(
      map((response: HttpResponseWrapper<BidAttachment>) => response.body.data)
    );
  }

  public sendDrugFeedback(data: Record<string, unknown>): Observable<BidAttachment> {
    return this.apiService.post(`${ApiUrlPrefix.OLD}/feedback/drugFeedback`, data).pipe(
      map((response: HttpResponseWrapper<BidAttachment>) => response.body.data)
    );
  }

  public reportMissingDrug(data: Record<string, unknown>): Observable<BidAttachment> {
    return this.apiService.post(`${ApiUrlPrefix.OLD}/feedback/missingDrug`, data).pipe(
      map((response: HttpResponseWrapper<BidAttachment>) => response.body.data)
    );
  }
}
