import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SystemMenuItem } from './system-menu-item';

@Component({
  selector: 'acc-system-menu',
  templateUrl: './system-menu.component.html',
  styleUrls: ['./system-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SystemMenuComponent {

  @Input()
  public menuConfig: (SystemMenuItem | string) [];
}
