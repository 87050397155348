import { HttpParams } from '@angular/common/http';

export class HttpUtils {
  public static VIEW_PERSPECTIVE_KEY = 'perspective';

  public static appendQueryParamToUrl(url: string, name: string, value: string): string {
    return `${url}${url.indexOf('?') === -1 ? '?' : '&'}${name}=${value}`;
  }

  public static appendPerspectiveQueryParamToUrlIfBidIsInternal(url: string, perspective: string,
                                                                isInternalBid: boolean): string {
    return isInternalBid ? HttpUtils.appendQueryParamToUrl(url, HttpUtils.VIEW_PERSPECTIVE_KEY, perspective) : url;
  }

  public static getMergedParams(name: string, value: number | number[], params: HttpParams): HttpParams {
    if (value && params) return params.set(name, value.toString());
    if (value) return new HttpParams().set(name, value.toString());
    if (params) return params;
  }
}
