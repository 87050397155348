import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { from, Observable, of } from 'rxjs';
import { PermissionService } from '@qv-common/services/auth/permission.service';
import { catchError, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PermissionGuard implements CanActivate {

  constructor(private permissionService: PermissionService) {}

  public canActivate(next: ActivatedRouteSnapshot): Observable<boolean> {

    const permission = next.data.permission;
    const feature = next.data.feature;

    return this.permissionService.fetchUserPermissions()
      .pipe(
        switchMap((permissions: Map<string, boolean>) =>
          from(this.permissionService.checkPermissionInUserPermissionList(permission, feature, permissions))
        ),
        catchError((e: any) => {
          console.error(e);
          return of(false);
        })
      );
  }
}
