import { Injectable } from '@angular/core';
import { Contract, Drug, Ndc, PriceProtection, Rebate, Scenario, UtilizationManagement, } from '@qv-bid/entities';
import { NdcDateValue, NdcDictionaryValue, NdcValue } from '@qv-term/entities';
import { MarketShare } from '@qv-bid/entities/market-share.entity';
import { MarketShareService } from '@qv-bid/services/sections';
import { DictionaryItem } from '@qv-common/entities';
import { TermTemplateStorageService } from '@qv-term/services';
import { TermName, TermSection } from '@qv-term/enums';
import { MarketShareState, PriceProtectionState, UtilizationManagementState } from '@qv-bid/enums';
import { FormArray, FormBuilder, FormControl, Validators } from '@angular/forms';
import { drugTermsConstants } from '@qv-term/constants';
import { CoreUtils } from '@qv-common/utils';
import { RequestObject } from '@qv-bid/models/request-object.interface';
import { BidUtils } from '@qv-bid/utils';
import {
  AdministrationFeeTerm,
  BaselineStartDateTerm,
  BaseRebateTerm,
  ContractEndDateTerm,
  ContractStartDateTerm,
  MinimumBaseRebateTerm,
  NetEffectivePriceTerm,
  ThresholdTerm,
  UmDetailsTerm
} from '@qv-term/models/drug';
import { ModalService } from 'quantuvis-angular-common/modal';
import { UserService } from '@qv-common/services/auth/user.service';

@Injectable()
export class DrugSectionsFormService {
  constructor(private userService: UserService,
              private modalService: ModalService,
              private marketShareService: MarketShareService,
              private termTemplateStorage: TermTemplateStorageService) {}

  public createContractSection(contract: Contract): { [key: string]: FormControl } {
    const startDate = this.getTermTemplateByName(TermName.SCENARIO_START_DATE) as ContractStartDateTerm;
    const endDate = this.getTermTemplateByName(TermName.SCENARIO_END_DATE) as ContractEndDateTerm;
    const adminFee = this.getTermTemplateByName(TermName.ADMINISTRATION_FEE) as AdministrationFeeTerm;
    contract.scenarioStartDate.value = this.getValueBasedOnAtNdcFlag(contract.scenarioStartDate);
    contract.scenarioEndDate.value = this.getValueBasedOnAtNdcFlag(contract.scenarioEndDate);
    return {
      [TermName.SCENARIO_START_DATE]: new FormControl(
        contract.scenarioStartDate.value, [
          startDate.validatorInvalidFormat(drugTermsConstants[TermName.SCENARIO_START_DATE].title),
          startDate.validatorScenarioEndDateGreaterStartDate(TermName.SCENARIO_START_DATE),
        ]
      ),
      [TermName.SCENARIO_END_DATE]: new FormControl(
        contract.scenarioEndDate.value, [
          endDate.validatorInvalidFormat(drugTermsConstants[TermName.SCENARIO_END_DATE].title),
          endDate.validatorScenarioEndDateGreaterStartDate(TermName.SCENARIO_END_DATE),
        ]
      ),
      [TermName.ADMINISTRATION_FEE]: new FormControl(
        contract.administrationFee.value, {
          validators: adminFee.numberValidator(),
          updateOn: 'blur'
        }),
      [TermName.SCENARIO_START_DATE_LOCK]: new FormControl(contract.scenarioStartDateLock),
      [TermName.SCENARIO_END_DATE_LOCK]: new FormControl(contract.scenarioEndDateLock),
      [TermName.ADMINISTRATION_FEE_LOCK]: new FormControl(contract.administrationFeeLock)
    };
  }

  public pathContractSection(contract: Contract): Record<string, unknown> {
    contract.scenarioStartDate.value = this.getValueBasedOnAtNdcFlag(contract.scenarioStartDate);
    contract.scenarioEndDate.value = this.getValueBasedOnAtNdcFlag(contract.scenarioEndDate);

    return {
      [TermName.SCENARIO_START_DATE]: contract.scenarioStartDate.value,
      [TermName.SCENARIO_END_DATE]: contract.scenarioEndDate.value,
      [TermName.ADMINISTRATION_FEE]: contract.administrationFee.value,
      [TermName.SCENARIO_START_DATE_LOCK]: contract.scenarioStartDateLock,
      [TermName.SCENARIO_END_DATE_LOCK]: contract.scenarioEndDateLock,
      [TermName.ADMINISTRATION_FEE_LOCK]: contract.administrationFeeLock
    };
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  public umSection(um: UtilizationManagement): Record<string, any> {
    const umDetailsTerm = new UmDetailsTerm(drugTermsConstants[TermName.UM_DETAILS].title);
    return {
      [TermName.STEP_THERAPY_ALLOWED_ON_PRODUCT]: [
        um.stepTherapyAllowed.value,
        [],
        umDetailsTerm.confirmChangeAndCheckUmDetailsToBeCleared(this.modalService)
      ],
      [TermName.STEP_THERAPY_REQUIRED_ON_COMPETITORS_PRODUCTS]: [
        um.stepTherapyRequired.value,
        [],
        umDetailsTerm.confirmChangeAndCheckUmDetailsToBeCleared(this.modalService)
      ],
      [TermName.PRIOR_AUTHORIZATION_ALLOWED_ON_PRODUCT]: [
        um.priorAuthorizationAllowed.value,
        [],
        umDetailsTerm.confirmChangeAndCheckUmDetailsToBeCleared(this.modalService)
      ],
      [TermName.PRIOR_AUTHORIZATION_REQUIRED_ON_COMPETITORS_PRODUCTS]: [
        um.priorAuthorizationRequired.value,
        [],
        umDetailsTerm.confirmChangeAndCheckUmDetailsToBeCleared(this.modalService)
      ],
      [TermName.QUANTITY_LIMIT]: [
        um.quantityLimit.value,
        [],
        umDetailsTerm.confirmChangeAndCheckUmDetailsToBeCleared(this.modalService)
      ],
      [TermName.OTHER_UM]: [
        um.otherUM.value,
        [],
        umDetailsTerm.confirmChangeAndCheckUmDetailsToBeCleared(this.modalService)
      ],
      [TermName.STATE]: um.state.value,
      [TermName.UM_DETAILS]: new FormControl(um.umDetails.value, {
        validators: Validators.maxLength(umDetailsTerm.maxLength),
        updateOn: 'blur'
      })
    };
  }

  public pathUmSection(um: UtilizationManagement): Record<string, unknown> {
    return {
      [TermName.STEP_THERAPY_ALLOWED_ON_PRODUCT]: um.stepTherapyAllowed.value,
      [TermName.STEP_THERAPY_REQUIRED_ON_COMPETITORS_PRODUCTS]: um.stepTherapyRequired.value,
      [TermName.PRIOR_AUTHORIZATION_ALLOWED_ON_PRODUCT]: um.priorAuthorizationAllowed.value,
      [TermName.PRIOR_AUTHORIZATION_REQUIRED_ON_COMPETITORS_PRODUCTS]: um.priorAuthorizationRequired.value,
      [TermName.QUANTITY_LIMIT]: um.quantityLimit.value,
      [TermName.OTHER_UM]: um.otherUM.value,
      [TermName.STATE]: um.state.value,
      [TermName.UM_DETAILS]: um.umDetails.value
    };
  }

  public patchMSHSection(marketShare: MarketShare, rouarFormArray: FormArray): { [key: string]: any } {
    const rangeOrUnitAndRebates = marketShare.rangeOrUnitAndRebates.value || [];
    const formControls = rangeOrUnitAndRebates.map((value) => new FormControl(value));
    rouarFormArray.controls.slice().forEach(() => rouarFormArray.removeAt(0));
    formControls.forEach((formControl: FormControl) => rouarFormArray.push(formControl));

    return {
      [TermName.STATE]: marketShare.state.value,
      [TermName.MARKET_SHARE_TIER]: marketShare.marketShareTier.value,
    };
  }

  public createRebateFormGroup(rebate: Rebate): { [key: string]: FormControl } {
    const minBaseRebate = this.getTermTemplateByName(TermName.MIN_BASE_REBATE) as MinimumBaseRebateTerm;
    const baseRebate = this.getTermTemplateByName(TermName.BASE_REBATE) as BaseRebateTerm;

    return {
      [TermName.MIN_BASE_REBATE]: new FormControl(rebate.minBaseRebate.value, {
        validators: minBaseRebate.numberValidator(),
        updateOn: 'blur'
      }),
      [TermName.BASE_REBATE]: new FormControl(rebate.baseRebate.value, {
        validators: baseRebate.numberValidator(),
        updateOn: 'blur'
      }),
      [TermName.BASE_REBATE_LOCK]: new FormControl(rebate.baseRebateLock),
      [TermName.TIER_PLACEMENT]: new FormControl(rebate.tierPlacement.value),
      [TermName.TIER_PLACEMENT_LOCK]: new FormControl(rebate.tierPlacementLock),
      [TermName.TIER_COMPETITION]: new FormControl(rebate.tierCompetition.value),
      [TermName.COMPETITION_GROUP]: new FormControl(rebate.competitionGroup.value),
      [TermName.COMPETITORS_TIER]: new FormControl(rebate.competitorsTier.value),
      [TermName.NOTES_LOCK]: new FormControl(rebate.notesLock),
    };
  }

  public priceProtectionSection(priceProtection: PriceProtection): Record<string, unknown> {
    priceProtection.baselineStartDate.value = this.getValueBasedOnAtNdcFlag(priceProtection.baselineStartDate);

    const netEffectivePrice = this.getTermTemplateByName(TermName.NET_EFFECTIVE_PRICE) as NetEffectivePriceTerm;
    const threshold = this.getTermTemplateByName(TermName.THRESHOLD) as ThresholdTerm;
    const baselineStartDate = this.getTermTemplateByName(TermName.BASELINE_START_DATE) as BaselineStartDateTerm;

    return {
      [TermName.NET_EFFECTIVE_PRICE]: [priceProtection.netEffectivePrice.value, {
        validators: netEffectivePrice.numberValidator(),
        updateOn: 'blur'
      }],
      [TermName.BASELINE_START_DATE]: new FormControl(priceProtection.baselineStartDate.value, [
        baselineStartDate.validatorInvalidFormat(drugTermsConstants[TermName.BASELINE_START_DATE].title),
      ]),
      [TermName.INDEX]: [priceProtection.index.value, []],
      [TermName.NEW_PRICE_EFFECTIVE_DATE]: [priceProtection.newPriceEffectiveDate.value, []],
      [TermName.RESET]: [priceProtection.reset.value, []],
      [TermName.STATE]: [priceProtection.state.value, []],
      [TermName.THRESHOLD]: [priceProtection.threshold.value, {
        validators: threshold.numberValidator(),
        updateOn: 'blur'
      }],
      [TermName.BASELINE_WAC]: [priceProtection.baselineWac.value, []]
    };
  }

  public pathPriceProtectionSection(priceProtection: PriceProtection): Record<string, unknown> {
    priceProtection.baselineStartDate.value = this.getValueBasedOnAtNdcFlag(priceProtection.baselineStartDate);

    return {
      [TermName.NET_EFFECTIVE_PRICE]: priceProtection.netEffectivePrice.value,
      [TermName.BASELINE_START_DATE]: priceProtection.baselineStartDate.value,
      [TermName.INDEX]: priceProtection.index.value,
      [TermName.NEW_PRICE_EFFECTIVE_DATE]: priceProtection.newPriceEffectiveDate.value,
      [TermName.RESET]: priceProtection.reset.value,
      [TermName.STATE]: priceProtection.state.value,
      [TermName.THRESHOLD]: priceProtection.threshold.value,
      [TermName.BASELINE_WAC]: priceProtection.baselineWac.value
    };
  }

  public patchRebateFormGroup(rebate: Rebate): { [key: string]: unknown } {
    return {
      [TermName.MIN_BASE_REBATE]: rebate.minBaseRebate.value,
      [TermName.BASE_REBATE]: rebate.baseRebate.value,
      [TermName.BASE_REBATE_LOCK]: rebate.baseRebateLock,
      [TermName.TIER_PLACEMENT]: rebate.tierPlacement.value,
      [TermName.TIER_PLACEMENT_LOCK]: rebate.tierPlacementLock,
      [TermName.TIER_COMPETITION]: rebate.tierCompetition.value,
      [TermName.COMPETITION_GROUP]: rebate.competitionGroup.value,
      [TermName.COMPETITORS_TIER]: rebate.competitorsTier.value,
      [TermName.NOTES_LOCK]: rebate.notesLock,
    };
  }

  public createMarketBasketFormGroup(scenario: Scenario): { [key: string]: FormControl } {
    return {
      [TermName.MARKET_BASKET_LOCK]: new FormControl(scenario ? scenario.marketBasketLock : null),
    };
  }

  public patchMarketBasketFormGroup(scenario: Scenario): { [key: string]: unknown } {
    return {
      [TermName.MARKET_BASKET_LOCK]: scenario ? scenario.marketBasketLock : null,
    };
  }

  public marketShareSection(fb: FormBuilder, marketShare: MarketShare): { [key: string]: FormControl | FormArray } {
    const rangeOrUnitAndRebates = marketShare.rangeOrUnitAndRebates.value || [];
    const formControls = rangeOrUnitAndRebates.map((value) => fb.control(value));
    return {
      [TermName.STATE]: new FormControl(
        marketShare.state.value,
        [],
        this.marketShareService.confirmResetValueForSection()
      ),
      [TermName.MARKET_SHARE_TIER]: new FormControl(marketShare.marketShareTier.value),
      [TermName.RANGE_OR_UNITS_AND_REBATES]: fb.array([...formControls]),
    };
  }

  public editModeIsAvailableForSection(sectionName: TermSection, drug: Drug | Ndc): boolean {
    switch (sectionName) {
      case TermSection.UM: {
        return this.editModeIsAvailableForUM(drug);
      }
      case TermSection.REBATE:
      case TermSection.MARKET_BASKET:
      case TermSection.SCENARIO:
        return true;
      case TermSection.MARKET_SHARE: {
        return this.editModeIsAvailableForMarketShare(drug);
      }
      case TermSection.PRICE_PROTECTION: {
        return this.editModeIsAvailableForPriceProtection(drug);
      }
      default: {
        return false;
      }
    }
  }

  public createRequestObjectForSection(
    sectionName: TermSection,
    changes: { [key: string]: any },
    originalEntity?: any
  ): RequestObject {
    const changesCopy = CoreUtils.copyDeep(changes);
    const entityCopy = CoreUtils.copyDeep(originalEntity);

    switch (sectionName) {
      case TermSection.REBATE:
      case TermSection.SCENARIO:
      case TermSection.UM:
      case TermSection.PRICE_PROTECTION: {
        return this.createRequestObjectForEntity(changesCopy, entityCopy);
      }
      case TermSection.MARKET_SHARE: {
        return this.createRequestObjectForMarketShareSection(changesCopy, entityCopy as MarketShare);
      }
      case TermSection.MARKET_BASKET:
      default: {
        return changesCopy;
      }
    }
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  private createRequestObjectForEntity(changes: { [key: string]: any }, entity: object): object {
    Object.keys(changes).forEach((key: string) => {
      if (CoreUtils.isNotDefined(entity[key])) return;

      if (entity[key] instanceof NdcDictionaryValue) {
        entity[key] = new NdcDictionaryValue(
          changes[key] ? new DictionaryItem(changes[key].id, changes[key].name) : null,
          CoreUtils.isNull(changes[key])
        );
      } else if (entity[key] instanceof NdcDateValue) {
        entity[key] = NdcDateValue.getUtcNdcDateValue(changes[key], entity[key]);
      } else if (entity[key] instanceof NdcValue) {
        entity[key] = new NdcValue(
          BidUtils.isAtNdc(changes[key]) ? null : changes[key],
          BidUtils.isAtNdc(changes[key]) || (entity[key].isNdc && CoreUtils.isNull(changes[key])
            && !BidUtils.isAtNdc(entity[key].value))
        );

        const entityValue = entity[key].value;
        entity[key].value = CoreUtils.canBeCastToNumber(entityValue) ? Number(entityValue) : entityValue;
      } else {
        entity[key] = changes[key];
      }
    });

    return entity;
  }

  private createRequestObjectForMarketShareSection(changes: { [key: string]: any },
                                                   originalEntity: MarketShare): RequestObject {
    const marketShareTier = originalEntity.marketShareTier;
    if (changes.state && (originalEntity.state.isNdc || originalEntity.state.value.id !== changes.state.id)
      && (changes.state.id === MarketShareState.ALLOWED || changes.state.id === MarketShareState.LOCKED)) {
      delete changes[TermName.RANGE_OR_UNITS_AND_REBATES];
      delete changes[TermName.MARKET_SHARE_TIER];
    }
    if (this.shouldClearRangeOrUnitAndRebates(marketShareTier, changes.marketShareTier)) {
      originalEntity.rangeOrUnitAndRebates.value = [];
      delete changes.rangeOrUnitAndRebates;
    }
    this.createRequestObjectForEntity(changes, originalEntity);

    return originalEntity;
  }

  private editModeIsAvailableForUM(drug: Drug | Ndc): boolean {
    return !(drug.utilizationManagement.state.value
      && drug.utilizationManagement.state.value.id === UtilizationManagementState.LOCKED
      && this.userService.isCurrentUserPharma());
  }

  private editModeIsAvailableForMarketShare(data: Drug | Ndc): boolean {
    if (this.userService.isCurrentUserPayer()) return true;
    return !(data.marketShare.state.value
      && data.marketShare.state.value.id === MarketShareState.LOCKED &&
      this.userService.isCurrentUserPharma());
  }

  private editModeIsAvailableForPriceProtection(drug: Drug | Ndc): boolean {
    return !(drug.priceProtection.state.value && drug.priceProtection.state.value.id === PriceProtectionState.LOCKED
      && this.userService.isCurrentUserPharma());
  }

  private getTermTemplateByName(termName: TermName): any {
    return this.termTemplateStorage.getTermTemplate(termName);
  }

  private getValueBasedOnAtNdcFlag(ndcValue: NdcValue<any>): any {
    return BidUtils.isAtNdc(ndcValue) ? null : ndcValue.value;
  }

  private shouldClearRangeOrUnitAndRebates(tier: NdcDictionaryValue, changedTier: DictionaryItem): boolean {
    return !tier.isNdc && changedTier && tier.value && tier.value.id !== changedTier.id;
  }
}
