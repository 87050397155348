import { SelectBoxTerm } from '@qv-term/models/types';
import { constants, resources } from '@qv-common/static';
import { WholesaleAcquisitionPrice } from 'quantuvis-core-entities';

export class WholesaleAcquisitionPriceTerm extends SelectBoxTerm {
  constructor(name: string) {
    super(
      name,
      name,
      resources.TermsDescriptions.CONTRACT.WHOLESALE_ACQUISITION_PRICE,
      constants.CONTRACT_TERMS.WHOLESALE_ACQUISITION_PRICE.label,
      '',
      WholesaleAcquisitionPrice.getValue(WholesaleAcquisitionPrice.EMPTY),
      'term__wholesale-acquisition-price'
    );
    this.values = WholesaleAcquisitionPrice.getValues();
  }
}
