<div class="qv-page-toolbar bid-revision-comparison-toolbar">
  <div class="left-block">
    <qv-bid-breadcrumbs>
      <a class="bid-title"
        [routerLink]="bidViewStateService.bidView"
        [queryParams]="enrichParamsWithViewPerspective({bidId: bidId}, bid.isInternal)"
        [matTooltip]="bid.title">{{bid.title}}</a>
      <a routerLink="/bid-history"
        [queryParams]="enrichParamsWithViewPerspective({bidId: bidId}, bid.isInternal)">Bid History</a>
      <span>Revision Comparison</span>
    </qv-bid-breadcrumbs>
  </div>

  <div class="right-block">
    <qv-role-switcher
      *ngIf="bidUtils.isAvailableRoleSwitcher(bid)"
      [bid]="bid"
    ></qv-role-switcher>

    <qv-action-button
      [svgIconName]="svgIconName.ARROW_BACK"
      cssClass="toolbar-button--primary"
      [tooltip]="'Previous version'"
      [disabled]="!isPreviousVersionAvailable()"
      (buttonClick)="loadRevisionComparisonVersion(-1)">
    </qv-action-button>
    <qv-action-button
      [svgIconName]="svgIconName.ARROW_NEXT"
      cssClass="toolbar-button--primary"
      [tooltip]="'Next version'"
      [disabled]="!isNextVersionAvailable()"
      (buttonClick)="loadRevisionComparisonVersion(1)">
    </qv-action-button>

    <qv-action-button
      [routerLink]="bidViewStateService.bidView"
      [queryParams]="enrichParamsWithViewPerspective({bidId: bidId, versionId: endVersion}, bid.isInternal)"
      [title]="'Bid Revision'"
      [tooltip]="'Bid revision'"
      [svgIconName]="svgIconName.REVISION"
      cssClass="toolbar-button--primary">
    </qv-action-button>

    <qv-action-button
      [routerLink]="bidViewStateService.bidView"
      [queryParams]="enrichParamsWithViewPerspective({bidId: bidId}, bid.isInternal)"
      [title]="'Current Version'"
      [tooltip]="tooltips.BID_ACTIONS.CURRENT_VERSION"
      [svgIconName]="svgIconName.CURRENT_DOC"
      cssClass="toolbar-button--primary">
    </qv-action-button>

    <qv-bid-extra-tools [bid]="bid"></qv-bid-extra-tools>
  </div>
</div>
