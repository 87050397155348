<div
  [ngClass]="containerClass"
  class="search"
>
  <input
    matInput
    class="search__input"
    [ngClass]="inputClass"
    [placeholder]="placeholder"
    [formControl]="searchControl"
    (focus)="onSearchFocus()"
    (blur)="onSearchBlur()"
    autocomplete="off"
  >
  <div *ngIf="isLoading" class="spinner-ring spinner-ring--16 search__spinner">
    <div></div><div></div><div></div><div></div>
  </div>
  <mat-icon
    *ngIf="!isLoading"
    class="icon-search-left search__icon search__icon--search"
  ></mat-icon>
  <mat-icon
    *ngIf="isClearSearchAvailable$ | async"
    class="icon-circle-clear search__icon search__icon--clear"
    (mousedown)="onSearchClear()"
  ></mat-icon>
</div>
