import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { DomManagerService } from '@qv-shared/services';
import { InsightsDaoService } from '@qv-insights/services/dao';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'qv-embedded-dashboard-panel',
  templateUrl: './embedded-dashboard-panel.component.html',
  styleUrls: ['./embedded-dashboard-panel.component.scss']
})
export class EmbeddedDashboardPanelComponent implements OnInit, OnDestroy {
  public embeddedDashboardLink: SafeResourceUrl;
  public isEmbeddedDashboardLinkReady = false;

  constructor(private activatedRoute: ActivatedRoute,
              private insightsDaoService: InsightsDaoService,
              private sanitizer: DomSanitizer,
              private domManagerService: DomManagerService) { }

  public ngOnInit(): void {
    this.initRouteParamsChangeHandler();

    this.domManagerService.performOperationOnElements(
      ['ui-view', 'ui-view-ng-upgrade', 'qv-embedded-dashboard-panel'],
      element => {
        // add is called twice because otherwise in IE11 only the first class is being added
        element.classList.add('vertical-flex-container');
        element.classList.add('full-height');
      }
    );
    this.domManagerService.performOperationOnElements(['#content'],
      element => element.classList.add('header-padding-short'));
  }

  public ngOnDestroy(): void {
    this.domManagerService.performOperationOnElements(
      ['ui-view', 'ui-view-ng-upgrade'],
      element => {
        // remove is called twice because otherwise in IE11 only the first class is being removed
        element.classList.remove('vertical-flex-container');
        element.classList.remove('full-height');
      }
    );
    this.domManagerService.performOperationOnElements(['#content'],
      element => element.classList.remove('header-padding-short'));
  }

  private loadEmbeddedDashBoardLink(dashboardId: string): void {
    this.insightsDaoService.getEmbeddedDashboardLink(dashboardId)
      .subscribe(link => {
        this.embeddedDashboardLink = this.sanitizer.bypassSecurityTrustResourceUrl(link);
        this.isEmbeddedDashboardLinkReady = true;
        setTimeout(() => {
          this.domManagerService.performOperationOnElements(
            ['.dashboard-iframe'],
            element => {
              // add is called twice because otherwise in IE11 only the first class is being added
              element.classList.add('vertical-flex-container');
              element.classList.add('full-height');
            }
          );
        });
      });
  }

  private initRouteParamsChangeHandler(): void {
    this.activatedRoute.queryParams
      .pipe(untilDestroyed(this))
      .subscribe((params: Params) => {
        this.loadEmbeddedDashBoardLink(params.dashboardId);
      });
  }
}
