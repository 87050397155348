import { Injectable } from '@angular/core';
import { Error } from '@qv-common/interfaces';
import { PermissionService } from '@qv-common/services/auth/permission.service';
import { ViewPerspectiveService } from '@qv-common/services/auth';
import { ApiService, FileService } from '@qv-common/services/api';
import { HttpUtils } from '@qv-common/utils';
import { QuantuvisPlusFeature } from '@qv-company/enums';
import { RequestMethod } from 'quantuvis-angular-common/api';
import { Observable, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpResponseWrapper } from '@qv-common/models/api';
import { BidVersionType, DrugScenarioStatus } from '@qv-bid/enums';
import { HttpResponse } from '@angular/common/http';
import { ApiUrlPrefix } from '@qv-common/enums';
import { AuthService } from 'quantuvis-angular-common/auth';

@Injectable()
export class BidExportDaoService {

  constructor(
    private apiService: ApiService,
    private fileService: FileService,
    private permissionService: PermissionService,
    private viewPerspectiveService: ViewPerspectiveService,
    private authService: AuthService
  ) { }

  public exportExcelBidVersion(versionId: number): Observable<any> {
    const url = this.viewPerspectiveService.appendPerspectiveViewToUrl(
      `${ApiUrlPrefix.BIDS}/bid-versions/${versionId}/export`
    );
    return this.exportFileByPermission(url, QuantuvisPlusFeature.EXPORT, RequestMethod.GET);
  }

  public exportExcelBids(bidIds: number[]): Observable<any> {
    const url = `${ApiUrlPrefix.BIDS}/export`;
    return this.exportFileByPermission(url, QuantuvisPlusFeature.EXPORT, RequestMethod.POST, { ids: bidIds });
  }

  public printPdfBids(bidIds: number[], atNdc: boolean = false, withAttachments: boolean = false): Observable<HttpResponse<Blob>> {
    const url = BidExportDaoService.appendNdcAndAttachmentsParamsToUrl(
      `${ApiUrlPrefix.BIDS}/print`, atNdc, withAttachments
    );

    return this.exportFileByPermission(url, QuantuvisPlusFeature.PRINT, RequestMethod.POST, { ids: bidIds });
  }

  public exportExcelCompareBids(bidIds: number[], isOfferedBids: boolean): Observable<any> {
    const url = `${ApiUrlPrefix.BIDS}/compare`;
    return this.exportFileByPermission(url, QuantuvisPlusFeature.EXPORT, RequestMethod.POST,
      { bidIds, isOfferedBids });
  }

  public exportPdfBidVersion(versionId: number, atNdc: boolean = false, withAttachments: boolean = false): Observable<any> {
    const url = BidExportDaoService.appendNdcAndAttachmentsParamsToUrl(
      this.viewPerspectiveService.appendPerspectiveViewToUrl(`${ApiUrlPrefix.BIDS}/bid-versions/${versionId}/print`),
      atNdc, withAttachments);
    return this.exportFileByPermission(url, QuantuvisPlusFeature.PRINT, RequestMethod.POST);
  }

  public exportPdfRevisionComparison(
    bidId: number, startVersion: number, endVersion: number, ndcLevel: boolean, isInternalBid: boolean
  ): Observable<any> {
    const perspective = this.viewPerspectiveService.getViewPerspective();
    let url = `${ApiUrlPrefix.BIDS}/${bidId}/bid-versions/${startVersion}/compare/${endVersion}/print`;

    url = HttpUtils.appendPerspectiveQueryParamToUrlIfBidIsInternal(url, perspective, isInternalBid);
    url = HttpUtils.appendQueryParamToUrl(url, 'atNdc', String(ndcLevel));

    return this.exportFileByPermission(url, QuantuvisPlusFeature.PRINT, RequestMethod.POST);
  }

  public exportExcelBidVersionsByType(bidId: number, isInternalBid: boolean,
                                      bidVersionType: BidVersionType): Observable<any> {
    let url = `${ApiUrlPrefix.BIDS}/${bidId}/bid-versions/export?type=${bidVersionType}`;

    const perspective = this.viewPerspectiveService.getViewPerspective();

    url = HttpUtils.appendPerspectiveQueryParamToUrlIfBidIsInternal(url, perspective, isInternalBid);

    return this.exportFileByPermission(url, QuantuvisPlusFeature.EXPORT);
  }

  public exportFileByPermission(url: string, feature?: string, method?: RequestMethod, body?: any): Observable<any> {
    if (!feature || this.permissionService.isFeatureAllowed(feature)) {
      return this.downloadFile(url, method, body);
    }
    return throwError({
      error: true,
      message: `Not allowed: ${feature}`
    } as Error);
  }

  public downloadFile(url: string, method?: RequestMethod, body?: any): Observable<any> {
    url = this.authService.appendSecureInfoToUrl(url);
    return this.fileService.downloadFile(url, method, body);
  }

  public downloadLetterOfIntent(bidId: number): Observable<any> {
    const url = `${ApiUrlPrefix.BIDS}/${bidId}/generate-loi`;
    return this.downloadFile(url, RequestMethod.POST);
  }

  public generateScenarioSummary(version: number, availableDrugScenarioStatuses: string[]): Observable<any> {
    const url = `${ApiUrlPrefix.BIDS}/bid-versions/${version}/generate-scenario-summary`;
    return this.fileService.downloadFile(url, RequestMethod.POST, availableDrugScenarioStatuses);
  }

  public getAvailableDrugScenarioStatuses(bidVersionId: number): Observable<DrugScenarioStatus[]> {
    const url = `${ApiUrlPrefix.BIDS}/bid-versions/${bidVersionId}/available-drug-statuses`;
    return this.apiService.get(url).pipe(map((response: HttpResponseWrapper<DrugScenarioStatus[]>) => {
      if (response.body && response.body.data) {
        return response.body.data;
      }
      BidExportDaoService.handleResponseError();
    }));
  }

  private static appendNdcAndAttachmentsParamsToUrl(url: string, atNdc: boolean = false, withAttachments: boolean = false): string {
    url = HttpUtils.appendQueryParamToUrl(url, 'atNdc', String(atNdc));
    url = HttpUtils.appendQueryParamToUrl(url, 'withAttachments', String(withAttachments));
    return url;
  }

  private static handleResponseError(): void {
    throw Error('Response is not valid');
  }
}
