/* eslint-disable no-underscore-dangle,@typescript-eslint/member-ordering */
import { FocusMonitor } from '@angular/cdk/a11y';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, NgControl } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { RangeOrUnitAndRebate } from '@qv-bid/entities/range-or-unit-and-rebates.entity';
import { SvgIconName } from '@qv-common/enums';
import { ValidateNumberOptions } from '@qv-common/models';
import { NumberUtils } from '@qv-common/utils';
import { Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'qv-range-rebate-input',
  templateUrl: './range-rebate-input.component.html',
  styleUrls: ['./range-rebate-input.component.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: RangeRebateInputComponent }],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    '[class.range-rebate--floating]': 'shouldLabelFloat',
    '[id]': 'id',
  }
})
export class RangeRebateInputComponent implements MatFormFieldControl<RangeOrUnitAndRebate>, OnInit, OnDestroy {
  public static nextId = 0;

  @Input()
  public rangePrefix: string;
  @Input()
  public rangeSuffix: string;
  @Input()
  public rangeValidationOptions: ValidateNumberOptions;
  @Input()
  public rebateValidationOptions: ValidateNumberOptions;
  @Input()
  public isBidInvalid: boolean;

  @Output()
  public valueUpdated = new EventEmitter<RangeOrUnitAndRebate>();

  @Output()
  public valueRemoved = new EventEmitter<number>();

  @ViewChild('rangeStart', { read: ElementRef })
  public rangeStart: ElementRef;
  @ViewChild('rangeEnd', { read: ElementRef })
  public rangeEnd: ElementRef;
  @ViewChild('rebate', { read: ElementRef })
  public rebate: ElementRef;

  public focused = false;
  public parts: FormGroup;
  public errorState = false;
  public id = `range-rebate-form__input--${RangeRebateInputComponent.nextId++}`;
  public ngControl: NgControl = null;
  public stateChanges = new Subject<void>();
  public describedBy = '';
  public readonly svgIconName = SvgIconName;

  constructor(
    private fb: FormBuilder,
    private fm: FocusMonitor,
    private elRef: ElementRef<HTMLElement>,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
    this.parts = this.fb.group({
      rangeStart: new FormControl(null, { updateOn: 'blur' }),
      rangeEnd: new FormControl(null, { updateOn: 'blur' }),
      rebate: new FormControl(null, { updateOn: 'blur' }),
      id: null,
    });

    fm.monitor(elRef.nativeElement, true)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.focused = this.isInputFocused();

        if (!this.focused) {
          this.stateChanges.next();
        }
        this.changeDetectorRef.markForCheck();
      });

    this.stateChanges
      .pipe(untilDestroyed(this))
      .subscribe(() => setTimeout(() => this.parts.dirty && this.valueUpdated.emit(this.value)));
  }

  private _required = false;

  @Input()
  get required(): boolean {
    return this._required;
  }

  set required(value: boolean) {
    this._required = Boolean(value);
  }

  private _placeholder: string;

  @Input()
  get placeholder(): string {
    return this._placeholder;
  }

  set placeholder(value: string) {
    this._placeholder = value;
  }

  private _disabled: boolean;

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }

  set disabled(value: boolean) {
    this._disabled = Boolean(value);
    this._disabled ? this.parts.disable() : this.parts.enable();
  }

  @Input()
  get value(): RangeOrUnitAndRebate | null {
    const { value: { rangeStart, rangeEnd, rebate, id } } = this.parts;
    return new RangeOrUnitAndRebate(rangeStart, rangeEnd, rebate, id);
  }

  set value(rangeRebate: RangeOrUnitAndRebate | null) {
    const { rangeStart, rangeEnd, rebate, id } = rangeRebate || new RangeOrUnitAndRebate(null, null, null);
    this.parts.setValue(new RangeOrUnitAndRebate(rangeStart, rangeEnd, rebate, id));
    setTimeout(() => {
      this.onResizeInput(this.rangeStart.nativeElement);
      this.onResizeInput(this.rangeEnd.nativeElement);
      this.onResizeInput(this.rebate.nativeElement);
    });
  }

  public get shouldLabelFloat(): boolean {
    return this.focused;
  }

  public get empty(): boolean {
    const { value: { rangeStart, rangeEnd, rebate } } = this.parts;
    return !(rangeStart || rangeEnd || rebate);
  }

  public ngOnInit(): void {
    this.parts.controls.rangeStart.setValidators(NumberUtils.numberValidator(this.rangeValidationOptions));
    this.parts.controls.rangeEnd.setValidators(NumberUtils.numberValidator(this.rangeValidationOptions));
    this.parts.controls.rebate.setValidators(NumberUtils.numberValidator(this.rebateValidationOptions));
  }

  public ngOnDestroy(): void {
    this.stateChanges.complete();
    this.fm.stopMonitoring(this.elRef.nativeElement);
  }

  public onContainerClick(event: MouseEvent): void {
    if ((event.target as Element).tagName.toLowerCase() !== 'input') {
      this.elRef.nativeElement.querySelector('input')?.focus();
    }
  }

  public setDescribedByIds(ids: string[]): void {
    this.describedBy = ids.join(' ');
  }

  public onResizeInput(element: HTMLInputElement): void {
    element.style.width = `${element.value.length + 1}ch`;
  }

  public isInputFocused(): boolean {
    return [this.rangeStart, this.rangeEnd, this.rebate]
      .some((el: ElementRef) => el.nativeElement === document.activeElement);
  }

  public onRemoveClick(event: Event): void {
    event.stopPropagation();
    this.valueRemoved.emit(this.parts.value.id);
  }
}
