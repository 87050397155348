import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { FeedbackDaoService } from '@qv-terms-and-conditions/services';
import { BaseInsideFormModal } from 'quantuvis-angular-common/modal';

@Component({
  selector: 'qv-report-missing-drug',
  templateUrl: './report-missing-drug.component.html',
  styleUrls: ['../styles/report-modal.scss', './report-missing-drug.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReportMissingDrugComponent extends BaseInsideFormModal {
  public readonly hasAsyncAction = true;
  public readonly form = new FormGroup({
    message: new FormControl('', {
      validators: [
        Validators.required.bind(this),
      ]
    }),
  });

  constructor(
    private readonly feedbackDaoService: FeedbackDaoService,
  ) {
    super();
  }

  public onSubmit(): Observable<any> {
    const data = {
      drugsList: this.form.get('message').value,
    };

    return this.feedbackDaoService.reportMissingDrug(data);
  }

  public isShowError(): boolean {
    return this.form.get('message').invalid && this.form.get('message').dirty;
  }
}
