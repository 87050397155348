import { Injectable } from '@angular/core';

import { drugTermsConstants } from '@qv-term/constants';
import { TermName } from '@qv-term/enums';
import { TermFactory } from './term.factory';
import { AdministrationFeeTerm } from '@qv-term/models/payment';
import {
  BaselineStartDateTerm,
  BaseLineWACTerm,
  BaseRebateTerm,
  BasketTerm,
  CompetitionGroupTerm,
  CompetitorsTierTerm,
  ContractEndDateTerm,
  ContractStartDateTerm,
  IndexTerm,
  MarketShareTierTerm,
  MinimumBaseRebateTerm,
  NetEffectivePriceTerm,
  NewPriceEffectiveDateTerm,
  NotesTerm,
  OtherUmTerm,
  PriorAuthorizationAllowedProductTerm,
  PriorAuthorizationRequiredCompetitorsProductTerm,
  QuantityLimitTerm,
  RangeOrUnitsAndRebatesTerm,
  ResetTerm,
  StepTherapyAllowedProductTerm,
  StepTherapyCompetitorsProductTerm,
  ThresholdTerm,
  TierCompetitionTerm,
  TierPlacementTerm,
  UmDetailsTerm
} from '@qv-term/models/drug';


@Injectable()
export class DrugListFactory implements TermFactory {
  private drugListTerms = new Map<string, any>();

  public buildTerms(force?: boolean): Map<string, any> {
    if (force || this.drugListTerms.size === 0) {
      this.drugListTerms.set(TermName.MIN_BASE_REBATE,
        new MinimumBaseRebateTerm(drugTermsConstants[TermName.MIN_BASE_REBATE].title));
      this.drugListTerms.set(TermName.BASE_REBATE,
        new BaseRebateTerm(drugTermsConstants[TermName.BASE_REBATE].title));
      this.drugListTerms.set(TermName.TIER_PLACEMENT,
        new TierPlacementTerm(drugTermsConstants[TermName.TIER_PLACEMENT].title));
      this.drugListTerms.set(TermName.TIER_COMPETITION,
        new TierCompetitionTerm(drugTermsConstants[TermName.TIER_COMPETITION].title));
      this.drugListTerms.set(TermName.COMPETITORS_TIER,
        new CompetitorsTierTerm(drugTermsConstants[TermName.COMPETITORS_TIER].title));
      this.drugListTerms.set(TermName.PRIOR_AUTHORIZATION_REQUIRED_ON_COMPETITORS_PRODUCTS,
        new PriorAuthorizationRequiredCompetitorsProductTerm(
          drugTermsConstants[TermName.PRIOR_AUTHORIZATION_REQUIRED_ON_COMPETITORS_PRODUCTS].title));
      this.drugListTerms.set(TermName.PRIOR_AUTHORIZATION_ALLOWED_ON_PRODUCT,
        new PriorAuthorizationAllowedProductTerm(
          drugTermsConstants[TermName.PRIOR_AUTHORIZATION_ALLOWED_ON_PRODUCT].title));
      this.drugListTerms.set(TermName.MARKET_SHARE_TIER,
        new MarketShareTierTerm(drugTermsConstants[TermName.MARKET_SHARE_TIER].title));
      this.drugListTerms.set(TermName.RANGE_OR_UNITS_AND_REBATES,
        new RangeOrUnitsAndRebatesTerm(drugTermsConstants[TermName.RANGE_OR_UNITS_AND_REBATES].title));
      this.drugListTerms.set(TermName.MARKET_BASKET,
        new BasketTerm(drugTermsConstants[TermName.MARKET_BASKET].title));
      this.drugListTerms.set(TermName.NET_EFFECTIVE_PRICE,
        new NetEffectivePriceTerm(drugTermsConstants[TermName.NET_EFFECTIVE_PRICE].title));
      this.drugListTerms.set(TermName.INDEX,
        new IndexTerm(drugTermsConstants[TermName.INDEX].title));
      this.drugListTerms.set(TermName.THRESHOLD,
        new ThresholdTerm(drugTermsConstants[TermName.THRESHOLD].title));
      this.drugListTerms.set(TermName.RESET,
        new ResetTerm(drugTermsConstants[TermName.RESET].title));
      this.drugListTerms.set(TermName.BASELINE_START_DATE,
        new BaselineStartDateTerm(drugTermsConstants[TermName.BASELINE_START_DATE].title));
      this.drugListTerms.set(TermName.BASELINE_WAC,
        new BaseLineWACTerm(drugTermsConstants[TermName.BASELINE_WAC].title));
      this.drugListTerms.set(TermName.NEW_PRICE_EFFECTIVE_DATE,
        new NewPriceEffectiveDateTerm(drugTermsConstants[TermName.NEW_PRICE_EFFECTIVE_DATE].title));
      this.drugListTerms.set(TermName.STEP_THERAPY_REQUIRED_ON_COMPETITORS_PRODUCTS,
        new StepTherapyCompetitorsProductTerm(
          drugTermsConstants[TermName.STEP_THERAPY_REQUIRED_ON_COMPETITORS_PRODUCTS].title));
      this.drugListTerms.set(TermName.STEP_THERAPY_ALLOWED_ON_PRODUCT,
        new StepTherapyAllowedProductTerm(drugTermsConstants[TermName.STEP_THERAPY_ALLOWED_ON_PRODUCT].title));
      this.drugListTerms.set(TermName.QUANTITY_LIMIT,
        new QuantityLimitTerm(drugTermsConstants[TermName.QUANTITY_LIMIT].title));
      this.drugListTerms.set(TermName.OTHER_UM,
        new OtherUmTerm(drugTermsConstants[TermName.OTHER_UM].title));
      this.drugListTerms.set(TermName.UM_DETAILS,
        new UmDetailsTerm(drugTermsConstants[TermName.UM_DETAILS].title));
      this.drugListTerms.set(TermName.NOTES,
        new NotesTerm(drugTermsConstants[TermName.NOTES].title));
      this.drugListTerms.set(TermName.SCENARIO_START_DATE,
        new ContractStartDateTerm(drugTermsConstants[TermName.SCENARIO_START_DATE].title));
      this.drugListTerms.set(TermName.SCENARIO_END_DATE,
        new ContractEndDateTerm(drugTermsConstants[TermName.SCENARIO_END_DATE].title));
      this.drugListTerms.set(TermName.ADMINISTRATION_FEE,
        new AdministrationFeeTerm(drugTermsConstants[TermName.ADMINISTRATION_FEE].title));
      this.drugListTerms.set(TermName.COMPETITION_GROUP,
        new CompetitionGroupTerm(drugTermsConstants[TermName.COMPETITION_GROUP].title));

      return this.drugListTerms;
    }
  }
}
