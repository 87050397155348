import { DictionaryItem } from '@qv-common/entities';

export enum LineOfBusiness {
  MEDICARE = 1,
  MEDICAID = 2,
  COMMERCIAL = 3,
  EXCHANGES = 4,
  MEDICAL = 5,
}

export namespace LineOfBusiness {
  const valuesMap = new Map<LineOfBusiness, DictionaryItem>([
    [LineOfBusiness.MEDICARE, { id: LineOfBusiness.MEDICARE, name: 'Medicare' }],
    [LineOfBusiness.MEDICAID, { id: LineOfBusiness.MEDICAID, name: 'Medicaid' }],
    [LineOfBusiness.COMMERCIAL, { id: LineOfBusiness.COMMERCIAL, name: 'Commercial' }],
    [LineOfBusiness.EXCHANGES, { id: LineOfBusiness.EXCHANGES, name: 'Exchanges' }],
    [LineOfBusiness.MEDICAL, { id: LineOfBusiness.MEDICAL, name: 'Medical' }],
  ]);

  export function getValue(lineOfBusiness: LineOfBusiness): DictionaryItem {
    return valuesMap.get(lineOfBusiness);
  }

  export function getValues(): DictionaryItem[] {
    return Array.from(valuesMap.values());
  }
}
