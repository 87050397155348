import { Injectable } from '@angular/core';
import { TermsAndConditionsEventBusService } from './terms-and-conditions-event-bus.service';
import {
  ContactUsModalComponent,
  OpenSourceSoftwareComponent,
  PrivacyPolicyComponent,
  TermsOfServiceComponent
} from '@qv-terms-and-conditions/components';
import { resources } from '@qv-common/static';
import { ModalService, TemplateFormModalData, TemplateModalData } from 'quantuvis-angular-common/modal';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Injectable()
export class TermsAndConditionsService {
  constructor(
    private readonly modalService: ModalService,
    private readonly termsAndConditionsEventBusService: TermsAndConditionsEventBusService
  ) {}

  public init(): void {
    this.termsAndConditionsEventBusService.openTermsOfServiceModal$
      .pipe(untilDestroyed(this))
      .subscribe(() => this.openTermsOfServiceModal());
    this.termsAndConditionsEventBusService.openOpenSourceSoftwareModal$
      .pipe(untilDestroyed(this))
      .subscribe(() => this.openOpenSourceSoftwareModal());
    this.termsAndConditionsEventBusService.openPrivacyPolicyModal$
      .pipe(untilDestroyed(this))
      .subscribe(() => this.openPrivacyPolicyModal());
    this.termsAndConditionsEventBusService.openContactUsModal$
      .pipe(untilDestroyed(this))
      .subscribe((subject: string) => this.openContactUsModal(subject));
  }

  private openTermsOfServiceModal(): void {
    this.modalService.openTemplateModal(new TemplateModalData('Terms of Use', TermsOfServiceComponent));
  }

  private openOpenSourceSoftwareModal(): void {
    this.modalService.openTemplateModal(new TemplateModalData('Open Source Software', OpenSourceSoftwareComponent));
  }

  private openPrivacyPolicyModal(): void {
    this.modalService.openTemplateModal(new TemplateModalData('Privacy Policy', PrivacyPolicyComponent));
  }

  private openContactUsModal(subject: string = ''): void {
    this.modalService.openTemplateFormModal(new TemplateFormModalData(
      'Contact Quantuvis',
      ContactUsModalComponent,
      { subject },
      resources.Actions.SEND,
      resources.Actions.CLOSE,
    ));
  }
}
