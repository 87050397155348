import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { BidCommentsService } from '@qv-bid/services';
import { CommonMessage } from '@qv-common/enums';
import { BidComment } from '@qv-bid/entities/bid-comment';
import { BidCommentsDaoService } from '@qv-bid/services/dao';
import { FormControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CompanyUtils } from '@qv-company/utils';
import { TermName } from '@qv-term/enums';
import { QvCache } from '@qv-common/decorators';
import { Summary } from '@qv-bid/entities';
import { SummaryService } from '@qv-bid/services/summary';
import { SnackBarService } from 'quantuvis-angular-common/snack-bar';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { BidEventBusService } from '@qv-bid/services/bid-event-bus.service';
import { BidStatus } from 'quantuvis-core-entities';
import { appConfig } from '@qv-common/configs';
import { HttpErrorResponse } from '@angular/common/http';
import { UserService } from '@qv-common/services/auth/user.service';

@UntilDestroy()
@Component({
  selector: 'qv-bid-comments',
  templateUrl: './bid-comments.component.html',
  styleUrls: ['./bid-comments.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [BidCommentsService]
})
export class BidCommentsComponent implements OnInit, OnChanges {
  @Input()
  public summary: Summary;
  @Input()
  public bidStatus: BidStatus;
  @Input()
  public manufacturerCompanyOldName: string;
  @Input()
  public isEditMode: boolean;
  @Input()
  public isBidInvalid: boolean;
  @Input()
  public isBidInternal: boolean;
  @Output()
  public commentsLoaded = new EventEmitter();

  public isUserPharma: boolean;
  public comments: BidComment[];
  public commentForSend: BidComment;
  public commentsLockControl: FormControl;

  public readonly commonMessage = CommonMessage;
  public readonly dateFormat = appConfig.dateFormat;

  constructor(
    private bidEventBusService: BidEventBusService,
    private bidCommentsDaoService: BidCommentsDaoService,
    private summaryService: SummaryService,
    private userService: UserService,
    private changeDetectorRef: ChangeDetectorRef,
    private snackBarService: SnackBarService,
  ) {}

  public ngOnInit(): void {
    this.isUserPharma = this.userService.isCurrentUserPharma();
    this.loadComments();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.summary && !changes.summary.firstChange) {
      this.loadComments();
    }
  }

  public isCommentFromOwnCompany(companyId: number): boolean {
    return this.userService.user.getValue().company.id === companyId;
  }

  public onCommentChange(comment: BidComment): void {
    this.commentForSend = comment;
    this.bidEventBusService.undoRedoEvent.emit();
  }

  public isDeleteHistoricAvailable(): boolean {
    return Boolean(
      this.isEditMode &&
      this.bidStatus === BidStatus.RFP_NOT_SENT &&
      this.comments &&
      this.comments.length
    );
  }

  public onDeleteHistoric(): void {
    this.snackBarService.start();

    this.bidCommentsDaoService.deleteHistoricComments(this.summary.bidVersionId, this.summary.id, this.isBidInternal)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.snackBarService.error();
          return throwError(error);
        })
      )
      .subscribe(() => {
        this.snackBarService.finish();
        this.bidEventBusService.undoRedoEvent.emit();
        this.loadComments();
      });
  }

  @QvCache()
  public shouldShowCommentToolbar(isUserPharma: boolean, isLocked: boolean): boolean {
    return !isUserPharma || (isUserPharma && Boolean(isLocked));
  }

  @QvCache()
  public shouldShowCommentField(isUserPharma: boolean, isLocked: boolean): boolean {
    return !isUserPharma || (isUserPharma && !Boolean(isLocked));
  }

  @QvCache()
  public getCompanyFullName(companyName: string, isPharma: boolean, oldCompanyName?: string): string {
    return CompanyUtils.getCompanyFullName(companyName, isPharma, oldCompanyName);
  }

  private static getSortedHistoricComments(comments: BidComment[]): BidComment[] {
    return comments
      .filter((comment: BidComment) => comment.isHistoric)
      .sort(BidCommentsService.sortCommentHandler.bind(this));
  }

  private static getCommentForSend(comments: BidComment[]): BidComment {
    return comments.find((comment: BidComment) => !comment.isHistoric);
  }

  private loadComments(): void {
    this.bidCommentsDaoService.getComments(this.summary.bidVersionId, this.summary.id)
      .subscribe((comments: BidComment[]) => {
        this.comments = BidCommentsComponent.getSortedHistoricComments(comments);
        this.commentForSend = BidCommentsComponent.getCommentForSend(comments);
        this.initCommentsLockControl();
        this.commentsLoaded.emit();
        this.changeDetectorRef.markForCheck();
      });
  }

  private initCommentsLockControl(): void {
    this.commentsLockControl = new FormControl(this.summary.commentsLock);
    this.subscribeOnCommentsLockChanges();
  }

  private subscribeOnCommentsLockChanges(): void {
    this.commentsLockControl.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((newCommentsLockState: boolean) => {
        this.updateSummaryInfoWithCommentsLock(newCommentsLockState);
      });
  }

  private updateSummaryInfoWithCommentsLock(commentsLock: boolean): void {
    const updatedSummary: Summary = Object.assign(
      new Summary(),
      this.summary,
      { [TermName.COMMENTS_LOCK]: commentsLock }
    );

    this.snackBarService.start();
    this.summaryService
      .updateSummaryInfo(this.summary.bidVersionId, this.summary.id, updatedSummary)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.snackBarService.error();
          return throwError(error);
        })
      )
      .subscribe((summary: Summary) => {
        this.summary = summary;
        this.bidEventBusService.undoRedoEvent.emit();
        this.changeDetectorRef.markForCheck();
        this.snackBarService.finish();
      });
  }
}
