import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ChangeDetectorRef
} from '@angular/core';
import { BidAttachment } from '@qv-bid/entities';
import { SvgIconName } from '@qv-common/enums';
import { FileService } from '@qv-common/services/api';
import { resources } from '@qv-common/static/resources';
import { Summary } from '@qv-bid/entities/summary.entity';
import { BidAttachmentType } from '@qv-bid/enums';
import { SummaryDaoService } from '@qv-bid/services/dao';
import { ApiUrlPrefix } from '@qv-common/enums';

@Component({
  selector: 'qv-bid-legal-attestation',
  templateUrl: './bid-legal-attestation.component.html',
  styleUrls: ['./bid-legal-attestation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BidLegalAttestationComponent implements OnInit, OnChanges {
  @Input()
  public summary: Summary;

  public legalAttestation: BidAttachment;

  public readonly svgIconName = SvgIconName;
  public readonly resources = resources;

  constructor(
    private summaryDaoService: SummaryDaoService,
    private fileService: FileService,
    private changeDetectorRef: ChangeDetectorRef,
  ) { }

  public ngOnInit(): void {
    this.loadLegalAttestation();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (!changes.summary.firstChange && changes.summary.currentValue) {
      this.loadLegalAttestation();
    }
  }

  public downloadLegalAttestationFile(): void {
    const url = `${ApiUrlPrefix.BIDS}/summaries/${this.summary.id}/`
          + `bid-attachments/${this.legalAttestation.id}/download`;
    this.fileService.downloadFile(url).subscribe();
  }

  private loadLegalAttestation(): void {
    this.summaryDaoService.getBidAttachmentsBySummaryId(this.summary.id, BidAttachmentType.LEGAL_ATTESTATION)
      .subscribe((bidAttachments: BidAttachment[]) => {
        this.legalAttestation = bidAttachments[0];
        this.changeDetectorRef.markForCheck();
      });
  }
}
