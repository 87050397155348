import { CompanyType } from '../enums';
import { JsonObject, JsonProperty } from 'json2typescript';

// WARNING: RN specific entity. Use CompanyBase for new features
@JsonObject('Company')
export class Company {
  @JsonProperty('id', Number)
  public id: number = null;

  @JsonProperty('name', String)
  public name: string = null;

  @JsonProperty('companyType', String, true)
  public companyType?: CompanyType = null;

  @JsonProperty('type', String, true)
  public type?: CompanyType = null;

  public isPharma(): boolean {
    return this.companyType === CompanyType.PHARMA || this.type === CompanyType.PHARMA;
  }
}
