declare let $: any;

export const ValidationInput = ['util', 'BidDetailsService', (util, BidDetailsService): any => {
  'ngInject';
  return {
    restrict: 'A',
    require: 'ngModel',
    link: (scope, element, attr, ngModel) => {
      if (!ngModel) return;
      const elem = $(element[0]);
      const customValidation = attr.customValidation;

      elem.on('blur', () => {
        let nextValue = ngModel.$viewValue;
        const prevValue = ngModel.$modelValue;

        if (scope.term.definition.validateValue || customValidation) {
          let validator = scope.term.definition.validateValue;

          if (customValidation && scope[customValidation]) {
            validator = util.getNumericValidation(scope[customValidation]);
          }

          nextValue = validator(nextValue);
          // triple === important in order to check for the type (to be able to set also empty values)
          if (nextValue === false) {
            ngModel.$setViewValue(prevValue);
            ngModel.$render();
            return;
          } else {
            ngModel.$setViewValue(nextValue);
            ngModel.$render();
          }
        }

        BidDetailsService.disableBidActions(prevValue, nextValue);
      });
    }
  };
}];
