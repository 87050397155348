import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { PermissionService } from '@qv-common/services/auth/permission.service';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PayerWriteAccessGuard implements CanActivate {

  constructor(private permissionService: PermissionService, private router: Router) {}

  public canActivate(): Observable<boolean> {
    return of(this.permissionService.isPayerHasWriteAccess()).pipe(tap((hasWriteAccess: boolean) => {
      if (!hasWriteAccess) {
        this.router.navigate(['/dashboard']);
      }
    }));
  }
}
