import { HttpResponse } from '@angular/common/http';
import { JsonConvert } from 'json2typescript';
import { Observable } from 'rxjs';
import { ApiService } from '@qv-common/services/api';
import { Inject, Injectable } from '@angular/core';
import { ApiUrlPrefix } from '@qv-common/enums';
import { map } from 'rxjs/operators';
import { HttpResponseWrapper } from '@qv-common/models/api';
import { JSON_CONVERT_TOKEN } from 'quantuvis-angular-common/json-converter/services';
import { UserState } from '@qv-user/entities/user-state.entity';
import { User } from '@qv-user/entities/user.entity';

@Injectable({
  providedIn: 'root'
})
export class UserDaoService {

  constructor(private apiService: ApiService, @Inject(JSON_CONVERT_TOKEN) private jsonConvert: JsonConvert) {}

  public getUserData(): Observable<UserState> {
    return this.apiService.get(`${ApiUrlPrefix.OLD}/user/getUserData`).pipe(
      map((response: HttpResponseWrapper<UserState>) =>
        this.jsonConvert.deserializeObject(response.body, UserState)
      ));
  }

  public filterByMyAccounts(flag: boolean): Observable<User> {
    return this.apiService.post(`${ApiUrlPrefix.OLD}/user/filterByMyAccounts`, flag).pipe(
      map((response: HttpResponseWrapper<User>) =>
        this.jsonConvert.deserializeObject(response.body.responseObject, User)
      ));
  }

  public disable(id: number): Observable<any> {
    return this.apiService.get(`${ApiUrlPrefix.OLD}/user/disable/${id}`)
      .pipe(map((data: HttpResponse<any>) => data.body));
  }

  public enable(id: number): Observable<any> {
    return this.apiService.get(`${ApiUrlPrefix.OLD}/user/enable/${id}`)
      .pipe(map((data: HttpResponse<any>) => data.body));
  }
}
