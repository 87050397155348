import { resources } from '@qv-common/static';
import { SelectBoxTerm } from '@qv-term/models/types';
import { MarketShareTier } from 'quantuvis-core-entities';

export class MarketShareTierTerm extends SelectBoxTerm {
  constructor(name: string) {
    super(
      name,
      name,
      resources.TermsDescriptions.DRUG_LIST.MARKET_SHARE_TIER,
      '',
      '',
      MarketShareTier.getValue(MarketShareTier.EMPTY),
      'term__market-share-tier'
    );

    this.values = MarketShareTier.getValues();
  }
}
