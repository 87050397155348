import { MatDialogRef } from '@angular/material/dialog';
import { Directive, EventEmitter, Input, Output } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { BidEventBusService } from '@qv-bid/services/bid-event-bus.service';
import { ErrorNotificationService } from '@qv-common/services';
import { HttpStatusCode } from 'quantuvis-angular-common/api';
import { Observable } from 'rxjs';
import { QvCache } from '@qv-common/decorators';
import { MarketBasketModalComponent } from '@qv-bid/components/market-basket/market-basket-modal/market-basket-modal.component';
import { MarketBasketModalOutput } from './market-basket-modal-output';
import { MarketBasketModalState } from '@qv-bid/enums';
import { MarketBasket } from '@qv-bid/entities';

@Directive()
export class MarketBasketModal {
  @Input()
  public state: MarketBasketModalState;
  @Input()
  public marketBasket: MarketBasket;
  @Input()
  public scenarioId: number;
  @Input()
  public drugName: string;
  @Input()
  public drugClass: string;
  @Input()
  public isNdcLevel: boolean;
  @Input()
  public isEditMode: boolean;
  @Input()
  public isBidInternal: boolean;

  @Output()
  public primaryAction = new EventEmitter<MarketBasketModalOutput>();

  constructor(
    protected dialogRef: MatDialogRef<MarketBasketModalComponent>,
    protected errorNotificationService: ErrorNotificationService,
    protected bidEventBusService: BidEventBusService
  ) {}

  public onClose(): void {
    this.dialogRef.close();
  }

  @QvCache()
  public isEditable(isEditMode: boolean, isNdcLevel: boolean): boolean {
    return isEditMode && !isNdcLevel;
  }

  protected onSaveError(errorResponse: HttpErrorResponse, customMessage?: string): Observable<HttpErrorResponse> {
    this.bidEventBusService.updateBidFailed.emit(errorResponse);

    return this.errorNotificationService
      .catchErrorExceptOfStatus(errorResponse, HttpStatusCode.FORBIDDEN, customMessage);
  }
}
