import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker'; // TODO: avoid this dependency
import moment, { Moment } from 'moment';
import { formats } from '../constants/formats';

// @dynamic
export class DateUtils {
  public static readonly MIN_DATE_PICKER_YEAR = 2000;
  public static readonly MAX_DATE_PICKER_YEAR = 2099;

  public static getMinDatePickerDate(): Date {
    return new Date(DateUtils.MIN_DATE_PICKER_YEAR, 0, 1);
  }

  public static getMaxDatePickerDate(): Date {
    return new Date(DateUtils.MAX_DATE_PICKER_YEAR, 11, 31);
  }

  public static convertDateToStr(
    event: MatDatepickerInputEvent<Moment>, control: AbstractControl, dateFormat = formats.dateFormat): string {
    let dateStr = '';

    if (moment.isMoment(event.value)) {
      dateStr = event.value.format(dateFormat);
    } else {
      const rawInputValue = (event.targetElement as HTMLInputElement).value;
      if (rawInputValue === '') {
        control.setErrors(null, { emitEvent: false });
        control.setValue(null);

        return null;
      }
      dateStr = DateUtils.autoCorrectDate(rawInputValue, control, dateFormat);
    }

    return dateStr;
  }

  public static autoCorrectDate(dateStr: string, control: AbstractControl, dateFormat = formats.dateFormat): string {
    const momentDate = moment(dateStr, dateFormat);

    if (momentDate.isValid()) {
      return momentDate.format(dateFormat);
    }
    if (moment.isMoment(control.value) && control.value.isValid()) {
      return control.value.format(dateFormat);
    }

    return dateStr;
  }

  public static convertDateToUTC(date: Moment): Moment {
    return moment.isMoment(date) ? date.utc(true) : null;
  }

  public static startDateBeforeEndDateValidator(
    controlKey: string,
    startDateKey = 'startDate',
    endDateKey = 'endDate'
  ): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.parent) {
        return null;
      }

      const controls = control.parent.controls as AbstractControl[];
      const startDate = controls[startDateKey].value;
      const endDate = controls[endDateKey].value;

      if (startDate && endDate && startDate > endDate) {
        return { startDateBeforeEndDate: true };
      }

      if (control.valid) {
        const siblingControl = controlKey === startDateKey ? controls[endDateKey] : controls[startDateKey];

        DateUtils.updateControlValidityByError(siblingControl, 'startDateBeforeEndDate', true);
      }

      return null;
    };
  }

  public static updateControlValidityByError(control: AbstractControl, errorKey: string, errorValue: any): void {
    if (control.invalid && control.errors[errorKey] === errorValue) {
      control.updateValueAndValidity({ onlySelf: true });
    }
  }

  public static inRange(date: Moment, fromDate: Moment, toDate: Moment): boolean {
    return moment.isMoment(date) && moment.isMoment(fromDate) && moment.isMoment(toDate)
      ? date.isBetween(fromDate, toDate, 'days', '[]')
      : false;
  }
}
