import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { CoreUtils } from '@qv-common/utils/core.utils';
import { StringUtils } from '@qv-common/utils/string.utils';

export class ValidationUtils {
  public static rangeGreaterOrEqualValidator(rangeStartControlName: string, rangeEndControlName: string): ValidatorFn {
    return (control: AbstractControl) => {
      if (!control.parent) {
        return null;
      }

      const controls = control.parent.controls as AbstractControl[];
      const from = controls[rangeStartControlName].value;
      const to = controls[rangeEndControlName].value;
      const isRangeValid = (CoreUtils.isNull(from) || CoreUtils.isNull(to))
        || (StringUtils.isEmpty(from) || StringUtils.isEmpty(to))
        || (Number(from) <= Number(to));

      return isRangeValid ? null : { range: true };
    };
  }

  public static notBlankString(control: AbstractControl): ValidationErrors | null {
    return !ValidationUtils.isBlankString(control.value) ? null : {
      notBlankString: true
    };
  }

  public static isBlankString(value: string): boolean {
    return /^\s*$/.test(value);
  }
}
