import { HttpErrorResponse } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';

@Injectable()
export class BidEventBusService {
  public readonly loadScenariosEvent = new EventEmitter<void>();
  public readonly loadScenariosAndDropNdcsCacheEvent = new EventEmitter<void>();
  public readonly reloadScenariosEvent = new EventEmitter<number[]>();
  public readonly reloadLoadedNdcsEvent = new EventEmitter<number[]>();
  public readonly reloadBidEvent = new EventEmitter<void>();
  public readonly applyFilterEvent = new EventEmitter<void>();
  public readonly resetFilterToAppliedStateEvent = new EventEmitter<void>();
  public readonly resetFilterEvent = new EventEmitter<void>();
  public readonly deleteDrugNamesFromFilterEvent = new EventEmitter<string[]>();
  public readonly editBidEvent = new EventEmitter<void>();
  public readonly undoRedoEvent = new EventEmitter<void>();
  public readonly updateROREvent = new EventEmitter<number>();
  public readonly rebuildGridWidthEvent = new EventEmitter<void>();
  public readonly quitEditModeEvent = new EventEmitter<void>();
  public readonly drugActionsEvent = new EventEmitter<void>();
  public readonly updateBidFailed = new EventEmitter<HttpErrorResponse>();
}
