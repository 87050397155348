<div class="standard-contract-terms" *ngIf="contract">
  <div class="standard-scenario-section"
    [class.standard-scenario-section--edit-mode]="isEditMode">
      <qv-standard-scenario-section-title
        [title]="'Contract Terms'"
        [tooltip]="resources.TermsDescriptions.DRUG_LIST.CONTRACT_DATES">
      </qv-standard-scenario-section-title>
    <div class="standard-scenario-section__terms">
      <qv-standard-cell class="standard-scenario-section__term"
        [name]="drugTermsConstants[termName.SCENARIO_START_DATE].displayNames.START_DATE"
        [termName]="termName.SCENARIO_START_DATE">
        <qv-lock
          data-test-id="test-id-contract-start-date-lock"
          top-right-block
          [isDisabled]="isBidInvalid"
          [locked]="contract.scenarioStartDateLock"
          [isEditMode]="isEditMode"
          [control]="getFormControlByTermName(termName.SCENARIO_START_DATE_LOCK)">
        </qv-lock>
        <qv-term-date
          class="term__side term__side--left"
          data-test-id="test-id-contract-start-date"
          [isPending]="isBidInvalid"
          [termName]="termName.SCENARIO_START_DATE"
          [termValue]="contract.scenarioStartDate"
          [isEditMode]="isEditMode"
          [isLocked]="contract.scenarioStartDateLock"
          [control]="getFormControlByTermName(termName.SCENARIO_START_DATE)"
          [errors]="scenarioDateErrors.get(termName.SCENARIO_START_DATE)"
          (dateChanged)="onScenarioDateChange(termName.SCENARIO_START_DATE, $event)"
        ></qv-term-date>
      </qv-standard-cell>
      <qv-standard-cell class="standard-scenario-section__term"
        [name]="drugTermsConstants[termName.SCENARIO_END_DATE].displayNames.END_DATE"
        [termName]="termName.SCENARIO_END_DATE">
        <qv-lock
          data-test-id="test-id-contract-end-date-lock"
          top-right-block
          [isDisabled]="isBidInvalid"
          [locked]="contract.scenarioEndDateLock"
          [isEditMode]="isEditMode"
          [control]="getFormControlByTermName(termName.SCENARIO_END_DATE_LOCK)">
        </qv-lock>
        <qv-term-date
          class="term__side term__side--left"
          data-test-id="test-id-contract-end-date"
          [isPending]="isBidInvalid"
          [termName]="termName.SCENARIO_END_DATE"
          [termValue]="contract.scenarioEndDate"
          [isEditMode]="isEditMode"
          [isLocked]="contract.scenarioEndDateLock"
          [control]="getFormControlByTermName(termName.SCENARIO_END_DATE)"
          [errors]="scenarioDateErrors.get(termName.SCENARIO_END_DATE)"
          (dateChanged)="onScenarioDateChange(termName.SCENARIO_END_DATE, $event)"
        ></qv-term-date>
      </qv-standard-cell>
      <qv-standard-cell class="standard-scenario-section__term"
        [name]="drugTermsConstants[termName.ADMINISTRATION_FEE].displayNames.ADMIN_FEE"
        [termName]="termName.ADMINISTRATION_FEE">
        <qv-lock
          data-test-id="test-id-contract-admin-fee-lock"
          top-right-block
          [isDisabled]="isBidInvalid"
          [locked]="contract.administrationFeeLock"
          [isEditMode]="isEditMode"
          [control]="getFormControlByTermName(termName.ADMINISTRATION_FEE_LOCK)">
        </qv-lock>
        <qv-term-input
          class="term__side term__side--left"
          data-test-id="test-id-contract-admin-fee"
          [isPending]="isBidInvalid"
          [termName]="termName.ADMINISTRATION_FEE"
          [termValue]="contract.administrationFee"
          [control]="getFormControlByTermName(termName.ADMINISTRATION_FEE)"
          [isLocked]="contract.administrationFeeLock"
          [isEditMode]="isEditMode">
        </qv-term-input>
      </qv-standard-cell>
    </div>
  </div>
</div>
