import { Injectable } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { BehaviorSubject, merge, Observable } from 'rxjs';
import { distinctUntilChanged, filter, map, take } from 'rxjs/operators';
import { BidSelectService } from '@qv-bid/services/selects/bid-select.service';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NdcManagerService } from '@qv-bid/services/ndc-manager.service';

@UntilDestroy()
@Injectable()
export class DrugSelectService {
  public readonly ndcSelection = new SelectionModel<number>(true, [], true);

  private readonly scenarioSelected$ = new BehaviorSubject(false);
  private readonly ndcsSelected$ = new BehaviorSubject(false);
  private ndcIds: number[] = [];

  constructor(private bidSelectService: BidSelectService,
              private ndcManagerService: NdcManagerService) {
    this.initNdcChangeHandler();
    this.initToggleBidHandler();
  }

  public setNdcIds(ndcIds: number[]): void {
    this.ndcIds = ndcIds;
    this.scenarioSelected$.pipe(take(1), filter((scenarioSelected: boolean) => scenarioSelected))
      .subscribe(() => this.ndcSelection.select(...ndcIds));
  }

  public scenarioSelected(): Observable<boolean> {
    return this.scenarioSelected$.asObservable();
  }

  public ndcSelected(ndcId: number): Observable<boolean> {
    return merge(this.ndcSelection.changed, this.scenarioSelected$)
      .pipe(map(() => this.ndcSelection.isSelected(ndcId)), distinctUntilChanged(), untilDestroyed(this));
  }

  public scenarioToggle(event: MatCheckboxChange): void {
    event.checked ? this.ndcSelection.select(...this.ndcIds) : this.ndcSelection.clear();
    this.ndcSelection.changed.next();

    if (!this.ndcIds.length) {
      this.scenarioSelected$.next(event.checked);
    }
  }

  public initScenarioSelectHandler(scenarioId: number): void {
    this.selectScenarioWhenExistInSelectedScenariosList(scenarioId);
    this.initNdcIdsChangeHandler(scenarioId);

    this.scenarioSelected$.pipe(distinctUntilChanged(), untilDestroyed(this))
      .subscribe((scenarioSelected) => scenarioSelected
        ? this.bidSelectService.scenarioSelection.select(scenarioId)
        : this.bidSelectService.scenarioSelection.deselect(scenarioId));

    merge(this.ndcsSelected$, this.scenarioSelected$).pipe(untilDestroyed(this))
      .subscribe(() => this.bidSelectService.updateSelectedDrugs(scenarioId, this.ndcSelection.selected));
  }

  private selectScenarioWhenExistInSelectedScenariosList(scenarioId: number): void {
    this.bidSelectService.scenarioSelected().pipe(
      take(1),
      filter((scenarioIds: number[]) => scenarioIds.includes(scenarioId))
    ).subscribe(() => this.scenarioSelected$.next(true));
  }

  private isSelectedAllNdcs(): boolean {
    return this.ndcSelection.selected.length && (this.ndcSelection.selected.length === this.ndcIds.length);
  }

  private isSelectedOneNdc(): boolean {
    return Boolean(this.ndcSelection.selected.length);
  }

  private initToggleBidHandler(): void {
    this.bidSelectService.toggleAllDrugs$.pipe(untilDestroyed(this))
      .subscribe((checked: boolean) => this.scenarioToggle({ checked } as MatCheckboxChange));
  }

  private initNdcChangeHandler(): void {
    this.ndcSelection.changed.pipe(untilDestroyed(this)).pipe(
      map(() => this.isSelectedAllNdcs())
    ).subscribe((selected) => this.scenarioSelected$.next(selected));

    this.ndcSelection.changed.pipe(untilDestroyed(this)).pipe(map(() => this.isSelectedOneNdc()))
      .subscribe((selected: boolean) => this.ndcsSelected$.next(selected));
  }

  private initNdcIdsChangeHandler(scenarioId: number): void {
    this.ndcManagerService.getNdcIds(scenarioId).pipe(untilDestroyed(this))
      .subscribe((ndcIds: number[]) => this.setNdcIds(ndcIds));
  }
}
