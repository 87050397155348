export enum LoiBlockingMessage {
  LOADING_CONTACTS = 'Loading Contacts...',
  LOADING_CONTACT = 'Loading Contact...',
  UPDATING_CONTACT = 'Updating Contact...',
  ADDING_CONTACT = 'Adding Contact...',
  DELETING_CONTACT = 'Deleting Contact...',
  EXPORTING_CONTACT = 'Exporting Contact...',
  ADDING_COLUMN = 'Adding Column...',
  UPDATING_COLUMN = 'Updating Column...',
  DELETING_TEMPLATE = 'Deleting LOI Template...',
  GENERATE_SCENARIO_SUMMARY = 'Generating Scenario Summary...'
}
