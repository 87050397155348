import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { combineLatest, Observable } from 'rxjs';
import { UserType } from '@qv-user/enums/user-type.enum';
import { resources } from '@qv-common/static/resources';
import { NavigationMainService } from '@qv-header/services/navigation-main.service';
import { filter } from 'rxjs/operators';
import { User } from '@qv-user/entities/user.entity';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AuthService } from 'quantuvis-angular-common/auth';
import { UserService } from '@qv-common/services/auth/user.service';

@UntilDestroy()
@Component({
  selector: 'qv-header-main',
  templateUrl: './header-main.component.html',
  styleUrls: ['./header-main.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderMainComponent implements OnInit {
  public isFilterByMyAccountsEnabled: Observable<boolean>;
  public myAccountsTooltip: string;
  public allAccountsTooltip: string;
  public disabledTooltip: string;
  public isMyAccountsSwitcherAvailable = false;
  public companyLogoUrl = '';

  public readonly tooltips = resources.TOOLTIPS.MAIN_MENU;

  private urlsWithMyAccounts = ['dashboard', 'bids', 'notifications'];

  constructor(
    private authService: AuthService,
    private userService: UserService,
    private navigationService: NavigationMainService,
    protected changeDetectorRef: ChangeDetectorRef,
    private router: Router,
  ) {}

  public ngOnInit(): void {
    this.isMyAccountsSwitcherAvailable = this.isMyAccountsAvailable(this.router.url, this.userService.user.getValue());
    this.isFilterByMyAccountsEnabled = this.userService.isFilterByMyAccountsEnabled();
    this.initUserDataChangeHandler();
    this.initUserAndRouteChangeHandler();
  }

  public prepareCompanyLogoUrl(): string {
    return this.authService.appendSecureInfoToUrl('service/companies/getlogo/forCurrentUser');
  }

  public showEnterpriseButton(): boolean {
    return this.navigationService.isEnterpriseButtonVisible();
  }

  public onToggleMyAccounts(flag: boolean): void {
    this.navigationService.toggleMyAccounts(flag);
  }

  public isMyAccountsToggleDisabled(): boolean {
    return this.userService.myAccounts.length === 0;
  }

  private initUserDataChangeHandler(): void {
    this.userService.user
      .pipe(
        filter((user: User) => Boolean(user)),
        untilDestroyed(this))
      .subscribe((user: User) => {
        this.companyLogoUrl = this.prepareCompanyLogoUrl();
        this.setTooltips(this.getOppositeUserType(user.role.type));
        this.changeDetectorRef.markForCheck();
      });
  }

  private initUserAndRouteChangeHandler(): void {
    combineLatest([
      this.router.events,
      this.userService.user
    ]).pipe(
      filter(([event, user]: [RouterEvent, User]) => user && (event instanceof NavigationEnd)),
      untilDestroyed(this),
    ).subscribe(([event, user]: [NavigationEnd, User]) => {
      this.isMyAccountsSwitcherAvailable = this.isMyAccountsAvailable(event.urlAfterRedirects, user);
    });
  }

  private getOppositeUserType(currentUserType: UserType): UserType {
    let userType = UserType.NONE;

    if (currentUserType === UserType.PAYER) {
      userType = UserType.PHARMA;
    } else if (currentUserType === UserType.PHARMA) {
      userType = UserType.PAYER;
    }

    return userType;
  }

  private setTooltips(userType: UserType): void {
    const userTypeName = UserType.displayValue(userType);
    this.myAccountsTooltip = `Display only my ${userTypeName} accounts on Dashboard, Bids and Notifications pages`;
    this.allAccountsTooltip = `Display all ${userTypeName} accounts on Dashboard, Bids and Notifications pages`;
    this.disabledTooltip = 'You must populate the My Accounts section of your Profile Settings to switch your ' +
      'Dashboard, Bids and Notifications pages between My Accounts and All Accounts';
  }

  private isMyAccountsAvailable(url: string, user: User): boolean {
    return Boolean(user && !user.isAdmin && this.isUrlWithMyAccounts(url));
  }

  private isUrlWithMyAccounts(url: string): boolean {
    return this.urlsWithMyAccounts.some((urlWithMyAccounts: string) => url.includes(urlWithMyAccounts));
  }
}
