/**
 * Quantuvis focus out directive
 *
 * @description
 * Used for handling focus out event from element and running callback function.
 * For basic use add 'qv-focus-out="callback($event)"' attribute to element.
 *
 * Optional attributes:
 * 'qv-focus-out-args'  - pass extra parameters to callback via this attribute
 *                    (ex.: qv-focus-out-args='{"prevValuePath": "term.value"}');
 */
export const QvFocusOut = (): any => ({
  restrict: 'A',
  link: (scope, elem, attrs) => {
    elem.on('focusout', $event => {
      const callback = attrs.qvFocusOut;
      const extraParameters = scope.$eval(attrs.qvFocusOutArgs);
      const parameters = Object.assign({ $event }, extraParameters);
      scope.$eval(callback, parameters);
    });
  }
});
