import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BidDeclineModalData } from './bid-decline-modal-data';
import { BidDeclineModalComponent } from '@qv-bid/components/shared/bid-decline-modal/bid-decline-modal.component';
import { ModalConfig, ModalSize } from 'quantuvis-angular-common/modal';

export class BidDeclineModalConfig extends ModalConfig<BidDeclineModalComponent, BidDeclineModalData> {

  constructor(modalData: BidDeclineModalData) {
    super(modalData, ModalSize.MEDIUM);
  }

  protected createDialogRef(dialog: MatDialog): MatDialogRef<BidDeclineModalComponent> {
    return dialog.open(BidDeclineModalComponent, this);
  }

}
