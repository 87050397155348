import { Injectable, OnDestroy } from '@angular/core';
import { ScenarioConfigList, ScenarioStateConfig } from '@qv-bid/models';
import { Scenario } from '@qv-bid/entities';
import { BidStateService } from '@qv-bid/services/bid-state.service';

@Injectable()
export class ScenarioConfigListService implements OnDestroy {
  public scenarioConfigList = new ScenarioConfigList();

  constructor(private bidStateService: BidStateService) {}

  public ngOnDestroy(): void {
    this.scenarioConfigList.storage.forEach((item: ScenarioStateConfig) => item.destroy());
  }

  public getScenarioStateConfig(uuid: string): ScenarioStateConfig {
    return this.scenarioConfigList.storage.get(uuid);
  }

  public resetFirstAndLastScenarioFlags(uuid: string): void {
    const scenarioConfig = this.getScenarioStateConfig(uuid);
    scenarioConfig.isFirstScenarioOfDrugGroup$.next(false);
    scenarioConfig.isLastScenarioOfDrugGroup$.next(false);
  }

  public updateScenarioConfigList(scenarios: Scenario[], bidVersionId: number): void {
    if (this.scenarioConfigList.bidVersionId !== bidVersionId
      || this.scenarioConfigList.cbId !== this.bidStateService.cbId) {
      this.updateScenarioConfigListOnNewCb(bidVersionId, scenarios);
    } else {
      this.updateScenarioConfigListOnChangeCb(scenarios);
    }
  }

  private updateScenarioConfigListOnChangeCb(scenarios: Scenario[]): void {
    const scenariosWithoutConfig = scenarios.filter((scenario: Scenario) =>
      !this.scenarioConfigList.storage.has(scenario.uuid));

    if (scenariosWithoutConfig.length) {
      scenariosWithoutConfig.forEach((scenario: Scenario) => this.setScenarioStateConfig(scenario));
    }

    const storageKeys = scenarios.map((scenario: Scenario) => scenario.uuid);
    const deprecatedConfigKeys = [];

    this.scenarioConfigList.storage.forEach((value: ScenarioStateConfig, key: string) => {
      if (!storageKeys.includes(key)) {
        deprecatedConfigKeys.push(key);
      }
    });

    if (deprecatedConfigKeys.length) {
      deprecatedConfigKeys.forEach((key: string) => this.scenarioConfigList.storage.delete(key));
    }

  }

  private updateScenarioConfigListOnNewCb(bidVersionId: number, scenarios: Scenario[]): void {
    this.scenarioConfigList.isFirstLoading = true;
    this.scenarioConfigList.bidVersionId = bidVersionId;
    this.scenarioConfigList.cbId = this.bidStateService.cbId;
    scenarios.forEach((scenario: Scenario) => this.setScenarioStateConfig(scenario));
  }

  private setScenarioStateConfig(scenario: Scenario): void {
    if (!this.scenarioConfigList.storage.has(scenario.uuid)) {
      this.scenarioConfigList.storage.set(scenario.uuid, new ScenarioStateConfig());
    }
  }

}
