import { Component, ChangeDetectionStrategy, ChangeDetectorRef, Input, OnInit } from '@angular/core';
import { TermTemplateStorageService } from '@qv-term/services';
import { BaseTermWithLockComponent } from '@qv-term/components/base-term-with-lock/base-term-with-lock.component';
import { ChoiceOptions } from '@qv-shared/models';
import { UserService } from '@qv-common/services/auth/user.service';

@Component({
  selector: 'qv-term-slide-toggle',
  templateUrl: './term-slide-toggle.component.html',
  styleUrls: ['./term-slide-toggle.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TermSlideToggleComponent extends BaseTermWithLockComponent implements OnInit {
  @Input()
  public choiceOptions = new ChoiceOptions(this.resources.Actions.YES, this.resources.Actions.NO);

  constructor(
    termTemplateStorage: TermTemplateStorageService,
    userService: UserService,
    changeDetectorRef: ChangeDetectorRef
  ) {
    super(userService, termTemplateStorage, changeDetectorRef);
  }

  public ngOnInit(): void {
    super.ngOnInit();
  }
}
