import { DictionaryItem } from 'quantuvis-angular-common/interfaces';

export enum StepTherapyAllowed {
  EMPTY = 70,
  NO,
  YES_SINGLE_STEP,
  YES_DOUBLE_STEP,
  YES_TRIPLE_STEP,
  YES_OTHER
}

export namespace StepTherapyAllowed {
  const valuesMap = new Map<StepTherapyAllowed, DictionaryItem>([
    [StepTherapyAllowed.EMPTY, { id: StepTherapyAllowed.EMPTY, name: '' }],
    [StepTherapyAllowed.NO, { id: StepTherapyAllowed.NO, name: 'No' }],
    [
      StepTherapyAllowed.YES_SINGLE_STEP,
      { id: StepTherapyAllowed.YES_SINGLE_STEP, name: 'Yes - Single step' }
    ],
    [
      StepTherapyAllowed.YES_DOUBLE_STEP,
      { id: StepTherapyAllowed.YES_DOUBLE_STEP, name: 'Yes - Double step' }
    ],
    [
      StepTherapyAllowed.YES_TRIPLE_STEP,
      { id: StepTherapyAllowed.YES_TRIPLE_STEP, name: 'Yes - Triple step' }
    ],
    [
      StepTherapyAllowed.YES_OTHER,
      { id: StepTherapyAllowed.YES_OTHER, name: 'Yes - Other' }
    ],
  ]);

  export function getValue(stepTherapyAllowedProduct: StepTherapyAllowed): DictionaryItem {
    return valuesMap.get(stepTherapyAllowedProduct);
  }

  export function getValues(): DictionaryItem[] {
    return Array.from(valuesMap.values());
  }
}
