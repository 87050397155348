import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges
} from '@angular/core';
import { CoreUtils, ErrorUtils } from '@qv-common/utils';
import { ValidationError } from '@qv-common/models';
import { SeverityLevel, SvgIconName } from '@qv-common/enums';
import { FormControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'qv-error-icon',
  templateUrl: './error-icon.component.html',
  styleUrls: ['./error-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ErrorIconComponent implements OnInit, OnChanges {
  @Input()
  public errors?: ValidationError[] = [];
  @Input()
  public errorsMap?: { [key: string]: ValidationError };
  @Input()
  public control?: FormControl;

  public errorLevel: SeverityLevel;
  public readonly severityLevel = SeverityLevel;
  public readonly svgIconName = SvgIconName;

  private readonly FORM_STATUS_INVALID = 'INVALID';

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
  ) {}

  public ngOnInit(): void {
    this.updateErrorLevel();

    if (this.control && CoreUtils.isNotEmptyObject(this.errorsMap)) {
      this.subscribeOnStateChanges();
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.errors && changes.errors.currentValue) {
      this.errors = changes.errors.currentValue;
      this.updateErrorLevel();
    }
  }

  public getErrorMessage(): string {
    const error = this.errors.find((err: ValidationError) => err.level === this.severityLevel.ERROR);
    if (error) return error.message;

    const warn = this.errors.find((err: ValidationError) => err.level === this.severityLevel.WARN);
    if (warn) return warn.message;
  }

  private updateErrorLevel(): void {
    this.errorLevel = this.errors.length ? ErrorUtils.getMaxSeverity(this.errors) : null;
    this.changeDetectorRef.markForCheck();
  }

  private subscribeOnStateChanges(): void {
    this.control.statusChanges
      .pipe(untilDestroyed(this))
      .subscribe((status: string) => {
        this.errors = [];
        if (status === this.FORM_STATUS_INVALID && (this.control.dirty || this.control.touched)) {
          Object.keys(this.control.errors).map((key) => this.errors.push(this.errorsMap[key]));
        }
        this.updateErrorLevel();
      });
  }
}
