import { SectionChangeManager } from '@qv-bid/services/sections/section-change-manager.service';
import { ChangeDetectorRef, Directive, Input, ViewRef } from '@angular/core';
import { Scenario } from '@qv-bid/entities';
import { Subject } from 'rxjs';

@Directive()
export class BaseRow {
  @Input()
  public isBidInvalid: boolean;
  @Input()
  public scenario: Scenario;

  protected destroyed$ = new Subject();

  constructor(
    protected sectionChangeManager: SectionChangeManager,
    protected changeDetectorRef: ChangeDetectorRef,
  ) {}

  protected subscriptionLiveHandler(): void {
    this.sectionChangeManager.isSectionChanging$.next(false);
    if ((this.changeDetectorRef as ViewRef).destroyed) {
      setTimeout(() => {
        this.destroyed$.next(null);
        this.destroyed$.complete();
      });
    }
  }
}
