import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { SvgIconName } from '@qv-common/enums';
import { TermTemplateStorageService } from '@qv-term/services';

@Component({
  selector: 'qv-term-field-base',
  templateUrl: './term-field-base.component.html',
  styleUrls: ['./term-field-base.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TermFieldBaseComponent implements OnInit {
  @Input()
  public termName: string;

  public termConfig;

  public readonly svgIconName = SvgIconName;

  constructor(private termTemplateStorage: TermTemplateStorageService) {}

  public ngOnInit(): void {
    this.loadTermTemplate();
  }

  private loadTermTemplate(): void {
    this.termConfig = this.termTemplateStorage.getTermTemplate(this.termName);
  }
}
