import { JsonObject, JsonProperty } from 'json2typescript';
import { DateConverter } from '@qv-bid/serializers';
import { Moment } from 'moment';

@JsonObject('Signature')
export class Signature {
  @JsonProperty('bidVersionId', Number)
  public bidVersionId: number = null;

  @JsonProperty('userId', Number)
  public userId: number = null;

  @JsonProperty('signature', String)
  public signature: string = null;

  @JsonProperty('companyName', String)
  public companyName: string = null;

  @JsonProperty('createdAt', DateConverter)
  public createdAt: Moment = null;
}
