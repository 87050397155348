/* tslint:disable:no-invalid-this triple-equals */
import { ConfirmNavigationInner } from '@qv-ng1-wrapper/classes';
import { constants, resources } from '@qv-common/static';
import { CoreUtils } from '@qv-common/utils';
import { HttpStatusCode } from 'quantuvis-angular-common/api';
import { forkJoin, of, OperatorFunction, throwError } from 'rxjs';
import { ApiUrlPrefix } from '@qv-common/enums';
import { catchError, filter, finalize, switchMap, tap } from 'rxjs/operators';
import isEqual from 'lodash.isequal';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { DisableConfirmationUserComponent, EnableConfirmationUserComponent } from '@qv-user/components';
import { OldBodyResponse } from '@qv-common/interfaces';
import { GeneralModalData, ModalService, ModalSize, TemplateModalData } from 'quantuvis-angular-common/modal';
import { UserService } from '@qv-common/services/auth/user.service';
import { AuthService } from 'quantuvis-angular-common/auth';

declare let angular: angular.IAngularStatic;
declare let $: any;

const UserController = class UserController extends ConfirmNavigationInner {

  public static $inject = ['$scope', '$rootScope', 'router', 'standards', 'apiService', 'timezone', 'role', 'util',
    'translations', 'permissionService', 'biddingUtilsService', 'spinnerService', '$window',
    'userService', 'userDaoService', 'authService', 'modalService'
  ];
  public confirmNavigationMessage = resources.GENERAL.CONFIRM_NAVIGATION_TEXT;
  private newUser;
  private redirectFromEditUserPage;
  private getClassFor;
  private isCurrentUserPayerAdmin;
  private clearAccessRightsMap;
  private select;
  private isCurrentUserPharmaAdmin;
  private getClassForStatus;
  private isPharmaAdmin;
  private isUserDisabled;
  private isUserInvited;
  private isUserUnInvited;
  private isUserInactive;
  private isUserActive;
  private isUserNew;
  private showEnableButton;
  private isLoggedInUserEditingHimself;
  private showEnableUserModal;
  private enableUser;
  private getCurrentUserDataForSave;
  private resendInvitation;
  private disableUser;
  private updateUser;
  private unInvite;
  private checkEmailPreferences;
  private resetPassword;
  private timezoneChanged;
  private getCurrentUserData;
  private roleChanged;
  private showDisableUserModal;
  private checkAndPreventDisable;
  private disableRoleField;
  private hideDisableButton;
  private showNoOtherActiveAdmins;
  private updateUserData;

  constructor(public $scope, $rootScope, private router, standards, apiService, timezone, role, util, translations,
              permissionService, biddingUtilsService, spinnerService, $window, userService: UserService, userDaoService,
              authService: AuthService, private modalService: ModalService) {
    super();
    const NUMBER_OF_TIMES_TO_EXECUTE_UNTIL_RENDER = 4;
    let accessRightsMap = {};
    translations.buildTranslations($scope, 'GENERAL');
    $scope.router = router;
    $scope.userRightsConstants = constants.UserRights;

    $scope.isCurrentUserQuantuvisAdmin = userService.isCurrentUserQuantuvisAdmin();

    /**
     * Cleans the scope state related to info and error messages.
     */
    function cleanState(): void {
      delete $rootScope.response;
      delete $scope.response;
      delete $scope.errorMessage;
    }

    function isRoleNamePayerOrPharma(): any {
      const roleTypes = constants.RoleTypes;
      return $scope.roleName === roleTypes.PAYER || $scope.roleName === roleTypes.PHARMA;
    }

    function isUserDataValid(user): any {
      let validateStatus = false;

      if (CoreUtils.isNotDefined(user.role)) {
        setError(translations.getString('UserValidation', 'ROLE_ERROR'));
      } else if ($scope.isCurrentUserPayerAdmin()
        && (CoreUtils.isNotDefined(user.accessRights) || !user.accessRights)) {
        setError(translations.getString('UserValidation', 'ACCESS_RIGHT_ERROR'));
      } else if (user.firstName === null || !user.firstName) {
        setError(translations.getString('UserValidation', 'FIRST_NAME_ERROR'));
      } else if (user.lastName === null || !user.lastName) {
        setError(translations.getString('UserValidation', 'LAST_NAME_ERROR'));
      } else if (user.title === null || !user.title) {
        setError(translations.getString('UserValidation', 'TITLE_ERROR'));
      } else if (user.phone === null || !user.phone) {
        setError(translations.getString('UserValidation', 'PHONE_ERROR'));
      } else if (user.email === null || !user.email) {
        setError(translations.getString('UserValidation', 'EMAIL_ERROR'));
      } else if (CoreUtils.isNotDefined(user.timezone)) {
        setError(translations.getString('UserValidation', 'TIMEZONE_ERROR'));
      } else if ($scope.isCurrentUserPharmaAdmin() && !isAccessRightsMapValid(user.accessRights)) {
        setError(translations.getString('UserValidation', 'ACCESS_RIGHTS_FOR_ALL_COMPANIES_ERROR'));
      } else {
        validateStatus = true;
        delete $scope.response;
      }

      return validateStatus;
    }

    function isAccessRightsMapValid(accessRights): any {
      const element = $scope.companies.find(company => {
        // Search every company id through the accessRightsMap
        if (CoreUtils.isNotDefined(accessRights[company.id])) {
          return company.id;
        }
      });

      return CoreUtils.isNotDefined(element);
    }

    function setError(message): void {
      delete $scope.response;
      $scope.response = {
        error: true,
        message
      };
    }

    function validateUser(user): any {
      let validateStatus = false;

      // If firstName or lastName is null or empty, the validation error message is properly set.
      if (user.firstName == null || !user.firstName) {
        delete $scope.response;
        $scope.userValidationErrorMsg = translations.getString('UserValidation', 'FIRST_NAME_ERROR');
      } else if (user.lastName == null || !user.lastName) {
        delete $scope.response;
        $scope.userValidationErrorMsg = translations.getString('UserValidation', 'LAST_NAME_ERROR');
      } else if (user.title == null || !user.title) {
        delete $scope.response;
        $scope.userValidationErrorMsg = translations.getString('UserValidation', 'TITLE_ERROR');
      } else if (user.phone == null || !user.phone) {
        delete $scope.response;
        $scope.userValidationErrorMsg = translations.getString('UserValidation', 'PHONE_ERROR');
      } else {
        validateStatus = true;
        delete $scope.userValidationErrorMsg;
      }

      return validateStatus;
    }

    function isAdmin(roleName): any {
      const roleNames = constants.RoleNames;
      return roleName === roleNames.PAYER_ADMIN || roleName === roleNames.PHARMA_ADMIN;
    }

    standards.apply($scope, {
      constructor: () => {
        // Get logged in user type
        const userType = userService.getUserType();

        let responseSum = 0;

        // Allow user edit flag
        $scope.allowEditUser = permissionService.isAllowed('edit_user');
        $scope.accessRightsOptions = constants.AccessRights;
        $scope.isManufacturer = userService.user.getValue().isManufacturer;
        translations.buildTranslations($scope, 'ProfileSettings');
        $scope.myAccountsMessages = $scope.i18n;
        $scope.invalidEmailPreferences = false;
        $scope.invalidEmailPrefsMessage = '';
        $scope.emailPreferences = constants.MY_ACCOUNTS.EMAIL_PREFERENCES;
        $scope.$watch('user.emailFilter', (newVal, oldVal) => {
          $scope.checkEmailPreferences();
        }, true);

        if (!$scope.isCurrentUserQuantuvisAdmin && !$scope.isManufacturer) {
          responseSum = 1;
        }

        timezone.fetchTimezones($scope); // Fetch timezones - if not loaded the request will be added to batch

        // Fetch roles - if not loaded - the request will be added to batch
        const roleTypes = constants.RoleTypes;
        if (userType === roleTypes.PAYER.toLowerCase() || userType === roleTypes.PHARMA.toLowerCase()) {
          role.fetchRoles($scope);
        }

        const getOtherType = () => {
          if (!$scope.isManufacturer) {
            return of(null);
          }

          // Load all payer companies on scope in order to generate access rights matrix
          return apiService.get(`${ApiUrlPrefix.OLD}/companies/othertype/false`).pipe(
            tap((response: HttpResponse<any>) => {
              $scope.companies = response.body.responseObject;
              responseCompleted();
            })
          );
        };

        const getUserData = () => {
          const userId = router.routerState.snapshot.root.queryParams.userId;
          if (this.isEditUserState()) {
            $scope.currentUserId = userId;
            return apiService.get(`${ApiUrlPrefix.OLD}/user/getData/${userId}`).pipe(
              this.parseResponse(),
              tap((response: HttpResponse<any>) => {
                const data = response.body;

                delete $rootScope.response;
                delete $scope.response;
                delete $scope.noOtherActiveAdmins;
                delete $scope.changedRole;

                if (data && data.role) {
                  $scope.user = data;
                  $scope.myAccountsIds = $scope.user.myAccounts;
                  $scope.user.isPayer = biddingUtilsService.isCurrentUserPayer($scope.user);
                  $scope.user.isManufacturer = biddingUtilsService.isPharma($scope.user);

                  setMyAccountsMessagesByUserType();

                  $scope.roleName = data.role.name;
                  $scope.savedData = $scope.getCurrentUserData();
                  $scope.companyType = $scope.user.role.type.toLowerCase();

                  if ($scope.isCurrentUserQuantuvisAdmin) {
                    // fetch roles for Quantuvis admin
                    role.fetchRolesForUserType($scope, $scope.companyType.toUpperCase(), () => {
                      responseCompleted();
                    });
                  } else {
                    responseCompleted();
                  }

                  $scope.companyId = $scope.user.companyId;
                  // Get number of enabled admins
                  apiService.get(`${ApiUrlPrefix.OLD}/users/count/${$scope.companyType}/enabledAdmins/${$scope.companyId}`).pipe(
                    this.parseResponse(),
                    tap(({ body }: HttpResponse<any>) => {

                      $scope.activeAdmins = body.responseObject > 1;
                      if (body.message) {
                        $scope.noOtherActiveAdmins = body.message;
                      }
                      responseCompleted();
                    }),
                    catchError((error: HttpErrorResponse) => {
                      delete $scope.noOtherActiveAdmins;
                      $scope.errorMessage = error.message;
                      responseCompleted();

                      return of(null);
                    })
                  ).subscribe();
                }
                responseCompleted();
              }),
              catchError((error: HttpErrorResponse) => {
                delete $scope.user;

                $scope.errorMessage = error.message;

                responseCompleted();

                return of(null);
              })
            );
          } else {
            // For 'Create New User'
            $scope.savedData = $scope.getCurrentUserDataForSave();

            return of(null);
          }
        };

        forkJoin([getOtherType(), getUserData()]).subscribe(() => responseCompleted());

        function setMyAccountsMessagesByUserType(): void {
          if ($scope.user.isPayer) {
            $scope.myAccountsTooltipMessage = $scope.myAccountsMessages.MY_ACCOUNTS_TOOLTIP_FOR_PAYER;
            $scope.emptyMyAccountsMessage = $scope.myAccountsMessages.EMPTY_MY_ACCOUNTS_INFO
              + $scope.myAccountsMessages.EMPTY_MY_ACCOUNTS_INFO_FOR_PAYER;
          } else if ($scope.user.isManufacturer) {
            $scope.myAccountsTooltipMessage = $scope.myAccountsMessages.MY_ACCOUNTS_TOOLTIP_FOR_PHARMA;
            $scope.emptyMyAccountsMessage = $scope.myAccountsMessages.EMPTY_MY_ACCOUNTS_INFO
              + $scope.myAccountsMessages.EMPTY_MY_ACCOUNTS_INFO_FOR_PHARMA;
          }
        }

        function responseCompleted(): void {
          if ($scope.isCurrentUserQuantuvisAdmin) {
            if (++responseSum === NUMBER_OF_TIMES_TO_EXECUTE_UNTIL_RENDER) {
              setDataReadyOnRefreshScope();
            }
          } else {
            setDataReadyOnRefreshScope();
          }
        }

        function setDataReadyOnRefreshScope(): void {
          $scope.dataReady = true;
        }

      },
      variables: {
        dataReady: false,
        allowEditUser: false,
        user: {},
        invalidEmailPreferences: false,
        invalidEmailPrefsMessage: ''
      },
      public: () => {

        this.newUser = () => {
          if ($scope.isCurrentUserPharmaAdmin()) {
            $scope.user.accessRights = accessRightsMap;
          }

          if (isUserDataValid($scope.user)) {
            spinnerService.start('Saving User...');

            const userData = util
              .removeEmptyStringPropertiesFromObject(util.removePropertiesFromObject($scope.user, '$$hashKey'));
            apiService.post(`${ApiUrlPrefix.OLD}/users/save`, userData).pipe(
              this.parseResponse(),
              tap((response: HttpResponse<any>) => {
                spinnerService.stop();
                $scope.savedData = angular.copy($scope.getCurrentUserDataForSave());
                $rootScope.response = {
                  user: response.body
                };
                router.navigate(['/people']);
              }),
              catchError((error: HttpErrorResponse) => {
                spinnerService.stop();
                $scope.response = error;
                util.refreshScope($scope);

                return of(null);
              })
            ).subscribe();
          }
        };

        this.redirectFromEditUserPage = () => {
          if ($scope.isCurrentUserQuantuvisAdmin) {
            const params = {
              companyType: $scope.companyType,
              companyId: $scope.user.companyId
            };
            router.navigate(['/editCompany'], { queryParams: params });
          } else {
            router.navigate(['/people']);
          }
        };

        this.disableRoleField = roleName =>
          ($scope.isLoggedInUserEditingHimself() || ($scope.isCurrentUserQuantuvisAdmin && isAdmin(roleName)))
          && !$scope.activeAdmins && !$scope.changedRole;

        this.hideDisableButton = roleName => $scope.isUserDisabled()
          || $scope.isUserInvited() || $scope.isUserUnInvited()
          || (($scope.isLoggedInUserEditingHimself() || ($scope.isCurrentUserQuantuvisAdmin && isAdmin(roleName)))
            && !$scope.activeAdmins && !$scope.changedRole);

        this.showNoOtherActiveAdmins = roleName => !$scope.isUserDisabled()
          && $scope.noOtherActiveAdmins && !$scope.changedRole && isAdmin(roleName)
          && ($scope.isLoggedInUserEditingHimself() || $scope.isCurrentUserQuantuvisAdmin);

        this.updateUserData = () => {
          const userData = angular.copy($scope.user);

          spinnerService.start('Updating User...');

          apiService.post(`${ApiUrlPrefix.OLD}/user/update`, userData).pipe(
            this.parseResponse(),
            tap((response: HttpResponse<any>) => {
              const data = response.body;

              // Successfully updated the user information
              spinnerService.stop();
              cleanState();

              $scope.savedData = angular.copy($scope.getCurrentUserData());

              $rootScope.response = {
                user: data
              };

              // The user is logged out if editing himself and changed his role from Admin to User
              if ($scope.isLoggedInUserEditingHimself()) {
                if (isRoleNamePayerOrPharma()) {
                  $('#confirmChangeRole').hide();
                  authService.logout();
                } else {
                  // When the admin user is updating himself, the user variable used for displaying first name
                  // and last name on the header should also be updated.
                  const currentUser = userService.user.getValue();
                  currentUser.firstName = $scope.user.firstName;
                  currentUser.lastName = $scope.user.lastName;
                  currentUser.timezone = $scope.user.timezone;
                  currentUser.title = $scope.user.title;
                  currentUser.phone = $scope.user.phone;
                  currentUser.emailFilter = $scope.user.emailFilter;
                }
              }

              $('#confirmChangeRole').hide();
              $scope.redirectFromEditUserPage();
            }),
            catchError((error: HttpErrorResponse) => {
              spinnerService.stop();
              cleanState();
              if (error.status == 406) {
                $scope.userValidationErrorMsg = error.message;
              } else {
                $scope.response = error;
              }
              util.refreshScope($scope);

              return of(null);
            })
          ).subscribe();
        };

        this.clearAccessRightsMap = () => {
          accessRightsMap = {};
        };

        this.select = (companyId, event) => {
          const target = $(event.target);
          if (target.hasClass('option')) {
            accessRightsMap[companyId] = target.html().toUpperCase();
          }
        };

        this.getClassFor = (companyId, suffix, klass) => {
          let result = suffix;
          if (accessRightsMap) {
            const value = accessRightsMap[companyId];
            if (klass.toUpperCase() === value) {
              result += ' selected';
            }
          }

          return result;
        };

        this.isCurrentUserPayerAdmin = () => biddingUtilsService.isPayerAdmin(userService.user.getValue());

        this.isCurrentUserPharmaAdmin = () => biddingUtilsService.isPharmaAdmin(userService.user.getValue());

        this.isPharmaAdmin = () => {
          const pharmaAdmin = constants.RoleNames.PHARMA_ADMIN;
          return $scope.user.role && $scope.user.role === pharmaAdmin;
        };

        this.isUserDisabled = () => $scope.user && biddingUtilsService.isUserDisabled($scope.user);

        this.isUserInvited = () => $scope.user && biddingUtilsService.isUserInvited($scope.user);

        this.isUserUnInvited = () => $scope.user && biddingUtilsService.isUserUnInvited($scope.user);

        this.isUserInactive = () => $scope.user && biddingUtilsService.isUserInactive($scope.user);

        this.isUserActive = () => $scope.user && biddingUtilsService.isUserActive($scope.user);

        this.isUserNew = () => $scope.user && biddingUtilsService.isUserNew($scope.user);

        this.showEnableButton = () => $scope.user && biddingUtilsService.isUserDisabled($scope.user)
          && !biddingUtilsService.isUserInvited($scope.user)
          && !biddingUtilsService.isUserUnInvited($scope.user);

        this.isLoggedInUserEditingHimself = () => $scope.user && $scope.user.id === userService.user.getValue().id;

        this.showEnableUserModal = () => {
          const modal = modalService.openTemplateModal(new TemplateModalData(
            'Enable User',
            EnableConfirmationUserComponent,
            {
              user: $scope.user,
            },
            resources.Actions.ENABLE_USER,
            resources.Actions.CANCEL,
            ModalSize.X_SMALL
          ));

          modal.pipe(filter((result: boolean) => result)).subscribe(() => {
            cleanState();

            userDaoService.enable($scope.user.id).subscribe((data: OldBodyResponse<string>) => {
              $rootScope.response = {
                user: data
              };
              $scope.redirectFromEditUserPage();
            });
          });
        };

        this.updateUser = () => {
          if (validateUser($scope.user)) {
            if ($scope.isLoggedInUserEditingHimself()) {
              if (isRoleNamePayerOrPharma()) {
                modalService.openConfirmModal(new GeneralModalData(
                  'Change User Role',
                  'Changing your role from Administrator to User will lead to a loss of editing privileges.' +
                  ' You will be logged out in order to proceed with the change.',
                  resources.Actions.CONTINUE,
                  resources.Actions.CANCEL,
                )).pipe(filter((result: boolean) => result)).subscribe(() => $scope.updateUserData());
              } else {
                $scope.updateUserData();
              }
            } else {
              $scope.updateUserData();
            }
          } else {
            util.refreshScope($scope);
          }
        };

        this.resendInvitation = () => {
          spinnerService.start('Resending Invitation...');

          apiService.post(`${ApiUrlPrefix.OLD}/users/resendInvitation`, null, {
            headers: {
              'X-Auth-Email': $scope.user.email
            }
          }).pipe(
            this.parseResponse(),
            tap((response: HttpResponse<any>) => {
              cleanState();
              $scope.response = response.body;

              $scope.user.userStatus = constants.UserStatus.INVITED;
            }),
            catchError(() => {
              cleanState();
              $scope.response = {
                error: true,
                message: $scope.i18n.ERROR
              };

              return of(null);
            }),
            finalize(() => spinnerService.stop())
          ).subscribe();
        };

        this.unInvite = () => {
          spinnerService.start('Uninviting User...');

          apiService.post(`${ApiUrlPrefix.OLD}/users/unInviteUser/${$scope.user.id}`).pipe(
            this.parseResponse(),
            tap((response: HttpResponse<any>) => {
              cleanState();
              $scope.response = response.body;

              $scope.user.userStatus = constants.UserStatus.UNINVITED;
            }),
            catchError(() => {
              cleanState();
              $scope.response = {
                error: true,
                message: $scope.i18n.ERROR
              };

              return of(null);
            }),
            finalize(() => spinnerService.stop())
          ).subscribe();
        };

        this.resetPassword = () => {
          spinnerService.start('Resetting Password...');

          apiService.post(`${ApiUrlPrefix.OLD}/user/resetPassword`, $scope.user.id).pipe(
            this.parseResponse(),
            tap((response: HttpResponse<any>) => {
              const data = response.body;
              cleanState();

              // If there is a response object on the data and its value is true, the message that is displayed will
              // be an info message, otherwise an error message.
              if (data.responseObject != null) {
                if (data.responseObject) {
                  $scope.response = data;
                } else {
                  $scope.response = {
                    error: true,
                    message: data.message
                  };
                }
              }
            }),
            catchError((error: OldBodyResponse<null>) => {
              cleanState();

              if (error.errorCode === HttpStatusCode.FAILED_DEPENDENCY) {
                $scope.response = {
                  message: `Password reset successfully.
                    Please ask the user to review the list of active sessions within Device Management.`
                };
              } else {
                $scope.response = error;
              }

              return of(null);
            }),
            finalize(() => spinnerService.stop())
          ).subscribe();
        };

        this.timezoneChanged = () => {
          util.timezoneChanged($scope.user, $scope.timezones);
        };

        this.roleChanged = () => {
          delete $scope.noOtherActiveAdmins;
          $scope.user.role = $scope.roles.find(item => item.name == $scope.roleName);
          $scope.changedRole = true;
        };


        /**
         * Display leave prevention popup before disabling a user
         */
        this.checkAndPreventDisable = () => {
          // Prevent duplicate trigger of $locationChangeStart
          if (!isEqual($scope.getCurrentUserData(), $scope.savedData)) {
            const modalData = new GeneralModalData(
              resources.GENERAL.CONFIRM_NAVIGATION_TITLE,
              resources.GENERAL.CONFIRM_NAVIGATION_TEXT,
              resources.Actions.YES, resources.Actions.CANCEL
            );

            this.modalService.openConfirmModal(modalData)
              .subscribe((result: boolean) => {
                if (result) {
                  $scope.savedData = $scope.getCurrentUserData();
                  $scope.showDisableUserModal();
                }
              });
          } else {
            $scope.showDisableUserModal();
          }
        };

        this.showDisableUserModal = () => {
          if (!($scope.isLoggedInUserEditingHimself() && !$scope.activeAdmins)) {
            const modal = modalService.openTemplateModal(new TemplateModalData(
              'Disable User',
              DisableConfirmationUserComponent,
              {
                user: $scope.user,
                isEditingHimself: $scope.isLoggedInUserEditingHimself(),
              },
              resources.Actions.DISABLE_USER,
              resources.Actions.CANCEL,
              ModalSize.MEDIUM
            ));

            modal.pipe(filter((result: boolean) => result)).subscribe(() => {
              cleanState();

              if ($scope.isLoggedInUserEditingHimself()) {
                authService.logout();
              } else {
                userDaoService.disable($scope.user.id).subscribe((data: OldBodyResponse<string>) => {
                  $rootScope.response = {
                    user: data
                  };
                  $scope.redirectFromEditUserPage();
                });
              }
            });
          }
        };

        this.getClassForStatus = user => util.getClassForStatus(user, 'inlineText');

        /**
         * Displays a warning message when Email Preferences is set to My Accounts and My Accounts list is empty.
         * The message is different if the admin user is editing himself vs editing another user.
         */
        this.checkEmailPreferences = () => {
          $scope.invalidEmailPreferences =
            ($scope.user && ($scope.user.emailFilter === constants.MY_ACCOUNTS.EMAIL_PREFERENCES[1].val) &&
              CoreUtils.isDefined($scope.user.myAccounts) && !CoreUtils.isNull($scope.user.myAccounts) &&
              ($scope.user.myAccounts.length === 0));

          if ($scope.invalidEmailPreferences) {
            if ($scope.isLoggedInUserEditingHimself()) {
              $scope.invalidEmailPrefsMessage = $scope.myAccountsMessages.EMAIL_PREFERENCES_WARNING;
            } else {
              $scope.invalidEmailPrefsMessage = $scope.myAccountsMessages.EMAIL_PREFERENCES_WARNING_ON_EDIT_USER;
            }
          } else {
            $scope.invalidEmailPrefsMessage = '';
          }
        };

        /**
         * Get current user data
         * @returns {Object}
         */
        this.getCurrentUserData = () => angular.copy({
          roleName: $scope.roleName,
          lastName: $scope.user.lastName,
          firstName: $scope.user.firstName,
          title: $scope.user.title,
          phone: $scope.user.phone,
          myAccounts: $scope.user.myAccounts,
          emailFilter: $scope.user.emailFilter,
          timezone: $scope.user.timezone
        });

        this.getCurrentUserDataForSave = () => angular.copy({
          accessRights: $scope.user.accessRights,
          email: $scope.user.email,
          firstName: $scope.user.firstName,
          lastName: $scope.user.lastName,
          phone: $scope.user.phone,
          role: $scope.user.role,
          title: $scope.user.title,
          timezone: $scope.user.timezone
        });

        return this;
      }
    });

  }

  public $onInit(): void {
    this.emitConfirmNavigationCallback();
  }

  public isNavigationNeedConfirmation(): boolean {
    return this.$scope.user && (this.isEditUserState() && !isEqual(this.$scope.getCurrentUserData(), this.$scope.savedData))
      || (!this.isEditUserState() && !isEqual(this.$scope.getCurrentUserDataForSave(), this.$scope.savedData));
  }

  private isEditUserState(): boolean {
    const userId = this.router.routerState.snapshot.root.queryParams.userId;

    return userId && this.$scope.allowEditUser;
  }

  private parseResponse<T extends HttpErrorResponse>(): OperatorFunction<T | HttpResponse<T>, T | HttpResponse<T>> {
    return switchMap((response: HttpResponse<T>) => response.body.error
      ? throwError(response.body)
      : of(response)
    );
  }
};
// tslint:disable-next-line:max-file-line-count
export default UserController;
