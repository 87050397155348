/* eslint-disable no-underscore-dangle*/
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { UserState } from '@qv-user/entities/user-state.entity';
import { catchError, map, tap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { UserDaoService } from '@qv-common/services/dao';
import { User } from '@qv-user/entities/user.entity';
import { UserRole } from '@qv-user/enums/user-role.enum';

@Injectable()
export class UserService {

  private _userState = new BehaviorSubject<UserState>(null);

  get userState(): Observable<UserState> {
    return this._userState;
  }

  private _user = new BehaviorSubject<User>(null);

  get user(): BehaviorSubject<User> {
    return this._user;
  }

  private _myAccounts: Array<number>;

  get myAccounts(): Array<number> {
    return this._myAccounts;
  }

  set myAccounts(value: Array<number>) {
    this._myAccounts = value;
  }

  private _isUserLoggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  get isUserLoggedIn(): BehaviorSubject<boolean> {
    return this._isUserLoggedIn;
  }

  set isUserLoggedIn(value: BehaviorSubject<boolean>) {
    this._isUserLoggedIn = value;
  }

  constructor(private userDaoService: UserDaoService) { }

  public loadUserData(): Observable<UserState> {
    return this.userDaoService.getUserData().pipe(
      tap((userState: UserState) => {
        this.extractUserDataFromResponse(userState);
        this._isUserLoggedIn.next(true);
      }),
      catchError((error: HttpErrorResponse) => {
        this._isUserLoggedIn.next(false);

        return throwError(error);
      })
    );
  }

  public setCurrentUser(value: User): void {
    this._user.next(value);
  }

  public isCurrentUserQuantuvisAdmin(): boolean {
    return this._user.getValue().role.name === UserRole.QUANTUVIS_ADMIN;
  }

  public isCurrentUserPayer(): boolean {
    return this._user.getValue().isPayer;
  }

  public isCurrentUserPharma(): boolean {
    return this._user.getValue().isManufacturer;
  }

  public isCurrentUserPayerAdmin(): boolean {
    const user = this._user.getValue();
    return user.role.name === UserRole.PAYER_ADMIN;
  }

  public isCurrentUserPharmaAdmin(): boolean {
    const user = this._user.getValue();
    return user.role.name === UserRole.PHARMA_ADMIN;
  }

  public isLetterOfIntentAvailable(): boolean {
    return this._user.getValue().company.coverLetterEnabled;
  }

  public getUserTimezone(): string {
    return this._user.getValue().timezone.name;
  }

  public isFilterByMyAccountsEnabled(): Observable<boolean> {
    return this._user.pipe(map((data: User) => data.filterByMyAccounts));
  }

  public getUserType(): string {
    return this.hasUserType() ? this._user.getValue().role.type.toLowerCase() : '';
  }

  private hasUserType(): boolean {
    return !!this._user.getValue()?.role?.type;
  }

  private extractUserDataFromResponse(data: UserState): void {
    this._userState.next(data);
    this._user.next(data.user);
    this._myAccounts = data.myAccounts as Array<number>;
  }
}
