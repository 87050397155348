import { JsonObject, JsonProperty } from 'json2typescript';
import { Moment } from 'moment';
import { MomentConverter } from 'quantuvis-angular-common/json-converter/serializers';

@JsonObject('Note')
export class Note {
  @JsonProperty('id', Number)
  public id: number = null;

  @JsonProperty('value', String)
  public value: string = null;

  @JsonProperty('isHistoric', Boolean)
  public isHistoric: boolean = null;

  @JsonProperty('companyName', String)
  public companyName: string = null;

  @JsonProperty('companyId', Number)
  public companyId: number = null;

  @JsonProperty('createdAt', MomentConverter)
  public createdAt: Moment = null;
}
