<div class="standard-scenario" [class.standard-scenario--dismissed]="scenario.drug.isDismissed()">
  <div class="standard-scenario__sections"
    data-test-id="test-id-scenario"
    [class.standard-scenario__sections--selected]="drugSelectService.scenarioSelected() | async">
    <qv-standard-scenario-header
      [scenario]="scenario"
      [scenarioStateConfig]="scenarioStateConfig"
      (toggleNdcsEvent)="toggleNdcs()"
    ></qv-standard-scenario-header>
    <div class="standard-scenario__body" (click)="transitToEditMode()">
      <qv-standard-scenario-terms *ngIf="filter[sectionFilterEnum.CONTRACT_TERMS].checked"
        [scenarioId]="scenario.id"
        [contract]="scenario.drug.contract"
        [summary]="summary | async"
        [isBidInvalid]="isBidInvalid"
        [isBidInternal]="isBidInternal"
      ></qv-standard-scenario-terms>
      <qv-standard-rebate
        [rebate]="scenario.drug.rebate"
        [marketBasket]="scenario.marketBasket"
        [marketBasketLock]="scenario.marketBasketLock"
        [scenarioId]="scenario.id"
        [cbId]="scenario.cbId"
        [bidStatus]="$any(bidStatus)"
        [isBidInvalid]="isBidInvalid"
        [isBidInternal]="isBidInternal"
        [drugName]="scenario.drugName"
        [drugClass]="scenario.drug.drugClass"
        [drugStatus]="scenario.drug.status"
        [netEffectivePrice]="scenario.drug.priceProtection?.netEffectivePrice"
        [isDismissed]="scenario.drug.isDismissed()"
        [manufacturerCompanyOldName]="manufacturerCompanyOldName"
        [manufacturerCompanyId]="manufacturerCompanyId"
        (marketBasketUpdated)="onMarketBasketUpdated($event)"
        (notesUpdated)="onUpdateNotes()"
        (historicNotesDeleted)="onDeleteHistoricNotes()"
      ></qv-standard-rebate>
      <qv-standard-um *ngIf="filter[sectionFilterEnum.UM].checked"
        [scenarioId]="scenario.id"
        [utilizationManagement]="scenario.drug.utilizationManagement"
        [isBidInvalid]="isBidInvalid"
        [isBidInternal]="isBidInternal"
      ></qv-standard-um>
      <qv-standard-market-share
        *ngIf="filter[sectionFilterEnum.MARKET_SHARE].checked"
        [marketShare]="scenario.drug.marketShare"
        [scenarioId]="scenario.id"
        [isBidInvalid]="isBidInvalid"
        [isBidInternal]="isBidInternal"
      ></qv-standard-market-share>
      <qv-standard-price-protection *ngIf="filter[sectionFilterEnum.PRICE_PROTECTION].checked"
        [scenarioId]="scenario.id"
        [priceProtection]="scenario.drug.priceProtection"
        [isBidInvalid]="isBidInvalid"
        [isBidInternal]="isBidInternal"
      ></qv-standard-price-protection>
    </div>
  </div>
  <div class="standard-scenario__ndcs"
    [@collapseExpand]="getState() | async"
    (@collapseExpand.done)="emitRefreshVirtualScroller($event)">
    <qv-standard-ndc *ngFor="let ndc of ndcs$ | async; trackBy: trackByNdcIdFn"
      [ndc]="ndc"
      [scenario]="scenario"
      [isBidInvalid]="isBidInvalid"
      [filter]="filter"
      [isBidInternal]="isBidInternal"
      [bidStatus]="$any(bidStatus)"
      [manufacturerCompanyOldName]="manufacturerCompanyOldName"
      [manufacturerCompanyId]="manufacturerCompanyId"
      (reloadScenarioEvent)="reloadRowDataScenario()"
    ></qv-standard-ndc>
  </div>
</div>
