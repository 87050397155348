import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CompaniesComponent } from '@qv-ng1-wrapper/components/companies/companies.component';
import { CopyBidComponent } from '@qv-ng1-wrapper/components/copy-bid/copy-bid.component';
import { CreateBidComponent } from '@qv-ng1-wrapper/components/create-bid/create-bid.component';
import { CreateCompanyComponent } from '@qv-ng1-wrapper/components/create-company/create-company.component';
import { CreateInternalBidComponent } from '@qv-ng1-wrapper/components/create-internal-bid/create-internal-bid.component';
import { CreateUserComponent } from '@qv-ng1-wrapper/components/create-user/create-user.component';
import { DashboardComponent } from '@qv-ng1-wrapper/components/dashboard/dashboard.component';
import { DrugExceptionsComponent } from '@qv-ng1-wrapper/components/drug-exceptions/drug-exceptions.component';
import { DrugSearchComponent } from '@qv-ng1-wrapper/components/drug-search/drug-search.component';
import { EditCompanyComponent } from '@qv-ng1-wrapper/components/edit-company/edit-company.component';
import { EditUserComponent } from '@qv-ng1-wrapper/components/edit-user/edit-user.component';
import { EnterpriseComponent } from '@qv-ng1-wrapper/components/enterprise/enterprise.component';
import { ManufacturerMappingComponent } from '@qv-ng1-wrapper/components/manufacturer-mapping/manufacturer-mapping.component';
import { NotificationsComponent } from '@qv-ng1-wrapper/components/notifications/notifications.component';
import { PeopleComponent } from '@qv-ng1-wrapper/components/people/people.component';
import { ProductEnrollmentComponent } from '@qv-ng1-wrapper/components/product-enrollment/product-enrollment.component';
import { ProfileComponent } from '@qv-ng1-wrapper/components/profile/profile.component';
import { RevisionComparisonComponent } from '@qv-ng1-wrapper/components/revision-comparison/revision-comparison.component';
import { RightsComponent } from '@qv-ng1-wrapper/components/rights/rights.component';
import { Permission } from '@qv-common/enums';
import { ConfirmNavigationGuard, PayerWriteAccessGuard, PermissionGuard } from '@qv-common/guards';
import { QuantuvisBusinessFeature, QuantuvisPlusFeature } from '@qv-company/enums';

const routes: Routes = [
  {
    path: 'dashboard',
    component: DashboardComponent,
  },
  {
    path: 'createBid',
    component: CreateBidComponent,
    data: { permission: Permission.BIDS },
    canActivate: [PermissionGuard, PayerWriteAccessGuard],
    canDeactivate: [ConfirmNavigationGuard],
  },
  {
    path: 'copy-bid',
    component: CopyBidComponent,
    data: { permission: Permission.BIDS },
    canActivate: [PermissionGuard],
    canDeactivate: [ConfirmNavigationGuard],
  },
  {
    path: 'notifications',
    component: NotificationsComponent,
    data: { permission: Permission.NOTIFICATIONS },
    canActivate: [PermissionGuard],
  },
  {
    path: 'quantuvisplus',
    component: EnterpriseComponent,
    data: { permission: Permission.ENTERPRISE },
    canActivate: [PermissionGuard],
  },
  {
    path: 'enterprise',
    redirectTo: '/quantuvisplus'
  },
  {
    path: 'profileSettings',
    component: ProfileComponent,
    data: { permission: Permission.PROFILE },
    canActivate: [PermissionGuard],
    canDeactivate: [ConfirmNavigationGuard],
  },
  {
    path: 'drugSearch',
    component: DrugSearchComponent,
  },
  {
    path: 'bid-revision-comparison',
    data: { permission: Permission.BIDS, feature: QuantuvisPlusFeature.REVISION_COMPARISON },
    canActivate: [PermissionGuard],
    component: RevisionComparisonComponent,
  },
  {
    path: 'create-internal-bid',
    redirectTo: 'createInternalBid'
  },
  {
    path: 'createInternalBid',
    data: { permission: Permission.BIDS, feature: QuantuvisBusinessFeature.INTERNAL_BIDS },
    canActivate: [PermissionGuard],
    component: CreateInternalBidComponent,
    canDeactivate: [ConfirmNavigationGuard],
  },
  {
    path: 'rights',
    data: { permission: Permission.EDIT_ACCESS_RIGHTS },
    canActivate: [PermissionGuard],
    component: RightsComponent,
  },
  {
    path: 'people',
    data: { permission: Permission.PEOPLE },
    canActivate: [PermissionGuard],
    component: PeopleComponent,
  },
  {
    path: 'administrator',
    data: { permission: Permission.QUANTUVIS_ADMIN },
    canActivate: [PermissionGuard],
    component: CompaniesComponent,
  },
  {
    path: 'createCompany',
    data: { permission: Permission.QUANTUVIS_ADMIN },
    canActivate: [PermissionGuard],
    component: CreateCompanyComponent,
    canDeactivate: [ConfirmNavigationGuard],
  },
  {
    path: 'editCompany',
    data: { permission: Permission.QUANTUVIS_ADMIN },
    canActivate: [PermissionGuard],
    component: EditCompanyComponent,
    canDeactivate: [ConfirmNavigationGuard],
  },
  {
    path: 'drugExceptions',
    data: { permission: Permission.QUANTUVIS_ADMIN },
    canActivate: [PermissionGuard],
    component: DrugExceptionsComponent,
    canDeactivate: [ConfirmNavigationGuard],
  },
  {
    path: 'manufacturerMapping',
    data: { permission: Permission.QUANTUVIS_ADMIN },
    canActivate: [PermissionGuard],
    component: ManufacturerMappingComponent,
  },
  {
    path: 'productEnrollment',
    data: { permission: Permission.QUANTUVIS_ADMIN },
    canActivate: [PermissionGuard],
    component: ProductEnrollmentComponent,
  },
  {
    path: 'createUser',
    data: { permission: Permission.NEW_USER },
    canActivate: [PermissionGuard],
    component: CreateUserComponent,
    canDeactivate: [ConfirmNavigationGuard],
  },
  {
    path: 'editUser',
    data: { permission: Permission.EDIT_USER },
    canActivate: [PermissionGuard],
    component: EditUserComponent,
    canDeactivate: [ConfirmNavigationGuard],
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
  ],
  exports: [RouterModule]
})
export class Ng1WrapperRoutingModule {}
