import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { HttpHeader, ResponseType, HttpMimeType } from 'quantuvis-angular-common/api';
import { AuthService } from '../services';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {}

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.includes(this.authService.issuer)
      || (!!this.authService.tokenEndpoint && req.url.includes(this.authService.tokenEndpoint))) {
      return next.handle(req);
    }

    if (!req.responseType) {
      req = req.clone({ responseType: ResponseType.JSON });
    }

    if (!req.headers.has(HttpHeader.CONTENT_TYPE) && !(req.body instanceof FormData)) {
      req = req.clone({ headers: req.headers.set(HttpHeader.CONTENT_TYPE, HttpMimeType.JSON) });
    }

    req = req.clone( {
      headers: req.headers
        .set(HttpHeader.AUTHORIZATION, this.token)
        .set(HttpHeader.X_CLIENT_TOKEN, this.authService.clientToken || '')
        // Disable ajax request caching in IE browser
        .set(HttpHeader.CACHE_CONTROL, 'no-cache')
        .set(HttpHeader.PRAGMA, 'no-cache')
        .set(HttpHeader.EXPIRES, '0')
    });

    return next.handle(req);
  }

  private get token(): string {
    return this.authService.hasValidSecurityToken ? `Bearer ${this.authService.securityToken}` : '';
  }
}
