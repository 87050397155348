<div class="col-12 itr">
  <h1 data-test-id="intend-to-respond-title">
    Intend To Respond
    <span class="float-right">
      <span class="btn btn-primary" (click)="save()">Save</span>
      <span class="btn btn-secondary btn-cancel" (click)="backToBidDetails()">Cancel</span>
    </span>
  </h1>

  <div class="itr__content">
    <div class="row">
      <div class="col-6">
        <p class="itr__notice">Please select all drugs that you will <strong>NOT</strong> submit a rebate offer on for this
          RFP</p>
        <span class="input-group input-append spacer bottom-margin">
          <input class="search-input-large form-control"
              #input
            type="text"
            [formControl]="control"
            [placeholder]="placeholder"
            maxlength="100">
          <span class="input-group-addon appended-suffix">
              <span class="icon fa fa-search qv-icon-gray"></span>
          </span>
        </span>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="list complex hasLeftBorder col-6">
      <h3>Drugs <strong>WITH</strong> a rebate offer</h3>
      <span class="listItem clickable"
        *ngFor="let drug of drugList"
        (click)="addToSelection(drug)">
        {{ drug.name }}
      </span>
    </div>

    <div class="list col-6 dismissedList">
      <h3>Drugs <strong>WITHOUT</strong> a rebate offer</h3>
      <span class="listItem clickable innerDrug canDelete"
        *ngFor="let sel of selectedDrugList"
        (click)="removeFromSelection(sel)">
        {{ sel.name }}
        <span class="dismissed">
          - Dismissed{{ sel.statusChangedByCompany && ' by ' + sel.statusChangedByCompany }} -
      </span>
        <span *ngIf="!sel.isDismissed()" class="icon fa fa-remove qv-icon-gray"></span>
      </span>
    </div>
  </div>
</div>
