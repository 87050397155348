import { Component, Inject } from '@angular/core';
import { SvgIconName } from '@qv-common/enums';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  ScenarioDependencyModalData
} from '@qv-bid/components/shared/scenario-dependency-modal/models/scenario-dependency-modal-data';

@Component({
  selector: 'qv-scenario-dependency-modal',
  templateUrl: './scenario-dependency-modal.component.html',
  styleUrls: ['./scenario-dependency-modal.component.scss']
})
export class ScenarioDependencyModalComponent {
  public readonly title = 'View Dependency';
  public readonly svgIconName = SvgIconName;

  constructor(private dialogRef: MatDialogRef<ScenarioDependencyModalComponent>,
              @Inject(MAT_DIALOG_DATA) public modalData: ScenarioDependencyModalData) {
  }

  public onClose(): void {
    this.dialogRef.close();
  }

}
