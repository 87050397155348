import { Contract } from '@qv-bid/entities/contract.entity';
import { Rebate } from '@qv-bid/entities/rebate.entity';
import { JsonObject, JsonProperty } from 'json2typescript';
import { PriceProtection } from '@qv-bid/entities/price-protection.entity';
import { MarketShare } from '@qv-bid/entities/market-share.entity';
import { DrugScenarioStatus } from '@qv-bid/enums';
import { DrugScenarioStatusConverter } from '@qv-bid/serializers';
import { UtilizationManagement } from '@qv-bid/entities/utilization-management.entity';
import { BidUtils } from '@qv-bid/utils';
import { DrugAlert } from '@qv-bid/entities/drug-alert.entity';

@JsonObject('Ndc')
export class Ndc {
  @JsonProperty('drugId', Number)
  public drugId: number = null;

  @JsonProperty('drugClass', String)
  public drugClass: string = null;

  @JsonProperty('description', String)
  public description: string = null;

  @JsonProperty('drugAlerts')
  public drugAlerts: DrugAlert[] = [];

  @JsonProperty('isLocked', Boolean)
  public isLocked: boolean = null;

  @JsonProperty('marketShare', MarketShare)
  public marketShare: MarketShare = null;

  @JsonProperty('versionWAC')
  public versionWAC: number | string = null;

  @JsonProperty('ndc', String)
  public ndc: string = null;

  @JsonProperty('currentWAC')
  public currentWAC: number | string = null;

  @JsonProperty('deltaWAC')
  public deltaWAC: number | string = null;

  @JsonProperty('deltaPWAC')
  public deltaPWAC: number | string = null;

  @JsonProperty('rebate', Rebate)
  public rebate: Rebate = null;

  @JsonProperty('priceProtection', PriceProtection)
  public priceProtection: PriceProtection = null;

  @JsonProperty('utilizationManagement', UtilizationManagement)
  public utilizationManagement: UtilizationManagement = null;

  @JsonProperty('contract', Contract)
  public contract: Contract = null;

  @JsonProperty('status', DrugScenarioStatusConverter)
  public status: DrugScenarioStatus = null;

  @JsonProperty('statusChangedBy', String)
  public statusChangedBy: string = null;

  public isDismissed(): boolean {
    return BidUtils.isDismissed(this.status);
  }
}
