import { SelectBoxTerm } from '@qv-term/models/types';
import { constants, resources } from '@qv-common/static';
import { NoFaultTerminationClauseWhoMayInvoke } from '@qv-term/enums/options';

export class NoFaultTerminationClauseWhoMayInvokeTerm extends SelectBoxTerm {
  constructor(name: string) {
    super(
      name,
      name,
      resources.TermsDescriptions.CONTRACT.NO_FAULT_TERMINATION_CLAUSE_WHO_MAY_INVOKE,
      constants.CONTRACT_TERMS.NO_FAULT_TERMINATION_CLAUSE_WHO_MAY_INVOKE.label,
      '',
      NoFaultTerminationClauseWhoMayInvoke.getValue(NoFaultTerminationClauseWhoMayInvoke.EMPTY),
      'term__no-fault-termination-clause-who-may-invoke'
    );
    this.values = NoFaultTerminationClauseWhoMayInvoke.getValues();
  }
}
