import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, } from '@angular/core';
import { UmSection } from '@qv-bid/classes/sections';
import { StandardDrugFormService } from '@qv-bid/services/drug';
import { UtilizationManagementDaoService } from '@qv-bid/services/dao';
import { SectionChangeManager } from '@qv-bid/services/sections/section-change-manager.service';

@Component({
  selector: 'qv-standard-um',
  templateUrl: './standard-um.component.html',
  styleUrls: ['./standard-um.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StandardUmComponent extends UmSection implements OnInit, OnDestroy {
  public readonly dropDownAppendTo = 'qv-standard-scenario';

  public constructor(
    standardDrugFormService: StandardDrugFormService,
    utilizationManagementDaoService: UtilizationManagementDaoService,
    changeDetectorRef: ChangeDetectorRef,
    sectionChangeManager: SectionChangeManager,
  ) {
    super(standardDrugFormService, utilizationManagementDaoService, changeDetectorRef, sectionChangeManager);
  }

  public ngOnInit(): void {
    this.initHandlers();
  }
}
