<qv-bid-view>
  <div class="standard-view" #scrollContent>
    <mat-tab-group #tabGroup class="tab-group tab-group--full-width"
      (selectedIndexChange)="onSelectedIndexChange($event)">
      <mat-tab class="tab"
        *ngFor="let cb of (bidVersionContractedBusinesses$ | async) as contractedBusinesses; let tabIndex = index"
        [disabled]="isBidInvalid$ | async">
        <ng-template mat-tab-label>
          <h2
            class="tab__title"
            data-test-id="contracted-business-tab"
            [class.tab__title--with-menu]="isManagingCBAvailable(manageCBService.cbManageState$.getValue(), tabIndex, tabGroup.selectedIndex)"
          >{{ cb.name }}</h2>
          <qv-standard-cb-manage-menu
            class="tab__manage-menu"
            *ngIf="isManagingCBAvailable(manageCBService.cbManageState$.getValue(), tabIndex, tabGroup.selectedIndex)"
            [cb]="cb"
            [cbList]="contractedBusinesses"
            [isDisabled]="isBidInvalid$ | async"
            (cbAddEvent)="onAddCB($event)"
            (cbDeleteEvent)="onDeleteCB()"
            (cbRenameEvent)="onRenameCB()"
            (cbImportEvent)="onImportCB()"
          ></qv-standard-cb-manage-menu>
        </ng-template>
        <ng-template matTabContent>
          <qv-standard-header
            [cbId]="cb.id"
            [bidVersionId]="bidViewService.getBidVersionId()"
            [pharmaId]="(bidStateService.bid$ | async)?.manufacturer.id">
          </qv-standard-header>
          <virtual-scroller #virtualScroll
            class="virtual-scroller"
            [items]="bidStateService.scenarios$ | async"
            [enableUnequalChildrenSizes]="true"
            [bufferAmount]="bidViewConfig.buffer"
            (vsUpdate)="onScrollUpdate($event)"
            (vsEnd)="onScrollEnd($event)">
            <ng-container *ngFor="let scenario of virtualScroll.viewPortItems; trackBy: trackByScenarioId">
              <qv-standard-scenario
                *ngIf="scenario.drug"
                [filter]="filter"
                [scenario]="scenario"
                [scenarioStateConfig]="scenarioConfigListService.getScenarioStateConfig(scenario.uuid)"
                [bidVersionId]="bidViewService.getBidVersionId()"
                [isBidInvalid]="bidStateService.isBidInvalid() | async"
                [isBidInternal]="(bidStateService.bid$ | async)?.isInternal"
                [bidStatus]="(bidStateService.bid$ | async)?.status"
                [manufacturerCompanyOldName]="(bidStateService.bid$ | async)?.manufacturerCompanyOldName"
                [manufacturerCompanyId]="(bidStateService.bid$ | async)?.manufacturer.id"
              ></qv-standard-scenario>
              <qv-standard-scenario-placeholder *ngIf="!scenario.drug"></qv-standard-scenario-placeholder>
            </ng-container>
            <div class="standard-view__empty-scenarios"
                 *ngIf="bidViewService.isNoFilteredScenarios(bidViewService.isReady$.getValue(),
              bidStateService.scenarios$.getValue().length)">
              <qv-empty-scenarios-message></qv-empty-scenarios-message>
            </div>
          </virtual-scroller>
        </ng-template>
      </mat-tab>
      <mat-tab *ngIf="(manageCBService.cbManageState$ | async) && (bidVersionContractedBusinesses$ | async).length"
        [disabled]="isBidInvalid$ | async">
        <ng-template mat-tab-label>
          <button
            tabindex="-1"
            class="tab-group tab-group__create-button"
            data-test-id="test-id-add-cb-button"
            [disabled]="isBidInvalid$ | async"
            (click)="createButtonClickHandler($event)">
            <qv-icon [iconName]="svgIconName.ADD_OUTLINE"></qv-icon>
          </button>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </div>
</qv-bid-view>
