import { ChangeDetectorRef, Directive, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Range } from '@qv-common/entities';
import { Moment } from 'moment';
import { FormGroup } from '@angular/forms';
import { resources } from '@qv-common/static';
import isEqual from 'lodash.isequal';
import { CoreUtils, StringUtils } from '@qv-common/utils';
import { distinctUntilChanged } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Directive()
export class BaseRangeFilter implements OnChanges {
  @Input()
  public hideSubmitButton = false;

  @Input()
  public range: Range<number | Moment>;

  @Output()
  public rangeChanged = new EventEmitter<Range<number | Moment>>();

  @Output()
  public closeEvent = new EventEmitter<void>();

  public rangeForm: FormGroup;

  public readonly resources = resources;

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  public ngOnChanges({ range }: SimpleChanges): void {
    if (range && !range.firstChange && !range.currentValue.invalid) {
      this.patchForm();
    }
  }

  public onSubmit(changedRange: Range<number | Moment>): void {
    if (!isEqual(this.range, changedRange)) {
      this.rangeChanged.emit(changedRange);
    }
    this.closeEvent.emit();
  }

  public initFromFieldChangesHandler(): void {
    this.rangeForm.get('from').valueChanges
      .pipe(
        distinctUntilChanged(),
        untilDestroyed(this)
      )
      .subscribe(() => {
        const to = this.rangeForm.get('to').value;
        if (!StringUtils.isEmpty(to) && !CoreUtils.isNull(to)) {
          this.rangeForm.get('to').updateValueAndValidity();
        }
      });
  }

  public setAppliedValues(): void {
    this.patchForm();
    this.changeDetectorRef.markForCheck();
  }

  private patchForm(): void {
    this.rangeForm.patchValue({
      from: this.range.from,
      to: this.range.to
    });
  }
}
