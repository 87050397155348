import { ChangeDetectorRef, Directive, Input, OnDestroy, ViewRef } from '@angular/core';
import { SectionChangeManager } from '@qv-bid/services/sections/section-change-manager.service';
import { TermName, TermSection } from '@qv-term/enums';
import { DrugFormService } from '@qv-bid/services/drug';
import { FormControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, OperatorFunction, pipe, Subject } from 'rxjs';
import { distinctUntilChanged, filter, finalize, takeUntil, takeWhile } from 'rxjs/operators';

@UntilDestroy()
@Directive()
export class BaseSection implements OnDestroy {
  @Input()
  public isBidInvalid: boolean;

  public isUserPharma: boolean;
  public isEditMode = false;
  public isSectionInViewport = false;

  public readonly sectionName: TermSection;

  protected destroyed$ = new Subject();
  protected readonly cellsReadonlyState: Map<TermName, boolean> = new Map<TermName, boolean>();

  constructor(
    public drugFormService: DrugFormService,
    protected changeDetectorRef: ChangeDetectorRef,
    protected sectionChangeManager: SectionChangeManager,
  ) {}

  public getFormControlByTermName(termName: TermName): FormControl {
    return this.drugFormService.getTermControl(termName, this.sectionName);
  }

  public getCellReadOnlyState(termName: TermName): boolean {
    return this.cellsReadonlyState.get(termName);
  }

  public setCellReadOnlyState(termName: TermName, state: boolean): void {
    this.cellsReadonlyState.set(termName, state);
    this.changeDetectorRef.detectChanges();
  }

  public ngOnDestroy(): void {
    if (this.sectionChangeManager.isSectionChanging$.value) return;
    this.destroyed$.next(null);
    this.destroyed$.complete();
  }

  protected sectionInViewPortHandler(sectionViewPortChangeObservable: Observable<boolean>): void {
    sectionViewPortChangeObservable.pipe(
      distinctUntilChanged(),
      takeWhile(() => !this.isSectionInViewport),
      untilDestroyed(this),
    ).subscribe((isSectionInViewport: boolean) => {
      this.isSectionInViewport = isSectionInViewport;
      setImmediate(() => {
        this.changeDetectorRef.markForCheck();
      });
    });
  }

  protected subscriptionLiveHandler(): void {
    if ((this.changeDetectorRef as ViewRef).destroyed && !this.sectionChangeManager.isSectionChanging$.value) {
      this.destroyed$.next(null);
      this.destroyed$.complete();
    }
  }

  protected sectionLeaveHandler<T>(): OperatorFunction<T, T> {
    return pipe(
      takeUntil(this.destroyed$),
      filter(() => !(this.changeDetectorRef as ViewRef).destroyed),
      finalize(() => this.subscriptionLiveHandler()),
    );
  }
}
