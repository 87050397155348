/* tslint:disable:triple-equals */
import { constants } from '@qv-common/static';
import { NumberUtils } from '@qv-common/utils';
import { appConfig } from '@qv-common/configs';
import { MimeDefinition } from '../interfaces';
// @ts-ignore
import template from '../../views/components/fileupload.html';

declare let angular: angular.IAngularStatic;
declare let $: any;

export const FileUpload = ['translations', 'spinnerService', (translations, spinnerService): any => {
  'ngInject';
  return {
    restrict: 'A',
    template,
    replace: true,
    scope: {
      resource: '=',
      delayedExecution: '=',
      acceptedFormats: '=',
      acceptedMimeTypes: '=',
      returnResponse: '<'
    },
    link: (scope, element) => {
      scope.fileToUpload = {};
      translations.buildTranslations(scope, 'FILE_UPLOAD');

      /**
       * listening to an event from parent scope to see if we need to set a default file name in the input
       */
      scope.$on(constants.EVENTS.SET_DEFAULT_FILENAME, (event, data) => {
        scope.fileToUpload.name = data;
      });

      const el = angular.element(element);
      const pathLabel = el[0].children[0];
      const fileInputContainer = el[0].children[1];

      const fileInput = angular.element('<input type="file" name="file"/>');

      if (scope.acceptedFormats) {
        fileInput.attr('accept', scope.acceptedFormats)
      }

      initializeFileUploadPlugin(fileInput, scope);

      $(pathLabel).css({
        cursor: 'pointer',
        color: 'gray'
      });

      angular.element(fileInputContainer).append(fileInput);
    }
  };

  function refresh(scope): void {
    if (!scope.$$phase) {
      scope.$digest();
    }
  }

  function initializeFileUploadPlugin(fileInput, scope): void {
    $(fileInput).fileupload({
      singleFileUploads: true,
      autoUpload: false,
      dataType: 'text',
      type: 'POST'
    }).on(
      'fileuploadadd',
      (event, data) => {
        scope.fileToUpload.name = data.files[0].name;
        if (isFileSizeAcceptable(data.files[0])) {
          processUploadedFile(scope, data);
        } else {
          scope.$emit(constants.EVENTS.FILE_UPLOADED, {
            error: true,
            message: `File(s) exceed ${NumberUtils.bytesToMegabytes(appConfig.maxFileSize)} MB limit`,
            index: scope.$index
          });
          refresh(scope);

          spinnerService.stop();
        }
      });
  }

  function isFileSizeAcceptable(file: Blob): boolean {
    return !('size' in file) || file.size <= appConfig.maxFileSize;
  }

  function executeUpload(scope, data): void {
    let uploadResponse: any = {};

    data.submit().done(resp => {
      resp = angular.fromJson(resp);
      // check if error happened in the back-end
      if (resp.error) {
        // check for special error codes
        if (resp.errorCode == -1) {
          // if error code equals -1 it means that the file cannot be empty
          uploadResponse.error = true;
          uploadResponse.message = scope.i18n.EMPTY_FILE_ERROR_MSG;
        } else if (resp.errorCode == -2) {
          // if error code equals -2 it means that the file format is not supported
          uploadResponse.error = true;
          uploadResponse.message = scope.i18n.FILE_FORMAT_ERROR_MSG;
        } else {
          uploadResponse.error = true;
          uploadResponse.message = scope.i18n.GENERAL_UPLOAD_FAIL;
        }
      } else {
        if (scope.returnResponse) {
          uploadResponse = resp;
        } else {
          uploadResponse.error = false;
        }
      }

      spinnerService.stop();
      // notify the parent scope with the upload response
      scope.$emit(constants.EVENTS.FILE_UPLOADED, uploadResponse);
    }).fail(() => {
      spinnerService.stop();
      uploadResponse.error = true;
      uploadResponse.message = scope.i18n.GENERAL_UPLOAD_FAIL;
      // notify the parent scope with the upload response
      scope.$emit(constants.EVENTS.FILE_UPLOADED, uploadResponse);
    });
  }

  function processUploadedFile(scope, data): void {
    validateFileMimeType(scope.acceptedMimeTypes, data.files[0])
      .then(() => {
        if (scope.delayedExecution) {
          // notify the parent scope that a file was selected
          scope.$emit(constants.EVENTS.FILE_SELECTED, true);

          // listening to an event that will trigger the submit for file upload service
          scope.$on(constants.EVENTS.DELAYED_UPLOAD, (e, newUrl) => {
            data.url = newUrl;
            executeUpload(scope, data);
          });
        } else {
          data.url = scope.resource;

          spinnerService.start('Uploading...');

          executeUpload(scope, data);
        }
      })
      .catch(() => {
        scope.fileToUpload.name = '';

        scope.$emit(constants.EVENTS.FILE_UPLOADED, {
          error: true,
          message: `File format is not supported or file is broken`,
          index: scope.$index
        });
      });
  }

  function validateFileMimeType(acceptedMimeTypes: MimeDefinition[], file: Blob): Promise<void> {
    return new Promise((resolve, reject) => {
      if (acceptedMimeTypes) {
        const fileReader = new FileReader();

        fileReader.onloadend = (event: ProgressEvent<FileReader>) => {
          const firstFileBytes = (new Uint8Array(event.target.result as ArrayBuffer)).subarray(0, 4);
          const isFileTypeValid = acceptedMimeTypes.some((type: MimeDefinition) => isMimeTypeAcceptable(firstFileBytes, type));

          isFileTypeValid ? resolve() : reject();
        };

        fileReader.readAsArrayBuffer(file);
      } else {
        resolve();
      }
    });
  }

  function isMimeTypeAcceptable(bytes: Uint8Array, mime: MimeDefinition): boolean {
    return mime.mask.some((mask: number, index: number) => (bytes[index] & mask) - mime.pattern[index] === 0);
  }
}];
