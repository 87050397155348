import { MimeDefinition } from '../interfaces';

export const imageMimeDefinitions: MimeDefinition[] = [
  {
    format: 'jpeg',
    pattern: [0xFF, 0xD8, 0xFF],
    mask: [0xFF, 0xFF, 0xFF],
  },
  {
    format: 'jpg',
    pattern: [0xFF, 0xD8, 0xFF],
    mask: [0xFF, 0xFF, 0xFF],
  },
  {
    format: 'png',
    pattern: [0x89, 0x50, 0x4E, 0x47],
    mask: [0xFF, 0xFF, 0xFF, 0xFF],
  },
  {
    format: 'bmp',
    pattern: [0x42, 0x4d],
    mask: [0xFF, 0xFF],
  },
  {
    format: 'gif',
    pattern: [0x47, 0x49, 0x46, 0x38],
    mask: [0xFF, 0xFF, 0xFF, 0xFF],
  }
];
