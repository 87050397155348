import { TermName } from '@qv-term/enums';
import { JsonObject, JsonProperty } from 'json2typescript';
import { NdcDictionaryValue, NdcValue } from '@qv-term/entities';

@JsonObject('UtilizationManagement')
export class UtilizationManagement {
  @JsonProperty(TermName.STATE, NdcDictionaryValue)
  public state: NdcDictionaryValue = null;

  @JsonProperty(TermName.STEP_THERAPY_ALLOWED_ON_PRODUCT, NdcDictionaryValue)
  public stepTherapyAllowed: NdcDictionaryValue = null;

  @JsonProperty(TermName.STEP_THERAPY_REQUIRED_ON_COMPETITORS_PRODUCTS, NdcDictionaryValue)
  public stepTherapyRequired: NdcDictionaryValue = null;

  @JsonProperty(TermName.QUANTITY_LIMIT, NdcDictionaryValue)
  public quantityLimit: NdcDictionaryValue = null;

  @JsonProperty(TermName.PRIOR_AUTHORIZATION_ALLOWED_ON_PRODUCT, NdcDictionaryValue)
  public priorAuthorizationAllowed: NdcDictionaryValue = null;

  @JsonProperty(TermName.PRIOR_AUTHORIZATION_REQUIRED_ON_COMPETITORS_PRODUCTS, NdcDictionaryValue)
  public priorAuthorizationRequired: NdcDictionaryValue = null;

  @JsonProperty(TermName.OTHER_UM, NdcDictionaryValue)
  public otherUM: NdcDictionaryValue = null;

  @JsonProperty(TermName.UM_DETAILS, NdcValue)
  public umDetails: NdcValue<string> = null;
}
