import { DateTerm } from '@qv-term/models/types';
import { KeepOriginalValue, Reset } from '@qv-common/interfaces';
import { constants, resources } from '@qv-common/static';

export class ContractStartDateTerm extends DateTerm implements Reset, KeepOriginalValue {
  public allowKeepOriginValue: boolean;
  public processKeepOriginalValue: boolean;
  public keepOriginalValue: boolean;

  constructor(name: string) {
    super(
      name,
      name,
      resources.TermsDescriptions.SUMMARY.CONTRACT_START_DATE,
      constants.SUMMARY_TERMS.CONTRACT_START_DATE.label,
      null,
      null,
      'term__contract-start-date',
      false
    );

    this.keepOriginalValue = this.allowKeepOriginValue;
    this.processKeepOriginalValue = false;
  }

  public getValueToSave(): number {
    // TODO need add KeepOriginal logic
    return super.getValueToSave();
  }

  public resetValue(): void {
    // TODO need add KeepOriginal logic
    this.value = this.defaultValue;
  }
}
