import { BlockUISettings } from 'ng-block-ui/models/block-ui-settings.model';
import { toastrConfig } from 'quantuvis-angular-common/notification';
import { formats } from 'quantuvis-angular-common/date-utils';

export const appConfig = {
  toastrConfig,
  dateFormatConfig: formats.dateFormatConfig,
  blockUIConfig: {
    delayStop: 0
  } as BlockUISettings,
  dateFormat: 'MM/DD/YYYY',
  dateFormatShort: 'MM/DD/YY',
  dateTimeFormat: 'MM/DD/YYYY hh:mm A',
  dateTimeFormatShort: 'MM/DD/YY hh:mm A',
  maxFileSize: 26214400, // 25 MB,
  trackingCode: 'UA-42417086-4',
  storeConfig: {
    runtimeChecks: {}
  }
};
