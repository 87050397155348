import { Inject, Injectable } from '@angular/core';
import { RemoteDataSource } from '@qv-table/data-sources';
import { Bid } from '@qv-bid/entities';
import { JsonConvert, OperationMode, ValueCheckingMode } from 'json2typescript';
import { LocalStorageService } from 'ngx-webstorage';
import { SpinnerService } from '@qv-common/services';
import { BlockingMessage, LocalStorageKeys } from '@qv-common/enums';
import { catchError, finalize, tap } from 'rxjs/operators';
import { PaginatedResponseWrapper, Range } from '@qv-common/entities';
import { HttpErrorResponse } from '@angular/common/http';
import { JSON_CONVERT_TOKEN } from 'quantuvis-angular-common/json-converter/services';
import { BidListFilterState, BidListRemoteState } from '@qv-bid-list/models';
import { BidListDaoService } from '@qv-bid-list/services/dao';
import { Moment } from 'moment';
import { defaultPageSize } from '@qv-table/constants';
import { NotificationService } from 'quantuvis-angular-common/notification';

@Injectable()
export class BidListRemoteDataSource extends RemoteDataSource<Bid, BidListFilterState> {
  constructor(
    private bidListDaoService: BidListDaoService,
    @Inject(JSON_CONVERT_TOKEN) protected jsonConvert: JsonConvert,
    private localStorageService: LocalStorageService,
    private spinnerService: SpinnerService,
    private notificationService: NotificationService,
  ) {
    super(jsonConvert, BidListRemoteDataSource.deserializeTableState(
      localStorageService.retrieve(LocalStorageKeys.BIDS_LIST_TABLE_STATE)
    ));
  }

  public getFilterValue(name: keyof BidListFilterState): (number | string)[] | Range<Moment> {
    return this.state$.getValue().filter && this.state$.getValue().filter[name];
  }

  public getFilterStateForUpdate(name: keyof BidListFilterState, state: BidListFilterState): BidListFilterState {
    return Object.assign(new BidListFilterState(), this.state$.getValue().filter, {
      [name]: state[name]
    });
  }

  disconnect(): void {
    return;
  }

  protected getTableData(state: BidListRemoteState): void {
    this.spinnerService.start(BlockingMessage.LOADING_BIDS);

    this.localStorageService.store(LocalStorageKeys.BIDS_LIST_TABLE_STATE, state);

    this.bidListDaoService.getBids(state).pipe(
      tap(() => this.notificationService.clearChannel(NotificationService.DEFAULT_MESSAGE_CHANNEL)),
      catchError((error: HttpErrorResponse) => this.notificationService.showServerError(error)),
      finalize(() => this.spinnerService.stop())
    ).subscribe((tableData: PaginatedResponseWrapper<Bid>) => this.tableData$.next(tableData));
  }

  private static deserializeTableState(state: BidListRemoteState): BidListRemoteState {
    const jsonConvert = new JsonConvert(OperationMode.ENABLE, ValueCheckingMode.ALLOW_NULL);

    return state
      ? jsonConvert.deserializeObject(state, BidListRemoteState)
      : new BidListRemoteState(0, defaultPageSize);
  }
}
