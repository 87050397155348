import { BaseTerm } from '@qv-term/models/types';
import { Comparator, SkipAutosave } from '@qv-common/interfaces';
import { constants, resources } from '@qv-common/static';

export class LegalAttestationTerm extends BaseTerm implements Comparator, SkipAutosave {
  public doNotSave = true;

  constructor(name: string) {
    super(
      name,
      name,
      resources.TermsDescriptions.SUMMARY.LEGAL_ATTESTATION,
      constants.BIDDING.LEGAL_ATTESTATION,
      null,
      null,
      'term__legal-attestation'
    );
  }

  public linkValue(): string {
    throw new Error('Method not implemented.');
  }

  public getTermValueForComparison(): string {
    throw new Error('Method not implemented.');
  }
}
