export enum FieldValidation {
  MAX_LENGTH_255 = 255,
  MAX_LENGTH_2000 = 2000,
  TITLE_MAX_LENGTH = 30,
  SIGNATURE_MAX_LENGTH = 75,
}

export enum FieldValidationMessage {
  REQUIRED = 'This field is required',
  MAX_LENGTH_255 = 'Field length is limited to 255 characters',
  MAX_LENGTH_2000 = 'Field length is limited to 2000 characters',
  TITLE_MAX_LENGTH = 'Field length is limited to 30 characters',
  SIGNATURE_MAX_LENGTH = 'Field length is limited to 75 characters',
  DATE_FORMAT = 'Invalid date format',
  INCORRECT_VALUE = 'Incorrect value',
  MAX_FILE_SIZE_MESSAGE = 'File(s) exceed {0} limit. Please lower the size of your file and try again.',
  FILE_FORMAT_NOT_VALID = 'File format is not supported. Please upload as a {0} file.',
  EMAIL = 'Email Address is invalid. Please verify spelling and try again.',
  RANGE_GTE = 'Range maximum should be greater or equal than minimum.',
}
