import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { BidCommentFormService } from '@qv-bid/services/summary';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BidComment } from '@qv-bid/entities/bid-comment';
import { BidCommentsDaoService } from '@qv-bid/services/dao';
import { SingleNotificationService } from '@qv-shared/services';
import { constants, resources } from '@qv-common/static';
import { StringUtils } from '@qv-common/utils';
import { SnackBarService } from 'quantuvis-angular-common/snack-bar';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { SingleNotification } from '@qv-shared/classes';
import { Toast } from 'ngx-toastr';
import { HttpErrorResponse } from '@angular/common/http';
import { NotificationService } from 'quantuvis-angular-common/notification';
import { FormValidationError } from '@qv-common/enums';

@UntilDestroy()
@Component({
  selector: 'qv-bid-comment-field',
  templateUrl: './bid-comment-field.component.html',
  styleUrls: ['./bid-comment-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BidCommentFieldComponent implements OnInit, OnChanges {
  @Input()
  public isEditMode: boolean;
  @Input()
  public bidVersionId: number;
  @Input()
  public summaryId: number;
  @Input()
  public comment: BidComment;
  @Input()
  public isBidInvalid = false;
  @Input()
  public isBidInternal: boolean;
  @Output()
  public commentChangeEvent = new EventEmitter<BidComment>();

  public readonly validationMessages = new Map<string, string>([[
    FormValidationError.MAX_LENGTH,
    `Comments are limited to ${constants.COMMENTS_MAX_LENGTH} characters. Please edit the text.`
  ]]);

  private singleNotification: SingleNotification<Toast>;

  constructor(
    private bidCommentsDaoService: BidCommentsDaoService,
    private notificationService: NotificationService,
    private changeDetectorRef: ChangeDetectorRef,
    private snackBarService: SnackBarService,
    public bidCommentFormService: BidCommentFormService,
    private singleNotificationService: SingleNotificationService
  ) {
    this.singleNotification = this.singleNotificationService.getInstance<Toast>();
  }

  public ngOnInit(): void {
    this.initCommentFormControl();
    this.changeDetectorRef.markForCheck();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.comment && !changes.comment.firstChange) {
      this.bidCommentFormService.formControl.setValue(this.comment ? this.comment.value : '', { emitEvent: false });
      return;
    }

    if (!this.bidCommentFormService.formControl) {
      return;
    }

    const options = { onlySelf: true, emitEvent: false };
    const isCommentValid = this.bidCommentFormService.formControl.valid;

    if (changes.isEditMode) {
      if (changes.isEditMode.currentValue) {
        this.bidCommentFormService.formControl.enable(options);
      } else {
        this.bidCommentFormService.formControl.disable(options);
      }
    } else if (changes.isBidInvalid && changes.isBidInvalid.currentValue && isCommentValid) {
      this.bidCommentFormService.formControl.disable(options);
    }
  }

  private initCommentFormControl(): void {
    const formState = {
      value: this.comment ? this.comment.value : '',
      disabled: !this.isEditMode
    };

    this.bidCommentFormService.formControl = new FormControl(formState, {
      updateOn: 'blur',
      validators: Validators.maxLength(constants.COMMENTS_MAX_LENGTH)
    });

    this.subscribeOnCommentChanges();
  }

  private subscribeOnCommentChanges(): void {
    this.bidCommentFormService.formControl.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((newCommentValue: string) => {
        const newTrimmedCommentValue = StringUtils.trim(newCommentValue);

        if (newCommentValue === newTrimmedCommentValue) {
          this.submitComment(newTrimmedCommentValue);
        } else {
          this.bidCommentFormService.formControl.setValue(newTrimmedCommentValue);
        }
      });
  }

  private submitComment(value: string): void {
    if (this.bidCommentFormService.formControl.invalid) {
      this.singleNotification.toast(this.notificationService.error(resources.BID_DETAILS.INVALID_FIELDS_SAVING_ERROR));
      return;
    }

    this.singleNotification.clear();

    if (!this.comment && !value) {
      return;
    }

    if (this.comment) {
      this.updateComment(value);
    } else {
      this.saveComment(value);
    }
  }

  private saveComment(value: string): void {
    const comment: BidComment = {
      ...new BidComment(),
      value,
      summaryId: this.summaryId
    };

    this.snackBarService.start();
    this.bidCommentsDaoService
      .saveComment(this.bidVersionId, this.summaryId, comment, this.isBidInternal)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.snackBarService.error();
          return throwError(error);
        })
      )
      .subscribe((savedComment: BidComment) => {
        this.comment = savedComment;
        this.commentChangeEvent.emit(savedComment);
        this.changeDetectorRef.markForCheck();
        this.snackBarService.finish();
      });
  }

  private updateComment(value: string): void {
    const comment: BidComment = {
      ...this.comment,
      value
    };

    this.snackBarService.start();
    this.bidCommentsDaoService
      .updateComment(this.bidVersionId, this.summaryId, comment, this.isBidInternal)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.snackBarService.error();
          return throwError(error);
        })
      )
      .subscribe((updatedComment: BidComment) => {
        this.comment = updatedComment;
        this.commentChangeEvent.emit(updatedComment);
        this.changeDetectorRef.markForCheck();
        this.snackBarService.finish();
      });
  }
}
