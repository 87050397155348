import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('ScenarioDrugIds')
export class ScenarioDrugIds {
  @JsonProperty('scenariosIds')
  public scenariosIds: number[] = [];
  @JsonProperty('drugIds')
  public drugIds: number[] = [];

  constructor(scenariosIds: number[], drugIds: number[]) {
    this.scenariosIds = scenariosIds;
    this.drugIds = drugIds;
  }
}
