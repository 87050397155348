import { PickerController } from './controllers/picker.controller';
import { AttachmentUpload } from './directives/attachmentupload.directive';
import { AutoFocus } from './directives/autofocus.directive';
import register from '../utils/register';
import { Expandable } from './directives/expandable.directive';
import { FileUpload } from './directives/fileupload.directive';
import { FrameDirective } from './directives/frame.directive';
import { MultipleSelection } from './directives/multipleselection.directive';
import { MyAccounts } from './directives/myaccounts.directive';
import { ScrollFix } from './directives/ng-auto-height.directive';
import { UiTinymce } from './directives/ng-tinymce.directive';
import { Infinite } from './directives/nginfinite.directive';
import { NgNoTrimModel } from './directives/ngnotrimmodel.directive';
import { NgRemove } from './directives/ngremove.directive';
import { NotificationDescription } from './directives/notificationdescription.directive';
import {
  DrugPicker,
  InternalPayerPicker,
  ManufacturerDrugsPicker,
  ManufacturerPicker
} from './directives/pickersdirectives.directive';
import { QvFocusOut } from './directives/qv-focus-out.directive';
import { QvRemoveScripts } from './directives/qv-remove-scripts.directive';
import { QvSmoothValue } from './directives/qv-smooth-value';
import { StickyContainer } from './directives/sticky-containers.directive';
import { TermView } from './directives/termview.directive';
import { Tooltip } from './directives/tooltip.directive';
import { ValidationInput } from './directives/validationinput.directive';
import { trackingDirective } from './directives/tracking.directive';
import { FilterMultiple } from './filters/filtermultiple';
import { ObjectFilter } from './filters/objectfilter';
import { StatusFilter } from './filters/statusfilter';
import { UniqueFilter } from './filters/unique';
import { Bidding } from './services/bidding';
import { CopyBidService } from './services/copy-bid.service';
import { CoreDomUtilsService } from './services/core-dom-utils.service';
import { DrugLevelService } from './services/drug-level.service';
import { DrugTermGroupStateManager } from './services/drugtermgroupstatemanager';
import { Locking } from './services/locking';
import { Role } from './services/roles';
import { Timezone } from './services/timezones';
import { Translations } from './services/translations';
import { Validations } from './services/validations';

declare let angular: angular.IAngularStatic;

export const SharedNg1Module = angular.module('quantuvis.sharedNg1', [])
  .controller('PickerController', PickerController)
  .directive('attachmentUpload', AttachmentUpload)
  .directive('autoFocus', AutoFocus)
  .directive('expandable', Expandable)
  .directive('fileUpload', FileUpload)
  .directive('frame', FrameDirective)
  .directive('multiple', MultipleSelection)
  .directive('myAccounts', MyAccounts)
  .directive('scrollFix', ScrollFix)
  .value('uiTinymceConfig', {})
  .directive('uiTinymce', UiTinymce)
  .directive('infinite', Infinite)
  .directive('ngNoTrimModel', NgNoTrimModel)
  .directive('ngRemove', NgRemove)
  .directive('notificationDescription', NotificationDescription)
  .directive('manufacturerPicker', ManufacturerPicker)
  .directive('internalPayerPicker', InternalPayerPicker)
  .directive('manufacturerDrugsPicker', ManufacturerDrugsPicker)
  .directive('drugPicker', DrugPicker)
  .directive('qvFocusOut', QvFocusOut)
  .directive('qvRemoveScripts', QvRemoveScripts)
  .directive('qvSmoothValue', QvSmoothValue)
  .directive('term', TermView)
  .directive('qvTooltip', Tooltip)
  .directive('validationInput', ValidationInput)
  .run(trackingDirective)

  .filter('filtermultiple', FilterMultiple)
  .filter('objectFilter', ObjectFilter)
  .filter('status', StatusFilter)
  .filter('unique', UniqueFilter)

  .service('bidding', Bidding)
  .service('CopyBidService', CopyBidService)
  .service('coreDomUtilsService', CoreDomUtilsService)
  .service('DrugLevelService', DrugLevelService)
  .service('drugTermGroupStateManager', DrugTermGroupStateManager)
  .service('locking', Locking)
  .service('role', Role)
  .service('timezone', Timezone)
  .service('translations', Translations)
  .service('validations', Validations)
;

register('quantuvis.sharedNg1').directive('stickyContainer', StickyContainer);
