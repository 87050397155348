import { Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { ApiService } from '@qv-common/services/api';
import { RequestOption } from '@qv-common/services/api/request-option.interface';
import { ResponseType } from 'quantuvis-angular-common/api';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiUrlPrefix } from '@qv-common/enums';
import { File, FileContent } from '@qv-pdf-viewer/models';

@Injectable()
export class LetterOfIntentDaoService {
  public static readonly URL = `${ApiUrlPrefix.DOCUMENT_MANAGEMENT}/intention-letters/`;

  constructor(
    private apiService: ApiService
  ) {}

  public getFiles(): Observable<Array<any>> {
    return this.apiService.get(LetterOfIntentDaoService.URL).pipe(map((response: HttpResponse<any>) => {
      if (response.body && response.body.data) {
        return response.body.data; // files data [{ id, name, pdfId } ...]
      }
      LetterOfIntentDaoService.handleResponseError();
    }));
  }

  public getFile(pdfId: number): Observable<FileContent> {
    const url = `${ApiUrlPrefix.DOCUMENT_MANAGEMENT}/documents/${pdfId}/download`;
    const options: RequestOption = {
      responseType: ResponseType.ARRAY_BUFFER
    };
    return this.apiService.get(url, options).pipe(map((response: HttpResponse<FileContent>) => {
      if (response.body) {
        return response.body; // file buffer
      }
      LetterOfIntentDaoService.handleResponseError();
    }));
  }

  public checkFileWarnings(pdfId: number): Observable<Array<any>> {
    const url = `${LetterOfIntentDaoService.URL}${pdfId}/warnings`;
    return this.apiService.get(url).pipe(map((response: HttpResponse<any>) => {
      if (response.body && response.body.data && response.body.data) {
        return response.body.data;
      }
      LetterOfIntentDaoService.handleResponseError();
    }));
  }

  public deleteFile(id: number): Observable<any> {
    const url = `${ApiUrlPrefix.DOCUMENT_MANAGEMENT}/documents/${id}`;
    return this.apiService.delete(url);
  }

  public upload(file: Blob, id?: number): Observable<File> {
    const formData = new FormData();
    formData.append('file', file);

    const uploadRequest = id ?
      this.apiService.put(`${LetterOfIntentDaoService.URL}${id}`, formData) :
      this.apiService.post(LetterOfIntentDaoService.URL, formData);

    return uploadRequest
      .pipe(
        map((response: HttpResponse<{ data: File[] }>) => response.body.data[0])
      );
  }

  private static handleResponseError(): void {
    throw Error('Response is not valid');
  }
}
