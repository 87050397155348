import { Range } from '@qv-common/entities';
import { Moment } from 'moment';

export class FilterState {
  public drugNames: string[];
  public drugStatuses: string[];
  public contractedBusinesses: number[];
  public drugIds: number[];
  public scenarioIds: number[];

  // Rebate
  public minBaseRebateRange: Range<number>;
  public baseRebateRange: Range<number>;
  public tierPlacements: number[];
  public tierCompetitions: number[];
  public competitorTiers: number[];
  public competitionGroups: number[];

  // UM
  public priorAuthorizationAllowed: number[];
  public stepTherapyAllowed: number[];
  public priorAuthorizationRequired: number[];
  public stepTherapyRequired: number[];
  public quantityLimits: number[];
  public otherUms: number[];

  // Scenario Terms
  public scenarioStartDates: Range<Moment>;
  public scenarioEndDates: Range<Moment>;
  public adminFeeRange: Range<number>;

  // Price Protection
  public netEffectivePriceRange: Range<number>;
  public indexes: number[];
  public thresholdRange: Range<number>;
  public resets: number[];
  public baselineStartDates: Range<Moment>;
  public newPriceEffectiveDates: string[];

  // Market Share
  public marketShareTiers: number[];
}
