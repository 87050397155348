<div class="section-toolbar">
  <div class="section-toolbar__title">
    <h2>Website Links</h2>
    <mat-icon
        [matTooltip]="resources.TermsDescriptions.SUMMARY.LINKS"
        [svgIcon]="svgIconName.INFO_OUTLINE"
        class="section-info">
    </mat-icon>
  </div>
  <qv-lock
      [control]="lockControl"
      [isDisabled]="isBidInvalid"
      [isEditMode]="isEditMode"
      [locked]="lockControl.value"
      data-test-id="test-id-summary-website-link-lock"
  ></qv-lock>
</div>
<ul *ngIf="websiteLinks?.length" class="row-items">
  <ng-container *ngFor="let websiteLink of websiteLinks">
    <li
        *ngIf="!isEditFieldVisible(editStateData.state, editStateData.websiteLinkId, websiteLink.id)"
        [class.row-item--with-manage]="isManageAvailable(isEditable(isEditMode, isUserPharma, summary.webSiteLinksLock, isBidInvalid), websiteLink.companyId, currentCompanyId)"
        class="row-item"
    >
      <a class="row-item__title" href="{{websiteLink?.link | httpUrl}}" target="_blank">{{websiteLink.link}}</a>
      <button
          (click)="onEditWebsiteLinkButtonHandler(websiteLink)"
          *ngIf="isManageAvailable(isEditable(isEditMode, isUserPharma, summary.webSiteLinksLock, isBidInvalid), websiteLink.companyId, currentCompanyId)"
          class="edit-link-button"
          mat-icon-button
      >
        <mat-icon [svgIcon]="svgIconName.PEN" class="qv-icon qv-icon--18"></mat-icon>
      </button>
      <button
          (click)="onDeleteWebsiteLinkButtonHandler(websiteLink)"
          *ngIf="isManageAvailable(isEditable(isEditMode, isUserPharma, summary.webSiteLinksLock, isBidInvalid), websiteLink.companyId, currentCompanyId)"
          class="delete-link-button"
          mat-icon-button
      >
        <mat-icon [svgIcon]="svgIconName.DELETE" class="qv-icon qv-icon--18"></mat-icon>
      </button>
    </li>
    <div *ngIf="isEditFieldVisible(editStateData.state, editStateData.websiteLinkId, websiteLink.id)"
         class="edit-link-field">
      <input
        #link
        (blur)="onUpdateWebsiteLinkHandler(websiteLink)"
        (keyup.enter)="onUpdateWebsiteLinkHandler(websiteLink)"
        [formControl]="inputControl"
        class="edit-link-field__input"
        matInput
      >
      <qv-error-icon
          [control]="inputControl"
          [errorsMap]="errorsMap"
          class="edit-link-field__validation-icon"
      ></qv-error-icon>
    </div>
  </ng-container>
</ul>
<p *ngIf="!websiteLinks?.length && !isEditable(isEditMode, isUserPharma, summary.webSiteLinksLock, isBidInvalid)"
   class="qv-sidebar-section__no-data">{{commonMessage.NO_DATA}}</p>
<ng-container *ngIf="isEditable(isEditMode, isUserPharma, summary.webSiteLinksLock, isBidInvalid)">
  <div
      (@expand.done)="focusInput()"
      [@expand]="isAddFieldVisible(editStateData.state) ? rowState.EXPANDED : rowState.COLLAPSED"
      class="add-link-field"
  >
    <input
      #link
      (blur)="onCreateWebsiteLinkHandler()"
      (keyup.enter)="onCreateWebsiteLinkHandler()"
      *ngIf="isAddFieldVisible(editStateData.state)"
      [formControl]="inputControl"
      class="add-link-field__input"
      data-test-id="test-id-summary-website-link"
      matInput
    >
    <qv-error-icon
        [control]="inputControl"
        [errorsMap]="errorsMap"
        class="add-link-field__validation-icon"
    ></qv-error-icon>
  </div>
  <button (click)="onAddWebsiteLinkButtonHandler()" class="add-link-button" mat-button>
    <span class="add-link-button__text">Add a link</span>
    <mat-icon [svgIcon]="svgIconName.ADD_SQUARE" class="qv-icon qv-icon--16"></mat-icon>
  </button>
</ng-container>
