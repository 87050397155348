import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { resources } from '@qv-common/static';
import {
  GeneralModalComponent,
  GeneralModalData,
  ModalConfig,
  ModalSize
} from 'quantuvis-angular-common/modal';

export class ContractedBusinessDeleteModalConfig
  extends ModalConfig<GeneralModalComponent, GeneralModalData> {

  constructor(name: string) {
    super(new GeneralModalData(
      'Delete Contracted Business',
      `${resources.CONTRACTED_BUSINESS_ACTIONS.DELETE_CONTRACTED_BUSINESS_WITH_NAME} "${name}"?`,
      resources.Actions.YES,
      resources.Actions.NO,
      false
    ), ModalSize.X_SMALL);
  }

  protected createDialogRef(dialog: MatDialog): MatDialogRef<GeneralModalComponent> {
    return dialog.open(GeneralModalComponent, this);
  }
}
