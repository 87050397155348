import { JsonObject, JsonProperty } from 'json2typescript';
import { Range } from '@qv-common/entities';
import { Moment } from 'moment';

@JsonObject('BidListFilterState')
export class BidListFilterState {
  @JsonProperty('titles', [String], true)
  public titles?: string[] = null;

  @JsonProperty('statuses', [String], true)
  public statuses?: string[] = null;

  @JsonProperty('lineOfBusinesses', [String], true)
  public lineOfBusinesses?: string[] = null;

  @JsonProperty('oppositeCompanyIds', [Number], true)
  public oppositeCompanyIds?: number[] = null;

  @JsonProperty('assignedToIds', [Number], true)
  public assignedToIds?: number[] = null;

  @JsonProperty('dateModifiedRange', Range, true)
  public dateModifiedRange?: Range<Moment> = null;

  @JsonProperty('rfpDueDateRange', Range, true)
  public rfpDueDateRange?: Range<Moment> = null;

  @JsonProperty('contractedPeriodRange', Range, true)
  public contractedPeriodRange?: Range<Moment> = null;
}
