/* tslint:disable:triple-equals prefer-template */
/**
 * Custom AngularJS services used by the APP logic
 */
import { constants } from '@qv-common/static';
import { SvgIconName } from '@qv-common/enums';
import { CoreUtils } from '@qv-common/utils';
import { UserService } from '@qv-common/services/auth/user.service';

export const Locking = ['util', 'biddingUtilsService', 'userService',
  function (util, biddingUtilsService, userService: UserService): any {
  'ngInject';
  const lockStateObject = constants.DRUG_TERM_GROUPS_STATE_VALUES.LOCKED_STATE;
  const unlockStateObject = constants.DRUG_TERM_GROUPS_STATE_VALUES.UNLOCKED_STATE;
  const hiddenStateObject = constants.DRUG_TERM_GROUPS_STATE_VALUES.HIDDEN_STATE;
  const visibleStateObject = constants.DRUG_TERM_GROUPS_STATE_VALUES.VISIBLE_STATE;
  const requiredStateObject = constants.DRUG_TERM_GROUPS_STATE_VALUES.REQUIRED_STATE;

  function getLockStateObject(): any {
    return lockStateObject;
  }

  function getUnlockStateObject(): any {
    return unlockStateObject;
  }

  function getHiddenStateObject(): any {
    return hiddenStateObject;
  }

  function getVisibleStateObject(): any {
    return visibleStateObject;
  }

  function getRequiredStateObject(): any {
    return requiredStateObject;
  }

  function initMetaObject(): void {
    metaChangeObject = {
      from: 'meta',
      dataChanges: []
    };
  }

  let metaChangeObject: any = {};

  function lockOrUnlockTerm(term, metadata, termsList, scope): void {
    initMetaObject();

    if (scope) {
      // Save changes already in queue
      scope.$emit(constants.EVENTS.SAVE_CHANGES_IMMEDIATELY);
    }

    if (isTermLocked(term, metadata) === false) {
      lockSingleTerm(term, metadata, termsList);
    } else {
      unlockSingleTerm(term, metadata, termsList);
    }

    triggerMetaChangedEvent(scope, constants.IMMEDIATELY_SAVE_EVENTS.LOCKS_CHANGE);
  }

  /**
   * Update Pharma right and term state
   *
   * @param term {Object}
   * @param state {Object}
   * @param metadata {Object}
   * @param scope {Object}
   */
  function updateTermState(term, state, metadata, scope): void {
    initMetaObject();
    const termPath = util.getPathFromArray(term.definition.path);
    if (metadata[termPath]) {
      // Remove previous state if it is exist
      addOperationToUpdateQueue('delete', 'bidMeta', termPath, metadata[termPath]);
    }
    addOperationToUpdateQueue('insert', 'bidMeta', termPath, state);
    triggerMetaChangedEvent(scope);
  }

  function enableDisablePharmaOption(allowOption, optionPath, metadata, scope): void {
    initMetaObject();

    // Save changes already in queue
    scope.$emit(constants.EVENTS.SAVE_CHANGES_IMMEDIATELY);

    // enabling the option by deleting the lock from metadata
    if (allowOption === true) {
      addOperationToUpdateQueue('delete', 'bidMeta', optionPath, metadata[optionPath]);
    } else {
      addOperationToUpdateQueue('insert', 'bidMeta', optionPath, metadata[optionPath]);
    }
    triggerMetaChangedEvent(scope, constants.IMMEDIATELY_SAVE_EVENTS.LOCKS_CHANGE);
  }

  function lockOrUnlockAllTermsInGroup(groupPath, metadata, termsList, scope): void {
    const termsGroupPath = util.getPathFromArray(groupPath);
    if (validateTermsInGroup(termsGroupPath, termsList)) {
      if (scope) {
        // Save changes already in queue
        scope.$emit(constants.EVENTS.SAVE_CHANGES_IMMEDIATELY);
      }

      initMetaObject();
      if (isTermsGroupLocked(termsGroupPath, metadata)) {
        unlockAllTermsInGroup(termsGroupPath, metadata, termsList);
      } else {
        lockAllTermsInGroup(termsGroupPath, metadata, termsList);
      }

      triggerMetaChangedEvent(scope, constants.IMMEDIATELY_SAVE_EVENTS.LOCKS_CHANGE);
    }
  }

  function lockAllTermsInGroup(termGroupPath, metadata, termsList): void {
    const regexp = new RegExp(`^${termGroupPath}`);
    // for (metaPath in metadata) {
    Object.keys(metadata).forEach(metaPath => {
      if (regexp.test(metaPath)) {
        addOperationToUpdateQueue('delete', 'meta', metaPath, metadata[metaPath]);
        delete metadata[metaPath];
      }
    });
    getTermsForGroupPath(termGroupPath, termsList).forEach(term => {
      term.definition.state = constants.DRUG_TERM_GROUPS_STATE_VALUES.LOCKED_STATE.state;
      term.definition.editable = biddingUtilsService.isCurrentUserPayer(userService.user.getValue());
    });

    metadata[termGroupPath] = lockStateObject;
    addOperationToUpdateQueue('insert', 'meta', termGroupPath, metadata[termGroupPath]);
  }

  function unlockAllTermsInGroup(termGroupPath, metadata, termsList): void {
    const regexp = new RegExp('^' + termGroupPath);
    // for (metaPath in metadata) {
    Object.keys(metadata).forEach(metaPath => {
      if (regexp.test(metaPath)) {
        addOperationToUpdateQueue('delete', 'meta', metaPath, metadata[metaPath]);
        delete metadata[metaPath];
      }
    });
    getTermsForGroupPath(termGroupPath, termsList).forEach(term => {
      delete term.definition.state;
      delete term.definition.editable;
    });
  }

  function lockSingleTerm(term, metadata, termsList): void {
    const termPath = util.getPathFromArray(term.definition.path);
    const termGroupPath = util.getPathFromArray(term.definition.groupPath);
    if (CoreUtils.isNotDefined(metadata[termPath]) && CoreUtils.isDefined(metadata[termGroupPath])) {
      getTermsForGroupPath(termGroupPath, termsList).forEach(t => {
        if (util.getPathFromArray(t.definition.path) != termPath) {
          const tPath = util.getPathFromArray(t.definition.path);
          metadata[tPath] = lockStateObject;
          addOperationToUpdateQueue('insert', 'meta', tPath, metadata[tPath]);
        }
      });
      delete metadata[termGroupPath];
    } else {
      metadata[termPath] = lockStateObject;
      term.definition.state = constants.DRUG_TERM_GROUPS_STATE_VALUES.LOCKED_STATE.state;
      term.definition.editable = biddingUtilsService.isCurrentUserPayer(userService.user.getValue());
      collapseMetadataLockedTerms(term, termGroupPath, metadata);
    }
  }

  function unlockSingleTerm(term, metadata, termsList): void {
    const termPath = util.getPathFromArray(term.definition.path);
    const termGroupPath = util.getPathFromArray(term.definition.groupPath);
    delete term.definition.state;
    delete term.definition.editable;

    if (CoreUtils.isNotDefined(metadata[termPath]) && CoreUtils.isDefined(metadata[termGroupPath])) {
      addOperationToUpdateQueue('delete', 'meta', termGroupPath, metadata[termGroupPath]);
      delete metadata[termGroupPath];
      expandMetadataLockedTerms(termPath, termGroupPath, metadata, termsList);
    } else {
      if (util.isPathDefined(term, 'definition.saveToBidMeta') && term.definition.saveToBidMeta === true) {
        addOperationToUpdateQueue('delete', 'bidMeta', termPath, metadata[termPath]);
      } else {
        addOperationToUpdateQueue('delete', 'meta', termPath, metadata[termPath]);
      }
      delete metadata[termPath];
    }
  }

  function expandMetadataLockedTerms(termPath, termGroupPath, metadata, termsList): void {
    getTermsForGroupPath(termGroupPath, termsList).forEach(t => {
      if (util.getPathFromArray(t.definition.path) != termPath) {
        const tPath = util.getPathFromArray(t.definition.path);
        metadata[tPath] = lockStateObject;
        addOperationToUpdateQueue('insert', 'meta', tPath, metadata[tPath]);
      }
    });

  }

  function collapseMetadataLockedTerms(term, termGroupPath, metadata): void {
    if (getNumberOfMetadataTermsForGroup(termGroupPath, metadata) == term.definition.numberOfTermsInGroup) {
      const regexp = new RegExp('^' + termGroupPath);
      // for (metaPath in metadata) {
      Object.keys(metadata).forEach(metaPath => {
        if (regexp.test(metaPath) == true) {
          if (metaPath != util.getPathFromArray(term.definition.path)) {
            addOperationToUpdateQueue('delete', 'meta', metaPath, metadata[metaPath]);
          }
          delete metadata[metaPath];
        }
      });
      metadata[termGroupPath] = lockStateObject;
      addOperationToUpdateQueue('insert', 'meta', termGroupPath, metadata[termGroupPath]);
    } else {
      const termPath = util.getPathFromArray(term.definition.path);
      if (util.isPathDefined(term, 'definition.saveToBidMeta') && term.definition.saveToBidMeta === true) {
        addOperationToUpdateQueue('insert', 'bidMeta', termPath, metadata[termPath]);
      } else {
        addOperationToUpdateQueue('insert', 'meta', termPath, metadata[termPath]);
      }
    }
  }

  function getNumberOfMetadataTermsForGroup(termGroupPath, metadata): number {
    let noTerms = 0;
    const regexp = new RegExp('^' + termGroupPath);
    for (const metaPath in metadata) {
      if (regexp.test(metaPath) == true) {
        noTerms++;
      }
    }
    return noTerms;
  }

  function getTermsForGroupPath(termGroupPath, termsList): any {
    return termsList.filter(term => util.getPathFromArray(term.definition.groupPath) == termGroupPath);
  }

  function isTermLocked(term, metadata): any {
    return ((CoreUtils.isDefined(term.definition)
      && CoreUtils.isDefined(metadata))
      && ((CoreUtils.isDefined(metadata[util
          .getPathFromArray(term.definition.path)])
        && CoreUtils.isDefined(metadata[util.getPathFromArray(term.definition.path)].state)
        && metadata[util
          .getPathFromArray(term.definition.path)].state
        === constants.DRUG_TERM_GROUPS_STATE_VALUES.LOCKED_STATE.state) || isTermsGroupLocked(
        util.getPathFromArray(term.definition.groupPath), metadata)));
  }

  function isTermsGroupLocked(termGroupPath, metadata): boolean {
    return !!(CoreUtils.isDefined(metadata)
      && CoreUtils.isDefined(metadata[termGroupPath])
      && CoreUtils.isDefined(metadata[termGroupPath].state) && metadata[termGroupPath].state
      === constants.DRUG_TERM_GROUPS_STATE_VALUES.LOCKED_STATE.state);
  }

  // todo: must implement specificity as it was implemented on the backend
  function isTermPathLocked(termPath, metadata): boolean {
    // for (metaPath in metadata) {
    Object.keys(metadata).forEach(metaPath => {
      const regexp = new RegExp('^' + metaPath);
      if (regexp.test(termPath) == true) {
        return true;
      }
    });
    return false;
  }

  function getLockUnlockIconClass(term, metadata): string {
    let classes = 'term-lock lock-icon-margin ';
    if (isTermLocked(term, metadata) || isTermsGroupLocked(util.getPathFromArray(term), metadata)) {
      classes = `${classes} term-lock--locked `;
    } else if (userService.isCurrentUserPayer()) {
      classes = `${classes} term-lock--unlocked `;
    } else {
      classes = `${classes} term-lock--hidden `;
    }

    return classes.trim();
  }

  function getLockUnlockIconName(term, metadata): SvgIconName.LOCK | SvgIconName.UNLOCK {
    return isTermLocked(term, metadata) || isTermsGroupLocked(util.getPathFromArray(term), metadata)
      ? SvgIconName.LOCK
      : SvgIconName.UNLOCK;
  }

  function addOperationToUpdateQueue(operation, operationPath, dataPath, dataFlags): void {
    metaChangeObject.dataChanges.push({
      type: operation,
      path: [
        operationPath
      ],
      data: {
        path: dataPath,
        flags: dataFlags
      }
    });
  }

  /**
   * Method that triggers the "valueChanged" event which will be processed in biddetails.controller.js
   * @param scope the current scope
   * @param event - marker type of operation
   */
  function triggerMetaChangedEvent(scope, event?): void {
    if (CoreUtils.isDefined(scope)) {
      if (event) {
        metaChangeObject.event = event;
      } else {
        delete metaChangeObject.event;
      }
      scope.$broadcast('valueChanged', metaChangeObject);
    } else {
      metaChangeObject.dataChanges = [];
    }
  }

  function validateTermsInGroup(termsGroup, termsList): boolean {
    let valid = true;
    getTermsForGroupPath(termsGroup, termsList).forEach(t => {
      if (t.definition.validate) {
        const validTerm = t.definition.validate(t);
        if (valid) {
          valid = validTerm;
        }
      }
    });
    return valid;
  }

  return {
    isTermsGroupLocked: isTermsGroupLocked,
    isTermLocked: isTermLocked,
    isTermPathLocked: isTermPathLocked,
    lockOrUnlockTerm: lockOrUnlockTerm,
    updateTermState: updateTermState,
    enableDisablePharmaOption: enableDisablePharmaOption,
    lockOrUnlockAllTermsInGroup: lockOrUnlockAllTermsInGroup,
    getLockUnlockIconClass: getLockUnlockIconClass,
    getLockUnlockIconName: getLockUnlockIconName,
    getLockStateObject: getLockStateObject,
    getUnlockStateObject: getUnlockStateObject,
    getHiddenStateObject: getHiddenStateObject,
    getVisibleStateObject: getVisibleStateObject,
    getRequiredStateObject: getRequiredStateObject,
    getTermsForGroupPath: getTermsForGroupPath
  };

}];
