import { Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { StringUtils } from '@qv-common/utils';
import { CoreUtils } from '@qv-common/utils/core.utils';

@Component({
  selector: 'qv-slide-toggle',
  templateUrl: './slide-toggle.component.html',
  styleUrls: ['./slide-toggle.component.scss'],
  providers: []
})
export class SlideToggleComponent {
  @Input()
  public toggleId: string;
  @Input()
  public toggleName: string;
  @Input()
  public checked: boolean;
  @Input()
  public firstOption: string | TemplateRef<HTMLElement>;
  @Input()
  public secondOption: string | TemplateRef<HTMLElement>;
  @Input()
  public firstTooltip: string;
  @Input()
  public secondTooltip: string;
  @Input()
  public disabled = false;
  @Input()
  public disabledTooltip: string;

  @Output()
  public checkedChange = new EventEmitter<boolean>();

  public coreUtils = CoreUtils;
  public stringUtils = StringUtils;

  public toggleHandler(): void {
    this.checkedChange.emit(this.checked);
  }

  public toggleWith(flag: boolean): void {
    if (this.disabled) {
      return;
    }
    this.checked = flag;
    this.toggleHandler();
  }

  public getFirstTooltip(): string {
    return !this.disabled ? this.firstTooltip : '';
  }

  public getSecondTooltip(): string {
    return !this.disabled ? this.secondTooltip : '';
  }

  public getDisabledTooltip(): string {
    return this.disabled ? this.disabledTooltip : '';
  }
}
