import { constants } from '@qv-common/static';
import { NumberUtils } from '@qv-common/utils';
import { appConfig } from '@qv-common/configs';
import { ApiUrlPrefix } from '@qv-common/enums';
import { Attachment } from '@qv-common/models';
import { AuthService } from 'quantuvis-angular-common/auth';

declare let angular: angular.IAngularStatic;
declare let $: any;

export const AttachmentUpload = ['BidDetailsService', 'spinnerService', 'authService',
  (BidDetailsService, spinnerService, authService: AuthService): any => {
  'ngInject';

  const refresh = (scope) => {
    if (!scope.$$phase) {
      scope.$digest();
    }
  };

  const getAccumulatedAttachmentsSize = (attachments: Attachment[]): number => attachments.reduce(
    (accumulator: number, { attachmentSize }: Attachment) => accumulator + (attachmentSize || 0), 0
  );

  const initializeFileUploadPlugin = (fileInput, uploadUrl, scope, dropZone = null) => {
    $(fileInput).fileupload({
      dropZone,
      singleFileUploads: true,
      autoUpload: false,
      url: uploadUrl,
      dataType: 'text',
      type: 'POST',
      beforeSend: (xhr: XMLHttpRequest) => authService.setTokensToXhr(xhr)
    }).on('fileuploadadd', (event, data) => {
      BidDetailsService.disableTermUpdate();
      const [newAttachment] = data.files;
      scope.attachment.attachmentName = newAttachment.name;

      const fullAttachmentsSize = getAccumulatedAttachmentsSize(scope.term.value) + newAttachment.size;

      if (fullAttachmentsSize <= appConfig.maxFileSize) {
        // if the size of the file is less than the maximum size allowed then proceed with the upload
        spinnerService.start('Uploading...');
        data.submit().done(resp => {
          const { id, name, size } = angular.fromJson(resp).data;

          BidDetailsService.enableTermUpdate(true);

          scope.attachment.attachmentId = id;
          scope.attachment.attachmentName = name;
          scope.attachment.attachmentSize = size;

          scope.$emit(constants.EVENTS.CREATE_BID_UPLOAD_MESSAGE, {
            error: false,
            message: 'Successfully saved the attachment'
          });

          refresh(scope);
          spinnerService.stop();
        }).fail(({ responseText }: XMLHttpRequest) => {
          const message = angular.fromJson(responseText).message || 'Attachment Failed';

          scope.$emit(constants.EVENTS.FILE_UPLOAD_MESSAGE, {
            error: true,
            message,
            index: scope.$index
          });

          spinnerService.stop();
        });
      } else {
        // else emit an event
        scope.$emit(constants.EVENTS.FILE_UPLOAD_MESSAGE, {
          error: true,
          message: `File(s) exceed ${NumberUtils.bytesToMegabytes(appConfig.maxFileSize)} MB limit`,
          index: scope.$index
        });
        refresh(scope);

        spinnerService.stop();
      }
    });
  };

  const createFileInput = (scope, element) => {
    const uploadUrl = `${ApiUrlPrefix.BIDS}/bid-attachments`;
    const el = angular.element(element);
    const pathLabel = el[0].children[0];
    const fileInputContainer = el[0].children[1];

    let fileInput;
    // @ts-ignore
    if (angular.element(fileInputContainer).children('.file-input').length === 0) {
      fileInput = angular.element('<input type="file" class="file-input" name="file"/>');
      angular.element(fileInputContainer).append(fileInput);
    } else {
      fileInput = element.find('.file-input').first();
    }

    if (scope.attachment.attachmentId) {
      fileInput.attr('disabled', true);
    } else {
      fileInput.attr('disabled', false);
      initializeFileUploadPlugin(fileInput, uploadUrl, scope, el);
    }

    $(pathLabel).css({
      cursor: 'pointer',
      color: 'gray'
    });
  };

  /**
   * @param {Object} scope
   * @param {Object} scope.attachment
   * @param {Function} scope.$watch
   * @param {Object} element
   */
  const linkFunction = (scope, element) => {

    $(document).on('dragover dragenter drop', event => {
      event.preventDefault();
      event.stopPropagation();
    });

    scope.$watch('attachment.attachmentId', val => {
      if (val) {
        const fileInput = element.find('.file-input').first();
        fileInput.attr('disabled', true);
        fileInput.fileupload({ dropZone: null });
      }
    });

    scope.$watchCollection('term.value', () => {
      createFileInput(scope, element);
    });

    scope.$watch('term.definition.biddingInfo.bidId', (newBidId, oldBidId) => {
      if (newBidId !== oldBidId || !oldBidId) {
        createFileInput(scope, element);
      }
    });
  };

  return {
    restrict: 'A',
    replace: true,
    link: linkFunction
  };
}];
