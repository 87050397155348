<ng-container *ngIf="!isEditEnabled()">
  <ng-template
      [qvAtNdc]="termValue"
      [setDisableClick]="!isEditMode || isPending"
      (overwriteValuesConfirmed)="overwriteValue()">
    <div class="term__value" [class.term__value--empty]="!getTermValue()">
      {{ maxCharCount ? (getTermValue() | truncate: maxCharCount) : getTermValue() }}
    </div>
  </ng-template>
</ng-container>

<ng-container *ngIf="isEditEnabled()">
  <textarea
    #termTextarea
    class="term__value--textarea"
    [formControl]="control"
    [maxLength]="termConfig.maxLength"
    (input)="textAreaChanges$.next($event)"
    (focus)="initChangesHandler()"
    (blur)="onBlur()"
  ></textarea>
</ng-container>
