import { Pipe, PipeTransform } from '@angular/core';
import { NumberUtils } from '../utils';
import { NumberOptions } from '../models';

@Pipe({
  name: 'numberFixed'
})
export class NumberFixedPipe implements PipeTransform {
  public transform(
    value: string | number,
    options: NumberOptions = new NumberOptions(5, 2, 0.0001)
  ): string | number {
    return NumberUtils.isNumeric(value)
      ? NumberUtils.toFixedDecimals(Number(value), options.decimals, options.minDecimals, options.step)
      : value;
  }
}
