<div class="snack-bar">
  <div class="snack-bar__icon">
    <mat-icon [svgIcon]="svgIconName.INFO_FILLED"></mat-icon>
  </div>
  <div class="snack-bar__message">{{ message }}</div>
  <button (click)="onClearFiltersClick()"
          *ngIf="showClearButton"
          class="qv-text-btn qv-text-btn--white"
  >clear filters
  </button>
  <button (click)="onClose()" *ngIf="showCloseButton" class="snack-bar__close-button">
    <div>&times;</div>
  </button>
</div>
