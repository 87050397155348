import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { BaseScenarioRow } from '@qv-bid/components/shared/base';
import { BidEventBusService } from '@qv-bid/services/bid-event-bus.service';
import { NdcManagerService } from '@qv-bid/services/ndc-manager.service';
import {
  BidStateService,
  ContractedBusinessesService,
  DrugAlertNotificationService,
  VirtualScrollerService,
} from '@qv-bid/services';
import { collapseExpandAnimation } from '@qv-bid/constants';
import { MarketShareService } from '@qv-bid/services/sections';
import { SectionChangeManager } from '@qv-bid/services/sections/section-change-manager.service';
import { SummaryService } from '@qv-bid/services/summary';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Summary } from '@qv-bid/entities';
import { SectionFilter } from '@qv-bid/enums';
import { SectionFilterCheckboxes } from '@qv-bid/models';
import { DrugSelectService } from '@qv-bid/services/selects';
import { ScenariosSnackBarService } from '@qv-bid/services/scenarios-snack-bar.service';
import { Observable } from 'rxjs';
import { BidViewService } from '@qv-bid/services/bid-view.service';
import { DrugSectionsFormService, StandardDrugFormService, } from '@qv-bid/services/drug';
import { TermSection } from '@qv-term/enums';
import { NotificationService } from 'quantuvis-angular-common/notification';

@UntilDestroy()
@Component({
  animations: [collapseExpandAnimation],
  selector: 'qv-standard-scenario',
  templateUrl: './standard-scenario.component.html',
  styleUrls: ['./standard-scenario.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    StandardDrugFormService,
    DrugSectionsFormService,
    DrugAlertNotificationService,
    DrugSelectService,
    MarketShareService,
    SectionChangeManager,
  ]
})
export class StandardScenarioComponent extends BaseScenarioRow implements OnInit, OnChanges {
  @Input()
  public filter: SectionFilterCheckboxes;
  public readonly sectionFilterEnum = SectionFilter;

  constructor(
    notificationService: NotificationService,
    changeDetectorRef: ChangeDetectorRef,
    ndcManagerService: NdcManagerService,
    virtualScrollerService: VirtualScrollerService,
    protected scenariosSnackBarService: ScenariosSnackBarService,
    bidEventBusService: BidEventBusService,
    drugAlertNotificationService: DrugAlertNotificationService,
    bidStateService: BidStateService,
    bidViewService: BidViewService,
    contractedBusinessesService: ContractedBusinessesService,
    sectionChangeManager: SectionChangeManager,
    private standardDrugFormService: StandardDrugFormService,
    public drugSelectService: DrugSelectService,
    private summaryService: SummaryService,
  ) {
    super(
      scenariosSnackBarService,
      bidStateService,
      bidEventBusService,
      drugAlertNotificationService,
      ndcManagerService,
      notificationService,
      changeDetectorRef,
      bidViewService,
      virtualScrollerService,
      contractedBusinessesService,
      sectionChangeManager
    );
  }

  public ngOnInit(): void {
    this.standardDrugFormService.setDrug(this.scenario);

    this.initRowHandlers();
    this.initNdcsHandler();
  }

  public transitToEditMode(): void {
    this.standardDrugFormService.transitDrugToEditMode();
  }

  public ngOnChanges({ scenario }: SimpleChanges): void {
    if (scenario && !scenario.firstChange) {
      this.changeDetectorRef.detectChanges();
      this.standardDrugFormService.updateDrug(scenario.currentValue);
      this.drugAlertNotificationService.calculateScenarioNotification(scenario.currentValue.drug.drugAlerts);
    }
  }

  public get summary(): Observable<Summary> {
    return this.summaryService.getSummary;
  }

  protected initSectionChangedHandler(): void {
    this.standardDrugFormService.sectionChanged$.pipe(untilDestroyed(this))
      .subscribe((section: TermSection) => this.scenariosSnackBarService.sectionChanged$.next(section));
  }
}
