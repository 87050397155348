import { Injectable } from '@angular/core';
import { DrugFormService } from '@qv-bid/services/drug/drug-form.service';
import { DrugSectionsFormService } from '@qv-bid/services/drug/drug-sections-form.service';
import { BidFormService, BidStateService } from '@qv-bid/services';
import { FormBuilder } from '@angular/forms';
import { SingleNotificationService } from '@qv-shared/services';
import { NotificationService } from 'quantuvis-angular-common/notification';
import { UserService } from '@qv-common/services/auth/user.service';

@Injectable()
export class StandardDrugFormService extends DrugFormService {
  public constructor(
    formBuilder: FormBuilder,
    drugSectionsFormService: DrugSectionsFormService,
    bidStateService: BidStateService,
    notificationService: NotificationService,
    userService: UserService,
    bidFormService: BidFormService,
    singleNotificationService: SingleNotificationService
  ) {
    super(
      formBuilder,
      drugSectionsFormService,
      bidStateService,
      notificationService,
      userService,
      bidFormService,
      singleNotificationService
    );
  }
}
