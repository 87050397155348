import { MarketBasketModalState } from '@qv-bid/enums';
import { MarketBasket } from '@qv-bid/entities';

export class MarketBasketModalData {
  constructor(
    public isEditMode: boolean,
    public isNdcLevel: boolean,
    public isBidInternal: boolean,
    public manufacturerCompanyOldName: string,
    public marketBasket?: MarketBasket,
    public scenarioId?: number,
    public drugName?: string,
    public drugClass?: string,
    public state: MarketBasketModalState = MarketBasketModalState.ASSIGN
  ) {}
}
