import { BaseEntity } from '@qv-bid/entities/base-entity';
import { NumberConverter } from '@qv-bid/serializers';
import { DictionaryItem } from '@qv-common/entities';
import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('PharmaAudit')
export class PharmaAudit extends BaseEntity {

  @JsonProperty('cbId', Number)
  public contractedBusinessId: number = null;

  @JsonProperty('frequency', DictionaryItem)
  public pharmaAuditFrequency: DictionaryItem = null;

  @JsonProperty('isLocked', Boolean)
  public pharmaAuditLock = false;

  @JsonProperty('lookBack', NumberConverter)
  public lookBack: number | string = null;

  @JsonProperty('priorNoticeRequired', NumberConverter)
  public priorNoticeRequired: number | string = null;
}
