import { constants } from '@qv-common/static';
import { SvgIconName } from '@qv-common/enums';

class ContractController {
  public static $inject = ['translations'];
  private translations;
  private contractTerms;
  private svgIconName;
  private preserveOriginalLocks;
  private preparedContractTerms;
  private BidService;
  private bid;
  private options;
  private onValid;

  constructor(translations) {
    'ngInject';
    this.translations = translations;
    this.translations.buildTranslations(this);
    this.contractTerms = constants.BID_SECTIONS.CONTRACT_TERMS;
    this.svgIconName = SvgIconName;
  }

  public init(): void {
    if (this.options && this.options.allowPreserveOriginalLocks && this.BidService) {
      this.preserveOriginalLocks = this.BidService.bidConfig.summaryPreserveOriginalLocks;
    }
    if (this.BidService && !this.bid && !this.preparedContractTerms) {
      this.bid = this.BidService.getBidConfig();
      this.preparedContractTerms = this.prepareContractTerms();
    }
  }

  public $onChanges(changes): void {
    if (changes.bidService) {
      this.BidService = changes.bidService.currentValue;
      this.init();
    }
    if (changes.options) {
      this.options = changes.options.currentValue;
      this.init();
    }
    if (changes.runValidation && changes.runValidation.currentValue) {
      this.onValid({ value: this.isValid() });
    }
  }

  public getTermByName(name): any {
    return this.bid.contract.find(term => term.name === name);
  }

  /**
   * Validate contract terms
   *
   * @returns {boolean}
   */
  public isValid(): boolean {
    let valid = true;
    for (const term of this.bid.contract) {
      if (term.definition.validate) {
        const testValidity = term.definition.validate(term, this.bid.contract);
        if (valid) {
          valid = testValidity;
        }
      }
    }

    return valid;
  }

  public lockOrUnlock(termsToLockOrUnlock, addGroupPath): void {
    this.BidService.lockOrUnlock(termsToLockOrUnlock, addGroupPath);
  }

  public onPreserveLocksChange(): void {
    if (this.preserveOriginalLocks) {
      this.BidService.setMetadata({});
      this.bid.contract.forEach(term => {
        if (term.definition && term.definition.state) {
          delete term.definition.state;
        }
      });
    }
    this.bid.contractPreserveOriginalLocks = this.preserveOriginalLocks;
  }

  public prepareContractTerms(): any[] {
    /** Contract term groups (Contract terms are displayed on 3 columns) */
    const contractTermGroups = [
      [
        constants.CONTRACT_TERMS.REBATE_ELIGIBILITY.label,
        constants.CONTRACT_TERMS.WHOLESALE_ACQUISITION_PRICE.label,
        constants.CONTRACT_TERMS.PAYMENT_LAG.label,
        constants.CONTRACT_TERMS.PAYMENT_FREQUENCY.label
      ],
      [
        constants.CONTRACT_TERMS.LATE_PAYMENT_PENALTY_TERMS_BASIS.label,
        constants.CONTRACT_TERMS.LATE_PAYMENT_PENALTY_TERMS_VALUE.label,
        constants.CONTRACT_TERMS.LATE_PAYMENT_PENALTY_TERMS_FREQUENCY.label,
        constants.CONTRACT_TERMS.ADMINISTRATION_FEE.label
      ],
      [
        constants.CONTRACT_TERMS.PHARMA_AUDIT_TERMS_AUDIT_FREQUENCY.label,
        constants.CONTRACT_TERMS.PHARMA_AUDIT_TERMS_PRIOR_NOTICE_REQUIRED.label,
        constants.CONTRACT_TERMS.PHARMA_AUDIT_TERMS_LOOK_BACK.label,
        constants.CONTRACT_TERMS.NO_FAULT_TERMINATION_CLAUSE_WHO_MAY_INVOKE.label,
        constants.CONTRACT_TERMS.NO_FAULT_TERMINATION_CLAUSE_PRIOR_NOTICE_REQUIRED.label
      ]
    ];
    const preparedContractTerms = [];
    for (let i = 0; i < contractTermGroups.length; i++) {
      preparedContractTerms[i] = [];
      for (const elem of contractTermGroups[i]) {
        preparedContractTerms[i].push(this.bid.contract.find(term => term.name === elem));
      }
    }
    preparedContractTerms[1].push(this.getTermByName(constants.BIDDING.LATE_PAYMENT_PENALTY_TERMS));

    return preparedContractTerms;
  }

  public resetValue(): void {
    this.bid.contract.forEach(term => {
      if (term.definition && typeof term.definition.resetValue === 'function') {
        term.definition.resetValue(term);
      }
    });
    this.preserveOriginalLocks = true;
    this.onPreserveLocksChange();
  }

  public getLockUnlockIconName(term): any {
    return this.BidService.getLockUnlockIconName(term);
  }

  public getLockUnlockIconClass(term): any {
    return this.BidService.getLockUnlockIconClass(term);
  }

  public neitherPartySelected(term): boolean {
    return term.value === constants.OPTIONS.NO_FAULT_TERMINATION_CLAUSE_WHO_MAY_INVOKE.NEITHER_PARTY;
  }
}

export default ContractController;
