import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  EventEmitter,
  Output,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, tap } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'acc-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchComponent implements OnInit, OnChanges {

  @Input()
  public placeholder = 'Search';

  @Input()
  public containerClass: string;

  @Input()
  public inputClass: string;

  @Input()
  public isSearchDisabled: boolean;

  @Input()
  public debounce = 0;

  @Input()
  public isLoading = false;

  @Input()
  public value: string;

  @Input()
  public isResetSearchQuery: boolean;

  @Output()
  public valueChanged = new EventEmitter<string>();

  public searchControl = new FormControl('');

  public isClearSearchAvailable$ = new BehaviorSubject(false);

  public ngOnInit(): void {
    this.handleSearchState();
    this.initSearchValueChangeHandler();
    this.fillSearchValue();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.isResetSearchQuery?.currentValue) {
      this.onSearchClear();
    }
  }

  public onSearchFocus(): void {
    this.isClearSearchAvailable$.next(Boolean(this.searchControl.value));
  }

  public onSearchBlur(): void {
    this.isClearSearchAvailable$.next(false);
  }

  public onSearchClear(): void {
    this.searchControl.reset('');
  }

  private initSearchValueChangeHandler(): void {
    this.searchControl.valueChanges
      .pipe(
        distinctUntilChanged(),
        debounceTime(this.debounce),
        tap((value: string) => this.isClearSearchAvailable$.next(Boolean(value))),
        filter(() => this.searchControl.enabled),
        tap((value: string) => this.valueChanged.emit(value ? value.trim() : value)),
        untilDestroyed(this)
      )
      .subscribe();
  }

  private fillSearchValue(): void {
    if (this.value) {
      this.searchControl.patchValue(this.value);
    }
  }

  private handleSearchState(): void {
    if (this.isSearchDisabled) {
      this.searchControl.disable();
    }
  }
}
