export enum CompanyType {
  PAYER = 'PAYER',
  PHARMA = 'PHARMA',
  ADMIN = 'ADMIN',
  PAYER_INTERNAL = 'PAYER_INTERNAL',
}

export namespace CompanyType {
  const displayValues = new Map([
    [CompanyType.PAYER, 'Payer'],
    [CompanyType.PHARMA, 'Pharma'],
    [CompanyType.ADMIN, 'Admin'],
    [CompanyType.PAYER_INTERNAL, 'Payer'],
  ]);

  export function displayValue(companyType: CompanyType): string {
    return displayValues.get(companyType);
  }
}
