export class AbstractSummaryController {
  protected bid;

  constructor() {
    this.bid = null;

    if (this.constructor.name === 'AbstractSummaryController') {
      throw new TypeError('Cannot construct Abstract instances directly');
    }
  }

  /**
   * Validate summary terms
   *
   * @returns {boolean}
   */
  public isValid(): boolean {
    let valid = true;
    for (const term of this.bid.summary) {
      if (term.definition.processKeepOriginalValue && term.definition.keepOriginalValue) {
        continue;
      }

      if (term.definition.validate) {
        const testValidity = term.definition.validate(term, this.bid.summary);
        if (valid) {
          valid = testValidity;
        }
      }
    }

    return valid;
  }

  /**
   * Get term by name
   *
   * @param name term name
   * @returns {Object} term which was found
   */
  public getTermByName(name): any {
    return this.bid.summary.find(search => search.name === name);
  }
}
