import { Inject, Injectable } from '@angular/core';
import { JsonConvert } from 'json2typescript';
import { SnackBarService } from 'quantuvis-angular-common/snack-bar';
import { catchError, finalize, map, switchMap, take, tap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { combineLatest, Observable, OperatorFunction, pipe, throwError } from 'rxjs';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { BlockingMessage } from '@qv-common/enums';
import { ViewPerspectiveService } from '@qv-common/services/auth';
import { BidSelectService, DrugSelectUtilsService } from '@qv-bid/services/selects';
import {
  DrugPasteValuesModalConfig,
  DrugPasteValuesModalData
} from '@qv-bid/components/shared/drug-paste-values-modal/models';
import { JSON_CONVERT_TOKEN } from 'quantuvis-angular-common/json-converter/services';
import { NdcManagerService } from '@qv-bid/services/ndc-manager.service';
import { ContractedBusinessScenarioIds, DrugCb, Ndc, Scenario } from '@qv-bid/entities';
import { ScenariosDependencyService } from '@qv-bid/services/scenarios-dependency.service';
import { BidStateService, ContractedBusinessesService, ScenarioService } from '@qv-bid/services';
import { BidUtils } from '@qv-bid/utils';
import { DrugDaoService, ScenarioDaoService } from '@qv-bid/services/dao';
import { DrugAddModalConfig } from '@qv-bid/components/shared/drug-add-modal/models';
import { DeleteDrugService } from '@qv-bid/services/delete-drug.service';
import { SelectedDrugs } from '@qv-bid/models/selected-drugs';
import { DrugCopyPasteService } from '@qv-bid/services/drug-copy-paste.service';
import { CoreUtils } from '@qv-common/utils';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BidEventBusService } from '@qv-bid/services/bid-event-bus.service';
import { ModalService } from 'quantuvis-angular-common/modal';
import { NotificationService } from 'quantuvis-angular-common/notification';
import { UserService } from '@qv-common/services/auth/user.service';
import { BidErrorResponseStatusUtil } from '@qv-bid/utils/bid-error-response-status.util';

@UntilDestroy()
@Injectable()
export class DrugEditActionsService {
  @BlockUI()
  public blockUI: NgBlockUI;

  constructor(
    private userService: UserService,
    private bidSelectService: BidSelectService,
    private bidEventBusService: BidEventBusService,
    private drugDaoService: DrugDaoService,
    @Inject(JSON_CONVERT_TOKEN) private jsonConvert: JsonConvert,
    private notificationService: NotificationService,
    private scenarioDaoService: ScenarioDaoService,
    private contractedBusinessesService: ContractedBusinessesService,
    private ndcManagerService: NdcManagerService,
    private bidStateService: BidStateService,
    private drugSelectUtilsService: DrugSelectUtilsService,
    private drugCopyPasteService: DrugCopyPasteService,
    private modalService: ModalService,
    private scenarioService: ScenarioService,
    private scenariosDependencyService: ScenariosDependencyService,
    private snackBarService: SnackBarService,
    private viewPerspectiveService: ViewPerspectiveService,
    private deleteDrugService: DeleteDrugService,
  ) { }

  public changeLockForNdcs(isLocked: boolean): void {
    this.snackBarService.start();

    this.scenarioService.getSelectedCbScenarioIds()
      .pipe(
        switchMap((cbScenarioIds: ContractedBusinessScenarioIds[]) =>
          this.scenarioDaoService.lockNdcs(this.bidStateService.bidVersionId, cbScenarioIds, isLocked)
        ),
        this.handleServerError(),
      )
      .subscribe(() => {
        this.snackBarService.finish();
        this.bidEventBusService.undoRedoEvent.emit();
        this.bidEventBusService.reloadScenariosEvent.emit(this.bidSelectService.getSelectedScenarioIds());
        this.bidEventBusService.reloadLoadedNdcsEvent.emit(this.bidSelectService.getSelectedScenarioIds());
        this.bidSelectService.clear();
      });
  }

  public dismissDrugs(): void {
    this.blockUI.start(BlockingMessage.DISMISSING);
    this.snackBarService.start();

    const bid = this.bidStateService.bid$.getValue();

    this.drugDaoService.dismissDrugs(
      this.bidStateService.bidVersionId,
      [new SelectedDrugs(this.bidStateService.cbId,
        this.bidSelectService.getSelectedNdcIds(),
        this.bidSelectService.getSelectedScenarioIds())],
      bid.isInternal
    ).pipe(this.reloadDismissedScenarios()).subscribe();
  }

  public recallDrugs(): void {
    this.blockUI.start(BlockingMessage.RECALLING);
    this.snackBarService.start();

    const bid = this.bidStateService.bid$.getValue();

    this.drugDaoService.recallDrugs(
      this.bidStateService.bidVersionId,
      [new SelectedDrugs(this.bidStateService.cbId,
        this.bidSelectService.getSelectedNdcIds(),
        this.bidSelectService.getSelectedScenarioIds())],
      bid.isInternal
    ).pipe(this.reloadScenariosAndNdcs()).subscribe();
  }

  public deleteScenarios(): void {
    this.snackBarService.start();
    this.scenarioService.getSelectedCbScenarioIds().pipe(
      switchMap((cbScenarioIds: ContractedBusinessScenarioIds[]) => this.scenarioDaoService.deleteScenarios(
        this.bidStateService.bidVersionId,
        cbScenarioIds,
        this.bidStateService.bid$.getValue().isInternal
      )),
      this.handleServerError(),
    ).subscribe(() => {
      this.loadScenariosAndClearSelection();
    });
  }

  public createScenarios(): void {
    this.snackBarService.start();

    this.scenarioService.getSelectedCbScenarioIds().pipe(
      switchMap((cbScenarioIds: ContractedBusinessScenarioIds[]) => this.scenarioDaoService.createScenarios(
        this.bidStateService.bidVersionId,
        cbScenarioIds,
        this.bidStateService.bid$.getValue().isInternal
      )),
      this.handleServerError(),
    ).subscribe(() => {
      this.loadScenariosAndClearSelection();
    });
  }

  public deleteHistoricNotes(bidVersionId: number, cbId: number): Observable<void> {
    return combineLatest([
      this.bidSelectService.scenarioSelected(),
      this.bidSelectService.ndcSelected(),
    ]).pipe(
      take(1),
      switchMap(([scenarioList, ndsList]) => {
        this.snackBarService.start();
        return this.contractedBusinessesService.deleteHistoricNotes(bidVersionId, cbId, scenarioList, ndsList);
      }),
      // TODO: Refactor subscribe in tap, and use reloadScenariosAndNdcs operator instead reloadSelected function
      tap(() => {
        this.snackBarService.finish();
        this.contractedBusinessesService.loadHistoricNotesPresents(bidVersionId, cbId).subscribe();
        this.reloadSelected();
      }),
      this.handleServerError()
    );
  }

  public copyValues(): void {
    const ndcId = this.bidSelectService.getSelectedNdcIds()[0];
    const scenarioId = this.bidSelectService.getSelectedScenarioIds()[0]
      || this.drugSelectUtilsService.getScenarioIdByNdcId(ndcId);
    const cbId = this.bidStateService.cbId;
    const drugName = this.getSelectedDrugNameByScenarioId(scenarioId);
    const isNdc = !(this.drugSelectUtilsService.getSelectedScenarioCount());
    let copiedSource;
    const source = this.bidStateService.scenarios$.getValue()
      .find((scenario: Scenario) => scenario.id === scenarioId);
    copiedSource = CoreUtils.copyDeep(source);

    if (isNdc) {
      copiedSource.drug = this.getNdcByNdcId(scenarioId, ndcId);
      copiedSource = CoreUtils.copyDeep(copiedSource);
    }
    copiedSource.drug.versionWAC = null;
    copiedSource.drug.currentWAC = null;
    copiedSource.drug.deltaWAC = null;
    copiedSource.drug.deltaPWAC = null;

    copiedSource.drug = this.jsonConvert.serialize(copiedSource.drug);
    copiedSource.marketBasket = this.jsonConvert.serialize(copiedSource.marketBasket);
    this.drugCopyPasteService.storeDrug({ scenarioId, ndcId, cbId, drugName, isNdc, source: copiedSource });
    this.bidSelectService.clear();
  }

  public pasteValues(): void {
    const bid = this.bidStateService.bid$.getValue();

    const pasteValuesModal = this.modalService.openModal(new DrugPasteValuesModalConfig(
      new DrugPasteValuesModalData(BidUtils.isUserPharmaOrPharmaPerspective(this.userService.isCurrentUserPharma(),
        bid.isInternal, this.viewPerspectiveService.isPharmaViewPerspective()))
    ));

    pasteValuesModal.componentInstance.primaryAction.pipe(
      switchMap(() => this.pastValuesAction().pipe(
        pasteValuesModal.componentInstance.handleAfterAction(),
        finalize(() => pasteValuesModal.componentInstance.primaryActionFinished())
      )),
    ).subscribe();
  }

  public openAddDrugModal(pharmaId: number): void {
    this.contractedBusinessesService.getUniqueDrugList(this.bidStateService.bidVersionId)
      .pipe(
        switchMap((alreadyAddedDrugs: Map<number, number[]>) => this.modalService.openModal(
          new DrugAddModalConfig(
            this.bidStateService.bidVersionId,
            pharmaId,
            alreadyAddedDrugs,
            this.contractedBusinessesService.contractedBusinesses$.getValue(),
            this.bidStateService.bid$.getValue().isInternal
          )).componentInstance.drugsAdded
        ),
        tap(() => {
          this.bidEventBusService.undoRedoEvent.emit();
          this.bidEventBusService.loadScenariosAndDropNdcsCacheEvent.emit();
        }),
        untilDestroyed(this)
      ).subscribe();
  }

  public openDeleteDrugModal(isBidInternal: boolean): Observable<void> {
    return combineLatest([
      this.bidStateService.scenarios$.pipe(
        map((scenarios: Scenario[]) => this.drugSelectUtilsService.getSelectedScenarios(scenarios))
      ),
      this.drugDaoService.getDrugsWithCb(this.bidStateService.bidVersionId).pipe(
        this.handleServerError()
      )
    ]).pipe(
      take(1),
      map(([selectedScenarios, drugs]: [Scenario[], DrugCb[]]) =>
        this.deleteDrugService
          .openDeleteDrugModal(this.bidStateService.bidVersionId, drugs, selectedScenarios, isBidInternal)),
    );
  }

  private getSelectedDrugNameByScenarioId(scenarioId: number): string {
    const scenario = this.bidStateService.scenarios$.value.find(({ id }: Scenario) => id === scenarioId);

    return scenario ? scenario.drugName : null;
  }

  private getNdcByNdcId(scenarioId: number, ndcId: number): Ndc {
    return this.ndcManagerService.ndcs.getValue()
      .get(scenarioId)
      .find((ndc: Ndc) => ndc.drugId === ndcId);
  }

  private loadScenariosAndClearSelection(): void {
    this.snackBarService.finish();
    this.bidSelectService.clear();
    this.bidEventBusService.undoRedoEvent.emit();
    this.bidEventBusService.loadScenariosEvent.emit();
  }

  private pastValuesAction(): Observable<null | HttpErrorResponse> {
    const storedDrug = this.drugCopyPasteService.getStoredDrug();
    const bid = this.bidStateService.bid$.getValue();

    this.snackBarService.start();

    const targetScenarios = this.bidSelectService.getSelectedScenarioIds();
    let targetNdcs = this.bidSelectService.getSelectedNdcIds();

    targetScenarios.forEach(sId => {
      targetNdcs = this.removeNdcIdsIfAllSelectedForScenario(sId, targetNdcs);
    });

    return this.drugDaoService.pasteDrugValues(
      this.bidStateService.bidVersionId,
      storedDrug.source,
      targetScenarios,
      targetNdcs,
      bid.isInternal
    ).pipe(this.reloadScenariosAndNdcs());
  }

  private removeNdcIdsIfAllSelectedForScenario(scenarioId: number, targetNdcs: number[]): number[] {
    const ndcs = this.ndcManagerService.ndcs.getValue();

    const allScenarioNdcs = ndcs.get(scenarioId) ? ndcs.get(scenarioId).map(ndc => ndc.drugId) : [];
    const isAllSelected = this.drugSelectUtilsService.allNdcForScenarioSelected(scenarioId, allScenarioNdcs);

    if (isAllSelected) {
      return targetNdcs.filter((el) => !allScenarioNdcs.includes(el));
    } else {
      return targetNdcs;
    }
  }

  private reloadSelected(): void {
    const scenarioList = this.drugSelectUtilsService.getScenariosIdsWithSelectedNdcs();

    this.bidEventBusService.undoRedoEvent.emit();
    this.bidEventBusService.reloadScenariosEvent.emit(scenarioList);
    this.bidEventBusService.reloadLoadedNdcsEvent.emit(scenarioList);
    this.bidSelectService.clear();
  }

  private reloadAllScenariosAndLoadedNdcs<T>(): OperatorFunction<T, T> {
    const scenariosIdList = this.bidStateService.scenarios$.getValue().map((scenario: Scenario) => scenario.id);

    return pipe(
      tap(() => {
        this.loadScenariosAndClearSelection();
        this.bidEventBusService.reloadLoadedNdcsEvent.emit(scenariosIdList);
      }),
      this.handleServerError(),
      finalize(() => this.blockUI.stop())
    );
  }

  private reloadScenariosAndNdcs<T>(): OperatorFunction<T, T> {
    return pipe(
      tap(() => {
        this.snackBarService.finish();
        this.bidEventBusService.undoRedoEvent.emit();
        this.bidEventBusService.reloadScenariosEvent
          .emit(this.drugSelectUtilsService.getScenariosIdsWithSelectedNdcs());
        this.bidEventBusService.reloadLoadedNdcsEvent
          .emit(this.drugSelectUtilsService.getScenariosIdsWithSelectedNdcs());
        this.bidSelectService.clear();
      }),
      this.handleServerError(),
      finalize(() => this.blockUI.stop())
    );
  }

  private handleServerError<T>(): OperatorFunction<T, T> {
    return catchError((response: HttpErrorResponse) => {
      this.snackBarService.error();

      return BidErrorResponseStatusUtil.isShowErrorInNotification(response) ?
        this.notificationService.showServerError(response) :
        throwError(response);
    });
  }

  private reloadDismissedScenarios<T>(): OperatorFunction<T, T> {
    if (this.scenariosDependencyService.isSomeSelectedDrugHasDependency()) {
      return this.reloadAllScenariosAndLoadedNdcs();
    }

    return pipe(
      tap(() => {
        if (this.drugSelectUtilsService.isSelectedScenarioOrNdcInEditMode(
          this.bidStateService.activeDrugInEditMode.getValue()
        )) {
          this.bidStateService.activeDrugInEditMode.next(null);
        }
      }),
      this.reloadScenariosAndNdcs()
    );
  }
}
