import { DictionaryItem } from 'quantuvis-angular-common/interfaces';

export enum StepTherapyRequired {
  EMPTY = 79,
  NO,
  YES_SINGLE_STEP,
  YES_DOUBLE_STEP,
  YES_TRIPLE_STEP,
  YES_OTHER
}

export namespace StepTherapyRequired {
  const valuesMap = new Map<StepTherapyRequired, DictionaryItem>([
    [StepTherapyRequired.EMPTY, { id: StepTherapyRequired.EMPTY, name: '' }],
    [StepTherapyRequired.NO, { id: StepTherapyRequired.NO, name: 'No' }],
    [
      StepTherapyRequired.YES_SINGLE_STEP,
      { id: StepTherapyRequired.YES_SINGLE_STEP, name: 'Yes - Single step' }
    ],
    [
      StepTherapyRequired.YES_DOUBLE_STEP,
      { id: StepTherapyRequired.YES_DOUBLE_STEP, name: 'Yes - Double step' }
    ],
    [
      StepTherapyRequired.YES_TRIPLE_STEP,
      { id: StepTherapyRequired.YES_TRIPLE_STEP, name: 'Yes - Triple step' }
    ],
    [
      StepTherapyRequired.YES_OTHER,
      { id: StepTherapyRequired.YES_OTHER, name: 'Yes - Other' }
    ],
  ]);

  export function getValue(stepTherapyAllowedProduct: StepTherapyRequired): DictionaryItem {
    return valuesMap.get(stepTherapyAllowedProduct);
  }

  export function getValues(): DictionaryItem[] {
    return Array.from(valuesMap.values());
  }
}
