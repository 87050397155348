/* tslint:disable:prefer-template no-invalid-this */

/**
 * Sticky Container directive
 *
 * @description
 * Used for keeping elements always in view port while scrolling and resizing container.
 * For basic use add 'sticky-container' attribute to element.
 *
 * Optional attributes:
 * 'sc-background'  - add this attribute with truthy value for adding white background under element
 *                    (ex.: sc-background='true', default value: false);
 * 'sc-padding-top' - add this attribute with number value for adding/preserving top padding when element is stuck
 *                    (ex.: sc-padding-top='5', no default value);
 * 'sc-offset'      - add this attribute with number value for setting offset from viewport's top where elements
 *                    will start to stuck (ex.: sc-offset='120', default value: 60);
 * 'sc-on-expanded' - add this attribute with truthy value for keepin directive working while expander is maximized
 *                    (ex.: sc-on-expanded='true', default value: false);
 * 'sc-container'   - add this attribute with CSS selector for applying directive not
 * to window but for specific container
 *                    (ex.: sc-container='#idOfElement', default value: Window Object)
 *                    (current limitations: width of element parent
 *                    should be equal to width of element in other case positioning will be broken);
 *
 * Notes: - Elements will stuck under each other, using order they have in DOM. Elements without background will be
 *          placed on the elements that have background, and will start from offset value regardless existing containers
 *          stack with background.
 *        - For styling stuck element you can use css selector with class that
 *        is added to stuck container: 'stuck-container'
 *
 * @author Vladimir Rozhnov <rozhnov.vladimir@gmail.com>
 */

declare let angular: angular.IAngularStatic;
declare let $: any;

export class StickyContainer {
  public static $inject = ['$document', '$window'];
  private restrict;
  private $document;
  private $window;

  constructor($document, $window) {
    'ngInject';
    this.restrict = 'A';
    this.$document = $document[0];
    this.$window = $window;
  }

  public link($scope, $element, $attr): void {
    const $document = this.$document;
    const $window = this.$window;
    const scrollContainer = $attr.scContainer || null;
    const setBackgroundColor = !!$attr.scBackground || false;
    const paddingTop = $attr.scPaddingTop ? +$attr.scPaddingTop : ''; // Empty string is used to leave padding from css
    const offset = $attr.scOffset ? +$attr.scOffset : 60; // Header height
    const showOnExpanded = !!$attr.scOnExpanded || false;

    // Details of the header position with expandable state of full screen section
    const detailHeaderPosition = null;
    let shouldMoveContainer = false;
    let isBogusElementPlaced = false;
    let hasExpandedSections = false;
    let containerIsWindow = true;
    let top, elementBoundingRect, parentElement, bogusElement, container;

    init();

    /**
     * Check if we can move container to the top when expander is maximized
     *
     * @returns {Boolean}
     */
    function applyWithExpander(): boolean {
      return hasExpandedSections && showOnExpanded;
    }

    /**
     * Get summary height of stuck containers
     *
     * @returns {Number}
     */
    function calculateTopContainersHeight(): any {
      const stickyContainers = getStuckElementsInContainer();

      return Array.prototype.reduce.call(stickyContainers,
        (acc, el) => {
          if (!!el.getAttribute('sc-background')) {
            // @ts-ignore
            return acc + el.offsetHeight - paddingTop;
          } else {
            return acc;
          }
        }, 0);
    }

    /**
     * Get top position for container to stuck
     *
     * @returns {Number}
     */
    function calculateTopPosition(): any {
      const stickyContainers = getStuckElementsInContainer();

      return Array.prototype.reduce.call(stickyContainers,
        (acc, el) => {
          if (
            // tslint:disable-next-line:no-bitwise
            ($element[0].compareDocumentPosition(el) & Node.DOCUMENT_POSITION_PRECEDING) &&
            !!el.getAttribute('sc-background')
          ) {
            return acc + el.offsetHeight;
          } else {
            return acc;
          }
        }, offset);
    }

    /**
     * Detect whether container is in viewport or not
     *
     * @returns {boolean}
     */
    function containerIsInViewport(): boolean {
      top = getElementOffsetTop();
      const bogusElementHeight = bogusElement.offsetHeight || 0;
      const topPosition = top || 0;

      return getScrollOffset()
        // @ts-ignore
        <= (topPosition - offset - paddingTop - calculateTopContainersHeight() + bogusElementHeight)
        || !shouldMoveContainer;
    }

    /**
     * Create bogus element
     * @description Make a full copy without event handlers of element that will stuck.
     */
    function createBogusElement(): void {
      const bogusAttribute = $document.createAttribute('sc-bogus');

      parentElement = $element[0].parentNode;
      bogusElement = $element[0].cloneNode(true);
      bogusElement.setAttributeNode(bogusAttribute);
    }

    /**
     * Get top position of element in document
     *
     * @returns {number}
     */
    function getElementOffsetTop(): number {
      if (containerIsWindow) {
        elementBoundingRect = $element[0].getBoundingClientRect();
      } else {
        elementBoundingRect = {
          top: $element[0].getBoundingClientRect().top - container[0].getBoundingClientRect().top
        };
      }
      return ($element.hasClass('stuck-container') || !elementBoundingRect.top) ?
        top : elementBoundingRect.top + getScrollOffset();
    }

    /**
     * Get scroll offset depending on element container
     *
     * @returns {Number}
     */
    function getScrollOffset(): number {
      return containerIsWindow ? $window.pageYOffset : container[0].scrollTop;
    }

    /**
     * Get stuck elements in container
     *
     * @returns {Number}
     */
    function getStuckElementsInContainer(): any {
      let stuckElements;

      if (containerIsWindow) {
        stuckElements = $document.querySelectorAll('.stuck-container');
      } else {
        stuckElements = container[0].querySelectorAll('.stuck-container');
      }

      return stuckElements;
    }

    /**
     * Init directive event handlers
     */
    function init(): void {
      if (!('scBogus' in $attr)) {
        prepareContainer();
        createBogusElement();
        registerEventsOnContainer();

        $scope.$watch(() => $document.getElementsByClassName('maximized').length, count => {
          hasExpandedSections = !!count;
        });

        $scope.$on('sizeChanged', () => {
          shouldMoveContainer = true;
          if (scrollContainer && containerIsWindow) {
            unregisterEventsOnContainer();
            container = null;
            prepareContainer();
            registerEventsOnContainer();
          }
          onResize();
        });

        $scope.$on('$destroy', () => {
          unregisterEventsOnContainer();
          if (!containerIsWindow) {
            container.parent()[0].style.transform = '';
          }
        });
      }
    }

    /**
     * Check if element is a table header or table
     *
     * @param element
     * @returns {boolean}
     */
    function isTableElement(element): boolean {
      let isTable = false;

      if (element && element.nodeType === Node.ELEMENT_NODE) {
        isTable = ['THEAD', 'TABLE'].indexOf(element.tagName) !== -1;
      }

      return isTable;
    }

    /**
     * Move element to its original position in document
     */
    function moveToOriginalPosition(): void {
      $element.removeClass('stuck-container');

      $element[0].style.top = '';
      $element[0].style.width = '';
      $element[0].style.left = '';
      $element[0].style.right = '';
      $element[0].style.zIndex = '';
      $element[0].style.paddingTop = '';
      $element[0].style.backgroundColor = '';
      removeBogusElement();
      if (isTableElement($element[0])) {
        resetHeaderItemsWidth();
      }
    }

    /**
     * Stick container to the top
     */
    function moveToTopPosition(): void {
      if (isTableElement($element[0])) {
        setHeaderItemsWidth();
      }
      insertBogusElement();
      $element[0].style.top = calculateTopPosition() + 'px';
      $element[0].style.width = Math.ceil(elementBoundingRect.width) + 'px';
      if (containerIsWindow) {
        $element[0].style.left = Math.floor(elementBoundingRect.left) + 'px';
        $element[0].style.right = Math.ceil($document.documentElement.clientWidth - elementBoundingRect.right) + 'px';
      }
      $element[0].style.paddingTop = paddingTop + 'px';
      if (setBackgroundColor) {
        $element[0].style.backgroundColor = '#FFF';
      } else {
        $element[0].style.zIndex = 999;
      }
      $element.addClass('stuck-container');
    }

    /**
     * Handle container resize.
     *
     * @description Resizing may cause element to appear/disappear in viewport so we need to reapply calculations
     */
    function onResize(): void {
      moveToOriginalPosition();
      toggleStickyState();
    }

    /**
     * Insert bogus element on original position of stuck element.
     *
     * @description For preventing scroll jumping because of element movement from its original position we are placing
     * a copy of it but without event handlers (bogus element) on its position.
     */
    function insertBogusElement(): void {
      if (!isBogusElementPlaced && setBackgroundColor) {
        parentElement.insertBefore(bogusElement, $element[0]);
        isBogusElementPlaced = true;
      }
    }

    /**
     * Prepare scroll container
     *
     * @description Get container for attaching scroll and resize events.
     */
    function prepareContainer(): void {
      const scrollElem = $document.querySelectorAll(scrollContainer);

      if (scrollElem.length) {
        let parent = $element.parent();
        while (parent.length > 0 && !container) {
          scrollElem.forEach(el => {
            if (el === parent[0]) {
              container = parent;
              container.parent()[0].style.transform = 'translateZ(0)';
              containerIsWindow = false;
            }
          });
          parent = parent.parent();
        }
      }

      container = container || angular.element($window);
    }

    /**
     * Removing bogus element from document
     *
     * @description Replacing bogus element with original when element is back in viewport.
     */
    function removeBogusElement(): void {
      if (isBogusElementPlaced) {
        parentElement.removeChild(bogusElement);
        isBogusElementPlaced = false;
      }
    }

    /**
     * Detect whether or not we should stick element
     */
    function toggleStickyState(): void {
      if (detailHeaderPosition && detailHeaderPosition.expanded) {
        return;
      }
      if (!$element.hasClass('stuck-container') && (!containerIsInViewport() || applyWithExpander())) {
        moveToTopPosition();
      } else if ($element.hasClass('stuck-container') && containerIsInViewport() && !applyWithExpander()) {
        moveToOriginalPosition();
      }
    }

    /**
     * Preserve width of each table header column
     */
    function setHeaderItemsWidth(): void {
      const ths = $element.find('th');
      ths.each(function(): any {
        const elementInnerWidth = parseFloat(String($(this).width()));
        $(this).width(elementInnerWidth);
      });
    }

    /**
     * Register scroll and resize events
     */
    function registerEventsOnContainer(): void {
      container.on('resize', onResize);
      container.on('scroll', toggleStickyState);
    }

    /**
     * Restore width of each table header column to its original value
     */
    function resetHeaderItemsWidth(): void {
      const ths = $element.find('th');
      ths.each(function(): any {
        this.style.width = '';
      });
    }

    /**
     * Unregister scroll and resize events
     */
    function unregisterEventsOnContainer(): void {
      container.off('resize', onResize);
      container.off('scroll', toggleStickyState);
    }
  }
}
