<button tabindex="-1" class="sidenav__close" mat-icon-button [disabled]="isBidInvalid$ | async" (click)="sideNav.toggle()">
  <mat-icon class="sidenav__close-icon" svgIcon="arrow-next"></mat-icon>
</button>

<div class="sidenav__content" *ngIf="(summaryService.getSummary | async) as summary">
  <mat-tab-group class="tab-group tab-group--full-width tab-group--right-padding tab-group--left-icon-space">

    <mat-tab class="tab" [disabled]="isBidInvalid$ | async">
      <ng-template mat-tab-label>
        <h2 class="tab__title">Summary</h2>
      </ng-template>
      <ng-template matTabContent>
        <qv-summary
          [isSidebarOpened]="sideNav.opened"
          [bid]="bid"
          [summary]="summary"
          [isEditMode]="bidStateService.isEditMode | async"
          [isBidInvalid]="isBidInvalid$ | async"
        ></qv-summary>
      </ng-template>
    </mat-tab>

    <mat-tab class="tab" [disabled]="isBidInvalid$ | async">
      <ng-template mat-tab-label>
        <h2 class="tab__title">Contract terms</h2>
      </ng-template>
      <ng-template matTabContent>
        <qv-contract
          *ngIf="summary.bidVersionId"
          [isEditMode]="bidStateService.isEditMode | async"
          [isBidInvalid]="isBidInvalid$ | async"
          [isBidInternal]="bid.isInternal"
        ></qv-contract>
      </ng-template>
    </mat-tab>

    <mat-tab class="tab" [disabled]="isBidInvalid$ | async">
      <ng-template mat-tab-label>
        <h2 class="tab__title" [matTooltip]="tooltips.COMMENTS">Comments</h2>
        <div class="tab__counter qv-counter" *ngIf="isShowCountUnreadComments(summary.countUnreadComments, isCommentsLoaded)">
          {{ countUnreadComments }}
        </div>
      </ng-template>
      <ng-template matTabContent>
        <qv-bid-comments
          [summary]="summary"
          [bidStatus]="bid.status"
          [manufacturerCompanyOldName]="bid.manufacturerCompanyOldName"
          [isEditMode]="bidStateService.isEditMode | async"
          [isBidInvalid]="isBidInvalid$ | async"
          [isBidInternal]="bid.isInternal"
          (commentsLoaded)="onCommentsLoaded()"
        ></qv-bid-comments>
      </ng-template>
    </mat-tab>

    <mat-tab class="tab" *ngIf="isInternalFieldsAvailable()">
      <ng-template mat-tab-label>
        <h2 class="tab__title">Internal Fields</h2>
      </ng-template>
      <ng-template matTabContent>
        <qv-internal-fields
          *ngIf="sideNav.opened"
          [bidId]="bid.id"
        ></qv-internal-fields>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>
