import { Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { ApiService } from '@qv-common/services/api';
import { RequestOption } from '@qv-common/services/api/request-option.interface';
import { HttpMimeType, ResponseType } from 'quantuvis-angular-common/api';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { File } from '@qv-common/models/core';

@Injectable()
export class LegalAttestationDaoService {

  constructor(private apiService: ApiService) {}


  public getFileMeta(url: string): Observable<File> {
    return this.apiService.get(url)
      .pipe(map((response: HttpResponse<any>) =>
        response.body.data || new File() // files data { id, name, content }
      ));
  }

  public getFile(url: string): Observable<ArrayBuffer> {
    const options: RequestOption = {
      responseType: ResponseType.ARRAY_BUFFER
    };
    return this.apiService.get(url, options).pipe(map((response: HttpResponse<ArrayBuffer>) =>
      response.body // file buffer
    ));
  }

  public exportFile(url: string): Observable<Blob> {
    const options: RequestOption = {
      responseType: ResponseType.ARRAY_BUFFER
    };
    return this.apiService.get(url, options).pipe(map((response: HttpResponse<ArrayBuffer>) =>
      new Blob([response.body], {
        type: HttpMimeType.PDF
      })));
  }
}
