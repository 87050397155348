import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { PharmaRight } from '@qv-bid/entities';
import { BidRight } from '@qv-bid/enums';
import { BidEventBusService } from '@qv-bid/services/bid-event-bus.service';
import { SnackBarService } from 'quantuvis-angular-common/snack-bar';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { PharmaRightDaoService } from '@qv-bid/services/dao';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { PharmaRightsUtil } from '@qv-bid/utils';
import { PharmaRightsService } from '@qv-bid/services';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { HttpErrorResponse } from '@angular/common/http';

@UntilDestroy()
@Component({
  selector: 'qv-bid-pharma-rights',
  templateUrl: './bid-pharma-rights.component.html',
  styleUrls: ['./bid-pharma-rights.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BidPharmaRightsComponent implements OnInit {
  @Input()
  public isEditMode = false;
  @Input()
  public isBidInvalid: boolean;

  public pharmaRights$: Observable<PharmaRight[]>;

  public readonly bidRight = BidRight;

  constructor(
    private pharmaRightDaoService: PharmaRightDaoService,
    private bidEventBusService: BidEventBusService,
    private snackBarService: SnackBarService,
    private pharmaRightsService: PharmaRightsService
  ) { }

  public ngOnInit(): void {
    this.initPharmaRights();
  }

  public changeHandler(changes: MatCheckboxChange): void {
    this.snackBarService.start();
    this.pharmaRights$.pipe(
      take(1),
      map((pharmaRights: PharmaRight[]) => this.preparePharmaRightData(pharmaRights, changes)),
      switchMap((data: PharmaRight) => this.pharmaRightDaoService.updateByBidVersion(data.bidVersionId, data)),
      tap((pharmaRight: PharmaRight) => {
        this.pharmaRightsService.updateSingleRightAtList(pharmaRight);
        this.bidEventBusService.undoRedoEvent.emit();
        this.reloadScenarios(changes.source.name as BidRight);
        this.snackBarService.finish();
      }),
      catchError((error: HttpErrorResponse) => {
        this.snackBarService.error();
        return throwError(error);
      })
    ).subscribe();
  }

  private initPharmaRights(): void {
    this.pharmaRights$ = this.pharmaRightsService.pharmaRights$.pipe(
      map((pharmaRights: PharmaRight[]) => Array.isArray(pharmaRights) ? pharmaRights.sort((a, b) => {
        if (!a.right || !b.right) return;
        return BidRight.displayValue(a.right).localeCompare(BidRight.displayValue(b.right));
      }) : null),
      untilDestroyed(this)
    );
  }

  private preparePharmaRightData(pharmaRights: PharmaRight[], changes: MatCheckboxChange): PharmaRight {
    const pharmaRight = PharmaRightsUtil.getRight(pharmaRights, changes.source.name as BidRight);

    return Object.assign(pharmaRight, { isActive: changes.checked });
  }

  private reloadScenarios(name: BidRight): void {
    switch (name) {
      case BidRight.ALLOW_MARKET_SHARE_REBATE_OFFERS_FROM_PHARMA:
      case BidRight.LOCK_ALL_NOTES_FIELDS:
      case BidRight.ALLOW_PHARMA_TO_NEGOTIATE_MB:
      case BidRight.ALLOW_OFFERS_AT_THE_NDC_LEVEL_FROM_PHARMA:
        this.bidEventBusService.loadScenariosAndDropNdcsCacheEvent.emit();
        break;
      default:
        return null;
    }
  }
}
