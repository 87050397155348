import { filter } from 'rxjs/operators';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { StandardScenarioStateSelectComponent } from '../standard-scenario-state-select/standard-scenario-state-select.component';
import { FormControl } from '@angular/forms';
import { NgOption, NgSelectComponent } from '@ng-select/ng-select';
import { DictionaryItem } from '@qv-common/entities';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'qv-standard-scenario-state-dropdown',
  templateUrl: './standard-scenario-state-dropdown.component.html',
  styleUrls: [
    '../standard-scenario-state-select/standard-scenario-state-select.component.scss',
    './standard-scenario-state-dropdown.component.scss',
  ]
})
export class StandardScenarioStateDropdownComponent
  extends StandardScenarioStateSelectComponent implements OnInit {

  public control = new FormControl();

  @Input() public resetSelect = new EventEmitter<void>();
  @Input() public openDropDown = new EventEmitter<void>();
  @Input()
  public appendTo: string;

  @Output() public optionSelected = new EventEmitter<DictionaryItem>();

  @ViewChild(NgSelectComponent) private ngSelect: NgSelectComponent;

  public ngOnInit(): void {
    super.ngOnInit();
    this.initOpenDropDownHandler();
    this.initControlValueChangesHandler();
    this.resetSelect.subscribe(() =>
      this.ngSelect.selectedItems.forEach((item: NgOption) => this.ngSelect.unselect(item)));
  }

  private initOpenDropDownHandler(): void {
    this.openDropDown.pipe(untilDestroyed(this)).subscribe(() => this.ngSelect.open());
  }

  private initControlValueChangesHandler(): void {
    this.control.valueChanges.pipe(
      filter((change: DictionaryItem) => Boolean(change)),
      untilDestroyed(this),
    ).subscribe((change: DictionaryItem) => this.optionSelected.next(change));
  }
}
