/* tslint:disable:triple-equals */
import { ActivatedRoute, Params } from '@angular/router';
import { untilDestroyed } from '@ngneat/until-destroy';
import { ApiUrlPrefix } from '@qv-common/enums';
import { BidUtils } from '@qv-bid/utils';
import { constants, resources } from '@qv-common/static';
import { ArrayUtils, CoreUtils } from '@qv-common/utils';
import { CompanyUtils } from '@qv-company/utils';
import { drugTermsConstants } from '@qv-term/constants';
import { TermName } from '@qv-term/enums';
import { filter, switchMap, take, tap } from 'rxjs/operators';
import { DrugListTerms, SummaryTerms } from '../../../utils/terms';

// @ts-ignore
import templateUrl from './revisioncomparison.html';
import { AuthService } from 'quantuvis-angular-common/auth';
import { UserService } from '@qv-common/services/auth/user.service';

const RevisionComparisonComponent = {
  template: templateUrl,
  bindings: {},
  controller: class RevisionComparisonComponent {
    public static $inject = [
      '$filter', '$scope', 'router', 'activatedRoute', '$timeout', '$location', 'util', 'translations', 'restapi',
      'BidDetailsService', 'BaselineWacService', 'bidDetailsNotificationService', 'biddingUtilsService',
      'viewPerspectiveService', '$sce', '$templateRequest', 'spinnerService', 'apiService', 'bidDaoService',
      'bidVersionService', 'summaryDaoService', 'bidInfoService', 'fileService', '$window', 'authService',
      'legalAttestationService', 'permissionService', 'userService'
    ];

    constructor(
      $filter, public $scope, router, private activatedRoute: ActivatedRoute, $timeout, $location, util, translations,
      restapi, BidDetailsService, BaselineWacService, bidDetailsNotificationService, biddingUtilsService,
      viewPerspectiveService, $sce, $templateRequest, spinnerService, apiService, bidDaoService, bidVersionService,
      summaryDaoService, bidInfoService, fileService, $window, authService: AuthService, legalAttestationService,
      permissionService, userService: UserService
    ) {
      let groups;
      const toggles = {};
      const exceptions = [
        'DRUG ADDED', 'SCENARIO CREATED', 'SCENARIO REMOVED', '@NDC', 'DISMISSED', 'RECALLED', 'DELETED'
      ];
      let last = '';

      const processFunctions = {
        Dependency: {
          func: term => {
            let result = '<ul>';
            term.value.split(',').forEach(item => {
              result += `<li>${item}</li>`;
            });

            return `${result}</ul>`;
          },
          noParse: true
        }
      };

      function init(): void {
        $scope.bidVersions = [];

        spinnerService.start(resources.BID_DETAILS.PROCESSING_VERSION_COMPARISON);
        prepareProcessFunctions();

        BidDetailsService.setWorkingScope($scope);
        loadBid().then(() => {
          $scope.loadData();
        });

        permissionService.permissionsReady$.pipe(
          filter((isReady: boolean) => isReady),
          take(1),
        ).subscribe(() => {
          const user = userService.user.getValue();
          $scope.isPayer = biddingUtilsService.isCurrentUserPayer(user);
          $scope.isPharma = biddingUtilsService.isPharma(user);
        });

        viewPerspectiveService.switchPerspectiveEvent.subscribe(() => calculateBidInfo());
      }

      function loadBid(): any {
        return fetchBidVersionsWithLatestHistoricBid(activatedRoute.snapshot.queryParams.bidId).toPromise()
          .then(bid => $scope.bid = bid)
          .then(bid => prepareBidInfo(bid).toPromise());
      }

      $scope.loadData = () => {
        $scope.serviceResponse = {};
        apiService.get(`${ApiUrlPrefix.BIDS}/${$scope.bid.id}/bid-versions/${
          $scope.startVersion}/compare/${$scope.endVersion}`).subscribe((data) => {
          const revisionComparison = data.body.data;

          $scope.revisionComparison = revisionComparison.COMPARISON_KEY;
          $scope.revisionComparisonCompanyNames = revisionComparison.COMPANY_NAME_KEY;
          $scope.revisionComparisonCreationDates = revisionComparison.CREATION_DATES_KEY;

          $scope.revisionComparison.forEach(item => {
            if (item.drugTerm) {
              if (!toggles[getGroupName(item)]) {
                toggles[getGroupName(item)] = false;
              }

              if (item.drugTerm && (item.left && item.left.indexOf('@NDC') != -1)
                || (item.right && item.right.indexOf('@NDC') != -1)) {
                toggles[getGroupName(item)] = true;
              }
            }
          });

          groups = ArrayUtils.groupBy($scope.revisionComparison, (data: any) =>
            `${data.contractedBusiness} ${data.displayName}`
          );

          $scope.revisionComparison.forEach(item => item.shouldKeep = shouldKeep(item));

          $scope.loaded = true;

          if ($scope.bid.isInternal) {
            bidDetailsNotificationService.showViewPerspectiveNotification($scope.bid);
          }

          spinnerService.stop();
          $scope.$broadcast('sizeChanged');
          util.refreshScope($scope);
        }, () => {
          $scope.serviceResponse = {
            error: true,
            message: 'There was an error while retrieving bid revision data. Please try again later.'
          };

          $scope.loaded = true;
          spinnerService.stop();
          util.refreshScope($scope);
        });
      };

      $scope.onChangeViewPerspective = () => {
        $scope.loadData();
      };

      $scope.$on('$locationChangeSuccess', () => {
        const viewAs = $location.search().viewAs;
        if (viewAs && viewPerspectiveService.getViewPerspective() !== viewAs) {
          viewPerspectiveService.setViewPerspective(viewAs);
          $scope.loadData();
          if ($scope.bid.isInternal) {
            bidDetailsNotificationService.showViewPerspectiveNotification($scope.bid);
          }
        }
      });

      init();

      /***************** UTILITIES *********************/
      /**
       * Determines the button class for the current version option
       */
      $scope.getRowSpan = field => {
        let result = 1;
        const groupName = `${field.contractedBusiness} ${field.displayName}`;
        const gr = groups.get(groupName);

        if (gr) {
          result = gr.length;
        }

        return result;
      };

      $scope.showField = field => {
        const groupName = getGroupName(field);
        return field.drugTerm ? true : CoreUtils.isDefined(toggles[groupName]) ? toggles[groupName] : true;
      };

      $scope.toggle = field => {

        const groupName = getGroupName(field);
        toggles[groupName] = !toggles[groupName] ? true : false;
      };

      $scope.getToggleClassFor = field => {
        const groupName = getGroupName(field);
        return toggles[groupName]
        || toggles[groupName] ? 'icon fa fa-chevron-down qv-icon-gray' : 'icon fa fa-chevron-right qv-icon-gray';
      };

      $scope.getClassFor = (field, planType) => {

        if (planType) {
          return `plantype ${field.rowType == 1 ? 'removed' : field.rowType == 2 ? 'added' : ''}`;
        } else {
          return !field.groupData || field.drugTerm ? 'drug' : 'ndc';
        }
      };

      $scope.removeToggleCursor = field => field.drugTerm == true;

      $scope.getValueFor = (field, left) => {
        let value = left ? field.left : field.right;
        const processingFunction = processFunctions[field.field];
        if (processingFunction && processingFunction.func && !CoreUtils.isEmpty(value)
          && exceptions.indexOf(value) == -1) {
          try {
            const local = !processingFunction.noParse ? JSON.parse(value) : value;
            value = processingFunction.func({
              value: local,
              definition: processingFunction.definition
            });
          } catch (e) {
            console.error(field.field, e.message, value);
          }
        }

        if (field.field === 'Dependency' && value !== '') {
          if (value === '@NDC') {
            value = `<div class="bolded marker dependency revision">${value}</div>`;
          } else {
            const dependencyField = left ? field.leftDependency : field.rightDependency;
            if (CoreUtils.isDefined(dependencyField) && !CoreUtils.isNull(dependencyField)) {
              value = `<div class="bolded marker dependency revision">${dependencyField}</div>${value}`;
            }
          }
        }

        return value;
      };

      $scope.isAttachments = field => field.field === 'Attachments';

      $scope.downloadAttachment = field => {
        const url = `${ApiUrlPrefix.BIDS}/summaries/${field.summaryId}/`
          + `bid-attachments/${field.attachmentId}/download`;
        fileService.downloadFile(url).subscribe();
      };

      /**
       * Determines the display text for field for revision comparison table
       */
      $scope.getFieldNameDisplayValue = field => field.field === constants.SUMMARY_TERMS.ATTACHMENTS.label ?
        constants.BIDDING.ATTACHMENTS_LABEL : field.field;

      function prepareProcessFunctions(): any {
        const drugListTerms: any = DrugListTerms($filter, util, translations, restapi, biddingUtilsService,
          viewPerspectiveService, $sce, $templateRequest, userService, BaselineWacService);
        const summaryTerms = SummaryTerms(util, legalAttestationService, translations, $timeout, biddingUtilsService,
          spinnerService, apiService, router, authService, userService);

        [...drugListTerms, ...summaryTerms].forEach(item => {
          if (!item.noProcessing) {
            const noParse = item.name === drugTermsConstants[TermName.BASELINE_WAC].title;
            processFunctions[item.name] = {
              func: item.getTermValueForComparison,
              definition: item,
              noParse
            };
          }
        });
      }

      function shouldKeep(field): any {
        if (field.fullRow) {
          last = '';
          return false;
        }

        const result = last !== field.displayName;
        last = field.displayName;

        return result;
      }

      function getGroupName(field): any {
        return `${field.contractedBusiness} ${field.groupData}`;
      }

      function prepareBidInfo(bid): any {
        return bidVersionService.getLastVersion(bid.id).pipe(
          switchMap((bidVersion: any) => summaryDaoService.getSummaryInfo(bidVersion.id)),
          tap((summary) => {
            $scope.summary = summary;

            calculateBidInfo();
          })
        );
      }

      function fetchBidVersionsWithLatestHistoricBid(bidId): any {
        return bidVersionService.getHistoricVersions(bidId).pipe(
          tap((list: any) => {
            $scope.bidVersions = list.map(({ id }) => id).reverse();
            $scope.startVersionIndex = $scope.bidVersions.indexOf(Number($scope.startVersion)) + 1;
            $scope.endVersionIndex = $scope.bidVersions.indexOf(Number($scope.endVersion)) + 1;
          }),
          switchMap((bidVersions) => bidDaoService.getHistoricBid($scope.currentBidId, bidVersions[0].id))
        );
      }

      function calculateBidInfo(): void {
        const newAssignee = bidInfoService.getCompanyForNewAssignee($scope.bid);
        const manufacturerFullName = CompanyUtils.getCompanyFullName($scope.bid.manufacturer.name,
          $scope.bid.assignee === 'PHARMA', $scope.bid.manufacturerCompanyOldName);
        const newAssigneeFullName = CompanyUtils.getCompanyFullName(newAssignee.name, newAssignee.isPharma(),
          $scope.bid.manufacturerCompanyOldName);

        $scope.bidInfo = {
          rfpTitle: $scope.summary.rfpTitle,
          lineOfBusiness: $scope.summary.lineOfBusiness,
          companyTypeForNewAssignee: newAssignee.type,
          companyNameForNewAssignee: newAssigneeFullName,
          formattedStatus: bidInfoService.getCompleteStatus($scope.bid),
          assignedTo: BidUtils.getAssignedCompanyName($scope.bid.assignee, manufacturerFullName, $scope.bid.payer.name),
        };
      }

    }

    public $onInit(): void {
      this.initRouteParamsChangeHandler();
    }

    public $onDestroy(): void {}

    private initRouteParamsChangeHandler(): void {
      this.activatedRoute.queryParams
        .pipe(
          untilDestroyed(this, '$onDestroy')
        )
        .subscribe((params: Params) => {
          this.setRouteParams(params);
          this.setVersionNumbers();

          if (this.$scope.bid) {
            this.$scope.loadData();
          }
        });
    }

    private setRouteParams(params: Params): void {
      this.$scope.currentBidId = Number(params.bidId);
      this.$scope.startVersion = Number(params.startVersion);
      this.$scope.endVersion = Number(params.endVersion);
    }

    private setVersionNumbers(): void {
      if (this.$scope.bidVersions) {
        this.$scope.startVersionIndex = this.getVersionNumber(this.$scope.startVersion);
        this.$scope.endVersionIndex = this.getVersionNumber(this.$scope.endVersion);
      }
    }

    private getVersionNumber(versionId: number): number {
      return this.$scope.bidVersions.indexOf(versionId) + 1;
    }
  }
};
export default RevisionComparisonComponent;
