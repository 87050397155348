import { Injectable } from '@angular/core';
import { Bid } from '@qv-bid/entities';
import { ViewPerspectiveService } from '@qv-common/services/auth';
import { Company, CompanyType } from 'quantuvis-core-entities';
import { UserService } from '@qv-common/services/auth/user.service';

@Injectable({
  providedIn: 'root'
})
export class BidInfoService {
  constructor(private userService: UserService,
              private viewPerspectiveService: ViewPerspectiveService) { }

  public getCompanyForNewAssignee(bid: Bid): Company {
    const company = new Company();

    if (!bid) {
      return company;
    }

    if ((this.userService.isCurrentUserPayer() && bid.manufacturer)
      || (bid.isInternal && this.viewPerspectiveService.isPayerViewPerspective())) {
      company.type = CompanyType.PHARMA;
      company.name = bid.manufacturer.name;
    } else if ((this.userService.isCurrentUserPharma() && bid.payer)
      || (bid.isInternal && this.viewPerspectiveService.isPharmaViewPerspective())) {
      company.type = CompanyType.PAYER;
      company.name = bid.payer.name;
    }

    return company;
  }

  public getCompleteStatus(bid: Bid, appendBindingSuffix: boolean = true): string {
    const currentUser = this.userService.user.getValue();
    const viewPerspective = this.viewPerspectiveService.getViewPerspective();

    return bid.getCompleteStatus(currentUser.company.id, currentUser.isPayer, viewPerspective, appendBindingSuffix);
  }
}
