/* eslint-disable no-underscore-dangle*/
import { constants } from '@qv-common/static';

export class Units {
  private readonly _dollar: string;
  private readonly _value: string;
  private readonly _priorNoticeRequired: string;
  private readonly _days: string;
  private readonly _months: string;
  private readonly _percent: string;

  constructor() {
    this._dollar = constants.UNITS.DOLLAR;
    this._value = constants.UNITS.VALUE;
    this._priorNoticeRequired = constants.UNITS.PRIOR_NOTICE_REQUIRED;
    this._days = constants.UNITS.DAYS;
    this._months = constants.UNITS.MONTHS;
    this._percent = constants.UNITS.PERCENT;
  }

  get dollar(): string {
    return this._dollar;
  }

  get value(): string {
    return this._value;
  }

  get priorNoticeRequired(): string {
    return this._priorNoticeRequired;
  }

  get days(): string {
    return this._days;
  }

  get months(): string {
    return this._months;
  }

  get percent(): string {
    return this._percent;
  }
}
