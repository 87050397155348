import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { DictionaryItem } from 'quantuvis-angular-common/interfaces';

@Component({
  selector: 'acc-checkbox-list',
  templateUrl: './checkbox-list.component.html',
  styleUrls: ['./checkbox-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CheckboxListComponent implements OnChanges {
  @Input()
  public items: DictionaryItem[] = [];

  @Input()
  public itemsTooltips: Map<number | string, string> = new Map<number, string>();

  @Input()
  public hideAllCheckbox = false;

  @Input()
  public isSelectionDisabled = false;

  @Input()
  public selectedItems: DictionaryItem[] = [];

  @Input()
  public maximumNumberOfItemsToSelect: number = null;

  @Input()
  public maximumNumberOfItemsToSelectTooltip: string = null;

  @Input()
  public itemClass = 'acc-checkbox--secondary';

  @Output()
  public changeEvent = new EventEmitter<DictionaryItem[]>();

  public isAllItemSelected: boolean;
  public isNumberOfSelectedItemsExceedMaximumNumberOfItemsToSelect = false;

  public ngOnChanges(): void {
    this.checkIsAllItemSelected();
  }

  public trackById(index: number, item: DictionaryItem): number {
    return item.id;
  }

  public checkIsAllItemSelected(): void {
    const itemIds: number[] = this.items.map((item: DictionaryItem) => item.id);
    const selectedItemIds: number[] = this.selectedItems.map((item: DictionaryItem) => item.id);

    this.isAllItemSelected = itemIds.every((id: number) => selectedItemIds.includes(id));

    this.checkIsNumberOfSelectedItemsExceedMaximumNumberOfItemsToSelect(selectedItemIds.length);
  }

  private checkIsNumberOfSelectedItemsExceedMaximumNumberOfItemsToSelect(selectedItemsCount: number): void {
    this.isNumberOfSelectedItemsExceedMaximumNumberOfItemsToSelect = !!this.maximumNumberOfItemsToSelect &&
      selectedItemsCount >= this.maximumNumberOfItemsToSelect;
  }

  public isIndeterminateState(): boolean {
    return this.selectedItems.length > 0 && !this.isAllItemSelected;
  }

  public isItemSelected(item: DictionaryItem): boolean {
    return this.selectedItems.some(({ id }: DictionaryItem) => id === item.id);
  }

  public onAllItemsChange(change: MatCheckboxChange): void {
    this.selectedItems = change.checked ? [...this.items] : [];

    this.changeEvent.emit(this.getSortedSelectedItems());
    this.checkIsAllItemSelected();
  }

  public onItemChange(change: MatCheckboxChange, item: DictionaryItem): void {
    this.selectedItems = change.checked
      ? [...this.selectedItems, item]
      : this.selectedItems.filter(({ id }: DictionaryItem) => id !== item.id);

    this.changeEvent.emit(this.getSortedSelectedItems());
    this.checkIsAllItemSelected();
  }

  private getSortedSelectedItems(): DictionaryItem[] {
    return this.selectedItems.sort((item1: DictionaryItem, item2: DictionaryItem) =>
      (item1.id - item2.id)
    );
  }
}
