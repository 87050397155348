import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { SvgIconName } from '@qv-common/enums';
import { Observable } from 'rxjs';

@Injectable()
export class AssetService {
  private iconRegistry: MatIconRegistry;
  private sanitizer: DomSanitizer;

  constructor(iconRegistry: MatIconRegistry, sanitizer: DomSanitizer) {
    this.iconRegistry = iconRegistry;
    this.sanitizer = sanitizer;
  }

  public getNamedSvgIcon(iconName: string): Observable<SVGElement> {
    return this.iconRegistry.getNamedSvgIcon(iconName);
  }

  public registerSvgIcon(svgIconName: string): string {
    if (svgIconName) {
      const iconUrl = this.sanitizer.bypassSecurityTrustResourceUrl(`/assets/images/${svgIconName}.svg`);
      this.iconRegistry.addSvgIcon(svgIconName, iconUrl);
    }

    return svgIconName;
  }

  public registerIcons(): void {
    Object.values(SvgIconName).forEach(name => this.registerSvgIcon(name));
  }
}

export function assetServiceFactory(assetService: AssetService): () => void {
  return () => assetService.registerIcons();
}
