import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class LockVerificationFailedService {

  public showSessionExpiredModalEvent: Observable<void>;

  private showSessionExpiredModalEventSubject = new Subject<void>();

  constructor() {
    this.showSessionExpiredModalEvent = this.showSessionExpiredModalEventSubject.asObservable();
  }

  public showSessionExpiredModal(): void {
    this.showSessionExpiredModalEventSubject.next();
  }
}
