import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { HttpStatusCode } from 'quantuvis-angular-common/api';
import { Observable, of } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CommonMessage } from '@qv-common/enums';
import { NotificationService } from 'quantuvis-angular-common/notification';
import { SnackBarService } from 'quantuvis-angular-common/snack-bar';
import { InternalField } from '@qv-internal-fields-common/entities';
import { InternalFieldsDaoService } from '@qv-internal-fields/services';
import { BidDetailsNotificationService } from '@qv-bid/services';
import { PermissionService } from '@qv-common/services/auth/permission.service';

@UntilDestroy()
@Component({
  selector: 'qv-internal-fields',
  templateUrl: './internal-fields.component.html',
  styleUrls: ['./internal-fields.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InternalFieldsComponent implements OnInit, OnDestroy {
  @Input()
  public bidId: number;

  public isReady = false;
  public internalFields: InternalField[] = [];
  public internalFieldsFormArray: FormArray;

  public readonly commonMessage = CommonMessage;

  constructor(
    private formBuilder: FormBuilder,
    private permissionService: PermissionService,
    private internalFieldsDaoService: InternalFieldsDaoService,
    private bidDetailsNotificationService: BidDetailsNotificationService,
    private snackBarService: SnackBarService,
    private notificationService: NotificationService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {}

  public ngOnInit(): void {
    this.loadInternalFields();
  }

  public ngOnDestroy(): void {
    this.notificationService.clear();
  }

  public trackByFn(index: number): number {
    return index;
  }

  public onChangeInternalField(field: InternalField): void {
    this.snackBarService.start();
    this.internalFieldsDaoService.update(this.bidId, field).pipe(
      tap(() => this.snackBarService.finish()),
      catchError((response: HttpErrorResponse) => this.handleChangeInternalFieldError(response)),
    ).subscribe();
  }

  private handleChangeInternalFieldError(response: HttpErrorResponse): Observable<false | never> {
    const isForbidden = response.status === HttpStatusCode.FORBIDDEN;

    if (isForbidden) {
      this.internalFieldsFormArray.disable();
      this.handleForbiddenError();
    }
    this.snackBarService.error();

    return isForbidden ? of(false) : this.notificationService.showServerError(response);
  }

  private loadInternalFields(): void {
    this.internalFieldsDaoService.getList(this.bidId).pipe(
      tap((fields: InternalField[]) => {
        this.internalFields = fields;
        this.internalFieldsFormArray = this.formBuilder.array(this.prepareInternalFieldFormGroups(fields));
      }),
      catchError((response: HttpErrorResponse) =>
        this.notificationService.showServerError(response)
      ),
      finalize(() => this.showContent()),
      untilDestroyed(this)
    ).subscribe();
  }

  private prepareInternalFieldFormGroups(fields: InternalField[]): FormGroup[] {
    return fields.map((field: InternalField) => this.getInternalFieldFormGroup(field));
  }

  private getInternalFieldFormGroup(field: InternalField): FormGroup {
    return this.formBuilder.group({
      value: [field.value, { updateOn: 'blur', validators: field.getValidators() }]
    });
  }

  private handleForbiddenError(): void {
    this.permissionService.reloadUserPermissions();
    this.bidDetailsNotificationService.showWriteAccessToBidRestrictedNotification();
  }

  private showContent(): void {
    this.isReady = true;
    this.changeDetectorRef.markForCheck();
  }
}
