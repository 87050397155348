import { GlobalConfig } from 'ngx-toastr';
import { ToastType } from '../enums/toast-type.enum';

export const toastrConfig: Partial<GlobalConfig> = {
  disableTimeOut: true,
  closeButton: true,
  enableHtml: true,
  preventDuplicates: true,
  tapToDismiss: false,
  iconClasses: ToastType
};
