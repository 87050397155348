import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('DictionaryItem')
export class DictionaryItem {

  @JsonProperty('id', Number)
  public id: number = null;

  @JsonProperty('displayValue', String)
  public name: string = null;

  @JsonProperty('shortName', String, true)
  public shortName?: string = null;

  constructor(id?: number, name?: string, shortName?: string) {
    this.id = id;
    this.name = name;
    this.shortName = shortName;
  }
}
