import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('DrugAlert')
export class DrugAlert {
  @JsonProperty('id', Number)
  public id: number = null;

  @JsonProperty('name', String)
  public name: string = null;
}
