<ng-container *ngIf="rebate">
  <div class="standard-scenario-section"
    [class.standard-scenario-section--edit-mode]="isEditMode">
    <qv-standard-scenario-section-title
      title="Rebate"
      [tooltip]="resources.TermsDescriptions.DRUG_LIST.GENERAL_REBATE"
    ></qv-standard-scenario-section-title>
    <div class="standard-scenario-section__terms">
      <qv-standard-cell
        class="standard-scenario-section__term"
        data-test-id="test-id-min-base-rebate"
        [name]="drugTermsConstants[termName.MIN_BASE_REBATE].displayNames.MIN_BASE_REBATE"
        [termName]="termName.MIN_BASE_REBATE">
        <qv-term-input
          class="term__side--left"
          [isPending]="isBidInvalid"
          [termName]="termName.MIN_BASE_REBATE"
          [termValue]="rebate.minBaseRebate"
          [isEditMode]="isMinBaseRebateEditable(isEditMode, isUserPharmaOrPharmaPerspective)"
          [control]="getFormControlByTermName(termName.MIN_BASE_REBATE)">
        </qv-term-input>
      </qv-standard-cell>
      <qv-standard-cell
        class="standard-scenario-section__term standard-scenario-section__term--base-rebate"
        data-test-id="test-id-base-rebate"
        [name]="drugTermsConstants[termName.BASE_REBATE].displayNames.BASE_REBATE"
        [termName]="termName.BASE_REBATE"
        [useSeparator]="true">
        <qv-lock
          top-right-block
          data-test-id="test-id-base-rebate-lock"
          [isDisabled]="isBidInvalid"
          [locked]="rebate.baseRebateLock"
          [isEditMode]="isEditMode"
          [control]="getFormControlByTermName(termName.BASE_REBATE_LOCK)">
        </qv-lock>
        <mat-icon
          *ngIf="isBaseRebateRequired(rebate.baseRebate, rebate.baseRebateLock, netEffectivePrice, isUserPharmaOrPharmaPerspective)"
          class="validation-icon validation-icon--error"
          [class.validation-icon--input-prefix]="isBaseRebateEditable(isEditMode, isUserPharmaOrPharmaPerspective, rebate.baseRebateLock)"
          [matTooltip]="resources.BID_DETAILS.INVALID_BASE_REBATE"
          [svgIcon]="svgIconName.WARNING">
        </mat-icon>
        <mat-icon
          *ngIf="isBaseRebateLow(rebate.baseRebate?.value, rebate.minBaseRebate?.value)"
          class="validation-icon validation-icon--arrow"
          [class.validation-icon--input-prefix]="isBaseRebateEditable(isEditMode, isUserPharmaOrPharmaPerspective
          , rebate.baseRebateLock)"
          [svgIcon]="svgIconName.ARROW_BOTTOM">
        </mat-icon>

        <qv-term-input
          class="term__side--left"
          [ngbPopover]="popoverBaseRebateContent"
          [popoverClass]="'popover--only-scaffold popover--width-520 popover--material'"
          [placement]="'bottom'"
          [autoClose]="false"
          [triggers]="'manual'"
          #popoverBaseRebate="ngbPopover"
          [class.term__validation--error]="isBaseRebateLow(rebate.baseRebate?.value, rebate.minBaseRebate?.value)
            || isBaseRebateRequired(rebate.baseRebate, rebate.baseRebateLock, netEffectivePrice, isUserPharmaOrPharmaPerspective)"
          [isPending]="isBidInvalid"
          [termName]="termName.BASE_REBATE"
          [termValue]="rebate.baseRebate"
          [isLocked]="rebate.baseRebateLock"
          [isEditMode]="isEditMode"
          [control]="getFormControlByTermName(termName.BASE_REBATE)">
        </qv-term-input>

        <ng-template #popoverBaseRebateContent>
          <mat-card>
            <mat-card-content>
              {{resources.BID_DETAILS.INVALID_BASE_REBATE_TOOLTIP}}
              <button mat-button class="qv-button qv-button--primary qv-button--h-32 base-rebate__tooltip--button"
                (click)="popoverBaseRebate.close()">{{resources.Actions.OK}}</button>
            </mat-card-content>
          </mat-card>
        </ng-template>

      </qv-standard-cell>
      <div class="standard-scenario-section__term">
        <qv-lock
          class="standard-scenario-section__lock"
          data-test-id="test-id-tier-placement-lock"
          [isDisabled]="isBidInvalid"
          [locked]="rebate.tierPlacementLock"
          [isEditMode]="isEditMode"
          [control]="getFormControlByTermName(termName.TIER_PLACEMENT_LOCK)">
        </qv-lock>
        <qv-standard-cell
          data-test-id="test-id-tier-placement"
          [name]="drugTermsConstants[termName.TIER_PLACEMENT].displayNames.TIER_PLACEMENT"
          [termName]="termName.TIER_PLACEMENT">
          <qv-term-select
            class="term__side--left tier-placement-editor"
            [isPending]="isBidInvalid"
            [appendTo]="dropDownAppendTo"
            [termName]="termName.TIER_PLACEMENT"
            [termValue]="rebate.tierPlacement"
            [isLocked]="rebate.tierPlacementLock"
            [isEditMode]="isEditMode"
            [control]="getFormControlByTermName(termName.TIER_PLACEMENT)">
          </qv-term-select>
        </qv-standard-cell>
      </div>
      <qv-standard-cell class="standard-scenario-section__term"
        [name]="drugTermsConstants[termName.TIER_COMPETITION].displayNames.TIER_COMPETITION"
        [termName]="termName.TIER_COMPETITION">
        <qv-term-select
          class="term__side--left tier-competition-editor"
          data-test-id="test-id-tier-competition"
          [isPending]="isBidInvalid"
          [appendTo]="dropDownAppendTo"
          [termName]="termName.TIER_COMPETITION"
          [termValue]="rebate.tierCompetition"
          [isLocked]="rebate.tierPlacementLock"
          [isEditMode]="isEditMode"
          [control]="getFormControlByTermName(termName.TIER_COMPETITION)">
        </qv-term-select>
      </qv-standard-cell>
      <qv-standard-cell class="standard-scenario-section__term"
        data-test-id="test-id-competition-group"
        [name]="drugTermsConstants[termName.COMPETITION_GROUP].displayNames.COMPETITION_GROUP"
        [termName]="termName.COMPETITION_GROUP">
        <qv-term-select
          class="term__side--left competition-group-editor"
          [isPending]="isBidInvalid"
          [appendTo]="dropDownAppendTo"
          [termName]="termName.COMPETITION_GROUP"
          [termValue]="rebate.competitionGroup"
          [isLocked]="rebate.tierPlacementLock"
          [isEditMode]="isEditMode"
          [control]="getFormControlByTermName(termName.COMPETITION_GROUP)">
        </qv-term-select>
      </qv-standard-cell>
      <qv-standard-cell class="standard-scenario-section__term"
        data-test-id="test-id-competitors-tier"
        [name]="drugTermsConstants[termName.COMPETITORS_TIER].displayNames.COMPETITORS_TIER"
        [termName]="termName.COMPETITORS_TIER"
        [useSeparator]="true">
        <qv-term-select
          class="term__side--left competition-tier-editor"
          [isPending]="isBidInvalid"
          [appendTo]="dropDownAppendTo"
          [termName]="termName.COMPETITORS_TIER"
          [termValue]="rebate.competitorsTier"
          [isLocked]="rebate.tierPlacementLock"
          [isEditMode]="isEditMode"
          [control]="getFormControlByTermName(termName.COMPETITORS_TIER)">
        </qv-term-select>
      </qv-standard-cell>
      <qv-standard-cell class="standard-scenario-section__term"
        [name]="drugTermsConstants[termName.MARKET_BASKET].displayNames.BASKET"
        [termName]="termName.MARKET_BASKET"
        [useSeparator]="true">
        <qv-lock
          top-right-block
          data-test-id="test-id-market-basket-lock"
          [isDisabled]="isBidInvalid"
          [locked]="marketBasketLock"
          [isEditMode]="isMarketBasketEditModeAvailable(isEditMode, drugId)"
          [control]="getMarketBasketFormControl(termName.MARKET_BASKET_LOCK)">
        </qv-lock>
        <qv-term-market-basket
          class="term__basket"
          data-test-id="test-id-market-basket"
          [marketBasket]="marketBasket"
          [scenarioId]="scenarioId"
          [drugName]="drugName"
          [drugClass]="drugClass"
          [isBidInternal]="isBidInternal"
          [termName]="termName.MARKET_BASKET"
          [manufacturerCompanyOldName]="manufacturerCompanyOldName"
          [isEditMode]="isEditModeByStateService(bidStateService.isEditMode | async, isDismissed)"
          [isPending]="isBidInvalid"
          [isLocked]="marketBasketLock"
          [isNdcLevel]="$any(drugId)"
          (marketBasketUpdated)="onMarketBasketUpdated($event)"
        ></qv-term-market-basket>
      </qv-standard-cell>
      <qv-standard-cell class="standard-scenario-section__term"
        [termName]="termName.NOTES"
        [name]="drugTermsConstants[termName.NOTES].displayNames.NOTES">
        <qv-lock
          top-right-block
          data-test-id="test-id-notes-lock"
          [isDisabled]="isBidInvalid"
          [locked]="rebate.notesLock"
          [isEditMode]="isEditMode"
          [control]="getFormControlByTermName(termName.NOTES_LOCK)"
        ></qv-lock>
        <qv-term-notes
          class="term__notes"
          data-test-id="test-id-notes"
          [termName]="termName.NOTES"
          [drugId]="drugId"
          [scenarioId]="scenarioId"
          [bidVersionId]="bidStateService.bidVersionId"
          [cbId]="cbId"
          [bidStatus]="bidStatus"
          [isBidInternal]="isBidInternal"
          [termValue]="rebate.notes"
          [isEditMode]="isEditModeByStateService(bidStateService.isEditMode | async, isDismissed)"
          [isPending]="isBidInvalid"
          [isLocked]="rebate.notesLock || isNdcLocked"
          [manufacturerCompanyOldName]="manufacturerCompanyOldName"
          [manufacturerCompanyId]="manufacturerCompanyId"
          (currentNoteUpdated)="updateCurrentNote($event)"
          (historicNotesDeleted)="deleteHistoricNote()"
        >
        </qv-term-notes>
      </qv-standard-cell>
    </div>
  </div>
</ng-container>
