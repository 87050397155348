/* tslint:disable:no-var-requires */
import InternalPayerPickerController from './internal-payer-picker.controller';

// @ts-ignore
import templateUrl from './internal-payer-picker.html';

const InternalPayerPickerComponent = {
  template: templateUrl,
  bindings: {
    runValidation: '<',
    onValid: '&',
    bidError: '='
  },
  controller: InternalPayerPickerController
};

export default InternalPayerPickerComponent;
