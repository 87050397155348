import { Inject, Injectable } from '@angular/core';
import { JsonConvert } from 'json2typescript';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PharmaRight } from '@qv-bid/entities';
import { HttpResponseWrapper } from '@qv-common/models/api';
import { ApiService } from '@qv-common/services/api';
import { JSON_CONVERT_TOKEN } from 'quantuvis-angular-common/json-converter/services';
import { ApiUrlPrefix } from '@qv-common/enums';

@Injectable()
export class PharmaRightDaoService {

  constructor(private apiService: ApiService, @Inject(JSON_CONVERT_TOKEN) private jsonConvert: JsonConvert) { }

  public getByBidVersion(bidVersionId: number): Observable<PharmaRight[]> {
    return this.apiService.get(`${ApiUrlPrefix.BIDS}/bid-versions/${bidVersionId}/pharma-rights`).pipe(
      map((response: HttpResponseWrapper<PharmaRight>) =>
        this.jsonConvert.deserializeArray(response.body.data, PharmaRight)
      ));
  }

  public updateByBidVersion(bidVersionId: number, pharmaRight: PharmaRight): Observable<PharmaRight> {
    const data = this.jsonConvert.serialize(pharmaRight);

    return this.apiService.put(
      `${ApiUrlPrefix.BIDS}/bid-versions/${bidVersionId}/pharma-rights/${pharmaRight.id}`,
      data
    ).pipe(
      map((response: HttpResponseWrapper<PharmaRight>) =>
        this.jsonConvert.deserializeObject(response.body.data, PharmaRight)
      )
    );
  }
}
