import { ErrorHandler } from '@angular/core';

export class DefaultErrorHandler extends ErrorHandler implements ErrorHandler {
  constructor(protected location: Location) {
    super();
  }

  public handleError(error: any): void {
    this.detectChunkLoadError(error);
    super.handleError(error);
  }

  protected detectChunkLoadError(error: any): void {
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;

    if (error && error.message && chunkFailedMessage.test(error.message)) {
      this.location.reload();
    }
  }
}
