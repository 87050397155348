import { EventEmitter, Injectable } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Drug, Ndc, Scenario } from '@qv-bid/entities';
import { Section, SectionChange, SectionChangeFailed } from '@qv-bid/models';
import { BidLockService, BidStateService } from '@qv-bid/services';
import { NdcManagerService } from '@qv-bid/services/ndc-manager.service';
import { TermSection } from '@qv-term/enums';
import { BehaviorSubject, Subject } from 'rxjs';
import { filter, map, take, tap } from 'rxjs/operators';

@UntilDestroy()
@Injectable()
export class SectionChangeManager {
  public readonly isSectionChanging$ = new BehaviorSubject(false);
  public readonly isSectionChangeFailed$ = new Subject<SectionChangeFailed>();
  public readonly sectionSaveSuccessEvent$ = new EventEmitter<SectionChange>();

  constructor(
    private bidStateService: BidStateService,
    private ndcManagerService: NdcManagerService,
    private bidLockService: BidLockService
  ) {
    this.handleSectionChangeFailed();
  }

  public onScenarioSectionUpdated(scenarioId: number, sectionName: string, section: Section): void {
    this.bidStateService.findScenarioById(scenarioId)
      .pipe(
        take(1),
        filter((scenario: Scenario) => Boolean(scenario)),
        map((scenario: Scenario) => this.patchScenarioWithSection(scenario, sectionName, section)),
        tap((scenario: Scenario) => this.bidStateService.replaceScenario(scenario))
      )
      .subscribe();
  }

  public onNdcSectionUpdated(scenarioId: number, drugId: number, sectionName: string, section: Section): void {
    this.ndcManagerService.findNdcByScenarioId(scenarioId, drugId)
      .pipe(
        take(1),
        filter((ndc: Ndc) => Boolean(ndc)),
        map((ndc: Ndc) => this.patchNdcWithSection(ndc, sectionName, section)),
        tap((ndc: Ndc) => this.ndcManagerService.replaceNdc(scenarioId, ndc))
      )
      .subscribe();
  }

  private patchScenarioWithSection(scenario: Scenario, sectionName: string, section: Section): Scenario {
    let patchedScenario = Object.assign(new Scenario(), scenario);

    if (sectionName === TermSection.MARKET_BASKET) {
      patchedScenario = Object.assign(patchedScenario, section);
    } else {
      const patchedDrug: Drug = Object.assign(new Drug(), scenario.drug);
      patchedDrug[sectionName] = section;
      patchedScenario = Object.assign(patchedScenario, { drug: patchedDrug } as Scenario);
    }

    return patchedScenario;
  }

  private patchNdcWithSection(ndc: Ndc, sectionName: string, section: Section): Ndc {
    const patchedNdc = Object.assign(new Ndc(), ndc);
    patchedNdc[sectionName] = section;

    return patchedNdc;
  }

  private handleSectionChangeFailed(): void {
    this.isSectionChangeFailed$
      .pipe(untilDestroyed(this))
      .subscribe(({ error }: SectionChangeFailed) => this.bidLockService.handleUpdateBidError(error));
  }
}
