import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberMax'
})
export class NumberMaxPipe implements PipeTransform {
  public transform(value: number, max?: number): string {
    const maxNumber = max || 99;
    return value > maxNumber ? `${maxNumber}+` : value.toString();
  }

}
