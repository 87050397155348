import { JsonConvert } from 'json2typescript';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { RemoteState } from '@qv-table/models';
import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { map, tap } from 'rxjs/operators';
import { untilDestroyed } from '@ngneat/until-destroy';
import { Sort } from '@angular/material/sort';
import { PageEvent } from '@angular/material/paginator';
import { defaultPageSize, defaultPageSizeOptions } from '@qv-table/constants';
import { PaginatedResponseWrapper } from '@qv-common/entities';

export abstract class RemoteDataSource<T, F> extends DataSource<T> {
  public pageSizeOptions: number[] = defaultPageSizeOptions;

  public state$ = new BehaviorSubject<RemoteState<F>>(this.defaultState);
  public hasAtLeastTwoPages$ = new BehaviorSubject<boolean>(false);
  public total$ = new BehaviorSubject<number>(0);
  public isEmpty$ = new BehaviorSubject<boolean>(false);
  public data: T[] = [];

  protected tableData$ = new Subject<PaginatedResponseWrapper<T>>();

  constructor(
    protected jsonConvert: JsonConvert,
    protected defaultState: RemoteState<F> = new RemoteState<F>(0, defaultPageSize)
  ) {
    super();
  }

  public connect(): Observable<T[]> {
    this.initStateChangeHandler();

    return this.onConnect();
  }

  public abstract disconnect(collectionViewer: CollectionViewer): void;

  public pageChanged({ pageIndex, pageSize }: PageEvent): void {
    this.updateState({
      page: pageIndex,
      size: pageSize
    });
  }

  public sortChanged(sortOptions: Sort): void {
    const sort = sortOptions.direction ? sortOptions : null;
    this.updateState({ sort });
  }

  public filterChanged(filter: F): void {
    this.updateState({
      filter,
      page: 0
    });
  }

  protected abstract getTableData(state: RemoteState<F>): void;

  private onConnect(): Observable<T[]> {
    return this.tableData$.pipe(
      tap((tableData: PaginatedResponseWrapper<T>) => this.setProperties(tableData)),
      map(({ data }: PaginatedResponseWrapper<T>) => data),
    );
  }

  private setProperties({ totalCount, data }: PaginatedResponseWrapper<T>): void {
    this.total$.next(totalCount);
    this.isEmpty$.next(!data.length);
    this.hasAtLeastTwoPages$.next(totalCount > this.pageSizeOptions[0]);
    this.data = data;
  }

  private initStateChangeHandler(): void {
    this.state$
      .pipe(
        map((state: RemoteState<F>) => this.jsonConvert.serialize(state)),
        untilDestroyed(this, 'disconnect')
      )
      .subscribe((state: RemoteState<F>) => this.getTableData(state));
  }

  private updateState(state: Record<string, unknown>): void {
    const currentState = this.state$.getValue();

    this.state$.next(Object.assign(currentState, state));
  }
}
