import { SeverityLevel } from '@qv-common/enums';
import { ValidationError } from '@qv-common/models';

export class ErrorUtils {
  public static getMaxSeverity(errors: ValidationError[]): SeverityLevel {
    const maxError = errors.reduce((prev, current) => (prev.level > current.level ? prev : current));

    return maxError.level;
  }
}
