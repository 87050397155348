/**
 * Custom AngularJS services used by the APP logic
 */
export const Validations = ['util', function(util) {
  'ngInject';

  /**
   * Method that checks if a password string meets the established password validation rule:  minimum length is 6, has at least
   * 1 lowercase letter, at least one capital letter and at least 1 digit.
   * @returns {boolean}
   */
  const passwordFollowsRule = password => (util.isNotEmpty(password)
    && password.match(/^.{8,}$/)
    && password.match(/^.*[a-z]+.*$/)
    && password.match(/^.*[A-Z]+.*$/) && password
      .match(/^.*[0-9]+.*$/));

  return { passwordFollowsRule };
} ];
