/* tslint:disable:no-invalid-this */
import { ActivatedRoute } from '@angular/router';
import { constants, resources } from '@qv-common/static';
import { TermsAndConditionsEventBusService } from '@qv-terms-and-conditions/services/terms-and-conditions-event-bus.service';

// @ts-ignore
import templateUrl from './quantuvisplus.html';

declare let angular: angular.IAngularStatic;
declare let $: any;

const EnterpriseComponent = {
  template: templateUrl,
  bindings: {},
  controller: class {
    public static $inject = ['$scope', '$window', 'activatedRoute', '$timeout', 'termsAndConditionsEventBusService'];
    constructor(
      $scope,
      $window,
      activatedRoute: ActivatedRoute,
      $timeout,
      termsAndConditionsEventBusService: TermsAndConditionsEventBusService
    ) {
      const minDistanceFromTop = 129;
      const minDistanceFromBottom = 120;
      const fromArchivedBid = !!activatedRoute.snapshot.queryParams.fromArchived;
      let contactTop = minDistanceFromTop;

      $scope.upgradeToEnterprisePhone = constants.COMPANY_CONSTANTS.UPGRADE_PHONE;
      $scope.upgradeEmailSubject = resources.UPGRADE_TO_ENTERPRISE.UPGRADE_EMAIL_SUBJECT;
      $scope.fromArchivedBid = fromArchivedBid;
      $scope.salesEmail = constants.COMPANY_CONSTANTS.SALES_EMAIL;
      $timeout(showContact, 0);
      window.scrollTo(0, 0);

      angular.element($window)
        .bind('resize', () => {
          showContact();
          $scope.$apply();
        }).bind('scroll', function(): void {
        const $contact = $('#contact');
        const contactHeight = $contact.height();
        const pageHeight = $('#enterprisePage').height();
        const innerHeight = $window.innerHeight;
        // @ts-ignore
        const percent = this.pageYOffset / (pageHeight - innerHeight);

        contactTop = $window.Math.round(percent * (innerHeight - contactHeight)) +
          minDistanceFromTop * (1 - percent) - minDistanceFromBottom * percent;

        if ($window.innerHeight - contactTop - minDistanceFromBottom < contactHeight) {
          contactTop = $window.innerHeight - contactHeight - minDistanceFromBottom;
        }
        $contact.css('top', `${contactTop}px`);

        $scope.$apply();
      });

      $scope.showFeedbackModal = subject => termsAndConditionsEventBusService.openContactUsModal$.next(subject);

      function showContact(): void {
        const $contact = $('#contact');
        const pageWidth = $('#enterprisePage').width();
        const tableLength = $('.content > .container').width();
        const spaceLeft = (pageWidth - tableLength) / 2;
        const contactLength = $contact.outerWidth();
        let blockState;
        if (spaceLeft < contactLength + 20) {
          blockState = 'none';
        } else {
          blockState = 'block';
        }
        $contact.css('display', blockState);
      }
    }
  }
};
export default EnterpriseComponent;
