import { DictionaryItem } from '@qv-common/entities';
import { BaseTerm } from './base.term';
import { TermTypes } from '@qv-common/enums';

export class SelectBoxTerm extends BaseTerm {
  public values: DictionaryItem[];

  constructor(name: string,
              label: string,
              description: string,
              path: string,
              value: string,
              defaultValue: DictionaryItem,
              cssClass: string) {
    super(name, label, description, path, value, defaultValue, cssClass);
    this.type = TermTypes.SelectBox;
  }
}
