import { Inject, Injectable } from '@angular/core';
import { HttpResponseWrapper } from '@qv-common/models/api';
import { JsonConvert } from 'json2typescript';
import { JSON_CONVERT_TOKEN } from 'quantuvis-angular-common/json-converter/services';
import { map } from 'rxjs/operators';
import { ApiService } from '@qv-common/services/api';
import { Observable } from 'rxjs';
import { AdministrationFee, PharmaAudit, TerminationClause } from '@qv-bid/entities';
import { ViewPerspectiveService } from '@qv-common/services/auth';
import { HttpUtils } from '@qv-common/utils';
import { ApiUrlPrefix } from '@qv-common/enums';

import { ContractedBusiness } from 'quantuvis-core-entities';

@Injectable()
export class ContractedBusinessDaoService {

  constructor(
    private apiService: ApiService,
    @Inject(JSON_CONVERT_TOKEN) private jsonConvert: JsonConvert,
    private viewPerspectiveService: ViewPerspectiveService
  ) { }

  public getByVersionId(bidVersionId: number): Observable<ContractedBusiness[]> {
    return this.apiService.get(`${ApiUrlPrefix.BIDS}/bid-versions/${bidVersionId}/contracted-businesses`)
      .pipe(map((response: HttpResponseWrapper<ContractedBusiness[]>) =>
        this.jsonConvert.deserializeArray(response.body.data, ContractedBusiness)));
  }

  public getTerminationClause(contractedBusinessId: number): Observable<TerminationClause[]> {
    return this.apiService
      .get(`${ApiUrlPrefix.BIDS}/contracted-businesses/${contractedBusinessId}/termination-clauses`)
      .pipe(map((response: HttpResponseWrapper<TerminationClause[]>) =>
        this.jsonConvert.deserializeArray(response.body.data, TerminationClause)
      ));
  }

  public getPharmaAudit(contractedBusinessId: number): Observable<PharmaAudit[]> {
    return this.apiService
      .get(`${ApiUrlPrefix.BIDS}/contracted-businesses/${contractedBusinessId}/audits`)
      .pipe(map((response: HttpResponseWrapper<PharmaAudit[]>) =>
        this.jsonConvert.deserializeArray(response.body.data, PharmaAudit)
      ));
  }

  public getAdministrationFee(contractedBusinessId: number): Observable<AdministrationFee[]> {
    return this.apiService
      .get(`${ApiUrlPrefix.BIDS}/contracted-businesses/${contractedBusinessId}/fees`)
      .pipe(map((response: HttpResponseWrapper<AdministrationFee[]>) =>
        this.jsonConvert.deserializeArray(response.body.data, AdministrationFee)
      ));
  }

  public create(name: string, bidVersionId: number, isBidInternal: boolean): Observable<ContractedBusiness> {
    let url = `${ApiUrlPrefix.BIDS}/bid-versions/${bidVersionId}/contracted-businesses`;

    const perspective = this.viewPerspectiveService.getViewPerspective();
    url = HttpUtils.appendPerspectiveQueryParamToUrlIfBidIsInternal(url, perspective, isBidInternal);

    return this.apiService
      .post(url, { name, bidVersionId })
      .pipe(map((response: HttpResponseWrapper<ContractedBusiness>) =>
        this.jsonConvert.deserializeObject(response.body.data, ContractedBusiness)
      ));
  }

  public update(cb: ContractedBusiness, bidVersionId: number, isBidInternal: boolean): Observable<ContractedBusiness> {
    let url = `${ApiUrlPrefix.BIDS}/bid-versions/${bidVersionId}/contracted-businesses/${cb.id}`;

    const perspective = this.viewPerspectiveService.getViewPerspective();
    url = HttpUtils.appendPerspectiveQueryParamToUrlIfBidIsInternal(url, perspective, isBidInternal);

    return this.apiService
      .put(url, cb)
      .pipe(map((response: HttpResponseWrapper<ContractedBusiness>) =>
        this.jsonConvert.deserializeObject(response.body.data, ContractedBusiness)
      ));
  }

  public import(
    cbId: number,
    originalCBId: number,
    bidVersionId: number,
    isBidInternal: boolean
  ): Observable<ContractedBusiness> {
    let url = `${ApiUrlPrefix.BIDS}/bid-versions/${bidVersionId}/contracted-businesses/${cbId}/import/${originalCBId}`;

    const perspective = this.viewPerspectiveService.getViewPerspective();
    url = HttpUtils.appendPerspectiveQueryParamToUrlIfBidIsInternal(url, perspective, isBidInternal);

    return this.apiService
      .put(url, null)
      .pipe(map((response: HttpResponseWrapper<ContractedBusiness>) =>
        this.jsonConvert.deserializeObject(response.body.data, ContractedBusiness)
      ));
  }

  public duplicate(cb: ContractedBusiness, bidVersionId: number,
                   isBidInternal: boolean): Observable<ContractedBusiness> {
    let url = `${ApiUrlPrefix.BIDS}/bid-versions/${bidVersionId}/contracted-businesses/${cb.id}/duplicate`;

    const perspective = this.viewPerspectiveService.getViewPerspective();
    url = HttpUtils.appendPerspectiveQueryParamToUrlIfBidIsInternal(url, perspective, isBidInternal);

    return this.apiService
      .post(url, cb)
      .pipe(map((response: HttpResponseWrapper<ContractedBusiness>) =>
        this.jsonConvert.deserializeObject(response.body.data, ContractedBusiness)
      ));
  }

  public delete(cbId: number, bidVersionId: number, isBidInternal: boolean): Observable<null> {
    let url = `${ApiUrlPrefix.BIDS}/bid-versions/${bidVersionId}/contracted-businesses/${cbId}`;

    const perspective = this.viewPerspectiveService.getViewPerspective();
    url = HttpUtils.appendPerspectiveQueryParamToUrlIfBidIsInternal(url, perspective, isBidInternal);

    return this.apiService.delete(url) as Observable<null>;
  }
}
