<section class="qv-modal-dialog qv-modal-dialog--decline-bid" *blockUI="'general-modal'">
  <header mat-dialog-title class="qv-modal-dialog-title">
    <h1 class="qv-modal-dialog-title__text">{{ title }}</h1>
    <button class="qv-modal-dialog-title__xbutton" (click)="onSecondaryAction()">
      <mat-icon>close</mat-icon>
    </button>
  </header>
  <div class="qv-modal-dialog-content qv-modal-dialog-content--font-light">
    <div class="qv-modal-dialog-content__description qv-modal-dialog-content__label-text--required" *ngIf="isPharma">
      Do you want to decline this Bid from {{modalData.companyName}}?
      This action will dismiss all drug scenarios and close the Bid.
      Please explain why (required):
    </div>
    <div class="qv-modal-dialog-content__description" *ngIf="!isPharma">
      Do you want to decline this Bid
      from {{getPharmaFullCompanyName(modalData.companyName, modalData.manufacturerCompanyOldName)}}?
      This action will dismiss all drug scenarios and close the Bid.
      Please use the box below to add any additional information to in the Bid's Comments section (optional).
    </div>
    <textarea class="qv-modal-dialog-content__reason" name="reason" id="reason" rows="4"
              [formControl]="control"
              placeholder="Type here to add a message in the Bid's Comments section."></textarea>
    <acc-form-field-errors
        class="qv-modal-dialog-content__field-errors"
        [control]="control"
        [validationMessages]="validationMessages"
    ></acc-form-field-errors>
  </div>
  <footer mat-dialog-actions class="qv-modal-dialog-actions qv-modal-dialog-actions--padding-sm">
    <button mat-button class="qv-modal-dialog-actions__item" (click)="onSecondaryAction()">
      Cancel
    </button>
    <button mat-button class="qv-modal-dialog-actions__item qv-modal-dialog-actions__item--primary"
            [disabled]="control.invalid"
            (click)="onPrimaryAction()">
      Decline
    </button>
  </footer>
</section>
