<div *ngIf="wac" class="wac">
  <span class="wac__item">
    Version WAC:&nbsp;
    <span [ngClass]="getWacColorClass(wac.versionWAC)" class="wac__value">
      {{ wac.versionWAC }}
    </span>
  </span>
  <span class="wac__item">
    Current WAC:&nbsp;
    <span [ngClass]="getWacColorClass(wac.currentWAC)" class="wac__value">
      {{ wac.currentWAC }}
    </span>
  </span>
  <div *ngIf="!wac.isUnavailable" class="wac__comparison">
    <span [ngClass]="getWacColorClass(wac.deltaWAC, wac.deltaSign)">
      {{ wac.deltaSign }}{{ wac.deltaWAC }}
    </span>
    <span [ngClass]="getWacColorClass(wac.deltaPWAC, wac.deltaSign)">
      ({{ wac.deltaSign }}{{ wac.deltaPWAC }})
    </span>
  </div>
</div>

