import { DictionaryItem } from 'quantuvis-angular-common/interfaces';

export enum LisFlag {
  EMPTY = 99,
  YES,
  NO,
  ALL
}

export namespace LisFlag {
  const valuesMap = new Map<LisFlag, DictionaryItem>([
    [LisFlag.EMPTY, { id: LisFlag.EMPTY, name: '' }],
    [LisFlag.YES, { id: LisFlag.YES, name: 'Yes' }],
    [LisFlag.NO, { id: LisFlag.NO, name: 'No' }],
    [LisFlag.ALL, { id: LisFlag.ALL, name: 'All' }],
  ]);

  export function getValue(lisFlag: LisFlag): DictionaryItem {
    return valuesMap.get(lisFlag);
  }

  export function getValues(): DictionaryItem[] {
    return Array.from(valuesMap.values());
  }
}
