/* eslint-disable no-underscore-dangle */
import { HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Ndc } from '@qv-bid/entities';
import { NotificationService } from 'quantuvis-angular-common/notification';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, map, take, tap } from 'rxjs/operators';
import { BidVersionDaoService } from '@qv-bid/services/dao';

@Injectable()
export class NdcManagerService {
  constructor(private bidVersionDaoService: BidVersionDaoService, private notificationService: NotificationService) { }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  private _ndcs: BehaviorSubject<Map<number, Ndc[]>> = new BehaviorSubject(new Map<number, Ndc[]>());

  public get ndcs(): BehaviorSubject<Map<number, Ndc[]>> {
    return this._ndcs;
  }

  public get flatNdcArray(): Ndc[] {
    const ndcs: Ndc[] = [];

    this._ndcs.getValue().forEach((scenarioNdc: Ndc[]) => ndcs.push(...scenarioNdc));

    return ndcs;
  }

  public loadNdcsByBidVersionIdScenarioId(bidVersionId: number, scenarioId: number, isBidInternal: boolean,
                                          force?: boolean, sortParams?: HttpParams): Observable<any> {
    const ndcs = this.ndcs.getValue().get(scenarioId);

    if (!force && ndcs && ndcs.length) {
      return of(ndcs);
    }

    return this.bidVersionDaoService.getNdcsByScenariosOfVersion(
      bidVersionId, scenarioId, isBidInternal, null, sortParams
    )
      .pipe(
        tap((loadedNdcs: Ndc[]) => this._ndcs.next(this._ndcs.getValue().set(scenarioId, loadedNdcs))),
        catchError((errorResponse: HttpErrorResponse) => {
          this.notificationService.showServerError(errorResponse);

          return throwError(errorResponse);
        })
      );
  }

  public findNdcByScenarioId(scenarioId: number, ndcId: number): Observable<Ndc> {
    return this._ndcs
      .pipe(
        map((ndcsMap: Map<number, Ndc[]>) => ndcsMap.get(scenarioId) || []),
        map((ndcs: Ndc[]) => ndcs.find((ndc: Ndc) => ndc.drugId === ndcId)
        ));
  }

  public replaceNdc(scenarioId: number, ndc: Ndc): void {
    this._ndcs
      .pipe(
        take(1)
      )
      .subscribe((ndcsMap: Map<number, Ndc[]>) => {
        const ndcs = ndcsMap.get(scenarioId) || [];
        const storedNdcIndex = ndcs.findIndex((storedNdc: Ndc) => storedNdc.drugId === ndc.drugId);

        if (storedNdcIndex >= 0) {
          const newNdcsMap = new Map(ndcsMap);
          const newNdcList = [...ndcs];

          newNdcList.splice(storedNdcIndex, 1, ndc);
          newNdcsMap.set(scenarioId, newNdcList);

          this._ndcs.next(newNdcsMap);
        }
      });
  }

  public clearNdcs(): void {
    this._ndcs.next(new Map<number, Ndc[]>());
  }

  public removeFromCache(scenarioId: number): void {
    const ndcsCache = this.ndcs.value;
    ndcsCache.delete(scenarioId);
    this.ndcs.next(ndcsCache);
  }

  public reset(): void {
    this.ndcs.next(new Map<number, Ndc[]>());
  }

  public getNdcIds(scenarioId: number): Observable<number[]> {
    return this._ndcs.pipe(
      map((ndcs: Map<number, Ndc[]>) => (ndcs.get(scenarioId) || []).map((ndc: Ndc) => ndc.drugId)),
    );
  }
}
