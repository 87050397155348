import { BaseTerm } from '@qv-term/models/types';
import { Comparator, KeepOriginalValue, Reset } from '@qv-common/interfaces';
import { CoreUtils } from '@qv-common/utils';
import { constants, resources } from '@qv-common/static';

export class InstructionTerm extends BaseTerm implements Reset, KeepOriginalValue, Comparator {
  public allowKeepOriginValue: boolean;
  public processKeepOriginalValue: boolean;
  public keepOriginalValue: boolean;

  constructor(name: string) {
    super(
      name,
      name,
      resources.TermsDescriptions.SUMMARY.INSTRUCTIONS,
      constants.SUMMARY_TERMS.INSTRUCTIONS.label,
      {
        text: ''
      },
      {
        text: ''
      },
      'term__instructions'
    );
    this.keepOriginalValue = this.allowKeepOriginValue;
    this.processKeepOriginalValue = false;
  }

  public getValueToSave(): Record<string, unknown> {
    // TODO need add KeepOriginal logic
    return {
      text: this.value.text
    };
  }

  public resetValue(): void {
    // TODO need add KeepOriginal logic
    this.value = this.defaultValue;
  }

  public getTermValueForComparison(): string {
    return (CoreUtils.isDefined(this.value) || CoreUtils.isNotDefined(this.value.text)) ? '' : this.value.text.trim();
  }
}
