import { DictionaryItem } from 'quantuvis-angular-common/interfaces';

export enum WholesaleAcquisitionPrice {
  EMPTY = 117,
  DAILY_WAC,
  DAILY_WEIGHTED_WAC_MONTH,
  DAILY_WEIGHTED_WAC_QUARTER,
  WAC_EFFECT_MOST_DAYS_MONTHLY,
  WAC_EFFECT_MOST_DAYS_QUARTER,
  FIRST_DAY_MONTH,
  N_15TH_DAY_MONTH,
  LAST_DAY_MONTH,
  FIRST_DAY_QUARTER,
  N_45TH_DAY_QUARTER,
  LAST_DAY_QUARTER,
  DAILY_MONTHLY_AVERAGE,
  DAILY_QUARTERLY_AVERAGE,
}

export namespace WholesaleAcquisitionPrice {
  const valuesMap = new Map<WholesaleAcquisitionPrice, DictionaryItem>([
    [WholesaleAcquisitionPrice.EMPTY, { id: WholesaleAcquisitionPrice.EMPTY, name: '' }],
    [WholesaleAcquisitionPrice.DAILY_WAC, { id: WholesaleAcquisitionPrice.DAILY_WAC, name: 'Daily WAC' }],
    [
      WholesaleAcquisitionPrice.DAILY_WEIGHTED_WAC_MONTH,
      { id: WholesaleAcquisitionPrice.DAILY_WEIGHTED_WAC_MONTH, name: 'Daily Weighted WAC by Month' }
    ],
    [
      WholesaleAcquisitionPrice.DAILY_WEIGHTED_WAC_QUARTER,
      { id: WholesaleAcquisitionPrice.DAILY_WEIGHTED_WAC_QUARTER, name: 'Daily Weighted WAC by Quarter' }
    ],
    [
      WholesaleAcquisitionPrice.WAC_EFFECT_MOST_DAYS_MONTHLY,
      { id: WholesaleAcquisitionPrice.WAC_EFFECT_MOST_DAYS_MONTHLY, name: 'WAC in Effect Most Days of Monthly' }
    ],
    [
      WholesaleAcquisitionPrice.WAC_EFFECT_MOST_DAYS_QUARTER,
      { id: WholesaleAcquisitionPrice.WAC_EFFECT_MOST_DAYS_QUARTER, name: 'WAC in Effect Most Days of Quarter' }
    ],
    [
      WholesaleAcquisitionPrice.FIRST_DAY_MONTH,
      { id: WholesaleAcquisitionPrice.FIRST_DAY_MONTH, name: '1st Day of Month' }
    ],
    [
      WholesaleAcquisitionPrice.N_15TH_DAY_MONTH,
      { id: WholesaleAcquisitionPrice.N_15TH_DAY_MONTH, name: '15th Day of Month' }
    ],
    [
      WholesaleAcquisitionPrice.LAST_DAY_MONTH,
      { id: WholesaleAcquisitionPrice.LAST_DAY_MONTH, name: 'Last Day of Month' }
    ],
    [
      WholesaleAcquisitionPrice.FIRST_DAY_QUARTER,
      { id: WholesaleAcquisitionPrice.FIRST_DAY_QUARTER, name: '1st Day of Quarter' }
    ],
    [
      WholesaleAcquisitionPrice.N_45TH_DAY_QUARTER,
      { id: WholesaleAcquisitionPrice.N_45TH_DAY_QUARTER, name: '45th Day of Quarter' }
    ],
    [
      WholesaleAcquisitionPrice.LAST_DAY_QUARTER,
      { id: WholesaleAcquisitionPrice.LAST_DAY_QUARTER, name: 'Last Day of Quarter' }
    ],
    [
      WholesaleAcquisitionPrice.DAILY_MONTHLY_AVERAGE,
      { id: WholesaleAcquisitionPrice.DAILY_MONTHLY_AVERAGE, name: 'Daily Monthly Average' }
    ],
    [
      WholesaleAcquisitionPrice.DAILY_QUARTERLY_AVERAGE,
      { id: WholesaleAcquisitionPrice.DAILY_QUARTERLY_AVERAGE, name: 'Daily Quarterly Average' }
    ],
  ]);

  export function getValue(price: WholesaleAcquisitionPrice): DictionaryItem {
    return valuesMap.get(price);
  }

  export function getValues(): DictionaryItem[] {
    return Array.from(valuesMap.values());
  }
}

