import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BidSendModalData } from './bid-send-modal-data';
import { BidSendModalComponent } from '@qv-bid/components/shared/bid-send-modal/bid-send-modal.component';
import { ModalConfig, ModalSize } from 'quantuvis-angular-common/modal';

export class BidSendModalConfig extends ModalConfig<BidSendModalComponent, BidSendModalData> {

  constructor(modalData: BidSendModalData) {
    super(modalData, ModalSize.X_SMALL);
  }

  protected createDialogRef(dialog: MatDialog): MatDialogRef<BidSendModalComponent> {
    return dialog.open(BidSendModalComponent, this);
  }
}
