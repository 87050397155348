<div #mainMenuTrigger="matMenuTrigger" [matMenuTriggerFor]="mainMenu" class="menu-wrapper">
  <span [ngClass]="{'menu-text--selected': mainMenuTrigger?.menuOpen}" class="menu-text">MENU</span>
  <mat-icon [ngClass]="{'menu-icon--selected': mainMenuTrigger?.menuOpen}"
            [svgIcon]="mainMenuTrigger?.menuOpen ? svgIconName?.CHEVRON_TOP : svgIconName?.CHEVRON_BOTTOM"
            class="menu-icon"></mat-icon>
</div>

<mat-menu #mainMenu="matMenu" [class]="'qv-menu-line'" [overlapTrigger]="false">
  <qv-menu-item-button [tooltip]="tooltips.DASHBOARD" routerLink="/dashboard"
                       routerLinkActive="horizontal-menu__item--active" text="Dashboard"></qv-menu-item-button>
  <qv-menu-item-button *ngIf="isAllowed('bids')" [tooltip]="tooltips.BIDS" routerLink="/bids"
                       text="Bids"></qv-menu-item-button>
  <qv-menu-item-button *ngIf="isAllowed('quantuvis_administrator')" [triggerFor]="$any(adminMenu)"
                       text="Administrator"></qv-menu-item-button>
  <qv-menu-item-button *ngIf="isAllowed('market_basket')" [tooltip]="tooltips.MARKET_BASKETS"
                       routerLink="/market-basket-templates" text="Market Baskets"></qv-menu-item-button>
  <qv-menu-item-button (menuOpened)="loadDashboards()" *ngIf="isInsightsSupported()"
                       [triggerFor]="$any(insightsMenu)" text="iQ"></qv-menu-item-button>
  <qv-menu-item-button *ngIf="isAllowed('people')" [tooltip]="tooltips.MANAGEMENT"
                       [triggerFor]="$any(managementMenu)" text="Management"></qv-menu-item-button>
  <qv-menu-item-button (click)="openDrugSearch()" [tooltip]="tooltips.DRUG_SEARCH"
                       text="Drug Search"></qv-menu-item-button>
  <qv-menu-item-button (click)="openHelpPage()" [tooltip]="tooltips.HELP"
                       text="Help"></qv-menu-item-button>
</mat-menu>

<mat-menu #adminMenu="matMenu" [overlapTrigger]="false">
  <button mat-menu-item routerLink="/administrator">Companies</button>
  <button mat-menu-item routerLink="/manufacturerMapping">Drug Subsidiary</button>
  <button mat-menu-item routerLink="/drugExceptions">Drug Exceptions</button>
  <button mat-menu-item routerLink="/productEnrollment">Product Enrollment</button>
</mat-menu>

<mat-menu #managementMenu="matMenu" [overlapTrigger]="false" class="qv-menu">
  <button mat-menu-item routerLink="/people">People</button>
  <button *ngIf="isAllowed('new_user')" mat-menu-item
          routerLink="/rights">Rights
  </button>
  <button *ngIf="isLoiSupported()" mat-menu-item routerLink="/loi/letterOfIntent">Letter of Intent</button>
  <button *ngIf="isLegalAttestationSupported()" mat-menu-item
          routerLink="/legalAttestation">Legal Attestation
  </button>
</mat-menu>

<mat-menu #insightsMenu="matMenu" [overlapTrigger]="false">
  <button
      [disabled]="isDashboardsDisabled((standardDashboards$ | async)?.length, isStandardDashboardsLoading)"
      [matMenuTriggerFor]="standardDashboardMenu"
      [ngClass]="getDynamicMenuItemClass(isStandardDashboardsLoading)"
      mat-menu-item>
    Standard dashboards
  </button>
  <button
      [disabled]="isDashboardsDisabled((customDashboards$ | async)?.length, isCustomDashboardsLoading)"
      [matMenuTriggerFor]="customDashboardMenu"
      [ngClass]="getDynamicMenuItemClass(isCustomDashboardsLoading)"
      mat-menu-item>
    Custom dashboards
  </button>
</mat-menu>

<mat-menu #standardDashboardMenu="matMenu" [class]="'dashboards-menu'" [overlapTrigger]="false">
  <button
      (click)="redirectToInsightsDashboard(dashboard.id)"
      *ngFor="let dashboard of standardDashboards$ | async"
      mat-menu-item>{{ dashboard.title }}</button>
</mat-menu>

<mat-menu #customDashboardMenu="matMenu" [overlapTrigger]="false">
  <button
      (click)="redirectToInsightsDashboard(dashboard.id)"
      *ngFor="let dashboard of customDashboards$ | async"
      mat-menu-item>{{ dashboard.title }}</button>
</mat-menu>
