<mat-icon class="multi-select__icon"
  [class.multi-select__icon--active]="(selectedItems | async)?.length"
  [class.multi-select__icon--disabled]="filterControl.disabled"
  (click)="ngSelect.open()"
  [svgIcon]="svgIconName.FILTER"></mat-icon>
<ng-select class="qv-select multi-select__select"
  dropdownPosition="bottom"
  [items]="multiSelectItems"
  [multiple]="multiple"
  [formControl]="filterControl"
  [placeholder]="placeholder"
  [loading]="isLoading"
  (open)="onOpen()"
  (close)="onApplyChanges()"
  (clear)="onClear()"
  [closeOnSelect]="closeOnSelect">

  <!--  Dropdown options template-->
  <ng-template ng-option-tmp let-item="item">
    <mat-checkbox class="multi-select__checkbox"
      [ngModel]="(selectedItems | async)?.includes(item)">{{item}}</mat-checkbox>
  </ng-template>

  <!--  Select labels template-->
  <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
    <div class="multi-select-value" *ngFor="let item of (items ? items.slice(0, visibleItemsNumber): [])">
      <span class="multi-select-value__label">{{item}}</span>
      <button
        class="multi-select-value__icon"
        [disabled]="disabled"
        (click)="clear(item)"
        aria-hidden="true"
      >×</button>
    </div>
    <div class="multi-select-value" *ngIf="items.length > visibleItemsNumber">
      <span class="multi-select-value__label">
        {{items | otherChips: visibleItemsNumber}}
      </span>
    </div>
  </ng-template>

  <!--  Select footer template-->
  <ng-template ng-footer-tmp>
    <div class="multi-select-footer">
      <div>Selected drugs count: <b>{{(selectedItems | async)?.length}}</b></div>
      <button mat-button
        class="qv-button qv-button--primary qv-button--h-32"
        (click)="ngSelect.close()">
        Apply
      </button>
    </div>
  </ng-template>

</ng-select>
