import { BaseTerm } from '@qv-term/models/types';
import { Comparator, Validate } from '@qv-common/interfaces';
import { CoreUtils } from '@qv-common/utils';
import { constants, resources } from '@qv-common/static';

export class CommentsTerm extends BaseTerm implements Validate, Comparator {
  public allowKeepOriginValue: boolean;
  public processKeepOriginalValue: boolean;
  public keepOriginalValue: boolean;

  constructor(name: string) {
    super(
      name,
      name,
      resources.TermsDescriptions.SUMMARY.COMMENTS,
      constants.SUMMARY_TERMS.COMMENTS.label,
      {},
      {},
      'term__comments'
    );
    this.keepOriginalValue = this.allowKeepOriginValue;
    this.processKeepOriginalValue = false;
  }

  public getValueToSave(): Record<string, unknown> {
    const termCopy = { value: CoreUtils.copyDeep(this.value) };
    // Delete 'companyDisplayName' before saving to db
    termCopy.value.historic = this.removeDisplayCompanyName(termCopy.value);

    return termCopy.value;
  }

  public validate(): boolean {
    throw new Error('Method not implemented.');
  }

  public getTermValueForComparison(): string {
    throw new Error('Method not implemented.');
  }

  // TODO make it using Symbol or Pipe in NEW-UI
  private removeDisplayCompanyName(value: any): Array<any> {
    const values = [];
    if (CoreUtils.isDefined(value) && CoreUtils.isDefined(value.historic)) {
      value.historic.forEach(val => {
        delete val.companyDisplayName;
        values.push(val);
      });
    }

    return values;
  }
}
