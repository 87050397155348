<div [class.error-number]="!scenarioNameFormService.isScenarioNumberCorrect(scenario.id)" class="scenario-title">

  <div (click)="scenarioNameFormService.selectSectionName(bidStateService.isEditMode.getValue(), scenario)"
       *ngIf="!isEditableScenarioNumber(bidStateService.isEditMode | async, scenarioNameFormService.editModeForNumber$ |async)"
       class="scenario-title__name">
    {{ scenario.name }}
  </div>

  <div *ngIf="scenarioNameFormService.editModeForNumber$ | async"
       class="scenario-title__input-field">
    <span>S</span>
    <input #nameInput
           (blur)="scenarioNameFormService.handleBlur()"
           (focus)="scenarioNameFormService.handleFocus()"
           [formControl]="scenarioNameFormService.getFormControl(scenario)"
           type="text">
  </div>

  <div *ngIf="scenarioNameFormService.isScenarioNumberIncorrect(scenario.id)"
       [ngClass]="getErrorMessagePositionClass(errorPosition)"
       class="scenario-title__error-message">
    Invalid Scenario number format
  </div>
  <div *ngIf="scenarioNameFormService.isScenarioNumberExist(scenario.id)"
       [ngClass]="getErrorMessagePositionClass(errorPosition)"
       class="scenario-title__error-message">
    This scenario number already exists, please choose a different one
  </div>
</div>
