import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'qv-scenario-dependency',
  templateUrl: './scenario-dependency.component.html',
  styleUrls: ['./scenario-dependency.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ScenarioDependencyComponent {
  @Input() public dependencyIndex: number;
}
