import { SelectBoxTerm } from '@qv-term/models/types';
import { constants, resources } from '@qv-common/static';
import { PharmaAuditFrequency } from '@qv-term/enums/options';

export class PharmaAuditFrequencyTerm extends SelectBoxTerm {

  constructor(name: string) {
    super(
      name,
      name,
      resources.TermsDescriptions.CONTRACT.PHARMA_AUDIT_TERMS_AUDIT_FREQUENCY,
      constants.CONTRACT_TERMS.PHARMA_AUDIT_TERMS_AUDIT_FREQUENCY.label,
      '',
      PharmaAuditFrequency.getValue(PharmaAuditFrequency.EMPTY),
      'term__pharma-audit-frequency'
    );
    this.values = PharmaAuditFrequency.getValues();
  }
}
