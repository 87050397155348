import { ActivatedRoute } from '@angular/router';
import { ArrayUtils, CoreUtils } from '@qv-common/utils';
import { ApiUrlPrefix } from '@qv-common/enums';
import { catchError, tap } from 'rxjs/operators';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { of } from 'rxjs';
// @ts-ignore
import template from '../../views/components/myaccounts.html';

export function MyAccounts(): any {
  return {
    restrict: 'A',
    template,
    scope: false,

    controller: ['$scope', 'activatedRoute', '$filter', 'apiService',
      ($scope, activatedRoute: ActivatedRoute, $filter, apiService) => {
      // Holds list of all Companies available for myAccounts ([{name: "name", "companyId"}]
      let allCompaniesList;

      function importCompanies(companies): any {
        allCompaniesList = getFullCompanyList(companies);
        $scope.myAccountsCompanies = getMyAccountsCompanies();
        updateRemainingCompanies();

        // Check email preferences when my accounts list changes
        $scope.checkEmailPreferences();
      }

      // Get all companies of the other type (eg: get pharmas for payers; get payers for pharmas)
      // Don't get companies for Quantuvis Admin
      // or when companies were already retrieved (in profile settings page)
      // Pharma populates $scope.companies with results from companies/othertype/false (companies with "NONE"
      // access are not filtered out) so we still need the filtered list
      if ($scope.user && (!$scope.user.isPayer || CoreUtils.isNotDefined($scope.companies))) {
        const userId = $scope.currentUserId || $scope.user.id || activatedRoute.snapshot.queryParams.userId;
        const companiesEndpoint = `${ApiUrlPrefix.OLD}/companies/othertype/true/${userId}`;
        apiService.get(companiesEndpoint).pipe(
          tap((response: HttpResponse<any>) => {
            const companies = response.body.responseObject;
            importCompanies(companies);
          }),
          catchError((error: HttpErrorResponse) => {
            console.error(`Error fetching ${companiesEndpoint}`, error);

            return of(null);
          })
        ).subscribe();
      } else {
        importCompanies($scope.companies);
      }

      /**
       * Get initial company list from companiesList
       *
       * @returns {Array} list of Companies added to myAccounts [{name: "name", displayName: "displayName",
       * companyId: "id"}]
       */
      function getMyAccountsCompanies(): any {
        const myAccountsCompanies = [];
        if ($scope.myAccountsIds) {
          $scope.myAccountsIds.forEach(companyId => {
            myAccountsCompanies.push(allCompaniesList.find(item => item.companyId === companyId));
          });
        }

        return myAccountsCompanies;
      }

      /**
       * Get list of companies to be displayed in popup
       */
      function updateRemainingCompanies(): any {
        // Get array of "names" of companies not added already
        $scope.companyListRemaining = allCompaniesList
          .filter(company => !$scope.myAccountsCompanies.includes(company))
          .map(val => val.name);
      }

      /**
       * Add the popup selection to the current list of myAccountsCompanies
       */
      function updateMyAccountsCompanies(): any {
        const companiesToAdd =
          $filter('filtermultiple')(allCompaniesList, $scope.myAccountsCriteria, ['name'], $scope.companyListRemaining);

        $scope.myAccountsCompanies = ArrayUtils.union($scope.myAccountsCompanies, companiesToAdd);
        $scope.user.myAccounts = getMyAccountsToSave();
        updateRemainingCompanies();

        // Check email preferences when my accounts list changes
        $scope.checkEmailPreferences();
      }

      /**
       * Hold needed info (name, companyId) for full list of companies
       * @param companies - full company list object
       * @returns {Array} [{name:"name", companyId:"id"}]
       */
      function getFullCompanyList(companies = []): any {
        const companyList = [];
        companies.forEach(company => {
          companyList.push({
            name: company.name,
            displayName: company.displayName,
            companyId: company.id
          });
        });
        return companyList;
      }

      /**
       * Get array of company Ids to save to user profile
       *
       * @returns {Array} list of company Ids
       */
      function getMyAccountsToSave(): any {
        return $scope.myAccountsCompanies.map(item => item.companyId);
      }

      /**
       * Handler for "apply/add" button from the selection popup
       * @param key
       * @returns {Function} Callback
       */
      $scope.addToMyAccounts = key => selection => {
        // Add to my accounts only if selection happened;
        if (selection) {
          const filter = {};
          filter[key] = selection;
          $scope.myAccountsCriteria = filter;
          updateMyAccountsCompanies();
        }
      };

      /**
       * Remove company from myAccounts
       * @param name - property to search in array
       */
      $scope.removeCompany = name => {
        const indexToRemove = $scope.myAccountsCompanies.findIndex(item => item.name === name);
        $scope.myAccountsCompanies.splice(indexToRemove, 1);
        $scope.user.myAccounts = getMyAccountsToSave();
        updateRemainingCompanies();

        // Check email preferences when my accounts list changes
        $scope.checkEmailPreferences();
      };
    }]
  };
}
