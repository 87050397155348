export enum FilterOptionName {
  DRUG_NAME = 'DRUG_NAME',
  DRUG_STATUS = 'DRUG_STATUS',
  CONTRACTED_BUSINESS = 'CONTRACTED_BUSINESS',

  // Rebate
  TIER_COMPETITION = 'TIER_COMPETITION',
  TIER_COMPETITOR = 'TIER_COMPETITOR',
  TIER_PLACEMENT = 'TIER_PLACEMENT',
  COMPETITION_GROUP = 'COMPETITION_GROUP',

  // UM
  ALLOWED_STEP_THERAPY = 'ALLOWED_STEP_THERAPY',
  REQUIRED_STEP_THERAPY = 'REQUIRED_STEP_THERAPY',
  ALLOWED_PRIOR_AUTHORIZATION = 'ALLOWED_PRIOR_AUTHORIZATION',
  REQUIRED_PRIOR_AUTHORIZATION = 'REQUIRED_PRIOR_AUTHORIZATION',
  OTHER_UM = 'OTHER_UM',
  QUANTITY_LIMIT = 'QUANTITY_LIMIT',

  // Price Protection
  RESET = 'RESET',
  PRICE_EFFECTIVE_DATE = 'PRICE_EFFECTIVE_DATE',
  INDEX = 'INDEX',

  // Market Share
  MARKET_SHARE_TIER = 'MARKET_SHARE_TIER',
}
