import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { CustomOAuthStorage } from './models/custom-oauth-storage';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { AuthService } from './services/auth.service';
import { OAuthModule, OAuthStorage } from 'angular-oauth2-oidc';
import { NavigationModule } from 'quantuvis-angular-common/navigation';

@NgModule({
  imports: [
    OAuthModule.forRoot(),
    NavigationModule,
  ],
  providers: [
    AuthService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: OAuthStorage, useClass: CustomOAuthStorage }
  ]
})
export class AuthCommonModule {}
