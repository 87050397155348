/* tslint:disable:no-invalid-this */
declare let $: any;

export const ScrollFix = ['$document', '$window', function($document, $window): any {
  'ngInject';
  return {
    restrict: 'A',
    compile: compileFunction
  };

  function compileFunction(tElem, tAttr): void {
    let onElement, scrollToElem, originalTop, offset, isTable, firstElem, theads;
    let detached = false;
    const onElementReady = selector => (
      new Promise((resolve) => {
        const observer = new MutationObserver(check);
        observer.observe($document[0].documentElement, {
          childList: true,
          subtree: true
        });
        check();

        function check(): void {
          const element = $document[0].querySelector(selector);
          if (element) {
            resolve(element);
            observer.disconnect();
          }
        }
      })
    );
    if (tAttr.element) {
      onElementReady(tAttr.element).then(el => {
        init($(el));
        scrollToElem.scroll(recalculateTableHeader);
      });
    } else {
      init();
      if (scrollToElem) {
        scrollToElem.scroll(recalculateTableHeader);
      }
    }

    function detachTableHead(): void {
      firstElem = $(tElem.parent().find('tbody tr')[0]);
      tElem.css('width', firstElem.width());
      if (firstElem) {
        // @ts-ignore
        function resizeThead(element): void {
          element.find('td').each(function(index): any {
            $(theads[index]).css('width', $(this).outerWidth());
          });
        }

        resizeThead(firstElem);
        // @ts-ignore
        function doResize(): void {
          tElem.css('width', firstElem.width());
          if (!firstElem.is(':visible')) {
            $($window[0]).off('measureTable', doResize);
          } else {
            resizeThead(firstElem);
          }
        }

        $($window[0]).on('measureTable', doResize);
      }
    }

    function init($element?): void {
      onElement = !!$element;
      scrollToElem = onElement ? $element : $($window[0]);
      detached = false;
      offset = tAttr.offset ? parseInt(tAttr.offset) : 0;
      const header = $('.site-header');
      if (!onElement && tAttr.windowOffset && header && header.is(':visible')) {
        offset = parseInt(tAttr.windowOffset);
      }
      isTable = tElem.prop('tagName').toLowerCase() === 'thead';
      $(window).on('resize', resizeHandler);
    }

    function resizeHandler(): void {
      recalculateTableHeader();
    }

    function recalculateTableHeader(): void {
      if (tElem.is(':visible')) {
        if (typeof originalTop === 'undefined') {
          originalTop = tElem.position().top;
        }
        if (scrollToElem.scrollTop() > originalTop - offset) {
          if (!detached) {
            detached = true;
          }
        } else if (detached) {
          tElem.attr('style', '');
          tElem.parent().attr('style', '');
          detached = false;
          originalTop = undefined;
        }

        if (detached) {
          tElem.css('position', 'absolute');
          if (isTable) {
            theads = tElem.find('th');
            detachTableHead();
          }
          tElem.parent().css('marginTop', tElem.height());
          tElem.css('top', scrollToElem.scrollTop() + offset);
        }
      }
    }
  }
}];
