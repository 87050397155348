import { Inject, Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { JsonConvert } from 'json2typescript';
import { ApiService } from '@qv-common/services/api';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiUrlPrefix } from '@qv-common/enums';
import { HttpResponseWrapper } from '@qv-common/models/api';
import { CompanyBase, CompanyType } from 'quantuvis-core-entities';
import { JSON_CONVERT_TOKEN } from 'quantuvis-angular-common/json-converter/services';

@Injectable()
export class CompanyDaoService {
  private readonly resourceUrl = `${ApiUrlPrefix.SYSTEM_ADMINISTRATION}/companies`;

  constructor(private apiService: ApiService, @Inject(JSON_CONVERT_TOKEN) private jsonConvert: JsonConvert) {}

  public getList(type?: CompanyType): Observable<CompanyBase[]> {
    let params = new HttpParams().set('sort', 'name');

    if (type) {
      params = params.set('type', type);
    }

    return this.apiService.get(this.resourceUrl, { params }).pipe(
      map((response: HttpResponseWrapper<CompanyBase[]>) =>
        this.jsonConvert.deserializeArray(response.body.data, CompanyBase)
      )
    );
  }
}
