export enum HttpHeader {
  X_CLIENT_VERSION = 'X-Client-Version',
  X_SECURITY_TOKEN = 'X-Security-Token',
  X_CLIENT_TOKEN = 'X-Client-Token',
  CACHE_CONTROL = 'Cache-Control',
  PRAGMA = 'Pragma',
  EXPIRES = 'Expires',
  CONTENT_TYPE = 'Content-Type',
  AUTHORIZATION = 'Authorization'
}
