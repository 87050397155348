import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'qv-drugs-menu',
  templateUrl: './drugs-menu.component.html',
  styleUrls: ['./drugs-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DrugsMenuComponent {
  @Input()
  public cbId: number;
  @Input()
  public bidVersionId: number;
  @Input()
  public pharmaId: number;
  @Input()
  public isBidInternal: boolean;
  @Input()
  public isReviewMode: boolean;
}
