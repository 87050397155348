<div class="qv-page-toolbar bid-toolbar">
  <div>
    <qv-bid-breadcrumbs>
      <span [matTooltip]="bid?.title" class="bid-title">{{bid?.title}}</span>
    </qv-bid-breadcrumbs>
  </div>
  <div class="right-block">
    <qv-bid-view-switcher
        *ngIf="isAvailableBidViewSwitcher()"
    ></qv-bid-view-switcher>
    <qv-role-switcher
        *ngIf="bidUtils.isAvailableRoleSwitcher(bid)"
        [bid]="bid"
    ></qv-role-switcher>

    <div *ngIf="isBidLoaded" class="main-actions">
      <ng-container *ngIf="hasUserWriteAccessToBid">
        <qv-action-button (buttonClick)="onActionButtonClick(bidToolbarAction.UNDO_BID)"
                          *ngIf="isUndoRedoButtonAvailable"
                          [disabled]="!isUndoButtonActive"
                          [svgIconName]="svgIconName.UNDO"
                          [tooltip]="tooltips.BID_ACTIONS.UNDO"
                          cssClass="toolbar-button toolbar-button--primary"
                          data-test-id="test-id-bid-toolbar-button-undo">
        </qv-action-button>
        <qv-action-button (buttonClick)="onActionButtonClick(bidToolbarAction.REDO_BID)"
                          *ngIf="isUndoRedoButtonAvailable"
                          [disabled]="!isRedoButtonActive"
                          [svgIconName]="svgIconName.REDO"
                          [tooltip]="tooltips.BID_ACTIONS.REDO"
                          cssClass="toolbar-button toolbar-button--primary"
                          data-test-id="test-id-bid-toolbar-button-redo">
        </qv-action-button>
        <qv-action-button (buttonClick)="onActionButtonClick(bidToolbarAction.SEND_BID)"
                          *ngIf="isSendNotFinalButtonAvailable"
                          [disabled]="isBidActionsDisallowed"
                          [svgIconName]="svgIconName.SEND"
                          [title]="'Send'"
                          [tooltip]="tooltips.BID_ACTIONS.SEND"
                          cssClass="toolbar-button toolbar-button--primary"
                          data-test-id="bid-toolbar-button-send-bid">
        </qv-action-button>
        <qv-action-button (buttonClick)="onActionButtonClick(bidToolbarAction.SEND_FINAL_BID)"
                          *ngIf="isSendFinalButtonAvailable"
                          [disabled]="isBidActionsDisallowed"
                          [svgIconName]="svgIconName.SEND"
                          [title]="'Send'"
                          [tooltip]="tooltips.BID_ACTIONS.SEND"
                          cssClass="toolbar-button toolbar-button--primary"
                          data-test-id="bid-toolbar-button-send-bid">
        </qv-action-button>
        <qv-action-button (buttonClick)="onActionButtonClick(bidToolbarAction.INTEND_TO_RESPOND)"
                          *ngIf="isRespondButtonAvailable"
                          [disabled]="isBidActionsDisallowed"
                          [svgIconName]="svgIconName.CIRCLE_OK"
                          [title]="'Respond'"
                          [tooltip]="tooltips.BID_ACTIONS.RESPOND"
                          cssClass="toolbar-button toolbar-button--primary"
                          data-test-id="test-id-bid-toolbar-button-respond">
        </qv-action-button>
        <qv-action-button (buttonClick)="onActionButtonClick(bidToolbarAction.ACCEPT_BID)"
                          *ngIf="isAcceptButtonAvailable"
                          [disabled]="isBidActionsDisallowed"
                          [svgIconName]="svgIconName.CIRCLE_OK"
                          [title]="'Accept'"
                          [tooltip]="tooltips.BID_ACTIONS.ACCEPT"
                          cssClass="toolbar-button toolbar-button--primary"
                          data-test-id="test-id-bid-toolbar-button-accept-bid">
        </qv-action-button>
        <qv-action-button (buttonClick)="onActionButtonClick(bidToolbarAction.REVIEW_BID)"
                          *ngIf="isReviewButtonAvailable"
                          [disabled]="isBidActionsDisallowed"
                          [svgIconName]="svgIconName.VIEW"
                          [title]="'Review'"
                          [tooltip]="tooltips.BID_ACTIONS.REVIEW"
                          cssClass="toolbar-button toolbar-button--primary"
                          data-test-id="test-id-bid-toolbar-button-review-bid">
        </qv-action-button>
        <qv-action-button (buttonClick)="onActionButtonClick(bidToolbarAction.DECLINE_BID)"
                          *ngIf="isRespondButtonAvailable || isAcceptButtonAvailable"
                          [disabled]="isBidActionsDisallowed"
                          [svgIconName]="svgIconName.BLOCK"
                          [title]="'Decline'"
                          [tooltip]="tooltips.BID_ACTIONS.DECLINE"
                          cssClass="toolbar-button toolbar-button--warn"
                          data-test-id="test-id-bid-toolbar-button-decline-bid">
        </qv-action-button>
        <qv-action-button (buttonClick)="onActionButtonClick(bidToolbarAction.EDIT_RFP_SENT_BID)"
                          *ngIf="isEditRfpSentButtonAvailable"
                          [svgIconName]="svgIconName.EDIT"
                          [title]="'Edit'"
                          [tooltip]="tooltips.BID_ACTIONS.EDIT"
                          cssClass="toolbar-button toolbar-button--primary"
                          data-test-id="test-id-bid-toolbar-button-edit-rfp">
        </qv-action-button>
        <qv-action-button (buttonClick)="onActionButtonClick(bidToolbarAction.EDIT_BID)"
                          *ngIf="isEditButtonAvailable"
                          [svgIconName]="svgIconName.EDIT"
                          [title]="'Edit'"
                          [tooltip]="tooltips.BID_ACTIONS.EDIT"
                          cssClass="toolbar-button toolbar-button--primary"
                          data-test-id="test-id-bid-toolbar-button-edit-bid">
        </qv-action-button>
        <qv-action-button (buttonClick)="onActionButtonClick(bidToolbarAction.REOPEN_BID)"
                          *ngIf="isReopenButtonAvailable"
                          [disabled]="isBidActionsDisallowed"
                          [svgIconName]="svgIconName.REOPEN"
                          [title]="'Reopen'"
                          [tooltip]="tooltips.BID_ACTIONS.REOPEN"
                          cssClass="toolbar-button toolbar-button--primary"
                          data-test-id="test-id-bid-toolbar-button-reopen-bid">
        </qv-action-button>
        <qv-action-button (buttonClick)="onActionButtonClick(bidToolbarAction.COPY_BID)"
                          *ngIf="isCopyButtonAvailable"
                          [disabled]="isBidActionsDisallowed"
                          [svgIconName]="svgIconName.COPY"
                          [title]="'Copy'"
                          [tooltip]="tooltips.BID_ACTIONS.COPY"
                          cssClass="toolbar-button toolbar-button--primary"
                          data-test-id="test-id-bid-toolbar-button-copy-bid">
        </qv-action-button>
        <qv-action-button (buttonClick)="onActionButtonClick(bidToolbarAction.DISCARD_DRAFT)"
                          *ngIf="isDiscardDraftButtonAvailable"
                          [disabled]="isBidActionsDisallowed"
                          [svgIconName]="svgIconName.DISCARD"
                          [title]="'Discard'"
                          [tooltip]="tooltips.BID_ACTIONS.DISCARD_DRAFT"
                          cssClass="toolbar-button toolbar-button--primary"
                          data-test-id="test-id-bid-toolbar-button-discard">
        </qv-action-button>
        <qv-action-button (buttonClick)="onActionButtonClick(bidToolbarAction.DELETE_BID)"
                          *ngIf="isDeleteButtonAvailable"
                          [disabled]="isBidActionsDisallowed"
                          [svgIconName]="svgIconName.DELETE"
                          [title]="'Delete'"
                          [tooltip]="tooltips.BID_ACTIONS.DELETE"
                          cssClass="toolbar-button toolbar-button--warn"
                          data-test-id="test-id-bid-toolbar-button-delete-bid">
        </qv-action-button>
      </ng-container>
      <qv-action-button (buttonClick)="onActionButtonClick(bidToolbarAction.DETAILS)"
                        *ngIf="isGridView || isStandardView"
                        [disabled]="isBidActionsDisallowed"
                        [svgIconName]="svgIconName.DETAILS"
                        [title]="'Details'"
                        [tooltip]="tooltips.BID_ACTIONS.DETAILS"
                        cssClass="toolbar-button toolbar-button--primary"
                        data-test-id="test-id-bid-toolbar-button-details">
      </qv-action-button>
      <qv-action-button (buttonClick)="openTermVisibilityMenu()"
                        *ngIf="isGridView"
                        [disabled]="isBidActionsDisallowed"
                        [svgIconName]="svgIconName.COLUMNS"
                        cssClass="toolbar-button toolbar-button--extra"
                        tooltip="Show/Hide Columns"
      ></qv-action-button>
      <div [matMenuTriggerFor]="gridColumnsStateMenu.menu" class="toolbar-button__menu-trigger"></div>
    </div>
    <qv-bid-extra-tools *ngIf="bid" [bid]="bid"></qv-bid-extra-tools>
  </div>
</div>
<qv-term-visibility-menu #gridColumnsStateMenu></qv-term-visibility-menu>
