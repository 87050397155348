export enum DrugScenarioStatus {
  ACCEPTED = 'ACCEPTED',
  DISMISSED = 'DISMISSED',
  OPEN = 'OPEN',
  DISMISSED_IN_REVIEW = 'DISMISSED_IN_REVIEW'
}

export namespace DrugScenarioStatus {
  const displayValues = new Map<DrugScenarioStatus, string>([
    [DrugScenarioStatus.ACCEPTED, 'Accepted'],
    [DrugScenarioStatus.DISMISSED, 'Dismissed'],
    [DrugScenarioStatus.OPEN, 'Open'],
    [DrugScenarioStatus.DISMISSED_IN_REVIEW, 'Review: Dismissed'],
  ]);

  export function getKeys(): DrugScenarioStatus[] {
    return Array.from(displayValues.keys());
  }

  export function displayValue(scenarioStatus: DrugScenarioStatus): string {
    return displayValues.get(scenarioStatus);
  }
}
