import { SelectBoxTerm } from '@qv-term/models/types';
import { constants, resources } from '@qv-common/static';
import { RebateEligibility } from '@qv-term/enums/options';

export class RebateEligibilityTerm extends SelectBoxTerm {

  constructor(name: string) {
    super(
      name,
      name,
      resources.TermsDescriptions.CONTRACT.REBATE_ELIGIBILITY,
      constants.CONTRACT_TERMS.REBATE_ELIGIBILITY.label,
      '',
      RebateEligibility.getValue(RebateEligibility.EMPTY),
      'term__rebate-eligibility'
    );

    this.values = RebateEligibility.getValues();
  }
}
