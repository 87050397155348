import { Component, OnInit } from '@angular/core';
import { SectionFiltersService } from '@qv-bid/services';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SectionFilter } from '@qv-bid/enums';
import { SectionFilterCheckboxes } from '@qv-bid/models';

@UntilDestroy()
@Component({
  selector: 'qv-standard-scenario-placeholder',
  templateUrl: './standard-scenario-placeholder.component.html',
  styleUrls: ['./standard-scenario-placeholder.component.scss']
})
export class StandardScenarioPlaceholderComponent implements OnInit {
  public filter: SectionFilterCheckboxes;
  public readonly sectionFilterEnum = SectionFilter;

  constructor(private sectionFiltersService: SectionFiltersService) {}

  public ngOnInit(): void {
    this.addFilterListener();
  }

  private addFilterListener(): void {
    this.sectionFiltersService.sectionsFilters$
      .pipe(untilDestroyed(this))
      .subscribe((sectionsFilter: SectionFilterCheckboxes) => this.filter = sectionsFilter);
  }
}
