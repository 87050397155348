import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { forkJoin, Observable } from 'rxjs';
import { NoneAccessGuard, PermissionGuard } from '@qv-common/guards';
import { ArchivedBidGuard } from '@qv-bid/guards/archived-bid.guard';
import { HistoricBidGuard } from '@qv-bid/guards/historic-bid.guard';
import { map } from 'rxjs/operators';

@Injectable()
export class BidViewGuard implements CanActivate {

  constructor(
    private noneAccessGuard: NoneAccessGuard,
    private permissionGuard: PermissionGuard,
    private historicBidGuard: HistoricBidGuard,
    private archivedBidGuard: ArchivedBidGuard,
  ) {}

  public canActivate(next: ActivatedRouteSnapshot): Observable<boolean> {
    const guards = [
      this.noneAccessGuard.canActivate(),
      this.permissionGuard.canActivate(next),
      this.historicBidGuard.canActivate(next),
      this.archivedBidGuard.canActivate(next)
    ];
    return forkJoin(guards).pipe(
      map((results) => results.every(isAllowed => isAllowed))
    );
  }
}
