import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { Injectable, Provider } from '@angular/core';
import { BidListNotificationService } from '@qv-bid/services';
import {
  HTTP_INTERCEPTORS,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { HttpResponseErrorStatus, HttpStatusCode } from 'quantuvis-angular-common/api';
import { catchError } from 'rxjs/operators';
import { MessageChannel } from '@qv-shared/enums';

@Injectable()
export class BidExistenceInterceptor implements HttpInterceptor {
  private resources: Array<RegExp> = [
    new RegExp(/^\/api\/bids\/(\d+).*/),
    new RegExp(/^\/api\/bids\?.+/),
    new RegExp(/^\/api\/bids\/bid-versions.*/),
    new RegExp(/^\/api\/bids\/(\d+)\/bid-versions.*/),
  ];
  private excludedResources: Array<RegExp> = [
    new RegExp(/(lock-info)$/)
  ];
  private bidNotFoundPattern = new RegExp(/The Bid with id [0-9,]+ does not exist/);

  constructor(private notificationService: BidListNotificationService, private router: Router) {}

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let httpEvent$ = next.handle(req);

    if (this.isBidApiRequest(req)) {
      httpEvent$ = httpEvent$.pipe(
        catchError((error: HttpErrorResponse) => {
          if (this.isBidNotFoundWithIdError(error)) {
            this.router.navigate(['/bids'])
              .then(() => this.notificationService.showBidNotFoundNotification(MessageChannel.BID_LIST));
          } else if (this.isBidNotFoundError(error)) {
            this.router.navigate(['/dashboard'])
              .then(() => this.notificationService.showBidNotFoundNotification());
          }

          return throwError(error);
        })
      );
    }

    return httpEvent$;
  }

  private isBidApiRequest(req: HttpRequest<any>): boolean {
    return this.resources.some((resource: RegExp) => resource.test(req.url)) &&
      !this.excludedResources.some((resource: RegExp) => resource.test(req.url));
  }

  private isBidNotFoundWithIdError(err: HttpErrorResponse): boolean {
    return (err.status === HttpStatusCode.NOT_FOUND || err.status === HttpStatusCode.FORBIDDEN)
      && this.bidNotFoundPattern.test(err.error.message);
  }

  private isBidNotFoundError(err: HttpErrorResponse): boolean {
    return err.status === HttpStatusCode.NOT_FOUND && err.error.status === HttpResponseErrorStatus.NOT_FOUND;
  }
}

export const bidExistenceInterceptorProvider: Provider = {
  provide: HTTP_INTERCEPTORS,
  useClass: BidExistenceInterceptor,
  multi: true
};
