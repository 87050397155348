import { Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { BehaviorSubject, Observable, Subscription, timer } from 'rxjs';
import { filter, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { NotificationBellDaoService } from '@qv-header/services/dao';
import { UserService } from '@qv-common/services/auth/user.service';

@UntilDestroy()
@Injectable()
export class NotificationBellService {
  private readonly unreadNotesRequestDelay = 3000;
  private notificationCount: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  constructor(
    private notificationBellDaoService: NotificationBellDaoService,
    private router: Router,
    private userService: UserService,
  ) {
    this.initRouteHandler();
  }

  get getNotificationCount(): BehaviorSubject<number> {
    return this.notificationCount;
  }

  public initRouteHandler(): void {
    this.router.events.pipe(
      untilDestroyed(this),
      filter((event: RouterEvent) => event instanceof NavigationEnd),
    ).subscribe(() => {
      if (this.router.isActive('/notifications', false)) {
        this.readNotifications();
      }
    });
  }

  public initCheckCountOfNotificationsHandler(delay: number = 0): Subscription {
    return this.checkCountOfNotifications(delay)
      .subscribe((response: HttpResponse<any>) =>
        this.notificationCount.next(response.body.responseObject.totalCount));
  }

  public readNotifications(): void {
    this.notificationBellDaoService.markNotificationsAsRead()
      .subscribe(() => this.notificationCount.next(0));
  }

  private checkCountOfNotifications(delay: number): Observable<HttpResponse<any>> {
    return timer(delay)
      .pipe(
        withLatestFrom(this.userService.isUserLoggedIn),
        filter(([, isUserLoggedIn]: [number, boolean]) => isUserLoggedIn),
        switchMap(() => this.notificationBellDaoService.getCountOfUnreadNotifications()),
        tap(() => this.initCheckCountOfNotificationsHandler(this.unreadNotesRequestDelay)),
        untilDestroyed(this));
  }
}
