<ng-container [formGroup]="internalFieldFormGroup">
  <div class="internal-field">
    <div class="internal-field__header">
      <span class="internal-field__label">
        {{ internalField.label }}
      </span>
      <mat-icon
          *ngIf="internalField.instruction"
          [matTooltip]="internalField.instruction"
          [svgIcon]="svgIconName.INFO_OUTLINE"
          class="qv-icon qv-icon--16 internal-field__info"
      ></mat-icon>
    </div>
    <input class="qv-input qv-input--primary internal-field__control" formControlName="value" matInput>
    <acc-form-field-errors
        [control]="internalFieldFormGroup.get('value')"
        [validationMessages]="internalField.getErrorMessages()"
    ></acc-form-field-errors>
  </div>
</ng-container>
