import { DrugMeta } from './drug-meta';

export class DrugGroupMeta {
  public isExpanded = true;

  constructor(
    public drugClass: string,
    public drugMap: Map<string, DrugMeta>
  ) {}

  public isSelected(): boolean {
    return Array.from(this.drugMap.values()).every((drug: DrugMeta) => drug.isSelected);
  }
}
