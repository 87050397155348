import { DictionaryItem } from 'quantuvis-angular-common/interfaces';

export enum LatePaymentPenaltyFrequencyStatus {
  EMPTY = 209,
  DAILY,
  MONTHLY,
  QUARTERLY,
}

export namespace LatePaymentPenaltyFrequencyStatus {
  const valuesMap = new Map<LatePaymentPenaltyFrequencyStatus, DictionaryItem>([
    [LatePaymentPenaltyFrequencyStatus.EMPTY, { id: LatePaymentPenaltyFrequencyStatus.EMPTY, name: '' }],
    [LatePaymentPenaltyFrequencyStatus.DAILY, { id: LatePaymentPenaltyFrequencyStatus.DAILY, name: 'Daily' }],
    [LatePaymentPenaltyFrequencyStatus.MONTHLY, { id: LatePaymentPenaltyFrequencyStatus.MONTHLY, name: 'Monthly' }],
    [LatePaymentPenaltyFrequencyStatus.QUARTERLY, { id: LatePaymentPenaltyFrequencyStatus.QUARTERLY, name: 'Quarterly' }],
  ]);

  export function getValue(price: LatePaymentPenaltyFrequencyStatus): DictionaryItem {
    return valuesMap.get(price);
  }

  export function getValues(): DictionaryItem[] {
    return Array.from(valuesMap.values());
  }
}

