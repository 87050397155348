import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit
} from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { FeatureUtils } from '@qv-common/utils';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Bid, BidVersion } from '@qv-bid/entities';
import { BidStatus } from 'quantuvis-core-entities';
import { FileService } from '@qv-common/services/api';
import { PermissionService } from '@qv-common/services/auth/permission.service';
import { ViewPerspectiveService } from '@qv-common/services/auth';
import { resources } from '@qv-common/static/resources';
import { HttpErrorResponse, HttpResponseBase } from '@angular/common/http';
import { BlockingMessage, SvgIconName } from '@qv-common/enums';
import { QuantuvisPlusFeature } from '@qv-company/enums';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { LoiErrorMessage } from '@qv-loi-common/enums';
import {
  ScenarioSummaryModalConfig,
  ScenarioSummaryModalData
} from '@qv-bid/components/shared/scenario-summary-modal/models';
import { BidStateService, BidVersionService } from '@qv-bid/services';
import { catchError, filter, finalize, switchMap, tap } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { BidExportDaoService } from '@qv-bid/services/dao';
import { LetterOfIntentDaoService } from '@qv-loi-common/services/dao';
import { ModalService, ModalSize } from 'quantuvis-angular-common/modal';
import { NotificationService } from 'quantuvis-angular-common/notification';
import { UserService } from '@qv-common/services/auth/user.service';

@UntilDestroy()
@Component({
  selector: 'qv-bid-extra-tools',
  templateUrl: './bid-extra-tools.component.html',
  styleUrls: ['./bid-extra-tools.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BidExtraToolsComponent implements OnInit, OnChanges {
  @Input()
  public bid: Bid;
  @Input()
  public bidVersionId: number;

  @BlockUI()
  public blockUI: NgBlockUI;

  public readonly resources = resources;
  public readonly svgIconName = SvgIconName;
  public readonly quantuvisPlusFeature = QuantuvisPlusFeature;
  public bidId: number;
  public startVersion: number;
  public endVersion: number;
  public isPharma: boolean;
  public isLetterOfIntentAvailable: boolean;
  public isScenarioSummaryAvailable: boolean;
  public quantuvisPlusTooltip: string;
  public quantuvisPlusRouterLink: string;
  public quantuvisPlusQueryParamsHandling: string;
  public isExportToPdfWithAttachmentsAvailable: boolean;
  public isExportButtonVisible: boolean;
  public isExportUpgradeButtonVisible: boolean;
  public isBidHistoryButtonVisible: boolean;
  public isBidHistoryUpgradeButtonVisible: boolean;
  public userFeatures$: Observable<Map<string, boolean>> = this.permissionService.userFeatures$;

  private currentVersion: BidVersion;

  constructor(
    protected userService: UserService,
    protected bidExportService: BidExportDaoService,
    protected permissionService: PermissionService,
    protected activatedRoute: ActivatedRoute,
    protected modalService: ModalService,
    public viewPerspectiveService: ViewPerspectiveService,
    protected letterOfIntentDaoService: LetterOfIntentDaoService,
    protected notificationService: NotificationService,
    protected bidVersionService: BidVersionService,
    protected changeDetectorRef: ChangeDetectorRef,
    protected bidStateService: BidStateService
  ) {
  }

  public ngOnInit(): void {
    this.initRouteParamsChangeHandler();
    this.initSubscriptions();
  }

  public ngOnChanges(): void {
    this.isPharma = this.userService.isCurrentUserPharma();
    this.setIsLetterOfIntendAvailable();
    this.setQuantuvisPlusButtonProperties();
    this.setIsExportToPdfWithAttachmentsAvailable();
    this.setIsExportToExcelAvailable();
    this.setIsDownloadBidAvailable();
    this.setIsBidHistoryAvailable();
    this.setIsBidHistoryAvaliableWithoutQuantuvisPlus();
  }

  public exportToPdf(ndcLevel: boolean, withAttachments: boolean): void {
    let result: Observable<HttpResponseBase>;

    this.blockUI.start(FileService.DOWNLOADING_MSG);
    if (this.startVersion && this.endVersion) {
      result = this.bidExportService
        .exportPdfRevisionComparison(this.bidId, this.startVersion, this.endVersion, ndcLevel, this.bid.isInternal);
    } else {
      const bidVersionId = this.getBidVersionIdForExport();
      result = this.bidExportService.exportPdfBidVersion(bidVersionId, ndcLevel, withAttachments);
    }

    result.pipe(finalize(() => this.blockUI.stop())).subscribe();
  }

  public exportToExcel(): void {
    const bidVersionId = this.getBidVersionIdForExport();
    this.blockUI.start(FileService.DOWNLOADING_MSG);

    this.bidExportService.exportExcelBidVersion(bidVersionId)
      .pipe(
        finalize(() => this.blockUI.stop())
      )
      .subscribe();
  }

  public downloadLetterOfIntent(): void {
    this.blockUI.start(FileService.DOWNLOADING_MSG);
    this.letterOfIntentDaoService.getFiles()
      .pipe(
        finalize(() => this.blockUI.stop()),
        catchError((error: HttpErrorResponse) => this.showDownloadLoiError(error)),
        tap((files: []) => this.checkLoiTemplateExistence(files)),
        filter((files: []) => Boolean(files && files.length)),
        switchMap(() => this.bidExportService.downloadLetterOfIntent(this.bidId)),
        catchError((error: HttpErrorResponse) => this.showContactInfoNotExistError(error))
      ).subscribe();
  }

  public showScenarioSummaryModal(): void {
    const versionId = this.bidVersionId ? this.bidVersionId : this.bidStateService.bidVersionId;

    this.blockUI.start(BlockingMessage.LOADING);
    this.bidExportService.getAvailableDrugScenarioStatuses(versionId).pipe(
      finalize(() => this.blockUI.stop()),
      catchError((errorResponse: HttpErrorResponse) => {
        this.notificationService.showServerError(errorResponse);

        return throwError(errorResponse);
      })
    )
      .subscribe((availableDrugScenarioStatuses: string[]) => {
        const modalData = new ScenarioSummaryModalData(versionId, availableDrugScenarioStatuses);
        const modalConfig = new ScenarioSummaryModalConfig(modalData, ModalSize.X_SMALL);
        this.modalService.openModal(modalConfig);
      });
  }

  public isFeatureAllowed(feature: string): boolean {
    return this.permissionService.isFeatureAllowed(feature);
  }

  public enrichParamsWithViewPerspective(params: Params, isInternal: boolean): Params {
    return this.viewPerspectiveService.enrichParamsWithViewPerspective(params, isInternal);
  }

  private isRevisionComparison(startVersion: number, endVersion: number): boolean {
    return Boolean(startVersion) && Boolean(endVersion);
  }

  private isHistoryAvailable(bidId: number, status: BidStatus): boolean {
    return bidId && status !== BidStatus.RFP_NOT_SENT;
  }

  private setIsExportToPdfWithAttachmentsAvailable(): void {
    const isPayerViewPerspective = this.viewPerspectiveService.isPayerViewPerspective();
    const isRevisionComparison = this.isRevisionComparison(this.startVersion, this.endVersion);

    this.isExportToPdfWithAttachmentsAvailable = isPayerViewPerspective && !isRevisionComparison;
  }

  private setIsExportToExcelAvailable(): void {
    this.isExportButtonVisible = this.isFeatureAllowed(this.quantuvisPlusFeature.EXPORT) &&
      !this.isRevisionComparison(this.startVersion, this.endVersion);
  }

  private setIsDownloadBidAvailable(): void {
    this.isExportUpgradeButtonVisible = !this.isFeatureAllowed(this.quantuvisPlusFeature.EXPORT) &&
      !this.isRevisionComparison(this.startVersion, this.endVersion);
  }

  private setIsBidHistoryAvailable(): void {
    this.isBidHistoryButtonVisible = this.isHistoryAvailable(this.bid?.id, this.bid?.status) &&
      this.isFeatureAllowed(this.quantuvisPlusFeature.BID_HISTORY);
  }

  private setIsBidHistoryAvaliableWithoutQuantuvisPlus(): void {
    this.isBidHistoryUpgradeButtonVisible = this.isHistoryAvailable(this.bid?.id, this.bid?.status) &&
      !this.isFeatureAllowed(this.quantuvisPlusFeature.BID_HISTORY);
  }

  private setQuantuvisPlusButtonProperties(): void {
    this.quantuvisPlusTooltip = FeatureUtils.getUpgradeMessage(this.isPharma);
    this.quantuvisPlusRouterLink = FeatureUtils.getQuantuvisPlusLink(this.isPharma);
    this.quantuvisPlusQueryParamsHandling = this.isPharma ? '' : 'merge';
  }

  private initRouteParamsChangeHandler(): void {
    this.activatedRoute.queryParams
      .pipe(untilDestroyed(this))
      .subscribe((params: Params) => {
        this.bidId = Number(params.bidId);
        this.startVersion = Number(params.startVersion);
        this.endVersion = Number(params.endVersion);
        this.isScenarioSummaryAvailable = this.calculateIsScenarioSummaryAvailable();
        this.setIsLetterOfIntendAvailable();
      });
  }

  private initSubscriptions(): void {
    this.bidVersionService.getCurrentVersion.pipe(untilDestroyed(this)).subscribe(bidVersion => {
      this.currentVersion = bidVersion;
      this.changeDetectorRef.markForCheck();
    });
  }

  private setIsLetterOfIntendAvailable(): void {
    this.isLetterOfIntentAvailable = this.calculateIsLetterOfIntentAvailable();
  }

  private calculateIsLetterOfIntentAvailable(): boolean {
    if (this.startVersion || !this.userService.isLetterOfIntentAvailable() || !this.hasUserPayerWriteAccessToBid()) {
      return false;
    }

    return (this.bid.status === BidStatus.ACCEPTED
      || (this.bid.status === BidStatus.ACCEPTED_WITH_CHANGES && this.bid.inReview))
      && this.bid.isBinding;
  }

  private calculateIsScenarioSummaryAvailable(): boolean {
    return !this.isRevisionComparison(this.startVersion, this.endVersion)
      && this.isFeatureAllowed(QuantuvisPlusFeature.PRINT_SCENARIO_SUMMARY)
      && this.hasUserScenarioSummaryAccess();
  }

  private hasUserScenarioSummaryAccess(): boolean {
    const companyId = this.userService.isCurrentUserPayer() ? this.bid.manufacturer.id : this.bid.payer.id;
    return this.permissionService.hasUserAtLeastReadAccessToCompany(companyId);
  }

  private hasUserPayerWriteAccessToBid(): boolean {
    if (this.bid && this.bid.manufacturer && this.userService.isCurrentUserPayer()) {
      const companyId = this.bid.manufacturer.id;
      return this.permissionService.hasUserWriteAccessToCompany(companyId);
    }
    return false;
  }

  private getBidVersionIdForExport(): number {
    return this.bidVersionId ? this.bidVersionId : this.currentVersion.id;
  }

  private showDownloadLoiError(error: HttpErrorResponse): Observable<never> {
    this.notificationService.error(LoiErrorMessage.DOWNLOAD_LOI_ERROR_MSG);

    return throwError(error);
  }

  private checkLoiTemplateExistence(files: []): void {
    if (!files || !files.length) {
      this.notificationService.warning(LoiErrorMessage.LOI_TEMPLATE_IS_MISSING_MSG);
    }
  }

  private showContactInfoNotExistError(error: HttpErrorResponse): Observable<never> {
    this.notificationService
      .error(LoiErrorMessage.CONTACT_INFO_NOT_EXISTS_ERROR_MSG);

    return throwError(error);
  }
}
