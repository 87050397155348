<!doctype html>
<html>

<head>
  <!--  Force IE to use the latest version of its rendering engine -->
  <meta content="IE=edge,chrome=1" http-equiv="X-UA-Compatible">

  <title>Quantuvis Open Source Software List</title>
</head>

<body>
<div id="wrapper">
    <span class="tos">
        <div class="quantuvisLogo"></div>
        <span class="title">OPEN SOURCE SOFTWARE LIST:</span>
        <p>Last Modified: June 3, 2014</p>

        <ul>
            <li>
                <a href="http://www.postgresql.org/" target="_blank">PostgreSQL</a>
                <a href="http://www.postgresql.org/about/licence/" target="_blank">(License)</a>
            </li>

            <li>
                <a href="https://www.mongodb.org/" target="_blank">MongoDB</a>
                <a href="http://www.mongodb.org/about/licensing/" target="_blank">(License)</a>
            </li>

            <li>
                <a href="https://dropwizard.github.io/dropwizard/" target="_blank">Dropwizard</a>
                <a href="https://raw.githubusercontent.com/dropwizard/dropwizard/master/LICENSE" target="_blank">(License)</a>
            </li>
            <li>
                <a href="http://commons.apache.org/" target="_blank">Apache Commons</a></li>
            <li>
                <a href="http://www.lingala.net/zip4j" target="_blank">Lingala zip4J</a>
                <a href="http://www.lingala.net/zip4j/about.php" target="_blank">(License)</a></li>
            <li>
                <a href="https://github.com/google/guice" target="_blank">Google Guice</a>
                <a href="http://www.apache.org/licenses/LICENSE-2.0" target="_blank">(License)</a>
            </li>

            <li>
                <a href="http://quartz-scheduler.org" target="_blank">Quartz</a>
                <a href="http://quartz-scheduler.org/overview/license-and-copyright" target="_blank">(License)</a></li>
            <li>
                <a href="http://angularjs.org/" target="_blank">AngularJS</a>
                <a href="https://raw.githubusercontent.com/angular/angular.js/master/LICENSE"
                   target="_blank">(License)</a>
            </li>
            <li>
                <a href="http://getbootstrap.com/2.3.2" target="_blank">Bootstrap</a>
                <a href="https://raw.githubusercontent.com/twbs/bootstrap/master/LICENSE" target="_blank">(License)</a>
            </li>
            <li>
                <a href="http://jquery.com/" target="_blank">JQuery</a>
                <a href="https://jquery.org/license/" target="_blank">(License)</a></li>
            <li>
                <a href="https://wiki.opscode.com/display/chef" target="_blank">Chef</a>
                <a href="https://wiki.opscode.com/display/chef/Apache+License" target="_blank">(License)</a></li>
            <li>
                <a href="http://freemarker.org" target="_blank">Freemarker</a>
                <a href="http://freemarker.org/docs/app_license.html" target="_blank">(License)</a></li>
        </ul>
    </span>
</div>
</body>

</html>
