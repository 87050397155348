<ng-container *ngIf="(isFormReady$ | async) || !isEditMode && entity">
  <div class="section-toolbar">
    <div class="section-toolbar__title">
      <h2>Pharma Audit Terms</h2>
    </div>
    <qv-lock
        [control]="$any(formGroup?.get(termName.getTermNameLock(sectionName)))"
        [isDisabled]="isBidInvalid"
        [isEditMode]="isEditMode"
        [locked]="entity?.pharmaAuditLock"
        data-test-id="test-id-contract-pharma-terms"
    ></qv-lock>
  </div>

  <section class="table-section table-section--two-equal-columns table-section--striped">
    <qv-table-section-row [termName]="termName?.PHARMA_AUDIT_TERMS_AUDIT_FREQUENCY" class="table-section__row">
      <qv-term-select
          [control]="$any(formGroup?.get(termName.PHARMA_AUDIT_TERMS_AUDIT_FREQUENCY))"
          [isEditMode]="isEditMode"
          [isLocked]="entity?.pharmaAuditLock"
          [termName]="termName?.PHARMA_AUDIT_TERMS_AUDIT_FREQUENCY"
          [termValue]="entity?.pharmaAuditFrequency?.name"
          data-test-id="test-id-contract-audit-frequency"
      ></qv-term-select>
    </qv-table-section-row>

    <qv-table-section-row [termName]="termName?.PHARMA_AUDIT_TERMS_PRIOR_NOTICE_REQUIRED"
                          class="table-section__row table-section__row--align-baseline"
                          data-test-id="test-id-contract-prior-notice-required">
      <qv-term-input
          [class.table-section__cell--error]="formGroup?.get(termName.PHARMA_AUDIT_TERMS_PRIOR_NOTICE_REQUIRED)?.errors"
          [control]="$any(formGroup?.get(termName.PHARMA_AUDIT_TERMS_PRIOR_NOTICE_REQUIRED))"
          [isEditMode]="isEditMode"
          [isLocked]="entity?.pharmaAuditLock"
          [numberOptions]="intNumberOptions"
          [termName]="termName?.PHARMA_AUDIT_TERMS_PRIOR_NOTICE_REQUIRED"
          [termValue]="entity?.priorNoticeRequired"
          class="table-section__cell table-section__cell--inline"
          termSuffix=""
      ></qv-term-input>
      <acc-form-field-errors
          *ngIf="isEditMode"
          [control]="formGroup?.get(termName.PHARMA_AUDIT_TERMS_PRIOR_NOTICE_REQUIRED)"
          [validationMessages]="validationMessages"
          class="table-section__error"
      ></acc-form-field-errors>
      <div class="table-section__cell">
        <div class="table-section__suffix">{{units.days}}</div>
      </div>
    </qv-table-section-row>

    <qv-table-section-row [termName]="termName?.PHARMA_AUDIT_TERMS_LOOK_BACK"
                          class="table-section__row table-section__row--align-baseline"
                          data-test-id="test-id-contract-lock-back">
      <qv-term-input [control]="$any(formGroup?.get(termName.PHARMA_AUDIT_TERMS_LOOK_BACK))"
                     [isEditMode]="isEditMode"
                     [isLocked]="entity?.pharmaAuditLock"
                     [numberOptions]="intNumberOptions"
                     [termName]="termName?.PHARMA_AUDIT_TERMS_LOOK_BACK"
                     [termValue]="entity?.lookBack"
                     class="table-section__cell table-section__cell--inline"
                     termSuffix=""
      ></qv-term-input>
      <div class="table-section__cell">
        <div class="table-section__suffix">{{units.months}}</div>
      </div>
    </qv-table-section-row>
  </section>
</ng-container>

