import { constants } from '@qv-common/static';
import { CoreUtils } from '@qv-common/utils';
import { ApiUrlPrefix } from '@qv-common/enums';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { catchError, tap } from 'rxjs/operators';
import { of } from 'rxjs';

// @ts-ignore
import templateUrl from './administrator.html';

const CompaniesComponent = {
  template: templateUrl,
  bindings: {},
  controller: class {
    public static $inject = ['$scope', '$rootScope', 'apiService', 'router', 'preferences', 'permissionService'];
    constructor($scope, $rootScope, apiService, router, preferences, permissionService) {
      'ngInject';
      $scope.router = router;
      $scope.response = $rootScope.response;
      delete $rootScope.response;

      $scope.requestFor = undefined;
      $scope.companies = undefined;
      $scope.companiesResponse = undefined;
      $scope.allow = permissionService.isAllowed.bind(permissionService);

      if (permissionService.isAllowed('quantuvis_administrator')) {

        $scope.$watch('requestFor', (requestFor) => {
          preferences.setUserPreference(constants.USER_PREFERENCES_ADMINISTRATOR_PAGE_COMPANY_TYPE_OPTION, requestFor);

          const requestOption = requestFor === 'all' ? '' : requestFor;
          apiService.get(`${ApiUrlPrefix.OLD}/companies/all/${requestOption}`).pipe(
            tap((response: HttpResponse<any>) => {
              $scope.companies = response.body.responseObject;
              $scope.companiesResponse = undefined;

              if (isNotDefined($scope.companies)) {
                $scope.companiesResponse = {
                  error: true,
                  message: 'There was an error while retrieving companies information. Please try again later.'
                };
              }
              refresh();
            }),
            catchError((error: HttpErrorResponse) => {
              $scope.companies = undefined;
              $scope.companiesResponse = error;

              return of(null);
            })
          ).subscribe();
        });

        $scope.companyTypeConstants = constants.COMPANY_TYPES;
        $scope.companyTypes = [
          {
            value: constants.ALL_OPTION.toLowerCase(),
            pluralValue: constants.ALL_COMPANIES_OPTION
          },
          $scope.companyTypeConstants.PAYER,
          $scope.companyTypeConstants.PHARMA,
          $scope.companyTypeConstants.PAYER_INTERNAL,
        ];

        $scope.requestFor = preferences.getUserPreference(
          constants.USER_PREFERENCES_ADMINISTRATOR_PAGE_COMPANY_TYPE_OPTION,
          constants.ALL_OPTION.toLowerCase()
        );
      }

      $scope.editCompany = company => {
        const params = {
          companyType: company.type.toLowerCase(),
          companyId: company.id
        };
        router.navigate(['/editCompany'], { queryParams: params });
      };

      function refresh(): void {
        if (!$scope.$$phase) {
          $scope.$digest();
        }
      }

      function isNotDefined(toCheck): any {
        return CoreUtils.isNotDefined(toCheck) || CoreUtils.isNull(toCheck);
      }
    }
  }
};
export default CompaniesComponent;
