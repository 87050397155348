import { BidUtils } from '@qv-bid/utils';
import { JsonCustomConvert } from 'json2typescript';

export abstract class EnumConverter<T> implements JsonCustomConvert<T> {
  protected abstract enumInstance: any;

  public serialize(value: any): string | number {
    return value.toString();
  }

  public deserialize(value: string): T {
    return BidUtils.isAtNdc(value) ? value : this.enumInstance[value];
  }
}
