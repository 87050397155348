import { Inject, Injectable } from '@angular/core';
import { ApiUrlPrefix } from '@qv-common/enums';
import { PriceProtection } from '@qv-bid/entities';
import { HttpResponseWrapper } from '@qv-common/models/api';
import { ViewPerspectiveService } from '@qv-common/services';
import { ApiService } from '@qv-common/services/api';
import { HttpUtils } from '@qv-common/utils';
import { JsonConvert } from 'json2typescript';
import { JSON_CONVERT_TOKEN } from 'quantuvis-angular-common/json-converter/services';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class PriceProtectionDaoService {

  constructor(
    private apiService: ApiService,
    @Inject(JSON_CONVERT_TOKEN) private jsonConvert: JsonConvert,
    private viewPerspectiveService: ViewPerspectiveService
  ) { }

  public update(
    priceProtection: PriceProtection,
    scenarioId: number,
    isBidInternal: boolean,
    drugId?: number
  ): Observable<PriceProtection> {
    let url = this.getRestUrl(scenarioId, drugId);

    const perspective = this.viewPerspectiveService.getViewPerspective();
    url = HttpUtils.appendPerspectiveQueryParamToUrlIfBidIsInternal(url, perspective, isBidInternal);

    return this.apiService.put(url, priceProtection).pipe(
      map((response: HttpResponseWrapper<PriceProtection>) =>
        this.jsonConvert.deserializeObject(response.body.data, PriceProtection)
      )
    );
  }

  private getRestUrl(scenarioId: number, drugId?: number): string {
    if (drugId) {
      return `${ApiUrlPrefix.BIDS}/scenarios/${scenarioId}/drugs/${drugId}/price-protection`;
    } else {
      return `${ApiUrlPrefix.BIDS}/scenarios/${scenarioId}/price-protection`;
    }
  }
}
