import { ActivatedRoute, Router } from '@angular/router';
import {
  BidDetailsNotificationService,
  BidListNotificationService,
  BidService,
  BidVersionService,
} from '@qv-bid/services';
import { BidInfoService } from '@qv-bid/services/summary/bid-info.service';
import { SpinnerService } from '@qv-common/services/spinner/spinner.service';
import { ILocationProvider } from 'angular';
import ngSanitize from 'angular-sanitize';
import { MatDialog } from '@angular/material/dialog';
import { ngTableModule } from 'ng-table';
import { downgradeInjectable } from '@angular/upgrade/static';
import { LocalStorageService } from 'ngx-webstorage';

import { Util } from '@qv-common/utils/util';
import { Preferences } from '@qv-common/services/preferences';
import { RestAPI } from '@qv-common/services/restapi';
import { Standards } from '@qv-common/services/standards';
import { ComponentsNgModule } from './components/components.module';
import { PermissionService } from '@qv-common/services/auth/permission.service';
import { ViewPerspectiveService } from '@qv-common/services/auth';
import { ApiService, FileService } from '@qv-common/services/api';
import { BidDaoService, BidExportDaoService, SummaryDaoService } from '@qv-bid/services/dao';
import { InsightsDaoService } from '@qv-insights/services/dao';
import { LegalAttestationService } from '@qv-company/services';
import { BidActionsService } from '@qv-bid/services/bid-actions.service';
import { BiddingUtilsService } from './services';
import { SharedNg1Module } from './shared/shared-ng1.module';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { SharedEventBusService } from '@qv-shared/services';
import { UserDaoService } from '@qv-common/services/dao';
import {
  TermsAndConditionsEventBusService
} from '@qv-terms-and-conditions/services/terms-and-conditions-event-bus.service';
import { ModalService } from 'quantuvis-angular-common/modal';
import { NotificationService } from 'quantuvis-angular-common/notification';
import { putTemplateCaches } from './template.cache';
import { AuthService } from 'quantuvis-angular-common/auth';
import { UserService } from '@qv-common/services/auth/user.service';

declare let angular: angular.IAngularStatic;

/**
 * AngularJS 1 app module
 */

export const AppModuleA1 = angular
  .module('quantuvis', [ngSanitize, ngTableModule.name, 'once', ComponentsNgModule.name,
    SharedNg1Module.name
  ])
  .service('preferences', Preferences)
  .service('restapi', RestAPI)
  .service('standards', Standards)
  .service('util', Util)
  .service('biddingUtilsService', BiddingUtilsService)
  .service('permissionService', downgradeInjectable(PermissionService) as any)
  .service('authService', downgradeInjectable(AuthService) as any)
  .service('userService', downgradeInjectable(UserService) as any)
  .service('spinnerService', downgradeInjectable(SpinnerService) as any)
  .service('localStorageService', downgradeInjectable(LocalStorageService) as any)
  .service('viewPerspectiveService', downgradeInjectable(ViewPerspectiveService) as any)
  .service('bidDetailsNotificationService', downgradeInjectable(BidDetailsNotificationService) as any)
  .service('bidExportService', downgradeInjectable(BidExportDaoService) as any)
  .service('bidListNotificationService', downgradeInjectable(BidListNotificationService) as any)
  .service('apiService', downgradeInjectable(ApiService) as any)
  .service('bidService', downgradeInjectable(BidService) as any)
  .service('bidInfoService', downgradeInjectable(BidInfoService) as any)
  .service('bidVersionService', downgradeInjectable(BidVersionService) as any)
  .service('summaryDaoService', downgradeInjectable(SummaryDaoService) as any)
  .service('dialogRef', downgradeInjectable(MatDialog) as any)
  .service('modalService', downgradeInjectable(ModalService) as any)
  .service('insightsDaoService', downgradeInjectable(InsightsDaoService))
  .service('notificationService', downgradeInjectable(NotificationService) as any)
  .service('legalAttestationService', downgradeInjectable(LegalAttestationService) as any)
  .service('bidActionsService', downgradeInjectable(BidActionsService) as any)
  .service('fileService', downgradeInjectable(FileService) as any)
  .service('bidDaoService', downgradeInjectable(BidDaoService) as any)
  .service('googleAnalyticsService', downgradeInjectable(GoogleAnalyticsService) as any)
  .service('router', downgradeInjectable(Router) as any)
  .service('activatedRoute', downgradeInjectable(ActivatedRoute) as any)
  .service('sharedEventBusService', downgradeInjectable(SharedEventBusService))
  .service('userDaoService', downgradeInjectable(UserDaoService))
  .service('termsAndConditionsEventBusService', downgradeInjectable(TermsAndConditionsEventBusService))
;

const a1Config = ['$locationProvider', ($locationProvider: ILocationProvider) => {
  'ngInject';
  $locationProvider.hashPrefix('').html5Mode(false);
}];

AppModuleA1.config(a1Config);

ComponentsNgModule.run(['$templateCache', putTemplateCaches]);
