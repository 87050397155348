<section class="qv-modal-dialog">
  <header mat-dialog-title class="qv-modal-dialog-title">
    <h1 class="qv-modal-dialog-title__text">{{ title }}</h1>
    <button class="qv-modal-dialog-title__xbutton" (click)="onClose()">
      <mat-icon>close</mat-icon>
    </button>
  </header>
  <div class="qv-modal-dialog-content qv-modal-dialog-content--font-light">
    <b>{{modalData.currentScenario.drugName}} {{modalData.currentScenario.name}} ({{ modalData.currentScenario.cbName }})</b>
    <span> is dependent upon: </span>
    <div class="dependency-container">
      <div *ngFor="let scenario of modalData.scenarios | orderBy: 'drugName'">
        {{scenario.drugName}} {{scenario.name}} ({{ modalData.currentScenario.cbName }})
      </div>
    </div>
  </div>
  <footer mat-dialog-actions class="qv-modal-dialog-actions">
    <button mat-button class="qv-modal-dialog-actions__item qv-modal-dialog-actions__item--primary" (click)="onClose()">
      Close
    </button>
  </footer>
</section>
