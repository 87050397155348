import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { MenuActionItem } from '@qv-bid/components/shared/drug-actions-menu/models';
import { resources } from '@qv-common/static';
import { DrugReviewActionsService } from '@qv-bid/services/drug';

@Component({
  selector: 'qv-review-drug-menu',
  templateUrl: './review-drug-menu.component.html',
  styleUrls: ['./review-drug-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReviewDrugMenuComponent implements OnInit {
  public reviewMenuItems: MenuActionItem[];

  constructor(private drugReviewActionsService: DrugReviewActionsService,
  ) { }

  public ngOnInit(): void {
    this.initReviewMenuItems();
  }

  private initReviewMenuItems(): void {

    this.reviewMenuItems = [
      new MenuActionItem(
        resources.DRUG_ACTIONS.ACCEPT_IN_REVIEW,
        () => this.drugReviewActionsService.acceptDrugs(),
        this.drugReviewActionsService.actionsIsDisabled()
      ),
      new MenuActionItem(
        resources.DRUG_ACTIONS.DISMISS_IN_REVIEW,
        () => this.drugReviewActionsService.dismissDrugs(),
        this.drugReviewActionsService.actionsIsDisabled()
      ),
      new MenuActionItem(
        resources.DRUG_ACTIONS.UNDO_IN_REVIEW,
        () => this.drugReviewActionsService.undoDrugs(),
        this.drugReviewActionsService.actionsIsDisabled()
      ),
    ];
  }

}
