import { CurrencyPipe } from '@angular/common';

import { TermTypes } from '@qv-common/enums';
import { Comparator, SkipAutosave } from '@qv-common/interfaces';
import { resources } from '@qv-common/static';
import { InputTerm } from '@qv-term/models/types';

export class BaseLineWACTerm extends InputTerm implements Comparator, SkipAutosave {
  public doNotSave: boolean;
  public ignoreOnNDCValue: boolean;
  public currency = {
    code: 'USD',
    indicatorFormat: 'symbol',
    digitsInfo: '1.6-6'
  };
  public emptyValue = 'Unavailable';

  constructor(name: string) {
    super(
      name,
      name,
      resources.TermsDescriptions.DRUG_LIST.BASELINE_WAC,
      '',
      '',
      '',
      'term_baseline-wac'
    );
    this.prefix = this.unitsService.dollar;
    this.type = TermTypes.PlainText;
    this.doNotSave = true;
    this.ignoreOnNDCValue = true;
  }

  public getTermValueForComparison(): string {
    if (this.value !== '') {
      return this.value
        ? new CurrencyPipe('en-US').transform(this.value, this.currency.code)
        : this.emptyValue;
    } else {
      return '';
    }
  }

}
