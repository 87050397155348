import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl } from '@angular/forms';
import { SentBidInfo } from '@qv-bid/models';
import { BidSendModalData } from '@qv-bid/components/shared';
import { GeneralModalComponent } from 'quantuvis-angular-common/modal';

@Component({
  selector: 'qv-bid-send-modal',
  templateUrl: './bid-send-modal.component.html',
  styleUrls: ['./bid-send-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BidSendModalComponent extends GeneralModalComponent {
  @Output()
  public primaryAction: EventEmitter<SentBidInfo> = new EventEmitter<SentBidInfo>();

  public readonly title = 'Send Bid';
  public control = new FormControl(false);

  constructor(public dialogRef: MatDialogRef<BidSendModalComponent | GeneralModalComponent>,
              @Inject(MAT_DIALOG_DATA) public modalData: BidSendModalData | any) {
    super(dialogRef, null);
  }

  public onPrimaryAction(): void {
    this.blockUI.start();
    this.primaryAction.next(new SentBidInfo(this.control.value));
  }
}
