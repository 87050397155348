/* tslint:disable:no-invalid-this */
import { resources } from '@qv-common/static';

class InternalPayerPickerController {
  public static $inject = ['$scope', 'standards', 'CreateInternalBidService'];
  private $scope;
  private standards;
  private CreateInternalBidService;
  private onValid;

  constructor($scope, standards, CreateInternalBidService) {
    'ngInject';
    this.$scope = $scope;
    this.standards = standards;
    this.CreateInternalBidService = CreateInternalBidService;
  }

  public $onInit(): void {
    this.cleanupPickersData();
    this.standards.apply(this.$scope, {
      constructor: () => {},
      variables: {
        DRUGS_EVALUATED_ENUM: this.getDrugEvaluatedEnum(),
        focusSearchCompany: false,
        companies: [],
        selectedCompanies: [],
        noCompanyResult: false,
        searchedCompanies: {},
        searching: false,
      },
    });
    this.$scope.DRUGS_EVALUATED_ENUM.setSelectedValue(this.$scope.DRUGS_EVALUATED_ENUM.SOME_INTERNAL_PAYERS);
  }

  public $onChanges(changes): void {
    if (changes.runValidation && changes.runValidation.currentValue) {
      const isValid = this.isValid();
      if (isValid) {
        this.CreateInternalBidService.payerList = this.$scope.selectedCompanies;
      }
      this.onValid({ value: isValid });
    }
  }

  /**
   * Validate selected payers
   *
   * @returns {boolean}
   */
  public isValid(): boolean {
    const staticData = this.$scope.DRUGS_EVALUATED_ENUM.getStaticDataForSelection();

    if (this.$scope[staticData.listFromRightName].length === 0) {
      this.CreateInternalBidService.pickerError = resources.NewBid.SelectPayerError;
      return false;
    } else {
      this.CreateInternalBidService.pickerError = '';
      return true;
    }
  }

  /**
   * Method return Drug Evaluated Enum
   *
   * @return {Object}
   */
  public getDrugEvaluatedEnum(): any {
    return {
      ALL_MANUFACTURERS_SOME_DRUGS: 1,
      SOME_MANUFACTURERS_ALL_DRUGS: 2,
      SOME_INTERNAL_PAYERS: 3,
      ALL_MANUFACTURER_DRUGS: 4,

      setSelectedValue(value): any {
        this.selectedValue = value;
      },
      getSelectedValue(): any {
        return this.selectedValue;
      }
    };
  }

  /**
   * Cleanup Picker data for picker controller
   */
  public cleanupPickersData(): void {
    this.$scope.companies = [];
    this.$scope.selectedCompanies = [];
    this.$scope.searching = false;
    this.$scope.tooManyResults = false;
    this.$scope.searchedCompanies = {};
    this.CreateInternalBidService.pickerError = '';
  }

}

export default InternalPayerPickerController;
