import { DictionaryItem } from '@qv-common/entities';

export enum PharmaAuditFrequency {
  EMPTY = 1,
  ANNUALLY = 2,
  ONE_PER_CONTRACT = 3,
  ONE_EVERY_2_YEARS = 4,
  ONE_EVERY_3_YEARS = 5,
}

export namespace PharmaAuditFrequency {
  const valuesMap = new Map<PharmaAuditFrequency, DictionaryItem>([
    [PharmaAuditFrequency.EMPTY, { id: PharmaAuditFrequency.EMPTY, name: '' }],
    [PharmaAuditFrequency.ANNUALLY, { id: PharmaAuditFrequency.ANNUALLY, name: 'Annually' }],
    [
      PharmaAuditFrequency.ONE_PER_CONTRACT,
      { id: PharmaAuditFrequency.ONE_PER_CONTRACT, name: '1 per Contract Term' }
    ],
    [
      PharmaAuditFrequency.ONE_EVERY_2_YEARS,
      { id: PharmaAuditFrequency.ONE_EVERY_2_YEARS, name: '1 every 2 years' }
    ],
    [
      PharmaAuditFrequency.ONE_EVERY_3_YEARS,
      { id: PharmaAuditFrequency.ONE_EVERY_3_YEARS, name: '1 every 3 years' }
    ],
  ]);

  export function getValue(frequency: PharmaAuditFrequency): DictionaryItem {
    return valuesMap.get(frequency);
  }

  export function getValues(): DictionaryItem[] {
    return Array.from(valuesMap.values());
  }
}
