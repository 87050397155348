import { JsonConvert, JsonConverter, JsonCustomConvert, OperationMode, ValueCheckingMode } from 'json2typescript';
import { Note } from '@qv-term/entities';
import { BidUtils } from '@qv-bid/utils/bid.utils';
import { NotesMetadata } from '@qv-term/models';
import { CoreUtils } from '@qv-common/utils';

@JsonConverter
export class NotesConverter implements JsonCustomConvert<NotesMetadata> {
  private jsonConvert = new JsonConvert(OperationMode.ENABLE, ValueCheckingMode.ALLOW_NULL);

  public serialize(data: NotesMetadata): { historicNotes: any, currentNote: any } {
    return {
      historicNotes: data ? this.jsonConvert.serialize(data.getHistoric) : [],
      currentNote: data ? this.jsonConvert.serialize(data.getCurrent) : null,
    };
  }

  public deserialize(data: any): NotesMetadata {
    const viewNotes = new NotesMetadata();

    if (!data) {
      return viewNotes;
    }

    if (Object.keys(data).includes('historicNotes')) {
      if (data.historicNotes instanceof Array) {
        data.historicNotes.forEach((item) => {
          viewNotes.getHistoric.push(this.jsonConvert.deserializeObject(item, Note));
        });
      } else if (BidUtils.isAtNdc(data.historicNotes)) {
        viewNotes.isAtNdcHistoric = true;
      }
    }

    if (Object.keys(data).includes('currentNote')) {
      if (CoreUtils.isNotEmptyObject(data.currentNote)) {
        viewNotes.getCurrent = this.jsonConvert.deserializeObject(data.currentNote, Note);
      } else if (BidUtils.isAtNdc(data.currentNote)) {
        viewNotes.isAtNdcCurrent = true;
      }
    }

    return viewNotes;
  }
}
