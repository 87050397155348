import { ChangeDetectionStrategy, ChangeDetectorRef, Component, } from '@angular/core';
import { BidEventBusService } from '@qv-bid/services/bid-event-bus.service';
import { StandardDrugFormService } from '@qv-bid/services/drug';
import { MarketShareDaoService } from '@qv-bid/services/dao';
import { NdcManagerService } from '@qv-bid/services/ndc-manager.service';
import { ScenariosSnackBarService } from '@qv-bid/services/scenarios-snack-bar.service';
import { SectionChangeManager } from '@qv-bid/services/sections/section-change-manager.service';
import { MarketShareSection } from '@qv-bid/classes/sections';
import { UserService } from '@qv-common/services/auth/user.service';

@Component({
  selector: 'qv-standard-market-share',
  templateUrl: './standard-market-share.component.html',
  styleUrls: ['./standard-market-share.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StandardMarketShareComponent extends MarketShareSection {
  public readonly dropDownAppendTo = 'qv-standard-scenario';

  public constructor(
    standardDrugFormService: StandardDrugFormService,
    marketShareDaoService: MarketShareDaoService,
    changeDetectorRef: ChangeDetectorRef,
    userService: UserService,
    ndcManagerService: NdcManagerService,
    scenariosSnackBarService: ScenariosSnackBarService,
    bidEventBusService: BidEventBusService,
    sectionChangeManager: SectionChangeManager,
  ) {
    super(standardDrugFormService, marketShareDaoService, changeDetectorRef, userService, ndcManagerService,
      scenariosSnackBarService, bidEventBusService, sectionChangeManager);
  }
}
