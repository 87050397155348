import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('ContractedBusiness')
export class ContractedBusiness {

  @JsonProperty('id', Number)
  public id: number = null;

  @JsonProperty('bidVersionId', Number)
  public bidVersionId: number = null;

  @JsonProperty('name', String)
  public name = '';

  @JsonProperty('uuid', String)
  public uuid: string = null;

  constructor(id: number = null, name: string = '') {
    this.id = id;
    this.name = name;
  }
}
