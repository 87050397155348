import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SvgIconName } from '@qv-common/enums';

@Component({
  selector: 'qv-no-items-message',
  templateUrl: './no-items-message.component.html',
  styleUrls: ['./no-items-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NoItemsMessageComponent {
  @Input()
  public message = 'There are no items available.';

  public readonly svgIconName = SvgIconName;
}
