import { BaseEntity } from '@qv-bid/entities/base-entity';
import { NumberConverter } from '@qv-bid/serializers';
import { DictionaryItem } from '@qv-common/entities';
import { NoFaultTerminationClauseWhoMayInvoke } from '@qv-term/enums/options';
import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('TerminationClause')
export class TerminationClause extends BaseEntity {

  @JsonProperty('cbId', Number)
  public contractedBusinessId: number = null;

  @JsonProperty('isLocked', Boolean)
  public noFaultTerminationClauseLock = false;

  @JsonProperty('priorNoticeRequired', NumberConverter)
  public terminationClausePriorNoticeRequired: number | string = null;

  @JsonProperty('whoMayInvoke', DictionaryItem)
  public whoMayInvoke: DictionaryItem = new DictionaryItem(NoFaultTerminationClauseWhoMayInvoke.EMPTY, '');

  public isPriorNoticeDisabled(): boolean {
    return this.whoMayInvoke.id === NoFaultTerminationClauseWhoMayInvoke.NEITHER_PARTY
      || this.whoMayInvoke.id === NoFaultTerminationClauseWhoMayInvoke.EMPTY;
  }

}
