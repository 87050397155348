import {
  BidFilterService,
  BidLockService,
  ContractedBusinessesService,
  ManageContractedBusinessesService,
  PharmaRightsService,
  ScenarioService
} from '@qv-bid/services';
import { BidStateService } from '@qv-bid/services/bid-state.service';
import { ScenarioConfigListService, } from '@qv-bid/services/scenario-config-list.service';
import { ScenarioNameFormService, } from '@qv-bid/services/scenario-name-form.service';
import { UndoRedoService } from '@qv-bid/services/undo-redo.service';
import { SectionFiltersService } from '@qv-bid/services/section-filters.service';
import { BidViewService } from '@qv-bid/services/bid-view.service';
import { SummaryService } from '@qv-bid/services/summary/summary.service';
import { SummaryFormService } from '@qv-bid/services/summary/summary-form.service';
import { BidCommentFormService } from '@qv-bid/services/summary/bid-comment-form.service';
import { BidHandleErrorService } from '@qv-bid/services/bid-handle-error.service';
import { DrugEditActionsService } from '@qv-bid/services/drug/actions/drug-edit-actions.service';
import { ScenariosDependencyService } from '@qv-bid/services/scenarios-dependency.service';
import { BidActionsService } from '@qv-bid/services/bid-actions.service';
import { BidSendActionService } from '@qv-bid/services/bid-send-action.service';
import { DeleteDrugService } from '@qv-bid/services/delete-drug.service';
import { ContractFormService } from '@qv-bid/components/shared/summary-panel/contract/contract-form.service';
import { BidFormService } from '@qv-bid/services/bid-form.service';
import { ScenarioDateValidationService } from '@qv-bid/services/sections/scenario-date-validation.service';
import { ScenariosSnackBarService } from '@qv-bid/services/scenarios-snack-bar.service';
import { DrugAlertNotificationService } from '@qv-bid/services/drug-alert-notification.service';
import { BidSelectService, DrugSelectUtilsService } from '@qv-bid/services/selects';
import { DrugEditActionsAccessibilityService, DrugReviewActionsService } from '@qv-bid/services/drug';
import { BidViewHandleErrorService } from '@qv-bid/services/bid-view-handle-error.service';


export const bidViewProviders = [
  BidFilterService,
  BidLockService,
  BidStateService,
  BidViewService,
  DeleteDrugService,
  DrugReviewActionsService,
  SectionFiltersService,
  SummaryService,
  BidSelectService,
  BidHandleErrorService,
  ContractFormService,
  SummaryFormService,
  BidFormService,
  DrugSelectUtilsService,
  UndoRedoService,
  ScenariosSnackBarService,
  ScenarioDateValidationService,
  ScenarioService,
  ScenariosDependencyService,
  ScenarioNameFormService,
  BidCommentFormService,
  ScenarioConfigListService,
  BidActionsService,
  BidSendActionService,
  DrugEditActionsService,
  DrugEditActionsAccessibilityService,
  DrugAlertNotificationService,
  PharmaRightsService,
  ManageContractedBusinessesService,
  ContractedBusinessesService,
  BidViewHandleErrorService
];
