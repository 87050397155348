import { JsonObject, JsonProperty } from 'json2typescript';
import { ScenarioFieldName } from '../enums';

@JsonObject('DropdownOption')
export class DropdownOption {

  @JsonProperty('name', String)
  public name: string = null;

  @JsonProperty('fieldOptionId', Number)
  public id: number = null;

  @JsonProperty('fieldName', String)
  public fieldName: ScenarioFieldName = null;

  @JsonProperty('isDisabled', Boolean)
  public isDisabled: boolean = null;

  @JsonProperty('isDefault', Boolean)
  public isDefault: boolean = null;

  @JsonProperty('isStandard', Boolean)
  public isStandard: boolean = null;

  @JsonProperty('position', Number)
  public position: number = null;
}
