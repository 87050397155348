import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { SearchComponent } from './components/search/search.component';
import { SystemMenuComponent } from './components/system-menu/system-menu.component';
import { AccessDeniedComponent } from './components/access-denied/access-denied.component';
import { FormFieldErrorsComponent } from './components/form-field-errors/form-field-errors.component';
import {
  CheckboxFilterComponent,
  CheckboxListComponent,
  RadioListComponent,
  ChipComponent,
  DateCellComponent,
  GridCellComponent,
  GridErrorIconComponent,
  InputListComponent,
  MenuCellComponent,
  NoteMenuComponent,
  NumberCellComponent,
  SelectCellComponent,
  TextareaMenuComponent,
  TextCellComponent
} from './components';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { NumberUtilsModule } from 'quantuvis-angular-common/number-utils';
import { DateUtilsModule, formats } from 'quantuvis-angular-common/date-utils';
import { NgSelectModule } from '@ng-select/ng-select';
import { MatButtonModule } from '@angular/material/button';
import { DirectivesModule } from 'quantuvis-angular-common/directives';
import { A11yModule } from '@angular/cdk/a11y';
import { AutofocusDirective } from './directives';
import { FilterMenuItemComponent } from './components/filter-menu-item/filter-menu-item.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { GlobalDateRangeComponent } from './components/global-date-range/global-date-range.component';
import { YearQuarterListenerDirective } from './directives/year-quarter-listener.directive';
import { MatRadioModule } from '@angular/material/radio';

@NgModule({
  declarations: [
    SystemMenuComponent,
    AccessDeniedComponent,
    InputListComponent,
    FormFieldErrorsComponent,
    SearchComponent,
    DateCellComponent,
    GridCellComponent,
    GridErrorIconComponent,
    TextCellComponent,
    MenuCellComponent,
    TextareaMenuComponent,
    NoteMenuComponent,
    NumberCellComponent,
    SelectCellComponent,
    AutofocusDirective,
    CheckboxListComponent,
    RadioListComponent,
    ChipComponent,
    FilterMenuItemComponent,
    SpinnerComponent,
    GlobalDateRangeComponent,
    YearQuarterListenerDirective,
    CheckboxFilterComponent
  ],
  imports: [
    CommonModule,
    MatDividerModule,
    MatIconModule,
    MatTooltipModule,
    MatMenuModule,
    MatCheckboxModule,
    RouterModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatRadioModule,
    NumberUtilsModule,
    DateUtilsModule,
    NgSelectModule,
    MatButtonModule,
    DirectivesModule,
    A11yModule,
    NgxDaterangepickerMd.forRoot({
      format: formats.dateFormat,
      displayFormat: formats.dateFormatShort
    }),
  ],
  exports: [
    SystemMenuComponent,
    AccessDeniedComponent,
    InputListComponent,
    FormFieldErrorsComponent,
    SearchComponent,
    GridCellComponent,
    GridErrorIconComponent,
    DateCellComponent,
    TextCellComponent,
    MenuCellComponent,
    TextareaMenuComponent,
    NoteMenuComponent,
    NumberCellComponent,
    SelectCellComponent,
    AutofocusDirective,
    CheckboxListComponent,
    RadioListComponent,
    ChipComponent,
    FilterMenuItemComponent,
    SpinnerComponent,
    GlobalDateRangeComponent,
    CheckboxFilterComponent
  ]
})
export class AngularComponentsCommonModule { }
