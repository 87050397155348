import { Directive, HostListener } from '@angular/core';
import { ConfirmNavigation } from '@qv-common/interfaces';

@Directive()
export class ConfirmNavigationOuter implements ConfirmNavigation {
  public confirmNavigationMessage: string;

  public isNavigationNeedConfirmationInner: () => boolean;

  public onInnerEmitConfirmNavigation(data: ConfirmNavigation): void {
    this.confirmNavigationMessage = data.confirmNavigationMessage;
    this.isNavigationNeedConfirmationInner = data.isNavigationNeedConfirmation;
  }

  public isNavigationNeedConfirmation(): boolean {
    return this.isNavigationNeedConfirmationInner();
  }

  @HostListener('window:beforeunload')
  public onBeforeUnload(): boolean {
    return !this.isNavigationNeedConfirmation();
  }
}
