import {
  HTTP_INTERCEPTORS,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { Injectable, Provider } from '@angular/core';
import { HttpHeader } from 'quantuvis-angular-common/api';
import { LocalStorageService } from 'ngx-webstorage';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable()
export class ApplicationVersionInterceptor implements HttpInterceptor {

  constructor(private storage: LocalStorageService) {}

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(req).pipe(tap((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) {
        if (event.headers.has(HttpHeader.X_CLIENT_VERSION)) {
          const lastVersion = event.headers.get(HttpHeader.X_CLIENT_VERSION);
          const currentVersion = this.storage.retrieve(HttpHeader.X_CLIENT_VERSION);

          if (!currentVersion) {
            this.storage.store(HttpHeader.X_CLIENT_VERSION, lastVersion);
          } else if (currentVersion !== lastVersion) {
            this.storage.clear(HttpHeader.X_CLIENT_VERSION);
            window.location.reload();
          }
        }
      }
    }));
  }
}

export const applicationVersionInterceptorProvider: Provider = {
  provide: HTTP_INTERCEPTORS,
  useClass: ApplicationVersionInterceptor,
  multi: true
};
