import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'httpUrl'
})
export class HttpUrlPipe implements PipeTransform {
  private httpPattern = /^https?:\/\//;

  public transform(value: string): string {
    return this.beginsWithHttp(value) ? value : `http://${value}`;
  }

  private beginsWithHttp(url: string): boolean {
    return url ? this.httpPattern.test(url) : false;
  }
}
