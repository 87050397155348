<div class="standard-scenario-section standard-scenario-section--um"
  [class.standard-scenario-section--edit-mode]="isEditMode">
  <qv-standard-scenario-section-title
    [title]="'Utilization Management'"
    [tooltip]="tooltips.UTILIZATION_MANAGEMENT"
    [state]="utilizationManagement.state.value"
    [isAtNdc]="utilizationManagement.state.isNdc"
    [isEditMode]="isEditMode"
    [sectionName]="sectionName">
  </qv-standard-scenario-section-title>
  <div class="standard-scenario-section__terms">
    <!-- Step Therapy & Prior Authorization -->
    <div class="standard-um__step-prior-container">
      <div class="standard-um standard-um--step-prior">
        <div class="standard-um__step-therapy">
          <span class="standard-um__name" [matTooltip]="tooltips.STEP_THERAPY">Step Therapy</span>
          <qv-standard-cell class="standard-um__term"
            data-test-id="test-id-step-allowed-on-product"
            [name]="drugTermsConstants[termName.STEP_THERAPY_ALLOWED_ON_PRODUCT].displayNames.ALLOWED_ON_PRODUCT"
            [termName]="termName.STEP_THERAPY_ALLOWED_ON_PRODUCT">
            <qv-term-select
              [isPending]="isBidInvalid"
              [appendTo]="dropDownAppendTo"
              [termName]="termName.STEP_THERAPY_ALLOWED_ON_PRODUCT"
              [isEditMode]="isEditMode"
              [termValue]="utilizationManagement.stepTherapyAllowed"
              [control]="drugFormService.getTermControl(termName.STEP_THERAPY_ALLOWED_ON_PRODUCT, sectionName)"
            ></qv-term-select>
          </qv-standard-cell>
          <qv-standard-cell class="standard-um__term"
            data-test-id="test-id-step-required-on-products"
            [name]="drugTermsConstants[termName.STEP_THERAPY_REQUIRED_ON_COMPETITORS_PRODUCTS].displayNames.REQUIRED_ON_COMPETITORS_PRODUCTS"
            [termName]="termName.STEP_THERAPY_REQUIRED_ON_COMPETITORS_PRODUCTS">
            <qv-term-select
              [isPending]="isBidInvalid"
              [appendTo]="dropDownAppendTo"
              [termName]="termName.STEP_THERAPY_REQUIRED_ON_COMPETITORS_PRODUCTS"
              [isEditMode]="isEditMode"
              [termValue]="utilizationManagement.stepTherapyRequired"
              [control]="drugFormService.getTermControl(termName.STEP_THERAPY_REQUIRED_ON_COMPETITORS_PRODUCTS, sectionName)"
            ></qv-term-select>
          </qv-standard-cell>
        </div>
      </div>
      <div class="standard-um__prior-auth">
        <span class="standard-um__name" [matTooltip]="tooltips.PRIOR_AUTHORIZATION">Prior Authorization</span>
        <qv-standard-cell class="standard-um__term"
          data-test-id="test-id-prior-allowed-on-product"
          [name]="drugTermsConstants[termName.PRIOR_AUTHORIZATION_ALLOWED_ON_PRODUCT].displayNames.ALLOWED_ON_PRODUCT"
          [termName]="termName.PRIOR_AUTHORIZATION_ALLOWED_ON_PRODUCT">
          <qv-term-select
            [isPending]="isBidInvalid"
            [appendTo]="dropDownAppendTo"
            [termName]="termName.PRIOR_AUTHORIZATION_ALLOWED_ON_PRODUCT"
            [isEditMode]="isEditMode"
            [termValue]="utilizationManagement.priorAuthorizationAllowed"
            [control]="drugFormService.getTermControl(termName.PRIOR_AUTHORIZATION_ALLOWED_ON_PRODUCT, sectionName)"
          ></qv-term-select>
        </qv-standard-cell>
        <qv-standard-cell class="standard-um__term"
          data-test-id="test-id-prior-required-on-products"
          [name]="drugTermsConstants[termName.PRIOR_AUTHORIZATION_REQUIRED_ON_COMPETITORS_PRODUCTS].displayNames.REQUIRED_ON_COMPETITORS_PRODUCTS"
          [termName]="termName.PRIOR_AUTHORIZATION_REQUIRED_ON_COMPETITORS_PRODUCTS">
          <qv-term-select
            [isPending]="isBidInvalid"
            [appendTo]="dropDownAppendTo"
            [termName]="termName.PRIOR_AUTHORIZATION_REQUIRED_ON_COMPETITORS_PRODUCTS"
            [isEditMode]="isEditMode"
            [termValue]="utilizationManagement.priorAuthorizationRequired"
            [control]="drugFormService.getTermControl(termName.PRIOR_AUTHORIZATION_REQUIRED_ON_COMPETITORS_PRODUCTS, sectionName)"
          ></qv-term-select>
        </qv-standard-cell>
      </div>
    </div>
    <!-- Quantity Limit & Other UM -->
    <div class="standard-um__quantity-other-container">
      <div class="standard-um__quantity">
        <qv-standard-cell
          data-test-id="test-id-quantity-limit"
          [name]="drugTermsConstants[termName.QUANTITY_LIMIT].displayNames.QUANTITY_LIMIT"
          [termName]="termName.QUANTITY_LIMIT">
          <qv-term-select
            [isPending]="isBidInvalid"
            [appendTo]="dropDownAppendTo"
            [termName]="termName.QUANTITY_LIMIT"
            [isEditMode]="isEditMode"
            [termValue]="utilizationManagement.quantityLimit"
            [control]="drugFormService.getTermControl(termName.QUANTITY_LIMIT, sectionName)"
          ></qv-term-select>
        </qv-standard-cell>
      </div>
      <div class="standard-um__other">
        <qv-standard-cell
          data-test-id="test-id-other-um"
          [name]="drugTermsConstants[termName.OTHER_UM].displayNames.OTHER_UM"
          [termName]="termName.OTHER_UM">
          <qv-term-select
            [isPending]="isBidInvalid"
            [appendTo]="dropDownAppendTo"
            [termName]="termName.OTHER_UM"
            [isEditMode]="isEditMode"
            [termValue]="utilizationManagement.otherUM"
            [control]="drugFormService.getTermControl(termName.OTHER_UM, sectionName)"
          ></qv-term-select>
        </qv-standard-cell>
      </div>
    </div>
    <!-- UM Details -->
    <qv-standard-um-details
      [isBidInvalid]="isBidInvalid"
      [umDetails]="utilizationManagement.umDetails"
      [isEditMode]="isEditMode"
      [sectionName]="sectionName"
      [scenarioId]="scenarioId"
    ></qv-standard-um-details>
  </div>
</div>
