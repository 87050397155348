import { AuthConfig } from 'angular-oauth2-oidc';
import { environment } from '@qv-env/environment';

export const authConfig: AuthConfig = {
  issuer: environment.auth.host,
  redirectUri: window.location.origin,
  postLogoutRedirectUri: window.location.origin,
  clientId: environment.auth.clientId,
  scope: 'openid profile email',
  responseType: 'code',
  disablePKCE: true
};

export const authTimeoutFactorRange: { from: number, to: number } = {
  from: 0.55,
  to: 0.85
};

export const authConstants = {
  sessionIdParamName: 'session_id',
  sessionIdParamRegex: /[&\?]session_id=[^&\$]*/
};
