import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  InvalidContractDateModalData
} from '@qv-bid/components/shared/invalid-contract-date-modal/models/invalid-contract-date-modal-data';
import { SvgIconName } from '@qv-common/enums';
import { resources } from '@qv-common/static';

@Component({
  selector: 'qv-invalid-contract-date-modal',
  templateUrl: './invalid-contract-date-modal.component.html',
  styleUrls: ['./invalid-contract-date-modal.component.scss']
})
export class InvalidContractDateModalComponent {
  public readonly svgIconName = SvgIconName;
  public readonly resources = resources;

  constructor(
    public dialogRef: MatDialogRef<InvalidContractDateModalComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: InvalidContractDateModalData
  ) {}
}
