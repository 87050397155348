import { DictionaryItem } from '@qv-common/entities';

export enum BooleanChoice {
  EMPTY = 1,
  NO = 2,
  YES = 3,
}

export namespace BooleanChoice {
  const valuesMap = new Map<BooleanChoice, DictionaryItem>([
    [BooleanChoice.EMPTY, { id: BooleanChoice.EMPTY, name: '' }],
    [BooleanChoice.YES, { id: BooleanChoice.YES, name: 'Yes' }],
    [BooleanChoice.NO, { id: BooleanChoice.NO, name: 'No' }],
  ]);

  export function getValue(booleanChoice: BooleanChoice): DictionaryItem {
    return valuesMap.get(booleanChoice);
  }

  export function getValues(): DictionaryItem[] {
    return Array.from(valuesMap.values());
  }

}
