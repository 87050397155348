import { constants } from '@qv-common/static/constants';
import { drugTermsConstants } from '@qv-term/constants';
import { TermName } from '@qv-term/enums';

const iconClass = 'icon qv-icon-gray ';

export const biddingConstants = {
  klass: iconClass,
  drugGroupStateOptionsValuesMap: {
    allowed: {
      state: constants.DRUG_TERM_GROUPS_STATE_VALUES.VISIBLE_STATE.state,
      prettyValue: 'Allowed',
      cssClass: `${iconClass}fa fa-eye-open`
    },
    visible: {
      state: constants.DRUG_TERM_GROUPS_STATE_VALUES.VISIBLE_STATE.state,
      prettyValue: 'Allowed',
      cssClass: `${iconClass}fa fa-eye-open`
    },
    hidden: {
      state: constants.DRUG_TERM_GROUPS_STATE_VALUES.HIDDEN_STATE.state,
      prettyValue: 'Hidden',
      cssClass: `${iconClass}fa fa-eye-close`
    },
    locked: {
      state: constants.DRUG_TERM_GROUPS_STATE_VALUES.LOCKED_STATE.state,
      prettyValue: 'Locked',
      cssClass: `${iconClass}fa fa-lock`
    },
    required: {
      state: constants.DRUG_TERM_GROUPS_STATE_VALUES.REQUIRED_STATE.state,
      prettyValue: 'Required',
      cssClass: `${iconClass}fa fa-warning-sign`
    },
    unlocked: {
      state: constants.DRUG_TERM_GROUPS_STATE_VALUES.UNLOCKED_STATE.state,
      prettyValue: 'Unlocked',
      cssClass: 'lock-icon-margin cust-sprite icon-unlocked'
    },
    atndc: {
      state: 'atndc',
      prettyValue: '@NDC',
      cssClass: ''
    }
  },
  contractTermGroups: [
    [
      constants.CONTRACT_TERMS.REBATE_ELIGIBILITY.label,
      constants.CONTRACT_TERMS.WHOLESALE_ACQUISITION_PRICE.label,
      constants.CONTRACT_TERMS.PAYMENT_LAG.label,
      constants.CONTRACT_TERMS.PAYMENT_FREQUENCY.label
    ],
    [
      constants.CONTRACT_TERMS.LATE_PAYMENT_PENALTY_TERMS_BASIS.label,
      constants.CONTRACT_TERMS.LATE_PAYMENT_PENALTY_TERMS_VALUE.label,
      constants.CONTRACT_TERMS.LATE_PAYMENT_PENALTY_TERMS_FREQUENCY.label,
      constants.CONTRACT_TERMS.ADMINISTRATION_FEE.label
    ],
    [
      constants.CONTRACT_TERMS.PHARMA_AUDIT_TERMS_AUDIT_FREQUENCY.label,
      constants.CONTRACT_TERMS.PHARMA_AUDIT_TERMS_PRIOR_NOTICE_REQUIRED.label,
      constants.CONTRACT_TERMS.PHARMA_AUDIT_TERMS_LOOK_BACK.label,
      constants.CONTRACT_TERMS.NO_FAULT_TERMINATION_CLAUSE_WHO_MAY_INVOKE.label,
      constants.CONTRACT_TERMS.NO_FAULT_TERMINATION_CLAUSE_PRIOR_NOTICE_REQUIRED.label
    ]
  ],
  validation: {
    priceProtection: {
      section: constants.DrugList.PRICE_PROTECTION,
      terms: [
        drugTermsConstants[TermName.NET_EFFECTIVE_PRICE].title,
        drugTermsConstants[TermName.INDEX].title,
        drugTermsConstants[TermName.THRESHOLD].title,
        drugTermsConstants[TermName.RESET].title,
        drugTermsConstants[TermName.BASELINE_START_DATE].title,
        drugTermsConstants[TermName.NEW_PRICE_EFFECTIVE_DATE].title
      ]
    }
  }
};
