import { Directive, ElementRef, Output, EventEmitter, HostListener } from '@angular/core';

@Directive({
  selector: '[qacClickOutside]'
})
export class ClickOutsideDirective {
  @Output()
  public clickOutside = new EventEmitter<void>();

  constructor(private elementRef: ElementRef) {}

  @HostListener('document:click', ['$event.target'])
  public onClick(targetElement: HTMLElement): void {
    if (this.shouldEmitClickOutsideEvent(targetElement)) {
      this.clickOutside.emit();
    }
  }

  private shouldEmitClickOutsideEvent(targetElement: HTMLElement): boolean {
    const rootElement = document.querySelector('body');
    const calendarElement = document.querySelector('.mat-calendar');
    const clickedInside = this.elementRef.nativeElement.contains(targetElement);
    const clickedInsideCalendar = rootElement.contains(calendarElement);

    return rootElement.contains(targetElement) && !clickedInside && !clickedInsideCalendar;
  }
}
