/* tslint:disable:triple-equals */
import { ArrayUtils, CoreUtils } from '@qv-common/utils';
import sortBy from 'lodash.sortby';
import { catchError, tap } from 'rxjs/operators';
import { HttpResponse } from '@angular/common/http';
import { ApiUrlPrefix } from '@qv-common/enums';
import { of } from 'rxjs';
import { ReportFeedbackComponent, ReportMissingDrugComponent } from '@qv-drug-search/components';
import { resources } from '@qv-common/static';
import { ModalSize, TemplateFormModalData } from 'quantuvis-angular-common/modal';

// @ts-ignore
import templateUrl from './drug-search.html';
import { UserService } from '@qv-common/services/auth/user.service';

declare let $: any;

const DrugSearchComponent = {
  template: templateUrl,
  bindings: {},
  controller: class {
    public static $inject = [
      '$scope', '$timeout', '$document', 'apiService', 'util', '$window', 'coreDomUtilsService', 'userService',
      'modalService', 'translations'
    ];

    constructor(
      $scope, $timeout, $document, apiService, util, $window, coreDomUtilsService, userService: UserService, modalService,
      translations
    ) {
      'ngInject';
      const rxOTCMappings = {
        R: 'Rx (single source)',
        S: 'Rx (multiple sources)',
        O: 'OTC (single source)',
        P: 'OTC (multiple sources)',
        '@NDC': '@NDC'
      }, tmMappings = {
        T: 'Trademarked',
        B: 'Branded Generic',
        G: 'Generic'
      };
      const delayInMs = 700;
      let currentSort = 'name';
      let currentSortAsc = true;
      let timeoutPromise, feedbackDrug;

      /** Public scope variables **/

      $scope.current = 'drug';
      $scope.expanded = [];
      $scope.drugs = [];
      $scope.isPharma = true;
      $scope.report = {};
      $scope.searching = false;
      $scope.noResultsFound = false;
      $scope.error = false;
      $scope.search = '';
      $scope.separateWindow = $window.opener && $window.history.length <= 1;
      translations.buildTranslations($scope);

      $scope.$on('$destroy', () => {
        // This is for resetting html tag styles
        $document.find('.root-view').removeClass('full-height');
      });

      /** Public scope functions **/

      /**
       * Is current tab selected - used for sub tab class
       * @param tab - check for tab name
       * @returns {string} css string class
       */
      $scope.isCurrent = tab => tab == $scope.current ? 'active' : '';

      /**
       * Get class for a specific table header column (sort function)
       * @param name column name
       * @returns {string} css string class
       */
      $scope.getClassForHeader = name => {
        let klass = 'sortable';

        if (name == currentSort) {
          klass += currentSortAsc ? ' sort-desc' : ' sort-asc';
        }

        return klass;
      };

      function concat(arr1, arr2): void {
        arr2.forEach(item => arr1.push(item));
      }

      /**
       * Perform sorting
       * @param attr - object attribute
       */
      $scope.sortBy = (attr, forceSort) => {
        if (attr === currentSort && !forceSort) {
          $scope.drugs.reverse();
          currentSortAsc = !currentSortAsc;
        } else {
          currentSort = attr;
          currentSortAsc = true;
          $scope.drugs = sortBy($scope.drugs, item => {
            let val = item[attr];
            /**
             * This is a workaround to include drug NDC
             */
            if (attr === 'name') {
              val += item.ndc;
            }
            return CoreUtils.isNotDefined(val) ? val : val.toString().toLowerCase();
          });
        }

        const groups = ArrayUtils.groupBy($scope.drugs, (item: any) => item.name);
        const finalList = [];
        groups.forEach(group => {

          const buffer = [];

          group.forEach(drug => {
            if (drug.ndc === '') {
              finalList.push(drug);
            } else {
              buffer.push(drug);
            }
          });

          concat(finalList, buffer);
        });

        $scope.drugs = finalList;
      };

      /**
       * Displays modal for provided id.
       * The same method is used to display both report feedback and missing drug modals.
       * When displayed the functionality clears all the color.red classes in order to clear
       * erroneous case
       *
       * @param id modal identifier to open
       * @param drug name
       */
      $scope.showModal = (id, drug) => {
        if (id === 'reportMissingDrug') {
          modalService.openTemplateFormModal(new TemplateFormModalData(
            `Report Missing Drug`,
            ReportMissingDrugComponent,
            null,
            resources.Actions.SUBMIT,
            resources.Actions.CLOSE,
            ModalSize.MEDIUM
          ));
        } else {
          modalService.openTemplateFormModal(new TemplateFormModalData(
            `Report Feedback for ${drug.name} ${drug.ndc}`,
            ReportFeedbackComponent,
            {
              drug
            },
            resources.Actions.SUBMIT,
            resources.Actions.CLOSE,
            ModalSize.MEDIUM
          ));
        }
      };

      /**
       * Return different contents for cells depending on the type
       * @param type column type
       * @param value term value
       * @returns {*} HTML output (binded once)
       */
      $scope.contentFor = (type, value) => {
        let html;

        if (type === 'ph') {
          const style = value === '@NDC' ? 'orange' : value === 'null' ? 'bright-red' : 'black';
          html = `<span class="font color ${style}">${value === 'null' ? 'Unassigned' : value} </span>`;
        } else if (type === 'e') {
          html = `<span class="badge ${
            value === true ? 'badge-success' : value === false ? 'badge-danger' : 'badge-warning'}">${
            value === true ? 'YES' : value === false ? 'NO' : '@NDC'}</span>`;
        } else if (type === 'ac') {
          const color = value === 'YES' ? 'green' : value === 'NO' ? 'bright-red' : 'orange';
          const text = value == 'AT_NDC' ? '@NDC' : value;
          html = `<span class="font color ${color}">${text}</span>`;
        } else if (type === 'rx') {
          html = `<span class="font color ${
            value === 'R' ? 'green' : value === '@NDC' ? 'orange' : 'bright-red'}">${rxOTCMappings[value]}</span>`;
        } else if (type === 'tm') {
          html = `<span class="font color ${
            value === 'T' ? 'green' : value === '@NDC' ? 'orange' : 'bright-red'}">${tmMappings[value]}</span>`;
        } else {
          html = `<span class="font color ${value === '@NDC' ? 'orange' : 'black'}">${value} </span>`;
        }

        return html;
      };

      /**
       * Collapsible class state for a drug
       */
      $scope.chevronClass = drug => `icon qv-icon-gray ${drug.expanded ? 'fa fa-chevron-down' : 'fa fa-chevron-right'}`;

      /**
       * Expand/collapse drug
       */
      $scope.toggle = drug => {
        drug.expanded = !drug.expanded;
      };

      $scope.expand = (index, event) => {
        const drugName = $scope.drugs[index].name;

        for (let i = index + 1; i < $scope.drugs.length; i++) {
          if ($scope.drugs[i].name === drugName) {
            $scope.drugs[i].collapsed = !$scope.drugs[i].collapsed;
          } else {
            break;
          }
        }

        const target = $(event.target);

        if (target.hasClass('fa fa-chevron-right')) {
          target.removeClass('fa fa-chevron-right');
          target.addClass('fa fa-chevron-down');
        } else {
          target.removeClass('fa fa-chevron-down');
          target.addClass('fa fa-chevron-right');
        }

        coreDomUtilsService.recalculateFixHeaders();
      };

      $scope.checkDrug = drug => {
        const checked = drug.checked;
        if (drug.isGroup) {
          drug.selected = checked ? drug.children.length : 0;

          drug.children.forEach(item => {
            if (!item.isAlreadyInList) {
              item.checked = checked;
            }
          });
        } else {
          drug.parent.selected += checked ? 1 : -1;
          drug.parent.checked = drug.parent.selected == drug.parent.children.length;
        }
      };

      /**
       * Class row css (binded once)
       */
      $scope.getClassFor = drug => `enabled ${drug.isGroup ? ' trIsGroup' : ' trIsNotGroup'}`;

      /**
       * Search for drugs functionality - connection with the API.
       *
       */
      $scope.searchForDrugsTimeout = () => {
        $timeout.cancel(timeoutPromise);
        timeoutPromise = $timeout($scope.searchForDrugs, delayInMs);
      };


      $scope.searchForDrugs = () => {
        $scope.drugs.length = 0;

        const user = userService.user.getValue();
        if (($scope.search && $scope.search.length > 2)
          || (user && user.isManufacturer && $scope.search.length == 0) || $scope.isOpenAsModal == true) {
          $scope.searching = true;
          $scope.noResultsFound = false;
          $scope.error = false;

          const search = $scope.search;
          const encodedSearchInfo = encodeURIComponent($scope.search);
          let query = '/drugs/search/';
          if (user.isPayer && $scope.manufacturer && $scope.manufacturer.companyId) {
            query += `pharmadrugs/byId?pharmaId=${$scope.manufacturer.companyId}`;
          } else if ($scope.current === 'name') {
            query += `pharmadrugs/byName?pattern=${encodedSearchInfo}`;
          } else {
            query += `classandname/all?pattern=${encodedSearchInfo}`;
          }
          apiService.get(ApiUrlPrefix.OLD + query).pipe(
            tap((response: HttpResponse<any>) => {
              const data = response.body;
              if ($scope.search === search) {
                $timeout(() => {
                  let noDrugsToAdd = true;
                  Object.values(data.responseObject).forEach((items: any[]) => {
                    let groupItem;
                    let isAlreadyInList = true;
                    items.forEach(item => {
                      // if (!$scope.ndcList || !) {
                      item.collapsed = !item.isGroup;
                      if (CoreUtils.isNotDefined(item.ndc)) {
                        item.ndc = '';
                      }
                      if (CoreUtils.isNotDefined(item.pharma)) {
                        item.pharma = 'null';
                      }
                      item.processedName = item.name + item.ndc;
                      if (item.isGroup) {
                        groupItem = item;
                        groupItem.children = [];
                        groupItem.selected = 0;
                      } else {
                        if (groupItem.children.length == 0) {
                          $scope.drugs.push(groupItem);
                        }

                        if ($scope.ndcList && $scope.ndcList[item.ndc]) {
                          item.isAlreadyInList = item.checked = true;
                        } else {
                          isAlreadyInList = false;
                        }

                        item.parent = groupItem;
                        groupItem.children.push(item);
                        $scope.drugs.push(item);
                      }
                    });
                    if (isAlreadyInList && groupItem) {
                      groupItem.isAlreadyInList = groupItem.checked = true;
                    } else {
                      noDrugsToAdd = false;
                    }
                  });
                  if (noDrugsToAdd && $scope.isOpenAsModal) {
                    $scope.noDrugsToAdd = true;
                  } else {
                    $scope.noDrugsToAdd = undefined;
                  }
                  $scope.sortBy(currentSort, true);

                  if ($scope.drugs.length === 0) {
                    $scope.noResultsFound = true;
                  } else {
                    $timeout(() => {
                      $scope.$broadcast('sizeChanged');
                    }, 0);
                  }
                  $scope.searching = false;
                }, 0);
              }

            }),
            catchError(() => {
              if ($scope.search === search) {
                $scope.searching = false;
                $scope.error = true;
              }

              return of(null);
            })
          ).subscribe();
        }
      };

      $scope.select = $event => {
        const selected = $($event.target).data('select');

        if (selected) {
          $scope.search = '';
          $scope.current = selected;
          $scope.expanded.length = 0;
          $scope.noResultsFound = false;

          if (!$scope.isPharma) {
            $scope.drugs = [];
          } else {
            // if isPharma we want to load the drug list
            $scope.searchForDrugs();
          }
          // Focus search when switching tabs
          $('#drugSearchInput').focus();
        }
      };

      /**
       * There might be the case when the user request comes later than the initialization of controller so
       * let's
       */

      function callOnGetUser(): void {
        $scope.isPharma = util.isPharma(userService.user.getValue());
        if (!$scope.isPharma && $scope.isOpenAsModal) {
          $scope.current = 'name';
          // use $scope.manufacturer.currentName in search criteria
          // $scope.manufacturer.name holds the display name
          $scope.search = $scope.manufacturer.currentName ? $scope.manufacturer.currentName : $scope.manufacturer.name;
        }
        $scope.searchForDrugs();
      }

      if (userService.user.getValue()) {
        callOnGetUser();
      } else {
        $scope.$on('userLoaded', callOnGetUser);
      }

    }
  }
};
export default DrugSearchComponent;
