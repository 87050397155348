import { AppRoute } from '@qv-common/enums/app-route.enum';

export enum AuthenticationRoute {
  LOGIN = 'login',
  FORGOT_PASSWORD = 'forgot-password',
  CHANGE_PASSWORD = 'change-password',
  INFO = 'info',
  RESEND_TOKEN = 'resend-token',
}

export class AuthenticationRouteUtils {
  public static getValues(): string[] {
    return Object.values(AuthenticationRoute);
  }

  public static getAuthenticationRoutesWithFullPath(): string[] {
    return AuthenticationRouteUtils.getValues().map((route: string) => `${ AppRoute.AUTH }/${ route }`);
  }
}
