import { Injectable } from '@angular/core';
import { RenumberScenario } from '@qv-bid/models/renumber-scenario';
import { HttpResponseWrapper } from '@qv-common/models/api';
import { ApiService } from '@qv-common/services/api';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DrugLockUpdate, SelectedScenarios } from '@qv-bid/models';
import { ContractedBusinessScenarioIds } from '@qv-bid/entities';
import { ViewPerspectiveService } from '@qv-common/services/auth';
import { HttpUtils } from '@qv-common/utils';
import { ApiUrlPrefix } from '@qv-common/enums';

@Injectable()
export class ScenarioDaoService {

  constructor(private apiService: ApiService, private viewPerspectiveService: ViewPerspectiveService) {}

  public setMarketBasketLock(scenarioId: number, marketBasketLock: boolean): Observable<boolean> {
    const url = `${ApiUrlPrefix.BIDS}/scenarios/${scenarioId}/market-baskets/lock/${marketBasketLock}`;

    return this.apiService.post(url).pipe(
      map((response: HttpResponseWrapper<boolean>) => response.body.data)
    );
  }

  public lockNdcs(
    versionId: number,
    cbScenarioIds: ContractedBusinessScenarioIds[],
    isLocked: boolean
  ): Observable<void> {
    const url = `${ApiUrlPrefix.BIDS}/bid-versions/${versionId}/lock-ndcs`;
    const requestData = new DrugLockUpdate(cbScenarioIds, isLocked);

    return this.apiService.post(url, requestData).pipe(map(() => null));
  }

  public createScenarios(versionId: number, cbScenarioIds: ContractedBusinessScenarioIds[],
                         isBidInternal: boolean): Observable<HttpResponseWrapper<number[]>> {
    let url = `${ApiUrlPrefix.BIDS}/bid-versions/${versionId}/scenarios`;

    const perspective = this.viewPerspectiveService.getViewPerspective();
    url = HttpUtils.appendPerspectiveQueryParamToUrlIfBidIsInternal(url, perspective, isBidInternal);

    return this.apiService.post(url, cbScenarioIds);
  }

  public deleteScenarios(versionId: number, cbScenarioIds: ContractedBusinessScenarioIds[],
                         isBidInternal: boolean): Observable<null> {
    let url = `${ApiUrlPrefix.BIDS}/bid-versions/${versionId}/scenarios`;

    const perspective = this.viewPerspectiveService.getViewPerspective();
    url = HttpUtils.appendPerspectiveQueryParamToUrlIfBidIsInternal(url, perspective, isBidInternal);

    return this.apiService.delete(url, cbScenarioIds) as Observable<null>;
  }

  public renumberScenarios(
    versionId: number,
    renumberScenarios: RenumberScenario[],
    isBidInternal: boolean
  ): Observable<null> {
    let url = `${ApiUrlPrefix.BIDS}/bid-versions/${versionId}/scenarios/renumber`;

    const perspective = this.viewPerspectiveService.getViewPerspective();
    url = HttpUtils.appendPerspectiveQueryParamToUrlIfBidIsInternal(url, perspective, isBidInternal);

    return this.apiService.post(url, { renumberDtos: renumberScenarios }) as Observable<null>;
  }

  public acceptInReview(
    versionId: number,
    selectedScenarios: SelectedScenarios[],
    isBidInternal: boolean
  ): Observable<null> {
    let url = `${ApiUrlPrefix.BIDS}/bid-versions/${versionId}/scenarios/accept-in-review`;

    const perspective = this.viewPerspectiveService.getViewPerspective();
    url = HttpUtils.appendPerspectiveQueryParamToUrlIfBidIsInternal(url, perspective, isBidInternal);

    return this.apiService.put(url, selectedScenarios) as Observable<null>;
  }

  public dismissInReview(
    versionId: number,
    selectedScenarios: SelectedScenarios[],
    isBidInternal: boolean
  ): Observable<null> {
    let url = `${ApiUrlPrefix.BIDS}/bid-versions/${versionId}/scenarios/dismiss-in-review`;

    const perspective = this.viewPerspectiveService.getViewPerspective();
    url = HttpUtils.appendPerspectiveQueryParamToUrlIfBidIsInternal(url, perspective, isBidInternal);

    return this.apiService.put(url, selectedScenarios) as Observable<null>;
  }

  public undoInReview(
    versionId: number,
    selectedScenarios: SelectedScenarios[],
    isBidInternal: boolean
  ): Observable<null> {
    let url = `${ApiUrlPrefix.BIDS}/bid-versions/${versionId}/scenarios/reopen-in-review`;

    const perspective = this.viewPerspectiveService.getViewPerspective();
    url = HttpUtils.appendPerspectiveQueryParamToUrlIfBidIsInternal(url, perspective, isBidInternal);

    return this.apiService.put(url, selectedScenarios) as Observable<null>;
  }
}
