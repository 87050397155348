import { DateTerm } from '@qv-term/models/types';
import { KeepOriginalValue, Reset } from '@qv-common/interfaces';
import { constants, resources } from '@qv-common/static';
import moment, { Moment } from 'moment';
import { ValidationError } from '@qv-common/models';
import { SeverityLevel } from '@qv-common/enums';
import { DateUtils } from '@qv-common/utils';

export class RfpDueDateTerm extends DateTerm implements Reset, KeepOriginalValue {
  public keepOriginalValue: boolean;
  public allowKeepOriginValue: boolean;
  public processKeepOriginalValue: boolean;

  constructor(name: string) {
    super(
      name,
      name,
      resources.TermsDescriptions.SUMMARY.RFP_DUE_DATE,
      constants.SUMMARY_TERMS.RFP_DUE_DATE.label,
      null,
      null,
      'term__rfp-due-date',
      false
    );
    this.keepOriginalValue = this.allowKeepOriginValue;
    this.processKeepOriginalValue = false;
  }

  public static getRfpDueDateWarnings(rfpDueDate: Moment): ValidationError[] {
    return rfpDueDate < DateUtils.convertDateToUTC(moment())
      ? [new ValidationError(SeverityLevel.WARN, 'RFP Due Date is in the past')]
      : [];
  }

  public resetValue(): void {
    // TODO need add KeepOriginal logic
    this.value = this.defaultValue;
  }
}
