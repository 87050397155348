import { ConfirmNavigationInner } from '@qv-ng1-wrapper/classes';
import sortBy from 'lodash.sortby';

export abstract class WizardController extends ConfirmNavigationInner {
  protected countSteps;
  protected firstStepNumber;
  protected currentStep;
  protected steps;

  constructor() {
    super();
    this.steps = [];
    this.countSteps = this.steps.length;
    this.firstStepNumber = 1;
  }

  /**
   * Prepare step titles for wizard
   *
   * @returns {Array}
   */
  public prepareStepTitles(): any[] {
    const stepTitles = [];
    const sortedStepsByOrder = sortBy(this.steps, value => value.order);
    for (const step of sortedStepsByOrder) {
      stepTitles.push(step.title);
    }

    return stepTitles;
  }

  /**
   * Get key for current step
   *
   * @returns {string}
   */
  public getCurrentStepKey(): string {
    return Object.keys(this.steps).find(key => this.steps[key].order === this.currentStep);
  }

  /**
   * Change step to next
   */
  public goToNextStep(): void {
    const nextStep = this.currentStep + 1;
    if (nextStep <= this.countSteps) {
      this.currentStep = nextStep;
    }
  }

  /**
   * Check if next button is visible
   * @returns {boolean}
   */
  public isNextVisible(): boolean {
    return this.currentStep < this.countSteps;
  }

  /**
   * Check if previous button is visible
   * @returns {boolean}
   */
  public isPreviousVisible(): boolean {
    return this.currentStep > this.firstStepNumber;
  }

  /**
   * Handler for next button
   */
  public next(): void {
    const currentStepKey = this.getCurrentStepKey();
    this.steps[currentStepKey].runValidation = true;
  }

  /**
   * Validate callback for output binding
   * @param {boolean} isValid
   */
  public onValid(isValid): void {
    const currentStepKey = this.getCurrentStepKey();
    this.steps[currentStepKey].runValidation = false;
    if (isValid) {
      if (this.currentStep === this.countSteps && typeof this.save === 'function') {
        this.save();
      } else {
        this.goToNextStep();
      }
    }
  }

  /**
   * Handler for previous button
   */
  public previous(): void {
    const prevStep = this.currentStep - 1;
    if (prevStep >= this.firstStepNumber) {
      this.currentStep = prevStep;
    }
  }

  public save(): void {}
}
