import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BaseInsideModal } from 'quantuvis-angular-common/modal';

@Component({
  selector: 'qv-enable-confirmation-user',
  templateUrl: './enable-confirmation-user.component.html',
  styleUrls: ['../styles/confirmation-user.scss', './enable-confirmation-user.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EnableConfirmationUserComponent extends BaseInsideModal {
  public readonly hasAsyncAction = true;
}
