<section class="qv-modal-dialog">
  <header class="qv-modal-dialog-title" mat-dialog-title>
    <h1 class="qv-modal-dialog-title__text">{{ title }}</h1>
    <button (click)="onSecondaryAction()" class="qv-modal-dialog-title__xbutton">
      <mat-icon>close</mat-icon>
    </button>
  </header>
  <div
      [class.instructions-modal-content--view-mode]="!modalData.isEditMode"
      class="qv-modal-dialog-content qv-modal-dialog-content--primary-padding qv-modal-dialog-content--font-light instructions-modal-content"
  >
    <qv-html-editor
        (initEvent)="onResize()"
        [control]="control"
        [initialValue]="modalData.initialValue"
        [isEditMode]="modalData.isEditMode"
        class="term-content qv-html-editor"
        data-test-id="test-id-instructions"
    ></qv-html-editor>
    <acc-form-field-errors
        [control]="control"
        [validationMessages]="modalData.validationMessages"
        class="instructions-modal-content__field-errors"
    ></acc-form-field-errors>
  </div>
  <footer class="qv-modal-dialog-actions qv-modal-dialog-actions--padding-sm" mat-dialog-actions>
    <button (click)="onSecondaryAction()"
            [class.qv-modal-dialog-actions__item--primary]="!modalData.isEditMode"
            class="qv-modal-dialog-actions__item"
            mat-button>
      Close
    </button>
    <button (click)="onPrimaryAction()"
            *ngIf="modalData.isEditMode" [disabled]="control.invalid"
            class="qv-modal-dialog-actions__item qv-modal-dialog-actions__item--primary"
            mat-button>
      Save
    </button>
  </footer>
</section>
