import { NgModule } from '@angular/core';
import { ReportFeedbackComponent, ReportMissingDrugComponent } from './components';
import { SharedModule } from '@qv-shared/shared.module';
import { CommonModule } from '@qv-common/common.module';
import { BlockUIModule } from 'ng-block-ui';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  imports: [
    BlockUIModule,
    CommonModule,
    FormsModule,
    MatButtonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatCheckboxModule,
    ReactiveFormsModule,
    SharedModule,
  ],
  declarations: [
    ReportFeedbackComponent,
    ReportMissingDrugComponent,
  ],
  exports: [
    ReportFeedbackComponent,
    ReportMissingDrugComponent,
  ],
})

export class DrugSearchModule {}
