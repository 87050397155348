import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PDFDocumentProxy } from 'pdfjs-dist';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { File } from '../../models';
import { FormControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SvgIconName } from '@qv-common/enums';

@UntilDestroy()
@Component({
  selector: 'qv-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.scss'],
})
export class PdfViewerComponent implements OnInit {
  @Input()
  public file: File;
  @Input()
  public fullPage = false;
  @Input()
  public filePrefix: string;
  @Output()
  public exportFile = new EventEmitter<void>();
  @BlockUI('pdf-viewer')
  public pdfViewerBlockUI: NgBlockUI;

  public readonly svgIconName = SvgIconName;
  public pdfFile: PDFDocumentProxy;
  public totalPages: number;
  public page = 1;
  public zoom = 1;
  public currentZoomPercent = new FormControl(100);
  public zoomPercents = [
    { value: 50, name: '50%' },
    { value: 100, name: '100%' },
    { value: 150, name: '150%' },
    { value: 200, name: '200%' }
  ];

  public ngOnInit(): void {
    this.currentZoomPercent.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => this.onChangeZoom(value));
  }

  public onLoadComplete(pdfFile: PDFDocumentProxy): void {
    this.pdfFile = pdfFile;
    this.totalPages = pdfFile.numPages;
  }

  public onChangePage(event: any): void {
    const newPage = +event.target.value;

    if (newPage > this.totalPages) {
      return this.setPage(this.totalPages, event);
    }

    this.setPage(newPage, event);
  }

  public onPrevPage(): void {
    if (this.page > 1) {
      this.page -= 1;
    }
  }

  public onNextPage(): void {
    if (this.page < this.totalPages) {
      this.page = +this.page + 1;
    }
  }

  public onChangeZoom(value: number): void {
    this.zoom = this.getZoomValueByPercent(value);
  }

  public onZoomOut(): void {
    if (+this.zoom.toFixed(1) !== 0.5) {
      this.zoom -= this.getZoomValueByPercent(50);
      this.currentZoomPercent.setValue(Number(this.currentZoomPercent.value) - 50);
    }
  }

  public onZoomIn(): void {
    if (this.zoom !== 2) {
      this.zoom += this.getZoomValueByPercent(50);
      this.currentZoomPercent.setValue(Number(this.currentZoomPercent.value) + 50);
    }
  }

  private setPage(page: number, event: any): void {
    if (!Number.isInteger(page) || page === 0) {
      event.target.value = '';
      return;
    }

    this.page = page;
    event.target.value = page;
  }

  private getZoomValueByPercent(amount: number): number {
    return amount / 100;
  }
}
