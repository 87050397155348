import { ContractedBusinessManageAction } from '@qv-bid/components/shared/cb-manage-modal/enums';

import { ContractedBusiness } from 'quantuvis-core-entities';

export class ContractBusinessModalData {
  constructor(
    public bidVersionId: number,
    public cbList: ContractedBusiness[],
    public action: ContractedBusinessManageAction,
    public cb: ContractedBusiness,
    public isBidInternal: boolean,
  ) {}
}
