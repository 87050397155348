import { Injectable } from '@angular/core';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

@Injectable()
export class HtmlEditorService {
  public htmlEditor;

  private readonly contentFormatHtml = 'html';

  public getContentWithTags(): string {
    if (!this.htmlEditor) {
      return '';
    }

    return this.htmlEditor.getContent({ format: this.contentFormatHtml });
  }

  public maxLengthWithTags(length: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors => {
      if (!control.value) {
        return;
      }

      if (this.getContentWithTags().length > length) {
        return { maxlength: true };
      }
    };
  }

  public setMaxHeight(maxHeight: number): void {
    const toolbarHeight =
      this.htmlEditor.editorContainer.clientHeight - this.htmlEditor.contentAreaContainer.clientHeight;
    const bordersHeight = 2;
    const calculatedMaxHeight = `${maxHeight - toolbarHeight - bordersHeight}px`;

    this.htmlEditor.iframeElement.style.setProperty('max-height', calculatedMaxHeight);
  }
}
