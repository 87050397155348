import { DictionaryItem } from '@qv-common/entities';

export enum BindingBidType {
  BINDING,
  NON_BINDING
}

export namespace BindingBidType {
  const valuesMap = new Map<BindingBidType, DictionaryItem>([
    [BindingBidType.BINDING, { id: BindingBidType.BINDING, name: 'Binding' }],
    [BindingBidType.NON_BINDING, { id: BindingBidType.NON_BINDING, name: 'Non Binding' }],
  ]);

  export function getValue(type: BindingBidType): DictionaryItem {
    return valuesMap.get(type);
  }

  export function getValues(): DictionaryItem[] {
    return Array.from(valuesMap.values());
  }
}
