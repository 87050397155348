import { HttpErrorResponse } from '@angular/common/http';
import { BrowserOptions, Event, EventHint } from '@sentry/browser';
import { Scope } from '@sentry/hub';
import { ErrorHandler } from '@angular/core';
import { DefaultErrorHandler } from './default-error-handler';
import { SentryAdapter } from './sentry-adapter';
import { SentryTag } from './enums/sentry-tag';

export class SentryErrorHandler extends DefaultErrorHandler implements ErrorHandler {
  constructor(options: BrowserOptions, protected location: Location) {
    super(location);
    SentryAdapter.init({
      ...options,
      environment: location.host,
      beforeSend: (event: Event, hint: EventHint): Event | null => SentryErrorHandler.handleBeforeSend(event, hint)
    });
  }

  public static handleBeforeSend(event: Event, hint: EventHint): Event | null {
    return hint.captureContext ? event : null;
  }

  public handleError(error: any): void {
    this.detectChunkLoadError(error);

    SentryAdapter.withScope((scope: Scope) => {
      scope.setTag(SentryTag.ERROR_CODE, error.status);

      SentryAdapter.captureException(this.extractError(error), scope);
    });
  }

  private extractError(exception: any): Error {
    let error = exception.originalError || exception;

    if (error instanceof HttpErrorResponse) {
      error = new Error();
      error.name = exception?.error?.message || exception?.error?.reason || exception?.message;
      error.message = `${ exception.status } ${ exception.statusText }`;
    }

    return error;
  }
}
