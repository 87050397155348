import { DictionaryItem } from 'quantuvis-angular-common/interfaces';

export enum CompetitionGroup {
  EMPTY = 133,
  MANUFACTURER,
  PRODUCT
}

export namespace CompetitionGroup {
  const valuesMap = new Map<CompetitionGroup, DictionaryItem>([
    [CompetitionGroup.EMPTY, { id: CompetitionGroup.EMPTY, name: '' }],
    [CompetitionGroup.MANUFACTURER, { id: CompetitionGroup.MANUFACTURER, name: 'Manufacturer' }],
    [CompetitionGroup.PRODUCT, { id: CompetitionGroup.PRODUCT, name: 'Product' }],
  ]);

  export function getValue(competitionGroup: CompetitionGroup): DictionaryItem {
    return valuesMap.get(competitionGroup);
  }

  export function getValues(): DictionaryItem[] {
    return Array.from(valuesMap.values());
  }
}
