import { NgModule } from '@angular/core';
import { PdfViewerModule as PdfViewer } from 'ng2-pdf-viewer';
import { PdfViewerComponent } from './components';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SharedModule } from '@qv-shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { CommonModule } from '@angular/common';
import { BlockUIModule } from 'ng-block-ui';

@NgModule({
  imports: [
    BlockUIModule,
    CommonModule,
    FormsModule,
    MatButtonModule,
    MatCheckboxModule,
    MatIconModule,
    MatTooltipModule,
    NgSelectModule,
    PdfViewer,
    ReactiveFormsModule,
    SharedModule,
  ],
  declarations: [
    PdfViewerComponent
  ],
  exports: [
    PdfViewerComponent
  ]
})

export class PdfViewerModule {}
