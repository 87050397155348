import { constants } from '@qv-common/static';

export class TermUtils {
  public static isAtNdc(value: any): boolean {
    return (value && value.isNdc) || (value === constants.AT_NDC);
  }

  public static isEditable(
    isEditMode: boolean,
    userIsPharma: boolean,
    isLocked: boolean,
    isBidInvalid: boolean = false
  ): boolean {
    return isEditMode && !isBidInvalid && (!userIsPharma || (userIsPharma && !Boolean(isLocked)));
  }
}
