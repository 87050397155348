import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { JsonConvert } from 'json2typescript';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { JSON_CONVERT_TOKEN } from 'quantuvis-angular-common/json-converter/services';

import { HttpResponseBody } from 'quantuvis-angular-common/api';
import { CompanyBase as Company } from '../entities';

@Injectable()
export class CompanyDaoService {
  public readonly resourceUrl = '/api/system-administration/companies';

  constructor(
    private httpClient: HttpClient,
    @Inject(JSON_CONVERT_TOKEN) private jsonConvert: JsonConvert
  ) {}

  public getList(): Observable<Company[]> {
    const params = new HttpParams().set('sort', 'name');

    return this.httpClient.get(this.resourceUrl, { params })
      .pipe(
        map(({ data }: HttpResponseBody<Company[]>) => this.jsonConvert.deserializeArray(data, Company))
      );
  }
}
