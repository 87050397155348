import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DisableConfirmationUserComponent, EnableConfirmationUserComponent } from '@qv-user/components';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    DisableConfirmationUserComponent,
    EnableConfirmationUserComponent,
  ],
  exports: [
    DisableConfirmationUserComponent,
    EnableConfirmationUserComponent,
  ],
})
export class UserModule {}
