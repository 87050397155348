import { Injectable } from '@angular/core';
import { BidEventBusService } from '@qv-bid/services/bid-event-bus.service';
import { ErrorMessage } from 'quantuvis-angular-common/notification';
import { HttpStatusCode } from 'quantuvis-angular-common/api';
import { Observable, of, OperatorFunction, throwError } from 'rxjs';
import { catchError, filter, finalize, map, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { AcceptMessageData, Bid } from '@qv-bid/entities';
import { HttpErrorResponse } from '@angular/common/http';
import { resources } from '@qv-common/static';
import { BlockingMessage } from '@qv-common/enums';
import { BidDetailsNotificationService } from '@qv-bid/services/bid-details-notification.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { BidDaoService } from '@qv-bid/services/dao';
import { BidStateService } from '@qv-bid/services/bid-state.service';
import { MatDialogRef } from '@angular/material/dialog';
import { ReasonInfo } from '@qv-bid/models';
import { BidSendActionService } from '@qv-bid/services/bid-send-action.service';
import { BidUtils } from '@qv-bid/utils';
import { BidDeclineModalConfig } from '@qv-bid/components/shared/bid-decline-modal/models/bid-decline-modal-config';
import { BidDeclineModalData } from '@qv-bid/components/shared/bid-decline-modal/models/bid-decline-modal-data';
import { ViewPerspectiveService } from '@qv-common/services/auth';
import { ScenarioConfigListService, UndoRedoService } from '@qv-bid/services';
import { ErrorNotificationService } from '@qv-common/services';
import { BidVersionService } from '@qv-bid/services/bid-version.service';
import { BidViewHandleErrorService } from '@qv-bid/services/bid-view-handle-error.service';
import {
  GeneralModalComponent,
  GeneralModalConfig,
  GeneralModalData,
  ModalService,
  ModalSize
} from 'quantuvis-angular-common/modal';
import { UserService } from '@qv-common/services/auth/user.service';
import { BidErrorResponseStatusUtil } from '@qv-bid/utils/bid-error-response-status.util';

@Injectable()
export class BidActionsService {
  @BlockUI()
  public blockUI: NgBlockUI;

  constructor(private bidDetailsNotificationService: BidDetailsNotificationService,
              private bidEventBusService: BidEventBusService,
              private bidDaoService: BidDaoService,
              private bidStateService: BidStateService,
              private userService: UserService,
              private bidSendActionService: BidSendActionService,
              private undoRedoService: UndoRedoService,
              private bidViewHandleErrorService: BidViewHandleErrorService,
              private errorNotificationService: ErrorNotificationService,
              private bidVersionService: BidVersionService,
              private viewPerspectiveService: ViewPerspectiveService,
              private scenarioConfigListService: ScenarioConfigListService,
              private modalService: ModalService,
  ) {}

  public editBid(): void {
    const bid = this.bidStateService.bid$.value;

    this.undoRedoService.clearVersions(bid.id);

    if (bid.inReview || this.receivedFinalForPayer(bid)) {
      this.confirmEditBid(BidUtils.getManufacturerCompanyDisplayName(bid), bid.id, bid.isInternal);
    } else {
      this.bidEventBusService.editBidEvent.emit();
    }
  }

  public reviewBid(): void {
    this.blockUI.start(BlockingMessage.LOADING);

    const { id, isInternal } = this.bidStateService.bid$.getValue();

    this.bidDaoService.startReview(id, isInternal).pipe(
      finalize(() => this.blockUI.stop()),
      this.bidViewHandleErrorService.handleLockError(),
    ).subscribe(() => {
      this.bidStateService.isEditMode.next(false);
      this.bidStateService.isReviewMode.next(true);
      this.bidEventBusService.reloadBidEvent.emit();
    });
  }

  public deleteBid(): Observable<null | HttpErrorResponse> {
    const title = resources.POPUPS.TITLES.DELETE_BID;
    const description = resources.BID_DETAILS.CONFIRM_DELETE_BID;

    const deleteConfirmModal = this.createConfirmModal(title, description);

    return deleteConfirmModal.componentInstance.primaryAction.pipe(
      switchMap(() => this.bidStateService.bid$.pipe(take(1))),
      switchMap((bid: Bid) => this.bidDaoService.deleteBid(bid.id, bid.isInternal).pipe(
        catchError((err: HttpErrorResponse) => this.catchDeleteProcessError(err)),
        deleteConfirmModal.componentInstance.handleAfterAction(),
      )),
    );
  }

  public sendCurrentBid(): Observable<null | HttpErrorResponse> {
    return this.bidSendActionService.sendCurrentBid();
  }

  public sendBid(bidId: number, oppositeCompanyName: string,
                 isInternal?: boolean): Observable<null | HttpErrorResponse> {
    return this.bidSendActionService.sendBid(bidId, oppositeCompanyName, isInternal);
  }

  public sendFinalBid(): Observable<null | HttpErrorResponse> {
    return this.bidSendActionService.sendFinalBid();
  }

  public declineBid(): Observable<null | HttpErrorResponse> {
    const bid = this.bidStateService.bid$.getValue();

    const isUserPharmaOrPharmaPerspective =
      BidUtils.isUserPharmaOrPharmaPerspective(
        this.userService.isCurrentUserPharma(),
        bid.isInternal, this.viewPerspectiveService.isPharmaViewPerspective()
      );

    return isUserPharmaOrPharmaPerspective ? this.declineBidByPharma(bid) : this.declineBidByPayer(bid);
  }

  public acceptBid(): Observable<null | HttpErrorResponse> {
    this.blockUI.start(BlockingMessage.LOADING);
    return this.bidStateService.bid$.pipe(
      take(1),
      switchMap((bid: Bid) =>
        this.bidVersionService.getAcceptMessageData(
          this.bidStateService.bidVersionId, bid.isInternal
        )
          .pipe(
            finalize(() => this.blockUI.stop()),
            this.errorNotificationService.catchServerError(ErrorMessage.PROCESSING_ERROR),
            map((acceptMessageData: AcceptMessageData) => [bid, acceptMessageData])
          )
      ),
      switchMap(([bid, acceptMessageData]: [Bid, AcceptMessageData]) => this.acceptBidWithMessageData(
        bid.id, bid.isInternal, BidUtils.getManufacturerCompanyDisplayName(bid), acceptMessageData
      )),
      tap(() => this.bidEventBusService.quitEditModeEvent.emit())
    );
  }

  public discardDraft(): Observable<null | HttpErrorResponse> {
    const title = resources.POPUPS.TITLES.DISCARD_DRAFT;
    const description = resources.BID_DETAILS.CONFIRM_DISCARD_DRAFT;

    const discardConfirmModal = this.createConfirmModal(title, description);

    return discardConfirmModal.componentInstance.primaryAction.pipe(
      switchMap(() => this.bidStateService.bid$.pipe(take(1))),
      switchMap(({ id, isInternal }: Bid) => this.bidDaoService.discardDraft(id, isInternal).pipe(
        tap(() => {
          this.undoRedoService.clearVersions(this.bidStateService.bid$.value.id);
          this.bidEventBusService.reloadBidEvent.emit();
          this.goToEditModeAfterReloadingBid();
        }),
        this.bidViewHandleErrorService.handleLockError(),
        this.closePopupWhenConflict(discardConfirmModal),
        discardConfirmModal.componentInstance.handleAfterAction()
      )),
    );
  }

  public reopenBid(): Observable<null | HttpErrorResponse> {
    const title = resources.POPUPS.TITLES.REOPEN_BID;
    let description = resources.BID_DETAILS.CONFIRM_REOPEN_BID;
    description += `${BidUtils.getManufacturerCompanyDisplayName(this.bidStateService.bid$.getValue())} ?`;
    const reopenConfirmModal = this.createConfirmModal(title, description);

    return reopenConfirmModal.componentInstance.primaryAction.pipe(
      switchMap(() => this.bidStateService.bid$.pipe(take(1))),
      switchMap(({ id, isInternal }: Bid) => this.bidDaoService.reopenBid(id, isInternal).pipe(
        tap(() => this.editBid()),
        this.errorNotificationService.catchServerError(ErrorMessage.PROCESSING_ERROR),
        reopenConfirmModal.componentInstance.handleAfterAction(),
      ))
    );
  }

  public undoBid(bidId: number, isInternal: boolean): void {
    this.blockUI.start(BlockingMessage.UNDO_IN_PROGRESS);
    this.undoRedoService.undoBid(bidId, isInternal).pipe(
      finalize(() => {
        this.scenarioConfigListService.scenarioConfigList.reset();
        this.bidEventBusService.reloadBidEvent.emit();

        this.stopSpinnerAfterReloadingBid();
      })
    ).subscribe();
  }

  public redoBid(bidId: number, isInternal: boolean): void {
    this.blockUI.start(BlockingMessage.REDO_IN_PROGRESS);
    this.undoRedoService.redoBid(bidId, isInternal).pipe(
      finalize(() => {
        this.scenarioConfigListService.scenarioConfigList.reset();
        this.bidEventBusService.reloadBidEvent.emit();

        this.stopSpinnerAfterReloadingBid();
      })
    ).subscribe();
  }

  private stopSpinnerAfterReloadingBid(): void {
    this.bidStateService.waitForBidToBeReloaded().subscribe(() => {
      this.blockUI.stop();
    });
  }

  private goToEditModeAfterReloadingBid(): void {
    this.bidStateService.isBidReloaded$
      .pipe(
        filter(isBidReloaded => isBidReloaded),
        take(1)
      ).subscribe(() => this.bidStateService.isEditMode.next(true));
  }

  private declineBidByPharma(bid: Bid): Observable<null | HttpErrorResponse> {
    const declineBidModal = this.modalService.openModal(new BidDeclineModalConfig(
      new BidDeclineModalData(BidUtils.getSenderCompanyName(bid), bid.isInternal, bid.manufacturerCompanyOldName)));

    return declineBidModal.componentInstance.primaryAction.pipe(
      withLatestFrom(this.bidStateService.bid$),
      switchMap(([reasonInfo, { id, isInternal }]: [ReasonInfo, Bid]) =>
        this.bidDaoService.declineBid(id, isInternal, reasonInfo).pipe(
          tap(() => {
            this.bidEventBusService.quitEditModeEvent.emit();
            this.bidEventBusService.reloadBidEvent.emit();
          }),
          catchError((error: HttpErrorResponse) => {
            this.bidEventBusService.updateBidFailed.emit(error);

            return BidErrorResponseStatusUtil.isShowErrorInNotification(error) ?
              this.errorNotificationService.catchErrorExceptOfStatus(error, HttpStatusCode.FORBIDDEN) :
              throwError(error);
          }),
          this.bidViewHandleErrorService.handleLockError(),
          this.closePopupWhenConflict(declineBidModal),
          declineBidModal.componentInstance.handleAfterAction()
        )
      )
    );
  }

  private declineBidByPayer(bid: Bid): Observable<null | HttpErrorResponse> {
    const declineBidModal = this.modalService.openModal(new BidDeclineModalConfig(
      new BidDeclineModalData(BidUtils.getSenderCompanyName(bid), bid.isInternal, bid.manufacturerCompanyOldName)));

    return declineBidModal.componentInstance.primaryAction.pipe(
      withLatestFrom(this.bidStateService.bid$),
      switchMap(([reasonInfo, { id, isInternal }]: [ReasonInfo, Bid]) =>
        this.bidDaoService.declineBid(id, isInternal, reasonInfo).pipe(
          tap(() => {
            this.bidEventBusService.quitEditModeEvent.emit();
            this.bidEventBusService.reloadBidEvent.emit();
          }),
          catchError((error: HttpErrorResponse) => {
            this.bidEventBusService.updateBidFailed.emit(error);

            return BidErrorResponseStatusUtil.isShowErrorInNotification(error) ?
              this.errorNotificationService
                .catchErrorExceptOfStatus(error, HttpStatusCode.FORBIDDEN, ErrorMessage.PROCESSING_ERROR) :
              throwError(error);
          }),
          declineBidModal.componentInstance.handleAfterAction()
        )
      )
    );
  }

  private acceptBidWithMessageData(
    bidId: number, isInternalBid: boolean, companyName: string, acceptMessageData: AcceptMessageData
  ): Observable<null | HttpErrorResponse> {
    const title = resources.POPUPS.TITLES.ACCEPT_BID;
    const description = this.createAcceptBidMessage(companyName, acceptMessageData);
    const acceptConfirmModal = this.createConfirmModal(title, description);

    return acceptConfirmModal.componentInstance.primaryAction.pipe(
      switchMap(() => this.bidDaoService.acceptBid(bidId, isInternalBid).pipe(
        catchError((error: HttpErrorResponse) => {
          this.bidEventBusService.updateBidFailed.emit(error);
          return BidErrorResponseStatusUtil.isShowErrorInNotification(error) ?
            this.errorNotificationService
              .catchErrorExceptOfStatus(error, HttpStatusCode.FORBIDDEN, ErrorMessage.PROCESSING_ERROR) :
            throwError(error);
        }),
        acceptConfirmModal.componentInstance.handleAfterAction()
      ))
    );
  }

  private createAcceptBidMessage(companyName: string, acceptMessageData: AcceptMessageData): string {
    return `Do you want to Accept this Bid from ${companyName} for formulary evaluation?` +
      '<ul class="qv-list qv-list--margin-left-16 qv-list--margin-top-16">' +
      `<li>${acceptMessageData.dismissedScenarios} of ${acceptMessageData.totalScenarios} scenarios “Dismissed”</li>` +
      `<li>${acceptMessageData.acceptedScenarios} of ${acceptMessageData.totalScenarios} scenarios “Accepted”</li>` +
      `<li>${acceptMessageData.openScenarios} of ${acceptMessageData.totalScenarios} scenarios “Open”</li></ul>`;
  }

  private confirmEditBid(companyName: string, bidId: number, isBidInternal: boolean): void {
    const title = resources.POPUPS.TITLES.EDIT_BID;
    const description = resources.BID_DETAILS.CONFIRM_EDIT_BID;
    const descriptionWithCompanyName = `${description} ${companyName} ?`;

    const confirmEditBidModal = this.createConfirmModal(title, descriptionWithCompanyName);

    confirmEditBidModal.componentInstance.primaryAction.pipe(
      switchMap(() => this.bidDaoService.abortReview(bidId, isBidInternal).pipe(
        tap(() => this.bidEventBusService.editBidEvent.emit()),
        this.bidViewHandleErrorService.handleLockError(),
        this.closePopupWhenConflict(confirmEditBidModal),
        confirmEditBidModal.componentInstance.handleAfterAction()
      ))
    ).subscribe();
  }

  private createConfirmModal(title: string, description: string): MatDialogRef<GeneralModalComponent> {
    const modalData = new GeneralModalData(
      title,
      description,
      resources.Actions.YES,
      resources.Actions.NO,
      false
    );
    const modalConfig = new GeneralModalConfig(modalData, ModalSize.X_SMALL);

    return this.modalService.openModal(modalConfig);
  }

  private catchDeleteProcessError(err: HttpErrorResponse): Observable<HttpErrorResponse> {
    this.bidDetailsNotificationService.showDeleteProcessingError(err);

    if (HttpStatusCode.CONFLICT === err.status) {
      this.bidEventBusService.reloadBidEvent.emit();
      return throwError(err);
    }

    if (HttpStatusCode.NOT_FOUND === err.status) {
      return of(err);
    }

    return throwError(err);
  }

  private closePopupWhenConflict<T>(modal: MatDialogRef<GeneralModalComponent>): OperatorFunction<T, T> {
    return catchError((error: HttpErrorResponse) => {
      if (error.status === HttpStatusCode.CONFLICT) {
        modal.componentInstance.primaryActionFinished();
      }

      return throwError(error);
    });
  }

  private receivedFinalForPayer(bid: Bid): boolean {
    return BidUtils.isUserPayerOrPayerPerspective(this.userService.isCurrentUserPayer(), bid.isInternal,
      this.viewPerspectiveService.isPayerViewPerspective()) && bid.isFinal && bid.hasAllowedStatusToBeFinal();
  }
}
