import { Inject, Injectable } from '@angular/core';
import { ApiService } from '@qv-common/services/api';
import { JsonConvert } from 'json2typescript';
import { Observable } from 'rxjs';
import { ApiUrlPrefix } from '@qv-common/enums';
import { map } from 'rxjs/operators';
import { HttpResponseWrapper } from '@qv-common/models/api';
import { JSON_CONVERT_TOKEN } from 'quantuvis-angular-common/json-converter/services';
import { Enrollment } from 'quantuvis-core-entities';

@Injectable({
  providedIn: 'root'
})
export class EnrollmentDaoService {

  constructor(private apiService: ApiService, @Inject(JSON_CONVERT_TOKEN) private jsonConvert: JsonConvert) {}

  public getList(): Observable<Enrollment[]> {
    const url = `${ApiUrlPrefix.SYSTEM_ADMINISTRATION}/applications/enrollments`;

    return this.apiService.get(url).pipe(map((response: HttpResponseWrapper<Enrollment[]>) =>
      this.jsonConvert.deserializeArray(response.body.data, Enrollment)
    ));
  }
}
