import { DictionaryItem } from '@qv-common/entities';

export enum NoFaultTerminationClauseWhoMayInvoke {
  EMPTY = 1,
  NEITHER_PARTY = 2,
  PAYER = 3,
  PHARMA = 4,
  BOTH = 5
}

export namespace NoFaultTerminationClauseWhoMayInvoke {
  const valuesMap = new Map<NoFaultTerminationClauseWhoMayInvoke, DictionaryItem>([
    [NoFaultTerminationClauseWhoMayInvoke.EMPTY, { id: NoFaultTerminationClauseWhoMayInvoke.EMPTY, name: '' }],
    [
      NoFaultTerminationClauseWhoMayInvoke.NEITHER_PARTY,
      { id: NoFaultTerminationClauseWhoMayInvoke.NEITHER_PARTY, name: 'Neither Party' }
    ],
    [
      NoFaultTerminationClauseWhoMayInvoke.PAYER,
      { id: NoFaultTerminationClauseWhoMayInvoke.PAYER, name: 'Payer' }
    ],
    [
      NoFaultTerminationClauseWhoMayInvoke.PHARMA,
      { id: NoFaultTerminationClauseWhoMayInvoke.PHARMA, name: 'Pharma' }
    ],
    [
      NoFaultTerminationClauseWhoMayInvoke.BOTH,
      { id: NoFaultTerminationClauseWhoMayInvoke.BOTH, name: 'Both' }
    ],
  ]);

  export function getValue(invoke: NoFaultTerminationClauseWhoMayInvoke): DictionaryItem {
    return valuesMap.get(invoke);
  }

  export function getValues(): DictionaryItem[] {
    return Array.from(valuesMap.values());
  }
}
