import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { ModalConfig } from '../../../models/modal-config';
import { TemplateModalData } from '../../../models/data/template-modal-data';
import { TemplateModalComponent } from '../template-modal.component';
import { ModalSize } from '../../../enums/modal-size';

export class TemplateModalConfig
  extends ModalConfig<TemplateModalComponent, TemplateModalData> {
  public readonly disableClose: boolean;

  constructor(data: TemplateModalData, width: ModalSize, disableClose: boolean) {
    super(data, width);
    this.disableClose = disableClose;
  }

  protected createDialogRef(dialog: MatDialog): MatDialogRef<TemplateModalComponent> {
    return dialog.open(TemplateModalComponent, this);
  }
}
