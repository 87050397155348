import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { NumberUtils, ValidateNumberOptions } from 'quantuvis-angular-common/number-utils';

// @dynamic
export class ValidationUtils {
  public static readonly defaultNumberOptions = new ValidateNumberOptions(5, 0, 100, 0.0001, 2);

  public static isBlankString(value: string): boolean {
    return new RegExp('^\\s*$').test(value);
  }

  public static notBlankString(control: AbstractControl): ValidationErrors | null {
    return !ValidationUtils.isBlankString(control.value) ? null : {
      notBlankString: true
    };
  }

  public static trimmedMaxLength(maxLength: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      return control.value.trim().length <= maxLength ? null : {
        maxLength: true
      };
    };
  }

  public static getValidationErrorMessages(
    errors: ValidationErrors,
    validationMessages: Map<string, string>
  ): string[] {
    return errors ? Object.keys(errors).map((key: string) => validationMessages.get(key)) : [];
  }

  public static concatValidationErrorMessages(
    errors: ValidationErrors,
    validationMessages: Map<string, string>,
    separator = '\n'
  ): string {
    return this.getValidationErrorMessages(errors, validationMessages).join(separator);
  }

  public static requiredWhenSiblingHasValueValidator(siblingControlKey: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.parent) {
        return null;
      }

      const controls = control.parent.controls as AbstractControl[];

      return !Boolean(control.value) && Boolean(controls[siblingControlKey]?.value) ? { required: true } : null;
    };
  }

  public static restoreLastNotEmptyValueValidator(): ValidatorFn {
    let prevValue;

    return (control: AbstractControl): null => {
      if (control.value === '' || control.value === null) {
        control.setValue(prevValue, { onlySelf: true, emitEvent: false });
      }

      prevValue = control.value;

      return null;
    };
  }

  public static spliceStringToNeededLength(maxLength: number): ValidatorFn {
    let prevValue;

    return (control: AbstractControl): null => {
      if (control.value === prevValue) {
        return null;
      }

      if (control.value === null || control.value === '') {
        return control.value;
      }

      const correctedString = control.value.slice(0, maxLength);
      prevValue = correctedString;
      control.setValue(correctedString, { onlySelf: true, emitEvent: false });

      return null;
    };
  }

  public static correctNumberValidator(options = this.defaultNumberOptions): ValidatorFn {
    let prevValue;

    return (control: AbstractControl): null => {
      if (control.value === prevValue) {
        return null;
      }

      const correctedNumber = NumberUtils.correctNumber(options, control.value, prevValue);

      prevValue = correctedNumber;

      control.setValue(correctedNumber, { onlySelf: true, emitEvent: false });

      return null;
    };
  }
}
