<div [matTooltip]="getTooltipMessage(isEmptyValue())" class="term-notes">
  <button (click)="onOpenModal($any($event))"
          [class.qv-button--at-ndc]="isAtNdcValue(termValue.isAtNdcCurrent, termValue.isAtNdcHistoric)"
          [disabled]="!isEnabled(isEmptyValue(), isEditMode, isUserPharma, isLocked, isPending)"
          [ngClass]="isEmptyValue() ? 'qv-button-icon--empty' : 'qv-button-icon--full'"
          class="qv-button-icon qv-button-icon--center"
          mat-icon-button>
    <mat-icon
        *ngIf="!isAtNdcValue(termValue.isAtNdcCurrent, termValue.isAtNdcHistoric)"
        [svgIcon]="svgIconName?.NOTES">
    </mat-icon>
    <span *ngIf="isAtNdcValue(termValue.isAtNdcCurrent, termValue.isAtNdcHistoric)" class="at-ndc">{{ AT_NDC }}</span>
  </button>
</div>
