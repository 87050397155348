<div class="section-toolbar">
  <div class="section-toolbar__title">
    <h2>Overall RFP Instructions</h2>
    <mat-icon
        [matTooltip]="resources.TermsDescriptions.SUMMARY.INSTRUCTIONS"
        [svgIcon]="svgIconName.INFO_OUTLINE"
        class="section-info">
    </mat-icon>
  </div>
</div>

<ng-container *ngIf="instructionsControl?.value || isPayerInEditMode()">
  <qv-html-editor
      [control]="instructionsControl"
      [initialValue]="summary.instructions"
      [isDisabled]="isBidInvalid"
      [isEditMode]="isPayerInEditMode()"
      [qvReadMoreEnabled]="!isPayerInEditMode()"
      class="term-content qv-html-editor"
      data-test-id="test-id-instructions"
      qvReadMore>
  </qv-html-editor>
  <acc-form-field-errors
      [control]="instructionsControl"
      [validationMessages]="validationMessages"
      class="instructions-error">
  </acc-form-field-errors>
  <button (click)="openInstructionsModal()"
          [disabled]="isInstructionsOpenModalDisabled(isBidInvalid, instructionsControl.invalid)"
          class="qv-read qv-read--external">
    <mat-icon [svgIcon]="svgIconName.EXTERNAL_LINK"></mat-icon>
    <span>Open in window</span>
  </button>
</ng-container>

<p *ngIf="!instructionsControl?.value && !isPayerInEditMode()"
   class="qv-sidebar-section__no-data">{{ commonMessage.NO_DATA }}</p>
