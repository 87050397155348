import { Type } from '@angular/core';

import { BaseInsideModal } from '../base/base-inside-modal';
import { ModalSize } from '../../enums/modal-size';

export class TemplateModalData {
  constructor(
    public readonly title: string,
    public readonly component: Type<BaseInsideModal>,
    public readonly componentPayload?: any,
    public readonly primaryActionText = 'Close',
    public readonly secondaryActionText?: string,
    public readonly size: ModalSize = ModalSize.LARGE,
    public readonly disableClose?: boolean,
  ) {}
}
