import { FilterOptionName } from '@qv-bid/enums/filter-option-name.enum';
import { TermSection } from '@qv-term/enums';

export enum BidFilterName {
  drugName = 'drugNames',
  drugStatus = 'drugStatuses',
  contractedBusiness = 'contractedBusinesses',

  // Rebate
  minBaseRebate = 'minBaseRebateRange',
  baseRebate = 'baseRebateRange',
  tierPlacement = 'tierPlacements',
  tierCompetition = 'tierCompetitions',
  competitorTier = 'competitorTiers',
  competitionGroup = 'competitionGroups',

  // UM
  priorAuthorizationAllowed = 'priorAuthorizationAllowed',
  stepTherapyAllowed = 'stepTherapyAllowed',
  priorAuthorizationRequired = 'priorAuthorizationRequired',
  stepTherapyRequired = 'stepTherapyRequired',
  quantityLimit = 'quantityLimits',
  otherUm = 'otherUms',

  // Scenario terms
  scenarioStartDate = 'scenarioStartDateRange',
  scenarioEndDate = 'scenarioEndDateRange',
  adminFee = 'adminFeeRange',

  // Price Protection
  netEffectivePrice = 'netEffectivePriceRange',
  index = 'indexes',
  threshold = 'thresholdRange',
  reset = 'resets',
  baselineStartDate = 'baselineStartDateRange',
  newPriceEffectiveDate = 'newPriceEffectiveDates',

  // Market Share
  marketShareTier = 'marketShareTiers',
}


export namespace BidFilterName {
  export function hasValue(value: any): boolean {
    return Object.values(BidFilterName).includes(value);
  }

  export function getCheckboxFilterNames(): BidFilterName[] {
    return [
      BidFilterName.drugName,
      BidFilterName.drugStatus,
      BidFilterName.contractedBusiness,
      BidFilterName.tierPlacement,
      BidFilterName.tierCompetition,
      BidFilterName.competitorTier,
      BidFilterName.competitionGroup,
      BidFilterName.priorAuthorizationAllowed,
      BidFilterName.stepTherapyAllowed,
      BidFilterName.priorAuthorizationRequired,
      BidFilterName.stepTherapyRequired,
      BidFilterName.quantityLimit,
      BidFilterName.otherUm,
      BidFilterName.index,
      BidFilterName.reset,
      BidFilterName.newPriceEffectiveDate,
      BidFilterName.marketShareTier,
    ];
  }

  export function getRangeFilterNames(): BidFilterName[] {
    return [
      BidFilterName.minBaseRebate,
      BidFilterName.baseRebate,
      BidFilterName.adminFee,
      BidFilterName.netEffectivePrice,
      BidFilterName.threshold
    ];
  }

  export function getDateRangeFilterNames(): BidFilterName[] {
    return [
      BidFilterName.scenarioStartDate,
      BidFilterName.scenarioEndDate,
      BidFilterName.baselineStartDate
    ];
  }

  export function getSectionFilterNames(section: TermSection): BidFilterName[] {
    switch (section) {
      case TermSection.REBATE:
        return [
          BidFilterName.minBaseRebate, BidFilterName.baseRebate, BidFilterName.tierPlacement,
          BidFilterName.tierCompetition, BidFilterName.competitorTier, BidFilterName.competitionGroup
        ];
      case TermSection.UM:
        return [
          BidFilterName.priorAuthorizationAllowed, BidFilterName.stepTherapyAllowed,
          BidFilterName.priorAuthorizationRequired, BidFilterName.stepTherapyRequired,
          BidFilterName.quantityLimit, BidFilterName.otherUm
        ];
      case TermSection.SCENARIO:
        return [BidFilterName.scenarioStartDate, BidFilterName.scenarioEndDate, BidFilterName.adminFee];
      case TermSection.PRICE_PROTECTION:
        return [
          BidFilterName.netEffectivePrice, BidFilterName.index, BidFilterName.threshold, BidFilterName.reset,
          BidFilterName.baselineStartDate, BidFilterName.newPriceEffectiveDate
        ];
      case TermSection.MARKET_SHARE:
        return [BidFilterName.marketShareTier];
    }
  }

  // TODO: Remove when https://quantuvis.atlassian.net/browse/ALLI-2507 will be done
  export function getOptionName(name: BidFilterName): FilterOptionName {
    switch (name) {
      case BidFilterName.drugName:
        return FilterOptionName.DRUG_NAME;
      case BidFilterName.drugStatus:
        return FilterOptionName.DRUG_STATUS;
      case BidFilterName.contractedBusiness:
        return FilterOptionName.CONTRACTED_BUSINESS;

      // Rebate
      case BidFilterName.tierPlacement:
        return FilterOptionName.TIER_PLACEMENT;
      case BidFilterName.tierCompetition:
        return FilterOptionName.TIER_COMPETITION;
      case BidFilterName.competitorTier:
        return FilterOptionName.TIER_COMPETITOR;
      case BidFilterName.competitionGroup:
        return FilterOptionName.COMPETITION_GROUP;

      // UM
      case BidFilterName.priorAuthorizationAllowed:
        return FilterOptionName.ALLOWED_PRIOR_AUTHORIZATION;
      case BidFilterName.stepTherapyAllowed:
        return FilterOptionName.ALLOWED_STEP_THERAPY;
      case BidFilterName.priorAuthorizationRequired:
        return FilterOptionName.REQUIRED_PRIOR_AUTHORIZATION;
      case BidFilterName.stepTherapyRequired:
        return FilterOptionName.REQUIRED_STEP_THERAPY;
      case BidFilterName.quantityLimit:
        return FilterOptionName.QUANTITY_LIMIT;
      case BidFilterName.otherUm:
        return FilterOptionName.OTHER_UM;

      // Price Protection
      case BidFilterName.reset:
        return FilterOptionName.RESET;
      case BidFilterName.index:
        return FilterOptionName.INDEX;
      case BidFilterName.newPriceEffectiveDate:
        return FilterOptionName.PRICE_EFFECTIVE_DATE;

      // Market Share
      case BidFilterName.marketShareTier:
        return FilterOptionName.MARKET_SHARE_TIER;
    }
  }
}
