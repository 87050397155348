import { Injectable } from '@angular/core';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { environment } from '@qv-env/environment';
import { GoogleAnalyticsService } from 'ngx-google-analytics';

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsTrackingService {

  private prevUrl = '';

  constructor(private googleAnalyticsService: GoogleAnalyticsService, private router: Router) {}

  public init(): void {
    if (environment.production) {
      this.initNavigationHandler();
    }
  }

  private initNavigationHandler(): void {
    this.router.events.subscribe((event: RouterEvent) => {
      if (event instanceof NavigationEnd) {
        this.googleAnalyticsService.pageView(event.urlAfterRedirects, undefined, document.location.href);
        this.googleAnalyticsService.event('pageChange', event.urlAfterRedirects, this.prevUrl);
        this.prevUrl = event.urlAfterRedirects;
      }
    });
  }
}
