import { RouterModule, Routes } from '@angular/router';
import { LegalAttestationGuard } from '@qv-company/guards/legal-attestation.guard';
import { LegalAttestationComponent } from '@qv-company/components/legal-attestation/legal-attestation.component';
import { NgModule } from '@angular/core';

export const routes: Routes = [
  {
    path: 'legalAttestation',
    component: LegalAttestationComponent,
    canActivate: [LegalAttestationGuard],
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
  ]
})
export class CompanyRoutingModule {}
