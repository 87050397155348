<ng-container *ngIf="(isFormReady$ | async) || !isEditMode && entity">
  <div class="section-toolbar">
    <div class="section-toolbar__title">
      <h2>Administration Fee</h2>
    </div>
    <qv-lock
        [control]="$any(formGroup?.get(termName.getTermNameLock(sectionName)))"
        [isDisabled]="isBidInvalid"
        [isEditMode]="isEditMode"
        [locked]="entity?.administrationFeeLock"
        data-test-id="test-id-contract-administration-fee-lock"
    ></qv-lock>
  </div>

  <section class="table-section table-section--two-equal-columns">
    <qv-table-section-row
        [termName]="termName.ADMINISTRATION_FEE"
        class="table-section__row table-section__row--highlighted"
        data-test-id="test-id-contract-administration-fee">
      <qv-term-input
          [control]="$any(formGroup?.get(termName.ADMINISTRATION_FEE))"
          [isEditMode]="isEditMode"
          [isLocked]="entity?.administrationFeeLock"
          [termName]="termName.ADMINISTRATION_FEE"
          [termValue]="entity?.administrationFee">
      </qv-term-input>
    </qv-table-section-row>
  </section>
</ng-container>
