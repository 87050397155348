import { Directive, ElementRef, Injector } from '@angular/core';
import { ConfirmNavigationUpgrade } from '@qv-ng1-wrapper/classes';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'qv-edit-user-wrapper'
})
export class EditUserWrapperDirective extends ConfirmNavigationUpgrade {
  constructor(elementRef: ElementRef, injector: Injector) {
    super('editUserComponent', elementRef, injector);
  }
}
