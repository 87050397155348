export class DrugMeta {
  public fullName: string;

  constructor(
    public name: string,
    public drugClass: string,
    public pharmaNames: Map<string, string>,
    public isSelected = false
  ) {
    this.setFullName();
  }

  private setFullName(): void {
    const manufacturerTuple = Array.from(this.pharmaNames.values())
      .map((pharmaName: string) => `(${pharmaName})`)
      .join(' ');

    this.fullName = `${this.name} ${manufacturerTuple}`;
  }
}
