import { MarketBasketDrugType } from './market-basket-drug-type.enum';

export enum DrugTypeIcon {
  ANCHOR = 'anchor',
  VERTICAL_ALIGN_BOTTOM = 'vertical_align_bottom',
  BAR_CHART = 'bar_chart',
}

export namespace DrugTypeIcon {
  const drugTypeIcons: Map<MarketBasketDrugType, DrugTypeIcon> = new Map([
    [MarketBasketDrugType.DISADVANTAGE, DrugTypeIcon.VERTICAL_ALIGN_BOTTOM],
    [MarketBasketDrugType.ANCHOR, DrugTypeIcon.ANCHOR],
    [MarketBasketDrugType.REPORTING, DrugTypeIcon.BAR_CHART],
  ]);

  const drugTypeColors: Map<MarketBasketDrugType, string> = new Map([
    [MarketBasketDrugType.DISADVANTAGE, '#51617c'],
    [MarketBasketDrugType.ANCHOR, '#188b97'],
    [MarketBasketDrugType.REPORTING, '#632D91'],
  ]);

  export const getIconForType = (type: MarketBasketDrugType): DrugTypeIcon => {
    return drugTypeIcons.get(type);
  };

  export const getIconColorForType = (type: MarketBasketDrugType): string => {
    return drugTypeColors.get(type);
  };
}
