import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Scenario } from '@qv-bid/entities';
import { BidStateService, ScenarioNameFormService } from '@qv-bid/services';
import { Position } from '@qv-common/enums';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { QvCache } from '@qv-common/decorators';
import { debounceTime, filter } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'qv-scenario-name',
  templateUrl: './scenario-name.component.html',
  styleUrls: ['./scenario-name.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScenarioNameComponent implements OnInit {
  @Input()
  public scenario: Scenario;
  @Input()
  public name: string;
  @Input()
  public errorPosition = Position.BOTTOM;
  @ViewChild('nameInput')
  public nameInput: ElementRef<HTMLInputElement>;

  public readonly position = Position;

  constructor(public scenarioNameFormService: ScenarioNameFormService,
              public bidStateService: BidStateService,
              private changeDetectorRef: ChangeDetectorRef) {
  }

  public ngOnInit(): void {
    const formControl = this.scenarioNameFormService.getFormControl(this.scenario);
    formControl.valueChanges.pipe(untilDestroyed(this))
      .subscribe(() => this.changeDetectorRef.markForCheck());
    this.initEditModeForCurrentScenarioAndFocusInputHandler();
    this.initExtraValidationHandler(formControl);
  }

  @QvCache()
  public isEditableScenarioNumber(isEditMode: boolean, selectScenarioNumber: number): boolean {
    return isEditMode && Boolean(selectScenarioNumber);
  }

  @QvCache()
  public getErrorMessagePositionClass(errorPosition: Position): string {
    return `scenario-title__error-message--${errorPosition}`;
  }

  private initEditModeForCurrentScenarioAndFocusInputHandler(): void {
    this.scenarioNameFormService.editModeForNumber$.pipe(
      debounceTime(0),
      filter((focusedScenarioNumber: number) => focusedScenarioNumber === this.scenario.id),
      untilDestroyed(this),
    ).subscribe(() => this.nameInput.nativeElement.focus());
  }

  private initExtraValidationHandler(formControl: FormControl): void {
    this.scenarioNameFormService.scenariosFormGroup.valueChanges.pipe(
      filter(() => this.scenarioNameFormService.getFormControl(this.scenario).invalid),
      untilDestroyed(this)
    ).subscribe(() => {
      const opts = { onlySelf: true, emitEvent: false };
      formControl.updateValueAndValidity(opts);
      this.scenarioNameFormService.scenariosFormGroup.updateValueAndValidity(opts);
      this.changeDetectorRef.markForCheck();
    });
  }
}
