import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild, } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { SvgIconName } from '@qv-common/enums';
import { CheckboxItem } from '@qv-common/models';
import { resources } from '@qv-common/static';
import { BaseFilterItem } from '@qv-shared/classes';
import { QvCache } from '@qv-common/decorators';

@Component({
  selector: 'qv-filter-menu',
  templateUrl: './filter-menu.component.html',
  styleUrls: ['./filter-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilterMenuComponent extends BaseFilterItem {
  @Input()
  public items: CheckboxItem[] = [];
  @Input()
  public isFilterChanged: boolean;
  @Input()
  public isFilterApplied: boolean;
  @Input()
  public isFilterInvalid: boolean;
  @Input()
  public menuClass = '';

  @Output()
  public apply = new EventEmitter<CheckboxItem[]>();
  @Output()
  public contentOpened = new EventEmitter();
  @Output()
  public contentClosed = new EventEmitter();

  @ViewChild(MatMenuTrigger, { static: true })
  public trigger: MatMenuTrigger;

  public readonly resources = resources;
  public readonly svgIconName = SvgIconName;

  public onApply(): void {
    this.apply.emit(this.items);
    this.trigger.closeMenu();
  }

  @QvCache()
  public isApplyButtonDisabled(isFilterChanged: boolean, isFilterInvalid: boolean): boolean {
    return !isFilterChanged || isFilterInvalid;
  }
}
