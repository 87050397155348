import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('RangeOrUnitAndRebate')
export class RangeOrUnitAndRebate {
  @JsonProperty('id', Number)
  public id: number = null;

  @JsonProperty('rangeStart', Number)
  public rangeStart: number = null;

  @JsonProperty('rangeEnd', Number)
  public rangeEnd: number = null;

  @JsonProperty('rebate', Number)
  public rebate: number = null;

  constructor(rangeStart?: number, rangeEnd?: number, rebate?: number, id?: number) {
    this.id = id;
    this.rangeStart = rangeStart;
    this.rangeEnd = rangeEnd;
    this.rebate = rebate;
  }
}
