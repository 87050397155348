import { animate, state, style, transition, trigger } from '@angular/animations';
import { RowState } from '@qv-common/enums';

export const collapseExpandAnimation = trigger('collapseExpand', [
  state(RowState.EXPANDED, style({
    opacity: 1,
    height: '*',
  })),
  state(RowState.COLLAPSED, style({
    opacity: 0,
    height: '0',
    display: 'none',
  })),
  transition(`${RowState.EXPANDED} => ${RowState.COLLAPSED}`, [
    animate('0.53s ease-in')
  ]),
  transition(`${RowState.COLLAPSED} => ${RowState.EXPANDED}`, [
    style({ display: 'block' }),
    animate('0.5s ease-out')
  ])
]);

export const expandAnimation = trigger('expand', [
  state(RowState.EXPANDED, style({
    opacity: 1,
    height: '*',
  })),
  state(RowState.COLLAPSED, style({
    opacity: 0,
    height: '0',
    display: 'none',
  })),
  transition(`${RowState.COLLAPSED} => ${RowState.EXPANDED}`, [
    style({ display: 'block' }),
    animate('0.5s ease-out')
  ])
]);
