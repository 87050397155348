import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';

import { SnackBarComponent } from './components/snack-bar/snack-bar.component';
import { SnackBarService } from './services/snack-bar.service';

@NgModule({
  declarations: [SnackBarComponent],
  imports: [MatSnackBarModule, CommonModule],
  providers: [SnackBarService],
})
export class SnackBarModule {}
