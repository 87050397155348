import { Component, Input, OnInit } from '@angular/core';
import { SvgIconName } from '@qv-common/enums';
import { TermTemplateStorageService } from '@qv-term/services';

@Component({
  selector: 'qv-table-section-row',
  templateUrl: './table-section-row.component.html',
  styleUrls: ['./table-section-row.component.scss']
})
export class TableSectionRowComponent implements OnInit {

  @Input()
  public termName: string;

  public termConfig;

  public readonly svgIconName = SvgIconName;

  constructor(protected termTemplateStorage: TermTemplateStorageService) {}

  public ngOnInit(): void {
    this.loadTermTemplate();
  }

  private loadTermTemplate(): void {
    this.termConfig = this.termTemplateStorage.getTermTemplate(this.termName);
  }
}
