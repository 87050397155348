import { PharmaRight } from '@qv-bid/entities';
import { BidRight } from '@qv-bid/enums';

export class PharmaRightsUtil {
  public static getRight(rightsList: PharmaRight[], key: BidRight): PharmaRight {
    return rightsList.find((pharmaRight: PharmaRight) => pharmaRight.right === key);
  }

  public static isEnabled(rightsList: PharmaRight[], key: BidRight): boolean {
    const right = PharmaRightsUtil.getRight(rightsList, key);

    return right && right.isActive;
  }
}
