<div [class.pdf-viewer--full-page]="fullPage"
     class="pdf-viewer">
  <header *ngIf="pdfFile" class="pdf-viewer__header">
    <div class="pdf-viewer__nav">
      <span class="pdf-viewer__nav-title">{{ filePrefix }}</span>
      <span (click)="exportFile.next()" class="pdf-viewer__nav-file-name">{{ file?.name }}</span>
    </div>
    <div class="pdf-viewer__nav pdf-viewer__nav-pagination">
      <span class="pdf-viewer__pages-prefix">Page</span>
      <button (click)="onPrevPage()" class="pdf-viewer__button pdf-viewer__button--nav-left" mat-button>
        <mat-icon
            [svgIcon]="svgIconName.ARROW_LEFT"
            class="qv-icon qv-icon--24"
        ></mat-icon>
      </button>
      <input #pageInput
             (click)="pageInput.select()"
             (input)="onChangePage($event)"
             [ngModel]="page"
             class="pdf-viewer__input qv-input"
             matInput
             type="text">
      <span class="pdf-viewer__total-pages">of {{ totalPages }}</span>
      <button (click)="onNextPage()" class="pdf-viewer__button pdf-viewer__button--nav-right" mat-button>
        <mat-icon
            [svgIcon]="svgIconName.ARROW_RIGHT"
            class="qv-icon qv-icon--24"
        ></mat-icon>
      </button>
      <span class="pdf-viewer__zoom-prefix">Zoom</span>
      <button (click)="onZoomOut()" class="pdf-viewer__button pdf-viewer__button--zoom-out" mat-button>
        <mat-icon
            [svgIcon]="svgIconName.ZOOM_OUT"
            class="qv-icon qv-icon--20"
        ></mat-icon>
      </button>
      <ng-select [clearable]="false"
                 [formControl]="currentZoomPercent"
                 [items]="zoomPercents"
                 [searchable]="false"
                 bindLabel="name"
                 bindValue="value"
                 class="qv-select pdf-viewer__form-field--zoom">
      </ng-select>
      <button (click)="onZoomIn()" class="pdf-viewer__button pdf-viewer__button--zoom-in" mat-button>
        <mat-icon
            [svgIcon]="svgIconName.ZOOM_IN"
            class="qv-icon qv-icon--20"
        ></mat-icon>
      </button>
    </div>
  </header>
  <pdf-viewer
      (after-load-complete)="onLoadComplete($event)"
      (on-progress)="pdfViewerBlockUI.start()"
      (page-rendered)="pdfViewerBlockUI.stop()"
      *blockUI="'pdf-viewer'"
      [page]="page"
      [render-text]="false"
      [show-all]="false"
      [src]="file?.content"
      [zoom]="zoom">
  </pdf-viewer>
</div>
