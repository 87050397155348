import { NgModule } from '@angular/core';

import CreateInternalBidComponent from './create-internal-bid.component';
import SummaryComponent from './summary/summary.component';
import ContractComponent from '../shared/contract/contract.component';
import { CreateInternalBidService } from './create-internal-bid.service';
import ContractedBusinessComponent from './contracted-business/contracted-business.component';
import InternalPayerPickerComponent from './internal-payer-picker/internal-payer-picker.component';
import ManufacturerDrugsPickerComponent from './manufacturer-drugs-picker/manufacturer-drugs-picker.component';
import DrugsLevelComponent from './drug-level/drug-level.component';

declare let angular: angular.IAngularStatic;

export const CreateInternalBidNgModule = angular
  .module('quantuvis.createInternalBid', [])
  .component('createInternalBid', CreateInternalBidComponent)
  .component('summaryInternal', SummaryComponent)
  .component('contractInternal', ContractComponent)
  .component('contractedBusiness', ContractedBusinessComponent)
  .component('internalPayerPicker', InternalPayerPickerComponent)
  .component('manufacturerDrugsPicker', ManufacturerDrugsPickerComponent)
  .component('drugsLevel', DrugsLevelComponent)
  .service('CreateInternalBidService', CreateInternalBidService);

@NgModule({})
export class CreateInternalBidModule {
}
