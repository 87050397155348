import he from 'he';

export const QvRemoveScripts = ['$sanitize', ($sanitize): any => {
  'ngInject';
  return {
    restrict: 'A',
    require: 'ngModel',
    link: (scope, elem, attrs, ngModel) => {
      if (!ngModel) return;

      const removeScripts = (value) => {
        return he.decode($sanitize(value));
      };

      ngModel.$parsers.push(removeScripts);
    }
  };
}];
