<div *blockUI="'cb-manage-modal'" class="cb-manage-modal">
  <form (ngSubmit)="onSubmit()" [formGroup]="manageCBForm">
    <header class="qv-modal-dialog-title" mat-dialog-title>
      <div class="qv-modal-dialog-title__text">{{title}}</div>
      <button [mat-dialog-close] class="qv-modal-dialog-title__xbutton">
        <mat-icon>close</mat-icon>
      </button>
    </header>
    <div class="qv-modal-dialog-content qv-modal-dialog-content--primary-padding" mat-dialog-content>
      <div class="qv-modal-dialog-content__label">
        <span class="qv-modal-dialog-content__label-text qv-modal-dialog-content__label-text--required">Name</span>
        <span class="qv-modal-dialog-content__label-hint">The name is limited to 55 characters</span>
      </div>
      <input
          (blur)="onBlur()"
          [autofocus]="true"
          [maxLength]="NAME_MAX_LENGTH"
          [placeholder]="'Fill in the Contracted Business name'"
          [required]="true"
          class="qv-modal-dialog-content__field qv-modal-dialog-content__field--name"
          formControlName="cbName"
          matInput
      >
      <acc-form-field-errors
          [control]="manageCBForm.get('cbName')"
          [validationMessages]="validationMessages"
      ></acc-form-field-errors>
    </div>
    <footer class="qv-modal-dialog-actions" mat-dialog-actions>
      <button
          [mat-dialog-close]
          class="qv-modal-dialog-actions__item"
          mat-button
          tabindex="-1"
      >Cancel
      </button>
      <button
          [disabled]="manageCBForm.invalid"
          class="qv-modal-dialog-actions__item qv-modal-dialog-actions__item--primary"
          mat-button
          tabindex="-1"
          type="submit"
      >Save
      </button>
    </footer>
  </form>
</div>
