import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DrugItem } from '@qv-bid/entities/drug-item.entity';
import { FormControl } from '@angular/forms';
import { resources } from '@qv-common/static';
import { DrugScenarioStatus } from '@qv-bid/enums';
import { BidUtils } from '@qv-bid/utils';
import sortBy from 'lodash.sortby';
import { BidViewStateService, IntendToRespondActionsService } from '@qv-bid/services';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'qv-intend-to-respond',
  templateUrl: './intend-to-respond.component.html',
  styleUrls: ['./intend-to-respond.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IntendToRespondComponent implements OnInit, AfterViewInit {
  @ViewChild('input')
  public input: ElementRef;

  public selectedDrugList: DrugItem[];
  public drugList: DrugItem[];
  public control = new FormControl('');
  public readonly placeholder = resources.INTEND_TO_RESPOND.DrugSearchPlaceholder;

  private drugs: DrugItem[];

  constructor(private router: Router,
              private activatedRoute: ActivatedRoute,
              private intendToRespondActionsService: IntendToRespondActionsService,
              private bidViewStateService: BidViewStateService
  ) {}

  public ngOnInit(): void {
    this.drugs = this.groupDrugsByName(this.activatedRoute.snapshot.data.drugs);
    this.selectedDrugList = this.drugs.filter((drug: DrugItem) => drug.isDismissed());
    this.calculateDrugList();
    this.control.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(() => this.calculateDrugList());
  }

  public save(): void {
    const dismissedDrugsIds = [].concat(...this.selectedDrugList.map((drug: DrugItem) => drug.ids));
    this.intendToRespondActionsService.respondBid(this.activatedRoute.snapshot.queryParams.bidId, dismissedDrugsIds)
      .subscribe(() => this.backToBidDetails());
  }

  public backToBidDetails(): void {
    this.router.navigate(
      [this.bidViewStateService.bidView],
      { queryParams: this.activatedRoute.snapshot.queryParams }
    );
  }

  public ngAfterViewInit(): void {
    this.input.nativeElement.focus();
  }

  public addToSelection(drug: DrugItem): void {
    this.selectedDrugList.push(drug);
    this.selectedDrugList = this.sortByName(this.selectedDrugList);
    this.calculateDrugList();
  }

  public removeFromSelection(drug: DrugItem): void {
    if (drug.isDismissed()) {
      return;
    }

    this.selectedDrugList = this.selectedDrugList.filter((selectedDrug: DrugItem) => selectedDrug.id !== drug.id);
    this.calculateDrugList();
  }

  private groupDrugsByName(drugs: DrugItem[]): any[] {
    const drugsGroupedByName = [];
    drugs.forEach((drug: DrugItem) => {
      const existsDrug = drugsGroupedByName.find((existDrug: DrugItem) => existDrug.name === drug.name);
      if (existsDrug) {
        existsDrug.ids.push(drug.id);
        existsDrug.statuses.push(drug.status);
      } else {
        drugsGroupedByName.push(Object.assign(drug, {
          ids: [drug.id],
          statuses: [drug.status]
        }));
      }
    });

    drugsGroupedByName.forEach((drug: DrugItem) => {
      const isDismissedStatus = drug.statuses.every((status: DrugScenarioStatus) => BidUtils.isDismissed(status));
      drug.status = isDismissedStatus ? DrugScenarioStatus.DISMISSED : DrugScenarioStatus.OPEN;
      if (!isDismissedStatus) {
        drug.statusChangedByCompany = null;
      }
    });

    return this.sortByName(drugsGroupedByName);
  }

  private calculateDrugList(): void {
    this.drugList = this.drugs
      .filter((drug: DrugItem) => !this.selectedDrugList.find((selectedDrug: DrugItem) => selectedDrug.id === drug.id))
      .filter((drug: DrugItem) => drug.name.toLowerCase().includes(this.control.value.toLowerCase()));
  }

  private sortByName(drugs: DrugItem[]): DrugItem[] {
    return sortBy(drugs, 'name');
  }
}
