import { SelectBoxTerm } from '@qv-term/models/types';
import { constants } from '@qv-common/static';
import { BindingBidType } from '@qv-term/enums/options';
import { DictionaryItem } from '@qv-common/entities';

export class BindingBidTerm extends SelectBoxTerm {
  public keepOriginalValue: boolean;
  public allowKeepOriginValue: boolean;
  public processKeepOriginalValue: boolean;
  public values: DictionaryItem[];

  constructor(name: string) {
    super(
      name,
      name,
      '',
      constants.SUMMARY_TERMS.BINDING_BID.label,
      '',
      BindingBidType.getValue(BindingBidType.NON_BINDING),
      'term__binding-bid'
    );
    this.keepOriginalValue = this.allowKeepOriginValue;
    this.processKeepOriginalValue = false;
    this.values = BindingBidType.getValues();
  }
}
