import { NdcDictionaryValue, NdcValue } from '@qv-term/entities';
import { JsonObject, JsonProperty } from 'json2typescript';
import { NotesMetadata } from '@qv-term/models';
import { NotesConverter } from '@qv-bid/serializers';

@JsonObject('Rebate')
export class Rebate {
  @JsonProperty('minBaseRebate', NdcValue)
  public minBaseRebate: NdcValue<number> = null;

  @JsonProperty('baseRebate', NdcValue)
  public baseRebate: NdcValue<number> = null;

  @JsonProperty('baseRebateLock', Boolean)
  public baseRebateLock = false;

  @JsonProperty('tierPlacement', NdcDictionaryValue)
  public tierPlacement: NdcDictionaryValue = null;

  @JsonProperty('tierPlacementLock', Boolean)
  public tierPlacementLock = false;

  @JsonProperty('tierCompetition', NdcDictionaryValue)
  public tierCompetition: NdcDictionaryValue = null;

  @JsonProperty('competitionGroup', NdcDictionaryValue)
  public competitionGroup: NdcDictionaryValue = null;

  @JsonProperty('tierCompetitor', NdcDictionaryValue)
  public competitorsTier: NdcDictionaryValue = null;

  @JsonProperty('notes', NotesConverter)
  public notes: NotesMetadata = null;

  @JsonProperty('notesLock', Boolean)
  public notesLock = false;
}
