import { JsonObject, JsonProperty } from 'json2typescript';
import { Moment } from 'moment';
import { DateConverter } from '@qv-bid/serializers';
import { Company } from 'quantuvis-core-entities';

@JsonObject('BidComment')
export class BidComment {
  @JsonProperty('id', Number)
  public id: number = null;

  @JsonProperty('summaryId', Number)
  public summaryId: number = null;

  @JsonProperty('value', String)
  public value: string = null;

  @JsonProperty('isHistoric', Boolean)
  public isHistoric = false;

  @JsonProperty('company', Company)
  public company: Company = null;

  @JsonProperty('createdAt', DateConverter)
  public createdAt: Moment = null;

}
