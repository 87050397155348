import { BaseTerm } from '@qv-term/models/types';
import { resources } from '@qv-common/static';

export class RangeOrUnitsAndRebatesTerm extends BaseTerm {
  public disabled: boolean;

  constructor(name: string) {
    super(
      name,
      name,
      resources.TermsDescriptions.DRUG_LIST.RANGE_OR_UNITS,
      '',
      [],
      [],
      'term__range-or-units-and-rebates'
    );

    this.disabled = false;
  }
}
