<ng-container *ngIf="(isFormReady$ | async) || !isEditMode && entity">
  <div class="section-toolbar">
    <div class="section-toolbar__title">
      <h2>Late Payment Penalty Terms</h2>
    </div>
    <qv-lock
        [control]="$any(formGroup?.get(termName.getTermNameLock(sectionName)))"
        [isDisabled]="isBidInvalid"
        [isEditMode]="isEditMode"
        [locked]="entity?.penaltyLock"
        data-test-id="test-id-contract-late-payment-penalty-term-lock"
    ></qv-lock>
  </div>

  <section class="table-section table-section--two-equal-columns table-section--striped">
    <qv-table-section-row [termName]="termName?.LATE_PAYMENT_PENALTY_TERMS_BASIS"
                          class="table-section__row"
                          data-test-id="test-id-contract-late-payment-penalty-term-basis">
      <qv-term-select
          [control]="$any(formGroup?.get(termName.LATE_PAYMENT_PENALTY_TERMS_BASIS))"
          [isEditMode]="isEditMode"
          [isLocked]="entity?.penaltyLock"
          [termName]="termName.LATE_PAYMENT_PENALTY_TERMS_BASIS"
          [termValue]="entity?.basis?.name"
      ></qv-term-select>
    </qv-table-section-row>

    <qv-table-section-row [termName]="termName?.LATE_PAYMENT_PENALTY_TERMS_VALUE"
                          class="table-section__row"
                          data-test-id="test-id-contract-late-payment-penalty-term-value">
      <qv-term-input
          [class.table-section__cell--error]="formGroup?.get(termName.LATE_PAYMENT_PENALTY_TERMS_VALUE)?.errors"
          [control]="$any(formGroup?.get(termName.LATE_PAYMENT_PENALTY_TERMS_VALUE))"
          [isEditMode]="isEditMode"
          [isLocked]="entity?.penaltyLock"
          [termName]="termName?.LATE_PAYMENT_PENALTY_TERMS_VALUE"
          [termSuffix]="penaltyValueUnit"
          [termValue]="entity?.setValue"
          class="table-section__cell"
      ></qv-term-input>
      <acc-form-field-errors
          *ngIf="isEditMode"
          [control]="formGroup?.get(termName.LATE_PAYMENT_PENALTY_TERMS_VALUE)"
          [validationMessages]="validationMessages"
          class="table-section__error"
      ></acc-form-field-errors>
    </qv-table-section-row>

    <qv-table-section-row [termName]="termName?.LATE_PAYMENT_PENALTY_TERMS_FREQUENCY"
                          class="table-section__row"
                          data-test-id="test-id-contract-late-payment-penalty-term-frequency">
      <qv-term-select
          [class.table-section__cell--error]="formGroup?.get(termName.LATE_PAYMENT_PENALTY_TERMS_FREQUENCY)?.errors"
          [control]="$any(formGroup?.get(termName.LATE_PAYMENT_PENALTY_TERMS_FREQUENCY))"
          [isEditMode]="isEditMode"
          [isLocked]="entity?.penaltyLock"
          [termName]="termName?.LATE_PAYMENT_PENALTY_TERMS_FREQUENCY"
          [termValue]="entity?.penaltyFrequency?.name"
      ></qv-term-select>
      <acc-form-field-errors
          *ngIf="isEditMode"
          [control]="formGroup?.get(termName.LATE_PAYMENT_PENALTY_TERMS_FREQUENCY)"
          [validationMessages]="validationMessages"
          class="table-section__error"
      ></acc-form-field-errors>
    </qv-table-section-row>
  </section>
</ng-container>
