import { NgModule } from '@angular/core';
import { authConfig } from '@qv-common/configs';
import { AUTH_CONFIG_TOKEN, AuthCommonModule } from 'quantuvis-angular-common/auth';
import { unauthorizedInterceptorProvider } from './interceptors';

@NgModule({
  imports: [
    AuthCommonModule,
  ],
  providers: [
    { provide: AUTH_CONFIG_TOKEN, useValue: authConfig },
    unauthorizedInterceptorProvider
  ]
})
export class AuthModule {}
