import { ConfirmNavigationInner } from '@qv-ng1-wrapper/classes';
import { constants, resources } from '@qv-common/static';
import { CoreUtils } from '@qv-common/utils';
import { ApiUrlPrefix } from '@qv-common/enums';
import { catchError, tap } from 'rxjs/operators';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { of } from 'rxjs';

declare let angular: angular.IAngularStatic;
declare let $: any;

// @ts-ignore
import templateUrl from './drug-exceptions.html';

const DrugExceptionsComponent = {
  template: templateUrl,
  bindings: {
    initConfirmNavigation: '&'
  },
  controller: class DrugExceptionsComponent extends ConfirmNavigationInner {

    public static $inject = ['$scope', '$timeout', 'apiService', 'util', 'translations'];
    public confirmNavigationMessage = resources.BID_DETAILS.CONFIRM_NAVIGATION_DRUG_EXCEPTIONS_PAGE;

    constructor(private $scope, $timeout, apiService, util, translations) {
      super();
      let timeoutPromise;
      const delayInMs = 700;

      $scope.searchPattern = '';
      $scope.selectedDrugExceptionType = 'DRUG_NAME';
      $scope.expanded = [];
      $scope.newDrugExceptions = {};
      $scope.removedExceptions = {};
      $scope.tooManyResults = false;
      $scope.searching = false;
      $scope.noDrugResult = false;

      translations.buildTranslations($scope);
      getDrugExceptions();

      $scope.searchInputChangeHandler = () => {
        $scope.searchResult = [];
        const minCharactersForSearch = constants.PICKER_MIN_CHARACTERS_FOR_SEARCH;
        if ($scope.searchPattern && $scope.searchPattern.length >= minCharactersForSearch) {
          delete $scope.response;
          $scope.searching = true;
          $scope.noDrugResult = false;
          $scope.tooManyResults = false;

          const search = $scope.searchPattern;
          const encodedSearchInfo = encodeURIComponent($scope.searchPattern);

          $timeout.cancel(timeoutPromise);
          timeoutPromise = $timeout(() => {
            const url = `${ApiUrlPrefix.OLD}/drugs/exceptions/search?searchPattern=${
              encodedSearchInfo}&drugExceptionType=${$scope.selectedDrugExceptionType}`;
            apiService.get(url).pipe(
              tap((response: HttpResponse<any>) => {
                const data = response.body;
                if ($scope.searchPattern === search) {
                  const tooManyResults = constants.ERROR_CODES.SEARCH_ERRORS.TOO_MANY_RESULTS;
                  if (data && data.errorCode && data.errorCode === tooManyResults) {
                    $scope.tooManyResults = true;
                  } else if (data && data.responseObject) {
                    $scope.tooManyResults = false;

                    if (data.responseObject.length === 0) {
                      $scope.noDrugResult = true;
                    } else {
                      $scope.noDrugResult = false;
                    }
                    $scope.searchResult = data.responseObject;
                  }
                }

                $scope.searching = false;
                util.refreshScope($scope);
              }),
              catchError((error: HttpErrorResponse) => {
                $scope.searching = false;
                $scope.noDrugResult = false;
                $scope.tooManyResults = false;
                handleErrorCase(error);
                return of(null);
              })
            ).subscribe();
          }, delayInMs);
        }
      };

      $scope.exists = item => {
        if ($scope.isDrugClassTabSelected()) {
          item = item.name;
        }
        return ($scope.currentDrugExceptions
          && $scope.currentDrugExceptions[$scope.selectedDrugExceptionType].indexOf(item) !== -1) ? 'exists' : '';
      };

      // tslint:disable-next-line:triple-equals
      $scope.isCurrent = tab => tab == $scope.selectedDrugExceptionType ? 'active' : '';

      $scope.showItems = drug => $scope.isDrugClassTabSelected() && drug.expanded;

      $scope.chevronClass =
        drug => `icon qv-icon-gray ${ drug.expanded ? 'fa fa-chevron-down' : 'fa fa-chevron-right' }`;

      $scope.toggle = drug => {
        drug.expanded = !drug.expanded;
      };

      $scope.select = $event => {
        delete $scope.response;
        $scope.noDrugResult = false;
        $scope.tooManyResults = false;
        $scope.searchPattern = '';
        $scope.searchResult = [];

        $scope.selectedDrugExceptionType = $($event.target).data('select');
        $scope.expanded.length = 0;

        if (CoreUtils.isNotDefined($scope.currentDrugExceptions) || util.isEmptyMap($scope.currentDrugExceptions)) {
          getDrugExceptions();
        }
        // Focus search when switching tabs
        $('#drugExceptionSearchInput').focus();
      };

      /**
       * Verifies if there are drug exceptions changes, if any drug exception was added or removed.
       *
       * @returns {boolean} true if any drug exception was added or removed
       */
      $scope.areDrugExceptionsChanges =
        () => !util.isEmptyMap($scope.newDrugExceptions) || !util.isEmptyMap($scope.removedExceptions);

      $scope.toggleDrugException = (drug, $event) => {

        const target = $($event.target);
        const item = $scope.isDrugClassTabSelected() ? drug.name : drug;
        if (target.hasClass('fa fa-chevron-down') || target.hasClass('fa fa-chevron-right')) {
          $scope.toggle(drug);
        } else {
          if ($scope.exists(drug) !== '') {
            $scope.removeDrugException(item);
          } else {
            // If item is found in removed exceptions, the only change to be done is to remove it from this list.
            // It's not necessary to add it to newDrugExceptions map as the change was not yet persisted in DB
            const found = find(item, $scope.removedExceptions[$scope.selectedDrugExceptionType]);
            if (found) {
              const index = $scope.removedExceptions[$scope.selectedDrugExceptionType].indexOf(found);
              $scope.removedExceptions[$scope.selectedDrugExceptionType].splice(index, 1);
            } else {
              if ($scope.newDrugExceptions[$scope.selectedDrugExceptionType]) {
                $scope.newDrugExceptions[$scope.selectedDrugExceptionType].push(item);
              } else {
                $scope.newDrugExceptions[$scope.selectedDrugExceptionType] = [
                  item
                ];
              }
            }

            const currentItemFound = find(item, $scope.currentDrugExceptions[$scope.selectedDrugExceptionType]);
            if (!currentItemFound) {
              if ($scope.currentDrugExceptions[$scope.selectedDrugExceptionType]) {
                // The new item is added at the beginning of current list
                $scope.currentDrugExceptions[$scope.selectedDrugExceptionType].unshift(item);
              } else {
                $scope.currentDrugExceptions[$scope.selectedDrugExceptionType] = [
                  item
                ];
              }
            }
          }
        }
        window.scrollTo(0, 0);
      };

      $scope.removeDrugException = item => {
        // Do not allow removal of the last element from every list of exceptions
        if ($scope.currentDrugExceptions && $scope.currentDrugExceptions[$scope.selectedDrugExceptionType]
          && $scope.currentDrugExceptions[$scope.selectedDrugExceptionType].length > 1) {

          // If item found, the only change to be done is to remove the item from newDrugExceptions.
          // It's not necessary to add it to removedExceptions map as the change was not yet persisted in DB
          const found = find(item, $scope.newDrugExceptions[$scope.selectedDrugExceptionType]);
          if (found) {
            const index = $scope.newDrugExceptions[$scope.selectedDrugExceptionType].indexOf(found);
            $scope.newDrugExceptions[$scope.selectedDrugExceptionType].splice(index, 1);
          } else {
            // Add element to removedExceptions and delete it from currentDrugExceptions
            if ($scope.removedExceptions[$scope.selectedDrugExceptionType]) {
              $scope.removedExceptions[$scope.selectedDrugExceptionType].push(item);
            } else {
              $scope.removedExceptions[$scope.selectedDrugExceptionType] = [
                item
              ];
            }
          }

          const currentFound = find(item, $scope.currentDrugExceptions[$scope.selectedDrugExceptionType]);
          if (currentFound) {
            const currentIndex = $scope.currentDrugExceptions[$scope.selectedDrugExceptionType].indexOf(item);
            $scope.currentDrugExceptions[$scope.selectedDrugExceptionType].splice(currentIndex, 1);
          }
          window.scrollTo(0, 0);
        }
      };

      /**
       * Saves the drug exceptions changes: insertions and / or deletions.
       */
      $scope.save = () => {
        $scope.noDrugResult = false;
        $scope.tooManyResults = false;

        if ($scope.areDrugExceptionsChanges()) {
          const allDrugExceptions = {
            add: $scope.newDrugExceptions,
            remove: $scope.removedExceptions
          };

          apiService.post(`${ApiUrlPrefix.OLD}/drugs/exceptions/update`, allDrugExceptions).pipe(
            tap((response: HttpResponse<any>) => {
              const data = response.body;
              $scope.newDrugExceptions = {};
              $scope.removedExceptions = {};
              $scope.searchResult = [];

              if (data && data.responseObject) {
                $scope.currentDrugExceptions = data.responseObject;
              }

              $scope.response = {
                message: data.message
              };

              $scope.allowLocationChange = true;
              util.refreshScope($scope);
            }),
            catchError((error: HttpErrorResponse) => {
              $scope.allowLocationChange = true;
              handleErrorCase(error);
              return of(null);
            })
          ).subscribe();
        }
      };

      $scope.revertChanges = () => {
        delete $scope.response;
        $scope.newDrugExceptions = {};
        $scope.removedExceptions = {};
        $scope.currentDrugExceptions = angular.copy($scope.originalDrugExceptions);
        $scope.searching = false;
        $scope.noDrugResult = false;
        $scope.tooManyResults = false;
      };

      function getDrugExceptions(): void {
        apiService.get(`${ApiUrlPrefix.OLD}/drugs/exceptions/get`).pipe(
          tap((response: HttpResponse<any>) => {
            const data = response.body;
            if (data && data.responseObject) {
              $scope.originalDrugExceptions = data.responseObject;
              $scope.currentDrugExceptions = angular.copy($scope.originalDrugExceptions);
              util.refreshScope($scope);
            }
          }),
          catchError((error: HttpErrorResponse) => {
            handleErrorCase(error);
            return of(null);
          })
        ).subscribe();
      }

      /**
       * Searches for the specified item in the specified map.
       *
       * @param item - the item to be searched
       * @param map - the list where the item is searched
       * @returns {*} the found item if item is part of the map, undefined otherwise
       */
      function find(item, list): any {
        let found;
        if (CoreUtils.isDefined(list)) {
          found = list.find(listItem => item === listItem);
        }
        return found;
      }

      $scope.isDrugClassTabSelected = () => $scope.selectedDrugExceptionType === 'DRUG_CLASS';

      function handleErrorCase(data): void {
        $scope.response = {
          error: data.error,
          message: data.message
        };
        util.refreshScope($scope);
      }
    }

    public $onInit(): void {
      this.emitConfirmNavigationCallback();
    }

    public isNavigationNeedConfirmation(): boolean {
      return !this.$scope.allowLocationChange && this.$scope.areDrugExceptionsChanges();
    }
  }
};
export default DrugExceptionsComponent;
