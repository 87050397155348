import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('Drug')
export class Drug {
  @JsonProperty('name', String)
  public name: string = null;

  @JsonProperty('drugClass', String)
  public drugClass: string = null;

  @JsonProperty('pharmaNames', [String])
  public pharmaNames: string[] = null;
}
