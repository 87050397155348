<div>
  <p>There are no results for your current combination of constraints.</p>
  <div>
    <b>Suggestions:</b>
    <ul>
      <li>Un-hide your dismissed drugs</li>
      <li>Modify your filtered drug list criteria</li>
    </ul>
  </div>
</div>
