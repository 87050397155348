import { ChangeDetectionStrategy, Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { finalize } from 'rxjs/operators';

import { FormValidationError, SvgIconName } from '@qv-common/enums';
import { ContractedBusinessDaoService } from '@qv-bid/services/dao';
import { ContractBusinessModalData } from '@qv-bid/components/shared/cb-manage-modal/models/cb-manage-modal-data';
import { Observable, of } from 'rxjs';
import { ContractedBusinessManageAction } from '@qv-bid/components/shared/cb-manage-modal/enums';
import { StringUtils } from '@qv-common/utils';

import { ContractedBusiness } from 'quantuvis-core-entities';

@Component({
  selector: 'qv-cb-manage-modal',
  templateUrl: './cb-manage-modal.component.html',
  styleUrls: ['./cb-manage-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContractedBusinessManageModalComponent implements OnInit {
  @BlockUI('cb-manage-modal')
  public blockUI: NgBlockUI;

  @Output()
  public cbSaved: EventEmitter<ContractedBusiness> = new EventEmitter<ContractedBusiness>();

  public readonly NAME_MAX_LENGTH = 55;

  public title: string;
  public svgIconName = SvgIconName;

  public manageCBForm = new FormGroup({
    cbName: new FormControl(
      this.isRenameAction() ? this.data.cb.name : '',
      [
        Validators.required.bind(this),
        Validators.maxLength(this.NAME_MAX_LENGTH),
        this.checkCBNameOnUnique.bind(this)
      ]
    )
  });

  public readonly validationMessages = new Map<string, string>([
    [FormValidationError.REQUIRED, 'Please fill in the Contracted Business name'],
    [FormValidationError.DUPLICATE, 'The Contracted Business name already exists']
  ]);

  constructor(
    public dialogRef: MatDialogRef<ContractedBusinessManageModalComponent>,
    private contractedBusinessDaoService: ContractedBusinessDaoService,
    @Inject(MAT_DIALOG_DATA) public data: ContractBusinessModalData
  ) {}

  public ngOnInit(): void {
    this.title = `${this.data.action} Contracted Business`;
  }

  public onBlur(): void {
    const control = this.manageCBForm.get('cbName');
    const value = StringUtils.trim(control.value);

    control.setValue(value);
  }

  public onSubmit(): void {
    if (this.manageCBForm.invalid) {
      return;
    }
    this.blockUI.start();
    this.callCBSubmitting()
      .pipe(finalize(() => this.blockUI.stop()))
      .subscribe((cb: ContractedBusiness) => {
        this.cbSaved.emit(cb);
        this.dialogRef.close();
      });
  }

  private checkCBNameOnUnique(control: AbstractControl): ValidationErrors {
    const matchedList = this.data.cbList.filter(
      (cb: ContractedBusiness) => this.compareCBName(cb, control.value)
    );

    return matchedList.length > 0 ? { duplicate: true } : null;
  }

  private callCBSubmitting(): Observable<ContractedBusiness> {
    const name = this.manageCBForm.value.cbName;

    switch (this.data.action) {
      case ContractedBusinessManageAction.RENAME:
        return this.contractedBusinessDaoService.update({ ...this.data.cb, name }, this.data.bidVersionId,
          this.data.isBidInternal);
      case ContractedBusinessManageAction.CREATE:
        return this.contractedBusinessDaoService.create(name, this.data.bidVersionId, this.data.isBidInternal);
      case ContractedBusinessManageAction.DUPLICATE:
        return this.contractedBusinessDaoService.duplicate({ ...this.data.cb, name }, this.data.bidVersionId,
          this.data.isBidInternal);
      default:
        return of(null);
    }
  }

  private compareCBName(cb: ContractedBusiness, value: string): boolean {
    if (this.isRenameAction()) {
      return value && cb.name.toLowerCase() === value.toLowerCase() && cb.id !== this.data.cb.id;
    } else {
      return value && cb.name.toLowerCase() === value.toLowerCase();
    }
  }

  private isRenameAction(): boolean {
    return this.data.action === ContractedBusinessManageAction.RENAME;
  }
}
