import { Injectable } from '@angular/core';

import { PermissionService } from '@qv-common/services/auth/permission.service';
import { QuantuvisBusinessFeature, QuantuvisPlusFeature } from '@qv-company/enums';
import { UserDaoService } from '@qv-common/services/dao';
import { SharedEventBusService } from '@qv-shared/services';
import { User } from '@qv-user/entities/user.entity';
import { AuthService } from 'quantuvis-angular-common/auth';
import { UserService } from '@qv-common/services/auth/user.service';

@Injectable()
export class NavigationMainService {

  private readonly featureConstants: Map<string, string>;

  constructor(
    private permissionService: PermissionService,
    private userDaoService: UserDaoService,
    private authService: AuthService,
    private userService: UserService,
    private sharedEventBusService: SharedEventBusService
  ) {
    const features = Object.assign({},
      QuantuvisPlusFeature,
      QuantuvisBusinessFeature
    );
    this.featureConstants = Object.keys(features).reduce((map, key) => map.set(key, features[key]), new Map());
  }

  get getFeatureConstants(): Map<string, string> {
    return this.featureConstants;
  }

  public isAllowed(name: string): boolean {
    return this.permissionService.isAllowed(name);
  }

  public isFeatureAllowed(name: string): boolean {
    return this.permissionService.isFeatureAllowed(name);
  }

  public isEnterpriseButtonVisible(): boolean {
    if (this.userService.isCurrentUserPharma() && this.permissionService.permissionsReady$.getValue()) {
      if (this.permissionService.isBusinessPackageFeaturesEnabled()) {
        return false;
      }

      const userFeatures = this.permissionService.userFeatures$.getValue();

      return Object.keys(QuantuvisPlusFeature).every((value: string) =>
        (value === QuantuvisPlusFeature.GRID_VIEW) || !userFeatures.get(value));
    }

    return false;
  }

  public getHelpUrl(): string {
    if (!this.authService.clientToken) {
      throw new Error('Secure tokens is missing');
    }

    return `service/help?clientToken=${this.authService.clientToken}`;
  }

  public toggleMyAccounts(flag: boolean): void {
    this.userDaoService.filterByMyAccounts(flag).subscribe((user: User) => {
      this.userService.user.next(user);
      this.sharedEventBusService.myAccountsFilterChangedEvent.emit();
    });
  }

  public isUserCompanyBindingBidEnabled(): boolean {
    return this.permissionService.isUserCompanyBindingBidEnabled();
  }

  public isUserCompanyCoverLetterEnabled(): boolean {
    return this.permissionService.isUserCompanyCoverLetterEnabled();
  }
}
