export class Page<T> {
  public content: T[] = [];
  public totalElements: number = null;
  public number?: number = null;
  public first?: boolean = null;
  public last?: boolean = null;

  constructor(
    content?: T[],
    totalElements?: number,
    public currentPage?: number,
    public totalPages?: number,
    first?: boolean,
    last?: boolean,
  ) {
    this.content = content;
    this.totalElements = totalElements;
    this.number = currentPage;
    this.first = first;
    this.last = last;
  }
}
