import { JsonObject, JsonProperty } from 'json2typescript';
import { DictionaryItem } from '@qv-common/entities';
import { NdcValue } from './ndc-value.entity';

@JsonObject('NdcDictionaryValue')
export class NdcDictionaryValue extends NdcValue<DictionaryItem> {
  @JsonProperty('value', DictionaryItem)
  public value: DictionaryItem = null;

  constructor(value: DictionaryItem, isNdc?: boolean) {
    super(value, isNdc);
    this.value = value;
  }

  public static copy(origin: NdcDictionaryValue): NdcDictionaryValue {
    const dictionaryItem = Object.assign(new DictionaryItem(), origin.value);
    return Object.assign(new NdcDictionaryValue(dictionaryItem, origin.isNdc));
  }
}
