import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ChangeDetectionStrategy, OnChanges, Input, EventEmitter, Output, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { NotificationService } from 'quantuvis-angular-common/notification';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, finalize, switchMap } from 'rxjs/operators';
import { Drug, MarketBasketDrug } from '../../entities';
import { DrugGroupMeta, DrugMeta } from '../../models';
import { DrugDaoService } from '../../services/dao';

@UntilDestroy()
@Component({
  selector: 'mb-market-basket-drug-search',
  templateUrl: './market-basket-drug-search.component.html',
  styleUrls: ['./market-basket-drug-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MarketBasketDrugSearchComponent implements OnInit, OnChanges {
  @Input()
  public selectedDrugs: MarketBasketDrug[] = [];

  @Input()
  public searchControl: FormControl;

  @Input()
  public disabledDrugName: string;

  @Input()
  public disabledDrugClass: string;

  @Output()
  public selectionChanged = new EventEmitter<MarketBasketDrug[]>();

  @BlockUI()
  public blockUI: NgBlockUI;

  public readonly debounceTime = 500;

  public isSelectedAll = false;
  public isExpandedAll = true;
  public isDrugGroupsEmpty$ = new BehaviorSubject<boolean>(true);
  public drugGroupMap = new Map<string, DrugGroupMeta>();

  constructor(
    private drugDaoService: DrugDaoService,
    private notificationService: NotificationService,
  ) {}

  public ngOnInit(): void {
    this.initSearchHandler();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedDrugs && !changes.selectedDrugs.firstChange) {
      this.updateDrugSelection();
      this.updateSelectedAllState();
    }
  }

  public onClearSearch(): void {
    this.searchControl.reset();
    this.onClearDrugs();
  }

  public onClearDrugs(): void {
    this.drugGroupMap.clear();
    this.updateDrugGroupsEmptyState();
  }

  public onDrugChange(drug: DrugMeta, changes: MatCheckboxChange): void {
    drug.isSelected = changes.checked;
    this.updateSelectedList(drug);
    this.selectionChanged.emit(this.selectedDrugs);
  }

  public onDrugClassChange(group: DrugGroupMeta, changes: MatCheckboxChange): void {
    this.changeSelectionForDrugs(group.drugMap, changes.checked, group.drugClass);
    this.selectionChanged.emit(this.selectedDrugs);
  }

  public onExpandItem(group: DrugGroupMeta, $event: MouseEvent): void {
    $event.preventDefault();
    group.isExpanded = !group.isExpanded;
    this.updateExpandedAllState();
  }

  public onExpandAll(): void {
    this.isExpandedAll = !this.isExpandedAll;
    this.drugGroupMap.forEach((group: DrugGroupMeta) => group.isExpanded = this.isExpandedAll);
  }

  public onSelectAll(): void {
    this.isSelectedAll = !this.isSelectedAll;
    this.drugGroupMap.forEach((group: DrugGroupMeta) => {
      this.changeSelectionForDrugs(group.drugMap, this.isSelectedAll, group.drugClass);
    });
    this.selectionChanged.emit(this.selectedDrugs);
  }

  private changeSelectionForDrugs(drugMap: Map<string, DrugMeta>, isSelected: boolean, drugClass: string): void {
    drugMap.forEach((drug: DrugMeta) => {
      const isDrugLocked = this.disabledDrugClass === drugClass && this.disabledDrugName === drug.name;

      drug.isSelected = isDrugLocked ? drug.isSelected : isSelected;
      this.updateSelectedList(drug);
    });
  }

  private updateSelectedList({ name, drugClass, isSelected }: DrugMeta): void {
    if (isSelected && !this.isDrugSelected(name, drugClass)) {
      const drug = Object.assign(new MarketBasketDrug(), { name, drugClass } as MarketBasketDrug);
      this.selectedDrugs.push(drug);
    } else if (!isSelected) {
      this.selectedDrugs = this.selectedDrugs.filter((drug: MarketBasketDrug) =>
        !drug.isMatchNameAndClass(name, drugClass)
      );
    }
  }

  private initSearchHandler(): void {
    this.searchControl.valueChanges
      .pipe(
        debounceTime(this.debounceTime),
        distinctUntilChanged(),
        filter((query: string) => query?.length > 2),
        switchMap((query: string) => this.searchDrugs(query)),
        untilDestroyed(this)
      )
      .subscribe((drugs: Drug[]) => {
        this.prepareDrugGroupMap(drugs);
        this.updateSelectedAllState();
        this.updateExpandedAllState();
      });
  }

  private searchDrugs(query: string): Observable<Drug[]> {
    this.blockUI.start('Loading...');

    return this.drugDaoService.searchByDrugNameAndClass(query)
      .pipe(
        catchError((errorResponse: HttpErrorResponse) => this.notificationService.showServerError(errorResponse)),
        finalize(() => this.blockUI.stop())
      );
  }

  private prepareDrugGroupMap(drugs: Drug[]): void {
    this.drugGroupMap.clear();

    if (drugs.length) {
      drugs.forEach(({ drugClass }: Drug) => {
        if (!this.drugGroupMap.has(drugClass)) {
          const drugsByDrugClass = this.prepareDrugsByDrugClass(drugs, drugClass);
          const drugMap = this.prepareDrugMap(drugsByDrugClass, drugClass);

          this.drugGroupMap.set(drugClass, new DrugGroupMeta(drugClass, drugMap));
        }
      });
    }

    this.updateDrugGroupsEmptyState();
  }

  private prepareDrugsByDrugClass(drugs: Drug[], drugClass: string): Drug[] {
    return drugs.filter((drug: Drug) => drug.drugClass === drugClass);
  }

  private prepareDrugMap(drugs: Drug[], drugClass: string): Map<string, DrugMeta> {
    const drugMap = new Map<string, DrugMeta>();

    drugs.forEach(({ name, pharmaNames }: Drug) => {
      const pharmaNameMap = this.preparePharmaNameMap(pharmaNames);
      const drug = new DrugMeta(name, drugClass, pharmaNameMap, this.isDrugSelected(name, drugClass));

      drugMap.set(name, drug);
    });

    return drugMap;
  }

  private preparePharmaNameMap(pharmaNames: string[]): Map<string, string> {
    const pharmaNameMap = new Map<string, string>();

    pharmaNames.forEach((name: string) => pharmaNameMap.set(name, name));

    return pharmaNameMap;
  }

  private updateDrugSelection(): void {
    this.drugGroupMap.forEach(((group: DrugGroupMeta) => {
      group.drugMap.forEach((drug: DrugMeta) => {
        drug.isSelected = this.isDrugSelected(drug.name, drug.drugClass);
      });
    }));
  }

  private isDrugSelected(drugName: string, drugClass: string): boolean {
    return this.selectedDrugs.some((drug: MarketBasketDrug) => drug.isMatchNameAndClass(drugName, drugClass));
  }

  private updateDrugGroupsEmptyState(): void {
    this.isDrugGroupsEmpty$.next(this.drugGroupMap.size === 0);
  }

  private updateSelectedAllState(): void {
    const groups = Array.from(this.drugGroupMap.values());
    const isAllSelected = groups.every((group: DrugGroupMeta) => group.isSelected());
    const isAllUnselected = groups.every((group: DrugGroupMeta) => !group.isSelected());

    if (isAllSelected) {
      this.isSelectedAll = true;
    } else if (isAllUnselected) {
      this.isSelectedAll = false;
    }
  }

  private updateExpandedAllState(): void {
    const groups = Array.from(this.drugGroupMap.values());
    const isAllExpanded = groups.every((group: DrugGroupMeta) => group.isExpanded);
    const isAllCollapsed = groups.every((group: DrugGroupMeta) => !group.isExpanded);

    if (isAllExpanded) {
      this.isExpandedAll = true;
    } else if (isAllCollapsed) {
      this.isExpandedAll = false;
    }
  }
}
