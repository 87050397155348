import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialogRef } from '@angular/material/dialog';
import { BidEventBusService } from '@qv-bid/services';
import { ErrorNotificationService } from '@qv-common/services';
import { HttpStatusCode, Page } from 'quantuvis-angular-common/api';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { catchError, finalize, tap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { resources } from '@qv-common/static';
import { NotificationService } from 'quantuvis-angular-common/notification';
import { BlockingMessage } from '@qv-common/enums';
import { QvCache } from '@qv-common/decorators';
import { MarketBasketModal, MarketBasketModalOutput } from '@qv-bid/models';
import { MarketBasketModalState } from '@qv-bid/enums';
import { MarketBasketModalComponent } from '../market-basket-modal/market-basket-modal.component';
import { MarketBasketDaoService } from '@qv-bid/services/dao';
import { MarketBasket } from '@qv-bid/entities';
import {
  MarketBasketTemplate,
  MarketBasketTemplateDaoService,
  MarketBasketTemplateSearchParams
} from 'market-basket/market-basket-common';
import { ModalSize } from 'quantuvis-angular-common/modal';

@Component({
  selector: 'qv-market-basket-assign',
  templateUrl: './market-basket-assign.component.html',
  styleUrls: ['./market-basket-assign.component.scss']
})
export class MarketBasketAssignComponent extends MarketBasketModal implements OnInit {
  @BlockUI('market-basket-assign-modal')
  public blockUI: NgBlockUI;

  @Output()
  public stateChanged: EventEmitter<MarketBasketModalState> = new EventEmitter<MarketBasketModalState>();

  public templatePage$ = new Subject<Page<MarketBasketTemplate>>();

  public selectedTemplate: MarketBasketTemplate;

  public addToOtherScenarios = false;

  public isTemplatesLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public title: string;

  public readonly resources = resources;
  public readonly pageSize = 25;
  public readonly importTitle = 'Import Market Basket Template';
  public readonly assignTitle = 'Assign Market Basket Template';

  constructor(
    protected dialogRef: MatDialogRef<MarketBasketModalComponent>,
    protected errorNotificationService: ErrorNotificationService,
    protected bidEventBusService: BidEventBusService,
    private notificationService: NotificationService,
    private marketBasketDaoService: MarketBasketDaoService,
    private marketBasketTemplateDaoService: MarketBasketTemplateDaoService
  ) {
    super(dialogRef, errorNotificationService, bidEventBusService);
  }

  public ngOnInit(): void {
    this.dialogRef.updateSize(ModalSize.X_LARGE);
    this.setState();
    this.setTitle();
    this.loadTemplates();
  }

  public onTemplateSelected(template: MarketBasketTemplate): void {
    this.selectedTemplate = template;
  }

  public onChangePage(pageIndex: number): void {
    this.loadTemplates(pageIndex);
  }

  public onAddToOtherScenariosChange(changes: MatCheckboxChange): void {
    this.addToOtherScenarios = changes.checked;
  }

  public onSave(): void {
    this.blockUI.start(BlockingMessage.SAVING);
    this.marketBasketDaoService.assignToScenario(
      this.scenarioId,
      this.selectedTemplate.id,
      this.addToOtherScenarios,
      this.isBidInternal
    ).pipe(
      catchError((error: HttpErrorResponse) => this.handleAssignmentError(error)),
      finalize(() => this.blockUI.stop()),
    ).subscribe((marketBasket: MarketBasket) => {
      const output = new MarketBasketModalOutput(marketBasket, this.addToOtherScenarios);
      this.primaryAction.emit(output);
      this.onClose();
    });
  }

  public onNewClick(): void {
    this.stateChanged.emit(MarketBasketModalState.NEW_AND_ASSIGN);
  }

  public onBackClick(): void {
    this.stateChanged.emit(MarketBasketModalState.PREVIEW);
  }

  @QvCache()
  public getPrimaryButtonTitle(state: MarketBasketModalState): string {
    return this.isImportState(state) ? this.resources.Actions.IMPORT : this.resources.Actions.SAVE;
  }

  @QvCache()
  public isImportState(state: MarketBasketModalState): boolean {
    return state === MarketBasketModalState.IMPORT;
  }

  private setTitle(): void {
    this.title = this.isImportState(this.state) ? this.importTitle : this.assignTitle;
  }

  private setState(): void {
    this.state = this.marketBasket ? MarketBasketModalState.IMPORT : MarketBasketModalState.ASSIGN;
  }

  private loadTemplates(page: number = 0): void {
    const searchParams = Object.assign(new MarketBasketTemplateSearchParams(), {
      page,
      size: this.pageSize,
      drugNames: [this.drugName],
      searchDrugClass: this.drugClass
    });

    this.isTemplatesLoading$.next(true);

    this.marketBasketTemplateDaoService.getList(searchParams)
      .pipe(
        tap((templatePage: Page<MarketBasketTemplate>) => this.templatePage$.next(templatePage)),
        catchError((response: HttpErrorResponse) => this.notificationService.showServerError(response)),
        finalize(() => this.isTemplatesLoading$.next(false))
      )
      .subscribe();
  }

  private handleAssignmentError(errorResponse: HttpErrorResponse): Observable<Error> {
    let errorMessage;

    if (HttpStatusCode.NOT_FOUND === errorResponse.status) {
      errorMessage = resources.MarketBasketManagement.ERROR_MESSAGES.MARKET_BASKET_NOT_FOUND_ERROR;

      this.loadTemplates();
    }

    return this.onSaveError(errorResponse, errorMessage);
  }
}
