import { ContractedBusiness } from 'quantuvis-core-entities';

export class ContractBusinessImportModalData {
  constructor(
    public bidVersionId: number,
    public cbList: ContractedBusiness[],
    public cb: ContractedBusiness,
    public isBidInternal: boolean,
  ) {}
}
