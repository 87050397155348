import { TermSection } from '@qv-term/enums';
import { MarketShareState, PriceProtectionState, UtilizationManagementState } from '../enums';

interface StatePerSection {
  [TermSection.UM]: typeof UtilizationManagementState;
  [TermSection.MARKET_SHARE]: typeof MarketShareState;
  [TermSection.PRICE_PROTECTION]: typeof PriceProtectionState;
}

export const statePerSection: StatePerSection = {
  [TermSection.UM]: UtilizationManagementState,
  [TermSection.MARKET_SHARE]: MarketShareState,
  [TermSection.PRICE_PROTECTION]: PriceProtectionState
};
