import { Inject, Injectable } from '@angular/core';
import { HttpResponseWrapper } from '@qv-common/models/api';
import { ApiService } from '@qv-common/services/api';
import { JsonConvert } from 'json2typescript';
import { JSON_CONVERT_TOKEN } from 'quantuvis-angular-common/json-converter/services';
import { map } from 'rxjs/operators';
import { BidComment } from '@qv-bid/entities/bid-comment';
import { Observable } from 'rxjs';
import { ViewPerspectiveService } from '@qv-common/services/auth';
import { HttpUtils } from '@qv-common/utils';
import { ApiUrlPrefix } from '@qv-common/enums';

@Injectable()
export class BidCommentsDaoService {

  constructor(
    private apiService: ApiService,
    @Inject(JSON_CONVERT_TOKEN) private jsonConvert: JsonConvert,
    private viewPerspectiveService: ViewPerspectiveService
  ) {}

  public getComments(bidVersionId: number, summaryId: number): Observable<BidComment[]> {
    return this.apiService.get(`${ApiUrlPrefix.BIDS}/bid-versions/${bidVersionId}/summaries/${summaryId}/comments`)
      .pipe(map((response: HttpResponseWrapper<BidComment[]>) =>
        this.jsonConvert.deserializeArray(response.body.data, BidComment)
      ));
  }

  public saveComment(bidVersionId: number, summaryId: number, comment: BidComment, isInternal: boolean): Observable<BidComment> {
    let url = `${ApiUrlPrefix.BIDS}/bid-versions/${bidVersionId}/summaries/${summaryId}/comments`;
    const perspective = this.viewPerspectiveService.getViewPerspective();
    url = HttpUtils.appendPerspectiveQueryParamToUrlIfBidIsInternal(url, perspective, isInternal);

    return this.apiService.post(url, comment)
      .pipe(map((response: HttpResponseWrapper<BidComment>) =>
        this.jsonConvert.deserializeObject(response.body.data, BidComment)
      ));
  }

  public updateComment(bidVersionId: number, summaryId: number, comment: BidComment, isInternal: boolean): Observable<BidComment> {
    let url = `${ApiUrlPrefix.BIDS}/bid-versions/${bidVersionId}/summaries/${summaryId}/comments/${comment.id}`;
    const perspective = this.viewPerspectiveService.getViewPerspective();
    url = HttpUtils.appendPerspectiveQueryParamToUrlIfBidIsInternal(url, perspective, isInternal);

    return this.apiService.put(url, comment)
      .pipe(map((response: HttpResponseWrapper<BidComment>) =>
        this.jsonConvert.deserializeObject(response.body.data, BidComment)
      ));
  }

  public deleteHistoricComments(bidVersionId: number, summaryId: number, isInternal: boolean): Observable<void> {
    let url = `${ApiUrlPrefix.BIDS}/bid-versions/${bidVersionId}/summaries/${summaryId}/comments/historic`;
    const perspective = this.viewPerspectiveService.getViewPerspective();
    url = HttpUtils.appendPerspectiveQueryParamToUrlIfBidIsInternal(url, perspective, isInternal);

    return this.apiService.delete(url).pipe(map(() => null));
  }
}
