import { JsonConverter, JsonCustomConvert } from 'json2typescript';
import { Moment } from 'moment';
import moment from 'moment';
import { BidUtils } from '@qv-bid/utils';

@JsonConverter
export class DateConverter implements JsonCustomConvert<Moment> {
  public serialize(date: Moment | string): any {
    if (moment.isMoment(date)) {
      return parseInt(date.format('x'), 10);
    } else if (BidUtils.isAtNdc(date)) {
      return date;
    } else {
      return null;
    }
  }

  public deserialize(date: any): Moment {
    if (BidUtils.isAtNdc(date)) {
      return date;
    } else {
      const momentDate = moment.utc(date);

      return momentDate.isValid() ? momentDate : null;
    }
  }
}
