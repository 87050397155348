export enum CustomRange {
  LAST_YEAR = 'Last year',
  THIS_YEAR = 'This year',
  NEXT_YEAR = 'Next year',
  Q1 = 'Q1',
  Q2 = 'Q2',
  Q3 = 'Q3',
  Q4 = 'Q4'
}

export enum CustomRangeGroup {
  YEAR = 'YEAR',
  QUARTER = 'QUARTER'
}

export class CustomRangeUtils {
  public static readonly displayValues = new Map<CustomRangeGroup, CustomRange[]>([
    [
      CustomRangeGroup.QUARTER,
      [
        CustomRange.Q1,
        CustomRange.Q2,
        CustomRange.Q3,
        CustomRange.Q4,
      ]
    ],
    [
      CustomRangeGroup.YEAR,
      [
        CustomRange.LAST_YEAR,
        CustomRange.THIS_YEAR,
        CustomRange.NEXT_YEAR
      ]
    ]
  ]);

  public static getCustomRangeGroup(group: CustomRangeGroup): string[] {
    return Array.from(CustomRangeUtils.displayValues.get(group));
  }
}
