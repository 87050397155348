import { ChangeDetectionStrategy, Component, Input, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatMenu } from '@angular/material/menu';
import { DictionaryItem } from '@qv-common/entities';

@Component({
  selector: 'qv-dictionary-menu',
  templateUrl: './dictionary-menu.component.html',
  styleUrls: ['./dictionary-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DictionaryMenuComponent {
  @Input()
  public menuItems: DictionaryItem[] = [];

  @Input()
  public control: FormControl;

  @ViewChild(MatMenu, { static: true })
  public menu: MatMenu;

  public onMenuItemClick(selectedItem: DictionaryItem): void {
    const currentValue = this.control.value;
    if (!currentValue || (currentValue.id !== selectedItem.id)) {
      this.control.markAsDirty();
      this.control.setValue(selectedItem);
    }
  }
}
