import { resources } from '@qv-common/static';
import { drugTermsConstants } from '@qv-term/constants';
import { TermName } from '@qv-term/enums';
import { SelectBoxTerm } from '@qv-term/models/types';
import { QuantityLimit } from 'quantuvis-core-entities';

export class QuantityLimitTerm extends SelectBoxTerm {
  public defaultValueForOldBid: string;

  constructor(name: string) {
    super(
      name,
      drugTermsConstants[TermName.QUANTITY_LIMIT].title,
      resources.TermsDescriptions.DRUG_LIST.QUANTITY_LIMIT,
      '',
      '',
      QuantityLimit.getValue(QuantityLimit.YES),
      'term__quantity-limit'
    );
    this.values = QuantityLimit.getValues();
    this.defaultValueForOldBid = QuantityLimit.getValue(QuantityLimit.EMPTY).name;
  }
}
