import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { ActiveToast } from 'ngx-toastr/toastr/toastr.service';
import { IndividualConfig } from 'ngx-toastr/toastr/toastr-config';
import { ComponentType } from 'ngx-toastr/portal/portal';

import { ErrorMessage } from '../enums/error-message.enum';
import { ToastType } from '../enums/toast-type.enum';

@Injectable()
export class NotificationService {
  public static readonly DEFAULT_MESSAGE_CHANNEL = 'DEFAULT';
  public static readonly DEFAULT_SUCCESS_MESSAGE_TIMEOUT = 4000;

  public toasts: Map<string, ActiveToast<any>[]> = new Map();

  constructor(protected toastrService: ToastrService) {}

  public show(message?: string, title?: string, config?: Partial<IndividualConfig>,
              type?: string, messageChannel?: string): ActiveToast<any> {
    const notification = this.toastrService.show(message, title, config, type);

    if (notification) {
      messageChannel = messageChannel || NotificationService.DEFAULT_MESSAGE_CHANNEL;
      const channelToasts = this.toasts.get(messageChannel);
      if (channelToasts) {
        channelToasts.push(notification);
      } else {
        this.toasts.set(messageChannel, [notification]);
      }
    }

    return notification;
  }

  public success(
    message?: string,
    title?: string,
    config: Partial<IndividualConfig> = {
      timeOut: NotificationService.DEFAULT_SUCCESS_MESSAGE_TIMEOUT,
      disableTimeOut: false
    },
    messageChannel?: string
  ): ActiveToast<any> {
    return this.show(message, title, config, ToastType.success, messageChannel);
  }

  public error(message?: string, title?: string, config?: Partial<IndividualConfig>,
               messageChannel?: string): ActiveToast<any> {
    return this.show(message, title, config, ToastType.error, messageChannel);
  }

  public info(message?: string, title?: string, config?: Partial<IndividualConfig>,
              messageChannel?: string): ActiveToast<any> {
    return this.show(message, title, config, ToastType.info, messageChannel);
  }

  public warning(message?: string, title?: string, config?: Partial<IndividualConfig>,
                 messageChannel?: string): ActiveToast<any> {
    return this.show(message, title, config, ToastType.warning, messageChannel);
  }

  public showBasedOnComponent<T>(message: string, toastComponent: ComponentType<T>, type: ToastType): ActiveToast<T> {
    return this.show(message, null, { toastComponent }, type, null);
  }

  public showProcessingError(): ActiveToast<any> {
    return this.error(ErrorMessage.PROCESSING_ERROR);
  }

  public showServerError(errorResponse: HttpErrorResponse, customMessage?: string): Observable<never> {
    this.serverError(errorResponse, customMessage);

    return throwError(errorResponse);
  }

  public serverError(errorResponse: HttpErrorResponse, customMessage?: string): ActiveToast<any> {
    if (errorResponse.status === 401) {
      return null;
    }

    const message = errorResponse.status === 500
      ? ErrorMessage.COMMON_SERVER_ERROR
      : customMessage || this.getErrorMessage(errorResponse);

    return this.error(message);
  }

  public clear(toastId?: number): void {
    if (toastId) {
      this.toastrService.clear(toastId);
      this.toasts.forEach((toastArr, toastChannel) =>
        this.toasts.set(toastChannel, toastArr.filter(toast => toast.toastId !== toastId)));
    } else {
      this.toastrService.clear();
      this.toasts = new Map<string, ActiveToast<any>[]>();
    }
  }

  public clearChannel(messageChannel: string): Promise<void> {
    return new Promise((resolve) => {
      const toasts = this.toasts.get(messageChannel);

      if (toasts) {
        toasts.forEach(toast => this.toastrService.remove(toast.toastId));
        this.toasts.delete(messageChannel);
      }

      resolve();
    });
  }

  private getErrorMessage({ error, message }: HttpErrorResponse): string {
    return error && error.message ? error.message : message;
  }
}
