import { Inject, Injectable } from '@angular/core';
import { JsonConvert } from 'json2typescript';
import { JSON_CONVERT_TOKEN } from 'quantuvis-angular-common/json-converter/services';
import { HttpResponseWrapper } from '@qv-common/models/api';
import { map } from 'rxjs/operators';
import { Signature } from '@qv-bid/entities';
import { ApiService } from '@qv-common/services/api';
import { Observable } from 'rxjs';
import { ApiUrlPrefix } from '@qv-common/enums';

@Injectable()
export class BidSignatureDaoService {

  constructor(private apiService: ApiService, @Inject(JSON_CONVERT_TOKEN) private jsonConvert: JsonConvert) {}

  public getSignatureInfo(bidVersion: number): Observable<Signature> {
    return this.apiService.get(`${ApiUrlPrefix.BIDS}/bid-versions/${bidVersion}/signatures/${bidVersion}`)
      .pipe(map((response: HttpResponseWrapper<Signature>) =>
        this.jsonConvert.deserializeObject(response.body.data, Signature)
      ));
  }
}
