import { JsonObject, JsonProperty } from 'json2typescript';
import { BidRight } from '@qv-bid/enums';

@JsonObject('PharmaRight')
export class PharmaRight {
  @JsonProperty('id', Number)
  public id: number = null;

  @JsonProperty('bidVersionId', Number)
  public bidVersionId: number = null;

  @JsonProperty('right', String)
  public right: BidRight = null;

  @JsonProperty('isActive', Boolean)
  public isActive: boolean = null;
}
