import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('Enrollment')
export class Enrollment {
  @JsonProperty('id', Number)
  public id: number = null;

  @JsonProperty('name', String)
  public name: string = null;

  constructor(id: number = null, name: string = null) {
    this.id = id;
    this.name = name;
  }
}
