/* eslint-disable max-lines*/

export const resources = {
  NewBid: {
    Step: 'Step',
    DrugTermsAllowed: {},
    BidTermsAllowed: {
      ALLOW_DEPENDENCIES: 'Allow Dependencies from Pharma',
      ALLOW_SCENARIOS: 'Allow Pharma to create Scenarios',
      ALLOW_CONTRACTED_BUSINESS: 'Allow Pharma to create or modify Contracted Businesses',
      LOCK_NOTES: 'Lock all Notes fields',
      ALLOW_MARKET_BASKET: 'Allow Pharma to negotiate Market Baskets',
      ALLOW_MARKET_SHARE_REBATE: 'Allow Market Share Rebate offers from Pharma',
      ALLOW_NDC_OFFERS: 'Allow offers at the NDC level from Pharma'
    },
    AllowedOrder: [
      'ALLOW_MARKET_SHARE_REBATE',
      'ALLOW_DEPENDENCIES',
      'ALLOW_SCENARIOS',
      'ALLOW_NDC_OFFERS',
      'ALLOW_CONTRACTED_BUSINESS',
      'ALLOW_MARKET_BASKET',
      'LOCK_NOTES'
    ],
    NewBidStates: [
      'Summary Terms',
      'Add Contract Terms',
      'Select Drugs to be Evaluated',
      'Warning',
      'Select Pharmas',
      'Select Drugs',
      'Set Drug Level Terms'
    ],
    WizardTitles: {
      SUMMARY_TERMS: 'Summary Terms',
      CONTRACT_TERMS: 'Add Contract Terms',
      SELECT_PAYERS: 'Select Payers',
      DRUG_LEVEL_TERMS: 'Set Drug Level Terms',
      SELECT_DRUGS: 'Select Drugs',
      SET_DRUG_LEVEL_TERMS: 'Set Drug Level Terms'
    },
    TierPlacement: [
      'Preferred Branded',
      'Non-Preferred Branded',
      'Specialty'
    ],
    TierCompetition: [
      '1 of 1',
      '1 of 2',
      '1 of 3',
      '1 of 4',
      '1 of 5',
      '1 of All',
      '1 of Many'
    ],
    SelectManufacturerError: 'Please select at least one pharma in order to proceed.',
    SearchForManufacturer: 'Search for pharmas',
    SelectDrugError: 'You must include at least one drug in your RFP. Please try again',
    SELECT_ALL: 'Select All',
    EXPAND_ALL: 'Expand All',
    CLEAR_ALL: 'Clear All',
    SearchForDrug: 'Search for a Drug or',
    SearchForClass: 'Therapeutic Class',
    ErrorWhileFetchingManufacturers: 'There was an error while loading pharma list.',
    ErrorWhileFetchingDrugs: 'There was an error while loading drugs list.',
    InfoDrugSearchPlaceholder: 'Search for a Drug or Class',
    InfoManufacturerSearchPlaceholder: 'Search for Pharmas',
    InfoDrugsToBeEvaluated: 'Drugs to be included in RFP',
    InfoManufacturerToBeEvaluated: 'Pharmas whose drugs will be evaluated in the RFP',
    SearchForPayers: 'Search for payers',
    SelectPayerError: 'Please select at least one Payer (Internal) in order to proceed.',
    ErrorWhileFetchingPayers: 'There was an error while loading payers list.',
    InfoPayerSearchPlaceholder: 'Search for Payers',
    INFO_SELECTED_PAYER: 'Selected Payers',
    SAVING_BID: 'Saving RFP...',
    CHOOSE_DRUGS: 'Choose which Drugs and Pharmas to include in your RFP.',
    DRUG_LEVEL_TERMS: 'Set Drug Level Terms',
    ALL_MANUFACTURERS: 'Add all rebatable Drugs from all Pharmas to the RFP',
    ALL_AND_ALL: 'You are about to create RFPs for all pharmas and all drugs in the system. This operation could take a while to complete',
    SELECT_MANUFACTURERS: 'Select Pharmas',
    SELECT_DRUGS: 'Select Drugs or Drug Classes',
    TOO_MANY_RESULTS: 'Enter more characters to reduce the number of results',
    LOADING_LIST: 'Loading list...',
    NO_RESULTS_FOUND: 'No results found',
    PRE_POPULATE_DRUG_SCENARIOS: 'Pre-populate Drug Scenarios',
    PRE_POPULATE_PRICE_PROTECTION: 'Pre-Populate Price Protection for all Drugs/NDCs',
    PRE_POPULATE_DRUG_SCENARIOS_TOOLTIP: 'Any Drug Scenario and/or field that is populated in this section will be ' +
      'automatically applied to each Drug for each separate Contracted Business',
    KEEP_ORIGINAL_VALUE: 'keep original value',
    PRESERVE_ORIGINAL_LOCKS: 'Preserve Original Locks',
    PRESERVE_ORIGINA_PHARMA_RIGHTS: 'Preserve original Pharma rights',
    RESET_VALUES: 'Reset values',
    RESETS_VALUE_DESCRIPTION: 'The default settings will be restored to default Step',
    KEEP_ORIGINAL_VALUE_DESCRIPTION: 'Fields populated with <strong>“keep original value”</strong> will be copied from ' +
      'the original Bid(s) to the newly copied Bid(s). If <strong>“keep original value”</strong> is edited, the new ' +
      'value will be added to the newly copied Bid(s).',
    PRESERVE_ORIGINAL_LOCKS_DESCRIPTION: 'If <strong>“Preserve original locks”</strong> is selected, Lock values are ' +
      'copied from the original Bid(s) to the newly copied Bid(s), otherwise the Locks will be populated with the value ' +
      'from this wizard.',
    PRESERVE_ORIGINAL_PHARMA_DESCRIPTION: 'If <strong>“Preserve original Pharma rights”</strong> is selected, Pharma’s ' +
      'rights are copied from the original Bid(s) to the newly copied Bid(s), otherwise the Pharma’s rights will ' +
      'be populated with the value from this wizard.',
    CONTRACTED_BUSINESS_DESCRIPTION: 'Contracted Business cannot be changed and will be copied from the original Bid(s).',
    COPY_COMMENTS_AND_NOTES_DESCRIPTION: 'Users can control if the original Bid(s) Comments and/or Notes will be ' +
      'copied from the original Bid(s) to the newly copied Bid(s).',
    COPY_COMMENTS: 'Copy Comments',
    COPY_NOTES: 'Copy Notes for all Drug Scenarios',
    COPY_BASE_REBATE: 'Copy Base Rebate',
    COPY_MIN_BASE_REBATE: 'Copy Min Base Rebate',
    COPY_MARKET_SHARE: 'Copy Market Share',
    REMOVE_DISMISSED_SCENARIOS: 'Remove Dismissed Scenarios',
    REMOVE_DISMISSED_SCENARIOS_WARNING: 'If all scenarios are dismissed for a drug it will be removed in the copied ' +
      'bid. <br> If all scenarios in the bid are dismissed, no copied bid will be created.',
  },
  INTEND_TO_RESPOND: {
    DrugSearchPlaceholder: 'Search for a Drug',
    FAILED_TO_LOAD_DRUGS: 'Failed to load the list of drugs. Please contact Quantuvis administrator.'
  },
  DASHBOARD: {
    STATUS_GRAPH: 'Status Graph',
    STATUS: 'STATUS',
    BIDS: 'BIDS',
    PERCENTAGE: 'PERCENTAGE'
  },
  ProfileSettings: {
    OLD_PASSWORD_MISSING: 'Please enter your current password',
    PASSWORD_REQUIREMENTS: 'Password requirements are: at least 8 characters with an uppercase letter, a lowercase letter and a number',
    PASSWORD_NOT_FOLLOW_RULE: 'Password does not follow the rule: at least 8 characters with an uppercase letter, a ' +
      'lowercase letter and a digit',
    PASSWORD_NOT_MATCH: 'Passwords did not match. Please check the spelling and try again.',
    SAVE_SUCCESSFUL: 'Your profile was successfully updated',
    EMPTY_FIRST_NAME_ERROR_MESSAGE: 'Please set the user first name',
    EMPTY_LAST_NAME_ERROR_MESSAGE: 'Please set the user last name',
    EMPTY_TITLE_ERROR_MESSAGE: 'Please set the user title',
    EMPTY_PHONE_ERROR_MESSAGE: 'Please set the user phone number',
    NOT_SAVED_ERROR: 'Settings are not saved',
    EMAIL_PREFERENCES_WARNING: 'You will not receive any Email Notifications because your "My Accounts" list is empty',
    EMAIL_PREFERENCES_WARNING_ON_EDIT_USER: ' User will not receive any Email Notifications because their "My Accounts" list is empty',
    ACCOUNTS_TOGGLE_WARNING: 'Your view was switched to "All Accounts" because your "My Accounts" list is empty',
    EMPTY_MY_ACCOUNTS_INFO: 'Please select companies for <strong>My Accounts</strong> and click <strong>Save</strong>.<br/> ',
    EMPTY_MY_ACCOUNTS_INFO_FOR_PAYER: 'This list allows you to filter which pharma accounts you <br/> receive emails ' +
      'on and which pharma accounts are visible <br/> on the Dashboard, Bids and Notifications pages.',
    EMPTY_MY_ACCOUNTS_INFO_FOR_PHARMA: 'This list allows you to filter which payer accounts you <br/> receive emails ' +
      'on and which payer accounts are visible <br/> on the Dashboard, Bids and Notifications pages.',
    UPDATING_USER_MESSAGE: 'Updating User...',
    PASSWORD_EMPTY_NOT_ALLOWED: 'Please fill both the new password and re-type new password fields',
    EMAIL_PREFERENCES_TOOLTIP: '<strong>All Accounts</strong><br />Receive notification emails for All Accounts <br/>' +
      '<strong>My Accounts</strong><br />Receive notification emails only for My Accounts <br/>' +
      '<strong>No Emails</strong><br />Do not receive any notification emails',
    MY_ACCOUNTS_TOOLTIP_FOR_PAYER: 'Allows you to filter which pharma accounts you receive emails on and which pharma ' +
      'accounts are visible on the Dashboard, Bids and Notifications pages.',
    MY_ACCOUNTS_TOOLTIP_FOR_PHARMA: 'Allows you to filter which payer accounts you receive emails on and which payer ' +
      'accounts are visible on the Dashboard, Bids and Notifications pages.'
  },
  UserValidation: {
    ROLE_ERROR: 'Please set the user role',
    ACCESS_RIGHT_ERROR: 'Please set the user access right',
    ACCESS_RIGHTS_FOR_ALL_COMPANIES_ERROR: 'Please set access rights for all the companies',
    FIRST_NAME_ERROR: 'Please set the user first name',
    LAST_NAME_ERROR: 'Please set the user last name',
    TITLE_ERROR: 'Please set the job title',
    PHONE_ERROR: 'Please set the phone number',
    EMAIL_ERROR: 'Please set the email address',
    TIMEZONE_ERROR: 'Please set the user timezone'
  },
  Feedback: {
    EMPTY_SUBJECT_ERROR_MESSAGE: 'Please fill in the subject field',
    EMPTY_FEEDBACK_ERROR_MESSAGE: 'Please fill in the feedback field',
    SUBJECT_MAX_LENGTH_ERROR_MSG: 'Subject is limited to {0} characters. Please edit the text and try again.',
    FEEDBACK_MAX_LENGTH_ERROR_MSG: 'Feedback is limited to {0} characters. Please edit the text and try again.',
    FEEDBACK_SUCCESS_MESSAGE: 'Your message has been submitted'
  },
  ContactQuantuvis: {
    EMPTY_CONTACT_MESSAGE_ERROR: 'Please fill in the message field',
  },
  MarketBasketManagement: {
    MARKET_BASKET_NAME_PLACEHOLDER: 'Market Basket Name',
    MARKET_BASKET_COPY_NAME_PLACEHOLDER: 'Copy Name',
    INFO_SELECTED_DRUGS_FOR_MARKET_BASKET: 'Drugs included in the Market Basket',
    NEW_MARKET_BASKET: 'New Market Basket',
    NEW_MARKET_BASKET_TEMPLATE: 'New Market Basket Template',
    EDIT_MARKET_BASKET: 'Edit',
    COPY_MARKET_BASKET: 'Create Copy',
    IMPORT_MARKET_BASKET: 'Import',
    LAST_UPDATE_DATE_LABEL: 'Last update',
    ADD_MARKET_BASKET_TO_ALL_OTHER_DRUG_SCENARIOS: 'Add Market Basket to all other drug scenarios',
    CREATED_BY_LABEL: 'Created by',
    MARKET_BASKETS_LABEL: 'Market Baskets',
    DEFINED_MARKET_BASKETS: 'Market Basket Templates',
    MARKET_BASKET_CONTENT: 'Market Basket Content',
    ERROR_MESSAGES: {
      EMPTY_MARKET_BASKET_NAME: 'Please fill in the Market Basket name',
      EMPTY_MARKET_BASKET_NAME_COPY: 'Please fill in the copy name',
      EMPTY_MARKET_BASKET_DRUGS_LIST: 'Please add at least one drug to Market Basket',
      MARKET_BASKET_MAX_LENGTH: 'Name is limited to {0} characters. Please edit the text and try again',
      NOT_SELECTED_MARKET_BASKET_ERROR: 'Please select a market basket from the list',
      COPY_FROM_MARKET_BASKET_GENERAL_ERROR: 'The market basket copy operation could not be performed',
      MARKET_BASKET_UNASSIGNABLE_TO_DRUG_ERROR: 'This Market Basket cannot be assigned to this drug scenario as ' +
        'this drug is not included in the Market Basket.',
      MARKET_BASKET_NOT_FOUND_ERROR: 'This market basket has been deleted',
      MARKET_BASKET_GENERAL_ERROR: 'The market basket operation could not be performed. Please contact Customer ' +
        'Quantuvis Support for assistance',
      MARKET_BASKET_ASSIGNMENT_GENERAL_ERROR: 'The market basket assignment operation could not be performed. Please ' +
        'contact Customer Quantuvis Support for assistance',
      MARKET_BASKET_UNASSIGNMENT_GENERAL_ERROR: 'The market basket unassignment operation could not be performed. ' +
        'Please contact Customer Quantuvis Support for assistance',
      MARKET_BASKET_OPERATION_UNAUTHORIZED_ERROR: 'You are not authorized to perform the market basket operation',
      MARKET_BASKET_ASSIGNMENT_OPERATION_FORBIDDEN_ERROR: 'You are not allowed to assign the market basket',
      MARKET_BASKET_UNASSIGNMENT_OPERATION_FORBIDDEN_ERROR: 'You are not allowed to unassign the market basket',
      MARKET_BASKET_ASSIGNMENT_OPERATION_UNAUTHORIZED_ERROR: 'You are not authorized to assign the market basket',
      MARKET_BASKET_UNASSIGNMENT_OPERATION_UNAUTHORIZED_ERROR: 'You are not authorized to unassign the market basket'
    },
    INFO_MESSAGES: {
      MARKET_BASKET_NOT_EDITABLE_AT_NDC_LEVEL: 'The market basket is editable only at the drug group level',
      NO_MARKET_BASKETS_FOR_CURRENT_DRUG: 'There are no Market Baskets for this drug. Please create a new Market Basket.',
      CANNOT_REMOVE_DRUGS_WHICH_INCLUDED_IN_AN_ASSIGNED_MARKET_BASKET: 'You cannot remove drugs which are already ' +
        'included in an assigned Market Basket.',
      SELECT_MARKET_BASKET: 'Select a Market Basket Template'
    }
  },
  TermsDescriptions: {
    CONTRACT: {
      REBATE_ELIGIBILITY: 'In addition to the current Payment Frequency period, the amount of look back time from the ' +
        'beginning of the Payment Frequency period where a prescription (based on fill date) is eligible for rebate payment',
      PAYMENT_LAG: 'Time period that the pharma has to pay rebates after receiving valid claims data from the payer',
      PAYMENT_FREQUENCY: 'How often pharma will make rebate payments to payer',
      WHOLESALE_ACQUISITION_PRICE: 'The WAC price that is used to calculate rebate payments for the specified drugs in an RFP/BID',
      LATE_PAYMENT_PENALTY_TERMS: 'High-level penalty terms regarding what pharma would owe the payer for failing to pay rebates on time',
      LATE_PAYMENT_PENALTY_TERMS_BASIS: 'The basis for what the late payment penalty will be calculated off of',
      LATE_PAYMENT_PENALTY_TERMS_VALUE: 'The value (% of rebates or flat dollar amount) of the late payment penalty',
      LATE_PAYMENT_PENALTY_TERMS_FREQUENCY: 'How often the late payment penalty will be paid to the payer',
      NO_FAULT_TERMINATION_CLAUSE: 'High-level terms regarding the ability for the parties to terminate the contract without cause',
      NO_FAULT_TERMINATION_CLAUSE_WHO_MAY_INVOKE: 'Which parties have the right to terminate the contract without cause',
      NO_FAULT_TERMINATION_CLAUSE_PRIOR_NOTICE_REQUIRED: 'Number of calendar days of prior written notice that need to ' +
        'be provided before a party can terminate the contract',
      PHARMA_AUDIT_TERMS: 'High-level terms for pharma to audit payer',
      PHARMA_AUDIT_TERMS_AUDIT_FREQUENCY: 'How often pharma is allowed to audit the payer',
      PHARMA_AUDIT_TERMS_PRIOR_NOTICE_REQUIRED: 'Number of calendar days of prior written notice that needs to be ' +
        'provided to the payer before the audit occurs',
      PHARMA_AUDIT_TERMS_LOOK_BACK: 'The maximum look back period that Pharma can review claims from the start date of the audit.',
      ADMINISTRATION_FEE: 'Additional fee for administrating all rebated claims'
    },
    SUMMARY: {
      RFP_TITLE: 'A descriptive name to distinguish what is contained in the RFP and to identify or group multiple RFPs ' +
        'in the system as needed.',
      LINE_OF_BUSINESS: 'The business that rebates are being contracted for (i.e. Medicare, Commercial, Medicaid, Exchanges)',
      PLAN_TYPE: 'The ability to select one or more attribute that makes up the Contracted Business for future stratification',
      CONTRACTED_BUSINESS: 'This is the area where you have the ability to add 1 or more breakouts (or more specific ' +
        'subsets) of the Line Of Business that is being contracted for. You may also add plan types, such as MAPD, LICS, ' +
        'HMO etc, to each breakout.',
      RFP_START_DATE: 'Date the request for proposal (RFP) is issued for payers and pharma to negotiate rebate terms',
      RFP_DUE_DATE: 'Date the best and final proposal (bid) is due back to the payer from the pharma',
      DRUGS_EVALUATED: 'This is where drugs are selected for evaluation as a part of the request for proposal (RFP)',
      SELECT_MANUFACTURERS: 'Select the Pharmas that you would like to include in the RFP. All rebatable Drugs for the ' +
        'specified Pharmas will be included in the RFP.',
      SELECT_MANUFACTURERS_HTML: 'Select the Pharmas that you would like to include in the RFP. </br> All rebatable ' +
        'Drugs for the specified Pharmas will be included in the RFP.',
      SELECT_DRUGS: 'Select specific rebatable Drugs &/or Drug Classes to include in the RFP.',
      ATTACHMENTS: 'A place for both parties to include additional files regarding the bid (e.g. Word, Excel, PowerPoint, ' +
        'OpenOffice, LibreOffice, PDF, TXT, CSV, PNG, JPG)',
      CONTRACT_START_DATE: 'Date that a rebate contract will become effective',
      CONTRACT_END_DATE: 'Date that a rebate contract will end on',
      CONTRACT_EVERGREEN: 'Agreement between two parties that automatically renews the contractual provisions after ' +
        'the Contract End Date, until a new contract is agreed upon or the contact is terminated',
      INSTRUCTIONS: 'A field for the payer to instruct pharma on the requirements of the request for proposal (RFP)',
      COMMENTS: 'A field through which the payer and pharma can leave comments',
      LINKS: 'A place for both parties to provide links to websites that have additional information regarding the RFP or bid',
      LEGAL_ATTESTATION: 'The Payer’s required legal terms that govern the Binding Bid with Pharma'
    },
    DRUG_LIST: {
      GENERAL_REBATE: 'General Rebate Terms',
      MIN_BASE_REBATE: 'The minimum rebate value for pharma to be competitive',
      BASE_REBATE: 'Rebate offered by pharma',
      TIER_PLACEMENT: 'The formulary tier that a drug is on',
      TIER_COMPETITION: 'Tier competition is based on a limited number of manufacturers/products within a specific tier',
      COMPETITION_GROUP: 'The group by which tier competition within a specific tier is based',
      TIER_COMPETITOR: 'Where on the formulary the competitor’s drug will reside',
      NOTES: 'Any additional criteria or information that is not already in a predefined field',
      EMPTY_NOTES_TOOLTIP: 'There are no Notes for this drug',
      UTILIZATION_MANAGEMENT: 'The application of additional requirements or restrictions to further drive utilization patterns',
      EMPTY_UM_DETAILS_TOOLTIP: 'There are no UM Details for this drug',
      CONTRACT_DATES: 'Individual contract terms',
      STEP_THERAPY: 'Requires a member to "stepthrough" or try another therapy before the plan will cover another',
      PRIOR_AUTHORIZATION: 'Requires a physician to complete a form stating that a patient meets certain criteria ' +
        'decided upon by a plan in order to obtain coverage for a therapy',
      MARKET_SHARE: 'Rebates for the percentage of a market controlled by a specified drug within a drug class',
      MARKET_SHARE_TIER: 'The basis for determining how market share will be evaluated',
      RANGE_OR_UNITS: 'The range or units for achieved market share that will earn a specified additional rebate above the base rebate',
      REBATE: 'The specified rebate that is earned by reaching an achieved market share',
      MARKET_BASKET: 'The drugs that comprise the market for a specified drug or drug class',
      EMPTY_MARKET_BASKET_TOOLTIP: 'There are no Market Baskets for this drug',
      PRICE_PROTECTION: 'Provides a ceiling or limit to the amount a price can be raised for a specified drug',
      NET_EFFECTIVE_PRICE: 'The set price for a drug\'s NDC on a per unit basis',
      INDEX: 'The basis by which price inflation will be measured',
      THRESHOLD: 'The percentage above the Index that will trigger a change in price',
      RESET: 'How often the Baseline WAC is recalculated',
      BASELINE_START_DATE: 'The date the Index baseline will be set',
      BASELINE_WAC: 'The drug\'s NDC WAC price as of the Baseline Start Date',
      NEW_PRICE_EFFECTIVE_DATE: 'The date the price change will be applied to the invoice',
      QUANTITY_LIMIT: 'A limit on the covered amount of a drug (e.g. time, tablets, refills)',
      OTHER_UM: 'Any UM restriction outside of ST, PA or QL',
      UM_DETAILS: 'This field explains the requirements of ST, UM, QL and Other UM',
      SCENARIO_DATES_INVALID_MSG: 'Scenario Start Date/End Date doesn’t match Contract Start Date/End Date'
    }
  },
  FILE_UPLOAD: {
    BROWSE: 'Browse',
    GENERAL_UPLOAD_FAIL: 'Failed to upload file',
    FILE_FORMAT_ERROR_MSG: 'File format is not supported',
    EMPTY_FILE_ERROR_MSG: 'File cannot be empty'
  },
  Actions: {
    CREATE: 'Create',
    CONTINUE: 'Continue',
    EDIT: 'Edit',
    VIEW: 'View',
    DELETE: 'Delete',
    DISABLE_USER: 'Disable User',
    ENABLE_USER: 'Enable User',
    OK: 'Ok',
    APPLY: 'Apply',
    CANCEL: 'Cancel',
    CLOSE: 'Close',
    SAVE: 'Save',
    UNASSIGN: 'Unassign',
    IMPORT: 'Import',
    SEND: 'Send',
    SUBMIT: 'Submit',
    NEXT: 'Next',
    BACK: 'Back',
    YES: 'Yes',
    NO: 'No',
    SIGN_OUT: 'Sign out',
    REFRESH: 'Refresh'
  },
  EDIT_ACTIONS: {
    EDIT: 'EDIT',
    REOPEN: 'REOPEN',
    EDIT_RFP_SENT: 'EDIT_RFP_SENT'
  },
  DRUG_ACTIONS: {
    CREATE_SCENARIO: 'Create Scenario',
    DELETE_SCENARIO: 'Delete Scenario',
    DISMISS_SCENARIO: 'Dismiss Scenario',
    RECALL_SCENARIO: 'Recall Scenario',
    CREATE_DEPENDENCY: 'Create dependency',
    LOCK_NDC: 'Lock NDCs',
    UNLOCK_NDC: 'Unlock NDCs',
    ACCEPT: 'Accept',
    ACCEPT_IN_REVIEW: 'Accept',
    UN_ACCEPT: 'Un-Accept',
    DISMISS_IN_REVIEW: 'Dismiss',
    UNDO: 'Undo',
    UNDO_IN_REVIEW: 'Undo',
    LABELS: {
      ACCEPTED: '- Accepted -',
      DISMISSED: '- Dismissed -',
      DISMISSED_IN_REVIEW: '- Review: Dismissed -'
    },
    COPY_VALUES: 'Copy Values',
    PASTE_VALUES: 'Paste Values',
    ADD_DRUG: 'Add New Drug',
    DELETE_DRUGS: 'Delete Drugs',
    DELETE_HISTORIC_NOTES: 'Delete Historic Notes',
  },
  PlanType: {
    OVERWRITE_WARNING: 'Overwriting a contracted business with another one will cause current values to be lost in ' +
      'favor of the values from the other contracted business.',
    DELETE: 'Delete',
    APPLY: 'Apply',
    OVERWRITE: 'Overwrite',
    DUPLICATE: 'Duplicate',
    IMPORT: 'Import',
    CANCEL: 'Cancel',
    IMPORT_TEXT: 'Import values from another tab',
    DUPLICATE_TEXT: 'Duplicate this tab',
    NAME_PLACEHOLDER: 'Name',
    DUPLICATE_NAME_PLACEHOLDER: 'Duplicate Name'
  },
  Instructions: {
    PLACEHOLDER: 'Overall RFP Instructions for '
  },
  Comments: {
    PLACEHOLDER: 'Comments for '
  },
  BID_DETAILS: {
    LOADING_CONTRACTED_BUSINESS: 'Loading Contracted Business',
    PROCESSING_VERSION_COMPARISON: 'Processing differences between versions',
    LOCKING_BID: 'Locking RFP/BID',
    SAVING: 'Saving ...',
    SAVED: 'Saved',
    INVALID_FIELDS_SAVING_ERROR: 'The bid cannot be saved until invalid values are corrected.',
    PAYMENT: 'Payment',
    AUDIT_TERMS: 'Pharma Audit Terms',
    NO_FAULT_TERMINATION_CLAUSE: 'No Fault Termination Clause',
    LATE_PAYMENT_PENALTY_TERMS: 'Late Payment Penalty Terms',
    FEES_SECTION: 'Fees',
    CONTRACT_TERMS: 'CONTRACT TERMS',
    CONTRACTED_BUSINESS: 'Contracted Business',
    SUMMARY: 'SUMMARY',
    REBATES: 'REBATES',
    CHANGING_WILL_OVERRIDE: 'Changing this value will modify all NDCs in this drug group.',
    CONFIRM_NAVIGATION: 'Are you sure you want to navigate out of the create bid wizard? All the data already entered will be lost.',
    CONFIRM_NAVIGATION_DRUG_EXCEPTIONS_PAGE: 'Are you sure you want to navigate out of the drug exceptions page? ' +
      'All the changes already made will be lost.',
    CONFIRM_NAVIGATION_COPY_BID: 'Are you sure you want to navigate out of the copy bid wizard? All the data already entered will be lost.',
    CREATE_NDC_MARKET_BASKET: 'The market basket is editable only at the drug group level',
    REQUIRED_MSG: 'Required',
    INVALID_VALUES_FOR_REQUIRED_FIELDS: 'Incomplete Response: Missing Values',
    INVALID_DRUG: 'This Drug or its NDCs have incomplete values',
    INVALID_NDC: 'This NDC has incomplete values',
    INVALID_PRICE_PROTECTION: 'For Best and Final offers, populating the Net Effective Price or the other 5 fields ' +
      'in the Price Protection section is required',
    INVALID_BASE_REBATE: 'For Best and Final offers, Base Rebate is required',
    INVALID_BASE_REBATE_TOOLTIP: 'Offer is below Minimum Rebate, low confidence of formulary acceptance.',
    DELETE_DRUGS_WITHOUT_DEPENDENCY_ERROR: 'One of the selected drugs has a dependency. This drug cannot be deleted ' +
      'until the dependency is removed.',
    DELETE_ALL_DRUGS_ERROR: 'A selected drug(s) cannot be deleted because each Contracted Business needs to have at ' +
      'minimum one drug included. Please select a different drug(s) or delete the entire Contracted Business that you no longer need.',
    DELETE_DRUGS_GENERAL_ERROR: 'Delete drugs cannot be performed at this moment. Please try again later.',
    CONFIRM_REOPEN_BID: 'Do you want to reopen this Bid to continue negotiation with ',
    CONFIRM_EDIT_BID: 'Do you want to edit this Bid to continue negotiation with ',
    CONFIRM_DELETE_BID: 'Do you want to delete this RFP?',
    CONFIRM_DELETE_BIDS: 'Do you want to delete this RFP(s)?',
    REQUIRED_TITLE: '* TITLE',
    LINE_OF_BUSINESS: 'LINE OF BUSINESS',
    BINDING_NO_BINDING: 'Binding/Non-binding',
    PASTE_VALUES_ERROR: 'Error while saving pasted values',
    CREATE_SCENARIO_ERROR: 'Error while creating new scenarios',
    DELETE_SCENARIO_ERROR: 'Error while deleting scenarios',
    CONFIRM_DISCARD_DRAFT: 'Do you want to permanently discard all changes to this draft?',
    UNDO_TOOLTIP: 'Undo latest changes',
    REDO_TOOLTIP: 'Redo latest changes',
    SCENARIO_NAME_WRONG_VALUE_ERROR: 'Wrong scenario number format',
    SCENARIO_NAME_DUPLICATE_ERROR: 'This scenario number already exists, please choose a different one',
    SCENARIO_NAME_SAVING_ERROR: 'Scenario number changes were not saved',
    TABS: {
      SUMMARY: 'SUMMARY TERMS',
      REBATES: 'REBATE TERMS'
    },
    ACCESS_TO_GRID_VIEW_RESTRICTED: 'Please contact Quantuvis Customer Support for access to Grid View',
    NO_DRUGS_FOUND: 'No drugs found',
  },
  BIDS_LIST: {
    REPORTING_BUTTON: 'Reporting',
    COMPARE_OFFERED_BIDS: 'Compare Offered Bids',
    COMPARE_ALL_BIDS: 'Compare All Bids',
    EXPORT_CURRENT_VERSION: 'Export Current Version',
    PRINT_CURRENT_VERSION: 'Print Current Version',
    DRUG_LEVEL: 'Drug Level',
    DRUG_LEVEL_WITH_ATTACHMENTS: 'Drug Level with Attachments',
    NDC_LEVEL: 'NDC Level',
    NDC_LEVEL_WITH_ATTACHMENTS: 'NDC Level with Attachments',
    LETTER_OF_INTENT: 'Letter of Intent',
    SCENARIO_SUMMARY: 'Scenario Summary',
    BIDS: 'Bids',
    CREATE_NEW_RFP: 'Create New RFP',
    CREATE_INTERNAL_RFP: 'Create Internal RFP',
    COPY_BIDS: 'Copy Bids',
    LOADING_BIDS_LIST: 'Loading RFPs/Bids List...',
    STATUS: 'STATUS',
    LINE_OF_BUSINESS: 'LINE OF BUSINESS',
    ASSIGNED_TO: 'ASSIGNED TO',
    DATE_MODIFIED: 'DATE MODIFIED',
    RFP_DUE_DATE: 'RFP DUE DATE',
    CONTRACT_PERIOD: 'CONTRACT PERIOD',
    RFP_TITLE: 'RFP TITLE',
    GENERAL_ERROR: 'There was an error while receiving bids information. Please try again later.',
    SOME_BIDS_CANNOT_BE_DELETED: 'Some of the selected bids are invalid, no bids will be deleted. Please select valid bids and try again.',
    CONCURRENT_EDIT_OR_DELETED_MESSAGE: 'Selected bid(s) might have been edited or deleted by other users. Please try again.',
    SOME_BIDS_CANNOT_BE_SENT: 'Some of the selected bids are invalid, no bids will be sent. Please select valid bids and try again.',
    EXPORT_BID_GENERAL_ERROR: 'Export bids cannot be performed at this moment. Please try again later.',
    PRINT_BID_GENERAL_ERROR: 'Print bids cannot be performed at this moment. Please try again later.',
    CONCURRENT_EDIT_OR_SENT_MESSAGE: 'Selected bid(s) might have been sent or edited by other users, or not all ' +
      'required fields are filled in. Please try again.',
    CONFIRM_SEND_BID: 'Do you want to send this Bid to ',
    CONFIRM_SEND_BIDS: 'Do you want to send these Bids?',
    CONFIRM_ACCEPT_BID: 'You currently do not have any Accepted Drug Scenarios within this Bid. Are you sure that you ' +
      'still want to Accept this Bid?',
    CONFIRM_DECLINE_BID_MESSAGE_PLACEHOLDER_TEMPLATE: 'Type here to add a message in the Bid\'s Comments section',
    WARNING_HIDDEN_BIDS: [
      'Some RFPs/Bids may be hidden since your view is set to <strong>My Accounts</strong>',
      'Some RFPs/Bids are hidden by the applied <strong>filters</strong>',
      'Some RFPs/Bids may be hidden since your view is set to <strong>My Accounts</strong> or by the applied <strong>filters</strong>'
    ],
    ARCHIVED_BID_TOOLTIP: 'This RFP/Bid has been archived and is only available if you upgrade, click here to learn more'
  },
  COMPANY_DETAILS_MSG: {
    COMPANY_NAME: 'Company name should be specified',
    COMPANY_SUBSIDIARIES: 'Pharma company cannot be saved with no subsidiaries',
    COMPANY_PHONE_NUMBER: 'Company phone number should be specified',
    COMPANY_ADMIN_FIRST_NAME: 'Company admin first name should be specified',
    COMPANY_ADMIN_LAST_NAME: 'Company admin last name should be specified',
    COMPANY_ADMIN_TITLE: 'Company admin title should be specified',
    COMPANY_ADMIN_PHONE: 'Company admin phone should be specified',
    COMPANY_ADMIN_EMAIL: 'Company admin email should be specified',
    COMPANY_ADMIN_INVALID_EMAIL: 'Company admin email is invalid. Please verify spelling and try again.',
    COMPANY_ADMIN_TIMEZONE: 'Company admin timezone should be specified',
    COMPANY_SAVED_SUCCESS_MSG: 'Successfully saved company',
    CUSTOM_DRUG_SAVE_ERROR: 'Failed to save custom drug',
    LOGO_UPLOADED: 'Logo',
    DRUG_NAME_ERROR: 'Custom drug name should be specified',
    DRUG_CLASS_ERROR: 'Custom drug class should be specified',
    NDC_ERROR: 'Please select a number of NDCs to generate for the custom drug',
    NO_CUSTOM_DRUGS_DEFINED: 'No custom drugs defined',
    CUSTOM_DRUGS: 'Custom Drugs',
    DRUG_NAME: 'Drug Name',
    DRUG_CLASS: 'Drug Class',
    NDC: 'NDCs'
  },
  LOGIN_MESSAGES: {
    DISABLED_ACCOUNT: 'Account disabled',
    DISABLED_ACCOUNT_MESSAGE: 'This account was disabled, please contact your company administrator.',
    PASSWORD_NOT_MATCH: 'Passwords did not match. Please check the spelling and try again.',
    PASSWORD_NOT_FOLLOW_RULE: 'Password does not follow the rule: at least 8 characters with an uppercase letter, a ' +
      'lowercase letter and a digit',
    RESET_WELCOME: 'Welcome to Quantuvis',
    RESET_SUBTITLE: 'In order to continue, you must set your password',
    RESET_PASSWORD_REQUIREMENTS: 'Password requirements are: at least 8 characters with an uppercase letter, a lowercase ' +
      'letter and a number',
    LOG_IN_MESSAGE: 'Log In',
    LOGIN_HEADER: 'Login',
    FORGOT_PASSWORD: 'Forgot password?',
    FORGOT_PASSWORD_MESSAGE: 'Please enter email address used to sign up with Quantuvis',
    ACCEPT_TOS_PP_START: 'I have read and agree to the ',
    ACCEPT_TOS_PP_END: ' and ',
    PAGE_NOT_FOUND: 'The Page Cannot Be Found',
    PAGE_NOT_FOUND_MESSAGE: 'The page you are looking for might have been removed, had its name changed, or is temporarily unavailable',
    GO_TO_LOGIN: 'Go To Log In',
    INVITATION_EXPIRED: 'Invitation Expired',
    ACCOUNT_NOT_ACTIVE: 'Account Not Active',
    ACCOUNT_NOT_ACTIVE_MESSAGE: 'This account has not been activated. Please use the link inside the account activation email to login.',
    LOGIN_REDIRECT_TO_CM: 'Redirecting you to Quantuvis Contract Management.',
    CONTINUE: 'Continue',
    CANCEL: 'Cancel'
  },
  GENERAL_TERM_ERRORS: {
    RFP_TITLE_ALPHANUMERIC_ONLY: 'Title must not contain special characters',
    CONTRACTED_BUSINESS_NAME_NOT_EMPTY: 'Please fill in the Contracted Business name',
    DUPLICATED_CONTRACTED_BUSINESS_NAME_NOT_EMPTY: 'Please fill in the duplicate name for Contracted Business',
    CONTRACTED_BUSINESS_NAME_NOT_UNIQUE: 'The Contracted Business name already exists',
    CONTRACTED_BUSINESS_NAME_MAX_LENGTH_ERROR_MESSAGE: 'Contracted Business name should be maximum 55 characters long',
    LEGAL_ATTESTATION_MISSING: 'Legal Attestation is mandatory for Binding Bids, contact your Company Administrator to add one'
  },
  GENERAL: {
    PHARMA: 'Pharma',
    PAGE_DOES_NOT_EXIST: 'The page you are trying to access does not exist',
    LOADING_LIST: 'Loading... ',
    COPYING_BIDS: 'Copying bids...',
    RESTRICTED_ACCESS_ERROR: 'You are not allowed to access this information. Please contact your company administrator',
    RESTRICTED_OPERATION_ERROR: 'You are not allowed to perform this operation. Please contact your company administrator',
    OPERATION_GENERAL_ERROR: 'The operation could not be performed. Please contact Customer Quantuvis Support for assistance',
    OPERATION_UNAUTHORIZED_ERROR: 'You are not authorized to perform this operation. Please contact Customer Quantuvis ' +
      'Support for assistance',
    ACCESS_UNAUTHORIZED_ERROR: 'You are not authorized to access this information. Please contact Customer Quantuvis ' +
      'Support for assistance',
    BID_NOT_FOUND_ERROR: 'The requested bid could not be found',
    MARKET_BASKET_NOT_FOUND_ERROR: 'The requested market basket could not be found',
    ERROR: 'An error has occurred while performing the operation.',
    RESPONSE_REQUIRED: 'Response required.',
    CONFIRM_NAVIGATION_TITLE: 'Confirmation',
    CONFIRM_NAVIGATION_TEXT: 'You have not yet Saved your changes. All the data entered will be lost. Are you sure you ' +
      'want to navigate away? ',
    UPGRADE_MESSAGE: 'This feature is only available if you upgrade, click here to learn more.'
  },
  CONTRACTED_BUSINESS_ACTIONS: {
    OVERWRITE_CONTRACTED_BUSINESS: 'Are you sure you would like to overwrite contracted business ',
    OVERWRITE_THIS_CONTRACTED_BUSINESS: 'Are you sure you would like to overwrite this contracted business',
    OVERWRITE_WITH_CONTRACTED_BUSINESS: ' with contracted business ',
    DELETE_CONTRACTED_BUSINESS_WITH_NAME: 'Are you sure you would like to delete contracted business ',
    DELETE_THIS_CONTRACTED_BUSINESS: 'Are you sure you would like to delete this contracted business?'
  },
  DRUG_SEARCH_CONTEXT: {
    MARKET_BASKET: 'MB',
    CREATE_RFP: 'RFP'
  },
  POPUPS: {
    TITLES: {
      DISCARD_DRAFT: 'Discard Draft',
      SEND_BID: 'Send Bid',
      REOPEN_BID: 'Reopen Bid',
      ACCEPT_BID: 'Accept Bid',
      EDIT_BID: 'Edit Bid',
      DECLINE_BID: 'Decline Bid',
      DELETE_BID: 'Delete RFP',
      DELETE_BIDS: 'Delete RFP(s)',
      DELETE_CUSTOM_DRUG: 'Delete custom drug',
      DELETE_MARKET_BASKET: 'Delete Market Basket',
      RESET_VALUES: 'Reset Values',
      EDIT_DEPENDENCY: 'Edit dependency',
      ERROR: 'Error',
      SESSION_EXPIRED: 'Session expired'
    },
    SESSION_EXPIRED_MSG: 'Your editing session has been expired.',
    CONFIRM_DELETE_CUSTOM_DRUG: 'This operation cannot be undone. Are you sure you want to delete drug',
    CONFIRM_DELETE_BIDS: 'Do you want to delete this RFP(s)?',
    CONFIRM_DELETE_MARKET_BASKET: 'Do you want to delete this Market Basket?',
    RESET_DEPENDENT_TERMS_PP: 'You cannot fill out both the Net Effective Price and Price Protection fields. This action ' +
      'will delete the values. Please press YES if you want to delete the values or NO if you want to keep the existing value.',
    RESET_UM_DETAILS: 'You have populated all the Utilization Management fields as either “NO” or “blank”. Due to this, ' +
      'the UM Details field will be automatically cleared. Do you want to continue?',
    INVALID_TERM_VALUES_ERROR_MESSAGE: 'In order to send this Best and Final Bid, please populate either the Base Rebate ' +
      'or Net Effective Price for each individual drug scenario within the Bid. If Price Protection is "Required" on ' +
      'any drug scenario, populate the Net Effective Price or the other 5 fields within Price Protection.',
    RESET_TERM_VALUES_MSG: 'By selecting this option the existing values for the whole section will be deleted. Please ' +
      'select YES if you want to delete the values or NO if you want to cancel the action',
    COPY_SCENARIO: {
      TITLE: 'Copy & Paste Rules',
      DESCRIPTION: 'All values & locks will copy from the copied scenario/NDC into the pasted scenario(s)/NDC(s) except for:',
      DESCRIPTION_FOR_INTERNAL: 'All values will copy from the copied scenario/NDC into the pasted scenario(s)/NDC(s) except for:',
      MARKET_BASKETS: 'Market Baskets are copied if the scenario’s drug you are pasting it into is already included ' +
        'within the Market Basket. Market Baskets will only be pasted at the scenario level',
      MARKET_BASKETS_PAYER: 'Market Baskets are copied if the scenario’s drug you are pasting it into is already ' +
        'included within the Market Basket. Market Baskets & their lock will only be pasted at the scenario level',
      DEPENDENCIES: 'Dependencies',
      NDC_FIELD: 'Fields populated with <strong>“@NDC”</strong>',
      LOCKED_FIELD: 'Values will not be pasted into a locked field',
      BASE_REBATE: 'Minimum Base Rebate fields',
      PHARMA_ABILITY: 'Pharma’s ability to bid at the NDC level'
    },
    VIEW_AS: {
      TITLE: 'Open Bid',
      QUESTION: 'How would you like to open this RFP/BID?'
    }
  },
  MARKET_BASKET: {
    UNASSIGNED_LABEL: 'UNASSIGNED',
    UNASSIGNED_LABEL_WITH_OPEN_ROUND_BRACKETS: '(unassigned)',
    MODAL_TITLE: {
      EDIT: 'Edit Market Basket',
      EDIT_TEMPLATE: 'Edit Market Basket Template',
      NEW_TEMPLATE: 'New Market Basket Template',
      COPY_TEMPLATE: 'Copy Market Basket Template'
    }
  },
  MY_ACCOUNTS: {
    TOGGLE_TOOLTIP_AVAILABLE_FOR_PAYER: '<strong>All Accounts</strong><br />Display all pharma accounts on Dashboard, ' +
      'Bids and Notifications pages<br/><strong>My Accounts</strong><br />Display only my pharma accounts on Dashboard, ' +
      'Bids and Notifications pages<br/>',
    TOGGLE_TOOLTIP_AVAILABLE_FOR_PHARMA: '<strong>All Accounts</strong><br />Display all payer accounts on Dashboard, ' +
      'Bids and Notifications pages<br/><strong>My Accounts</strong><br />Display only my payer accounts on Dashboard, ' +
      'Bids and Notifications pages<br/>',
    TOGGLE_TOOLTIP_UNAVAILABLE: 'You must populate the <strong>My Accounts</strong> section of your ' +
      '<strong>Profile Settings</strong> to switch your Dashboard, Bids and Notifications pages between My Accounts and All Accounts'
  },
  UPGRADE_TO_ENTERPRISE: {
    UPGRADE_EMAIL_SUBJECT: 'I want to Upgrade to Quantuvis Plus!'
  },
  NOTIFICATION_TYPE: {
    SHARED_REPORT: 'Shared report'
  },
  REPORTS: {
    NEW_REPORT_TITLE: 'Untitled Report',
    ERRORS: {
      EMPTY_TITLE: {
        CODE: 1,
        TEXT: 'To save this report you have to populate the Title.'
      },
      LONG_TITLE: {
        CODE: 2,
        TEXT: 'Title should be maximum 55 characters long.'
      },
      NOT_UNIQUE_TITLE_MY_REPORTS: {
        CODE: 3,
        ACCESS_LEVEL: 'USER',
        TEXT: 'You already have a report with this Title. Please populate a different Title.'
      },
      NOT_UNIQUE_TITLE_COMPANY_REPORTS: {
        CODE: 3,
        ACCESS_LEVEL: 'COMPANY',
        TEXT: 'This Title already exists in Company Reports. Please populate a different Title.'
      },
      LONG_DESCRIPTION: {
        CODE: 4,
        TEXT: 'Description should be maximum 200 characters long.'
      }
    },
    MESSAGES: {
      UPDATE_SUCCESS: {
        TEXT: 'The report was successfully saved'
      },
      CREATE_SUCCESS: {
        TEXT: 'The report was successfully saved.'
      }
    },
    PLACEHOLDERS: {
      SHARED_REPORT_USER_SEARCH: 'Search by First Name and Last Name',
      SHARED_REPORT_MESSAGE: 'Enter your message here'
    },
    DELETE_CONFIRMATION_TITLE: 'Delete Report',
    DELETE_CONFIRMATION_MESSAGE: 'Do you want to delete this report?',
    DELETE_ERROR_TEXT: 'Error deleting report: ',
    EXPORT_ERROR_TEXT: 'Error has occurred during exporting report.',
    REPORT_IN_VIEW_MODE_TITLE: 'The report is in view mode'
  },
  TERM_SECTIONS: {
    CONTRACT_TERMS: {
      name: 'Contract Terms'
    }
  },
  PUBLIC_PAGES: {
    SECURE_SIGN_OUT: {
      SIGN_OUT_CONFIRMATION_TITLE: 'Sign out confirmation',
      SIGN_OUT_CONFIRMATION_MESSAGE: 'Do you really want to sign out from this device?',
      SIGN_OUT_ERROR: 'Sign out link is not valid, please try later',
      SIGN_OUT_TITLE: 'Secure sign out',
      SIGN_OUT_SUBTITLE: 'In order to sign out from this device, click this',
      SIGN_OUT_SUCCESS_TITLE: 'Password successfully changed',
      SIGN_OUT_SUCCESS_SUBTITLE: 'Your password was successfully changed. Please login to the system with your new password.',
      RESET_PASSWORD_TITLE: 'Reset your password',
      CHANGE_PASSWORD_TITLE: 'Change your password',
      RESET_PASSWORD_SUBTITLE: 'Enter your new password',
      RESET_PASSWORD_REQUIREMENTS: 'Password requirements are: at least 8 characters with an uppercase letter, a ' +
        'lowercase letter and a number',
      PASSWORD_NOT_FOLLOW_RULE: 'Password does not follow the rule: at least 8 characters with an uppercase letter, a ' +
        'lowercase letter and a digit',
      PASSWORD_NOT_MATCH: 'Passwords did not match. Please check the spelling and try again.'
    }
  },
  TOOLTIPS: {
    BID_ACTIONS: {
      ACCEPT: 'Accept the Bid from Pharma',
      EDIT: 'Edit the Bid',
      COPY: 'Copy the Bid',
      REOPEN: 'Re-open the Bid for negotiations with Pharma',
      SEND: 'Send the Bid to the trading party',
      REVIEW: 'Conduct an internal evaluation of the Bid',
      CREATE_NEW_RFP: 'Create new rebate RFPs for Pharma',
      PRINT: 'Create a PDF of the Bid',
      PRINT_LIMIT_TOOLTIP: 'Print option is not available for more than 30 Bids at a time',
      EXPORT: 'Create an Excel spreadsheet of the Bid',
      BID_HISTORY: 'Access the Bid’s history and revisions',
      CURRENT_VERSION: 'View the most current version of the Bid',
      EXPORT_OFFERED_VERSIONS: 'Create an Excel spreadsheet of the Bid’s offered versions',
      REVISION_COMPARISON: 'View the changes between offered versions of a Bid',
      DELETE: 'Delete RFPs that are not yet sent to Pharma',
      DETAILS: 'Show/Hide details panel',
      COMPARE_OFFERED_BIDS: 'Compare the most recent sent version of the selected Bids',
      COMPARE_ALL_BIDS: 'Compare the most recent draft or version of the selected Bids',
      EXPORT_CURRENT_VERSION: 'Create an Excel spreadsheet of the selected Bid',
      PRINT_CURRENT_VERSION: 'Create a PDF of the selected Bid',
      RESPOND: 'Respond',
      DECLINE: 'Decline',
      PREVIOUS_VERSION: 'Previous version',
      NEXT_VERSION: 'Next version',
      DISCARD_DRAFT: 'Will permanently discard all changes to this draft',
      UNDO: 'Undo latest changes',
      REDO: 'Redo latest changes',
      BID_NOT_CONTAIN_SCENARIO: 'Bid doesn\'t contain this scenario status to generate Scenario Summary'
    },
    MAIN_MENU: {
      DASHBOARD: 'General information',
      BIDS: 'List of all Bids',
      MARKET_BASKETS: 'Manage your Standard Market Baskets',
      MANAGEMENT: 'Company administrative controls',
      DRUG_SEARCH: 'Research products and classes',
      REPORTS: 'Create real-time ad hoc reports',
      PROFILE_ICON: 'Personal profile and Quantuvis information',
      PROFILE_SETTINGS: 'Manage your personal profile and assigned accounts',
      DEVICE_ACTIVITY: 'Review recent device activity on your account',
      CONTACT_QUANTUVIS: 'Quantuvis’ contact information',
      HELP: 'Quantuvis training materials and FAQs'
    },
    LOI: {
      UPLOAD_TEMPLATE: 'To upload Letter of Intent template just select (.docx) file. Before uploading check that all ' +
        'placeholders are correct.',
      DELETE: 'Delete template',
      EXPORT: 'Export template'
    },
    CONTACTS: {
      ADD: 'Add New Contact',
      DELETE: 'Delete Contact',
      EXPORT: 'Create an Excel spreadsheet',
      ADD_COLUMN: 'Add New Column',
      ADD_COLUMN_DISABLED: 'Button is disabled because you have reached the limit of 10 extra columns. Please delete ' +
        'some columns to add a new one',
      EDIT_COLUMN: 'Edit Column',
      DELETE_COLUMN: 'Delete Column'
    }
  }
};
