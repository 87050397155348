import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FileUpload } from 'primeng/fileupload';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { resources } from '@qv-common/static/resources';
import { BlockingMessage, ErrorMessage, SvgIconName } from '@qv-common/enums';
import { FileExtension } from '@qv-common/enums/file';
import { LegalAttestationService } from '@qv-company/services';
import { File } from '@qv-common/models/core';
import { PermissionService } from '@qv-common/services/auth/permission.service';
import { NotificationService } from 'quantuvis-angular-common/notification';
import { FieldValidationMessage } from '@qv-common/enums/field-validation.enum';
import { NumberUtils } from '@qv-common/utils/number.utils';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { catchError, filter, finalize, switchMap, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { CoreUtils } from '@qv-common/utils/core.utils';
import { appConfig } from '@qv-common/configs';
import { UserService } from '@qv-common/services';

@Component({
  selector: 'qv-legal-attestation',
  templateUrl: './legal-attestation.component.html',
  styleUrls: ['./legal-attestation.component.scss']
})
export class LegalAttestationComponent implements OnInit, OnDestroy {
  public static readonly LOADING_MSG = BlockingMessage.LOADING;

  @BlockUI()
  public blockUI: NgBlockUI;
  @ViewChild('fileUploader')
  public fileUploader: FileUpload;

  public readonly svgIconName = SvgIconName;
  public readonly tooltips = resources.TOOLTIPS;
  public readonly acceptFileExtension = FileExtension.PDF;
  public readonly uploadTooltip = 'Upload new Legal Attestation Document (PDF only)';
  public readonly exportTooltip = 'Export Legal Attestation Document';
  public readonly maxFileSize = appConfig.maxFileSize;
  public readonly maxFileSizeMessage = FieldValidationMessage.MAX_FILE_SIZE_MESSAGE.replace('{0}',
    `${NumberUtils.bytesToMegabytes(appConfig.maxFileSize)} MB`);
  public readonly fileFormatNotValidMessage = FieldValidationMessage.FILE_FORMAT_NOT_VALID.replace('{0}',
    FileExtension.PDF);
  public readonly filePrefix = 'Current Legal Attestation Document:';

  public file: File;
  public isReady: boolean;
  public uploadUrl: string;

  constructor(
    public userService: UserService,
    private permissionService: PermissionService,
    private legalAttestationService: LegalAttestationService,
    private notificationService: NotificationService
  ) {}


  public ngOnDestroy(): void {
    this.notificationService.clear();
  }

  public ngOnInit(): void {
    this.setUploadUrl();
    this.loadFile();
  }

  public isActionAllowed(): boolean {
    return this.permissionService.isPayerAdminHasWriteAccess();
  }

  public hasFile(): boolean {
    return !!(this.file && this.file.content);
  }

  public selectFile(event: Event): void {
    this.fileUploader.onFileSelect(event);
    const { msgs } = this.fileUploader;
    if (msgs && msgs.length) {
      msgs.forEach((msg) => {
        this.notificationService.error(msg.detail);
      });
    }
  }

  public onBeforeSendHandler(): void {
    this.blockUI.start(LegalAttestationComponent.LOADING_MSG);
  }

  public onUploadHandler(event: { originalEvent: HttpResponse<{ data: File }>, files: File[] }): void {
    try {
      const fileMeta = event.originalEvent.body.data;
      if (CoreUtils.isNotDefined(fileMeta) || CoreUtils.isNull(fileMeta)) return;

      this.legalAttestationService.getFile(fileMeta.id)
        .pipe(
          tap((content: ArrayBuffer) => {
            if (!content || content.byteLength === 0) {
              this.notificationService.error(ErrorMessage.COMMON_SERVER_ERROR);
            }
          }),
          finalize(() => this.blockUI.stop()),
          filter((content: ArrayBuffer) => content && content.byteLength !== 0),
          catchError((error: HttpErrorResponse) => this.notificationService.showServerError(error)),
        )
        .subscribe((content: ArrayBuffer) => {
          this.file = fileMeta;
          this.file.content = content;
        });
    } catch (error) {
      this.blockUI.stop();
      this.notificationService.error(ErrorMessage.COMMON_SERVER_ERROR);
    }
  }

  public onErrorHandler({ error }: { error: HttpErrorResponse }): void {
    this.blockUI.stop();
    this.notificationService.showServerError(error);
  }

  public exportFile(): void {
    if (this.file && this.file.id) {
      this.legalAttestationService.exportFile(this.file)
        .pipe(catchError((error: HttpErrorResponse) => this.notificationService.showServerError(error)))
        .subscribe();
    }
  }

  public loadFile(): void {
    this.blockUI.start(LegalAttestationComponent.LOADING_MSG);
    this.loadFileMeta()
      .pipe(
        tap(() => {
          if (!this.file || !this.file.id) {
            this.allowPageRendering();
          }
        }),
        filter(() => !!this.file && !!this.file.id),
        switchMap(() => this.loadFileContent())
      )
      .subscribe((content: ArrayBuffer) => {
        if (!content || content.byteLength === 0) {
          this.allowPageRendering();
          this.notificationService.error(ErrorMessage.COMMON_SERVER_ERROR);
        } else {
          this.file.content = content;
        }
      });
  }

  private loadFileMeta(): Observable<File> {
    return this.legalAttestationService.getFileMeta()
      .pipe(
        tap((fileMeta: File) => this.file = fileMeta),
        catchError((error: HttpErrorResponse) => {
          this.allowPageRendering();
          return this.notificationService.showServerError(error);
        })
      );
  }

  private loadFileContent(): Observable<ArrayBuffer> {
    return this.legalAttestationService.getFile(this.file.id).pipe(
      finalize(() => this.allowPageRendering()),
      catchError((error: HttpErrorResponse) => this.notificationService.showServerError(error))
    );
  }

  private allowPageRendering(): void {
    this.isReady = true;
    this.blockUI.stop();
  }

  private setUploadUrl(): void {
    this.uploadUrl = this.legalAttestationService.getUploadUrl();
  }
}
