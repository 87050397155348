import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Params, Router, RouterEvent } from '@angular/router';
import { AppRoute, AuthenticationRoute } from '@qv-common/enums';
import { QvCache } from '@qv-common/decorators';
import { WINDOW_TOKEN, WindowEvent } from 'quantuvis-angular-common/window-utils';
import { SystemMenuItem } from 'angular-components-common';
import { CoreDomUtilsService } from '../../../../../webapp/app/shared/services/core-dom-utils.service';
import { BehaviorSubject, combineLatest, fromEvent } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SystemMenuService } from '@qv-bootstrap/services';
import { UserService } from '@qv-common/services/auth/user.service';

@UntilDestroy()
@Component({
  selector: 'qv-root',
  templateUrl: './root.component.html',
  styleUrls: ['./root.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RootComponent implements OnInit {
  public readonly isHeaderAvailable$ = new BehaviorSubject<boolean>(false);
  public readonly isSystemMenuAvailable$ = new BehaviorSubject<boolean>(false);
  public readonly menuConfig: (SystemMenuItem | string)[] = this.systemMenuService.menuConfig;
  private readonly resizeDebounceTime = 500;
  private readonly routesWithoutNavigation = [
    AuthenticationRoute.FORGOT_PASSWORD, AppRoute.ACCESS_DENIED
  ];

  constructor(
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public userService: UserService,
    private systemMenuService: SystemMenuService,
    private coreDomUtilsService: CoreDomUtilsService,
    @Inject(WINDOW_TOKEN) private window: Window
  ) {}

  public ngOnInit(): void {
    this.onShowNavigationHandler();
    this.onResizeHandler();
  }

  public onResizeHandler(): void {
    fromEvent(this.window, WindowEvent.RESIZE).pipe(debounceTime(this.resizeDebounceTime), untilDestroyed(this))
      .subscribe(() => this.coreDomUtilsService.recalculateFixHeaders());
  }

  private onShowNavigationHandler(): void {
    combineLatest([
      this.userService.isUserLoggedIn,
      this.activatedRoute.queryParams,
      this.router.events,
    ])
      .pipe(
        filter(([, , event]: [boolean, Params, RouterEvent]) => event instanceof NavigationEnd),
        untilDestroyed(this)
      )
      .subscribe(([isUserLoggedIn, params, event]: [boolean, Params, NavigationEnd]) => {
        this.isHeaderAvailable$.next(this.isHeaderAvailable(isUserLoggedIn, params.noHeader, event.urlAfterRedirects));
        this.isSystemMenuAvailable$.next(this.isSystemMenuAvailable(isUserLoggedIn, params.noHeader));
      });
  }

  private isHeaderAvailable(isUserLoggedIn: boolean, noHeader: string, currentRouteName: string): boolean {
    return isUserLoggedIn && !this.isNoHeader(noHeader)
      && !this.routesWithoutNavigation.some((url: string) => currentRouteName.includes(url));
  }

  private isSystemMenuAvailable(isUserLoggedIn: boolean, noHeader: string): boolean {
    return isUserLoggedIn && !this.isNoHeader(noHeader);
  }

  @QvCache()
  private isNoHeader(noHeader: string): boolean {
    return noHeader === 'true';
  }
}
