import { DictionaryItem } from 'quantuvis-angular-common/interfaces';

export enum ScenarioStatus {
  DRAFT = 1,
  FORECAST_MODELING,
  IN_NEGOTIATION,
  INTERNAL_ACCEPTANCE,
  QUALITY_ASSURANCE,
  IN_CONTRACTING,
  ACTIVE,
  ASSUMPTION_FILE = 195,
}

export namespace ScenarioStatus {
  const valuesMap = new Map<number, DictionaryItem>([
    [ScenarioStatus.DRAFT, { id: ScenarioStatus.DRAFT, name: 'Draft' }],
    [ScenarioStatus.FORECAST_MODELING, { id: ScenarioStatus.FORECAST_MODELING, name: 'Forecast Modeling' }],
    [ScenarioStatus.IN_NEGOTIATION, { id: ScenarioStatus.IN_NEGOTIATION, name: 'In Negotiation' }],
    [ScenarioStatus.ASSUMPTION_FILE, { id: ScenarioStatus.ASSUMPTION_FILE, name: 'Assumption File' }],
    [ScenarioStatus.INTERNAL_ACCEPTANCE, { id: ScenarioStatus.INTERNAL_ACCEPTANCE, name: 'Internal Acceptance' }],
    [ScenarioStatus.QUALITY_ASSURANCE, { id: ScenarioStatus.QUALITY_ASSURANCE, name: 'Quality Assurance' }],
    [ScenarioStatus.IN_CONTRACTING, { id: ScenarioStatus.IN_CONTRACTING, name: 'In Contracting' }],
    [ScenarioStatus.ACTIVE, { id: ScenarioStatus.ACTIVE, name: 'Active' }]
  ]);

  export function getValue(index: ScenarioStatus): DictionaryItem {
    return valuesMap.get(index);
  }

  export function getValues(): DictionaryItem[] {
    return Array.from(valuesMap.values());
  }
}
