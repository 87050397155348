import { createAction, props } from '@ngrx/store';
import { FieldDropdownOptions } from '../../entities/field-dropdown-options.entity';

export namespace FieldsDropdownOptionsActions {
  export const get = createAction(
    '[fields dropdown options] get'
  );

  export const setPendingStatus = createAction(
    '[fields dropdown options] set pending status',
  );

  export const getResolved = createAction(
    '[fields dropdown options] get resolved',
    props<{ data: FieldDropdownOptions[] }>()
  );

  export const getRejected = createAction(
    '[fields dropdown options] get rejected'
  );
}
