import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { BlockingMessage } from '@qv-common/enums';
import { MenuActionItem } from '@qv-bid/components/shared/drug-actions-menu/models';
import { resources } from '@qv-common/static';
import { BidStateService, ContractedBusinessesService, PharmaRightsService } from '@qv-bid/services';
import { Observable, of } from 'rxjs';
import { finalize, take } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DrugSelectUtilsService } from '@qv-bid/services/selects';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ScenariosDependencyService } from '@qv-bid/services/scenarios-dependency.service';
import { DrugEditActionsAccessibilityService, DrugEditActionsService } from '@qv-bid/services/drug';

@UntilDestroy()
@Component({
  selector: 'qv-drug-actions-menu',
  templateUrl: './drug-actions-menu.component.html',
  styleUrls: ['./drug-actions-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DrugActionsMenuComponent implements OnInit {
  @BlockUI()
  public blockUI: NgBlockUI;
  @Input()
  public cbId: number;
  @Input()
  public bidVersionId: number;
  @Input()
  public pharmaId: number;
  @Input()
  public isBidInternal: boolean;

  public editMenuItems: MenuActionItem[];
  public isBidInvalid$: Observable<boolean>;

  constructor(
    public bidStateService: BidStateService,
    private pharmaRightsService: PharmaRightsService,
    private drugEditActionsService: DrugEditActionsService,
    private drugEditActionsAccessibilityService: DrugEditActionsAccessibilityService,
    private contractedBusinessesService: ContractedBusinessesService,
    private scenariosDependencyService: ScenariosDependencyService,
    private drugSelectUtilsService: DrugSelectUtilsService,
  ) {}

  public ngOnInit(): void {
    // TODO: Delete if when multi cb historicNotes load will be implemented
    if (this.bidVersionId && this.cbId) {
      this.contractedBusinessesService.loadHistoricNotesPresents(this.bidVersionId, this.cbId)
        .pipe(
          take(1),
          untilDestroyed(this)
        )
        .subscribe();
    }

    this.initEditMenuItems();
    this.isBidInvalid$ = this.bidStateService.isBidInvalid().pipe(untilDestroyed(this));
  }

  private initEditMenuItems(): void {
    this.editMenuItems = [
      new MenuActionItem(
        resources.DRUG_ACTIONS.CREATE_SCENARIO,
        () => this.drugEditActionsService.createScenarios(),
        this.drugEditActionsAccessibilityService.isCreateScenarioActionDisabled(),
        this.pharmaRightsService.isAllowCreateScenarios(),
      ),
      new MenuActionItem(
        resources.DRUG_ACTIONS.DELETE_SCENARIO,
        () => this.drugEditActionsService.deleteScenarios(),
        this.drugEditActionsAccessibilityService.isDeleteScenarioActionDisabled(),
        this.pharmaRightsService.isAllowCreateScenarios(),
      ),
      new MenuActionItem(
        resources.DRUG_ACTIONS.DISMISS_SCENARIO,
        () => this.drugEditActionsService.dismissDrugs(),
        this.drugSelectUtilsService.isDrugSelected()
      ),
      new MenuActionItem(
        resources.DRUG_ACTIONS.RECALL_SCENARIO,
        () => this.drugEditActionsService.recallDrugs(),
        this.drugSelectUtilsService.isDrugSelected()
      ),
      new MenuActionItem(
        resources.DRUG_ACTIONS.CREATE_DEPENDENCY,
        () => this.scenariosDependencyService.createDependency(),
        this.scenariosDependencyService.isCreateDependencyDisabled(),
        this.pharmaRightsService.isAllowDependencies()
      ),
      new MenuActionItem(
        resources.DRUG_ACTIONS.UNLOCK_NDC,
        () => this.drugEditActionsService.changeLockForNdcs(false),
        this.drugEditActionsAccessibilityService.isChangeLockForNdcsDisabled(false),
        this.drugEditActionsAccessibilityService.isChangeLockForNdcsAvailable()
      ),
      new MenuActionItem(
        resources.DRUG_ACTIONS.LOCK_NDC,
        () => this.drugEditActionsService.changeLockForNdcs(true),
        this.drugEditActionsAccessibilityService.isChangeLockForNdcsDisabled(true),
        this.drugEditActionsAccessibilityService.isChangeLockForNdcsAvailable()
      ),
      new MenuActionItem(
        resources.DRUG_ACTIONS.ADD_DRUG,
        () => this.drugEditActionsService.openAddDrugModal(this.pharmaId),
      ),
      new MenuActionItem(
        resources.DRUG_ACTIONS.DELETE_DRUGS,
        () => this.actionHandlerBlockUIWrapper(
          this.drugEditActionsService.openDeleteDrugModal(this.isBidInternal),
          BlockingMessage.LOADING
        ),
        of(false),
        this.drugEditActionsAccessibilityService.isDeleteDrugActionAvailable()
      ),
      new MenuActionItem(
        resources.DRUG_ACTIONS.DELETE_HISTORIC_NOTES,
        () => this.actionHandlerBlockUIWrapper(
          this.drugEditActionsService.deleteHistoricNotes(this.bidVersionId, this.cbId),
          BlockingMessage.DELETING
        ),
        this.drugEditActionsAccessibilityService.isDeleteHistoricNotesDisabled(),
        this.drugEditActionsAccessibilityService.isDeleteHistoricNotesAvailable()
      ),
      new MenuActionItem(
        resources.DRUG_ACTIONS.COPY_VALUES,
        () => this.drugEditActionsService.copyValues(),
        this.drugSelectUtilsService.isOneScenarioOrOneNdcSelected()
      ),
      new MenuActionItem(
        resources.DRUG_ACTIONS.PASTE_VALUES,
        () => this.drugEditActionsService.pasteValues(),
        this.drugSelectUtilsService.isDrugSelected(),
        this.drugEditActionsAccessibilityService.isDragCopyExistInStorage()
      ),
    ];
  }

  private actionHandlerBlockUIWrapper(action: Observable<void>, message: BlockingMessage): void {
    this.blockUI.start(message);
    action.pipe(take(1), finalize(() => this.blockUI.stop())).subscribe();
  }
}
