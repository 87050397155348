import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { StandardViewComponent } from '@qv-bid/components/standard-view';
import { IntendToRespondComponent } from '@qv-bid/components/shared/intend-to-respond/intend-to-respond.component';
import { Permission } from '@qv-common/enums';
import { EmptyComponent } from '@qv-shared/components';
import {
  ArchivedBidGuard,
  BidViewGuard,
  HistoricBidGuard,
  WriteAccessToBidGuard,
  PharmaCanRespondGuard
} from '@qv-bid/guards';
import { RedirectToNewViewResolver } from '@qv-bid/resolves/redirect-to-new-view.resolver';
import { IntendToRespondResolver } from '@qv-bid/resolves/intend-to-respond.resolver';
import { NoneAccessGuard } from '@qv-common/guards';

const routes: Routes = [
  {
    path: 'standard/bid',
    component: StandardViewComponent,
    data: { permission: Permission.BIDS },
    canActivate: [BidViewGuard]
  },
  {
    path: 'bid',
    component: EmptyComponent,
    resolve: { drugs: RedirectToNewViewResolver }
  },
  {
    path: 'itr',
    component: IntendToRespondComponent,
    resolve: { drugs: IntendToRespondResolver },
    canActivate: [NoneAccessGuard, WriteAccessToBidGuard, PharmaCanRespondGuard]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
  ],
  providers: [
    ArchivedBidGuard,
    BidViewGuard,
    HistoricBidGuard,
  ]
})
export class BidRoutingModule { }
