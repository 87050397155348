import { ApiService } from '@qv-common/services/api';
import { JsonConvert } from 'json2typescript';
import { map } from 'rxjs/operators';
import { Inject, Injectable } from '@angular/core';
import { HttpResponseWrapper } from '@qv-common/models/api';
import { Observable } from 'rxjs';
import { ApiUrlPrefix } from '@qv-common/enums';
import { CompanyInsightsConfig, InsightDashboard } from '@qv-insights/entities';
import { JSON_CONVERT_TOKEN } from 'quantuvis-angular-common/json-converter/services';

@Injectable()
export class InsightsDaoService {

  constructor(private apiService: ApiService, @Inject(JSON_CONVERT_TOKEN) private jsonConvert: JsonConvert) {}

  public getCompanyInsightsConfig(): Observable<CompanyInsightsConfig> {
    const url = `${ApiUrlPrefix.SYSTEM_ADMINISTRATION}/applications/company-insights`;

    return this.apiService.get(url).pipe(map((response: HttpResponseWrapper<CompanyInsightsConfig>) =>
      this.jsonConvert.deserializeObject(response.body.data, CompanyInsightsConfig)
    ));
  }

  public getDashboards(folderId: string): Observable<InsightDashboard[]> {
    const url = `${ApiUrlPrefix.SYSTEM_ADMINISTRATION}/applications/insights/folders/${folderId}/dashboards`;

    return this.apiService.get(url).pipe(map((response: HttpResponseWrapper<InsightDashboard[]>) =>
      this.jsonConvert.deserializeArray(response.body.data, InsightDashboard)
    ));
  }

  public getEmbeddedDashboardLink(id: string): Observable<string> {
    const url = `${ApiUrlPrefix.SYSTEM_ADMINISTRATION}/applications/insights/dashboards/${id}/generate-embedded-link`;

    return this.apiService.get(url)
      .pipe(map((response: HttpResponseWrapper<string>) => response.body.data));
  }
}
