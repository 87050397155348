import { resources } from '@qv-common/static';
import { drugTermsConstants } from '@qv-term/constants';
import { TermName } from '@qv-term/enums';
import { BooleanChoice } from '@qv-term/enums/options';
import { SelectBoxTerm } from '@qv-term/models/types';

export class OtherUmTerm extends SelectBoxTerm {
  public defaultValueForOldBid: string;

  constructor(name: string) {
    super(
      name,
      drugTermsConstants[TermName.OTHER_UM].title,
      resources.TermsDescriptions.DRUG_LIST.OTHER_UM,
      '',
      '',
      BooleanChoice.getValue(BooleanChoice.NO),
      'term__other-um'
    );

    this.values = BooleanChoice.getValues();
    this.defaultValueForOldBid = BooleanChoice.getValue(BooleanChoice.EMPTY).name;
  }
}
