import { JsonObject, JsonProperty } from 'json2typescript';
import { BidVersionTypeConverter, DateConverter } from '@qv-bid/serializers';
import { Moment } from 'moment';
import { BidVersionType } from '@qv-bid/enums';
import { Company } from 'quantuvis-core-entities';

@JsonObject('BidVersion')
export class BidVersion {
  @JsonProperty('id', Number)
  public id: number = null;

  @JsonProperty('versionType', BidVersionTypeConverter)
  public versionType: BidVersionType = null;

  @JsonProperty('updatedAt', DateConverter)
  public updatedAt: Moment = null;

  @JsonProperty('authorCompany', Company)
  public authorCompany: Company = null;
}
