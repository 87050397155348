import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BidRoutingModule } from '@qv-bid/bid.routing';
import { LogoutGuard } from '@qv-common/guards';
import { InsightsRoutingModule } from '@qv-insights/insights.routing';
import { CompanyRoutingModule } from '@qv-company/company.routing';
import { AppRoute, AuthenticationRoute } from '@qv-common/enums';
import { EmptyComponent } from '@qv-shared/components';
import { AccessDeniedComponent } from 'angular-components-common';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '/dashboard'
  },
  {
    path: AppRoute.GRID,
    loadChildren: () => import('@qv-bid-grid-view/bid-grid-view.module').then(m => m.BidGridViewModule),
  },
  {
    path: AppRoute.BIDS,
    loadChildren: () => import('@qv-bid-list/bid-list.module').then(m => m.BidListModule),
  },
  {
    path: AppRoute.BID_HISTORY,
    loadChildren: () => import('@qv-bid-history/bid-history.module').then(m => m.BidHistoryModule),
  },
  {
    path: AppRoute.MARKET_BASKET_TEMPLATES,
    loadChildren: () => import('@qv-market-basket-management/market-basket-management.module')
      .then(m => m.MarketBasketManagementModule),
  },
  {
    path: AppRoute.LOI,
    loadChildren: () => import('@qv-loi/loi.module').then(m => m.LoiModule),
  },
  {
    path: AppRoute.CREATE_BID,
    loadChildren: () => import('@qv-bid-wizards/create-rfp-wizard/create-rfp-wizard.module')
      .then(m => m.CreateRfpWizardModule),
  },
  {
    path: AppRoute.INTERNAL_FIELDS,
    loadChildren: () => import('@qv-manage-internal-fields/manage-internal-fields.module')
      .then(m => m.ManageInternalFieldsModule),
  },
  {
    path: AuthenticationRoute.LOGIN,
    redirectTo: AppRoute.AUTH,
  },
  {
    path: AppRoute.AUTH,
    loadChildren: () => import('@qv-auth/auth-lazy-load.module').then(m => m.AuthLazyLoadModule),
  },
  {
    path: AppRoute.LOGOUT,
    component: EmptyComponent,
    canActivate: [LogoutGuard],
  },
  {
    path: AppRoute.ACCESS_DENIED,
    component: AccessDeniedComponent,
  },
  {
    path: '**',
    redirectTo: '/dashboard'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      enableTracing: false,
      onSameUrlNavigation: 'ignore',
      relativeLinkResolution: 'legacy'
    }),
    BidRoutingModule,
    CompanyRoutingModule,
    InsightsRoutingModule,
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
