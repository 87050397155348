import { Inject, Injectable, Optional } from '@angular/core';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateUtils, StringUtils } from '@qv-common/utils';
import moment, { Moment } from 'moment';

@Injectable()
export class CenturyDateAdapter extends MomentDateAdapter {

  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(@Optional() @Inject(MAT_DATE_LOCALE) dateLocale: string) {
    super(dateLocale);
  }

  public parse(value: any, parseFormat: string | string[]): Moment | null {
    if (value && StringUtils.isString(value)) {
      return this.getFormattedDate(value, parseFormat as string);
    }

    return value ? moment(value).locale(this.locale) : null;
  }

  public deserialize(value: any): Moment | null {
    let date = null;
    if (value instanceof Date) {
      date = moment(value);
    }
    if (typeof value === 'string') {
      if (!value) {
        return null;
      }
      date = moment(new Date(value), moment.ISO_8601).locale(this.locale);
    }
    if (date && this.isValid(date)) {
      return date;
    }
    return super.deserialize(value);
  }

  private getFormattedDate(value: string, parseFormat: string): Moment {
    const date = new Date(value);

    if (date.getFullYear() < DateUtils.MIN_DATE_PICKER_YEAR) {
      date.setFullYear(date.getFullYear() + 100);
    }

    return moment(date, parseFormat, this.locale, true);
  }
}
