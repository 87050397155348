<div class="table-section__cell">
  <span class="table-section__title">{{ termConfig?.label }}:</span>
  <mat-icon
      [matTooltip]="termConfig?.description"
      [svgIcon]="svgIconName.INFO_OUTLINE"
      class="table-section__info">
  </mat-icon>
</div>
<div class="table-section__cell">
  <ng-content></ng-content>
</div>
