import { CheckboxItem } from '@qv-common/models';
import { FilterOption } from '@qv-common/entities';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { NotificationService } from 'quantuvis-angular-common/notification';
import { HttpErrorResponse } from '@angular/common/http';

export abstract class CheckboxFilter<T> {
  public filters = new Map<T, BehaviorSubject<CheckboxItem[]>>();

  constructor(public filterNames: T[], protected notificationService: NotificationService) {
    this.initFilters();
  }

  public loadFilterOptions(name: T): void {
    this.filters.get(name).next(null);
    this.updateFilterOptions(name).pipe(
      map((options: FilterOption[]) => this.mapFilterOptionsToCheckboxItems(this.getFilterState(name), options)),
      catchError((response: HttpErrorResponse) => this.notificationService.showServerError(response))
    ).subscribe((options: CheckboxItem[]) => this.filters.get(name).next(options));
  }

  public onFilterChange(name: T, checkboxFilterOptions: CheckboxItem[]): void {
    const selectedItemIds = this.getSelectedFilterItemIds(checkboxFilterOptions);
    const filterValue = selectedItemIds.length ? selectedItemIds : undefined;

    this.filters.get(name).next(checkboxFilterOptions);
    this.updateFilterState(name, filterValue);
  }

  protected abstract updateFilterState(name: T, filterValue: (string | number)[]): void;

  protected abstract getFilterState(name: T): (string | number)[];

  protected abstract updateFilterOptions(name: T): Observable<FilterOption[]>;

  protected resetFilter(): void {
    this.filters.forEach((filter: BehaviorSubject<CheckboxItem[]>) => filter.next([]));
  }

  protected initFilters(): void {
    this.filterNames.forEach((name: T) => this.filters.set(name, new BehaviorSubject([])));
  }

  protected mapFilterOptionsToCheckboxItems(
    filteredOptions: (string | number)[],
    options: FilterOption[]
  ): CheckboxItem[] {
    return options.map(({ id, value }: FilterOption) => {
      const isChecked = filteredOptions && filteredOptions.includes(id);
      return new CheckboxItem(id, value, value, isChecked);
    });
  }

  private getSelectedFilterItemIds(items: CheckboxItem[]): (number | string)[] {
    return items
      .filter((item: CheckboxItem) => item.checked)
      .map((item: CheckboxItem) => item.id);
  }
}
