<mat-menu [overlapTrigger]="false">
  <div class="state-menu">
    <button
      class="state-menu__button--reset-all"
      mat-menu-item
      (click)="onResetAll($event)"
    >
      <mat-icon [svgIcon]="svgIconName.RESET"></mat-icon>
      Reset All
    </button>
    <button
      mat-menu-item
      *ngFor="let section of menuItems"
      class="state-menu__button"
      [matMenuTriggerData]="{section: section}"
      [matMenuTriggerFor]="sectionColumns"
      (click)="onSectionItemClick($event, section)"
    >
      <mat-icon
        class="state-menu__icon"
        [svgIcon]="getSectionIcon(section) | async"
        [class.state-menu__icon--hidden]="isSectionHidden(section) | async"
        [class.state-menu__icon--partially-hidden]="isSectionPartiallyHidden(section) | async"
      ></mat-icon>
      {{termVisibilityMenuItems[section]}}
    </button>
  </div>
</mat-menu>

<mat-menu #sectionColumns [overlapTrigger]="false">
  <ng-template matMenuContent let-section="section">
    <div class="state-submenu">
      <ng-container *ngFor="let column of submenuItems.get(section)">
        <button
          mat-menu-item
          class="state-submenu__button"
          [matTooltip]="termVisibilitySubmenuItems[column]"
          (click)="onColumnItemClick($event, column)"
        >
          <mat-icon
            class="state-submenu__icon"
            [class.state-submenu__icon--hidden]="isColumnHidden(column) | async"
            [svgIcon]="getColumnIcon(column) | async"
          ></mat-icon>
          {{termVisibilitySubmenuItems[column]}}
        </button>
      </ng-container>
    </div>
  </ng-template>
</mat-menu>