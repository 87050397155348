import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { EnrollmentService } from '@qv-common/services';

@Injectable({
  providedIn: 'root'
})
export class EnrollmentGuard implements CanActivate {

  constructor(private enrollmentService: EnrollmentService) {}

  public canActivate(next: ActivatedRouteSnapshot): boolean {
    return this.enrollmentService.isEnrollmentInList(next.data.enrollmentId);
  }
}
