import { SvgIconName } from '@qv-common/enums';

export class SwitchButtonConfig<T> {
  constructor(
    public title: string,
    public tooltip: string,
    public icon: SvgIconName,
    public value: T,
  ) {}
}
