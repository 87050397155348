import { InputTerm } from '@qv-term/models/types';
import { ValidateNumberOptions } from '@qv-common/models/core';
import { KeepOriginalValue, Reload, Reset } from '@qv-common/interfaces';
import { NumberUtils } from '@qv-common/utils';
import { constants, resources } from '@qv-common/static';

export class AdministrationFeeTerm extends InputTerm implements Reset, KeepOriginalValue, Reload {
  public reloadNeeded: boolean;
  public allowKeepOriginValue: boolean;
  public processKeepOriginalValue: boolean;
  public keepOriginalValue: boolean;

  constructor(name: string) {
    super(
      name,
      name,
      resources.TermsDescriptions.CONTRACT.ADMINISTRATION_FEE,
      constants.CONTRACT_TERMS.ADMINISTRATION_FEE.label,
      '',
      '',
      'term__late-payment-penalty-value'
    );
    this.keepOriginalValue = this.allowKeepOriginValue;
    this.processKeepOriginalValue = false;
    this.suffix = this.unitsService.percent;
    this.reloadNeeded = true;
  }

  public getValueToSave(): string {
    // TODO need add KeepOriginal logic
    return super.getValueToSave();
  }

  public resetValue(): void {
    this.value = this.defaultValue;
  }

  public validateNumber(): void {
    const options = new ValidateNumberOptions(2, 0, 100, null);
    this.value = NumberUtils.correctNumber(options, this.value);
  }

}
