import { BaseTerm } from '@qv-term/models/types';
import { Reset, Validate } from '@qv-common/interfaces';
import { constants, resources } from '@qv-common/static';
import { drugTermsConstants } from '@qv-term/constants';
import { TermName } from '@qv-term/enums';

export class NotesTerm extends BaseTerm implements Validate, Reset {
  public lockName: string;
  public copyFromBidPath: string;

  constructor(name: string) {
    super(
      name,
      name,
      resources.TermsDescriptions.DRUG_LIST.NOTES,
      constants.DRUG_TERM_PATHS.ALLOW_NOTES[1],
      {
        historic: [],
        currentValue: ''
      },
      {
        historic: [],
        currentValue: ''
      },
      'term__notes'
    );

    this.copyFromBidPath = `${constants.BID_SECTIONS.DRUG_LIST_PATH}.
                                ${drugTermsConstants[TermName.NOTES].title.toLowerCase()}`;
    this.lockName = drugTermsConstants[TermName.NOTES].title.toLowerCase();
  }

  public validate(): boolean {
    throw new Error('Method not implemented.');
  }

  public resetValue(): void {
    this.value = this.defaultValue;
  }

  public getValueToSave(): string {
    return this.value;
  }
}
