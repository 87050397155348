import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { LockInfo } from '@qv-bid/entities';
import { BidService } from '@qv-bid/services/bid.service';
import { BidStateService } from '@qv-bid/services/bid-state.service';
import { BidViewHandleErrorService } from '@qv-bid/services/bid-view-handle-error.service';
import { BidEventBusService } from '@qv-bid/services/bid-event-bus.service';
import { BidLockDaoService } from '@qv-bid/services/dao';
import { BidUtils } from '@qv-bid/utils';
import { BlockingMessage } from '@qv-common/enums';
import { ViewPerspectiveService } from '@qv-common/services/auth';
import { resources } from '@qv-common/static';
import { TermEventBusService } from '@qv-term/services';
import moment from 'moment';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { HttpResponseErrorCode, HttpStatusCode } from 'quantuvis-angular-common/api';
import { GeneralModalData, ModalService } from 'quantuvis-angular-common/modal';
import { BehaviorSubject, interval, Observable, Subscription, throwError } from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  filter,
  finalize,
  map,
  switchMap,
  take,
  takeWhile,
  tap,
} from 'rxjs/operators';
import { LockVerificationFailedService } from '@qv-bid/services/lock-verification-failed.service';

@UntilDestroy()
@Injectable()
export class BidLockService implements OnDestroy {
  @BlockUI()
  public blockUI: NgBlockUI;

  public readonly showExpiringLockInfoSeconds = 3 * 60;

  private remainingLockTime$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  private lockInfo$: BehaviorSubject<LockInfo> = new BehaviorSubject<LockInfo>(null);
  private quitEditModeSubscription$: Subscription;

  constructor(
    private router: Router,
    private bidService: BidService,
    private modalService: ModalService,
    private bidStateService: BidStateService,
    private bidLockDaoService: BidLockDaoService,
    private bidEventBusService: BidEventBusService,
    private termEventBusService: TermEventBusService,
    private viewPerspectiveService: ViewPerspectiveService,
    private bidViewHandleErrorService: BidViewHandleErrorService,
    private lockVerificationFailedService: LockVerificationFailedService
  ) {
    this.initLeaveBidViewHandler();
    this.initExitEditModeHandler();
    this.initEnterEditModeHandler();
    this.initUpdateBidErrorHandler();
    this.initShowSessionExpiredModalHandler();
  }

  public handleUpdateBidError(response: HttpErrorResponse): void {
    if (response.status === HttpStatusCode.FORBIDDEN &&
      response.error.code !== HttpResponseErrorCode.LOCK_VERIFICATION_FAILED) {
      this.unlockExpiredEditingSession();
    }
  }

  public ngOnDestroy(): void {
    this.quitEditModeSubscription$?.unsubscribe();
  }

  public initLockHandler(): void {
    this.initLockInfoUpdateHandler();

    this.bidStateService.isEditOrReviewMode$
      .pipe(
        distinctUntilChanged(),
        filter((isEditOrReviewMode: boolean) => isEditOrReviewMode),
      )
      .subscribe(() => this.setUpTimer());
  }

  private initShowSessionExpiredModalHandler(): void {
    this.lockVerificationFailedService
      .showSessionExpiredModalEvent
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.bidStateService.checkoutViewMode();
        this.showSessionExpiredModal();
      });
  }

  private showSessionExpiredModal(): void {
    this.modalService.closeAll();
    this.modalService.openConfirmModal(new GeneralModalData(
      resources.POPUPS.TITLES.SESSION_EXPIRED,
      resources.POPUPS.SESSION_EXPIRED_MSG,
      resources.Actions.OK
    ))
      .pipe(
        tap(() => this.bidEventBusService.reloadBidEvent.emit()),
        switchMap(() => this.bidStateService.waitForBidToBeReloaded()),
        tap(() => this.unlockCurrentBid())
      )
      .subscribe();
  }

  private initUpdateBidErrorHandler(): void {
    this.bidEventBusService.updateBidFailed
      .pipe(untilDestroyed(this))
      .subscribe((error: HttpErrorResponse) => this.handleUpdateBidError(error));

    this.termEventBusService.updateTermFailed
      .pipe(untilDestroyed(this))
      .subscribe((error: HttpErrorResponse) => this.handleUpdateBidError(error));
  }

  private handleProlongLockError(error: HttpErrorResponse): Observable<HttpErrorResponse> {
    if (error.status === HttpStatusCode.CONFLICT) {
      this.unlockExpiredEditingSession();
    }

    return throwError(error);
  }

  private unlockExpiredEditingSession(): void {
    this.unlockCurrentBid()
      .pipe(
        finalize(() => {
          this.bidStateService.checkoutViewMode();
          this.showSessionExpiredModal();
        })
      ).subscribe();
  }

  private initLeaveBidViewHandler(): void {
    this.router.events.pipe(
      filter(e => e instanceof NavigationStart),
      filter((e: NavigationStart) => !BidUtils.isBidViewUrl(e.url)),
      untilDestroyed(this)
    ).subscribe(() => {
      this.bidEventBusService.quitEditModeEvent.emit();
    });
  }

  private initEnterEditModeHandler(): void {
    this.bidEventBusService.editBidEvent
      .pipe(untilDestroyed(this))
      .subscribe(() => this.handleEditBid());
  }

  private handleEditBid(): void {
    this.blockUI.start(BlockingMessage.LOCKING_BID);
    this.lockCurrentBid().pipe(
      this.bidViewHandleErrorService.handleLockError(),
      tap(() => this.bidEventBusService.reloadBidEvent.emit()),
      switchMap(() => this.bidStateService.waitForBidToBeReloaded()),
      finalize(() => this.blockUI.stop()),
    ).subscribe(() => {
      this.bidStateService.isEditMode.next(true);
      this.bidStateService.isReviewMode.next(false);
    });
  }

  private lockCurrentBid(): Observable<void> {
    const { id, isInternal } = this.bidService.currentBid$.getValue();
    const perspective = this.viewPerspectiveService.getViewPerspective();

    return this.bidLockDaoService.lockBid(id, isInternal, perspective);
  }

  private setUpTimer(): void {
    const bidId = this.bidService.currentBid$.getValue().id;

    this.bidLockDaoService.getLockInfo(bidId)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.unlockExpiredEditingSession();

          return throwError(error);
        })
      )
      .subscribe((lockInfo: LockInfo) => {
        const remainingTime = this.getLockRemainingTime(lockInfo);

        if (remainingTime > 0) {
          this.lockInfo$.next(lockInfo);
        } else {
          this.unlockExpiredEditingSession();
        }
      });
  }

  private prolongBidLock(): Observable<LockInfo | HttpErrorResponse> {
    const { id, isInternal } = this.bidService.currentBid$.getValue();
    const perspective = this.viewPerspectiveService.getViewPerspective();

    return this.bidLockDaoService.prolongBidLock(id, isInternal, perspective)
      .pipe(
        catchError((error: HttpErrorResponse) => this.handleProlongLockError(error))
      );
  }

  private initLockInfoUpdateHandler(): void {
    this.lockInfo$
      .pipe(
        filter((lockInfo: LockInfo) => Boolean(lockInfo)),
        tap(() => this.handleLockExpiring()),
        switchMap((lockInfo: LockInfo) => this.createLockInfoTick(lockInfo)),
      )
      .subscribe((lockInfo: LockInfo) => {
        const remainingTime = this.getLockRemainingTime(lockInfo);

        this.remainingLockTime$.next(remainingTime);

        if (remainingTime <= 0) {
          this.setUpTimer();
        }
      });
  }

  private createLockInfoTick(lockInfo: LockInfo): Observable<LockInfo> {
    return interval(1000)
      .pipe(
        switchMap(() => this.remainingLockTime$.pipe(take(1))),
        takeWhile((remainingTime: number) => remainingTime >= 0),
        map(() => lockInfo),
        untilDestroyed(this)
      );
  }

  private handleLockExpiring(): void {
    this.remainingLockTime$
      .pipe(
        filter((remainingTime: number) =>
          remainingTime <= this.showExpiringLockInfoSeconds && remainingTime > 0),
        tap(() => this.showExtendTimerModal()),
        take(1),
      )
      .subscribe();
  }

  private showExtendTimerModal(): void {
    this.lockInfo$
      .pipe(
        filter((lockInfo: LockInfo) => Boolean(lockInfo)),
        take(1),
        switchMap((lockInfo: LockInfo) => {
          const expiringTime = moment(lockInfo.updatedAt).add(lockInfo.ttl, 'seconds').local().format('h:mm a');

          return this.modalService.openConfirmModal(new GeneralModalData(
            'Session Expiration',
            `The edit session for this bid will expire at ${expiringTime}. Do you want to extend the session?`,
            'Extend timer',
            'Cancel'
          ));
        }),
        filter((result: boolean) => result),
        switchMap(() => this.refreshTimer())
      )
      .subscribe();
  }

  private refreshTimer(): Observable<void> {
    this.resetRemainingLockTime();

    return this.prolongBidLock()
      .pipe(
        map((lockInfo: LockInfo) => this.lockInfo$.next(lockInfo))
      );
  }

  private resetRemainingLockTime(): void {
    this.remainingLockTime$.next(-1);
  }

  private getLockRemainingTime(lockInfo: LockInfo): number {
    const expiresAt = lockInfo.updatedAt.clone();
    expiresAt.add(lockInfo.ttl, 'seconds');

    return expiresAt.diff(moment(), 'seconds');
  }

  private initExitEditModeHandler(): void {
    this.quitEditModeSubscription$ = this.bidEventBusService.quitEditModeEvent
      .pipe(
        map(() => this.bidService.currentBid$.getValue()),
        filter((bid) => Boolean(bid) && Boolean(bid.editor)),
        switchMap(() => this.unlockCurrentBid().pipe(take(1)))
      )
      .subscribe(() => this.bidStateService.isEditMode.next(false));
  }

  private unlockCurrentBid(): Observable<void> {
    const bidId = this.bidService.currentBid$.getValue().id;

    return this.bidLockDaoService.unlockBid(bidId)
      .pipe(
        tap(() => this.resetRemainingLockTime())
      );
  }
}
