import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { FieldsDropdownOptionsActions } from './fields-dropdown-options.actions';
import { catchError, filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { Action, Store } from '@ngrx/store';
import { CompanyDropdownOptionsDaoService } from '../../services/company-dropdown-options-dao.service';
import { FieldsDropdownOptionsSelector } from './fields-dropdown-options.selector';
import { StateStatus } from '../../enums/state-status';

@Injectable()
export class FieldsDropdownOptionsEffects {

  public get$ = createEffect(() => this.actions.pipe(
    ofType(FieldsDropdownOptionsActions.get),
    withLatestFrom(this.store.select(FieldsDropdownOptionsSelector.selectStatus)),
    filter(([, status]) => [StateStatus.Idle, StateStatus.Rejected].includes(status)),
    tap(() => this.store.dispatch(FieldsDropdownOptionsActions.setPendingStatus())),
    switchMap(() => this.get())
  ));

  constructor(
    private actions: Actions,
    private store: Store<any>,
    private companyDropdownOptionsDaoService: CompanyDropdownOptionsDaoService) {
  }

  private get(): Observable<Action> {
    return this.companyDropdownOptionsDaoService.get().pipe(
      map(data => FieldsDropdownOptionsActions.getResolved({ data })),
      catchError(() => of(FieldsDropdownOptionsActions.getRejected()))
    );
  }
}
