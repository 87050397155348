import { Inject, Injectable } from '@angular/core';
import { Payment } from '@qv-bid/entities';
import { BaseContractDao } from '@qv-bid/services/dao/contract-terms/base-contract.dao';
import { ApiService } from '@qv-common/services/api';
import { JSON_CONVERT_TOKEN } from 'quantuvis-angular-common/json-converter/services';
import { JsonConvert } from 'json2typescript';
import { ViewPerspectiveService } from '@qv-common/services';

@Injectable()
export class PaymentDaoService extends BaseContractDao<Payment> {
  protected restEntityName = 'payments';
  protected type = Payment;

  public constructor(
    apiService: ApiService,
    @Inject(JSON_CONVERT_TOKEN) jsonConvert: JsonConvert,
    viewPerspectiveService: ViewPerspectiveService) {
    super(apiService, jsonConvert, viewPerspectiveService);
  }
}
