import { Component, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'acc-access-denied',
  templateUrl: './access-denied.component.html',
  styleUrls: ['./access-denied.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AccessDeniedComponent {
}
