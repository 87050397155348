import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { BidStateService, UndoRedoService } from '@qv-bid/services';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Bid } from '@qv-bid/entities';
import { BidUtils } from '@qv-bid/utils';
import { PermissionService } from '@qv-common/services/auth/permission.service';
import { User } from '@qv-user/entities/user.entity';
import { constants } from '@qv-common/static/constants';
import { resources } from '@qv-common/static/resources';
import { ViewPerspectiveService } from '@qv-common/services/auth';
import { SvgIconName } from '@qv-common/enums';
import { QuantuvisBusinessFeature, QuantuvisPlusFeature } from '@qv-company/enums';
import { BidToolbarAction } from '@qv-bid/enums/bid-toolbar-action.enum';
import { filter, tap } from 'rxjs/operators';
import { MatMenuTrigger } from '@angular/material/menu';
import { UserService } from '@qv-common/services/auth/user.service';

@UntilDestroy()
@Component({
  selector: 'qv-bid-toolbar',
  templateUrl: './bid-toolbar.component.html',
  styleUrls: ['./bid-toolbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BidToolbarComponent implements OnChanges {
  public isBidActionsDisallowed = false;
  public isEditRfpSentButtonAvailable = false;
  public isEditButtonAvailable = false;
  public hasUserWriteAccessToBid = false;
  public isRegularBidOrAllowedInternal = false;
  public isGridView = false;
  public isStandardView = false;
  public isUndoRedoButtonAvailable = false;
  public isSendNotFinalButtonAvailable = false;
  public isSendFinalButtonAvailable = false;
  public isRespondButtonAvailable = false;
  public isAcceptButtonAvailable = false;
  public isReviewButtonAvailable = false;
  public isReopenButtonAvailable = false;
  public isCopyButtonAvailable = false;
  public isDiscardDraftButtonAvailable = false;
  public isDeleteButtonAvailable = false;
  public isBidLoaded = false;
  public isUndoButtonActive = false;
  public isRedoButtonActive = false;

  @Input()
  public bid: Bid;
  @Input()
  public disabledBidActions = false;
  @Input()
  public itemsInSaveQueue = false;

  @Output()
  public actionButtonClick: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  public changeViewPerspective: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild(MatMenuTrigger)
  public matMenuTrigger: MatMenuTrigger;

  public user: User;
  public perspective: string;
  public readonly tooltips = resources.TOOLTIPS;
  public readonly svgIconName = SvgIconName;
  public readonly constants = constants;
  public readonly bidToolbarAction = BidToolbarAction;
  public readonly bidUtils = BidUtils;

  private isBidInvalid = false;
  private isSubscriptionsInitialized = false;

  constructor(
    private userService: UserService,
    private permissionService: PermissionService,
    private viewPerspectiveService: ViewPerspectiveService,
    private router: Router,
    private changeDetectorRef: ChangeDetectorRef,
    private bidStateService: BidStateService,
    private undoRedoService: UndoRedoService,
  ) {}

  public ngOnChanges(changes: SimpleChanges): void {
    this.initSubscriptions();

    if (changes.bid.currentValue) {
      this.calculateState();
    }
  }

  public onActionButtonClick(action: any): void {
    this.actionButtonClick.emit(action);
  }

  public isBidLoadedCalculate(): boolean {
    return Boolean(this.bid);
  }

  public hasUserWriteAccessToBidCalculate(): boolean {
    return this.permissionService.hasUserWriteAccessToBid(this.bid.payer.id, this.bid.manufacturer.id);
  }

  public isBidActionsDisallowedCalculate(): boolean {
    return this.disabledBidActions || this.isBidInvalid;
  }

  public isUndoButtonActiveCalculate(): boolean {
    return !this.isBidActionsDisallowedCalculate() &&
      !this.itemsInSaveQueue &&
      this.undoRedoService.hasVersions(this.bid.id, constants.UNDO_VERSIONS_STORAGE_KEY);
  }

  public isRedoButtonActiveCalculate(): boolean {
    return !this.isBidActionsDisallowedCalculate() &&
      !this.itemsInSaveQueue &&
      this.undoRedoService.hasVersions(this.bid.id, constants.REDO_VERSIONS_STORAGE_KEY);
  }

  public isRespondButtonAvailableCalculate(): boolean {
    const bidStatusResponseRequested = this.bid.isStatusResponseRequested();
    const userCompanyAssigned = this.bid.isBidToUserCompanyAssigned(this.user.company.id);
    const isPayerPerspective = this.viewPerspectiveService.isCurrentUserWithPayerPerspective();
    const canAccessBid = this.isRegularBidOrAllowedInternal;

    return bidStatusResponseRequested && userCompanyAssigned && !isPayerPerspective && canAccessBid;
  }

  public isEditRfpSentButtonAvailableCalculate(): boolean {
    const bidStatusResponseRequested = this.bid.isStatusResponseRequested();
    const isPayerPerspective = this.viewPerspectiveService.isCurrentUserWithPayerPerspective();
    const canAccessBid = this.isRegularBidOrAllowedInternal;
    const isLockedForAnotherUser = BidUtils.isBidEditingByOtherPerson(this.bid, this.user.id);

    return bidStatusResponseRequested && isPayerPerspective && canAccessBid && !isLockedForAnotherUser;
  }

  public isReviewButtonAvailableCalculate(): boolean {
    const bidAcceptedWithChanges = this.bid.isStatusAcceptedWithChanges();
    const bidLockableForUser = BidUtils.isBidLockableForUser(this.user, this.bid, this.perspective);
    const finalBid = this.bid.isFinal;
    const isPayerPerspective = this.viewPerspectiveService.isCurrentUserWithPayerPerspective();
    const assignedToPayer = this.bid.isAssignedToPayer();
    const canAccessBid = this.isRegularBidOrAllowedInternal;

    return bidAcceptedWithChanges && bidLockableForUser && finalBid && isPayerPerspective && assignedToPayer
      && canAccessBid;
  }

  public isAcceptButtonAvailableCalculate(): boolean {
    const bidAcceptedWithChanges = this.bid.isStatusAcceptedWithChanges();
    const isPayerPerspective = this.viewPerspectiveService.isCurrentUserWithPayerPerspective();
    const userAssigned = BidUtils.isBidToCurrentUserAssigned(this.user, this.bid, this.perspective);
    const canAccessBid = this.isRegularBidOrAllowedInternal;
    const inReview = this.bid.inReview;

    return bidAcceptedWithChanges && inReview && isPayerPerspective && userAssigned && canAccessBid;
  }

  public isEditButtonAvailableCalculate(): boolean {
    const userCompanyInBid = BidUtils.isUserCompanyRelatedToBid(this.user, this.bid);
    const bidAcceptedWithChanges = this.bid.isStatusAcceptedWithChanges();
    const bidLockableForUser = BidUtils.isBidLockableForUser(this.user, this.bid, this.perspective);
    const bidAccepted = this.bid.isStatusAccepted();
    const bidResponseRequested = this.bid.isStatusResponseRequested();
    const bidDeclined = this.bid.isStatusDeclined();
    const canAccessBid = this.isRegularBidOrAllowedInternal;
    const inReview = this.bid.inReview;
    const userAssigned = BidUtils.isBidToCurrentUserAssigned(this.user, this.bid, this.perspective);
    const finalBid = this.bid.isFinal;
    const pharmaView = this.viewPerspectiveService.isPharmaViewPerspective();
    const isLockedForAnotherUser = this.bid.editor && this.bid.editor.userId !== this.user.id;

    return userCompanyInBid
      && (bidAcceptedWithChanges || bidLockableForUser)
      && !(bidAccepted || bidResponseRequested || bidDeclined)
      && canAccessBid
      && !(inReview && !bidLockableForUser && !userAssigned)
      && !(finalBid && pharmaView)
      && !isLockedForAnotherUser;
  }

  public isReopenButtonAvailableCalculate(): boolean {
    const bidAccepted = this.bid.isStatusAccepted();
    const bidDeclined = this.bid.isStatusDeclined();
    const userCompanyInBid = BidUtils.isUserCompanyRelatedToBid(this.user, this.bid);
    const isPayerPerspective = this.viewPerspectiveService.isCurrentUserWithPayerPerspective();
    const canAccessBid = this.isRegularBidOrAllowedInternal;

    return (bidAccepted || bidDeclined) && userCompanyInBid && isPayerPerspective && canAccessBid;
  }

  public isCopyButtonAvailableCalculate(): boolean {
    return this.userService.isCurrentUserPayer();
  }

  public isDiscardDraftButtonAvailableCalculate(): boolean {
    const bidStatusDraft = this.bid.isStatusDraft();
    const bidLockableForUser = BidUtils.isBidLockableForUser(this.user, this.bid, this.perspective);
    const userAssigned = BidUtils.isBidToCurrentUserAssigned(this.user, this.bid, this.perspective);
    const canAccessBid = this.isRegularBidOrAllowedInternal;

    return bidStatusDraft && (bidLockableForUser || userAssigned) && canAccessBid;
  }

  public isDeleteButtonAvailableCalculate(): boolean {
    const bidStatusRfpNotSent = this.bid.isStatusRfpNotSent();
    const bidLockableForUser = BidUtils.isBidLockableForUser(this.user, this.bid, this.perspective);
    const userAssigned = BidUtils.isBidToCurrentUserAssigned(this.user, this.bid, this.perspective);

    return bidStatusRfpNotSent && (userAssigned || bidLockableForUser);
  }

  public isUndoRedoButtonAvailableCalculate(): boolean {
    const statusDraft = this.bid.isStatusDraft();
    const statusRfpNotSent = this.bid.isStatusRfpNotSent();
    const userCompanyAssigned = this.bid.isBidToUserCompanyAssigned(this.user.company.id);
    const bidLockable = BidUtils.isBidLockableForUser(this.user, this.bid, this.perspective);
    const bidEditable = BidUtils.isBidEditableByUser(this.user, this.bid, this.perspective);

    return (statusDraft || statusRfpNotSent) && userCompanyAssigned && !bidLockable && bidEditable;
  }

  public isSendNotFinalButtonAvailableCalculate(): boolean {
    const isPayerPerspective = this.viewPerspectiveService.isCurrentUserWithPayerPerspective();

    return this.isSendButtonAvailable() && isPayerPerspective;
  }

  public isSendFinalButtonAvailableCalculate(): boolean {
    const isPayerPerspective = this.viewPerspectiveService.isCurrentUserWithPayerPerspective();
    const canAccessBid = this.isRegularBidOrAllowedInternal;

    return this.isSendButtonAvailable() && !isPayerPerspective && canAccessBid;
  }

  public isGridViewCalculate(): boolean {
    return this.router.isActive('grid', false);
  }

  public isStandardViewCalculate(): boolean {
    return this.router.isActive('standard', false);
  }

  public openTermVisibilityMenu(): void {
    this.matMenuTrigger.openMenu();
  }

  public isAvailableBidViewSwitcher(): boolean {
    return this.permissionService.isFeatureAllowed(QuantuvisPlusFeature.GRID_VIEW);
  }

  private initSubscriptions(): void {
    if (this.isSubscriptionsInitialized) return;

    this.userService.user
      .pipe(untilDestroyed(this))
      .subscribe(user => {
        this.user = user;
        if (this.isBidLoaded) {
          this.calculateState();
        }
      });

    this.permissionService.userAcl$.pipe(
      filter(() => this.isBidLoaded),
      untilDestroyed(this)
    ).subscribe(() => {
      this.calculateState();
      this.changeDetectorRef.markForCheck();
    });

    this.bidStateService.isBidInvalid().pipe(
      filter(() => this.isBidLoaded),
      tap((isBidInvalid: boolean) => this.isBidInvalid = isBidInvalid),
      untilDestroyed(this)
    ).subscribe(() => {
      this.calculateState();
      this.changeDetectorRef.markForCheck();
    });

    this.undoRedoService.undoVersionChange$
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.isUndoButtonActive = this.isUndoButtonActiveCalculate();
        this.changeDetectorRef.markForCheck();
      });

    this.undoRedoService.redoVersionChange$
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.isRedoButtonActive = this.isRedoButtonActiveCalculate();
        this.changeDetectorRef.markForCheck();
      });

    this.isSubscriptionsInitialized = true;
  }

  private isSendButtonAvailable(): boolean {
    return BidUtils.isSendBidAvailable(this.bid, this.user, this.perspective);
  }

  private isRegularBidOrAllowedInternalCalculate(): boolean {
    return !this.bid.isInternal
      || this.permissionService.isFeatureAllowed(QuantuvisBusinessFeature.INTERNAL_BIDS);
  }

  private calculateState(): void {
    this.perspective = this.viewPerspectiveService.getViewPerspective();
    this.isBidLoaded = this.isBidLoadedCalculate();
    this.isBidActionsDisallowed = this.isBidActionsDisallowedCalculate();
    this.isRegularBidOrAllowedInternal = this.isRegularBidOrAllowedInternalCalculate();
    this.hasUserWriteAccessToBid = this.hasUserWriteAccessToBidCalculate();

    this.isGridView = this.isGridViewCalculate();
    this.isStandardView = this.isStandardViewCalculate();

    this.isUndoButtonActive = this.isUndoButtonActiveCalculate();
    this.isRedoButtonActive = this.isRedoButtonActiveCalculate();
    this.isUndoRedoButtonAvailable = this.isUndoRedoButtonAvailableCalculate();
    this.isSendNotFinalButtonAvailable = this.isSendNotFinalButtonAvailableCalculate();
    this.isSendFinalButtonAvailable = this.isSendFinalButtonAvailableCalculate();
    this.isRespondButtonAvailable = this.isRespondButtonAvailableCalculate();
    this.isAcceptButtonAvailable = this.isAcceptButtonAvailableCalculate();
    this.isReviewButtonAvailable = this.isReviewButtonAvailableCalculate();
    this.isEditRfpSentButtonAvailable = this.isEditRfpSentButtonAvailableCalculate();
    this.isEditButtonAvailable = this.isEditButtonAvailableCalculate();
    this.isReopenButtonAvailable = this.isReopenButtonAvailableCalculate();
    this.isCopyButtonAvailable = this.isCopyButtonAvailableCalculate();
    this.isDiscardDraftButtonAvailable = this.isDiscardDraftButtonAvailableCalculate();
    this.isDeleteButtonAvailable = this.isDeleteButtonAvailableCalculate();
  }
}
