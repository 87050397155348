import { SimpleChanges, OnInit, Input, Directive } from '@angular/core';
import { Summary } from '@qv-bid/entities';
import { FormControl } from '@angular/forms';
import { TermUtils } from '@qv-term/utils';
import { SnackBarService } from 'quantuvis-angular-common/snack-bar';
import { throwError } from 'rxjs';
import { switchMap, take, tap, catchError } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SummaryService } from '@qv-bid/services/summary';
import { User } from '@qv-user/entities/user.entity';
import { QvCache } from '@qv-common/decorators';
import { BidEventBusService } from '@qv-bid/services/bid-event-bus.service';
import { HttpErrorResponse } from '@angular/common/http';
import { UserService } from '@qv-common/services/auth/user.service';

@UntilDestroy()
@Directive()
export class BaseSummarySection implements OnInit {
  @Input()
  public summary: Summary;
  @Input()
  public isEditMode: boolean;
  @Input()
  public isBidInvalid: boolean;

  public isUserPharma: boolean;
  public currentCompanyId: number;

  public lockControl = new FormControl();

  constructor(
    protected summaryService: SummaryService,
    protected userService: UserService,
    protected snackBarService: SnackBarService,
    protected bidEventBusService: BidEventBusService
  ) {}

  public ngOnInit(): void {
    this.isUserPharma = this.userService.isCurrentUserPharma();
    this.userService.user.pipe(
      take(1),
      tap((user: User) => this.currentCompanyId = user.company.id)
    ).subscribe();
  }

  public isSummaryChanged(changes: SimpleChanges): boolean {
    return (changes.summary && changes.summary.currentValue
      && (!changes.summary.previousValue || changes.summary.currentValue.id !== changes.summary.previousValue.id)
    );
  }

  @QvCache()
  public isEditable(isEditMode: boolean, isUserPharma: boolean, isLocked: boolean, isBidInvalid: boolean = false): boolean {
    return TermUtils.isEditable(isEditMode, isUserPharma, isLocked, isBidInvalid);
  }

  @QvCache()
  public isManageAvailable(isEditable: boolean, companyId: number, currentCompanyId: number): boolean {
    return isEditable && companyId === currentCompanyId;
  }

  protected subscribeOnLockChanges(fieldName: string): void {
    this.lockControl.valueChanges
      .pipe(
        tap(() => this.snackBarService.start()),
        switchMap((state: boolean) => this.summaryService.updateSummaryInfo(
          this.summary.bidVersionId,
          this.summary.id,
          Object.assign(new Summary(), this.summary, { [fieldName]: state })
        )),
        tap(() => this.bidEventBusService.undoRedoEvent.emit()),
        tap(() => this.snackBarService.finish()),
        catchError((error: HttpErrorResponse) => {
          this.snackBarService.error();
          return throwError(error);
        }),
        untilDestroyed(this)
      ).subscribe();
  }
}
