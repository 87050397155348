import { JsonObject, JsonProperty } from 'json2typescript';
import { NdcDateValue, NdcDictionaryValue, NdcValue } from '@qv-term/entities';
import { BaselineWac } from '@qv-bid/entities';

@JsonObject('PriceProtection')
export class PriceProtection {
  @JsonProperty('netEffectivePrice', NdcValue)
  public netEffectivePrice: NdcValue<number> = null;

  @JsonProperty('baselineStartDate', NdcDateValue)
  public baselineStartDate: NdcDateValue = null;

  @JsonProperty('index', NdcDictionaryValue)
  public index: NdcDictionaryValue = null;

  @JsonProperty('newPriceEffectiveDate', NdcDictionaryValue)
  public newPriceEffectiveDate: NdcDictionaryValue = null;

  @JsonProperty('reset', NdcDictionaryValue)
  public reset: NdcDictionaryValue = null;

  @JsonProperty('state', NdcDictionaryValue)
  public state: NdcDictionaryValue = null;

  @JsonProperty('threshold', NdcValue)
  public threshold: NdcValue<number> = null;

  @JsonProperty('baselineWac', NdcValue)
  public baselineWac: NdcValue<BaselineWac> = null;
}
