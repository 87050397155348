<section class="qv-modal-dialog market-basket-modal market-basket-modal--assign market-basket-assign" *blockUI="'market-basket-assign-modal'">
  <mb-market-basket-template-modal-header [title]="title"></mb-market-basket-template-modal-header>

  <div class="qv-modal-dialog-content qv-modal-dialog-content--font-light">
    <div class="info-message">
      <span *ngIf="isNdcLevel" class="not-editable-msg">
        {{ resources.MarketBasketManagement.INFO_MESSAGES.MARKET_BASKET_NOT_EDITABLE_AT_NDC_LEVEL }}
      </span>
    </div>
    <div *ngIf="isEditable(isEditMode, isNdcLevel)" class="panels-row market-basket-assign__actions">
      <div class="left-column">
        <div class="actions-bar">
          <button class="link-btn new-mb-link" (click)="onNewClick()">
            {{ resources.MarketBasketManagement.NEW_MARKET_BASKET_TEMPLATE }}
          </button>
        </div>
      </div>
    </div>
    <div class="panels-row market-basket-assign__content">
      <qv-market-basket-template-list
        class="left-column"
        [isPaginationDisabled]="isTemplatesLoading$ | async"
        [excludedMarketBasketTemplate]="$any(marketBasket)"
        [templatePage]="templatePage$ | async"
        (templateSelected)="onTemplateSelected($event)"
        (changePage)="onChangePage($event)"
      ></qv-market-basket-template-list>
      <mb-market-basket-drugs
        class="right-column"
        [marketBasketDrugs]="selectedTemplate?.drugs">
      </mb-market-basket-drugs>
    </div>
  </div>

  <footer mat-dialog-actions class="qv-modal-dialog-actions qv-modal-dialog-actions--two-column">
    <div class="left-block">
      <mat-checkbox
        *ngIf="isEditable(isEditMode, isNdcLevel)"
        class="qv-mat-checkbox qv-mat-checkbox--add-to-other"
        [checked]="addToOtherScenarios"
        (change)="onAddToOtherScenariosChange($event)">
        {{ resources.MarketBasketManagement.ADD_MARKET_BASKET_TO_ALL_OTHER_DRUG_SCENARIOS }}
      </mat-checkbox>
    </div>
    <div class="right-block">
      <button mat-button
        *ngIf="isImportState(state)"
        class="qv-modal-dialog-actions__item"
        (click)="onBackClick()">
        {{ resources.Actions.BACK }}
      </button>

      <button mat-button
        class="qv-modal-dialog-actions__item cancel-btn"
        [class.qv-modal-dialog-actions__item--primary]="!isEditable(isEditMode, isNdcLevel)"
        (click)="onClose()">
        {{ resources.Actions.CANCEL }}
      </button>

      <button mat-button
        *ngIf="isEditable(isEditMode, isNdcLevel)"
        class="qv-modal-dialog-actions__item qv-modal-dialog-actions__item--primary save-btn"
        [disabled]="!selectedTemplate"
        (click)="onSave()">
        {{ getPrimaryButtonTitle(state) }}
      </button>
    </div>
  </footer>
</section>
