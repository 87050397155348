import { TermName } from '@qv-term/enums';

export const umDetailsCheckControls = [
  TermName.STEP_THERAPY_ALLOWED_ON_PRODUCT,
  TermName.STEP_THERAPY_REQUIRED_ON_COMPETITORS_PRODUCTS,
  TermName.PRIOR_AUTHORIZATION_ALLOWED_ON_PRODUCT,
  TermName.PRIOR_AUTHORIZATION_REQUIRED_ON_COMPETITORS_PRODUCTS,
  TermName.QUANTITY_LIMIT,
  TermName.OTHER_UM
];
