import { EventEmitter, Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { merge, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { GeneralModalConfig } from '../components/general-modal/models';
import { TemplateFormModalConfig } from '../components/template-form-modal/models';
import { TemplateModalConfig } from '../components/template-modal/models';

import { ModalSize } from '../enums';
import { ConfirmModal } from '../interfaces';
import { GeneralModalData, ModalConfig, TemplateFormModalData, TemplateModalData } from '../models';

@Injectable()
export class ModalService {
  private stateUpdated = new EventEmitter();

  constructor(private dialog: MatDialog) {}

  public openModal<C, D>(config: ModalConfig<C, D>): MatDialogRef<C> {
    const modal = config.openModal(this.dialog);

    merge(modal.afterOpened(), modal.afterClosed()).subscribe(() => this.stateUpdated.emit());

    return modal;
  }

  public openConfirmModal(
    modalData: GeneralModalData, size: ModalSize = ModalSize.MEDIUM
  ): Observable<unknown | boolean> {
    return this.openConfirmationConfigModal(new GeneralModalConfig(modalData, size));
  }

  public openTemplateFormModal(modalData: TemplateFormModalData): Observable<unknown | boolean> {
    return this.openConfirmationConfigModal(
      new TemplateFormModalConfig(modalData, modalData.size, modalData.disableClose)
    );
  }
  public openTemplateModal(modalData: TemplateModalData): Observable<unknown | boolean> {
    return this.openConfirmationConfigModal(new TemplateModalConfig(modalData, modalData.size, modalData.disableClose));
  }

  public isModalOpened(): Observable<boolean> {
    return this.stateUpdated.pipe(map(() => this.dialog.openDialogs.length > 0));
  }

  public closeAll(): void {
    this.dialog.closeAll();
  }

  private openConfirmationConfigModal(config: ModalConfig<ConfirmModal, any>): Observable<unknown | boolean> {
    const modalInstance = this.openModal(config);

    return merge(
      modalInstance.componentInstance.primaryAction.pipe(map((data: unknown) => data || true)),
      modalInstance.componentInstance.secondaryAction.pipe(map(() => false)),
      modalInstance.afterClosed().pipe(map(() => false)),
    ).pipe(take(1));
  }
}
