import { DictionaryItem } from 'quantuvis-angular-common/interfaces';

export enum NewPriceEffectiveDate {
  EMPTY = 56,
  FIRST_DAY_OF_MONTH,
  FIFTEENTH_DAY_OF_MONTH,
  LAST_DAY_OF_MONTH,
  FIRST_DAY_OF_THE_FOLLOWING_MONTH,
  FIRST_DAY_OF_THE_QUATER,
  FORTY_FIVETH_DAY_OF_QUATER,
  LAST_DAY_OF_QUATER,
  FIRST_DAY_OF_THE_FOLLOWING_QUATER,
  DAY_OF_PRICE_CHANGE,
  DATE_DISPENSED
}

export namespace NewPriceEffectiveDate {
  const valuesMap = new Map<NewPriceEffectiveDate, DictionaryItem>([
    [
      NewPriceEffectiveDate.EMPTY,
      { id: NewPriceEffectiveDate.EMPTY, name: '' }
    ],
    [
      NewPriceEffectiveDate.FIRST_DAY_OF_MONTH,
      { id: NewPriceEffectiveDate.FIRST_DAY_OF_MONTH, name: '1st day of month' }
    ],
    [
      NewPriceEffectiveDate.FIFTEENTH_DAY_OF_MONTH,
      { id: NewPriceEffectiveDate.FIFTEENTH_DAY_OF_MONTH, name: '15th day of month' }
    ],
    [
      NewPriceEffectiveDate.LAST_DAY_OF_MONTH,
      { id: NewPriceEffectiveDate.LAST_DAY_OF_MONTH, name: 'Last day of month' }
    ],
    [
      NewPriceEffectiveDate.FIRST_DAY_OF_THE_FOLLOWING_MONTH,
      { id: NewPriceEffectiveDate.FIRST_DAY_OF_THE_FOLLOWING_MONTH, name: '1st day of the following month' }
    ],
    [
      NewPriceEffectiveDate.FIRST_DAY_OF_THE_QUATER,
      { id: NewPriceEffectiveDate.FIRST_DAY_OF_THE_QUATER, name: '1st day of quarter' }
    ],
    [
      NewPriceEffectiveDate.FORTY_FIVETH_DAY_OF_QUATER,
      { id: NewPriceEffectiveDate.FORTY_FIVETH_DAY_OF_QUATER, name: '45th day of quarter' }
    ],
    [
      NewPriceEffectiveDate.LAST_DAY_OF_QUATER,
      { id: NewPriceEffectiveDate.LAST_DAY_OF_QUATER, name: 'Last day of quarter' }
    ],
    [
      NewPriceEffectiveDate.FIRST_DAY_OF_THE_FOLLOWING_QUATER,
      { id: NewPriceEffectiveDate.FIRST_DAY_OF_THE_FOLLOWING_QUATER, name: '1st day of the following quarter' }
    ],
    [
      NewPriceEffectiveDate.DAY_OF_PRICE_CHANGE,
      { id: NewPriceEffectiveDate.DAY_OF_PRICE_CHANGE, name: 'Day of price change' }
    ],
    [
      NewPriceEffectiveDate.DATE_DISPENSED,
      { id: NewPriceEffectiveDate.DATE_DISPENSED, name: 'Date Dispensed' }
    ],
  ]);

  export function getValue(newPriceEffectiveDate: NewPriceEffectiveDate): DictionaryItem {
    return valuesMap.get(newPriceEffectiveDate);
  }

  export function getValues(): DictionaryItem[] {
    return Array.from(valuesMap.values());
  }
}
