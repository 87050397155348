import { JsonObject, JsonProperty } from 'json2typescript';
import { Scenario } from '@qv-bid/entities/scenario.entity';

@JsonObject('ScenarioDependency')
export class ScenarioDependency {
  @JsonProperty('id', Number)
  public id: number = null;

  @JsonProperty('index', Number)
  public index: number = null;

  @JsonProperty('userId', Number)
  public userId: number = null;

  @JsonProperty('scenarios')
  public scenarios: Scenario[] = [];
}
