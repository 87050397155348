import { Inject, Injectable } from '@angular/core';
import { JsonConvert } from 'json2typescript';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpResponseWrapper } from '@qv-common/models/api';
import { Bid } from '@qv-bid/entities';
import { ApiService } from '@qv-common/services/api';
import { ViewPerspectiveService } from '@qv-common/services/auth';
import { JSON_CONVERT_TOKEN } from 'quantuvis-angular-common/json-converter/services';
import { HttpUtils } from '@qv-common/utils';
import { ApiUrlPrefix } from '@qv-common/enums';
import { ReasonInfo, SentBidInfo } from '@qv-bid/models';
import { HttpParams } from '@angular/common/http';

@Injectable()
export class BidDaoService {
  constructor(
    private apiService: ApiService,
    @Inject(JSON_CONVERT_TOKEN) private jsonConvert: JsonConvert,
    private viewPerspectiveService: ViewPerspectiveService
  ) {}

  public getById(bidId: number, perspective?: string): Observable<Bid> {
    let url = `${ApiUrlPrefix.BIDS}/${bidId}`;

    if (perspective) {
      url = HttpUtils.appendQueryParamToUrl(url, HttpUtils.VIEW_PERSPECTIVE_KEY, perspective);
    }

    return this.apiService.get<Bid>(url).pipe(
      map((response: HttpResponseWrapper<Bid>) =>
        this.jsonConvert.deserializeObject(response.body.data, Bid)
      )
    );
  }

  public getHistoricBid(bidId: number, bidVersion: number): Observable<Bid> {
    const url = `${ApiUrlPrefix.BIDS}/${bidId}/historic/${bidVersion}`;

    return this.apiService.get<Bid>(url).pipe(
      map((response: HttpResponseWrapper<Bid>) =>
        this.jsonConvert.deserializeObject(response.body.data, Bid)
      )
    );
  }

  public deleteBid(id: number, isInternalBid: boolean): Observable<null> {
    let url = `${ApiUrlPrefix.BIDS}/${id}`;

    const perspective = this.viewPerspectiveService.getViewPerspective();
    url = HttpUtils.appendPerspectiveQueryParamToUrlIfBidIsInternal(url, perspective, isInternalBid);

    return this.apiService.delete(url) as Observable<null>;
  }

  public deleteBids(ids: number[]): Observable<null> {
    return this.apiService.delete(ApiUrlPrefix.BIDS, null, {
      params: new HttpParams().set('ids', ids.join(','))
    }) as Observable<null>;
  }

  public respondBid(bidId: number, dismissedDrugsIds: number[]): Observable<null> {
    return this.apiService.post(`${ApiUrlPrefix.BIDS}/${bidId}/respond`, { dismissedDrugsIds }) as Observable<null>;
  }

  public acceptBid(bidId: number, isInternalBid: boolean): Observable<null> {
    let url = `${ApiUrlPrefix.BIDS}/${bidId}/accept`;
    const perspective = this.viewPerspectiveService.getViewPerspective();

    url = HttpUtils.appendPerspectiveQueryParamToUrlIfBidIsInternal(url, perspective, isInternalBid);

    return this.apiService.post(url, null) as Observable<null>;
  }

  public discardDraft(bidId: number, isInternalBid: boolean): Observable<null> {
    let url = `${ApiUrlPrefix.BIDS}/${bidId}/discard`;
    const perspective = this.viewPerspectiveService.getViewPerspective();

    url = HttpUtils.appendPerspectiveQueryParamToUrlIfBidIsInternal(url, perspective, isInternalBid);

    return this.apiService.post(url, null) as Observable<null>;
  }

  public reopenBid(bidId: number, isInternalBid: boolean): Observable<null> {
    let url = `${ApiUrlPrefix.BIDS}/${bidId}/reopen`;
    const perspective = this.viewPerspectiveService.getViewPerspective();

    url = HttpUtils.appendPerspectiveQueryParamToUrlIfBidIsInternal(url, perspective, isInternalBid);

    return this.apiService.post(url, null) as Observable<null>;
  }

  public startReview(bidId: number, isBidInternal: boolean): Observable<null> {
    let url = `${ApiUrlPrefix.BIDS}/${bidId}/review/start`;

    const perspective = this.viewPerspectiveService.getViewPerspective();
    url = HttpUtils.appendPerspectiveQueryParamToUrlIfBidIsInternal(url, perspective, isBidInternal);

    return this.apiService.put(url, null) as Observable<null>;
  }

  public declineBid(id: number, isInternalBid: boolean, reasonInfo: ReasonInfo = new ReasonInfo()): Observable<null> {
    let url = `${ApiUrlPrefix.BIDS}/${id}/decline`;
    const perspective = this.viewPerspectiveService.getViewPerspective();

    url = HttpUtils.appendPerspectiveQueryParamToUrlIfBidIsInternal(url, perspective, isInternalBid);

    return this.apiService.post(url, reasonInfo) as Observable<null>;
  }

  public abortReview(bidId: number, isBidInternal: boolean): Observable<null> {
    let url = `${ApiUrlPrefix.BIDS}/${bidId}/review/abort`;

    const perspective = this.viewPerspectiveService.getViewPerspective();
    url = HttpUtils.appendPerspectiveQueryParamToUrlIfBidIsInternal(url, perspective, isBidInternal);

    return this.apiService.put(url, null) as Observable<null>;
  }

  public redoBid(bidId: number, isBidInternal: boolean): Observable<null> {
    let url = `${ApiUrlPrefix.BIDS}/${bidId}/redo`;

    const perspective = this.viewPerspectiveService.getViewPerspective();
    url = HttpUtils.appendPerspectiveQueryParamToUrlIfBidIsInternal(url, perspective, isBidInternal);

    return this.apiService.post(url) as Observable<null>;
  }

  public undoBid(bidId: number, isBidInternal: boolean): Observable<null> {
    let url = `${ApiUrlPrefix.BIDS}/${bidId}/undo`;

    const perspective = this.viewPerspectiveService.getViewPerspective();
    url = HttpUtils.appendPerspectiveQueryParamToUrlIfBidIsInternal(url, perspective, isBidInternal);

    return this.apiService.post(url) as Observable<null>;
  }

  public sendBid(id: number, isBidInternal: boolean, sentBidInfo: SentBidInfo = new SentBidInfo()): Observable<null> {
    let url = `${ApiUrlPrefix.BIDS}/${id}/send`;

    const perspective = this.viewPerspectiveService.getViewPerspective();
    url = HttpUtils.appendPerspectiveQueryParamToUrlIfBidIsInternal(url, perspective, isBidInternal);

    return this.apiService.post(url, sentBidInfo) as Observable<null>;
  }

  public sendBids(ids: number[]): Observable<null> {
    return this.apiService.post(`${ApiUrlPrefix.BIDS}/send`, {}, {
      params: new HttpParams().set('ids', ids.join(','))
    }) as Observable<null>;
  }

  public convertOldBidIdToNew(id: number): Observable<Bid> {
    return this.apiService.post(ApiUrlPrefix.BIDS, {
      mongoBidIds: [id]
    }).pipe(map((response: HttpResponseWrapper<Bid[]>) =>
      Object.assign(new Bid(), response.body.data[0]))) as Observable<null>;
  }
}
