import { CompetitorsTier } from 'quantuvis-core-entities';
import { SelectBoxTerm } from '@qv-term/models/types';
import { constants, resources } from '@qv-common/static';

export class CompetitorsTierTerm extends SelectBoxTerm {

  constructor(name: string) {
    super(
      name,
      name,
      resources.TermsDescriptions.DRUG_LIST.TIER_COMPETITOR,
      constants.DRUG_TERM_PATHS.ALLOW_TIERS[1],
      '',
      CompetitorsTier.getValue(CompetitorsTier.EMPTY),
      'term__tier-competition'
    );
    this.values = CompetitorsTier.getValues();
  }
}
