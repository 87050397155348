import { resources } from '@qv-common/static';
import { drugTermsConstants } from '@qv-term/constants';
import { TermName } from '@qv-term/enums';
import { SelectBoxTerm } from '@qv-term/models/types';
import { PriorAuthRequired } from 'quantuvis-core-entities';

export class PriorAuthorizationRequiredCompetitorsProductTerm extends SelectBoxTerm {

  constructor(name: string) {
    const config = drugTermsConstants[TermName.PRIOR_AUTHORIZATION_REQUIRED_ON_COMPETITORS_PRODUCTS];

    super(
      name,
      config.displayNames.REQUIRED_ON_COMPETITORS_PRODUCTS,
      resources.TermsDescriptions.DRUG_LIST.PRIOR_AUTHORIZATION,
      '',
      '',
      PriorAuthRequired.getValue(PriorAuthRequired.NO),
      'term__prior-auth-required-comp-prod'
    );
    this.values = PriorAuthRequired.getValues();
  }
}
