import { Injectable } from '@angular/core';

import { TermName } from '@qv-term/enums';
import { constants } from '@qv-common/static';
import { TermFactory } from './term.factory';
import {
  AdministrationFeeTerm,
  LatePaymentPenaltyBasisTerm,
  LatePaymentPenaltyFrequencyTerm,
  LatePaymentPenaltyValueTerm,
  NoFaultTerminationClausePriorNoticeRequiredTerm,
  NoFaultTerminationClauseWhoMayInvokeTerm,
  PaymentFrequencyLagTerm,
  PaymentLagTerm,
  PharmaAuditFrequencyTerm,
  PharmaAuditLockBackTerm,
  PharmaAuditPriorNoticeTerm,
  RebateEligibilityTerm,
  WholesaleAcquisitionPriceTerm
} from '@qv-term/models/payment';

@Injectable()
export class PaymentFactory implements TermFactory {
  private paymentTerms = new Map<string, any>();

  public buildTerms(force?: boolean): Map<string, any> {
    if (force || this.paymentTerms.size === 0) {
      const contractTerms = constants.CONTRACT_TERMS;
      // TODO: update terms that uses displayName as they are working unexpected way
      this.paymentTerms.set(TermName.REBATE_ELIGIBILITY,
        new RebateEligibilityTerm(contractTerms.REBATE_ELIGIBILITY.label));
      this.paymentTerms.set(TermName.PAYMENT_LAG,
        new PaymentLagTerm(contractTerms.PAYMENT_LAG.label));
      this.paymentTerms.set(TermName.PAYMENT_FREQUENCY,
        new PaymentFrequencyLagTerm(contractTerms.PAYMENT_FREQUENCY.label));
      this.paymentTerms.set(TermName.WHOLESALE_ACQUISITION_PRICE,
        new WholesaleAcquisitionPriceTerm(contractTerms.WHOLESALE_ACQUISITION_PRICE.displayName));
      this.paymentTerms.set(TermName.LATE_PAYMENT_PENALTY_TERMS_BASIS,
        new LatePaymentPenaltyBasisTerm(contractTerms.LATE_PAYMENT_PENALTY_TERMS_BASIS.displayName));
      this.paymentTerms.set(TermName.LATE_PAYMENT_PENALTY_TERMS_VALUE,
        new LatePaymentPenaltyValueTerm(contractTerms.LATE_PAYMENT_PENALTY_TERMS_VALUE.displayName));
      this.paymentTerms.set(TermName.LATE_PAYMENT_PENALTY_TERMS_FREQUENCY,
        new LatePaymentPenaltyFrequencyTerm(contractTerms.LATE_PAYMENT_PENALTY_TERMS_FREQUENCY.displayName));
      this.paymentTerms.set(TermName.PHARMA_AUDIT_TERMS_AUDIT_FREQUENCY,
        new PharmaAuditFrequencyTerm(contractTerms.PHARMA_AUDIT_TERMS_AUDIT_FREQUENCY.displayName));
      this.paymentTerms.set(TermName.PHARMA_AUDIT_TERMS_PRIOR_NOTICE_REQUIRED,
        new PharmaAuditPriorNoticeTerm(contractTerms.PHARMA_AUDIT_TERMS_PRIOR_NOTICE_REQUIRED.displayName));
      this.paymentTerms.set(TermName.PHARMA_AUDIT_TERMS_LOOK_BACK,
        new PharmaAuditLockBackTerm(contractTerms.PHARMA_AUDIT_TERMS_LOOK_BACK.displayName));
      this.paymentTerms.set(TermName.NO_FAULT_TERMINATION_CLAUSE_WHO_MAY_INVOKE,
        new NoFaultTerminationClauseWhoMayInvokeTerm(
          contractTerms.NO_FAULT_TERMINATION_CLAUSE_WHO_MAY_INVOKE.displayName));
      this.paymentTerms.set(TermName.NO_FAULT_TERMINATION_CLAUSE_PRIOR_NOTICE_REQUIRED,
        new NoFaultTerminationClausePriorNoticeRequiredTerm(
          contractTerms.NO_FAULT_TERMINATION_CLAUSE_PRIOR_NOTICE_REQUIRED.displayName));
      this.paymentTerms.set(TermName.ADMINISTRATION_FEE,
        new AdministrationFeeTerm(contractTerms.ADMINISTRATION_FEE.label));
    }

    return this.paymentTerms;
  }
}
