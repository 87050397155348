import { DictionaryItem } from 'quantuvis-angular-common/interfaces';

export enum PriorAuthRequired {
  EMPTY = 76,
  YES,
  NO
}

export namespace PriorAuthRequired {
  const valuesMap = new Map<PriorAuthRequired, DictionaryItem>([
    [PriorAuthRequired.EMPTY, { id: PriorAuthRequired.EMPTY, name: '' }],
    [PriorAuthRequired.NO, { id: PriorAuthRequired.NO, name: 'No' }],
    [PriorAuthRequired.YES, { id: PriorAuthRequired.YES, name: 'Yes' }],
  ]);

  export function getValue(priorAuthAllowedProd: PriorAuthRequired): DictionaryItem {
    return valuesMap.get(priorAuthAllowedProd);
  }

  export function getValues(): DictionaryItem[] {
    return Array.from(valuesMap.values());
  }
}
