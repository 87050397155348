<ng-container *ngIf="!isEditPresentation(termValue, isEditMode, isOverrideMode, isUserPharma, isLocked)">
  <div [matTooltipDisabled]="!isTooltipEnabled || isAtNdc(termValue)"
       [matTooltip]="getTooltipMessage()"
       class="term term__select term--view">
    <ng-template
        (overwriteValuesConfirmed)="overwriteValue()"
        [qvAtNdc]="termValue"
        [setDisableClick]="!isEditable(isEditMode, isUserPharma, isLocked, isPending)">
      <div [class.term__value--empty]="!getTermValue()" class="term__value">
        {{ getTermValue() }}
      </div>
    </ng-template>
  </div>
</ng-container>

<ng-container *ngIf="isEditPresentation(termValue, isEditMode, isOverrideMode, isUserPharma, isLocked)">
  <ng-select #ngSelectCmp
             (blur)="onBlur()"
             (close)="completeEditMode()"
             (keydown.enter)="preventEvent($event)"
             (open)="disableNavEvent.emit()"
             [appendTo]="appendTo"
             [clearable]="false"
             [formControl]="control"
             [items]="selectItems"
             [matTooltipDisabled]="!isTooltipEnabled"
             [matTooltip]="control?.value?.name"
             [openOnEnter]="false"
             [searchable]="false"
             bindLabel="name"
             class="qv-select">
  </ng-select>
</ng-container>
