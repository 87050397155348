import { DictionaryItem } from 'quantuvis-angular-common/interfaces';

export enum PriceProtectionIndex {
  EMPTY = 44,
  ACTUAL_DRUG_WAC_PRICE,
  CONSUMER_PRICE_INDEX,
  CONSUMER_PRICE_INDEX_CONSUMERS,
  CHAINED_CONSUMER_PRICE_INDEX,
  CONSUMER_PRICE_INDEX_WORKERS,
  PRESCRIPTION_PRICE_INDEX,
  CONSUMER_PRICE_INDEX_CPI = 182
}

export namespace PriceProtectionIndex {
  const valuesMap = new Map<number, DictionaryItem>([
    [PriceProtectionIndex.EMPTY, { id: PriceProtectionIndex.EMPTY, name: '' }],
    [
      PriceProtectionIndex.ACTUAL_DRUG_WAC_PRICE,
      { id: PriceProtectionIndex.ACTUAL_DRUG_WAC_PRICE, name: 'Actual Drug WAC Price' }
    ],
    [
      PriceProtectionIndex.CONSUMER_PRICE_INDEX,
      { id: PriceProtectionIndex.CONSUMER_PRICE_INDEX, name: 'Consumer Price Index (CPI)' }
    ],
    [
      PriceProtectionIndex.CONSUMER_PRICE_INDEX_CONSUMERS,
      { id: PriceProtectionIndex.CONSUMER_PRICE_INDEX_CONSUMERS, name: 'Consumer Price Index-Urban Consumers (CPI-U)' }
    ],
    [
      PriceProtectionIndex.CHAINED_CONSUMER_PRICE_INDEX,
      { id: PriceProtectionIndex.CHAINED_CONSUMER_PRICE_INDEX, name: 'Chained Consumer Price Index (C-CPI-U)' }],
    [
      PriceProtectionIndex.CONSUMER_PRICE_INDEX_WORKERS,
      {
        id: PriceProtectionIndex.CONSUMER_PRICE_INDEX_WORKERS,
        name: 'Consumer Price Index- Urban Wage Earners and Clerical Workers (CPI-W)'
      }
    ],
    [
      PriceProtectionIndex.PRESCRIPTION_PRICE_INDEX,
      { id: PriceProtectionIndex.PRESCRIPTION_PRICE_INDEX, name: 'Prescription Price Index (PPI)' }
    ],
    [
      PriceProtectionIndex.CONSUMER_PRICE_INDEX_CPI,
      { id: PriceProtectionIndex.CONSUMER_PRICE_INDEX_CPI, name: 'Consumer Price Index (CPI) for Medical Care' }
    ]
  ]);

  export function getValue(index: PriceProtectionIndex): DictionaryItem {
    return valuesMap.get(index);
  }

  export function getValues(): DictionaryItem[] {
    return Array.from(valuesMap.values());
  }
}
