import isEqual from 'lodash.isequal';
import { WizardController } from '../shared/wizard/wizard-controller';
import { constants, resources } from '@qv-common/static';
import { finalize } from 'rxjs/operators';

class CreateInternalBidController extends WizardController {
  public static $inject = ['$scope', '$document', 'translations', 'CreateInternalBidService', 'biddingUtilsService',
    'spinnerService', '$window', 'router'];
  public confirmNavigationMessage = resources.BID_DETAILS.CONFIRM_NAVIGATION;
  public isWizardValid;
  public createBidError;
  public initValues;
  public stepTitles;
  public i18n;

  private allowLocationChange: boolean;

  constructor(private $scope, private $document, private translations, public CreateInternalBidService,
              private biddingUtilsService, private spinnerService, private $window, public router) {
    super();
    this.isWizardValid = true;
    this.createBidError = {
      visible: false,
      message: ''
    };
  }

  public $onInit(): void {
    this.biddingUtilsService.setCreatingInternalBid(true);
    this.$document.find('ui-view').addClass('vertical-flex-container full-height');
    this.CreateInternalBidService.initBidConfig();
    this.CreateInternalBidService.prepareBidTerms();
    this.translations.buildTranslations(this);
    this.currentStep = this.firstStepNumber;

    this.steps = ({
      summaryTerms: {
        title: this.i18n.NewBid.WizardTitles.SUMMARY_TERMS,
        order: 1,
        runValidation: false
      },
      contractTerms: {
        title: this.i18n.NewBid.WizardTitles.CONTRACT_TERMS,
        order: 2,
        runValidation: false
      },
      selectPayersInternal: {
        title: this.i18n.NewBid.WizardTitles.SELECT_PAYERS,
        order: 3,
        runValidation: false
      },
      selectPharmasDrugs: {
        title: this.i18n.NewBid.WizardTitles.SELECT_DRUGS,
        order: 4,
        runValidation: false
      },
      setDrugLevelTerms: {
        title: this.i18n.NewBid.WizardTitles.SET_DRUG_LEVEL_TERMS,
        order: 5,
        runValidation: false
      }
    } as any);

    this.countSteps = Object.keys(this.steps).length;
    this.stepTitles = this.prepareStepTitles();

    this.$window.tinymce.on('addeditor', () => {
      this.initValues = this.CreateInternalBidService.getValuesToSave();
    }, true);

    this.emitConfirmNavigationCallback();
  }

  public $onDestroy(): void {
    this.$document.find('ui-view').removeClass('vertical-flex-container full-height');
    this.biddingUtilsService.setCreatingInternalBid(false);
  }

  public isNavigationNeedConfirmation(): boolean {
    return !this.allowLocationChange && !isEqual(this.initValues, this.CreateInternalBidService.getValuesToSave());
  }

  public previous(): void {
    this.CreateInternalBidService.pickerError = '';

    return super.previous();
  }

  /**
   * Handler for save button
   */
  public save(): void {
    this.spinnerService.start(this.i18n.NewBid.SAVING_BID);
    this.CreateInternalBidService.save()
      .pipe(
        finalize(() => {
          this.allowLocationChange = true;
          this.spinnerService.stop();
        })
      )
      .subscribe();
  }

  public getCreationInfo(): string {
    if (this.steps[this.getCurrentStepKey()].order === 1) {
      return '';
    }

    const contractStartDateTermName = constants.SUMMARY_TERMS.CONTRACT_START_DATE.label;
    const contractEndDateTermName = constants.SUMMARY_TERMS.CONTRACT_END_DATE.label;
    const rfpTitleTermName = constants.SUMMARY_TERMS.RFP_TITLE.label;
    const lineOfBusinessTermName = constants.SUMMARY_TERMS.LINE_OF_BUSINESS.label;

    const data = `${this.CreateInternalBidService.getTermByName(contractStartDateTermName).value} -
                      ${this.CreateInternalBidService.getTermByName(contractEndDateTermName).value}`;

    return `${this.CreateInternalBidService.getTermByName(rfpTitleTermName).value} for
                ${this.CreateInternalBidService.getTermByName(lineOfBusinessTermName).value} (${data})`;
  }

}

export default CreateInternalBidController;
