import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Directive, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { SectionChange, SectionChangeFailed } from '@qv-bid/models';
import { BidDetailsNotificationService } from '@qv-bid/services';
import { SectionChangeManager } from '@qv-bid/services/sections/section-change-manager.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Contract, Summary } from '@qv-bid/entities';
import { ValidationError } from '@qv-common/models';
import { SeverityLevel, SvgIconName } from '@qv-common/enums';
import { TermName, TermSection } from '@qv-term/enums';
import { constants, resources } from '@qv-common/static';
import { drugTermsConstants } from '@qv-term/constants';
import { DrugFormService } from '@qv-bid/services/drug';
import { ScenarioDateValidationService } from '@qv-bid/services/sections/scenario-date-validation.service';
import { Moment } from 'moment';
import { RequestObject } from '@qv-bid/models/request-object.interface';
import { catchError, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { ContractDaoService } from '@qv-bid/services/dao';
import { BaseSection } from '@qv-bid/classes/sections/base-section';

@UntilDestroy()
@Directive()
export class ScenarioTermsSection extends BaseSection implements OnChanges, OnDestroy {
  @Input()
  public contract: Contract;
  @Input()
  public summary: Summary;
  @Input()
  public scenarioId: number;
  @Input()
  public drugId: number;
  @Input()
  public isBidInternal: boolean;

  public scenarioDateErrors = new Map<string, ValidationError[]>();
  public serverErrors = new Map<string, ValidationError[]>();

  public readonly svgIconName = SvgIconName;
  public readonly termName = TermName;
  public readonly resources = resources;
  public readonly drugTermsConstants = drugTermsConstants;
  public readonly sectionName = TermSection.SCENARIO;
  public readonly scenarioDateNotValidForNdcCode = 'scenario_date_not_valid_for_ndc';

  constructor(
    drugFormService: DrugFormService,
    private bidDetailsNotificationService: BidDetailsNotificationService,
    private scenarioDateValidationService: ScenarioDateValidationService,
    private contractDaoService: ContractDaoService,
    changeDetectorRef: ChangeDetectorRef,
    sectionChangeManager: SectionChangeManager,
  ) {
    super(drugFormService, changeDetectorRef, sectionChangeManager);
  }

  public initScenarioTermHandlers(): void {
    this.initSectionChangeHandler();
    this.initSectionEditModeChangeHandler();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.contract || changes.summary) {
      this.updateScenarioDateValidationErrors();
    }
  }

  public onScenarioDateChange(termName: TermName, data: Moment): void {
    this.scenarioDateValidationService.getValidationWarnings$(termName, data)
      .subscribe((errors: ValidationError[]) => {
        const innerErrors = this.getFormControlByTermName(termName).errors;
        if (innerErrors) {
          errors = [...errors, innerErrors as ValidationError];
        }
        this.scenarioDateErrors.set(termName, errors);
        this.changeDetectorRef.markForCheck();
      });
  }

  public updateScenarioDateValidationErrors(): void {
    if (!(this.contract && this.summary)) {
      this.scenarioDateErrors.set(this.termName.SCENARIO_START_DATE, []);
      this.scenarioDateErrors.set(this.termName.SCENARIO_END_DATE, []);

      return;
    }

    this.updateScenarioDateError(this.termName.SCENARIO_START_DATE);
    this.updateScenarioDateError(this.termName.SCENARIO_END_DATE);
  }

  protected initSectionChangeHandler(): void {
    this.drugFormService.sectionChangeHandler(this.sectionName).pipe(
      tap(() => this.sectionChangeManager.isSectionChanging$.next(true)),
      switchMap((changes) => this.updateContract(changes)),
      this.sectionLeaveHandler(),
    ).subscribe();
  }

  protected initSectionEditModeChangeHandler(): void {
    this.drugFormService.isEditMode(this.sectionName).pipe(
      distinctUntilChanged(),
      untilDestroyed(this)
    ).subscribe((isEditMode: boolean) => {
      this.isEditMode = isEditMode;
      this.changeDetectorRef.markForCheck();
    });
  }

  private updateContract(changes: RequestObject): Observable<Contract> {
    const contract = Object.assign(new Contract(), this.contract, changes);

    return this.contractDaoService.update(
      this.scenarioId,
      contract,
      this.isBidInternal,
      this.drugId,
    ).pipe(
      tap((updatedSection: Contract) => {
        const sectionChange = new SectionChange(this.scenarioId, this.drugId, this.sectionName, updatedSection);
        this.sectionChangeManager.sectionSaveSuccessEvent$.emit(sectionChange);
      }),
      catchError((errorResponse: HttpErrorResponse) => {
        this.handleServerError(errorResponse);
        this.sectionChangeManager.isSectionChangeFailed$.next(new SectionChangeFailed(this.sectionName, errorResponse));

        return of(this.contract);
      }),
    );
  }

  private handleServerError(errorResponse: HttpErrorResponse): void {
    if (errorResponse.error.code === this.scenarioDateNotValidForNdcCode) {
      this.serverErrors.set(this.termName.SCENARIO_END_DATE, [
        new ValidationError(SeverityLevel.ERROR, errorResponse.error.message)
      ]);
      this.bidDetailsNotificationService.error(errorResponse.error.message);
    }
  }

  private updateScenarioDateError(scenarioTermName: TermName): void {
    const isStartDate = scenarioTermName === TermName.SCENARIO_START_DATE;

    if (this.serverErrors.get(scenarioTermName)) {
      this.scenarioDateErrors.set(scenarioTermName, this.serverErrors.get(scenarioTermName));
      this.serverErrors.delete(scenarioTermName);
    } else {
      this.scenarioDateErrors.set(
        scenarioTermName,
        ScenarioDateValidationService.getValidationData(
          this.contract[scenarioTermName],
          isStartDate ? this.summary.contractStartDate : this.summary.contractEndDate,
          drugTermsConstants[scenarioTermName].title,
          constants.SUMMARY_TERMS[`CONTRACT_${isStartDate ? 'START' : 'END'}_DATE`].label
        )
      );
    }
  }
}
