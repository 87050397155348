import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnChanges,
  OnInit,
  SimpleChanges
} from '@angular/core';
import { ContractManager } from '@qv-bid/components/shared/base/classes/contract.manager';
import { ContractFormService } from '@qv-bid/components/shared/summary-panel/contract/contract-form.service';
import { Penalty } from '@qv-bid/entities';
import { SingleNotificationService } from '@qv-shared/services';
import { BidFormService } from '@qv-bid/services';
import { PenaltyDaoService } from '@qv-bid/services/dao/contract-terms';
import { Units } from '@qv-common/enums';
import { TermName, TermSection } from '@qv-term/enums';
import { LatePaymentPenaltyBasis } from '@qv-term/enums/options';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SnackBarService } from 'quantuvis-angular-common/snack-bar';
import { filter, switchMap, tap } from 'rxjs/operators';
import { NotificationService } from 'quantuvis-angular-common/notification';

@UntilDestroy()
@Component({
  selector: 'qv-penalty',
  templateUrl: './penalty.component.html',
  styleUrls: ['./penalty.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PenaltyComponent extends ContractManager<Penalty> implements OnInit, OnChanges {
  public penaltyValueUnit: string;
  public sectionName = TermSection.PENALTY;

  private units = new Units();

  constructor(
    protected daoService: PenaltyDaoService,
    protected contractFormService: ContractFormService,
    protected bidFormService: BidFormService,
    protected changeDetectorRef: ChangeDetectorRef,
    protected notificationService: NotificationService,
    snackBarService: SnackBarService,
    singleNotificationService: SingleNotificationService
  ) {
    super(
      contractFormService,
      bidFormService,
      changeDetectorRef,
      notificationService,
      snackBarService,
      singleNotificationService
    );
  }

  public ngOnInit(): void {
    this.entity$
      .pipe(untilDestroyed(this))
      .subscribe((penalty: Penalty) =>
        this.penaltyValueUnit = penalty ? this.getPenaltyValueUnit(penalty.basis.id) : '');

    this.isFormReady$.pipe(
      filter((isReady: boolean) => isReady),
      tap(() => this.initValueFormatter()),
      switchMap(() => this.formGroup.valueChanges),
      untilDestroyed(this)
    ).subscribe((changes: any) => {
      this.penaltyValueUnit = this.getPenaltyValueUnit(changes[TermName.LATE_PAYMENT_PENALTY_TERMS_BASIS].id);
    });

    if (Number.isInteger(this.contractedBusinessId)) {
      this.loadEntity();
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.contractedBusinessId && !changes.contractedBusinessId.firstChange) {
      this.loadEntity();
    } else if (changes.isEditMode && changes.isEditMode.currentValue) {
      this.initEditMode();
    }
  }

  public initValueFormatter(): void {
    const valueControl = this.formGroup.get(TermName.LATE_PAYMENT_PENALTY_TERMS_VALUE);
    const basisControl = this.formGroup.get(TermName.LATE_PAYMENT_PENALTY_TERMS_BASIS);

    basisControl.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
      valueControl.setValue('', { onlySelf: true, emitEvent: false });
    });
  }

  protected buildFormForSection(source: Penalty): void {
    this.contractFormService.buildPenaltyForm(source);
  }

  private getPenaltyValueUnit(penaltyBasis: LatePaymentPenaltyBasis): string {
    switch (penaltyBasis) {
      case LatePaymentPenaltyBasis.FLAT_AMOUNT: {
        return this.units.dollar;
      }
      case LatePaymentPenaltyBasis.PERCENT_OF_TOTAL_REBATES:
      case LatePaymentPenaltyBasis.PERCENT_OF_UNPAID_REBATES: {
        return this.units.percent;
      }
      default: {
        return '';
      }
    }
  }
}
