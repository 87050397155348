import { Pipe, PipeTransform } from '@angular/core';
import { Moment } from 'moment';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { User } from '@qv-user/entities/user.entity';

import 'moment-timezone';
import { appConfig } from '@qv-common/configs';
import { DateUtils } from '@qv-common/utils';
import { UserService } from '@qv-common/services/auth/user.service';

@Pipe({
  name: 'dateTimeUserTz'
})
export class DateTimeUserTzPipe implements PipeTransform {
  constructor(private userService: UserService) {}

  public transform(date: Moment, format: string = appConfig.dateTimeFormatShort): Observable<string> {
    return this.userService.user.pipe(
      take(1),
      map((user: User) => DateUtils.formatToTimezone(date, user.timezone.name, format))
    );
  }
}
