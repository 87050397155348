import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, NavigationExtras, Resolve, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map, pluck, tap } from 'rxjs/operators';
import { BidDaoService } from '@qv-bid/services/dao';

@Injectable({
  providedIn: 'root'
})
export class RedirectToNewViewResolver implements Resolve<Observable<boolean>> {
  private readonly redirectOptions: NavigationExtras = { replaceUrl: true };

  constructor(private bidDaoService: BidDaoService,
              private router: Router) {}

  public resolve(next: ActivatedRouteSnapshot): Observable<boolean> {
    return this.bidDaoService.convertOldBidIdToNew(next.queryParams.bidId).pipe(
      pluck('id'),
      tap((bidId: number) => setTimeout(() =>
        bidId
          ? this.router.navigate(['/standard', 'bid'], Object.assign(this.redirectOptions, { queryParams: { bidId } }))
          : this.goToBidsPage()
      )),
      catchError(() => this.goToBidsPage()),
      map(() => null),
    );
  }

  private goToBidsPage(): Observable<null> {
    this.router.navigate(['/bids'], this.redirectOptions);
    return of(null);
  }
}
