/* tslint:disable:no-var-requires */
import CreateInternalBidController from './create-internal-bid.controller';

// @ts-ignore
import templateUrl from './create-internal-bid.html';

const CreateInternalBidComponent = {
  template: templateUrl,
  bindings: {
    initConfirmNavigation: '&'
  },
  controller: CreateInternalBidController
};

export default CreateInternalBidComponent;
