import { ChangeDetectorRef, Directive, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ContractFormService } from '@qv-bid/components/shared/summary-panel/contract/contract-form.service';
import { BaseEntity } from '@qv-bid/entities';
import { SingleNotificationService } from '@qv-shared/services';
import { BidFormService } from '@qv-bid/services';
import { resources } from '@qv-common/static';
import { TermName, TermSection } from '@qv-term/enums';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SnackBarService } from 'quantuvis-angular-common/snack-bar';
import { BehaviorSubject, ReplaySubject, throwError } from 'rxjs';
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';
import { SingleNotification } from '@qv-shared/classes';
import { Toast } from 'ngx-toastr';
import { HttpErrorResponse } from '@angular/common/http';
import { NotificationService } from 'quantuvis-angular-common/notification';
import { FieldValidationMessage, FormValidationError } from '@qv-common/enums';

@UntilDestroy()
@Directive()
export abstract class ContractManager<T extends BaseEntity> {
  @Input()
  public contractedBusinessId: number;
  @Input()
  public isEditMode = false;
  @Input()
  public isBidInvalid: boolean;
  @Input()
  public isBidInternal: boolean;

  @Output()
  public sectionChanged = new EventEmitter<void>();

  public readonly termName = TermName;
  public readonly validationMessages = new Map<string, string>([
    [FormValidationError.REQUIRED, FieldValidationMessage.REQUIRED],
  ]);

  public entity$ = new ReplaySubject<T>(1);
  public entity: T;
  public isFormReady$ = new BehaviorSubject(false);
  public formGroup: FormGroup;

  protected abstract daoService;
  protected abstract sectionName: string;

  private bidSectionName = TermSection.CONTRACT;
  private singleNotification: SingleNotification<Toast>;

  protected constructor(
    protected contractFormService: ContractFormService,
    protected bidFormService: BidFormService,
    protected cdRef: ChangeDetectorRef,
    protected notificationService: NotificationService,
    private snackBarService: SnackBarService,
    private singleNotificationService: SingleNotificationService
  ) {
    this.singleNotification = this.singleNotificationService.getInstance<Toast>();
    this.entity$
      .pipe(untilDestroyed(this))
      .subscribe((data: T) => {
        this.entity = data;
        this.cdRef.markForCheck();
      });
  }

  protected loadEntity(): void {
    return this.daoService.get(this.contractedBusinessId)
      .pipe(
        map((data: T[]) => Array.isArray(data) && data.length > 0 ? data[0] : null),
      ).subscribe((data: T) => this.entity$.next(data));
  }

  protected initEditMode(): void {
    this.entity$.pipe(untilDestroyed(this))
      .subscribe((source: T) => this.buildFormForSection(source));

    this.contractFormService.isContractFormReady$.pipe(
      filter((isReady: boolean) => isReady),
      map(() => this.formGroup = (this.bidFormService.getBidForm(this.bidSectionName)
        .get(this.sectionName) as FormGroup)),
      tap((form: FormGroup) => setTimeout(() => this.isFormReady$.next(Boolean(form)))),
      switchMap(() => this.formGroup.valueChanges),
      tap(() => this.handleUpdateNotifications()),
      map((changes: { [key: string]: any }) => this.entity.applyChanges(changes)),
      filter(() => this.bidFormService.isBidValid()),
      tap(() => this.snackBarService.start()),
      switchMap((data: T) => this.daoService.update(this.contractedBusinessId, data, this.isBidInternal)
        .pipe(
          catchError((error: HttpErrorResponse) => {
            this.snackBarService.error();
            return throwError(error);
          })
        )
      ),
      tap(() => this.snackBarService.finish()),
      filter((data: T) => Boolean(data)),
      tap(() => this.sectionChanged.emit()),
      untilDestroyed(this)
    ).subscribe((data: T) => this.entity$.next(data));
  }

  protected abstract buildFormForSection(source: T): void;

  private handleUpdateNotifications(): void {
    if (this.bidFormService.isBidValid()) {
      this.singleNotification.clear();
    } else {
      this.singleNotification.toast(
        this.notificationService.error(resources.BID_DETAILS.INVALID_FIELDS_SAVING_ERROR)
      );
    }
  }
}
