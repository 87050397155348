<div *ngIf="isEditMode" class="actions-bar">
  <div
    class="actions-bar__button-wrapper"
    [matTooltip]="clearAllDisabledTooltipText"
    [matTooltipDisabled]="!isClearAllDisabled"
  >
    <button
      class="actions-bar__button"
      [disabled]="isClearAllDisabled"
      (click)="onClearAll()"
    >
      Clear All
    </button>
  </div>
</div>

<div class="header-bar">
  Drugs included in the Market Basket:
</div>

<div class="market-basket-drugs">
  <div *ngIf="!drugGroupMap.size" class="market-basket-drugs__no-data">
    <span>Please add at least one drug to Market Basket</span>
  </div>
  <ng-container *ngIf="drugGroupMap.size">
    <div *ngFor="let group of drugGroupMap | keyvalue" class="market-basket-drugs__item">
      <div class="market-basket-drug-class" [class.market-basket-drug-class--edit-mode]="isEditMode">
        <span
          class="market-basket-drug-class__name"
          [class.market-basket-drug-class__name--edit-mode]="isEditMode"
          (click)="onDrugClassClick(group.key)"
        >
          {{ group.key }}
        </span>
        <button
          *ngIf="isEditMode && group.key !== disabledDrugClass"
          mat-icon-button
          class="market-basket-drug-class__remove-button"
          (click)="onRemoveDrugClass(group.key)"
        >
          <mat-icon>close</mat-icon>
        </button>
      </div>
      <div
        *ngFor="let drug of group.value"
        class="market-basket-drug"
        [class.market-basket-drug--anchor]="drug.type === marketBasketDrugType.ANCHOR"
        [class.market-basket-drug--disadvantage]="drug.type === marketBasketDrugType.DISADVANTAGE"
        [class.market-basket-drug--reporting]="drug.type === marketBasketDrugType.REPORTING"
        [class.market-basket-drug--edit-mode]="isEditMode"
      >
        <mat-icon *ngIf="drug.type === marketBasketDrugType.ANCHOR" class="market-basket-drug__icon">
          {{ drugTypeIcon.getIconForType(marketBasketDrugType.ANCHOR) }}
        </mat-icon>
        <mat-icon *ngIf="drug.type === marketBasketDrugType.DISADVANTAGE" class="market-basket-drug__icon">
          {{ drugTypeIcon.getIconForType(marketBasketDrugType.DISADVANTAGE) }}
        </mat-icon>
        <mat-icon *ngIf="drug.type === marketBasketDrugType.REPORTING" class="market-basket-drug__icon">
          {{ drugTypeIcon.getIconForType(marketBasketDrugType.REPORTING) }}
        </mat-icon>
          {{ drug.name }}
        <ng-container *ngIf="isEditMode">
          <button [matMenuTriggerFor]="drugMenu" mat-icon-button class="market-basket-drug__menu-trigger">
            <mat-icon>arrow_drop_down</mat-icon>
          </button>
          <mat-menu #drugMenu="matMenu" xPosition="before" [overlapTrigger]="false">
            <button
              *ngFor="let drugType of marketBasketDrugType.getValues()"
              mat-menu-item
              class="market-basket-drug__menu-button"
              (click)="onChangeDrugType(drug, drugType.id)"
            >
              {{ drugType.name }} Drug
            </button>

            <ng-container *ngIf="drug.name !== disabledDrugName">
              <mat-divider class="market-basket-drug__menu-divider"></mat-divider>

              <button
                mat-menu-item
                class="market-basket-drug__menu-button"
                (click)="onRemoveDrug(drug.name, group.key)"
              >
                Remove Drug
              </button>
            </ng-container>
          </mat-menu>
        </ng-container>
      </div>
    </div>
  </ng-container>
</div>
