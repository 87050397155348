<button *ngIf="options.closeButton" (click)="remove()" class="toast-close-button" aria-label="Close">
  <span aria-hidden="true">&times;</span>
</button>
<div role="alertdialog" aria-live="polite" [class]="options.messageClass">
  {{ message }}
  <button
    mat-button
    class="filter-notification-button"
    (click)="clearFilters.next()">
    {{ clearFilterButtonText }}
  </button>
</div>
