import { DictionaryItem } from 'quantuvis-angular-common/interfaces';

export enum TierCompetition {
  EMPTY = 33,
  ONE_OF_ONE,
  ONE_OF_TWO,
  ONE_OF_TWO_WO_ML,
  ONE_OF_THREE,
  ONE_OF_THREE_WO_ML,
  ONE_OF_FOUR,
  ONE_OF_FIVE,
  ONE_OF_ALL,
  ONE_OF_MANY,
  BRAND_OVER_GENERIC
}

export namespace TierCompetition {
  const valuesMap = new Map<TierCompetition, DictionaryItem>([
    [TierCompetition.EMPTY, { id: TierCompetition.EMPTY, name: '' }],
    [TierCompetition.ONE_OF_ONE, { id: TierCompetition.ONE_OF_ONE, name: '1 of 1' }],
    [TierCompetition.ONE_OF_TWO, { id: TierCompetition.ONE_OF_TWO, name: '1 of 2' }],
    [
      TierCompetition.ONE_OF_TWO_WO_ML,
      { id: TierCompetition.ONE_OF_TWO_WO_ML, name: '1 of 2 w/o market leader' }
    ],
    [TierCompetition.ONE_OF_THREE, { id: TierCompetition.ONE_OF_THREE, name: '1 of 3' }],
    [
      TierCompetition.ONE_OF_THREE_WO_ML,
      { id: TierCompetition.ONE_OF_THREE_WO_ML, name: '1 of 3 w/o market leader' }
    ],
    [TierCompetition.ONE_OF_FOUR, { id: TierCompetition.ONE_OF_FOUR, name: '1 of 4' }],
    [TierCompetition.ONE_OF_FIVE, { id: TierCompetition.ONE_OF_FIVE, name: '1 of 5' }],
    [TierCompetition.ONE_OF_ALL, { id: TierCompetition.ONE_OF_ALL, name: '1 of All' }],
    [TierCompetition.ONE_OF_MANY, { id: TierCompetition.ONE_OF_MANY, name: '1 of Many' }],
    [
      TierCompetition.BRAND_OVER_GENERIC,
      { id: TierCompetition.BRAND_OVER_GENERIC, name: 'Brand over Generic' }
    ],
  ]);

  export function getValue(tierCompetition: TierCompetition): DictionaryItem {
    return valuesMap.get(tierCompetition);
  }

  export function getValues(): DictionaryItem[] {
    return Array.from(valuesMap.values());
  }
}

