import { InputTerm } from '@qv-term/models/types';
import { KeepOriginalValue, Reset } from '@qv-common/interfaces';
import { constants, resources } from '@qv-common/static';

export class LatePaymentPenaltyValueTerm extends InputTerm implements Reset, KeepOriginalValue {
  public allowKeepOriginValue: boolean;
  public processKeepOriginalValue: boolean;
  public keepOriginalValue: boolean;

  constructor(name: string) {
    super(
      name,
      name,
      resources.TermsDescriptions.CONTRACT.LATE_PAYMENT_PENALTY_TERMS_VALUE,
      constants.CONTRACT_TERMS.LATE_PAYMENT_PENALTY_TERMS_VALUE.label,
      '',
      '',
      'term__late-payment-penalty-value'
    );
    this.keepOriginalValue = this.allowKeepOriginValue;
    this.processKeepOriginalValue = false;
    this.suffix = this.unitsService.value;
  }

  public getValueToSave(): string {
    // TODO need add KeepOriginal logic
    return super.getValueToSave();
  }

  public resetValue(): void {
    // TODO need add KeepOriginal logic
    this.value = this.defaultValue;
  }
}
