import { BidListFilterState } from '@qv-bid-list/models';

export enum BidListTableColumnName {
  TITLE = 'TITLE',
  STATUS = 'STATUS',
  LINE_OF_BUSINESS = 'LINE_OF_BUSINESS',
  COMPANY_NAME = 'COMPANY_NAME',
  ASSIGNED_TO = 'ASSIGNED_TO',
  DATE_MODIFIED = 'DATE_MODIFIED',
  RFP_DUE_DATE = 'RFP_DUE_DATE',
  CONTRACTED_PERIOD = 'CONTRACTED_PERIOD'
}

export namespace BidListTableColumnName {
  const displayValues = new Map<BidListTableColumnName, string>([
    [BidListTableColumnName.TITLE, 'Title'],
    [BidListTableColumnName.STATUS, 'Status'],
    [BidListTableColumnName.LINE_OF_BUSINESS, 'Line Of Business'],
    [BidListTableColumnName.COMPANY_NAME, 'Company Name'],
    [BidListTableColumnName.ASSIGNED_TO, 'Assigned To'],
    [BidListTableColumnName.DATE_MODIFIED, 'Date Modified'],
    [BidListTableColumnName.RFP_DUE_DATE, 'RFP Due Date'],
    [BidListTableColumnName.CONTRACTED_PERIOD, 'Contracted Period'],
  ]);

  const stateValues = new Map<BidListTableColumnName, keyof BidListFilterState>([
    [BidListTableColumnName.TITLE, 'titles'],
    [BidListTableColumnName.STATUS, 'statuses'],
    [BidListTableColumnName.LINE_OF_BUSINESS, 'lineOfBusinesses'],
    [BidListTableColumnName.COMPANY_NAME, 'oppositeCompanyIds'],
    [BidListTableColumnName.ASSIGNED_TO, 'assignedToIds'],
    [BidListTableColumnName.DATE_MODIFIED, 'dateModifiedRange'],
    [BidListTableColumnName.RFP_DUE_DATE, 'rfpDueDateRange'],
    [BidListTableColumnName.CONTRACTED_PERIOD, 'contractedPeriodRange'],
  ]);

  export function displayValue(columnName: BidListTableColumnName): string {
    return displayValues.get(columnName);
  }

  export function stateValue(columnName: BidListTableColumnName): keyof BidListFilterState {
    return stateValues.get(columnName);
  }

  export function getCheckboxColumnNames(): BidListTableColumnName[] {
    return [
      BidListTableColumnName.TITLE,
      BidListTableColumnName.STATUS,
      BidListTableColumnName.LINE_OF_BUSINESS,
      BidListTableColumnName.COMPANY_NAME,
      BidListTableColumnName.ASSIGNED_TO,
    ];
  }

  export function getDateRangeColumnNames(): BidListTableColumnName[] {
    return [
      BidListTableColumnName.DATE_MODIFIED,
      BidListTableColumnName.RFP_DUE_DATE,
      BidListTableColumnName.CONTRACTED_PERIOD,
    ];
  }

  export function getKeys(): BidListTableColumnName[] {
    return Array.from(displayValues.keys());
  }
}
