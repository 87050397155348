export enum QuantuvisPlusFeature {
  COMPARE_OFFERED_BIDS = 'COMPARE_OFFERED_BIDS',
  BID_HISTORY = 'BID_HISTORY',
  REVISION_COMPARISON = 'REVISION_COMPARISON',
  PRINT = 'PRINT',
  EXPORT = 'EXPORT',
  STATUS_GRAPH = 'STATUS_GRAPH',
  ARCHIVED = 'ARCHIVED',
  GRID_VIEW = 'GRID_VIEW',
  PRINT_SCENARIO_SUMMARY = 'PRINT_SCENARIO_SUMMARY',
  INTERNAL_FIELDS = 'INTERNAL_FIELDS',
}
