import { ChangeDetectionStrategy, Component, ElementRef } from '@angular/core';
import { TermsAndConditionsEventBusService } from '../../services/terms-and-conditions-event-bus.service';
import { BaseInsideModal } from 'quantuvis-angular-common/modal';

@Component({
  selector: 'qv-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PrivacyPolicyComponent extends BaseInsideModal {
  constructor(
    private termsAndConditionsEventBusService: TermsAndConditionsEventBusService,
    private readonly elementRef: ElementRef
  ) {
    super();
  }

  public openTermOfServiceModal(): void {
    this.closeEvent.emit();
    this.termsAndConditionsEventBusService.openTermsOfServiceModal$.next();
  }

  public scrollTo(selector: string): void {
    this.elementRef.nativeElement.querySelector(selector).scrollIntoView({ behavior: 'smooth' });
  }
}
