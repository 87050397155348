import { DrugCb } from '@qv-bid/entities';

export class BidDeleteDrugCbMeta {
  public cbId: number;
  public cbName: string;
  public drugName: string;
  public isSelected = false;

  constructor(drug: DrugCb) {
    this.cbId = drug.cbId;
    this.cbName = drug.cbName;
    this.drugName = drug.drugName;
  }
}
