export class WindowUtils {
  public static openChildWindow(url: string, title: string, percentWidth: number, percentHeight: number): void {
    const nohUrl =
      `${window.location.protocol}//${window.location.host}${window.location.pathname}#${url}?noHeader=true`;

    // Fudge factors for window decoration space.
    // In my tests these work well on all platforms & browsers.
    let w = screen.width * percentWidth + 32;
    let h = screen.height * percentHeight + 96;
    let wleft = (screen.width - w) / 2;
    let wtop = (screen.height - h) / 2;
    // IE5 and other old browsers might allow a window that is
    // partially offscreen or wider than the screen. Fix that.
    // (Newer browsers fix this for us, but let's be thorough.)
    if (wleft < 0) {
      w = screen.width;
      wleft = 0;
    }
    if (wtop < 0) {
      h = screen.height;
      wtop = 0;
    }
    const win = window.open(nohUrl, title, `width=${w}, height=${h}, left=${wleft}, top=${wtop}, location=no,
      menubar=no, status=no, toolbar=no, scrollbars=yes, resizable=no`);
    // Just in case width and height are ignored
    win.resizeTo(w, h);
    // Just in case left and top are ignored
    win.moveTo(wleft, wtop);
    win.focus();
  }
}
