/* eslint-disable */
import moment from 'moment';
import { constants, resources } from '@qv-common/static';
import { CoreUtils, DateUtils, StringUtils } from '@qv-common/utils';

declare let angular: angular.IAngularStatic;
declare let $: any;

export class Util {s
  scrollLocation;
  httpPattern;
  viewModes;
  dateFormat;
  private EMAIL_PATTERN;
  private urlTemplatePattern;

  constructor() {

    this.EMAIL_PATTERN = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    this.urlTemplatePattern = /.*\[.+\|.+\].*/;

    // Vertical scroll position
    this.scrollLocation = null;

    this.httpPattern = /^https?:\/\//;

    this.viewModes = constants.VIEW_MODES;

    // The date format to use in the UI.
    this.dateFormat = 'MM/dd/yy';
  }

  /** Public functions */

  /**
   * Checks if the text matches the provided urlTemplatePattern.
   *
   * @param text
   *            the text to be checked against the pattern
   * @return {boolean} true if the text matches the pattern; false otherwise
   */
  matchURLTemplatePattern(text): boolean {
    return this.urlTemplatePattern.test(text);
  };

  /**
   * Checks if text is ending with provided search parameter
   * @param search - substring to search for
   * @param text - text to check for
   */
  endsWith(search, text): boolean {
    return new RegExp(search + '$').test(text);
  };

  getURLInformation(text): { description: string; url: string } {
    // extract content between square brackets to obtain the URL information
    var pattern = /\[(.*?)\]/g;
    var urlInfoAsArray = pattern.exec(text)[1].split('|');

    return {
      url: urlInfoAsArray[0],
      description: urlInfoAsArray[1]
    };
  };

  /**
   * Verifies if the provided object is 'undefined' or null.
   * @param toCheck object to verify
   * @returns {boolean} whether the object is undefined or null
   */
  isNotDefined(toCheck): boolean {
    return CoreUtils.isNotDefined(toCheck) || CoreUtils.isNull(toCheck);
  };

  /**
   * Verifies if the provided object is defined, not null
   * @param toCheck object to verify
   * @returns {boolean} whether the object if defined and it does not equal with null
   */
  isDefined(toCheck): boolean {
    return typeof toCheck !== 'undefined' && toCheck !== null;
  };

  /**
   * Verifies if the provided object is defined, not null and it does not equal with empty string
   * @param toCheck object to verify
   * @returns {boolean} whether the object if defined and it does not equal with empty string
   */
  isNotEmpty(toCheck): boolean {
    return !this.isNotDefined(toCheck) && toCheck != '';
  };

  /**
   * Removes empty string properties from provided object
   * @param object object from which to remove empty string properties
   * @returns new object without empty string properties present
   */
  removeEmptyStringPropertiesFromObject(object): any {
    let objectClone = Object.assign({}, object);
    Object.keys(object).map(key => {
      if (objectClone[key] === '') {
        delete objectClone[key];
      }
    });
    return objectClone;
  };

  /**
   * Recursively removes specified properties from provided object
   * @param object object from which to remove specified properties
   * @param propertyNameList list of strings representing properties to be removed from the object
   * @returns new object without specified properties present
   */
  removePropertiesFromObject(object, ...propertyNameList): any {
    if (propertyNameList.length === 0) {
      return object;
    }

    const objectClone = Object.assign({}, object);
    Object.keys(objectClone).forEach(key => {
      if (propertyNameList.includes(key)) {
        delete objectClone[key];
      } else if (typeof objectClone[key] === 'object') {
        objectClone[key] = this.removePropertiesFromObject(objectClone[key], ...propertyNameList);
      }
    });
    return objectClone;
  }

  /**
   * Returns the user full name.
   *
   * @param user the user containing first name and last name nedeed for full name
   * @return {String}  the user full name
   */
  getUserFullName(user): string {
    return user.firstName + ' ' + user.lastName;
  };


  /**
   * Returns company name the user belongs to.
   *
   * @param user the user specified
   * @return {String} the company name the user belongs to
   */
  getUserCompanyName(user): string | any {
    if (user && user.company) {
      return user.company.name;
    }

    return '';
  };

  /**
   * Returns company id the user belongs to.
   *
   * @param user the user specified
   * @return {String} the company name the user belongs to
   */
  getUserCompanyId(user): string {
    return user && user.company ? user.company.id : '';
  };

  /**
   *  Extracts company information for the logged in user
   */
  extractUserCompanyInformation(user): any {
    const company: any = {};
    company.type = user.role.type.toLowerCase();
    company.id = user.company.id;

    return company;
  };

  /**
   * Verifies if the given string contains any whitespaces.
   * @param str the string to verify
   * @returns {boolean} <code>true</code> if it contains white spaces
   */
  containsWhiteSpaces(str): boolean {
    return !/[^\s]+/.test(str);
  };

  /**
   * Verifies if the given string contains only whitespaces.
   * @param str the string to verify
   * @returns {boolean} <code>true</code> if it contains only white spaces
   */
  containsOnlyWhiteSpaces(str): boolean {
    return /^\s*$/.test(str);
  };

  /**
   * Verifies if the givens string is empty (it is also considered to be empty if it contains only white spaces).
   * @param str the string to verify
   * @returns {boolean|*} <code>true</code> if it is considered empty
   */
  isEmpty(str): boolean {
    return !str || this.containsWhiteSpaces(str);
  };

  /**
   * Iterates over all object properties and call callback with (key, value)
   *
   * @param {Object} obj
   * @param {Function} callback
   */
  forEachObjectProperty(obj, callback): void {
    const keys = Object.keys(obj);
    for (let i = 0, l = keys.length; i < l; i++) {
      callback(keys[i], obj[keys[i]]);
    }
  };

  /**
   * Parses the given string to a date in the correct date format. The date format is specified by <b>dateFormat</b> variable.
   * <b>Note:</b> This function does not ensure that the obtained date is valid.
   *
   * @param str the string to parse to a date
   * @returns {Date} the date object
   */
  parseDate(str): Date {
    var parts = str.split('/');
    return new Date(parts[2], parts[0] - 1, parts[1]);
  };

  /**
   * Verifies if the date if in the correct date format. The date format is specified by <b>dateFormat</b> variable.
   * @param str the string to verify
   * @returns {boolean} <code>true</code> is the string is in the correct date format
   */
  isCorrectDateFormat(str): boolean {
    return CoreUtils.isDefined(DateUtils.autoCorrectDateFormat(str));
  };

  /**
   * Validates a simple date without comparing it with depending dates.
   * @param term containing date term to verify
   * @param dateInTheFuture - boolean indicating if date to be validated must be in the future
   * @returns {boolean} true if the term is a valid date
   */
  validateSimpleDate(term, dateInTheFuture): boolean {
    var valid = true;
    delete term.definition.error;
    delete term.definition.warning;

    if (this.isEmpty(term.value)) {
      term.definition.error = term.name + ' should be specified';
      valid = false;
    } else {
      if (StringUtils.isString(term.value)) {
        if (!this.isCorrectDateFormat(term.value)) {
          term.definition.error = term.name + ' should be in MM/DD/YY format';
          valid = false;
        } else {
          if (!DateUtils.autoCorrectDateFormat(term.value)) {
            term.definition.error = term.name + ' should be a valid date';
            valid = false;
          } else if (CoreUtils.isNotDefined(dateInTheFuture) || dateInTheFuture != false) {
            if (this.isDateInThePast(term.value)) {
              term.definition.error = term.name + ' should be in the future';
              valid = false;
            }
          }
        }
      } else {
        if (CoreUtils.isNotDefined(dateInTheFuture) || dateInTheFuture != false) {
          if (this.isDateInThePast(term.value)) {
            term.definition.error = term.name + ' should be in the future';
            valid = false;
          }
        }
      }
    }
    return valid;
  };

  /**
   * Verifies if a specific date is in the past.
   *
   * @param dateToVerify the date to be verified if it is in the past
   * @returns {boolean} true if the date is in the past; false otherwise
   */
  isDateInThePast(dateToVerify): boolean {
    const todayStart = moment.utc().startOf('day').valueOf();
    return StringUtils.isString(dateToVerify)
      ? DateUtils.getDateBasedOnFormattedString(dateToVerify).getTime() < todayStart
      : new Date(dateToVerify).getTime() < todayStart;
  };

  /**
   * Date validator function
   *
   * term - term to verify
   * terms - terms that depend on
   * parent - parent bid
   * dateInTheFuture - boolean indicating if date to be validated must be in the future
   */
  validateDate(term, terms, parent, dateInTheFuture): boolean {
    delete term.definition.error;
    delete term.definition.warning;

    let valid = this.validateSimpleDate(term, dateInTheFuture);

    if (valid && parent) {
      const date = DateUtils.getDateBasedOnFormattedString(term.value);
      const startDate = terms.find(data => data.name === parent);

      if (startDate) {
        if (startDate.value && DateUtils.getDateBasedOnFormattedString(startDate.value).getTime() > date.getTime()) {
          term.definition.error = term.name + ' should be greater than ' + parent;
          valid = false;
        }
      }
    }
    return valid;
  };

  /**
   * Validator function in order to limit term value length and setting the error message for the specified term.
   *
   * @param term the term to limit
   * @param maxLength the limit value
   * @param errorMessage the error message to be set if the limit is reached
   * @return {Boolean} true if the term value doesn't reach the max length; false otherwise
   */
  validateLength(term, maxLength, errorMessage): boolean {
    var valid = true;
    delete term.definition.error;

    if (term.value && term.value.text && term.value.text.length > maxLength) {
      term.definition.error = errorMessage;
      valid = false;
    }

    return valid;
  };

  /**
   * Validator function in order to limit comments term value length and setting the error message in case it is exceeded.
   *
   * @param term the comments term to limit
   * @param errorMessage the error message to be set if the limit is reached
   * @return {Boolean} true if the comments concatenated together doesn't reach the max length; false otherwise
   */
  validateCommentsLength(term, errorMessage): boolean {
    delete term.definition.error;

    if (term.value && term.value.currentValue && term.value.currentValue.length > constants.COMMENTS_MAX_LENGTH) {
      term.definition.error = errorMessage;
      return false;
    }

    return true;
  };

  /**
   * Validator function in order to check if termFieldToValidate length exceeds the constants.DRUG_NOTES_MAX_LENGTH value.
   *
   * @param term  the specified term to set the error message on it
   * @param errorMessage the error message to be set on the term
   * @return {Boolean} true if the termFieldToValidate length does not exceed the max length allowed; false otherwise
   */
  validateLengthForDrugNotes(term, errorMessage): boolean {
    delete term.definition.error;

    if (!term.value) {
      return true;
    }

    const termFieldToValidate = term.value.text || term.value.currentValue;

    if (termFieldToValidate && termFieldToValidate.length > constants.DRUG_NOTES_MAX_LENGTH) {
      term.definition.error = errorMessage;
      return false;
    }
    return true;
  };

  /**
   *
   * @param options object containing options to validate for and for formatting purpose
   *        Supported options: min, max, decimals, step, minDecimals
   * @returns {Function}
   */
  getNumericValidation(options): (value) => (false | '' | string) {
    const min = CoreUtils.isDefined(options.min) && !CoreUtils.isNull(options.min) ? options.min : -Number.MAX_VALUE;
    const max = CoreUtils.isDefined(options.max) && !CoreUtils.isNull(options.max) ? options.max : Number.MAX_VALUE;
    const decimals = CoreUtils.isDefined(options.decimals) && !CoreUtils.isNull(options.decimals) ? options.decimals : 0;
    const step = CoreUtils.isDefined(options.step) && !CoreUtils.isNull(options.step) ? options.step : .1;
    const minDecimals = CoreUtils.isDefined(options.minDecimals) && !CoreUtils.isNull(options.minDecimals) ? options.minDecimals : decimals;

    const addDecimals = (value, decimalsNumber) => {
      const withDecimals = value.toFixed(decimalsNumber);
      return Number.parseFloat(withDecimals) === value ? withDecimals : value.toString();
    };

    return (value) => {
      if (isNaN(value)) {
        return false;
      }

      if (value === '') {
        return '';
      }

      const roundedValue = (Math.round(value / step / .1) * step * .1).toFixed(decimals);
      const formattedValue = addDecimals(Number.parseFloat(roundedValue), minDecimals);
      return min <= formattedValue && formattedValue <= max
        ? formattedValue
        : (formattedValue < min ? addDecimals(min, minDecimals) : addDecimals(max, minDecimals));
    };
  };

  /**
   * function user for retrieving a nested value of a javascript Object by passing a path, eg:
   * getNestedValue(term, "definition.numeric.decimals")
   *
   * @param object the javascript Object
   * @param path the path
   * @returns {*}
   */
  getNestedValue(object, path): any {
    var pathArray = path ? path.split('.') : '';
    var returnObject = object;
    for (var i = 0; i < pathArray.length; i++) {
      returnObject = returnObject[pathArray[i]];
    }
    return returnObject;
  };

  /**
   * function used for updating an object's nested value by passing a path, eg:
   * setNestedValue(term, 'definition.numeric.decimals', 3)
   *
   * @param object the javascript Object
   * @param path the path to inject value into
   * @param value the value to set
   */
  setNestedValue(object, path, value): void {
    var keys = path ? path.split(/\./) : path;
    var path_to_set = keys ? keys.pop() : '';
    if (keys) {
      angular.forEach(keys, function (obj_name) {
        if (!(typeof object[obj_name] === 'object')) {
          object[obj_name] = {};
        }
        object = object[obj_name];
      });
      object[path_to_set] = value;
    }
  };

  beginsWithHttp(url): boolean {
    return url ? this.httpPattern.test(url) : false;
  };

  prepareHttpUrl(link): string {
    return this.beginsWithHttp(link) ? link : `http://${link}`;
  };

  timezoneChanged(user, timezones): void {
    user.timezone = timezones.find(timezone => timezone.name == user.timezone.name);
  };

  /**
   * Validates email address against an email pattern.
   *
   * @param emailAddress - the email address to be validated
   * @returns {boolean} - true if the email address is valid, false otherwise
   */
  validateEmailAddress(emailAddress): boolean {
    return this.EMAIL_PATTERN.test(emailAddress);
  };

  /**
   * Method that creates a path delimited by dot (.) from an array of string values
   */
  getPathFromArray(pathValues): string {
    pathValues = pathValues || [];

    return pathValues.join('.');
  };

  applyScope(scope, closure): void {
    // TODO: Refactor scope.$apply && $$phase detection
    if (!scope.$$phase) {
      scope.$apply(closure);
    }

  };

  refreshScope(scope): void {
    // TODO: Refactor scope.$apply && $$phase detection
    if (!scope.$$phase) {
      scope.$apply();
    }
  };

  /**
   * Check if drug has @NDC terms
   * Exclude terms with ignoreOnNDCValue flag from checking
   *
   * @param drugTerms
   * @returns {boolean}
   */
  hasChangesAtAnyTermAtNDC(drugTerms): boolean {
    var inner = this;
    return drugTerms.some(term => term.definition && term.definition.ignoreOnNDCValue ? false : inner.hasChangesAtNDC(term));
  };

  buildGetterAndSetter(value): { set: (val) => any; get: () => any } {

    var _val = value;

    function get(): any {
      return _val;
    }

    function set(val): any {
      _val = val;
      return _val;
    }

    return {
      'get': get,
      'set': set
    };
  };

  payerCompanySupportsBindingBid(user): boolean | true | false {
    return user && user.company && user.company.bindingBidEnabled;
  };

  isPathDefined(rootObject, path): boolean {
    var available = true;

    if (this.isNotDefined(rootObject)) {
      available = false;
    } else {
      var lastPath = rootObject;
      var checkPath = path.split('.');
      checkPath.forEach((pathItem) => {
        if (available) {
          if (this.isNotDefined(lastPath[pathItem])) {
            available = false;
          } else {
            lastPath = lastPath[pathItem];
          }
        }
      });

    }

    return available;
  };

  extractScenarioNumber(scenarioName): number {
    const scenarioNamePrefix = constants.SCENARIO_INDICATOR_PREFIX;
    try {
      return parseInt(scenarioName.substr(scenarioNamePrefix.length));
    } catch (error) {
      throw new Error(`Can't get scenario number for ${scenarioName}: (${error.message})`);
    }
  };

  /**
   * Acts on a term value dependency, if the dependency exists
   * @param term - the term to get the dependency from
   * @param depName - term's dependency name
   * @param act - a function to apply to the dependency if the dependency exists
   * @returns - the result of applying 'act' over the term and its dependency, 'undefined' otherwise
   */
  actOnTermDependency(term, depName, act): undefined | any {
    if (term.definition.list) {
      var depTerm = term.definition.list[depName];
      if (depTerm) {
        return act(term, depTerm);
      }
    }
    return undefined;
  };

  /*************************/
  /* Drugs table utilities */

  /*************************/
  publishChanges(term, scope, optionalInfo): void {
    if (!term.definition.doNotSave) {
      var changeInfo: any = {
        'from': 'term',
        'term': term
      };

      if (optionalInfo && optionalInfo.drug) {
        changeInfo.drug = optionalInfo.drug;
      }

      scope.$emit('valueChanged', changeInfo);
    }
  };

  hasChangesAtNDC(term): boolean {
    var hasChanges = false;
    // Exclude terms which have hasNoAtNDCValue flag from @NDC checking, used for term views
    if (term && term.definition && !term.definition.hasNoAtNDCValue) {
      hasChanges = term.definition.hasAtNDC;
    }

    return hasChanges;
  };

  displayOriginalCustom(term): boolean {
    return term && term.definition.type == 5 && !term.definition.getCustomValue;
  };

  notNullOrEmpty(value): boolean {
    return (value && !CoreUtils.isNull(value) && value !== '');
  };

  clearTerm(term, scope, optional): void {

    term.definition.hasAtNDC = false;
    if (term.definition.resetValue) {
      term.definition.resetValue(term);
    } else if (term.definition.defaultValue) {
      term.value = term.definition.defaultValue;
    } else {
      term.value = '';
    }

    var publishChanges = this.publishChanges;
    //scope.publishChanges();
    publishChanges(term, scope, optional);

    if (term.definition.onChange) {
      term.definition.onChange(term);

      if (term.definition.valueDependencies) {
        term.definition.valueDependencies.forEach((dependency) => {
          const valueTerm = term.definition.list[dependency];

          if (valueTerm && valueTerm.definition.onChange) {
            term.definition.onChange(valueTerm);
          }
          valueTerm.definition.hasAtNDC = false;
          publishChanges(valueTerm, scope, optional);
        });
      }
    }
  };

  isPharma(user): boolean {
    return user && user.role && user.role.type === constants.RoleTypes.PHARMA;
  };

  capitaliseFirstLetter(string): string {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  saved(): void {
    var savingDiv = $('#saving');
    savingDiv.removeClass('savingError');
    savingDiv.addClass('savingSuccess');
    savingDiv.html('Saved');
    savingDiv.fadeOut(5000);
  };

  errorMessage(message): void {
    var savingDiv = $('#saving');
    savingDiv.stop(true, true).fadeIn();
    savingDiv.addClass('savingError');
    savingDiv.removeClass('savingSuccess');
    savingDiv.html('<span class=\'error btn-wide\'>' + message + '</span>');
  };

  clearErrorMessage(): void {
    var savingDiv = $('#saving');
    savingDiv.stop(true, true).fadeIn();
    savingDiv.removeClass('savingError');
    savingDiv.html('<span class=\'error btn-wide\'></span>');
  };

  saveError(): void {
    this.errorMessage('Error while saving');
  };

  saving(): void {
    var savingDiv = $('#saving');
    savingDiv.html('<span class=\'bidssavingspinnermessage\'>Saving </span>  <span class=\'bidssavingspinner\' ></span>');
    savingDiv.fadeIn();
  };

  /**
   * The method returns a string of size 'len' formed from the given 'str' padded with 'pad'. The string 'pad'
   * is added to the right. Example: pad('Fluvirin', 30, ' ') returns 'Fluvirin                    '.
   * This is similar to java's String.format("%-30s", 'Fluvirin')
   * @param str - the string that needs padding
   * @param len - the length of the final string
   * @param pad - characters used for padding
   * @returns a String formed from the given 'str' padded with 'pad'
   */
  pad(str, len, pad): any {

    if (typeof (len) == 'undefined') {
      len = 0;
    }
    if (typeof (pad) == 'undefined') {
      pad = ' ';
    }

    if (len + 1 >= str.length) {
      str = str + Array(len + 1 - str.length).join(pad);
    }
    return str;
  };

  /**
   * Verifies if the specified map is empty. The map should have an array as key value.
   *
   * @param mapData  - the map to be checked
   * @returns {boolean}  true if the map is empty, false otherwise
   */
  isEmptyMap(mapData): boolean {
    let empty = true;
    Object.values(mapData).forEach((value) => {
      if (empty && !CoreUtils.isEmpty(value)) {
        empty = false;
      }
    });

    return empty;
  };

  toTitleCase(str): string {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };

  resetValueDropdown(term): void {
    if (term.definition.processKeepOriginalValue) {
      term.value = resources.NewBid.KEEP_ORIGINAL_VALUE;
      term.definition.keepOriginalValue = true;
    } else {
      term.value = term.definition.defaultValue;
    }
  };

  resetValueInput(term): void {
    if (term.definition.processKeepOriginalValue) {
      term.value = '';
      term.definition.placeholder = resources.NewBid.KEEP_ORIGINAL_VALUE;
      term.definition.keepOriginalValue = true;
    } else {
      term.value = term.definition.defaultValue;
    }
  };

  resetValueDate(term): void {
    delete term.definition.error;
    delete term.definition.warning;
    term.value = term.definition.defaultValue;
    if (term.definition.processKeepOriginalValue) {
      term.definition.keepOriginalValue = true;
      term.definition.placeholder = resources.NewBid.KEEP_ORIGINAL_VALUE;
    }
  };

  getValueToSaveForInputField(term): string | any {
    if (term.definition.processKeepOriginalValue && term.definition.keepOriginalValue) {
      return resources.NewBid.KEEP_ORIGINAL_VALUE;
    }
    return term.value;
  };

  getViewModeClass(viewMode): 'listView' | 'gridView' {
    return viewMode === this.viewModes.LIST_VIEW || viewMode === this.viewModes.WIDE_VIEW ? 'listView' : 'gridView';
  };

  /**
   * returns specified class depends on User Status
   *
   * @returns class name
   */
  getClassForStatus(user, customClasses): string {
    var classValue = !customClasses ? 'color ' : customClasses + ' color ';

    const userStatus = constants.UserStatus;
    if (user.userStatus === userStatus.DISABLED || user.userStatus === userStatus.UNINVITED) {
      classValue += 'red';
    } else if (user.userStatus === userStatus.ENROLLED) {
      classValue += 'green';
    } else if (user.userStatus === userStatus.INVITED_EXPIRED || user.userStatus === userStatus.TEMPORARILY_LOCKED) {
      classValue += 'orange';
    } else {
      classValue += 'purple';
    }

    return classValue;
  };

  compareDrugs(first: any, second: any): boolean {
    return first.ndc === second.ndc &&
      first.name === second.name &&
      first.scenario.id === second.scenario.id;
  }

  /**
   * Saving scroll location
   *
   * @param location
   */
  setScrollLocation(location): void {
    this.scrollLocation = location;
  };

  /**
   * returns saved scroll location
   *
   * @returns
   */
  getScrollLocation(): any {
    return this.scrollLocation;
  };
}
