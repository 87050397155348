import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { QvCache } from '@qv-common/decorators';
import { SvgIconName } from '@qv-common/enums';
import { UserService } from '@qv-common/services/auth/user.service';

@Component({
  selector: 'qv-lock',
  templateUrl: './lock.component.html',
  styleUrls: ['./lock.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LockComponent implements OnInit {
  @Input()
  public locked = false;

  @Input()
  public btnClass: string;

  @Input()
  public iconClass: string;

  @Input()
  public isDisabled = false;

  @Input()
  public isEditMode = false;

  @Input()
  public control: FormControl;

  @Output()
  public clickEvent = new EventEmitter<void>();

  public readonly svgIconName = SvgIconName;

  public userIsPharma: boolean;

  public constructor(protected userService: UserService) {}

  public ngOnInit(): void {
    this.userIsPharma = this.userService.isCurrentUserPharma();
  }

  public onLockButtonClick(): void {
    if (this.control) {
      this.control.setValue(!this.locked);
    }
    this.clickEvent.emit();
  }

  @QvCache()
  public isVisible(userIsPharma: boolean, locked: boolean): boolean {
    return userIsPharma && locked === true || !userIsPharma;
  }

  @QvCache()
  public isEditable(isUserPharma: boolean, isEditMode: boolean, isDisabled: boolean): boolean {
    return isEditMode && !isUserPharma && !isDisabled;
  }
}
