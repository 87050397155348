<div *ngIf="signature$ | async" class="section-toolbar">
  <div class="section-toolbar__title">
    <h2>Legal Attestation Signature</h2>
    <mat-icon
        [matTooltip]="resources.TermsDescriptions.SUMMARY.LEGAL_ATTESTATION"
        [svgIcon]="svgIconName.INFO_OUTLINE"
        class="section-info">
    </mat-icon>
  </div>
</div>

<section *ngIf="signature$ | async as signature"
         class="table-section table-section--two-equal-columns table-section--striped">
  <div class="table-section__row">
    <div class="table-section__cell">
      <span class="table-section__title">Name:</span>
    </div>
    <div class="table-section__cell">
      <span class="table-section__value">{{ signature?.signature }}</span>
    </div>
  </div>
  <div class="table-section__row table-section__row--fixed-height">
    <div class="table-section__cell">
      <span class="table-section__title">Signature:</span>
    </div>
    <div class="table-section__cell">
      <span [matTooltip]="signature?.signature"
            class="table-section__value table-section__value--signature">{{ signature?.signature }}</span>
    </div>
  </div>
  <div class="table-section__row">
    <div class="table-section__cell">
      <span class="table-section__title">Company:</span>
    </div>
    <div class="table-section__cell">
      <span
          class="table-section__value">{{ getCompanyFullName(signature?.companyName, manufacturerCompanyOldName) }}</span>
    </div>
  </div>
  <div class="table-section__row">
    <div class="table-section__cell">
      <span class="table-section__title">Date:</span>
    </div>
    <div class="table-section__cell">
      <span class="table-section__value">{{ signature?.createdAt | dateTimeUserTz:dateFormat | async }}</span>
    </div>
  </div>
</section>

