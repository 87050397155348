import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
} from '@angular/core';
import { TermName, TermSection } from '@qv-term/enums';
import { StandardDrugFormService } from '@qv-bid/services/drug/standard-drug-form.service';
import { SvgIconName } from '@qv-common/enums';
import { QvCache } from '@qv-common/decorators';
import { ViewPerspectiveService } from '@qv-common/services/auth';
import { BidStateService } from '@qv-bid/services';
import { filter, switchMap, take } from 'rxjs/operators';
import { resources } from '@qv-common/static';
import { constants } from '@qv-common/static/constants';
import { statePerSection } from '@qv-bid/constants';
import { DictionaryItem } from '@qv-common/entities';
import { BidUtils } from '@qv-bid/utils';
import { UserService } from '@qv-common/services/auth/user.service';

@Component({
  selector: 'qv-standard-scenario-section-title',
  templateUrl: './standard-scenario-section-title.component.html',
  styleUrls: ['./standard-scenario-section-title.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StandardScenarioSectionTitleComponent implements OnInit {
  @Input() public title: string;
  @Input() public state: DictionaryItem;
  @Input() public isValid: boolean;
  @Input() public isAtNdc = false;
  @Input() public tooltip: string;
  @Input() public isEditMode: boolean;
  @Input() public sectionName: TermSection;
  @Input() public validationMessage?: string;
  public readonly svgIconName = SvgIconName;
  public readonly sectionTermName = TermName.STATE;
  public isPharmaOrPharmaPerspective: boolean;
  public readonly resources = resources;
  public readonly openStateDropDown = new EventEmitter();
  public readonly resetSelect = new EventEmitter();

  constructor(public standardDrugFormService: StandardDrugFormService,
              public bidStateService: BidStateService,
              private userService: UserService,
              private viewPerspectiveService: ViewPerspectiveService) {
  }

  public ngOnInit(): void {
    this.isPharmaOrPharmaPerspective = BidUtils.isUserPharmaOrPharmaPerspective(this.userService.isCurrentUserPharma(),
      this.bidStateService.bid$.getValue().isInternal, this.viewPerspectiveService.isPharmaViewPerspective());
  }

  public stateDependingOnMode(): DictionaryItem {
    return this.isEditMode
      ? this.standardDrugFormService.getTermControl(this.sectionTermName, this.sectionName).value
      : this.state;
  }

  @QvCache()
  public isWarningIconVisible(isPharmaOrPharmaPerspective: boolean, state: DictionaryItem, sectionName: TermSection,
                              isValid: boolean): boolean {
    return isPharmaOrPharmaPerspective && state && state.id === statePerSection[sectionName].REQUIRED && !isValid;
  }

  @QvCache()
  public isHidden(state: DictionaryItem, sectionName: TermSection): boolean {
    return state && state.id === statePerSection[sectionName].HIDDEN;
  }

  @QvCache()
  public isLocked(state: DictionaryItem, sectionName: TermSection): boolean {
    return state ? state.id === statePerSection[sectionName].LOCKED : false;
  }

  public openDropDownWhenAllConditionsFulfilled(): void {
    this.bidStateService.isEditMode.pipe(
      take(1),
      filter((isBidEditMode: boolean) => isBidEditMode && !this.isPharmaOrPharmaPerspective),
      switchMap(() => this.standardDrugFormService.isEditMode(this.sectionName)),
      filter((isEditModeForDrug: boolean) => isEditModeForDrug),
      take(1),
    ).subscribe(() => {
      this.openStateDropDown.next();
      this.resetSelect.emit();
      this.standardDrugFormService.getTermControl(this.sectionTermName, this.sectionName)
        .setValue(null, { emitEvent: false, onlySelf: true });
    });
  }

  public setStateFromDropdownOptions(state: DictionaryItem): void {
    const control = this.standardDrugFormService.getTermControl(this.sectionTermName, this.sectionName);
    control.markAsDirty();
    control.setValue(state);
  }

  @QvCache()
  public getSectionStateValue(state: DictionaryItem): string {
    return this.isAtNdc ? constants.AT_NDC : state.name;
  }

}
