import moment, { Moment, unitOfTime } from 'moment';
import { DateUtils } from '../utils';

export class DateRange {
  constructor(
    public startDate?: Moment,
    public endDate?: Moment
  ) {
  }

  public static getOneYearRangeFromToday(): DateRange {
    const startDate = moment().startOf('day');
    const endDate = moment().endOf('day').add(1, 'year');

    return new DateRange(startDate, endDate);
  }

  public static createNowRange(): DateRange {
    return new DateRange(moment(), moment());
  }

  public static createOverlappingRange(rangeA: DateRange, rangeB: DateRange): DateRange {
    const startDate = rangeA.startDate ?
      (rangeA.startDate.isAfter(rangeB.startDate) ? rangeA.startDate : rangeB.startDate) :
      null;

    const endDate = rangeA.endDate ?
      (rangeA.endDate.isBefore(rangeB.endDate) ? rangeA.endDate : rangeB.endDate) :
      null;

    return new DateRange(startDate, endDate);
  }

  public static getFirstDayOfTheYearQuarter(year: number, quarter: number): Moment {
    return moment().quarter(quarter).startOf('quarter').set('year', year);
  }

  public static getLastDayOfTheYearQuarter(year: number, quarter: number): Moment {
    return moment().quarter(quarter).endOf('quarter').set('year', year);
  }

  public getFullDateRangeInUTC(): DateRange {
    const startDate = Boolean(this.startDate) ?
      DateUtils.convertDateToUTC(this.startDate.clone()).startOf('day') : null;
    const endDate = Boolean(this.endDate) ?
      DateUtils.convertDateToUTC(this.endDate.clone()).endOf('day') : null;

    return new DateRange(startDate, endDate);
  }

  public isEmpty(): boolean {
    return !Boolean(this.startDate) && !Boolean(this.endDate);
  }

  public isIncomplete(): boolean {
    return Boolean(this.startDate) !== Boolean(this.endDate);
  }

  public isOverlappingWithRange(comparedDateRange: DateRange): boolean {
    return this.isPartiallyContainRange(comparedDateRange)
      || comparedDateRange.isPartiallyContainRange(this);
  }

  public isEqual(dateRange: DateRange, granularity?: unitOfTime.StartOf): boolean {
    return this.isSame(this.startDate, dateRange.startDate, granularity)
      && this.isSame(this.endDate, dateRange.endDate, granularity);
  }

  private isSame(date1: Moment, date2: Moment, granularity?: unitOfTime.StartOf): boolean {
    return (date1 === date2) || date1?.isSame(date2, granularity);
  }

  private isPartiallyContainRange(comparedDateRange: DateRange): boolean {
    const isStartDateInRange = this.isDateWithinRange(comparedDateRange.startDate);
    const isEndDateInRange = this.isDateWithinRange(comparedDateRange.endDate);

    return isStartDateInRange || isEndDateInRange;
  }

  private isDateWithinRange(date: Moment): boolean {
    const dateUtc = date.clone().utc(true);
    const startDateUtc = this.startDate.clone().utc(true);
    const endDateUtc = this.endDate.clone().utc(true);

    return dateUtc.isSameOrAfter(startDateUtc) && dateUtc.isSameOrBefore(endDateUtc);
  }
}
