import { DictionaryItem } from 'quantuvis-angular-common/interfaces';

export enum InvoicingTimelineStatus {
  EMPTY = 196,
  N_30_DAYS_AFTER_EOM,
  N_60_DAYS_AFTER_EOM,
  N_90_DAYS_AFTER_EOM,
  N_120_DAYS_AFTER_EOM,
  N_30_DAYS_AFTER_EOQ,
  N_60_DAYS_AFTER_EOQ,
  N_90_DAYS_AFTER_EOQ,
  N_120_DAYS_AFTER_EOQ,
}

export namespace InvoicingTimelineStatus {
  const valuesMap = new Map<InvoicingTimelineStatus, DictionaryItem>([
    [InvoicingTimelineStatus.EMPTY, { id: InvoicingTimelineStatus.EMPTY, name: '' }],
    [
      InvoicingTimelineStatus.N_30_DAYS_AFTER_EOM,
      { id: InvoicingTimelineStatus.N_30_DAYS_AFTER_EOM, name: '30 days after EOM' }],
    [
      InvoicingTimelineStatus.N_60_DAYS_AFTER_EOM,
      { id: InvoicingTimelineStatus.N_60_DAYS_AFTER_EOM, name: '60 days after EOM' }
    ],
    [
      InvoicingTimelineStatus.N_90_DAYS_AFTER_EOM,
      { id: InvoicingTimelineStatus.N_90_DAYS_AFTER_EOM, name: '90 days after EOM' }
    ],
    [
      InvoicingTimelineStatus.N_120_DAYS_AFTER_EOM,
      { id: InvoicingTimelineStatus.N_120_DAYS_AFTER_EOM, name: '120 days after EOM' }
    ],
    [
      InvoicingTimelineStatus.N_30_DAYS_AFTER_EOQ,
      { id: InvoicingTimelineStatus.N_30_DAYS_AFTER_EOQ, name: '30 days after EOQ' }
    ],
    [
      InvoicingTimelineStatus.N_60_DAYS_AFTER_EOQ,
      { id: InvoicingTimelineStatus.N_60_DAYS_AFTER_EOQ, name: '60 days after EOQ' }
    ],
    [
      InvoicingTimelineStatus.N_90_DAYS_AFTER_EOQ,
      { id: InvoicingTimelineStatus.N_90_DAYS_AFTER_EOQ, name: '90 days after EOQ' }
    ],
    [
      InvoicingTimelineStatus.N_120_DAYS_AFTER_EOQ,
      { id: InvoicingTimelineStatus.N_120_DAYS_AFTER_EOQ, name: '120 days after EOQ' }
    ],
  ]);

  export function getValue(price: InvoicingTimelineStatus): DictionaryItem {
    return valuesMap.get(price);
  }

  export function getValues(): DictionaryItem[] {
    return Array.from(valuesMap.values());
  }
}

