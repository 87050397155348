export enum SvgIconName {
  ACTIONS = 'actions',
  ADD = 'add',
  ADD_SQUARE = 'add-square',
  ADD_OUTLINE = 'add-outline',
  REMOVE_OUTLINE = 'remove-outline',
  ARCHIVED = 'archived',
  ADD_FILLED = 'add-filled',
  ARROW_BACK = 'arrow-back',
  ARROW_DOWN = 'arrow-down',
  ARROW_BOTTOM = 'arrow-bottom',
  ARROW_NEXT = 'arrow-next',
  ARROW_LEFT = 'arrow-left',
  ARROW_RIGHT = 'arrow-right',
  SORT_ARROW = 'sort-arrow',
  SORT_ASC = 'sort-asc',
  SORT_DESC = 'sort-desc',
  ATTACHED_FILE = 'attached-file',
  ATTENTION = 'attention',
  BELL = 'bell',
  BID_HISTORY = 'bid-history',
  BLOCK = 'block',
  BULLET = 'bullet',
  CIRCLE_OK = 'circle-ok',
  CHEVRON_BOTTOM = 'chevron-bottom',
  CHEVRON_BOTTOM_16 = 'chevron-bottom-16',
  CHEVRON_TOP = 'chevron-top',
  COLLAPSE = 'collapse',
  COLUMNS = 'columns',
  COMPARE = 'compare',
  COPY = 'copy',
  COPY_OUTLINED = 'copy-outlined',
  CURRENT_DOC = 'current-doc',
  DELETE = 'delete',
  DELETE_FILLED = 'delete-filled',
  DETAILS = 'details',
  DISCARD = 'discard',
  DOWNLOAD = 'download',
  EDIT = 'edit',
  FLAG = 'flag',
  EXPAND = 'expand',
  FILTER = 'filter',
  HIDE = 'hide',
  I_FILLED = 'i-filled',
  INFO_FILLED = 'info-filled',
  INFO_OUTLINE = 'info-outline',
  LOCK = 'lock',
  MARKET_BASKET = 'market-basket',
  HIDDEN = 'hidden',
  GRID = 'grid',
  MORE = 'more',
  NEXT = 'next',
  NDC = 'ndc',
  NOTES = 'notes',
  PAYER = 'payer',
  PHARMA = 'pharma',
  PRINTER = 'printer',
  REDO = 'redo',
  REOPEN = 'reopen',
  RESET = 'reset',
  ROUND_INFO = 'round-info',
  REVISION = 'revision',
  SEARCH = 'search',
  SEND = 'send',
  SPINNER = 'spinner',
  TILES = 'tiles',
  UNDO = 'undo',
  USER = 'user',
  VIEW = 'view',
  CLOSE = 'close',
  ZOOM_IN = 'zoom-in',
  ZOOM_OUT = 'zoom-out',
  UNLOCK = 'unlock',
  UPLOAD = 'upload',
  VISIT = 'visit',
  WARNING = 'warning',
  WARNING_COLOR = 'warning-color',
  ADD_COLUMN = 'add-column',
  PEN = 'pen',
  CHECK_FILLED = 'check-filled',
  EXTERNAL_LINK = 'external-link'
}
