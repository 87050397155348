export class ValidateNumberOptions {
  public readonly decimals: number;
  public readonly min: number;
  public readonly max: number;
  public readonly step: number;
  public readonly minDecimals: number;

  constructor(decimals: number, min: number, max: number, step: number, minDecimals?: number) {
    this.decimals = decimals;
    this.min = min;
    this.max = max;
    this.step = step;
    this.minDecimals = minDecimals;
  }
}
