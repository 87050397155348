<div class="term-um-details" [matTooltip]="getTooltipMessage(isEmptyValue(termValue.value))">
  <button mat-icon-button
    class="qv-button-icon qv-button-icon--center"
    [class.qv-button-icon--empty]="isEmptyValue(termValue.value)"
    [class.qv-button-icon--full]="!isEmptyValue(termValue.value)"
    [class.qv-button--at-ndc]="isAtNdc(termValue)"
    [disableRipple]="isAtNdc(termValue)"
    [disabled]="isButtonDisabled(isEmptyValue(termValue.value), isEditMode, isPending)"
    (click)="onOpenModal($any($event))">
    <mat-icon *ngIf="!isAtNdc(termValue)" [svgIcon]="svgIconName.NOTES"></mat-icon>
    <span *ngIf="isAtNdc(termValue)" class="at-ndc">{{ AT_NDC }}</span>
  </button>
</div>
