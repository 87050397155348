export enum ScenarioFieldName {
  EDITOR_ID = 'editorId',
  GROUP_ID = 'groupId',
  IS_ASSUMPTION_EXISTS = 'isAssumptionExists',
  STATUS = 'status',
  ELIGIBILITY_STATUS = 'eligibilityStatus',
  SOURCE_TYPE_ID = 'sourceTypeId',
  VOLUME = 'volume',
  EXCLUDED_NDC_COUNT = 'excludedNdcCount',
  START_DATE = 'startDate',
  END_DATE = 'endDate',
  CONTRACTED_BUSINESS_NAME = 'contractedBusinessName',
  TIER_PLACEMENT = 'tierPlacement',
  TIER_COMPETITION = 'tierCompetition',
  COMPETITION_GROUP = 'competitionGroup',
  COMPETITORS_TIER = 'competitorsTier',

  WHOLESALE_ACQUISITION_PRICE = 'wholesaleAcquisitionPrice',
  TARGET_DISCOUNT = 'targetDiscount',
  BASE_DISCOUNT = 'baseDiscount',
  ADMIN_FEE = 'adminFee',
  PORTAL_FEE = 'portalFee',
  MAX_FORMULARY_REBATE = 'maxFormularyRebate',
  ACTUAL_PP_REBATE = 'actualPpRebate',
  MARKET_SHARE_TIER = 'marketShareTier',
  NET_EFFECTIVE_PRICE = 'netEffectivePrice',

  PRICE_PROTECTION_INDEX = 'priceProtectionIndex',
  BASELINE_START_DATE = 'baselineStartDate',
  NEW_PRICE_EFFECTIVE_DATE = 'newPriceEffectiveDate',
  PRICE_PROTECTION_RESET = 'priceProtectionReset',
  PRICE_PROTECTION_THRESHOLD = 'priceProtectionThreshold',
  PRICE_PROTECTION_FEE_ABOVE_THRESHOLD = 'priceProtectionFeeAboveThreshold',
  PRICE_PROTECTION_FEE_BELOW_THRESHOLD = 'priceProtectionFeeBelowThreshold',
  THRESHOLD_HISTORY = 'thresholdHistory',
  ROLL_IN = 'rollIn',

  PRIOR_AUTH_ALLOWED = 'priorAuthAllowed',
  STEP_THERAPY_ALLOWED = 'stepTherapyAllowed',
  PRIOR_AUTH_REQUIRED = 'priorAuthRequired',
  STEP_THERAPY_REQUIRED = 'stepTherapyRequired',
  QUANTITY_LIMIT = 'quantityLimit',
  OTHER_UM_DETAILS = 'otherUmDetails',

  CHANNEL = 'channel',
  PLAN_TYPE = 'planType',
  PLAN_ID = 'planId',
  IS_FORMULARY_EXISTS = 'isFormularyExists',
  LIS_FLAG = 'lisFlag',
  PRICE_PROTECTION_METHOD = 'priceProtectionMethod',
  ASP_GUARANTEE_METHOD = 'aspGuaranteeMethod',
  PAYMENT_METHOD = 'paymentMethod',
  IS_EXCLUSION_EXISTS = 'isExclusionExists',
  NOTES = 'notes',
  QA_COMPLETED_DATE = 'qaCompletedDate',
  QA_COMPLETED_BY = 'qaCompletedBy',
  DATA_ENTERED_BY = 'dataEnteredBy',
  CORIUM_STATUS = 'coriumStatus',
  DATA_FEE = 'dataFee',
  ENTERPRISE_FEE = 'enterpriseFee',
  FORMULARY_COMPLIANCE_REBATE = 'formularyComplianceRebate'
}

export namespace ScenarioFieldName {
  const displayShortValues = new Map<ScenarioFieldName, string>([
    [ScenarioFieldName.IS_ASSUMPTION_EXISTS, 'Assumptions'],
    [ScenarioFieldName.STATUS, 'Status'],
    [ScenarioFieldName.ELIGIBILITY_STATUS, 'Eligibility'],
    [ScenarioFieldName.SOURCE_TYPE_ID, 'Source'],
    [ScenarioFieldName.VOLUME, 'Est. Vol.'],
    [ScenarioFieldName.EXCLUDED_NDC_COUNT, 'NDC'],
    [ScenarioFieldName.START_DATE, 'Start Date'],
    [ScenarioFieldName.END_DATE, 'End Date'],
    [ScenarioFieldName.CONTRACTED_BUSINESS_NAME, 'Contracted B.'],
    [ScenarioFieldName.TIER_PLACEMENT, 'Tier Placement'],
    [ScenarioFieldName.TIER_COMPETITION, 'Tier Comp.'],
    [ScenarioFieldName.COMPETITION_GROUP, 'Comp. Group'],
    [ScenarioFieldName.COMPETITORS_TIER, 'Comp. Tier'],

    [ScenarioFieldName.WHOLESALE_ACQUISITION_PRICE, 'WAC Price Basis'],
    [ScenarioFieldName.TARGET_DISCOUNT, 'Target'],
    [ScenarioFieldName.BASE_DISCOUNT, 'Base Discount'],
    [ScenarioFieldName.ADMIN_FEE, 'Admin Fee'],
    [ScenarioFieldName.PORTAL_FEE, 'Portal Fee'],
    [ScenarioFieldName.MAX_FORMULARY_REBATE, 'Max Formulary Rebate'],
    [ScenarioFieldName.ACTUAL_PP_REBATE, 'Actual PP Rebate'],
    [ScenarioFieldName.MARKET_SHARE_TIER, 'Market Share'],
    [ScenarioFieldName.NET_EFFECTIVE_PRICE, 'NEP'],
    [ScenarioFieldName.PRICE_PROTECTION_INDEX, 'Price Protection Index'],
    [ScenarioFieldName.BASELINE_START_DATE, 'PP Baseline'],
    [ScenarioFieldName.NEW_PRICE_EFFECTIVE_DATE, 'PP New Price Effective'],
    [ScenarioFieldName.PRICE_PROTECTION_RESET, 'PP Reset Method'],
    [ScenarioFieldName.PRICE_PROTECTION_THRESHOLD, 'PP Threshold'],
    [ScenarioFieldName.THRESHOLD_HISTORY, 'Threshold History'],
    [ScenarioFieldName.ROLL_IN, 'Roll-in'],
    [ScenarioFieldName.PRICE_PROTECTION_FEE_ABOVE_THRESHOLD, 'PP Fee Above Threshold'],
    [ScenarioFieldName.PRICE_PROTECTION_FEE_BELOW_THRESHOLD, 'PP Fee Below Threshold'],

    [ScenarioFieldName.PRIOR_AUTH_ALLOWED, 'Prod PA'],
    [ScenarioFieldName.STEP_THERAPY_ALLOWED, 'Prod ST'],
    [ScenarioFieldName.PRIOR_AUTH_REQUIRED, 'Comp PA'],
    [ScenarioFieldName.STEP_THERAPY_REQUIRED, 'Comp ST'],
    [ScenarioFieldName.QUANTITY_LIMIT, 'Quantity Limit'],
    [ScenarioFieldName.OTHER_UM_DETAILS, 'Other UM'],

    [ScenarioFieldName.CHANNEL, 'Channel'],
    [ScenarioFieldName.PLAN_TYPE, 'Plan Type'],
    [ScenarioFieldName.PLAN_ID, 'Plan ID'],
    [ScenarioFieldName.IS_FORMULARY_EXISTS, 'Formulary'],
    [ScenarioFieldName.LIS_FLAG, 'LIS Flag'],
    [ScenarioFieldName.PRICE_PROTECTION_METHOD, 'Price Protection Method'],
    [ScenarioFieldName.ASP_GUARANTEE_METHOD, 'ASP Guarantee Method'],
    [ScenarioFieldName.PAYMENT_METHOD, 'Payment Method'],
    [ScenarioFieldName.IS_EXCLUSION_EXISTS, 'Exclusions'],
    [ScenarioFieldName.NOTES, 'Notes'],
    [ScenarioFieldName.QA_COMPLETED_DATE, 'QA Completed Date'],
    [ScenarioFieldName.QA_COMPLETED_BY, 'QA Completed By'],
    [ScenarioFieldName.DATA_ENTERED_BY, 'Data Entered By'],
    [ScenarioFieldName.CORIUM_STATUS, 'Corium Status'],
    [ScenarioFieldName.DATA_FEE, 'Data Fee'],
    [ScenarioFieldName.ENTERPRISE_FEE, 'Enterprise Fee'],
    [ScenarioFieldName.FORMULARY_COMPLIANCE_REBATE, 'Formulary Compliance Rebate']
  ]);

  // keep ordering according to scenario table columns
  const displayValues = new Map<ScenarioFieldName, string>([
    [ScenarioFieldName.IS_ASSUMPTION_EXISTS, 'Assumptions'],
    [ScenarioFieldName.STATUS, 'Status'],
    [ScenarioFieldName.ELIGIBILITY_STATUS, 'Eligibility'],
    [ScenarioFieldName.SOURCE_TYPE_ID, 'Source'],
    [ScenarioFieldName.VOLUME, 'Estimated Volume'],
    [ScenarioFieldName.EXCLUDED_NDC_COUNT, 'NDC'],
    [ScenarioFieldName.START_DATE, 'Start Date'],
    [ScenarioFieldName.END_DATE, 'End Date'],
    [ScenarioFieldName.CONTRACTED_BUSINESS_NAME, 'Contracted Business'],
    [ScenarioFieldName.TIER_PLACEMENT, 'Tier Placement'],
    [ScenarioFieldName.TIER_COMPETITION, 'Tier Competition'],
    [ScenarioFieldName.COMPETITION_GROUP, 'Competition Group'],
    [ScenarioFieldName.COMPETITORS_TIER, 'Competitor\'s Tier'],

    [ScenarioFieldName.WHOLESALE_ACQUISITION_PRICE, 'WAC Price Basis'],
    [ScenarioFieldName.TARGET_DISCOUNT, 'Target Discount'],
    [ScenarioFieldName.BASE_DISCOUNT, 'Base Rebate'],
    [ScenarioFieldName.ADMIN_FEE, 'Admin Fee'],
    [ScenarioFieldName.PORTAL_FEE, 'Portal Fee'],
    [ScenarioFieldName.MAX_FORMULARY_REBATE, 'Max Formulary Rebate'],
    [ScenarioFieldName.ACTUAL_PP_REBATE, 'Actual PP Rebate'],
    [ScenarioFieldName.MARKET_SHARE_TIER, 'Market Share'],
    [ScenarioFieldName.NET_EFFECTIVE_PRICE, 'Net Effective Price'],
    [ScenarioFieldName.PRICE_PROTECTION_INDEX, 'Price Protection Index'],
    [ScenarioFieldName.BASELINE_START_DATE, 'Price Protection Baseline Start Date'],
    [ScenarioFieldName.NEW_PRICE_EFFECTIVE_DATE, 'Price Protection New Price Effective Date'],
    [ScenarioFieldName.PRICE_PROTECTION_RESET, 'Price Protection Reset Method'],
    [ScenarioFieldName.PRICE_PROTECTION_THRESHOLD, 'Price Protection Threshold'],
    [ScenarioFieldName.THRESHOLD_HISTORY, 'Threshold History'],
    [ScenarioFieldName.ROLL_IN, 'Roll-in'],
    [ScenarioFieldName.PRICE_PROTECTION_FEE_ABOVE_THRESHOLD, 'Price Protection Fee Above Threshold'],
    [ScenarioFieldName.PRICE_PROTECTION_FEE_BELOW_THRESHOLD, 'Price Protection Fee Below Threshold'],

    [ScenarioFieldName.PRIOR_AUTH_ALLOWED, 'Prior Authorization Allowed on Product'],
    [ScenarioFieldName.STEP_THERAPY_ALLOWED, 'Step Therapy Allowed on Product'],
    [ScenarioFieldName.PRIOR_AUTH_REQUIRED, 'Prior Authorization Required on Competitors Products'],
    [ScenarioFieldName.STEP_THERAPY_REQUIRED, 'Step Therapy Required on Competitors Products'],
    [ScenarioFieldName.QUANTITY_LIMIT, 'Quantity Limit'],
    [ScenarioFieldName.OTHER_UM_DETAILS, 'Other UM Details'],

    [ScenarioFieldName.CHANNEL, 'Channel'],
    [ScenarioFieldName.PLAN_TYPE, 'Plan Type'],
    [ScenarioFieldName.PLAN_ID, 'Plan ID'],
    [ScenarioFieldName.IS_FORMULARY_EXISTS, 'Formulary'],
    [ScenarioFieldName.LIS_FLAG, 'Low Income Subsidy Flag'],
    [ScenarioFieldName.PRICE_PROTECTION_METHOD, 'Price Protection Method'],
    [ScenarioFieldName.ASP_GUARANTEE_METHOD, 'ASP Guarantee Method'],
    [ScenarioFieldName.PAYMENT_METHOD, 'Payment Method'],
    [ScenarioFieldName.IS_EXCLUSION_EXISTS, 'Exclusions'],
    [ScenarioFieldName.NOTES, 'Notes'],
    [ScenarioFieldName.QA_COMPLETED_DATE, 'QA Completed Date'],
    [ScenarioFieldName.QA_COMPLETED_BY, 'QA Completed By'],
    [ScenarioFieldName.DATA_ENTERED_BY, 'Data Entered By'],
    [ScenarioFieldName.CORIUM_STATUS, 'Corium Status'],
    [ScenarioFieldName.DATA_FEE, 'Data Fee'],
    [ScenarioFieldName.ENTERPRISE_FEE, 'Enterprise Fee'],
    [ScenarioFieldName.FORMULARY_COMPLIANCE_REBATE, 'Formulary Compliance Rebate']
  ]);

  export const displayShortValue = (fieldName: ScenarioFieldName): string => displayShortValues.get(fieldName);

  export const displayValue = (fieldName: ScenarioFieldName): string => displayValues.get(fieldName);

  export function getValues(): ScenarioFieldName[] {
    return Array.from(displayValues.keys());
  }
}
