import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AssetService } from '@qv-shared/services/asset.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'qv-action-button',
  templateUrl: './action-button.component.html',
  styleUrls: ['./action-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActionButtonComponent implements OnInit {
  @Input()
  public title: string;
  @Input()
  public svgIconName: string;
  @Input()
  public tooltip: string;
  @Input()
  public cssClass: string;
  @Input()
  public disabled = false;
  @Input()
  public excludeBreakpoint = false;

  @Output()
  public buttonClick: EventEmitter<any> = new EventEmitter<any>();

  public icon: Observable<SVGElement>;

  constructor(private assetService: AssetService) {
    this.assetService = assetService;
  }

  public ngOnInit(): void {
    this.icon = this.assetService.getNamedSvgIcon(this.svgIconName);
  }

  public onButtonClick($event: any): void {
    if ($event && $event.currentTarget && typeof $event.currentTarget.blur === 'function') {
      $event.currentTarget.blur();
    }
    this.buttonClick.emit($event);
  }
}
