<!-- View mode -->
<editor
    *ngIf="!isEditMode"
    [init]="config"
    [initialValue]="initialValue"
    [inline]="true"
></editor>
<!-- Edit mode -->
<editor
    *ngIf="isEditMode"
    [formControl]="control"
    [init]="config"
    [inline]="false"
></editor>
