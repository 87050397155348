import { Injectable } from '@angular/core';
import { BidDaoService } from '@qv-bid/services/dao';
import { BehaviorSubject, Observable } from 'rxjs';
import { Bid } from '@qv-bid/entities';
import { filter, tap } from 'rxjs/operators';
import { ViewPerspectiveService } from '@qv-common/services/auth';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class BidService {
  public readonly currentBid$ = new BehaviorSubject<Bid>(null);

  constructor(private bidDaoService: BidDaoService,
              private viewPerspectiveService: ViewPerspectiveService) {
    this.initSetPerspectiveBasedOnBidTypeHandler();
  }

  public getById(bidId: number, perspective: string): Observable<Bid> {
    return this.bidDaoService.getById(bidId, perspective).pipe(tap((bid: Bid) => this.currentBid$.next(bid)));
  }

  private initSetPerspectiveBasedOnBidTypeHandler(): void {
    this.currentBid$.pipe(
      filter((bid: Bid) => bid && !bid.isInternal),
      untilDestroyed(this)
    )
      .subscribe(() => this.viewPerspectiveService.setDefaultViewPerspective());
  }
}
