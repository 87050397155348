import { HeaderMainComponent } from '@qv-header/components';
import { DatePickerComponent } from '@qv-shared/components';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { TruncateTextComponent } from '@qv-shared/components';

declare let angular: angular.IAngularStatic;

export const SharedNgModule = angular
  .module('quantuvis.shared', [])
  .directive('qvDatePicker',
    downgradeComponent({ component: DatePickerComponent }) as angular.IDirectiveFactory)
  .directive('qvHeaderMain',
    downgradeComponent({ component: HeaderMainComponent }) as angular.IDirectiveFactory)
  .directive('qvTruncateText',
    downgradeComponent({ component: TruncateTextComponent }) as angular.IDirectiveFactory);


@NgModule({
  imports: [
    BrowserModule,
    CommonModule,
    FormsModule,
    NgbModule,
  ],
})
export class SharedModule {
}
