<div class="horizontal-menu">
  <ul class="horizontal-menu__list">
    <li class="horizontal-menu__item" routerLinkActive="horizontal-menu__item--active">
      <a [matTooltip]="tooltips.DASHBOARD"
         class="horizontal-menu__link" routerLink="/dashboard">Dashboard</a>
    </li>
    <li *ngIf="isAllowed('quantuvis_administrator')" class="horizontal-menu__item"
        routerLinkActive="horizontal-menu__item--active">
      <a [matMenuTriggerFor]="menu" class="horizontal-menu__link">
        Administrator
      </a>
      <mat-menu #menu="matMenu" [overlapTrigger]="false">
        <button mat-menu-item routerLink="/administrator">Companies</button>
        <button mat-menu-item routerLink="/manufacturerMapping">Drug Subsidiary</button>
        <button mat-menu-item routerLink="/drugExceptions">Drug Exceptions</button>
        <button mat-menu-item routerLink="/productEnrollment">Product Enrollment</button>
      </mat-menu>
    </li>
    <li *ngIf="isAllowed('bids')" class="horizontal-menu__item" routerLinkActive="horizontal-menu__item--active">
      <a [matTooltip]="tooltips.BIDS" class="horizontal-menu__link" routerLink="/bids">Bids</a>
    </li>
    <li *ngIf="isAllowed('market_basket')" class="horizontal-menu__item"
        routerLinkActive="horizontal-menu__item--active">
      <a [matTooltip]="tooltips.MARKET_BASKETS" class="horizontal-menu__link"
         routerLink="/market-basket-templates">Market
        Baskets</a>
    </li>
    <li *ngIf="isInsightsSupported()" class="horizontal-menu__item"
        routerLinkActive="horizontal-menu__item--active">
      <a (menuOpened)="loadDashboards()" [matMenuTriggerFor]="insightsMenu" class="horizontal-menu__link">iQ</a>
    </li>
    <li *ngIf="isAllowed('people')"
        class="horizontal-menu__item"
        routerLinkActive="horizontal-menu__item--active">
      <a [matMenuTriggerFor]="menu" [matTooltip]="tooltips.MANAGEMENT" class="horizontal-menu__link">
        Management
      </a>
      <mat-menu #menu="matMenu" [overlapTrigger]="false" class="submenu-panel qv-menu">
        <button mat-menu-item routerLink="/people">People</button>
        <button *ngIf="isAllowed('new_user')" mat-menu-item routerLink="/rights">Rights</button>
        <button *ngIf="isLoiSupported()" mat-menu-item routerLink="/loi/letterOfIntent">
          Letter of Intent
        </button>
        <button *ngIf="isLegalAttestationSupported()" mat-menu-item routerLink="/legalAttestation">
          Legal Attestation
        </button>
      </mat-menu>
    </li>
    <li [class.horizontal-menu__item--active]="isUrlActive(router.url, 'drugSearch')"
        class="horizontal-menu__item">
      <a (click)="openDrugSearch()" [matTooltip]="tooltips.DRUG_SEARCH" class="horizontal-menu__link">Drug
        Search</a>
    </li>
    <li class="horizontal-menu__item">
      <a (click)="openHelpPage()" [matTooltip]="tooltips.HELP" class="horizontal-menu__link">Help</a>
    </li>
  </ul>
</div>

<mat-menu #insightsMenu="matMenu" [overlapTrigger]="false" class="submenu-panel">
  <button
      [disabled]="isDashboardsDisabled((standardDashboards$ | async)?.length, isStandardDashboardsLoading)"
      [matMenuTriggerFor]="standardDashboardMenu"
      [ngClass]="getDynamicMenuItemClass(isStandardDashboardsLoading)"
      mat-menu-item>
    Standard dashboards
  </button>
  <button
      [disabled]="isDashboardsDisabled((customDashboards$ | async)?.length, isCustomDashboardsLoading)"
      [matMenuTriggerFor]="customDashboardMenu"
      [ngClass]="getDynamicMenuItemClass(isCustomDashboardsLoading)"
      mat-menu-item>
    Custom dashboards
  </button>
</mat-menu>

<mat-menu #standardDashboardMenu="matMenu" [overlapTrigger]="false" class="submenu-panel">
  <button
      (click)="redirectToInsightsDashboard(dashboard.id)"
      *ngFor="let dashboard of standardDashboards$ | async"
      mat-menu-item>{{ dashboard.title }}</button>
</mat-menu>

<mat-menu #customDashboardMenu="matMenu" [overlapTrigger]="false" class="submenu-panel">
  <button
      (click)="redirectToInsightsDashboard(dashboard.id)"
      *ngFor="let dashboard of customDashboards$ | async"
      mat-menu-item>{{ dashboard.title }}</button>
</mat-menu>
