<nav class="system-menu">
  <div class="system-menu-top">
    <ng-container *ngFor="let menuItem of menuConfig">
      <ng-container *ngIf="menuItem !== '-'">
        <a *ngIf="!$any(menuItem).isExternal"
          class="system-menu__item"
          [routerLink]="[$any(menuItem).url]"
          [matTooltip]="$any(menuItem).description"
          matTooltipPosition="right"
          matTooltipClass="tooltip-primary"
          matTooltipShowDelay="0"
        >
          <mat-icon
            *ngIf="$any(menuItem).iconName"
            class="system-menu__icon"
            [class.system-menu__icon--active]="$any(menuItem).isActive"
            [ngClass]="$any(menuItem).iconClass"
            [svgIcon]="$any(menuItem).iconName"></mat-icon>
          <div
            *ngIf="$any(menuItem).iconClass && !$any(menuItem).iconName"
            class="system-menu__icon"
            [class.system-menu__icon--active]="$any(menuItem).isActive"
            [ngClass]="$any(menuItem).iconClass"></div>
        </a>

        <a *ngIf="$any(menuItem).isExternal"
          class="system-menu__item"
          target="_blank"
          [href]="$any(menuItem).url"
          [matTooltip]="$any(menuItem).description"
          matTooltipPosition="right"
          matTooltipClass="tooltip-primary"
          matTooltipShowDelay="0"
        >
          <mat-icon
            *ngIf="$any(menuItem).iconName"
            class="system-menu__icon"
            [class.system-menu__icon--active]="$any(menuItem).isActive"
            [svgIcon]="$any(menuItem).iconName"></mat-icon>
          <div
            *ngIf="$any(menuItem).iconClass"
            class="system-menu__icon"
            [class.system-menu__icon--active]="$any(menuItem).isActive"
            [ngClass]="$any(menuItem).iconClass"></div>
        </a>
      </ng-container>

      <ng-container *ngIf="menuItem === '-'">
        <mat-divider></mat-divider>
      </ng-container>
    </ng-container>
  </div>
  <div class="system-menu-bottom"></div>
</nav>
