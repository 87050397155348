import { DictionaryItem } from 'quantuvis-angular-common/interfaces';

export enum Channel {
  EMPTY = 96,
  PHARMACY,
  MEDICAL
}

export namespace Channel {
  const valuesMap = new Map<Channel, DictionaryItem>([
    [Channel.EMPTY, { id: Channel.EMPTY, name: '' }],
    [Channel.PHARMACY, { id: Channel.PHARMACY, name: 'Pharmacy' }],
    [Channel.MEDICAL, { id: Channel.MEDICAL, name: 'Medical' }]
  ]);

  export function getValue(channel: Channel): DictionaryItem {
    return valuesMap.get(channel);
  }

  export function getValues(): DictionaryItem[] {
    return Array.from(valuesMap.values());
  }
}
