/* tslint:disable:triple-equals */
import { SvgIconName } from '@qv-common/enums';
import { CoreUtils } from '@qv-common/utils';
import { ApiUrlPrefix } from '@qv-common/enums';
import { catchError, tap } from 'rxjs/operators';
import { HttpErrorResponse, HttpParams, HttpResponse } from '@angular/common/http';
import { of } from 'rxjs';

// @ts-ignore
import templateUrl from './manufacturer-mapping.html';

declare let $: any;
declare let angular: angular.IAngularStatic;

const ManufacturerMappingComponent = {
  template: templateUrl,
  bindings: {},
  controller: class {
    public static $inject = ['$scope', '$filter', '$window', '$timeout', '$q', 'NgTableParams', 'apiService', 'util'];

    constructor($scope, $filter, $window, $timeout, $q, NgTableParams, apiService, util) {
        'ngInject';
        // Private variables
        let preventDrugWatch;
        let preventSubsidiaryWatch;
        let preventSelectVersion = true;
        let tableData;
        let timeoutPromise;
        const delayInMs = 700;
        const resizeTable = () => {
          const footerHeight = $('#footer').outerHeight();
          const headerHeight = 100;
          const mappingControlsHeight = $('#mappingControls').outerHeight();
          const windowHeight = $window.innerHeight;
          const height = windowHeight - headerHeight - mappingControlsHeight - footerHeight - 50;
          $('#mappingTable').height(height);
        };
        const sort = {
          modified: 'asc',
          everythingExcept: 'asc',
          drugName: 'asc' // initial sorting
        };
        const clearModifiedInfo = () => {
          tableData.forEach(mapping => {
            mapping.modified = null;
          });
        };
        const toTitleCaseSubsidiaryNames = subsidiaryList => {
          subsidiaryList.forEach(item => {
            // this is for subsidiary names in mappings
            if (item.originalSubsidiaryName != null) {
              item.originalSubsidiaryName = util.toTitleCase(item.originalSubsidiaryName);
            }
            if (item.newSubsidiaryName != null) {
              item.newSubsidiaryName = util.toTitleCase(item.newSubsidiaryName);
            }
            // this is for subsidiary names in drugs json array
            // and for     subsidiary names in subsidiaries json array
            if (item.subsidiaryName != null) {
              item.subsidiaryName = util.toTitleCase(item.subsidiaryName);
            }
          });
        };
        const createSubsidiaryFromMapping = mapping => ({
          medispanLabelerId: mapping.newMedispanLabelerId,
          pharmaName: mapping.newPharmaName,
          subsidiaryId: mapping.newSubsidiaryId,
          subsidiaryName: mapping.newSubsidiaryName
        });
        const createDrugFromMapping = mapping => ({
          medispanLabelerId: mapping.originalMedispanLabelerId,
          name: mapping.drugName,
          pharma: mapping.originalPharmaName,
          subsidiaryId: mapping.originalSubsidiaryId,
          subsidiaryName: mapping.originalSubsidiaryName,
          everythingExcept: mapping.everythingExcept
        });
        // drug has the new subsidiary info and the original subsidiary info prefixed
        // with 'original' (when drug comes from database)
        const createMappingFromDrug = drug => ({
          drugName: drug.name,
          newMedispanLabelerId: drug.medispanLabelerId,
          newPharmaName: drug.pharma,
          newSubsidiaryId: drug.subsidiaryId,
          newSubsidiaryName: drug.subsidiaryName,
          originalMedispanLabelerId: drug.originalMedispanLabelerId,
          originalPharmaName: drug.originalPharmaName,
          originalSubsidiaryId: drug.originalSubsidiaryId,
          originalSubsidiaryName: drug.originalSubsidiaryName
        });
        // drug has the original subsidiary info and subsidiary has the new subsidiary info (when drug comes from table)
        const createMappingFromDrugAndSubsidiary = (drug, subsidiary) => ({
          drugName: drug.name,
          newMedispanLabelerId: subsidiary.medispanLabelerId,
          newPharmaName: subsidiary.pharmaName,
          newSubsidiaryId: subsidiary.subsidiaryId,
          newSubsidiaryName: subsidiary.subsidiaryName,
          originalMedispanLabelerId: drug.medispanLabelerId,
          originalPharmaName: drug.pharma,
          originalSubsidiaryId: drug.subsidiaryId,
          originalSubsidiaryName: drug.subsidiaryName
        });
        const selectDrugOnly = drug => {
          $scope.drugs = [];
          $scope.drugNameSearchInfo = drug.name;
          if (CoreUtils.isNotDefined(drug.everythingExcept)) {
            drug.everythingExcept = false;
            $scope.disableEdit = false;
            $scope.disableSubsidiaryEdit = false;
          } else {
            $scope.disableEdit = drug.everythingExcept;
            $scope.disableSubsidiaryEdit = drug.everythingExcept;
          }
          $scope.selectedDrug = drug;
          preventSubsidiaryWatch = true;
          $scope.subsidiaries = [];
          $scope.selectedSub = null;
          $scope.subsidiarySearchInfo = null;
          setTimeout(resizeTable, 0);
        };
        const selectDrugAndSubsidiary = (drug, subsidiary) => {
          preventSubsidiaryWatch = true;
          preventDrugWatch = true;
          selectDrugOnly(drug);
          $scope.selectSubsidiary(subsidiary);
        };
        let error = false;
        const errorMessage = data => {
          error = true;
          util.errorMessage(`Error getting data: ${data.message}`);
          const savingDiv = $('#saving');
          util.applyScope($scope);
        };
        const clearErrorMessage = () => {
          if (error) {
            error = false;
            util.clearErrorMessage();
          }
        };


        // do not allow change for everythingExcept rows. Except for deletion.
        $scope.disableEdit = false;
        $scope.disableSubsidiaryEdit = false;
        // Drug search variables
        $scope.drugNameSearchInfo = null;
        $scope.drugs = [];
        $scope.selectedDrug = null;

        // Subsidiary search variables
        $scope.subsidiaries = [];
        $scope.subsidiarySearchInfo = null;
        $scope.selectedSub = null;

        $scope.dirtyTable = false;

        $scope.svgIconName = SvgIconName;

        /************ Scope Watchers ************/
        $scope.$watch('drugNameSearchInfo', (newVal, oldVal) => {
          clearErrorMessage();
          $timeout.cancel(timeoutPromise);
          if (preventDrugWatch) {
            preventDrugWatch = false;
          } else if (newVal != null && CoreUtils.isDefined(newVal) && newVal.length > 1) {
            timeoutPromise = $timeout(() => {
              $scope.selectedDrug = null;
              $('#drug-search-add').addClass('spinner');
              const options = {
                params: new HttpParams().set('pattern', $scope.drugNameSearchInfo)
              };

              apiService.get(`${ApiUrlPrefix.OLD}/drugs/search/name/all`, options).pipe(
                tap((response: HttpResponse<any>) => {
                  const data = response.body;
                  toTitleCaseSubsidiaryNames(data.responseObject);
                  $scope.drugs = data.responseObject;
                  $('#drug-search-add').removeClass('spinner');
                  util.applyScope($scope);
                }),
                catchError((response: HttpErrorResponse) => {
                  $('#manufacturer-search-add').removeClass('spinner');
                  errorMessage(response);
                  return of(null);
                })
              ).subscribe();
            }, delayInMs);
          } else {
            $('#drug-search-add').removeClass('spinner');
            $scope.selectedDrug = null;
            $scope.drugs = [];
          }
        });
        $scope.$watch('subsidiarySearchInfo', (newVal, oldVal) => {
          clearErrorMessage();
          $timeout.cancel(timeoutPromise);
          if (preventSubsidiaryWatch) {
            preventSubsidiaryWatch = false;
          } else if (newVal != null && CoreUtils.isDefined(newVal) && newVal.length > 1) {
            timeoutPromise = $timeout(() => {
              $scope.selectedSub = null;
              $('#manufacturer-search-add').addClass('spinner');
              const url = `${ApiUrlPrefix.OLD}/manufacturers/searchSubsidiariesWithPharma`;
              const options = {
                params: new HttpParams().set('pattern', $scope.subsidiarySearchInfo)
              };
              apiService.get(url, options).pipe(
                tap((response: HttpResponse<any>) => {
                  const data = response.body;
                  $('#manufacturer-search-add').removeClass('spinner');
                  data.forEach(sub => {
                    if (sub.subsidiaryName != null) {
                      sub.subsidiaryName = util.toTitleCase(sub.subsidiaryName);
                    }
                  });
                  $scope.subsidiaries = data;
                  util.applyScope($scope);
                }),
                catchError((response: HttpErrorResponse) => {
                  $('#manufacturer-search-add').removeClass('spinner');
                  errorMessage(response);
                  return of(null);
                })
              ).subscribe();
            }, delayInMs);
          } else {
            $('#manufacturer-search-add').removeClass('spinner');
            $scope.selectedSub = null;
            $scope.subsidiaries = [];
          }
        });
        $scope.$watch('selectedVersion', (newVal, oldVal) => {
          clearErrorMessage();
          if (preventSelectVersion) {
            preventSelectVersion = false;
            return;
          }
          if (newVal != null && CoreUtils.isDefined(newVal)) {
            apiService.get(`${ApiUrlPrefix.OLD}/manufacturerMapping/getAll/${newVal}`).pipe(
              tap((response: HttpResponse<any>) => {
                const data = response.body;
                toTitleCaseSubsidiaryNames(data.responseObject);
                tableData = data.responseObject;
                if (!$scope.isLatestVersion(newVal)) {
                  $scope.dirtyTable = true;
                }
                $scope.tableParams.reload();
              }),
              catchError((response: HttpErrorResponse) => {
                errorMessage(response);
                return of(null);
              })
            ).subscribe();
          }
        });

        $scope.$watch('selectedDrug.everythingExcept', (newVal, oldVal) => {
          clearErrorMessage();
          if (!oldVal && newVal) {
            let foundMapping = null;
            tableData.forEach(mapping => {
              if (foundMapping == null && mapping.everythingExcept
                && mapping.originalSubsidiaryId == $scope.selectedDrug.subsidiaryId) {
                foundMapping = mapping;
              }
            });
            if (foundMapping != null) {
              $scope.selectSubsidiary(createSubsidiaryFromMapping(foundMapping));
            }
          }
          $scope.disableSubsidiaryEdit = newVal;
        });

        $scope.foundDrugs = () => !$.isEmptyObject($scope.drugs);

        $scope.cancelSearch = attr => {
          // tslint:disable-next-line:triple-equals
          if (attr == 'drugs') {
            $scope.clearDrugs();
            // tslint:disable-next-line:triple-equals
          } else if (attr == 'subsidiaries') {
            $scope.clearSubsidiaries();
          }
        };

        $scope.selectDrug = drug => {
          clearErrorMessage();
          preventDrugWatch = true;
          // first search the drug in table
          let found = false;
          tableData.forEach(mapping => {
            // tslint:disable-next-line:triple-equals
            if (!found && mapping.drugName == drug.name &&
              // tslint:disable-next-line:triple-equals
              (drug.originalSubsidiaryId != null && mapping.originalSubsidiaryId == drug.originalSubsidiaryId ||
                drug.originalSubsidiaryId == null && mapping.originalSubsidiaryId == drug.subsidiaryId)) {
              $scope.selectFromMapping(mapping);
              found = true;
            }
          });
          if (found) {
            return;
          }
          if (drug.originalSubsidiaryId != null) {
            $scope.selectFromMapping(createMappingFromDrug(drug));
            return;
          }

          selectDrugOnly(drug);

        };

        $scope.drugSelected = () => $scope.selectedDrug !== null;

        $scope.searchDrug = () => $scope.selectedDrug = null;

        $scope.foundSubsidiaries = () => $scope.subsidiaries.length > 0;

        $scope.selectSubsidiary = subsidiary => {
          preventSubsidiaryWatch = true;
          $scope.subsidiaries = [];
          $scope.selectedSub = subsidiary;
          $scope.subsidiarySearchInfo = subsidiary.subsidiaryName;
        };

        $scope.subsidiarySelected = () => $scope.selectedSub != null;

        $scope.searchSubsidiary = () => $scope.selectedSub = null;

        $scope.clearAll = () => {
          $scope.clearDrugs();
          $scope.clearSubsidiaries();
          setTimeout(resizeTable, 0);
        };

        $scope.clearDrugs = () => {
          if ($scope.drugNameSearchInfo != null) {
            preventDrugWatch = true;
          }
          $scope.selectedDrug = null;
          $scope.drugs = [];
          $scope.drugNameSearchInfo = null;
        };
        $scope.clearSubsidiaries = () => {
          if ($scope.subsidiarySearchInfo != null) {
            preventSubsidiaryWatch = true;
          }
          $scope.subsidiaries = [];
          $scope.selectedSub = null;
          $scope.subsidiarySearchInfo = null;
        };
        $scope.versions = [
          {
            version: 0,
            timestamp: new Date()
          }
        ];
        preventSelectVersion = true;
        $scope.selectedVersion = $scope.versions[0].version;
        // tslint:disable-next-line:triple-equals
        $scope.isLatestVersion = version => $scope.versions[0].version == version;
        const getVersions = preventWatch => {
          apiService.get(`${ApiUrlPrefix.OLD}/manufacturerMapping/getVersions`).pipe(
            tap((response: HttpResponse<any>) => {
              $scope.versions = response.body.responseObject;
              preventSelectVersion = preventWatch;
              $scope.selectedVersion = $scope.versions[0].version;
              util.applyScope($scope);
            }),
            catchError(() => {
              util.applyScope($scope);
              return of(null);
            })
          ).subscribe();
        };
        getVersions(true);
        // [Mapping Table
        apiService.get(`${ApiUrlPrefix.OLD}/manufacturerMapping/getAll/0`).pipe(
          tap((response: HttpResponse<any>) => {
            const data = response.body;
            toTitleCaseSubsidiaryNames(data.responseObject);
            tableData = data.responseObject;

            $scope.addToTable = () => {
              clearModifiedInfo();
              const newMapping = {
                drugName: $scope.selectedDrug.name,
                originalSubsidiaryId: $scope.selectedDrug.subsidiaryId,
                originalSubsidiaryName: $scope.selectedDrug.subsidiaryName,
                originalMedispanLabelerId: $scope.selectedDrug.medispanLabelerId,
                originalPharmaName: $scope.selectedDrug.pharma,
                newSubsidiaryId: $scope.selectedSub.subsidiaryId,
                newSubsidiaryName: $scope.selectedSub.subsidiaryName,
                newMedispanLabelerId: $scope.selectedSub.medispanLabelerId,
                newPharmaName: $scope.selectedSub.pharmaName,
                everythingExcept: $scope.selectedDrug.everythingExcept,
                modified: true
              };
              let existingMapping = null;
              tableData.forEach(mapping => {
                // tslint:disable-next-line:triple-equals
                if (mapping.drugName == newMapping.drugName
                  && mapping.originalSubsidiaryId == newMapping.originalSubsidiaryId) {
                  existingMapping = mapping;
                }
              });
              if (existingMapping == null) {
                tableData.push(newMapping);
              } else {
                existingMapping.drugName = newMapping.drugName;
                existingMapping.originalSubsidiaryId = newMapping.originalSubsidiaryId;
                existingMapping.originalSubsidiaryName = newMapping.originalSubsidiaryName;
                existingMapping.originalMedispanLabelerId = newMapping.originalMedispanLabelerId;
                existingMapping.originalPharmaName = newMapping.originalPharmaName;
                existingMapping.newSubsidiaryId = newMapping.newSubsidiaryId;
                existingMapping.newSubsidiaryName = newMapping.newSubsidiaryName;
                existingMapping.newMedispanLabelerId = newMapping.newMedispanLabelerId;
                existingMapping.newPharmaName = newMapping.newPharmaName;
                existingMapping.everythingExcept = newMapping.everythingExcept;
                existingMapping.modified = newMapping.modified;
              }
              $scope.clearAll();
              $scope.dirtyTable = true;
              $scope.tableParams.reload();
              $scope.saveAll();
              util.applyScope($scope);
            };

            $scope.selectFromMapping = mapping => {
              const subsidiary = createSubsidiaryFromMapping(mapping);
              const drug = createDrugFromMapping(mapping);
              selectDrugAndSubsidiary(drug, subsidiary);
            };

            $scope.alreadyInTable = () => {
              if (!$scope.subsidiarySelected() || !$scope.drugSelected()) {
                return false;
              }
              let found = false;
              tableData.forEach(mapping => {
                if (!found && mapping.drugName == $scope.selectedDrug.name
                  && mapping.originalSubsidiaryId == $scope.selectedDrug.subsidiaryId
                  && mapping.newSubsidiaryId == $scope.selectedSub.subsidiaryId
                  && mapping.everythingExcept == $scope.selectedDrug.everythingExcept) {
                  found = true;
                }
              });
              return found;
            };

            $scope.tableParams = new NgTableParams({
              page: 1, // show first page
              count: 1e20, // count per page
              sorting: sort
            }, {
              counts: [], // hide page counts control
              total: tableData.length, // length of data
              getData: (params) => {
                const deferred = $q.defer();
                // use build-in angular filter
                let orderedData = params.sorting() ? $filter('orderBy')(tableData, params.orderBy()) : tableData;
                orderedData = params.filter() ? $filter('filter')(orderedData, params.filter()) : orderedData;
                deferred.resolve(
                  orderedData.slice((params.page() - 1) * params.count(), params.page() * params.count())
                );

                return deferred.promise;
              }
            });

            $scope.doSortBy = (column, $event) => {
              const target = $($event.target);
              clearModifiedInfo();
              if (!target.hasClass('btn') && !target.hasClass('icon')) {
                const newSort = {
                  modified: 'asc'
                };
                newSort[column] = $scope.tableParams.isSortBy(column, 'asc') ? 'desc' : 'asc';
                if (column != 'drugName') {
                  // @ts-ignore
                  newSort.drugName = 'asc';
                }
                $scope.tableParams.sorting(newSort);
              }
            };

            $scope.getSortClass = column => {
              if ($scope.tableParams.isSortBy(column, 'asc')) {
                return 'sort-asc';
              }
              if ($scope.tableParams.isSortBy(column, 'desc')) {
                return 'sort-desc';
              }
              return '';
            };

            $scope.deleteRowWithDrugAndSub = (drug, subsidiary) => {
              $scope.deleteRow(createMappingFromDrugAndSubsidiary(drug, subsidiary));
            };
            $scope.deleteRow = deletedMapping => {
              clearErrorMessage();
              let index = 0;
              let foundIndex;
              tableData.forEach(mapping => {
                if (mapping.drugName == deletedMapping.drugName
                  && mapping.originalSubsidiaryId == deletedMapping.originalSubsidiaryId) {
                  foundIndex = index;
                }
                index++;
              });
              tableData.splice(foundIndex, 1);
              $scope.dirtyTable = true;
              $scope.clearAll();
              $scope.tableParams.reload();
              $scope.saveAll();
            };

            $scope.getRowClass = index => index % 2 == 0 ? 'even' : 'odd';

            $scope.getModifiedClass = mapping => {

              if (mapping.modified) {
                return 'highlightedClass';
              }
              return '';
            };

            $scope.getDeletedClass = index => {
              if (tableData[index].deleted) {
                return 'disabled';
              } else {
                return '';
              }
            };

            $scope.saveAll = force => {
              clearErrorMessage();
              if (CoreUtils.isNotDefined(force) || !force) {
                if (!$scope.dirtyTable) {
                  return;
                }
              }
              util.saving();
              apiService.post(`${ApiUrlPrefix.OLD}/manufacturerMapping/saveAll`, tableData).pipe(
                tap(() => {
                  getVersions(true);
                  $scope.dirtyTable = false;
                  util.saved();
                }),
                catchError(() => {
                  util.saveError();
                  return of(null);
                })
              ).subscribe();
            };
            util.applyScope($scope);
          }),
          catchError((response: HttpErrorResponse) => {
            errorMessage(response);
            return of(null);
          })
        ).subscribe();
        // Mapping Table]

        setTimeout(resizeTable, 0);
        angular.element($window).bind('resize', () => {
          setTimeout(resizeTable, 0);
        });

      }
  }
};
export default ManufacturerMappingComponent;
