import { TermName } from '@qv-term/enums';

export const termVisibilitySubmenuItems = {
  [TermName.SCENARIO_START_DATE]: 'Start Date',
  [TermName.SCENARIO_END_DATE]: 'End Date',
  [TermName.ADMINISTRATION_FEE]: 'Admin Fee',
  [TermName.REBATE]: 'Min/Base Rebate',
  [TermName.TIER_PLACEMENT]: 'Tier Placement',
  [TermName.TIER_COMPETITION]: 'Tier Competition',
  [TermName.COMPETITION_GROUP]: 'Competition Group',
  [TermName.COMPETITORS_TIER]: 'Competitors Tier',
  [TermName.MARKET_BASKET]: 'Market Basket',
  [TermName.NOTES]: 'Notes',
  [TermName.PRODUCT_UM]: 'Product UM',
  [TermName.COMPETITION_UM]: 'Competition UM',
  [TermName.QUANTITY_LIMIT]: 'Quantity Limit',
  [TermName.OTHER_UM]: 'Other UM',
  [TermName.UM_DETAILS]: 'UM Details',
  [TermName.MARKET_SHARE_TIER]: 'Market Share Tier',
  [TermName.RANGE_OR_UNITS_AND_REBATES]: 'Range or Unit and Rebates',
  [TermName.NET_EFFECTIVE_PRICE]: 'Net Effective Price ',
  [TermName.INDEX]: 'Index',
  [TermName.THRESHOLD]: 'Threshold',
  [TermName.RESET]: 'Reset',
  [TermName.BASELINE_START_DATE]: 'Baseline Start Date',
  [TermName.NEW_PRICE_EFFECTIVE_DATE]: 'Net Price Effective Date',
  [TermName.BASELINE_WAC]: 'Baseline WAC',
};
