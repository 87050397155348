import { BaseEntity } from '@qv-bid/entities/base-entity';
import { JsonObject, JsonProperty } from 'json2typescript';
import { DictionaryItem } from '@qv-common/entities';

@JsonObject('Payment')
export class Payment extends BaseEntity {

  @JsonProperty('cbId', Number)
  public contractedBusinessId: number = null;

  @JsonProperty('frequency', DictionaryItem)
  public paymentFrequency: DictionaryItem = null;

  @JsonProperty('frequencyLock', Boolean)
  public paymentFrequencyLock = false;

  @JsonProperty('lag', DictionaryItem)
  public lag: DictionaryItem = null;

  @JsonProperty('lagLock', Boolean)
  public lagLock = false;

  @JsonProperty('rebateEligibility', DictionaryItem)
  public rebateEligibility: DictionaryItem = null;

  @JsonProperty('rebateEligibilityLock', Boolean)
  public rebateEligibilityLock = false;

  @JsonProperty('wholesalePrice', DictionaryItem)
  public wholesalePrice: DictionaryItem = null;

  @JsonProperty('wholesalePriceLock', Boolean)
  public wholesalePriceLock = false;
}
