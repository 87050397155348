import { InputTerm } from '@qv-term/models/types';
import { Reset, Validate } from '@qv-common/interfaces';
import { constants, resources } from '@qv-common/static';
import { FormValidationError } from '@qv-common/enums';

export class RfpTitleTerm extends InputTerm implements Validate, Reset {
  public static readonly validationMessages = new Map<string, string>([
    [FormValidationError.REQUIRED, 'Please fill in the Title'],
    [FormValidationError.NOT_BLANK_STRING, 'Please fill in the Title'],
    [FormValidationError.MAX_LENGTH, 'Title should be maximum 55 characters long']
  ]);
  public readonly required = true;

  constructor(name: string) {
    super(
      name,
      name,
      resources.TermsDescriptions.SUMMARY.RFP_TITLE,
      constants.SUMMARY_TERMS.RFP_TITLE.label,
      '',
      '',
      'term__rfp-title'
    );
  }

  public validate(): boolean {
    let valid = true;

    if (this.value.length > 0) {
      if (this.value.length > constants.CONTRACTED_BUSINESS_NAME_MAX_LENGTH) {
        this.errorMessage = RfpTitleTerm.validationMessages.get(FormValidationError.MAX_LENGTH);
        valid = false;
      }
    } else {
      this.errorMessage = RfpTitleTerm.validationMessages.get(FormValidationError.REQUIRED);
      valid = false;
    }

    return valid;
  }

  public resetValue(): void {
    this.value = this.defaultValue;
  }
}
