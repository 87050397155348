import { CoreUtils } from '@qv-common/utils';
import { JsonObject, JsonProperty } from 'json2typescript';
import { MomentConverter } from 'quantuvis-angular-common/json-converter/serializers';

@JsonObject('Range')
export class Range<T> {
  @JsonProperty('from', MomentConverter)
  public from: T = null;

  @JsonProperty('to', MomentConverter)
  public to: T = null;

  @JsonProperty('invalid', Boolean)
  public invalid = false;

  constructor(from: T = null, to: T = null, invalid: boolean = false) {
    this.from = from;
    this.to = to;
    this.invalid = invalid;
  }

  public isEmpty(): boolean {
    return CoreUtils.isNull(this.from) && CoreUtils.isNull(this.to);
  }
}
