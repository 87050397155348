/* tslint:disable:no-var-requires */

import UserController from './user.controller';

// @ts-ignore
import templateUrl from './createuser.html';

const CreateUserComponent = {
  template: templateUrl,
  bindings: {
    initConfirmNavigation: '&'
  },
  controller: UserController
};

export default CreateUserComponent;
