export class StringUtils {
  public static isString(value: any): boolean {
    return typeof value === 'string';
  }

  public static isEmpty(value: string): boolean {
    return value === '';
  }

  public static trim(value: string): string {
    if (!value) {
      return value;
    }

    // eslint-disable-next-line no-control-regex
    const invalidSymbols = /(?![\x09\x0A\x0D])[\x00-\x1F\x7F]/;
    const result = value.replace(invalidSymbols, ' ');

    return result.trim();
  }

  /**
   * Escapes a string for insertion into HTML, replacing &, <, >, ", `, and ' characters.
   *
   * @param value
   */
  public static escape(value: string): string {
    const escapeMap = {
      '&': '&amp;',
      '<': '&lt;',
      '>': '&gt;',
      '"': '&quot;',
      '\'': '&#x27;',
      '`': '&#x60;'
    };
    const source = `(?:${Object.keys(escapeMap).join('|')})`;
    const testRegexp = RegExp(source);
    const replaceRegexp = RegExp(source, 'g');

    value = value === null ? '' : `${value}`;

    return testRegexp.test(value)
      ? value.replace(replaceRegexp, (match: string) => escapeMap[match])
      : value;
  }
}
