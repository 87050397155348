import { ScenarioFieldName } from '../enums';
import { DropdownOption } from './company-dropdown-option.entity';
import { JsonObject, JsonProperty } from 'json2typescript';
import { DefaultDropdownOptionConverter } from '../json-converters/default-dropdown-option.converter';

@JsonObject('FieldDropdownOptions')
export class FieldDropdownOptions {

  @JsonProperty('id', String)
  public id: ScenarioFieldName = null;

  @JsonProperty('dropdownOptions', [DropdownOption])
  public dropdownOptions: DropdownOption[] = [];

  @JsonProperty('dropdownOptions', DefaultDropdownOptionConverter)
  public defaultDropdownOption: DropdownOption = null;

  public getValue(id: number): DropdownOption {
    return this.dropdownOptions.find(item => item.id === id);
  }

  public getValues(): DropdownOption[] {
    return this.dropdownOptions
      .filter(item => !item.isDisabled)
      .sort((o1: DropdownOption, o2: DropdownOption) => o1.position - o2.position);
  }
}
