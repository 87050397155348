declare let $: any;

export const NgNoTrimModel = (): any => ({
  restrict: 'A',
  link: (scope, element, attrs) => {

    /**
     * Set the scope variable given by ngNoTrimModel attribute with the element value when changing.
     */
    $(element).change(() => {
      // The expression to be evaluated assigns the element value
      // to the scope variable specified by ngNoTrimModel attribute
      const evaluate = `${attrs.ngNoTrimModel}='${$(element).val()}'`;

      // Executes the specified expression
      scope.$eval(evaluate);
    });
  }
});
