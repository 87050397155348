import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { TooltipPosition } from '@angular/material/tooltip';

@Component({
  selector: 'qv-menu-item-button',
  templateUrl: './menu-item-button.component.html',
  styleUrls: ['./menu-item-button.component.scss']
})
export class MenuItemButtonComponent {
  @Input()
  public text: string;
  @Input()
  public supText: string;
  @Input()
  public tooltip: string;
  @Input()
  public tooltipPosition: TooltipPosition = 'left';
  @Input()
  public tooltipClass = 'tooltip-sm';
  @Input()
  public triggerFor: string;
  @Output()
  public menuOpened = new EventEmitter<void>();

  @ViewChild(MatMenuTrigger)
  public trigger: MatMenuTrigger;

  private readonly parentMenuClass = '.mat-menu-content';

  public openSubMenu(): void {
    this.trigger.openMenu();
    this.trigger.focus('mouse');
    this.menuOpened.emit();
  }

  public closeSubMenu($event: MouseEvent): void {
    const relatedTargetParentClass = ($event.relatedTarget as Element).closest(this.parentMenuClass);
    const targetParentClass = ($event.target as Element).closest(this.parentMenuClass);
    if (relatedTargetParentClass === targetParentClass) {
      this.trigger.closeMenu();
    }
  }
}
