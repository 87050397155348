import { Directive, HostListener, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Directive({
  selector: 'input[qvFormControlFocus]'
})
export class FormControlFocusDirective {
  @Input() public qvFormControlFocus: FormGroup;

  @HostListener('focus')
  public onFocus(): void {
    this.qvFormControlFocus.markAsTouched();
  }

}
