import { Directive, ElementRef, ViewChild } from '@angular/core';
import { VirtualScrollerComponent } from 'ngx-virtual-scroller';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { ScenarioConfigListService, VirtualScrollerService } from '@qv-bid/services';
import { BidViewService } from '@qv-bid/services/bid-view.service';

@UntilDestroy()
@Directive()
export class BaseView {
  @ViewChild(VirtualScrollerComponent)
  public virtualScroller: VirtualScrollerComponent;

  public virtualScroll: ElementRef;
  protected readonly scrollAnimation = 100;

  constructor(
    protected bidViewService: BidViewService,
    protected scenarioConfigListService: ScenarioConfigListService,
    private virtualScrollerService: VirtualScrollerService,
  ) {}

  public subscribeOnVirtuallScrollerSubjects(): void {
    this.virtualScrollerService.scrollRefresh
      .pipe(untilDestroyed(this))
      .subscribe(() => this.virtualScroller?.refresh());

    this.virtualScrollerService.invalidateAllCachedMeasurements
      .pipe(untilDestroyed(this))
      .subscribe(() => this.virtualScroller.invalidateAllCachedMeasurements());

    this.virtualScrollerService.scrollToPosition
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        if (this.shouldUnlockFirstViewportItemConfig()) {
          this.unlockFirstViewportItemConfig();
        } else {
          this.scrollTo();
        }
      });
  }

  protected updateScrollStartPosition(): void {
    if (this.bidViewService.firstViewportItemConfig && !this.bidViewService.firstViewportItemConfig.isLocked) {
      const { scrollStartPosition, startIndex } = this.virtualScroller.viewPortInfo;

      this.bidViewService.firstViewportItemConfig.scrollStartPosition = scrollStartPosition;
      this.bidViewService.firstViewportItemConfig.startIndex = startIndex;
    }
  }

  protected scrollTo(): void {
    this.virtualScroller.scrollToPosition(
      this.bidViewService.firstViewportItemConfig.scrollStartPosition,
      this.scrollAnimation,
      this.unlockFirstViewportItemConfig.bind(this)
    );
  }

  protected unlockFirstViewportItemConfig(): void {
    this.bidViewService.firstViewportItemConfig.unlock();
    this.scenarioConfigListService.scenarioConfigList.isFirstLoading = false;
  }

  private shouldUnlockFirstViewportItemConfig(): boolean {
    return this.scenarioConfigListService.scenarioConfigList.isFirstLoading &&
      !this.bidViewService.firstViewportItemConfig.scrollStartPosition;
  }
}
