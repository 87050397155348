import {
  HTTP_INTERCEPTORS,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable, Provider } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class BlobErrorInterceptor implements HttpInterceptor {
  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError(response => {
        if (response instanceof HttpErrorResponse
          && response.error instanceof Blob
          && response.error.type === 'application/json'
        ) {
          // https://github.com/angular/angular/issues/19888
          // When request of type Blob, the error is also in Blob instead of object of the json data
          return new Promise<any>((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (e: Event) => {
              try {
                const jsonError = JSON.parse((e.target as any).result);
                reject(new HttpErrorResponse({
                  error: jsonError,
                  headers: response.headers,
                  status: response.status,
                  statusText: response.statusText,
                  url: response.url
                }));
              } catch (e) {
                console.error(e);
                reject(response);
              }
            };
            reader.onerror = (e) => {
              console.error(e);
              reject(response);
            };
            reader.readAsText(response.error);
          });
        }
        return throwError(response);
      })
    );
  }
}

export const blobErrorInterceptorProvider: Provider = {
  provide: HTTP_INTERCEPTORS,
  useClass: BlobErrorInterceptor,
  multi: true
};
