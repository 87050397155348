<div class="actions-bar">
  <ng-container *ngIf="(isDrugGroupsEmpty$ | async) === false">
    <button class="actions-bar__button" (click)="onSelectAll()">
      {{ isSelectedAll ? 'Deselect' : 'Select' }} All
    </button>
    <button class="actions-bar__button" (click)="onExpandAll()">
      {{ isExpandedAll ? 'Collapse' : 'Expand' }} All
    </button>
  </ng-container>
</div>
<div class="drug-search-panel">
  <div class="drug-search">
    <input
      matInput
      [formControl]="searchControl"
      class="drug-search__input"
      placeholder="Search for a Drug or Class..."
      autocomplete="off">
    <button *ngIf="searchControl.value" mat-icon-button class="drug-search__clear" (click)="onClearSearch()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="drug-search-panel__content" [class.drug-search-panel__content--empty]="isDrugGroupsEmpty$ | async">
    <div *ngIf="isDrugGroupsEmpty$ | async" class="drug-search-panel__no-data">
      No results found.<br/>
      Please, check the request and try again.
    </div>
    <ng-container *ngIf="(isDrugGroupsEmpty$ | async) === false">
      <div class="drug-group" *ngFor="let drugGroup of drugGroupMap | keyvalue">
        <mat-checkbox
          class="acc-checkbox acc-checkbox--primary acc-checkbox--label-width-256 acc-checkbox--label-truncated"
          [checked]="drugGroup.value.isSelected()"
          (change)="onDrugClassChange(drugGroup.value, $event)">
          <button mat-icon-button class="drug-group__toggler" (click)="onExpandItem(drugGroup.value, $event)">
            <mat-icon *ngIf="drugGroup.value.isExpanded">expand_more</mat-icon>
            <mat-icon *ngIf="!drugGroup.value.isExpanded">chevron_right</mat-icon>
          </button>
          <span class="drug-group__drug-class" [matTooltip]="drugGroup.value.drugClass">
            {{ drugGroup.value.drugClass }}
          </span>
        </mat-checkbox>
        <ng-container *ngIf="drugGroup.value.isExpanded">
          <mat-checkbox
            *ngFor="let drug of drugGroup.value.drugMap | keyvalue"
            class="acc-checkbox acc-checkbox--primary acc-checkbox--label-width-232 acc-checkbox--label-truncated drug-group__drug"
            [matTooltip]="drug.value.fullName"
            [checked]="drug.value.isSelected"
            [disabled]="drug.value.name === disabledDrugName && drug.value.drugClass === disabledDrugClass"
            (change)="onDrugChange(drug.value, $event)">
            <span class="drug-group__drug-name">
              {{ drug.value.fullName }}
            </span>
          </mat-checkbox>
        </ng-container>
      </div>
    </ng-container>
  </div>
</div>
