import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnChanges,
  OnInit,
  SimpleChanges
} from '@angular/core';
import { ContractManager } from '@qv-bid/components/shared/base/classes/contract.manager';
import { AdministrationFee } from '@qv-bid/entities';
import { BidFormService } from '@qv-bid/services';
import { BidEventBusService } from '@qv-bid/services/bid-event-bus.service';
import { TermName, TermSection } from '@qv-term/enums';
import { ContractFormService } from '@qv-bid/components/shared/summary-panel/contract/contract-form.service';
import { AdminFeeDaoService } from '@qv-bid/services/dao/contract-terms';
import { SnackBarService } from 'quantuvis-angular-common/snack-bar';
import { filter } from 'rxjs/operators';
import { SingleNotificationService } from '@qv-shared/services';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NotificationService } from 'quantuvis-angular-common/notification';

@UntilDestroy()
@Component({
  selector: 'qv-administration-fee',
  templateUrl: './administration-fee.component.html',
  styleUrls: ['./administration-fee.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdministrationFeeComponent extends ContractManager<AdministrationFee>
  implements OnInit, OnChanges {
  public readonly termName = TermName;

  public sectionName = TermSection.ADMINISTRATION_FEE;

  constructor(
    protected daoService: AdminFeeDaoService,
    protected contractFormService: ContractFormService,
    protected bidFormService: BidFormService,
    protected notificationService: NotificationService,
    protected changeDetectorRef: ChangeDetectorRef,
    private bidEventBusService: BidEventBusService,
    snackBarService: SnackBarService,
    singleNotificationService: SingleNotificationService
  ) {
    super(
      contractFormService,
      bidFormService,
      changeDetectorRef,
      notificationService,
      snackBarService,
      singleNotificationService
    );
  }

  public ngOnInit(): void {
    this.subscribeOnAdminFeeChangesToReloadScenarios();

    if (Number.isInteger(this.contractedBusinessId)) {
      this.loadEntity();
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.contractedBusinessId && !changes.contractedBusinessId.firstChange) {
      this.loadEntity();
    } else if (changes.isEditMode && changes.isEditMode.currentValue) {
      this.initEditMode();
    }
  }

  protected buildFormForSection(source: AdministrationFee): void {
    this.contractFormService.buildAdminFeeForm(source);
  }

  private subscribeOnAdminFeeChangesToReloadScenarios(): void {
    this.entity$.pipe(
      untilDestroyed(this),
      filter(() => this.isFormReady$.value),
    ).subscribe(() => this.bidEventBusService.loadScenariosAndDropNdcsCacheEvent.emit());
  }
}
