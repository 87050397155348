import { Directive, ElementRef, Injector } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'qv-enterprise-wrapper'
})
export class EnterpriseWrapperDirective extends UpgradeComponent {
  constructor(elementRef: ElementRef, injector: Injector) {
    super('enterpriseComponent', elementRef, injector);
  }
}
