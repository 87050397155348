import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'acc-textarea-menu',
  templateUrl: './textarea-menu.component.html',
  styleUrls: ['./textarea-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TextareaMenuComponent implements OnChanges {
  @Input()
  public control: AbstractControl;

  @Input()
  public title: string;

  @Input()
  public placeholder = 'Enter Notes';

  @Input()
  public isEditMode: boolean;

  @Input()
  public isAutofocusEnabled = true;

  @Input()
  public value: string;

  @Input()
  public maxLength: number;

  @Output()
  public completeEvent = new EventEmitter<void>();

  public textareaControl = new FormControl('');

  private valueChangesSubscription: Subscription;

  @HostListener('document:keydown.escape')
  public cancel(): void {
    this.completeEvent.emit();
    this.textareaControl.patchValue(this.control?.value);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.control && Boolean(changes.control.currentValue?.value)) {
      this.textareaControl.setValidators(changes.control.currentValue.validator);
      this.textareaControl.patchValue(changes.control.currentValue.value);

      this.initValueChangesHandler(changes.control.currentValue);
    }

    if (changes.isEditMode) {
      if (!changes.isEditMode.currentValue) {
        this.textareaControl.disable();
        this.textareaControl.patchValue(this.value);
      } else {
        this.textareaControl.enable();
      }
    }
  }

  public save(): void {
    this.textareaControl.patchValue(this.textareaControl.value?.trim());
    if (this.textareaControl.value !== this.control.value) {
      this.control.patchValue(this.textareaControl.value);
    }
    this.completeEvent.emit();
  }

  public onClickOutside(): void {
    if (this.control && !this.control.touched) {
      const value = this.textareaControl.value?.trim();
      if (this.control.value !== value && this.textareaControl.valid) {
        this.save();
      } else {
        this.cancel();
      }
    }
  }

  private initValueChangesHandler(control: AbstractControl): void {
    this.valueChangesSubscription?.unsubscribe();
    this.valueChangesSubscription = control.valueChanges
      .pipe(
        filter((value: string) => value !== this.textareaControl.value),
        untilDestroyed(this)
      )
      .subscribe((value: string) => this.textareaControl.patchValue(value));
  }

}
