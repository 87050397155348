import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BaseInsideModal } from 'quantuvis-angular-common/modal';

@Component({
  selector: 'qv-disable-confirmation-user',
  templateUrl: './disable-confirmation-user.component.html',
  styleUrls: ['../styles/confirmation-user.scss', './disable-confirmation-user.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DisableConfirmationUserComponent extends BaseInsideModal {
  public readonly hasAsyncAction = true;
  public user: any;
  public isEditingHimself: boolean;
}
