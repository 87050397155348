<button
  mat-icon-button
  class="standard-cb-manage-button"
  [matMenuTriggerFor]="cbMenu"
  [disabled]="isDisabled"
  (menuOpened)="menuOpenedHandler()"
  (menuClosed)="menuClosedHandler()"
>
  <mat-icon class="standard-cb-manage-button__icon" [svgIcon]="icon | async"></mat-icon>
</button>
<mat-menu class="standard-cb-manage-menu" #cbMenu="matMenu" [overlapTrigger]="false">
  <ng-container *ngFor="let menuItem of menuItems; index as menuItemIndex">
    <button
      mat-menu-item
      *ngIf="menuItem.visible()"
      (click)="onMenuItemClick(cb, menuItemIndex)"
    >
      {{menuItem.name}}
    </button>
  </ng-container>
</mat-menu>
