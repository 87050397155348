import { BaseTerm } from './base.term';
import { TermTypes } from '@qv-common/enums';

export class TextAreaTerm extends BaseTerm {
  public maxLength: number;

  constructor(name: string,
              label: string,
              description: string,
              path: string,
              value: string,
              defaultValue: string,
              cssClass: string) {
    super(name, label, description, path, value, defaultValue, cssClass);
    this.type = TermTypes.TextArea;
  }
}
