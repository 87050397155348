import { BaseRow } from './base-row';
import { Ndc, Summary } from '@qv-bid/entities';
import { ChangeDetectorRef, Directive, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { SectionChangeManager } from '@qv-bid/services/sections/section-change-manager.service';
import { TermSection } from '@qv-term/enums';
import { ScenariosSnackBarService } from '@qv-bid/services/scenarios-snack-bar.service';
import { BidStateService, ContractedBusinessesService, DrugAlertNotificationService } from '@qv-bid/services';
import { BidEventBusService } from '@qv-bid/services/bid-event-bus.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DrugFormService } from '@qv-bid/services/drug';
import { Observable } from 'rxjs';
import { SummaryService } from '@qv-bid/services/summary';
import { BidStatus } from 'quantuvis-core-entities';
import { QvCache } from '@qv-common/decorators';

@UntilDestroy()
@Directive()
export class BaseNdcRow extends BaseRow implements OnChanges {
  @Input()
  public ndc: Ndc;
  @Input()
  public isBidInternal: boolean;
  @Input()
  public bidStatus: BidStatus;
  @Input()
  public manufacturerCompanyOldName: string;
  @Input()
  public manufacturerCompanyId: number;

  @Output()
  public reloadScenarioEvent = new EventEmitter();

  public summary: Observable<Summary>;

  constructor(
    private scenariosSnackBarService: ScenariosSnackBarService,
    protected bidEventBusService: BidEventBusService,
    protected drugAlertNotificationService: DrugAlertNotificationService,
    protected bidStateService: BidStateService,
    protected contractedBusinessesService: ContractedBusinessesService,
    protected changeDetectorRef: ChangeDetectorRef,
    protected drugFormService: DrugFormService,
    protected summaryService: SummaryService,
    protected sectionChangeManager: SectionChangeManager,
  ) {
    super(sectionChangeManager, changeDetectorRef);
    this.summary = this.summaryService.getSummary;
  }

  public ngOnChanges({ ndc }: SimpleChanges): void {
    if (ndc && !ndc.firstChange) {
      this.changeDetectorRef.detectChanges();
      this.drugFormService.updateDrug(ndc.currentValue);
      this.drugAlertNotificationService.calculateNdcNotification(ndc.currentValue);
    }
  }

  public onDeleteHistoricNotes(): void {
    this.contractedBusinessesService.loadHistoricNotesPresents(
      this.bidStateService.bidVersionId,
      this.bidStateService.cbId
    );
  }

  public transitToEditMode(): void {
    this.drugFormService.transitDrugToEditMode();
  }

  @QvCache()
  public isNdcAvailable(isScenarioPresent: boolean, isNdcPresent: boolean): boolean {
    return Boolean(isScenarioPresent && isNdcPresent);
  }

  public onUpdateNotes(): void {
    this.reloadScenarioEvent.next();
  }

  protected initNdc(): void {
    this.drugFormService.setDrug(this.ndc);
    this.initSectionChangedHandler();
  }

  protected initSectionChangedHandler(): void {
    this.drugFormService.sectionChanged$.pipe(untilDestroyed(this))
      .subscribe((section: TermSection) => this.scenariosSnackBarService.sectionChanged$.next(section));
  }
}
