import { DrugListTerms } from '../../../../utils/terms';


export class DrugScenariosService {
  public static $inject = ['$filter', 'translations', 'util', 'biddingUtilsService',
    'viewPerspectiveService', '$sce', '$templateRequest', 'restapi', 'authService', 'BaselineWacService'];
  private $filter;
  private translations;
  private util;
  private biddingUtilsService;
  private authService;
  private BaselineWacService;
  private drugListTerms;

  constructor($filter, translations, util, biddingUtilsService, viewPerspectiveService, $sce,
              $templateRequest, restapi, authService, BaselineWacService) {
    'ngInject';
    this.$filter = $filter;
    this.translations = translations;
    this.util = util;
    this.biddingUtilsService = biddingUtilsService;
    this.authService = authService;
    this.BaselineWacService = BaselineWacService;
    this.drugListTerms = DrugListTerms(this.$filter, this.util, this.translations, restapi,
      biddingUtilsService, viewPerspectiveService, $sce, $templateRequest, authService, BaselineWacService);
  }

  public getTermPropertyByName(name, property, emptyValue): any {
    const filteredTerms = this.drugListTerms.filter((item) => (item.name === name));
    return filteredTerms.length > 0 ? filteredTerms[0][property] : emptyValue;
  }
}
