/* tslint:disable:no-var-requires */
import CopyBidController from './copy-bid.controller';

// @ts-ignore
import templateUrl from './copy-bid.html';

const CopyBidComponent = {
  template: templateUrl,
  bindings: {
    initConfirmNavigation: '&'
  },
  controller: CopyBidController
};

export default CopyBidComponent;
