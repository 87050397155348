import { drugTermsConstants } from '@qv-term/constants';
import { TermName } from '@qv-term/enums';

class DrugsLevelController {
  public static $inject = ['standards', 'translations', 'CreateInternalBidService', 'DrugLevelService'];
  private standards;
  private translations;
  private CreateInternalBidService;
  private DrugLevelService;
  private termNames;
  private onValid;

  constructor(standards, translations, CreateInternalBidService, DrugLevelService) {
    'ngInject';
    this.standards = standards;
    this.translations = translations;
    this.CreateInternalBidService = CreateInternalBidService;
    this.DrugLevelService = DrugLevelService;
    this.termNames = this.CreateInternalBidService.usedDrugTermNames;
  }

  public $onInit(): void {
    this.translations.buildTranslations(this);
    this.CreateInternalBidService.scenariosList = [this.DrugLevelService.getEmptyDrugScenario()];
  }

  public $onChanges(changes): void {
    if (changes.runValidation && changes.runValidation.currentValue) {
      this.onValid({ value: this.isValid() });
    }
  }

  public onDrugScenarioActionHandler(event): void {
    switch (event.action) {
      case 'add':
        this.DrugLevelService.addDrugScenario(this.CreateInternalBidService.scenariosList, false);
        break;
      case 'delete':
        this.DrugLevelService.deleteDrugScenario(this.CreateInternalBidService.scenariosList, event.scenarioIndex);
        break;
    }
  }

  public getDrugTermByName(name): any {
    return this.CreateInternalBidService.getDrugTermByName(name);
  }

  public isValid(): boolean {
    return this.CreateInternalBidService.validateTerm(drugTermsConstants[TermName.BASELINE_START_DATE].title, 'drugTerms');
  }
}

export default DrugsLevelController;
