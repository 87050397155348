/* tslint:disable:no-var-requires */
import ContractedBusinessController from './contracted-business.controller';

// @ts-ignore
import templateUrl from './contracted-business.html';

const ContractedBusinessComponent = {
  template: templateUrl,
  bindings: {
    bid: '<',
    runValidation: '<',
    onValid: '&'
  },
  controller: ContractedBusinessController
};

export default ContractedBusinessComponent;
