<button *ngIf="!triggerFor" [matTooltipClass]="tooltipClass"
        [matTooltipPosition]="tooltipPosition"
        [matTooltip]="tooltip"
        mat-menu-item>{{text}}<sup class="sup-top-right">{{supText}}</sup></button>

<button (mouseenter)="openSubMenu()" (mouseleave)="closeSubMenu($event)"
        *ngIf="triggerFor"
        [matMenuTriggerFor]="$any(triggerFor)"
        [matTooltipClass]="tooltipClass"
        [matTooltipPosition]="tooltipPosition"
        [matTooltip]="tooltip"
        mat-menu-item>{{text}}<sup class="sup-top-right">{{supText}}</sup></button>
