<div class="standard-cell" *ngIf="name && termConfig">
  <div class="standard-cell__name-container">
    <span
      class="standard-cell__name"
      [matTooltip]="termConfig.description"
    >{{ name }}:</span>
    <div class="standard-cell__label-icon">
      <ng-content select="[top-right-block]"></ng-content>
    </div>
  </div>
  <div class="standard-cell__content-container">
    <ng-content></ng-content>
  </div>
  <span *ngIf="useSeparator" class="standard-cell__separator"></span>
</div>