import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MarketBasketModalState } from '@qv-bid/enums';
import { MarketBasketModalData } from '@qv-bid/models/market-basket-modal-data';
import { MarketBasketModalOutput } from '@qv-bid/models/market-basket-modal-output';
import { MarketBasketTemplate } from 'market-basket/market-basket-common';
import { MarketBasket } from '@qv-bid/entities';

@Component({
  selector: 'qv-market-basket-modal',
  templateUrl: './market-basket-modal.component.html',
  styleUrls: ['./market-basket-modal.component.scss']
})
export class MarketBasketModalComponent {
  @Output()
  public primaryAction = new EventEmitter<MarketBasketModalOutput>();

  @Output()
  public templateSaved = new EventEmitter<MarketBasketTemplate>();

  @Output()
  public templateDeleted = new EventEmitter<MarketBasketTemplate>();

  public currentState: MarketBasketModalState;
  public marketBasket: MarketBasket;
  public isNewOrEditState: boolean;
  public isAssignOrImportState: boolean;
  public isPreviewState: boolean;

  constructor(
    private dialogRef: MatDialogRef<MarketBasketModalComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: MarketBasketModalData
  ) {
    this.updateCurrentState(modalData.state);
  }

  public updateCurrentState(state: MarketBasketModalState): void {
    this.currentState = state;
    this.setMarketBasketForEdit();
    this.setStateRelatedProperties();
  }

  public onClose(): void {
    this.dialogRef.close();
  }

  private setStateRelatedProperties(): void {
    this.isNewOrEditState = [
      MarketBasketModalState.NEW_AND_ASSIGN,
      MarketBasketModalState.EDIT
    ].includes(this.currentState);

    this.isAssignOrImportState = [
      MarketBasketModalState.ASSIGN,
      MarketBasketModalState.IMPORT
    ].includes(this.currentState);

    this.isPreviewState = this.currentState === MarketBasketModalState.PREVIEW;
  }

  private setMarketBasketForEdit(): void {
    this.marketBasket = this.currentState === MarketBasketModalState.EDIT ?
      Object.assign(new MarketBasket(), this.modalData.marketBasket) :
      null;
  }
}
