import {
  Component,
  ChangeDetectionStrategy,
  Input,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter
} from '@angular/core';
import { MarketBasketDrug } from '../../entities';
import { DrugTypeIcon, MarketBasketDrugType } from '../../enums';

@Component({
  selector: 'mb-market-basket-drugs',
  templateUrl: './market-basket-drugs.component.html',
  styleUrls: ['./market-basket-drugs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MarketBasketDrugsComponent implements OnChanges {
  @Input()
  public isEditMode = false;

  @Input()
  public marketBasketDrugs: MarketBasketDrug[] = [];

  @Input()
  public disabledDrugName: string;

  @Input()
  public disabledDrugClass: string;

  @Input()
  public isClearAllDisabled: boolean;

  @Input()
  public clearAllDisabledTooltipText: string;

  @Output()
  public selectionChanged = new EventEmitter<MarketBasketDrug[]>();

  @Output()
  public searchForDrug = new EventEmitter<string>();

  public drugGroupMap = new Map<string, MarketBasketDrug[]>();

  public readonly marketBasketDrugType = MarketBasketDrugType;
  public readonly drugTypeIcon = DrugTypeIcon;

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.marketBasketDrugs) {
      this.fillMarketBasketDrugsMap();
    }
  }

  public onClearAll(): void {
    this.marketBasketDrugs = this.marketBasketDrugs.filter(
      (drug: MarketBasketDrug) => drug.drugClass === this.disabledDrugClass && drug.name === this.disabledDrugName
    );
    let lockedDrugs = [];

    if (this.disabledDrugClass) {
      lockedDrugs = this.drugGroupMap.get(this.disabledDrugClass).filter(
        (drug: MarketBasketDrug) => drug.name === this.disabledDrugName
      );
    }

    this.drugGroupMap.clear();
    this.drugGroupMap.set(this.disabledDrugClass, lockedDrugs);
    this.selectionChanged.emit(this.marketBasketDrugs);
  }

  public onDrugClassClick(drugClass: string): void {
    if (this.isEditMode) {
      this.searchForDrug.emit(drugClass);
    }
  }

  public onRemoveDrugClass(drugClass: string): void {
    this.drugGroupMap.delete(drugClass);
    this.marketBasketDrugs = this.marketBasketDrugs
      .filter((drug: MarketBasketDrug) => drug.drugClass !== drugClass);
    this.selectionChanged.emit(this.marketBasketDrugs);
  }

  public onRemoveDrug(drugName: string, drugClass: string): void {
    const drugs = this.drugGroupMap.get(drugClass).filter((item: MarketBasketDrug) => item.name !== drugName);

    if (drugs.length) {
      this.drugGroupMap.set(drugClass, drugs);
      this.marketBasketDrugs = this.marketBasketDrugs
        .filter((drug: MarketBasketDrug) => !drug.isMatchNameAndClass(drugName, drugClass));
      this.selectionChanged.emit(this.marketBasketDrugs);
    } else {
      this.onRemoveDrugClass(drugClass);
    }
  }

  public onChangeDrugType(drug: MarketBasketDrug, type: MarketBasketDrugType): void {
    const updatedDrug = Object.assign(new MarketBasketDrug(), drug, { type } as MarketBasketDrug);

    this.marketBasketDrugs = this.replaceDrugInList(this.marketBasketDrugs, updatedDrug);
    this.replaceDrugInGroup(updatedDrug);
    this.selectionChanged.emit(this.marketBasketDrugs);
  }

  private fillMarketBasketDrugsMap(): void {
    this.drugGroupMap = new Map();

    if (!this.marketBasketDrugs) return;

    this.marketBasketDrugs.forEach((drug: MarketBasketDrug) => {
      if (!this.drugGroupMap.has(drug.drugClass)) {
        this.drugGroupMap.set(drug.drugClass, []);
      }

      this.drugGroupMap.get(drug.drugClass).push(drug);
    });
  }

  private replaceDrugInList(drugList: MarketBasketDrug[], drug: MarketBasketDrug): MarketBasketDrug[] {
    const newDrugList = [...drugList];
    const drugIndex = newDrugList.findIndex((drugItem: MarketBasketDrug) =>
      drugItem.isMatchNameAndClass(drug.name, drug.drugClass)
    );

    newDrugList.splice(drugIndex, 1, drug);

    return newDrugList;
  }

  private replaceDrugInGroup(updatedDrug: MarketBasketDrug): void {
    const newDrugList = this.replaceDrugInList(this.drugGroupMap.get(updatedDrug.drugClass), updatedDrug);

    this.drugGroupMap.set(updatedDrug.drugClass, newDrugList);
  }
}
