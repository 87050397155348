import { BrowserOptions, init, captureException, withScope } from '@sentry/browser';
import { CaptureContext } from '@sentry/types';
import { Scope } from '@sentry/hub';

export class SentryAdapter {
  public static init(options?: BrowserOptions): void {
    init(options);
  }

  public static captureException(exception: any, captureContext?: CaptureContext): string {
    return captureException(exception, captureContext);
  }

  public static withScope(callback: (scope: Scope) => void): void {
    withScope(callback);
  }
}
