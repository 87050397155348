import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

import { errorSnackData, pendingSnackData, snackBarConfig, successSnackData } from '../constants';
import { SnackBarComponent } from '../components/snack-bar/snack-bar.component';
import { SnackBarData } from '../models/snack-bar-config';

@Injectable()
export class SnackBarService {
  constructor(private matSnackBar: MatSnackBar) {}

  public start(): void {
    this.showSnackBar(pendingSnackData);
  }

  public finish(): void {
    this.showSnackBar(successSnackData);
  }

  public error(): void {
    this.showSnackBar(errorSnackData);
  }

  public showSnackBar(snackBarData: SnackBarData, duration = 2000): void {
    this.matSnackBar.openFromComponent(SnackBarComponent, {
      ...snackBarConfig,
      duration,
      data: snackBarData
    });
  }
}
