import { DictionaryItem } from 'quantuvis-angular-common/interfaces';

export enum QuantityLimit {
  EMPTY = 85,
  YES,
  NO
}

export namespace QuantityLimit {
  const valuesMap = new Map<QuantityLimit, DictionaryItem>([
    [QuantityLimit.EMPTY, { id: QuantityLimit.EMPTY, name: '' }],
    [QuantityLimit.YES, { id: QuantityLimit.YES, name: 'Yes' }],
    [QuantityLimit.NO, { id: QuantityLimit.NO, name: 'No' }],
  ]);

  export function getValue(quantityLimit: QuantityLimit): DictionaryItem {
    return valuesMap.get(quantityLimit);
  }

  export function getValues(): DictionaryItem[] {
    return Array.from(valuesMap.values());
  }
}
