<div class="list-panel__header list-panel__header--relative">{{headerTitle}}
  <button (click)="openSearch()" class="search-icon" mat-icon-button>
    <mat-icon [svgIcon]="svgIconName.SEARCH" class="qv-icon--24"></mat-icon>
  </button>
  <div *ngIf="isSearchControlActive" class="drug-search-wrapper">
    <div class="drug-search">
      <input #searchInput
             [formControl]="control"
             [placeholder]="placeholder"
             class="qv-modal-dialog-content__field drug-search__input"
             matInput
             type="text">
      <button (click)="closeSearch()" class="drug-search__close" mat-icon-button>
        <mat-icon *ngIf="!isSearching" [svgIcon]="svgIconName.CLOSE" class="qv-icon--16"></mat-icon>
        <mat-icon *ngIf="isSearching" [svgIcon]="svgIconName.SPINNER" top-right-block></mat-icon>
      </button>
    </div>
  </div>
</div>
