import { Component, EventEmitter, Inject, OnDestroy, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HttpErrorResponse } from '@angular/common/http';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { NotificationService } from 'quantuvis-angular-common/notification';
import { SvgIconName } from '@qv-common/enums';
import { DrugScenarioStatus } from '@qv-bid/enums';
import { ScenarioSummaryModalData } from './models/scenario-summary-modal-data';
import { LoiBlockingMessage } from '@qv-loi-common/enums';
import { resources } from '@qv-common/static';
import { DrugScenarioStatusCheckbox } from './interfaces';
import { catchError, finalize } from 'rxjs/operators';
import { BidExportDaoService } from '@qv-bid/services/dao';
import { SingleNotification } from '@qv-shared/classes';
import { Toast } from 'ngx-toastr';
import { SingleNotificationService } from '@qv-shared/services';

@UntilDestroy()
@Component({
  selector: 'qv-scenario-summary-modal',
  templateUrl: './scenario-summary-modal.component.html',
  styleUrls: ['./scenario-summary-modal.component.scss']
})
export class ScenarioSummaryModalComponent implements OnInit, OnDestroy {
  @BlockUI()
  public blockUI: NgBlockUI;
  @Output()
  public primaryAction: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  public secondaryAction: EventEmitter<any> = new EventEmitter<any>();
  public drugScenarioStatuses: DrugScenarioStatusCheckbox[];
  public readonly tooltips = resources.TOOLTIPS;
  public readonly svgIconName = SvgIconName;
  private singleNotification: SingleNotification<Toast>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public modalData: ScenarioSummaryModalData,
    private dialogRef: MatDialogRef<ScenarioSummaryModalComponent>,
    private bidExportDaoService: BidExportDaoService,
    private notificationService: NotificationService,
    private singleNotificationService: SingleNotificationService
  ) {
    this.singleNotification = this.singleNotificationService.getInstance<Toast>();
  }

  public ngOnInit(): void {
    this.drugScenarioStatuses = ScenarioSummaryModalComponent.getDrugScenarioStatuses();
    this.setDisabledDrugScenarioStatuses(this.modalData.availableDrugScenarioStatuses);
  }

  public ngOnDestroy(): void {
    this.singleNotification.clear();
  }

  public onClose(): void {
    this.dialogRef.close();
    this.secondaryAction.emit();
  }

  public onGenerateScenarioSummary(): void {
    const availableDrugScenarioStatuses = this.getAvailableDrugScenarioStatuses();
    const { version } = this.modalData;
    this.blockUI.start(LoiBlockingMessage.GENERATE_SCENARIO_SUMMARY);
    this.bidExportDaoService.generateScenarioSummary(version, availableDrugScenarioStatuses)
      .pipe(
        finalize(() => this.blockUI.stop()),
        catchError((errorResponse: HttpErrorResponse) => this.singleNotification.throwErrorToast(
          this.notificationService.serverError(errorResponse), errorResponse)
        ),
        untilDestroyed(this),
      )
      .subscribe(() => {
        this.dialogRef.close();
        this.primaryAction.emit();
      });
  }

  public shouldDisableGenerateButton(): boolean {
    return this.drugScenarioStatuses.every((status) => status.value === false);
  }

  private static getDrugScenarioStatuses(): DrugScenarioStatusCheckbox[] {
    return Object.values(DrugScenarioStatus)
      .filter(status => typeof status !== 'function' && status !== DrugScenarioStatus.DISMISSED_IN_REVIEW)
      .map((status: DrugScenarioStatus) => ({
        name: status,
        displayValue: DrugScenarioStatus.displayValue(status),
        value: false,
        disabled: false
      } as DrugScenarioStatusCheckbox));
  }

  private getAvailableDrugScenarioStatuses(): string[] {
    return this.drugScenarioStatuses
      .filter((status) => status.value)
      .map((status) => status.name.toUpperCase());
  }

  private setDisabledDrugScenarioStatuses(availableDrugScenarioStatuses: string[]): void {
    if (availableDrugScenarioStatuses && availableDrugScenarioStatuses.length) {
      const { OPEN, ACCEPTED, DISMISSED } = DrugScenarioStatus;
      this.drugScenarioStatuses.forEach((status) => {
        switch (status.name) {
          case OPEN:
            status.disabled = !availableDrugScenarioStatuses.includes(OPEN.toUpperCase());
            break;
          case ACCEPTED:
            status.disabled = !availableDrugScenarioStatuses.includes(ACCEPTED.toUpperCase());
            break;
          case DISMISSED:
            status.disabled = !availableDrugScenarioStatuses.includes(DISMISSED.toUpperCase());
            break;
        }
      });
    }
  }
}
