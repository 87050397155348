import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { animate, style, transition, trigger } from '@angular/animations';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'acc-form-field-errors',
  templateUrl: './form-field-errors.component.html',
  styleUrls: ['./form-field-errors.component.scss'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        style({ opacity: 0.5, transform: 'translateY(-4px)' }),
        animate(200, style({ opacity: 1, transform: 'translateY(0)' }))
      ])
    ])
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormFieldErrorsComponent implements OnInit {
  @Input()
  public control: AbstractControl;

  @Input()
  public validationMessages: Map<string, string>;

  public readonly isErrorsVisible$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public readonly errors$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);

  public ngOnInit(): void {
    this.calculateErrors();
    this.initControlStatusChangesListener();
  }

  private calculateErrors(): void {
    const errors: string[] = this.control.errors
      ? Object.keys(this.control.errors).map((key: string) => this.validationMessages.get(key))
      : [];
    const uniqueErrors = Array.from(new Set(errors));

    this.isErrorsVisible$.next(uniqueErrors.length && (this.control.touched || this.control.dirty));
    this.errors$.next(uniqueErrors);
  }

  private initControlStatusChangesListener(): void {
    this.control.statusChanges
      .pipe(untilDestroyed(this))
      .subscribe(() => this.calculateErrors());
  }
}
