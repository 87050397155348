export class BidWizardService {
  protected errorMessage;
  protected bidConfig;

  constructor() {
    this.initBidConfig();
  }

  public initBidConfig(): void {
    this.errorMessage = null;
    this.bidConfig = {
      summary: [],
      contract: [],
      editable: true,
      isNew: true
    };
  }

  public getTermByName(name): number {
    return this.bidConfig.summary.concat(this.bidConfig.contract)
      .concat(this.bidConfig.drugTerms).find(search => search.name === name);
  }

  /**
   * Get term by name from source
   *
   * @param {string} name
   * @param {Array} source
   * @returns {Object}
   */
  public getTermByNameFromSource(name, source): null | any {
    if (Array.isArray(source)) {
      return source.find(search => search.name === name);
    }
    return null;
  }

  public getBidConfig(): any {
    return this.bidConfig;
  }

  public validateTerm(termName, section): true | any | boolean {
    if (!this.bidConfig) {
      return true;
    }

    const terms = this.bidConfig[section];

    if (!(terms && terms.length > 0)) {
      return true;
    }

    const term = terms.find(term => term.name === termName);

    if (term && term.definition && term.definition.validate && typeof term.definition.validate === 'function') {
      return term.definition.validate(term);
    }

    return true;
  }
}
