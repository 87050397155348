/**
 * Custom AngularJS services used by the APP logic
 */
import { HttpResponse } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { ApiUrlPrefix } from '@qv-common/enums';

export const Timezone = ['apiService', function(apiService): any {
  'ngInject';
  let timezones;

  this.fetchTimezones = (scope) => {
    if (!timezones) {
      apiService.get(`${ApiUrlPrefix.OLD}/timezones/all`).pipe(
        tap((response: HttpResponse<any>) => {
          const timezonesData = response.body;
          scope.timezones = timezonesData;
          timezones = timezonesData;
        }),
      ).subscribe();
    } else {
      scope.timezones = timezones;
    }
  };

  return {
    fetchTimezones: this.fetchTimezones
  };
}];
