import { Injectable } from '@angular/core';
import { BidFilterName, FilterOptionName } from '@qv-bid/enums';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { FilterOption } from '@qv-common/entities';
import { BidFilterDaoService } from '@qv-bid/services/dao';
import { BidFilterService } from '@qv-bid/services/bid-filter.service';
import { NotificationService } from 'quantuvis-angular-common/notification';
import { FilterState } from '@qv-bid/models';

@Injectable()
export class StandardDrugFilterService {
  constructor(
    private bidFilterDaoService: BidFilterDaoService,
    private bidFilterService: BidFilterService,
    private notificationService: NotificationService,
  ) {}

  public updateFilter(versionId: number): Observable<string[]> {
    const { contractedBusinesses } = this.bidFilterService.getFilterStateValue();
    const state = Object.assign(new FilterState(), { contractedBusinesses });

    return this.bidFilterDaoService.updateFilterOptions(versionId, FilterOptionName.DRUG_NAME, state).pipe(
      map((items: FilterOption[]) => items.map((item: FilterOption) => item.value)),
      catchError((response: HttpErrorResponse) => this.notificationService.showServerError(response))
    );
  }

  public applyChanges(drugNames: string[]): void {
    this.bidFilterService.updateFilter(BidFilterName.drugName, drugNames);
    this.bidFilterService.applyFilter();
  }
}
