import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { BidStateService } from '@qv-bid/services/bid-state.service';
import { PharmaRightsService } from '@qv-bid/services/pharma-rights.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  ContractBusinessModalData,
  ContractedBusinessDeleteModalConfig,
  ContractedBusinessManageModalConfig
} from '@qv-bid/components/shared/cb-manage-modal/models';
import { ContractedBusinessManageAction } from '@qv-bid/components/shared/cb-manage-modal/enums';
import { ContractedBusinessesService } from '@qv-bid/services/contracted-businesses.service';
import { ContractedBusinessDaoService } from '@qv-bid/services/dao';
import {
  ContractBusinessImportModalData,
  ContractedBusinessImportModalConfig
} from '@qv-bid/components/shared/cb-import-modal/models';

import { ContractedBusiness } from 'quantuvis-core-entities';
import { ModalService } from 'quantuvis-angular-common/modal';

@UntilDestroy()
@Injectable()
export class ManageContractedBusinessesService {
  public cbManageState$ = new BehaviorSubject<boolean>(false);

  constructor(
    private bidStateService: BidStateService,
    private contractedBusinessDaoService: ContractedBusinessDaoService,
    private pharmaRightsService: PharmaRightsService,
    private contractedBusinessesService: ContractedBusinessesService,
    private modalService: ModalService,
  ) {
    this.initEditModeListener();
  }

  public openModal(
    action: ContractedBusinessManageAction,
    cb?: ContractedBusiness
  ): Observable<ContractedBusiness[]> {
    if (action === ContractedBusinessManageAction.DELETE) {
      return this.openDeleteModal(cb);
    } else if (action === ContractedBusinessManageAction.IMPORT) {
      return this.openImportModal(cb);
    } else {
      return this.openCBManageModal(action, cb);
    }
  }

  private initEditModeListener(): void {
    this.bidStateService.isEditMode.pipe(
      switchMap((isEditMode: boolean) => this.pharmaRightsService.isAllowManageCB().pipe(
        map((isAllowManageCB: boolean) => isEditMode && isAllowManageCB)
      )),
      tap((state: boolean) => this.cbManageState$.next(state)),
      untilDestroyed(this)
    ).subscribe();
  }

  private openDeleteModal(cb: ContractedBusiness): Observable<ContractedBusiness[]> {
    const bid = this.bidStateService.bid$.getValue();
    const bidVersionId = this.bidStateService.bidVersionId;
    const modal = this.modalService.openModal(new ContractedBusinessDeleteModalConfig(cb.name));

    return modal.componentInstance.primaryAction.pipe(
      take(1),
      switchMap(() => this.contractedBusinessDaoService.delete(cb.id, bidVersionId, bid.isInternal)),
      switchMap(() => this.delete(cb)),
      tap(() => modal.componentInstance.primaryActionFinished()),
    );
  }

  private openCBManageModal(
    action: ContractedBusinessManageAction,
    cb: ContractedBusiness
  ): Observable<ContractedBusiness[]> {
    const bid = this.bidStateService.bid$.getValue();
    const modalData = new ContractBusinessModalData(
      this.bidStateService.bidVersionId,
      this.contractedBusinessesService.contractedBusinesses$.getValue(),
      action,
      cb,
      bid.isInternal,
    );
    const modal = this.modalService.openModal(new ContractedBusinessManageModalConfig(modalData));

    return modal.componentInstance.cbSaved.pipe(
      take(1),
      switchMap((savedCb: ContractedBusiness) => this.cbSavedHandler(action, savedCb))
    );
  }

  private openImportModal(cb: ContractedBusiness): Observable<ContractedBusiness[]> {
    const bid = this.bidStateService.bid$.getValue();
    const cbList = this.contractedBusinessesService.contractedBusinesses$.getValue();
    const modalData = new ContractBusinessImportModalData(
      this.bidStateService.bidVersionId,
      cbList,
      cb,
      bid.isInternal
    );

    const modal = this.modalService.openModal(new ContractedBusinessImportModalConfig(modalData));

    return modal.componentInstance.importedEvent.pipe(
      take(1),
      switchMap((importedCb: ContractedBusiness) => this.replace(importedCb))
    );
  }

  private cbSavedHandler(
    action: ContractedBusinessManageAction,
    cb?: ContractedBusiness
  ): Observable<ContractedBusiness[]> {
    switch (action) {
      case ContractedBusinessManageAction.CREATE:
      case ContractedBusinessManageAction.DUPLICATE:
        return this.add(cb);
      case ContractedBusinessManageAction.RENAME:
        return this.rename(cb);
    }
  }

  private add(cb: ContractedBusiness): Observable<ContractedBusiness[]> {
    return this.contractedBusinessesService.contractedBusinesses$.pipe(
      take(1),
      map((cbList: ContractedBusiness[]) => [...cbList, cb]),
      tap((cbList: ContractedBusiness[]) => this.contractedBusinessesService.contractedBusinesses$.next(cbList))
    );
  }

  private delete(deletedCB: ContractedBusiness): Observable<ContractedBusiness[]> {
    return this.contractedBusinessesService.contractedBusinesses$.pipe(
      take(1),
      map((cbList: ContractedBusiness[]) => cbList.filter((cb: ContractedBusiness) => cb.id !== deletedCB.id)),
      tap((cbList: ContractedBusiness[]) => this.contractedBusinessesService.contractedBusinesses$.next(cbList))
    );
  }

  private rename(renamedCb: ContractedBusiness): Observable<ContractedBusiness[]> {
    return this.contractedBusinessesService.contractedBusinesses$.pipe(
      take(1),
      tap((cbList: ContractedBusiness[]) => {
        const cbIndex = cbList.findIndex((cb: ContractedBusiness) => cb.id === renamedCb.id);
        cbList[cbIndex] = renamedCb;
        this.contractedBusinessesService.contractedBusinesses$.next(cbList);
      })
    );
  }

  private replace(importedCB: ContractedBusiness): Observable<ContractedBusiness[]> {
    return this.contractedBusinessesService.contractedBusinesses$.pipe(
      take(1),
      tap((cbList: ContractedBusiness[]) => {
        const index = cbList.findIndex((cb: ContractedBusiness) => importedCB.name === cb.name);
        cbList[index] = importedCB;
        this.contractedBusinessesService.contractedBusinesses$.next(cbList);
      })
    );
  }
}
