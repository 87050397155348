import { Component, Input } from '@angular/core';

@Component({
  selector: 'qv-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss']
})
export class IconComponent {

  @Input()
  public iconName: string;
  @Input()
  public iconClass: string;
  @Input()
  public tooltip: string;
}
