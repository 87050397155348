import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UmDetailsModalData } from './models/um-details-modal-data';
import { SvgIconName } from '@qv-common/enums';
import { FormControl } from '@angular/forms';
import { TermName } from '@qv-term/enums';
import { TermUtils } from '@qv-term/utils';
import { QvCache } from '@qv-common/decorators';

@Component({
  selector: 'qv-um-details-modal',
  templateUrl: './um-details-modal.component.html',
  styleUrls: ['./um-details-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UmDetailsModalComponent {

  public readonly title = 'UM Details';
  public readonly svgIconName = SvgIconName;
  public readonly control: FormControl;
  public readonly termName = TermName;
  public readonly umDetailsDebounceTime = 0;

  @Output()
  public primaryAction = new EventEmitter<string>();

  constructor(
    private dialogRef: MatDialogRef<UmDetailsModalComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: UmDetailsModalData
  ) {
    this.control = new FormControl(this.getTermValue(this.modalData.umDetails));
  }

  public onClose(): void {
    this.primaryAction.complete();
    this.dialogRef.close();
  }

  public isAtNdc(value: any): boolean {
    return TermUtils.isAtNdc(value);
  }

  @QvCache()
  public getTermValue(value: string): string {
    return this.isAtNdc(value) ? '' : value;
  }

  public onPrimaryAction(): void {
    const value = this.control.value.trim();
    if (this.modalData.umDetails !== value) {
      this.primaryAction.next(value);
    }
    this.dialogRef.close();
  }
}
