import { Injectable } from '@angular/core';
import { NotificationService } from 'quantuvis-angular-common/notification';
import { PermissionService } from '@qv-common/services/auth/permission.service';
import { BidDetailsNotificationService } from '@qv-bid/services/bid-details-notification.service';
import { BidEventBusService } from '@qv-bid/services/bid-event-bus.service';
import { HttpStatusCode } from 'quantuvis-angular-common/api';
import { HttpErrorResponse } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { OperatorFunction, throwError } from 'rxjs';

@Injectable()
export class BidViewHandleErrorService {
  private readonly handledLockBidErrorStatuses = [HttpStatusCode.FORBIDDEN, HttpStatusCode.CONFLICT];

  constructor(private notificationService: NotificationService,
              private permissionService: PermissionService,
              private bidDetailsNotificationService: BidDetailsNotificationService,
              private bidEventBusService: BidEventBusService,
  ) { }

  public handleLockError<T>(): OperatorFunction<T, T> {
    return catchError((error: HttpErrorResponse) => {

      if (this.handledLockBidErrorStatuses.includes(error.status)) {
        if (error.status === HttpStatusCode.CONFLICT) {
          this.bidDetailsNotificationService.showAnotherUserEditingNotification(error.error.data);
        }

        if (error.status === HttpStatusCode.FORBIDDEN) {
          this.permissionService.reloadUserPermissions();
          this.bidDetailsNotificationService.showWriteAccessToBidRestrictedNotification();
        }

        this.bidEventBusService.quitEditModeEvent.emit();
        this.bidEventBusService.reloadBidEvent.emit();

        return throwError(error);
      }

      return this.notificationService.showServerError(error);
    });
  }
}
