import { TermUtils } from '@qv-term/utils';
import { JsonObject, JsonProperty } from 'json2typescript';
import { NdcValue } from './ndc-value.entity';
import { DateType } from '@qv-common/types';
import moment, { Moment } from 'moment';
import { CoreUtils, DateUtils } from '@qv-common/utils';
import { MomentConverter } from 'quantuvis-angular-common/json-converter/serializers';

@JsonObject('NdcDateValue')
export class NdcDateValue extends NdcValue<DateType> {
  @JsonProperty('value', MomentConverter)
  public value: DateType = null;

  constructor(value: DateType, isNdc?: boolean) {
    super(value, isNdc);
    this.value = value;
  }

  public static getUtcNdcDateValue(date: Moment | string, ndcDateValue: NdcDateValue): NdcDateValue {
    const value = date instanceof moment ? DateUtils.convertDateToUTC(date as Moment) : date;
    const isAtNdc = TermUtils.isAtNdc(value);

    return new NdcDateValue(
      isAtNdc ? null : value,
      isAtNdc || (ndcDateValue.isNdc && CoreUtils.isNull(value))
    );
  }
}
