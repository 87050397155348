<section class="qv-modal-dialog">
  <header mat-dialog-title class="qv-modal-dialog-title">
    <h1 class="qv-modal-dialog-title__text">{{ title }}</h1>
    <button class="qv-modal-dialog-title__xbutton" (click)="onClose()">
      <mat-icon>close</mat-icon>
    </button>
  </header>
  <div mat-dialog-content
    class="qv-modal-dialog-content qv-modal-dialog-content--primary-padding qv-modal-dialog-content--font-light">
    <p
      class="qv-modal-dialog__value qv-modal-dialog__value--forbidden"
      *ngIf="!modalData.isEditMode else editModeDialog">
        {{ modalData.umDetails }}
    </p>
    <ng-template #editModeDialog>
      <div class="qv-modal-dialog-content__warning-message" *ngIf="isAtNdc(modalData.umDetails)">
        The UM Details differ at the Drug's NDC level (@NDC). Saving this record will override all UM Details at the NDC
        level.
      </div>
      <qv-term-textarea class="qv-modal-dialog-content__textarea"
        data-test-id="test-id-um-details"
        [isEditMode]="modalData.isEditMode"
        [termName]="termName.UM_DETAILS"
        [termValue]="getTermValue(modalData.umDetails)"
        [debounceTime]="umDetailsDebounceTime"
        [control]="control"
      ></qv-term-textarea>
    </ng-template>
  </div>
  <footer mat-dialog-actions class="qv-modal-dialog-actions qv-modal-dialog-actions--padding-sm">
    <button mat-button class="qv-modal-dialog-actions__item"
      [class.qv-modal-dialog-actions__item--primary]="!modalData.isEditMode"
      (click)="onClose()">
      Close
    </button>
    <button *ngIf="modalData.isEditMode"
      mat-button class="qv-modal-dialog-actions__item qv-modal-dialog-actions__item--primary"
      [disabled]="control.invalid"
      (click)="onPrimaryAction()">
      Save
    </button>
  </footer>
</section>
