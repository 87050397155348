<ng-container *ngIf="(isFormReady$ | async) || !isEditMode && entity">
  <div class="section-toolbar">
    <div class="section-toolbar__title">
      <h2>Payment</h2>
    </div>
    <qv-lock
        (clickEvent)="onManageSectionLock(!isSectionLocked$.value)"
        [isDisabled]="isBidInvalid"
        [isEditMode]="isEditMode"
        [locked]="isSectionLocked$ | async"
        data-test-id="test-id-contract-payment-lock"
    ></qv-lock>
  </div>

  <section class="table-section table-section--two-equal-columns table-section--striped">
    <qv-table-section-row [termName]="termName?.REBATE_ELIGIBILITY" class="table-section__row">
      <qv-term-select [control]="$any(formGroup?.get(termName.REBATE_ELIGIBILITY))"
                      [isEditMode]="isEditMode"
                      [isLocked]="entity?.rebateEligibilityLock"
                      [isTooltipEnabled]="isEditMode"
                      [termName]="termName.REBATE_ELIGIBILITY"
                      [termValue]="entity?.rebateEligibility?.name"
                      class="table-section__lockable-term-wrapper"
                      data-test-id="test-id-contract-rebate-eligibility"
      ></qv-term-select>
      <qv-lock [control]="$any(formGroup?.get(termName.getTermNameLock(termName.REBATE_ELIGIBILITY)))"
               [isDisabled]="isBidInvalid"
               [isEditMode]="isEditMode"
               [locked]="entity?.rebateEligibilityLock"
               class="table-section__lock"
               data-test-id="test-id-contract-rebate-eligibility-lock"
      ></qv-lock>
    </qv-table-section-row>

    <qv-table-section-row [termName]="termName.WHOLESALE_ACQUISITION_PRICE" class="table-section__row">
      <qv-term-select [control]="$any(formGroup?.get(termName.WHOLESALE_ACQUISITION_PRICE))"
                      [isEditMode]="isEditMode"
                      [isLocked]="entity?.wholesalePriceLock"
                      [isTooltipEnabled]="isEditMode"
                      [termName]="termName?.WHOLESALE_ACQUISITION_PRICE"
                      [termValue]="entity?.wholesalePrice?.name"
                      class="table-section__lockable-term-wrapper"
                      data-test-id="test-id-contract-wac"
      ></qv-term-select>
      <qv-lock [control]="$any(formGroup?.get(termName.getTermNameLock(termName.WHOLESALE_ACQUISITION_PRICE)))"
               [isDisabled]="isBidInvalid"
               [isEditMode]="isEditMode"
               [locked]="entity?.wholesalePriceLock"
               class="table-section__lock"
               data-test-id="test-id-contract-wac-lock"
      ></qv-lock>
    </qv-table-section-row>

    <qv-table-section-row [termName]="termName.PAYMENT_LAG" class="table-section__row">
      <qv-term-select [control]="$any(formGroup?.get(termName.PAYMENT_LAG))"
                      [isEditMode]="isEditMode"
                      [isLocked]="entity?.lagLock"
                      [isTooltipEnabled]="isEditMode"
                      [termName]="termName.PAYMENT_LAG"
                      [termValue]="entity?.lag?.name"
                      class="table-section__lockable-term-wrapper"
                      data-test-id="test-id-contract-payment-lag"
      ></qv-term-select>
      <qv-lock [control]="$any(formGroup?.get(termName.getTermNameLock(termName.PAYMENT_LAG)))"
               [isDisabled]="isBidInvalid"
               [isEditMode]="isEditMode"
               [locked]="entity?.lagLock"
               class="table-section__lock"
               data-test-id="test-id-contract-payment-lag-lock"
      ></qv-lock>
    </qv-table-section-row>

    <qv-table-section-row [termName]="termName?.PAYMENT_FREQUENCY" class="table-section__row">
      <qv-term-select [control]="$any(formGroup?.get(termName?.PAYMENT_FREQUENCY))"
                      [isEditMode]="isEditMode"
                      [isLocked]="entity?.paymentFrequencyLock"
                      [isTooltipEnabled]="isEditMode"
                      [termName]="termName?.PAYMENT_FREQUENCY"
                      [termValue]="entity?.paymentFrequency?.name"
                      class="table-section__lockable-term-wrapper"
                      data-test-id="test-id-contract-payment-frequency"
      ></qv-term-select>
      <qv-lock [control]="$any(formGroup?.get(termName.getTermNameLock(termName.PAYMENT_FREQUENCY)))"
               [isDisabled]="isBidInvalid"
               [isEditMode]="isEditMode"
               [locked]="entity?.paymentFrequencyLock"
               class="table-section__lock"
               data-test-id="test-id-contract-payment-frequency-lock"
      ></qv-lock>
    </qv-table-section-row>
  </section>
</ng-container>
