import { Injectable } from '@angular/core';
import { ApiService } from '@qv-common/services/api';
import { Observable, of, throwError } from 'rxjs';
import { ApiUrlPrefix, AuthenticationResponseErrorType } from '@qv-common/enums';
import { HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { catchError, switchMap } from 'rxjs/operators';
import { AuthenticationResponseError, OldBodyResponse } from '@qv-common/interfaces';
import { constants } from '@qv-common/static';
import { UserState } from '@qv-common/models/user';

@Injectable({
  providedIn: 'root'
})
export class AuthDaoService {
  constructor(private apiService: ApiService) {}

  public forgotPassword(email: string): Observable<boolean | AuthenticationResponseError> {
    const headers = new HttpHeaders().set('X-Auth-Email', email);
    return this.apiService.post(`${ApiUrlPrefix.OLD}/user/forgotPassword`, null, { headers }).pipe(
      catchError(() => throwError(constants.EMAIL_NOT_VALID_ERROR_MSG)),
      switchMap((data: HttpResponse<OldBodyResponse<boolean>>) =>
        this.prepareResponse(data.body, constants.EMAIL_NOT_VALID_ERROR_MSG)),
    );
  }

  public resendToken(email: string): Observable<boolean | AuthenticationResponseError> {
    const headers = new HttpHeaders().set('X-Auth-Email', email);
    return this.apiService.post(`${ApiUrlPrefix.OLD}/users/resendSetPassword`, null, { headers }).pipe(
      catchError(() => throwError(constants.EMAIL_NOT_VALID_ERROR_MSG)),
      switchMap((data: HttpResponse<OldBodyResponse<boolean>>) =>
        this.prepareResponse(data.body, constants.EMAIL_NOT_VALID_ERROR_MSG))
    );
  }

  public changePassword(password: string, token: string): Observable<UserState | AuthenticationResponseError> {
    const alternativeMessage = constants.EMAIL_NOT_VALID_ERROR_MSG;
    const headers = new HttpHeaders().set('X-Auth-User', btoa(password)).set('X-Auth-Token', token);

    return this.apiService.post(`${ApiUrlPrefix.OLD}/changePassword/setInitialPassword`, null, { headers }).pipe(
      catchError((err: HttpErrorResponse) => this.catchErrorResponse(err, alternativeMessage)),
      switchMap((data: HttpResponse<OldBodyResponse<UserState>>) =>
        this.prepareResponse<UserState>(data.body, alternativeMessage))
    );
  }

  public getInfoForToken(token: string): Observable<string> {
    const headers = new HttpHeaders().set('X-Auth-Token', token);

    return this.apiService.get(`${ApiUrlPrefix.OLD}/users/getInfoForToken`, { headers }).pipe(
      switchMap((data: HttpResponse<OldBodyResponse<string>>) =>
        data.body.error ? throwError(data.body.responseObject) : of(data.body.responseObject))
    );
  }

  private catchErrorResponse(err: HttpErrorResponse,
                             alternativeMessage: string): Observable<AuthenticationResponseError> {
    return throwError(err.error ? this.prepareErrorResponse({} as OldBodyResponse<string>, alternativeMessage) : err);
  }

  private prepareResponse<T>(body: OldBodyResponse<T>, alternativeMessage: string): Observable<T | AuthenticationResponseError> {
    return body.error
      ? throwError(this.prepareErrorResponse(body as any, alternativeMessage))
      : of(body.responseObject);
  }

  private prepareErrorResponse(body: OldBodyResponse<string>, alternativeMessage: string): AuthenticationResponseError {
    const type = body.errorCode === -1
      ? AuthenticationResponseErrorType.redirectUrl
      : AuthenticationResponseErrorType.message;
    return {
      type,
      errorCode: body.errorCode,
      payload: type === AuthenticationResponseErrorType.redirectUrl
        ? body.responseObject
        : (body.message || alternativeMessage)
    };
  }
}
