<section *ngIf="comments" class="bid-comments qv-sidebar-section">
  <header *ngIf="shouldShowCommentToolbar(isUserPharma, summary.commentsLock)" class="section-toolbar">
    <div class="section-toolbar__title">
      <h2>Comments</h2>
    </div>
    <qv-lock
        [control]="commentsLockControl"
        [isDisabled]="isBidInvalid"
        [isEditMode]="isEditMode"
        [locked]="summary.commentsLock"
        data-test-id="test-id-comments-lock"
        top-right-block>
    </qv-lock>
  </header>
  <div *ngIf="isDeleteHistoricAvailable()" class="bid-comments__delete-historic">
    <button (click)="onDeleteHistoric()" class="link-btn">Delete Historic Comments</button>
  </div>
  <div *ngIf="comments.length"
       [class.bid-comments__wrapper--has-field]="shouldShowCommentField(isUserPharma, summary.commentsLock)"
       class="bid-comments__wrapper"
       data-test-id="test-id-comments-list"
  >
    <div *ngFor="let comment of comments"
         [class.bid-comment--highlighted]="isCommentFromOwnCompany(comment.company?.id)"
         class="bid-comment"
    >
      <div
          [ngClass]="isCommentFromOwnCompany(comment.company?.id) ? 'bid-comment__header--right-side' : 'bid-comment__header--left-side'"
          class="bid-comment__header"
      >
        <span *ngIf="comment.company?.name" class="bid-comment__company-name">
          From {{ getCompanyFullName(comment.company.name, comment.company.isPharma(), manufacturerCompanyOldName) }}
        </span>
        <span class="bid-comment__created-at">{{ comment.createdAt | dateTimeUserTz:dateFormat | async }}</span>
      </div>
      <div class="bid-comment__body">{{ comment.value }}</div>
    </div>
  </div>
  <p *ngIf="!comments.length"
     class="qv-sidebar-section__no-data"
     data-test-id="test-id-comments-list-empty">{{ commonMessage.NO_DATA }}</p>
  <qv-bid-comment-field
      (commentChangeEvent)="onCommentChange($event)"
      *ngIf="shouldShowCommentField(isUserPharma, summary.commentsLock)"
      [bidVersionId]="summary.bidVersionId"
      [comment]="commentForSend"
      [isBidInternal]="isBidInternal"
      [isBidInvalid]="isBidInvalid"
      [isEditMode]="isEditMode"
      [summaryId]="summary.id"
      class="bid-comments__field"
      data-test-id="test-id-comments-field"
  >
  </qv-bid-comment-field>
</section>
