import { Component, Input, OnInit } from '@angular/core';
import { appConfig } from '@qv-common/configs';
import { CompanyUtils } from '@qv-company/utils';
import { MarketBasket } from '@qv-bid/entities';
import { CompanyType } from 'quantuvis-core-entities';

@Component({
  selector: 'qv-market-basket-info',
  templateUrl: './market-basket-info.component.html',
  styleUrls: ['./market-basket-info.component.scss']
})
export class MarketBasketInfoComponent implements OnInit {
  @Input()
  public marketBasket: MarketBasket;
  @Input()
  public manufacturerCompanyOldName: string;

  public readonly dateFormat = appConfig.dateFormat;

  public createdBy: string;

  public ngOnInit(): void {
    this.setCreatedBy();
  }

  private setCreatedBy(): void {
    const { type, name } = this.marketBasket.createdBy.company;
    const isPharma = type === CompanyType.PHARMA;

    this.createdBy = CompanyUtils.getCompanyFullName(name, isPharma, this.manufacturerCompanyOldName);
  }
}
