import { Injectable } from '@angular/core';

import { constants } from '@qv-common/static';
import { TermName } from '@qv-term/enums';
import { TermFactory } from './term.factory';
import {
  AttachmentsTerm,
  BindingBidTerm,
  CommentsTerm,
  ContractEndDateTerm,
  ContractEvergreenTerm,
  ContractStartDateTerm,
  InstructionTerm,
  LegalAttestationTerm,
  LineOfBusinessTerm,
  RfpDueDateTerm,
  RfpStartDateTerm,
  RfpTitleTerm,
  WebsiteLinksTerm
} from '@qv-term/models/summary';

@Injectable()
export class SummaryFactory implements TermFactory {
  private summaryTerms = new Map<string, any>();

  public buildTerms(force?: boolean): Map<string, any> {
    if (force || this.summaryTerms.size === 0) {
      const summaryTerms = constants.SUMMARY_TERMS;
      // TODO: update terms that uses displayName as they are working unexpected way
      this.summaryTerms.set(TermName.RFP_TITLE,
        new RfpTitleTerm(summaryTerms.RFP_TITLE.displayName));
      this.summaryTerms.set(TermName.LINE_OF_BUSINESS,
        new LineOfBusinessTerm(summaryTerms.LINE_OF_BUSINESS.label));
      this.summaryTerms.set(TermName.BINDING_BID,
        new BindingBidTerm(TermName.BINDING_BID));
      this.summaryTerms.set(TermName.LEGAL_ATTESTATION,
        new LegalAttestationTerm(constants.BIDDING.LEGAL_ATTESTATION));
      this.summaryTerms.set(TermName.RFP_START_DATE,
        new RfpStartDateTerm(summaryTerms.RFP_START_DATE.label));
      this.summaryTerms.set(TermName.RFP_DUE_DATE,
        new RfpDueDateTerm(summaryTerms.RFP_DUE_DATE.label));
      this.summaryTerms.set(TermName.ATTACHMENTS,
        new AttachmentsTerm(summaryTerms.ATTACHMENTS.label));
      this.summaryTerms.set(TermName.WEBSITE_LINKS,
        new WebsiteLinksTerm(summaryTerms.WEBSITE_LINKS.label));
      this.summaryTerms.set(TermName.CONTRACT_START_DATE,
        new ContractStartDateTerm(summaryTerms.CONTRACT_START_DATE.label));
      this.summaryTerms.set(TermName.CONTRACT_END_DATE,
        new ContractEndDateTerm(summaryTerms.CONTRACT_END_DATE.label));
      this.summaryTerms.set(TermName.CONTRACT_EVERGREEN,
        new ContractEvergreenTerm(summaryTerms.CONTRACT_EVERGREEN.label));
      this.summaryTerms.set(TermName.INSTRUCTIONS,
        new InstructionTerm(TermName.INSTRUCTIONS));
      this.summaryTerms.set(TermName.COMMENTS,
        new CommentsTerm(summaryTerms.COMMENTS.label));
    }

    return this.summaryTerms;
  }
}
