export enum BidRight {
  ALLOW_MARKET_SHARE_REBATE_OFFERS_FROM_PHARMA = 'ALLOW_MARKET_SHARE_REBATE_OFFERS_FROM_PHARMA',
  ALLOW_PHARMA_TO_CREATE_SCENARIOS = 'ALLOW_PHARMA_TO_CREATE_SCENARIOS',
  ALLOW_PHARMA_TO_CREATE_OR_MODIFY_CB = 'ALLOW_PHARMA_TO_CREATE_OR_MODIFY_CB',
  LOCK_ALL_NOTES_FIELDS = 'LOCK_ALL_NOTES_FIELDS',
  ALLOW_DEPENDENCIES_FROM_PHARMA = 'ALLOW_DEPENDENCIES_FROM_PHARMA',
  ALLOW_OFFERS_AT_THE_NDC_LEVEL_FROM_PHARMA = 'ALLOW_OFFERS_AT_THE_NDC_LEVEL_FROM_PHARMA',
  ALLOW_PHARMA_TO_NEGOTIATE_MB = 'ALLOW_PHARMA_TO_NEGOTIATE_MB'
}

export namespace BidRight {
  const displayValues = new Map([
    [BidRight.ALLOW_MARKET_SHARE_REBATE_OFFERS_FROM_PHARMA, 'Allow Market Share Rebate offers from Pharma'],
    [BidRight.ALLOW_PHARMA_TO_CREATE_SCENARIOS, 'Allow Pharma to create Scenarios'],
    [BidRight.ALLOW_PHARMA_TO_CREATE_OR_MODIFY_CB, 'Allow Pharma to create or modify Contracted Businesses'],
    [BidRight.LOCK_ALL_NOTES_FIELDS, 'Lock all Notes fields'],
    [BidRight.ALLOW_DEPENDENCIES_FROM_PHARMA, 'Allow Dependencies from Pharma'],
    [BidRight.ALLOW_OFFERS_AT_THE_NDC_LEVEL_FROM_PHARMA, 'Allow offers at the NDC level from Pharma'],
    [BidRight.ALLOW_PHARMA_TO_NEGOTIATE_MB, 'Allow Pharma to negotiate Market Baskets']
  ]);

  export function displayValue(right: BidRight): string {
    return displayValues.get(right);
  }
}
