import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FieldValidationMessage, FormValidationError } from '@qv-common/enums';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'qv-expansion-form-panel',
  templateUrl: './expansion-form-panel.component.html',
  styleUrls: ['./expansion-form-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExpansionFormPanelComponent implements OnInit, OnChanges {
  @Input()
  public title: string;
  @Input()
  public data: { [key: string]: string };
  @Input()
  public group: FormGroup;
  @Input()
  public datePickerFields?: string[];

  public filteredOptions: { [key: string]: Observable<string[]> } = {};

  public readonly validationMessages = new Map<string, string>([
    [FormValidationError.REQUIRED, FieldValidationMessage.REQUIRED],
    [FormValidationError.MAX_LENGTH, FieldValidationMessage.MAX_LENGTH_255],
    [FormValidationError.INCORRECT_VALUE, FieldValidationMessage.INCORRECT_VALUE]
  ]);

  @Input()
  public options?: Record<string, unknown>;

  public ngOnInit(): void {
    this.setFilteredOptions();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.options) {
      this.setFilteredOptions();
    }
  }

  public ascOrder(item: { key: number }): number {
    return item.key;
  }

  public shouldDisableAutocomplete(key: string): boolean {
    return !(this.options && this.options[key]);
  }

  public isDatePickerField(key: string): boolean {
    return this.datePickerFields && this.datePickerFields.includes(key);
  }

  public displayFn(value: { name: string } | string): string {
    return typeof value === 'string' ? value : value.name;
  }

  private setFilteredOptions(): void {
    if (this.options) {
      Object.keys(this.options).forEach((key) => {
        this.filteredOptions[key] = this.getFilteredOptions(key);
      });
    }
  }

  private getFilteredOptions(key: string): Observable<string[]> {
    if (this.options && this.options[key] && this.group && this.group.controls && this.group.controls[key]) {
      return this.group.controls[key].valueChanges
        .pipe(
          startWith<{ name: string } | string>(''),
          map((value: { name: string } | string) =>
            typeof value === 'string' ? value : value.name
          ),
          map((value: string) =>
            value ? this.filterOptions(key, value) : (this.options[key] as any).slice()
          )
        );
    }
  }

  private filterOptions(key: string, value: string): string[] {
    if (this.options && this.options[key]) {
      const filterValue = value.toLowerCase();
      return (this.options[key] as any[]).filter((option) => {
        option = option.name || option;
        return option.toLowerCase().includes(filterValue);
      });
    }
  }
}
