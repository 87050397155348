import { Injectable } from '@angular/core';
import { SnackBarService } from 'quantuvis-angular-common/snack-bar';
import { Subject } from 'rxjs';
import { TermSection } from '@qv-term/enums';
import { filter, switchMap, take, tap } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Injectable()
export class ScenariosSnackBarService {
  public readonly sectionChanged$ = new Subject<TermSection>();
  public readonly sectionChangeSuccess$ = new Subject<TermSection>();
  public readonly sectionChangeFailed$ = new Subject<TermSection>();
  private readonly pendingSections = new Map<TermSection, boolean>();
  private readonly failedSections = new Map<TermSection, boolean>();

  constructor(private snackBarService: SnackBarService) {
    this.initSectionChangeHandler();
    this.initSectionChangeSuccessHandler();
    this.initSectionChangeFailedHandler();
  }

  private initSectionChangeHandler(): void {
    this.sectionChanged$
      .pipe(untilDestroyed(this))
      .subscribe((section: TermSection) => {
        if (this.allSectionsFinishingSaving()) {
          this.snackBarService.start();
        }
        this.pendingSections.set(section, true);
        this.failedSections.set(section, false);
      });
  }

  private initSectionChangeSuccessHandler(): void {
    this.sectionChangeSuccess$.pipe(
      tap((section: TermSection) => this.pendingSections.set(section, false)),
      filter((section: TermSection) => this.allSectionsFinishingSaving() && !this.failedSections.get(section)),
      untilDestroyed(this)
    ).subscribe(() => this.snackBarService.finish());
  }

  private initSectionChangeFailedHandler(): void {
    this.sectionChangeFailed$.pipe(
      tap((section: TermSection) => {
        this.pendingSections.set(section, false);
        this.failedSections.set(section, true);
        this.snackBarService.error();
      }),
      switchMap(() => this.sectionChangeSuccess$.pipe(take(1))),
      untilDestroyed(this)
    ).subscribe((section: TermSection) => this.failedSections.set(section, false));
  }

  private allSectionsFinishingSaving(): boolean {
    return Array.from(this.pendingSections.values()).every((pending: boolean) => !pending);
  }
}
