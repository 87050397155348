import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { JSON_CONVERT_TOKEN } from 'quantuvis-angular-common/json-converter/services';
import { JsonConvert } from 'json2typescript';
import { HttpResponseBody } from 'quantuvis-angular-common/api';
import { DropdownOption } from '../entities/company-dropdown-option.entity';
import { map } from 'rxjs/operators';
import { FieldDropdownOptions } from '../entities/field-dropdown-options.entity';

type ResponseType = HttpResponseBody<DropdownOption[]>;

@Injectable({
  providedIn: 'root'
})
export class CompanyDropdownOptionsDaoService {

  private static resourceUrl = '/api/system-administration/applications/company-fields/dropdown-options/scenarios';

  constructor(
    private httpClient: HttpClient,
    @Inject(JSON_CONVERT_TOKEN) private jsonConvert: JsonConvert) {
  }

  public get(): Observable<FieldDropdownOptions[]> {
    return this.httpClient
      .get<ResponseType>(CompanyDropdownOptionsDaoService.resourceUrl)
      .pipe(
        map(({ data }) => this.deserializeData(data)),
      );
  }

  private regroupData(dropdownOptions: DropdownOption[]): [ string, DropdownOption ][] {
    return Object.entries(dropdownOptions
      .reduce((result, dropdownOption) => {
        result[dropdownOption.fieldName] = (result[dropdownOption.fieldName] || []).concat(dropdownOption);

        return result;
      }, {}));
  }

  private deserializeData(dropdownOptions: DropdownOption[]): FieldDropdownOptions[] {
    return this.regroupData(dropdownOptions)
      .map(([id, dropdownOptions]) =>
        this.jsonConvert.deserializeObject({ id, dropdownOptions }, FieldDropdownOptions));
  }
}
