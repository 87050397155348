import { DrugFormService } from '@qv-bid/services/drug/drug-form.service';
import { DictionaryItem } from '@qv-common/entities';
import { TermName, TermSection } from '@qv-term/enums';
import { merge, Observable } from 'rxjs';
import { UtilizationManagement } from '@qv-bid/entities';
import { filter, switchMap } from 'rxjs/operators';
import { umDetailsCheckControls } from '@qv-term/constants';

export class UmDetailsService {
  private readonly sectionName = TermSection.UM;
  private readonly selectValue = {
    EMPTY: 1,
    NO: 2,
    NDC: null
  };
  private readonly emptyOrNoValue = [this.selectValue.EMPTY, this.selectValue.NO];

  constructor(private drugFormService: DrugFormService) {}

  public getAccessBasedOnTerms(): boolean {
    return this.checkTermValue(this.getSelectsValue());
  }

  public getAccessBasedOnTermsByData(um: UtilizationManagement): boolean {
    return this.checkTermValue(this.getSelectValueByData(um));
  }

  public sectionUpdated(): Observable<Record<string, unknown>> {
    return merge(
      this.drugFormService.drugUpdated,
      this.drugFormService.formChangeHandler(this.sectionName),
      this.handleUmDetailsChange()
    );
  }

  private handleUmDetailsChange(): Observable<any> {
    return this.drugFormService.isEditMode(this.sectionName).pipe(
      filter((isEditMode: boolean) => isEditMode),
      switchMap(() => this.drugFormService.getTermControl(TermName.UM_DETAILS, this.sectionName).valueChanges)
    );
  }

  private checkTermValue(checkTermValue: number[]): boolean {
    return checkTermValue.some((controlValue: number) => !this.emptyOrNoValue.includes(controlValue));
  }

  private getSelectsValue(): number[] {
    return umDetailsCheckControls.map((controlName: TermName) => this.getTermValueId(
      this.drugFormService.getTermControl(controlName, this.sectionName).value)
    );
  }

  private getSelectValueByData(um: UtilizationManagement): number[] {
    return umDetailsCheckControls.map((termName: TermName) => this.getTermValueId(um[termName].value));
  }

  private getTermValueId(termValue: DictionaryItem): number {
    // TODO null will be changed to check NDC Selects value in task ALLI-602
    return termValue ? termValue.id : null;
  }
}
