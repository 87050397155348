import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('InsightDashboard')
export class InsightDashboard {
  @JsonProperty('id', String)
  public id: string = null;

  @JsonProperty('title', String)
  public title: string = null;

  constructor(id: string = null, title: string = null) {
    this.id = id;
    this.title = title;
  }
}
