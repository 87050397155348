import {
  Component,
  Inject,
  ViewContainerRef,
  ViewChild,
  ComponentFactoryResolver,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  AfterViewInit,
  Output,
  EventEmitter
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

import { ConfirmModal } from '../../interfaces';
import { BaseInsideModal } from '../../models/base/base-inside-modal';
import { TemplateModalData } from '../../models/data/template-modal-data';

@UntilDestroy()
@Component({
  selector: 'qac-template-modal',
  templateUrl: './template-modal.component.html',
  styleUrls: ['./template-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TemplateModalComponent implements AfterViewInit, ConfirmModal {
  @BlockUI('template-modal')
  public blockUI: NgBlockUI;

  @ViewChild('nestedComponent', { read: ViewContainerRef })
  public nestedCmpContainerRef: ViewContainerRef;

  @Output()
  public primaryAction = new EventEmitter();

  @Output()
  public secondaryAction = new EventEmitter();

  public nestedCmp: BaseInsideModal;

  constructor(
    public readonly dialogRef: MatDialogRef<TemplateModalComponent>,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly resolver: ComponentFactoryResolver,
    @Inject(MAT_DIALOG_DATA) public modalData: TemplateModalData
  ) {}

  public ngAfterViewInit(): void {
    this.createComponent();
  }

  public onPrimaryAction(): void {
    this.primaryAction.emit();
    this.close();
  }

  public onSecondaryAction(): void {
    this.secondaryAction.emit();
    this.close();
  }

  public close(): void {
    this.dialogRef.close();
  }

  private createComponent(): void {
    const factory = this.resolver.resolveComponentFactory(this.modalData.component);

    this.nestedCmp = this.nestedCmpContainerRef.createComponent(factory).instance;
    this.nestedCmp.payloadData = this.modalData.componentPayload;
    this.nestedCmp.closeEvent.pipe(untilDestroyed(this)).subscribe(() => this.close());

    this.changeDetectorRef.detectChanges();
  }
}
