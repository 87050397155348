<button
    (click)="$event.stopPropagation()"
    (menuClosed)="contentClosed.next()"
    (menuOpened)="contentOpened.next()"
    [matMenuTriggerFor]="filterMenu"
    class="filter-menu__trigger"
    mat-button
>
  <mat-icon
      [class.filter-menu__icon--applied]="isFilterApplied"
      [svgIcon]="svgIconName.FILTER"
      class="qv-icon qv-icon--16 filter-menu__icon"
  ></mat-icon>
</button>

<mat-menu #filterMenu [class]="menuClass" [overlapTrigger]="false">
  <div (click)="$event.stopPropagation()" class="filter-menu">
    <div *ngIf="title" class="filter-menu__title">{{title}}</div>
    <ng-content></ng-content>
    <div class="filter-menu__actions">
      <button
          (click)="trigger.closeMenu()"
          mat-button
      >{{resources.Actions.CANCEL}}</button>
      <button
          (click)="onApply()"
          [disabled]="isApplyButtonDisabled(isFilterChanged, isFilterInvalid)"
          class="qv-button qv-button--primary"
          mat-button
      >{{resources.Actions.APPLY}}</button>
    </div>
  </div>
</mat-menu>
