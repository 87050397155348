import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { NdcValue } from '@qv-term/entities';
import { SelectBoxTerm } from '@qv-term/models';
import { TermTemplateStorageService } from '@qv-term/services';
import { DictionaryItem } from '@qv-common/entities';
import { NgSelectComponent } from '@ng-select/ng-select';
import { BaseTermWithLockComponent } from '@qv-term/components/base-term-with-lock/base-term-with-lock.component';
import { UserService } from '@qv-common/services/auth/user.service';

@Component({
  selector: 'qv-term-select',
  templateUrl: './term-select.component.html',
  styleUrls: ['./term-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TermSelectComponent extends BaseTermWithLockComponent implements OnInit, OnChanges {
  @Input()
  public termValue: string | NdcValue<any>;
  @Input()
  public isTooltipEnabled = false;
  @Input()
  public isShowShortName = false;
  @Input()
  public isDisabled = false;
  @Input()
  public appendTo = '';

  public termConfig: SelectBoxTerm;
  public selectItems: DictionaryItem[] = [];
  public readonly isUserPharma: boolean;

  @ViewChild('ngSelectCmp')
  public ngSelectCmp: NgSelectComponent;

  constructor(
    protected termTemplateStorage: TermTemplateStorageService,
    userService: UserService,
    changeDetectorRef: ChangeDetectorRef
  ) {
    super(userService, termTemplateStorage, changeDetectorRef);
  }

  public ngOnInit(): void {
    super.ngOnInit();

    if (this.termConfig.values) {
      this.setSelectOptions();
    }
  }

  public ngOnChanges({ control }: SimpleChanges): void {
    if (control && control.currentValue && this.isDisabled) {
      this.control.disable({ onlySelf: true, emitEvent: false });
    }
  }

  public getTermValue(): string {
    const value = super.getTermValue();
    return value instanceof DictionaryItem ? this.getName(value) : value;
  }

  public getTooltipMessage(): string {
    const value = super.getTermValue();
    return value instanceof DictionaryItem ? value.name : value;
  }

  public completeEditMode(): void {
    this.deactivateOverrideMode();
    this.exitFromEditModeEvent.emit();
    this.ngSelectCmp.element.querySelector('input').blur();
  }

  public transferToEditMode(): void {
    this.ngSelectCmp.open();
  }

  public isEditingAvailable(): boolean {
    return Boolean(this.ngSelectCmp) || super.isEditingAvailable();
  }

  public preventEvent(event: Event): void {
    event.preventDefault();
    event.stopPropagation();
  }

  public focus(): void {
    this.ngSelectCmp.focus();
  }

  @HostListener('keydown', ['$event'])
  public handleKeyboardEvent(event: KeyboardEvent): void {
    event.stopPropagation();
  }

  private setSelectOptions(): void {
    this.selectItems = this.termConfig.values;
  }

  private getName(value: DictionaryItem): string {
    return this.isShowShortName && value.shortName ? value.shortName : value.name;
  }
}
