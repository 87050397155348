export class NavigationUtils {
  public static deepLinkSelector = 'QV-deepLink';

  public static getDeepLink(): string {
    return localStorage.getItem(NavigationUtils.deepLinkSelector);
  }

  public static setDeepLink(url: string): void {
    localStorage.setItem(NavigationUtils.deepLinkSelector, url);
  }

  public static clearDeepLink(): void {
    localStorage.removeItem(NavigationUtils.deepLinkSelector);
  }

  public static setDeepLinkToCurrentUrl(): void {
    const currentUrl = window.location.hash.slice(1);

    if (currentUrl) {
      NavigationUtils.setDeepLink(currentUrl);
    }
  }

  public static replaceStateToRoot(): void {
    window.history.replaceState(null, null, '/');
  }
}
