import { ChangeDetectionStrategy, Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { ReasonInfo } from '@qv-bid/models';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BidDeclineModalData } from '@qv-bid/components/shared';
import { FieldValidation, FieldValidationMessage, FormValidationError } from '@qv-common/enums';
import { ViewPerspectiveService } from '@qv-common/services/auth';
import { BidUtils } from '@qv-bid/utils';
import { CompanyUtils } from '@qv-company/utils';
import { GeneralModalComponent } from 'quantuvis-angular-common/modal';
import { UserService } from '@qv-common/services/auth/user.service';

@Component({
  selector: 'qv-bid-decline-modal',
  templateUrl: './bid-decline-modal.component.html',
  styleUrls: ['./bid-decline-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BidDeclineModalComponent extends GeneralModalComponent implements OnInit {

  @Output()
  public primaryAction: EventEmitter<ReasonInfo> = new EventEmitter<ReasonInfo>();

  public readonly title = 'Decline Bid';
  public readonly validationMessages = new Map<string, string>([
    [FormValidationError.REQUIRED, FieldValidationMessage.REQUIRED],
    [FormValidationError.MAX_LENGTH, FieldValidationMessage.MAX_LENGTH_2000]
  ]);

  public isPharma = false;
  public control = new FormControl('', [
    Validators.maxLength(FieldValidation.MAX_LENGTH_2000),
  ]);

  constructor(public dialogRef: MatDialogRef<BidDeclineModalComponent>,
              @Inject(MAT_DIALOG_DATA) public modalData: BidDeclineModalData | any,
              private userService: UserService,
              private viewPerspectiveService: ViewPerspectiveService) {
    super(dialogRef, null);
  }

  public ngOnInit(): void {
    this.isPharma = BidUtils.isUserPharmaOrPharmaPerspective(this.userService.isCurrentUserPharma(),
      this.modalData.isBidInternal, this.viewPerspectiveService.isPharmaViewPerspective());

    if (this.isPharma) {
      this.control.setValidators([
        Validators.required.bind(this),
        Validators.maxLength(FieldValidation.MAX_LENGTH_2000)
      ]);
    }
  }

  public onPrimaryAction(): void {
    this.blockUI.start();
    this.primaryAction.next(new ReasonInfo(this.control.value));
  }

  public getPharmaFullCompanyName(companyName: string, manufacturerCompanyOldName: string): string {
    return CompanyUtils.appendOldCompanyName(companyName, manufacturerCompanyOldName);
  }
}
