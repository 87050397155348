<qv-market-basket-preview
  *ngIf="isPreviewState"
  [state]="currentState"
  [marketBasket]="modalData.marketBasket"
  [scenarioId]="modalData.scenarioId"
  [isEditMode]="modalData.isEditMode"
  [isNdcLevel]="modalData.isNdcLevel"
  [isBidInternal]="modalData.isBidInternal"
  [manufacturerCompanyOldName]="modalData.manufacturerCompanyOldName"
  (stateChanged)="updateCurrentState($event)"
  (primaryAction)="primaryAction.emit($event)">
</qv-market-basket-preview>

<qv-market-basket-assign
  *ngIf="isAssignOrImportState"
  [scenarioId]="modalData.scenarioId"
  [drugName]="modalData.drugName"
  [drugClass]="modalData.drugClass"
  [marketBasket]="modalData.marketBasket"
  [isEditMode]="modalData.isEditMode"
  [isNdcLevel]="modalData.isNdcLevel"
  [isBidInternal]="modalData.isBidInternal"
  (stateChanged)="updateCurrentState($event)"
  (primaryAction)="primaryAction.emit($event)">
</qv-market-basket-assign>

<qv-market-basket-edit
  *ngIf="isNewOrEditState"
  [state]="currentState"
  [scenarioId]="modalData.scenarioId"
  [drugName]="modalData.drugName"
  [drugClass]="modalData.drugClass"
  [marketBasket]="marketBasket"
  [isEditMode]="modalData.isEditMode"
  [isBidInternal]="modalData.isBidInternal"
  [manufacturerCompanyOldName]="modalData.manufacturerCompanyOldName"
  (primaryAction)="primaryAction.emit($event)">
</qv-market-basket-edit>
