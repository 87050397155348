<mat-form-field *ngIf="useFormField" [formGroup]="group" class="date-picker">
  <input #datePickerInput
         (keyup)="onKeyUp()"
         [formControlName]="controlName"
         [matDatepicker]="picker"
         [max]="maxDate"
         [min]="minDate"
         [placeholder]="placeholder"
         matInput>
  <mat-datepicker #picker></mat-datepicker>
  <mat-datepicker-toggle [for]="picker" matSuffix></mat-datepicker-toggle>
  <acc-form-field-errors
      [control]="group.get(controlName)"
      [validationMessages]="validationMessages"
      class="date-picker__error"
  ></acc-form-field-errors>
</mat-form-field>
<ng-container *ngIf="!useFormField">
  <input #datePickerInput
         (dateChange)="onDateChanged($event)"
         (focus)="onFocusHandler($event)"
         [disabled]="disabled"
         [matDatepicker]="picker"
         [max]="maxDate"
         [min]="minDate"
         [ngClass]="{'keep-origin-val': keepOriginalValue}"
         [placeholder]="placeholder"
         [value]="control.value"
         class="form-control dateinput"
         matInput>
  <mat-datepicker #picker [disabled]="disabled"></mat-datepicker>
  <mat-datepicker-toggle [for]="picker" matSuffix></mat-datepicker-toggle>
</ng-container>
