import { Injectable } from '@angular/core';
import { HttpStatusCode } from 'quantuvis-angular-common/api';
import { OperatorFunction, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorMessage } from '@qv-common/enums';
import { NotificationService } from 'quantuvis-angular-common/notification';
import { ModalService } from 'quantuvis-angular-common/modal';

@Injectable()
export class BidHandleErrorService {
  constructor(
    private modalService: ModalService,
    private notificationService: NotificationService
  ) {}

  public handleSendBidError<T>(): OperatorFunction<T, T> {
    return catchError((error: HttpErrorResponse) => {
      switch (error.status) {
        case HttpStatusCode.NOT_FOUND: {
          this.modalService.closeAll();
          break;
        }
        case HttpStatusCode.CONFLICT: {
          this.notificationService.error(error.error.message);
          break;
        }
        case HttpStatusCode.FORBIDDEN: {
          this.notificationService.warning(ErrorMessage.ANOTHER_USER_MIGHT_HAVE_SENT);
          break;
        }
        default: {
          this.notificationService.showServerError(error);
          break;
        }
      }

      return throwError(error);
    });
  }
}
