export const formats = {
  dateFormat: 'MM/DD/YYYY',
  dateFormatShort: 'MM/DD/YY',
  dateTimeFormat: 'MM/DD/YYYY hh:mm A',
  dateTimeFormatShort: 'MM/DD/YY hh:mm A',
  dateTimeFormatFull: 'dddd MMM DD, YYYY hh:mm A',
  dateFormatConfig: {
    parse: {
      dateInput: 'MM/DD/YY',
    },
    display: {
      dateInput: 'MM/DD/YY',
      monthYearLabel: 'MMM YYYY',
      dateA11yLabel: 'LL',
      monthYearA11yLabel: 'MMMM YYYY',
    }
  }
};
