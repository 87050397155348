import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ArrayUtils } from '@qv-common/utils';
import { ContractDateConflict } from './models/contract-date-conflict';
import { ContractDateConflictsModalData } from './models/contract-date-conflicts-modal-data';
import { SvgIconName } from '@qv-common/enums';

@Component({
  selector: 'qv-contract-date-conflicts-modal',
  templateUrl: './contract-date-conflicts-modal.component.html',
  styleUrls: ['./contract-date-conflicts-modal.component.scss']
})
export class ContractDateConflictsModalComponent implements OnInit {

  public readonly svgIconName = SvgIconName;
  public modalContent: Map<string, ContractDateConflict[]>;

  constructor(
    private dialogRef: MatDialogRef<ContractDateConflictsModalComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: ContractDateConflictsModalData) {}

  public ngOnInit(): void {
    this.modalContent = this.prepareModalContent(this.modalData.conflicts);
  }

  public closeModalDialog(): void {
    this.dialogRef.close();
  }

  public getPlanTypes(): string[] {
    return Array.from(this.modalContent.keys());
  }

  public getConflictsOfPlanType(planType: string): ContractDateConflict[] {
    return this.modalContent.get(planType);
  }

  public prepareModalContent(conflicts: ContractDateConflict[]): Map<string, ContractDateConflict[]> {
    const conflictsGroupedByPlanType = ArrayUtils.groupBy(conflicts,
      conflict => conflict.planType.details.name);
    conflictsGroupedByPlanType.forEach(conflictsOfPlanType => {
      const planTypeDrugList = conflictsOfPlanType[0].planType.drugList;
      const planTypeDrugsGroupedByScenario = ArrayUtils.groupBy(planTypeDrugList,
        drug => drug.scenario.id);
      const conflictsGroupedByScenario = ArrayUtils.groupBy(conflictsOfPlanType,
        conflict => conflict.drug.scenario.id);
      conflictsOfPlanType.forEach(conflict => {
        const scenarioId = conflict.drug.scenario.id;
        const totalDrugsInScenario = planTypeDrugsGroupedByScenario.get(scenarioId).length;
        const conflictsInScenario = conflictsGroupedByScenario.get(scenarioId).length;
        conflict.isAtNdcLevel = totalDrugsInScenario > conflictsInScenario;
      });
      conflictsGroupedByScenario.forEach(scenarioConflicts => {
        scenarioConflicts[0].isDisplayed = true;
      });
    });
    return conflictsGroupedByPlanType;
  }
}
