<section *ngIf="(isFormReady$ | async) || !isEditMode && summary"
         class="table-section table-section--two-equal-columns table-section--striped">
  <qv-table-section-row [termName]="termName?.RFP_TITLE" class="table-section__row">
    <qv-term-input
        [class.table-section__cell--error]="formGroup?.get(termName.RFP_TITLE)?.errors"
        [control]="$any(formGroup?.get(termName.RFP_TITLE))"
        [isEditMode]="isRfpTitleEditable()"
        [isFullWidth]="true"
        [termName]="termName.RFP_TITLE"
        [termValue]="summary.rfpTitle"
        class="table-section__cell"
        data-test-id="test-id-summary-title"
    ></qv-term-input>
    <acc-form-field-errors
        *ngIf="isRfpTitleEditable()"
        [control]="formGroup?.get(termName.RFP_TITLE)"
        [validationMessages]="rfpTitleValidationMessages"
        class="table-section__error"
    ></acc-form-field-errors>
  </qv-table-section-row>

  <qv-table-section-row [termName]="termName?.LINE_OF_BUSINESS" class="table-section__row">
    <qv-term-select
        [termName]="termName?.LINE_OF_BUSINESS"
        [termValue]="summary?.lineOfBusiness"
        data-test-id="test-id-summary-line-of-business">
    </qv-term-select>
  </qv-table-section-row>

  <div class="table-section__row">
    <div class="table-section__cell">
      <span class="table-section__title">{{ oppositeCompanyType }}:</span>
    </div>
    <div class="table-section__cell" data-test-id="test-id-summary-company-name"
    >
      <span class="table-section__value">{{ oppositeCompanyName }}</span>
    </div>
  </div>

  <div class="table-section__row">
    <div class="table-section__cell">
      <span class="table-section__title">Status:</span>
    </div>
    <div class="table-section__cell">
      <span class="table-section__value table-section__value--summary-status"
            data-test-id="test-id-summary-status"
      >{{ completeBidStatus }}</span>
      <qv-term-select
          *ngIf="isPayerInEditModeWithBindingBidFeature()"
          [control]="$any(formGroup?.get(termName.BINDING_BID))"
          [isEditMode]="isEditMode"
          [termName]="termName.BINDING_BID"
          [termValue]="$any(summary.bindingBid)"
          class="bid-binding-status-editor"
          data-test-id="test-id-summary-status-select"
      >
      </qv-term-select>
    </div>
  </div>

  <div class="table-section__row">
    <div class="table-section__cell">
      <span class="table-section__title">Assigned To:</span>
    </div>
    <div class="table-section__cell"
         data-test-id="test-id-summary-assigned-to"
    >
      <span class="table-section__value">{{ assignedCompanyName }}</span>
    </div>
  </div>

  <div *ngIf="isVersionVisible() | async" class="table-section__row table-section__row--version-number">
    <div class="table-section__cell">
      <span class="table-section__title">Version:</span>
    </div>
    <div class="table-section__cell">
      <span class="table-section__value">{{ summary.versionNumber }}
        ({{ summary.versionCreatedDate | dateTimeUserTz | async }})</span>
    </div>
  </div>

  <qv-table-section-row [termName]="termName?.RFP_START_DATE" class="table-section__row">
    <qv-term-date
        [termName]="termName?.RFP_START_DATE"
        [termValue]="summary?.rfpStartDate"
        data-test-id="test-id-rfp-start-date"
    ></qv-term-date>
  </qv-table-section-row>

  <qv-table-section-row [termName]="termName?.RFP_DUE_DATE" class="table-section__row">
    <qv-term-date
        [control]="$any(formGroup?.get(termName.RFP_DUE_DATE))"
        [errors]="rfpDueDateErrors"
        [isEditMode]="isPayerInEditMode(isEditMode, isPayerOrPayerPerspective)"
        [termName]="termName?.RFP_DUE_DATE"
        [termValue]="summary?.rfpDueDate"
        data-test-id="test-id-summary-rfp-due-date"
    ></qv-term-date>
  </qv-table-section-row>

  <qv-table-section-row [termName]="termName?.CONTRACT_START_DATE" class="table-section__row">
    <qv-term-date
        [control]="$any(formGroup?.get(termName.CONTRACT_START_DATE))"
        [isEditMode]="isEditMode"
        [isLocked]="summary?.contractStartDateLock"
        [termName]="termName?.CONTRACT_START_DATE"
        [termValue]="summary?.contractStartDate"
        class="table-section__lockable-term-wrapper"
        data-test-id="test-id-summary-contract-start-date"
    ></qv-term-date>
    <qv-lock
        [control]="$any(formGroup?.get(termName.getTermNameLock(termName.CONTRACT_START_DATE)))"
        [isDisabled]="isBidInvalid"
        [isEditMode]="isEditMode"
        [locked]="summary?.contractStartDateLock"
        class="table-section__lock"
        data-test-id="test-id-summary-contract-start-date-lock"
    ></qv-lock>
  </qv-table-section-row>

  <qv-table-section-row [termName]="termName?.CONTRACT_END_DATE" class="table-section__row">
    <qv-term-date
        [control]="$any(formGroup?.get(termName.CONTRACT_END_DATE))"
        [isEditMode]="isEditMode"
        [isLocked]="summary?.contractEndDateLock"
        [termName]="termName?.CONTRACT_END_DATE"
        [termValue]="summary?.contractEndDate"
        class="table-section__lockable-term-wrapper"
        data-test-id="test-id-summary-contract-end-date"
    ></qv-term-date>
    <qv-lock
        [control]="$any(formGroup?.get(termName.getTermNameLock(termName.CONTRACT_END_DATE)))"
        [isDisabled]="isBidInvalid"
        [isEditMode]="isEditMode"
        [locked]="summary?.contractEndDateLock"
        class="table-section__lock"
        data-test-id="test-id-summary-contract-end-date-lock"
    ></qv-lock>
  </qv-table-section-row>

  <qv-table-section-row [termName]="termName?.CONTRACT_EVERGREEN" class="table-section__row">
    <qv-term-slide-toggle
        [control]="$any(formGroup?.get(termName.CONTRACT_EVERGREEN))"
        [isEditMode]="isEditMode"
        [isLocked]="summary?.contractEvergreenLock"
        [termName]="termName?.CONTRACT_EVERGREEN"
        [termValue]="summary?.contractEvergreen"
        class="table-section__lockable-term-wrapper"
        data-test-id="test-id-summary-contract-evergreen"
    >
    </qv-term-slide-toggle>
    <qv-lock
        [control]="$any(formGroup?.get(termName.getTermNameLock(termName.CONTRACT_EVERGREEN)))"
        [isDisabled]="isBidInvalid"
        [isEditMode]="isEditMode"
        [locked]="summary?.contractEvergreenLock"
        class="table-section__lock"
        data-test-id="test-id-summary-contract-evergreen-lock"
    ></qv-lock>
  </qv-table-section-row>
</section>


