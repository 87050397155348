import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BidAssignee, CompanyType } from 'quantuvis-core-entities';
import { Bid } from '@qv-bid/entities';
import { ViewPerspectiveService } from '@qv-common/services/auth/view-perspective.service';
import { BidDetailsNotificationService } from '@qv-bid/services';
import { SvgIconName } from '@qv-common/enums';
import { SwitchButtonConfig } from '@qv-shared/models';

@Component({
  selector: 'qv-role-switcher',
  templateUrl: './role-switcher.component.html',
  styleUrls: ['./role-switcher.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RoleSwitcherComponent implements OnInit, OnDestroy {
  @Input()
  public bid: Bid;

  public perspective: BidAssignee;
  public readonly primaryButtonConfig = new SwitchButtonConfig<BidAssignee>(
    CompanyType.displayValue(CompanyType.PHARMA),
    'View the Bid from Pharma perspective',
    SvgIconName.PHARMA,
    BidAssignee.PHARMA
  );
  public readonly secondaryButtonConfig = new SwitchButtonConfig<BidAssignee>(
    CompanyType.displayValue(CompanyType.PAYER),
    'View the Bid from Payer perspective',
    SvgIconName.PAYER,
    BidAssignee.PAYER
  );

  constructor(
    private viewPerspectiveService: ViewPerspectiveService,
    private bidDetailsNotificationService: BidDetailsNotificationService
  ) {}

  public ngOnInit(): void {
    this.perspective = this.viewPerspectiveService.getViewPerspective();
  }

  public onChangeViewPerspective(value: BidAssignee): void {
    this.viewPerspectiveService.setViewPerspective(value);
    this.perspective = value;
    this.viewPerspectiveService.switchPerspectiveEvent.emit();
    this.bidDetailsNotificationService.showViewPerspectiveNotification(this.bid);
  }

  public ngOnDestroy(): void {
    this.bidDetailsNotificationService.clearViewPerspectiveNotification();
  }
}
