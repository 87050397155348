import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';
import { Toast, ToastPackage, ToastrService } from 'ngx-toastr';
import { trigger } from '@angular/animations';

@Component({
  selector: 'qv-update-editing-rights',
  templateUrl: './update-editing-rights.component.html',
  styleUrls: ['./update-editing-rights.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [trigger('flyInOut', [])]
})
export class UpdateEditingRightsComponent extends Toast {
  constructor(
    protected toastrService: ToastrService,
    public toastPackage: ToastPackage,
    private router: Router,
  ) {
    super(toastrService, toastPackage);
  }

  public goToRights(): void {
    this.router.navigate(['/rights'])
      .finally(() => this.remove());
  }
}
