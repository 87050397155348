import { Injectable } from '@angular/core';
import { saveAs } from 'file-saver';
import { Observable } from 'rxjs';
import { File } from '@qv-common/models/core';
import { LegalAttestationDaoService } from '@qv-company/services/dao';
import { map } from 'rxjs/operators';
import { ApiUrlPrefix } from '@qv-common/enums';
import { AuthService } from 'quantuvis-angular-common/auth';

@Injectable()
export class LegalAttestationService {
  public static readonly FILE_UPLOAD_URL = `${ApiUrlPrefix.DOCUMENT_MANAGEMENT}/legal-attestation/upload/`;
  public static readonly FILE_META_URL = `${ApiUrlPrefix.DOCUMENT_MANAGEMENT}/legal-attestation/current/`;

  constructor(
    private authService: AuthService,
    private legalAttestationDaoService: LegalAttestationDaoService,
  ) {}


  public getFileMeta(): Observable<File> {
    const url = this.authService.appendSecureInfoToUrl(LegalAttestationService.FILE_META_URL);
    return this.legalAttestationDaoService.getFileMeta(url);
  }

  public getFile(fileId: number): Observable<ArrayBuffer> {
    const url = this.authService.appendSecureInfoToUrl(
      `${ApiUrlPrefix.DOCUMENT_MANAGEMENT}/documents/${fileId}/download`
    );
    return this.legalAttestationDaoService.getFile(url);
  }

  public exportFile(fileMeta: File): Observable<void> {
    const url = this.authService.appendSecureInfoToUrl(`${ApiUrlPrefix.DOCUMENT_MANAGEMENT}` +
      `/documents/${fileMeta.id}/download`);
    return this.legalAttestationDaoService.exportFile(url).pipe(map((blob: Blob) => {
      saveAs(blob, fileMeta.name);
    }));
  }

  public getUploadUrl(): string {
    return this.authService.appendSecureInfoToUrl(LegalAttestationService.FILE_UPLOAD_URL);
  }
}
