import { DictionaryItem } from 'quantuvis-angular-common/interfaces';

export enum MarketShareTier {
  EMPTY = 88,
  SPECIFIC_UNIT_COUNT,
  MARKET_SHARE_PERCENT,
  MARKET_SHARE_PLUS_PERCENT,
  NATIONAL_MARKET_SHARE_PLUS_PERCENT,
  SPECIFIC_RX_COUNT,
  SPECIFIC_SPEND,
  PERCENT_OF_NATIONAL_MARKET_SHARE
}

export namespace MarketShareTier {
  const displayRangeUnits = new Map<MarketShareTier, string>([
    [MarketShareTier.SPECIFIC_UNIT_COUNT, ''],
    [MarketShareTier.MARKET_SHARE_PERCENT, '%'],
    [MarketShareTier.MARKET_SHARE_PLUS_PERCENT, '%'],
    [MarketShareTier.NATIONAL_MARKET_SHARE_PLUS_PERCENT, '%'],
    [MarketShareTier.SPECIFIC_RX_COUNT, ''],
    [MarketShareTier.SPECIFIC_SPEND, '$'],
    [MarketShareTier.PERCENT_OF_NATIONAL_MARKET_SHARE, '%']
  ]);

  const valuesMap = new Map<MarketShareTier, DictionaryItem>([
    [MarketShareTier.EMPTY, {
      id: MarketShareTier.EMPTY,
      name: ''
    }],
    [MarketShareTier.SPECIFIC_UNIT_COUNT, {
      id: MarketShareTier.SPECIFIC_UNIT_COUNT,
      name: 'Specific Unit Count'
    }],
    [MarketShareTier.MARKET_SHARE_PERCENT, {
      id: MarketShareTier.MARKET_SHARE_PERCENT,
      name: 'Market Share %'
    }],
    [MarketShareTier.MARKET_SHARE_PLUS_PERCENT, {
      id: MarketShareTier.MARKET_SHARE_PLUS_PERCENT,
      name: 'Market Share +%'
    }],
    [MarketShareTier.NATIONAL_MARKET_SHARE_PLUS_PERCENT, {
      id: MarketShareTier.NATIONAL_MARKET_SHARE_PLUS_PERCENT,
      name: 'National Market Share +%'
    }],
    [MarketShareTier.SPECIFIC_RX_COUNT, {
      id: MarketShareTier.SPECIFIC_RX_COUNT,
      name: 'Specific RX Count'
    }],
    [MarketShareTier.SPECIFIC_SPEND, {
      id: MarketShareTier.SPECIFIC_SPEND,
      name: 'Specific Spend ($US)'
    }],
    [MarketShareTier.PERCENT_OF_NATIONAL_MARKET_SHARE, {
      id: MarketShareTier.PERCENT_OF_NATIONAL_MARKET_SHARE,
      name: '% of National Market Share'
    }]
  ]);

  export function displayRangeUnit(marketShareState: MarketShareTier): string {
    return displayRangeUnits.get(marketShareState);
  }

  export function getValue(marketShareState: MarketShareTier): any {
    return valuesMap.get(marketShareState);
  }

  export function getValues(): DictionaryItem[] {
    return Array.from(valuesMap.values());
  }
}
