<section class="qv-sidebar-section">
  <div class="section-toolbar">
    <div class="section-toolbar__title">
      <h2>Legal Attestation</h2>
      <mat-icon
          [matTooltip]="resources.TermsDescriptions.SUMMARY.LEGAL_ATTESTATION"
          [svgIcon]="svgIconName.INFO_OUTLINE">
      </mat-icon>
    </div>
  </div>
  <ul *ngIf="legalAttestation" class="row-items">
    <li class="row-item">
      <h4 class="row-item__title">{{legalAttestation.name}}</h4>
      <div class="row-item__actions">
        <button (click)="downloadLegalAttestationFile()" mat-icon-button>
          <mat-icon [svgIcon]="svgIconName.DOWNLOAD" class="qv-icon qv-icon--18"
                    data-test-id="download-legal-attestation-button"></mat-icon>
        </button>
      </div>
    </li>
  </ul>
</section>
