import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ManageScenarioDependencyModalData } from './manage-scenario-dependency-modal-data';
import {
  ManageScenarioDependencyModalComponent
} from '../manage-scenario-dependency-modal.component';
import { ModalConfig, ModalSize } from 'quantuvis-angular-common/modal';

export class ManageScenarioDependencyModalConfig
  extends ModalConfig<ManageScenarioDependencyModalComponent, ManageScenarioDependencyModalData> {

  constructor(data: ManageScenarioDependencyModalData) {
    super(data, ModalSize.LARGE);
  }

  protected createDialogRef(dialog: MatDialog): MatDialogRef<ManageScenarioDependencyModalComponent> {
    return dialog.open(ManageScenarioDependencyModalComponent, this);
  }
}
