import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {
  BidDeleteDrugModalComponent
} from '@qv-bid/components/shared/bid-delete-drug-modal/bid-delete-drug-modal.component';
import {
  BidDeleteDrugModalData
} from '@qv-bid/components/shared/bid-delete-drug-modal/models/bid-delete-drug-modal-data';
import { ModalConfig, ModalSize } from 'quantuvis-angular-common/modal';

export class BidDeleteDrugModalConfig extends ModalConfig<BidDeleteDrugModalComponent, BidDeleteDrugModalData> {

  constructor(modalData: BidDeleteDrugModalData) {
    super(modalData, ModalSize.LARGE);
  }

  protected createDialogRef(dialog: MatDialog): MatDialogRef<BidDeleteDrugModalComponent> {
    return dialog.open(BidDeleteDrugModalComponent, this);
  }
}
