import { constants } from '@qv-common/static';
import { catchError, tap } from 'rxjs/operators';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { of } from 'rxjs';
import { ApiUrlPrefix } from '@qv-common/enums';

// @ts-ignore
import templateUrl from './people.html';
import { AuthService } from 'quantuvis-angular-common/auth';
import { UserService } from '@qv-common/services';

const PeopleComponent = {
  template: templateUrl,
  bindings: {},
  controller: class {
    public static $inject = [
      '$scope', '$rootScope', 'util', 'router', 'preferences',
      'permissionService', 'authService', 'apiService', 'userService'
    ];
    constructor($scope, $rootScope, util, router, preferences, permissionService,
                authService: AuthService, apiService, userService: UserService) {
      $scope.router = router;
      $scope.allow = permissionService.isAllowed.bind(permissionService);
      $scope.response = $rootScope.response;
      delete $rootScope.response;

      // The request made by the logged in user when trying to retrieve the list of users for People page.
      // The options are: all, admin or member users.
      $scope.requestFor = undefined;

      // The list of users retrieved if any.
      $scope.users = undefined;

      // The info message used when no users found for the logged in user company.
      $scope.infoMessage = undefined;

      // The error message displayed when an error appears.
      $scope.errorMessage = undefined;

      $scope.allowEditUser = permissionService.isAllowed('edit_user');

      if (permissionService.isAllowed('people')) {

        $scope.$watch('requestFor', (requestOption) => {

          preferences.setUserPreference(constants.USER_PREFERENCES_PEOPLE_PAGE_USER_TYPE_OPTION, requestOption);

          const getUsersByTypeURL = `${ApiUrlPrefix.OLD}/users/${userService.getUserType()}/${requestOption}`;
          const resultUrl = `${requestOption === 'all' ? `${getUsersByTypeURL}/false` : getUsersByTypeURL}`;
          apiService.get(resultUrl).pipe(
            tap((response: HttpResponse<any>) => {
              const data = response.body;
              delete $rootScope.response;
              delete $scope.errorMessage;
              delete $scope.infoMessage;
              $scope.users = data.responseObject;

              // If there are no users returned, it means the message for no users found is set on the returned
              // object
              if (util.isNotDefined($scope.users)) {
                $scope.infoMessage = data.message;
              }

              refresh();
            }),
            catchError((error: HttpErrorResponse) => {
              // if error present
              delete $scope.users;
              delete $scope.infoMessage;
              $scope.errorMessage = error.message;

              refresh();

              return of(null);
            })
          ).subscribe();
        });

        // Used to populate the combo box to filter user by: All, User, Administrator
        $scope.userTypes = [
          {
            value: constants.ALL_OPTION.toLowerCase(),
            displayValue: constants.ALL_ROLES_OPTION
          }
        ];

        constants.USER_TYPES.forEach((userType) => {
          $scope.userTypes.push({
            value: userType.value,
            displayValue: userType.displayValue
          });
        });

        $scope.getClass = () => {
          return `card ${!$scope.allowEditUser && 'textCursor'}`;
        };

        $scope.requestFor = preferences.getUserPreference(
          constants.USER_PREFERENCES_PEOPLE_PAGE_USER_TYPE_OPTION,
          constants.ALL_OPTION.toLowerCase()
        );
      }

      $scope.editUser = (user) => {
        if ($scope.allowEditUser) {
          router.navigate(['editUser'], { queryParams: { userId: user.id } });
        }
      };

      $scope.searchFilter = (query) => {
        return (user) => (!query || getUserFullName(user).indexOf(query.toLowerCase()) !== -1);
      };

      $scope.getClassForStatus = (user) => {
        const userStatus = constants.UserStatus;
        let classValue = 'info color ';
        if (user.userStatus === userStatus.DISABLED || user.userStatus === userStatus.UNINVITED) {
          classValue += 'red';
        } else if (user.userStatus === userStatus.ENROLLED) {
          classValue += 'green';
        } else if (user.userStatus === userStatus.INVITED_EXPIRED
          || user.userStatus === userStatus.TEMPORARILY_LOCKED) {
          classValue += 'orange';
        } else {
          classValue += 'purple';
        }

        return classValue;
      };

      function getUserFullName(user): string {
        if (!user) return '';
        return `${user.firstName.toLowerCase()} ${user.lastName.toLowerCase()}`;
      }

      function refresh(): void {
        if (!$scope.$$phase) {
          $scope.$digest();
        }
      }
    }

  }
};
export default PeopleComponent;
