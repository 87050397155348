import { constants, resources } from '@qv-common/static';
import { drugTermsConstants } from '@qv-term/constants';
import { TermName } from '@qv-term/enums';
import { SvgIconName } from '@qv-common/enums';

class DrugLevelController {
  public static $inject = ['translations', 'CopyBidService'];
  private translations;
  private CopyBidService;
  private copyItemOptions;
  private svgIconName;
  private bidConfig;
  private copyHistoricNotes;
  private copyHistoricComments;
  private copyBaseRebate;
  private copyMinBaseRebate;
  private copyMarketShare;
  private removeDismissedScenarios;
  private priceProtectionMetadataStates;
  private priceProtectionSelectedState;
  private allowQuestions;

  constructor(translations, CopyBidService) {
    'ngInject';
    this.translations = translations;
    this.CopyBidService = CopyBidService;
    this.copyItemOptions = [
      { value: true, displayValue: 'Yes' },
      { value: false, displayValue: 'No' }
    ];
    this.svgIconName = SvgIconName;
  }

  public onValid(arg: any): void {}

  public $onInit(): void {
    this.bidConfig = this.CopyBidService.getBidConfig();
    this.copyHistoricNotes = this.copyItemOptions.find(item => item.value === this.bidConfig.copyHistoricNotes);
    this.copyHistoricComments = this.copyItemOptions
      .find(item => item.value === this.bidConfig.copyHistoricComments);
    this.copyBaseRebate = this.copyItemOptions
      .find(item => item.value === this.bidConfig.copyBaseRebate);
    this.copyMinBaseRebate = this.copyItemOptions
      .find(item => item.value === this.bidConfig.copyMinBaseRebate);
    this.copyMarketShare = this.copyItemOptions
      .find(item => item.value === this.bidConfig.copyMarketShare);
    this.removeDismissedScenarios = this.copyItemOptions
      .find(item => item.value === this.bidConfig.removeDismissedScenarios);
    this.translations.buildTranslations(this);
    this.priceProtectionMetadataStates = Object.assign([], constants.PRICE_PROTECTION_METADATA_STATES);
    this.priceProtectionMetadataStates.push({
      name: resources.NewBid.KEEP_ORIGINAL_VALUE,
      state: resources.NewBid.KEEP_ORIGINAL_VALUE
    });
    this.priceProtectionSelectedState =
      this.priceProtectionMetadataStates[this.priceProtectionMetadataStates.length - 1].state;
    this.allowQuestions = this.CopyBidService.getAllowQuestions();
  }

  public $onChanges(changes): void {
    if (changes.runValidation && changes.runValidation.currentValue) {
      this.onValid({ value: this.isValid() });
    }
  }

  public addOrRemovePharmaRight(termAllowed): void {
    this.CopyBidService.addOrRemovePharmaRight(termAllowed);
  }

  public getDrugTermByName(name): any {
    return this.bidConfig.drugTerms.find(search => search.name === name);
  }

  public onCopyHistoricCommentsChange(): void {
    this.bidConfig.copyHistoricComments = this.copyHistoricComments.value;
  }

  public onCopyHistoricNotesChange(): void {
    this.bidConfig.copyHistoricNotes = this.copyHistoricNotes.value;
  }

  public onCopyBaseRebateChange(): void {
    this.bidConfig.copyBaseRebate = this.copyBaseRebate.value;
  }

  public onCopyMinBaseRebateChange(): void {
    this.bidConfig.copyMinBaseRebate = this.copyMinBaseRebate.value;
  }

  public onCopyMarketShareChange(): void {
    this.bidConfig.copyMarketShare = this.copyMarketShare.value;
  }

  public onRemoveDismissedScenariosChange(): void {
    this.bidConfig.removeDismissedScenarios = this.removeDismissedScenarios.value;
  }

  public onPreservePharmaRightsChange(): void {
    if (this.CopyBidService.preserveOriginalPharmaRights) {
      this.allowQuestions.forEach(question => {
        question.checked = false;
        this.CopyBidService.clearPharmaRights();
      });
    } else {
      this.CopyBidService.prepareMetadata();
    }
  }

  public resetValue(): void {
    this.bidConfig.drugTerms.forEach(term => {
      if (term.definition && typeof term.definition.resetValue === 'function') {
        term.definition.resetValue(term);
      }
    });

    const keepOriginalValue = resources.NewBid.KEEP_ORIGINAL_VALUE;
    const keepOrigin = this.priceProtectionMetadataStates.find(state => state.name === keepOriginalValue);
    if (keepOrigin) {
      this.priceProtectionSelectedState = keepOrigin.state;
    }

    const defaultValue = true;
    this.copyHistoricNotes = this.copyItemOptions.find(item => item.value === defaultValue);
    this.copyHistoricComments = this.copyItemOptions.find(item => item.value === defaultValue);
    this.copyBaseRebate = this.copyItemOptions.find(item => item.value === defaultValue);
    this.copyMinBaseRebate = this.copyItemOptions.find(item => item.value === defaultValue);
    this.copyMarketShare = this.copyItemOptions.find(item => item.value === defaultValue);
    this.removeDismissedScenarios = this.copyItemOptions.find(item => item.value === false);
    this.bidConfig.copyHistoricNotes = defaultValue;
    this.bidConfig.copyHistoricComments = defaultValue;
    this.bidConfig.copyBaseRebate = defaultValue;
    this.bidConfig.copyMinBaseRebate = defaultValue;
    this.bidConfig.copyMarketShare = defaultValue;
    this.bidConfig.removeDismissedScenarios = false;
    this.CopyBidService.preserveOriginalPharmaRights = true;
    this.onPreservePharmaRightsChange();
  }

  public setMetadataForPriceProtection(priceProtectionSelectedState): void {
    this.CopyBidService.setMetadataForPriceProtection(priceProtectionSelectedState);
  }

  public isValid(): any {
    if (!(this.bidConfig && this.bidConfig.drugTerms && this.bidConfig.drugTerms.length > 0)) {
      return true;
    }

    return this.validateBaselineStartDate();
  }

  public validateBaselineStartDate(): any {
    return this.CopyBidService.validateTerm(drugTermsConstants[TermName.BASELINE_START_DATE].title, 'drugTerms');
  }
}

export default DrugLevelController;
