import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Bid, Summary } from '@qv-bid/entities';
import { QvCache } from '@qv-common/decorators';
import { UserService } from '@qv-common/services/auth/user.service';

@Component({
  selector: 'qv-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SummaryComponent {
  @Input()
  public bid: Bid;
  @Input()
  public summary: Summary;
  @Input()
  public isEditMode: boolean;
  @Input()
  public isBidInvalid: boolean;
  @Input()
  public isSidebarOpened: boolean;

  constructor(private readonly userService: UserService) {}

  public isCurrentUserPayer(): boolean {
    return this.userService.isCurrentUserPayer();
  }

  @QvCache()
  public isBidInvalidAndSidebarOpened(isBidInvalid: boolean, isSidebarOpened: boolean): boolean {
    return isSidebarOpened && isBidInvalid;
  }

  public isSignatureVisible(): boolean {
    return this.summary && this.bid
      && this.summary.bindingBid && this.summary.bidVersionId
      && this.bid.isFinal && this.bid.hasAllowedStatusForSignature();
  }
}

