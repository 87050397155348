import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { Toast, ToastPackage, ToastrService } from 'ngx-toastr';
import { trigger } from '@angular/animations';

@Component({
  selector: 'qv-filter-notification',
  templateUrl: './filter-notification.component.html',
  styleUrls: ['./filter-notification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [trigger('flyInOut', [])],
})
export class FilterNotificationComponent extends Toast {
  public readonly clearFilterButtonText = 'Reset filters';

  @Output()
  public readonly clearFilters = new EventEmitter();

  constructor(public toastPackage: ToastPackage, protected toastrService: ToastrService) {
    super(toastrService, toastPackage);
  }
}
