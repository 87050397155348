import { SeverityLevel } from '@qv-common/enums';

export class ValidationError {
  public readonly level: SeverityLevel;
  public readonly message: string;

  constructor(level: SeverityLevel, message: string) {
    this.level = level;
    this.message = message;
  }
}
