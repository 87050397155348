import { Injectable } from '@angular/core';
import { NotificationService } from 'quantuvis-angular-common/notification';
import { resources } from '@qv-common/static';
import { BidSelectService, DrugSelectUtilsService } from '@qv-bid/services/selects';
import { Scenario, ScenarioDependency } from '@qv-bid/entities';
import { BidStateService } from '@qv-bid/services/bid-state.service';
import { SnackBarService } from 'quantuvis-angular-common/snack-bar';
import { combineLatest, merge, Observable, of, OperatorFunction, pipe } from 'rxjs';
import { catchError, finalize, map, switchMap, take, tap } from 'rxjs/operators';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { BidVersionDaoService, ScenarioDependencyDaoService } from '@qv-bid/services/dao';
import { HttpErrorResponse } from '@angular/common/http';
import { BidEventBusService } from '@qv-bid/services/bid-event-bus.service';
import {
  ScenarioDependencyModalConfig,
  ScenarioDependencyModalData
} from '@qv-bid/components/shared/scenario-dependency-modal/models';
import { ManageScenarioDependencyModalComponent } from '@qv-bid/components/shared';
import { SelectedDrug } from '@qv-bid/models';
import { PharmaRightsService } from '@qv-bid/services';
import {
  ManageScenarioDependencyModalConfig,
  ManageScenarioDependencyModalData
} from '@qv-bid/components/shared/manage-scenario-dependency-modal/models';
import { ErrorNotificationService } from '@qv-common/services';
import { HttpStatusCode } from 'quantuvis-angular-common/api';
import { ModalService, ModalSize } from 'quantuvis-angular-common/modal';

@Injectable()
export class ScenariosDependencyService {
  @BlockUI()
  public blockUI: NgBlockUI;

  constructor(
    private modalService: ModalService,
    private bidSelectService: BidSelectService,
    private drugSelectUtilsService: DrugSelectUtilsService,
    private bidStateService: BidStateService,
    private bidVersionDaoService: BidVersionDaoService,
    private scenarioDependencyDaoService: ScenarioDependencyDaoService,
    private bidEventBusService: BidEventBusService,
    private snackBarService: SnackBarService,
    private notificationService: NotificationService,
    private pharmaRightsService: PharmaRightsService,
    private errorNotificationService: ErrorNotificationService
  ) {
  }

  public createDependency(): void {
    const selectedScenario: Scenario = this.getSelectedScenario();

    this.getScenariosWithoutDependenciesAndSelf(selectedScenario).pipe(
      take(1),
      switchMap((scenariosWithoutDependencies: Scenario[]) => {
        const createDependencyModal = this.modalService.openModal(new ManageScenarioDependencyModalConfig(
          new ManageScenarioDependencyModalData(
            resources.DRUG_ACTIONS.CREATE_DEPENDENCY,
            selectedScenario.fullName,
            scenariosWithoutDependencies
          ))
        );

        return createDependencyModal.componentInstance.primaryAction.pipe(
          switchMap((selectedScenarios: number[]) => this.createDependencyAction(
            [...selectedScenarios, selectedScenario.id], selectedScenario.cbId
          ).pipe(createDependencyModal.componentInstance.handleAfterAction())),
        );
      })
    ).subscribe();
  }

  public onOpenScenarioDependencyModal(currentScenario: Scenario, isAllowedManageDependency: boolean): void {
    combineLatest([this.bidStateService.isEditMode, this.pharmaRightsService.isAllowDependencies()])
      .pipe(
        take(1),
        switchMap(([isEditMode, isAllowDependenciesRight]: [boolean, boolean]) =>
          this.openScenarioDependencyModal(
            currentScenario, isEditMode && isAllowDependenciesRight && isAllowedManageDependency)
        ),
      )
      .subscribe();
  }

  public isCreateDependencyDisabled(): Observable<boolean> {
    return this.bidSelectService.scenarioSelected().pipe(
      switchMap(() => this.drugSelectUtilsService.getSelectedScenarioCount() === 1
        ? this.checkDependenciesAndDismissed(this.getSelectedScenario()) : of(true)
      ));
  }

  public isSomeSelectedDrugHasDependency(): boolean {
    let selectedDrugsIds = this.bidSelectService.getSelectedScenarioIds();

    selectedDrugsIds = selectedDrugsIds.concat(this.getSelectedNdcsScenarioIds());

    return this.bidStateService.scenarios$.getValue()
      .some(scenario => selectedDrugsIds.includes(scenario.id) && Boolean(scenario.dependencyId));
  }

  private getSelectedNdcsScenarioIds(): number[] {
    return this.bidSelectService.selectedDrugs
      .filter((drug: SelectedDrug) => drug.ndcIds && drug.ndcIds.length > 0)
      .map((drug: SelectedDrug) => drug.scenarioId);
  }

  private openScenarioDependencyModal(currentScenario: Scenario, isAllowManageDependency: boolean): Observable<null | HttpErrorResponse> {
    return this.getDependencyScenariosWithoutSelf(currentScenario).pipe(
      switchMap((scenarios: Scenario[]) => isAllowManageDependency
        ? this.manageDependency(currentScenario, scenarios)
        : of(this.viewDependency(currentScenario, scenarios) as null)
      )
    );
  }

  private viewDependency(currentScenario: Scenario, scenarios: Scenario[]): void {
    this.modalService.openModal(new ScenarioDependencyModalConfig(
      new ScenarioDependencyModalData(currentScenario, scenarios),
      ModalSize.X_SMALL
    ));
  }

  private manageDependency(currentScenario: Scenario, dependencyScenarios: Scenario[]): Observable<null | HttpErrorResponse> {
    return this.getScenariosWithoutOtherDependenciesAndSelf(currentScenario).pipe(
      take(1),
      switchMap((scenariosWithoutDismissed: Scenario[]) => {
        const manageDependencyModal = this.modalService.openModal(new ManageScenarioDependencyModalConfig(
          new ManageScenarioDependencyModalData(
            resources.POPUPS.TITLES.EDIT_DEPENDENCY,
            currentScenario.fullName,
            scenariosWithoutDismissed,
            dependencyScenarios
          ))
        );

        return this.handleManageActions(manageDependencyModal.componentInstance, currentScenario);
      })
    );
  }

  private handleManageActions(componentInstance: ManageScenarioDependencyModalComponent,
                              scenario: Scenario): Observable<null | HttpErrorResponse> {
    return merge(
      componentInstance.deleteAction.pipe(
        switchMap(() => this.deleteDependencyAction(scenario.dependencyId).pipe(componentInstance.handleAfterAction()))
      ),
      componentInstance.primaryAction.pipe(
        switchMap((selectedScenarioIds: number[]) => this.editDependencyAction(
          scenario.dependencyId,
          [...selectedScenarioIds, scenario.id]
        ).pipe(componentInstance.handleAfterAction()))
      )
    );
  }

  private getDependencyScenariosWithoutSelf(currentScenario: Scenario): Observable<Scenario[]> {
    this.blockUI.start();
    return this.bidVersionDaoService.getDependencyListByDependencyIdOfVersion(
      this.bidStateService.bidVersionId, currentScenario.dependencyId
    ).pipe(
      map(({ scenarios }: ScenarioDependency) =>
        scenarios.filter(({ id }: Scenario) => id !== currentScenario.id)),
      catchError((err: HttpErrorResponse) => this.notificationService.showServerError(err)),
      finalize(() => this.blockUI.stop()),
    );
  }

  private getScenariosWithoutDismissedAndSelf(currentScenario: Scenario): Observable<Scenario[]> {
    const { isInternal } = this.bidStateService.bid$.getValue();

    return this.bidVersionDaoService.getDrugScenariosOfVersion(this.bidStateService.bidVersionId, [], isInternal)
      .pipe(
        map((scenarios: Scenario[]) => scenarios.filter((scenario: Scenario) =>
          !scenario.isDrugDismissed() && (scenario.id !== currentScenario.id))
        ),
      );
  }

  private getScenariosWithoutDependenciesAndSelf(currentScenario: Scenario): Observable<Scenario[]> {
    return this.getScenariosWithoutDismissedAndSelf(currentScenario).pipe(
      map((scenarios: Scenario[]) => scenarios.filter((scenario: Scenario) =>
        scenario.isInCb(currentScenario.cbId) && !scenario.dependencyIndex)
      ),
    );
  }

  private getScenariosWithoutOtherDependenciesAndSelf(currentScenario: Scenario): Observable<Scenario[]> {
    return this.getScenariosWithoutDismissedAndSelf(currentScenario).pipe(
      map((scenarios: Scenario[]) => scenarios.filter((scenario: Scenario) =>
        scenario.isInCb(currentScenario.cbId)
        && (!scenario.dependencyIndex || currentScenario.dependencyId === scenario.dependencyId))
      ),
    );
  }

  private checkDependenciesAndDismissed(selectedScenario: Scenario): Observable<boolean> {
    return this.getScenariosWithoutDependenciesAndSelf(selectedScenario).pipe(
      map((scenariosWithoutDependencies: Scenario[]) => !(
        !!(scenariosWithoutDependencies.length)
        && !selectedScenario.dependencyIndex
        && !selectedScenario.isDrugDismissed()
      ))
    );
  }

  private createDependencyAction(selectedScenarios: number[], cbId: number): Observable<null | HttpErrorResponse> {
    this.snackBarService.start();

    const bid = this.bidStateService.bid$.getValue();

    return this.scenarioDependencyDaoService.create(
      this.bidStateService.bidVersionId,
      cbId,
      selectedScenarios,
      bid.isInternal
    ).pipe(this.reloadBidAndHandleError());
  }

  private deleteDependencyAction(dependencyId: number): Observable<null | HttpErrorResponse> {
    this.snackBarService.start();

    const bid = this.bidStateService.bid$.getValue();

    return this.scenarioDependencyDaoService.delete(
      this.bidStateService.bidVersionId,
      dependencyId,
      bid.isInternal
    ).pipe(this.reloadBidAndHandleError());
  }

  private editDependencyAction(dependencyId: number, selectedScenarioIds: number[]): Observable<null | HttpErrorResponse> {
    this.snackBarService.start();

    const bid = this.bidStateService.bid$.getValue();

    return this.scenarioDependencyDaoService.update(
      this.bidStateService.bidVersionId,
      dependencyId,
      selectedScenarioIds,
      bid.isInternal
    ).pipe(this.reloadBidAndHandleError());
  }

  private reloadBidAndHandleError<T>(): OperatorFunction<T, T | HttpErrorResponse> {
    return pipe(
      tap(() => {
        this.snackBarService.finish();
        this.bidEventBusService.undoRedoEvent.emit();
        this.bidEventBusService.reloadBidEvent.emit();
      }),
      catchError((error: HttpErrorResponse) => {
        this.snackBarService.error();
        this.bidEventBusService.updateBidFailed.emit(error);

        return this.errorNotificationService.catchErrorExceptOfStatus(error, HttpStatusCode.FORBIDDEN);
      })
    );
  }

  private getSelectedScenario(): Scenario {
    return this.drugSelectUtilsService.getSelectedScenarios(this.bidStateService.scenarios$.getValue())[0];
  }
}
