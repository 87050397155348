import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BlockUIModule } from 'ng-block-ui';

import { GeneralModalComponent } from './components/general-modal/general-modal.component';
import { TemplateModalComponent } from './components/template-modal/template-modal.component';
import { TemplateFormModalComponent } from './components/template-form-modal/template-form-modal.component';
import { ModalService } from './services/modal.service';
import { MatCheckboxModule } from '@angular/material/checkbox';

@NgModule({
  imports: [
    BlockUIModule,
    CommonModule,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    ReactiveFormsModule,
    MatTooltipModule,
    MatCheckboxModule
  ],
  declarations: [
    GeneralModalComponent,
    TemplateModalComponent,
    TemplateFormModalComponent,
  ],
  exports: [
    GeneralModalComponent,
    TemplateModalComponent,
    TemplateFormModalComponent,
  ],
  providers: [
    ModalService
  ],
})
export class ModalModule {}
