import { Inject, Injectable } from '@angular/core';
import { JsonConvert } from 'json2typescript';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiUrlPrefix } from '@qv-common/enums';
import { JSON_CONVERT_TOKEN } from 'quantuvis-angular-common/json-converter/services';
import { ApiService } from '@qv-common/services/api';
import { HttpResponseWrapper } from '@qv-common/models/api';
import { InternalField } from '@qv-internal-fields-common/entities';

@Injectable()
export class InternalFieldsDaoService {

  constructor(private apiService: ApiService, @Inject(JSON_CONVERT_TOKEN) private jsonConvert: JsonConvert) {}

  public getList(bidId: number): Observable<InternalField[]> {
    const url = `${ApiUrlPrefix.BIDS}/${bidId}/internal-fields`;

    return this.apiService.get(url).pipe(
      map((response: HttpResponseWrapper<InternalField>) =>
        this.jsonConvert.deserializeArray(response.body.data, InternalField)
      ));
  }

  public update(bidId: number, field: InternalField): Observable<unknown> {
    const url = `${ApiUrlPrefix.BIDS}/${bidId}/internal-fields/${field.id}`;

    return this.apiService.put(url, field);
  }
}
