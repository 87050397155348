import { JsonConverter, JsonCustomConvert } from 'json2typescript';
import { StringUtils } from '@qv-common/utils';

@JsonConverter
export class NumberConverter implements JsonCustomConvert<number | string> {
  public serialize(value: number | string): any {
    return value;
  }

  public deserialize(value: any): number | string {
    if (isNaN(parseFloat(value))) {
      return StringUtils.isString(value) ? value : null;
    } else {
      return parseFloat(value);
    }
  }
}
