<form (ngSubmit)="onSubmit()" [formGroup]="rangeForm" autocomplete="off" class="range-form">
  <div class="input">
    <input
        (click)="pickerFrom.open()"
        (dateChange)="onDateChanged($event, 'from')"
        [class.input__field--error]="rangeForm.get('from').invalid"
        [matDatepicker]="pickerFrom"
        [matTooltipDisabled]="rangeForm.get('from').valid"
        [matTooltip]="getValidationMessage(rangeForm.get('from').errors)"
        class="input__field"
        formControlName="from"
        matInput
        placeholder="From"
    >
    <mat-datepicker #pickerFrom class="input__picker"></mat-datepicker>
    <mat-datepicker-toggle [for]="pickerFrom" class="input__prefix" matPrefix></mat-datepicker-toggle>
  </div>
  <div class="separator">&ndash;</div>
  <div class="input">
    <input
        (click)="pickerTo.open()"
        (dateChange)="onDateChanged($event, 'to')"
        [class.input__field--error]="rangeForm.get('to').invalid"
        [matDatepicker]="pickerTo"
        [matTooltipDisabled]="rangeForm.get('to').valid"
        [matTooltip]="getValidationMessage(rangeForm.get('to').errors)"
        class="input__field"
        formControlName="to"
        matInput
        placeholder="To"
    >
    <mat-datepicker #pickerTo class="input__picker"></mat-datepicker>
    <mat-datepicker-toggle [for]="pickerTo" class="input__prefix" matPrefix></mat-datepicker-toggle>
  </div>
  <button *ngIf="!hideSubmitButton"
          [disabled]="rangeForm.invalid"
          class="qv-button qv-button--primary"
          mat-button
          type="submit"
  >{{ resources.Actions.OK }}</button>
</form>

