import { Inject, Injectable } from '@angular/core';
import { JsonConvert } from 'json2typescript';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpResponseWrapper } from '@qv-common/models/api';
import { JSON_CONVERT_TOKEN } from 'quantuvis-angular-common/json-converter/services';
import { ApiService } from '@qv-common/services/api';
import { Contract } from '@qv-bid/entities';
import { ViewPerspectiveService } from '@qv-common/services/auth';
import { HttpUtils } from '@qv-common/utils';
import { ApiUrlPrefix } from '@qv-common/enums';

@Injectable()
export class ContractDaoService {

  constructor(
    private apiService: ApiService,
    @Inject(JSON_CONVERT_TOKEN) private jsonConvert: JsonConvert,
    private viewPerspectiveService: ViewPerspectiveService
  ) {}

  public update(
    scenarioId: number,
    contract: Contract,
    isBidInternal: boolean,
    drugId?: number,
  ): Observable<Contract> {
    const url = this.getUrl(scenarioId, isBidInternal, drugId);

    return this.apiService.put(url, contract).pipe(
      map((response: HttpResponseWrapper<Contract>) => this.jsonConvert.deserializeObject(response.body.data, Contract))
    );
  }

  private getUrl(
    scenarioId: number,
    isBidInternal: boolean,
    drugId?: number,
  ): string {
    const perspective = this.viewPerspectiveService.getViewPerspective();
    let url = drugId
      ? `${ApiUrlPrefix.BIDS}/scenarios/${scenarioId}/drugs/${drugId}/contract`
      : `${ApiUrlPrefix.BIDS}/scenarios/${scenarioId}/contract`;

    url = HttpUtils.appendPerspectiveQueryParamToUrlIfBidIsInternal(url, perspective, isBidInternal);

    return url;
  }
}
