import { RowState } from '@qv-common/enums';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export class ScenarioStateConfig {
  public isExpanded$ = new BehaviorSubject<boolean>(false);
  public isNdcsLoading$ = new BehaviorSubject<boolean>(false);
  public isFirstScenarioOfDrugGroup$ = new BehaviorSubject<boolean>(false);
  public isLastScenarioOfDrugGroup$ = new BehaviorSubject<boolean>(false);

  public set isExpanded(value: boolean) {
    this.isExpanded$.next(value);
  }

  public toggleExpand(): void {
    this.isExpanded$.next(!this.isExpanded$.getValue());
  }

  public getRowState(): Observable<RowState> {
    return this.isExpanded$.pipe(
      map((isExpanded: boolean) => isExpanded ? RowState.EXPANDED : RowState.COLLAPSED)
    );
  }

  // eslint-disable-next-line no-empty-function
  public destroy(): void {}
}
