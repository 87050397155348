<section *ngIf="isReady" class="internal-fields qv-sidebar-section">
  <div *ngIf="internalFields.length" class="internal-fields__content">
    <p class="internal-fields__info">
      The following fields can be edited at any time.
      They are for internal uses and only available to you and your team.
      Your trading partner will never see these.
    </p>
    <div class="internal-fields__controls">
      <qv-dynamic-internal-field
          (onChange)="onChangeInternalField($event)"
          *ngFor="let group of internalFieldsFormArray.controls; trackBy: trackByFn; index as i"
          [internalFieldFormGroup]="$any(group)"
          [internalField]="internalFields[i]"
      ></qv-dynamic-internal-field>
    </div>
  </div>
  <div *ngIf="!internalFields.length" class="internal-fields__no-data">
    {{ commonMessage.NO_DATA }}
  </div>
</section>
<qv-loading-ring *ngIf="!isReady"></qv-loading-ring>
