import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { filter } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SvgIconName } from '@qv-common/enums';
import { InternalField } from '@qv-internal-fields-common/entities';
import { BaseInternalField } from '@qv-internal-fields/classes';

@UntilDestroy()
@Component({
  selector: 'qv-text-internal-field',
  templateUrl: './text-internal-field.component.html',
  styleUrls: ['./text-internal-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TextInternalFieldComponent extends BaseInternalField implements OnInit {
  public readonly svgIconName = SvgIconName;

  public ngOnInit(): void {
    this.initFormGroupValueChanges();
  }

  private initFormGroupValueChanges(): void {
    this.internalFieldFormGroup.valueChanges
      .pipe(
        filter(() => this.internalFieldFormGroup.valid),
        untilDestroyed(this)
      )
      .subscribe((field: InternalField) => this.onChange.emit(
        Object.assign(this.internalField, field)
      ));
  }
}
