/**
 * ObjectFilter implementation. At the moment the filter is used in bids list table.
 * Requires - AngularJS $filter
 *
 * Filter parameters:
 *  items - array of object to filter
 *  searchValue - search value for filter
 *  key - name of the key to apply the filter
 */
export const ObjectFilter = (): any => (items, searchValue, key) => items.filter((item) => item[key] === searchValue);

