import { Inject, Injectable } from '@angular/core';
import { BidListFilterState, BidListRemoteState } from '@qv-bid-list/models';
import { JsonConvert } from 'json2typescript';
import { Observable } from 'rxjs';
import { FilterOption, PaginatedResponseWrapper } from '@qv-common/entities';
import { Bid } from '@qv-bid/entities';
import { HttpParams, HttpResponse } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { JSON_CONVERT_TOKEN } from 'quantuvis-angular-common/json-converter/services';
import { ApiService } from '@qv-common/services/api';
import { BidListTableColumnName } from '@qv-bid-list/enums';
import { HttpResponseWrapper } from '@qv-common/models/api';
import { ApiUrlPrefix } from '@qv-common/enums';

@Injectable()
export class BidListDaoService {
  constructor(private apiService: ApiService, @Inject(JSON_CONVERT_TOKEN) private jsonConvert: JsonConvert) { }

  public getBids({ page, size, sort, filter }: BidListRemoteState): Observable<PaginatedResponseWrapper<Bid>> {
    let params = new HttpParams()
      .append('page', String(page))
      .append('size', String(size));

    if (sort) {
      params = params.append('sort', `${sort.active},${sort.direction}`);
    }

    return this.apiService.post(`${ApiUrlPrefix.BIDS}/search`, filter || {}, { params }).pipe(
      map((response: HttpResponse<any>) => {
        const { content, totalElements } = response.body.data;
        const bids = this.jsonConvert.deserializeArray<Bid>(content, Bid);

        return new PaginatedResponseWrapper<Bid>(bids, totalElements);
      }));
  }

  public updateFilterOptions(name: BidListTableColumnName, state: BidListFilterState): Observable<FilterOption[]> {
    return this.apiService.post(`${ApiUrlPrefix.BIDS}/filter-options/${name}`, state || {}).pipe(
      map((response: HttpResponseWrapper<FilterOption[]>) =>
        this.jsonConvert.deserializeArray(response.body.data, FilterOption)
      ));
  }
}
