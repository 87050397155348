import { SnackBarMessage } from '../enums/snack-bar-message';
import { SnackBarData } from '../models/snack-bar-config';

export const errorSnackData = new SnackBarData(
  'snack-bar--failed',
  SnackBarMessage.NOT_SAVED,
  'icon-error-filled'
);

export const pendingSnackData = new SnackBarData(
  'snack-bar--pending',
  SnackBarMessage.SAVING,
  'snack-bar__icon--pending'
);

export const successSnackData = new SnackBarData(
  'snack-bar--success',
  SnackBarMessage.SAVED,
  'icon-check-filled'
);
