import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { HttpErrorResponse } from '@angular/common/http';
import { BidEventBusService } from '@qv-bid/services';
import { ErrorNotificationService } from '@qv-common/services';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { HttpStatusCode } from 'quantuvis-angular-common/api';
import { SnackBarService } from 'quantuvis-angular-common/snack-bar';
import { catchError, finalize, tap } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { BlockingMessage, SvgIconName } from '@qv-common/enums';
import { resources } from '@qv-common/static';
import { BidDeleteDrugModalData } from '@qv-bid/components/shared/bid-delete-drug-modal/models/bid-delete-drug-modal-data';
import { BidDeleteDrugCbMeta } from '@qv-bid/components/shared/bid-delete-drug-modal/models/bid-delete-drug-cb-meta';
import { DrugCb, Scenario } from '@qv-bid/entities';
import { DrugDaoService } from '@qv-bid/services/dao';

@Component({
  selector: 'qv-bid-delete-drug-modal',
  templateUrl: './bid-delete-drug-modal.component.html',
  styleUrls: ['./bid-delete-drug-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BidDeleteDrugModalComponent implements OnInit {
  @BlockUI()
  public blockUI: NgBlockUI;

  @ViewChild(MatSort, { static: true })
  public sort: MatSort;

  public drugs: MatTableDataSource<BidDeleteDrugCbMeta>;
  public isDeleteDrugFromRfpSelected = true;
  public errorMessage = null;

  public readonly displayedColumns = ['drugName', 'cbName'];
  public readonly svgIconName = SvgIconName;
  public readonly resources = resources;

  constructor(
    @Inject(MAT_DIALOG_DATA) public modalData: BidDeleteDrugModalData,
    public dialogRef: MatDialogRef<BidDeleteDrugModalComponent>,
    private drugDaoService: DrugDaoService,
    private errorNotificationService: ErrorNotificationService,
    private snackBarService: SnackBarService,
    private changeDetectorRef: ChangeDetectorRef,
    private bidEventBusService: BidEventBusService
  ) {}

  public ngOnInit(): void {
    this.initDrugs();
  }

  public onToggleDrugSelection(drug: BidDeleteDrugCbMeta, $event: MouseEvent): void {
    $event.preventDefault();

    drug.isSelected = !drug.isSelected;

    if (this.isDeleteDrugFromRfpSelected) {
      this.toggleDrugSelectionForAllCbs(drug);
    }
  }

  public onToggleDeleteDrugFromRfpSelection(): void {
    this.isDeleteDrugFromRfpSelected = !this.isDeleteDrugFromRfpSelected;

    if (this.isDeleteDrugFromRfpSelected) {
      this.selectDrugsForAllCbs();
    }
  }

  public onClearAll(): void {
    this.drugs.data.forEach((drug: BidDeleteDrugCbMeta) => drug.isSelected = false);
  }

  public onFilter(value: string): void {
    this.drugs.filter = value.trim().toLowerCase();
  }

  public hasDrugsInFilteredList(): boolean {
    return this.drugs.filteredData.length > 0;
  }

  public onDeleteDrugs(): void {
    this.errorMessage = null;

    const selectedDrugs = this.drugs.data.filter((drug: BidDeleteDrugCbMeta) => drug.isSelected);

    if (!selectedDrugs.length) return;

    if (!this.shouldAllowDeleteDrugs(selectedDrugs)) {
      this.errorMessage = resources.BID_DETAILS.DELETE_ALL_DRUGS_ERROR;
      return;
    }

    this.blockUI.start(BlockingMessage.DELETING);
    this.snackBarService.start();

    this.drugDaoService
      .deleteDrugs(this.modalData.bidVersionId, selectedDrugs, this.modalData.isBidInternal)
      .pipe(
        tap(() => {
          this.snackBarService.finish();
          this.dialogRef.close(selectedDrugs);
        }),
        catchError((error: HttpErrorResponse) => {
          this.bidEventBusService.updateBidFailed.emit(error);
          this.snackBarService.error();

          if (error.status === HttpStatusCode.CONFLICT) {
            this.errorMessage = error.error.message;
          } else {
            this.errorNotificationService.catchErrorExceptOfStatus(error, HttpStatusCode.FORBIDDEN);
          }

          return throwError(error);
        }),
        finalize(() => {
          this.changeDetectorRef.markForCheck();
          this.blockUI.stop();
        }),
      ).subscribe();
  }

  private initDrugs(): void {
    this.drugs = new MatTableDataSource(this.modalData.drugs.map((drug: DrugCb) => new BidDeleteDrugCbMeta(drug)));
    this.drugs.sort = this.sort;
    this.autoSelectDrugs();
  }

  private autoSelectDrugs(): void {
    if (!this.modalData.selectedScenarios.length) return;

    this.drugs.data.forEach((drug: BidDeleteDrugCbMeta) => {
      if (this.shouldAutoSelectDrug(drug)) {
        drug.isSelected = true;
      }
    });

    if (this.isDeleteDrugFromRfpSelected) {
      this.selectDrugsForAllCbs();
    }
  }

  private shouldAutoSelectDrug(drug: BidDeleteDrugCbMeta): boolean {
    return this.modalData.selectedScenarios.some((scenario: Scenario) =>
      scenario.drugName === drug.drugName && scenario.cbName === drug.cbName);
  }

  private toggleDrugSelectionForAllCbs(currentDrug: BidDeleteDrugCbMeta): void {
    this.drugs.data.forEach((drug: BidDeleteDrugCbMeta) => {
      if (drug.drugName === currentDrug.drugName && drug.cbName !== currentDrug.cbName) {
        drug.isSelected = currentDrug.isSelected;
      }
    });
  }

  private selectDrugsForAllCbs(): void {
    const selectedDrugNames = this.getSelectedDrugNames();

    this.drugs.data.forEach((drug: BidDeleteDrugCbMeta) => {
      if (!drug.isSelected && selectedDrugNames.includes(drug.drugName)) {
        drug.isSelected = true;
      }
    });
  }

  private getSelectedDrugNames(): string[] {
    const selectedDrugNames = this.drugs.data
      .filter((drug: BidDeleteDrugCbMeta) => drug.isSelected)
      .map((drug: BidDeleteDrugCbMeta) => drug.drugName);

    return Array.from(new Set(selectedDrugNames)); // unique drug names
  }

  private shouldAllowDeleteDrugs(selectedDrugs: BidDeleteDrugCbMeta[]): boolean {
    const uniqueSelectedCbNames = Array.from(new Set(selectedDrugs.map((drug: BidDeleteDrugCbMeta) => drug.cbName)));

    // allow if all drugs for selected cbNames have at least one drug that is not selected
    return uniqueSelectedCbNames.every((cbName: string) =>
      this.drugs.data
        .filter((drug: BidDeleteDrugCbMeta) => drug.cbName === cbName) // get drugs for specific cbName
        .some((drug: BidDeleteDrugCbMeta) => !drug.isSelected) // check that at least one drug is not selected
    );
  }
}
