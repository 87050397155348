<section *blockUI="'general-modal'" class="qv-modal-dialog">
  <header class="qv-modal-dialog-title" mat-dialog-title>
    <h1 class="qv-modal-dialog-title__text">{{ title }}</h1>
    <button (click)="onSecondaryAction()" class="qv-modal-dialog-title__xbutton">
      <mat-icon>close</mat-icon>
    </button>
  </header>
  <div class="qv-modal-dialog-content qv-modal-dialog-content--primary-padding qv-modal-dialog-content--font-light">
    <div class="qv-modal-dialog-content__title">
      Is this your <span class="qv-modal-dialog-content__title-suffix--bold">Best and Final</span>* bid?
    </div>
    <mat-radio-group [formControl]="control">
      <mat-radio-button [value]="true">Yes</mat-radio-button>
      <mat-radio-button [value]="false">No</mat-radio-button>
    </mat-radio-group>
    <div class="qv-modal-dialog-content__description">
      <ng-container *ngIf="modalData.isBinding">
        * Best and Final bids are legally Binding and are required to<br/>be sent by the RFP Due Date
      </ng-container>
      <ng-container *ngIf="!modalData.isBinding">
        * Best and Final bids are required to be sent by the RFP Due Date
      </ng-container>
    </div>
  </div>
  <footer class="qv-modal-dialog-actions qv-modal-dialog-actions--padding-sm" mat-dialog-actions>
    <button (click)="onSecondaryAction()" class="qv-modal-dialog-actions__item" mat-button>
      Cancel
    </button>
    <button (click)="onPrimaryAction()" class="qv-modal-dialog-actions__item qv-modal-dialog-actions__item--primary"
            mat-button>
      {{ title }}
    </button>
  </footer>
</section>
