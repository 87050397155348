import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { BidService } from '@qv-bid/services';
import { Bid } from '@qv-bid/entities';
import { PermissionService } from '@qv-common/services/auth/permission.service';
import { NotificationService } from 'quantuvis-angular-common/notification';
import { HttpErrorResponse } from '@angular/common/http';
import { HttpStatusCode } from 'quantuvis-angular-common/api';

@Injectable()
export class ArchivedBidGuard implements CanActivate {

  public readonly bidRetrievingErrorMsg = 'There was an error while retrieving bid data. Please try again later.';

  constructor(
    private bidService: BidService,
    private notificationService: NotificationService,
    private permissionService: PermissionService,
    private router: Router
  ) {}

  public canActivate(next: ActivatedRouteSnapshot): Observable<boolean> {
    const { bidId, perspective } = next.queryParams;

    return this.bidService.getById(bidId, perspective)
      .pipe(
        map((bid: Bid) => {
          if (this.permissionService.canAccessArchived(bid.isArchived)) {
            return true;
          } else {
            this.router.navigate(['/quantuvisplus'], { queryParams: { fromArchived: 1 } });
            return false;
          }
        }),
        catchError((error: HttpErrorResponse) => {
          if (error.status !== HttpStatusCode.UNAUTHORIZED) {
            this.notificationService.error(this.bidRetrievingErrorMsg);
          }
          return throwError(this.bidRetrievingErrorMsg);
        })
      );
  }
}
