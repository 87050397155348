import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('PaginatedResponseWrapper')
export class PaginatedResponseWrapper<T> {
  @JsonProperty('content')
  public data: T[] = [];
  @JsonProperty('totalElements', Number)
  public totalCount: number = null;

  constructor(data: T[], totalCount: number) {
    this.data = data;
    this.totalCount = totalCount;
  }
}
