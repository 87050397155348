import { Injectable } from '@angular/core';
import { SnackBarService } from 'quantuvis-angular-common/snack-bar';
import { Observable, OperatorFunction, pipe, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { BidSelectService, DrugSelectUtilsService } from '@qv-bid/services/selects';
import { BidEventBusService } from '@qv-bid/services/bid-event-bus.service';
import { ScenarioDaoService } from '@qv-bid/services/dao';
import { SelectedScenarios } from '@qv-bid/models';
import { BidStateService } from '@qv-bid/services';
import { ScenariosDependencyService } from '@qv-bid/services/scenarios-dependency.service';
import { NotificationService } from 'quantuvis-angular-common/notification';
import { BidErrorResponseStatusUtil } from '@qv-bid/utils/bid-error-response-status.util';

@Injectable()
export class DrugReviewActionsService {

  constructor(
    private bidSelectService: BidSelectService,
    private scenarioDaoService: ScenarioDaoService,
    private bidStateService: BidStateService,
    private bidEventBusService: BidEventBusService,
    private notificationService: NotificationService,
    private scenariosDependencyService: ScenariosDependencyService,
    private snackBarService: SnackBarService,
    private drugSelectUtilsService: DrugSelectUtilsService
  ) {}

  public actionsIsDisabled(): Observable<boolean> {
    return this.bidSelectService.allScenariosSelected().pipe(
      map((bidSelected: boolean) => !(bidSelected || this.drugSelectUtilsService.getSelectedScenarioCount()))
    );
  }

  public acceptDrugs(): void {
    this.snackBarService.start();

    const bid = this.bidStateService.bid$.getValue();

    this.scenarioDaoService.acceptInReview(
      this.bidStateService.bidVersionId,
      [new SelectedScenarios(this.bidStateService.cbId, this.bidSelectService.getSelectedScenarioIds())],
      bid.isInternal
    )
      .pipe(this.afterAction())
      .subscribe(() => null, () => null);
  }

  public dismissDrugs(): void {
    this.snackBarService.start();

    const bid = this.bidStateService.bid$.getValue();

    this.scenarioDaoService.dismissInReview(
      this.bidStateService.bidVersionId,
      [new SelectedScenarios(this.bidStateService.cbId, this.bidSelectService.getSelectedScenarioIds())],
      bid.isInternal
    )
      .pipe(this.reloadDismissedScenarios())
      .subscribe(() => null, () => null);
  }

  public undoDrugs(): void {
    this.snackBarService.start();

    const bid = this.bidStateService.bid$.getValue();

    this.scenarioDaoService.undoInReview(
      this.bidStateService.bidVersionId,
      [new SelectedScenarios(this.bidStateService.cbId, this.bidSelectService.getSelectedScenarioIds())],
      bid.isInternal
    )
      .pipe(this.afterAction())
      .subscribe(() => null, () => null);
  }

  private afterAction<T>(): OperatorFunction<T, T> {
    return pipe(
      tap(() => {
        this.snackBarService.finish();
        this.bidEventBusService.undoRedoEvent.emit();
        this.bidEventBusService.reloadScenariosEvent.emit(this.bidSelectService.scenarioSelection.selected);
        this.bidEventBusService.reloadLoadedNdcsEvent.emit(this.bidSelectService.scenarioSelection.selected);
        this.bidSelectService.clear();
      }),
      catchError((err: HttpErrorResponse) => {
        this.snackBarService.error();
        return BidErrorResponseStatusUtil.isShowErrorInNotification(err) ?
          this.notificationService.showServerError(err) :
          throwError(err);
      })
    );
  }

  private reloadAllScenarios<T>(): OperatorFunction<T, T> {
    return pipe(
      tap(() => {
        this.snackBarService.finish();
        this.bidSelectService.clear();
        this.bidEventBusService.undoRedoEvent.emit();
        this.bidEventBusService.loadScenariosEvent.emit();
      }),
      catchError((err: HttpErrorResponse) => {
        this.snackBarService.error();
        return BidErrorResponseStatusUtil.isShowErrorInNotification(err) ?
          this.notificationService.showServerError(err) :
          throwError(err);
      })
    );
  }

  private reloadDismissedScenarios<T>(): OperatorFunction<T, T> {
    if (this.scenariosDependencyService.isSomeSelectedDrugHasDependency()) {
      return this.reloadAllScenarios();
    }

    return this.afterAction();
  }
}
