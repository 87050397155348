import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { UmDetailsModalConfig, UmDetailsModalData } from '@qv-term/components/um-details-modal/models';
import { QvCache } from '@qv-common/decorators';
import { constants, resources } from '@qv-common/static';
import { SvgIconName } from '@qv-common/enums';
import { NdcValue } from '@qv-term/entities';
import { BaseTermComponent } from '../base-term/base-term.component';
import { TermTemplateStorageService } from '@qv-term/services';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ModalService } from 'quantuvis-angular-common/modal';

@UntilDestroy()
@Component({
  selector: 'qv-term-um-details',
  templateUrl: './term-um-details.component.html',
  styleUrls: ['./term-um-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TermUmDetailsComponent extends BaseTermComponent {
  @Input()
  public termValue: NdcValue<string>;

  public readonly svgIconName = SvgIconName;
  public readonly AT_NDC = constants.AT_NDC;

  constructor(protected termTemplateStorage: TermTemplateStorageService,
              private modalService: ModalService, changeDetectorRef: ChangeDetectorRef) {
    super(termTemplateStorage, changeDetectorRef);
  }

  @QvCache()
  public getTooltipMessage(isEmptyValue: boolean): string {
    return isEmptyValue ? resources.TermsDescriptions.DRUG_LIST.EMPTY_UM_DETAILS_TOOLTIP : '';
  }

  @QvCache()
  public isButtonDisabled(isEmptyValue: boolean, isEditMode: boolean, isPending: boolean): boolean {
    return (isEmptyValue && !isEditMode) || (isPending && isEmptyValue);
  }

  @QvCache()
  public isEmptyValue(value: string): boolean {
    return !(value && value.length);
  }

  public transferToEditMode(): void {
    this.openModal();
  }

  public isEditingAvailable(): boolean {
    return this.isEditMode &&
      !this.isLocked &&
      !this.isPending;
  }

  public openModal(): void {
    const modal = this.modalService.openModal(new UmDetailsModalConfig(new UmDetailsModalData(
      this.getTermValue(), this.isEditingAvailable()
    )));
    modal.componentInstance.primaryAction
      .pipe(untilDestroyed(this))
      .subscribe((value: string) => {
        if (this.control) {
          this.control.setValue(value);
        }
      });
    modal.afterClosed().subscribe(() => this.exitFromEditModeEvent.emit());
  }
}
