<ng-container *ngIf="(isFormReady$ | async) || !isEditMode && entity">
  <div class="section-toolbar">
    <div class="section-toolbar__title">
      <h2>No Fault Termination Clause</h2>
    </div>
    <qv-lock
        [control]="$any(formGroup?.get(termName.getTermNameLock(sectionName)))"
        [isDisabled]="isBidInvalid"
        [isEditMode]="isEditMode"
        [locked]="entity?.noFaultTerminationClauseLock"
        data-test-id="test-id-contract-administration-fee"
    ></qv-lock>
  </div>

  <section class="table-section table-section--two-equal-columns table-section--striped">
    <qv-table-section-row [termName]="termName.NO_FAULT_TERMINATION_CLAUSE_WHO_MAY_INVOKE"
                          class="table-section__row"
                          data-test-id="test-id-contract-who-may-invoke">
      <qv-term-select
          [control]="$any(formGroup?.get(termName.NO_FAULT_TERMINATION_CLAUSE_WHO_MAY_INVOKE))"
          [isEditMode]="isEditMode"
          [isLocked]="entity?.noFaultTerminationClauseLock"
          [termName]="termName.NO_FAULT_TERMINATION_CLAUSE_WHO_MAY_INVOKE"
          [termValue]="getWhoMayInvokeValue(entity?.whoMayInvoke)"
      ></qv-term-select>
    </qv-table-section-row>

    <qv-table-section-row [termName]="termName?.NO_FAULT_TERMINATION_CLAUSE_PRIOR_NOTICE_REQUIRED"
                          class="table-section__row table-section__row--align-baseline"
                          data-test-id="test-id-contract-prior-notice-required">
      <qv-term-input [class.table-section__cell--error]="formGroup?.get(termName.NO_FAULT_TERMINATION_CLAUSE_PRIOR_NOTICE_REQUIRED)?.errors"
                     [control]="$any(formGroup?.get(termName.NO_FAULT_TERMINATION_CLAUSE_PRIOR_NOTICE_REQUIRED))"
                     [isEditMode]="isEditMode"
                     [isLocked]="entity?.noFaultTerminationClauseLock"
                     [numberOptions]="intNumberOptions"
                     [termName]="termName?.NO_FAULT_TERMINATION_CLAUSE_PRIOR_NOTICE_REQUIRED"
                     [termValue]="entity?.terminationClausePriorNoticeRequired"
                     class="table-section__cell table-section__cell--inline"
                     termSuffix=""
      ></qv-term-input>
      <acc-form-field-errors
          *ngIf="isEditMode"
          [control]="formGroup?.get(termName.NO_FAULT_TERMINATION_CLAUSE_PRIOR_NOTICE_REQUIRED)"
          [validationMessages]="validationMessages"
          class="table-section__error"
      ></acc-form-field-errors>
      <div class="table-section__cell">
        <div class="table-section__suffix">{{units.days}}</div>
      </div>
    </qv-table-section-row>
  </section>
</ng-container>
