import { SelectBoxTerm } from '@qv-term/models/types';
import { constants, resources } from '@qv-common/static';
import { TierPlacement } from 'quantuvis-core-entities';

export class TierPlacementTerm extends SelectBoxTerm {
  constructor(name: string) {
    super(
      name,
      name,
      resources.TermsDescriptions.DRUG_LIST.TIER_PLACEMENT,
      constants.DRUG_TERM_PATHS.ALLOW_TIERS[1],
      '',
      TierPlacement.getValue(TierPlacement.EMPTY),
      'term__tier-placement'
    );
    this.values = TierPlacement.getValues();
  }
}
