import { Inject, Injectable } from '@angular/core';
import { LocalStorageService } from 'ngx-webstorage';
import { combineLatest, from, Observable, throwError, timer } from 'rxjs';
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';
import { AuthConfig, OAuthEvent, OAuthService } from 'angular-oauth2-oidc';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { HISTORY_TOKEN, LOCATION_TOKEN, NavigationUtils } from 'quantuvis-angular-common/window-utils';
import { authTimeoutFactorRange, sessionIdParamConfig } from '../constants/auth.constants';
import { AuthEventCode } from '../enums/auth-event-code.enum';
import { AUTH_CONFIG_TOKEN } from '../tokens/auth-config.token';
import { HttpHeader } from 'quantuvis-angular-common/api';
import { NavigationService } from 'quantuvis-angular-common/navigation';

@UntilDestroy()
@Injectable()
export class AuthService {
  public static readonly CLIENT_TOKEN_KEY = 'QV-clientToken';
  public static readonly ACCESS_TOKEN_KEY = 'access_token';
  public static readonly ACCESS_TOKEN_EXPIRES_AT_KEY = 'expires_at';
  private static readonly ACCESS_TOKEN_STORED_AT_KEY = 'access_token_stored_at';
  private static readonly AUTHORIZATION_PREFIX = 'Bearer ';

  public issuer: string;
  public tokenEndpoint: string;

  private isPageLoggedOut = false;

  constructor(
    private oauthService: OAuthService,
    private localStorageService: LocalStorageService,
    private navigationService: NavigationService,
    @Inject(LOCATION_TOKEN) private location: Location,
    @Inject(HISTORY_TOKEN) private history: History,
    @Inject(AUTH_CONFIG_TOKEN) private authConfig: AuthConfig
  ) {
    this.issuer = this.authConfig.issuer;
  }

  public get hasValidSecurityToken(): boolean {
    return this.oauthService.hasValidAccessToken();
  }

  public get clientToken(): string {
    return this.localStorageService.retrieve(AuthService.CLIENT_TOKEN_KEY);
  }

  public get securityToken(): string {
    return this.oauthService.getAccessToken();
  }

  private get hasValidAccessToken(): boolean {
    return this.oauthService.hasValidAccessToken();
  }

  private get accessTokenExpiration(): number {
    return this.oauthService.getAccessTokenExpiration();
  }

  public initLoginProcess(): Observable<boolean> {
    this.oauthService.configure(this.authConfig);
    this.authEventHandler();
    this.observeClientTokenHandler();

    return from(this.oauthService.loadDiscoveryDocument())
      .pipe(
        tap(() => this.tokenEndpoint = this.oauthService.tokenEndpoint),
        switchMap(() => from(this.oauthService.tryLogin())),
        catchError((event: OAuthEvent) => {
          this.authorize();

          return throwError(event);
        }),
        map(() => this.hasSecureInfo()),
        tap(() => this.removeRedundantParamsFromURL())
      );
  }

  public authorize(additionalState?: string): void {
    NavigationUtils.setDeepLinkToCurrentUrl();

    this.oauthService.initCodeFlow(additionalState);
  }

  public logout(): void {
    this.oauthService.logOut({ client_id: this.authConfig.clientId, id_token_hint: this.oauthService.getIdToken() });
    this.isPageLoggedOut = true;
    this.localStorageService.clear(AuthService.CLIENT_TOKEN_KEY);
    NavigationUtils.setDeepLinkToCurrentUrl();
  }

  public hasSecureInfo(): boolean {
    return Boolean(this.hasValidAccessToken && this.clientToken);
  }

  public clearSecureInfo(): void {
    this.localStorageService.clear(AuthService.ACCESS_TOKEN_EXPIRES_AT_KEY);
    this.localStorageService.clear(AuthService.CLIENT_TOKEN_KEY);
  }

  public getSessionIdFromCurrentUrl(): string {
    const url = this.location.href;
    let sessionId = null;

    url.split('&').forEach((part: string) => {
      const [paramName, paramValue] = part.split('=');

      if (paramName === sessionIdParamConfig.sessionIdParamName) {
        sessionId = decodeURIComponent(paramValue);
      }
    });

    return sessionId;
  }

  public removeRedundantParamsFromURL(): void {
    const href = this.location.href
      .replace(sessionIdParamConfig.sessionIdParamRegex, '')
      .replace(sessionIdParamConfig.issuerParamRegex, '');

    this.history.replaceState(null, window.name, href);
  }

  public setClientToken(clientToken: string): void {
    if (clientToken) {
      this.localStorageService.store(AuthService.CLIENT_TOKEN_KEY, clientToken);
    }
  }

  public setTokensToXhr(xhr: XMLHttpRequest): void {
    const securityToken = this.securityToken || '';
    const clientToken = this.clientToken || '';

    if (securityToken) {
      xhr.setRequestHeader(HttpHeader.AUTHORIZATION, AuthService.AUTHORIZATION_PREFIX + securityToken);
    }

    if (clientToken) {
      xhr.setRequestHeader(HttpHeader.X_CLIENT_TOKEN, clientToken);
    }
  }

  public appendSecureInfoToUrl(url: string): string {
    if (!this.clientToken) {
      throw new Error('Secure tokens are missing');
    }

    return `${url}${url.indexOf('?') === -1 ? '?' : '&'}${AuthService.CLIENT_TOKEN_KEY}=${this.clientToken}`;
  }

  public getState(): string {
    return decodeURIComponent(this.oauthService.state);
  }

  private authEventHandler(): void {
    this.oauthService.events
      .pipe(untilDestroyed(this))
      .subscribe((event: OAuthEvent) => {
        switch (event.type) {
          case AuthEventCode.TOKEN_REFRESHED:
            this.setupAutomaticSilentRefresh();
            break;
          case AuthEventCode.TOKEN_REFRESH_ERROR:
            console.error('TOKEN_REFRESH_ERROR');

            this.authorize();
            break;
        }
      });
  }

  private observeClientTokenHandler(): void {
    combineLatest([
      this.localStorageService.observe(AuthService.ACCESS_TOKEN_KEY),
      this.localStorageService.observe(AuthService.CLIENT_TOKEN_KEY)
    ]).pipe(
      filter(([accessToken, clientToken]: [ string, string ]) =>
        !accessToken && !clientToken && !this.isPageLoggedOut)
    ).subscribe(() => this.location.reload());
  }

  private setupAutomaticSilentRefresh(): void {
    if (!this.accessTokenExpiration || this.navigationService.isPublicPage()) {
      return;
    }

    const refreshTimeout = this.getRefreshTimeout();

    timer(refreshTimeout)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        if (this.shouldRefreshAccessToken()) {
          this.oauthService.refreshToken();
        } else {
          this.setupAutomaticSilentRefresh();
        }
      });
  }

  private getRefreshTimeout(): number {
    const storedAt = this.getAccessTokenStoredAt();
    const now = Date.now();
    const timeoutFactor = this.getTimeoutFactor();

    const delta = (this.accessTokenExpiration - storedAt) * timeoutFactor - (now - storedAt);

    return Math.max(0, delta);
  }

  private getAccessTokenStoredAt(): number {
    // this.localStorageService.retrieve cannot be used here because it return the same first cached result all the time

    return parseInt(localStorage.getItem(AuthService.ACCESS_TOKEN_STORED_AT_KEY), 10);
  }

  private getTimeoutFactor(): number {
    return Math.random() * (authTimeoutFactorRange.to - authTimeoutFactorRange.from) + authTimeoutFactorRange.from;
  }

  private shouldRefreshAccessToken(): boolean {
    const storedAt = this.getAccessTokenStoredAt();
    const now = Date.now();

    return (this.accessTokenExpiration - storedAt) * authTimeoutFactorRange.from < (now - storedAt);
  }
}
