/* tslint:disable:no-invalid-this */
import { ConfirmNavigationInner } from '@qv-ng1-wrapper/classes';
import { constants, applicationUrls, resources } from '@qv-common/static';
import { CoreUtils } from '@qv-common/utils';
import { QuantuvisBusinessFeature, QuantuvisPlusFeature } from '@qv-company/enums';
import { HttpStatusCode } from 'quantuvis-angular-common/api';
import { GeneralModalData, ModalService } from 'quantuvis-angular-common/modal';
import { forkJoin, of } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { ApiUrlPrefix } from '@qv-common/enums';
import isEqual from 'lodash.isequal';

// @ts-ignore
import templateUrl from './profilesettings.html';
import { UserService } from '@qv-common/services/auth/user.service';

declare let angular: angular.IAngularStatic;
declare let $: any;

const ProfileComponent = {
  template: templateUrl,
  bindings: {
    user: '<',
    initConfirmNavigation: '&'
  },
  controller: class ProfileComponent extends ConfirmNavigationInner {

    public static $inject = ['$scope', 'standards', 'timezone', 'util', 'validations', 'translations',
      'permissionService', 'userService', 'spinnerService', 'apiService', 'modalService'];
    public confirmNavigationMessage = resources.GENERAL.CONFIRM_NAVIGATION_TEXT;

    constructor(private $scope, standards, timezone, util, validations, translations, permissionService,
      userService: UserService,
      spinnerService,
      apiService,
      private modalService: ModalService,
    ) {
      super();
      const componentInstance = this;

      standards.apply($scope,
        {
          constructor: () => {
            $scope.user = userService.user.getValue();
            $scope.featureConstants = Object.assign({},
              QuantuvisPlusFeature,
              QuantuvisBusinessFeature
            );
            // Allow view access rights flag
            $scope.allowViewAccessRights = permissionService.isAllowed('view_access_rights');
            timezone.fetchTimezones($scope);
            translations.buildTranslations($scope, 'ProfileSettings');
            $scope.userACLs = permissionService.userAcl$.value;

            $scope.emailPreferences = constants.MY_ACCOUNTS.EMAIL_PREFERENCES;

            $scope.qPlusFeatures = {
              [QuantuvisPlusFeature.COMPARE_OFFERED_BIDS]: false,
              [QuantuvisPlusFeature.BID_HISTORY]: false,
              [QuantuvisPlusFeature.REVISION_COMPARISON]: false,
              [QuantuvisPlusFeature.PRINT]: false,
              [QuantuvisPlusFeature.EXPORT]: false,
              [QuantuvisPlusFeature.STATUS_GRAPH]: false,
              [QuantuvisPlusFeature.GRID_VIEW]: false,
              [QuantuvisPlusFeature.PRINT_SCENARIO_SUMMARY]: false,
              [QuantuvisPlusFeature.INTERNAL_FIELDS]: false
            };
            $scope.qBusinessFeatures = {
              [QuantuvisBusinessFeature.INTERNAL_BIDS]: false
            };

            $scope.myAccountsMessages = $scope.i18n;

            // List of Company Ids already added to myAccounts
            $scope.user.myAccounts = userService.myAccounts || [];
            $scope.myAccountsIds = $scope.user.myAccounts;

            // Warning messages
            $scope.warning = '';

            // Email Preferences invalid
            $scope.invalidEmailPreferences = false;
            $scope.checkEmailPreferences = checkEmailPreferences;

            setMyAccountsMessagesByUserType();

            const getProfile = () => {
              return apiService.get(`${ApiUrlPrefix.OLD}/user/getProfileData`).pipe(
                tap((response: HttpResponse<any>) => {
                  const data = response.body;

                  $scope.user.timezone = data.timezone;
                  $scope.user.role = data.role;
                  $scope.user.emailFilter = data.emailFilter;
                  $scope.newTitle = data.title;
                  $scope.newPhone = data.phone;
                  $scope.user.company = data.company;
                  $scope.newLastName = data.lastName;
                  $scope.newFirstName = data.firstName;

                  // Store currently saved data
                  $scope.savedData = angular.copy($scope.getCurrentProfileData());

                  prepareFeatures();
                }),
                catchError((error: HttpErrorResponse) => {
                  console.error('Error fetching user/getProfileData', error);
                  return of(null);
                })
              );
            };

            const getOtherType = () => {
              if ($scope.user.role.name === constants.RoleNames.QUANTUVIS_ADMIN) {
                return of(null);
              }
              // Get all companies of the other type (eg: get pharmas for payers; get payers for pharmas)
              // Don't get companies for Quantuvis Admin
              return apiService.get(`${ApiUrlPrefix.OLD}/companies/othertype/true`).pipe(
                tap((response: HttpResponse<any>) => {
                  $scope.companies = response.body.responseObject;
                }),
                catchError((error: HttpErrorResponse) => {
                  console.error('Error fetching /companies/othertype/true', error);

                  return of(null);
                })
              );
            };

            forkJoin([getProfile(), getOtherType()]).subscribe(() => {
              resetPasswordFields();
              $scope.dataReady = true;
            });

            $scope.isAdmin = () => {
              const roleNames = constants.RoleNames;
              return $scope.user.role.name === roleNames.PAYER_ADMIN
                || $scope.user.role.name === roleNames.PHARMA_ADMIN;
            };

            $scope.$watch('user.emailFilter', (newVal, oldVal) => {
              checkEmailPreferences();
            }, true);


            /**
             * Get current profile settings data
             * @returns {Object}
             */
            $scope.getCurrentProfileData = () => {

              const currentData: any = {
                lastName: $scope.newLastName,
                firstName: $scope.newFirstName,
                title: $scope.newTitle,
                phone: $scope.newPhone,
                myAccounts: $scope.user.myAccounts,
                emailFilter: $scope.user.emailFilter,
                timezone: $scope.user.timezone

              };

              currentData.oldPassword = $scope.oldPassword || undefined;
              currentData.newPassword = $scope.newPassword || undefined;
              currentData.newPasswordRetyped = $scope.newPasswordRetyped || undefined;

              return currentData;
            };

            /**
             * Method that checks if the current logged user is a Quantuvis administrator
             * @returns {boolean}
             */
            $scope.isQuantuvisAdmin = () => $scope.user.role.type === constants.RoleTypes.ADMIN;
          },
          variables: {},
          public(): any {
            /**
             * This method calls the updateProfile service with new profile information to be saved in db.
             */
            this.saveUserProfile = () => {
              if (checkRequiredFields()) {

                if (isPasswordChangingValid()) {
                  // This is the case when either all the password
                  // fields are filled and they are valid or all of them are empty

                  if ($scope.user.isPayer || $scope.user.isManufacturer) {
                    updateMyAccountsToggle();
                  }
                  deleteMessageFields();

                  spinnerService.start($scope.i18n.UPDATING_USER_MESSAGE);

                  const userToUpdate = angular.copy($scope.user);
                  userToUpdate.lastName = $scope.newLastName;
                  userToUpdate.firstName = $scope.newFirstName;
                  userToUpdate.title = $scope.newTitle;
                  userToUpdate.phone = $scope.newPhone;
                  userToUpdate.myAccounts = $scope.user.myAccounts;
                  userToUpdate.emailFilter = $scope.user.emailFilter;
                  if ($scope.user.isPayer) {
                    userToUpdate.filterByMyAccounts = $scope.user.filterByMyAccounts;
                  }
                  if (CoreUtils.isDefined($scope.oldPassword) && CoreUtils.isDefined($scope.newPassword)
                    && CoreUtils.isDefined($scope.newPasswordRetyped)) {
                    // @ts-ignore
                    userToUpdate.oldPassword = base64.encode($scope.oldPassword);
                    // @ts-ignore
                    userToUpdate.newPassword = base64.encode($scope.newPassword);
                  }

                  resetPasswordFields();

                  apiService.post(`${ApiUrlPrefix.OLD}/user/updateProfile`, userToUpdate).pipe(
                    tap((response: HttpResponse<any>) => {
                      const data = response.body;


                      if (data.errorCode === HttpStatusCode.FAILED_DEPENDENCY) {
                        componentInstance.handleFailedLogoutDevices();
                      } else if (data.error) {
                        delete $scope.userValidationErrorMsg;
                        $scope.error = true;
                        $scope.message = data.message;
                        return;
                      }

                      // Successfully updated the user information
                      userService.loadUserData().subscribe();

                      $scope.error = false;
                      $scope.message = $scope.i18n.SAVE_SUCCESSFUL;
                      // update saved data
                      $scope.savedData = angular.copy($scope.getCurrentProfileData());

                    }),
                    catchError((error: HttpErrorResponse) => {
                      delete $scope.userValidationErrorMsg;
                      $scope.error = true;
                      $scope.message = error.message;

                      return of(null);
                    }),
                    finalize(() => spinnerService.stop())
                  ).subscribe();
                }
              }
            };

            /**
             * Returns the current user's right for the given company id.
             * It uses the user's ACLs retrieved upon login.
             */
            this.getRightValue = (companyId) => {
              const userRight = $scope.userACLs.get(companyId);
              return constants.UserRights[userRight];
            };

            this.getClassForAccessRight = companyId => {
              const right = $scope.getRightValue(companyId);
              let klass = 'right color';
              const userRights = constants.UserRights;
              switch (right) {
                case userRights.READ :
                  klass += ' blue';
                  break;
                case userRights.WRITE :
                  klass += ' purple';
                  break;
                default :
                  console.error('No access right found for company id', companyId);
              }

              return klass;
            };

            return {
              getRightValue: this.getRightValue,
              saveUserProfile: this.saveUserProfile,
              timezoneChanged: this.timezoneChanged,
              getClassForAccessRight: this.getClassForAccessRight
            };
          }
        });

      function prepareFeatures(): void {
        $scope.anyQPlusFeatureChecked = false;
        $scope.anyQBusinessFeatureChecked = false;
        util.forEachObjectProperty(QuantuvisPlusFeature, (key, featureName) => {
          $scope.qPlusFeatures[featureName] = permissionService.isFeatureAllowed(featureName);
          $scope.anyQPlusFeatureChecked = $scope.anyQPlusFeatureChecked || $scope.qPlusFeatures[featureName];
        });
        util.forEachObjectProperty(QuantuvisBusinessFeature, (key, featureName) => {
          $scope.qBusinessFeatures[featureName] = permissionService.isFeatureAllowed(featureName);
          $scope.anyQBusinessFeatureChecked =
            $scope.anyQBusinessFeatureChecked || $scope.qBusinessFeatures[featureName];
        });
      }

      /**
       * Verifies if the user is changing his / her password. The user is not changing his password if all the
       * password fields are empty. Otherwise, the fields are verified to be all of them filled in with the new
       * password equal to retyped password and the new password should follow the rule. An error message is set up if
       * changing the password cannot be performed due to validations.
       *
       * @returns {boolean} true if all the password fields are empty or all the password fields are filled in and
       *                    the new password is equal to new password retyped and the new password if following the
       *                    rule; false otherwise
       */
      function isPasswordChangingValid(): boolean {
        let isValid = false;

        // If all the fields are empty, it means the user don't want to update his password
        if (!util.isNotEmpty($scope.oldPassword)
          && !util.isNotEmpty($scope.newPassword) && !util.isNotEmpty($scope.newPasswordRetyped)) {
          isValid = true;
        } else if (!util.isNotEmpty($scope.oldPassword)) {
          // This is the case when the current password
          // is empty and at least one of the other password fields are filled in
          $scope.message = $scope.i18n.OLD_PASSWORD_MISSING;
        } else if (!util.isNotEmpty($scope.newPassword) || !util.isNotEmpty($scope.newPasswordRetyped)) {
          // This is the case when the current password is filled in, but one or both new password fields are empty
          $scope.message = $scope.i18n.PASSWORD_EMPTY_NOT_ALLOWED;
        } else {
          // This is the case when all the password fields are filled in
          if ($scope.newPassword === $scope.newPasswordRetyped) {

            // If the new password is equal to retyped password, check if the new password follows the rule
            if (validations.passwordFollowsRule($scope.newPassword)) {
              // The new password follows the rule
              isValid = true;
            } else {
              // This is the case when the new password doesn't follow the rule
              $scope.message = $scope.i18n.PASSWORD_NOT_FOLLOW_RULE;
            }
          } else {
            // This is the case when the retyped password doesn't match the new password
            $scope.message = $scope.i18n.PASSWORD_NOT_MATCH;
          }
        }

        if (!isValid) {
          $scope.error = !isValid;
        }

        return isValid;
      }

      function setMyAccountsMessagesByUserType(): void {
        if ($scope.user.isPayer) {
          $scope.myAccountsTooltipMessage = $scope.myAccountsMessages.MY_ACCOUNTS_TOOLTIP_FOR_PAYER;
          $scope.emptyMyAccountsMessage = $scope.myAccountsMessages.EMPTY_MY_ACCOUNTS_INFO
            + $scope.myAccountsMessages.EMPTY_MY_ACCOUNTS_INFO_FOR_PAYER;
        } else if ($scope.user.isManufacturer) {
          $scope.myAccountsTooltipMessage = $scope.myAccountsMessages.MY_ACCOUNTS_TOOLTIP_FOR_PHARMA;
          $scope.emptyMyAccountsMessage = $scope.myAccountsMessages.EMPTY_MY_ACCOUNTS_INFO
            + $scope.myAccountsMessages.EMPTY_MY_ACCOUNTS_INFO_FOR_PHARMA;
        }
      }

      /**
       * Removes error and info messages from the scope.
       */
      function deleteMessageFields(): void {
        delete $scope.error;
        delete $scope.message;
      }

      /**
       * Display warning message when Email Preferences is set to My Accounts and My Accounts list is empty
       */
      function checkEmailPreferences(): void {
        $scope.invalidEmailPreferences =
          (($scope.user.emailFilter === constants.MY_ACCOUNTS.EMAIL_PREFERENCES[1].val) &&
            (CoreUtils.isDefined($scope.user.myAccounts) && !CoreUtils.isNull($scope.user.myAccounts) &&
              ($scope.user.myAccounts.length === 0)));
      }

      /**
       * Method that checks required fields and sets proper messages in case of error
       * @returns {boolean} true if both fields are define and are not empty
       *                  false otherwise
       */
      function checkRequiredFields(): boolean {
        let isValid = false;

        if (!util.isNotEmpty($scope.newFirstName)) {
          $scope.message = $scope.i18n.EMPTY_FIRST_NAME_ERROR_MESSAGE;
        } else if (!util.isNotEmpty($scope.newLastName)) {
          $scope.message = $scope.i18n.EMPTY_LAST_NAME_ERROR_MESSAGE;
        } else if (!util.isNotEmpty($scope.newTitle)) {
          $scope.message = $scope.i18n.EMPTY_TITLE_ERROR_MESSAGE;
        } else if (!util.isNotEmpty($scope.newPhone)) {
          $scope.message = $scope.i18n.EMPTY_PHONE_ERROR_MESSAGE;
        } else {
          isValid = true;
          deleteMessageFields();
        }

        if (!isValid) {
          $scope.error = !isValid;
        }

        return isValid;
      }

      /**
       * If My Accounts list is empty reset toggle to All Accounts and show warning
       */
      function updateMyAccountsToggle(): void {
        let message = '';
        if ($scope.user.myAccounts.length === 0) {
          message += $scope.user.filterByMyAccounts ?
            $scope.i18n.ACCOUNTS_TOGGLE_WARNING : '';
          $scope.user.filterByMyAccounts = false;
        }
        $scope.warning = message;
      }

      /**
       * Resets password fields by removing the variables associated with them from the scope and the elements value
       * are set to empty string.
       */
      function resetPasswordFields(): void {
        // Delete password variables from scope
        delete $scope.oldPassword;
        delete $scope.newPassword;
        delete $scope.newPasswordRetyped;

        // The password fields values are reset this way due to replacing ng-model with ng-no-trim-model
        $('#oldPassword').val('');
        $('#newPassword').val('');
        $('#newPasswordRetyped').val('');
        $('#newPassword, #newPasswordRetyped').popover({
          trigger: 'focus',
          content: $scope.i18n.PASSWORD_REQUIREMENTS
        });
      }

    }


    public handleFailedLogoutDevices = (): void => {
      const modalData = new GeneralModalData(
        'Failed to log out from all the devices',
        'Some devices are still logged in. To log them out, follow the link to Device Management',
        'Device Management',
        'Close',
        true
      );
      const modal = this.modalService.openConfirmModal(modalData);

      modal.subscribe((result: boolean) => {
        if (result) {
          window.open(applicationUrls.deviceActivityUrl, '_blank');
        }
      });
    }

    public $onInit(): void {
      this.emitConfirmNavigationCallback();
    }

    public isNavigationNeedConfirmation(): boolean {
      return !isEqual(this.$scope.getCurrentProfileData(), this.$scope.savedData);
    }
  }
};
export default ProfileComponent;
