import { Component, EventEmitter, Input, Output } from '@angular/core';
import { resources } from '@qv-common/static';
import { CoreUtils } from '@qv-common/utils';
import {
  GeneralModalComponent,
  GeneralModalConfig,
  GeneralModalData,
  ModalConfig,
  ModalService,
  ModalSize
} from 'quantuvis-angular-common/modal';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'qv-at-ndc',
  templateUrl: './at-ndc.component.html',
  styleUrls: ['./at-ndc.component.scss']
})
export class AtNdcComponent {
  private static readonly TITLE_OVERWRITE_VALUES_MODAL = 'Overwrite NDC Values';
  @Input()
  public disableClick = false;
  @Output()
  public overwriteValuesConfirmed = new EventEmitter();
  @Output()
  public overwriteValuesCanceled = new EventEmitter();
  @Output()
  public disableNavEvent = new EventEmitter();

  constructor(private modalService: ModalService) {}

  public onShowOverwriteValuesModal(event: PointerEvent): void {
    if (CoreUtils.isClickEvent(event)) {
      this.showOverwriteValuesModal();
    }
  }

  public showOverwriteValuesModal(): void {
    if (this.disableClick) return;

    const modalConfig = AtNdcComponent.getOverwriteValuesModalConfig();
    const modal = this.modalService.openModal(modalConfig);

    modal.componentInstance.primaryAction
      .pipe(untilDestroyed(this))
      .subscribe(() => this.overwriteValuesConfirmed.emit());

    modal.componentInstance.secondaryAction
      .pipe(untilDestroyed(this))
      .subscribe(() => this.overwriteValuesCanceled.emit());
  }

  private static getOverwriteValuesModalConfig(): ModalConfig<GeneralModalComponent, GeneralModalData> {
    const modalData = new GeneralModalData(
      AtNdcComponent.TITLE_OVERWRITE_VALUES_MODAL,
      resources.BID_DETAILS.CHANGING_WILL_OVERRIDE,
      resources.Actions.CONTINUE,
      resources.Actions.CANCEL
    );
    return new GeneralModalConfig(modalData, ModalSize.X_SMALL);
  }
}
