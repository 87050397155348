export enum Permission {
  ENTERPRISE = 'enterprise',
  DASHBOARD = 'dashboard',
  BIDS = 'bids',
  NEW_BID = 'new_bid',
  NOTIFICATIONS = 'notifications',
  PROFILE = 'profile',
  PEOPLE = 'people',
  FEEDBACK = 'feedback',
  CONTACT_QUANTUVIS = 'contact_quantuvis',
  MARKET_BASKET = 'market_basket',
  VIEW_ACCESS_RIGHTS = 'view_access_rights',
  EDIT_ACCESS_RIGHTS = 'edit_access_rights',
  LEGAL_ATTESTATION = 'legal_attestation',
  QUANTUVIS_ADMIN = 'quantuvis_administrator',
  NEW_USER = 'new_user',
  EDIT_USER = 'edit_user',
}
