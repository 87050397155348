import { DictionaryItem } from 'quantuvis-angular-common/interfaces';

export enum MarketBasketDrugType {
  ANCHOR = 1,
  STANDARD = 2,
  DISADVANTAGE = 3,
  REPORTING = 4,
}

export namespace MarketBasketDrugType {
  const displayValues = new Map<MarketBasketDrugType, DictionaryItem>([
    [MarketBasketDrugType.ANCHOR, { id: MarketBasketDrugType.ANCHOR, name: 'Anchor' }],
    [MarketBasketDrugType.STANDARD, { id: MarketBasketDrugType.STANDARD, name: 'Standard' }],
    [MarketBasketDrugType.DISADVANTAGE, { id: MarketBasketDrugType.DISADVANTAGE, name: 'Disadvantage' }],
    [MarketBasketDrugType.REPORTING, { id: MarketBasketDrugType.REPORTING, name: 'Reporting' }],
  ]);

  export function getValue(id: MarketBasketDrugType): DictionaryItem {
    return displayValues.get(id);
  }

  export function getValues(): DictionaryItem[] {
    return Array.from(displayValues.values());
  }
}
