import { SelectBoxTerm } from '@qv-term/models/types';
import { resources } from '@qv-common/static';
import { PriceProtectionIndex } from 'quantuvis-core-entities';

export class IndexTerm extends SelectBoxTerm {
  constructor(name: string) {
    super(
      name,
      name,
      resources.TermsDescriptions.DRUG_LIST.INDEX,
      '',
      '',
      PriceProtectionIndex.getValue(PriceProtectionIndex.EMPTY),
      'term_index'
    );

    this.values = PriceProtectionIndex.getValues();
  }
}
