import { BaseTerm } from '@qv-term/models/types';
import { Comparator, KeepOriginalValue, Reset } from '@qv-common/interfaces';
import { CoreUtils } from '@qv-common/utils';
import { constants, resources } from '@qv-common/static';

export class WebsiteLinksTerm extends BaseTerm implements Reset, KeepOriginalValue, Comparator {
  public allowKeepOriginValue: boolean;
  public processKeepOriginalValue: boolean;
  public keepOriginalValue: boolean;
  public company: string;

  constructor(name: string) {
    super(
      name,
      name,
      resources.TermsDescriptions.SUMMARY.LINKS,
      constants.SUMMARY_TERMS.WEBSITE_LINKS.label,
      [{}],
      [{}],
      'term__website-links'
    );

    this.keepOriginalValue = this.allowKeepOriginValue;
    this.processKeepOriginalValue = false;
  }

  public getValueToSave(): Array<Record<string, unknown>> {
    const values = [];
    // TODO need add KeepOriginal logic
    this.value.forEach(val => {
      if (CoreUtils.isDefined(val.link) && val.link) {
        values.push({
          link: val.link,
          company: val.company
        });
      }
    });

    return values;
  }


  public resetValue(): void {
    // TODO need add KeepOriginal logic
    this.value = [];
    this.value.push({
      link: '',
      company: this.company
    });
  }

  public getTermValueForComparison(): string {
    throw new Error('Method not implemented.');
  }
}
