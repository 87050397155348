<div *ngIf="isReady" class="legal-attestation">
  <header class="legal-attestation__header row">
    <p *ngIf="!hasFile()"
       [class.legal-attestation__text--admin]="userService.isCurrentUserPayerAdmin()"
       class="legal-attestation__text col-10">
      There are no Legal Attestation documents uploaded for the company.<br>
      Users will not be able to create <strong>Binding Bids</strong>.
    </p>
    <div class="legal-attestation__nav col-2">
      <div class="float-right">
        <input
          #inputFileUpload
          (change)="selectFile($event)"
          hidden
          type="file">
        <p-fileUpload
          #fileUploader
          (onBeforeUpload)="onBeforeSendHandler()"
          (onError)="onErrorHandler($event)"
          (onUpload)="onUploadHandler($event)"
          [accept]="acceptFileExtension"
          [auto]="true"
          [invalidFileSizeMessageDetail]="maxFileSizeMessage"
          [invalidFileTypeMessageDetail]="fileFormatNotValidMessage"
          [maxFileSize]="maxFileSize"
          [url]="uploadUrl"
          hidden
          name="file">
        </p-fileUpload>
        <qv-action-button
            (buttonClick)="inputFileUpload.click()"
            *ngIf="isActionAllowed()"
            [svgIconName]="svgIconName.UPLOAD"
            [title]="'Upload file'"
            [tooltip]="uploadTooltip"
            cssClass="toolbar-button--primary">
        </qv-action-button>
        <qv-action-button
            (buttonClick)="exportFile()"
            *ngIf="hasFile()"
            [svgIconName]="svgIconName.DOWNLOAD"
            [tooltip]="exportTooltip"
            cssClass="toolbar-button--extra">
        </qv-action-button>
      </div>
    </div>
  </header>
  <qv-pdf-viewer
      (exportFile)="exportFile()"
      *ngIf="hasFile()"
      [filePrefix]="filePrefix"
      [file]="$any(file)"
      [fullPage]="true">
  </qv-pdf-viewer>
</div>
