import { ChangeDetectionStrategy, Component, Input, OnInit, ViewChild } from '@angular/core';
import { MenuActionItem } from '@qv-bid/components/shared/drug-actions-menu/models';
import { MatMenuTrigger } from '@angular/material/menu';
import { BidEventBusService } from '@qv-bid/services/bid-event-bus.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'qv-action-menu',
  templateUrl: './action-menu.component.html',
  styleUrls: ['./action-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActionMenuComponent implements OnInit {

  @ViewChild(MatMenuTrigger)
  public trigger: MatMenuTrigger;

  @Input()
  public menuActionItems: MenuActionItem[];

  constructor(private bidEventBusService: BidEventBusService) { }

  public ngOnInit(): void {
    this.initDrugActionsEventHandler();
  }

  private initDrugActionsEventHandler(): void {
    this.bidEventBusService.drugActionsEvent
      .pipe(untilDestroyed(this))
      .subscribe(() => this.trigger.openMenu());
  }

}
