<div class="standard-scenario-section-container" [class.standard-scenario-section-container--hidden]="isHidden(state, sectionName)">
  <div class="standard-scenario-section-container__name" [matTooltip]="tooltip">
    {{title}}
  </div>
  <div class="standard-scenario-section-container__value" *ngIf="!isAtNdc; else atNdc">
    <qv-lock class="standard-scenario-section-container__lock-icon"
      *ngIf="isLocked(stateDependingOnMode(), sectionName)"
      [isDisabled]="true"
      [locked]="true">
    </qv-lock>
    <mat-icon class="standard-scenario-section-container__warning-icon validation-icon validation-icon--error"
      *ngIf="isWarningIconVisible(isPharmaOrPharmaPerspective, state, sectionName, isValid)"
      [matTooltip]="validationMessage"
      [svgIcon]="svgIconName.WARNING">
    </mat-icon>
    <qv-standard-scenario-state-select
      attr.data-test-id="test-id-scenario-{{sectionName}}"
      *ngIf="stateDependingOnMode()"
      [state]="stateDependingOnMode()"
      [isEditMode]="isEditMode"
      [sectionName]="sectionName"
      [control]="standardDrugFormService.getTermControl(sectionTermName, sectionName)"
    ></qv-standard-scenario-state-select>
  </div>
  <ng-template #atNdc>
    <div class="standard-scenario-section-container__at-ndc"
      [class.standard-scenario-section-container__at-ndc--pointer]="bidStateService.isEditMode | async"
      (click)="openDropDownWhenAllConditionsFulfilled()">
      {{getSectionStateValue(state)}}
    </div>
    <qv-standard-scenario-state-dropdown
      [sectionName]="sectionName"
      [resetSelect]="resetSelect"
      [appendTo]="'qv-standard-scenario'"
      [openDropDown]="openStateDropDown"
      (optionSelected)="setStateFromDropdownOptions($event)"
    ></qv-standard-scenario-state-dropdown>
  </ng-template>
</div>
