import { JsonObject, JsonProperty } from 'json2typescript';
import { Moment } from 'moment';
import { MomentConverter } from 'quantuvis-angular-common/json-converter/serializers';
import { CompanyBase as Company, LineOfBusiness } from 'quantuvis-core-entities';
import { MarketBasketDrugType, MarketBasketTemplateType } from '../enums';
import { MarketBasketDrug } from './market-basket-drug.entity';
import { marketBasketTemplateStateConstants } from '../constants/market-basket-template-state.constants';

@JsonObject('MarketBasketTemplate')
export class MarketBasketTemplate {
  @JsonProperty('id', Number)
  public id: number = null;

  @JsonProperty('name', String)
  public name: string = null;

  @JsonProperty('typeId', Number)
  public type: MarketBasketTemplateType = MarketBasketTemplateType.ANALYTICAL;

  @JsonProperty('lineOfBusinessId', Number)
  public lineOfBusiness: LineOfBusiness = null;

  @JsonProperty('tradingPartners', [Company])
  public tradingPartners: Company[] = [];

  @JsonProperty('startDate', MomentConverter)
  public startDate: Moment = null;

  @JsonProperty('endDate', MomentConverter)
  public endDate: Moment = null;

  @JsonProperty('updatedAt', MomentConverter, true)
  public updatedAt: Moment = null;

  @JsonProperty('drugs', [MarketBasketDrug])
  public drugs: MarketBasketDrug[] = [];

  @JsonProperty('isArchived', Boolean)
  public isArchived: boolean = false;

  public isCompetitive(): boolean {
    return this.type === MarketBasketTemplateType.COMPETITIVE;
  }

  public get nameWithState(): string {
    return this.isArchived
      ? `${ this.name } ${ marketBasketTemplateStateConstants.archivedStateDisplayValue }`
      : this.name;
  }

  public getAnchorDrugs(): MarketBasketDrug[] {
    return this.drugs.filter((drug: MarketBasketDrug) => drug.type === MarketBasketDrugType.ANCHOR);
  }

  public copy(): MarketBasketTemplate {
    const drugs = this.drugs.map((drug: MarketBasketDrug) =>
      Object.assign(new MarketBasketDrug(), drug));
    const tradingPartners = this.tradingPartners.map((partner: Company) =>
      Object.assign(new Company(), partner));

    return Object.assign(new MarketBasketTemplate(), this, {
      id: null,
      name: `Copy of ${this.name}`,
      drugs,
      tradingPartners
    } as MarketBasketTemplate);
  }
}
