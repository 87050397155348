import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { ActivatedRoute } from '@angular/router';
import { BidExtraToolsComponent } from '@qv-bid/components/shared/bid-extra-tools/bid-extra-tools.component';
import { PermissionService } from '@qv-common/services/auth/permission.service';
import { ViewPerspectiveService } from '@qv-common/services/auth';
import { NotificationService } from 'quantuvis-angular-common/notification';
import { SvgIconName } from '@qv-common/enums';
import { QuantuvisPlusFeature } from '@qv-company/enums';
import { BidStateService, BidVersionService } from '@qv-bid/services';
import { BidExportDaoService } from '@qv-bid/services/dao';
import { LetterOfIntentDaoService } from '@qv-loi-common/services/dao';
import { ModalService } from 'quantuvis-angular-common/modal';
import { UserService } from '@qv-common/services/auth/user.service';

@Component({
  selector: 'qv-bid-extra-tools-menu',
  templateUrl: './bid-extra-tools-menu.component.html',
  styleUrls: ['./bid-extra-tools-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BidExtraToolsMenuComponent extends BidExtraToolsComponent {
  @ViewChild(MatMenuTrigger)
  public trigger: MatMenuTrigger;

  public readonly svgIconName = SvgIconName;
  public readonly quantuvisPlusFeature = QuantuvisPlusFeature;

  constructor(
    protected userService: UserService,
    protected bidExportService: BidExportDaoService,
    protected permissionService: PermissionService,
    protected activatedRoute: ActivatedRoute,
    protected modalService: ModalService,
    public viewPerspectiveService: ViewPerspectiveService,
    protected letterOfIntentDaoService: LetterOfIntentDaoService,
    protected notificationService: NotificationService,
    protected bidVersionService: BidVersionService,
    protected changeDetectorRef: ChangeDetectorRef,
    protected bidStateService: BidStateService
  ) {
    super(
      userService,
      bidExportService,
      permissionService,
      activatedRoute,
      modalService,
      viewPerspectiveService,
      letterOfIntentDaoService,
      notificationService,
      bidVersionService,
      changeDetectorRef,
      bidStateService,
    );
  }

  @HostListener('window:resize')
  public onResize(): void {
    if (this.trigger.menuOpen) {
      this.trigger.closeMenu();
    }
  }
}
