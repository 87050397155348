<ng-container *ngIf="!isEditPresentation(termValue, isEditMode, isOverrideMode, isUserPharma, isLocked)">
  <div class="term term__input term--view">
    <ng-template
        (overwriteValuesConfirmed)="overwriteValue()"
        [qvAtNdc]="termValue"
        [setDisableClick]="!isEditable(isEditMode, isUserPharma, isLocked, isPending)">
      <ng-container *ngIf="isNumberValue()">
        <div *ngIf="hasTermPrefix()" class="term__prefix">{{ getTermPrefix() }}</div>
        <div class="term__value">
          {{ getTermValue() | numberFixed: numberOptions.decimals : numberOptions.minDecimals : numberOptions.step }}
        </div>
        <div *ngIf="hasTermSuffixAtInput() || hasTermSuffixAtConfig()" class="term__suffix">{{ getTermSuffix() }}</div>
      </ng-container>

      <ng-container *ngIf="!isNumberValue() && getTermValue()">
        <div *ngIf="hasTermPrefix()" class="term__prefix">{{ getTermPrefix() }}</div>
        <div class="term__value">{{ getTermValue() }}</div>
        <div *ngIf="hasTermSuffixAtInput() || hasTermSuffixAtConfig()" class="term__suffix">
          &nbsp;{{ getTermSuffix() }}
        </div>
      </ng-container>

      <ng-container *ngIf="!isNumberValue() && !getTermValue()">
        <div class="term__value term__value--empty"></div>
      </ng-container>
    </ng-template>
  </div>
</ng-container>

<ng-container *ngIf="isEditPresentation(termValue, isEditMode, isOverrideMode, isUserPharma, isLocked)">
  <div [class.term__input-wrapper--full-width]="isFullWidth" class="term__input-wrapper">
    <div *ngIf="hasTermPrefix()" class="term__prefix">{{ getTermPrefix() }}</div>
    <input #inputElement
           (blur)="onBlur()"
           (focus)="disableNavEvent.emit()"
           (keyup.enter)="completeEditMode($event)"
           (keyup.escape)="completeEditMode($event)"
           *ngIf="control"
           [class.term__field--no-suffix]="!getTermSuffix()"
           [formControl]="control"
           class="term__field"
           matInput
           tabindex="-1"
    >
    <div *ngIf="getTermSuffix()" class="term__suffix">{{ getTermSuffix() }}</div>
  </div>
</ng-container>
