import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CheckboxItem } from '@qv-common/models';
import { SvgIconName } from '@qv-common/enums';
import { ArrayUtils } from '@qv-common/utils';

@Component({
  selector: 'qv-checkbox-list-wrapper',
  templateUrl: './checkbox-list-wrapper.component.html',
  styleUrls: ['./checkbox-list-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CheckboxListWrapperComponent {
  @Input()
  public items: CheckboxItem[] | null = null;

  public readonly svgIconName = SvgIconName;

  public isEmptyArray(items: CheckboxItem[]): boolean {
    return ArrayUtils.isEmptyArray(items);
  }

}
