import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { EditorModule, TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';
import { BlockUIModule } from 'ng-block-ui';

import { DomManagerService } from '@qv-shared/services';
import { BgColorDirective } from './directives/bg-color.directive';
import {
  BooleanChoicePipe,
  DateTimeUserTzPipe,
  DateTimeUtcPipe,
  HttpUrlPipe,
  NumberFixedPipe,
  NumberMaxPipe,
  OtherChipsPipe,
  SecondsToTimePipe
} from '@qv-shared/pipes';
import { AssetService, assetServiceFactory } from './services';
import { ClickOutsideDirective, ReadMoreDirective } from '@qv-shared/directives';
import { MatDialogModule } from '@angular/material/dialog';
import {
  ActionButtonComponent,
  CheckboxListComponent,
  CheckboxListPlaceholderComponent,
  CheckboxListWrapperComponent,
  ContractDateConflictsModalComponent,
  DatePickerComponent,
  DateRangeFilterComponent,
  DropdownComponent,
  EmptyComponent,
  ErrorIconComponent,
  ExpansionFormPanelComponent,
  FilterMenuComponent,
  FilterSnackBarComponent,
  HtmlEditorComponent,
  ListPanelHeaderSearchComponent,
  LoadingRingComponent,
  LockComponent,
  MenuItemButtonComponent,
  NoItemsMessageComponent,
  SlideToggleComponent,
  TruncateTextComponent,
} from '@qv-shared/components';
import { IconComponent } from '@qv-shared/components/icon/icon.component';
import { FormControlFocusDirective } from './directives/form-control-focus.directive';
import { NgSelectModule } from '@ng-select/ng-select';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { ViewSwitcherComponent } from '@qv-shared/components/view-switcher/view-switcher.component';
import { ContentLoaderModule } from '@netbasal/ngx-content-loader';
import { EmptyScenariosMessageComponent } from './components/empty-scenarios-message/empty-scenarios-message.component';
import { ModalModule } from 'quantuvis-angular-common/modal';
import { AngularComponentsCommonModule } from 'angular-components-common';

@NgModule({
  imports: [
    FormsModule,
    ReactiveFormsModule,
    AngularComponentsCommonModule,
    CommonModule,
    MatButtonModule,
    MatMenuModule,
    MatIconModule,
    MatTooltipModule,
    MatAutocompleteModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatExpansionModule,
    MatDialogModule,
    BlockUIModule,
    EditorModule,
    MatIconModule,
    MatTooltipModule,
    NgSelectModule,
    MatCheckboxModule,
    MatButtonToggleModule,
    ContentLoaderModule,
    ModalModule,
  ],
  declarations: [
    CheckboxListComponent,
    ClickOutsideDirective,
    SlideToggleComponent,
    DropdownComponent,
    BgColorDirective,
    NumberMaxPipe,
    ActionButtonComponent,
    MenuItemButtonComponent,
    DatePickerComponent,
    ExpansionFormPanelComponent,
    ContractDateConflictsModalComponent,
    HtmlEditorComponent,
    IconComponent,
    HttpUrlPipe,
    BooleanChoicePipe,
    ReadMoreDirective,
    NumberFixedPipe,
    DateTimeUserTzPipe,
    DateTimeUtcPipe,
    LockComponent,
    ListPanelHeaderSearchComponent,
    FormControlFocusDirective,
    OtherChipsPipe,
    SecondsToTimePipe,
    ErrorIconComponent,
    EmptyComponent,
    FilterMenuComponent,
    ViewSwitcherComponent,
    CheckboxListPlaceholderComponent,
    NoItemsMessageComponent,
    CheckboxListWrapperComponent,
    FilterSnackBarComponent,
    EmptyScenariosMessageComponent,
    DateRangeFilterComponent,
    TruncateTextComponent,
    LoadingRingComponent,
  ],
  providers: [
    AssetService,
    DomManagerService,
    { provide: APP_INITIALIZER, useFactory: assetServiceFactory, deps: [AssetService], multi: true },
    { provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' }
  ],
  exports: [
    CheckboxListComponent,
    ClickOutsideDirective,
    FilterMenuComponent,
    SlideToggleComponent,
    DropdownComponent,
    BgColorDirective,
    NumberMaxPipe,
    OtherChipsPipe,
    ActionButtonComponent,
    MenuItemButtonComponent,
    DatePickerComponent,
    ExpansionFormPanelComponent,
    ContractDateConflictsModalComponent,
    IconComponent,
    HtmlEditorComponent,
    HttpUrlPipe,
    BooleanChoicePipe,
    ReadMoreDirective,
    NumberFixedPipe,
    DateTimeUserTzPipe,
    DateTimeUtcPipe,
    SecondsToTimePipe,
    LockComponent,
    ListPanelHeaderSearchComponent,
    FormControlFocusDirective,
    MatCheckboxModule,
    ErrorIconComponent,
    ViewSwitcherComponent,
    CheckboxListPlaceholderComponent,
    NoItemsMessageComponent,
    CheckboxListWrapperComponent,
    FilterSnackBarComponent,
    EmptyScenariosMessageComponent,
    DateRangeFilterComponent,
    TruncateTextComponent,
    LoadingRingComponent
  ],
})
export class SharedModule {}
