import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { resources } from '@qv-common/static';
import { DrugPasteValuesModalData } from '@qv-bid/components/shared/drug-paste-values-modal/models';
import { GeneralModalComponent } from 'quantuvis-angular-common/modal';

@Component({
  selector: 'qv-drug-paste-values-modal',
  templateUrl: './drug-paste-values-modal.component.html',
  styleUrls: ['./drug-paste-values-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DrugPasteValuesModalComponent extends GeneralModalComponent {
  public readonly copyScenarioResources = resources.POPUPS.COPY_SCENARIO;

  constructor(public dialogRef: MatDialogRef<DrugPasteValuesModalComponent>,
              @Inject(MAT_DIALOG_DATA) public modalData: DrugPasteValuesModalData | any) {
    super(dialogRef, modalData);
  }
}
