import { Injectable } from '@angular/core';
import { BidListTableColumnName } from '@qv-bid-list/enums';
import { CheckboxFilter } from '@qv-shared/classes';
import { BidListRemoteDataSource } from '@qv-bid-list/components/bid-list-table/data-source';
import { BidListDaoService } from '@qv-bid-list/services/dao';
import { NotificationService } from 'quantuvis-angular-common/notification';
import { BidListFilterState } from '@qv-bid-list/models';
import { Observable } from 'rxjs';
import { FilterOption } from '@qv-common/entities';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BidListEventBusService } from '@qv-bid-list/services/bid-list-event-bus.service';

@UntilDestroy()
@Injectable()
export class BidListCheckboxFilterService extends CheckboxFilter<BidListTableColumnName> {
  public filterStorageWaitingForApply = new BidListFilterState();

  constructor(
    private bidListRemoteDataSource: BidListRemoteDataSource,
    private bidListDaoService: BidListDaoService,
    protected notificationService: NotificationService,
    private bidListEventBusService: BidListEventBusService,
  ) {
    super(BidListTableColumnName.getCheckboxColumnNames(), notificationService);
    this.initResetFilterHandler();
  }

  public onFilterApply(name: BidListTableColumnName): void {
    const filterStateForUpdate = this.bidListRemoteDataSource.getFilterStateForUpdate(
      BidListTableColumnName.stateValue(name), this.filterStorageWaitingForApply
    );
    this.bidListRemoteDataSource.filterChanged(filterStateForUpdate);
  }

  public getFilterState(name: BidListTableColumnName): (string | number)[] {
    return this.bidListRemoteDataSource.getFilterValue(BidListTableColumnName.stateValue(name)) as (string | number)[];
  }

  protected updateFilterOptions(name: BidListTableColumnName): Observable<FilterOption[]> {
    const filterState = Object.assign(new BidListFilterState(), this.bidListRemoteDataSource.state$.value.filter);
    delete filterState[BidListTableColumnName.stateValue(name)];
    this.updateFilterState(name, this.getFilterState(name));

    return this.bidListDaoService.updateFilterOptions(name, filterState);
  }

  protected updateFilterState(name: BidListTableColumnName, filterValue: (string | number)[]): void {
    this.filterStorageWaitingForApply = Object.assign(this.filterStorageWaitingForApply, {
      [BidListTableColumnName.stateValue(name)]: filterValue
    });
  }

  protected initResetFilterHandler(): void {
    this.bidListEventBusService.resetFiltersEvent.pipe(untilDestroyed(this)).subscribe(() => this.resetFilter());
  }

  protected resetFilter(): void {
    super.resetFilter();
    this.filterStorageWaitingForApply = new BidListFilterState();
    this.bidListRemoteDataSource.filterChanged(null);
  }
}
