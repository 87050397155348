import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export interface ElSubjectAndObserver {
  elSubject: Subject<Element>;
  observer: MutationObserver;
}

@Injectable()
export class DomManagerService {

  constructor(@Inject(DOCUMENT) private document: Document) { }

  public static isElementVisible(el: Element): boolean {
    return getComputedStyle(el).display === 'none';
  }

  public subscribeOnElementAppears(input: string | HTMLElement): ElSubjectAndObserver {
    const elSubject = new Subject<Element>();
    const observer = new MutationObserver(() => this.checkElementExists(input, elSubject));

    observer.observe(this.document.documentElement, {
      childList: true,
      subtree: true
    });

    this.checkElementExists(input, elSubject);

    return { elSubject, observer };
  }

  public subscribeOnDisappearElement(container: Element, elementClassName: string): Promise<any> {
    return new Promise<any>((resolve) => {
      const observer = new MutationObserver((mutations: Array<MutationRecord>) => {
        mutations.forEach((mutation: MutationRecord) => {
          const removedNodes = mutation.removedNodes as NodeList;
          Array.from(removedNodes).forEach((el: Element) => {
            const classList = el.classList;
            if (classList instanceof DOMTokenList && classList.contains(elementClassName)) {
              resolve(el);
            }
          });
        });
        observer.disconnect();
      });

      observer.observe(container, {
        childList: true,
        subtree: true
      });
    });
  }

  public performOperationOnElements(selectors: string[], func: (element: Element) => void): void {
    selectors.forEach(selector => {
      const elements = this.document.querySelectorAll(selector);
      for (let i = 0; i < elements.length; i++) {
        func(elements.item(i));
      }
    });
  }

  private checkElementExists(input: string | Element, elSubject: Subject<Element>): void {
    const element = typeof input === 'string' ? this.document.querySelector(input) : input;
    if (element) {
      elSubject.next(element);
    }
  }
}
