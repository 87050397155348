import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';
import { VirtualScrollerService } from '@qv-bid/services/virtual-scroller.service';
import { SectionFilter } from '@qv-bid/enums';
import { SectionFilterCheckboxes } from '@qv-bid/models';

@Injectable()
export class SectionFiltersService {
  public sectionsFilters$ = new BehaviorSubject<SectionFilterCheckboxes>(
    {
      [SectionFilter.ALL]: {
        title: 'All',
        checked: true,
        order: 1
      },
      [SectionFilter.CONTRACT_TERMS]: {
        title: 'Contract Terms',
        checked: true,
        order: 2
      },
      [SectionFilter.UM]: {
        title: 'Utilization Management',
        checked: true,
        order: 3
      },
      [SectionFilter.MARKET_SHARE]: {
        title: 'Market Share',
        checked: true,
        order: 4
      },
      [SectionFilter.PRICE_PROTECTION]: {
        title: 'Price Protection',
        checked: true,
        order: 5
      },
      [SectionFilter.DISMISSED]: {
        title: 'Dismissed',
        checked: true,
        order: 6
      }
    }
  );

  constructor(private virtualScrollerService: VirtualScrollerService) {}

  public filterChanged(filterKey: SectionFilter, checked: boolean): void {
    this.sectionsFilters$.pipe(take(1)).subscribe(
      sectionFilters => {
        if (filterKey === SectionFilter.ALL) {
          this.handleAllFilter(sectionFilters, checked);
        } else {
          this.handleRegularFilter(sectionFilters, filterKey, checked);
        }

        this.virtualScrollerService.invalidateAllCachedMeasurements.next();
      }
    );
  }

  private handleAllFilter(sectionFilters: SectionFilterCheckboxes, checked: boolean): void {
    this.sectionsFilters$.next(this.setAllFiltersCheck(sectionFilters, checked));
  }

  private setAllFiltersCheck(sectionFilters: SectionFilterCheckboxes, checked: boolean): any {
    return Object.keys(sectionFilters).reduce((sum, val): any => {
      sum[val] = { ...sectionFilters[val], checked };

      return sum;
    }, {});
  }

  private handleRegularFilter(sectionFilters: SectionFilterCheckboxes, filterKey: SectionFilter, checked: boolean):
    void {
    const currentFilterValue = sectionFilters[filterKey];

    this.sectionsFilters$.next({
      ...sectionFilters,
      ...this.calculateAllFilter(filterKey, checked, sectionFilters),
      [filterKey]: {
        ...currentFilterValue,
        checked
      }
    });
  }

  private calculateAllFilter(filterKey: SectionFilter, checked: boolean, sectionFilters: SectionFilterCheckboxes):
    SectionFilterCheckboxes {
    const currentAllFilterValue = sectionFilters[SectionFilter.ALL];

    const isAllFilterChecked = Object.keys(sectionFilters)
      .every(key => {
        if (SectionFilter[key] === SectionFilter.ALL) return true;

        return SectionFilter[key] === filterKey ? checked : sectionFilters[key].checked;
      });

    return { [SectionFilter.ALL]: { ...currentAllFilterValue, checked: isAllFilterChecked } };
  }
}
