import { DateTerm } from '@qv-term/models/types';
import { KeepOriginalValue, Reset } from '@qv-common/interfaces';
import { constants, resources } from '@qv-common/static';

export class ContractEndDateTerm extends DateTerm implements Reset, KeepOriginalValue {
  public allowKeepOriginValue: boolean;
  public processKeepOriginalValue: boolean;
  public keepOriginalValue: boolean;

  constructor(name: string) {
    super(
      name,
      name,
      resources.TermsDescriptions.SUMMARY.CONTRACT_END_DATE,
      constants.SUMMARY_TERMS.CONTRACT_END_DATE.label,
      null,
      null,
      'term__contract-end-date',
      true
    );

    this.keepOriginalValue = this.allowKeepOriginValue;
    this.processKeepOriginalValue = false;
  }

  public getValueToSave(): any {
    if (this.processKeepOriginalValue && this.keepOriginalValue) {
      return resources.NewBid.KEEP_ORIGINAL_VALUE;
    }

    return super.getValueToSave();
  }

  public resetValue(): void {
    this.value = this.defaultValue;
    if (this.processKeepOriginalValue) {
      this.keepOriginalValue = true;
      this.placeholder = resources.NewBid.KEEP_ORIGINAL_VALUE;
    }
  }
}
