<div class="checkbox-list">
  <mat-checkbox
    *ngIf="!hideAllCheckbox"
    [checked]="isAllItemSelected"
    [class]="itemClass"
    [disabled]="isSelectionDisabled || !!isNumberOfSelectedItemsExceedMaximumNumberOfItemsToSelect"
    [indeterminate]="isIndeterminateState()"
    class="acc-checkbox acc-checkbox--default acc-checkbox--primary acc-checkbox--all"
    (change)="onAllItemsChange($event)"
  >All
  </mat-checkbox>
  <div
    *ngFor="let item of items; trackBy: trackById"
    [matTooltip]="!!isNumberOfSelectedItemsExceedMaximumNumberOfItemsToSelect && !isItemSelected(item) && !!maximumNumberOfItemsToSelectTooltip
    ? maximumNumberOfItemsToSelectTooltip
    : itemsTooltips.get(item.id) || item.name"
    matTooltipClass="tooltip-multiline">
    <mat-checkbox
      [checked]="isItemSelected(item)"
      [class]="itemClass"
      [disabled]="isSelectionDisabled || !!isNumberOfSelectedItemsExceedMaximumNumberOfItemsToSelect && !isItemSelected(item)"
      [name]="item.name"
      class="acc-checkbox acc-checkbox--default acc-checkbox--text-overflow"
      (change)="onItemChange($event, item)"
    >{{ item.name || "Empty" }}</mat-checkbox>
  </div>
</div>
