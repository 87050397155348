import { InputTerm } from '@qv-term/models/types';
import { ValidateNumberOptions } from '@qv-common/models/core';
import { Comparator, KeepOriginalValue, Reset } from '@qv-common/interfaces';
import { CoreUtils, NumberUtils, StringUtils } from '@qv-common/utils';
import { constants, resources } from '@qv-common/static';

export class PharmaAuditLockBackTerm extends InputTerm implements Reset, KeepOriginalValue, Comparator {
  public allowKeepOriginValue: boolean;
  public processKeepOriginalValue: boolean;
  public keepOriginalValue: boolean;

  constructor(name: string) {
    super(
      name,
      name,
      resources.TermsDescriptions.CONTRACT.PHARMA_AUDIT_TERMS_LOOK_BACK,
      constants.CONTRACT_TERMS.PHARMA_AUDIT_TERMS_LOOK_BACK.label,
      '',
      '',
      'term__pharma-audit-prior-notice'
    );
    this.keepOriginalValue = this.allowKeepOriginValue;
    this.processKeepOriginalValue = false;
    this.suffix = this.unitsService.months;
  }

  public getValueToSave(): string {
    // TODO need add KeepOriginal logic
    return super.getValueToSave();
  }

  public resetValue(): void {
    // TODO need add KeepOriginal logic
    this.value = this.defaultValue;
  }

  public getTermValueForComparison(): string {
    return CoreUtils.isDefined(this.value) && !StringUtils.isEmpty(this.value) ? `${this.value} ${this.suffix}` : '';
  }

  public validateNumber(): void {
    const pharmaAuditNumber = constants.PHARMA_AUDIT_MAX_NUMBER;
    const options = new ValidateNumberOptions(0, 1, pharmaAuditNumber, null);
    this.value = NumberUtils.correctNumber(options, this.value);
  }
}
