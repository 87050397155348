import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { constants, resources } from '@qv-common/static';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { String as StringOperations } from 'typescript-string-operations';
import { Observable } from 'rxjs';
import { FeedbackDaoService } from '@qv-terms-and-conditions/services/dao/feedback-dao.service';
import { BaseInsideFormModal } from 'quantuvis-angular-common/modal';
import { FormValidationError } from '@qv-common/enums';

@Component({
  selector: 'qv-contact-us-modal',
  templateUrl: './contact-us-modal.component.html',
  styleUrls: ['./contact-us-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactUsModalComponent extends BaseInsideFormModal {
  public readonly constants = constants;
  public readonly contactCoordinates = constants.APP_CONFIG.CONTACT;
  public readonly subjectValidationMessages = new Map<string, string>([
    [FormValidationError.REQUIRED, resources.Feedback.EMPTY_SUBJECT_ERROR_MESSAGE],
    [FormValidationError.MAX_LENGTH, StringOperations.Format(
      resources.Feedback.SUBJECT_MAX_LENGTH_ERROR_MSG,
      constants.FEEDBACK_SUBJECT_MAX_LENGTH
    )]
  ]);
  public readonly feedbackValidationMessages = new Map<string, string>([
    [FormValidationError.REQUIRED, resources.Feedback.EMPTY_FEEDBACK_ERROR_MESSAGE],
    [FormValidationError.MAX_LENGTH, StringOperations.Format(
      resources.Feedback.FEEDBACK_MAX_LENGTH_ERROR_MSG,
      constants.MESSAGE_MAX_LENGTH
    )]
  ]);

  public payloadData: { subject: string };

  constructor(
    private feedbackDaoService: FeedbackDaoService,
    @Inject(MAT_DIALOG_DATA) public modalData: { componentPayload: { subject: string } }
  ) {
    super();
    this.form = this.createForm(this.modalData.componentPayload.subject);
  }

  public onSubmit(): Observable<any> {
    const data = {
      subject: this.form.get('subject').value,
      feedback: this.form.get('feedback').value,
    };

    return this.feedbackDaoService.send(data);
  }

  private createForm(subject: any): FormGroup {
    return new FormGroup({
      subject: new FormControl(subject, [
        Validators.required.bind(this),
        Validators.maxLength(constants.FEEDBACK_SUBJECT_MAX_LENGTH)
      ]),
      feedback: new FormControl('', [
        Validators.required.bind(this),
        Validators.maxLength(constants.MESSAGE_MAX_LENGTH)
      ])
    });
  }
}
