/* tslint:disable:no-invalid-this */
import { resources } from '@qv-common/static';

class ManufacturerDrugsPickerController {
  public static $inject = ['$scope', 'standards', 'CreateInternalBidService'];
  private $scope;
  private standards;
  private CreateInternalBidService;
  private onValid;

  constructor($scope, standards, CreateInternalBidService) {
    'ngInject';

    this.$scope = $scope;
    this.standards = standards;
    this.CreateInternalBidService = CreateInternalBidService;
  }

  public $onInit(): void {
    const DRUGS_EVALUATED_ENUM = {
      ALL_MANUFACTURERS_SOME_DRUGS: 1,
      SOME_MANUFACTURERS_ALL_DRUGS: 2,
      MANUFACTURER_DRUGS: 4,

      setSelectedValue(value): any {
        this.selectedValue = value;
      },
      getSelectedValue(): any {
        return this.selectedValue;
      }
    };

    this.standards.apply(this.$scope, {
      constructor: () => {},
      variables: {
        DRUGS_EVALUATED_ENUM, // DRUGS_EVALUATED_ENUM enum
        focusSearchDrug: false, // flag used to focus search drug input
        drugs: [], // Drug list fetched from the server
        selectedDrugs: [], // List of selected drugs
        noDrugResult: false, // no drug result flag
        searchedDrugs: {}, // object for drug search
        searching: false, // Searching in progress flag
      },
    });

    this.$scope.DRUGS_EVALUATED_ENUM.setSelectedValue(this.$scope.DRUGS_EVALUATED_ENUM.MANUFACTURER_DRUGS);
  }

  public $onChanges(changes): void {
    if (changes.runValidation && changes.runValidation.currentValue) {
      const isValid = this.isValid();
      if (isValid) {
        this.CreateInternalBidService.drugList = this.$scope.selectedDrugs;
      }
      this.onValid({ value: isValid });
    }
  }

  /**
   * Validate manufacture picker
   *
   * @returns {boolean}
   */
  public isValid(): boolean {
    if (this.$scope.selectedDrugs.length === 0) {
      this.CreateInternalBidService.pickerError = resources.NewBid.SelectDrugError;
      return false;
    } else {
      this.CreateInternalBidService.pickerError = '';
      return true;
    }
  }

  /**
   * Cleanup Picker data for picker controller
   */
  public cleanupPickersData(): void {
    this.$scope.focusSearchDrug = [];
    this.$scope.drugs = [];
    this.$scope.selectedDrugs = [];
    this.$scope.searching = false;
    this.$scope.noDrugResult = false;
    this.$scope.searchedDrugs = false;
    this.CreateInternalBidService.pickerError = '';
  }

  public $onDestroy(): void {
    this.cleanupPickersData();
  }

}

export default ManufacturerDrugsPickerController;
