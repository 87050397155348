import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BidSendBestAndFinalModalData } from './bid-send-best-and-final-modal-data';
import {
  BidSendBestAndFinalModalComponent
} from '@qv-bid/components/shared/bid-send-best-and-final-modal/bid-send-best-and-final-modal.component';
import { ModalConfig, ModalSize } from 'quantuvis-angular-common/modal';

export class BidSendBestAndFinalModalConfig
  extends ModalConfig<BidSendBestAndFinalModalComponent, BidSendBestAndFinalModalData> {

  constructor(modalData: BidSendBestAndFinalModalData) {
    super(modalData, ModalSize.X_LARGE);
  }

  protected createDialogRef(dialog: MatDialog): MatDialogRef<BidSendBestAndFinalModalComponent> {
    return dialog.open(BidSendBestAndFinalModalComponent, this);
  }
}
