import { InputTerm } from '@qv-term/models/types';
import { Comparator } from '@qv-common/interfaces';
import { CoreUtils } from '@qv-common/utils';
import { resources } from '@qv-common/static';

export class MinimumBaseRebateTerm extends InputTerm implements Comparator {
  public doNotCopyForCreateScenario: boolean;

  constructor(name: string) {
    super(
      name,
      name,
      resources.TermsDescriptions.DRUG_LIST.MIN_BASE_REBATE,
      '',
      '',
      '',
      'term__minimum-base-rebate'
    );

    this.suffix = this.unitsService.percent;
    this.doNotCopyForCreateScenario = true;
  }

  public getTermValueForComparison(): string {
    return CoreUtils.isDefined(this.value) ? `${this.value} ${this.suffix}` : '';
  }
}
