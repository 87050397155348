import { Inject, Injectable, Optional } from '@angular/core';
import { NAVIGATION_TOKEN } from '../tokens/navigation.token';

@Injectable()
export class NavigationService {
  private publicRoutes: string[] = [];

  constructor(@Inject(NAVIGATION_TOKEN) @Optional() private readonly routesList: string[]) {
    this.publicRoutes = this.routesList || [];
  }

  public isPublicPage(page: string = window.location.hash): boolean {
    return this.publicRoutes.some((route: string) => page.includes(route));
  }
}
