/* tslint:disable:prefer-template */
import { constants } from '@qv-common/static';
import { QuantuvisBusinessFeature, QuantuvisPlusFeature } from '@qv-company/enums';
import { ApiUrlPrefix } from '@qv-common/enums';
import { first, filter, take, skip, tap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { HttpStatusCode } from 'quantuvis-angular-common/api';

declare let angular: angular.IAngularStatic;

// @ts-ignore
import templateUrl from './dashboard.html';
import { UserService } from '@qv-common/services/auth/user.service';
import { AuthService } from 'quantuvis-angular-common/auth';

const DashboardComponent = {
  template: templateUrl,
  bindings: {},
  controller: class {
    public static $inject = ['$scope', 'permissionService', 'translations', 'preferences', 'util', 'apiService',
      '$window', 'router', 'authService', 'sharedEventBusService', 'userService'];

    constructor(
      $scope, permissionService, translations, preferences, util, apiService, $window, router,
      authService: AuthService, sharedEventBusService, userService: UserService
    ) {
      'ngInject';
      translations.buildTranslations($scope, 'DASHBOARD');
      $scope.isFeatureAllowed = permissionService.isFeatureAllowed.bind(permissionService);
      $scope.featureConstants = Object.assign({},
        QuantuvisPlusFeature,
        QuantuvisBusinessFeature
      );
      $scope.showBindingStats = true;
      $scope.router = router;

      const getUser = () => {
        return userService.user.getValue();
      };
      const getUserType = () => {
        return getUser().role.type.toLowerCase();
      };

      const refreshUserFeatures = () => {
        permissionService.reloadUserPermissions();

        return permissionService.userFeatures$.pipe(
          skip(1),
          take(1)
        );
      };

      const getStatusGraphStats = () => {

        if (getUser() && !permissionService.isAllowed('quantuvis_administrator')) {

          /**
           * Get template object with default values and set order
           * @param order
           * @returns {{count: number, order: *, percentage: number, statusName: string}}
           */
          // @ts-ignore
          function getTplDefaults(order?, binding?): { percentage: 0; count: 0; binding: any; order: any } {
            binding = binding || false;
            return {
              count: 0,
              order,
              percentage: 0,
              binding
            };
          }

          /**
           * Generate Template Object for status graph
           * @param userType
           * @param showBindingStats
           * @returns {Object} - template object for status graph with default values;
           *                     Object's key will be the displayed status name
           */
          // @ts-ignore
          function buildStatusGraphTpl(userType): any {
            const isPharma = userType === constants.RoleTypes.PHARMA.toLowerCase(),
              sfxFinal = ' ' + constants.BidStatusSuffix.FINAL,
              sfxBinding = ` ${constants.BidStatusDisplayNameMap.BINDING}`,
              statusGraphTemplate: any = {};

            if (!isPharma) {

              // RFP Not Sent
              statusGraphTemplate[constants.BidStatusDisplayNameMap.RFP_NOT_SENT] = getTplDefaults(0);
              if ($scope.showBindingStats) {
                statusGraphTemplate[constants.BidStatusDisplayNameMap.RFP_NOT_SENT + sfxBinding] =
                  getTplDefaults(1, true);
              }

              // RFP Sent
              statusGraphTemplate[constants.BidStatusDisplayNameMap.RFP_SENT] = getTplDefaults(2);
              if ($scope.showBindingStats) {
                statusGraphTemplate[constants.BidStatusDisplayNameMap.RFP_SENT + sfxBinding] =
                  getTplDefaults(3, true);
              }
            }
            // RFP Received
            if (isPharma) {
              statusGraphTemplate[constants.BidStatusDisplayNameMap.RFP_RECEIVED] = getTplDefaults(2);
              if ($scope.showBindingStats) {
                statusGraphTemplate[constants.BidStatusDisplayNameMap.RFP_RECEIVED + sfxBinding] =
                  getTplDefaults(3, true);
              }
            }
            // Bid Draft
            statusGraphTemplate[constants.BidStatusDisplayNameMap.DRAFT] = getTplDefaults(4);
            if ($scope.showBindingStats) {
              statusGraphTemplate[constants.BidStatusDisplayNameMap.DRAFT + sfxBinding] =
                getTplDefaults(5, true);
            }

            // Bid Received
            statusGraphTemplate[constants.BidStatusDisplayNameMap.ACCEPTED_WITH_CHANGES[1]] = getTplDefaults(6);
            if (!isPharma) {
              statusGraphTemplate[constants.BidStatusDisplayNameMap.ACCEPTED_WITH_CHANGES[1] + sfxFinal] =
                getTplDefaults(7);
            }
            if ($scope.showBindingStats) {
              statusGraphTemplate[constants.BidStatusDisplayNameMap.ACCEPTED_WITH_CHANGES[1] + sfxBinding] =
                getTplDefaults(8, true);
              if (!isPharma) {
                statusGraphTemplate[constants.BidStatusDisplayNameMap.ACCEPTED_WITH_CHANGES[1] + sfxFinal + sfxBinding] =
                  getTplDefaults(9, true);
              }
            }

            // Bid Sent
            statusGraphTemplate[constants.BidStatusDisplayNameMap.ACCEPTED_WITH_CHANGES[0]] = getTplDefaults(10);
            if (isPharma) {
              statusGraphTemplate[constants.BidStatusDisplayNameMap.ACCEPTED_WITH_CHANGES[0] + sfxFinal] =
                getTplDefaults(11);
            }
            if ($scope.showBindingStats) {
              statusGraphTemplate[constants.BidStatusDisplayNameMap.ACCEPTED_WITH_CHANGES[0] + sfxBinding] =
                getTplDefaults(12, true);
              if (isPharma) {
                statusGraphTemplate[constants.BidStatusDisplayNameMap.ACCEPTED_WITH_CHANGES[0] + sfxFinal + sfxBinding] =
                  getTplDefaults(13, true);
              }
            }

            // Bid Review
            if (!isPharma) {
              statusGraphTemplate[constants.BidStatusDisplayNameMap.REVIEW] = getTplDefaults(14);
              if ($scope.showBindingStats) {
                statusGraphTemplate[constants.BidStatusDisplayNameMap.REVIEW + sfxBinding] = getTplDefaults(15, true);
              }
            }

            // Bid Declined
            statusGraphTemplate[constants.BidStatusDisplayNameMap.DECLINED] = getTplDefaults(14);
            if ($scope.showBindingStats) {
              statusGraphTemplate[constants.BidStatusDisplayNameMap.DECLINED + sfxBinding] = getTplDefaults(15, true);
            }

            // Bid Accepted
            statusGraphTemplate[constants.BidStatusDisplayNameMap.ACCEPTED] = getTplDefaults(16);
            if ($scope.showBindingStats) {
              statusGraphTemplate[constants.BidStatusDisplayNameMap.ACCEPTED + sfxBinding] = getTplDefaults(17, true);
            }

            // ignored
            statusGraphTemplate.ignored = getTplDefaults(18);

            return statusGraphTemplate;
          }


          /**
           * Add item's count and percentage to the current acumulated value for a status
           * @param item
           * @param currentStat
           * @returns {count: number, percentage: number} -
           */
          // @ts-ignore
          function updateItemStats(item, currentStat): { percentage: 0; count: 0 } {
            const updated = currentStat || { count: 0, percentage: 0 };
            if (currentStat) {
              updated.count += item.count;
              updated.percentage += item.percentage;
            }
            return updated;
          }

          /**
           * Based on the template, build object that will accumulate the totals for each different status
           * @param rawStats - data retrieved from API
           * @param userType - current user's type
           * @param statusTemplate - output template
           * @returns {Object} - finalStats - array based on template with calculated totals
           */
          // @ts-ignore
          function calculateStatuses(rawStats, userType, statusTemplate): any[] {
            const finalStats = angular.copy(statusTemplate);
            const isPharma = userType === constants.RoleTypes.PHARMA.toLowerCase();

            rawStats.forEach(item => {
              if (item) {
                switch (item.status) {
                  case constants.BidStatuses.RFP_NOT_SENT:
                    item.statusDisplayName = isPharma ? 'ignored' :
                      constants.BidStatusDisplayNameMap.RFP_NOT_SENT;
                    if (item.isBinding) {
                      item.statusDisplayName += ` ${constants.BidStatusDisplayNameMap.BINDING}`;
                    }
                    break;

                  case constants.BidStatuses.RESPONSE_REQUESTED:
                    item.statusDisplayName = userType !== item.assignee.toLowerCase() ?
                      constants.BidStatusDisplayNameMap.RESPONSE_REQUESTED[0] :
                      constants.BidStatusDisplayNameMap.RESPONSE_REQUESTED[1];
                    if (item.isBinding) {
                      item.statusDisplayName += ` ${constants.BidStatusDisplayNameMap.BINDING}`;
                    }
                    break;

                  case constants.BidStatuses.DRAFT:
                    item.statusDisplayName = constants.BidStatusDisplayNameMap.DRAFT;
                    if (item.isBinding) {
                      item.statusDisplayName += ` ${constants.BidStatusDisplayNameMap.BINDING}`;
                    }
                    break;

                  case constants.BidStatuses.ACCEPTED_WITH_CHANGES:
                    if (item.inReview && !isPharma) {
                      item.statusDisplayName = constants.BidStatusDisplayNameMap.REVIEW;
                    } else {
                      item.statusDisplayName = userType !== item.assignee.toLowerCase() ?
                        constants.BidStatusDisplayNameMap.ACCEPTED_WITH_CHANGES[0] :
                        constants.BidStatusDisplayNameMap.ACCEPTED_WITH_CHANGES[1];
                      if (item.finalBid) {
                        item.statusDisplayName += ' ' + constants.BidStatusSuffix.FINAL;
                      }
                    }
                    if (item.isBinding) {
                      item.statusDisplayName += ` ${constants.BidStatusDisplayNameMap.BINDING}`;
                    }
                    break;

                  case constants.BidStatuses.ACCEPTED:
                    item.statusDisplayName = constants.BidStatusDisplayNameMap.ACCEPTED;
                    if (item.isBinding) {
                      item.statusDisplayName += ` ${constants.BidStatusDisplayNameMap.BINDING}`;
                    }
                    break;

                  case constants.BidStatuses.DECLINED:
                    item.statusDisplayName = constants.BidStatusDisplayNameMap.DECLINED;
                    if (item.isBinding) {
                      item.statusDisplayName += ` ${constants.BidStatusDisplayNameMap.BINDING}`;
                    }
                    break;

                  default:
                    item.statusDisplayName = 'ignored';

                }

                finalStats[item.statusDisplayName] = updateItemStats(item, finalStats[item.statusDisplayName]);
              }
            });

            // Map finalStats object into an array that includes it's key, which is the statusDisplayLabel
            return Object.entries(finalStats).map(([key, val]: [string, any]) => {
              val.label = key;
              return val;
            });
          }

          /**
           * Constructs a full bid status stats array based on web service response provided information.
           * Array elements contain all information required for status statistics display.
           * @param response - the response object from the web service call
           * @returns array of full bid status stats array based on web service response provided information
           */
          // @ts-ignore
          function getFullBidStatusStats(response): any {
            const user = userService.user.getValue();
            const userType = user.role.type.toLowerCase();
            $scope.showBindingStats = (userType === constants.RoleTypes.PAYER.toLowerCase())
              ? user.company.bindingBidEnabled : true;

            return calculateStatuses(response, userType, buildStatusGraphTpl(userType));

          }

          $scope.lineOfBusinessOptions = [
            constants.ALL_OPTION, constants.LINE_OF_BUSINESS.MEDICARE, constants.LINE_OF_BUSINESS.MEDICAID,
            constants.LINE_OF_BUSINESS.COMMERCIAL,
            constants.LINE_OF_BUSINESS.EXCHANGES, constants.LINE_OF_BUSINESS.MEDICAL
          ];

          $scope.lineOfBusiness = preferences.getUserPreference(
            constants.USER_PREFERENCES_DASHBOARD_LINE_OF_BUSINESS_OPTION,
            constants.ALL_OPTION);

          $scope.updateLineOfBusiness = lineOfBusiness => {
            preferences.setUserPreference(constants.USER_PREFERENCES_DASHBOARD_LINE_OF_BUSINESS_OPTION, lineOfBusiness);

            const url = `${ApiUrlPrefix.BIDS}/status-graph?lineOfBusiness=${lineOfBusiness}`;

            apiService.get(url).pipe(first()).toPromise()
              .then(response => {
                if (response && response.body && response.body.data) {
                  $scope.bidStatusStatsResponse = undefined;
                  $scope.bidStatusStats = getFullBidStatusStats(response.body.data);
                } else {
                  $scope.bidStatusStatsResponse = {
                    error: true,
                    message: 'There was an error while retrieving bid status stats. Please try again later.'
                  };
                  $scope.bidStatusStats = undefined;
                }
              })
              .catch((httpErrorResponse: HttpErrorResponse) => {
                if (httpErrorResponse.status === HttpStatusCode.FORBIDDEN) {
                  return refreshUserFeatures().pipe(tap(updateStatusGraph)).toPromise();
                } else {
                  throw httpErrorResponse;
                }
              })
              .catch(response => {
                $scope.bidStatusStatsResponse = response;
                $scope.bidStatusStats = undefined;
              });
          };

          $scope.updateLineOfBusiness($scope.lineOfBusiness);

          sharedEventBusService.myAccountsFilterChangedEvent.subscribe(() => {
            const lineOfBusiness = preferences.getUserPreference(
              constants.USER_PREFERENCES_DASHBOARD_LINE_OF_BUSINESS_OPTION,
              constants.ALL_OPTION
            );
            $scope.updateLineOfBusiness(lineOfBusiness);
          });
        }
      };

      const getEnterpriseBidStatusStats = () => {
        const userType = getUserType();
        const roleTypes = constants.RoleTypes;
        $scope.isPayer = userType === roleTypes.PAYER.toLowerCase();
        $scope.isPharma = userType === roleTypes.PHARMA.toLowerCase();
      };

      const updateStatusGraph = () => {
        if (permissionService.isFeatureAllowed(QuantuvisPlusFeature.STATUS_GRAPH)) {
          getStatusGraphStats();
        } else {
          getEnterpriseBidStatusStats();
        }
      };

      permissionService.permissionsReady$.pipe(
        filter((isReady: boolean) => isReady),
        take(1),
      ).subscribe(updateStatusGraph);
    }
  }
};
export default DashboardComponent;
