/**
 * Custom AngularJS services used by the APP logic
 */
import { resources } from '@qv-common/static';


export const Translations = function() {
  /**
   * Loads the resource bundle file.
   *
   * @param scope the scope where the i18n function is defined
   * @param key string value to load from key
   */
  this.buildTranslations = (scope, key) => {
    // Loading only one part of the resource file specified by the key parameter if the key is specified
    if (key) {
      scope.i18n = resources[key];
      scope.i18n.general = resources.GENERAL;
    } else {
      scope.i18n = resources;
    }
  };

  this.getString = function(key, param) {
    return this.i18n[key][param];
  };

  // Publish all the translations on service scope
  this.i18n = resources;

  return {
    buildTranslations: this.buildTranslations,
    getString: this.getString,
    i18n: this.i18n
  };
};
