<div class="range-rebate-container">
  <div [formGroup]="parts" class="range-rebate-form">
    <span *ngIf="rangePrefix" matPrefix class="range-rebate-form__prefix">{{ rangePrefix }}</span>
    <input #rangeStart matInput [type]="'number'" class="range-rebate-form__input" formControlName="rangeStart"
      (paste)="onResizeInput(rangeStart)"
      (cut)="onResizeInput(rangeStart)"
      (input)="onResizeInput(rangeStart)">
    <span *ngIf="rangeSuffix" matSuffix class="range-rebate-form__suffix">{{ rangeSuffix }}</span>
    <span class="range-rebate-form__spacer">-</span>
    <span *ngIf="rangePrefix" matPrefix class="range-rebate-form__prefix">{{ rangePrefix }}</span>
    <input #rangeEnd matInput [type]="'number'" class="range-rebate-form__input" formControlName="rangeEnd"
      (paste)="onResizeInput(rangeEnd)"
      (cut)="onResizeInput(rangeEnd)"
      (input)="onResizeInput(rangeEnd)">
    <span *ngIf="rangeSuffix" matSuffix class="range-rebate-form__suffix">{{ rangeSuffix }}</span>
    <span class="range-rebate-form__spacer">|</span>
    <input #rebate matInput [type]="'number'" class="range-rebate-form__input" formControlName="rebate"
      (paste)="onResizeInput(rebate)"
      (cut)="onResizeInput(rebate)"
      (input)="onResizeInput(rebate)">
    <span matSuffix class="range-rebate-form__suffix range-rebate-form__suffix--default">%</span>
  </div>
  <button
    mat-icon-button
    class="range-rebate-container__clear"
    [disabled]="isBidInvalid"
    (click)="onRemoveClick($event)">
    <mat-icon matSuffix [svgIcon]="svgIconName.CLOSE"></mat-icon>
  </button>
</div>

