<section class="qv-modal-dialog" *blockUI="'general-modal'">
  <header mat-dialog-title class="qv-modal-dialog-title">
    <h1 class="qv-modal-dialog-title__text">{{ copyScenarioResources.TITLE }}</h1>
    <button class="qv-modal-dialog-title__xbutton" (click)="onSecondaryAction()">
      <mat-icon>close</mat-icon>
    </button>
  </header>
  <div mat-dialog-content class="qv-modal-dialog-content qv-modal-dialog-content--primary-padding qv-modal-dialog-content--font-light">
    <div class="qv-modal-dialog-content--description">{{ copyScenarioResources.DESCRIPTION }}</div>
    <ul class="qv-modal-dialog-content__list">
      <li *ngIf="modalData.isPharma">{{ copyScenarioResources.LOCKED_FIELD }}</li>
      <li>{{ modalData.isPharma ? copyScenarioResources.MARKET_BASKETS : copyScenarioResources.MARKET_BASKETS_PAYER }}</li>
      <li>{{ copyScenarioResources.DEPENDENCIES }}</li>
      <li *ngIf="modalData.isPharma">{{ copyScenarioResources.BASE_REBATE }}</li>
      <li [innerHTML]="copyScenarioResources.NDC_FIELD"></li>
      <li *ngIf="modalData.isPharma">{{ copyScenarioResources.PHARMA_ABILITY }}</li>
    </ul>
  </div>
  <footer mat-dialog-actions class="qv-modal-dialog-actions">
    <button mat-button class="qv-modal-dialog-actions__item" (click)="onSecondaryAction()">
      Cancel
    </button>
    <button mat-button class="qv-modal-dialog-actions__item qv-modal-dialog-actions__item--primary"
      (click)="onPrimaryAction()">
      Paste
    </button>
  </footer>
</section>
