import { InputTerm } from '@qv-term/models/types';
import { ValidateNumberOptions } from '@qv-common/models/core';
import { KeepOriginalValue, Reset } from '@qv-common/interfaces';
import { NumberUtils } from '@qv-common/utils';
import { constants, resources } from '@qv-common/static';

export class NoFaultTerminationClausePriorNoticeRequiredTerm extends InputTerm implements Reset, KeepOriginalValue {
  public allowKeepOriginValue: boolean;
  public processKeepOriginalValue: boolean;
  public keepOriginalValue: boolean;

  constructor(name: string) {
    super(
      name,
      name,
      resources.TermsDescriptions.CONTRACT.NO_FAULT_TERMINATION_CLAUSE_PRIOR_NOTICE_REQUIRED,
      constants.CONTRACT_TERMS.NO_FAULT_TERMINATION_CLAUSE_PRIOR_NOTICE_REQUIRED.label,
      '',
      '',
      'term__no-fault-termination-clause-prior-notice-required'
    );
    this.keepOriginalValue = this.allowKeepOriginValue;
    this.processKeepOriginalValue = false;
    this.suffix = this.unitsService.days;
  }

  public getValueToSave(): string {
    // TODO need add KeepOriginal logic
    return super.getValueToSave();
  }

  public resetValue(): void {
    // TODO need add KeepOriginal logic
    this.value = this.defaultValue;
  }

  public validateNumber(): void {
    const options = new ValidateNumberOptions(null, 0, null, 0.1);
    this.value = NumberUtils.correctNumber(options, this.value);
  }

}
