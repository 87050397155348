import { Router } from '@angular/router';
import { resources } from '@qv-common/static';
import isEqual from 'lodash.isequal';
import { WizardController } from '../shared/wizard/wizard-controller';
import { StringUtils } from '@qv-common/utils';

class CopyBidController extends WizardController {
  public static $inject = ['$scope', 'CopyBidService', '$document', 'router', 'translations', 'spinnerService',
    '$window'];
  public confirmNavigationMessage = resources.BID_DETAILS.CONFIRM_NAVIGATION_COPY_BID;
  public i18n;
  private isWizardValid;
  private errorMessage;
  private copyBids;
  private stepTitles;
  private contractOptions;
  private initValues;
  private allowLocationChange: boolean;

  constructor(private $scope, private CopyBidService, private $document, private router: Router, private translations,
              private spinnerService, private $window) {
    super();
    this.isWizardValid = true;
    this.errorMessage = null;
  }

  public $onInit(): void {
    // This is for stretching child on full height
    this.$document.find('.root-view').addClass('full-height');

    const bidIds = this.router.routerState.snapshot.root.queryParams.ids;
    if (Array.isArray(bidIds)) {
      this.CopyBidService.addCopyBids(bidIds.map(id => parseInt(id, 10)));
    } else if (StringUtils.isString(bidIds)) {
      this.CopyBidService.addCopyBids([parseInt(bidIds, 10)]);
    } else {
      this.isWizardValid = false;
      this.navigateByUrl('/bids');
      return;
    }
    this.translations.buildTranslations(this);
    this.CopyBidService.initCopyBidConfig();
    this.copyBids = this.CopyBidService.getCopyBids();
    this.currentStep = this.firstStepNumber;
    this.steps = ({
      summaryTerms: {
        title: this.i18n.NewBid.WizardTitles.SUMMARY_TERMS,
        order: 1,
        runValidation: false
      },
      contractTerms: {
        title: this.i18n.NewBid.WizardTitles.CONTRACT_TERMS,
        order: 2,
        runValidation: false
      },
      drugLevelTerms: {
        title: this.i18n.NewBid.WizardTitles.DRUG_LEVEL_TERMS,
        order: 3,
        runValidation: false
      }
    } as any);

    this.countSteps = Object.keys(this.steps).length;
    this.stepTitles = this.prepareStepTitles();
    this.contractOptions = {
      lock: true,
      allowResetValues: true,
      allowPreserveOriginalLocks: true
    };

    this.$window.tinymce.on('addeditor', () => {
      this.initValues = this.CopyBidService.getValuesToSave();
    }, true);

    this.emitConfirmNavigationCallback();
  }

  public $onDestroy(): void {
    this.CopyBidService.clearCopyBids();
  }

  public navigateByUrl(url): void {
    this.router.navigateByUrl(url);
  }

  public save(): void {
    this.spinnerService.start(this.i18n.GENERAL.COPYING_BIDS);

    this.CopyBidService.saveBids()
      .then(
        response => {
          if (response && !response.error) {
            this.allowLocationChange = true;
            this.navigateByUrl('/bids');
          } else if (response && response.error) {
            this.errorMessage = response.message;
          }
        },
        response => {
          if (response && response.error) {
            this.errorMessage = response.message;
          }
        }
      )
      .finally(() => {
        this.spinnerService.stop();
      });
  }

  public isNavigationNeedConfirmation(): boolean {
    return !this.allowLocationChange && !isEqual(this.initValues, this.CopyBidService.getValuesToSave());
  }
}

export default CopyBidController;
