import { DrugCb, Scenario } from '@qv-bid/entities';

export class BidDeleteDrugModalData {
  constructor(
    public bidVersionId: number,
    public drugs: DrugCb[],
    public selectedScenarios: Scenario[],
    public isBidInternal: boolean
  ) {}
}
