<section class="bid-info">
  <div class="bid-info-item">
    <span class="bid-info-item__label">Title:</span>
    <span class="bid-info-item__value">{{ summary?.rfpTitle }}</span>
  </div>
  <div class="bid-info-item">
    <span class="bid-info-item__label">Line of Business:</span>
    <span class="bid-info-item__value">{{ summary?.lineOfBusiness }}</span>
  </div>
  <div class="bid-info-item">
    <span class="bid-info-item__label">{{ oppositeCompanyType }}:</span>
    <span class="bid-info-item__value">{{ oppositeCompanyName }}</span>
  </div>
  <div class="bid-info-item">
    <span class="bid-info-item__label">Status:</span>
    <span class="bid-info-item__value">{{ completeBidStatus }}</span>
  </div>
  <div class="bid-info-item">
    <span class="bid-info-item__label">Assigned To:</span>
    <span class="bid-info-item__value">{{ assignedCompanyName }}</span>
  </div>
</section>
