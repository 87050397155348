import { QvCache } from '@qv-common/decorators';
import { resources } from '@qv-common/static';

export class FeatureUtils {
  @QvCache()
  public static getUpgradeMessage(isPharma: boolean): string {
    return isPharma ? resources.GENERAL.UPGRADE_MESSAGE : '';
  }

  @QvCache()
  public static getQuantuvisPlusLink(isPharma: boolean): string {
    return isPharma ? '/quantuvisplus' : null;
  }
}
