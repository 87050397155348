import { ScenarioDependencyModalComponent } from '@qv-bid/components/shared/scenario-dependency-modal/scenario-dependency-modal.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ScenarioDependencyModalData } from './scenario-dependency-modal-data';
import { ModalConfig, ModalSize } from 'quantuvis-angular-common/modal';

export class ScenarioDependencyModalConfig
  extends ModalConfig<ScenarioDependencyModalComponent, ScenarioDependencyModalData> {

  public constructor(data: ScenarioDependencyModalData, width: ModalSize) {
    super(data, width);
  }

  protected createDialogRef(dialog: MatDialog): MatDialogRef<ScenarioDependencyModalComponent> {
    return dialog.open(ScenarioDependencyModalComponent, this);
  }
}
