import { BaseTerm } from '@qv-term/models/types';
import { Attachment } from '@qv-common/models/core';
import { KeepOriginalValue, Reset } from '@qv-common/interfaces';
import { constants, resources } from '@qv-common/static';

export class AttachmentsTerm extends BaseTerm implements Reset, KeepOriginalValue {
  public allowKeepOriginValue: boolean;
  public processKeepOriginalValue: boolean;
  public keepOriginalValue: boolean;
  public placeholder: string;

  constructor(name: string) {
    super(
      name,
      constants.BIDDING.ATTACHMENTS_LABEL,
      resources.TermsDescriptions.SUMMARY.ATTACHMENTS,
      constants.SUMMARY_TERMS.ATTACHMENTS.label,
      [],
      [],
      'term__attachments'
    );
    this.placeholder = constants.PLACEHOLDERS.PATH;
    this.keepOriginalValue = this.allowKeepOriginValue;
    this.processKeepOriginalValue = false;
  }

  public getValueToSave(): Array<Attachment> {
    const values = [];
    if (this.processKeepOriginalValue && this.keepOriginalValue) {
      // return resources.NewBid.KEEP_ORIGINAL_VALUE;
    }

    this.value.forEach(val => {
      if (val.attachmentId && val.attachmentName) {
        values.push(new Attachment(val.attachmentId, val.attachmentName, val.attachmentSize));
      }
    });

    return values;
  }

  public resetValue(): void {
    this.value = [];
    if (this.processKeepOriginalValue) {
      this.keepOriginalValue = true;
      this.placeholder = resources.NewBid.KEEP_ORIGINAL_VALUE;
    }
  }
}
