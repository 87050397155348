import { Moment } from 'moment';
import { BidAssignee, BidStatus } from '../enums';
import { BidEditor } from './bid-editor.entity';
import { Company } from './company.entity';

export class Bid {
  public static readonly FINAL_SUFFIX = 'Final';
  public static readonly BINDING_SUFFIX = '(Binding)';

  public id: number = null;
  public mongoBidId: string = null;
  public title: string = null;
  public assignee: BidAssignee = null;
  public status: BidStatus = null;
  public payer: Company = null;
  public manufacturerCompanyOldName: string = null;
  public isBinding: boolean = null;
  public editRfpSent?: boolean = null;
  public isFinal?: boolean = null;
  public inReview: boolean = null;
  public isArchived: boolean = null;
  public isInternal: boolean = null;
  public manufacturer?: Company = null;
  public editor?: BidEditor = null;
  public lineOfBusiness?: string = null;
  public contractStartDate?: Moment = null;
  public contractEndDate?: Moment = null;
  public dateModified?: Moment = null;
  public rfpDueDate?: Moment = null;

  public isStatusRfpNotSent(): boolean {
    return this.status === BidStatus.RFP_NOT_SENT;
  }

  public isStatusDraft(): boolean {
    return this.status === BidStatus.DRAFT;
  }

  public isStatusResponseRequested(): boolean {
    return this.status === BidStatus.RESPONSE_REQUESTED;
  }

  public isStatusAccepted(): boolean {
    return this.status === BidStatus.ACCEPTED;
  }

  public isStatusAcceptedWithChanges(): boolean {
    return this.status === BidStatus.ACCEPTED_WITH_CHANGES;
  }

  public isStatusDeclined(): boolean {
    return this.status === BidStatus.DECLINED;
  }

  public isStatusReview(): boolean {
    return this.status === BidStatus.REVIEW;
  }

  public isAcceptedWithChangesFinal(): boolean {
    return this.isStatusAcceptedWithChanges() && this.isFinal;
  }

  public isAssignedToPayer(): boolean {
    return this.assignee === BidAssignee.PAYER;
  }

  public isAssignedToPharma(): boolean {
    return this.assignee === BidAssignee.PHARMA;
  }

  public isBidToUserCompanyAssigned(companyId: number): boolean {
    if (this.isAssignedToPharma()) {
      return companyId === this.manufacturer.id;
    }

    if (this.isAssignedToPayer()) {
      return companyId === this.payer.id || (this.isInternal && companyId === this.manufacturer.id);
    }

    return false;
  }

  public hasAllowedStatusToBeFinal(): boolean {
    return [BidStatus.RESPONSE_REQUESTED, BidStatus.ACCEPTED_WITH_CHANGES].includes(this.status);
  }

  public hasAllowedStatusForSignature(): boolean {
    return [BidStatus.RESPONSE_REQUESTED, BidStatus.ACCEPTED_WITH_CHANGES, BidStatus.ACCEPTED, BidStatus.DECLINED]
      .includes(this.status);
  }

  public hasAllowedForOpenDetailsPanel(): boolean {
    return this.isAssignedToPharma()
      && !this.isFinal
      && [BidStatus.RESPONSE_REQUESTED, BidStatus.ACCEPTED_WITH_CHANGES].includes(this.status);
  }

  public getStatus(viewingCompanyId: number, viewPerspective: BidAssignee): string {
    const isTheSameSide = this.isInternal
      ? viewPerspective === this.assignee
      : this.isBidToUserCompanyAssigned(viewingCompanyId);

    return BidStatus.displayValue(this.status, isTheSameSide);
  }

  public getCompleteStatus(
    viewingCompanyId: number,
    isViewingCompanyPayer: boolean,
    viewPerspective: BidAssignee,
    appendBindingSuffix: boolean = true
  ): string {
    let completeStatus = [this.getStatus(viewingCompanyId, viewPerspective)];
    if (this.isFinal && this.hasAllowedStatusToBeFinal()) {
      completeStatus.push(Bid.FINAL_SUFFIX);
    }

    if (this.inReview && this.isStatusAcceptedWithChanges()
      && (isViewingCompanyPayer || viewPerspective === BidAssignee.PAYER)) {
      completeStatus = [BidStatus.displayValue(BidStatus.REVIEW)];
    }

    if (this.isBinding && appendBindingSuffix) {
      completeStatus.push(Bid.BINDING_SUFFIX);
    }

    return completeStatus.join(' ');
  }
}
