/* tslint:disable:no-var-requires */
import DrugLevelController from './drug-level.controller';

// @ts-ignore
import templateUrl from './drug-level.html';

const DrugLevelComponent = {
  template: templateUrl,
  bindings: {
    runValidation: '<',
    onValid: '&'
  },
  controller: DrugLevelController
};

export default DrugLevelComponent;
