/* tslint:disable */
export class Standards {
  static $inject = ['$q'];
  $q;
  callbacks;
  validation;
  doNotClearCallbacks;
  model;
  preventMessage;

  constructor($q: any) {
    'ngInject';
    this.$q = $q;
    this.callbacks = {};
    this.validation = null;
    this.doNotClearCallbacks = true;
    this.model = {};
    this.preventMessage = null;
  }

  getModel(): any {
    return this.model;
  };

  apply(scope: any, enclosingStandards: any): void {
    const deferred = this.$q.defer();
    deferred.promise.then((constructor) => {
      constructor();
    });

    let enclosing;

    if (enclosingStandards.variables) {
      enclosing = enclosingStandards.variables;
      if (typeof enclosing === 'function') {
        enclosing = enclosingStandards.variables();
      }

      Object.keys(enclosing).forEach((key) => scope[key] = enclosing[key]);
    }

    if (enclosingStandards.public) {
      enclosing = enclosingStandards.public;
      if (typeof enclosing === 'function') {
        enclosing = enclosingStandards.public();
      }

      Object.keys(enclosing).forEach((key) => scope[key] = enclosing[key]);
    }

    if (enclosingStandards.constructor) {
      deferred.resolve(enclosingStandards.constructor);
    }

  };

  primary(): void {
    if (this.callbacks.primary) {
      this.callbacks.primary();
    }
    if (!this.doNotClearCallbacks) {
      this.callbacks = {};
    }
  };

  secondary(): void {
    if (this.callbacks.secondary) {
      this.callbacks.secondary();
    }
    if (!this.doNotClearCallbacks) {
      this.callbacks = {};
    }
  };
}
