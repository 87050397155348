import { Inject, Injectable } from '@angular/core';
import { ApiService } from '@qv-common/services/api';
import { JsonConvert } from 'json2typescript';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpResponseWrapper } from '@qv-common/models/api';
import { DrugCb, Scenario } from '@qv-bid/entities';
import { JSON_CONVERT_TOKEN } from 'quantuvis-angular-common/json-converter/services';
import { DrugItem } from '@qv-bid/entities/drug-item.entity';
import { RequestOption } from '@qv-common/services/api/request-option.interface';
import { HttpParams } from '@angular/common/http';
import { SelectedDrugs } from '@qv-bid/models/selected-drugs';
import { ViewPerspectiveService } from '@qv-common/services/auth';
import { HttpUtils } from '@qv-common/utils';
import { ScenarioDrugIds } from '@qv-bid/entities/scenario-drug-ids.entity';
import { ApiUrlPrefix } from '@qv-common/enums';

@Injectable()
export class DrugDaoService {
  constructor(
    private apiService: ApiService,
    @Inject(JSON_CONVERT_TOKEN) private jsonConvert: JsonConvert,
    private viewPerspectiveService: ViewPerspectiveService
  ) {
  }

  public searchAllByDrugNameAndClass(searchText?: string): Observable<Record<string, any>> {
    const url = `${ApiUrlPrefix.OLD}/drugs/search/classandname/all`;
    const params = searchText ? new HttpParams().set('pattern', searchText) : null;

    return this.apiService.get(url, { params }).pipe(
      map((response: HttpResponseWrapper<any>) => response.body.responseObject)
    );
  }

  public searchByPharmaId(id: number): Observable<Record<string, any>> {
    const url = `${ApiUrlPrefix.OLD}/drugs/search/pharmadrugs/byId`;
    const options: RequestOption = {
      params: new HttpParams().set('pharmaId', String(id))
    };
    return this.apiService.get(url, options).pipe(
      map((response: HttpResponseWrapper<any>) => response.body.responseObject)
    );
  }

  public addDrugsToContractedBusiness(bidVersionId: number, cbId: number, drugIds: number[],
                                      inAllCbs: boolean, isBidInternal: boolean): Observable<null> {
    let url = `${ApiUrlPrefix.BIDS}/bid-versions/${bidVersionId}/contracted-businesses/${cbId}/drugs`;

    const perspective = this.viewPerspectiveService.getViewPerspective();
    url = HttpUtils.appendPerspectiveQueryParamToUrlIfBidIsInternal(url, perspective, isBidInternal);

    return this.apiService.post(url, { drugIds, inAllCbs }) as Observable<null>;
  }

  public getAllDrugs(bidId: number): Observable<DrugItem[]> {
    return this.apiService.get(`${ApiUrlPrefix.BIDS}/${bidId}/drugs`).pipe(
      map((response: HttpResponseWrapper<DrugItem[]>) =>
        this.jsonConvert.deserializeArray(response.body.data, DrugItem)
      )
    );
  }

  public getDrugsWithCb(versionId: number): Observable<DrugCb[]> {
    return this.apiService.get(`${ApiUrlPrefix.BIDS}/bid-versions/${versionId}/drugs-with-cb`).pipe(
      map((response: HttpResponseWrapper<DrugCb>) =>
        this.jsonConvert.deserializeArray(response.body.data, DrugCb)
      )
    );
  }

  public deleteDrugs(versionId: number, drugs: DrugCb[], isBidInternal: boolean): Observable<void> {
    let url = `${ApiUrlPrefix.BIDS}/bid-versions/${versionId}/drugs`;
    const perspective = this.viewPerspectiveService.getViewPerspective();
    url = HttpUtils.appendPerspectiveQueryParamToUrlIfBidIsInternal(url, perspective, isBidInternal);

    return this.apiService.delete(url, drugs).pipe(map(() => null));
  }

  public dismissDrugs(versionId: number, selectedDrugs: SelectedDrugs[], isBidInternal: boolean): Observable<null> {
    let url = `${ApiUrlPrefix.BIDS}/bid-versions/${versionId}/scenarios/dismiss`;

    const perspective = this.viewPerspectiveService.getViewPerspective();
    url = HttpUtils.appendPerspectiveQueryParamToUrlIfBidIsInternal(url, perspective, isBidInternal);

    return this.apiService.put(url, selectedDrugs) as Observable<null>;
  }

  public recallDrugs(versionId: number, selectedDrugs: SelectedDrugs[], isBidInternal: boolean): Observable<null> {
    let url = `${ApiUrlPrefix.BIDS}/bid-versions/${versionId}/scenarios/recall`;

    const perspective = this.viewPerspectiveService.getViewPerspective();
    url = HttpUtils.appendPerspectiveQueryParamToUrlIfBidIsInternal(url, perspective, isBidInternal);

    return this.apiService.put(url, selectedDrugs) as Observable<null>;
  }

  public pasteDrugValues(versionId: number, source: Scenario, scenariosIds: number[], drugIds: number[],
                         isBidInternal: boolean): Observable<null> {
    let url = `${ApiUrlPrefix.BIDS}/bid-versions/${versionId}/scenarios/copy`;

    const perspective = this.viewPerspectiveService.getViewPerspective();
    url = HttpUtils.appendPerspectiveQueryParamToUrlIfBidIsInternal(url, perspective, isBidInternal);
    const target = new ScenarioDrugIds(scenariosIds, drugIds);
    return this.apiService.put(url, { source, target }) as Observable<null>;
  }
}
