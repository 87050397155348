import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { CoreUtils, DateUtils, StringUtils } from '@qv-common/utils';
import { Moment } from 'moment/moment';
import { FieldValidationMessage, FormValidationError } from '@qv-common/enums';

import moment from 'moment';
import { appConfig } from '@qv-common/configs';

@Component({
  selector: 'qv-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
})
export class DatePickerComponent implements OnInit, OnChanges {
  @ViewChild('datePickerInput')
  public datePickerInput: ElementRef;
  @Input()
  public value: string | number;
  @Input()
  public useFormField: boolean;
  @Input()
  public group: FormGroup;
  @Input()
  public controlName: string;
  @Input()
  public disabled: boolean;
  @Input()
  public keepOriginalValue: boolean;
  @Input()
  public placeholder = appConfig.dateFormatShort;
  @Input()
  public control = new FormControl();
  @Output()
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  public onChange = new EventEmitter();
  @Output()
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  public onFocus = new EventEmitter();

  public readonly minDate = DateUtils.getMinDatePickerDate();
  public readonly maxDate = DateUtils.getMaxDatePickerDate();
  public readonly validationMessages = new Map<string, string>([
    [FormValidationError.MAT_PICKER_PARSE, FieldValidationMessage.DATE_FORMAT]
  ]);

  private readonly dateFormat = appConfig.dateFormat;

  public ngOnInit(): void {
    this.placeholder = this.placeholder || appConfig.dateFormatShort;

    if (!this.useFormField) {
      this.updateDate(this.value as string);
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.value && CoreUtils.isDefined(changes.value.currentValue)) {
      this.updateDate(changes.value.currentValue);
    }
  }

  public onFocusHandler(event: any): void {
    this.onFocus.emit(event);
  }

  public onDateChanged(event: MatDatepickerInputEvent<Moment>): void {
    const convertedDate = DateUtils.convertDateToStr(event, this.control, this.dateFormat);
    this.updateDateInDatePicker(convertedDate);
    this.onChange.emit(convertedDate);
  }

  public onKeyUp(): void {
    if (this.group && this.controlName) {
      const control = this.group.get(this.controlName);
      control.markAsTouched();
      control.markAsDirty();
      control.updateValueAndValidity();
    }
  }

  private updateDate(value: string, emitEvent: boolean = true): void {
    if (value) {
      const date = moment(new Date(value));
      const formattedDate = DateUtils.getFormattedDate(date, this.dateFormat);

      if (formattedDate && formattedDate.isValid()) {
        this.control.setValue(formattedDate, { emitEvent });
      }
    } else if (StringUtils.isEmpty(value)) {
      this.control.setValue(value, { emitEvent });
    }
  }

  private updateDateInDatePicker(convertedDate: string): void {
    const date = moment(convertedDate, this.dateFormat);
    const formattedDate = DateUtils.getFormattedDate(date, this.dateFormat);

    if (formattedDate && formattedDate.isValid()) {
      this.control.setValue(formattedDate);
    }
  }
}
