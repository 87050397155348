import {
  Component,
  ChangeDetectionStrategy,
  Input,
  EventEmitter,
  Output,
  SimpleChanges,
  OnChanges
} from '@angular/core';
import { DictionaryItem } from 'quantuvis-angular-common/interfaces';

@Component({
  selector: 'acc-checkbox-filter',
  templateUrl: './checkbox-filter.component.html',
  styleUrls: ['./checkbox-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CheckboxFilterComponent implements OnChanges {
  @Input()
  public items: DictionaryItem[] = [];

  @Input()
  public selectedItems: DictionaryItem[] = [];

  @Output()
  public apply: EventEmitter<DictionaryItem[]> = new EventEmitter<DictionaryItem[]>();

  public isFilterChanged = false;

  private initialSelectedItems: DictionaryItem[];

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedItems) {
      this.initialSelectedItems = this.selectedItems;
      this.updateFilterChangedValue();
    }
  }

  public onChange(selectedItems: DictionaryItem[]): void {
    this.selectedItems = selectedItems;
    this.updateFilterChangedValue();
  }

  public onApply(): void {
    this.apply.emit(this.selectedItems);
  }

  public onReset(): void {
    this.selectedItems = [];
    this.updateFilterChangedValue();
  }

  private updateFilterChangedValue(): void {
    const initialSelection = JSON.stringify(this.initialSelectedItems);
    const currentSelection = JSON.stringify(this.selectedItems);

    this.isFilterChanged = initialSelection !== currentSelection;
  }
}
