import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {
  InvalidContractDateModalComponent
} from '@qv-bid/components/shared/invalid-contract-date-modal/invalid-contract-date-modal.component';
import {
  InvalidContractDateModalData
} from '@qv-bid/components/shared/invalid-contract-date-modal/models/invalid-contract-date-modal-data';
import { ModalConfig, ModalSize } from 'quantuvis-angular-common/modal';

export class InvalidContractDateModalConfig
  extends ModalConfig<InvalidContractDateModalComponent, InvalidContractDateModalData> {

  constructor(invalidContractDateModalData: InvalidContractDateModalData) {
    super(invalidContractDateModalData, ModalSize.X_SMALL);
  }

  protected createDialogRef(dialog: MatDialog): MatDialogRef<InvalidContractDateModalComponent> {
    return dialog.open(InvalidContractDateModalComponent, this);
  }
}
