/* tslint:disable:triple-equals */
import { SvgIconName } from '@qv-common/enums';
/**
 * Bidding Drug List Term Definitions
 */
import { constants, resources } from '@qv-common/static';
import { ArrayUtils, CoreUtils, StringUtils } from '@qv-common/utils';
import { DateUtils } from '@qv-common/utils/date.utils';
import { drugTermsConstants } from '@qv-term/constants';
import { TermName } from '@qv-term/enums';
import { biddingConstants } from '../../constants';
import { RangeOrUnitsAndRebates } from './range-or-units-and-rebates';
import { UserService } from '@qv-common/services/auth/user.service';

declare let angular: angular.IAngularStatic;

export function DrugListTerms($filter, util, translations, restapi, biddingUtilsService,
                              viewPerspectiveService, $sce, $templateRequest, userService: UserService, BaselineWacService) {

    // Price Protection validation group
    const ppSection = biddingConstants.validation.priceProtection;

    /**
     * Date validator function for drug term scenario and summary contract dates
     *
     * term - term to verify
     * summaryTerms - summary terms
     */
    function validateDependContractDateRange(term, summaryTerms): any {
      delete term.definition.informMessage;

      if (term.value && !util.isCorrectDateFormat(term.value)) {
        return;
      }

      if (!Array.isArray(summaryTerms)) {
        return;
      }

      const contractDateTerm = summaryTerms.find(data => data.name === term.name);

      if (!(contractDateTerm && contractDateTerm.value)) return;

      const scenarioDate = DateUtils.getDateBasedOnFormattedString(term.value);
      const contractDate = DateUtils.getDateBasedOnFormattedString(contractDateTerm.value);

      let warningString;

      if (util.isEmpty(term.value) && contractDate.getTime() > 0) {
        warningString = ` doesn't match ${term.name}`;
      } else if (contractDate.getTime() > scenarioDate.getTime()) {
        warningString = ` is earlier than ${term.name}`;
      } else if (contractDate.getTime() < scenarioDate.getTime()) {
        warningString = ` is greater than ${term.name}`;
      } else {
        return;
      }

      switch (term.name) {
        case constants.SUMMARY_TERMS.CONTRACT_START_DATE.label:
          warningString = `Scenario Start Date${warningString}`;
          break;
        case constants.SUMMARY_TERMS.CONTRACT_END_DATE.label:
          warningString = `Scenario End Date${warningString}`;
          break;
        default:
          return;
      }

      term.definition.informMessage = warningString;
    }

    /**
     * Date validator function for term scenario
     *
     * term - term to verify
     * terms - terms that depend on
     * parent - parent term name
     * parentDisplayName - display name
     */
    function validateTermScenarioDateRange(term, terms, parent, parentDisplayName): any {
      delete term.definition.error;
      delete term.definition.warning;

      if (util.isEmpty(term.value)) {
        return true;
      } else if (typeof term.value === 'string' && !util.isCorrectDateFormat(term.value)) {
        term.definition.error = `${term.definition.displayName} should be in MM/DD/YY format`;
        return false;
      }

      if (parent) {
        const date = DateUtils.getDateBasedOnFormattedString(term.value);
        if (!Array.isArray(terms)) {
          return true;
        }

        const startDate = terms.find(data => data.name === parent);

        if (startDate) {
          if (startDate.value
            && DateUtils.getDateBasedOnFormattedString(startDate.value).getTime() > date.getTime()) {
            term.definition.error = `${term.definition.displayName} should be greater than ${parentDisplayName}`;
            return false;
          }
        }
      }

      return true;
    }

    function validateDateTerm(term): any {
      delete term.definition.error;
      delete term.definition.warning;

      if (util.isEmpty(term.value) || (typeof term.value === 'string' && util.isCorrectDateFormat(term.value))) {
        return true;
      }

      term.definition.error = `${term.definition.displayName} should be in MM/DD/YY format`;
      return false;
    }

    // @ts-ignore
    // @ts-ignore
    // @ts-ignore
    return [
      {
        name: drugTermsConstants[TermName.MIN_BASE_REBATE].title,
        groupPath: constants.DRUG_TERM_PATHS.ALLOW_REBATE,
        type: 7,
        suffix: '%',
        class: 'input-mini',
        validateValue: util.getNumericValidation({
          minDecimals: 2,
          decimals: 5,
          min: 0,
          max: 100,
          step: 0.0001
        }),
        getTermValueForComparison: term =>
          util.isNotDefined(term.value) ? '' : `${term.value} ${term.definition.suffix}`,
        defaultValue: '',
        hasDependencies: true,
        dependsOn: [
          drugTermsConstants[TermName.BASE_REBATE].title
        ],
        onChange: term => {
          const dep = term.definition.list[drugTermsConstants[TermName.BASE_REBATE].title];
          if (dep && term && dep.value && term.value) {
            dep.contentStyle = Number(dep.value) < Number(term.value) ?
              ' error minBaseRebateError' : '';
          }
        },
        doNotCopyForCreateScenario: true,
        description: translations.i18n.TermsDescriptions.DRUG_LIST.MIN_BASE_REBATE
      },
      {
        name: drugTermsConstants[TermName.BASE_REBATE].title,
        groupPath: constants.DRUG_TERM_PATHS.ALLOW_BASE_REBATE,
        path: constants.DRUG_TERM_PATHS.ALLOW_BASE_REBATE,
        type: 7,
        suffix: '%',
        class: 'input-mini',
        doNotCopyForCreateScenario: true,
        validateValue: util.getNumericValidation({
          minDecimals: 2,
          decimals: 5,
          min: 0,
          max: 100,
          step: 0.0001
        }),
        defaultValue: '',
        getTermValueForComparison: term =>
          util.isNotDefined(term.value) ? '' : `${term.value} ${term.definition.suffix}`,
        hasDependencies: true,
        dependsOn: [
          drugTermsConstants[TermName.MIN_BASE_REBATE].title
        ],

        description: translations.i18n.TermsDescriptions.DRUG_LIST.BASE_REBATE,
        processContentStyle: (term, element, disabled) => {
          let style = '';
          const minBaseRebate = term.definition.list[drugTermsConstants[TermName.MIN_BASE_REBATE].title];
          if (minBaseRebate && minBaseRebate.value) {
            if (util.notNullOrEmpty(term.value) && util.notNullOrEmpty(minBaseRebate.value)
              && Number(term.value) < Number(minBaseRebate.value)) {
              style = ' error minBaseRebateError';

              if (!term.definition.hasAtNDC
                && !disabled && userService.user.getValue().isManufacturer
                && element.get(0).classList.contains('base-rebate')) {
                const templateUrl = $sce.getTrustedResourceUrl('app/views/components/baserebatepopup.html');
                $templateRequest(templateUrl).then((template) => element.append(template));
              }
            }
          }
          return style;
        }
      },
      {
        name: drugTermsConstants[TermName.TIER_PLACEMENT].title,
        groupPath: constants.DRUG_TERM_PATHS.ALLOW_TIERS,
        path: constants.DRUG_TERM_PATHS.ALLOW_TIERS,
        type: 1,
        values: [
          '', 'Preferred Branded', 'Non-Preferred Branded', 'Lowest Branded Tier',
          'Preferred Branded Co-Insurance', 'Non-Preferred Co-Insurance', 'Preferred Brand Copay',
          'Non-Preferred Copay', 'Select', 'Specialty Preferred', 'Specialty Non-Preferred',
          'Specialty', 'Injectable', 'Medical', 'Tier 1', 'Tier 2', 'Tier 3', 'Tier 4', 'Tier 5',
          'Tier 6', 'Generic', 'Branded', 'Preferred Generic', 'Non-Preferred Generic', 'On Formulary'
        ],
        defaultValue: '',
        class: 'input-auto',
        description: translations.i18n.TermsDescriptions.DRUG_LIST.TIER_PLACEMENT
      },
      {
        name: drugTermsConstants[TermName.TIER_COMPETITION].title,
        groupPath: constants.DRUG_TERM_PATHS.ALLOW_TIERS,
        path: constants.DRUG_TERM_PATHS.ALLOW_TIERS,
        type: 1,
        values: [
          '', '1 of 1', '1 of 2', '1 of 2 w/o market leader', '1 of 3', '1 of 3 w/o market leader',
          '1 of 4', '1 of 5', '1 of All', '1 of Many', 'Brand over Generic'
        ],
        defaultValue: '',
        class: 'input-auto',
        description: translations.i18n.TermsDescriptions.DRUG_LIST.TIER_COMPETITION
      },
      {
        name: drugTermsConstants[TermName.COMPETITORS_TIER].title,
        groupPath: constants.DRUG_TERM_PATHS.ALLOW_TIERS,
        path: constants.DRUG_TERM_PATHS.ALLOW_TIERS,
        type: 1,
        values: [
          '', 'Formulary on a Higher Tier', 'Non-Formulary', 'Excluded', 'Parity', 'N/A'
        ],
        defaultValue: '',
        class: 'input-auto',
        description: translations.i18n.TermsDescriptions.DRUG_LIST.TIER_COMPETITOR
      },
      {
        name: drugTermsConstants[TermName.PRIOR_AUTHORIZATION_REQUIRED_ON_COMPETITORS_PRODUCTS].title,
        groupPath: constants.DRUG_TERM_PATHS.ALLOW_UTILIZATION_MANAGEMENT,
        displayName: 'Required on Competitors Products',
        type: 1,
        values: [
          '', 'Yes', 'No'
        ],
        defaultValue: 'No',
        resetValueFlag: true,
        hasDependencies: true,
        dependsOn: [
          drugTermsConstants[TermName.UM_DETAILS].title
        ],
        confirmationPopupId: 'confirmResetUMDetails'
      },
      {
        name: drugTermsConstants[TermName.PRIOR_AUTHORIZATION_ALLOWED_ON_PRODUCT].title,
        groupPath: constants.DRUG_TERM_PATHS.ALLOW_UTILIZATION_MANAGEMENT,
        displayName: constants.DrugList.ALLOWED,
        type: 1,
        values: [
          '', 'Yes', 'No'
        ],
        defaultValue: 'No',
        resetValueFlag: true,
        hasDependencies: true,
        dependsOn: [
          drugTermsConstants[TermName.UM_DETAILS].title
        ],
        confirmationPopupId: 'confirmResetUMDetails'
      },
      {
        name: constants.DrugList.MARKET_SHARE_REBATE,
        groupPath: constants.DRUG_TERM_PATHS.ALLOW_MARKET_SHARE_REBATE,
        type: 8,
        hasNoAtNDCValue: true,
        doNotSave: true,
        dependsOn: [
          drugTermsConstants[TermName.MARKET_SHARE_TIER].title, constants.DrugList.RANGE_OR_UNITS_AND_REBATES
        ],
        hasDependencies: true,
        description: translations.i18n.TermsDescriptions.DRUG_LIST.MARKET_SHARE_REBATE
      },
      {
        name: drugTermsConstants[TermName.MARKET_SHARE_TIER].title,
        groupPath: constants.DRUG_TERM_PATHS.ALLOW_MARKET_SHARE_REBATE,
        type: 1,
        values: [
          '',
          'Specific Unit Count',
          'Market Share %',
          'Market Share +%',
          'National Market Share +%',
          'Specific RX Count',
          'Specific Spend ($US)',
          '% of National Market Share'
        ],
        defaultValue: '',
        description: translations.i18n.TermsDescriptions.DRUG_LIST.MARKET_SHARE_TIER,
        numeric: {},
        valueDependencies: [
          drugTermsConstants[TermName.RANGE_OR_UNITS_AND_REBATES].title
        ],
        dependsOn: [
          drugTermsConstants[TermName.RANGE_OR_UNITS_AND_REBATES].title
        ],
        hasDependencies: true,

        resetValue: (term, propagate) => {
          term.value = '';

          if (propagate != false) {
            const valueTerm = term.definition.list[drugTermsConstants[TermName.RANGE_OR_UNITS_AND_REBATES].title];

            if (valueTerm) {
              valueTerm.definition.resetValue(valueTerm, false);
            }
          }

        },
        onChange: term => {
          if (term.definition.list) {

            const valueTerm = term.definition.list[drugTermsConstants[TermName.RANGE_OR_UNITS_AND_REBATES].title];
            if (valueTerm) {

              let validateOptions;

              const resetFunction = (valueTerm, min, max, decimals) => {
                validateOptions = {
                  min: min,
                  max: max,
                  decimals: decimals
                };
                valueTerm.definition.validateValue = util.getNumericValidation(validateOptions);
                if (!term.definition.skipResettingRange) {
                  valueTerm.value = [
                    {
                      range_start: '',
                      range_end: '',
                      rebate: ''
                    }
                  ];
                }
              };

              valueTerm.definition.resetValue(valueTerm, false);

              if (!util.isNotDefined(valueTerm) && !util.isNotDefined(term.value)) {
                if (term.value === '') {
                  delete valueTerm.definition.suffix;
                  delete valueTerm.definition.currency;
                  valueTerm.value = [
                    {
                      range_start: '',
                      range_end: '',
                      rebate: ''
                    }
                  ];
                } else if (term.value.indexOf('%') > -1) {
                  valueTerm.definition.suffix = '%';
                  delete valueTerm.definition.currency;
                  resetFunction(valueTerm, -999.99, 999.99, 2);
                } else if (term.value.indexOf('$') > -1) {
                  delete valueTerm.definition.suffix;
                  valueTerm.definition.currency = '$';
                  resetFunction(valueTerm, -1e20, 1e20, 2);
                } else {
                  delete valueTerm.definition.suffix;
                  delete valueTerm.definition.currency;
                  resetFunction(valueTerm, -1e20, 1e20, 0);
                }
              }

              if (term.definition.skipResettingRange) {
                delete term.definition.skipResettingRange;
              }

              valueTerm.definition.reset = true;
              valueTerm.definition.editable = valueTerm && term.value != '';
            }
          }
        },
        prepareDependencies: term => {
          if (term.definition.list) {
            const valueTerm = term.definition.list[drugTermsConstants[TermName.RANGE_OR_UNITS_AND_REBATES].title];

            let validateOptions;
            if (valueTerm && term.value) {
              if (term.value.indexOf('%') > -1) {
                valueTerm.definition.suffix = '%';
                delete valueTerm.definition.currency;
                validateOptions = {
                  min: -999.99999,
                  max: 999.99999,
                  decimals: 5,
                  minDecimals: 2,
                  step: 0.0001
                };
              } else if (term.value.indexOf('$') > -1) {
                delete valueTerm.definition.suffix;
                valueTerm.definition.currency = '$';
                valueTerm.definition.currency = '$';
                validateOptions = {
                  min: -1e20,
                  max: 1e20,
                  decimals: 2
                };
              } else {
                delete valueTerm.definition.suffix;
                delete valueTerm.definition.currency;
                validateOptions = {
                  min: -1e20,
                  max: 1e20,
                  decimals: 0
                };
              }
              valueTerm.definition.validateValue = util.getNumericValidation(validateOptions);
            }
            if (valueTerm) {
              valueTerm.definition.reset = true;
            }

            valueTerm.definition.editable = valueTerm && term.value !== '';
          }
        }
      },
      RangeOrUnitsAndRebates(util, translations),
      {
        name: drugTermsConstants[TermName.MARKET_BASKET].title,
        groupPath: constants.DRUG_TERM_PATHS.ALLOW_MARKET_BASKET,
        lockName: constants.DRUG_TERM_PATHS.ALLOW_MARKET_BASKET[1],
        copyFromBidPath: constants.BID_SECTIONS.ALLOW_MARKET_BASKET_PATH,
        type: 5,
        // @ts-ignore
        templateFile: '/app/views/bidding/custom/marketbasket.html',
        description: translations.i18n.TermsDescriptions.DRUG_LIST.MARKET_BASKET,
        doNotSave: true,
        prepareCustom: (scope) => {

          /**
           * The market basket is clickable if term value is not empty or if it is
           * not locked or the logged in user is a payer and bid is in edit mode.
           */
          const isMarketBasketModalAllowed = (editable, term, drug) => {
            const lockedConst = constants.DRUG_TERM_GROUPS_STATE_VALUES.LOCKED_STATE;
            const isMBUnlocked = drug.renderData.marketBasket.state !== lockedConst.state;
            const isDrugDismissed = drug.dismissed && drug.dismissed.status;

            return term && (term.value || editable && !isDrugDismissed
              && (viewPerspectiveService.isPayerViewPerspective() || isMBUnlocked));
          };

          const showMarketBasketModal = (term, drugName) => {
            restapi.getMarketBaskets({
              requestOption: `${constants.ALL_OPTION.toLowerCase()}ByDrug`,
              termValue: term.value,
              drugName: drugName.renderData.isGroup ? drugName.renderData.groupName : drugName.name,
              drugClass: drugName.renderData.isGroup ? drugName.renderData.drugClass : drugName.drugClass
            }, (data) => {
              scope.$emit('showMarketBasketModal', {
                term: term,
                marketBasket: term.value,
                drugName: drugName,
                baskets: data,
                drugDetails: {
                  drug: scope.getDrug(),
                  bidId: scope.$parent.bidInfo.bidId,
                  bidManufacturerId: scope.$parent.bidInfo.manufacturer.companyId,
                  bidPayerId: scope.$parent.bidInfo.payer.companyId,
                  id: scope.$parent.bid.id,
                  planTypeIndex: scope.$parent.planTypeIndex
                }
              });
            }, (data) => {
              if (data.error === true && !util.isNotDefined(data.message)) {
                scope.marketBasketsResponse = data;
              } else if (data.status !== 200) {
                let errorMessage;
                switch (data.status) {
                  case 403 :
                    errorMessage = translations.i18n.GENERAL.RESTRICTED_ACCESS_ERROR;
                    break;
                  case 401 :
                    errorMessage = translations.i18n.GENERAL.ACCESS_UNAUTHORIZED_ERROR;
                    break;
                  default :
                    errorMessage = translations.i18n.GENERAL.OPERATION_GENERAL_ERROR;
                    break;
                }
                scope.marketBasketsResponse = {
                  message: errorMessage,
                  error: -1
                };
              }
            });
          };

          scope.onMarketBasketClick = (editable, term, drug): any => {
            // tslint:disable-next-line:no-unused-expression
            isMarketBasketModalAllowed(editable, term, drug)
            && showMarketBasketModal(term, drug);
          };


          scope.getMarketBasketTooltip = (term) => {
            return term.value ? '' : resources.TermsDescriptions.DRUG_LIST.EMPTY_MARKET_BASKET_TOOLTIP;
          };

          scope.getMarketBasketIconClass = (editable, term, drug) => {
            let className = 'market-basket ';

            if (term.value) {
              className += 'market-basket--full ';
            } else if (!editable || drug.dismissed) {
              className += 'market-basket--inactive ';
            } else {
              className += 'market-basket--empty ';
            }

            return className.trim();
          };

          scope.svgIconName = SvgIconName;

        },
        isEmpty: term => {
          const termValue = term.value;
          let empty = true;
          if (CoreUtils.isDefined(termValue)) {
            empty = !(termValue.name || !CoreUtils.isEmpty(termValue.name));
          }
          return empty;
        },
        resetValue: term => {
          delete term.value;
        },
        getTermValueForComparison: term => {

          let value = '';

          if (!util.isNotDefined(term.value)
            && !util.isNotDefined(term.value.name) && !util.isNotDefined(term.value.drugsList)) {

            value = `<span class="bolded">${term.value.name}</span><br />`;

            const groups = ArrayUtils.groupBy(term.value.drugsList, (val: any) => val.drugClass);

            groups.forEach((group, key) => {
              value += `&emsp;${key}<br />`;
              value += '<ul>';
              group.forEach(drug => value += `<li>${drug.name}</li>`);
              value += '</ul>';
            });
          }

          return value;

        },
        getValueToSave: term => term.value,
        getValueForComparison: term => {
          let value = '';

          if (!util.isNotDefined(term.value)
            && !util.isNotDefined(term.value.name) && !util.isNotDefined(term.value.drugsList)) {

            value = `<span class="bolded">${term.value.name}</span><br />`;

            const groups = ArrayUtils.groupBy(term.value.drugsList, (val: any) => val.drugClass);

            groups.forEach((group, key) => {
              value += `&emsp;${key}<br />`;
              value += '<ul>';
              group.forEach(drug => value += `<li>${drug.name}</li>`);
              value += '</ul>';
            });
          }

          return value;
        }
      },
      {
        name: constants.DrugList.PRICE_PROTECTION,
        groupPath: constants.DRUG_TERM_PATHS.ALLOW_PRICE_PROTECTION,
        type: 8,
        hasNoAtNDCValue: true,
        doNotSave: true,
        description: translations.i18n.TermsDescriptions.DRUG_LIST.PRICE_PROTECTION,
        dependsOn: [
          drugTermsConstants[TermName.INDEX].title, drugTermsConstants[TermName.THRESHOLD].title,
          drugTermsConstants[TermName.RESET].title,
          drugTermsConstants[TermName.BASELINE_START_DATE].title,
          drugTermsConstants[TermName.NET_EFFECTIVE_PRICE].title,
          drugTermsConstants[TermName.NEW_PRICE_EFFECTIVE_DATE].title
        ],
        hasDependencies: true
      },
      {
        name: drugTermsConstants[TermName.NET_EFFECTIVE_PRICE].title,
        groupPath: constants.DRUG_TERM_PATHS.ALLOW_PRICE_PROTECTION,
        resetValueFlag: true,
        type: 7,
        allowKeepOriginValue: true,
        currency: '$',
        placeholder: 'Per Unit',
        class: 'input-mini',
        validateValue: util.getNumericValidation({
          decimals: 2,
          min: 0,
          max: 1000000000000
        }),
        defaultValue: '',
        getTermValueForComparison: term => !term.value ? '' : `${term.definition.currency} ${term.value}`,
        getValueToSave: util.getValueToSaveForInputField,
        description: translations.i18n.TermsDescriptions.DRUG_LIST.NET_EFFECTIVE_PRICE,
        validateSection: ppSection,
        resetValue: util.resetValueInput
      },
      {
        name: drugTermsConstants[TermName.INDEX].title,
        groupPath: constants.DRUG_TERM_PATHS.ALLOW_PRICE_PROTECTION,
        resetValueFlag: true,
        type: 1,
        allowKeepOriginValue: true,
        values: [
          '', 'Actual Drug WAC Price', 'Consumer Price Index (CPI)',
          'Consumer Price Index-Urban Consumers (CPI-U)',
          'Chained Consumer Price Index (C-CPI-U)',
          'Consumer Price Index- Urban Wage Earners and Clerical Workers (CPI-W)',
          'Prescription Price Index (PPI)'
        ],
        defaultValue: '',
        description: translations.i18n.TermsDescriptions.DRUG_LIST.INDEX,
        validateSection: ppSection,
        resetValue: util.resetValueDropdown
      },
      {
        name: drugTermsConstants[TermName.THRESHOLD].title,
        groupPath: constants.DRUG_TERM_PATHS.ALLOW_PRICE_PROTECTION,
        resetValueFlag: true,
        type: 7,
        allowKeepOriginValue: true,
        suffix: '%',
        class: 'input-mini',
        noProcessing: true,
        validateValue: util.getNumericValidation({
          decimals: 5,
          minDecimals: 2,
          min: 0,
          max: 100,
          step: 0.0001
        }),
        defaultValue: '',
        getTermValueForComparison: term =>
          (util.isNotDefined(term.value)) ? '' : `${term.value} ${term.definition.suffix}`,
        getValueToSave: util.getValueToSaveForInputField,
        description: translations.i18n.TermsDescriptions.DRUG_LIST.THRESHOLD,
        validateSection: ppSection,
        resetValue: util.resetValueInput
      },
      {
        name: drugTermsConstants[TermName.RESET].title,
        groupPath: constants.DRUG_TERM_PATHS.ALLOW_PRICE_PROTECTION,
        resetValueFlag: true,
        type: 1,
        allowKeepOriginValue: true,
        values: [
          '', 'Annually', 'Annually - Contract Year',
          'Cumulative', 'After Last Price Increase that Impacted Rebate Offer'
        ],
        defaultValue: '',
        description: translations.i18n.TermsDescriptions.DRUG_LIST.RESET,
        validateSection: ppSection,
        resetValue: util.resetValueDropdown
      },
      {
        name: drugTermsConstants[TermName.BASELINE_START_DATE].title,
        groupPath: constants.DRUG_TERM_PATHS.ALLOW_PRICE_PROTECTION,
        resetValueFlag: true,
        type: 2,
        allowKeepOriginValue: true,
        defaultValue: '',
        keepValueOnCopy: true, // When scenario is copied, ignore the "getValueToSave" modifier
        validate: term => {
          if (term.definition.processKeepOriginalValue && term.definition.keepOriginalValue && !term.value) {
            return true;
          }
          return validateDateTerm(term);
        },
        description: translations.i18n.TermsDescriptions.DRUG_LIST.BASELINE_START_DATE,
        getValueToSave: term => {
          if (term.definition.processKeepOriginalValue && term.definition.keepOriginalValue) {
            return resources.NewBid.KEEP_ORIGINAL_VALUE;
          }
          return StringUtils.isString(term.value)
          && !util.isEmpty(term.value) ? DateUtils.getDateBasedOnFormattedString(term.value).getTime()
            : term.value;
        },
        getValueForComparison: term => { // This one is used for @NDC
          return StringUtils.isString(term.value)
          && !util.isEmpty(term.value) ? DateUtils.getDateBasedOnFormattedString(term.value).getTime()
            : term.value;
        },
        validateSection: ppSection,
        onChange: (term, drug) => {
          if (term && drug) {
            if (drug.renderData && drug.renderData.isGroup) {
              BaselineWacService.getGroupBaselineWAC(drug, term.value);
            } else {
              BaselineWacService.getNDCBaselineWAC(drug, term.value);
            }
          }

          if (typeof term.value === 'undefined') {
            term.definition.keepOriginalValue = false;
            term.definition.placeholder = '';
          }

          validateDateTerm(term);
        },
        onFocus: (term) => {
          if (term.definition && term.definition.processKeepOriginalValue && term.definition.keepOriginalValue) {
            term.value = '';
            term.definition.keepOriginalValue = false;
            term.definition.placeholder = '';
          }
        },
        resetValue: util.resetValueDate
      },
      {
        name: drugTermsConstants[TermName.BASELINE_WAC].title,
        groupPath: constants.DRUG_TERM_PATHS.ALLOW_PRICE_PROTECTION,
        type: 4,
        ignoreOnNDCValue: true,
        defaultValue: '',
        description: translations.i18n.TermsDescriptions.DRUG_LIST.BASELINE_WAC,
        doNotSave: true,
        resetValueFlag: true,
        getValueForComparison: term => {
          if (term.value !== '') {
            return term.value ? $filter('currency')(term.value, '$', '6') : 'Unavailable';
          } else {
            return '';
          }
        },
        getTermValueForComparison: term => util.isEmpty(term.value) ? term.definition.defaultValue : term.value,
        getCustomValue: term => {
          if (term.value !== term.definition.defaultValue) {
            return term.value ? $filter('currency')(term.value, '$', '6') : 'Unavailable';
          } else {
            return term.definition.defaultValue;
          }
        }
      },
      {
        name: drugTermsConstants[TermName.NEW_PRICE_EFFECTIVE_DATE].title,
        groupPath: constants.DRUG_TERM_PATHS.ALLOW_PRICE_PROTECTION,
        resetValueFlag: true,
        type: 1,
        allowKeepOriginValue: true,
        values: [
          '', '1st day of month',
          '15th day of month',
          'Last day of month', '1st day of the following month', '1st day of quarter', '45th day of quarter',
          'Last day of quarter', '1st day of the following quarter', 'Day of price change', 'Date Dispensed'
        ],
        defaultValue: '',
        description: translations.i18n.TermsDescriptions.DRUG_LIST.NEW_PRICE_EFFECTIVE_DATE,
        validateSection: ppSection,
        resetValue: util.resetValueDropdown
      },
      {
        name: drugTermsConstants[TermName.STEP_THERAPY_REQUIRED_ON_COMPETITORS_PRODUCTS].title,
        groupPath: constants.DRUG_TERM_PATHS.ALLOW_UTILIZATION_MANAGEMENT,
        displayName: constants.DrugList.REQUIRED,
        type: 1,
        values: [
          '', 'No', 'Yes - Single step', 'Yes - Double step', 'Yes - Triple step', 'Yes - Other'
        ],
        defaultValue: 'No',
        resetValueFlag: true,
        hasDependencies: true,
        dependsOn: [
          drugTermsConstants[TermName.UM_DETAILS].title
        ],
        confirmationPopupId: 'confirmResetUMDetails'
      },
      {
        name: drugTermsConstants[TermName.STEP_THERAPY_ALLOWED_ON_PRODUCT].title,
        groupPath: constants.DRUG_TERM_PATHS.ALLOW_UTILIZATION_MANAGEMENT,
        displayName: constants.DrugList.ALLOWED,
        type: 1,
        values: [
          '', 'No', 'Yes - Single step', 'Yes - Double step', 'Yes - Triple step', 'Yes - Other'
        ],
        defaultValue: 'No',
        resetValueFlag: true,
        hasDependencies: true,
        dependsOn: [
          drugTermsConstants[TermName.UM_DETAILS].title
        ],
        confirmationPopupId: 'confirmResetUMDetails'
      },
      {
        name: drugTermsConstants[TermName.QUANTITY_LIMIT].title,
        groupPath: constants.DRUG_TERM_PATHS.ALLOW_UTILIZATION_MANAGEMENT,
        displayName: drugTermsConstants[TermName.QUANTITY_LIMIT].title,
        type: 1,
        values: [
          '', 'Yes', 'No'
        ],
        defaultValue: 'Yes',
        defaultValueForOldBid: '',
        description: translations.i18n.TermsDescriptions.DRUG_LIST.QUANTITY_LIMIT,
        resetValueFlag: true,
        hasDependencies: true,
        dependsOn: [
          drugTermsConstants[TermName.UM_DETAILS].title
        ],
        confirmationPopupId: 'confirmResetUMDetails'
      },
      {
        name: drugTermsConstants[TermName.OTHER_UM].title,
        groupPath: constants.DRUG_TERM_PATHS.ALLOW_UTILIZATION_MANAGEMENT,
        displayName: drugTermsConstants[TermName.OTHER_UM].title,
        type: 1,
        values: [
          '', 'Yes', 'No'
        ],
        defaultValue: 'No',
        defaultValueForOldBid: '',
        description: translations.i18n.TermsDescriptions.DRUG_LIST.OTHER_UM,
        resetValueFlag: true,
        hasDependencies: true,
        dependsOn: [
          drugTermsConstants[TermName.UM_DETAILS].title
        ],
        confirmationPopupId: 'confirmResetUMDetails'
      },
      {
        name: drugTermsConstants[TermName.UM_DETAILS].title,
        groupPath: constants.DRUG_TERM_PATHS.ALLOW_UTILIZATION_MANAGEMENT,
        displayName: drugTermsConstants[TermName.UM_DETAILS].title,
        type: 6,
        defaultValue: '',
        getTermValueForComparison: term => term.value,
        description: translations.i18n.TermsDescriptions.DRUG_LIST.UM_DETAILS,
        hasDependencies: true,
        dependsOn: [
          drugTermsConstants[TermName.PRIOR_AUTHORIZATION_REQUIRED_ON_COMPETITORS_PRODUCTS].title,
          drugTermsConstants[TermName.PRIOR_AUTHORIZATION_ALLOWED_ON_PRODUCT].title,
          drugTermsConstants[TermName.STEP_THERAPY_REQUIRED_ON_COMPETITORS_PRODUCTS].title,
          drugTermsConstants[TermName.STEP_THERAPY_ALLOWED_ON_PRODUCT].title,
          drugTermsConstants[TermName.QUANTITY_LIMIT].title,
          drugTermsConstants[TermName.OTHER_UM].title
        ],
        isEditable: term => {
          let result = false;
          const negativeValuesArray = ['No', ''];
          if (term.definition.list) {
            term.definition.list.forEach((element) => {
              if (negativeValuesArray.indexOf(element.value) === -1) {
                result = true;
              }
            });
          }
          return result;
        },
        showWholeValueInPopup: true,
        processContentStyle: (term, element, disabled, isEditMode) => {
          if (isEditMode) {
            return '';
          }
          let style = ' um-details-section-view';
          style += (term.value && term.value !== '') ? ` clickable'` + ` data-toggle='dropdown' ` : `'`;
          return style;
        },
        maxLength: 2000
      },
      {
        name: drugTermsConstants[TermName.NOTES].title,
        groupPath: constants.DRUG_TERM_PATHS.ALLOW_NOTES,
        path: constants.DRUG_TERM_PATHS.ALLOW_NOTES,
        lockName: drugTermsConstants[TermName.NOTES].title.toLowerCase(),
        copyFromBidPath:
          `${constants.BID_SECTIONS.DRUG_LIST_PATH}.${drugTermsConstants[TermName.NOTES].title.toLowerCase()}`,
        type: 5,
        // @ts-ignore
        templateFile: '/app/views/bidding/custom/drugnotes.html',
        description: translations.i18n.TermsDescriptions.DRUG_LIST.NOTES,
        validateNotes: (term) => {
          const errorMsg = `${term.name} are limited to ${
            constants.DRUG_NOTES_MAX_LENGTH} characters. Please edit the text and save again.`;
          return util.validateLengthForDrugNotes(term, errorMsg);
        },
        prepare: (term, settings) => {
          const userCompanyName = util.getUserCompanyName(userService.user.getValue());
          const manufCompName = settings.manufacturerCompanyName;
          const payerCompName = settings.payerCompanyName;
          if (!util.isNotDefined(manufCompName)
            && manufCompName === userCompanyName && !util.isNotDefined(payerCompName)) {
            term.definition.displayName = translations.i18n.Comments.PLACEHOLDER + payerCompName;
          } else if (!util.isNotDefined(payerCompName)
            && payerCompName === userCompanyName && !util.isNotDefined(manufCompName)) {
            term.definition.displayName = translations.i18n.Comments.PLACEHOLDER + manufCompName;
          }
        },
        resetValue: term => {
          term.value = {
            historic: [],
            currentValue: ''
          };
        },
        clearHistoric: term => {
          term.value.historic = [];
        },
        prepareCustom: (scope) => {
          // Update notes object with correct companyDisplayName

          if (biddingUtilsService.isDefined(scope.term.value)
            && biddingUtilsService.isDefined(scope.term.value.historic)) {
            scope.term.value.historic = biddingUtilsService
              .getDisplayCompanyName(scope.term.value.historic, scope.getBidManufacturer());
          }
          scope.term.definition.userCompanyName = util.getUserCompanyName(userService.user.getValue());

          const valueTemplate = {
            historic: [],
            currentValue: ''
          };

          const isNotesEmpty = (term) => {
            return !term.value || (!term.value.currentValue
              && (!term.value.historic || term.value.historic.length === 0));
          };

          /**
           * The Notes icon is clickable if term value is not empty or if bid is in edit mode.
           */
          const isNotesModalAllowed = (editable?, term?) => {
            return term && (!isNotesEmpty(term) || editable);
          };

          scope.onNotesClick = (editable, term, drug) => {
            // tslint:disable-next-line:no-unused-expression
            isNotesModalAllowed(editable, term) && scope.showNotesModal(term, drug);
          };

          scope.createNotes = () => {
            scope.term.value = angular.copy(valueTemplate);
          };

          scope.showNotesModal = (term, drug) => {
            if (!term.value) {
              term.value = angular.copy(valueTemplate);
            }
            scope.$emit('showNotesModal', term, drug);
          };

          scope.notesIconClass = (editable, term, drug) => {
            let className = 'notes ';

            if (!isNotesEmpty(term)) {
              className += 'notes--full ';
            } else if (!editable || drug.dismissed) {
              className += 'notes--inactive ';
            } else {
              className += 'notes--empty ';
            }

            return className.trim();
          };

          scope.getNotesTooltip = (term) => {
            return isNotesEmpty(term) ? resources.TermsDescriptions.DRUG_LIST.EMPTY_NOTES_TOOLTIP : '';
          };

          scope.svgIconName = SvgIconName;

          if (typeof scope.term.value === 'undefined') {
            scope.$emit('disableTermUpdate');
            scope.createNotes();
            scope.$emit('enableTermUpdate');
          }
        },
        setValue: (term, val) => {
          if (!util.isNotDefined(term.value)) {
            term.value.currentValue = val.currentValue;
          } else {
            term.value = Object.assign({}, val);
          }
        },
        getValueToSave: term => !util.isNotDefined(term.value) ? term.value.currentValue : '',
        getTermValueForComparison: term => {
          let value = '';
          if (!biddingUtilsService.isNotDefined(term.value) && !biddingUtilsService.isNotDefined(term.value.historic)
            && !CoreUtils.isEmpty(term.value.historic)) {
            value += '<div class="comments-section">';
            term.value.historic.forEach(note => {
              value += '<span class="comments spacer bottom-margin">';
              // TODO: qu-5199: Here we get '.oldCompanyName' from backend from backend unlike in other methods
              value += `<div class='bolded ng-binding font-size-mini purple'>${
                biddingUtilsService.addOldName(note.companyName, note.oldCompanyName)} - ${note.formattedDate}</div>`;
              value += `<span>${note.text}</span>`;
              value += '</span>';
            });
            value += '</div>';
          }
          return value;
        },
        getValueForComparison: term => {
          // This method is used for @NDC
          let currentNote = '';
          let historicNotes = '';

          if (!util.isNotDefined(term.value)) {
            if (!util.isNotDefined(term.value.currentValue) && term.value.currentValue.length > 0) {
              currentNote = `<span>${term.value.currentValue}</span><br />`;
            }
            if (!util.isNotDefined(term.value.historic) && term.value.historic.length > 0) {

              term.value.historic.forEach(item => {
                historicNotes += `&emsp;${item.text}<br />`;
                historicNotes += '<ul>';
                historicNotes += `<li>${item.companyId}</li>`;
                // TODO: qu-5199: Here we get '.oldCompanyName' from backend
                historicNotes += `<li>${biddingUtilsService.addOldName(item.companyName, item.oldCompanyName)}</li>`;
                historicNotes += `<li>${item.date}</li>`;
                historicNotes += '</ul>';
              });
            }
          }

          return {
            currentNote: currentNote,
            historicNotes: historicNotes
          };
        }
      },
      {
        name: constants.SUMMARY_TERMS.CONTRACT_START_DATE.label,
        displayName: drugTermsConstants[TermName.SCENARIO_START_DATE].title,
        groupPath: constants.DRUG_TERM_PATHS.ALLOW_CONTRACT_DATES,
        path: constants.DRUG_TERM_PATHS.ALLOW_CONTRACT_START_DATE,
        copyFromBidPath: `${constants.BID_SECTIONS.SUMMARY_TERMS}.${
          constants.SUMMARY_TERMS.CONTRACT_START_DATE.label}`,
        resetValueFlag: true,
        type: 2,
        defaultValue: '',
        keepValueOnCopy: true, // When scenario is copied, ignore the "getValueToSave" modifier
        resetValue: term => {
          term.value = '';
        },
        validateDependentDate: (term, summaryTerms) => {
          validateDependContractDateRange(term, summaryTerms);
        },
        validate: term => validateTermScenarioDateRange(term, undefined, undefined, undefined),
        description: translations.i18n.TermsDescriptions.SUMMARY.CONTRACT_START_DATE,
        getValueToSave: term => StringUtils.isString(term.value)
        && !util.isEmpty(term.value) ? DateUtils.getDateBasedOnFormattedString(term.value).getTime()
          : term.value,
        getValueForComparison: term => { // This one is used for @NDC
          return StringUtils.isString(term.value)
          && !util.isEmpty(term.value) ? DateUtils.getDateBasedOnFormattedString(term.value).getTime()
            : term.value;
        },
        hasDependencies: false
      },
      {
        name: constants.SUMMARY_TERMS.CONTRACT_END_DATE.label,
        displayName: drugTermsConstants[TermName.SCENARIO_END_DATE].title,
        groupPath: constants.DRUG_TERM_PATHS.ALLOW_CONTRACT_DATES,
        path: constants.DRUG_TERM_PATHS.ALLOW_CONTRACT_END_DATE,
        copyFromBidPath: `${constants.BID_SECTIONS.SUMMARY_TERMS}.${constants.SUMMARY_TERMS.CONTRACT_END_DATE.label}`,
        resetValueFlag: true,
        type: 2,
        defaultValue: '',
        keepValueOnCopy: true, // When scenario is copied, ignore the "getValueToSave" modifier
        resetValue: term => {
          term.value = '';
        },
        validateDependentDate: (term, summaryTerms) => {
          validateDependContractDateRange(term, summaryTerms);
        },
        validate: (term, terms) => {
          const name = constants.SUMMARY_TERMS.CONTRACT_START_DATE.label;
          const displayName = drugTermsConstants[TermName.SCENARIO_START_DATE].title;

          return validateTermScenarioDateRange(term, terms, name, displayName);
        },
        description: translations.i18n.TermsDescriptions.SUMMARY.CONTRACT_END_DATE,
        getValueToSave: term => StringUtils.isString(term.value)
        && !util.isEmpty(term.value) ? DateUtils.getDateBasedOnFormattedString(term.value).getTime()
          : term.value,
        getValueForComparison: term => { // This one is used for @NDC
          return StringUtils.isString(term.value)
          && !util.isEmpty(term.value) ? DateUtils.getDateBasedOnFormattedString(term.value).getTime()
            : term.value;
        },
        hasDependencies: false
      },
      {
        name: constants.CONTRACT_TERMS.ADMINISTRATION_FEE.label,
        displayName: constants.CONTRACT_TERMS.ADMINISTRATION_FEE.label,
        groupPath: constants.DRUG_TERM_PATHS.ALLOW_ADMINISTRATION_FEE,
        path: constants.DRUG_TERM_PATHS.ALLOW_ADMINISTRATION_FEE,
        copyFromBidPath: `${constants.BID_SECTIONS.CONTRACT_TERMS}.${translations.i18n.BID_DETAILS.FEES_SECTION}`,
        resetValueFlag: true,
        type: 7,
        suffix: '%',
        class: 'input-mini',
        validateValue: util.getNumericValidation({
          min: 0,
          max: 100,
          minDecimals: 2,
          decimals: 5,
          step: 0.0001
        }),
        defaultValue: '',
        getTermValueForComparison: term =>
          (util.isNotDefined(term.value)) ? '' : `${term.value} ${term.definition.suffix}`,
        description: translations.i18n.TermsDescriptions.CONTRACT.ADMINISTRATION_FEE
      },
      {
        name: drugTermsConstants[TermName.COMPETITION_GROUP].title,
        groupPath: constants.DRUG_TERM_PATHS.ALLOW_UTILIZATION_MANAGEMENT,
        displayName: drugTermsConstants[TermName.COMPETITION_GROUP].title,
        description: translations.i18n.TermsDescriptions.DRUG_LIST.COMPETITION_GROUP,
        type: 1,
        defaultValue: constants.COMPETITION_GROUP_OPTIONS.EMPTY,
        defaultValueForOldBid: constants.COMPETITION_GROUP_OPTIONS.EMPTY,
        values: constants.COMPETITION_GROUP_OPTIONS,
      }
    ];
  }
