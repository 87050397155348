<section class="qv-modal-dialog" *blockUI="'general-modal'">
  <header mat-dialog-title class="qv-modal-dialog-title">
    <h1 class="qv-modal-dialog-title__text">{{ title }}</h1>
    <button class="qv-modal-dialog-title__xbutton" (click)="onSecondaryAction()">
      <mat-icon>close</mat-icon>
    </button>
  </header>
  <div class="qv-modal-dialog-content qv-modal-dialog-content--primary-padding qv-modal-dialog-content--font-light qv-modal-dialog-content--scrollable">
    <div class="qv-modal-dialog-content__title">
      By signing and sending this Binding Bid, Company agrees to the terms and conditions referenced
      within the following legal attestation
    </div>
    <div class="qv-modal-dialog-content__form">
      <div class="qv-modal-dialog-content__row">
        <div class="qv-modal-dialog-content__row-name qv-modal-dialog-content__label-text qv-modal-dialog-content__label-text--required">Name</div>
        <div class="qv-modal-dialog-content__row-item">
          <input
            class="qv-modal-dialog-content__field qv-modal-dialog-content__field--name"
            matInput
            [formControl]="control"
            [autofocus]="true"
            [maxLength]="signatureMaxLength"
            [required]="true">
          <acc-form-field-errors
            class="qv-modal-dialog-content__field-errors"
            [control]="control"
            [validationMessages]="validationMessages"
          ></acc-form-field-errors>
        </div>
      </div>
      <div class="qv-modal-dialog-content__row">
        <div class="qv-modal-dialog-content__row-name">Signature</div>
        <div class="qv-modal-dialog-content__row-item qv-modal-dialog-content__row-item--signature">
          {{control.value}}
        </div>
      </div>
      <div class="qv-modal-dialog-content__row">
        <div class="qv-modal-dialog-content__row-name">Company</div>
        <div class="qv-modal-dialog-content__row-item">{{modalData.companyName}}</div>
      </div>
      <div class="qv-modal-dialog-content__row">
        <div class="qv-modal-dialog-content__row-name">Date</div>
        <div class="qv-modal-dialog-content__row-item">{{currentTime | dateTimeUserTz:dateFormat | async }}</div>
      </div>

    </div>
    <div class="qv-modal-dialog-content__pdf-viewer" *blockUI="blockUiSelector">
      <qv-pdf-viewer
        *ngIf="file$ | async as bidAttachment"
        [filePrefix]="filePrefix"
        [file]="$any(bidAttachment)"
        (exportFile)="exportFile()"
      ></qv-pdf-viewer>
    </div>
  </div>
  <footer mat-dialog-actions class="qv-modal-dialog-actions qv-modal-dialog-actions--padding-sm">
    <button mat-button class="qv-modal-dialog-actions__item" (click)="onSecondaryAction()">
      Cancel
    </button>
    <button mat-button class="qv-modal-dialog-actions__item qv-modal-dialog-actions__item--primary"
            [disabled]="control.invalid"
            (click)="onPrimaryAction()">
      Send Bid
    </button>
  </footer>
</section>
