import { ChangeDetectorRef, Directive, Input, OnDestroy } from '@angular/core';
import { AnimationEvent } from '@angular/animations';
import { HttpErrorResponse } from '@angular/common/http';
import { Ndc } from '@qv-bid/entities';
import { DrugScenarioStatus } from '@qv-bid/enums';
import { NdcManagerService } from '@qv-bid/services/ndc-manager.service';
import { BidStateService, ContractedBusinessesService, DrugAlertNotificationService, } from '@qv-bid/services';
import { SectionChangeManager } from '@qv-bid/services/sections/section-change-manager.service';
import { QvCache } from '@qv-common/decorators';
import { RowState } from '@qv-common/enums';
import { VirtualScrollerService } from '@qv-bid/services/virtual-scroller.service';
import { BidUtils } from '@qv-bid/utils';
import { MarketBasketModalOutput, ScenarioStateConfig, SectionChange, SectionChangeFailed } from '@qv-bid/models';
import { CoreUtils } from '@qv-common/utils';
import { catchError, delay, filter, finalize, map, skip, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { BaseRow } from './base-row';
import { ScenariosSnackBarService } from '@qv-bid/services/scenarios-snack-bar.service';
import { BidEventBusService } from '@qv-bid/services/bid-event-bus.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BidViewService } from '@qv-bid/services/bid-view.service';
import { NotificationService } from 'quantuvis-angular-common/notification';

@UntilDestroy()
@Directive()
export class BaseScenarioRow extends BaseRow implements OnDestroy {
  @Input()
  public scenarioStateConfig: ScenarioStateConfig;
  @Input()
  public bidVersionId: number;
  @Input()
  public isBidInvalid: boolean;
  @Input()
  public bidStatus: string;
  @Input()
  public isBidInternal: boolean;
  @Input()
  public manufacturerCompanyOldName: string;
  @Input()
  public manufacturerCompanyId: number;

  public ndcs$ = new BehaviorSubject<Ndc[]>([]);

  public isNdcsLoaded = false;

  public expandDelay = 100;

  constructor(
    protected scenariosSnackBarService: ScenariosSnackBarService,
    public bidStateService: BidStateService,
    protected bidEventBusService: BidEventBusService,
    public drugAlertNotificationService: DrugAlertNotificationService,
    protected ndcManagerService: NdcManagerService,
    protected notificationService: NotificationService,
    protected changeDetectorRef: ChangeDetectorRef,
    protected bidViewService: BidViewService,
    protected virtualScrollerService: VirtualScrollerService,
    protected contractedBusinessesService: ContractedBusinessesService,
    protected sectionChangeManager: SectionChangeManager,
  ) {
    super(sectionChangeManager, changeDetectorRef);
    this.initSectionChangeSuccessHandler();
    this.initSectionChangeFailedHandler();
  }

  public ngOnDestroy(): void {
    if (this.sectionChangeManager.isSectionChanging$.value) return;
    this.destroyed$.next(null);
    this.destroyed$.complete();
  }

  public toggleNdcs(): void {
    this.scenarioStateConfig.isNdcsLoading$
      .pipe(
        take(1),
        untilDestroyed(this),
        filter((isNdcsLoading: boolean) => !isNdcsLoading),
        switchMap(() => this.scenarioStateConfig.isExpanded$),
        take(1),
        tap((isExpanded: boolean) => (isExpanded || this.isNdcsLoaded)
          ? this.scenarioStateConfig.toggleExpand()
          : this.loadAndExpandNdcs())
      )
      .subscribe();
  }

  public getState(): Observable<RowState> {
    return this.scenarioStateConfig && this.scenarioStateConfig.getRowState();
  }

  public emitRefreshVirtualScroller(event: AnimationEvent): void {
    if (event && event.fromState !== RowState.VOID) {
      this.virtualScrollerService.scrollRefresh.next();
    }
  }

  public loadNdcs(forceLoad?: boolean): Observable<any> {
    const { isInternal } = this.bidStateService.bid$.getValue();

    return this.ndcManagerService.loadNdcsByBidVersionIdScenarioId(
      this.bidVersionId, this.scenario.id, isInternal, forceLoad, this.bidStateService.sortParams
    ).pipe(
      tap((ndcs: Ndc[]) => {
        this.ndcs$.next(ndcs);
        this.isNdcsLoaded = true;
        this.bidEventBusService.rebuildGridWidthEvent.emit();
      }),
      catchError((errorResponse: HttpErrorResponse) => {
        this.notificationService.showServerError(errorResponse);

        return throwError(errorResponse);
      })
    );
  }

  @QvCache()
  public isDismissed(status: DrugScenarioStatus): boolean {
    return BidUtils.isDismissed(status);
  }

  public reloadRowDataScenario(): void {
    this.bidEventBusService.reloadScenariosEvent.emit([this.scenario.id]);
  }

  public onMarketBasketUpdated(data: MarketBasketModalOutput): void {
    if (data.isAddedToOtherScenarios) {
      this.bidEventBusService.reloadBidEvent.emit();
    }
  }

  public onUpdateNotes(): void {
    this.reloadRowDataScenario();
    this.bidEventBusService.reloadLoadedNdcsEvent.emit([this.scenario.id]);
  }

  public onDeleteHistoricNotes(): void {
    this.contractedBusinessesService.loadHistoricNotesPresents(
      this.bidStateService.bidVersionId,
      this.bidStateService.cbId
    );
  }

  public trackByNdcIdFn(index: number, ndcEntity: Ndc): string {
    return ndcEntity.ndc;
  }

  protected initRowHandlers(): void {
    this.bidViewService.isReady$.pipe(
      filter((isReady: boolean) => isReady),
      take(1),
      untilDestroyed(this)
    ).subscribe(() => {
      this.addExpandRowHandler();
      this.reloadNdcsHandler();
      this.initSectionChangedHandler();
    });
  }

  protected reloadNdcsHandler(): void {
    this.bidEventBusService.reloadLoadedNdcsEvent.pipe(
      untilDestroyed(this),
      filter((scenarioIds: number[] | null) => scenarioIds && scenarioIds.includes(this.scenario.id)),
      switchMap(() => this.reloadNdcs()),
    ).subscribe();
  }

  protected reloadNdcs(): Observable<null> {
    return this.isNdcsLoaded ? this.loadNdcs(true) : of(null);
  }

  protected addExpandRowHandler(): void {
    this.scenarioStateConfig.isExpanded$.pipe(
      untilDestroyed(this),
      filter(isExpanded => isExpanded && !this.scenarioStateConfig.isNdcsLoading$.getValue()),
      switchMap(() => this.loadNdcs()),
    ).subscribe();
  }

  protected initNdcsHandler(): void {
    this.ndcManagerService.ndcs
      .pipe(
        map((ndcs: Map<number, Ndc[]>) => ndcs.get(this.scenario.id)),
        filter((ndcs: Ndc[]) => CoreUtils.isDefined(ndcs)),
        tap(() => this.isNdcsLoaded = true),
        untilDestroyed(this)
      )
      .subscribe((ndcs: Ndc[]) => this.ndcs$.next(ndcs));
  }

  // eslint-disable-next-line no-empty-function
  protected initSectionChangedHandler(): void {}

  private loadAndExpandNdcs(): void {
    this.scenarioStateConfig.isNdcsLoading$.next(true);
    this.loadNdcs()
      .pipe(
        take(1),
        untilDestroyed(this),
        delay(this.expandDelay),
        tap(() => this.scenarioStateConfig.isNdcsLoading$.next(false)),
        finalize(() => this.scenarioStateConfig.toggleExpand()),
      )
      .subscribe();
  }

  private initSectionChangeFailedHandler(): void {
    this.sectionChangeManager.isSectionChangeFailed$.pipe(
      tap(() => this.subscriptionLiveHandler()),
      takeUntil(this.destroyed$),
    ).subscribe(({ sectionName }: SectionChangeFailed) =>
      this.scenariosSnackBarService.sectionChangeFailed$.next(sectionName));
  }

  private initSectionChangeSuccessHandler(): void {
    this.sectionChangeManager.sectionSaveSuccessEvent$.pipe(
      tap(() => this.subscriptionLiveHandler()),
      takeUntil(this.destroyed$),
    ).subscribe((sectionChange: SectionChange) => this.handleSectionChangeSuccess(sectionChange));
  }

  private handleSectionChangeSuccess(sectionChange: SectionChange): void {
    this.bidEventBusService.undoRedoEvent.emit();

    if (sectionChange.drugId) {
      this.handleNdcSectionChange(sectionChange);
    } else {
      this.handleScenarioSectionChange(sectionChange);
    }
  }

  private handleScenarioSectionChange(sectionChange: SectionChange): void {
    const { scenarioId, sectionName, section } = sectionChange;

    this.sectionChangeManager.onScenarioSectionUpdated(scenarioId, sectionName, section);

    if (this.drugAlertNotificationService.shouldReloadDrugAlerts(sectionName)) {
      this.drugAlertNotificationService.reloadScenarioDrugAlerts(scenarioId).pipe(take(1)).subscribe();
    }

    this.reloadNdcs().subscribe(() => {
      this.scenariosSnackBarService.sectionChangeSuccess$.next(sectionName);
    });
  }

  private handleNdcSectionChange(sectionChange: SectionChange): void {
    const { scenarioId, drugId, sectionName, section } = sectionChange;

    this.sectionChangeManager.onNdcSectionUpdated(scenarioId, drugId, sectionName, section);

    if (this.drugAlertNotificationService.shouldReloadDrugAlerts(sectionName)) {
      this.drugAlertNotificationService.reloadNdcDrugAlerts(scenarioId, drugId).pipe(take(1)).subscribe();
    }

    this.bidStateService.scenarios$.pipe(
      skip(1),
      take(1)
    ).subscribe(() => this.scenariosSnackBarService.sectionChangeSuccess$.next(sectionName));

    this.bidEventBusService.reloadScenariosEvent.emit([scenarioId]);
  }
}
