import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { HtmlEditorService } from '@qv-shared/services';

@Component({
  selector: 'qv-html-editor',
  templateUrl: './html-editor.component.html',
  styleUrls: ['./html-editor.component.scss']
})
export class HtmlEditorComponent implements OnChanges {
  @Input()
  public isEditMode: boolean;
  @Input()
  public initialValue: string;
  @Input()
  public control: FormControl;
  @Input()
  public isDisabled: boolean;
  @Output()
  public initEvent = new EventEmitter();

  public readonly plugins = [
    'advlist', 'autolink', 'lists', 'link', 'charmap', 'print', 'preview', 'anchor',
    'searchreplace', 'visualblocks', 'code', 'insertdatetime', 'paste', 'autoresize'
  ];

  public config = {
    statusbar: false,
    menubar: false,
    base_url: '/tinymce',
    suffix: '.min',
    plugins: this.plugins.join(' '),
    toolbar: 'undo redo | bold italic underline strikethrough | ' +
      'bullist numlist outdent indent | alignleft aligncenter alignright alignjustify | ' +
      'forecolor backcolor | link | styleselect | fontselect fontsizeselect | code ',
    trusted: 'true',
    fontsize_formats: '80%=11.2px 100%=14px 120%=16.8px 140%=19.6px 160%=22.4px 200%=28px',
    autoresize_max_height: 512,
    autoresize_overflow_padding: 10,
    autoresize_bottom_margin: 25,
    setup: (editor) => {
      this.htmlEditorService.htmlEditor = editor;

      editor.on('init', () => {
        this.addEditorLinkClickEventListener(editor);
        this.setEditMode();

        this.initEvent.next();
      });
    }
  };

  constructor(
    private htmlEditorService: HtmlEditorService
  ) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.isDisabled && this.control) {
      changes.isDisabled.currentValue
        ? this.control.disable({ emitEvent: false })
        : this.control.enable({ emitEvent: false });
    }

    if (changes.isEditMode) {
      this.setEditMode();
    }
  }

  private addEditorLinkClickEventListener(editor: any): void {
    if (!editor.bodyElement) return;

    editor.bodyElement.addEventListener('click', (event: MouseEvent) => {
      const targetElement = event.target as HTMLElement;

      if (targetElement && targetElement.nodeName === 'A') {
        const href = targetElement.getAttribute('href');
        const target = targetElement.getAttribute('target');

        if (href) {
          window.open(href, target || '_self');
        }
      }
    });
  }

  private setEditMode(): void {
    this.htmlEditorService.htmlEditor?.getBody()?.setAttribute('contenteditable', this.isEditMode);
  }
}
