import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ScenarioSummaryModalData } from './scenario-summary-modal-data';
import { ModalConfig, ModalSize } from 'quantuvis-angular-common/modal';
import { ScenarioSummaryModalComponent } from '../scenario-summary-modal.component';

export class ScenarioSummaryModalConfig
  extends ModalConfig<ScenarioSummaryModalComponent, ScenarioSummaryModalData> {

  public constructor(data: ScenarioSummaryModalData, width: ModalSize) {
    super(data, width);
  }

  protected createDialogRef(dialog: MatDialog): MatDialogRef<ScenarioSummaryModalComponent> {
    return dialog.open(ScenarioSummaryModalComponent, this);
  }
}
