import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDatepicker, MatDatepickerInputEvent } from '@angular/material/datepicker';
import { KeyCode, SeverityLevel, SvgIconName } from '@qv-common/enums';
import { DateUtils, ErrorUtils, StringUtils } from '@qv-common/utils';
import { TermTemplateStorageService } from '@qv-term/services';
import { ValidationError } from '@qv-common/models';
import moment, { Moment } from 'moment';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { QvCache } from '@qv-common/decorators';
import { BaseTermWithLockComponent } from '@qv-term/components/base-term-with-lock/base-term-with-lock.component';
import { appConfig } from '@qv-common/configs';
import { UserService } from '@qv-common/services/auth/user.service';

@UntilDestroy()
@Component({
  selector: 'qv-term-date',
  templateUrl: './term-date.component.html',
  styleUrls: ['./term-date.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DateTermComponent extends BaseTermWithLockComponent implements OnChanges {
  @Input()
  public errors: ValidationError[] = [];
  @Input()
  public control = new FormControl();
  @Input()
  public isDisplayErrors = true;
  @Input()
  public hasPickerIcon = true;

  @Output()
  public dateChanged = new EventEmitter();

  @ViewChild('dateInput', { read: ElementRef })
  public dateInput: ElementRef<HTMLInputElement>;

  public datePicker: MatDatepicker<Moment>;
  public readonly dateFormat = appConfig.dateFormat;
  public readonly minDate = DateUtils.getMinDatePickerDate();
  public readonly maxDate = DateUtils.getMaxDatePickerDate();
  public readonly severityLevel = SeverityLevel;
  public readonly svgIconName = SvgIconName;
  public errorLevel: SeverityLevel;
  public combinedErrors: ValidationError[] = [];
  private isEditModeInited = false;

  constructor(
    protected termTemplateStorage: TermTemplateStorageService,
    userService: UserService, changeDetectorRef: ChangeDetectorRef
  ) {
    super(userService, termTemplateStorage, changeDetectorRef);
  }

  @ViewChild(MatDatepicker)
  set component(cmp: MatDatepicker<Moment>) {
    if (!cmp) return;
    this.datePicker = cmp;
    this.datePicker.closedStream.pipe(untilDestroyed(this)).subscribe(() => {
      this.exitFromEditModeEvent.emit();

      if (this.dateInput) {
        this.dateInput.nativeElement.blur();
      }
    });
    this.datePicker.openedStream.pipe(untilDestroyed(this)).subscribe(
      () => setTimeout(() => this.dateInput.nativeElement.focus())
    );
  }

  public ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);

    if (changes.isEditMode && changes.isEditMode.currentValue || this.isOverrideMode) {
      const isEditPresentation = this.isEditPresentation(
        this.termValue,
        this.isEditMode,
        this.isOverrideMode,
        this.isUserPharma,
        this.isLocked
      );

      if (isEditPresentation && !this.isEditModeInited) {
        this.initEditMode();
        this.isEditModeInited = true;
      }
    } else if (changes.errors && changes.errors.currentValue) {
      this.processError();
    }
  }

  public onDateChanged(event: MatDatepickerInputEvent<Moment>): void {
    const convertedDate = DateUtils.convertDateToStr(event, this.control, this.dateFormat);
    const date = moment(convertedDate, this.dateFormat);
    const formattedDate = DateUtils.getFormattedDate(date, this.dateFormat);

    if (formattedDate && formattedDate.isValid()) {
      this.control.setValue(formattedDate);
      this.deactivateOverrideMode();
    } else if (StringUtils.isString(convertedDate)) {
      this.control.setErrors({ invalidFormat: true });
      this.control.updateValueAndValidity();
    }
  }

  @HostListener('keydown', ['$event'])
  public handleKeyDownEvent(event: KeyboardEvent): void {
    if (event.key === KeyCode.Tab) {
      this.datePicker.close();
    }
  }

  @HostListener('keyup', ['$event'])
  public handleKeyUpEvent(event: KeyboardEvent): void {
    if (event.key === KeyCode.Enter) {
      this.datePicker.close();
      event.stopPropagation();
    }
  }

  @QvCache()
  public isErrorsVisible(isDisplayErrors: boolean, hasErrors: boolean): boolean {
    return isDisplayErrors && hasErrors;
  }

  public onClick(): void {
    this.disableNavEvent.emit();

    if (!this.hasPickerIcon) {
      this.datePicker.open();
    }
  }

  public focus(): void {
    this.datePicker.open();
  }

  public onCloseDatePicker(): void {
    this.dateInput.nativeElement.focus();
  }

  public transferToEditMode(): void {
    this.datePicker.open();
    this.changeDetectorRef.markForCheck();
  }

  public isEditingAvailable(): boolean {
    return Boolean(this.datePicker) || super.isEditingAvailable();
  }

  private initEditMode(): void {
    this.updateFormattedDate(this.control.value);
    this.dateChanged.emit(this.control.value);

    if (this.control) {
      this.control.registerOnChange(() => this.changeDetectorRef.markForCheck());
      this.control.valueChanges.pipe(untilDestroyed(this)).subscribe(() => this.dateChanged.emit(this.control.value));
      this.control.statusChanges.pipe(untilDestroyed(this)).subscribe(() => this.processError());
    }
  }

  private updateFormattedDate(value: Moment): void {
    if (value) {
      const formattedDate = DateUtils.getFormattedDate(value, this.dateFormat);

      if (formattedDate && formattedDate.isValid()) {
        this.control.setValue(formattedDate, { emitEvent: false });
      }
    }
  }

  private processError(): void {
    this.combinedErrors = this.errors;
    if (this.control && this.control.errors) {
      this.combinedErrors = [...this.errors, this.control.errors as ValidationError];
    }

    this.updateErrorLevel();
  }

  private updateErrorLevel(): void {
    this.errorLevel = this.combinedErrors && this.combinedErrors.length
      ? ErrorUtils.getMaxSeverity(this.combinedErrors)
      : null;
  }
}
