import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { InstructionsModalData } from './instructions-modal-data';
import {
  InstructionsModalComponent
} from '@qv-bid/components/shared/summary-panel/instructions-modal/instructions-modal.component';
import { ModalConfig, ModalSize } from 'quantuvis-angular-common/modal';

export class InstructionsModalConfig
  extends ModalConfig<InstructionsModalComponent, InstructionsModalData> {

  constructor(data: InstructionsModalData) {
    super(data, ModalSize.X_LARGE);
  }

  protected createDialogRef(dialog: MatDialog): MatDialogRef<InstructionsModalComponent> {
    return dialog.open(InstructionsModalComponent, this);
  }
}
