import { Inject, Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BID_VIEW_CONFIG, BidViewConfig } from '@qv-bid/configs/bid-view-config';
import { BehaviorSubject, Observable, OperatorFunction, pipe } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { RenumberScenario } from '@qv-bid/models/renumber-scenario';
import { ScenarioDaoService } from '@qv-bid/services/dao';
import { debounceTime, distinctUntilChanged, filter, map, switchMap, take, tap } from 'rxjs/operators';
import { Scenario } from '@qv-bid/entities';
import { BidStateService } from '@qv-bid/services/bid-state.service';
import { BidEventBusService } from '@qv-bid/services/bid-event-bus.service';
import { ErrorNotificationService } from '@qv-common/services/error-notification.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ViewPerspectiveService } from '@qv-common/services/auth';
import { BidUtils } from '@qv-bid/utils';
import { UserService } from '@qv-common/services/auth/user.service';

@UntilDestroy()
@Injectable()
export class ScenarioNameFormService {
  public editModeForNumber$ = new BehaviorSubject<number>(null);
  public isFocusOnScenarioNumber$ = new BehaviorSubject<boolean>(false);

  public scenariosFormGroup: FormGroup = new FormGroup({});
  private loadedScenarios: Scenario[] = [];

  constructor(
    private bidStateService: BidStateService,
    private scenarioDaoService: ScenarioDaoService,
    private bidEventBusService: BidEventBusService,
    private errorNotificationService: ErrorNotificationService,
    private userService: UserService,
    @Inject(BID_VIEW_CONFIG) private bidViewConfig: BidViewConfig,
    private viewPerspectiveService: ViewPerspectiveService,
  ) {
    this.initHandleChangeFormControlValue();
  }

  public getFormControl(scenario: Scenario): FormControl {
    if (!this.scenariosFormGroup.controls[scenario.id]) {
      this.scenariosFormGroup
        .addControl(scenario.id.toString(), new FormControl(this.getNumberFromScenarioName(scenario.name),
          [
            Validators.required.bind(this),
            Validators.pattern('^[0-9]*$'),
            Validators.min(1),
            Validators.max(99),
            this.duplicateNameValidator.bind(this, scenario),
          ]));
    }
    return this.scenariosFormGroup.controls[scenario.id] as FormControl;
  }

  public initHandleChangeFormControlValue(): void {
    this.handleChangeLoadedScenarios();
    this.scenariosFormGroup.valueChanges
      .pipe(distinctUntilChanged(),
        debounceTime(this.bidViewConfig.scenarioNameUpdateDelayTime),
        untilDestroyed(this)
      ).subscribe(this.updateScenariosArray.bind(this));
  }

  public selectSectionName(isEditMode: boolean, scenario: Scenario): void {
    if (BidUtils.isUserPharmaOrPharmaPerspective(this.userService.isCurrentUserPharma(),
      this.bidStateService.bid$.getValue().isInternal, this.viewPerspectiveService.isPharmaViewPerspective())) {
      return;
    }

    if (isEditMode) {
      this.editModeForNumber$.next(scenario.id);
    }

    const control = this.getFormControl(scenario);
    if (control.value !== this.getNumberFromScenarioName(scenario.name)) {
      control.setValue(this.getNumberFromScenarioName(scenario.name));
    }
  }

  public handleFocus(): void {
    this.isFocusOnScenarioNumber$.next(true);
  }

  public handleBlur(): void {
    this.isFocusOnScenarioNumber$.next(false);
    this.checkFocusOnInputAndSendRequest();
  }

  public isScenarioNumberCorrect(scenarioId: number): boolean {
    return this.scenariosFormGroup.controls[scenarioId].valid;
  }

  public isScenarioNumberIncorrect(scenarioId: number): boolean {
    return this.scenariosFormGroup.controls[scenarioId].hasError('max')
      || this.scenariosFormGroup.controls[scenarioId].hasError('min')
      || this.scenariosFormGroup.controls[scenarioId].hasError('required')
      || this.scenariosFormGroup.controls[scenarioId].hasError('pattern');
  }

  public isScenarioNumberExist(scenarioId: number): boolean {
    return this.scenariosFormGroup.controls[scenarioId].hasError('numberExist');
  }

  public clearFormGroup(): void {
    const keys = Object.keys(Object.assign(this.scenariosFormGroup.controls));
    keys.forEach((key: string) => this.scenariosFormGroup.removeControl(key));
  }

  private checkFocusOnInputAndSendRequest(): void {
    this.isFocusOnScenarioNumber$.pipe(
      debounceTime(100),
      take(1),
      filter((isSectionNameFocus: boolean) => !isSectionNameFocus && this.scenariosFormGroup.valid),
      tap(() => this.editModeForNumber$.next(null)),
      map(() => this.getRenumberedScenarios()),
      filter((scenarios) => Boolean(scenarios.length)),
      tap(() => this.scenariosFormGroup.markAsPristine()),
      switchMap((scenarios) => this.sendRenumberScenarios(scenarios)),
    ).subscribe();
  }

  private sendRenumberScenarios(scenarios: RenumberScenario[]): Observable<null | HttpErrorResponse> {
    const { isInternal } = this.bidStateService.bid$.getValue();
    return this.scenarioDaoService.renumberScenarios(this.bidStateService.bidVersionId, scenarios, isInternal).pipe(
      this.reloadBidAndHandleError()
    );
  }

  private getRenumberedScenarios(): RenumberScenario[] {
    const renumberScenarios: RenumberScenario[] = [];
    Object.keys(this.scenariosFormGroup.controls)
      .filter((key) => this.scenariosFormGroup.controls[key].dirty)
      .forEach((key) => {
        this.renumberScenariosExistByScenarioId(renumberScenarios, key)
          ? this.updateScenarioNumber(renumberScenarios, key)
          : renumberScenarios.push(this.convertToRenumberScenario(parseInt(key, 10)));
      });
    return renumberScenarios;
  }

  private renumberScenariosExistByScenarioId(renumberScenarios: RenumberScenario[], key: string): boolean {
    return renumberScenarios.some((obj: RenumberScenario) => obj.scenarioId === parseInt(key, 10));
  }

  private updateScenarioNumber(renumberScenarios: RenumberScenario[], key: string): void {
    renumberScenarios
      .filter((obj: RenumberScenario) => obj.scenarioId === parseInt(key, 10))
      .map((obj: RenumberScenario) => {
        obj.scenarioNumber = parseInt(this.scenariosFormGroup.controls[key].value, 10);
      });
  }

  private updateScenariosArray(): void {
    Object.keys(this.scenariosFormGroup.controls)
      .forEach((key) => {
        this.loadedScenarios
          .filter((scenario: Scenario) => scenario.id === parseInt(key, 10))
          .forEach((scenario: Scenario) => scenario.name = `S${this.scenariosFormGroup.controls[key].value}`);
      });
  }

  private duplicateNameValidator(scenarioControl: Scenario, scenarioNameControl: FormControl): { [key: string]: boolean } | null {
    const activeScenario = scenarioControl;
    const currentScenarioNumber = parseInt(scenarioNameControl.value, 10);
    const isScenarioNumberExist = this.loadedScenarios
      .filter((scenario: Scenario) => scenario.drugName === activeScenario.drugName)
      .filter((scenario: Scenario) => scenario.cbId === activeScenario.cbId)
      .some((scenario: Scenario) =>
        (this.getNumberFromScenarioName(scenario.name) === currentScenarioNumber
          && scenario.id !== activeScenario.id));
    return isScenarioNumberExist ? { numberExist: true } : null;
  }

  private handleChangeLoadedScenarios(): void {
    this.bidStateService.scenarios$.pipe(untilDestroyed(this))
      .subscribe((scenarios: Scenario[]) => this.loadedScenarios = scenarios);
  }

  private convertToRenumberScenario(scenarioId: number): RenumberScenario {
    const scenarioNumber = parseInt(this.scenariosFormGroup.controls[scenarioId].value, 10);
    return Object.assign(new RenumberScenario(), { scenarioId, scenarioNumber });
  }

  private reloadBidAndHandleError<T>(): OperatorFunction<T, T> {
    return pipe(
      tap(() => this.bidEventBusService.undoRedoEvent.emit()),
      tap(() => this.bidEventBusService.reloadBidEvent.emit()),
      this.errorNotificationService.catchServerError()
    );
  }

  private getNumberFromScenarioName(scenarioName: string): number {
    return parseInt(scenarioName.substr(1), 10);
  }

}
