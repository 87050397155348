import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ContractFormService } from '@qv-bid/components/shared/summary-panel/contract/contract-form.service';
import { ScenarioNameFormService } from '@qv-bid/services/scenario-name-form.service';
import { BidStateService } from '@qv-bid/services/bid-state.service';
import { BidCommentFormService } from '@qv-bid/services/summary/bid-comment-form.service';
import { SummaryFormService } from '@qv-bid/services/summary/summary-form.service';
import { ValidationStateEnum } from '@qv-common/enums';
import { TermSection } from '@qv-term/enums';
import { distinctUntilChanged, filter } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Injectable()
export class BidFormService {
  private readonly bidForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private contractService: ContractFormService,
    private summaryFormService: SummaryFormService,
    private bidStateService: BidStateService,
    private bidCommentFormService: BidCommentFormService,
    private scenarioNameFormService: ScenarioNameFormService,
  ) {

    const mockControl = { mockControl: new FormControl() };
    this.bidForm = this.formBuilder.group({
      [TermSection.SUMMARY]: new FormGroup({}),
      [TermSection.CONTRACT]: new FormGroup({}),
      [TermSection.COMMENTS]: new FormGroup({}),
      [TermSection.DRUG]: new FormGroup(mockControl),
      [TermSection.SCENARIO_NAME]: this.scenarioNameFormService.scenariosFormGroup,
    });

    this.contractService.isContractFormReady$.pipe(
      filter((isReady: boolean) => isReady),
      untilDestroyed(this)
    ).subscribe(() => this.buildContractForm());
    this.summaryFormService.isFormReady$.pipe(
      filter((isReady: boolean) => isReady),
      untilDestroyed(this)
    ).subscribe(() => this.buildSummaryForm());
    this.bidCommentFormService.isFormReady$.pipe(
      filter((isReady: boolean) => isReady),
      untilDestroyed(this)
    ).subscribe(() => this.buildCommentForm());

    this.bidForm.statusChanges
      .pipe(
        distinctUntilChanged(),
        untilDestroyed(this)
      )
      .subscribe((status: string) => {
        this.bidStateService.isBidInvalid$.next(ValidationStateEnum.VALID !== status);
      });
  }

  public getBidForm(sectionName?: string): FormGroup {
    return sectionName ? (this.bidForm.get(sectionName) as FormGroup) : this.bidForm;
  }

  public isBidValid(): boolean {
    this.bidForm.updateValueAndValidity({ emitEvent: false });

    return this.bidForm.valid && !this.bidForm.pending;
  }

  public setDrugFormToBidForm(drugForm: FormGroup): void {
    this.bidForm.setControl(TermSection.DRUG, drugForm);
  }

  private buildContractForm(): void {
    this.bidForm.setControl(TermSection.CONTRACT, this.contractService.contractFormGroup);
  }

  private buildSummaryForm(): void {
    this.bidForm.setControl(TermSection.SUMMARY, this.summaryFormService.summaryFormGroup);
  }

  private buildCommentForm(): void {
    this.bidForm.setControl(TermSection.COMMENTS, this.bidCommentFormService.formControl);
  }
}
