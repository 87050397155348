import { Injectable } from '@angular/core';
import { BidView } from '@qv-bid/enums';
import { LocalStorageService } from 'ngx-webstorage';
import { LocalStorageKeys } from '@qv-common/enums';

@Injectable()
export class BidViewStateService {
  public bidView: string;

  private readonly storeKey = LocalStorageKeys.BID_VIEW;

  constructor(private localStorageService: LocalStorageService) {
    this.calculateRoute(this.localStorageService.retrieve(this.storeKey) || BidView.STANDARD);
  }

  public setBidView(bidView: BidView): void {
    this.calculateRoute(bidView);
    this.localStorageService.store(this.storeKey, bidView);
  }

  private calculateRoute(currentBidView: BidView): void {
    this.bidView = currentBidView === BidView.STANDARD ? '/standard/bid' : '/grid/bid';
  }
}
