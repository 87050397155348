<div class="input-list">
  <div class="input-list__label">{{ title }}</div>
  <div class="input-list__wrapper">
    <div [formGroup]="formGroup">
      <ng-container formArrayName="formArray">
        <div *ngFor="let control of formArray.controls; index as i; last as isLast" class="input-row">
          <div class="input-row__input">
            <input
              [formControlName]="i"
              [maxlength]="inputMaxLength"
              [placeholder]="placeholder"
              [class.input-list__input--edit-mode]="isEditMode"
              [class.input-list__input--error]="control.invalid && control.touched"
              class="acc-input acc-input--primary input-list__input"
              [accAutofocus]="isLast">
            <acc-form-field-errors
              *ngIf="control.invalid && control.touched"
              [control]="control"
              [validationMessages]="validationMessages"
            ></acc-form-field-errors>
          </div>
          <div *ngIf="isEditMode" class="acc-menu__item-buttons">
            <button class="icon-remove-filled acc-menu__button acc-menu__button--remove" (click)="onRemoveItem(i)"></button>
            <button
              *ngIf="isLast && !!control.value && (this.isControlsLengthLessThanElementsLimit$ | async)"
              class="icon-add-filled acc-menu__button acc-menu__button--add"
              (click)="onAddItem()"
            ></button>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
