/**
 * Tooltips
 * Directive used for displaying a custom tooltip on hover
 */
export const Tooltip = (): any => ({
  restrict: 'A',
  link: (scope, element, attrs) => {
    // @ts-ignore
    $(element).tooltip(
      {
        placement: 'bottom',
        html: true,
        title: scope.$eval(attrs.qvTooltip),
        delay: 100
      }
    );
  }
});
