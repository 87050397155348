/**
 * Custom AngularJS services used by the APP logic
 */
import { ApiUrlPrefix } from '@qv-common/enums';
import { HttpResponse } from '@angular/common/http';
import { tap } from 'rxjs/operators';

export const Role = ['apiService', function(apiService): any {
  'ngInject';
  let roles;

  function fetchRoles(method, scope, callback) {
    apiService.get(ApiUrlPrefix.OLD + method).pipe(
      tap((response: HttpResponse<any>) => {
        const rolesData = response.body;
        scope.roles = rolesData;
        roles = rolesData;
        if (callback) {
          callback();
        }
      }),
    ).subscribe();
  }

  this.fetchRoles = function(scope, callback) {
    fetchRoles('/roles/forCurrentUser', scope, callback);
  };

  this.fetchRolesForUserType = function(scope, userType, callback) {
    fetchRoles(`/roles/forUserType/${userType}`, scope, callback);
  };

  return {
    fetchRoles: this.fetchRoles,
    fetchRolesForUserType: this.fetchRolesForUserType
  };
}];
