import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'acc-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class SpinnerComponent {
  @Input()
  public spinnerClass = 'spinner-ring--16';
}
