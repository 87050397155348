import { Contract, PriceProtection, Rebate, UtilizationManagement } from '@qv-bid/entities';
import { MarketShare } from '@qv-bid/entities/market-share.entity';
import { TermSection } from '@qv-term/enums';
import { MarketBasketSection } from './market-basket-section';

export type Section = Rebate | UtilizationManagement | Contract | PriceProtection | MarketShare | MarketBasketSection;

export class SectionChange {
  constructor(
    public scenarioId: number,
    public drugId: number,
    public sectionName: TermSection,
    public section: Section
  ) {}
}
