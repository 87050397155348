/* tslint:disable:no-var-requires */
import isEqual from 'lodash.isequal';
import { SvgIconName } from '@qv-common/enums';
import { constants, resources } from '@qv-common/static';
import { drugTermsConstants } from '@qv-term/constants';
import { TermName } from '@qv-term/enums';
import { GeneralModalConfig, GeneralModalData, ModalSize } from 'quantuvis-angular-common/modal';

// @ts-ignore
import templateUrl from './drug-scenarios.html';

const DrugScenariosComponent = {
  template: templateUrl,
  bindings: {
    list: '<',
    hideLocks: '<',
    onAction: '&'
  },
  controller: class {
    public static $inject = ['drugScenariosService', 'translations', 'standards', 'modalService'];
    public onAction;
    private drugScenariosService;
    private translations;
    private standards;
    private hideLocks;
    private modalService;
    private termLabels;
    private tierPlacements;
    private tierCompetitions;
    private competitionGroups;
    private competitorsTiers;
    private stepTherapyAllowedOptions;
    private stepTherapyRequiredOptions;
    private priorAuthorizationAllowedOptions;
    private priorAuthorizationRequiredOptions;
    private otherUMOptions;
    private umDetailsMaxLength;
    private quantityLimitOptions;
    private modelOptions;
    private svgIconName;
    private list;

    constructor(drugScenariosService, translations, standards, modalService) {
      'ngInject';
      this.drugScenariosService = drugScenariosService;
      this.translations = translations;
      this.standards = standards;
      this.hideLocks = false;
      this.modalService = modalService;
    }
    public $onInit(): void {
      this.translations.buildTranslations(this, 'TermsDescriptions');
      this.termLabels = constants.DrugList;
      this.tierPlacements = this.drugScenariosService.getTermPropertyByName(
        drugTermsConstants[TermName.TIER_PLACEMENT].title,
        'values',
        []
      );
      this.tierCompetitions = this.drugScenariosService.getTermPropertyByName(
        drugTermsConstants[TermName.TIER_COMPETITION].title,
        'values',
        []
      );
      this.competitionGroups = this.drugScenariosService.getTermPropertyByName(
        drugTermsConstants[TermName.COMPETITION_GROUP].title,
        'values',
        []
      );
      this.competitorsTiers = this.drugScenariosService.getTermPropertyByName(
        drugTermsConstants[TermName.COMPETITORS_TIER].title,
        'values',
        []
      );
      this.stepTherapyAllowedOptions = this.drugScenariosService.getTermPropertyByName(
        drugTermsConstants[TermName.STEP_THERAPY_ALLOWED_ON_PRODUCT].title,
        'values',
        []
      );
      this.stepTherapyRequiredOptions = this.drugScenariosService.getTermPropertyByName(
        drugTermsConstants[TermName.STEP_THERAPY_REQUIRED_ON_COMPETITORS_PRODUCTS].title,
        'values',
        []
      );
      this.priorAuthorizationAllowedOptions = this.drugScenariosService.getTermPropertyByName(
        drugTermsConstants[TermName.PRIOR_AUTHORIZATION_ALLOWED_ON_PRODUCT].title,
        'values',
        []
      );
      this.priorAuthorizationRequiredOptions = this.drugScenariosService.getTermPropertyByName(
        drugTermsConstants[TermName.PRIOR_AUTHORIZATION_REQUIRED_ON_COMPETITORS_PRODUCTS].title,
        'values',
        []
      );
      this.quantityLimitOptions = this.drugScenariosService.getTermPropertyByName(
        drugTermsConstants[TermName.QUANTITY_LIMIT].title,
        'values',
        []
      );
      this.otherUMOptions = this.drugScenariosService.getTermPropertyByName(
        drugTermsConstants[TermName.OTHER_UM].title,
        'values',
        []
      );
      this.umDetailsMaxLength = this.drugScenariosService.getTermPropertyByName(
        drugTermsConstants[TermName.UM_DETAILS].title,
        'maxLength',
        null
      );

      this.modelOptions = {
        updateOn: 'default blur',
        debounce: {
          default: constants.APP.TERM_CHANGE_DEBOUNCE_INTERVAL,
          blur: 0
        }
      };

      this.svgIconName = SvgIconName;
    }

    public $onChanges(changes): void {
      if (changes.hideLocks && changes.hideLocks.currentValue !== 'undefined') {
        this.hideLocks = changes.hideLocks.currentValue;
      }
    }

    public allowAddingScenarios(): any {
      return this.list.length < constants.SCENARIOS_MAX_LENGTH;
    }

    public checkUMDetails(newScenario, oldScenario): void {
      const umDetailsFilled = (newScenario.UMDetails && typeof newScenario.UMDetails.trim === 'function') ?
        !!newScenario.UMDetails.trim() : false;
      if (this.shouldClearUMDetails(newScenario) && umDetailsFilled) {
        this.showUMResetConfirmation(newScenario, oldScenario);
      }
    }

    public shouldClearUMDetails(scenario): any {
      const negativeValuesArray = ['No', ''];
      const umFields = [
        scenario.stepTherapyAllowed,
        scenario.stepTherapyRequired,
        scenario.priorAuthorizationAllowed,
        scenario.priorAuthorizationRequired,
        scenario.quantityLimit,
        scenario.otherUM,
      ];
      return umFields.every((field) => (negativeValuesArray.indexOf(field) !== -1));
    }

    public onActionItem($event, action, scenarioIndex): void {
      $event.preventDefault();
      $event.stopPropagation();
      this.onAction({
        $event: {
          action,
          scenarioIndex
        }
      });
    }

    public showUMResetConfirmation(scenario, oldScenario): void {
      const modalData = new GeneralModalData(resources.GENERAL.CONFIRM_NAVIGATION_TITLE,
        resources.POPUPS.RESET_UM_DETAILS,
        resources.Actions.YES.toUpperCase(), resources.Actions.NO.toUpperCase());
      const modalConfig = new GeneralModalConfig(modalData, ModalSize.MEDIUM);
      const dialogRef = this.modalService.openModal(modalConfig);

      dialogRef.componentInstance.primaryAction.subscribe(() => scenario.UMDetails = '');
      dialogRef.componentInstance.secondaryAction.subscribe(() => {
        const scenarioIndex = this.list.findIndex(object => isEqual(object, scenario));
        this.list[scenarioIndex] = oldScenario;
      });
    }

    public toggleTiersLock(scenario): void {
      scenario.lockTierOptions = !scenario.lockTierOptions;
    }

    public toggleUMLock(scenario): void {
      scenario.lockUMOptions = !scenario.lockUMOptions;
    }
  }
};
export default DrugScenariosComponent;
