import { NgModule } from '@angular/core';
import UiViewWrapperComponent from './ui-view-wrapper.component';

declare let angular: angular.IAngularStatic;

export const UiViewWrapperNgModule = angular
  .module('quantuvis.ui-view-wrapper', [])
  .component('ui-view-wrapper', UiViewWrapperComponent);

@NgModule({})
export class UiViewWrapperModule {}
