import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnChanges,
  OnInit,
  SimpleChanges
} from '@angular/core';
import { ContractManager } from '@qv-bid/components/shared/base/classes/contract.manager';
import { ContractFormService } from '@qv-bid/components/shared/summary-panel/contract/contract-form.service';
import { PharmaAudit } from '@qv-bid/entities';
import { SingleNotificationService } from '@qv-shared/services';
import { BidFormService } from '@qv-bid/services';
import { PharmaAuditDaoService } from '@qv-bid/services/dao/contract-terms';
import { Units } from '@qv-common/enums';
import { TermName, TermSection } from '@qv-term/enums';
import { NumberUtils } from '@qv-common/utils';
import { NotificationService } from 'quantuvis-angular-common/notification';
import { SnackBarService } from 'quantuvis-angular-common/snack-bar';

@Component({
  selector: 'qv-pharma-audit',
  templateUrl: './pharma-audit.component.html',
  styleUrls: ['./pharma-audit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PharmaAuditComponent extends ContractManager<PharmaAudit> implements OnInit, OnChanges {
  public readonly termName = TermName;
  public readonly units = new Units();
  public readonly intNumberOptions = NumberUtils.intNumberOptions;

  public sectionName = TermSection.PHARMA_AUDIT;

  constructor(
    protected daoService: PharmaAuditDaoService,
    protected contractFormService: ContractFormService,
    protected bidFormService: BidFormService,
    protected changeDetectorRef: ChangeDetectorRef,
    protected notificationService: NotificationService,
    snackBarService: SnackBarService,
    singleNotificationService: SingleNotificationService,
  ) {
    super(
      contractFormService,
      bidFormService,
      changeDetectorRef,
      notificationService,
      snackBarService,
      singleNotificationService
    );
  }

  public ngOnInit(): void {
    if (Number.isInteger(this.contractedBusinessId)) {
      this.loadEntity();
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.contractedBusinessId && !changes.contractedBusinessId.firstChange) {
      this.loadEntity();
    } else if (changes.isEditMode && changes.isEditMode.currentValue) {
      this.initEditMode();
    }
  }

  protected buildFormForSection(source: PharmaAudit): void {
    this.contractFormService.buildPharmaAuditForm(source);
  }
}
