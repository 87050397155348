import { Injectable } from '@angular/core';
import { ErrorMessage } from 'quantuvis-angular-common/notification';
import { iif, Observable } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { BlockingMessage } from '@qv-common/enums';
import { catchError, finalize, switchMap, take } from 'rxjs/operators';
import { Bid } from '@qv-bid/entities';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { BidService } from '@qv-bid/services/bid.service';
import { BidDaoService, BidLockDaoService } from '@qv-bid/services/dao';
import { resources } from '@qv-common/static';
import { ViewPerspectiveService } from '@qv-common/services/auth';
import { GeneralModalConfig, GeneralModalData, ModalService, ModalSize } from 'quantuvis-angular-common/modal';

@Injectable()
export class IntendToRespondActionsService {
  @BlockUI()
  public blockUI: NgBlockUI;

  constructor(
    private bidService: BidService,
    private bidDaoService: BidDaoService,
    private bidLockDaoService: BidLockDaoService,
    private modalService: ModalService,
    private viewPerspectiveService: ViewPerspectiveService,
  ) {}

  public respondBid(bidId: number, dismissedDrugsIds: number[]): Observable<null | HttpErrorResponse> {
    const perspective = this.viewPerspectiveService.getViewPerspective();

    this.blockUI.start(BlockingMessage.SAVING);

    return iif(() => Boolean(this.bidService.currentBid$.getValue()),
      this.bidService.currentBid$,
      this.bidService.getById(bidId, perspective)
    ).pipe(
      take(1),
      switchMap((bid: Bid) => this.bidLockDaoService.lockBid(bidId, bid.isInternal, perspective)),
      switchMap(() => this.bidDaoService.respondBid(bidId, dismissedDrugsIds)),
      finalize(() => this.blockUI.stop()),
      catchError((err: HttpErrorResponse) => this.createErrorModal(err.error.message))
    );
  }

  private createErrorModal(message: string): Observable<null> {
    const modalData = new GeneralModalData(
      resources.POPUPS.TITLES.ERROR,
      message || ErrorMessage.PROCESSING_ERROR,
      resources.Actions.REFRESH,
    );
    const modalConfig = new GeneralModalConfig(modalData, ModalSize.X_SMALL);

    return this.modalService.openModal(modalConfig).componentInstance.primaryAction.pipe(take(1));
  }
}
