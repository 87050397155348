<button
  mat-raised-button
  [matMenuTriggerFor]="moreMenu"
  class="toolbar-button toolbar-button--extra">
  <mat-icon [svgIcon]="svgIconName.MORE" class="more-horiz"></mat-icon>
</button>
<mat-menu
  #moreMenu="matMenu"
  yPosition="below"
  [overlapTrigger]="false">

  <!-- Print menu item -->
  <button
    *ngIf="!(userFeatures$ | async).get(quantuvisPlusFeature.PRINT)"
    mat-menu-item
    class="menu-button menu-button--upgrade"
    [matTooltip]="quantuvisPlusTooltip"
    [routerLink]="quantuvisPlusRouterLink"
    [queryParamsHandling]="$any(quantuvisPlusQueryParamsHandling)">
    <mat-icon svgIcon="printer"></mat-icon>
    <span>Print Bid</span>
  </button>
  <button
    *ngIf="(userFeatures$ | async).get(quantuvisPlusFeature.PRINT)"
    mat-menu-item
    [matMenuTriggerFor]="printMenu"
    [matTooltip]="resources.TOOLTIPS.BID_ACTIONS.PRINT"
    class="menu-button">
    <mat-icon svgIcon="printer"></mat-icon>
    <span>Print Bid</span>
  </button>
  <mat-menu #printMenu="matMenu">
    <button
      mat-menu-item
      class="menu-button"
      (click)="exportToPdf(false, false)">
      <span>{{resources.BIDS_LIST.DRUG_LEVEL}}</span>
    </button>
    <button
      mat-menu-item
      class="menu-button"
      (click)="exportToPdf(false, true)"
      *ngIf="isExportToPdfWithAttachmentsAvailable">
      <span>{{resources.BIDS_LIST.DRUG_LEVEL_WITH_ATTACHMENTS}}</span>
    </button>
    <button
      mat-menu-item
      class="menu-button"
      (click)="exportToPdf(true, false)">
      <span>{{resources.BIDS_LIST.NDC_LEVEL}}</span>
    </button>
    <button
      mat-menu-item
      class="menu-button"
      (click)="exportToPdf(true, true)"
      *ngIf="isExportToPdfWithAttachmentsAvailable">
      <span>{{resources.BIDS_LIST.NDC_LEVEL_WITH_ATTACHMENTS}}</span>
    </button>
    <button
      mat-menu-item
      *ngIf="isLetterOfIntentAvailable"
      class="menu-button"
      (click)="downloadLetterOfIntent()">
      <span>{{resources.BIDS_LIST.LETTER_OF_INTENT}}</span>
    </button>
    <button
      mat-menu-item
      *ngIf="isScenarioSummaryAvailable"
      (click)="showScenarioSummaryModal()">
      <span>{{resources.BIDS_LIST.SCENARIO_SUMMARY}}</span>
    </button>
  </mat-menu>

  <!-- Export menu item -->
  <button
    *ngIf="isExportButtonVisible"
    mat-menu-item
    class="menu-button"
    [matTooltip]="resources.TOOLTIPS.BID_ACTIONS.EXPORT"
    (click)="exportToExcel()">
    <mat-icon svgIcon="download"></mat-icon>
    <span>Export to Excel</span>
  </button>
  <button
    *ngIf="isExportUpgradeButtonVisible"
    mat-menu-item
    class="menu-button menu-button--upgrade"
    [matTooltip]="quantuvisPlusTooltip"
    [routerLink]="quantuvisPlusRouterLink"
    [queryParamsHandling]="$any(quantuvisPlusQueryParamsHandling)">
    <mat-icon svgIcon="download"></mat-icon>
    <span>Download Bid</span>
  </button>

  <!-- History menu item -->
  <button
    *ngIf="isBidHistoryButtonVisible"
    mat-menu-item
    class="menu-button"
    [matTooltip]="resources.TOOLTIPS.BID_ACTIONS.BID_HISTORY"
    routerLink="/bid-history"
    [queryParams]="enrichParamsWithViewPerspective({bidId: bidId}, bid.isInternal)">
    <mat-icon svgIcon="bid-history"></mat-icon>
    <span>Bid History</span>
  </button>
  <button
    *ngIf="isBidHistoryUpgradeButtonVisible"
    mat-menu-item
    class="menu-button menu-button--upgrade"
    [matTooltip]="quantuvisPlusTooltip"
    [routerLink]="quantuvisPlusRouterLink"
    [queryParamsHandling]="$any(quantuvisPlusQueryParamsHandling)">
    <mat-icon svgIcon="bid-history"></mat-icon>
    <span>Bid History</span>
  </button>
</mat-menu>
