import { resources } from '@qv-common/static';
import { drugTermsConstants } from '@qv-term/constants';
import { TermName } from '@qv-term/enums';
import { SelectBoxTerm } from '@qv-term/models/types';
import { PriorAuthAllowed } from 'quantuvis-core-entities';

export class PriorAuthorizationAllowedProductTerm extends SelectBoxTerm {

  constructor(name: string) {
    super(
      name,
      drugTermsConstants[TermName.PRIOR_AUTHORIZATION_ALLOWED_ON_PRODUCT].displayNames.ALLOWED_ON_PRODUCT,
      resources.TermsDescriptions.DRUG_LIST.PRIOR_AUTHORIZATION,
      '',
      '',
      PriorAuthAllowed.getValue(PriorAuthAllowed.NO),
      'term__prior-auth-allowed-prod'
    );
    this.values = PriorAuthAllowed.getValues();
  }
}
