import { Injectable } from '@angular/core';
import { SystemMenuItem } from 'angular-components-common';
import { environment } from '@qv-env/environment';
import { EnrollmentService } from '@qv-common/services';

import { EnrollmentId } from 'quantuvis-core-entities';

@Injectable({
  providedIn: 'root'
})
export class SystemMenuService {

  private readonly initialMenuConfig: (SystemMenuItem | string) [] = [
    {
      name: 'RN',
      url: '/dashboard',
      description: null,
      iconClass: 'icon-32 icon-q system-menu__icon--primary',
      isActive: false,
    },
    '-',
    {
      name: 'SM',
      url: environment.envUrls.sm,
      description: 'Rates',
      iconClass: 'icon-32 icon-hexagon-filled-240',
      isActive: false,
      isExternal: true
    },
    {
      name: 'RN',
      url: '/',
      description: 'Rebate Negotiation',
      iconClass: 'icon-32 icon-rn',
      isActive: true,
      isExternal: false
    },
    {
      name: 'IA',
      url: environment.envUrls.ia,
      description: 'Invoice Administration',
      iconClass: 'icon-24 icon-ia',
      isActive: false,
      isExternal: true
    },
    {
      name: 'CM',
      url: environment.envUrls.cm,
      description: 'Contract Management',
      iconClass: 'icon-32 icon-cm',
      isActive: false,
      isExternal: true
    },
    '-',
    {
      name: 'IQ',
      url: environment.envUrls.iq,
      description: 'iQ',
      iconClass: 'icon-32 icon-iq',
      isActive: false,
      isExternal: true
    },
    '-',
    {
      name: 'SA',
      url: environment.envUrls.sa,
      description: 'System Administration',
      iconClass: 'icon-32 icon-sa',
      isActive: false,
      isExternal: true
    },
  ];


  constructor(private enrollmentService: EnrollmentService) {}

  public get menuConfig(): (SystemMenuItem | string) [] {
    return this.prepareMenuConfigOptions();
  }

  private prepareMenuConfigOptions(): (SystemMenuItem | string) [] {
    const hasSomeIAEnrolment = this.enrollmentService.isEnrollmentInList(EnrollmentId.ACCESS_TO_INVOICING) ||
                               this.enrollmentService.isEnrollmentInList(EnrollmentId.ACCESS_TO_INVOICE_PACKAGING);
    let menuConfig = [...this.initialMenuConfig];

    if (!hasSomeIAEnrolment) {
      menuConfig = this.removeMenuItem(this.initialMenuConfig, 'IA');
    }

    return menuConfig;
  }

  private removeMenuItem(menuConfig: (SystemMenuItem | string) [], menuItemName: string): (SystemMenuItem | string) [] {
    return menuConfig.filter((item: SystemMenuItem) => item.name !== menuItemName);
  }
}
