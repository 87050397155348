import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DrugScenarioStatus } from '@qv-bid/enums/drug-scenario-status.enum';
import { QvCache } from '@qv-common/decorators';
import { SvgIconName } from '@qv-common/enums';

@Component({
  selector: 'qv-drug-status',
  templateUrl: './drug-status.component.html',
  styleUrls: ['./drug-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DrugStatusComponent {
  public static viewMap = {
    [DrugScenarioStatus.ACCEPTED]: {
      cssClass: 'drug-status--accepted',
      displayValue: DrugScenarioStatus.displayValue(DrugScenarioStatus.ACCEPTED),
      tooltipMessage: 'Accepted by'
    },
    [DrugScenarioStatus.DISMISSED]: {
      cssClass: 'drug-status--dismissed',
      displayValue: DrugScenarioStatus.displayValue(DrugScenarioStatus.DISMISSED),
      tooltipMessage: 'Dismissed by'
    },
    [DrugScenarioStatus.OPEN]: {
      cssClass: 'drug-status--open',
      displayValue: DrugScenarioStatus.displayValue(DrugScenarioStatus.OPEN),
      tooltipMessage: 'Opened by'
    },
    [DrugScenarioStatus.DISMISSED_IN_REVIEW]: {
      cssClass: 'drug-status--dismissed-in-review',
      displayValue: DrugScenarioStatus.displayValue(DrugScenarioStatus.DISMISSED_IN_REVIEW),
      tooltipMessage: 'Dismissed in review by'
    }
  };

  @Input()
  public value: DrugScenarioStatus = DrugScenarioStatus.OPEN;

  @Input()
  public companyName: string;

  @Input()
  public showOpenStatus = false;

  public readonly svgIconName = SvgIconName;

  @QvCache()
  public getClass(value: DrugScenarioStatus): string {
    return DrugStatusComponent.viewMap[value].cssClass;
  }

  @QvCache()
  public getDisplayValue(value: DrugScenarioStatus): string {
    return DrugStatusComponent.viewMap[value].displayValue;
  }

  @QvCache()
  public getTooltipMessage(value: DrugScenarioStatus, companyName: string): string {
    return `${DrugStatusComponent.viewMap[value].tooltipMessage} ${companyName}`;
  }

  @QvCache()
  public shouldDisableTooltip(value: DrugScenarioStatus): boolean {
    return value !== DrugScenarioStatus.DISMISSED;
  }

  @QvCache()
  public isVisible(value: DrugScenarioStatus, showOpenStatus: boolean): boolean {
    return value && value !== DrugScenarioStatus.OPEN || showOpenStatus;
  }
}
