import { DictionaryItem } from 'quantuvis-angular-common/interfaces';

export enum CompetitorsTier {
  EMPTY = 136,
  FORMULARY_ON_HIGHER_TIER,
  NON_FORMULARY,
  EXCLUDED,
  PARITY,
  NA
}

export namespace CompetitorsTier {
  const valuesMap = new Map<CompetitorsTier, DictionaryItem>([
    [CompetitorsTier.EMPTY, { id: CompetitorsTier.EMPTY, name: '' }],
    [
      CompetitorsTier.FORMULARY_ON_HIGHER_TIER,
      { id: CompetitorsTier.FORMULARY_ON_HIGHER_TIER, name: 'Formulary on a Higher Tier' }
    ],
    [CompetitorsTier.NON_FORMULARY, { id: CompetitorsTier.NON_FORMULARY, name: 'Non-Formulary' }],
    [CompetitorsTier.EXCLUDED, { id: CompetitorsTier.EXCLUDED, name: 'Excluded' }],
    [CompetitorsTier.PARITY, { id: CompetitorsTier.PARITY, name: 'Parity' }],
    [CompetitorsTier.NA, { id: CompetitorsTier.NA, name: 'N/A' }],
  ]);

  export function getValue(competitionGroup: CompetitorsTier): DictionaryItem {
    return valuesMap.get(competitionGroup);
  }

  export function getValues(): DictionaryItem[] {
    return Array.from(valuesMap.values());
  }
}
