export enum UserType {
  NONE = '',
  PAYER = 'PAYER',
  PHARMA = 'PHARMA',
  ADMIN = 'ADMIN'
}

export namespace UserType {
  const displayValues = new Map([
    [UserType.NONE, ''],
    [UserType.PAYER, 'Payer'],
    [UserType.PHARMA, 'Pharma'],
    [UserType.ADMIN, 'Admin'],
  ]);

  export function displayValue(userType: UserType): string {
    return displayValues.get(userType);
  }
}
