import { Injectable } from '@angular/core';
import { AcceptMessageData, BidVersion } from '@qv-bid/entities';
import { iif, Observable, ReplaySubject, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { BidVersionDaoService } from '@qv-bid/services/dao/bid-version.dao.service';
import { BidVersionType } from '@qv-bid/enums';

@Injectable()
export class BidVersionService {
  private currentVersion = new ReplaySubject<BidVersion>(1);

  constructor(private bidVersionDaoService: BidVersionDaoService) {}

  get getCurrentVersion(): Subject<BidVersion> {
    return this.currentVersion;
  }

  public getBidVersion(bidId: number, bidVersionId: number, isInternalBid: boolean): Observable<BidVersion> {
    return iif(() => Boolean(bidVersionId),
      this.getBidVersionById(Number(bidVersionId), isInternalBid),
      this.getLastVersion(bidId, isInternalBid),
    ).pipe(tap((bidVersion: BidVersion) => this.currentVersion.next(bidVersion)));
  }

  public getAcceptMessageData(bidVersionId: number, isInternalBid: boolean): Observable<AcceptMessageData> {
    return this.bidVersionDaoService.getAcceptMessageData(bidVersionId, isInternalBid);
  }

  public getHistoricVersions(bidId: number): Observable<BidVersion[]> {
    return this.bidVersionDaoService.getVersionsByType(bidId, BidVersionType.HISTORIC_VERSION);
  }

  private getLastVersion(bidId: number, isInternalBid: boolean): Observable<BidVersion> {
    return this.bidVersionDaoService.getLastVersion(bidId, isInternalBid);
  }

  private getBidVersionById(bidVersionId: number, isInternalBid: boolean): Observable<BidVersion> {
    return this.bidVersionDaoService.getVersionsById(bidVersionId, isInternalBid);
  }
}
