export enum TermSection {
  REBATE = 'rebate',
  UM = 'utilizationManagement',
  SCENARIO = 'contract',
  SCENARIO_NAME = 'scenarioName',
  MARKET_SHARE = 'marketShare',
  PRICE_PROTECTION = 'priceProtection',
  MARKET_BASKET = 'marketBasket',
  NOTES = 'notes',
  PAYMENT = 'payment',
  PENALTY = 'penalty',
  PHARMA_AUDIT = 'pharmaAudit',
  NO_FAULT_TERMINATION_CLAUSE = 'noFaultTerminationClause',
  ADMINISTRATION_FEE = 'administrationFee',
  SUMMARY = 'summary',
  CONTRACT = 'contract',
  COMMENTS = 'comments',
  DRUG = 'drug',
  STATUS = 'status',
}

export namespace TermSection {
  export function hasValue(value: any): boolean {
    return Object.values(TermSection).includes(value);
  }

  export function getTermSectionLock(termSection: TermSection): string {
    return `${termSection}Lock`;
  }
}
