<div *ngIf="bid" class="qv-page-toolbar bid-revision-toolbar">
  <div class="left-block">
    <qv-bid-breadcrumbs>
      <a class="bid-title"
        [routerLink]="[]"
        [queryParams]="enrichParamsWithViewPerspective({versionId: null}, bid.isInternal)"
        [matTooltip]="bid.title">{{bid.title}}</a>
      <a routerLink="/bid-history"
        [queryParams]="enrichParamsWithViewPerspective({bidId: bidId}, bid.isInternal)">Bid History</a>
      <span>Version {{getVersionNumber(currentVersionIndex)}}</span>
    </qv-bid-breadcrumbs>
  </div>

  <div class="right-block">
    <qv-bid-view-switcher
      *ngIf="isAvailableBidViewSwitcher()"
    ></qv-bid-view-switcher>
    <qv-role-switcher
      *ngIf="bidUtils.isAvailableRoleSwitcher(bid)"
      [bid]="bid"
    ></qv-role-switcher>

    <qv-action-button
      cssClass="toolbar-button--primary"
      (buttonClick)="navigateToVersion(getBidVersionByOffset(-1))"
      [svgIconName]="svgIconName.ARROW_BACK"
      [tooltip]="resources.TOOLTIPS.BID_ACTIONS.PREVIOUS_VERSION"
      [disabled]="!(isPreviousVersionAvailable(currentVersionIndex))"
    ></qv-action-button>
    <qv-action-button
      cssClass="toolbar-button--primary"
      (buttonClick)="navigateToVersion(getBidVersionByOffset(1))"
      [svgIconName]="svgIconName.ARROW_NEXT"
      [tooltip]="resources.TOOLTIPS.BID_ACTIONS.NEXT_VERSION"
      [disabled]="!(isNextVersionAvailable(currentVersionIndex, bidHistoricVersions.length))"
    ></qv-action-button>

    <qv-action-button
      *ngIf="(isPreviousVersionAvailable(currentVersionIndex)) && isFeatureAllowed(quantuvisPlusFeature.REVISION_COMPARISON)"
      routerLink="/bid-revision-comparison"
      [queryParams]="enrichParamsWithViewPerspective({bidId: bidId, startVersion: (getPreviousBidVersion()), endVersion: getCurrentBidVersion()}, bid.isInternal)"
      title="Revision Comparison"
      [tooltip]="resources.TOOLTIPS.BID_ACTIONS.REVISION_COMPARISON"
      [svgIconName]="svgIconName.COMPARE"
      cssClass="toolbar-button--primary">
    </qv-action-button>
    <qv-action-button
      *ngIf="(isPreviousVersionAvailable(currentVersionIndex)) && !isFeatureAllowed(quantuvisPlusFeature.REVISION_COMPARISON)"
      title="Revision Comparison"
      [tooltip]="isPharma() ? resources.GENERAL.UPGRADE_MESSAGE : ''"
      [svgIconName]="svgIconName.COMPARE"
      cssClass="toolbar-button--extra toolbar-button--upgrade mat-elevation-z0"
      [routerLink]="isPharma() ? 'quantuvisplus' : null">
    </qv-action-button>
    <qv-action-button
      [routerLink]="[]"
      [queryParams]="enrichParamsWithViewPerspective({versionId: null}, bid.isInternal)"
      title="Current Version"
      [tooltip]="resources.TOOLTIPS.BID_ACTIONS.CURRENT_VERSION"
      [svgIconName]="svgIconName.CURRENT_DOC"
      cssClass="toolbar-button--primary">
    </qv-action-button>
    <qv-action-button
      title="Details"
      [tooltip]="resources.TOOLTIPS.BID_ACTIONS.DETAILS"
      [svgIconName]="svgIconName.DETAILS"
      cssClass="toolbar-button--primary"
      (buttonClick)="openBidDetailsPanel()">
    </qv-action-button>
    <qv-action-button
      *ngIf="router.isActive('grid', false)"
      tooltip="Show/Hide Columns"
      cssClass="toolbar-button toolbar-button--extra"
      [svgIconName]="svgIconName.COLUMNS"
      [matMenuTriggerFor]="gridColumnsStateMenu.menu"
    ></qv-action-button>
    <qv-bid-extra-tools
      [bid]="bid"
      [bidVersionId]="bidVersionId"
    ></qv-bid-extra-tools>
  </div>
</div>
<qv-term-visibility-menu #gridColumnsStateMenu></qv-term-visibility-menu>
