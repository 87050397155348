import { NgModule } from '@angular/core';
import DashboardComponent from './dashboard/dashboard.controller';
import DrugSearchComponent from './drug-search/drug-search.component';
import EnterpriseComponent from './enterprise/enterprise.component';
import NotificationsComponent from './notifications/notifications.component';
import ProfileComponent from './profile/profile.component';

declare let angular: angular.IAngularStatic;

export const MainNgModule = angular.module('quantuvis.main', [])
  .component('dashboardComponent', DashboardComponent)
  .component('drugSearchComponent', DrugSearchComponent)
  .component('enterpriseComponent', EnterpriseComponent)
  .component('notificationsComponent', NotificationsComponent)
  .component('profileComponent', ProfileComponent)
;

@NgModule({})
export class MainModule {
}
