import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { BidSendBestAndFinalModalConfig } from '@qv-bid/components/shared/bid-send-best-and-final-modal/models/bid-send-best-and-final-modal-config';
import { BidSendBestAndFinalModalData } from '@qv-bid/components/shared/bid-send-best-and-final-modal/models/bid-send-best-and-final-modal-data';
import { BidSendModalConfig } from '@qv-bid/components/shared/bid-send-modal/models/bid-send-modal-config';
import { BidSendModalData } from '@qv-bid/components/shared/bid-send-modal/models/bid-send-modal-data';
import { Bid, ScenariosValidate, Summary } from '@qv-bid/entities';
import { SentBidInfo } from '@qv-bid/models';
import { BidHandleErrorService } from '@qv-bid/services/bid-handle-error.service';
import { BidStateService } from '@qv-bid/services/bid-state.service';
import { BidDaoService, BidVersionDaoService } from '@qv-bid/services/dao';
import { SummaryService } from '@qv-bid/services/summary';
import { BidUtils } from '@qv-bid/utils';
import { resources } from '@qv-common/static';
import { CoreUtils } from '@qv-common/utils';
import { CompanyUtils } from '@qv-company/utils';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import {
  GeneralModalComponent,
  GeneralModalConfig,
  GeneralModalData,
  ModalService,
  ModalSize
} from 'quantuvis-angular-common/modal';
import { NotificationService } from 'quantuvis-angular-common/notification';
import { Observable, of, throwError } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { CompanyType } from 'quantuvis-core-entities';

@Injectable()
export class BidSendActionService {
  @BlockUI('general-modal')
  public blockUIModal: NgBlockUI;

  public bestAndFinalValidationFailed = 'Bid validation failed on "Send best and final"';

  constructor(
    private bidDaoService: BidDaoService,
    private bidVersionDaoService: BidVersionDaoService,
    private bidStateService: BidStateService,
    private summaryService: SummaryService,
    private modalService: ModalService,
    private notificationService: NotificationService,
    private bidHandleErrorService: BidHandleErrorService
  ) {}


  public sendCurrentBid(): Observable<null | HttpErrorResponse> {
    return this.bidStateService.bid$.pipe(
      take(1),
      switchMap((bid: Bid) => {
        const oppositeCompany = BidUtils.getBidOppositeCompanyByType(bid, CompanyType[bid.assignee]);
        const oppositeCompanyName = CompanyUtils.getCompanyFullName(
          oppositeCompany.name, oppositeCompany.companyType === CompanyType.PHARMA, bid.manufacturerCompanyOldName);

        return this.sendBid(bid.id, oppositeCompanyName);
      }));
  }

  public sendBid(
    bidId: number,
    oppositeCompanyName: string,
    isInternal?: boolean
  ): Observable<null | HttpErrorResponse> {
    const sendModal = this.createConfirmModal(resources.POPUPS.TITLES.SEND_BID,
      `${resources.BIDS_LIST.CONFIRM_SEND_BID}${oppositeCompanyName}?`);
    const isInternalBid = CoreUtils.isDefined(isInternal)
      ? isInternal
      : this.bidStateService.bid$.getValue().isInternal;

    return sendModal.componentInstance.primaryAction.pipe(
      switchMap(() => this.bidDaoService.sendBid(bidId, isInternalBid).pipe(
        this.bidHandleErrorService.handleSendBidError(),
        sendModal.componentInstance.handleAfterAction()
      ))
    );
  }

  public sendFinalBid(): Observable<null | HttpErrorResponse> | any {
    const sendFinalBidModal = this.modalService.openModal(
      new BidSendModalConfig(new BidSendModalData(this.bidStateService.bid$.value.isBinding))
    );

    return sendFinalBidModal.componentInstance.primaryAction.pipe(
      withLatestFrom(this.bidStateService.bid$),
      switchMap(([sentBidInfo, bid]: [SentBidInfo, Bid]) => {
        if (sentBidInfo.isFinal) {
          if (bid.isBinding) {
            sendFinalBidModal.componentInstance.primaryActionFinished();
            return this.sendBestAndFinalBidWithLA();
          } else {
            return this.sendBestAndFinalBid(sentBidInfo).pipe(
              tap(() => this.blockUIModal.stop()),
              filter((isValid: boolean) => isValid),
              sendFinalBidModal.componentInstance.handleAfterAction()
            );
          }
        } else {
          return this.sendBidWithInfo(sentBidInfo).pipe(sendFinalBidModal.componentInstance.handleAfterAction());
        }
      })
    );
  }

  private sendBestAndFinalBid(sentBidInfo: SentBidInfo): Observable<boolean | HttpErrorResponse> {
    return this.checkBestAndFinalBidValidity().pipe(
      switchMap((isValid: boolean) =>
        isValid ? this.sendBidWithInfo(sentBidInfo) : of(isValid)
      )
    );
  }

  private sendBestAndFinalBidWithLA(): Observable<null | HttpErrorResponse> {
    return this.summaryService.getSummary.pipe(take(1), switchMap((summary: Summary) => {
      const sendBestAndFinalBidModal = this.modalService.openModal(
        new BidSendBestAndFinalModalConfig(new BidSendBestAndFinalModalData(
          BidUtils.getManufacturerCompanyDisplayName(this.bidStateService.bid$.value),
          summary.id
        )));

      return sendBestAndFinalBidModal.componentInstance.primaryAction.pipe(
        switchMap((sentBidInfo: SentBidInfo) =>
          this.checkValidityForSendBeastAndFinalBidWithLA().pipe(
            mergeMap(() => this.sendBidWithInfo(sentBidInfo)),
            sendBestAndFinalBidModal.componentInstance.handleAfterAction())
        )
      );
    }));
  }

  private checkValidityForSendBeastAndFinalBidWithLA(): Observable<null> {
    return this.checkBestAndFinalBidValidity().pipe(
      switchMap((isValid: boolean) =>
        isValid ? of(null) : throwError(new Error(this.bestAndFinalValidationFailed))
      )
    );
  }

  private sendBidWithInfo(sentBidInfo: SentBidInfo): Observable<null | HttpErrorResponse> {
    const isInternalBid = this.bidStateService.bid$.getValue().isInternal;

    return this.bidStateService.bid$.pipe(
      take(1),
      switchMap(({ id }: Bid) => this.bidDaoService.sendBid(id, isInternalBid, sentBidInfo)
        .pipe(this.bidHandleErrorService.handleSendBidError()))
    );
  }

  private checkBestAndFinalBidValidity(): Observable<boolean | HttpErrorResponse> {
    return this.bidVersionDaoService.checkBidValidity(this.bidStateService.bidVersionId).pipe(
      map(({ isValid }: ScenariosValidate) => isValid),
      tap((isValid: boolean) => {
        if (!isValid) {
          this.modalService.openModal(new GeneralModalConfig(new GeneralModalData(
            resources.BID_DETAILS.INVALID_VALUES_FOR_REQUIRED_FIELDS,
            resources.POPUPS.INVALID_TERM_VALUES_ERROR_MESSAGE,
            resources.Actions.OK
          ), ModalSize.MEDIUM));
        }
      }),
      catchError((err: HttpErrorResponse) => {
        this.modalService.closeAll();

        return this.notificationService.showServerError(err);
      })
    );
  }

  private createConfirmModal(title: string, description: string): MatDialogRef<GeneralModalComponent> {
    const modalData = new GeneralModalData(
      title,
      description,
      resources.Actions.YES,
      resources.Actions.NO,
      false
    );
    const modalConfig = new GeneralModalConfig(modalData, ModalSize.X_SMALL);

    return this.modalService.openModal(modalConfig);
  }
}
