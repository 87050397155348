import { SelectBoxTerm } from '@qv-term/models/types';
import { resources } from '@qv-common/static';
import { drugTermsConstants } from '@qv-term/constants';
import { TermName } from '@qv-term/enums';
import { CompetitionGroup } from 'quantuvis-core-entities';

export class CompetitionGroupTerm extends SelectBoxTerm {
  public defaultValueForOldBid: string;

  constructor(name: string) {
    super(
      name,
      drugTermsConstants[TermName.COMPETITION_GROUP].title,
      resources.TermsDescriptions.DRUG_LIST.COMPETITION_GROUP,
      '',
      '',
      CompetitionGroup.getValue(CompetitionGroup.EMPTY),
      'term__competition-group'
    );
    this.values = CompetitionGroup.getValues();
    this.defaultValueForOldBid = CompetitionGroup.getValue(CompetitionGroup.EMPTY).name;
  }
}
