import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DynamicInternalFieldComponent, InternalFieldsComponent, TextInternalFieldComponent, } from './components';
import { InternalFieldsDaoService } from './services';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SharedModule } from '@qv-shared/shared.module';
import { InternalFieldsCommonModule } from '@qv-internal-fields-common/internal-fields-common.module';
import { AngularComponentsCommonModule } from 'angular-components-common';

@NgModule({
  declarations: [
    InternalFieldsComponent,
    DynamicInternalFieldComponent,
    TextInternalFieldComponent,
  ],
  imports: [
    AngularComponentsCommonModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatIconModule,
    MatTooltipModule,
    MatButtonModule,
    InternalFieldsCommonModule,
    SharedModule,
  ],
  exports: [
    InternalFieldsComponent,
    TextInternalFieldComponent,
  ],
  providers: [
    InternalFieldsDaoService,
  ],
})
export class InternalFieldsModule {}
