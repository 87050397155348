import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DrugNotesModalComponent } from '../drug-notes-modal.component';
import { DrugNotesModalData } from './drug-notes-modal-data';
import { ModalConfig, ModalSize } from 'quantuvis-angular-common/modal';

export class DrugNotesModalConfig extends ModalConfig<DrugNotesModalComponent, DrugNotesModalData> {
  constructor(drugNotesModalData: DrugNotesModalData) {
    super(drugNotesModalData, ModalSize.LARGE);
  }

  protected createDialogRef(dialog: MatDialog): MatDialogRef<DrugNotesModalComponent> {
    return dialog.open(DrugNotesModalComponent, this);
  }
}
