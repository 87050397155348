import { Component, Inject, OnInit } from '@angular/core';
import { applicationUrls } from '@qv-common/static';
import { resources } from '@qv-common/static/resources';
import { NavigationMainService } from '@qv-header/services/navigation-main.service';
import { PermissionService } from '@qv-common/services/auth/permission.service';
import { SvgIconName } from '@qv-common/enums';
import { TermsAndConditionsEventBusService } from '@qv-terms-and-conditions/services/terms-and-conditions-event-bus.service';
import { User } from '@qv-user/entities/user.entity';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter } from 'rxjs/operators';
import { WINDOW_TOKEN } from 'quantuvis-angular-common/window-utils';
import { UserService } from '@qv-common/services/auth/user.service';

@UntilDestroy()
@Component({
  selector: 'qv-profile-menu',
  templateUrl: './profile-menu.component.html',
  styleUrls: ['./profile-menu.component.scss']
})
export class ProfileMenuComponent implements OnInit {
  public readonly tooltips = resources.TOOLTIPS.MAIN_MENU;
  public readonly svgIconName = SvgIconName;

  public isAdmin: boolean;
  public userFullName: string;

  constructor(
    private navigationService: NavigationMainService,
    private userService: UserService,
    private permissionService: PermissionService,
    private termsAndConditionsEventBusService: TermsAndConditionsEventBusService,
    @Inject(WINDOW_TOKEN) private window: Window
  ) {}

  public ngOnInit(): void {
    this.initUserChangeHandler();
  }

  public openHelpPage(): void {
    this.window.open(this.navigationService.getHelpUrl(), '_blank');
  }

  public openDeviceActivityPage(): void {
    this.window.open(applicationUrls.deviceActivityUrl, '_blank');
  }

  public isAllowed(permission: string): boolean {
    return this.permissionService.isAllowed(permission);
  }

  public openTermOfServiceModal(): void {
    this.termsAndConditionsEventBusService.openTermsOfServiceModal$.next();
  }

  public openPrivacyPolicyModal(): void {
    this.termsAndConditionsEventBusService.openPrivacyPolicyModal$.next();
  }

  public openFeedbackModal(): void {
    this.termsAndConditionsEventBusService.openContactUsModal$.next();
  }

  private initUserChangeHandler(): void {
    this.userService.user
      .pipe(
        filter((user: User) => Boolean(user)),
        untilDestroyed(this)
      )
      .subscribe((user: User) => {
        this.isAdmin = user.isAdmin;
        this.userFullName = user.fullName;
      });
  }
}
