import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { BaseSectionState } from '@qv-bid/classes/sections';
import { QvCache } from '@qv-common/decorators';
import { TermSection } from '@qv-term/enums';
import { statePerSection } from '@qv-bid/constants';
import { DictionaryItem } from '@qv-common/entities';

@Component({
  selector: 'qv-standard-scenario-state-select',
  templateUrl: './standard-scenario-state-select.component.html',
  styleUrls: ['./standard-scenario-state-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StandardScenarioStateSelectComponent extends BaseSectionState implements OnInit {
  @Input() public state: DictionaryItem;

  @QvCache()
  public isLocked(state: DictionaryItem, sectionName: TermSection): boolean {
    return state.id === statePerSection[sectionName].LOCKED;
  }

  @QvCache()
  public isAllowed(state: DictionaryItem, sectionName: TermSection): boolean {
    return state.id === statePerSection[sectionName].ALLOWED;
  }

  @QvCache()
  public isRequired(state: DictionaryItem, sectionName: TermSection): boolean {
    return state.id === statePerSection[sectionName].REQUIRED;
  }

  @QvCache()
  public isHidden(state: DictionaryItem, sectionName: TermSection): boolean {
    return state.id === statePerSection[sectionName].HIDDEN;
  }

  @QvCache()
  public isDisplayed(isPharma: boolean, state: DictionaryItem, sectionName: TermSection): boolean {
    return !isPharma || this.isLocked(state, sectionName) || this.isRequired(state, sectionName);
  }
}
