import { Injectable } from '@angular/core';
import { DrugStore, DrugStoreOptions } from '@qv-bid/models';
import { LocalStorageService } from 'ngx-webstorage';
import { SnackBarData, SnackBarService } from 'quantuvis-angular-common/snack-bar';
import { merge, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { LocalStorageKeys } from '@qv-common/enums';

@Injectable()
export class DrugCopyPasteService {
  public readonly DRUG_COPIED_MESSAGE = 'Drug Data Copied';
  private readonly storeKey = LocalStorageKeys.COPIED_DRUG;

  constructor(private snackBarService: SnackBarService,
              private localStorageService: LocalStorageService) { }

  public storeDrug(options: DrugStoreOptions): void {
    const drugStore = new DrugStore(options);

    this.localStorageService.store(this.storeKey, drugStore);
    const snackBarData = new SnackBarData(
      'snack-bar--success',
      this.DRUG_COPIED_MESSAGE,
      'icon-check-filled');
    this.snackBarService.showSnackBar(snackBarData);
  }

  public isDrugCopyExistInStorage(): Observable<boolean> {
    return merge(
      of(this.localStorageService.retrieve(this.storeKey)),
      this.localStorageService.observe(this.storeKey)
    ).pipe(map((data: DrugStore) => Boolean(data)));
  }

  public getStoredDrug(): DrugStore {
    return this.localStorageService.retrieve(this.storeKey);
  }

  public clearStorage(): void {
    this.localStorageService.clear(this.storeKey);
  }
}
