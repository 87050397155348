import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { Ng1WrapperModule } from '@qv-ng1-wrapper/ng1-wrapper.module';
import { AngularComponentsCommonModule } from 'angular-components-common';
import { BootstrapService } from './services';
import { RootComponent, UiViewWrapperDirective } from './components';
import { HeaderModule } from '@qv-header/header.module';
import { BlockUIModule } from 'ng-block-ui';
import { appConfig } from '@qv-common/configs';

@NgModule({
  imports: [
    CommonModule,
    HeaderModule,
    BlockUIModule.forRoot(appConfig.blockUIConfig),
    RouterModule,
    Ng1WrapperModule,
    AngularComponentsCommonModule,
  ],
  declarations: [
    RootComponent,
    UiViewWrapperDirective,
  ],
  exports: [
    RootComponent
  ],
  providers: [BootstrapService]
})
export class BootstrapModule {
}
