import { Component, Input, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { TermTemplateStorageService } from '@qv-term/services';
import { BaseTerm } from '@qv-term/models';

@Component({
  selector: 'qv-standard-cell',
  templateUrl: './standard-cell.component.html',
  styleUrls: ['./standard-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StandardCellComponent implements OnInit {
  @Input()
  public name: string;

  @Input()
  public termName: string;

  @Input()
  public useSeparator?: boolean;

  public termConfig: BaseTerm;

  constructor(protected termTemplateStorage: TermTemplateStorageService) {}

  public ngOnInit(): void {
    this.termConfig = this.termTemplateStorage.getTermTemplate(this.termName);
  }
}
