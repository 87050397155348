import { ChangeDetectionStrategy, Component, ViewChild, OnInit } from '@angular/core';
import { MatMenu } from '@angular/material/menu';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SvgIconName } from '@qv-common/enums';
import { TermVisibilityService } from '@qv-bid/services';
import {
  termVisibilityMenuItems,
  termVisibilitySubmenuItems
} from '@qv-bid/components/shared/term-visibility-menu/constants';
import { TermName, TermSection } from '@qv-term/enums';
import { GridColumnState, GridSectionState } from '@qv-bid/enums';

@Component({
  selector: 'qv-term-visibility-menu',
  templateUrl: './term-visibility-menu.component.html',
  styleUrls: ['./term-visibility-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TermVisibilityMenuComponent implements OnInit {
  public readonly svgIconName = SvgIconName;
  public readonly termVisibilityMenuItems = termVisibilityMenuItems;
  public readonly termVisibilitySubmenuItems = termVisibilitySubmenuItems;
  public menuItems: TermSection[];
  public submenuItems: Map<TermSection, TermName[]>;


  @ViewChild(MatMenu, { static: true })
  public menu: MatMenu;

  constructor(
    public termVisibilityService: TermVisibilityService
  ) {}

  public ngOnInit(): void {
    this.menuItems = this.termVisibilityService.getSections();
    this.submenuItems = new Map(this.menuItems.map(
      (termSection: TermSection) => [termSection, this.termVisibilityService.getColumns(termSection)]
    ));
  }

  public getColumnIcon(termName: TermName): Observable<SvgIconName> {
    return this.termVisibilityService.getColumnState(termName).pipe(
      map((state: GridColumnState) => state === GridColumnState.HIDDEN ? SvgIconName.HIDE : SvgIconName.VIEW)
    );
  }

  public getSectionIcon(termSection: TermSection): Observable<SvgIconName> {
    return this.termVisibilityService.getSectionState(termSection).pipe(
      map((state: GridSectionState) => state === GridSectionState.HIDDEN ? SvgIconName.HIDE : SvgIconName.VIEW)
    );
  }

  public isColumnHidden(termName: TermName): Observable<boolean> {
    return this.termVisibilityService.getColumnState(termName)
      .pipe(map((state: GridColumnState) => state === GridColumnState.HIDDEN));
  }

  public isSectionHidden(termSection: TermSection): Observable<boolean> {
    return this.termVisibilityService.getSectionState(termSection)
      .pipe(map((state: GridSectionState) => state === GridSectionState.HIDDEN));
  }

  public isSectionPartiallyHidden(termSection: TermSection): Observable<boolean> {
    return this.termVisibilityService.getSectionState(termSection)
      .pipe(map((state: GridSectionState) => state === GridSectionState.PARTIALLY_HIDDEN));
  }

  public onColumnItemClick(event: MouseEvent, termName: TermName): void {
    event.stopPropagation();

    this.termVisibilityService.toggleColumnState(termName);
  }

  public onSectionItemClick(event: MouseEvent, termSection: TermSection): void {
    event.stopPropagation();

    this.termVisibilityService.changeSectionState(termSection);
  }

  public onResetAll(event: MouseEvent): void {
    event.stopPropagation();

    this.termVisibilityService.resetAll();
  }
}
