import { constants, resources } from '@qv-common/static';
import { BooleanChoice } from '@qv-term/enums/options';
import { SelectBoxTerm } from '@qv-term/models/types';

export class ContractEvergreenTerm extends SelectBoxTerm {
  public allowKeepOriginValue: boolean;
  public processKeepOriginalValue: boolean;
  public keepOriginalValue: boolean;

  constructor(name: string) {
    super(name,
      name,
      resources.TermsDescriptions.SUMMARY.CONTRACT_EVERGREEN,
      constants.SUMMARY_TERMS.CONTRACT_EVERGREEN.label,
      '',
      BooleanChoice.getValue(BooleanChoice.NO),
      'term__contract-evergreen'
    );
    this.keepOriginalValue = this.allowKeepOriginValue;
    this.processKeepOriginalValue = false;
    this.values = BooleanChoice.getValues();
  }
}
