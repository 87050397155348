declare let angular: angular.IAngularStatic;

import { NgModule } from '@angular/core';

import CopyBidComponent from './copy-bid.component';
import SummaryComponent from './summary/summary.component';
import ContractComponent from '../shared/contract/contract.component';
import DrugLevelComponent from './drug-level/drug-level.component';

export const CopyBidNgModule = angular
  .module('quantuvis.copyBid', [])
  .component('copyBid', CopyBidComponent)
  .component('summary', SummaryComponent)
  .component('contract', ContractComponent)
  .component('drugLevel', DrugLevelComponent);

@NgModule({})
export class CopyBidModule {
}
