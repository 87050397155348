import { ScenarioStateConfig } from '@qv-bid/models/index';

export class ScenarioConfigList {
  public storage: Map<string, ScenarioStateConfig> = new Map<string, ScenarioStateConfig>();

  constructor(
    public isFirstLoading: boolean = true,
    public bidVersionId: number = 0,
    public cbId: number = 0
  ) {}

  public reset(): void {
    this.isFirstLoading = true;
    this.bidVersionId = 0;
    this.cbId = 0;
    this.storage = new Map<string, ScenarioStateConfig>();
  }
}
