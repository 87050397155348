<div *blockUI="'drug-add'" class="drug-add-modal">
  <header class="qv-modal-dialog-title" mat-dialog-title>
    <div class="qv-modal-dialog-title__text">Add Drugs</div>
    <button [mat-dialog-close] class="qv-modal-dialog-title__xbutton">
      <mat-icon>close</mat-icon>
    </button>
  </header>
  <div class="qv-modal-dialog-content qv-modal-dialog-content--padding-top-32" mat-dialog-content>
    <div class="drug-add-modal__label qv-modal-dialog-content__label">
      <span class="qv-modal-dialog-content__label-text">Contracted Business</span>
      <ng-select
          (change)="onCbChange($event)"
          [clearable]="false"
          [formControl]="cbControl"
          [items]="cbItems"
          [searchable]="false"
          appendTo="body"
          bindLabel="name"
          class="qv-select drug-add-modal__select"
      ></ng-select>
    </div>
    <div (matSortChange)="sortBy($event)" class="drug-list__header" matSort>
      <div
          *ngFor="let item of drugsListHeaderConfig"
          class="drug-list__cell drug-list__cell--{{item.modifier}}"
          mat-sort-header="{{item.sortKey}}"
      >{{item.columnName}}</div>
    </div>
    <div class="drug-list__body">
      <mat-tree *ngIf="dataSource.data.length" [dataSource]="dataSource" [treeControl]="treeControl"
                class="drug-list__tree">
        <!-- Group -->
        <mat-tree-node
            *matTreeNodeDef="let node; when: isGroup"
            [class.drug-list__row-parent--even]="isRootEven(node)"
            class="drug-list__row-parent"
        >
          <div class="drug-list__cell drug-list__cell--drug">
            <mat-checkbox
                (change)="onGroupSelectionChange(node)"
                [checked]="this.treeSelection.isSelected(node) || isAllChildrenSelected(node)"
                [disabled]="isAllChildrenDisabled(node)"
                [indeterminate]="isSomeChildSelected(node)"
                class="qv-mat-checkbox"
            >{{node.getName()}}</mat-checkbox>
            <button class="drug-list drug-list__toggle-button" mat-icon-button matTreeNodeToggle>
              <mat-icon
                  [svgIcon]="treeControl.isExpanded(node) ? svgIconName.CHEVRON_TOP :  svgIconName.CHEVRON_BOTTOM"
                  class="qv-icon qv-icon--24"
              ></mat-icon>
            </button>
          </div>
          <div class="drug-list__cell drug-list__cell--eligible">
            <div [class]="getColorModifier(node.eligible, 'drug-list__colored-area')"
                 class="drug-list__colored-area">{{node.getEligibleText()}}</div>
          </div>
          <div class="drug-list__cell drug-list__cell--pharma">{{node.pharma}}</div>
          <div
              [class]="getColorModifier(node.drugClass, 'drug-list__cell', 'drug-class', true)">{{node.drugClass.value}}</div>
          <div [class]="getColorModifier(node.active, 'drug-list__cell', 'active')">{{node.getActiveText()}}</div>
          <div
              [class]="getColorModifier(node.rxOTCIndicatorCode, 'drug-list__cell', 'rx-otc')">{{node.rxOTCIndicatorText()}}</div>
          <div
              [class]="getColorModifier(node.nameTypeCode, 'drug-list__cell', 'type-code')">{{node.getNameTypeCodeText()}}</div>
        </mat-tree-node>
        <!-- Drug -->
        <mat-tree-node
            *matTreeNodeDef="let node"
            [class.drug-list__row-child--even]="isRootEven(node)"
            class="drug-list__row-child"
        >
          <mat-checkbox
              (change)="onChildSelectionChange(node);"
              [checked]="treeSelection.isSelected(node)"
              [disabled]="node.isPresentInBid"
              class="drug-list__cell drug-list__cell--drug qv-mat-checkbox"
          >{{node.getName()}}</mat-checkbox>
          <div class="drug-list__cell drug-list__cell--eligible">
            <div
                [class]="getColorModifier(node.eligible, 'drug-list__colored-area')"
                class="drug-list__colored-area"
            >{{node.getEligibleText()}}</div>
          </div>
          <div class="drug-list__cell drug-list__cell--pharma">{{node.pharma}}</div>
          <div
              [class]="getColorModifier(node.drugClass, 'drug-list__cell', 'drug-class', true)">{{node.drugClass.value}}</div>
          <div [class]="getColorModifier(node.active, 'drug-list__cell', 'active')">{{node.getActiveText()}}</div>
          <div
              [class]="getColorModifier(node.rxOTCIndicatorCode, 'drug-list__cell', 'rx-otc')">{{node.rxOTCIndicatorText()}}</div>
          <div
              [class]="getColorModifier(node.nameTypeCode, 'drug-list__cell', 'type-code')">{{node.getNameTypeCodeText()}}</div>
        </mat-tree-node>
      </mat-tree>
    </div>
  </div>
  <footer class="qv-modal-dialog-actions" mat-dialog-actions>
    <div class="right-block">
      <button
          [mat-dialog-close]
          class="qv-modal-dialog-actions__item"
          mat-button
          tabindex="-1"
      >{{resources.Actions.CANCEL}}</button>
      <button
          (click)="onSubmit()"
          class="qv-modal-dialog-actions__item qv-modal-dialog-actions__item--primary"
          mat-button
          tabindex="-1"
          type="submit"
      >{{resources.Actions.OK}}</button>
    </div>
  </footer>
</div>
