import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('RenumberScenario')
export class RenumberScenario {

  @JsonProperty('scenarioId', Number)
  public scenarioId: number;

  @JsonProperty('scenarioNumber', Number)
  public scenarioNumber: number;
}
