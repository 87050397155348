import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BaseContractedBusinessManageMenu } from '@qv-bid/components/shared/base/classes/base-cb-manage-menu';

import { ContractedBusiness } from 'quantuvis-core-entities';

@Component({
  selector: 'qv-standard-cb-manage-menu',
  templateUrl: './standard-cb-manage-menu.component.html',
  styleUrls: ['./standard-cb-manage-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StandardContractedBusinessManageMenuComponent extends BaseContractedBusinessManageMenu {
  @Input()
  public cb: ContractedBusiness;
}
