import { HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { HttpResponseWrapper } from '@qv-common/models/api';
import { ApiService } from '@qv-common/services/api';
import { JsonConvert } from 'json2typescript';
import { JSON_CONVERT_TOKEN } from 'quantuvis-angular-common/json-converter/services';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Note } from '@qv-term/entities';
import { HttpUtils } from '@qv-common/utils';
import { ViewPerspectiveService } from '@qv-common/services/auth';
import { ApiUrlPrefix } from '@qv-common/enums';

@Injectable()
export class DrugNotesDaoService {

  constructor(
    private apiService: ApiService,
    @Inject(JSON_CONVERT_TOKEN) private jsonConvert: JsonConvert,
    private viewPerspectiveService: ViewPerspectiveService
  ) {}

  public getHistoricNotesPresents(bidVersionId: number, cbId: number): Observable<boolean> {
    return this.apiService
      .get(`${ApiUrlPrefix.BIDS}/bid-versions/${bidVersionId}/contracted-businesses/${cbId}/has-historic-notes`)
      .pipe(map((response: HttpResponse<boolean>) => response.body));
  }

  public createAtScenarioLevel(scenarioId: number, value: Note, isInternal: boolean): Observable<Note> {
    let url = `${ApiUrlPrefix.BIDS}/scenarios/${scenarioId}/notes`;
    const perspective = this.viewPerspectiveService.getViewPerspective();
    url = HttpUtils.appendPerspectiveQueryParamToUrlIfBidIsInternal(url, perspective, isInternal);

    return this.apiService.post(url, value)
      .pipe(map((response: HttpResponseWrapper<Note>) =>
        this.jsonConvert.deserializeObject(response.body.data, Note)
      ));
  }

  public createAtNdcLevel(scenarioId: number, drugId: number, value: Note, isInternal: boolean): Observable<Note> {
    let url = `${ApiUrlPrefix.BIDS}/scenarios/${scenarioId}/drugs/${drugId}/notes`;
    const perspective = this.viewPerspectiveService.getViewPerspective();
    url = HttpUtils.appendPerspectiveQueryParamToUrlIfBidIsInternal(url, perspective, isInternal);

    return this.apiService.post(url, value)
      .pipe(map((response: HttpResponseWrapper<Note>) =>
        this.jsonConvert.deserializeObject(response.body.data, Note)
      ));
  }

  public updateAtScenarioLevel(scenarioId: number, value: Note, isInternal: boolean): Observable<Note> {
    let url = `${ApiUrlPrefix.BIDS}/scenarios/${scenarioId}/notes`;
    const perspective = this.viewPerspectiveService.getViewPerspective();
    url = HttpUtils.appendPerspectiveQueryParamToUrlIfBidIsInternal(url, perspective, isInternal);

    return this.apiService.put(url, value)
      .pipe(map((response: HttpResponseWrapper<Note>) =>
        this.jsonConvert.deserializeObject(response.body.data, Note)
      ));
  }

  public updateAtNdcLevel(scenarioId: number, drugId: number, value: Note, isInternal: boolean): Observable<Note> {
    let url = `${ApiUrlPrefix.BIDS}/scenarios/${scenarioId}/drugs/${drugId}/notes`;
    const perspective = this.viewPerspectiveService.getViewPerspective();
    url = HttpUtils.appendPerspectiveQueryParamToUrlIfBidIsInternal(url, perspective, isInternal);

    return this.apiService.put(url, value)
      .pipe(map((response: HttpResponseWrapper<Note>) =>
        this.jsonConvert.deserializeObject(response.body.data, Note)
      ));
  }

  public deleteAtScenarioLevel(scenarioId: number, isInternal: boolean): Observable<Note> {
    let url = `${ApiUrlPrefix.BIDS}/scenarios/${scenarioId}/notes`;
    const perspective = this.viewPerspectiveService.getViewPerspective();
    url = HttpUtils.appendPerspectiveQueryParamToUrlIfBidIsInternal(url, perspective, isInternal);

    return this.apiService.delete(url) as Observable<null>;
  }

  public deleteAtNdcLevel(scenarioId: number, drugId: number, noteId: number, isInternal: boolean): Observable<Note> {
    let url = `${ApiUrlPrefix.BIDS}/scenarios/${scenarioId}/drugs/${drugId}/notes/${noteId}`;
    const perspective = this.viewPerspectiveService.getViewPerspective();
    url = HttpUtils.appendPerspectiveQueryParamToUrlIfBidIsInternal(url, perspective, isInternal);

    return this.apiService.delete(url).pipe(map((response: HttpResponseWrapper<Note>) =>
      this.jsonConvert.deserializeObject(response.body, Note)
    ));
  }

  public deleteHistoricNotes(
    versionId: number, cbId: number, scenariosIds: number[], drugIds: number[]
  ): Observable<void> {
    const reqParams = [{ cbId, scenarioDrugIds: { scenariosIds, drugIds } }];

    return this.apiService
      .delete(`${ApiUrlPrefix.BIDS}/bid-versions/${versionId}/delete-historic-notes`, reqParams)
      .pipe(map(() => null));
  }
}
