import { BaseTerm } from '@qv-term/models/types';
import { Comparator, Reset, SkipAutosave } from '@qv-common/interfaces';
import { resources } from '@qv-common/static';

export class BasketTerm extends BaseTerm implements Reset, Comparator, SkipAutosave {
  public doNotSave: boolean;

  constructor(name: string) {
    super(
      name,
      name,
      resources.TermsDescriptions.DRUG_LIST.MARKET_BASKET,
      '',
      {},
      {},
      'term_basket'
    );

    this.doNotSave = true;
  }

  public resetValue(): void {
    this.value = this.defaultValue;
  }

  public getTermValueForComparison(): string {
    throw new Error('Method not implemented.');
  }
}
