import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { Router } from '@angular/router';
import { NavigationMainComponent } from '@qv-header/components';
import { NavigationMainService } from '@qv-header/services/navigation-main.service';
import { NotificationService } from 'quantuvis-angular-common/notification';
import { SvgIconName } from '@qv-common/enums';
import { InsightsDaoService } from '@qv-insights/services/dao';
import { EnrollmentService } from '@qv-common/services';

@Component({
  selector: 'qv-navigation-mobile',
  templateUrl: './navigation-mobile.component.html',
  styleUrls: ['./navigation-mobile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavigationMobileComponent extends NavigationMainComponent {

  public readonly svgIconName = SvgIconName;

  constructor(protected navigationService: NavigationMainService,
              public router: Router,
              protected enrollmentService: EnrollmentService,
              protected insightsDaoService: InsightsDaoService,
              protected notificationService: NotificationService,
              protected changeDetectorRef: ChangeDetectorRef) {
    super(navigationService, router, enrollmentService, insightsDaoService, notificationService, changeDetectorRef);
  }
}
