<section class="qv-modal-dialog market-basket-modal market-basket-modal--preview" *blockUI="'market-basket-preview-modal'">
  <mb-market-basket-template-modal-header [title]="marketBasket.name"></mb-market-basket-template-modal-header>
  <div class="qv-modal-dialog-content qv-modal-dialog-content--font-light">
    <div class="info-message" *ngIf="isEditMode && isNdcLevel">
      <span class="not-editable-msg">
        {{ resources.MarketBasketManagement.INFO_MESSAGES.MARKET_BASKET_NOT_EDITABLE_AT_NDC_LEVEL }}
      </span>
    </div>
    <qv-market-basket-info
      [marketBasket]="marketBasket"
      [manufacturerCompanyOldName]="manufacturerCompanyOldName">
    </qv-market-basket-info>
    <div class="actions-bar">
      <button *ngIf="isEditable(isEditMode, isNdcLevel)" class="link-btn" (click)="onImportClick()">
        {{ resources.MarketBasketManagement.IMPORT_MARKET_BASKET }}
      </button>
      <button *ngIf="isEditable(isEditMode, isNdcLevel)" class="link-btn" (click)="onEditClick()">
        {{ resources.MarketBasketManagement.EDIT_MARKET_BASKET }}
      </button>
    </div>
    <mb-market-basket-drugs
      class="market-basket-drugs"
      [marketBasketDrugs]="marketBasket.drugs">
    </mb-market-basket-drugs>
  </div>
  <footer mat-dialog-actions class="qv-modal-dialog-actions">
    <button mat-button
      class="qv-modal-dialog-actions__item cancel-btn"
      [class.qv-modal-dialog-actions__item--primary]="!isEditable(isEditMode, isNdcLevel)"
      (click)="onClose()">
      {{ resources.Actions.CANCEL }}
    </button>

    <button mat-button
      *ngIf="isEditable(isEditMode, isNdcLevel)"
      class="qv-modal-dialog-actions__item qv-modal-dialog-actions__item--primary unassign-btn"
      (click)="onUnassign()">
      {{ resources.Actions.UNASSIGN }}
    </button>
  </footer>
</section>
