import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SharedModule } from '@qv-shared/shared.module';
import {
  AtNdcComponent,
  CheckboxTermComponent,
  DateTermComponent,
  TermCurrencyComponent,
  TermInputComponent,
  TermMarketBasketComponent,
  TermNotesComponent,
  TermUmDetailsComponent,
  TermSelectComponent,
  TermSlideToggleComponent,
  TermTextareaComponent,
  TermFieldBaseComponent,
  UmDetailsModalComponent,
  DrugNotesModalComponent
} from '@qv-term/components';
import { AtNdcDirective } from '@qv-term/directives';
import { TermTemplateStorageService, TermEventBusService } from '@qv-term/services';
import { DrugNotesDaoService } from '@qv-term/services/dao';
import { DrugListFactory, PaymentFactory, SummaryFactory } from '@qv-term/services/factories';
import { BlockUIModule } from 'ng-block-ui';
import { TruncateModule } from 'ng2-truncate';
import { ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { BaseTermWithLockComponent, BaseTermComponent } from '@qv-term/components';
import { AngularComponentsCommonModule } from 'angular-components-common';
import { MatInputModule } from '@angular/material/input';

@NgModule({
  imports: [
    AngularComponentsCommonModule,
    CommonModule,
    MatIconModule,
    MatSlideToggleModule,
    MatSelectModule,
    MatTooltipModule,
    MatButtonModule,
    MatDatepickerModule,
    SharedModule,
    TruncateModule,
    ReactiveFormsModule,
    NgSelectModule,
    MatDialogModule,
    BlockUIModule,
    MatInputModule
  ],
  declarations: [
    AtNdcComponent,
    AtNdcDirective,
    BaseTermComponent,
    BaseTermWithLockComponent,
    TermInputComponent,
    TermCurrencyComponent,
    TermSelectComponent,
    DateTermComponent,
    CheckboxTermComponent,
    TermMarketBasketComponent,
    TermNotesComponent,
    TermUmDetailsComponent,
    TermSlideToggleComponent,
    TermTextareaComponent,
    TermFieldBaseComponent,
    UmDetailsModalComponent,
    DrugNotesModalComponent
  ],
  exports: [
    AtNdcDirective,
    BaseTermComponent,
    BaseTermWithLockComponent,
    TermInputComponent,
    TermCurrencyComponent,
    TermSelectComponent,
    DateTermComponent,
    CheckboxTermComponent,
    TermMarketBasketComponent,
    TermNotesComponent,
    TermUmDetailsComponent,
    TermSlideToggleComponent,
    TermTextareaComponent,
    TermFieldBaseComponent,
  ],
  providers: [
    TermTemplateStorageService,
    SummaryFactory,
    PaymentFactory,
    DrugListFactory,
    DrugNotesDaoService,
    TermEventBusService
  ],
})
export class TermModule { }
