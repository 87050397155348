import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, } from '@angular/core';
import { ScenarioStateConfig } from '@qv-bid/models';
import { Scenario } from '@qv-bid/entities';
import { SvgIconName } from '@qv-common/enums';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { BidStateService, DrugAlertNotificationService } from '@qv-bid/services';
import { DrugAlertType } from '@qv-bid/enums';
import { DrugSelectService, DrugSelectUtilsService } from '@qv-bid/services/selects';
import { ScenariosDependencyService } from '@qv-bid/services/scenarios-dependency.service';
import { QvCache } from '@qv-common/decorators';

@Component({
  selector: 'qv-standard-scenario-header',
  templateUrl: './standard-scenario-header.component.html',
  styleUrls: ['./standard-scenario-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StandardScenarioHeaderComponent implements OnInit {
  @BlockUI()
  public blockUI: NgBlockUI;

  @Input()
  public scenario: Scenario;

  @Input()
  public scenarioStateConfig?: ScenarioStateConfig;

  @Output()
  public toggleNdcsEvent = new EventEmitter<void>();

  public readonly svgIconName = SvgIconName;
  public readonly drugAlertType = DrugAlertType;

  constructor(
    public drugAlertNotificationService: DrugAlertNotificationService,
    public drugSelectService: DrugSelectService,
    public bidStateService: BidStateService,
    public drugSelectUtilsService: DrugSelectUtilsService,
    private scenariosDependencyService: ScenariosDependencyService,
  ) {}

  public ngOnInit(): void {
    this.drugAlertNotificationService.calculateScenarioNotification(this.scenario.drug.drugAlerts);
    this.drugSelectService.initScenarioSelectHandler(this.scenario.id);
  }

  public toggleNdcs($event: MouseEvent): void {
    $event.stopPropagation();
    this.toggleNdcsEvent.emit();
  }

  @QvCache()
  public getToggleIcon(isExpanded: boolean): SvgIconName {
    return isExpanded ? SvgIconName.CHEVRON_TOP : SvgIconName.CHEVRON_BOTTOM;
  }

  public onOpenScenarioDependencyModal(): void {
    this.scenariosDependencyService.onOpenScenarioDependencyModal(this.scenario, true);
  }
}
