import { Type } from '@angular/core';

import { ModalSize } from '../../enums/modal-size';
import { BaseInsideFormModal } from '../base/base-inside-form-modal';

export class TemplateFormModalData {
  constructor(
    public readonly title: string,
    public readonly component: Type<BaseInsideFormModal>,
    public readonly componentPayload?: any,
    public readonly primaryActionText = 'Save',
    public readonly secondaryActionText?: string,
    public readonly size: ModalSize = ModalSize.LARGE,
    public readonly disableClose?: boolean
  ) {}
}
