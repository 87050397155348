<div class="bid-comment-field">
  <textarea
      [class.bid-comment-field__textarea--error]="bidCommentFormService.formControl.invalid"
      [formControl]="bidCommentFormService.formControl"
      class="bid-comment-field__textarea"
      placeholder="Add comment here"
  >
  </textarea>
  <acc-form-field-errors
      [control]="bidCommentFormService.formControl"
      [validationMessages]="validationMessages"
      class="bid-comment-field__error"
  ></acc-form-field-errors>
</div>
