import { Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[accAutofocus]'
})
export class AutofocusDirective implements OnInit {

  @Input()
  public accAutofocus: boolean;

  constructor(private fieldElement: ElementRef) {
  }

  public ngOnInit(): void {
    if (this.accAutofocus && this.fieldElement) {
      setTimeout(() => this.fieldElement.nativeElement.focus());
    }
  }
}
