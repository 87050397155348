import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { ModalSize } from '../../../enums/modal-size';
import { GeneralModalData } from '../../../models/data/general-modal-data';
import { ModalConfig } from '../../../models/modal-config';
import { GeneralModalComponent } from '../general-modal.component';

export class GeneralModalConfig
  extends ModalConfig<GeneralModalComponent, GeneralModalData> {

  constructor(data: GeneralModalData, width: ModalSize) {
    super(data, width);
  }

  protected createDialogRef(dialog: MatDialog): MatDialogRef<GeneralModalComponent> {
    return dialog.open(GeneralModalComponent, this);
  }
}
