import { NdcDateValue, NdcDictionaryValue, NdcValue } from '@qv-term/entities';
import { DictionaryItem } from '@qv-common/entities';
import { BidUtils } from '@qv-bid/utils';
import { CoreUtils, DateUtils } from '@qv-common/utils';
import moment from 'moment';

export abstract class BaseEntity {

  public applyChanges(changes: { [key: string]: any }): BaseEntity {
    Object.keys(changes).forEach((key: string) => {
      if (CoreUtils.isNotDefined(this[key])) return;

      if (this[key] instanceof NdcDictionaryValue) {
        this[key] = new NdcDictionaryValue(
          changes[key] ? new DictionaryItem(changes[key].id, changes[key].name) : null,
          CoreUtils.isNull(changes[key])
        );
      } else if (this[key] instanceof NdcDateValue) {
        this[key] = NdcDateValue.getUtcNdcDateValue(changes[key], this[key]);
      } else if (this[key] instanceof NdcValue) {
        this[key] = new NdcValue(
          BidUtils.isAtNdc(changes[key]) ? null : changes[key],
          BidUtils.isAtNdc(changes[key]) || (this[key].isNdc && CoreUtils.isNull(changes[key])
            && !BidUtils.isAtNdc(this[key].value))
        );
      } else if (this[key] instanceof DictionaryItem) {
        this[key] = new DictionaryItem(changes[key].id, changes[key].name);
      } else if (this[key] instanceof moment) {
        this[key] = DateUtils.convertDateToUTC(changes[key]);
      } else {
        this[key] = changes[key];
      }
    });

    return this;
  }
}
