import { Injectable } from '@angular/core';
import { BidVersionDaoService, ContractedBusinessDaoService } from '@qv-bid/services/dao';
import { DrugNotesDaoService } from '@qv-term/services/dao';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { tap } from 'rxjs/operators';

import { ContractedBusiness } from 'quantuvis-core-entities';

@Injectable()
export class ContractedBusinessesService {
  public contractedBusinesses$ = new BehaviorSubject<ContractedBusiness[]>([]);
  public isHistoricNotesPresents$ = new ReplaySubject<boolean>();

  constructor(
    private contractedBusinessDaoService: ContractedBusinessDaoService,
    private bidVersionDaoService: BidVersionDaoService,
    private drugNotesDaoService: DrugNotesDaoService
  ) {}

  public loadCB(bidVersionId: number): Observable<ContractedBusiness[]> {
    return this.contractedBusinessDaoService.getByVersionId(bidVersionId).pipe(
      tap((contractedBusiness: ContractedBusiness[]) => this.contractedBusinesses$.next(contractedBusiness))
    );
  }

  public loadHistoricNotesPresents(bidVersionId: number, cbId: number): Observable<boolean> {
    return this.drugNotesDaoService.getHistoricNotesPresents(bidVersionId, cbId).pipe(
      tap((state: boolean) => this.isHistoricNotesPresents$.next(state))
    );
  }

  public deleteHistoricNotes(
    bidVersionId: number, cbId: number, scenarioIds: number[], drugIds: number[]
  ): Observable<void> {
    return this.drugNotesDaoService.deleteHistoricNotes(bidVersionId, cbId, scenarioIds, drugIds);
  }

  public getUniqueDrugList(bidVersionId: number): Observable<Map<number, number[]>> {
    return this.bidVersionDaoService.getUniqueDrugList(bidVersionId);
  }
}
