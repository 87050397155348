import { SelectBoxTerm } from '@qv-term/models/types';
import { constants, resources } from '@qv-common/static';
import { TierCompetition } from 'quantuvis-core-entities';

export class TierCompetitionTerm extends SelectBoxTerm {

  constructor(name: string) {
    super(
      name,
      name,
      resources.TermsDescriptions.DRUG_LIST.TIER_COMPETITION,
      constants.DRUG_TERM_PATHS.ALLOW_TIERS[1],
      '',
      TierCompetition.getValue(TierCompetition.EMPTY),
      'term__tier-competition'
    );
    this.values = TierCompetition.getValues();
  }
}
