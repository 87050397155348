import { Pipe, PipeTransform } from '@angular/core';
import { NumberUtils } from '@qv-common/utils';

@Pipe({
  name: 'numberFixed'
})
export class NumberFixedPipe implements PipeTransform {

  public transform(value: string | number, decimals: number = 5, minDecimals: number = 2, step: number = 0.0001): any {
    return typeof value === 'number' ? NumberUtils.toFixedDecimals(value, decimals, minDecimals, step) : value;
  }

}
