import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnChanges,
  OnInit,
  SimpleChanges
} from '@angular/core';
import { ContractManager } from '@qv-bid/components/shared/base/classes/contract.manager';
import { ContractFormService } from '@qv-bid/components/shared/summary-panel/contract/contract-form.service';
import { TerminationClause } from '@qv-bid/entities';
import { BidStateService, BidFormService } from '@qv-bid/services';
import { TerminationClauseDaoService } from '@qv-bid/services/dao/contract-terms';
import { DictionaryItem } from '@qv-common/entities';
import { Units } from '@qv-common/enums';
import { CompanyUtils } from '@qv-company/utils';
import { TermTemplateStorageService } from '@qv-term/services';
import { NumberUtils } from '@qv-common/utils';
import { TermName, TermSection } from '@qv-term/enums';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SnackBarService } from 'quantuvis-angular-common/snack-bar';
import { filter, switchMap, take } from 'rxjs/operators';
import { NoFaultTerminationClauseWhoMayInvoke } from '@qv-term/enums/options';
import {
  NoFaultTerminationClauseWhoMayInvokeTerm
} from '@qv-term/models/payment/no-fault-termination-clause-who-may-invoke.term';
import { SingleNotificationService } from '@qv-shared/services';
import { NotificationService } from 'quantuvis-angular-common/notification';

@UntilDestroy()
@Component({
  selector: 'qv-termination-clause',
  templateUrl: './termination-clause.component.html',
  styleUrls: ['./termination-clause.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TerminationClauseComponent extends ContractManager<TerminationClause> implements OnInit, OnChanges {

  public readonly termName = TermName;
  public readonly units = new Units();
  public readonly intNumberOptions = NumberUtils.intNumberOptions;

  public sectionName = TermSection.NO_FAULT_TERMINATION_CLAUSE;

  constructor(
    protected daoService: TerminationClauseDaoService,
    protected contractFormService: ContractFormService,
    protected bidFormService: BidFormService,
    protected changeDetectorRef: ChangeDetectorRef,
    protected notificationService: NotificationService,
    private bidStateService: BidStateService,
    private termTemplateStorage: TermTemplateStorageService,
    snackBarService: SnackBarService,
    singleNotificationService: SingleNotificationService
  ) {
    super(
      contractFormService,
      bidFormService,
      changeDetectorRef,
      notificationService,
      snackBarService,
      singleNotificationService
    );
  }

  public ngOnInit(): void {
    if (Number.isInteger(this.contractedBusinessId)) {
      this.loadEntity();
    }

    this.isFormReady$.pipe(
      filter((isReady: boolean) => isReady),
      switchMap(() => this.entity$.pipe(take(1))),
      filter((entity: TerminationClause) => entity.isPriorNoticeDisabled()),
      untilDestroyed(this)
    ).subscribe(() => this.formGroup.get(TermName.NO_FAULT_TERMINATION_CLAUSE_PRIOR_NOTICE_REQUIRED)
      .disable({ emitEvent: false }));
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.contractedBusinessId && !changes.contractedBusinessId.firstChange) {
      this.loadEntity();
    } else if (changes.isEditMode && changes.isEditMode.currentValue) {
      this.processWhoMayInvoke();
      this.initEditMode();
    }
  }

  public getWhoMayInvokeValue(whoMayInvoke: DictionaryItem): string {
    if (!whoMayInvoke) {
      return '';
    }

    const manufacturerCompanyOldName = this.bidStateService.bid$.getValue().manufacturerCompanyOldName;

    return CompanyUtils.getCompanyFullName(whoMayInvoke.name,
      whoMayInvoke.id === NoFaultTerminationClauseWhoMayInvoke.PHARMA, manufacturerCompanyOldName);
  }

  protected buildFormForSection(source: TerminationClause): void {
    this.contractFormService.buildTerminationClauseForm(source);
  }

  private processWhoMayInvoke(): void {
    const whoMayInvoke = TermName.NO_FAULT_TERMINATION_CLAUSE_WHO_MAY_INVOKE;
    const valueTerm = this.termTemplateStorage.getTermTemplate(whoMayInvoke);
    const whoMayInvokeOptions = (valueTerm as NoFaultTerminationClauseWhoMayInvokeTerm).values;
    whoMayInvokeOptions.map((item) => {
      const bid = this.bidStateService.bid$.value;
      if (item.id === 3) {
        item.name = bid.payer.name;
      } else if (item.id === 4) {
        item.name = CompanyUtils.appendOldCompanyName(bid.manufacturer.name, bid.manufacturerCompanyOldName);
      }
    });
  }
}
