import { Directive, ElementRef, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[qvClickOutside]'
})
export class ClickOutsideDirective {

  @Output()
  public clickOutside = new EventEmitter<void>();

  constructor(private elementRef: ElementRef) {}

  @HostListener('document:click', ['$event.target'])
  public onClick(targetElement: HTMLElement): void {
    const clickedInside = this.elementRef.nativeElement.contains(targetElement);
    const rootElement = document.getElementById('qv-root');

    if (rootElement.contains(targetElement) && !clickedInside) {
      this.clickOutside.emit();
    }
  }
}
