import { DictionaryItem } from '@qv-common/entities';

export enum LatePaymentPenaltyFrequency {
  EMPTY = 1,
  DAILY = 2,
  MONTHLY = 3,
  QUARTERLY = 4
}

export namespace LatePaymentPenaltyFrequency {
  const valuesMap = new Map<LatePaymentPenaltyFrequency, DictionaryItem>([
    [LatePaymentPenaltyFrequency.EMPTY, { id: LatePaymentPenaltyFrequency.EMPTY, name: '' }],
    [LatePaymentPenaltyFrequency.DAILY, { id: LatePaymentPenaltyFrequency.DAILY, name: 'Daily' }],
    [LatePaymentPenaltyFrequency.MONTHLY, { id: LatePaymentPenaltyFrequency.MONTHLY, name: 'Monthly' }],
    [LatePaymentPenaltyFrequency.QUARTERLY, { id: LatePaymentPenaltyFrequency.QUARTERLY, name: 'Quarterly' }],
  ]);

  export function getValue(frequency: LatePaymentPenaltyFrequency): DictionaryItem {
    return valuesMap.get(frequency);
  }

  export function getValues(): DictionaryItem[] {
    return Array.from(valuesMap.values());
  }
}
