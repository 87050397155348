import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { OperatorFunction, pipe, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

import { ConfirmModal } from '../../interfaces';
import { GeneralModalData } from '../../models/data/general-modal-data';

@Component({
  selector: 'qac-general-modal',
  templateUrl: './general-modal.component.html',
  styleUrls: ['./general-modal.component.scss']
})
export class GeneralModalComponent implements ConfirmModal {
  @BlockUI('general-modal')
  public blockUI: NgBlockUI;

  @Output()
  public primaryAction = new EventEmitter();

  @Output()
  public secondaryAction = new EventEmitter();

  constructor(
    public dialogRef: MatDialogRef<GeneralModalComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: GeneralModalData
  ) {}

  public onPrimaryAction(): void {
    if (this.modalData.shouldImmediatelyClose) {
      this.dialogRef.close();
    } else {
      this.blockUI.start();
    }

    this.primaryAction.emit();
  }

  public onSecondaryAction(): void {
    this.secondaryAction.emit();
    this.dialogRef.close();
  }

  public primaryActionFinished(): void {
    this.blockUI.stop();
    this.dialogRef.close();
  }

  public handleAfterAction<T>(): OperatorFunction<T, T> {
    return pipe(
      this.handlePrimaryActionFinished(),
      this.handleError()
    );
  }

  private handleError<T>(): OperatorFunction<T, T> {
    return catchError((err: HttpErrorResponse) => {
      this.blockUI.stop();

      return throwError(err);
    });
  }

  private handlePrimaryActionFinished<T>(): OperatorFunction<T, T> {
    return tap(() => {
      this.blockUI.stop();
      this.primaryAction.complete();
      this.dialogRef.close();
    });
  }
}
