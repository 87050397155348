import { SelectBoxTerm } from '@qv-term/models/types';
import { KeepOriginalValue, Reset } from '@qv-common/interfaces';
import { resources } from '@qv-common/static';
import { PriceProtectionReset } from 'quantuvis-core-entities';

export class ResetTerm extends SelectBoxTerm implements KeepOriginalValue, Reset {
  public allowKeepOriginValue: boolean;
  public processKeepOriginalValue: boolean;
  public keepOriginalValue: boolean;

  constructor(name: string) {
    super(
      name,
      name,
      resources.TermsDescriptions.DRUG_LIST.RESET,
      '',
      '',
      PriceProtectionReset.getValue(PriceProtectionReset.EMPTY),
      'term__reset'
    );

    this.values = PriceProtectionReset.getValues();
  }

  public resetValue(): void {
    this.value = this.defaultValue;
  }

}
