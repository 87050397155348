import { constants } from '@qv-common/static';
import { drugTermsConstants } from '@qv-term/constants';
import { TermName } from '@qv-term/enums';
import { DrugListTerms } from '../../utils/terms';

export class DrugLevelService {
  public static $inject = ['$filter', 'util', 'translations', 'biddingUtilsService',
    'viewPerspectiveService', '$sce', '$templateRequest', 'restapi', 'authService', 'BaselineWacService'];
  private $filter;
  private util;
  private translations;
  private biddingUtilsService;
  private viewPerspectiveService;
  private restapi;
  private $sce;
  private $templateRequest;
  private authService;
  private BaselineWacService;

  constructor($filter, util, translations, biddingUtilsService, viewPerspectiveService, $sce,
              $templateRequest, restapi, authService, BaselineWacService) {
    'ngInject';
    this.$filter = $filter;
    this.util = util;
    this.translations = translations;
    this.biddingUtilsService = biddingUtilsService;
    this.viewPerspectiveService = viewPerspectiveService;
    this.restapi = restapi;
    this.$sce = $sce;
    this.$templateRequest = $templateRequest;
    this.authService = authService;
    this.BaselineWacService = BaselineWacService;
  }

  /**
   * Create default drug scenario
   */
  public getEmptyDrugScenario(lockFlag = false): any {
    const drugListTerms = DrugListTerms(this.$filter, this.util, this.translations, this.restapi,
      this.biddingUtilsService, this.viewPerspectiveService, this.$sce, this.$templateRequest,
      this.authService, this.BaselineWacService);

    return {
      lockTierOptions: lockFlag ? lockFlag : false,
      placement: this.getTermDefaultValueByName(drugListTerms, drugTermsConstants[TermName.TIER_PLACEMENT].title),
      competition: this.getTermDefaultValueByName(drugListTerms, drugTermsConstants[TermName.TIER_COMPETITION].title),
      competitionGroup:
        this.getTermDefaultValueByName(drugListTerms, drugTermsConstants[TermName.COMPETITION_GROUP].title),
      competitor: this.getTermDefaultValueByName(drugListTerms, drugTermsConstants[TermName.COMPETITORS_TIER].title),
      lockUMOptions: lockFlag ? lockFlag : false,
      stepTherapyAllowed:
        this.getTermDefaultValueByName(drugListTerms,
          drugTermsConstants[TermName.STEP_THERAPY_ALLOWED_ON_PRODUCT].title),
      stepTherapyRequired: this.getTermDefaultValueByName(drugListTerms,
        drugTermsConstants[TermName.STEP_THERAPY_REQUIRED_ON_COMPETITORS_PRODUCTS].title),
      priorAuthorizationAllowed: this.getTermDefaultValueByName(drugListTerms,
        drugTermsConstants[TermName.PRIOR_AUTHORIZATION_ALLOWED_ON_PRODUCT].title),
      priorAuthorizationRequired: this.getTermDefaultValueByName(drugListTerms,
        drugTermsConstants[TermName.PRIOR_AUTHORIZATION_REQUIRED_ON_COMPETITORS_PRODUCTS].title),
      quantityLimit: this.getTermDefaultValueByName(drugListTerms, drugTermsConstants[TermName.QUANTITY_LIMIT].title),
      otherUM: this.getTermDefaultValueByName(drugListTerms, drugTermsConstants[TermName.OTHER_UM].title),
      UMDetails: this.getTermDefaultValueByName(drugListTerms, drugTermsConstants[TermName.UM_DETAILS].title),
    };
  }

  public getTermDefaultValueByName(drugListTerms, name): any[] {
    const filteredTerms = drugListTerms.filter((item) => (item.name === name));
    return filteredTerms.length > 0 ? filteredTerms[0].defaultValue : [];
  }

  public addDrugScenario(scenariosList, generalScenariosLock): void {
    if (scenariosList.length < constants.SCENARIOS_MAX_LENGTH) {
      const emptyDrugScenario = this.getEmptyDrugScenario(generalScenariosLock);
      scenariosList.push(emptyDrugScenario);
    }
  }

  public deleteDrugScenario(scenariosList, scenarioIndex): void {
    scenariosList.splice(scenarioIndex, 1);
  }

}

