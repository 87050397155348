import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Directive, Input } from '@angular/core';
import { BaseSection } from '@qv-bid/classes/sections/base-section';
import { UtilizationManagement } from '@qv-bid/entities';
import { SectionChange, SectionChangeFailed } from '@qv-bid/models';
import { SectionChangeManager } from '@qv-bid/services/sections/section-change-manager.service';
import { TermName, TermSection } from '@qv-term/enums';
import { resources } from '@qv-common/static';
import { drugTermsConstants } from '@qv-term/constants';
import { DrugFormService } from '@qv-bid/services/drug';
import { UtilizationManagementDaoService } from '@qv-bid/services/dao';
import { Observable, of } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { catchError, switchMap, tap } from 'rxjs/operators';

@UntilDestroy()
@Directive()
export class UmSection extends BaseSection {
  @Input()
  public utilizationManagement: UtilizationManagement;

  @Input()
  public drugId: number;
  @Input()
  public scenarioId: number;
  @Input()
  public isBidInvalid: boolean;
  @Input()
  public isBidInternal: boolean;

  public readonly tooltips = resources.TermsDescriptions.DRUG_LIST;
  public readonly drugTermsConstants = drugTermsConstants;
  public readonly termName = TermName;
  public readonly sectionName = TermSection.UM;

  constructor(
    public drugFormService: DrugFormService,
    private utilizationManagementDaoService: UtilizationManagementDaoService,
    changeDetectorRef: ChangeDetectorRef,
    sectionChangeManager: SectionChangeManager,
  ) {
    super(drugFormService, changeDetectorRef, sectionChangeManager);
  }

  public initHandlers(): void {
    this.initSectionChangeHandler();
    this.initSectionEditModeChangeHandler();
  }

  private initSectionChangeHandler(): void {
    this.drugFormService.sectionChangeHandler(this.sectionName)
      .pipe(
        tap(() => this.sectionChangeManager.isSectionChanging$.next(true)),
        switchMap((um: UtilizationManagement) => this.updateSection(um)),
        this.sectionLeaveHandler(),
      )
      .subscribe();
  }

  private initSectionEditModeChangeHandler(): void {
    this.drugFormService.isEditMode(this.sectionName).pipe(untilDestroyed(this))
      .subscribe((isEditMode: boolean) => {
        this.isEditMode = isEditMode;
        this.changeDetectorRef.markForCheck();
      });
  }

  private updateSection(um: UtilizationManagement): Observable<UtilizationManagement> {
    return this.utilizationManagementDaoService.update(um, this.scenarioId, this.isBidInternal, this.drugId)
      .pipe(
        tap((updatedSection: UtilizationManagement) => {
          const sectionChange = new SectionChange(this.scenarioId, this.drugId, this.sectionName, updatedSection);
          this.sectionChangeManager.sectionSaveSuccessEvent$.emit(sectionChange);
        }),
        catchError((error: HttpErrorResponse) => this.handleChangeHandlerError(error))
      );
  }

  private handleChangeHandlerError(error: HttpErrorResponse): Observable<UtilizationManagement> {
    this.sectionChangeManager.isSectionChangeFailed$.next(new SectionChangeFailed(this.sectionName, error));

    return of(this.utilizationManagement);
  }
}
