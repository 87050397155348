export class ArrayUtils {

  public static groupBy<T, K>(array: T[], func: (item: T) => K): Map<K, T[]> {
    const map = new Map<K, T[]>();
    array.forEach((item) => {
      const key = func(item);
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    });

    return map;
  }

  public static getUniqueValuesByKey<T>(items: T[], key: string): string[] {
    const values = items.map((item: T) => item[key]);

    return Array.from(new Set(values));
  }

  /**
   * Creates an array of unique values from all given arrays
   *
   * @param arrays
   */
  public static union<T>(...arrays: T[][]): T[] {
    return Array.from(new Set([].concat(...arrays)));
  }

  /**
   * Creates an array of array values not included in the other given arrays
   *
   * @param array1
   * @param array2
   */
  public static difference<T>(array1: T[], array2: T[]): T[] {
    return [array1, array2].reduce((arr1: T[], arr2: T[]) => arr1.filter((item: T) => !arr2.includes(item)));
  }

  public static isEmptyArray<T>(items: T[]): boolean {
    return Array.isArray(items) && !items.length;
  }
}
