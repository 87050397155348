import { Injectable } from '@angular/core';
import { Summary } from '@qv-bid/entities';
import { NdcDateValue } from '@qv-term/entities';
import { SummaryService } from '@qv-bid/services/summary';
import { BidUtils } from '@qv-bid/utils';
import { ValidationError } from '@qv-common/models';
import { drugTermsConstants } from '@qv-term/constants';
import { DateUtils } from '@qv-common/utils';
import { TermName } from '@qv-term/enums';
import { Moment } from 'moment';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { RfpDueDateTerm } from '@qv-term/models/summary';
import { appConfig } from '@qv-common/configs';

@Injectable()
export class ScenarioDateValidationService {
  private readonly termsForWarningsValidation = [TermName.SCENARIO_START_DATE, TermName.SCENARIO_END_DATE];
  private readonly dateFormat = appConfig.dateFormat;

  constructor(private summaryService: SummaryService) { }

  public static getValidationData(date: NdcDateValue, contractDate: Moment, title: string, label: string): ValidationError[] {
    if (BidUtils.isAtNdc(date)) {
      return [];
    }

    return DateUtils.validateComparingDates(date && date.value as Moment, contractDate, title, label);
  }

  public getValidationWarnings$(currentTermName: TermName, value: Moment): Observable<ValidationError[]> {
    return this.summaryService.getSummary.pipe(
      take(1),
      map((summary: Summary) => this.getValidationWarnings(currentTermName, value, summary)),
    );
  }

  private getValidationWarnings(currentTermName: TermName, value: Moment, summary: Summary): ValidationError[] {
    if (currentTermName === TermName.RFP_DUE_DATE) {
      const rfpDueDate = DateUtils.convertDateToUTC(value);
      return RfpDueDateTerm.getRfpDueDateWarnings(rfpDueDate);
    }

    if (!this.termsForWarningsValidation.includes(currentTermName)) return;

    const scenarioDate = value;
    const contractDate = this.getContractDate(currentTermName, summary);
    const scenarioDateName = drugTermsConstants[currentTermName].title;
    const contractDateName = SummaryService.getContractDateName(currentTermName);

    return DateUtils.validateComparingDates(scenarioDate, contractDate, scenarioDateName, contractDateName);
  }

  private getContractDate(currentTermName: string, summary: Summary): Moment {
    if (!(summary.contractStartDate && summary.contractEndDate)) return null;
    if (currentTermName === TermName.SCENARIO_START_DATE) {
      return DateUtils.getFormattedDate(summary.contractStartDate, this.dateFormat);
    } else if (currentTermName === TermName.SCENARIO_END_DATE) {
      return DateUtils.getFormattedDate(summary.contractEndDate, this.dateFormat);
    }
  }
}
