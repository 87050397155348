/**
 * Application frame directive.
 * Using AngularJS templating system in order to render a complete frame.
 * The template HTML til located in templates/drugpicker.html
 */
import { constants } from '@qv-common/static';

export const DrugPicker = [() => {
  return {
    // @ts-ignore
    templateUrl: `/app/views/bidding/templates/drugpicker.html`,
    restrict: 'A',
    replace: true,
    controller: 'PickerController',
    link: (scope) => {
      scope.nameMaxLength = constants.CONTRACTED_BUSINESS_NAME_MAX_LENGTH;

    }
  };
}];


export function ManufacturerPicker(): { controller: 'PickerController'; replace: true; restrict: 'A'; templateUrl: string } {

  return {
    // @ts-ignore
    templateUrl: `/app/views/bidding/create/companypicker.html`,
    restrict: 'A',
    replace: true,
    controller: 'PickerController'
  };
}

export function InternalPayerPicker(): any {
  return {
    // @ts-ignore
    templateUrl: `/app/views/bidding/create/companypicker.html`,
    restrict: 'A',
    replace: true,
    controller: 'PickerController'
  };
}

export function ManufacturerDrugsPicker(): any {
  return {
    // @ts-ignore
    templateUrl: `/app/views/bidding/templates/drugpicker.html`,
    restrict: 'A',
    replace: true,
    controller: 'PickerController',
  };
}
// tslint:disable-next-line:max-file-line-count
