import { JsonObject, JsonProperty } from 'json2typescript';
import { User } from './user.entity';

@JsonObject('UserState')
export class UserState {
  @JsonProperty('clientToken', String)
  public clientToken = null;
  @JsonProperty('features', [])
  public features = null;
  @JsonProperty('myAccounts', [])
  public myAccounts = null;
  @JsonProperty('securityToken', String)
  public securityToken = null;
  @JsonProperty('user', User)
  public user: User = null;
  @JsonProperty('userACLs')
  public userACLs = null;
}
