import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { MarketShareService } from '@qv-bid/services/sections';
import { SectionChangeManager } from '@qv-bid/services/sections/section-change-manager.service';
import { SummaryService } from '@qv-bid/services/summary';
import { SectionFilter } from '@qv-bid/enums';
import { QvCache } from '@qv-common/decorators';
import { BidStateService, ContractedBusinessesService, DrugAlertNotificationService, } from '@qv-bid/services';
import { SectionFilterCheckboxes } from '@qv-bid/models';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject } from 'rxjs';
import { DrugSelectService } from '@qv-bid/services/selects';
import { ScenariosSnackBarService } from '@qv-bid/services/scenarios-snack-bar.service';
import { BidEventBusService } from '@qv-bid/services/bid-event-bus.service';
import { DrugSectionsFormService, StandardDrugFormService, } from '@qv-bid/services/drug';
import { BaseNdcRow } from '@qv-bid/components/shared/base';

@UntilDestroy()
@Component({
  selector: 'qv-standard-ndc',
  templateUrl: './standard-ndc.component.html',
  styleUrls: ['./standard-ndc.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    StandardDrugFormService,
    DrugSectionsFormService,
    MarketShareService
  ]
})
export class StandardNdcComponent extends BaseNdcRow implements OnInit {
  @Input()
  public filter: SectionFilterCheckboxes;
  @Input()
  public isBidInvalid: boolean;

  public readonly sectionFilterEnum = SectionFilter;
  public readonly ndcSelected$ = new BehaviorSubject(false);

  public constructor(
    scenariosSnackBarService: ScenariosSnackBarService,
    bidEventBusService: BidEventBusService,
    drugAlertNotificationService: DrugAlertNotificationService,
    bidStateService: BidStateService,
    contractedBusinessesService: ContractedBusinessesService,
    summaryService: SummaryService,
    standardDrugFormService: StandardDrugFormService,
    sectionChangeManager: SectionChangeManager,
    changeDetectorRef: ChangeDetectorRef,
    protected drugSelectService: DrugSelectService,
  ) {
    super(
      scenariosSnackBarService,
      bidEventBusService,
      drugAlertNotificationService,
      bidStateService,
      contractedBusinessesService,
      changeDetectorRef,
      standardDrugFormService,
      summaryService,
      sectionChangeManager,
    );
  }

  public ngOnInit(): void {
    this.initNdc();
    this.initNdcSelectedHandler();
  }

  @QvCache()
  public isNdcVisible(isDismissed: boolean, checked: boolean): boolean {
    return checked || !isDismissed;
  }

  private initNdcSelectedHandler(): void {
    if (this.ndc) {
      this.drugSelectService.ndcSelected(this.ndc.drugId).pipe(untilDestroyed(this))
        .subscribe((isSelected: boolean) => this.ndcSelected$.next(isSelected));
    }
  }
}
