import { Component, Input, OnInit, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { QvCache } from '@qv-common/decorators';
import { SvgIconName } from '@qv-common/enums';
import { TermTemplateStorageService } from '@qv-term/services';
import { NotesMetadata } from '@qv-term/models';
import { constants, resources } from '@qv-common/static';
import { DrugNotesModalConfig, DrugNotesModalData } from '@qv-term/components/drug-notes-modal/models';
import { Note } from '@qv-term/entities';
import { BidStatus } from 'quantuvis-core-entities';
import { BaseTermWithLockComponent } from '@qv-term/components/base-term-with-lock/base-term-with-lock.component';
import { ModalService } from 'quantuvis-angular-common/modal';
import { UserService } from '@qv-common/services/auth/user.service';

@Component({
  selector: 'qv-term-notes',
  templateUrl: './term-notes.component.html',
  styleUrls: ['./term-notes.component.scss']
})
export class TermNotesComponent extends BaseTermWithLockComponent implements OnInit {
  @Input()
  public termValue: NotesMetadata;
  @Input()
  public drugId?: number;
  @Input()
  public scenarioId: number;
  @Input()
  public cbId: number;
  @Input()
  public bidVersionId: number;
  @Input()
  public bidStatus: BidStatus;
  @Input()
  public isBidInternal: boolean;
  @Input()
  public manufacturerCompanyOldName: string;
  @Input()
  public manufacturerCompanyId: number;

  @Output()
  public currentNoteUpdated = new EventEmitter<Note>();
  @Output()
  public historicNotesDeleted = new EventEmitter<Note>();

  public readonly svgIconName = SvgIconName;
  public readonly AT_NDC = constants.AT_NDC;

  constructor(
      protected termTemplateStorage: TermTemplateStorageService,
      private modalService: ModalService,
      userService: UserService,
      changeDetectorRef: ChangeDetectorRef
  ) {
    super(userService, termTemplateStorage, changeDetectorRef);
  }

  public ngOnInit(): void {
    super.ngOnInit();

    this.readOnlyStateEvent.next(
      !this.isEditable(this.isEditMode, this.isUserPharma, this.isLocked, this.isPending)
      || this.isAtNdcValue(this.termValue.isAtNdcHistoric, this.termValue.isAtNdcCurrent));
  }

  @QvCache()
  public getTooltipMessage(isNotesEmpty: boolean): string {
    return isNotesEmpty ? resources.TermsDescriptions.DRUG_LIST.EMPTY_NOTES_TOOLTIP : '';
  }

  @QvCache()
  public isAtNdcValue(isAtNdcCurrent: boolean, isAtNdcHistoric: boolean): boolean {
    return isAtNdcCurrent || isAtNdcHistoric;
  }

  public isEmptyValue(): boolean {
    return !this.getTermValue() || this.getTermValue().isEmpty();
  }

  public transferToEditMode(): void {
    this.openModal();
  }

  public isEditingAvailable(): boolean {
    return this.isEnabled(this.isEmptyValue(), this.isEditMode, this.isUserPharma, this.isLocked, this.isPending);
  }

  public openModal(): void {
    const modal = this.modalService.openModal(new DrugNotesModalConfig(new DrugNotesModalData(
      this.getTermValue(),
      this.isEditable(this.isEditMode, this.isUserPharma, this.isLocked),
      this.scenarioId,
      this.cbId,
      this.drugId,
      this.bidVersionId,
      this.bidStatus,
      this.isBidInternal,
      this.manufacturerCompanyOldName,
      this.manufacturerCompanyId
    )));
    modal.componentInstance.currentNoteUpdated = this.currentNoteUpdated;
    modal.componentInstance.historicNotesDeleted = this.historicNotesDeleted;
    modal.afterClosed().subscribe(() => this.exitFromEditModeEvent.emit());
  }
}
