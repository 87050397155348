<div class="qv-modal-dialog-title" mat-dialog-title>
  <div class="qv-modal-dialog-title__text">{{modalData.title}}</div>
  <div (click)="closeModalDialog()" class="qv-modal-dialog-title__xbutton">
    <mat-icon>close</mat-icon>
  </div>
</div>
<div class="qv-modal-dialog-content" mat-dialog-content>
  <div *ngIf="modalData.message" class="qv-modal-dialog-content__message">
    <p>{{modalData.message}}</p>
  </div>
  <div *ngIf="modalContent" class="qv-modal-dialog-content__data">
    <mat-expansion-panel *ngFor="let planType of getPlanTypes()">
      <mat-expansion-panel-header>{{planType}}</mat-expansion-panel-header>
      <ul *ngFor="let conflict of getConflictsOfPlanType(planType)" id="{{planType}}"
          style="list-style-type:disc">
        <li *ngIf="conflict.isDisplayed">{{conflict.drug.scenario.name}} {{conflict.drug.name}} <span
            *ngIf="conflict.isAtNdcLevel">@NDC</span></li>
      </ul>
    </mat-expansion-panel>
  </div>
</div>
<div class="qv-modal-dialog-actions" mat-dialog-actions>
  <button (click)="closeModalDialog()"
          class="qv-modal-dialog-actions__item qv-modal-dialog-actions__item--primary"
          mat-button>OK
  </button>
</div>
