<div class="invalid-contract-date-modal">
  <header mat-dialog-title class="qv-modal-dialog-title">
    <h1 class="qv-modal-dialog-title__text">Invalid values</h1>
    <button class="qv-modal-dialog-title__xbutton" [mat-dialog-close]>
      <mat-icon>close</mat-icon>
    </button>
  </header>
  <div mat-dialog-content class="qv-modal-dialog-content">
    <p class="qv-modal-dialog-content__message">
      Populated {{ modalData.contractDateName }} cannot be saved because it's invalid for the following scenarios:
    </p>
    <div class="qv-modal-dialog-content__data">
      <mat-expansion-panel *ngFor="let invalidData of modalData.invalidContractDateData">
        <mat-expansion-panel-header>{{ invalidData.cbName }}</mat-expansion-panel-header>
        <ul>
          <li class="invalid-contract-date-modal__conflict" *ngFor="let conflict of invalidData.conflicts">
            {{ conflict.value }} <span class="invalid-contract-date-modal__at-ndc" *ngIf="conflict.isNdc">@NDC</span>
          </li>
        </ul>
      </mat-expansion-panel>
    </div>
  </div>
  <footer mat-dialog-actions class="qv-modal-dialog-actions">
    <button mat-button [mat-dialog-close] class="qv-modal-dialog-actions__item qv-modal-dialog-actions__item--primary">
      {{ resources.Actions.OK }}
    </button>
  </footer>
</div>
