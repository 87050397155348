import { SelectBoxTerm } from '@qv-term/models/types';
import { constants, resources } from '@qv-common/static';
import { PaymentLag } from '@qv-term/enums/options';

export class PaymentLagTerm extends SelectBoxTerm {

  constructor(name: string) {
    super(
      name,
      name,
      resources.TermsDescriptions.CONTRACT.PAYMENT_LAG,
      constants.CONTRACT_TERMS.PAYMENT_LAG.label,
      '',
      PaymentLag.getValue(PaymentLag.EMPTY),
      'term__payment-lag'
    );
    this.values = PaymentLag.getValues();
  }
}
