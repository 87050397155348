import { JsonObject, JsonProperty } from 'json2typescript';
import { MarketBasketDrugType } from '../enums';

@JsonObject('MarketBasketDrug')
export class MarketBasketDrug {
  @JsonProperty('name', String)
  public name: string = null;

  @JsonProperty('drugClass', String)
  public drugClass: string = null;

  @JsonProperty('typeId', Number)
  public type: MarketBasketDrugType = MarketBasketDrugType.STANDARD;

  public isMatchNameAndClass(drugName: string, drugClass: string): boolean {
    return this.name === drugName && this.drugClass === drugClass;
  }

  public get id(): string {
    const nameKey = this.name.toLowerCase().replace(' ', '-');

    return `${nameKey}-${this.drugClass}`;
  }
}
