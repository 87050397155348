import { JsonObject, JsonProperty } from 'json2typescript';
import { Sort } from '@angular/material/sort';
import { defaultPageSize } from '@qv-table/constants';

@JsonObject('RemoteState')
export class RemoteState<F> {
  @JsonProperty('page', Number)
  public page = 0;

  @JsonProperty('size', Number)
  public size: number = defaultPageSize;

  @JsonProperty('sort', Object, true)
  public sort?: Sort = null;

  @JsonProperty('filter', Object, true)
  public filter?: F = null;

  constructor(page: number = 0, size: number = defaultPageSize, sort?: Sort, filter?: F) {
    this.page = page;
    this.size = size;
    this.sort = sort;
    this.filter = filter;
  }
}
