import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { tap } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SvgIconName } from '@qv-common/enums';

@UntilDestroy()
@Component({
  selector: 'qv-list-panel-header-search',
  templateUrl: './list-panel-header-search.component.html',
  styleUrls: ['./list-panel-header-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListPanelHeaderSearchComponent implements OnInit {
  @Input()
  public control: FormControl;
  @Input()
  public headerTitle: string;
  @Input()
  public placeholder: string;
  @Input()
  public isSearching = false;

  @Output()
  public clearSearchEvent = new EventEmitter();

  public isSearchControlActive = false;
  public readonly svgIconName = SvgIconName;

  @ViewChild('searchInput')
  public searchInput: ElementRef<HTMLInputElement>;

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  public ngOnInit(): void {
    this.initSearchHandler();
  }

  public openSearch(): void {
    this.isSearchControlActive = true;
    this.changeDetectorRef.detectChanges();
    this.searchInput.nativeElement.focus();
  }

  public closeSearch(): void {
    this.isSearchControlActive = false;
    this.changeDetectorRef.markForCheck();
    this.control.reset();
    this.clearSearchEvent.next();
  }

  private initSearchHandler(): void {
    this.control.valueChanges
      .pipe(
        tap((query: string) => {
          if (Boolean(query && query.length)) {
            this.isSearchControlActive = true;
            this.changeDetectorRef.markForCheck();
          } else {
            this.clearSearchEvent.next();
          }
        }),
        untilDestroyed(this)
      )
      .subscribe();
  }
}
