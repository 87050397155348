import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { UmDetailsModalComponent } from '../um-details-modal.component';
import { UmDetailsModalData } from './um-details-modal-data';
import { ModalConfig, ModalSize } from 'quantuvis-angular-common/modal';

export class UmDetailsModalConfig extends ModalConfig<UmDetailsModalComponent, UmDetailsModalData> {
  constructor(umDetailsModalData: UmDetailsModalData) {
    super(umDetailsModalData, ModalSize.MEDIUM);
  }

  protected createDialogRef(dialog: MatDialog): MatDialogRef<UmDetailsModalComponent> {
    return dialog.open(UmDetailsModalComponent, this);
  }
}
