/* tslint:disable:triple-equals no-invalid-this */
/**
 * Bidding Contract Terms definitions
 */
import { constants, resources } from '@qv-common/static';
import { CoreUtils } from '@qv-common/utils';

export function ContractTerms(util, translations, q?) {
  function disableKeepOriginalValue(term): void {
    term.definition.keepOriginalValue = false;
    term.definition.placeholder = '';
  }

  function enableKeepOriginalValue(term): void {
    term.definition.keepOriginalValue = true;
    term.definition.placeholder = resources.NewBid.KEEP_ORIGINAL_VALUE;
  }

  return [
    {
      name: constants.CONTRACT_TERMS.REBATE_ELIGIBILITY.label,
      type: 1,
      allowKeepOriginValue: true,
      path: [
        constants.BID_SECTIONS.CONTRACT_TERMS,
        translations.i18n.BID_DETAILS.PAYMENT,
        constants.CONTRACT_TERMS.REBATE_ELIGIBILITY.label
      ],
      groupPath: [
        constants.BID_SECTIONS.CONTRACT_TERMS, translations.i18n.BID_DETAILS.PAYMENT
      ],
      numberOfTermsInGroup: 4,
      values: [
        '', '12 months', '18 months', '24 months', '60 days', '90 days', '120 days', '150 days', '180 days', '210 days', '270 days',
        '1st Day of Current Invoice', 'Current Quarter', 'Calendar Quarter', 'Beyond Calendar Month',
        'During the quarter + previous 4 quarters', 'During the quarter + previous 12 months',
        '180 days after calendar quarter/month', '45 days after calendar quarter/month', 'Plan Year + 180 days',
        '180 days preceding calendar quarter', '180 days preceding calendar month',
        '45 days preceding current calendar quarter', '45 days preceding current calendar month'
      ],
      defaultValue: '',
      description: translations.i18n.TermsDescriptions.CONTRACT.REBATE_ELIGIBILITY,
      resetValue: util.resetValueDropdown
    },
    {
      name: constants.CONTRACT_TERMS.PAYMENT_LAG.label,
      path: [
        constants.BID_SECTIONS.CONTRACT_TERMS,
        translations.i18n.BID_DETAILS.PAYMENT,
        constants.CONTRACT_TERMS.PAYMENT_LAG.label
      ],
      groupPath: [
        constants.BID_SECTIONS.CONTRACT_TERMS, translations.i18n.BID_DETAILS.PAYMENT
      ],
      numberOfTermsInGroup: 4,
      type: 1,
      allowKeepOriginValue: true,
      values: [
        '', '10 days', '15 days', '30 days', '45 days', '60 days', '90 days', '120 days', '150 days', '180 days',
        'Estimate (90%) Net 30', 'Estimate (80%) Net 30', 'Estimate (70%) Net 30', 'Estimate (60%) Net 30'
      ],
      defaultValue: '',
      description: translations.i18n.TermsDescriptions.CONTRACT.PAYMENT_LAG,
      resetValue: util.resetValueDropdown
    },
    {
      name: constants.CONTRACT_TERMS.PAYMENT_FREQUENCY.label,
      path: [
        constants.BID_SECTIONS.CONTRACT_TERMS,
        translations.i18n.BID_DETAILS.PAYMENT,
        constants.CONTRACT_TERMS.PAYMENT_FREQUENCY.label
      ],
      groupPath: [
        constants.BID_SECTIONS.CONTRACT_TERMS, translations.i18n.BID_DETAILS.PAYMENT
      ],
      numberOfTermsInGroup: 4,
      type: 1,
      allowKeepOriginValue: true,
      values: [
        '', 'Monthly', 'Quarterly'
      ],
      defaultValue: '',
      description: translations.i18n.TermsDescriptions.CONTRACT.PAYMENT_FREQUENCY,
      resetValue: util.resetValueDropdown
    },
    {
      name: constants.CONTRACT_TERMS.WHOLESALE_ACQUISITION_PRICE.label,
      path: [
        constants.BID_SECTIONS.CONTRACT_TERMS, translations.i18n.BID_DETAILS.PAYMENT,
        constants.CONTRACT_TERMS.WHOLESALE_ACQUISITION_PRICE.label
      ],
      groupPath: [
        constants.BID_SECTIONS.CONTRACT_TERMS, translations.i18n.BID_DETAILS.PAYMENT
      ],
      numberOfTermsInGroup: 4,
      type: 1,
      allowKeepOriginValue: true,
      values: [
        '', 'Daily WAC', 'Daily Weighted WAC by Month',
        'Daily Weighted WAC by Quarter', 'WAC in Effect Most Days of Monthly',
        'WAC in Effect Most Days of Quarter', '1st Day of Month',
        '15th Day of Month', 'Last Day of Month', '1st Day of Quarter',
        '45th Day of Quarter', 'Last Day of Quarter', 'Daily Monthly Average', 'Daily Quarterly Average'
      ],
      defaultValue: '',
      description: translations.i18n.TermsDescriptions.CONTRACT.WHOLESALE_ACQUISITION_PRICE,
      resetValue: util.resetValueDropdown
    },
    {
      name: constants.BIDDING.LATE_PAYMENT_PENALTY_TERMS,
      allowKeepOriginValue: true,
      path: [
        constants.BID_SECTIONS.CONTRACT_TERMS, translations.i18n.BID_DETAILS.LATE_PAYMENT_PENALTY_TERMS
      ],
      groupPath: [
        constants.BID_SECTIONS.CONTRACT_TERMS, translations.i18n.BID_DETAILS.LATE_PAYMENT_PENALTY_TERMS
      ],
      hasDependencies: true,
      dependsOn: [
        constants.CONTRACT_TERMS.LATE_PAYMENT_PENALTY_TERMS_BASIS.label,
        constants.CONTRACT_TERMS.LATE_PAYMENT_PENALTY_TERMS_VALUE.label,
        constants.CONTRACT_TERMS.LATE_PAYMENT_PENALTY_TERMS_FREQUENCY.label
      ],
      description: translations.i18n.TermsDescriptions.CONTRACT.LATE_PAYMENT_PENALTY_TERMS,
      validate: (term: any) => {
        let valid = true;
        delete term.definition.error;

        const basisTerm = term.definition.list[constants.CONTRACT_TERMS.LATE_PAYMENT_PENALTY_TERMS_BASIS.label];
        const valueTerm = term.definition.list[constants.CONTRACT_TERMS.LATE_PAYMENT_PENALTY_TERMS_VALUE.label];
        const frequencyTerm = term.definition.list[constants.CONTRACT_TERMS.LATE_PAYMENT_PENALTY_TERMS_FREQUENCY.label];

        if (valueTerm.definition.processKeepOriginalValue) {
          if (valueTerm.definition.keepOriginalValue
            && basisTerm.value === resources.NewBid.KEEP_ORIGINAL_VALUE
            && frequencyTerm.value === resources.NewBid.KEEP_ORIGINAL_VALUE) {
            return true;
          }
        }

        if (!util.isNotDefined(basisTerm) && !util.isNotDefined(frequencyTerm) && !util.isNotDefined(valueTerm)) {
          if ((basisTerm.value === basisTerm.definition.defaultValue
            && frequencyTerm.value != frequencyTerm.definition.defaultValue)
            || (basisTerm.value != basisTerm.definition.defaultValue
              && (frequencyTerm.value === frequencyTerm.definition.defaultValue || CoreUtils.isEmpty(valueTerm.value)))
          ) {
            term.definition.error = `${term.name} should have all values either filled in or cleared`;
            valid = false;
          }
        }

        return valid;
      }
    },
    {
      name: constants.CONTRACT_TERMS.LATE_PAYMENT_PENALTY_TERMS_BASIS.label,
      path: [
        constants.BID_SECTIONS.CONTRACT_TERMS, translations.i18n.BID_DETAILS.LATE_PAYMENT_PENALTY_TERMS,
        constants.CONTRACT_TERMS.LATE_PAYMENT_PENALTY_TERMS_BASIS.label
      ],
      groupPath: [
        constants.BID_SECTIONS.CONTRACT_TERMS, translations.i18n.BID_DETAILS.LATE_PAYMENT_PENALTY_TERMS
      ],
      numberOfTermsInGroup: 3,
      displayName: '',
      description: translations.i18n.TermsDescriptions.CONTRACT.LATE_PAYMENT_PENALTY_TERMS_BASIS,
      prefix: constants.BIDDING.LATE_PAYMENT_PENALTY_TERMS_BASIS_PREFIX,
      type: 1,
      allowKeepOriginValue: true,
      values: [
        '', '% of total rebates', '% of unpaid rebates', 'Flat Amount'
      ],
      defaultValue: '',
      hasDependencies: true,
      dependsOn: [
        constants.CONTRACT_TERMS.LATE_PAYMENT_PENALTY_TERMS_VALUE.label,
        constants.CONTRACT_TERMS.LATE_PAYMENT_PENALTY_TERMS_FREQUENCY.label,
      ],
      prepareDependencies(term: any): any {
        term.definition.onChange.apply(this, [
          term, true
        ]);
      },
      onChange: (term, initial) => {
        util.actOnTermDependency(term, constants.CONTRACT_TERMS.LATE_PAYMENT_PENALTY_TERMS_VALUE.label,
          (term, valueTerm) => {
            let validateOptions;
            if (!util.isNotDefined(valueTerm) && !util.isNotDefined(term.value)) {
              valueTerm.definition.changeValueFromParent = true;
              if (term.value === '% of total rebates' || term.value === '% of unpaid rebates') {
                valueTerm.value = initial ? valueTerm.value : '';
                delete valueTerm.definition.currency;
                valueTerm.definition.suffix = '%';
                valueTerm.definition.disabled = false;

                validateOptions = {
                  min: 0,
                  max: 100,
                  decimals: 5,
                  step: 0.0001,
                  minDecimals: 2
                };
                valueTerm.definition.validateValue = util.getNumericValidation(validateOptions);
                valueTerm.value = valueTerm.definition.validateValue(valueTerm.value);
                if (valueTerm.definition.processKeepOriginalValue) {
                  disableKeepOriginalValue(valueTerm);
                }
              } else if (term.value === 'Flat Amount') {
                valueTerm.definition.disabled = false;
                valueTerm.value = initial ? valueTerm.value : '';
                delete valueTerm.definition.suffix;
                valueTerm.definition.currency = '$';

                validateOptions = {
                  min: 0.00,
                  decimals: 2
                };
                valueTerm.definition.validateValue = util.getNumericValidation(validateOptions);
                valueTerm.value = valueTerm.definition.validateValue(valueTerm.value);
                if (valueTerm.definition.processKeepOriginalValue) {
                  disableKeepOriginalValue(valueTerm);
                }
              } else if (term.value === '') {
                delete valueTerm.definition.suffix;
                delete valueTerm.definition.currency;
                delete valueTerm.value;
                valueTerm.definition.disabled = true;
                if (valueTerm.definition.processKeepOriginalValue && valueTerm.definition.placeholder) {
                  disableKeepOriginalValue(valueTerm);
                }
              } else if (term.value === resources.NewBid.KEEP_ORIGINAL_VALUE) {
                delete valueTerm.definition.suffix;
                delete valueTerm.value;
                delete valueTerm.definition.currency;
                valueTerm.definition.disabled = true;
                if (valueTerm.definition.processKeepOriginalValue) {
                  enableKeepOriginalValue(valueTerm);
                }
              }
            }
          });

        if (term.definition.processKeepOriginalValue) {
          util.actOnTermDependency(term, constants.CONTRACT_TERMS.LATE_PAYMENT_PENALTY_TERMS_FREQUENCY.label,
            (term, valueTerm) => {
              if (!util.isNotDefined(valueTerm) && !util.isNotDefined(term.value)) {
                if (term.value === '') {
                  if (valueTerm.definition.processKeepOriginalValue) {
                    disableKeepOriginalValue(valueTerm);
                    valueTerm.definition.values = valueTerm.definition.values
                      .filter(val => val !== resources.NewBid.KEEP_ORIGINAL_VALUE);
                    if (!term.definition.changeValueFromChild) {
                      valueTerm.value = valueTerm.definition.defaultValue;
                    }
                  }
                } else if (term.value === resources.NewBid.KEEP_ORIGINAL_VALUE) {
                  if (valueTerm.definition.processKeepOriginalValue) {
                    if (!valueTerm.definition.values.find(val => val === resources.NewBid.KEEP_ORIGINAL_VALUE)) {
                      valueTerm.definition.values.push(resources.NewBid.KEEP_ORIGINAL_VALUE);
                    }
                    valueTerm.value = resources.NewBid.KEEP_ORIGINAL_VALUE;
                    valueTerm.definition.keepOriginalValue = true;
                  }
                } else {
                  if (valueTerm.definition.processKeepOriginalValue) {
                    disableKeepOriginalValue(valueTerm);
                    if (valueTerm.value === resources.NewBid.KEEP_ORIGINAL_VALUE
                      && (term.definition.changeValueFromChild
                        || term.value !== resources.NewBid.KEEP_ORIGINAL_VALUE)) {
                      valueTerm.value = valueTerm.definition.defaultValue;
                    }
                    valueTerm.definition.values = valueTerm.definition.values
                      .filter(val => val !== resources.NewBid.KEEP_ORIGINAL_VALUE);
                  }
                }
              }
            });
        }
      },
      getTermValueForComparison: term => term.value,
      resetValue: util.resetValueDropdown
    },
    {
      name: constants.CONTRACT_TERMS.LATE_PAYMENT_PENALTY_TERMS_VALUE.label,
      path: [
        constants.BID_SECTIONS.CONTRACT_TERMS, translations.i18n.BID_DETAILS.LATE_PAYMENT_PENALTY_TERMS,
        constants.CONTRACT_TERMS.LATE_PAYMENT_PENALTY_TERMS_VALUE.label
      ],
      groupPath: [
        constants.BID_SECTIONS.CONTRACT_TERMS, translations.i18n.BID_DETAILS.LATE_PAYMENT_PENALTY_TERMS
      ],
      numberOfTermsInGroup: 3,
      displayName: '',
      description: translations.i18n.TermsDescriptions.CONTRACT.LATE_PAYMENT_PENALTY_TERMS_VALUE,
      prefix: constants.BIDDING.LATE_PAYMENT_PENALTY_TERMS_VALUE_PREFIX,
      type: 7,
      allowKeepOriginValue: true,
      disabled: true,
      class: 'input-mini',
      hasDependencies: true,
      dependsOn: [
        constants.BIDDING.LATE_PAYMENT_PENALTY_TERMS,
        constants.CONTRACT_TERMS.LATE_PAYMENT_PENALTY_TERMS_BASIS.label,
        constants.CONTRACT_TERMS.LATE_PAYMENT_PENALTY_TERMS_FREQUENCY.label,
      ],
      validateValue: util.getNumericValidation({
        decimals: 2
      }),
      getTermValueForComparison: term => {
        let prefix = '';
        let suffix = '';

        if (!util.isNotDefined(term.definition.suffix) && !CoreUtils.isEmpty(term.definition.suffix)) {
          suffix = ` ${term.definition.suffix}`;
        }

        if (!util.isNotDefined(term.definition.currency) && !CoreUtils.isEmpty(term.definition.currency)) {
          prefix = `${term.definition.currency} `;
        }

        return (!util.isNotDefined(term.value) || !CoreUtils.isEmpty(term.value)) ? prefix + term.value + suffix : '';
      },
      validate: (term) => {
        delete term.definition.error;
        return util.actOnTermDependency(term, constants.CONTRACT_TERMS.LATE_PAYMENT_PENALTY_TERMS_BASIS.label,
          (term, depTerm) => {
            if (term.value && !depTerm.value) {
              depTerm.definition.error = `${depTerm.definition.displayName} Please enter value`;
              return false;
            } else {
              delete depTerm.definition.error;
              return true;
            }
          });
      },
      onChange: term => {
        util.actOnTermDependency(term, constants.BIDDING.LATE_PAYMENT_PENALTY_TERMS, (term, depTerm) => {
          delete depTerm.definition.error;
        });
      },
      onFocus: (event, term) => {
        if (term.definition.processKeepOriginalValue && term.definition.keepOriginalValue) {
          util.actOnTermDependency(term, constants.CONTRACT_TERMS.LATE_PAYMENT_PENALTY_TERMS_BASIS.label,
            (term, valueTerm) => {
              valueTerm.value = '';
            });

          util.actOnTermDependency(term, constants.CONTRACT_TERMS.LATE_PAYMENT_PENALTY_TERMS_FREQUENCY.label,
            (term, valueTerm) => {
              valueTerm.value = '';
              valueTerm.definition.values = valueTerm.definition.values
                .filter(val => val !== resources.NewBid.KEEP_ORIGINAL_VALUE);
            });
        }
      }
    },
    {
      name: constants.CONTRACT_TERMS.LATE_PAYMENT_PENALTY_TERMS_FREQUENCY.label,
      path: [
        constants.BID_SECTIONS.CONTRACT_TERMS, translations.i18n.BID_DETAILS.LATE_PAYMENT_PENALTY_TERMS,
        constants.CONTRACT_TERMS.LATE_PAYMENT_PENALTY_TERMS_FREQUENCY.label
      ],
      groupPath: [
        constants.BID_SECTIONS.CONTRACT_TERMS, translations.i18n.BID_DETAILS.LATE_PAYMENT_PENALTY_TERMS
      ],
      numberOfTermsInGroup: 3,
      displayName: '',
      description: translations.i18n.TermsDescriptions.CONTRACT.LATE_PAYMENT_PENALTY_TERMS_FREQUENCY,
      prefix: constants.BIDDING.LATE_PAYMENT_PENALTY_TERMS_FREQUENCY_PREFIX,
      type: 1,
      allowKeepOriginValue: true,
      values: [
        '', 'Daily', 'Monthly', 'Quarterly'
      ],
      defaultValue: '',
      getTermValueForComparison: term => term.value,
      hasDependencies: true,
      dependsOn: [
        constants.BIDDING.LATE_PAYMENT_PENALTY_TERMS,
        constants.CONTRACT_TERMS.LATE_PAYMENT_PENALTY_TERMS_VALUE.label,
        constants.CONTRACT_TERMS.LATE_PAYMENT_PENALTY_TERMS_BASIS.label
      ],
      onChange: term => {
        util.actOnTermDependency(term, constants.BIDDING.LATE_PAYMENT_PENALTY_TERMS, (term, depTerm) => {
          delete depTerm.definition.error;
        });
        if (!term.definition.changeValueFromParent && term.value !== resources.NewBid.KEEP_ORIGINAL_VALUE) {
          util.actOnTermDependency(term, constants.CONTRACT_TERMS.LATE_PAYMENT_PENALTY_TERMS_BASIS.label,
            (term, depTerm) => {
              if (depTerm.value === resources.NewBid.KEEP_ORIGINAL_VALUE) {
                depTerm.value = '';
                depTerm.definition.changeValueFromChild = true;
              }
            });
        }
      }
    },
    {
      name: constants.BIDDING.PHARMA_AUDIT_TERMS,
      path: [constants.BID_SECTIONS.CONTRACT_TERMS, translations.i18n.BID_DETAILS.AUDIT_TERMS],
      description: translations.i18n.TermsDescriptions.CONTRACT.PHARMA_AUDIT_TERMS
    },
    {
      name: constants.CONTRACT_TERMS.PHARMA_AUDIT_TERMS_AUDIT_FREQUENCY.label,
      path: [
        constants.BID_SECTIONS.CONTRACT_TERMS, constants.BIDDING.PHARMA_AUDIT_TERMS,
        constants.CONTRACT_TERMS.PHARMA_AUDIT_TERMS_AUDIT_FREQUENCY.label
      ],
      groupPath: [
        constants.BID_SECTIONS.CONTRACT_TERMS, constants.BIDDING.PHARMA_AUDIT_TERMS
      ],
      numberOfTermsInGroup: 3,
      displayName: '',
      description: translations.i18n.TermsDescriptions.CONTRACT.PHARMA_AUDIT_TERMS_AUDIT_FREQUENCY,
      prefix: constants.BIDDING.AUDIT_FREQUENCY,
      type: 1,
      allowKeepOriginValue: true,
      values: [
        '', 'Annually', '1 per Contract Term', '1 every 2 years', '1 every 3 years'
      ],
      defaultValue: '',
      hasDependencies: true,
      dependsOn: [
        constants.CONTRACT_TERMS.PHARMA_AUDIT_TERMS_PRIOR_NOTICE_REQUIRED.label
      ],
      onChange: term => {
        util.actOnTermDependency(term, constants.CONTRACT_TERMS.PHARMA_AUDIT_TERMS_PRIOR_NOTICE_REQUIRED.label,
          (term, depTerm) => {
            delete depTerm.definition.error;

            if (!util.isNotDefined(depTerm) && !util.isNotDefined(term.value)) {
              if (term.value === '') {
                delete depTerm.value;
                depTerm.definition.disabled = true;
                if (depTerm.definition.processKeepOriginalValue && depTerm.definition.placeholder) {
                  disableKeepOriginalValue(depTerm);
                }
              } else if (term.value === resources.NewBid.KEEP_ORIGINAL_VALUE) {
                delete depTerm.value;
                depTerm.definition.disabled = true;
                if (depTerm.definition.processKeepOriginalValue) {
                  enableKeepOriginalValue(depTerm);
                }
              } else {
                depTerm.definition.disabled = false;
                if (depTerm.definition.processKeepOriginalValue) {
                  disableKeepOriginalValue(depTerm);
                }
                const validateOptions = {
                  min: 1,
                  decimals: 0
                };
                depTerm.definition.validateValue = util.getNumericValidation(validateOptions);
              }
            }
          });
      },
      getTermValueForComparison: term => term.value,
      resetValue: util.resetValueDropdown
    },
    {
      name: constants.CONTRACT_TERMS.PHARMA_AUDIT_TERMS_PRIOR_NOTICE_REQUIRED.label,
      path: [
        constants.BID_SECTIONS.CONTRACT_TERMS, constants.BIDDING.PHARMA_AUDIT_TERMS,
        constants.CONTRACT_TERMS.PHARMA_AUDIT_TERMS_PRIOR_NOTICE_REQUIRED.label
      ],
      groupPath: [
        constants.BID_SECTIONS.CONTRACT_TERMS, constants.BIDDING.PHARMA_AUDIT_TERMS
      ],
      numberOfTermsInGroup: 3,
      displayName: '',
      description: translations.i18n.TermsDescriptions.CONTRACT.PHARMA_AUDIT_TERMS_PRIOR_NOTICE_REQUIRED,
      prefix: constants.BIDDING.PRIOR_NOTICE_REQUIRED,
      type: 7,
      allowKeepOriginValue: true,
      disabled: true,
      suffix: 'Days',
      class: 'input-mini days',
      hasDependencies: true,
      dependsOn: [
        constants.CONTRACT_TERMS.PHARMA_AUDIT_TERMS_AUDIT_FREQUENCY.label
      ],
      validate: (term) => {
        delete term.definition.error;
        return util.actOnTermDependency(term, constants.CONTRACT_TERMS.PHARMA_AUDIT_TERMS_AUDIT_FREQUENCY.label,
          (term, depTerm) => {
            if (term.value && !depTerm.value) {
              depTerm.definition.error = `${depTerm.definition.displayName} Please enter value`;
              return false;
            } else {
              delete depTerm.definition.error;
            }

            const isValid = (term.definition.disabled
              || (!term.definition.disabled && CoreUtils.isDefined(term.value) && term.value !== '')
              || (term.value === '' && depTerm.value === ''));
            if (!isValid) {
              term.definition.error = `${term.definition.displayName} Please enter value`;
            }
            return isValid;
          });
      },
      validateValue: util.getNumericValidation({
        min: 1,
        step: 1
      }),
      getTermValueForComparison: term => (!util.isNotDefined(term.value) || !CoreUtils.isEmpty(term.value))
        ? `${term.value} ${term.definition.suffix}`
        : '',
      getValueToSave: util.getValueToSaveForInputField,
      prepare: (term, settings) => {
        util.actOnTermDependency(term, constants.CONTRACT_TERMS.PHARMA_AUDIT_TERMS_AUDIT_FREQUENCY.label,
          (term, depTerm) => {
            if (depTerm.value === '') {
              term.definition.disabled = true;
              depTerm.definition.onChange(depTerm);
            }

          });
      },
      onFocus: (event, term) => {
        if (term.definition.processKeepOriginalValue && term.definition.keepOriginalValue) {
          util.actOnTermDependency(term, constants.CONTRACT_TERMS.PHARMA_AUDIT_TERMS_AUDIT_FREQUENCY.label,
            (term, valueTerm) => {
              valueTerm.value = '';
            });
        }
      },
      resetValue: util.resetValueInput
    },
    // QU-3927
    {
      name: constants.CONTRACT_TERMS.PHARMA_AUDIT_TERMS_LOOK_BACK.label,
      path: [
        constants.BID_SECTIONS.CONTRACT_TERMS, constants.BIDDING.PHARMA_AUDIT_TERMS,
        constants.CONTRACT_TERMS.PHARMA_AUDIT_TERMS_LOOK_BACK.label
      ],
      groupPath: [
        constants.BID_SECTIONS.CONTRACT_TERMS, constants.BIDDING.PHARMA_AUDIT_TERMS
      ],
      numberOfTermsInGroup: 3,
      displayName: '',
      description: translations.i18n.TermsDescriptions.CONTRACT.PHARMA_AUDIT_TERMS_LOOK_BACK,
      prefix: constants.BIDDING.LOOK_BACK,
      type: 7,
      allowKeepOriginValue: true,
      suffix: 'Months',
      class: 'input-mini days',
      validateValue: util.getNumericValidation({
        min: 1,
        decimals: 0,
        // QU-3927 - X months  (X is a 3 digit text box that can only have #s in it)
        max: 999
      }),
      getTermValueForComparison: term => (!util.isNotDefined(term.value) && !CoreUtils.isEmpty(term.value))
        ? `${term.value} ${term.definition.suffix}`
        : '',
      getValueToSave: util.getValueToSaveForInputField,
      resetValue: util.resetValueInput
    },
    {
      name: constants.BIDDING.NO_FAULT_TERMINATION_CLAUSE,
      path: [constants.BID_SECTIONS.CONTRACT_TERMS, translations.i18n.BID_DETAILS.NO_FAULT_TERMINATION_CLAUSE],
      description: translations.i18n.TermsDescriptions.CONTRACT.NO_FAULT_TERMINATION_CLAUSE
    },
    {
      name: constants.CONTRACT_TERMS.NO_FAULT_TERMINATION_CLAUSE_WHO_MAY_INVOKE.label,
      path: [
        constants.BID_SECTIONS.CONTRACT_TERMS, constants.BIDDING.NO_FAULT_TERMINATION_CLAUSE,
        constants.CONTRACT_TERMS.NO_FAULT_TERMINATION_CLAUSE_WHO_MAY_INVOKE.label
      ],
      groupPath: [
        constants.BID_SECTIONS.CONTRACT_TERMS, constants.BIDDING.NO_FAULT_TERMINATION_CLAUSE
      ],
      numberOfTermsInGroup: 2,
      displayName: '',
      description: translations.i18n.TermsDescriptions.CONTRACT.NO_FAULT_TERMINATION_CLAUSE_WHO_MAY_INVOKE,
      prefix: constants.BIDDING.WHO_MAY_INVOKE,
      type: 9,
      allowKeepOriginValue: true,
      values: [
        {
          value: '',
          displayValue: ''
        }, {
          value: constants.BIDDING.WHO_MAY_INVOKE_NEITHER_PARTY_VALUE,
          displayValue: constants.BIDDING.WHO_MAY_INVOKE_NEITHER_PARTY_VALUE
        }, {
          value: 'Payer',
          displayValue: 'Payer'
        }, {
          value: resources.GENERAL.PHARMA,
          displayValue: resources.GENERAL.PHARMA
        }, {
          value: 'Both',
          displayValue: 'Both'
        }
      ],
      defaultValue: '',
      hasDynamicValues: true,
      prepare: (term, settings) => {
        if (settings.payerCompanyName) {
          const payerValue = term.definition.values.find(val => val.value === 'Payer');
          payerValue.displayValue = settings.payerCompanyName;
        }

        const manufacturerValue = term.definition.values.find(val => val.value === resources.GENERAL.PHARMA);

        if (settings.manufacturerCompanyName) {
          manufacturerValue.displayValue = settings.manufacturerCompanyName;
        } else {
          manufacturerValue.displayValue = manufacturerValue.value;
        }

        term.definition.onChange(term);
      },
      hasDependencies: true,
      dependsOn: [
        constants.CONTRACT_TERMS.NO_FAULT_TERMINATION_CLAUSE_PRIOR_NOTICE_REQUIRED.label
      ],
      onChange: term => {
        util.actOnTermDependency(term,
          constants.CONTRACT_TERMS.NO_FAULT_TERMINATION_CLAUSE_PRIOR_NOTICE_REQUIRED.label,
          (term, depTerm) => {
            delete depTerm.definition.error;

            if (!util.isNotDefined(depTerm) && !util.isNotDefined(term.value)) {
              if (term.value === '' || term.value === constants.BIDDING.WHO_MAY_INVOKE_NEITHER_PARTY_VALUE) {
                delete depTerm.value;
                depTerm.definition.disabled = true;
                if (depTerm.definition.processKeepOriginalValue && depTerm.definition.placeholder) {
                  disableKeepOriginalValue(depTerm);
                }
              } else if (term.value === resources.NewBid.KEEP_ORIGINAL_VALUE) {
                if (depTerm.definition.processKeepOriginalValue) {
                  enableKeepOriginalValue(depTerm);
                  depTerm.definition.resetValue(depTerm);
                }
              } else {
                depTerm.definition.disabled = false;
                if (depTerm.definition.processKeepOriginalValue) {
                  disableKeepOriginalValue(depTerm);
                }
                const validateOptions = {
                  min: 1,
                  decimals: 0
                };
                depTerm.definition.validateValue = util.getNumericValidation(validateOptions);
              }
            }
          });
      },
      getTermValueForComparison: (term: any) => {
        let value = '';
        const termTemp = term.definition.values.find(val => val.value === termTemp.value);

        if (termTemp) {
          value = termTemp.displayValue;
        }

        return value;
      },
      resetValue: util.resetValueDropdown
    },
    {
      name: constants.CONTRACT_TERMS.NO_FAULT_TERMINATION_CLAUSE_PRIOR_NOTICE_REQUIRED.label,
      path: [
        constants.BID_SECTIONS.CONTRACT_TERMS, constants.BIDDING.NO_FAULT_TERMINATION_CLAUSE,
        constants.CONTRACT_TERMS.NO_FAULT_TERMINATION_CLAUSE_PRIOR_NOTICE_REQUIRED.label
      ],
      groupPath: [
        constants.BID_SECTIONS.CONTRACT_TERMS, constants.BIDDING.NO_FAULT_TERMINATION_CLAUSE
      ],
      numberOfTermsInGroup: 2,
      displayName: '',
      description: translations.i18n.TermsDescriptions.CONTRACT.NO_FAULT_TERMINATION_CLAUSE_PRIOR_NOTICE_REQUIRED,
      prefix: constants.BIDDING.PRIOR_NOTICE_REQUIRED,
      type: 7,
      allowKeepOriginValue: true,
      disabled: true,
      suffix: 'Days',
      class: 'input-mini days',
      validate: term => {
        delete term.definition.error;
        return util.actOnTermDependency(term,
          constants.CONTRACT_TERMS.NO_FAULT_TERMINATION_CLAUSE_WHO_MAY_INVOKE.label,
          (term, depTerm) => {
            if (term.definition.processKeepOriginalValue && term.definition.keepOriginalValue) {
              return true;
            }
            if (term.value && !depTerm.value) {
              depTerm.definition.error = `${depTerm.definition.displayName} Please enter value`;
              return false;
            } else {
              delete depTerm.definition.error;
            }
            const isValid = term.definition.disabled
              || (!term.definition.disabled && CoreUtils.isDefined(term.value) && term.value !== '');
            if (!isValid) {
              term.definition.error = `${term.definition.displayName} Please enter value`;
            }
            return isValid;
          });
      },
      validateValue: util.getNumericValidation({
        min: 1,
        step: 1
      }),
      getTermValueForComparison: term => (!util.isNotDefined(term.value) || !CoreUtils.isEmpty(term.value))
        ? `${term.value} ${term.definition.suffix}`
        : '',
      getValueToSave: util.getValueToSaveForInputField,
      hasDependencies: true,
      dependsOn: [
        constants.CONTRACT_TERMS.NO_FAULT_TERMINATION_CLAUSE_WHO_MAY_INVOKE.label
      ],
      onFocus: (event, term) => {
        if (term.definition.processKeepOriginalValue && term.definition.keepOriginalValue) {
          util.actOnTermDependency(term, constants.CONTRACT_TERMS.NO_FAULT_TERMINATION_CLAUSE_WHO_MAY_INVOKE.label,
            (term, valueTerm) => {
              valueTerm.value = '';
            });
        }
      },
      resetValue: util.resetValueInput
    },
    {
      name: constants.BIDDING.FEES_SECTION
    },
    {
      name: constants.CONTRACT_TERMS.ADMINISTRATION_FEE.label,
      displayName: constants.CONTRACT_TERMS.ADMINISTRATION_FEE.label,
      suffix: ' %',
      path: [
        constants.BID_SECTIONS.CONTRACT_TERMS, translations.i18n.BID_DETAILS.FEES_SECTION,
        constants.CONTRACT_TERMS.ADMINISTRATION_FEE.label
      ],
      groupPath: [
        constants.BID_SECTIONS.CONTRACT_TERMS, translations.i18n.BID_DETAILS.FEES_SECTION
      ],
      numberOfTermsInGroup: 1,
      type: 7,
      allowKeepOriginValue: true,
      defaultValue: '',
      defaultValuePath: '',
      resetDrugList: true,
      description: translations.i18n.TermsDescriptions.CONTRACT.ADMINISTRATION_FEE,
      class: 'input-mini',
      validateValue: util.getNumericValidation({
        min: 0,
        max: 100,
        decimals: 5,
        minDecimals: 2,
        step: 0.0001
      }),
      getValueToSave: term => {
        if (term.definition.processKeepOriginalValue && term.definition.keepOriginalValue) {
          return resources.NewBid.KEEP_ORIGINAL_VALUE;
        }

        return term.value;
      },
      resetValue: util.resetValueInput
    }
  ];
}
