import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { marketBasketTemplateStateConstants } from '../../constants';

@Component({
  selector: 'mb-market-basket-template-modal-header',
  templateUrl: './market-basket-template-modal-header.component.html',
  styleUrls: ['./market-basket-template-modal-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MarketBasketTemplateModalHeaderComponent {

  @Input()
  public title: string;

  @Input()
  public isArchived: boolean = false;

  public readonly stateConstants = marketBasketTemplateStateConstants;

}
