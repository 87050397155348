import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Inject, OnInit } from '@angular/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { BlockingMessage, SortDirection, SvgIconName } from '@qv-common/enums';
import { resources } from '@qv-common/static';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { DrugAddModalData } from '@qv-bid/components/shared/drug-add-modal/interfaces';
import { BidEventBusService, DrugService } from '@qv-bid/services';
import { DrugAddModalMeta } from '@qv-bid/components/shared/drug-add-modal/models';
import { FlatTreeControl } from '@angular/cdk/tree';
import { SelectionModel } from '@angular/cdk/collections';
import { throwError } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';
import { NdcValue } from '@qv-term/entities';
import { DrugDaoService } from '@qv-bid/services/dao';
import { DrugListSortKey } from '@qv-bid/components/shared/drug-add-modal/enums/drug-list-sort-key';
import { HttpErrorResponse } from '@angular/common/http';
import { FormControl } from '@angular/forms';
import { ArrayUtils } from '@qv-common/utils';

import { SnackBarService } from 'quantuvis-angular-common/snack-bar';
import { ContractedBusiness } from 'quantuvis-core-entities';

@Component({
  selector: 'qv-drug-add-modal',
  templateUrl: './drug-add-modal.component.html',
  styleUrls: ['./drug-add-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DrugAddModalComponent implements OnInit {
  @BlockUI('drug-add')
  public blockUI: NgBlockUI;

  public cbControl: FormControl;
  public cbItems: ContractedBusiness[];
  public drugsAdded: EventEmitter<void> = new EventEmitter<void>();

  public readonly drugsListHeaderConfig = [
    { sortKey: DrugListSortKey.DRUG, columnName: 'Drug', modifier: 'drug', isChevronVisible: true },
    { sortKey: DrugListSortKey.ELIGIBLE, columnName: 'Rebate Eligible', modifier: 'eligible', isChevronVisible: true },
    { sortKey: DrugListSortKey.PHARMA, columnName: 'Pharma', modifier: 'pharma', isChevronVisible: true },
    { sortKey: DrugListSortKey.DRUG_CLASS, columnName: 'Drug Class', modifier: 'drug-class', isChevronVisible: true },
    { sortKey: DrugListSortKey.ACTIVE, columnName: 'Active', modifier: 'active', isChevronVisible: true },
    { sortKey: DrugListSortKey.RX_OTC, columnName: 'Rx/OTC', modifier: 'rx-otc', isChevronVisible: true },
    { sortKey: DrugListSortKey.TYPE_CODE, columnName: 'Name', modifier: 'type-code', isChevronVisible: true },
  ];

  public readonly resources = resources;
  public readonly svgIconName = SvgIconName;

  public treeFlattener = new MatTreeFlattener<DrugAddModalMeta, DrugAddModalMeta>(
    this.transformer.bind(this),
    this.getLevel.bind(this),
    this.isExpandable.bind(this),
    this.getChildren.bind(this)
  );
  public treeControl = new FlatTreeControl<DrugAddModalMeta>(this.getLevel.bind(this), this.isExpandable.bind(this));
  public dataSource = new MatTreeFlatDataSource<DrugAddModalMeta, DrugAddModalMeta>(
    this.treeControl,
    this.treeFlattener
  );
  public treeSelection = new SelectionModel<DrugAddModalMeta>(true);

  private defaultSort: Sort = { active: DrugListSortKey.DRUG, direction: SortDirection.ASC };

  private readonly colorModifierAtNdc = 'royal-purple';
  private readonly colorModifierNone = 'none';
  private readonly colorModifierPrimary = 'eastern-blue';
  private readonly colorModifierSecondary = 'scarlet';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DrugAddModalData,
    private dialogRef: MatDialogRef<DrugAddModalComponent>,
    private drugService: DrugService,
    private drugDaoService: DrugDaoService,
    private snackBarService: SnackBarService,
    private changeDetectorRef: ChangeDetectorRef,
    private bidEventBusService: BidEventBusService
  ) {}

  public ngOnInit(): void {
    this.initCbSelect();
    this.loadPharmaDrugs();
  }

  public isSomeChildSelected(node: DrugAddModalMeta): boolean {
    const hasSelectedChild = this.treeControl.getDescendants(node)
      .some(child => this.treeSelection.isSelected(child));

    return hasSelectedChild && !this.isAllChildrenSelected(node);
  }

  public isAllChildrenSelected(node: DrugAddModalMeta): boolean {
    return this.treeControl.getDescendants(node).every(child => this.treeSelection.isSelected(child));
  }

  public isAllChildrenDisabled(node: DrugAddModalMeta): boolean {
    return this.treeControl.getDescendants(node).every(child => child.isPresentInBid);
  }

  public onGroupSelectionChange(node: DrugAddModalMeta): void {
    this.treeSelection.toggle(node);
    const descendants = this.treeControl.getDescendants(node)
      .filter((meta: DrugAddModalMeta) => !meta.isPresentInBid);

    this.treeSelection.isSelected(node)
      ? this.treeSelection.select(...descendants)
      : this.treeSelection.deselect(...descendants);
  }

  public onChildSelectionChange(node: DrugAddModalMeta): void {
    this.treeSelection.toggle(node);
    const parent = this.getParentNode(node);

    this.isAllChildrenSelected(parent)
      ? this.treeSelection.select(parent)
      : this.treeSelection.deselect(parent);
  }

  public onCbChange(cb: ContractedBusiness): void {
    this.treeSelection.clear();
    this.cbControl.setValue(cb);
    this.dataSource.data = this.drugService.prepareDrugGroups(this.getCurrentAddedDrugs());
    this.sortBy();
  }

  public onSubmit(): void {
    const selectedIds = this.treeSelection.selected
      .filter((node: DrugAddModalMeta) => !node.isGroup)
      .map((node: DrugAddModalMeta) => node.id);

    this.blockUI.start(BlockingMessage.SAVING);
    this.snackBarService.start();

    const { bidVersionId, isBidInternal } = this.data;
    const cbId = this.getCurrentCbId() || this.data.cbs[0].id;
    const inAllCbs = !this.getCurrentCbId();

    this.drugDaoService.addDrugsToContractedBusiness(bidVersionId, cbId, selectedIds, inAllCbs, isBidInternal).pipe(
      tap(() => {
        this.snackBarService.finish();
        this.dialogRef.close();
        this.drugsAdded.emit();
      }),
      catchError((error: HttpErrorResponse) => {
        this.bidEventBusService.updateBidFailed.emit(error);
        this.snackBarService.error();

        return throwError(error);
      }),
      finalize(() => this.blockUI.stop())
    ).subscribe();
  }

  public sortBy(sortConfig: Sort = this.defaultSort): void {
    sortConfig = sortConfig.direction ? sortConfig : this.defaultSort;

    const sortedData = this.dataSource.data.sort(
      (nodeA: DrugAddModalMeta, nodeB: DrugAddModalMeta) =>
        this.compareValues(nodeA, nodeB, sortConfig)
    );

    this.dataSource.data.forEach((groupNode: DrugAddModalMeta) => {
      groupNode.children = groupNode.children.sort((nodeA: DrugAddModalMeta, nodeB: DrugAddModalMeta) =>
        this.compareValues(nodeA, nodeB, sortConfig));
    });

    this.dataSource.data = sortedData;
  }

  public isGroup(index: number, node: DrugAddModalMeta): boolean {
    return node.isGroup;
  }

  public isRootEven(meta: DrugAddModalMeta): boolean {
    if (!meta.isGroup) {
      meta = this.getParentNode(meta);
    }

    const groupsList = [];
    this.treeControl.dataNodes
      .filter((node: DrugAddModalMeta) => node.isGroup)
      .forEach((node: DrugAddModalMeta) => groupsList.push(node));

    return (groupsList.indexOf(meta) + 1) % 2 === 0;
  }

  public getColorModifier(
    value: NdcValue<any>,
    elClass: string,
    additionalModifier?: string,
    isNdcOnly?: boolean
  ): string {
    const easternBlueColorList = ['Rx', 'T', 'YES', true];
    let modifier;

    if (value.isNdc) {
      modifier = this.colorModifierAtNdc;
    } else if (isNdcOnly) {
      modifier = this.colorModifierNone;
    } else {
      modifier = value.value && easternBlueColorList.includes(value.value)
        ? this.colorModifierPrimary
        : this.colorModifierSecondary;
    }

    return additionalModifier
      ? `${elClass} ${elClass}--${modifier} ${elClass}--${additionalModifier}`
      : `${elClass} ${elClass}--${modifier}`;
  }

  private getParentNode(node: DrugAddModalMeta): DrugAddModalMeta | null {
    const level = this.getLevel(node);

    if (level < 1) {
      return null;
    }

    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];

      if (this.getLevel(currentNode) < level) {
        return currentNode;
      }
    }
    return null;
  }

  private getLevel(node: DrugAddModalMeta): number {
    return node.level;
  }

  private isExpandable(node: DrugAddModalMeta): boolean {
    return node.isGroup;
  }

  private getChildren(node: DrugAddModalMeta): DrugAddModalMeta[] {
    return node.isGroup ? node.children : [];
  }

  private transformer(node: DrugAddModalMeta, level: number): DrugAddModalMeta {
    node.level = level;

    if (node.isPresentInBid) {
      this.treeSelection.select(node);
    }

    return node;
  }

  private initCbSelect(): void {
    this.cbItems = [new ContractedBusiness(null, 'Entire RFP (all Contracted Business)'), ...this.data.cbs];
    this.cbControl = new FormControl(this.cbItems[0]);
  }

  private loadPharmaDrugs(): void {
    this.blockUI.start(BlockingMessage.LOADING);

    this.drugService.getDrugAddData(this.data.pharmaId, this.getCurrentAddedDrugs())
      .pipe(finalize(() => this.blockUI.stop()))
      .subscribe((drugList: DrugAddModalMeta[]) => {
        this.dataSource.data = drugList;
        this.changeDetectorRef.markForCheck();
        this.sortBy();
      });
  }

  private getCurrentCbId(): number {
    return this.cbControl && this.cbControl.value.id;
  }

  private getCurrentAddedDrugs(): number[] {
    const cbId = this.getCurrentCbId();

    return cbId ? this.data.alreadyAddedDrugs.get(cbId) : this.getDrugsPresentInAllCbs();
  }

  private getDrugsPresentInAllCbs(): number[] {
    const addedDrugsForAllCbs = Array.from(this.data.alreadyAddedDrugs.values());
    const drugsPresentInAllCbs = addedDrugsForAllCbs.reduce(
      (accumulator: number[], current: number[]) =>
        [...accumulator, ...current.filter((id: number) => this.isDrugPresentInAllCbs(id, addedDrugsForAllCbs))],
      []
    );

    return ArrayUtils.union(drugsPresentInAllCbs);
  }

  private isDrugPresentInAllCbs(drugId: number, addedDrugsForAllCbs: number[][]): boolean {
    return addedDrugsForAllCbs.every((drugIds: number[]) => drugIds.includes(drugId));
  }

  private compareValues(nodeA: DrugAddModalMeta, nodeB: DrugAddModalMeta, sortConfig: Sort): number {
    const valueA = this.getValueByNode(nodeA, sortConfig);
    const valueB = this.getValueByNode(nodeB, sortConfig);
    const compareResult = sortConfig.direction === SortDirection.ASC
      ? valueA.localeCompare(valueB)
      : valueB.localeCompare(valueA);

    if (compareResult !== 0 || compareResult === 0 && sortConfig.active === DrugListSortKey.DRUG) {
      return compareResult;
    } else {
      return this.compareValues(nodeA, nodeB, { active: DrugListSortKey.DRUG, direction: SortDirection.ASC });
    }
  }

  private getValueByNode(node: DrugAddModalMeta, sortConfig: Sort): string {
    switch (sortConfig.active) {
      case DrugListSortKey.DRUG:
        return node.getName();
      case DrugListSortKey.ELIGIBLE:
        return node.getEligibleText();
      case DrugListSortKey.PHARMA:
        return node.pharma;
      case DrugListSortKey.DRUG_CLASS:
        return node.drugClass.value;
      case DrugListSortKey.ACTIVE:
        return node.getActiveText();
      case DrugListSortKey.RX_OTC:
        return node.rxOTCIndicatorText();
      case DrugListSortKey.TYPE_CODE:
        return node.getNameTypeCodeText();
    }
  }
}
