import { Injectable } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Summary } from '@qv-bid/entities';
import { constants, resources } from '@qv-common/static';
import { TermName } from '@qv-term/enums';
import { BindingBidType } from '@qv-term/enums/options';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { TermTemplateStorageService } from '@qv-term/services';
import { SummaryDaoService } from '@qv-bid/services/dao';
import { ContractEndDateTerm, ContractStartDateTerm, RfpDueDateTerm } from '@qv-term/models/summary';
import { ValidationUtils } from '@qv-common/utils';
import { GeneralModalConfig, GeneralModalData, ModalService, ModalSize } from 'quantuvis-angular-common/modal';
import { map } from 'rxjs/operators';

@Injectable()
export class SummaryFormService {
  public isFormReady$ = new BehaviorSubject(false);
  public summaryFormGroup: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private termTemplateStorage: TermTemplateStorageService,
    private summaryDaoService: SummaryDaoService,
    private modalService: ModalService
  ) {}

  public buildSummaryForm(summary: Summary): void {
    const rftDurDate = this.termTemplateStorage.getTermTemplate(TermName.RFP_DUE_DATE) as RfpDueDateTerm;
    const startDate = this.termTemplateStorage.getTermTemplate(TermName.CONTRACT_START_DATE) as ContractStartDateTerm;
    const endDate = this.termTemplateStorage.getTermTemplate(TermName.CONTRACT_END_DATE) as ContractEndDateTerm;
    const bindingBidType = summary.bindingBid ? BindingBidType.BINDING : BindingBidType.NON_BINDING;

    this.summaryFormGroup = this.formBuilder.group({
      [TermName.RFP_TITLE]: new FormControl(summary.rfpTitle, {
        updateOn: 'blur',
        validators: [
          Validators.required.bind(this),
          ValidationUtils.notBlankString.bind(ValidationUtils),
          Validators.maxLength(constants.RFP_TITLE_MAX_LENGTH)
        ]
      }),
      [TermName.RFP_DUE_DATE]: new FormControl(summary.rfpDueDate, {
        updateOn: 'blur',
        validators: [
          rftDurDate.validatorInvalidFormat(constants.SUMMARY_TERMS.RFP_DUE_DATE.label),
          rftDurDate.validatorEmptyDate(constants.SUMMARY_TERMS.RFP_DUE_DATE.label),
        ]
      }),
      [TermName.BINDING_BID]: new FormControl(BindingBidType.getValue(bindingBidType), {
        asyncValidators: this.missingLegalAttestationValidator()
      }),
      [TermName.CONTRACT_START_DATE]: new FormControl(summary.contractStartDate, {
        updateOn: 'blur',
        validators: [
          startDate.validatorInvalidFormat(constants.SUMMARY_TERMS.CONTRACT_START_DATE.label),
          startDate.validatorEmptyDate(constants.SUMMARY_TERMS.CONTRACT_START_DATE.label),
          startDate.validatorContractEndDateGreaterStartDate(TermName.CONTRACT_START_DATE)
        ]
      }),
      [TermName.CONTRACT_START_DATE_LOCK]: new FormControl(summary.contractStartDateLock),
      [TermName.CONTRACT_END_DATE]: new FormControl(summary.contractEndDate, {
        updateOn: 'blur',
        validators: [
          endDate.validatorInvalidFormat(constants.SUMMARY_TERMS.CONTRACT_END_DATE.label),
          endDate.validatorEmptyDate(constants.SUMMARY_TERMS.CONTRACT_END_DATE.label),
          endDate.validatorContractEndDateGreaterStartDate(TermName.CONTRACT_END_DATE)
        ]
      }),
      [TermName.CONTRACT_END_DATE_LOCK]: new FormControl(summary.contractEndDateLock),
      [TermName.CONTRACT_EVERGREEN]: new FormControl(summary.contractEvergreen),
      [TermName.getTermNameLock(TermName.CONTRACT_EVERGREEN)]: new FormControl(summary.contractEvergreenLock),
    });

    this.isFormReady$.next(true);
  }

  public updateSummaryForm(summary: Summary): void {
    if (!this.summaryFormGroup) return;

    const bindingBidType = summary.bindingBid ? BindingBidType.BINDING : BindingBidType.NON_BINDING;

    this.summaryFormGroup.patchValue({
      [TermName.RFP_TITLE]: summary.rfpTitle,
      [TermName.RFP_DUE_DATE]: summary.rfpDueDate,
      [TermName.BINDING_BID]: BindingBidType.getValue(bindingBidType),
      [TermName.CONTRACT_START_DATE]: summary.contractStartDate,
      [TermName.CONTRACT_START_DATE_LOCK]: summary.contractStartDateLock,
      [TermName.CONTRACT_END_DATE]: summary.contractEndDate,
      [TermName.CONTRACT_END_DATE_LOCK]: summary.contractEndDateLock,
      [TermName.CONTRACT_EVERGREEN]: summary.contractEvergreen,
      [TermName.getTermNameLock(TermName.CONTRACT_EVERGREEN)]: summary.contractEvergreenLock,
    }, { emitEvent: false });
  }

  public missingLegalAttestationValidator(): (control: AbstractControl) => Observable<null> {
    return (control: AbstractControl): Observable<null> => {
      if (!control.pristine && control.value && control.value.id === BindingBidType.BINDING) {
        return this.summaryDaoService
          .hasLegalAttestation()
          .pipe(
            map((hasLegalAttestation: boolean) => {
              if (hasLegalAttestation) {
                control.markAsPristine();
                control.updateValueAndValidity();
              } else {
                this.openMissingLegalAttestationModal(control);
              }

              return null;
            })
          );
      }

      return of(null);
    };
  }

  private openMissingLegalAttestationModal(control: AbstractControl): void {
    const modalData = new GeneralModalData(
      constants.BIDDING.LEGAL_ATTESTATION,
      resources.GENERAL_TERM_ERRORS.LEGAL_ATTESTATION_MISSING,
      resources.Actions.OK.toUpperCase()
    );
    const modalConfig = new GeneralModalConfig(modalData, ModalSize.X_SMALL);

    this.modalService.openModal(modalConfig).afterClosed().subscribe(() => {
      control.setValue(BindingBidType.getValue(BindingBidType.NON_BINDING), { emitEvent: false });
    });
  }
}
