import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { RangeOrUnitAndRebate } from '@qv-bid/entities/range-or-unit-and-rebates.entity';
import { QvCache } from '@qv-common/decorators';
import { DictionaryItem } from '@qv-common/entities';
import { SvgIconName } from '@qv-common/enums';
import { ValidateNumberOptions } from '@qv-common/models';
import { NumberUtils } from '@qv-common/utils';
import { NdcValue } from '@qv-term/entities';
import { TermName } from '@qv-term/enums';
import { MarketShareTier } from 'quantuvis-core-entities';

@Component({
  selector: 'qv-range-or-unit-and-rebate',
  templateUrl: './range-or-unit-and-rebate.component.html',
  styleUrls: ['./range-or-unit-and-rebate.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RangeOrUnitAndRebateComponent implements OnInit, OnChanges {
  @Input() public marketShareTier: NdcValue<DictionaryItem>;
  @Input() public rangeOrUnitAndRebate: RangeOrUnitAndRebate;
  @Input() public isEditMode: boolean;
  @Input() public isBidInvalid: boolean;

  @Output() public valueUpdated = new EventEmitter<RangeOrUnitAndRebate>();
  @Output() public valueRemoved = new EventEmitter<number>();

  public exitFromEditModeEvent = new EventEmitter();
  public disableNavEvent = new EventEmitter();

  public rangePrefix: string;
  public rangeSuffix: string;

  public rangeValidationOptions: ValidateNumberOptions;
  public rebateValidationOptions: ValidateNumberOptions;

  public readonly svgIconName = SvgIconName;
  public readonly termName = TermName.RANGE_OR_UNITS_AND_REBATES;
  public readonly validateNumberOptions = new ValidateNumberOptions(2, -1000000000000000, 1000000000000000, 0.01, 2);
  public readonly validateDollarOptions = new ValidateNumberOptions(2, -1000000000000000, 1000000000000000, 0.01, 2);
  public readonly validatePercentOptions = new ValidateNumberOptions(5, -999.99999, 999.99999, 0.0001, 2);
  public readonly visibleRangeNumber = 5;

  constructor(
    private elementRef: ElementRef,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  public ngOnInit(): void {
    this.calculateRangePrefixAndSuffix();
    this.rebateValidationOptions = this.validatePercentOptions;
    this.rangeValidationOptions = this.getRangeValidationOptions();
    this.changeDetectorRef.markForCheck();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.marketShareTier && !changes.marketShareTier.firstChange && changes.marketShareTier.currentValue) {
      this.calculateRangePrefixAndSuffix();
      this.rangeValidationOptions = this.getRangeValidationOptions();
      this.changeDetectorRef.markForCheck();
    }
  }

  @QvCache()
  public getFormattedNumberAsString(numberToFormat: number | null): string {
    return typeof numberToFormat === 'number' ? String(NumberUtils.getDecimals(numberToFormat, 2)) : '';
  }

  @QvCache()
  public isValueTooltipDisabled(value: number | string): boolean {
    return String(value).length <= this.visibleRangeNumber;
  }

  public startEdit(): void {
    this.focusFirstInput();
  }

  public focusFirstInput(): void {
    this.elementRef.nativeElement.querySelector('input').focus();
    this.changeDetectorRef.markForCheck();
  }

  public isEditingAvailable(): boolean {
    return this.isEditMode;
  }

  private calculateRangePrefixAndSuffix(): void {
    if (this.marketShareTier.isNdc) return;
    const dictionaryItem = this.marketShareTier.value;
    if (dictionaryItem.id === MarketShareTier.SPECIFIC_SPEND) {
      this.rangePrefix = MarketShareTier.displayRangeUnit(dictionaryItem.id);
    } else {
      this.rangeSuffix = MarketShareTier.displayRangeUnit(dictionaryItem.id);
    }
  }

  private getRangeValidationOptions(): ValidateNumberOptions {
    if (this.marketShareTier.isNdc) return;
    const dictionaryItem = this.marketShareTier.value;
    switch (dictionaryItem.id) {
      case MarketShareTier.SPECIFIC_UNIT_COUNT:
      case MarketShareTier.SPECIFIC_RX_COUNT: {
        return this.validateNumberOptions;
      }
      case MarketShareTier.SPECIFIC_SPEND: {
        return this.validateDollarOptions;
      }
      case MarketShareTier.MARKET_SHARE_PERCENT:
      case MarketShareTier.MARKET_SHARE_PLUS_PERCENT:
      case MarketShareTier.NATIONAL_MARKET_SHARE_PLUS_PERCENT:
      case MarketShareTier.PERCENT_OF_NATIONAL_MARKET_SHARE: {
        return this.validatePercentOptions;
      }
      default: {
        return this.validateNumberOptions;
      }
    }
  }
}
