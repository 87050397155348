import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('CompanyInsightsConfig')
export class CompanyInsightsConfig {
  @JsonProperty('modelName', String)
  public modelName: string = null;

  @JsonProperty('standardReportsFolderId', String)
  public standardReportsFolderId: string = null;

  @JsonProperty('customReportsFolderId', String)
  public customReportsFolderId: string = null;

  @JsonProperty('groupId', Number)
  public groupId: number = null;

  constructor(
    modelName: string = null,
    standardReportsFolderId: string = null,
    customReportsFolderId: string = null,
    groupId: number = null
  ) {
    this.modelName = modelName;
    this.standardReportsFolderId = standardReportsFolderId;
    this.customReportsFolderId = customReportsFolderId;
    this.groupId = groupId;
  }
}
