import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'otherChips'
})
export class OtherChipsPipe implements PipeTransform {
  public transform(items: any[], visibleItemsNumber?: number): string {
    return `and ${items.length - visibleItemsNumber} ${items.length === (visibleItemsNumber + 1) ? 'other' : 'others'}`;
  }
}
