/* tslint:disable:prefer-template triple-equals max-line-length */
import { SvgIconName } from '@qv-common/enums';
import { constants, resources } from '@qv-common/static';
import { CoreUtils } from '@qv-common/utils';
import { drugTermsConstants } from '@qv-term/constants';
import { TermName } from '@qv-term/enums';
import { GeneralModalConfig, GeneralModalData, ModalSize } from 'quantuvis-angular-common/modal';
import { TermUtils } from '../../utils/terms';
// @ts-ignore
import template from '../../views/bidding/templates/termview.html';

/**
 * Term view directive controller.
 * It is responsible for term preparation when the definition is set.
 */

declare let $: any;
declare let angular: angular.IAngularStatic;

export const TermView = ['$timeout', 'util', 'standards', 'BidDetailsService', 'biddingUtilsService',
  '$document', 'userService', 'modalService', 'translations',
  ($timeout, util, standards, BidDetailsService, biddingUtilsService, $document, userService, modalService,
   translations): any => {
  'ngInject';
  let blockPropagations = false;
  const skipChangedSymbol = Symbol.for('skipChanges');


  return {
    restrict: 'A',
    template,
    scope: {
      term: '=',
      hideName: '=',
      verticalLabel: '=',
      editable: '=',
      lock: '=',
      grayedOut: '=',
      selected: '=',
      displayType: '=',
      ctrl: '='
    },
    link: (scope, element, attrs) => {

      /* publish functions on scope from the utilities */
      scope.hasChangesAtNDC = util.hasChangesAtNDC;
      scope.displayOriginalCustom = util.displayOriginalCustom;
      scope.user = userService.user.getValue();
      scope.modelOptions = {
        updateOn: 'default blur',
        debounce: {
          default: constants.APP.TERM_CHANGE_DEBOUNCE_INTERVAL,
          blur: 0
        }
      };
      scope.svgIconName = SvgIconName;
      translations.buildTranslations(scope);

      // Destroy scope once we leave edit mode for the term to prevent memory leaks and watcher duplication
      element.on('$destroy', () => {
        scope.$destroy();
      });

      scope.$watch('term.value', (newValue, oldValue) => {
        if (!scope.term || !scope.term.definition) {
          return;
        }
        if (typeof newValue !== 'undefined' && newValue[skipChangedSymbol]) {
          newValue[skipChangedSymbol] = false;
          return;
        }
        if (scope.term && scope.term.definition.processKeepOriginalValue && typeof scope.term.definition.updateKeepOriginState === 'function') {
          scope.term.definition.updateKeepOriginState(scope.term);
        }

        const term = scope.term;
        term.definition.error = false; // cleanup errors, new value might be ok

        function doChange(): void {
          util.publishChanges(term, scope, {
            drug: scope.getDrug()
          });

          if (term.definition.onChange) {
            term.definition.onChange(term, scope.getDrug());
          }

          if (term.definition.onChangeEvent) {
            const scopeForTerm = scope.ctrl ? scope.ctrl : scope;
            term.definition.onChangeEvent(term, scopeForTerm);
          }

          if (util.isPathDefined(scope, '$parent.drug.renderData.isGroup')) {
            const renderData = scope.$parent.drug.renderData;
            let drugGroupRenderData;
            if (renderData.isGroup && (util.notNullOrEmpty(newValue) ||
              (term.name === constants.SUMMARY_TERMS.CONTRACT_START_DATE.label ||
                term.name === constants.SUMMARY_TERMS.CONTRACT_END_DATE.label))) {
              drugGroupRenderData = renderData;
            } else if (!renderData.isGroup && renderData.parent) {
              drugGroupRenderData = renderData.parent.renderData;
            }

            if (shouldChange(renderData, drugGroupRenderData)) {
              drugGroupRenderData.calculateValue();
            }

            // @ts-ignore
            function shouldChange(renderData, drugGroupRenderData): boolean {
              return drugGroupRenderData && !renderData.fromCalculate &&
                typeof drugGroupRenderData.calculateValue === 'function';
            }
          }

          if (term.definition.processContentStyle) {
            term.contentStyle = term.definition.processContentStyle(term, element, scope.isDisabled(term), isEditMode());
          }
        }

        if (!BidDetailsService.disableTermUpdateWatcher && newValue !== oldValue) {

          if (term && term.definition && term.definition.resetValueFlag && !blockPropagations) {
            let dependenciesHaveValues = false;
            if (typeof term.definition.list === 'object') {
              Object.values(term.definition.list).forEach((term: any) => {
                if (!term.definition.doNotSave && ((term.value && term.value !== '') || term.definition.hasAtNDC)) {
                  dependenciesHaveValues = true;
                }
              });
            }

            scope.setEditablePropertyForDependentTerm(term);

            if (dependenciesHaveValues) {
              // If term value is changed to not empty string or term has dependent UM details display reset popup modal.
              if (newValue !== '' || term.definition.list[drugTermsConstants[TermName.UM_DETAILS].title]) {
                const dialogRef = scope.showConfirmationPopup(term, doChange);

                if (dialogRef) {
                  dialogRef.componentInstance.primaryAction.subscribe(() => {
                    blockPropagations = true;
                    doChange();
                    if (typeof term.definition.list === 'object') {
                      Object.values(term.definition.list).forEach((term: any) => {
                        term.value = '';

                        if (scope.$parent.drug.renderData.isGroup) {
                          // hack for updating NDCs
                          scope.$parent.drug.renderData.fromCalculate = false;
                        }

                        if (term.definition.hasAtNDC) {
                          // Force change to propagate to all ndcs
                          util.publishChanges(term, scope, {
                            drug: scope.getDrug()
                          });
                          term.definition.onChange(term);
                          term.definition.hasAtNDC = false;
                        }
                      });
                    }
                    if (!scope.$parent.drug.renderData.isGroup) {
                      scope.$parent.drug.renderData.parent.renderData.calculateValue();
                    }
                    $timeout(() => {
                      blockPropagations = false;
                    }, 0);
                  });

                  dialogRef.componentInstance.secondaryAction.subscribe(() => term.value = oldValue);
                }
              }
            } else {
              doChange();
            }
          } else {
            doChange();
          }
        }
      }, true);

      scope.$watch('term.definition', definition => {
        if (definition) {
          const term = scope.term;
          const type = term.definition.type;

          // Set term auto-focus based on template attribute
          term.setTermAutoFocus = scope.$eval(attrs.termAutoFocus);

          term.definition.mandatory = angular.isDefined(attrs.mandatory);
          term.definition.hideLabel = angular.isDefined(attrs.hideLabel);
          if (angular.isDefined(attrs.attrErrorPosition)) {
            term.definition.attrErrorPosition = attrs.attrErrorPosition;
          }

          term.definition.displayName = angular.isUndefined(term.definition.displayName) ?
            term.definition.name : term.definition.displayName;

          scope.setEditableProperty(term);

          if (term && scope.displayType != 1) {
            /**
             *  Dropdowns: If an old value that was removed from the options was chosen for a term
             *  make sure it will be displayed in edit mode, but it will be unselectable
             */
            if (type == 1 && term.definition.values) {
              if (typeof term.definition.getValueForView === 'function') {
                term.value = term.definition.getValueForView(term);
              }
              if (term.definition.processKeepOriginalValue) {
                const keepOriginalValue = term.definition.values.find(val => val === resources.NewBid.KEEP_ORIGINAL_VALUE);
                if (!keepOriginalValue) {
                  term.definition.values.push(resources.NewBid.KEEP_ORIGINAL_VALUE);
                }
                term.value = resources.NewBid.KEEP_ORIGINAL_VALUE;
                term.definition.updateKeepOriginState = (term) => {
                  term.definition.keepOriginalValue = term.value === resources.NewBid.KEEP_ORIGINAL_VALUE;
                };
              } else if (!term.definition.values.includes(term.value)) {
                term.definition.archivedValue = term.value;
              }

              if (!term.definition.processKeepOriginalValue) {
                term.definition.values = term.definition.values.filter(val => val !== resources.NewBid.KEEP_ORIGINAL_VALUE);
              }
            }
            if (type === 2 || type === 3) {
              if (CoreUtils.isNotDefined(term.value) && CoreUtils.isDefined(term.definition.defaultValue)) {
                term.value = term.definition.defaultValue;
              }
              if (term.definition.processKeepOriginalValue && type === 2) {
                term.definition.placeholder = resources.NewBid.KEEP_ORIGINAL_VALUE;
                term.definition.updateKeepOriginState = (term) => {
                  term.definition.keepOriginalValue = !(term.value !== resources.NewBid.KEEP_ORIGINAL_VALUE && term.value);
                  if (term.definition.keepOriginalValue) {
                    term.definition.placeholder = resources.NewBid.KEEP_ORIGINAL_VALUE;
                  }
                };
              }
            } else if (type === 5) {
              if (term.definition.processKeepOriginalValue) {
                term.definition.placeholder = resources.NewBid.KEEP_ORIGINAL_VALUE;
                term.definition.keepOriginalValue = true;

                if (term.definition.tinyMceOptions) {
                  const originValue = `<p>${resources.NewBid.KEEP_ORIGINAL_VALUE}</p>`;
                  term.definition.tinyMceOptions.setup = (editor) => {
                    editor.on('init', (e) => {
                      e.target.setContent(originValue);
                      e.target.getBody().style.backgroundColor = '#eaeaea';
                      e.target.undoManager.clear();
                    });
                    editor.on('Focus', (e) => {
                      if (e.target.getContent() === originValue) {
                        term.value.text = '';
                        e.target.setContent('');
                        e.target.getBody().style.backgroundColor = '#fff';
                        term.definition.keepOriginalValue = false;
                      }
                    });
                    editor.on('BeforeSetContent', (e) => {
                      if (term.definition.reset || e.content === originValue) {
                        e.target.getBody().style.backgroundColor = '#eaeaea';
                        term.definition.reset = false;
                        term.definition.keepOriginalValue = true;
                      } else {
                        e.target.getBody().style.backgroundColor = '#fff';
                        term.definition.keepOriginalValue = false;
                      }
                    });
                    editor.on('Change', (e) => {
                      if (e.target.getContent() === originValue) {
                        e.target.getBody().style.backgroundColor = '#eaeaea';
                        term.definition.keepOriginalValue = true;
                      } else {
                        e.target.getBody().style.backgroundColor = '#fff';
                        term.definition.keepOriginalValue = false;
                      }
                    });
                  };
                }
                if (term.definition.name === constants.SUMMARY_TERMS.ATTACHMENTS.label) {
                  term.definition.updateKeepOriginState = (term) => {
                    if (term.value.length > 0 && typeof term.value[0] === 'object' &&
                      Object.getOwnPropertyNames(term.value[0]).length > 0) {
                      term.definition.keepOriginalValue = false;
                    }
                  };
                }
              } else {
                if (term.definition.tinyMceOptions) {
                  term.definition.tinyMceOptions.setup = editor => {
                    editor.on('blur', e => {
                      scope.$broadcast('$tinymce:blur', e.target.getContent());
                    });
                  };

                  scope.$on('$tinymce:blur', (event, value) => {
                    term.value.text = value;
                  });
                }
              }
              if (CoreUtils.isDefined(term.definition.prepareCustom) && typeof (term.definition.prepareCustom) == 'function') {
                term.definition.prepareCustom(scope, term);

                term.definition.isNewBid = CoreUtils.isDefined(attrs.newbid);
              }
            } else if (type == 7) {
              term.definition.initialSetup = true;

              if (term.definition.processKeepOriginalValue) {
                term.definition.placeholder = resources.NewBid.KEEP_ORIGINAL_VALUE;
                term.definition.class = term.definition.class.replace('input-mini', '');
                if (typeof term.definition.onFocus !== 'function') {
                  term.definition.onFocus = (event, term) => {
                    if (term.definition.placeholder) {
                      term.definition.placeholder = '';
                    } else {
                      event.currentTarget.setAttribute('placeholder', '');
                    }
                    term.definition.keepOriginalValue = false;
                  };
                }
              } else if (CoreUtils.isDefined(term.value)) {
                term.definition.disabled = false;
              }
            } else if (type === 9) {
              if (term.definition.processKeepOriginalValue) {
                term.definition.updateKeepOriginState = (term) => {
                  term.definition.keepOriginalValue = term.value === resources.NewBid.KEEP_ORIGINAL_VALUE;
                };
                const keepOriginalValue = term.definition.values.find(val => val.value === resources.NewBid.KEEP_ORIGINAL_VALUE);
                if (!keepOriginalValue) {
                  const keepOriginValue = {
                    value: resources.NewBid.KEEP_ORIGINAL_VALUE,
                    displayValue: resources.NewBid.KEEP_ORIGINAL_VALUE
                  };
                  term.definition.values.push(keepOriginValue);
                }
                term.value = resources.NewBid.KEEP_ORIGINAL_VALUE;
              } else {
                term.definition.values = term.definition.values.filter(val => val.value !== resources.NewBid.KEEP_ORIGINAL_VALUE);
              }
            }

            if (type === 3) {
              term.definition.getCustomValue = term => {
                if (term.value) {
                  return '<i class="icon qv-icon-ok qv-icon-white"></span>';
                } else {
                  return '<i class="icon qv-icon-remove qv-icon-white"></span>';
                }
              };
            }

            if (term.definition.processContentStyle) {
              term.contentStyle = term.definition.processContentStyle(term, element, scope.isDisabled(term), isEditMode());
            }
          }
        }
      });


      /**
       * Return term value based on definition
       */
      scope.getTermValue = term => {
        let value;

        if (term) {
          if (!term.definition.getCustomValue) {
            value = TermUtils.getTermValueForComparison(term, true);
          } else {
            value = term.definition.getCustomValue(term);
          }
        }

        return value;
      };

      scope.setTermValue = (term, value) => {
        term.value = value;
        emitTermValueUpdate(term);
      };

      scope.onTermFocus = (event, term) => {
        if (typeof term.definition.onFocus === 'function') {
          term.definition.onFocus(term, event);
        }
      };

      /**
       * Return term value for comparison purposes
       */
      scope.getTermValueForComparison = term => TermUtils.getTermValueForComparison(term);

      scope.groupStyle = term => {
        let style = '';
        if (term && term.definition && term.definition.error) {
          style += ' error';
        }
        if (term && term.definition && term.definition.groupStyle) {
          style += ' ' + term.definition.groupStyle;
        }

        return style;
      };


      /**
       * If the logged in user is a Payer, the term value shouldn't be disabled even if it's locked.
       *
       * @param term - term to be checked if disabled / non-editable
       * @returns {Boolean} true if logged in user is not a payer and term is disabled, non-editable or grayed
       *               out; false otherwise
       */
      scope.isDisabled = (term) => {
        const isPayer = biddingUtilsService.isCurrentUserPayer(userService.user.getValue());
        const isCreatingInternalBid = biddingUtilsService.isCreatingInternalBid();

        return term.definition.editable === false || ((isPayer || isCreatingInternalBid)
        && !scope.grayedOut ? false : term.definition.disabled || scope.grayedOut);
      };

      /**
       * Confirmation popup appears:
       *   if all fields in the UM section have value "NO" or ""(blank).
       *   if we need to reset appropriate fields in the Price Protection section
       *
       * @param {Object} term - term to be checked
       * @param {Function} callBackDoChange function to be called if we just save value without additional actions
       * @returns {MatDialogRef}
       */
      scope.showConfirmationPopup = (term, callBackDoChange) => {
        if (term.definition.confirmationPopupId === 'confirmResetUMDetails') {
          const objUMDetails = term.definition.list[drugTermsConstants[TermName.UM_DETAILS].title];
          if (!objUMDetails.definition.isEditable(objUMDetails)) {
            const modalData = new GeneralModalData(scope.i18n.GENERAL.CONFIRM_NAVIGATION_TITLE,
              scope.i18n.POPUPS.RESET_UM_DETAILS,
              resources.Actions.YES.toUpperCase(), resources.Actions.NO.toUpperCase());
            const modalConfig = new GeneralModalConfig(modalData, ModalSize.MEDIUM);
            return modalService.openModal(modalConfig);
          } else {
            callBackDoChange();
          }
        }
      };

      /**
       * Set 'Editable' property for current term
       *
       * @param term - term to be checked
       *
       */
      scope.setEditableProperty = term => {
        if (term.definition.isEditable) {
          term.definition.editable = term.definition.isEditable(term);
        }
      };

      /**
       * Set 'Editable' property for dependent terms
       *
       * @param term - term to be checked
       *
       */
      scope.setEditablePropertyForDependentTerm = term => {
        const definitionList = term.definition.list || [];
        definitionList.forEach(elem => {
          if (elem.definition.isEditable) {
            elem.definition.editable = elem.definition.isEditable(elem);
          }
        });
      };

      scope.disableBidActions = (event, prevValuePath) => {
        const prevValue = util.getNestedValue(scope, prevValuePath);
        BidDetailsService.disableBidActions(prevValue, event.currentTarget.value);
      };

      /**
       * Method that return the class for a currency or a suffix
       * @param currency the currency of a term (e.g. '$')
       * @param suffix the suffix of a term (e.g. '%')
       * @returns {string}
       */
      scope.getAppendPrependStyle = (currency, suffix) => {
        if (CoreUtils.isDefined(currency) && constants.CURRENCIES.includes(currency)) {
          return 'input-group input-prepend';
        } else if (CoreUtils.isDefined(suffix) && suffix === constants.UNITS.PERCENT) {
          return 'input-group input-append';
        }
        return '';
      };

      /**
       * Method that returns the input-group-addon class for a prefix or a suffix
       * @param attribute the value of the attribute that will be placed as a suffix or a prefix
       *              (e.g. the '$' should be placed as a prefix)
       * @returns {string}
       */
      scope.getPrefixSuffixStyle = attribute => {
        if (CoreUtils.isDefined(attribute)) {
          if (attribute === '%') {
            return ' input-group-addon appended-suffix ';
          } else if (constants.CURRENCIES.includes(attribute)) {
            return ' input-group-addon prepended-price-currency ';
          }
        }
        return '';
      };

      scope.switchType = term => {
        if (term.definition.hasAtNDC && scope.editable && !scope.grayedOut) {
          // Wrap event emitter in $timeout to make sure the event is emitted
          // on the correct scope (after drug got to "edit mode");
          $timeout(() => {
            scope.$emit('showOverrideModal', () => {
              util.clearTerm(term, scope, {
                drug: scope.getDrug()
              });
            });
          }, 0);
        }
      };

      scope.getInputClass = term => {
        let cls = CoreUtils.isDefined(term.definition.class) ? term.definition.class : '';
        cls += ' dismissed' + (scope.grayedOut ? scope.grayedOut : false);
        cls += ' input-field  float-left';
        if (term.definition.keepOriginalValue) {
          cls += ' keep-origin-val';
        }
        return cls;
      };
      scope.getInputPlaceholder = term => term.definition.placeholder;

      scope.getDrug = () => (scope.$parent && scope.$parent.drug) ? scope.$parent.drug : null;

      scope.getBidPayer = () => BidDetailsService.getBidPayer();

      scope.getBidManufacturer = () => BidDetailsService.getBidManufacturer();

      scope.getBidStatus = () => BidDetailsService.getStatus();

      scope.getBidId = () => BidDetailsService.getBidId();

      scope.getLabelStyle = () => scope.verticalLabel ? 'term-label vertical-label' : 'term-label';

      scope.getLockUnlockIconClass = term => {
        let classes = 'term-lock lock-icon-margin ';
        if (scope.lock) {
          if (term && term.definition
            && term.definition.state === constants.DRUG_TERM_GROUPS_STATE_VALUES.LOCKED_STATE.state) {
            classes += 'term-lock--locked ';
          } else if (userService.isCurrentUserPayer()) {
            classes += 'term-lock--unlocked ';
          } else {
            classes += 'term-lock--hidden ';
          }
        }

        return classes.trim();
      };

      scope.getLockUnlockIconName = term => {
        if (scope.lock) {
          return term && term.definition
          && term.definition.state === constants.DRUG_TERM_GROUPS_STATE_VALUES.LOCKED_STATE.state
            ? SvgIconName.LOCK
            : SvgIconName.UNLOCK;
        }
      };

      scope.lockOrUnlockTerm = term => {
        if (scope.ctrl) {
          scope.ctrl.lockOrUnlock([term], false);
        } else {
          scope.$emit(constants.EVENTS.LOCK_OR_UNLOCK_TERM, term);
        }
      };

      scope.getErrorPosition = term => {
        if (term && term.definition && term.definition.attrErrorPosition) {
          return term.definition.attrErrorPosition;
        } else if (term && term.name
          && term.name === drugTermsConstants[TermName.BASELINE_START_DATE].title) {
          return 'alert--absolute';
        }


        return '';
      };

      function emitTermValueUpdate(term): void {
        const data = {
          detail: {
            term
          }
        };

        $document[0].dispatchEvent(new CustomEvent(constants.EVENTS.TERM_VALUE_UPDATE, data));
      }

      function isEditMode(): any {
        const drug = scope.getDrug();
        return drug && drug.isEditMode;
      }
    },
  };
  // tslint:disable-next-line:max-file-line-count
}];
