import { Injectable } from '@angular/core';
import { Router, RouterEvent, RoutesRecognized } from '@angular/router';
import { AppRoute } from '@qv-common/enums';
import { PermissionService } from '@qv-common/services/auth/permission.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter, skip, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { AuthService } from 'quantuvis-angular-common/auth';
import { UserService } from '@qv-common/services/auth/user.service';
import { NavigationUtils } from 'quantuvis-angular-common/window-utils';
import { NavigationService } from 'quantuvis-angular-common/navigation';

@UntilDestroy()
@Injectable()
export class NavigateAuthService {
  constructor(
    private router: Router,
    private authService: AuthService,
    private userService: UserService,
    private permissionService: PermissionService,
    private navigationService: NavigationService,
  ) {}

  public init(): void {
    this.router.events.pipe(
      filter((event: RouterEvent) => event instanceof RoutesRecognized),
      skip(1),
      switchMap((event: RoutesRecognized) => this.checkRedirectStrategy(event.urlAfterRedirects)),
      untilDestroyed(this),
    ).subscribe();
  }

  public checkRedirectStrategy(url: string): Observable<boolean> {
    const hasSecureInfo = this.authService.hasSecureInfo();
    const isPublicPage = this.navigationService.isPublicPage(url);

    if (!hasSecureInfo && !isPublicPage) {
      NavigationUtils.setDeepLinkToCurrentUrl();
      this.authService.authorize();

      return of(false);
    }

    if (hasSecureInfo && isPublicPage) {
      this.router.navigate(['/', 'dashboard']);
    }

    this.checkAccessDeniedRedirect(hasSecureInfo, url);

    return of(true);
  }

  private checkAccessDeniedRedirect(hasSecureInfo: boolean, url: string): void {
    if (hasSecureInfo && !this.userService.isCurrentUserQuantuvisAdmin()
      && this.permissionService.isUserHasNoneAccess()
      && !url.includes(AppRoute.LOGOUT) && !url.includes(AppRoute.ACCESS_DENIED)
    ) {
      this.router.navigate([AppRoute.ACCESS_DENIED]);
    }
  }
}
