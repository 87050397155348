import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { ModalConfig } from '../../../models/modal-config';
import { TemplateFormModalData } from '../../../models/data/template-form-modal-data';
import { TemplateFormModalComponent } from '../template-form-modal.component';
import { ModalSize } from '../../../enums/modal-size';

export class TemplateFormModalConfig
  extends ModalConfig<TemplateFormModalComponent, TemplateFormModalData> {
  public readonly disableClose: boolean;

  constructor(data: TemplateFormModalData, width: ModalSize, disableClose: boolean) {
    super(data, width);
    this.disableClose = disableClose;
  }

  protected createDialogRef(dialog: MatDialog): MatDialogRef<TemplateFormModalComponent> {
    return dialog.open(TemplateFormModalComponent, this);
  }
}
