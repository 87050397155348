import { resources } from '@qv-common/static';
import { SelectBoxTerm } from '@qv-term/models/types';
import { NewPriceEffectiveDate } from 'quantuvis-core-entities';

export class NewPriceEffectiveDateTerm extends SelectBoxTerm {
  constructor(name: string) {
    super(
      name,
      name,
      resources.TermsDescriptions.DRUG_LIST.NEW_PRICE_EFFECTIVE_DATE,
      '',
      '',
      NewPriceEffectiveDate.getValue(NewPriceEffectiveDate.EMPTY),
      'term__new-price-effective-date'
    );
    this.values = NewPriceEffectiveDate.getValues();
  }

  public resetValue(): void {
    this.value = this.defaultValue;
  }
}
