export class FirstViewportItemConfig {
  public scrollStartPosition = 0;
  public startIndex = 0;
  public isLocked = true;

  public lock(): void {
    this.isLocked = true;
  }

  public unlock(): void {
    this.isLocked = false;
  }

  public reset(): void {
    this.scrollStartPosition = 0;
    this.startIndex = 0;
    this.isLocked = true;
  }
}
