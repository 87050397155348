import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ModalSize } from '../enums/modal-size';

export abstract class ModalConfig<C, D> {
  public readonly panelClass = 'qv-modal-dialog-wrapper';
  public readonly width: string;
  public data: D;
  public autoFocus = false;
  public restoreFocus = false;

  protected constructor(data: D, width: ModalSize) {
    this.data = data;
    this.width = width;
  }

  public openModal(dialog: MatDialog): MatDialogRef<C> {
    const dialogRef = this.createDialogRef(dialog);
    dialogRef.afterClosed().subscribe(() => this.data = null);

    return dialogRef;
  }

  protected abstract createDialogRef(dialog: MatDialog): MatDialogRef<C>;
}
