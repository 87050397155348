import { FieldsDropdownOptionsState } from './fields-dropdown-options.state';
import { fieldsDropdownOptionsAdapter } from './fields-dropdown-options.adapter';
import { Action, createReducer, on } from '@ngrx/store';
import { FieldsDropdownOptionsActions } from './fields-dropdown-options.actions';
import { StateStatus } from '../../enums/state-status';

export const initialState: FieldsDropdownOptionsState = fieldsDropdownOptionsAdapter.getInitialState({
  status: StateStatus.Idle
});

const reducer = createReducer(
  initialState,
  on(FieldsDropdownOptionsActions.setPendingStatus, state => ({ ...state, status: StateStatus.Pending })),

  on(FieldsDropdownOptionsActions.getResolved, (state, { data }) =>
    fieldsDropdownOptionsAdapter.setAll(data, { ...state, status: StateStatus.Resolved })),

  on(FieldsDropdownOptionsActions.getRejected, state => ({ ...state, status: StateStatus.Rejected }))
);

export function fieldsDropdownOptionsReducer(state: FieldsDropdownOptionsState,
                                             action: Action): FieldsDropdownOptionsState {
  return reducer(state, action);
}
