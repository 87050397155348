import { Scenario } from '@qv-bid/entities';

export interface DrugStoreOptions {
  scenarioId: number;
  ndcId: number;
  cbId: number;
  drugName: string;
  isNdc: boolean;
  source: Scenario;
}

export class DrugStore {
  public readonly scenarioId: number;
  public readonly ndcId: number;
  public readonly cbId: number;
  public readonly drugName: string;
  public readonly isNdc: boolean;
  public readonly source: Scenario;

  constructor({ scenarioId, ndcId, cbId, drugName, isNdc, source }: DrugStoreOptions) {
    this.scenarioId = scenarioId;
    this.ndcId = ndcId;
    this.cbId = cbId;
    this.drugName = drugName;
    this.isNdc = isNdc;
    this.source = source;
  }
}
