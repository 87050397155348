import { constants } from '@qv-common/static';
import { LocalStorageService } from 'ngx-webstorage';
import { CoreUtils } from '@qv-common/utils';

export class Preferences {
  public static $inject = ['localStorageService'];

  constructor(private localStorageService: LocalStorageService) {}

  public setUserPreference(key: string, value: any): void {
    let userPreferences = this.localStorageService.retrieve(constants.USER_PREFERENCES);

    if (CoreUtils.isNotDefined(userPreferences) || CoreUtils.isNull(userPreferences)) {
      userPreferences = {};
    }

    userPreferences[key] = value;

    this.localStorageService.store(constants.USER_PREFERENCES, userPreferences);
  }

  public getUserPreference(key: string, defaultValue: any): string {
    let value;
    const userPreferences = this.localStorageService.retrieve(constants.USER_PREFERENCES);

    if (CoreUtils.isNotDefined(userPreferences) || CoreUtils.isNull(userPreferences)) {
      value = defaultValue;
    } else {
      value = userPreferences[key];

      if (CoreUtils.isNotDefined(value) || CoreUtils.isNull(value)) {
        value = defaultValue;
      }
    }

    return value;
  }
}
