import { Injectable } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { AdministrationFee, Payment, Penalty, PharmaAudit, TerminationClause } from '@qv-bid/entities';
import { DictionaryItem } from '@qv-common/entities';
import { FieldValidationMessage } from '@qv-common/enums';
import { DefaultState } from '@qv-common/enums/default-state.enum';
import { TermTemplateStorageService } from '@qv-term/services';
import { NumberUtils } from '@qv-common/utils';
import { TermName, TermSection } from '@qv-term/enums';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { NoFaultTerminationClauseWhoMayInvoke } from '@qv-term/enums/options';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BidStateService } from '@qv-bid/services/bid-state.service';
import {
  AdministrationFeeTerm,
  LatePaymentPenaltyValueTerm,
  NoFaultTerminationClausePriorNoticeRequiredTerm,
  PharmaAuditLockBackTerm,
  PharmaAuditPriorNoticeTerm
} from '@qv-term/models/payment';

@UntilDestroy()
@Injectable()
export class ContractFormService {
  public isContractFormReady$ = new BehaviorSubject(false);
  public readonly contractFormGroup = new FormGroup({
    [TermSection.PAYMENT]: new FormGroup({}),
    [TermSection.PENALTY]: new FormGroup({}),
    [TermSection.PHARMA_AUDIT]: new FormGroup({}),
    [TermSection.NO_FAULT_TERMINATION_CLAUSE]: new FormGroup({}),
    [TermSection.ADMINISTRATION_FEE]: new FormGroup({}),
  });
  private readonly formChangeEvent$ = new Subject<void>();
  private readonly requiredError = { required: FieldValidationMessage.REQUIRED };

  constructor(private formBuilder: FormBuilder, private termTemplateStorage: TermTemplateStorageService,
              private bidStateService: BidStateService) {}

  public contactFormReadyHandler(): Observable<boolean> {
    return this.formChangeEvent$.pipe(
      map(() => Object.values(this.contractFormGroup.controls)
        .every((group: FormGroup) => Object.keys(group.controls).length > 0)),
      tap((isReady: boolean) => this.isContractFormReady$.next(isReady))
    );
  }

  public buildPenaltyForm(penalty: Penalty): void {
    const valueTermName = TermName.LATE_PAYMENT_PENALTY_TERMS_VALUE;
    const frequencyName = TermName.LATE_PAYMENT_PENALTY_TERMS_FREQUENCY;
    const valueTerm = this.termTemplateStorage.getTermTemplate(valueTermName) as LatePaymentPenaltyValueTerm;
    let penaltyFormGroup = (this.contractFormGroup.get(TermSection.PENALTY) as FormGroup);

    const basisTermName = TermName.LATE_PAYMENT_PENALTY_TERMS_BASIS;
    if (Object.keys(penaltyFormGroup.controls).length > 0) {
      penaltyFormGroup.patchValue({
        [basisTermName]: penalty.basis,
        [valueTermName]: penalty.setValue,
        [frequencyName]: penalty.penaltyFrequency,
        [TermSection.getTermSectionLock(TermSection.PENALTY)]: penalty.penaltyLock,
      }, { emitEvent: false });
    } else {
      penaltyFormGroup = this.formBuilder.group({
        [basisTermName]: new FormControl(penalty.basis, {
          validators: this.fieldsAvailability([valueTermName, frequencyName])
        }),
        [valueTermName]: new FormControl(penalty.setValue, {
          updateOn: 'blur',
          validators: [
            valueTerm.numberValidator(Penalty.getPenaltyValueNumberValidationOptions(penalty.basis.id)),
            this.sectionValidator(basisTermName)
          ],
        }),
        [frequencyName]: new FormControl(penalty.penaltyFrequency, {
          validators: this.sectionValidator(basisTermName),
        }),
        [TermSection.getTermSectionLock(TermSection.PENALTY)]: new FormControl(penalty.penaltyLock),
      });

      this.initValidationUpdateOnBasisValueChange(penaltyFormGroup, valueTerm);

      this.contractFormGroup.setControl(TermSection.PENALTY, penaltyFormGroup);
    }

    this.formChangeEvent$.next();
  }

  public buildPaymentForm(payment: Payment): void {
    let paymentFormGroup = (this.contractFormGroup.get(TermSection.PAYMENT) as FormGroup);

    if (Object.keys(paymentFormGroup.controls).length > 0) {
      paymentFormGroup.patchValue({
        [TermName.REBATE_ELIGIBILITY]: payment.rebateEligibility,
        [TermName.getTermNameLock(TermName.REBATE_ELIGIBILITY)]: payment.rebateEligibilityLock,
        [TermName.WHOLESALE_ACQUISITION_PRICE]: payment.wholesalePrice,
        [TermName.getTermNameLock(TermName.WHOLESALE_ACQUISITION_PRICE)]: payment.wholesalePriceLock,
        [TermName.PAYMENT_LAG]: payment.lag,
        [TermName.getTermNameLock(TermName.PAYMENT_LAG)]: payment.lagLock,
        [TermName.PAYMENT_FREQUENCY]: payment.paymentFrequency,
        [TermName.getTermNameLock(TermName.PAYMENT_FREQUENCY)]: payment.paymentFrequencyLock,
      }, { emitEvent: false });
    } else {
      paymentFormGroup = this.formBuilder.group({
        [TermName.REBATE_ELIGIBILITY]: new FormControl(payment.rebateEligibility),
        [TermName.getTermNameLock(TermName.REBATE_ELIGIBILITY)]: new FormControl(payment.rebateEligibilityLock),
        [TermName.WHOLESALE_ACQUISITION_PRICE]: new FormControl(payment.wholesalePrice),
        [TermName.getTermNameLock(TermName.WHOLESALE_ACQUISITION_PRICE)]: new FormControl(payment.wholesalePriceLock),
        [TermName.PAYMENT_LAG]: new FormControl(payment.lag),
        [TermName.getTermNameLock(TermName.PAYMENT_LAG)]: new FormControl(payment.lagLock),
        [TermName.PAYMENT_FREQUENCY]: new FormControl(payment.paymentFrequency),
        [TermName.getTermNameLock(TermName.PAYMENT_FREQUENCY)]: new FormControl(payment.paymentFrequencyLock),
      });

      this.contractFormGroup.setControl(TermSection.PAYMENT, paymentFormGroup);
    }

    this.formChangeEvent$.next();
  }

  public buildPharmaAuditForm(pharmaAudit: PharmaAudit): void {
    const pharmaAuditPriorNoticeTerm = this.termTemplateStorage
      .getTermTemplate(TermName.PHARMA_AUDIT_TERMS_PRIOR_NOTICE_REQUIRED) as PharmaAuditPriorNoticeTerm;
    const pharmaAuditLockBackTerm = this.termTemplateStorage
      .getTermTemplate(TermName.PHARMA_AUDIT_TERMS_LOOK_BACK) as PharmaAuditLockBackTerm;
    let pharmaAuditFormGroup = (this.contractFormGroup.get(TermSection.PHARMA_AUDIT) as FormGroup);

    if (Object.keys(pharmaAuditFormGroup.controls).length > 0) {
      pharmaAuditFormGroup.patchValue({
        [TermName.PHARMA_AUDIT_TERMS_AUDIT_FREQUENCY]: pharmaAudit.pharmaAuditFrequency,
        [TermName.PHARMA_AUDIT_TERMS_PRIOR_NOTICE_REQUIRED]: pharmaAudit.priorNoticeRequired,
        [TermName.PHARMA_AUDIT_TERMS_LOOK_BACK]: pharmaAudit.lookBack,
        [TermName.getTermNameLock(TermSection.PHARMA_AUDIT)]: pharmaAudit.pharmaAuditLock
      }, { emitEvent: false });
    } else {
      pharmaAuditFormGroup = this.formBuilder.group({
        [TermName.PHARMA_AUDIT_TERMS_AUDIT_FREQUENCY]: new FormControl(pharmaAudit.pharmaAuditFrequency, {
          validators: this.fieldsAvailability([TermName.PHARMA_AUDIT_TERMS_PRIOR_NOTICE_REQUIRED])
        }),
        [TermName.PHARMA_AUDIT_TERMS_PRIOR_NOTICE_REQUIRED]:
          new FormControl(pharmaAudit.priorNoticeRequired, {
            updateOn: 'blur',
            validators: [
              pharmaAuditPriorNoticeTerm.numberValidator(NumberUtils.defaultCalendarOptions),
              this.sectionValidator(TermName.PHARMA_AUDIT_TERMS_AUDIT_FREQUENCY)
            ]
          }),
        [TermName.PHARMA_AUDIT_TERMS_LOOK_BACK]: new FormControl(pharmaAudit.lookBack, {
          updateOn: 'blur',
          validators: pharmaAuditLockBackTerm.numberValidator(NumberUtils.defaultCalendarOptions)
        }),
        [TermName.getTermNameLock(TermSection.PHARMA_AUDIT)]: new FormControl(pharmaAudit.pharmaAuditLock),
      });

      this.contractFormGroup.setControl(TermSection.PHARMA_AUDIT, pharmaAuditFormGroup);
    }

    this.contractFormGroup.setControl(TermSection.PHARMA_AUDIT, pharmaAuditFormGroup);
    this.formChangeEvent$.next();
  }

  public buildTerminationClauseForm(terminationClause: TerminationClause): void {
    const noticeRequiredName = TermName.NO_FAULT_TERMINATION_CLAUSE_PRIOR_NOTICE_REQUIRED;
    const whoMayInvokeName = TermName.NO_FAULT_TERMINATION_CLAUSE_WHO_MAY_INVOKE;
    const terminationClauseName = TermSection.NO_FAULT_TERMINATION_CLAUSE;
    const noticeRequiredNameTerm = this.termTemplateStorage.getTermTemplate(
      TermName.NO_FAULT_TERMINATION_CLAUSE_PRIOR_NOTICE_REQUIRED
    ) as NoFaultTerminationClausePriorNoticeRequiredTerm;
    let terminationClauseForm = (this.contractFormGroup.get(terminationClauseName) as FormGroup);

    this.updateWhoMayInvokeWithOldManufacturerName(terminationClause.whoMayInvoke);

    if (Object.keys(terminationClauseForm.controls).length > 0) {
      terminationClauseForm.patchValue({
        [whoMayInvokeName]: terminationClause.whoMayInvoke,
        [noticeRequiredName]: terminationClause.terminationClausePriorNoticeRequired,
        [TermName.getTermNameLock(terminationClauseName)]: terminationClause.noFaultTerminationClauseLock
      }, { emitEvent: false });
    } else {
      terminationClauseForm = this.formBuilder.group({
        [whoMayInvokeName]: new FormControl(terminationClause.whoMayInvoke, {
          validators: this.fieldsAvailability(
            [noticeRequiredName],
            [NoFaultTerminationClauseWhoMayInvoke.EMPTY, NoFaultTerminationClauseWhoMayInvoke.NEITHER_PARTY])
        }),
        [noticeRequiredName]:
          new FormControl(terminationClause.terminationClausePriorNoticeRequired, {
            updateOn: 'blur',
            validators: [
              noticeRequiredNameTerm.numberValidator(NumberUtils.defaultCalendarOptions),
              this.sectionValidator(
                whoMayInvokeName,
                [NoFaultTerminationClauseWhoMayInvoke.EMPTY, NoFaultTerminationClauseWhoMayInvoke.NEITHER_PARTY]
              )
            ]
          }),
        [TermName.getTermNameLock(terminationClauseName)]:
          new FormControl(terminationClause.noFaultTerminationClauseLock),
      });

      this.contractFormGroup.setControl(terminationClauseName, terminationClauseForm);
    }

    this.contractFormGroup.setControl(terminationClauseName, terminationClauseForm);
    this.formChangeEvent$.next();
  }

  public buildAdminFeeForm(administrationFee: AdministrationFee): void {
    const adminFee = this.termTemplateStorage.getTermTemplate(TermName.ADMINISTRATION_FEE) as AdministrationFeeTerm;
    let administrationFeeForm = (this.contractFormGroup.get(TermSection.ADMINISTRATION_FEE) as FormGroup);

    if (Object.keys(administrationFeeForm.controls).length > 0) {
      administrationFeeForm.patchValue({
        [TermName.ADMINISTRATION_FEE]: administrationFee.administrationFee,
        [TermName.getTermNameLock(TermSection.ADMINISTRATION_FEE)]: administrationFee.administrationFeeLock,
      }, { emitEvent: false });
    } else {
      administrationFeeForm = this.formBuilder.group({
        [TermName.ADMINISTRATION_FEE]: new FormControl(administrationFee.administrationFee, {
          validators: adminFee.numberValidator(),
          updateOn: 'blur'
        }),
        [TermName.getTermNameLock(TermSection.ADMINISTRATION_FEE)]: new FormControl(
          administrationFee.administrationFeeLock
        ),
      });

      this.contractFormGroup.setControl(TermSection.ADMINISTRATION_FEE, administrationFeeForm);
    }

    this.contractFormGroup.setControl(TermSection.ADMINISTRATION_FEE, administrationFeeForm);
    this.formChangeEvent$.next();
  }

  public fieldsAvailability(
    dependingTermNames: string[],
    notRequiredList: number[] = [DefaultState.EMPTY]
  ): ValidatorFn {
    return (control: AbstractControl): ValidationErrors => {

      control = control as FormControl;
      const formChangeOptions = { emitEvent: false };
      const parentFormGroup = control.parent as FormGroup;
      if (!parentFormGroup) return;

      dependingTermNames.forEach((termName: string) => {
        const dependingControl = parentFormGroup.get(termName);

        if (!notRequiredList.includes(control.value.id)) {
          dependingControl.enable(formChangeOptions);
          if (!dependingControl.value || notRequiredList.includes(dependingControl.value.id)) {
            dependingControl.markAsDirty();
            dependingControl.setErrors(this.requiredError);
          }
        } else {
          dependingControl.setErrors(null);
          dependingControl.setValue(this.termTemplateStorage.getTermTemplate(termName).defaultValue, formChangeOptions);
          setTimeout(() => dependingControl.disable(formChangeOptions));
        }
      });

      return null;
    };
  }

  public sectionValidator(rootTernName: string, notRequiredList: number[] = [DefaultState.EMPTY]): ValidatorFn {
    return (control: AbstractControl): ValidationErrors => {
      control = control as FormControl;
      const parentFormGroup = control.parent as FormGroup;
      if (!parentFormGroup || control.pending) return;

      const rootControlValue = (parentFormGroup.get(rootTernName).value as DictionaryItem);

      if (rootControlValue && !notRequiredList.includes(rootControlValue.id)) {
        if (!Boolean(control.value) || (control.value.id && notRequiredList.includes(control.value.id))) {
          parentFormGroup.setErrors(this.requiredError);
          return this.requiredError;
        }
      }
      parentFormGroup.updateValueAndValidity({ emitEvent: false });

      return null;
    };
  }

  private initValidationUpdateOnBasisValueChange(
    penaltyFormGroup: FormGroup,
    valueTerm: LatePaymentPenaltyValueTerm
  ): void {
    const basisTermName = TermName.LATE_PAYMENT_PENALTY_TERMS_BASIS;
    const valueTermName = TermName.LATE_PAYMENT_PENALTY_TERMS_VALUE;

    penaltyFormGroup.get(basisTermName).valueChanges.pipe(
      tap((val: { id: number, name: string }) => penaltyFormGroup.get(valueTermName).setValidators([
        valueTerm.numberValidator(Penalty.getPenaltyValueNumberValidationOptions(val.id)),
        this.sectionValidator(basisTermName)
      ])),
      untilDestroyed(this)
    ).subscribe();
  }

  private updateWhoMayInvokeWithOldManufacturerName(whoMayInvoke: DictionaryItem): void {
    const manufacturerCompanyOldName = this.bidStateService.bid$.getValue().manufacturerCompanyOldName;

    if (whoMayInvoke.id === NoFaultTerminationClauseWhoMayInvoke.PHARMA && manufacturerCompanyOldName) {
      whoMayInvoke.name = `${whoMayInvoke.name} (${manufacturerCompanyOldName})`;
    }
  }
}
