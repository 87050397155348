import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ActiveToast } from 'ngx-toastr/toastr/toastr.service';
import { HttpErrorResponse } from '@angular/common/http';
import { HttpStatusCode } from 'quantuvis-angular-common/api';
import { NotificationService } from 'quantuvis-angular-common/notification';

@Injectable()
export class BidListNotificationService extends NotificationService {
  public readonly CONCURRENT_EDITING_BID = 'Selected bid(s) might have been edited or deleted by other users.' +
    ' Please try again.';
  public readonly SOME_BIDS_CANNOT_BE_DELETED = 'Some of the selected bids are invalid, ' +
    'no bids will be deleted. Please select valid bids and try again.';

  constructor(
    protected toastrService: ToastrService
  ) {
    super(toastrService);
  }

  public showBidNotFoundNotification(messageChannel?: string): ActiveToast<any> {
    return this.error(this.CONCURRENT_EDITING_BID, undefined, undefined, messageChannel);
  }

  public showDeleteProcessingError(err: HttpErrorResponse): ActiveToast<any> {
    if (HttpStatusCode.CONFLICT === err.status) {
      return this.showBidNotFoundNotification();
    } else {
      return super.error(this.SOME_BIDS_CANNOT_BE_DELETED);
    }
  }
}
