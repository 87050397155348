import { SelectBoxTerm } from '@qv-term/models/types';
import { constants, resources } from '@qv-common/static';
import { LatePaymentPenaltyBasis } from '@qv-term/enums/options';

export class LatePaymentPenaltyBasisTerm extends SelectBoxTerm {
  public allowKeepOriginValue: boolean;
  public processKeepOriginalValue: boolean;
  public keepOriginalValue: boolean;

  constructor(name: string) {
    super(
      name,
      name,
      resources.TermsDescriptions.CONTRACT.LATE_PAYMENT_PENALTY_TERMS_BASIS,
      constants.CONTRACT_TERMS.LATE_PAYMENT_PENALTY_TERMS_BASIS.label,
      '',
      LatePaymentPenaltyBasis.getValue(LatePaymentPenaltyBasis.EMPTY),
      'term__late-payment-penalty-basis'
    );
    this.keepOriginalValue = this.allowKeepOriginValue;
    this.processKeepOriginalValue = false;
    this.values = LatePaymentPenaltyBasis.getValues();
  }
}
