import { HttpErrorResponse } from '@angular/common/http';
import { BaselineWac, PriceProtection } from '@qv-bid/entities';
import { NdcDictionaryValue, NdcValue } from '@qv-term/entities';
import { SectionChange, SectionChangeFailed } from '@qv-bid/models';
import { SectionChangeManager } from '@qv-bid/services/sections/section-change-manager.service';
import { SectionState, TermName, TermSection } from '@qv-term/enums';
import { resources } from '@qv-common/static';
import { drugTermsConstants } from '@qv-term/constants';
import { DrugFormService } from '@qv-bid/services/drug';
import { PriceProtectionDaoService } from '@qv-bid/services/dao';
import { QvCache } from '@qv-common/decorators';
import { BidUtils } from '@qv-bid/utils';
import { CoreUtils } from '@qv-common/utils';
import { BaseSection } from '@qv-bid/classes/sections/base-section';
import { ChangeDetectorRef, Directive, Input, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, of } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { DateType } from '@qv-common/types';
import { NetEffectivePriceTerm } from '@qv-term/models/drug/net-effective-price.term';
import { UserService } from '@qv-common/services/auth/user.service';

@UntilDestroy()
@Directive()
export class PriceProtectionSection extends BaseSection implements OnInit {
  @Input()
  public priceProtection: PriceProtection;
  @Input()
  public drugId: number;
  @Input()
  public scenarioId: number;
  @Input()
  public isBidInvalid: boolean;
  @Input()
  public isBidInternal: boolean;

  public readonly termName = TermName;
  public readonly drugTermsConstants = drugTermsConstants;
  public readonly sectionName = TermSection.PRICE_PROTECTION;
  public readonly resources = resources;
  public readonly tooltips = resources.TermsDescriptions.DRUG_LIST;
  public readonly netEffectivePriceNumberOptions = NetEffectivePriceTerm.numberOptions;

  constructor(
    public drugFormService: DrugFormService,
    protected userService: UserService,
    protected changeDetectorRef: ChangeDetectorRef,
    protected priceProtectionDaoService: PriceProtectionDaoService,
    protected sectionChangeManager: SectionChangeManager,
  ) {
    super(drugFormService, changeDetectorRef, sectionChangeManager);
  }

  public ngOnInit(): void {
    this.isUserPharma = this.userService.isCurrentUserPharma();
    this.initSectionChangeHandler();
    this.initSectionEditModeChangeHandler();
  }

  @QvCache()
  public isAtNdc(value: any): boolean {
    return BidUtils.isAtNdc(value);
  }

  @QvCache()
  public createTermCurrencyValueFromBaselineWac(baselineWac: number, isNdc: boolean): NdcValue<number> {
    return new NdcValue<number>(baselineWac, isNdc);
  }

  public isBaselineWacUnavailable(baselineWac: NdcValue<BaselineWac>): boolean {
    return !baselineWac.isNdc && baselineWac.value && !baselineWac.value.baselineWac;
  }

  @QvCache()
  public isPriceProtectionRequired(state: NdcDictionaryValue): boolean {
    return state && state.value ? !state.isNdc && state.value.name.toUpperCase() === SectionState.REQUIRED : false;
  }

  @QvCache()
  public hasPriceProtectionTermEmptyValue(
    isUserPharma: boolean, isNdc: boolean, termValue: string | Date | DateType | number
  ): boolean {
    const isTermValueEmpty = !(CoreUtils.isNumber(termValue) || Boolean(termValue));

    return (isUserPharma && !isNdc) ? isTermValueEmpty : false;
  }

  public isPriceProtectionEmpty(priceProtection: PriceProtection): boolean {
    return Boolean(priceProtection.netEffectivePrice.isNdc)
      || CoreUtils.isNumber(priceProtection.netEffectivePrice.value)
      || this.isTermsEffectivePriceValid(priceProtection);
  }

  @QvCache()
  public isNetEffectivePriceValid(isNdc: boolean, value: number): boolean {
    return isNdc || CoreUtils.isNumber(value);
  }

  public isTermsEffectivePriceValid(priceProtection: PriceProtection): boolean {
    return (priceProtection.index.isNdc || Boolean(priceProtection.index.value?.name))
      && (priceProtection.threshold.isNdc || CoreUtils.isNumber(priceProtection.threshold.value))
      && (priceProtection.reset.isNdc || Boolean(priceProtection.reset.value?.name))
      && (priceProtection.baselineStartDate.isNdc || Boolean(priceProtection.baselineStartDate.value))
      && (priceProtection.newPriceEffectiveDate.isNdc || Boolean(priceProtection.newPriceEffectiveDate.value?.name));
  }

  @QvCache()
  public checkValidationTermForPharma(isNetEffectivePriceValid: boolean, isPriceProtectionRequired: boolean,
                                      hasPriceProtectionTermEmptyValue: boolean): boolean {
    return !isNetEffectivePriceValid
      && isPriceProtectionRequired
      && hasPriceProtectionTermEmptyValue;
  }

  @QvCache()
  public checkValidationNetEffectivePriceForPharma(isTermsEffectivePriceValid: boolean,
                                                   isPriceProtectionRequired: boolean,
                                                   hasPriceProtectionTermEmptyValue: boolean): boolean {
    return !isTermsEffectivePriceValid
      && isPriceProtectionRequired
      && hasPriceProtectionTermEmptyValue;
  }

  private initSectionChangeHandler(): void {
    this.drugFormService.sectionChangeHandler(this.sectionName).pipe(
      tap(() => this.sectionChangeManager.isSectionChanging$.next(true)),
      switchMap((changes: PriceProtection) => this.updateSection(changes)),
      this.sectionLeaveHandler(),
    ).subscribe();
  }

  private updateSection(priceProtection: PriceProtection): Observable<PriceProtection> {
    return this.priceProtectionDaoService.update(priceProtection, this.scenarioId, this.isBidInternal, this.drugId)
      .pipe(
        tap((updatedSection: PriceProtection) => {
          const sectionChange = new SectionChange(this.scenarioId, this.drugId, this.sectionName, updatedSection);
          this.sectionChangeManager.sectionSaveSuccessEvent$.emit(sectionChange);
        }),
        catchError((error: HttpErrorResponse) => this.handleChangeHandlerError(error))
      );
  }

  private initSectionEditModeChangeHandler(): void {
    this.drugFormService.isEditMode(this.sectionName).pipe(untilDestroyed(this))
      .subscribe((isEditMode: boolean) => {
        this.isEditMode = isEditMode;
        this.changeDetectorRef.markForCheck();
      });
  }

  private handleChangeHandlerError(error: HttpErrorResponse): Observable<PriceProtection> {
    this.sectionChangeManager.isSectionChangeFailed$.next(new SectionChangeFailed(this.sectionName, error));

    return of(this.priceProtection);
  }
}
