import { NgModule } from '@angular/core';
import CompaniesComponent from './companies/companies.component';
import CreateCompanyComponent from './company/create-company.component';
import EditCompanyComponent from './company/edit-company.component';
import DrugExceptionsComponent from './drug-exceptions/drug-exceptions.component';
import ManufacturerMappingComponent from './manufacturer-mapping/manufacturer-mapping.component';
import ProductEnrollmentComponent from './product-enrollment/productenrollment.component';
import CreateUserComponent from './user/create-user.component';
import EditUserComponent from './user/edit-user.component';

declare let angular: angular.IAngularStatic;

export const AdministratorNgModule = angular
  .module('quantuvis.administrator', [])
  .component('companiesComponent', CompaniesComponent)
  .component('createCompanyComponent', CreateCompanyComponent)
  .component('editCompanyComponent', EditCompanyComponent)
  .component('drugExceptionsComponent', DrugExceptionsComponent)
  .component('manufacturerMappingComponent', ManufacturerMappingComponent)
  .component('productEnrollmentComponent', ProductEnrollmentComponent)
  .component('createUserComponent', CreateUserComponent)
  .component('editUserComponent', EditUserComponent)
;

@NgModule({})
export class AdministratorModule {
}
