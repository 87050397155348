/**
 * Application frame directive.
 */
import { SvgIconName } from '@qv-common/enums';

declare let $: any;


export const FrameDirective = ['$compile', ($compile): { link: ($scope, elem) => void; restrict: 'A' } => {
  'ngInject';
  return {
    restrict: 'A',
    link: ($scope, elem) => {
      // Adds widget frame class in order to apply the widget styles
      $(elem).addClass('widget-frame');

      // Content element
      let content;

      // Collapsed flag
      let collapse = false;

      /**
       * Get the first element (span/div) after H1 tag.
       * The H1 Tag will be considered widget header. Everything that comes up after this will be content.
       * In order for the collapse to work the content must be wrapped with a div or a span.
       */
      const getContentElement = () => {
        if (!content) {
          const title = $(elem).find('h1');
          if (title) {
            // Gets the next element after the widget header
            const contentElem = $(title).next();

            if (contentElem) {
              content = $(contentElem);
            }
          }
        }

        return content;
      };

      // Prepare the collapse/expand button and add it to the widget
      let toggle = $compile(`<qv-icon icon-name="${SvgIconName.CHEVRON_TOP}"
                        icon-class="chevron-top"></qv-icon>`)($scope);

      $(elem).append(toggle);

      toggle.on('click', () => {
        collapse = !collapse;
        getContentElement().slideToggle(300, () => {
          $(toggle).removeAttr('icon-class');
          $(toggle).attr('icon-class', collapse ? 'chevron-top chevron-top--down' : 'chevron-top');
          toggle = $compile(toggle)($scope);
        });
      });

    }
  };
}];
