import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { BidDetailsNotificationService } from '@qv-bid/services';
import { ScenarioDateValidationService } from '@qv-bid/services/sections/scenario-date-validation.service';
import { SectionChangeManager } from '@qv-bid/services/sections/section-change-manager.service';
import { StandardDrugFormService } from '@qv-bid/services/drug';
import { ContractDaoService } from '@qv-bid/services/dao';
import { ScenarioTermsSection } from '@qv-bid/classes/sections';
import { UserService } from '@qv-common/services/auth/user.service';

@Component({
  selector: 'qv-standard-scenario-terms',
  templateUrl: './standard-scenario-terms.component.html',
  styleUrls: ['./standard-scenario-terms.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StandardScenarioTermsComponent extends ScenarioTermsSection implements OnInit, OnChanges, OnDestroy {
  constructor(
    private userService: UserService,
    bidDetailsNotificationService: BidDetailsNotificationService,
    standardDrugFormService: StandardDrugFormService,
    contractDaoService: ContractDaoService,
    scenarioDateValidationService: ScenarioDateValidationService,
    changeDetectorRef: ChangeDetectorRef,
    sectionChangeManager: SectionChangeManager,
  ) {
    super(standardDrugFormService, bidDetailsNotificationService, scenarioDateValidationService, contractDaoService,
      changeDetectorRef, sectionChangeManager);
  }

  public ngOnInit(): void {
    super.initScenarioTermHandlers();
    this.isUserPharma = this.userService.isCurrentUserPharma();
  }
}
