import { DictionaryItem } from '@qv-common/entities';

export enum MarketShareState {
  ALLOWED = 1,
  HIDDEN = 2,
  LOCKED = 3,
}

export namespace MarketShareState {
  const valuesMap = new Map<MarketShareState, DictionaryItem>([
    [MarketShareState.ALLOWED, { id: MarketShareState.ALLOWED, name: 'Allowed' }],
    [MarketShareState.LOCKED, { id: MarketShareState.LOCKED, name: 'Locked' }],
    [MarketShareState.HIDDEN, { id: MarketShareState.HIDDEN, name: 'Hidden' }]
  ]);

  export function getValue(marketShareState: MarketShareState): DictionaryItem {
    return valuesMap.get(marketShareState);
  }


  export function getValues(): DictionaryItem[] {
    return Array.from(valuesMap.values());
  }
}
