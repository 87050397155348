import {
  ChangeDetectionStrategy,
  Component,
  ComponentFactoryResolver,
  OnInit,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { InternalFieldType } from '@qv-internal-fields-common/enums';
import { BaseInternalField } from '@qv-internal-fields/classes';
import { TextInternalFieldComponent } from '../text-internal-field/text-internal-field.component';

@Component({
  selector: 'qv-dynamic-internal-field',
  templateUrl: './dynamic-internal-field.component.html',
  styleUrls: ['./dynamic-internal-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DynamicInternalFieldComponent extends BaseInternalField implements OnInit {
  @ViewChild('container', { read: ViewContainerRef, static: true })
  public container: ViewContainerRef;

  private readonly componentMapper = {
    [InternalFieldType.TEXT]: TextInternalFieldComponent,
  };

  constructor(private componentFactoryResolver: ComponentFactoryResolver) {
    super();
  }

  public ngOnInit(): void {
    this.initDynamicInternalFieldComponentByType();
  }

  private initDynamicInternalFieldComponentByType(): void {
    const component = this.componentMapper[this.internalField.type];
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(component);
    this.container.clear();
    const componentRef = this.container.createComponent(componentFactory);
    componentRef.instance.internalField = this.internalField;
    componentRef.instance.internalFieldFormGroup = this.internalFieldFormGroup;
    componentRef.instance.onChange = this.onChange;
  }
}
