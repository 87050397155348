import { JsonObject, JsonProperty } from 'json2typescript';
import { BaseEntity } from '@qv-bid/entities/base-entity';
import { NumberConverter } from '@qv-bid/serializers';

@JsonObject('AdministrationFee')
export class AdministrationFee extends BaseEntity {

  @JsonProperty('cbId', Number)
  public contractedBusinessId: number = null;

  @JsonProperty('isLocked', Boolean)
  public administrationFeeLock = false;

  @JsonProperty('administrationFee', NumberConverter)
  public administrationFee: number = null;

}
