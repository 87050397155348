import { DictionaryItem } from '@qv-common/entities';

export enum LatePaymentPenaltyBasis {
  EMPTY = 1,
  PERCENT_OF_TOTAL_REBATES = 2,
  PERCENT_OF_UNPAID_REBATES = 3,
  FLAT_AMOUNT = 4
}

export namespace LatePaymentPenaltyBasis {
  const valuesMap = new Map<LatePaymentPenaltyBasis, DictionaryItem>([
    [LatePaymentPenaltyBasis.EMPTY, { id: LatePaymentPenaltyBasis.EMPTY, name: '' }],
    [
      LatePaymentPenaltyBasis.PERCENT_OF_TOTAL_REBATES,
      { id: LatePaymentPenaltyBasis.PERCENT_OF_TOTAL_REBATES, name: '% of total rebates' }
    ],
    [
      LatePaymentPenaltyBasis.PERCENT_OF_UNPAID_REBATES,
      { id: LatePaymentPenaltyBasis.PERCENT_OF_UNPAID_REBATES, name: '% of unpaid rebates' }
    ],
    [LatePaymentPenaltyBasis.FLAT_AMOUNT, { id: LatePaymentPenaltyBasis.FLAT_AMOUNT, name: 'Flat Amount' }],
  ]);

  export function getValue(basis: LatePaymentPenaltyBasis): DictionaryItem {
    return valuesMap.get(basis);
  }

  export function getValues(): DictionaryItem[] {
    return Array.from(valuesMap.values());
  }
}
