import { Injectable, Provider } from '@angular/core';
import {
  HTTP_INTERCEPTORS,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { catchError } from 'rxjs/operators';
import { HttpResponseErrorCode, HttpStatusCode } from 'quantuvis-angular-common/api';
import { LockVerificationFailedService } from '@qv-bid/services/lock-verification-failed.service';

@Injectable()
export class LockVerificationFailedInterceptor implements HttpInterceptor {

  private readonly resourcePattern = /^\/api\/bids\/*/;
  private readonly routePatter = [/^\/standard\/*/, /^\/grid\/*/];

  constructor(
    private router: Router,
    private lockVerificationFailedService: LockVerificationFailedService) {
  }

  public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (!this.isBidApiRequest(request.url) || !this.isBidRoute(this.router.url)) {
      return next.handle(request);
    }

    return next.handle(request).pipe(
      catchError((response: HttpErrorResponse) => {
        if (LockVerificationFailedInterceptor.isLockVerificationFailedError(response)) {
          this.lockVerificationFailedService.showSessionExpiredModal();
        }

        return throwError(response);
      })
    );
  }

  private static isLockVerificationFailedError(response: HttpErrorResponse): boolean {
    return response.status === HttpStatusCode.FORBIDDEN &&
      response.error.code === HttpResponseErrorCode.LOCK_VERIFICATION_FAILED;
  }

  private isBidApiRequest(url: string): boolean {
    return this.resourcePattern.test(url);
  }

  private isBidRoute(url: string): boolean {
    return this.routePatter.some(pattern => pattern.test(url));
  }

}

export const lockVerificationFailedInterceptorProvider: Provider = {
  provide: HTTP_INTERCEPTORS,
  useClass: LockVerificationFailedInterceptor,
  multi: true
};
