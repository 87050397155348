import { DictionaryItem } from 'quantuvis-angular-common/interfaces';

export enum LatePaymentPenaltyBasisStatus {
  EMPTY = 205,
  PERCENT_OF_TOTAL_REBATES,
  PERCENT_OF_UNPAID_REBATES,
  FLAT_AMOUNT,
}

export namespace LatePaymentPenaltyBasisStatus {
  const valuesMap = new Map<LatePaymentPenaltyBasisStatus, DictionaryItem>([
    [LatePaymentPenaltyBasisStatus.EMPTY, { id: LatePaymentPenaltyBasisStatus.EMPTY, name: '' }],
    [
      LatePaymentPenaltyBasisStatus.PERCENT_OF_TOTAL_REBATES,
      { id: LatePaymentPenaltyBasisStatus.PERCENT_OF_TOTAL_REBATES, name: '% of total rebates' }],
    [
      LatePaymentPenaltyBasisStatus.PERCENT_OF_UNPAID_REBATES,
      { id: LatePaymentPenaltyBasisStatus.PERCENT_OF_UNPAID_REBATES, name: '% of unpaid rebates' }
    ],
    [
      LatePaymentPenaltyBasisStatus.FLAT_AMOUNT,
      { id: LatePaymentPenaltyBasisStatus.FLAT_AMOUNT, name: 'Flat Amount' }
    ],
  ]);

  export function getValue(price: LatePaymentPenaltyBasisStatus): DictionaryItem {
    return valuesMap.get(price);
  }

  export function getValues(): DictionaryItem[] {
    return Array.from(valuesMap.values());
  }
}

