import { Injectable } from '@angular/core';
import { BLOCKUI_DEFAULT, BlockUIService } from 'ng-block-ui';

@Injectable()
export class SpinnerService {

  constructor(private blockUIService: BlockUIService) { }

  /**
   * Starts blocking for given BlockUI instance or instances
   */
  public start(message?: any, target: string | string[] = BLOCKUI_DEFAULT): void {
    this.blockUIService.start(target, message);
  }

  /**
   * Stops blocking for given BlockUI instance or instances
   */
  public stop(target: string | string[] = BLOCKUI_DEFAULT): void {
    this.blockUIService.stop(target);
  }

  /**
   * Unsubscribes for given BlockUI instance or instances
   */
  public unsubscribe(target: string | string[]): void {
    this.blockUIService.unsubscribe(target);
  }

  /**
   * Checks if BlockUI is actively blocking
   */
  public isActive(target?: string | string[]): boolean {
    return this.blockUIService.isActive(target);
  }
}
