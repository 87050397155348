import { JsonObject, JsonProperty } from 'json2typescript';
import { Bid as BaseBid, BidAssignee, BidEditor, BidStatus, Company } from 'quantuvis-core-entities';
import { MomentConverter } from 'quantuvis-angular-common/json-converter/serializers';
import { Moment } from 'moment';

@JsonObject('Bid')
export class Bid extends BaseBid {
  @JsonProperty('id', Number)
  public id: number = null;

  @JsonProperty('mongoBidId', String)
  public mongoBidId: string = null;

  @JsonProperty('title', String)
  public title: string = null;

  @JsonProperty('assignee', String)
  public assignee: BidAssignee = null;

  @JsonProperty('status', String)
  public status: BidStatus = null;

  @JsonProperty('payer', Company)
  public payer: Company = null;

  @JsonProperty('manufacturerCompanyOldName', String)
  public manufacturerCompanyOldName: string = null;

  @JsonProperty('isBinding', Boolean)
  public isBinding: boolean = null;

  @JsonProperty('editRfpSent', Boolean)
  public editRfpSent: boolean = null;

  @JsonProperty('isFinal', Boolean)
  public isFinal: boolean = null;

  @JsonProperty('inReview', Boolean)
  public inReview: boolean = null;

  @JsonProperty('isArchived', Boolean)
  public isArchived: boolean = null;

  @JsonProperty('isInternal', Boolean)
  public isInternal: boolean = null;

  @JsonProperty('manufacturer', Company, true)
  public manufacturer?: Company = null;

  @JsonProperty('editor', BidEditor, true)
  public editor?: BidEditor = null;

  @JsonProperty('lineOfBusiness', String, true)
  public lineOfBusiness?: string = null;

  @JsonProperty('contractStartDate', MomentConverter, true)
  public contractStartDate?: Moment = null;

  @JsonProperty('contractEndDate', MomentConverter, true)
  public contractEndDate?: Moment = null;

  @JsonProperty('dateModified', MomentConverter, true)
  public dateModified?: Moment = null;

  @JsonProperty('rfpDueDate', MomentConverter, true)
  public rfpDueDate?: Moment = null;
}
