import he from 'he';

/**
 * Parser directive that polish field value
 *
 * @desc This directive decode field value each time when it was changed and
 *       remove invalid symbols, non-printable characters, except line breaks
 *
 * @link https://gist.github.com/Hihaj/ba90ccee0cde981655708ce1054f2a7a - strip non-printable unicode characters
 */
export const QvSmoothValue = (): any => ({
  restrict: 'A',
  require: 'ngModel',
  link: (scope, elem, attrs, ngModel) => {
    if (!ngModel) return;

    // Decode field value (trigger after value changing in different way)
    scope.$watch(attrs.ngModel, newValue => {
      if (newValue) {
        ngModel.$setViewValue(he.decode(newValue));
        ngModel.$render();
      }
    });

    // Remove invalid symbols (trigger after value changing using manual typing)
    ngModel.$parsers.push(value => {
      if (value) {
        const invalidSymbols = /(?![\x09\x0A\x0D])[\x00-\x1F\x7F]/g;
        const result = value.replace(invalidSymbols, ' ');

        if (result !== value) {
          ngModel.$setViewValue(result);
          ngModel.$render();
        }

        return result;
      }
    });
  }
});
