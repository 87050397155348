import { HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AcceptMessageData, BidVersion, Ndc, Scenario, ScenarioDependency, ScenariosValidate } from '@qv-bid/entities';
import { BidVersionType } from '@qv-bid/enums';
import { FilterState } from '@qv-bid/models';
import { HttpUtils } from '@qv-common/utils';
import { JsonConvert } from 'json2typescript';
import { Observable } from 'rxjs';
import { JSON_CONVERT_TOKEN } from 'quantuvis-angular-common/json-converter/services';
import { ApiService } from '@qv-common/services/api';
import { HttpResponseWrapper } from '@qv-common/models/api';
import { map } from 'rxjs/operators';
import { ViewPerspectiveService } from '@qv-common/services/auth';
import { ApiUrlPrefix } from '@qv-common/enums';

@Injectable()
export class BidVersionDaoService {

  constructor(
    private apiService: ApiService,
    @Inject(JSON_CONVERT_TOKEN) private jsonConvert: JsonConvert,
    private viewPerspectiveService: ViewPerspectiveService
  ) {
  }

  public getVersionsByType(bidId: number, versionType?: BidVersionType): Observable<BidVersion[]> {
    let url = `${ApiUrlPrefix.BIDS}/${bidId}/bid-versions`;
    url = HttpUtils.appendQueryParamToUrl(url, 'versionType', String(versionType));
    return this.apiService.get(url).pipe(
      map((response: HttpResponseWrapper<BidVersion>) =>
        this.jsonConvert.deserializeArray(response.body.data, BidVersion)
      ));
  }

  public getVersionsById(bidVersionId: number, isInternalBid: boolean): Observable<BidVersion> {
    let url = `${ApiUrlPrefix.BIDS}/bid-versions/${bidVersionId}`;
    const perspective = this.viewPerspectiveService.getViewPerspective();

    url = HttpUtils.appendPerspectiveQueryParamToUrlIfBidIsInternal(url, perspective, isInternalBid);

    return this.apiService.get(url).pipe(
      map((response: HttpResponseWrapper<BidVersion>) =>
        this.jsonConvert.deserializeObject(response.body.data, BidVersion)
      )
    );
  }

  public getLastVersion(bidId: number, isInternalBid: boolean): Observable<BidVersion> {
    let url = `${ApiUrlPrefix.BIDS}/${bidId}/bid-versions/current`;

    const perspective = this.viewPerspectiveService.getViewPerspective();
    url = HttpUtils.appendPerspectiveQueryParamToUrlIfBidIsInternal(url, perspective, isInternalBid);

    return this.apiService.get(url).pipe(
      map((response: HttpResponseWrapper<BidVersion>) =>
        this.jsonConvert.deserializeObject(response.body.data, BidVersion)
      )
    );
  }

  public getGeneralDrugScenariosOfVersion(
    versionId: number,
    filterState: FilterState,
    sort?: HttpParams
  ): Observable<Scenario[]> {
    const options = sort ? { params: sort } : null;

    return this.apiService.post(`${ApiUrlPrefix.BIDS}/bid-versions/${versionId}/scenarios/search`, filterState, options)
      .pipe(map((response: HttpResponseWrapper<any>) =>
        this.jsonConvert.deserializeArray(response.body.data, Scenario)
      ));
  }

  public getDrugScenariosOfVersion(versionId: number, scenarioIds: number[],
                                   isInternalBid: boolean, filterState: FilterState = new FilterState()): Observable<Scenario[]> {
    let url = `${ApiUrlPrefix.BIDS}/bid-versions/${versionId}/scenarios/scenario-drugs/search`;
    const state: FilterState = Object.assign(new FilterState(), filterState, { scenarioIds });

    const perspective = this.viewPerspectiveService.getViewPerspective();
    url = HttpUtils.appendPerspectiveQueryParamToUrlIfBidIsInternal(url, perspective, isInternalBid);

    return this.apiService.post(url, state).pipe(
      map((response: HttpResponseWrapper<any>) =>
        this.jsonConvert.deserializeArray(response.body.data, Scenario)
      ));
  }

  public getNdcsByScenariosOfVersion(versionId: number, scenarioId: number, isInternalBid: boolean,
                                     ndcsIds?: number[], sortParams?: HttpParams): Observable<Ndc[]> {
    let url = `${ApiUrlPrefix.BIDS}/bid-versions/${versionId}/scenarios/${scenarioId}/ndcs`;

    const perspective = this.viewPerspectiveService.getViewPerspective();
    url = HttpUtils.appendPerspectiveQueryParamToUrlIfBidIsInternal(url, perspective, isInternalBid);

    const params = HttpUtils.getMergedParams('ndcsIds', ndcsIds, sortParams);
    const options = params ? { params } : null;

    return this.apiService.get(url, options).pipe(
      map((response: HttpResponseWrapper<any>) => this.jsonConvert.deserializeArray(response.body.data, Ndc))
    );
  }

  public getDependencyListByDependencyIdOfVersion(versionId: number, dependencyId: number): Observable<ScenarioDependency> {
    return this.apiService.get(`${ApiUrlPrefix.BIDS}/bid-versions/${versionId}/dependencies/${dependencyId}`).pipe(
      map((response: HttpResponseWrapper<ScenarioDependency>) =>
        this.jsonConvert.deserializeObject(response.body.data, ScenarioDependency)
      ));
  }

  public checkBidValidity(versionId: number): Observable<ScenariosValidate> {
    return this.apiService.get(`${ApiUrlPrefix.BIDS}/bid-versions/${versionId}/scenarios/validate`).pipe(
      map((response: HttpResponseWrapper<ScenariosValidate>) =>
        this.jsonConvert.deserializeObject(response.body.data, ScenariosValidate))
    );
  }

  public getUniqueDrugList(versionId: number): Observable<Map<number, number[]>> {
    return this.apiService.get(`${ApiUrlPrefix.BIDS}/bid-versions/${versionId}/drugs`)
      .pipe(map((response: HttpResponseWrapper<{ [cbId: number]: number[] }>) => {
        const drugsMap = new Map();
        const data = response.body.data;

        Object.keys(data).forEach((cbId: string) => drugsMap.set(Number(cbId), data[cbId]));

        return drugsMap;
      }));
  }

  public getAcceptMessageData(bidVersionId: number, isInternalBid: boolean): Observable<AcceptMessageData> {
    let url = `${ApiUrlPrefix.BIDS}/bid-versions/${bidVersionId}/accept-message-data`;
    const perspective = this.viewPerspectiveService.getViewPerspective();

    url = HttpUtils.appendPerspectiveQueryParamToUrlIfBidIsInternal(url, perspective, isInternalBid);

    return this.apiService.get(url, null).pipe(
      map((res: HttpResponseWrapper<AcceptMessageData>) =>
        this.jsonConvert.deserializeObject(res.body.data, AcceptMessageData))
    );
  }
}

