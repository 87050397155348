import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { SwitchButtonConfig } from '@qv-shared/models';
import { MatButtonToggleChange } from '@angular/material/button-toggle';

@Component({
  selector: 'qv-view-switcher',
  templateUrl: './view-switcher.component.html',
  styleUrls: ['./view-switcher.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ViewSwitcherComponent implements OnChanges {
  @Input()
  public primaryButtonConfig: SwitchButtonConfig<any>;
  @Input()
  public secondaryButtonConfig: SwitchButtonConfig<any>;
  @Input()
  public initState: any;

  @Output()
  public stateChange = new EventEmitter();

  public state: any;

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.initState) {
      this.state = changes.initState.currentValue;
    }
  }

  public onChangeView(event: MatButtonToggleChange): void {
    this.state = event.value;
    this.stateChange.next(event.value);
  }
}
