<section class="qv-modal-dialog" *blockUI="'general-modal'">
  <header mat-dialog-title class="qv-modal-dialog-title">
    <h1 class="qv-modal-dialog-title__text">{{ modalData.title }}</h1>
    <button class="qv-modal-dialog-title__xbutton" (click)="onSecondaryAction()">
      <mat-icon>close</mat-icon>
    </button>
  </header>
  <div class="qv-modal-dialog-content qv-modal-dialog-content--font-light qv-modal-dialog-content--dependency">
    <div class="scenario-info">
      <div>Scenario: <b>{{ modalData.scenarioName }}</b></div>
      <div>Contracted Business: <b>{{ modalData.scenarios[0].cbName }}</b></div>
    </div>
    <div class="dependency-container">
      <div class="left-column">
        <div class="actions-bar">
          <button class="link-btn toggle-all" (click)="toggleAll()">
            {{isAllSelected() ? "Remove" : "Select" }} All
          </button>
        </div>
        <div class="list-panel">
          <qv-list-panel-header-search
            class="list-panel__header"
            headerTitle="Dependency:"
            placeholder="Search..."
            [control]="searchControl"
          ></qv-list-panel-header-search>
          <div class="list-panel__content">
            <div class="list-panel__item"
              [class.list-panel__item--selected]="scenarioSelection.isSelected(scenario.id)"
              *ngFor="let scenario of scenarios$ | async | orderBy: 'drugName'">
              <mat-checkbox class="qv-mat-checkbox"
                [checked]="scenarioSelection.isSelected(scenario.id)"
                (change)="scenarioSelection.toggle(scenario.id)"
                [disableRipple]="true">{{scenario.fullName}}
              </mat-checkbox>
            </div>
          </div>
        </div>
      </div>
      <div class="right-column">
        <div class="list-panel">
          <div class="list-panel__header">Selected:</div>
          <div class="list-panel__content">
            <div class="list-panel__item"
              *ngFor="let scenario of selectedScenarios$ | async | orderBy: 'drugName'">
              {{scenario.fullName}}
              <mat-icon class="list-panel__item--close-icon"
                (click)="scenarioSelection.deselect(scenario.id)">close</mat-icon>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <footer mat-dialog-actions class="qv-modal-dialog-actions qv-modal-dialog-actions--two-column">
    <div class="left-block">
      <button mat-button
        *ngIf="modalData.isManageMode"
        class="qv-modal-dialog-actions__item qv-modal-dialog-actions__item--delete"
        (click)="onDeleteAction()">
        Delete Dependency
      </button>
    </div>
    <div class="right-block">
      <button mat-button class="qv-modal-dialog-actions__item" (click)="onSecondaryAction()">
        Cancel
      </button>
      <button mat-button
        class="qv-modal-dialog-actions__item qv-modal-dialog-actions__item--primary"
        (click)="onPrimaryAction()"
        [disabled]="scenarioSelection.isEmpty() && !modalData.isManageMode">
        Save
      </button>
    </div>
  </footer>
</section>
