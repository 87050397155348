<section [matTooltip]="getDisabledTooltip()" class="qv-toggle">
  <div (click)="toggleWith(false)" class="qv-toggle__label">
    <div [matTooltip]="getFirstTooltip()"
         [ngClass]="{ 'qv-toggle__label--active': !checked }"
         class="qv-toggle__label">
      <div *ngIf="stringUtils.isString(firstOption)" [innerText]="firstOption"></div>
      <div *ngIf="coreUtils.isTemplateRef(secondOption)" [ngTemplateOutlet]="$any(firstOption)"></div>
    </div>
  </div>

  <label [for]="toggleId" class="qv-toggle__switch">
    <input (change)="toggleHandler()" [(ngModel)]="checked" [name]="toggleName" class="qv-toggle__input"
           disabled="{{disabled}}"
           id="{{toggleId}}"
           type="checkbox">
    <span [ngClass]="{ 'qv-toggle__slider--disabled': disabled }"
          class="qv-toggle__slider qv-toggle__slider--round"></span>
  </label>

  <div (click)="toggleWith(true)" class="qv-toggle__label">
    <div [matTooltip]="getSecondTooltip()"
         [ngClass]="{ 'qv-toggle__label--active': checked }"
         class="qv-toggle__label">
      <div *ngIf="stringUtils.isString(secondOption)" [innerText]="secondOption"></div>
      <div *ngIf="coreUtils.isTemplateRef(secondOption)" [ngTemplateOutlet]="$any(secondOption)"></div>
    </div>
  </div>
</section>
