import {
  ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild,
  OnInit, ChangeDetectorRef, SimpleChanges, OnChanges
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SvgIconName } from '@qv-common/enums';
import { NgSelectComponent } from '@ng-select/ng-select';
import { filter, finalize, take, tap } from 'rxjs/operators';
import sortBy from 'lodash.sortby';
import isEqual from 'lodash.isequal';
import { BidFilterService, StandardDrugFilterService } from '@qv-bid/services';
import { BidFilterName } from '@qv-bid/enums';
import { BidEventBusService } from '@qv-bid/services/bid-event-bus.service';

@UntilDestroy()
@Component({
  selector: 'qv-standard-drug-filter',
  templateUrl: './standard-drug-filter.component.html',
  styleUrls: ['./standard-drug-filter.component.scss', './standard-multi-select-override.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StandardDrugFilterComponent implements OnInit, OnChanges {
  @ViewChild(NgSelectComponent)
  public ngSelect: NgSelectComponent;
  @Input() public bidVersionId: number;
  @Input() public placeholder: string;
  @Input() public disabled: boolean;
  @Output() public applyChanges = new EventEmitter();

  public readonly multiple = true;
  public readonly closeOnSelect = false;
  public readonly visibleItemsNumber = 2;

  public filterControl = new FormControl([]);
  public items: string[] = [];
  public multiSelectItems: string[] = [];
  public lastItemsFromApplyChanges: string[] = [];
  public selectedItems = new BehaviorSubject<string[]>([]);
  public isLoading = false;

  public readonly svgIconName = SvgIconName;

  constructor(
    private bidEventBusService: BidEventBusService,
    private bidFilterService: BidFilterService,
    private standardDrugFilterService: StandardDrugFilterService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {}

  public ngOnInit(): void {
    this.initItems();
    this.initFilterControlHandler();
    this.initDeleteDrugNamesHandler();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.disabled) {
      changes.disabled.currentValue ? this.filterControl.disable() : this.filterControl.enable();
    }
  }

  public onOpen(): void {
    this.isLoading = true;
    this.standardDrugFilterService.updateFilter(this.bidVersionId)
      .pipe(
        tap((items: string[]) => {
          this.updateItems(items);
          this.changeDetectorRef.markForCheck();
        }),
        finalize(() => this.isLoading = false),
        untilDestroyed(this),
      )
      .subscribe();
  }

  public onApplyChanges(): void {
    this.selectedItems.pipe(
      take(1),
      filter((items: string[]) => !isEqual(this.lastItemsFromApplyChanges, items))
    ).subscribe((items: string[]) => this.applyChanges.next(this.lastItemsFromApplyChanges = items));
  }

  public onClear(): void {
    this.selectedItems.next([]);
    this.onApplyChanges();
    this.ngSelect.close();
  }

  private initDeleteDrugNamesHandler(): void {
    this.bidEventBusService.deleteDrugNamesFromFilterEvent.pipe(untilDestroyed(this))
      .subscribe((drugNames: string[]) => this.deleteDrugNamesFromFilter(drugNames));
  }

  private initItems(): void {
    const drugNames = this.getFilteredDrugNames();

    this.selectedItems.next(drugNames);
    this.lastItemsFromApplyChanges = drugNames;
  }

  private initFilterControlHandler(): void {
    const drugNames = this.getFilteredDrugNames();

    this.filterControl.setValue(drugNames);
    this.filterControl.valueChanges.pipe(untilDestroyed(this))
      .subscribe((items: string[]) => setTimeout(() => this.selectedItems.next(items)));
  }

  private getFilteredDrugNames(): string[] {
    return this.bidFilterService.getFilterStateValue()[BidFilterName.drugName] || [];
  }

  private updateItems(items: string[]): void {
    this.items = items;
    this.multiSelectItems = sortBy(items);
  }

  private deleteDrugNamesFromFilter(drugNames: string[]): void {
    const filterDrugNames = this.getFilteredDrugNames();

    if (filterDrugNames && filterDrugNames.length) {
      const updatedDrugNames = filterDrugNames.filter((drugName: string) => !drugNames.includes(drugName));
      this.bidFilterService.updateFilter(BidFilterName.drugName, updatedDrugNames);
    }
  }
}
