import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DrugPasteValuesModalData } from './drug-paste-values-modal-data';
import {
  DrugPasteValuesModalComponent
} from '@qv-bid/components/shared/drug-paste-values-modal/drug-paste-values-modal.component';
import { ModalConfig, ModalSize } from 'quantuvis-angular-common/modal';

export class DrugPasteValuesModalConfig
  extends ModalConfig<DrugPasteValuesModalComponent, DrugPasteValuesModalData> {

  constructor(data: DrugPasteValuesModalData) {
    super(data, ModalSize.MEDIUM);
  }

  protected createDialogRef(dialog: MatDialog): MatDialogRef<DrugPasteValuesModalComponent> {
    return dialog.open(DrugPasteValuesModalComponent, this);
  }
}
