/* tslint:disable:no-var-requires */
import CompanyController from './company.controller';

// @ts-ignore
import templateUrl from './createcompany.html';

const CreateCompanyComponent = {
  template: templateUrl,
  bindings: {
    initConfirmNavigation: '&'
  },
  controller: CompanyController
};

export default CreateCompanyComponent;
