import { AbstractSummaryController } from '../../shared/wizard/abstract-summary.controller';
import { constants } from '@qv-common/static';
import { SvgIconName } from '@qv-common/enums';
import { appConfig } from '@qv-common/configs';
import { UserService } from '@qv-common/services/auth/user.service';

class SummaryController extends AbstractSummaryController {
  public static $inject = ['translations', 'userService', 'util', 'CopyBidService', 'BidDetailsService', '$document'];
  private preserveOriginalLocks;
  private document;
  private svgIconName;
  private onValid: (arg) => {};

  constructor(private translations,
              private userService: UserService,
              private util,
              private CopyBidService,
              private BidDetailsService,
              $document) {
    'ngInject';
    super();
    this.preserveOriginalLocks = this.CopyBidService.bidConfig.summaryPreserveOriginalLocks;
    this.document = $document[0];
    this.svgIconName = SvgIconName;
  }

  public $onInit(): void {
    this.CopyBidService.prepareBidTerms();
    this.bid = this.CopyBidService.getBidConfig();
    this.translations.buildTranslations(this);
    this.updateBindingBidSummaryTerm();

    this.document.addEventListener(constants.EVENTS.TERM_VALUE_UPDATE, (event) => {
      this.updateDependentContractDateTerms(event.detail.term);
    });
  }

  public $onChanges(changes): void {
    if (changes.runValidation && changes.runValidation.currentValue) {
      this.onValid({ value: this.isValid() });
    }
  }

  public $onDestroy(): void {
    this.resetValue();
  }

  public getBidStatus(): any {
    return this.BidDetailsService.getStatus();
  }

  public getBidPayer(): any {
    return this.userService.user.getValue().company;
  }

  public lockOrUnlock(termsToLockOrUnlock, addGroupPath): void {
    this.CopyBidService.lockOrUnlock(termsToLockOrUnlock, addGroupPath);
  }

  public onPreserveLocksChange(): void {
    if (this.preserveOriginalLocks) {
      this.CopyBidService.removeLocksFromBidMetadata();
      this.bid.summary.forEach(term => {
        if (term.definition && term.definition.state) {
          delete term.definition.state;
        }
      });
    }
    this.bid.summaryPreserveOriginalLocks = this.preserveOriginalLocks;
  }

  public payerCompanySupportsBindingBid(): any {
    return this.util.payerCompanySupportsBindingBid(this.userService.user.getValue());
  }

  public resetValue(): void {
    this.bid.summary.forEach(term => {
      if (term.definition && typeof term.definition.resetValue === 'function') {
        term.definition.resetValue(term);
      }
    });
    this.preserveOriginalLocks = true;
    this.onPreserveLocksChange();
  }

  public updateBindingBidSummaryTerm(): void {
    for (const term of this.bid.summary) {
      if (term.name === constants.SUMMARY_TERMS.BINDING_BID.label
        && this.util.payerCompanySupportsBindingBid(this.userService.user.getValue())) {
        term.value = constants.BINDING_BID_TYPE.BINDING;
        term.definition.onChangeEvent(term, this);
      }
    }
  }

  public updateDependentContractDateTerms(term): void {
    if (!(term && term.name)) {
      return;
    }

    if (term.name === constants.SUMMARY_TERMS.CONTRACT_START_DATE.label) {
      this.updateContractDateTerm(term, constants.SUMMARY_TERMS.CONTRACT_END_DATE.label);
    } else if (term.name === constants.SUMMARY_TERMS.CONTRACT_END_DATE.label) {
      this.updateContractDateTerm(term, constants.SUMMARY_TERMS.CONTRACT_START_DATE.label);
    }
  }

  public updateContractDateTerm(term, termName): void {
    const dependingTerm = this.getTermByName(termName);
    dependingTerm.definition.keepOriginalValue = false;
    dependingTerm.definition.placeholder = appConfig.dateFormatShort;
  }
}

export default SummaryController;
