import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AppRoute } from '@qv-common/enums';
import { HttpStatusCode } from 'quantuvis-angular-common/api';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { PermissionService } from '@qv-common/services/auth/permission.service';
import { UserState } from '@qv-common/models/user';
import { ModalService } from 'quantuvis-angular-common/modal';
import { AuthService } from 'quantuvis-angular-common/auth';
import { UserService } from '@qv-common/services/auth/user.service';

@Injectable()
export class NoneAccessInterceptor implements HttpInterceptor {

  public readonly noneAccessErrorCode = 'none_access_rights';
  public readonly userNotActiveErrorCode = 'user_not_active';

  constructor(
    private authService: AuthService,
    private userService: UserService,
    private permissionService: PermissionService,
    private modalService: ModalService,
    private router: Router
  ) {}

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req)
      .pipe(
        catchError((response: HttpErrorResponse) => this.handleNoneAccessResponse(response))
      );
  }

  private handleNoneAccessResponse(response: HttpErrorResponse): Observable<never> {
    if (response.status === HttpStatusCode.FORBIDDEN) {
      if (response.error.code === this.noneAccessErrorCode) {
        this.redirectUserToAccessDeniedPage();
      } else if (response.error.code === this.userNotActiveErrorCode) {
        this.authService.logout();
      }
    }

    return throwError(response);
  }

  private updateUserRights(): void {
    this.userService.loadUserData().subscribe((user: UserState) => this.permissionService.updateUserRights(user));
  }

  private redirectUserToAccessDeniedPage(): void {
    this.modalService.closeAll();
    this.router.navigate([AppRoute.ACCESS_DENIED]);
    this.updateUserRights();
  }
}
