import { DrugAddModalComponent } from '@qv-bid/components/shared/drug-add-modal/drug-add-modal.component';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { DrugAddModalData } from '@qv-bid/components/shared/drug-add-modal/interfaces';

import { ContractedBusiness } from 'quantuvis-core-entities';
import { ModalConfig, ModalSize } from 'quantuvis-angular-common/modal';

export class DrugAddModalConfig
  extends ModalConfig<DrugAddModalComponent, DrugAddModalData> {

  constructor(
    bidVersionId: number,
    pharmaId: number,
    alreadyAddedDrugs: Map<number, number[]>,
    cbs: ContractedBusiness[],
    isBidInternal: boolean
  ) {
    super({ bidVersionId, pharmaId, alreadyAddedDrugs, cbs, isBidInternal }, ModalSize.X_LARGE);
  }

  protected createDialogRef(dialog: MatDialog): MatDialogRef<DrugAddModalComponent> {
    return dialog.open(DrugAddModalComponent, this);
  }
}
