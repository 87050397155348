import { Injectable, Provider } from '@angular/core';
import {
  HTTP_INTERCEPTORS,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpStatusCode } from 'quantuvis-angular-common/api';
import { AuthService } from 'quantuvis-angular-common/auth';
import { NavigationUtils } from 'quantuvis-angular-common/window-utils';
import { authConfig } from '@qv-common/configs';
import { NavigationService } from 'quantuvis-angular-common/navigation';

@Injectable()
export class UnauthorizedInterceptor implements HttpInterceptor {
  private readonly resourceListThatNeedsLogout = [
    new RegExp(/\/api\/bids.*\/unlock$/)
  ];

  constructor(
      private authService: AuthService,
      private navigationService: NavigationService,
  ) { }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(catchError((err: HttpErrorResponse) => this.handleStrategy(err)));
  }

  private handleStrategy(errorResponse: HttpErrorResponse): Observable<never> {
    if (errorResponse.status === HttpStatusCode.UNAUTHORIZED && !errorResponse.url.includes(authConfig.issuer)) {

      if (this.isPerformLogoutForUrl(errorResponse.url)) {
        this.authService.logout();
      } else {
        this.handleUnauthorizedError();
      }
    }

    return throwError(errorResponse);
  }

  private handleUnauthorizedError(): void {
    this.authService.clearSecureInfo();

    if (!this.navigationService.isPublicPage()) {
      NavigationUtils.setDeepLinkToCurrentUrl();

      this.authService.authorize();
    }
  }

  private isPerformLogoutForUrl(url: string): boolean {
    return this.resourceListThatNeedsLogout.some(
      (resourceRegex: RegExp) => resourceRegex.test(url)
    );
  }
}

export const unauthorizedInterceptorProvider: Provider = {
  provide: HTTP_INTERCEPTORS,
  useClass: UnauthorizedInterceptor,
  multi: true
};

