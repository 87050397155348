import {
  Component,
  Input,
  OnInit,
  ChangeDetectionStrategy,
  ViewChild,
  ElementRef,
  HostListener,
  ChangeDetectorRef
} from '@angular/core';
import { BaseTermWithLockComponent } from '@qv-term/components/base-term-with-lock/base-term-with-lock.component';
import { TermTemplateStorageService } from '@qv-term/services';
import { CoreUtils } from '@qv-common/utils';
import { NdcValue } from '@qv-term/entities';
import { FormControl } from '@angular/forms';
import { NumberOptions } from '@qv-common/models';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { UserService } from '@qv-common/services/auth/user.service';

@UntilDestroy()
@Component({
  selector: 'qv-term-input',
  templateUrl: './term-input.component.html',
  styleUrls: ['./term-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TermInputComponent extends BaseTermWithLockComponent implements OnInit {
  @Input()
  public termValue: string | number | NdcValue<string | number>;
  @Input()
  public termSuffix: string;
  @Input()
  public control: FormControl;
  @Input()
  public isFullWidth = false;
  @Input()
  public numberOptions = new NumberOptions(5, 2, 0.0001);

  @ViewChild('inputElement')
  public inputElement: ElementRef;

  public isUserPharma: boolean;

  constructor(
    protected termTemplateStorage: TermTemplateStorageService,
    userService: UserService,
    changeDetectorRef: ChangeDetectorRef
  ) {
    super(userService, termTemplateStorage, changeDetectorRef);
  }

  @HostListener('keydown', ['$event'])
  public handleKeyboardEvent(event: KeyboardEvent): void {
    event.stopPropagation();
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.initStatusChangesHandler();
  }

  public isNumberValue(): boolean {
    return CoreUtils.isNumber(this.getTermValue());
  }

  public hasTermSuffixAtInput(): boolean {
    return CoreUtils.isDefined(this.termSuffix) && !CoreUtils.isNull(this.termSuffix);
  }

  public hasTermSuffixAtConfig(): boolean {
    return Boolean(this.termConfig && this.termConfig.suffix);
  }

  public getTermSuffix(): string {
    if (this.hasTermSuffixAtInput()) {
      return this.termSuffix;
    } else if (this.hasTermSuffixAtConfig()) {
      return this.termConfig.suffix;
    } else {
      return '';
    }
  }

  public hasTermPrefix(): boolean {
    return this.termConfig && this.termConfig.prefix;
  }

  public getTermPrefix(): string {
    return this.hasTermPrefix() ? this.termConfig.prefix : '';
  }

  public focus(): void {
    this.inputElement.nativeElement.focus();
  }

  public isEditingAvailable(): boolean {
    return Boolean(this.inputElement) || super.isEditingAvailable();
  }

  public completeEditMode(event: Event): void {
    this.inputElement.nativeElement.blur();
    this.exitFromEditModeEvent.emit();
    event.stopPropagation();
  }

  public transferToEditMode(): void {
    this.focus();
  }

  public onBlur(): void {
    this.deactivateOverrideMode();
    this.exitFromEditModeEvent.emit();
  }

  private initStatusChangesHandler(): void {
    if (this.control) {
      this.control.statusChanges.pipe(untilDestroyed(this)).subscribe(() => this.changeDetectorRef.markForCheck());
    }
  }
}
