import { TextAreaTerm } from '@qv-term/models/types';
import { Comparator } from '@qv-common/interfaces';
import { constants, resources } from '@qv-common/static';
import { drugTermsConstants, umDetailsCheckControls } from '@qv-term/constants';
import { AbstractControl, AsyncValidatorFn, FormControl, FormGroup } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { TermName } from '@qv-term/enums';
import { map, tap } from 'rxjs/operators';
import { DictionaryItem } from '@qv-common/entities';
import { CoreUtils } from '@qv-common/utils';
import { GeneralModalData, ModalService } from 'quantuvis-angular-common/modal';

export class UmDetailsTerm extends TextAreaTerm implements Comparator {
  public isEditable: boolean;
  private readonly overrideModalData = new GeneralModalData(
    resources.GENERAL.CONFIRM_NAVIGATION_TITLE,
    resources.POPUPS.RESET_UM_DETAILS,
    resources.Actions.YES,
    resources.Actions.NO
  );

  constructor(name: string) {
    super(
      name,
      drugTermsConstants[TermName.UM_DETAILS].title,
      resources.TermsDescriptions.DRUG_LIST.UM_DETAILS,
      '',
      '',
      '',
      'term_um-details'
    );
    this.isEditable = true;
    this.maxLength = constants.COMMENTS_MAX_LENGTH;
  }

  public getTermValueForComparison(): string {
    return this.value;
  }

  public confirmChangeAndCheckUmDetailsToBeCleared(modalService: ModalService): AsyncValidatorFn {
    let previousValue = null;
    const checkValues = [1, 2];

    return (control: AbstractControl): Observable<null> => {
      if (!control.parent) {
        return of(null);
      }

      const umDetailsControl = control.parent.controls[TermName.UM_DETAILS];

      const areAllConditionsFulfilled = !this.isInstanceOfInitialType(control.value)
        && this.isPreviousValueDifferentFromCurrent(previousValue, control)
        && this.isLastCheckValues((control.parent as FormGroup), checkValues)
        && checkValues.includes(control.value.id)
        && this.umDetailsHasValueOrAtNdc(umDetailsControl);

      if (!areAllConditionsFulfilled) {
        previousValue = control.value;
        return of(null);
      }

      control.markAsPending();

      return modalService.openConfirmModal(this.overrideModalData).pipe(
        tap((isConfirm: boolean) => this.actionsAfterOpenModal(isConfirm, control, umDetailsControl, previousValue)),
        tap(() => previousValue = control.value),
        map(() => null)
      );
    };
  }

  private actionsAfterOpenModal(
    isConfirm: boolean, control: AbstractControl, umDetailsControl: FormControl, previousValue: Record<string, unknown>
  ): void {
    if (isConfirm) {
      umDetailsControl.setValue(this.defaultValue);
      control.updateValueAndValidity();
    } else {
      control.setValue(previousValue);
      umDetailsControl.updateValueAndValidity({ onlySelf: true });
    }
  }

  private umDetailsHasValueOrAtNdc(control: FormControl): boolean {
    return control.value && control.value.trim() || CoreUtils.isNull(control.value);
  }

  private isLastCheckValues(formGroup: FormGroup, checkValues: number[]): boolean {
    const selectsInUmSection = umDetailsCheckControls;
    const formRawValue = formGroup.getRawValue();

    return selectsInUmSection
      // TODO {} will be changed to check NDC Selects value in task ALLI-602
      .map((key: string) => (formRawValue[key] || {}).id)
      .every((value: number) => checkValues.includes(value));
  }

  private isPreviousValueDifferentFromCurrent(previousValue: number, control: AbstractControl): boolean {
    return previousValue !== control.value;
  }

  private isInstanceOfInitialType(value: Record<string, unknown> | DictionaryItem): boolean {
    return value instanceof DictionaryItem;
  }
}
