<ul *ngIf="(pharmaRights$ | async) as pharmaRights" class="pharma-rights">
  <li *ngFor="let pharmaRight of pharmaRights; let i = index"
      attr.data-test-id="test-id-summary-pharma-right-{{i}}"
  >
    <mat-checkbox
        (change)="changeHandler($event)"
        [checked]="pharmaRight.isActive"
        [disabled]="!isEditMode || isBidInvalid"
        [name]="pharmaRight.right"
        class="qv-mat-checkbox"
    >{{bidRight.displayValue(pharmaRight.right)}}</mat-checkbox>
  </li>
</ul>
