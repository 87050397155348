import { BaseEntity } from '@qv-bid/entities/base-entity';
import { DictionaryItem } from '@qv-common/entities';
import { DefaultState } from '@qv-common/enums/default-state.enum';
import { ValidateNumberOptions } from '@qv-common/models';
import { NumberUtils } from '@qv-common/utils';
import { NumberConverter } from '@qv-bid/serializers';
import { LatePaymentPenaltyBasis } from '@qv-term/enums/options';
import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('Penalty')
export class Penalty extends BaseEntity {

  @JsonProperty('cbId', Number)
  public contractedBusinessId: number = null;

  @JsonProperty('isLocked', Boolean)
  public penaltyLock = false;

  @JsonProperty('frequency', DictionaryItem)
  public penaltyFrequency: DictionaryItem = new DictionaryItem(DefaultState.EMPTY, '');

  @JsonProperty('basis', DictionaryItem)
  public basis: DictionaryItem = new DictionaryItem(DefaultState.EMPTY, '');

  @JsonProperty('value', NumberConverter)
  private value: number | string = null;

  public get setValue(): number | string {
    return NumberUtils.correctNumber(Penalty.getPenaltyValueNumberValidationOptions(this.basis.id), this.value);
  }

  public set setValue(value: number | string) {
    this.value = value;
  }

  public static getPenaltyValueNumberValidationOptions(id: number): ValidateNumberOptions {
    switch (id) {
      case LatePaymentPenaltyBasis.FLAT_AMOUNT: {
        return NumberUtils.defaultDollarOptions;
      }
      case LatePaymentPenaltyBasis.PERCENT_OF_TOTAL_REBATES:
      case LatePaymentPenaltyBasis.PERCENT_OF_UNPAID_REBATES: {
        return NumberUtils.defaultPercentOptions;
      }
      default: {
        return NumberUtils.defaultNumberOptions;
      }
    }
  }
}
