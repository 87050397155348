import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot } from '@angular/router';
import { AppRoute } from '@qv-common/enums';
import { ConfirmNavigation } from '@qv-common/interfaces';
import { resources } from '@qv-common/static';
import { Observable } from 'rxjs';
import { GeneralModalData, ModalService } from 'quantuvis-angular-common/modal';

@Injectable({
  providedIn: 'root'
})
export class ConfirmNavigationGuard implements CanDeactivate<ConfirmNavigation> {

  private readonly bypassRoutes = [AppRoute.ACCESS_DENIED];

  constructor(private modalService: ModalService) {}

  public canDeactivate(
    component: ConfirmNavigation,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot
  ): Observable<boolean> | boolean {
    if (this.isBypassRoute(nextState.url)) {
      return true;
    }

    if (component.isNavigationNeedConfirmation && component.isNavigationNeedConfirmation()) {
      const modalData = this.getModalData(component.confirmNavigationMessage);

      return this.modalService.openConfirmModal(modalData) as Observable<boolean>;
    }

    return true;
  }

  private getModalData(message: string): GeneralModalData {
    return new GeneralModalData(
      resources.GENERAL.CONFIRM_NAVIGATION_TITLE,
      message ? message : resources.GENERAL.CONFIRM_NAVIGATION_TEXT,
      resources.Actions.YES, resources.Actions.CANCEL);
  }

  private isBypassRoute(url: string): boolean {
    return this.bypassRoutes.some((route: string) => url.includes(route));
  }
}
