<div class="range-or-unit-and-rebate" *ngIf="!isEditMode">
  <div class="range-or-unit-and-rebate__body">
    <div class="range-or-unit-and-rebate__body-wrap">
      <span
        class="range-or-unit-and-rebate__value"
        [matTooltip]="getFormattedNumberAsString(rangeOrUnitAndRebate?.rangeStart)"
      >{{rangePrefix}}{{getFormattedNumberAsString(rangeOrUnitAndRebate?.rangeStart) | truncate: visibleRangeNumber}}{{rangeSuffix}}</span>
      <span class="range-or-unit-and-rebate__range-delimiter"> - </span>
      <span
          class="range-or-unit-and-rebate__value"
        [matTooltip]="getFormattedNumberAsString(rangeOrUnitAndRebate?.rangeEnd)"
      >{{rangePrefix}}{{getFormattedNumberAsString(rangeOrUnitAndRebate?.rangeEnd) | truncate: visibleRangeNumber}}{{rangeSuffix}}</span>
      <span class="range-or-unit-and-rebate__border"></span>
      <span
        class="range-or-unit-and-rebate__value"
        [matTooltip]="getFormattedNumberAsString(rangeOrUnitAndRebate?.rebate)"
      >
        {{getFormattedNumberAsString(rangeOrUnitAndRebate?.rebate) | truncate: visibleRangeNumber}}%
      </span>
    </div>
  </div>
</div>


<div *ngIf="isEditMode" class="range-or-unit-and-rebate range-or-unit-and-rebate--editable">
  <mat-form-field [floatLabel]="'never'">
    <qv-range-rebate-input
      [isBidInvalid]="isBidInvalid"
      [value]="rangeOrUnitAndRebate"
      [rangePrefix]="rangePrefix"
      [rangeSuffix]="rangeSuffix"
      [rangeValidationOptions]="rangeValidationOptions"
      [rebateValidationOptions]="rebateValidationOptions"
      (valueUpdated)="valueUpdated.emit($event)"
      (valueRemoved)="valueRemoved.emit($event)"
    ></qv-range-rebate-input>
  </mat-form-field>
</div>
