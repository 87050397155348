export enum BidToolbarAction {
  EDIT_BID = 'editBid',
  EDIT_RFP_SENT_BID = 'editRfpSentBid',
  DETAILS = 'details',
  SEND_BID = 'sendBid',
  SEND_FINAL_BID = 'sendFinalBid',
  DELETE_BID = 'deleteBid',
  INTEND_TO_RESPOND = 'intendToRespond',
  DECLINE_BID = 'declineBid',
  REVIEW_BID = 'reviewBid',
  ACCEPT_BID = 'acceptBid',
  DISCARD_DRAFT = 'discardDraft',
  REOPEN_BID = 'reopenBid',
  UNDO_BID = 'undoBid',
  REDO_BID = 'redoBid',
  COPY_BID = 'copyBid',
}
