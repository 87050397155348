import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { BidEventBusService } from '@qv-bid/services/bid-event-bus.service';
import { ErrorNotificationService } from '@qv-common/services';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { catchError, finalize } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { resources } from '@qv-common/static';
import { BlockingMessage } from '@qv-common/enums';
import { MarketBasketModal, MarketBasketModalOutput } from '@qv-bid/models';
import { MarketBasketModalComponent } from '../market-basket-modal/market-basket-modal.component';
import { MarketBasketModalState } from '@qv-bid/enums';
import { MarketBasketDaoService } from '@qv-bid/services/dao';
import { ModalSize } from 'quantuvis-angular-common/modal';

@Component({
  selector: 'qv-market-basket-preview',
  templateUrl: './market-basket-preview.component.html',
  styleUrls: ['./market-basket-preview.component.scss']
})
export class MarketBasketPreviewComponent extends MarketBasketModal implements OnInit {
  @Input()
  public manufacturerCompanyOldName: string;

  @BlockUI('market-basket-preview-modal')
  public blockUI: NgBlockUI;

  @Output()
  public stateChanged: EventEmitter<MarketBasketModalState> = new EventEmitter<MarketBasketModalState>();

  public readonly resources = resources;

  constructor(
    protected dialogRef: MatDialogRef<MarketBasketModalComponent>,
    protected errorNotificationService: ErrorNotificationService,
    protected bidEventBusService: BidEventBusService,
    private marketBasketDaoService: MarketBasketDaoService
  ) {
    super(dialogRef, errorNotificationService, bidEventBusService);
  }

  public ngOnInit(): void {
    this.dialogRef.updateSize(ModalSize.MEDIUM);
  }

  public onUnassign(): void {
    this.blockUI.start(BlockingMessage.SAVING);
    this.marketBasketDaoService.unassignFromScenario(this.scenarioId, this.isBidInternal)
      .pipe(
        catchError((errorResponse: HttpErrorResponse) => this.onSaveError(errorResponse)),
        finalize(() => this.blockUI.stop()),
      )
      .subscribe(() => {
        this.primaryAction.emit(new MarketBasketModalOutput());
        this.onClose();
      });
  }

  public onEditClick(): void {
    this.stateChanged.emit(MarketBasketModalState.EDIT);
  }

  public onImportClick(): void {
    this.stateChanged.emit(MarketBasketModalState.IMPORT);
  }
}
