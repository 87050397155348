import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('CompanyBase')
export class CompanyBase {
  @JsonProperty('id', Number)
  public id: number = null;

  @JsonProperty('name', String)
  public name: string = null;
}
