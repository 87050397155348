import { ContractDateConflictsModalData } from '@qv-shared/components/contract-date-conflicts-modal/models/contract-date-conflicts-modal-data';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ContractDateConflictsModalComponent } from '@qv-shared/components/contract-date-conflicts-modal/contract-date-conflicts-modal.component';
import { ModalConfig, ModalSize } from 'quantuvis-angular-common/modal';

export class ContractDateConflictsModalConfig
  extends ModalConfig<ContractDateConflictsModalComponent, ContractDateConflictsModalData> {

  public constructor(data: ContractDateConflictsModalData, width: ModalSize) {
    super(data, width);
  }

  protected createDialogRef(dialog: MatDialog): MatDialogRef<ContractDateConflictsModalComponent> {
    return dialog.open(ContractDateConflictsModalComponent, this);
  }
}
