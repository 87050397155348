import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild, } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { ActivatedRoute, NavigationEnd, Router, RouterEvent } from '@angular/router';
import {
  BidDetailsNotificationService,
  BidLockService,
  BidService,
  BidStateService,
  BidVersionService
} from '@qv-bid/services';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { delay, filter, map, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { ViewPerspectiveService } from '@qv-common/services/auth';
import { BidUtils } from '@qv-bid/utils';
import { BidViewService } from '@qv-bid/services/bid-view.service';
import { Bid, BidVersion } from '@qv-bid/entities';
import { User } from '@qv-user/entities/user.entity';
import { PermissionService } from '@qv-common/services/auth/permission.service';
import { BidActionsService } from '@qv-bid/services/bid-actions.service';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { BidToolbarAction } from '@qv-bid/enums/bid-toolbar-action.enum';
import { BidVersionType } from '@qv-bid/enums';
import { BidEventBusService } from '@qv-bid/services/bid-event-bus.service';
import { UserService } from '@qv-common/services/auth/user.service';

@UntilDestroy()
@Component({
  selector: 'qv-bid-view',
  templateUrl: './bid-view.component.html',
  styleUrls: ['./bid-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BidViewComponent implements OnInit {
  @ViewChild(MatSidenav, { static: true })
  public sidenav: MatSidenav;

  public isBidInvalid$: Observable<boolean>;
  public notificationsDelay = 200;
  public sidenavWasOpened$ = new BehaviorSubject<boolean>(false);

  constructor(
    private router: Router,
    private userService: UserService,
    private viewPerspectiveService: ViewPerspectiveService,
    private bidViewService: BidViewService,
    private bidStateService: BidStateService,
    private bidVersionService: BidVersionService,
    private bidDetailsNotificationService: BidDetailsNotificationService,
    private permissionService: PermissionService,
    private bidActionsService: BidActionsService,
    private bidEventBusService: BidEventBusService,
    private changeDetectorRef: ChangeDetectorRef,
    private bidService: BidService,
    private activatedRoute: ActivatedRoute,
    private bidLockService: BidLockService
  ) {}

  public ngOnInit(): void {
    this.initNavigationChangeListener();
    this.setEditMode();
    this.setReviewMode();
    this.calculateNotifications();
    this.openDetailsPanel();
    this.initViewPerspectiveEventListener();
    this.isBidInvalid$ = this.bidStateService.isBidInvalid().pipe(untilDestroyed(this));
    this.bidLockService.initLockHandler();
  }

  public handleToolbarAction(actionName: string): void {
    const { id, isBinding, isInternal } = this.bidStateService.bid$.value;

    switch (actionName) {
      case BidToolbarAction.DETAILS:
        this.sidenav.toggle();
        break;
      case BidToolbarAction.INTEND_TO_RESPOND:
        this.bidStateService.bid$.pipe(take(1))
          .subscribe((bid: Bid) => this.router.navigate(['/itr'], { queryParams: { bidId: bid.id } }));
        break;
      case BidToolbarAction.EDIT_RFP_SENT_BID:
      case BidToolbarAction.EDIT_BID:
        this.bidActionsService.editBid();
        break;
      case BidToolbarAction.REVIEW_BID:
        this.bidActionsService.reviewBid();
        break;
      case BidToolbarAction.DELETE_BID:
        this.bidActionsService.deleteBid().subscribe(() => this.onDeleteBidHandler());
        break;
      case BidToolbarAction.SEND_BID:
        this.bidActionsService.sendCurrentBid().subscribe(() => this.goToBidsPage());
        break;
      case BidToolbarAction.SEND_FINAL_BID:
        this.bidActionsService.sendFinalBid().subscribe(() => this.goToBidsPage());
        break;
      case BidToolbarAction.DECLINE_BID:
        this.bidActionsService.declineBid().subscribe();
        break;
      case BidToolbarAction.ACCEPT_BID:
        this.bidActionsService.acceptBid().subscribe(() => this.goToBidsPage());
        break;
      case BidToolbarAction.DISCARD_DRAFT:
        this.bidActionsService.discardDraft().subscribe();
        break;
      case BidToolbarAction.REOPEN_BID:
        this.bidActionsService.reopenBid().subscribe();
        break;
      case BidToolbarAction.UNDO_BID:
        this.bidActionsService.undoBid(id, isInternal);
        break;
      case BidToolbarAction.REDO_BID:
        this.bidActionsService.redoBid(id, isInternal);
        break;
      case BidToolbarAction.COPY_BID:
        this.router.navigate(['copy-bid'], { queryParams: { ids: [id], isBinding } });
        break;
      default: {}
    }
  }

  public getBid(): Observable<Bid> {
    return this.bidStateService.bid$.pipe(filter((bid: Bid) => Boolean(bid)));
  }

  public isBidVersionHistoric(): Observable<boolean> {
    return this.bidVersionService.getCurrentVersion.pipe(
      map((bidVersion: BidVersion) => bidVersion.versionType === BidVersionType.HISTORIC_VERSION)
    );
  }

  public onSidenavOpen(): void {
    if (this.sidenavWasOpened$.getValue()) return;

    this.sidenavWasOpened$.next(true);
  }

  private goToBidsPage(): void {
    this.router.navigate(['/bids']);
  }

  private setEditMode(): void {
    combineLatest([this.userService.user, this.getBid()]).pipe(
      take(1),
      map(([user, bid]) => BidUtils.isBidEditableByUser(user, bid, this.viewPerspectiveService.getViewPerspective())),
      filter((isBidEditableByUser: boolean) => isBidEditableByUser),
    ).subscribe(() => this.bidStateService.isEditMode.next(true));
  }

  private setReviewMode(): void {
    combineLatest([this.userService.user, this.getBid()]).pipe(
      take(1),
      map(([user, bid]) => BidUtils.isBidReviewedByUser(user, bid, this.viewPerspectiveService.getViewPerspective())),
      filter((isBidReviewedByUser: boolean) => isBidReviewedByUser)
    ).subscribe(() => this.bidStateService.isReviewMode.next(true));
  }

  private calculateNotifications(): void {
    this.getBid().pipe(
      take(1),
      delay(this.notificationsDelay),
      withLatestFrom(this.userService.user)
    ).subscribe(([bid, user]: [Bid, User]) => {
      if (!this.permissionService.hasUserWriteAccessToBid(bid.payer.id, bid.manufacturer.id)) {
        this.bidDetailsNotificationService.showWriteAccessToBidRestrictedNotification();
      } else if (BidUtils.isBidReviewingByOtherPerson(bid, user.id)) {
        this.bidDetailsNotificationService.showAnotherUserReviewingNotification(bid.editor.fullName);
      } else if (BidUtils.isBidEditingByOtherPerson(bid, user.id)) {
        this.bidDetailsNotificationService.showAnotherUserEditingNotification(bid.editor.fullName);
      }

      if (bid.isInternal) {
        this.bidDetailsNotificationService.showViewPerspectiveNotification(bid);
      }
    });
  }

  private openDetailsPanel(): void {
    this.getBid().pipe(
      take(1),
      map((bid: Bid) => this.userService.isCurrentUserPharma()
        && bid.hasAllowedForOpenDetailsPanel()
        && this.isCurrentVersion(this.bidStateService.bidVersionType)),
      filter((openDetailsPanel: boolean) => openDetailsPanel),
      switchMap(() => this.bidViewService.isReady$.pipe(
        filter((isReady: boolean) => isReady),
        take(1)
      )),
    ).subscribe(() => {
      this.sidenav.open();
      // Without setTimeout animation is spoiled
      setTimeout(() => this.changeDetectorRef.markForCheck());
    });
  }

  private onDeleteBidHandler(): void {
    this.bidService.currentBid$.next(null);
    this.goToBidsPage();
  }

  private isCurrentVersion(bidVersionType: BidVersionType): boolean {
    return bidVersionType === BidVersionType.CURRENT_VERSION;
  }

  private initViewPerspectiveEventListener(): void {
    this.viewPerspectiveService.switchPerspectiveEvent
      .pipe(
        tap(() => {
          this.bidEventBusService.quitEditModeEvent.emit();
          this.bidStateService.isReviewMode.next(false);

          this.router.navigate([],
            {
              relativeTo: this.activatedRoute,
              queryParams: { perspective: this.viewPerspectiveService.getViewPerspectiveFromStorage() },
              queryParamsHandling: 'merge'
            });
        }),
        untilDestroyed(this)
      )
      .subscribe();
  }

  private initNavigationChangeListener(): void {
    this.router.events.pipe(
      filter((event: RouterEvent) => event instanceof NavigationEnd),
      switchMap(() => this.bidViewService.reloadBidData()),
      untilDestroyed(this)
    ).subscribe();
  }
}
