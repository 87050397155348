export enum KeyCode {
  Up = 'Up',
  Down = 'Down',
  Left = 'Left',
  Right = 'Right',
  ArrowUp = 'ArrowUp',
  ArrowDown = 'ArrowDown',
  ArrowLeft = 'ArrowLeft',
  ArrowRight = 'ArrowRight',
  Enter = 'Enter',
  Escape = 'Escape',
  Tab = 'Tab',
  Shift = 'Shift',
  Backspace = 'Backspace',
}
