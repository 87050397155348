import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { take } from 'rxjs/operators';
import { Bid, Summary } from '@qv-bid/entities';
import { BidStateService } from '@qv-bid/services';
import { resources } from '@qv-common/static';
import { SummaryService } from '@qv-bid/services/summary';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { QvCache } from '@qv-common/decorators';
import { CoreUtils } from '@qv-common/utils';
import { PermissionService } from '@qv-common/services/auth/permission.service';
import { QuantuvisPlusFeature } from '@qv-company/enums';

@UntilDestroy()
@Component({
  selector: 'qv-bid-details-sidebar',
  templateUrl: './bid-details-sidebar.component.html',
  styleUrls: ['./bid-details-sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BidDetailsSidebarComponent implements OnInit {
  @Input()
  public bid: Bid;

  @Input()
  public sideNav: MatSidenav;

  public isBidInvalid$: Observable<boolean>;
  public isCommentsLoaded = false;
  public countUnreadComments: number | string;
  public readonly tooltips = resources.TermsDescriptions.SUMMARY;

  constructor(
    public permissionService: PermissionService,
    public bidStateService: BidStateService,
    public summaryService: SummaryService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
    this.isBidInvalid$ = this.bidStateService.isBidInvalid().pipe(untilDestroyed(this));
  }

  public ngOnInit(): void {
    this.initCountUnreadComments();
    this.initSideNavOpenedChange();
  }

  @QvCache()
  public isShowCountUnreadComments(counter: number, isCommentsLoaded: boolean): boolean {
    return Boolean(counter) && !isCommentsLoaded;
  }

  public onCommentsLoaded(): void {
    this.isCommentsLoaded = true;
  }

  public isInternalFieldsAvailable(): boolean {
    return this.permissionService.isFeatureAllowed(QuantuvisPlusFeature.INTERNAL_FIELDS);
  }

  private initSideNavOpenedChange(): void {
    this.sideNav.openedChange.pipe(untilDestroyed(this)).subscribe(() => this.changeDetectorRef.markForCheck());
  }

  private initCountUnreadComments(): void {
    this.summaryService.getSummary.pipe(take(1), untilDestroyed(this)).subscribe(({ countUnreadComments }: Summary) => {
      this.countUnreadComments = CoreUtils.getMaxDisplayedValue(countUnreadComments);
    });
  }
}
