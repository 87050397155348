import { JsonConverter, JsonCustomConvert } from 'json2typescript';
import { DropdownOption } from '../entities';
import { CompanyDropdownOptionResponse } from '../interfaces/company-dropdown-option-response';

@JsonConverter
export class DefaultDropdownOptionConverter implements JsonCustomConvert<DropdownOption> {
  public deserialize(data: CompanyDropdownOptionResponse[]): DropdownOption {
    const defaultOption = data.find(item => item.isDefault);
    const fieldOptionId = defaultOption.fieldOptionId;
    delete defaultOption.fieldOptionId;

    return { ...defaultOption, id: fieldOptionId };
  }

  public serialize(data: DropdownOption): CompanyDropdownOptionResponse[] {
    return [{ ...data, fieldOptionId: data.id }];
  }
}
