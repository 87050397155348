import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Drug, Ndc } from '@qv-bid/entities';
import { CurrencyPipe, DecimalPipe } from '@angular/common';
import { Wac } from '@qv-bid/models';
import { BidUtils } from '@qv-bid/utils';
import { QvCache } from '@qv-common/decorators';
import { constants } from '@qv-common/static';

@Component({
  selector: 'qv-wac',
  templateUrl: './wac.component.html',
  styleUrls: ['./wac.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WacComponent implements OnInit {
  @Input()
  public data: Drug | Ndc;
  public wac: Wac;
  private static readonly LOCALE = 'en-US';
  private static readonly PLUS_DELTA_SIGN = '+';
  private static readonly MINUS_DELTA_SIGN = '-';
  private static readonly CURRENCY_CODE = 'USD';
  private static readonly CURRENCY_SYMBOL = '$';
  private static readonly PERCENT_SYMBOL = '%';
  private static readonly DIGITS_2 = '1.2-2';
  private static readonly DIGITS_6 = '1.6-6';
  private static readonly ITEM_CLASS_NAME = 'wac__value';

  public ngOnInit(): void {
    this.wac = this.getWac();
  }

  @QvCache()
  public getWacColorClass(value: string, deltaSign?: string): string {
    if (BidUtils.isAtNdc(value)) return `${WacComponent.ITEM_CLASS_NAME}--ndc`;

    if (!deltaSign) return '';

    if (deltaSign === WacComponent.PLUS_DELTA_SIGN) {
      return `${WacComponent.ITEM_CLASS_NAME}--increase`;
    }
    if (deltaSign === WacComponent.MINUS_DELTA_SIGN) {
      return `${WacComponent.ITEM_CLASS_NAME}--decrease`;
    }
  }

  @QvCache()
  private static getTransformedCurrencyWac(value: number | string, digit: string = WacComponent.DIGITS_6): string {
    const currencyPipe = new CurrencyPipe(WacComponent.LOCALE);

    return typeof value === 'number'
      ? currencyPipe.transform(Math.abs(value), WacComponent.CURRENCY_CODE, WacComponent.CURRENCY_SYMBOL, digit)
      : value;
  }

  @QvCache()
  private static getTransformedPercentWac(value: number | string): string {
    const decimalPipe = new DecimalPipe(WacComponent.LOCALE);

    return typeof value === 'number'
      ? `${decimalPipe.transform(Math.abs(value), WacComponent.DIGITS_2)} ${WacComponent.PERCENT_SYMBOL}`
      : value;
  }

  private static isUnavailable(data: Drug | Ndc): boolean {
    return data.currentWAC === null ||
      data.versionWAC === null ||
      data.deltaWAC === null ||
      data.deltaPWAC === null;
  }

  @QvCache()
  private static getWacValue(value: number | string): number | string {
    return value === null ? constants.WAC.UNAVAILABLE : value;
  }

  @QvCache()
  private static getDeltaSign(value: number | string): string {
    if (value > 0) return WacComponent.PLUS_DELTA_SIGN;
    if (value < 0) return WacComponent.MINUS_DELTA_SIGN;
    return '';
  }

  private getWac(): Wac {
    if (!this.data) return;

    const currentWAC = WacComponent.getWacValue(this.data.currentWAC);
    const versionWAC = WacComponent.getWacValue(this.data.versionWAC);

    return {
      isUnavailable: WacComponent.isUnavailable(this.data),
      currentWAC: WacComponent.getTransformedCurrencyWac(currentWAC),
      versionWAC: WacComponent.getTransformedCurrencyWac(versionWAC),
      deltaWAC: WacComponent.getTransformedCurrencyWac(this.data.deltaWAC, WacComponent.DIGITS_2),
      deltaPWAC: WacComponent.getTransformedPercentWac(this.data.deltaPWAC),
      deltaSign: WacComponent.getDeltaSign(this.data.deltaWAC)
    };
  }
}
