<div
  class="checkbox-filter"
  (click)="$event.stopPropagation()"
>
  <div class="checkbox-filter__list">
    <acc-checkbox-list
      *ngIf="items.length"
      [items]="items"
      [selectedItems]="selectedItems"
      itemClass="acc-checkbox--primary"
      (changeEvent)="onChange($event)">
    </acc-checkbox-list>
  </div>

  <div *ngIf="!items.length" class="checkbox-filter__no-options">No Available options</div>

  <div class="checkbox-filter__actions">
    <button
      *ngIf="selectedItems?.length"
      mat-flat-button
      class="action-button action-button--secondary"
      (click)="onReset()"
    >Reset</button>
    <button
      mat-flat-button
      [disabled]="!isFilterChanged"
      class="action-button action-button--primary action-button--margin-0"
      (click)="onApply()"
    >Apply</button>
  </div>
</div>
