<mat-menu #actionMenu [overlapTrigger]="false" class="actions-menu">
  <ng-container *ngFor="let actionItem of menuActionItems">
    <button
        (click)="actionItem.handler()"
        *ngIf="actionItem.isAvailable$ | async"
        [disabled]="actionItem.isDisabled$ | async"
        attr.data-test-id="test-id-action-button-{{actionItem.title.split(' ').join('-')}}"
        class="actions-menu__item"
        mat-menu-item
    >{{actionItem.title}}</button>
  </ng-container>
</mat-menu>
<span [matMenuTriggerFor]="actionMenu"></span>
