import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges
} from '@angular/core';
import { CommonMessage, SvgIconName, FormValidationError } from '@qv-common/enums';
import { resources } from '@qv-common/static/resources';
import { Summary } from '@qv-bid/entities';
import { ViewPerspectiveService } from '@qv-common/services/auth';
import { SummaryService } from '@qv-bid/services/summary';
import { FormControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BidStateService } from '@qv-bid/services';
import { constants } from '@qv-common/static';
import { HtmlEditorService, SingleNotificationService } from '@qv-shared/services';
import { SnackBarService } from 'quantuvis-angular-common/snack-bar';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { BidEventBusService } from '@qv-bid/services/bid-event-bus.service';
import { BidUtils } from '@qv-bid/utils';
import { SingleNotification } from '@qv-shared/classes';
import { Toast } from 'ngx-toastr';
import {
  InstructionsModalConfig,
  InstructionsModalData
} from '@qv-bid/components/shared/summary-panel/instructions-modal/models';
import { QvCache } from '@qv-common/decorators';
import { HttpErrorResponse } from '@angular/common/http';
import { ModalService } from 'quantuvis-angular-common/modal';
import { NotificationService } from 'quantuvis-angular-common/notification';
import { UserService } from '@qv-common/services/auth/user.service';

@UntilDestroy()
@Component({
  selector: 'qv-instructions',
  templateUrl: './instructions.component.html',
  styleUrls: ['./instructions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [HtmlEditorService]
})
export class InstructionsComponent implements OnInit, OnChanges {
  @Input()
  public summary: Summary;
  @Input()
  public isEditMode: boolean;
  @Input()
  public isBidInvalid: boolean;

  public isPayerOrPayerPerspective: boolean;
  public instructionsControl: FormControl;
  public readonly resources = resources;
  public readonly svgIconName = SvgIconName;
  public readonly commonMessage = CommonMessage;
  public readonly validationMessages = new Map<string, string>([[
    FormValidationError.MAX_LENGTH,
    `Instructions are limited to ${constants.INSTRUCTIONS_MAX_LENGTH} characters. Please edit the text.`
  ]]);
  private singleNotification: SingleNotification<Toast>;

  constructor(
    private bidEventBusService: BidEventBusService,
    private userService: UserService,
    private summaryService: SummaryService,
    private notificationService: NotificationService,
    private changeDetectorRef: ChangeDetectorRef,
    private htmlEditorService: HtmlEditorService,
    private snackBarService: SnackBarService,
    private viewPerspectiveService: ViewPerspectiveService,
    private bidStateService: BidStateService,
    private modalService: ModalService,
    private singleNotificationService: SingleNotificationService
  ) {
    this.singleNotification = this.singleNotificationService.getInstance<Toast>();
  }

  public ngOnInit(): void {
    this.isPayerOrPayerPerspective = BidUtils.isUserPayerOrPayerPerspective(this.userService.isCurrentUserPayer(),
      this.bidStateService.bid$.getValue().isInternal, this.viewPerspectiveService.isPayerViewPerspective());
    this.initInstructionsControl();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.summary && !changes.summary.firstChange) {
      this.instructionsControl.setValue(this.summary.instructions, { emitEvent: false });
    }
  }

  public isPayerInEditMode(): boolean {
    return this.isEditMode && this.isPayerOrPayerPerspective;
  }

  public openInstructionsModal(): void {
    this.modalService.openModal(new InstructionsModalConfig(new InstructionsModalData(
      this.isPayerInEditMode(),
      this.summary.instructions,
      this.instructionsControl,
      this.validationMessages
    )));
  }

  @QvCache()
  public isInstructionsOpenModalDisabled(isBidInvalid: boolean, instructionsControlInvalid: boolean): boolean {
    return isBidInvalid || instructionsControlInvalid;
  }

  private initInstructionsControl(): void {
    this.instructionsControl = new FormControl(this.summary.instructions, {
      updateOn: 'blur',
      validators: this.htmlEditorService.maxLengthWithTags(constants.INSTRUCTIONS_MAX_LENGTH)
    });
    this.instructionsControl.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((instructions: string) => {
        if (this.instructionsControl.invalid) {
          this.singleNotification.toast(
            this.notificationService.error(resources.BID_DETAILS.INVALID_FIELDS_SAVING_ERROR)
          );
        } else {
          this.singleNotification.clear();
          this.updateSummaryInfoWithInstructions(instructions);
        }

        this.changeDetectorRef.markForCheck();
      });
  }

  private updateSummaryInfoWithInstructions(instructions: string): void {
    const updatedSummary = Object.assign(new Summary(), this.summary, { instructions });
    this.snackBarService.start();
    this.summaryService
      .updateSummaryInfo(this.summary.bidVersionId, this.summary.id, updatedSummary)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.snackBarService.error();
          return throwError(error);
        })
      )
      .subscribe((summary: Summary) => {
        this.summary = summary;
        this.bidEventBusService.undoRedoEvent.emit();
        this.snackBarService.finish();
      });
  }
}
