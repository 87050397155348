import { ConfirmNavigationInner } from '@qv-ng1-wrapper/classes';
import { BlockingMessage, SvgIconName } from '@qv-common/enums';
import { constants, resources } from '@qv-common/static';
import { ArrayUtils, CoreUtils } from '@qv-common/utils';
import { QuantuvisBusinessFeature, QuantuvisPlusFeature } from '@qv-company/enums';
import { ApiUrlPrefix } from '@qv-common/enums';

import { catchError, finalize, first, tap } from 'rxjs/operators';
import { HttpErrorResponse, HttpParams, HttpResponse } from '@angular/common/http';
import { forkJoin, of } from 'rxjs';
import isEqual from 'lodash.isequal';
import { AppRoute } from '@qv-common/enums';
import { GeneralModalConfig, GeneralModalData, ModalSize } from 'quantuvis-angular-common/modal';
import { imageMimeDefinitions } from '../../../shared/constants';
import { MimeDefinition } from '../../../shared/interfaces';
import { AuthService } from 'quantuvis-angular-common/auth';

declare let angular: angular.IAngularStatic;

const CompanyController = class CompanyController extends ConfirmNavigationInner {

    public static $inject = ['$scope', '$document', '$rootScope', 'timezone', 'router', 'util', 'translations',
    'permissionService', 'biddingUtilsService', 'spinnerService', 'modalService', 'apiService', 'authService'];
    public confirmNavigationMessage = resources.GENERAL.CONFIRM_NAVIGATION_TEXT;

    constructor(private $scope, $document, $rootScope, timezone, router, util, translations,
                permissionService, biddingUtilsService, spinnerService, private modalService,
                apiService, authService: AuthService) {
      super();
      $scope.router = router;
      $scope.svgIconName = SvgIconName;
      $scope.allow = permissionService.isAllowed.bind(permissionService);
      $scope.updateLogoResource = 'service/companies/updatelogo/';
      $scope.subsidiaries = [];
      $scope.selectedSub = [];
      $scope.addedSubsidiaries = [];
      $scope.foundDrugClasses = [];

      $scope.companyAdmin = {};
      $scope.companyUsers = [];
      $scope.company = {};
      $scope.company.subsidiaries = [];
      $scope.company.bindingBidEnabled = false;
      $scope.company.coverLetterEnabled = false;
      $scope.company.skipUserInvites = false;
      $scope.tempCompany = null;

      $scope.companyTypes = [];
      util.forEachObjectProperty(constants.COMPANY_TYPES, (key, value) => {
        $scope.companyTypes.push(value);
      });
      $scope.company.type = constants.COMPANY_TYPES.PAYER.value;
      $scope.isPayerCompany = isPayerCompany;
      $scope.isPharmaCompany = isPharmaCompany;
      $scope.isPayerInternalCompany = () => $scope.company
        && $scope.company.type === constants.COMPANY_TYPES.PAYER_INTERNAL.value;
      $scope.isPharmaOrPayerCompany = () => $scope.isPayerCompany() || $scope.isPharmaCompany();
      timezone.fetchTimezones($scope);
      $scope.disableSaveButton = false;
      $scope.ndcOptionList = Array.from({ length: 10 }, (_, i) => i + 1);
      $scope.packageTypes = constants.PACKAGE_TYPES;
      const acceptedLogoExtensions =  ['png', 'jpg', 'jpeg', 'bmp', 'gif'];
      $scope.acceptedLogoMimeTypes = imageMimeDefinitions.filter(
        (mimeType: MimeDefinition) => acceptedLogoExtensions.some((extension: string) => mimeType.format === extension)
      );

      $scope.qPlusPackageEnabled = true;
      $scope.qBusinessPackageEnabled = true;

      $scope.features = {
        [QuantuvisPlusFeature.COMPARE_OFFERED_BIDS]: true,
        [QuantuvisPlusFeature.BID_HISTORY]: true,
        [QuantuvisPlusFeature.REVISION_COMPARISON]: true,
        [QuantuvisPlusFeature.PRINT]: true,
        [QuantuvisPlusFeature.EXPORT]: true,
        [QuantuvisPlusFeature.STATUS_GRAPH]: true,
        [QuantuvisPlusFeature.PRINT_SCENARIO_SUMMARY]: false,
        [QuantuvisPlusFeature.INTERNAL_FIELDS]: false,
        [QuantuvisBusinessFeature.INTERNAL_BIDS]: false,
      };
      $scope.featureArray = [
        {
          display: 'Compare RFPs/Bids',
          key: QuantuvisPlusFeature.COMPARE_OFFERED_BIDS,
          package: $scope.packageTypes.QUANTUVIS_PLUS
        },
        {
          display: 'RFP/Bid History',
          key: QuantuvisPlusFeature.BID_HISTORY,
          package: $scope.packageTypes.QUANTUVIS_PLUS
        },
        {
          display: 'RFP/Bid Revision Comparison',
          key: QuantuvisPlusFeature.REVISION_COMPARISON,
          package: $scope.packageTypes.QUANTUVIS_PLUS
        },
        {
          display: 'Print RFPs/Bid',
          key: QuantuvisPlusFeature.PRINT,
          package: $scope.packageTypes.QUANTUVIS_PLUS
        },
        {
          display: 'Export RFPs/Bids',
          key: QuantuvisPlusFeature.EXPORT,
          package: $scope.packageTypes.QUANTUVIS_PLUS
        },
        {
          display: 'Status Graph',
          key: QuantuvisPlusFeature.STATUS_GRAPH,
          package: $scope.packageTypes.QUANTUVIS_PLUS
        },
        {
          display: 'Grid View',
          key: QuantuvisPlusFeature.GRID_VIEW,
          package: $scope.packageTypes.QUANTUVIS_PLUS
        },
        {
          display: 'Archived RFP/Bid Access',
          key: QuantuvisPlusFeature.ARCHIVED,
          package: $scope.packageTypes.QUANTUVIS_PLUS,
          filters: [$scope.isPharmaCompany],
          isDisabled: () => $scope.isPayerCompany()
        },
        {
          display: 'Internal Bids',
          key: QuantuvisBusinessFeature.INTERNAL_BIDS,
          package: $scope.packageTypes.QUANTUVIS_BUSINESS,
          filters: [$scope.isPharmaCompany]
        },
        {
          display: 'Print Scenario Summary',
          key: QuantuvisPlusFeature.PRINT_SCENARIO_SUMMARY,
          package: $scope.packageTypes.QUANTUVIS_PLUS,
        },
        {
          display: 'Internal Fields',
          key: QuantuvisPlusFeature.INTERNAL_FIELDS,
          package: $scope.packageTypes.QUANTUVIS_PLUS,
        },
      ];
      $scope.featuresUpdated = false;

      const handleClickOutside = ({ target }: Event) => {
        if (!$scope.foundClasses()) return;

        const dropdown = document.querySelector('.simple-drop');

        if (!dropdown.contains(target as HTMLElement)) {
          $scope.foundDrugClasses = [];
          refresh();
        }
      };

      $document.on('click', handleClickOutside);
      $scope.$on('$destroy', () => $document.off('click', handleClickOutside));

      $scope.featureChanged = (feature, value) => {
        if (feature === QuantuvisPlusFeature.BID_HISTORY && value === false) {
          $scope.features[QuantuvisPlusFeature.REVISION_COMPARISON] = false;
        }
        if (feature === QuantuvisPlusFeature.REVISION_COMPARISON && value === true) {
          $scope.features[QuantuvisPlusFeature.BID_HISTORY] = true;
        }

        processAllCheckbox();
      };

      $scope.checkAllPackage = (packageType, enabled) => {
        $scope.featureArray
          .filter(feature => feature.package === packageType)
          .map(feature => $scope.features[feature.key] = enabled);

        setDefaultFeatures();
      };

      translations.buildTranslations($scope, 'COMPANY_DETAILS_MSG');

      $scope.noSubsidiariesErrorMessage = $scope.i18n.COMPANY_SUBSIDIARIES;

      $scope.extractCompanyInformation = data => {
        Object.assign($scope.company, data);

        // change user status from INACTIVE to NEW if user has an activationToken
        $scope.company.companyUsers.forEach((user) => {
          if (biddingUtilsService.isUserInactive(user) && user.activationToken) {
            user.userStatus.id = -1;
            user.userStatus.name = constants.UserStates.NEW;
          }
          // decorate user with helper fields
          user.isUserInactive = biddingUtilsService.isUserInactive(user);
          user.isUserActive = biddingUtilsService.isUserActive(user);
          user.isUserNew = biddingUtilsService.isUserNew(user);
        });

        $scope.addedSubsidiaries = Object.assign([], data.subsidiaries);
      };

      $scope.getCompanyDetails = () => {
        apiService.get(`${ApiUrlPrefix.OLD}/companies/get/${$scope.company.id}`)
          .pipe(
            tap((response: HttpResponse<any>) => {
              const data = response.body;

              $scope.extractCompanyInformation(data.responseObject);
              $scope.company.name = slicePrefixInternal($scope.company.name);
              $scope.companyName = $scope.company.name;
              $scope.companiesResponse = undefined;
              $scope.isBindingBidOptionDisabled = $scope.company.bindingBidEnabled;

              if (isNotDefined($scope.company)) {
                $scope.companiesResponse = {
                  error: true,
                  message: 'There was an error while receiving companies information. Please try again later.'
                };
              }

              $scope.featureArray = $scope.featureArray.filter(feature => {
                if (!feature.filters || feature.filters.length < 1) {
                  return true;
                }
                return feature.filters.every(filter => typeof filter === 'function' && filter());
              });

              /**
               * notify fileUpload component to set the default file name for the input
               */
              if ((CoreUtils.isDefined($scope.company)
                // tslint:disable-next-line:triple-equals
                && ($scope.company.hasLogo == true || $scope.company.hasLogo == 'true'))) {
                $scope.$broadcast(constants.EVENTS.SET_DEFAULT_FILENAME, $scope.i18n.LOGO_UPLOADED);
              }

              const getAllCustomDrugs = () => {
                if (!$scope.isPharmaCompany()) {
                  return of(null);
                }

                return apiService.get(`${ApiUrlPrefix.OLD}/drugs/allCustom/${$scope.company.id}`)
                  .pipe(tap(({ body }: HttpResponse<any>) => {
                    if (body.error === false) {
                      $scope.customDrugs = processCustomDrugsList(body.responseObject);
                      delete $scope.response;
                    } else {
                      $scope.response = body;
                    }
                  }));
              };

              const getEnterpriseFeatures = () => {
                return apiService.get(`${ApiUrlPrefix.OLD}/enterpriseFeatures/get/${$scope.company.type}/${$scope.company.id}`)
                  .pipe(tap(({ body }: HttpResponse<any>) => {
                    body.forEach((feature) => {
                      $scope.features[feature.name] = feature.allow;
                    });
                    $scope.originalFeatures = angular.copy($scope.features);
                    $scope.featuresUpdated = true;
                  }));
              };

              forkJoin([
                getAllCustomDrugs(),
                getEnterpriseFeatures()
              ]).subscribe(() => {
                $scope.$apply(() => {
                  processAllCheckbox();
                  $scope.dataReady = true;
                  $scope.savedData = $scope.getCurrentCompanyData();
                });
              });
            }),
            catchError((error: HttpErrorResponse) => {
              $scope.company = undefined;
              $scope.companiesResponse = error;

              $scope.$apply(() => {
                $scope.dataReady = true;
                $scope.emptyCompany = true;
              });

              return of(null);
            })
          ).subscribe();
      };

      $scope.extractCompanyInfoFromUrl = () => {
        const params = router.routerState.snapshot.root.queryParams;

        if (params.companyType && params.companyId) {
          $scope.company.type = params.companyType;
          $scope.company.id = params.companyId;

          $scope.getCompanyDetails();
        }
      };

      $scope.extractCompanyInfoFromUrl();
      addLogoUploadDependency();

      function addLogoUploadDependency(): void {
        // listening to an event from file upload component when a file was selected
        $scope.$on(constants.EVENTS.FILE_SELECTED, (event, data) => {
          $scope.logoFile = data;
          $scope.logoErrorFlag = false;
          $scope.response = null;
        });
        // listening for an event from the upload component triggered after the file was uploaded
        $scope.$on(constants.EVENTS.FILE_UPLOADED, (event, uploadResponse) => {
          if (uploadResponse.error) {
            $scope.response = uploadResponse;
            $scope.logoErrorFlag = true;
            refresh();
          } else {
            $rootScope.response = {
              error: false,
              message: $scope.i18n.COMPANY_SAVED_SUCCESS_MSG
            };
            $scope.$apply(() => {
              router.navigate(['/administrator']);
            });
          }
        });
      }

      $scope.newCompany = () => {

        if (validateCompany()) {
          $scope.company.features = filterFeaturesForNewCompany();
          $scope.company.subsidiaries = $scope.addedSubsidiaries;
          $scope.company.companyUsers = [{
            firstName: $scope.companyAdmin.firstName,
            lastName: $scope.companyAdmin.lastName,
            title: $scope.companyAdmin.title,
            phone: $scope.companyAdmin.phone,
            email: $scope.companyAdmin.email,
            timezone: $scope.companyAdmin.timezone
          }];

          const companyData = util.removeEmptyStringPropertiesFromObject(angular.copy($scope.company));

          spinnerService.start(BlockingMessage.COMPANY_SAVING);

          apiService.post(`${ApiUrlPrefix.OLD}/companies/save`, companyData)
            .pipe(
              tap((response: HttpResponse<any>) => {
                const data = response.body;
                const savedCompany = data.responseObject;

                if (data.error === false) {
                  $scope.savedData = $scope.getCurrentCompanyData();
                  manageUploadLogo(savedCompany.id, data);
                } else {
                  spinnerService.stop();
                  $scope.response = data;
                }
              }),
              catchError((error: HttpErrorResponse) => {
                $scope.response = error;

                return of(null);
              }),
              finalize(() => {
                spinnerService.stop();
                refresh();
              })
            ).subscribe();
        }
      };

      $scope.updateCompany = () => {
        if (validateCompanyBeforeUpdate()) {
          updateCompanySubsidiaries();

          spinnerService.start('Updating Company...');

          const objectToSave = angular.copy($scope.company);

          const updateFeatures = {};
          Object.entries($scope.features).forEach(([key, value]) => {
            if ($scope.originalFeatures[key] !== value) {
              updateFeatures[key] = value;
            }
          });

          objectToSave.features = updateFeatures;

          apiService.post(`${ApiUrlPrefix.OLD}/companies/update`, objectToSave).pipe(
            tap((response: HttpResponse<any>) => {
              const data = response.body;

              if (data.error === false) {
                $scope.savedData = $scope.getCurrentCompanyData();
                manageUploadLogo($scope.company.id, data);
              } else {
                spinnerService.stop();
                $scope.response = data;
              }
            }),
            catchError((error: HttpErrorResponse) => {
              spinnerService.stop();
              $scope.response = error;
              refresh();

              return of(null);
            })
          ).subscribe();
        }
      };

      $scope.isUserInactive = () => biddingUtilsService.isUserInactive($scope.user);

      $scope.isUserActive = () => biddingUtilsService.isUserActive($scope.user);

      $scope.isUserNew = () => biddingUtilsService.isUserNew($scope.user);

      function processAllCheckbox(): void {
        let qPlusPackageEnabled = true;
        let qBusinessPackageEnabled = true;

        $scope.featureArray.forEach(feature => {
          switch (feature.package) {
            case $scope.packageTypes.QUANTUVIS_PLUS:
              qPlusPackageEnabled = qPlusPackageEnabled ? $scope.features[feature.key] : false;
              break;
            case $scope.packageTypes.QUANTUVIS_BUSINESS:
              qBusinessPackageEnabled = qBusinessPackageEnabled ? $scope.features[feature.key] : false;
              break;
          }
        });

        $scope.qPlusPackageEnabled = qPlusPackageEnabled;
        $scope.qBusinessPackageEnabled = qBusinessPackageEnabled;
      }

      function updateCompanySubsidiaries(): void {
        const removedSubsidiaries = ArrayUtils.difference($scope.company.subsidiaries, $scope.addedSubsidiaries);
        $scope.company.subsidiaries = Object.assign([], $scope.addedSubsidiaries);
        removedSubsidiaries.forEach((removedSubsidiary: any) => {
          removedSubsidiary.removed = true;
          $scope.company.subsidiaries.push(removedSubsidiary);
        });
      }

      /**
       * Method that manages the delayed file upload.
       * If there is a file selected than the upload will be triggered after
       * the save request is finished
       * @param companyId the company id that will be appended to upload URL
       * @param data the save/update request response to be displayed if there is no file to upload
       */
      function manageUploadLogo(companyId, data): void {
        if ($scope.logoFile) {
          const newUrl = authService.appendSecureInfoToUrl($scope.updateLogoResource + companyId);
          // notify the upload component that the upload file can be submitted
          $scope.$broadcast(constants.EVENTS.DELAYED_UPLOAD, newUrl);
        } else {
          spinnerService.stop();
          $rootScope.response = data;
          $scope.$apply(() => {
            router.navigate(['/administrator']);
          });
        }
      }

      $scope.cancelSearch = () => {
        $scope.subsidiaries = [];
      };

      /*
       * Deletes a subsidiary from the list of added subsidiaries.
       * If no subsidiary left in the list then the 'disableSaveButton'
       * variable will be set to true disabling the 'Save' button.
       */
      $scope.deleteSubsidiary = subsidiary => {
        $scope.addedSubsidiaries = $scope.addedSubsidiaries.filter(item => item !== subsidiary);
        $scope.disableSaveButton = $scope.addedSubsidiaries.length === 0;
      };

      /*
       * Adds a subsidiary to the list of subsidiaries if it was not already added and
       * the 'disableSaveButton' variable will be set to false.
       */
      $scope.addSubsidiaries = () => {
        $scope.selectedSub.forEach(elem => {
          if (!checkIfExistsById($scope.addedSubsidiaries, elem.id)) {
            $scope.addedSubsidiaries.push(elem);
            $scope.disableSaveButton = false;
          }

        });
        $scope.subsidiaries = [];
      };

      $scope.isSelected = data => {
        if (!checkIfExistsById($scope.selectedSub, data.id)) {
          $scope.selectedSub.push(data);
        } else {
          $scope.selectedSub = $scope.selectedSub.filter(item => item !== data);
        }
      };

      $scope.$watch('subsidiarySearchInfo', (newVal, oldVal) => {
        if (CoreUtils.isDefined(newVal) && newVal.length > 1) {
          const options = {
            params: new HttpParams().set('pattern', $scope.subsidiarySearchInfo)
          };
          apiService.get(`${ApiUrlPrefix.OLD}/manufacturers/searchSubsidiaries`, options).pipe(
            tap((response: HttpResponse<any>) => {
              $scope.subsidiaries = response.body;
              $scope.selectedSub = [];
              refresh();
            })
          ).subscribe();
        } else {
          $scope.subsidiaries = [];
        }
      });

      $scope.searchForDrugs = drugSearchInfo => {
        if (util.isNotEmpty(drugSearchInfo)) {
          const regexp = new RegExp(drugSearchInfo.toLowerCase());
          $scope.customDrugs.forEach(cDrug => {
            // tslint:disable-next-line:triple-equals
            if (regexp.test(cDrug.drugName.toLowerCase()) == false) {
              cDrug.showDrug = false;
            } else {
              cDrug.showDrug = true;
            }
          });
        } else {
          $scope.customDrugs.forEach(cDrug => cDrug.showDrug = true);
        }
      };

      $scope.foundClasses = () => util.isDefined($scope.foundDrugClasses) && $scope.foundDrugClasses.length;

      $scope.searchForDrugClasses = () => {
        if (util.isNotEmpty($scope.selectedDrug.drugClass) && $scope.selectedDrug.drugClass.length > 1) {
          const options = {
            params: new HttpParams().set('pattern', $scope.selectedDrug.drugClass)
          };

          apiService.get(`${ApiUrlPrefix.OLD}/drugs/searchClass/`, options).pipe(
            tap((response: HttpResponse<any>) => {
              $scope.foundDrugClasses = response.body.responseObject;
              refresh();
            })
          ).subscribe();
        } else {
          $scope.foundDrugClasses = [];
        }
      };

      $scope.isCustomDrugsListEmpty = () => !(util.isDefined($scope.customDrugs) && $scope.customDrugs.length);

      $scope.selectDrugClass = selectedDrugClass => {
        $scope.selectedDrug.drugClass = selectedDrugClass;
        $scope.foundDrugClasses = [];
      };

      $scope.foundSubsidiaries = () => $scope.subsidiaries.length > 0;

      $scope.hasInactiveSubsidiaries = () => $scope.addedSubsidiaries
        .some(companySubsidiary => (companySubsidiary.inactive === true || companySubsidiary.inactive === 'true'));

      $scope.selectDrug = drug => {
        $scope.selectedDrug = angular.copy(drug);
        $scope.editingDrug = true;
      };

      $scope.cancelDrugAction = () => {
        resetDrugSelection();
      };

      $scope.createNew = () => {
        $scope.createNewCustomDrug = true;
        $scope.selectedDrug = {};
      };

      $scope.saveCustomDrug = () => {
        if ($scope.isSavedAllowed()) {
          spinnerService.start('Saving Custom Drug...');

          if ($scope.selectedDrug && $scope.createNewCustomDrug) {
            createCustomDrug();
          } else if ($scope.selectedDrug && $scope.editingDrug) {
            saveCustomDrugInformation();
          }
        }
      };

      $scope.isSavedAllowed = () => {
        const selDrug = $scope.selectedDrug;
        return (CoreUtils.isNotDefined(selDrug) || !util.isNotEmpty(selDrug.drugName)
          || !util.isNotEmpty(selDrug.drugClass) || ($scope.createNewCustomDrug && !util
            .isNotEmpty(selDrug.ndcsNumber))) ? false : true;
      };

      $scope.deleteCustomDrug = cDrug => {
        $scope.drugToDelete = cDrug;
        showConfirmDeleteDrug();
      };

      $scope.editUser = user => {
        if (permissionService.isAllowed('edit_user')) {
          router.navigate(['editUser'], { queryParams: { userId: user.id } });
        }
      };

      $scope.goToEditRights = () => {
        const params = {
          type: encodeURIComponent($scope.company.type),
          id: encodeURIComponent($scope.company.id),
          name: encodeURIComponent($scope.company.name)
        };
        router.navigate(['rights'], { queryParams: params });
      };

      $scope.goToEditInternalFields = () => {
        const queryParams = {
          companyId: encodeURIComponent($scope.company.id),
          companyType: encodeURIComponent($scope.company.type)
        };
        router.navigate([AppRoute.INTERNAL_FIELDS], { queryParams });
      };

      $scope.getClassForStatus = user => {
        let classValue = 'info color ';

        if (user.userStatus === constants.UserStatus.DISABLED || user.userStatus === constants.UserStatus.UNINVITED) {
          classValue += 'red';
        } else if (user.userStatus === constants.UserStatus.ENROLLED) {
          classValue += 'green';
        } else if (user.userStatus === constants.UserStatus.INVITED_EXPIRED
          || user.userStatus === constants.UserStatus.TEMPORARILY_LOCKED) {
          classValue += 'orange';
        } else {
          classValue += 'purple';
        }

        return classValue;
      };

      $scope.getCurrentCompanyData = () => {
        const currentData = {
          companyType: $scope.company.type,
          companyName: $scope.company.name,
          companyPhone: $scope.company.phone,
          logoFile: $scope.logoFile,
          bindingBidEnabled: $scope.company.bindingBidEnabled,
          coverLetterEnabled: $scope.company.coverLetterEnabled,
          addedSubsidiaries: Object.assign([], $scope.addedSubsidiaries),
          companyAddress: $scope.company.address,
          companyAddress2: $scope.company.address2,
          companyCity: $scope.company.city,
          companyState: $scope.company.state,
          companyZip: $scope.company.zip,
          features: Object.assign({}, $scope.features),
          selectedDrug: CoreUtils.isNotDefined($scope.selectedDrug) ? null : Object.assign({}, $scope.selectedDrug),

          companyAdminFirstName: $scope.companyAdmin.firstName,
          companyAdminLastName: $scope.companyAdmin.lastName,
          companyAdminTitle: $scope.companyAdmin.title,
          companyAdminPhone: $scope.companyAdmin.phone,
          companyAdminEmail: $scope.companyAdmin.email,
          companyAdminTimezone: $scope.companyAdmin.timezone
        };

        angular.forEach(currentData, (value, key) => {
          // tslint:disable-next-line:triple-equals
          if (value == '' || CoreUtils.isNotDefined(value)) {
            currentData[key] = null;
          }
        });

        return currentData;
      };

      $scope.$watch('company.type', () => {
        setDefaultFeatures();
        processAllCheckbox();
      });

      init();

      /**
       * Method initializes the default variables
       */
      function init(): void {
        setDefaultFeatures();
        $scope.savedData = $scope.getCurrentCompanyData();
      }

      /**
       * Method that calls the create new custom drug service with information contained in $scope.selectedDrug
       */
      function createCustomDrug(): void {
        if (!isNaN($scope.selectedDrug.ndcsNumber)) {
          const customDrug = {
            drugName: $scope.selectedDrug.drugName,
            drugClass: $scope.selectedDrug.drugClass
          };
          const url = `${ApiUrlPrefix.OLD}/drugs/createCustom/${$scope.company.id}/${$scope.selectedDrug.ndcsNumber}`;

          apiService.post(url, customDrug).pipe(
            tap((response: HttpResponse<any>) => {
              const data = response.body;

              if (data.error === false) {
                $scope.customDrugs = processCustomDrugsList(data.responseObject);
                delete $scope.response;
              } else {
                setResponse(true, data.message);
              }
              resetDrugSelection();
            }),
            catchError((error: HttpErrorResponse) => {
              setResponse(true, error.message);

              return of(null);
            }),
            finalize(() => {
              spinnerService.stop();
              refresh();
            })
          ).subscribe();
        } else {
          setResponse(true, $scope.i18n.NDC_ERROR);
          spinnerService.stop();
        }
      }

      /**
       * Method that save the custom drug information contained in $scope.selectedDrug
       */
      function saveCustomDrugInformation(): void {
        const customDrugsToUpdate = [];
        $scope.selectedDrug.children.forEach(cDrug => {
          cDrug.drugName = $scope.selectedDrug.drugName;
          cDrug.drugClass = $scope.selectedDrug.drugClass;
          customDrugsToUpdate.push(cDrug);
        });

        apiService.post(`${ApiUrlPrefix.OLD}/drugs/saveCustom/${$scope.company.id}`, customDrugsToUpdate).pipe(
          tap((response: HttpResponse<any>) => {
            const data = response.body;

            if (data.error === false) {
              $scope.customDrugs = processCustomDrugsList(data.responseObject);
              delete $scope.response;
            } else {
              $scope.response = data;
            }
            resetDrugSelection();
          }),
          catchError((error: HttpErrorResponse) => {
            setResponse(true, error.message);

            return of(null);
          }),
          finalize(() => {
            spinnerService.stop();
            refresh();
          })
        ).subscribe();
      }

      function showConfirmDeleteDrug(): void {
        const modalData = new GeneralModalData(resources.POPUPS.TITLES.DELETE_CUSTOM_DRUG,
          `${ resources.POPUPS.CONFIRM_DELETE_CUSTOM_DRUG } ${ $scope.drugToDelete.drugName }?`,
          resources.Actions.YES.toUpperCase(), resources.Actions.NO.toUpperCase());
        const modalConfig = new GeneralModalConfig(modalData, ModalSize.MEDIUM);
        const dialogRef = modalService.openModal(modalConfig);

        dialogRef.componentInstance.primaryAction.subscribe(() => {
          apiService.post(`${ApiUrlPrefix.OLD}/drugs/deleteCustom/${ $scope.company.id }`, $scope.drugToDelete.children)
            .pipe(first()).toPromise().then((response) => {
            if (!response.body.error) {
              $scope.customDrugs = processCustomDrugsList(response.body.responseObject);
              resetDrugSelection();
              refresh();
            } else {
              $scope.response = response.body;
            }
          })
            .catch(() => {
            });
        });
      }

      /**
       * Verifies if the mandatory fields from company and company admin are filled in when creating a new company.
       *
       * @returns {boolean} true if all the mandatory
       * fields for company and company admins are filled in; false otherwise
       */
      function validateCompany(): boolean {
        let isValid = false;
        if (util.isNotDefined($scope.company.name) || util.isEmpty($scope.company.name)) {
          setResponse(true, $scope.i18n.COMPANY_NAME);
        } else if ($scope.isPayerInternalCompany()) {
          return isValid = true;
        } else if ($scope.isPharmaCompany()
          && (util.isNotDefined($scope.addedSubsidiaries) || $scope.addedSubsidiaries.length === 0)) {
          delete $scope.response;
          $scope.disableSaveButton = true;
        } else if (util.isNotDefined($scope.company.phone) || util.isEmpty($scope.company.phone)) {
          setResponse(true, $scope.i18n.COMPANY_PHONE_NUMBER);
        } else if (util.isNotDefined($scope.companyAdmin.firstName) || util.isEmpty($scope.companyAdmin.firstName)) {
          setResponse(true, $scope.i18n.COMPANY_ADMIN_FIRST_NAME);
        } else if (util.isNotDefined($scope.companyAdmin.title) || util.isEmpty($scope.companyAdmin.title)) {
          setResponse(true, $scope.i18n.COMPANY_ADMIN_TITLE);
        } else if (util.isNotDefined($scope.companyAdmin.phone) || util.isEmpty($scope.companyAdmin.phone)) {
          setResponse(true, $scope.i18n.COMPANY_ADMIN_PHONE);
        } else if (util.isNotDefined($scope.companyAdmin.lastName) || util.isEmpty($scope.companyAdmin.lastName)) {
          setResponse(true, $scope.i18n.COMPANY_ADMIN_LAST_NAME);
        } else if (util.isNotDefined($scope.companyAdmin.email) || util.isEmpty($scope.companyAdmin.email)) {
          setResponse(true, $scope.i18n.COMPANY_ADMIN_EMAIL);
        } else if (!util.validateEmailAddress($scope.companyAdmin.email)) {
          setResponse(true, $scope.i18n.COMPANY_ADMIN_INVALID_EMAIL);
        } else if (util.isNotDefined($scope.companyAdmin.timezone) || util.isEmpty($scope.companyAdmin.timezone)) {
          setResponse(true, $scope.i18n.COMPANY_ADMIN_TIMEZONE);
        } else {
          isValid = true;
        }

        return isValid;
      }

      /**
       * Verifies if the mandatory fields from company and company admins are filled in when updating a company.
       *
       * @returns {boolean} true if all the mandatory
       * fields for company and company admins are filled in; false otherwise
       */
      function validateCompanyBeforeUpdate(): boolean {
        let isValid = true;
        if (util.isNotDefined($scope.company.name) || util.isEmpty($scope.company.name)) {
          setResponse(true, $scope.i18n.COMPANY_NAME);
          isValid = false;
        } else if ($scope.isPayerInternalCompany()) {
          return isValid;
        } else if (util.isNotDefined($scope.company.phone) || util.isEmpty($scope.company.phone)) {
          setResponse(true, $scope.i18n.COMPANY_PHONE_NUMBER);
          isValid = false;
        }

        return isValid;
      }

      function setResponse(errorFlag, message): void {
        $scope.response = {
          error: errorFlag,
          message
        };
      }

      function refresh(): void {
        if (!$scope.$$phase) {
          $scope.$digest();
        }
      }

      function checkIfExistsById(arrayList, elemId): boolean {
        return arrayList.some(data => elemId === data.id);
      }

      function isNotDefined(toCheck): boolean {
        return CoreUtils.isNotDefined(toCheck) || CoreUtils.isNull(toCheck);
      }

      function processCustomDrugsList(customDrugsList): any {
        const customDrugGroupsMap = {};
        customDrugsList.forEach(cDrug => {
          if (CoreUtils.isNotDefined(customDrugGroupsMap[cDrug.drugName])) {
            const cDrugGroup = {
              drugName: cDrug.drugName,
              drugClass: cDrug.drugClass,
              ndcList: [
                cDrug.ndc
              ],
              showDrug: true,
              children: [
                cDrug
              ]
            };
            customDrugGroupsMap[cDrug.drugName] = cDrugGroup;
          } else {
            const cDrugGroup = customDrugGroupsMap[cDrug.drugName];
            cDrugGroup.ndcList.push(cDrug.ndc);
            cDrugGroup.children.push(cDrug);
          }
        });

        const customDrugGroups = [];
        for (const groupKey in customDrugGroupsMap) {
          customDrugGroups.push(customDrugGroupsMap[groupKey]);
        }

        return customDrugGroups;
      }

      function resetDrugSelection(): void {
        delete $scope.selectedDrug;
        $scope.createNewCustomDrug = false;
        $scope.customDrugs.forEach(cDrug => cDrug.showDrug = true);
      }

      /**
       * Method set the default features value according to the company type
       */
      function setDefaultFeatures(): void {
        if ($scope.isPayerCompany()) {
          $scope.features[QuantuvisPlusFeature.ARCHIVED] = true;
          restoreTempCompanyFlags();
        } else {
          saveTempCompanyFlags();
          $scope.company.bindingBidEnabled = false;
          $scope.company.coverLetterEnabled = false;
        }
      }

      function filterFeaturesForNewCompany(): any {
        const enabledFeatures = {};
        let allowedFeatures = $scope.featureArray;

        if ($scope.isPayerInternalCompany()) {
          return {};
        } else if ($scope.isPayerCompany()) {
          allowedFeatures = $scope.featureArray
            .filter(feature => feature.package === $scope.packageTypes.QUANTUVIS_PLUS);
        }

        allowedFeatures.map(feature => {
          enabledFeatures[feature.key] = $scope.features[feature.key];
        });

        return enabledFeatures;
      }

      function isPayerCompany(): boolean {
        return $scope.company
          && $scope.company.type.toLowerCase() === constants.COMPANY_TYPES.PAYER.value.toLowerCase();
      }

      function isPharmaCompany(): boolean {
        return $scope.company
          && $scope.company.type.toLowerCase() === constants.COMPANY_TYPES.PHARMA.value.toLowerCase();
      }

      function slicePrefixInternal(str): boolean {
        return str.replace(' (INTERNAL)', '');
      }

      function saveTempCompanyFlags(): void {
        if (!$scope.tempCompany) {
          $scope.tempCompany = {
            bindingBidEnabled: $scope.company.bindingBidEnabled,
            coverLetterEnabled: $scope.company.coverLetterEnabled
          };
        }
      }

      function restoreTempCompanyFlags(): void {
        if ($scope.tempCompany) {
          $scope.company.bindingBidEnabled = $scope.tempCompany.bindingBidEnabled;
          $scope.company.coverLetterEnabled = $scope.tempCompany.coverLetterEnabled;
          $scope.tempCompany = null;
        }
      }

    }

  public $onInit(): void {
    this.emitConfirmNavigationCallback();
  }

  public isNavigationNeedConfirmation(): boolean {
    return !isEqual(this.$scope.getCurrentCompanyData(), this.$scope.savedData);
  }
};
// tslint:disable-next-line:max-file-line-count
export default CompanyController;
