import { ChangeDetectionStrategy, Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl } from '@angular/forms';
import { SelectionModel } from '@angular/cdk/collections';
import { Scenario } from '@qv-bid/entities';
import { BehaviorSubject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ManageScenarioDependencyModalData } from './models';
import { GeneralModalComponent } from 'quantuvis-angular-common/modal';

@UntilDestroy()
@Component({
  selector: 'qv-manage-scenario-dependency-modal',
  templateUrl: './manage-scenario-dependency-modal.component.html',
  styleUrls: ['./manage-scenario-dependency-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ManageScenarioDependencyModalComponent extends GeneralModalComponent implements OnInit {
  public readonly searchControl = new FormControl();
  public readonly scenarioSelection = new SelectionModel<number>(true, [], true);
  public readonly scenarios$ = new BehaviorSubject<Scenario[]>([]);
  public readonly selectedScenarios$ = new BehaviorSubject<Scenario[]>([]);

  @Output()
  public deleteAction: EventEmitter<any> = new EventEmitter<any>();

  constructor(public dialogRef: MatDialogRef<ManageScenarioDependencyModalComponent>,
              @Inject(MAT_DIALOG_DATA) public modalData: ManageScenarioDependencyModalData | any) {
    super(dialogRef, modalData);
  }

  public ngOnInit(): void {
    this.scenarios$.next(this.modalData.scenarios);
    this.initSelectionChangeHandler();
    this.initControlChangesHandler();
    this.scenarioSelection.select(...this.getIdsFromScenarios(this.modalData.selectedScenarios));
  }

  public onPrimaryAction(): void {
    this.blockUI.start();
    if (this.scenarioSelection.isEmpty()) {
      this.onDeleteAction();
    } else {
      this.primaryAction.next(this.scenarioSelection.selected);
    }
  }

  public onDeleteAction(): void {
    this.blockUI.start();
    this.deleteAction.next();
  }

  public toggleAll(): void {
    this.isAllSelected()
      ? this.scenarioSelection.clear()
      : this.scenarioSelection.select(...this.getIdsFromScenarios(this.modalData.scenarios));
  }

  public isAllSelected(): boolean {
    return this.scenarioSelection.selected.length === this.modalData.scenarios.length;
  }

  private getIdsFromScenarios(scenarios: Scenario[]): number[] {
    return scenarios.map(({ id }: Scenario) => id);
  }

  private initControlChangesHandler(): void {
    this.searchControl.valueChanges.pipe(untilDestroyed(this))
      .subscribe((value: string) => this.filterScenarios(value));
  }

  private initSelectionChangeHandler(): void {
    this.scenarioSelection.changed.pipe(untilDestroyed(this))
      .subscribe(() => this.selectedScenarios$.next(this.calculateSelected()));
  }

  private calculateSelected(): Scenario[] {
    return this.modalData.scenarios.filter(({ id }: Scenario) => this.scenarioSelection.selected.includes(id));
  }

  private filterScenarios(value: string): void {
    this.scenarios$.next(this.modalData.scenarios.filter(({ fullName }: Scenario) =>
      fullName.toLowerCase().includes((value || '').toLowerCase())));
  }
}
