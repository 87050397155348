import { FormControl } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { SeverityLevel, SortCompareResult } from '@qv-common/enums';
import { ValidationError } from '@qv-common/models';
import { StringUtils } from '@qv-common/utils/string.utils';
import moment, { Moment } from 'moment';
import { appConfig } from '@qv-common/configs';

export class DateUtils {
  public static readonly MIN_DATE_PICKER_YEAR = 2000;
  public static readonly MAX_DATE_PICKER_YEAR = 2099;

  public static autoCorrectDateFormat(str: string): string {
    const pattern = new RegExp(
      '^(1[012]|0[1-9])(\\/{1,2}|\\-{1,2}|\\.{1,2})(0[1-9]|[12]\\d|3[01])\\2((?:19|20)?\\d{2})$'
    );
    const valid = pattern.test(str);

    if (valid) {
      let result = str.replace(/\/\//g, '/').replace(/\.{1,2}/g, '/').replace(/-{1,2}/g, '/');
      const parts = result.split('/');

      if (parts[2].length === 4) {
        result = `${parts[0]}/${parts[1]}/${parts[2].substring(2, parts[2].length)}`;
      }

      return result;
    }

    return undefined;
  }

  public static formatDate(date: Date): string {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const convertedMonth = (month < 10 ? '0' : '') + String(month);
    const convertedDay = (day < 10 ? '0' : '') + String(day);
    const convertedYear = date.getFullYear() % 100;

    return `${convertedMonth}/${convertedDay}/${convertedYear}`;
  }

  public static convertDateToUTC(date: Moment): Moment {
    return date instanceof moment ? date.utc(true) : null;
  }

  public static getUTCDate(): Moment {
    return moment.utc(moment.now());
  }

  public static validateComparingDates(firstDate: Moment, secondDate: Moment, firstName: string, secondName: string): ValidationError[] {

    const errors = [];
    if (!firstDate && secondDate) {
      errors.push(new ValidationError(SeverityLevel.WARN, `${firstName} doesn't match ${secondName}`));
    } else if (secondDate > firstDate) {
      errors.push(new ValidationError(SeverityLevel.WARN, `${firstName} is earlier than ${secondName}`));
    } else if (secondDate < firstDate) {
      errors.push(new ValidationError(SeverityLevel.WARN, `${firstName} is greater than ${secondName}`));
    }

    return errors;
  }

  public static convertDateToStr(event: MatDatepickerInputEvent<Moment>, control: FormControl, dateFormat: string): string {
    let dateStr = '';
    if (event.value instanceof moment) {
      dateStr = event.value.format(dateFormat);
    } else {
      const rawInputValue = (event.targetElement as HTMLInputElement).value;
      if (StringUtils.isEmpty(rawInputValue)) {
        control.setErrors(null, { emitEvent: false });
        control.setValue(null);
        return;
      }
      dateStr = DateUtils.autoCorrectDate(rawInputValue, control, dateFormat);
    }

    return dateStr;
  }

  public static getDateBasedOnFormattedString(date: string): Date {
    return moment(new Date(date)).utc(true).toDate();
  }

  public static autoCorrectDate(dateStr: string, control: FormControl, dateFormat: string): string {
    const momentDate = moment(dateStr, dateFormat);
    if (momentDate.isValid()) {
      return momentDate.format(dateFormat);
    }
    if (moment.isMoment(control.value) && control.value.isValid()) {
      return control.value.format(dateFormat);
    }

    return dateStr;
  }

  public static getFormattedDate(date: Moment, dateFormat: string): Moment {
    return date ? moment(DateUtils.convertDateToUTC(date), dateFormat) : null;
  }

  public static compareDatesForSort(dateA: Moment, dateB: Moment): SortCompareResult {
    return dateA.isAfter(dateB)
      ? SortCompareResult.LESS
      : dateA.isBefore(dateB) ? SortCompareResult.GREATER : SortCompareResult.EQUAL;
  }

  public static getMinDatePickerDate(): Date {
    return new Date(DateUtils.MIN_DATE_PICKER_YEAR, 0, 1);
  }

  public static getMaxDatePickerDate(): Date {
    return new Date(DateUtils.MAX_DATE_PICKER_YEAR, 11, 31);
  }

  public static formatToTimezone(date: Moment, timezone: string, format: string = appConfig.dateFormatShort): string {
    return date instanceof moment ? date.tz(timezone).format(format) : null;
  }
}
