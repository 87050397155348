/* tslint:disable */
import { CoreUtils, DateUtils, StringUtils } from '@qv-common/utils';
import { drugTermsConstants } from '@qv-term/constants';
import { TermName } from '@qv-term/enums';

export class TermUtils {

  /**
   * Obtains term value to be used for comparison purposes
   * @param term - term for which to determine comparison value
   * @param skipPrefix
   * @returns String representing term value to be used for comparison purposes
   */
  public static getTermValueForComparison(term, skipPrefix = false): string {
    let value = '';
    if (term && term.definition) {
      if (!term.definition.getTermValueForComparison) {
        value += skipPrefix || CoreUtils.isNotDefined(term.definition.prefix) ? '' : term.definition.prefix;
        if (term.definition.type == 2) {
          value += this.isNotDefinedOrNull(term.value)
            ? ''
            : Number.isNaN(Number(term.value)) || term.value === ''
              ? term.value
              : DateUtils.formatDate(new Date(Number(term.value)));
        } else {
          value += this.isNotDefinedOrNull(term.value) ? '' : term.value;
        }
        value += value === '' || CoreUtils.isNotDefined(term.definition.suffix) ? '' : term.definition.suffix;
        value = StringUtils.escape(value);

      } else {
        value += term.definition.getTermValueForComparison(term);
        if (term.name !== drugTermsConstants[TermName.RANGE_OR_UNITS_AND_REBATES].title) {
          value = StringUtils.escape(value);
        }
      }
    }
    return value;
  }

  /**
   * Get Baseline Wac Term for drug
   * @param drug
   * @returns baseline Wac Term
   */
  public static getBaselineWACTerm(drug): any {
    return drug.terms.find(childTerm => {
      return childTerm.name === drugTermsConstants[TermName.BASELINE_WAC].title;
    });
  }

  /**
   * Verifies if the provided object is 'undefined' or null.
   * @param toCheck object to verify
   * @returns {boolean} whether the object is undefined or null
   */
  public static isNotDefinedOrNull(toCheck): boolean {
    return CoreUtils.isNotDefined(toCheck) || CoreUtils.isNull(toCheck);
  };
}
