<div *blockUI="'template-modal'">
  <div mat-dialog-title class="qv-modal-dialog-title">
    <div class="qv-modal-dialog-title__text">{{modalData.title}}</div>
    <button class="qv-modal-dialog-title__xbutton" [mat-dialog-close]>
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div mat-dialog-content class="qv-modal-dialog-content" [ngClass]="nestedCmp?.contentClass">
    <ng-container #nestedComponent></ng-container>
  </div>
  <div mat-dialog-actions class="qv-modal-dialog-actions" [ngClass]="nestedCmp?.dialogActionsBlockClass">
    <button
      *ngIf="modalData.secondaryActionText"
      mat-button
      tabindex="-1"
      class="qv-modal-dialog-actions__item"
      (click)="onSecondaryAction()"
    >{{ modalData.secondaryActionText }}</button>
    <button
      mat-button
      tabindex="-1"
      class="qv-modal-dialog-actions__item qv-modal-dialog-actions__item--primary"
      (click)="onPrimaryAction()"
    >{{ modalData.primaryActionText }}</button>
  </div>
</div>
