/* tslint:disable */
import { constants, resources } from '@qv-common/static';
import { drugTermsConstants } from '@qv-term/constants';
import { TermName } from '@qv-term/enums';
import { biddingConstants } from '../constants';

export class BiddingUtilsService {
  allCheckedDrugs;
  shouldRefreshCheckedDrugsCollection;
  _isCreatingInternalBid;
  companyDisplayNameSymbol;

  constructor() {
    "ngInject";
    this.allCheckedDrugs = [];
    this.shouldRefreshCheckedDrugsCollection = true;
    this._isCreatingInternalBid = false;
    this.companyDisplayNameSymbol = Symbol("companyDisplayName");
  }

  /**
   * Verifies if the bid is assigned to a payer company.
   * @param bid the bid to verify
   * @returns {boolean} true is it is assigned, false otherwise.
   */
  isAssignedToPayer(bid) {
    return this.isAssignedToCompanyType(bid, constants.BidAssigneeTypes.PAYER);
  }

  /**
   * Verifies if the bid is assigned to a manufacturer company.
   * @param bid the bid to verify
   * @returns boolean true is it is assigned, false otherwise.
   */
  isAssignedToPharma(bid) {
    return this.isAssignedToCompanyType(bid, constants.BidAssigneeTypes.PHARMA);
  }

  /**
   * Verifies if the provided bid is assigned to the provided party.
   * @param bid the bid to verify
   * @param companyTpe the party on which the bid should be assigned
   * @returns boolean true if the bid is assigned to 'who', false otherwise
   */
  isAssignedToCompanyType(bid, companyTpe) {
    return !this.isNotDefined(bid) && !this.isNotDefined(bid.assignee)
      && bid.assignee.toLowerCase() === companyTpe.toLowerCase();
  }

  /**
   * Gets the id of the company on which the bid is currently assigned.
   * @param bid the id of the bid
   * @returns long the company id
   */
  getBidAssignedCompanyId(bid) {
    let companyId;
    if (this.isAssignedToPayer(bid) && !this.isNotDefined(bid.payer)) {
      companyId = bid.payer.companyId;
    } else if (this.isAssignedToPharma(bid) && !this.isNotDefined(bid.manufacturer)) {
      companyId = bid.manufacturer.companyId;
    }
    return companyId;
  }

  getBidAssignedCompanyName(bid) {
    if (this.isAssignedToPayer(bid) && bid.payer) {
      return bid.payer.name;
    }

    if (this.isAssignedToPharma(bid) && bid.manufacturer) {
      return bid.manufacturer.name;
    }
  }

  getBidSummaryAssignedCompanyId(bid) {
    let companyId;
    if (this.isAssignedToPayer(bid) && !this.isNotDefined(bid.payerCompanyId)) {
      companyId = bid.payerCompanyId;
    } else if (this.isAssignedToPharma(bid) && !this.isNotDefined(bid.manufacturerCompanyId)) {
      companyId = bid.manufacturerCompanyId;
    }
    return companyId;
  }

  /**
   * Gets the id of the other company on which the bid is currently not assigned.
   * @param bid the id of the bid
   * @returns long the company id
   */
  getBidNotAssignedCompanyId(bid) {
    let companyId;
    if (this.isAssignedToPayer(bid) && !this.isNotDefined(bid.manufacturer)) {
      companyId = bid.manufacturer.companyId;
    } else if (this.isAssignedToPharma(bid) && !this.isNotDefined(bid.payer)) {
      companyId = bid.payer.companyId;
    }
    return companyId;
  }

  /**
   * Verifies if the currently logged in use is a payer.
   * @returns boolean true if he is a payer, false otherwise
   */
  isCurrentUserPayer(user) {
    return this.isPayer(user);
  }

  /**
   * Verifies if the currently logged in use is a pharma.
   * @returns boolean true if he is a pharma, false otherwise
   */
  isCurrentUserPharma(user) {
    return this.isPharma(user);
  }

  /**
   * Verifies if the provided user is the current assignee of the bid.
   * @param user the user to verify
   * @param bid the bid against which to verify
   * @param perspective the user view perspective
   * @returns {boolean} true if the user is currently assigned to the bid, false otherwise
   */
  isCurrentUserAssigned(user, bid, perspective) {
    if (this.isAssignedToPayer(bid) && bid.payer && this.isPayerViewPerspective(perspective)) {
      return bid.payer.userId === user.id;
    } else if (this.isAssignedToPharma(bid) && bid.manufacturer && this.isPharmaViewPerspective(perspective)) {
      return bid.manufacturer.userId === user.id;
    }

    return false;
  }


  /**
   * Verifies if the specified user is a payer admin.
   *
   * @param user - the user to be checked
   * @returns {boolean} true if the user is a payer admin, false otherwise
   */
  isPayerAdmin(user) {
    return user.role.name === constants.RoleNames.PAYER_ADMIN;
  }

  /**
   * Verifies if the specified user is a pharma admin.
   *
   * @param user - the user to be checked
   * @returns {boolean} true if the user is a pharma admin, false otherwise
   */
  isPharmaAdmin(user) {
    return user.role.name === constants.RoleNames.PHARMA_ADMIN;
  }

  /**
   * Verifies if the user specified is disabled / inactive.
   *
   * @param user - the user to be checked
   * @returns {boolean} true if the user is disabled, false otherwise
   */
  isUserDisabled(user) {
    return user && user.disabled;
  }

  isUserInvited(user) {
    return user.userStatus
      && (user.userStatus === constants.UserStatus.INVITED ||
        user.userStatus === constants.UserStatus.INVITED_EXPIRED);
  }

  isUserUnInvited(user) {
    return user.userStatus && (user.userStatus === constants.UserStatus.UNINVITED);
  }

  /**
   * Verifies if the user specified is new (aka Invited).
   *
   * @param user - the user to be checked. If you have a user with status INACTIVE that has an activation token
   * you must change it's status to 'NEW' before using this method.
   * @returns {boolean} true if the user is new, false otherwise
   */
  isUserNew(user) {
    return user && user.userStatus && user.userStatus.name === constants.UserStates.NEW;
  }

  /**
   * Verifies if the user specified is inactive (aka Disabled).
   *
   * @param user - the user to be checked. If you have a user with status INACTIVE that has an activation token
   * you must change it's status to 'NEW' before using this method.
   * @returns {boolean} true if the user is inactive, false otherwise
   */
  isUserInactive(user) {
    return user && user.userStatus && (user.userStatus.name === constants.UserStates.INACTIVE);
  }

  /**
   * Verifies if the user specified is active (aka Enrolled).
   *
   * @param user - the user to be checked
   * @returns {boolean} true if the user is active, false otherwise
   */
  isUserActive(user) {
    return user && user.userStatus && (user.userStatus.name === constants.UserStates.ACTIVE);
  }

  /**
   * Gets the name of the company to which the user belongs.
   * @returns string company name
   */
  getCurrentUserCompanyName(user) {
    return user.company ? user.company.name : "";
  }

  /**
   * Checks to see if the user with the given ACLs has write access to the bid.
   */
  hasUserWriteAccessToBid(user, userACLs, bidManufacturerId, bidPayerId) {
    const readRight = constants.UserRights.READ.toUpperCase();
    const noneRight = constants.UserRights.NONE.toUpperCase();
    const userCompanyId = this.getCompanyId(user, userACLs, bidManufacturerId, bidPayerId);
    const userRight = userACLs.get(userCompanyId);

    return userRight !== readRight && userRight !== noneRight;
  }

  /**
   * Checks to see if the user with the given ACLs has at least READ access to the bid.
   */
  hasUserAtLeastReadAccessToBid(user, userACLs, bidManufacturerId, bidPayerId) {
    const noneRight = constants.UserRights.NONE.toUpperCase();
    const userCompanyId = this.getCompanyId(user, userACLs, bidManufacturerId, bidPayerId);
    const userRight = userACLs.get(userCompanyId);

    return userRight !== noneRight;
  }

  /**
   * Checks to see if the given user is payer and if yes checks to see if he/she has the type of access specified
   * on one company. Payer users can only have either READ or WRITE access for all PHARMA companies. If the user
   * is not payer or he/she has no rights the result will be false.
   */
  hasPayerUserAccess(user, userACLs, accessType) {
    if (this.isPayer(user)) {
      if (!userACLs) {
        return false;
      }
      const userCompanyId = Array.from(userACLs.keys())[0];
      const userRight = userACLs.get(userCompanyId);
      return userRight === accessType;
    }

    return false;
  }

  /**
   * Checks to see if the given user has at least one access right equal to accessType.
   * If accessType is undefined the method will return false.
   */
  hasUserAtLeastOneAccessRightEqualToOrGreater(user, userACLs, accessType) {
    if (!userACLs || this.isNotDefined(accessType)) {
      return false;
    }
    return Array.from(userACLs.values()).some((right: string) => right >= accessType);
  }

  /**
   * Verifies if the bid is internal
   */
  isCreatingInternalBid() {
    return this._isCreatingInternalBid;
  }

  /**
   * Set flag, is the bid is an internal
   */
  setCreatingInternalBid(isInternalBid) {
    this._isCreatingInternalBid = isInternalBid;
  };

  /**
   * Get flag, is the bid is internal
   * @param bid {boolean}
   */
  isInternalBid(bid) {
    return bid && bid.isInternal;
  }

  /**
   * Verifies if the user's company is the current assignee of the bid.
   *
   * @param user the user to be checked
   * @param bid  the bid
   * @returns {boolean} true if the user belongs to the payer or pharma company set on the bid; false otherwise
   */
  isCompanyIdsEquals(user, bid) {
    if (bid.assignee) {
      if (this.isAssignedToPharma(bid) && user.company) {
        return user.company.id === bid.manufacturer.companyId;
      }
      if (this.isAssignedToPayer(bid) && user.company) {
        return user.company.id === bid.payer.companyId || (bid.isInternal && user.company.id === bid.manufacturer.companyId);
      }
    }

    return false;
  }

  /**
   * Verifies if bid assignee, provided user company type and view perspective related to the same user.
   *
   * @param user
   *          - the user to be checked
   * @param bid
   *          - the bid to be checked
   * @param perspective
   *          - the user view perspective
   * @returns {boolean} true if bid assignee, provided user company type and view perspective related to the same
   *                    user; false otherwise.
   */
  isBidLockableForUser(user, bid, perspective) {
    if (bid.assignee) {
      if (this.isAssignedToPharma(bid) && user.company
        && user.company.companyType === constants.COMPANY_TYPES.PHARMA.value
        && this.isPharmaViewPerspective(perspective)) {

        return bid.manufacturer.userId === null;
      } else if (this.isAssignedToPayer(bid) && user.company
        && (user.company.companyType === constants.COMPANY_TYPES.PAYER.value
          || this.isPayerViewPerspective(perspective))) {

        return bid.payer.userId === null;
      }
    }
    return false;
  }

  /**
   * Verifies if the user's company is the assignee of the bid.
   *
   * @param user the user to be verified
   * @param bid the bid on which to check the assignee field
   * @returns {boolean} whether the given user's company is the current assignee of the bid
   */
  isUserCompanyAssigned(user, bid) {
    return this.isCompanyIdsEquals(user, bid);
  }

  /**
   * Verifies if the user's company is either the current bid assignee company or the other party.
   *
   * @param user the user for which to run the verification
   * @param bid the bid against which to verify
   * @returns {boolean} true if the user's company is either the current bid assignee company or the other party, false otherwise
   */
  isUserCompanyInBid(user, bid) {
    // TODO move logic from UI to Server

    // if this is a bid summary containing the assignedTo field
    if (this.isBidSummary(bid)) {
      return (bid.manufacturerCompanyId === user.company.id)
        || (bid.payerCompanyId === user.company.id);
    }

    return (bid.manufacturer && user.company && bid.manufacturer.companyId === user.company.id)
      || (bid.payer && user.company && bid.payer.companyId === user.company.id);

  }

  /**
   * Verifies is the current bid has in review status
   *
   * @param user
   *          - the Payer or Payer Internal for which to run the verification
   * @param bid
   *          - the bid against which to verify
   * @param perspective
   *          - the user view perspective
   * @returns {boolean} true if the user's company is either the current bid assignee company or the other party,
   *                    false otherwise
   */
  isReviewStatus(user, bid, perspective) {
    return (bid.isFinal && bid.inReview && this.isOpenAsPayerOrInternalPayer(user, perspective));
  }

  isOpenAsPayerOrInternalPayer(user, perspective) {
    return this.isCurrentUserPayer(user) || this.isPayerViewPerspective(perspective);
  }

  /**
   * Verifies if the provided user is the current assignee of the bid.
   * @param user the user to verify
   * @param bid the bid against which to verify
   * @returns {boolean} true if the user is currently assigned to the bid, false otherwise
   */
  isUserAssigned(user, bid) {
    let userAssigned = false;
    if (this.isAssignedToPayer(bid) && !this.isNotDefined(bid.payer)) {
      userAssigned = bid.payer.userId === user.id;
    } else if (this.isAssignedToPharma(bid) && !this.isNotDefined(bid.manufacturer)) {
      userAssigned = bid.manufacturer.userId === user.id;
    }

    return userAssigned;
  }

  /**
   * Verifies if the bid is in fact a bid summary used in the Bids List page.
   * @param bid the bid object to verify
   * @returns {boolean} true if it is a bid summary, false otherwise
   */
  isBidSummary(bid) {
    const bidType = bid.bidType && bid.bidType.toLowerCase();
    return bidType === constants.BidTypes.BID_SUMMARY.toLowerCase();
  }

  getFinalSuffix(bidObject) {
    return (!this.isNotDefined(bidObject.isFinal) && bidObject.isFinal)
      ? ` ${constants.BidStatusSuffix.FINAL}` : "";
  }

  getBindingSuffix(bidObject?, s?) {
    return (!this.isNotDefined(bidObject) && (!this.isNotDefined(bidObject.isBinding))
      && (bidObject.isBinding)) ? ` ${constants.BidStatusDisplayNameMap.BINDING}` : "";
  }

  /**
   * Gets the id of the company to which the user belongs.
   * @returns string the company id
   */
  getCurrentUserCompanyId(user) {
    return user.company ? user.company.id : "";

  }

  /**
   * Computes the bid status as it is displayed to the user, for the provided bidObject and the current user.
   *
   * @param bidObject
   *              - the bid for which to return the status display value
   * @param user
   *              - the current user
   * @param perspective
   *              - the user view perspective
   * @returns {String} the display value of the bid status for the current user
   */
  computeBidStatus(bidObject, user, perspective) {
    if (this.isNotDefined(bidObject) || this.isNotDefined(bidObject.status)
      || this.isNotDefined(bidObject.assignee) || this.isNotDefined(user)
      || this.isNotDefined(user.role) || this.isNotDefined(user.role.type)) {
      return null;
    }

    if ((bidObject.status.indexOf(constants.BidStatuses.ACCEPTED_WITH_CHANGES) > -1)
      || bidObject.status.indexOf(constants.BidStatuses.RESPONSE_REQUESTED) > -1) {

      // is the user's company is different than any of the companies the bid was created for
      if (!this.isUserCompanyInBid(user, bidObject)) {
        return null;
      }

      if (!perspective) {
        throw new Error(`View perspective is not defined: ${perspective}`);
      }

      if (this.isReviewStatus(user, bidObject, perspective)) {
        return constants.BidStatusDisplayNameMap.REVIEW + this.getBindingSuffix(bidObject, user);
      }

      if (this.isUserTypeBidAssignee(user, bidObject, perspective)) {
        return constants.BidStatusDisplayNameMap[bidObject.status][1] + this.getFinalSuffix(bidObject) + this.getBindingSuffix(bidObject, user);
      } else {
        return constants.BidStatusDisplayNameMap[bidObject.status][0] + this.getFinalSuffix(bidObject) + this.getBindingSuffix(bidObject, user);
      }
    }
    return constants.BidStatusDisplayNameMap[bidObject.status] + this.getBindingSuffix(bidObject, user);
  }

  /**
   * Computes the bid type of the bid (binding or non binding)
   * @param bid the bid for which to return the type display value
   * @returns {String} the display value of the bid type
   */
  computeBindingBidType(bid) {
    if (this.isNotDefined(bid) || this.isNotDefined(bid.summaryTerms)) {
      return constants.BINDING_BID_TYPE.NON_BINDING;
    }
    const bindingBidLabel = constants.SUMMARY_TERMS.BINDING_BID.label;
    const bindBid = bid.summaryTerms.find(term => term.name === bindingBidLabel);

    if (!this.isNotDefined(bindBid) && !this.isNotDefined(bindBid.value) && bindBid.value) {
      return constants.BINDING_BID_TYPE.BINDING;
    }

    return constants.BINDING_BID_TYPE.NON_BINDING;
  }

  /**
   * Computes the bid type of the bid term
   * @param summaryTerms the bid for which to return the type display value
   * @returns {String} the display value of the bid type
   */
  computeBindingBidTypeForTerm(summaryTerms) {
    const bidTerm = this.getTermByName(constants.SUMMARY_TERMS.BINDING_BID.label, summaryTerms);
    if (!this.isNotDefined(bidTerm) && !this.isNotDefined(bidTerm.value) && bidTerm.value) {
      return constants.BINDING_BID_TYPE.BINDING;
    }
    return constants.BINDING_BID_TYPE.NON_BINDING;
  }

  /**
   * Method return the company object related to logged in user and view perspective
   *
   * @param user
   *          - the user to be checked
   * @param bid
   *          - the bid for which to return the check value
   * @param perspective
   *          - the user view perspective
   * @returns {{type: string, name: string}} the company object related to logged in user and view perspective
   */
  getCompanyForNewAssignee(user, bid, perspective) {
    const company = {
      "type": "",
      "name": ""
    };

    if (user && bid && user.role.type) {
      if ((this.isPayer(user) && bid.manufacturer)
        || (bid.isInternal && this.isPayerViewPerspective(perspective))) {
        company.type = constants.BIDDING.MANUFACTURER.toUpperCase();
        company.name = bid.manufacturer.name;
      } else if ((this.isPharma(user) && bid.payer)
        || (bid.isInternal && this.isPharmaViewPerspective(perspective))) {
        company.type = constants.BidAssigneeTypes.PAYER;
        company.name = bid.payer.name;
      }
    }

    return company;
  }

  /**
   * Returns payer or pharma company data according to view perspective
   *
   * @param {object} bidInfo
   * @param {string} perspective
   * @returns {object}
   */
  getActingCompanyByViewPerspective(bidInfo, perspective) {
    return this.isPayerViewPerspective(perspective) ? bidInfo.payer : bidInfo.manufacturer;
  }

  /**
   * Append old company name if exists
   * @param name current company name
   * @param oldCompanyName
   * @returns {string} name (oldCompanyName)
   */
  addOldName(name, oldCompanyName) {
    if (!this.isNotDefined(oldCompanyName)) {
      return `${name} (${oldCompanyName})`;
    }

    return name;
  }

  /**
   * Add old company name to 'companyDisplayName' for merged manufacturers; keep companyName for others
   * @param termValues {array} array of objects; assuming object has 'companyName' property
   * @param manufacturer {Object} current bid's manufacturer object; assuming object has 'oldCompanyName' appended to 'name'
   * @returns {Array}
   */
  getDisplayCompanyName(termValues, manufacturer) {
    const processedTermValues = [];

    if (Array.isArray(termValues)) {
      termValues.forEach((item) => {
        if (manufacturer.currentName && (item.companyName === manufacturer.currentName)) {
          // Add old company name if company has one (eg: merged pharma)
          item[this.companyDisplayNameSymbol] = manufacturer.name;
        } else {
          item[this.companyDisplayNameSymbol] = item.companyName;
        }
        processedTermValues.push(item);
      });
    }

    return processedTermValues;
  }

  /**
   * Searches for a term by name
   * @param termName the term name
   * @param terms a list of terms
   * @returns the matching term
   */
  getTermByName(termName, terms) {
    return terms.find(item => item.name === termName);
  }

  /**
   * Searches for a term by name and returns it's value
   * @param termName the term name
   * @param terms a list of terms
   * @returns {string} value of the matching term, otherwise ''
   */
  getTermByNameValue(termName, terms) {
    const term = this.getTermByName(termName, terms);
    return term ? term.value : "";
  }

  /**
   * Verifies if bid has displayed status starts with ns.Constants.BidStatusDisplayNameMap.RFP_NOT_SENT.
   *
   * @return {Boolean}  'true' if the bidInfo status starts with
   *     ns.Constants.BidStatusDisplayNameMap.RFP_NOT_SENT;
   *                    'false' otherwise or if it's null (eg: at create rfp)
   */
  isBidDisplayedStatusRFPNotSent(bidStatus) {
    return bidStatus ? (bidStatus.indexOf(constants.BidStatusDisplayNameMap.RFP_NOT_SENT) > -1) : bidStatus;
  }

  /**
   * Verifies if bid has status equals to {@link constants.BidStatuses.RFP_NOT_SENT}
   *
   * @return {Boolean} true if the bid status is equals to {@link ns.Constants.BidStatuses.RFP_NOT_SENT};
   *                   false otherwise
   */
  isBidStatusRfpNotSent(bid) {
    return bid && bid.status && bid.status === constants.BidStatuses.RFP_NOT_SENT;
  }

  /**
   * Verifies if bid has status equals to {@link constants.BidStatuses.DRAFT}
   *
   * @return {Boolean} true if the bid status is equals to {@link ns.Constants.BidStatuses.DRAFT}; false otherwise
   */
  isBidStatusDraft(bid) {
    return bid && bid.status && bid.status === constants.BidStatuses.DRAFT;
  }

  /**
   * Verifies if bid has status equals to {@link constants.BidStatuses.RESPONSE_REQUESTED}
   *
   * @return {Boolean} true if the bid status is equals to {@link ns.Constants.BidStatuses.RESPONSE_REQUESTED};
   *                   false otherwise
   */
  isBidStatusResponseRequested(bid) {
    return bid && bid.status && bid.status === constants.BidStatuses.RESPONSE_REQUESTED;
  }

  /**
   * Verifies if bid has status equals to {@link constants.BidStatuses.ACCEPTED_WITH_CHANGES}
   *
   * @return {Boolean} true if the bid status is equals to {@link constants.BidStatuses.ACCEPTED_WITH_CHANGES};
   *                   false otherwise
   */
  isBidStatusAcceptedWithChanges(bid) {
    return bid && bid.status && bid.status === constants.BidStatuses.ACCEPTED_WITH_CHANGES;
  }

  /**
   * Verifies if bid has status equals to {@link ns.Constants.BidStatuses.ACCEPTED}
   *
   * @return {Boolean} true if the bid status is equals to {@link ns.Constants.BidStatuses.ACCEPTED};
   *                   false otherwise
   */
  isBidStatusAccepted(bid) {
    return bid && bid.status && bid.status === constants.BidStatuses.ACCEPTED;
  }

  /**
   * Verifies if bid has status equals to {@link ns.Constants.BidStatuses.DECLINED}
   *
   * @return {Boolean} true if the bid status is equals to {@link ns.Constants.BidStatuses.DECLINED};
   *                   false otherwise
   */
  isBidStatusDeclined(bid) {
    return bid && bid.status && bid.status === constants.BidStatuses.DECLINED;
  }

  /**
   * Returns the content of class attribute for plan type tab.
   *
   * @param planType
   *            - the plan type
   * @param index
   *            - the plan type index
   * @param selectedIndex
   *            - the selected plan type index
   * @returns {string} the content of class attribute for plan type tab specified
   */
  getPlanTypeTabClass(planType, index, selectedIndex) {
    const emptyString = constants.COMMONS.EMPTY_STRING;
    let planTypeClass = index === 0 ? "first " : emptyString;
    planTypeClass += index === parseInt(selectedIndex) ? "active" : emptyString;
    planTypeClass += planType.emptyNameError || planType.duplicateNameError ? " error " : emptyString;
    planTypeClass += " tab-class  ";

    return planTypeClass;
  }

  redirectToEnterprisePage() {
    window.location.href = "#/quantuvisplus";
  }

  /**
   * Check if all drug's children are valid
   * @param drug {Object} drug object to validate
   *
   * @returns {boolean}
   */
  getGroupIsInvalidFlag(drug) {
    drug.renderData.isInvalidGroup = !this.isNotDefined(
      drug.renderData.children.find(item => {
        // Ignore dismissed NDCs
        if (!this.isNotDefined(item.dismissed) && item.dismissed.status) {
          return false;
        } else {
          return item.renderData.isPPSectionInvalid || item.renderData.isBaseRebateInvalid;
        }
      })
    );
  }

  /**
   * Check if term has value or is @NDC
   * @param term {Object} drug object to validate
   *
   * @returns {boolean} check if term has value or @NDC
   */
  checkTermHasValue(term) {
    return (!this.isNotDefined(term.value) && (term.value !== "")) ||
      (!this.isNotDefined(term.definition.hasAtNDC) && term.definition.hasAtNDC);
  }

  /**
   * Price Protection section Validation
   * @param drug {Object} drug object to validate
   * @param isPharma {boolean}
   * @param fromCalculate {boolean} recall the method to validate drug's children or parent
   */
  validatePriceProtection(drug, isPharma, fromCalculate = false) {
    const termsList = [];
    let term;
    let hasNEPVal = false;
    let hasOnePPVal = false;
    let drugInvalid = false;

    const requiredState = constants.DRUG_TERM_GROUPS_STATE_VALUES.REQUIRED_STATE;
    if (isPharma && (this.isDefined(drug.renderData) &&
      drug.renderData.priceProtection.state === requiredState.state)) {
      // get termsList and values sorted like defined in constants
      biddingConstants.validation.priceProtection.terms.forEach(termName => {
        term = this.getTermByName(termName, drug.terms);
        // Add the term here (empty term) if it's not present;
        if (this.isNotDefined(term)) {
          term = {
            name: termName,
            value: ""
          };
        }
        if (term.name === drugTermsConstants[TermName.NET_EFFECTIVE_PRICE].title) {
          hasNEPVal = this.checkTermHasValue(term);
        } else if (!hasOnePPVal) {
          hasOnePPVal = this.checkTermHasValue(term);
        }

        termsList.push(term);
      });

      // Set style for Net Effective Price fields
      termsList[0].contentStyle = (hasNEPVal || hasOnePPVal) ? "" :
        constants.BIDDING.INVALID_TERM_STYLE;

      // Set style for Price Protection fields
      for (let i = 1; i < termsList.length; i++) {
        termsList[i].contentStyle = (hasNEPVal || this.checkTermHasValue(termsList[i])) ?
          "" : constants.BIDDING.INVALID_TERM_STYLE;
      }

      // check if drug is valid
      termsList.forEach(term => {
        if (!drugInvalid && term.contentStyle === constants.BIDDING.INVALID_TERM_STYLE) {
          drugInvalid = true;
        }
      });

      drug.renderData.isPPSectionInvalid = drugInvalid;
      this.validateGroup(drug, isPharma, fromCalculate, this.validatePriceProtection);
    }
  }

  /**
   * Validate drug group and NDC
   *
   * @param {object} drug
   * @param {boolean} isPharma
   * @param {boolean} fromCalculate
   * @param {Function} validatorFn
   */
  validateGroup(drug, isPharma, fromCalculate, validatorFn) {
    if (drug.renderData.isGroup) {
      if (!fromCalculate) {
        setTimeout(() => {
          drug.renderData.children.forEach(item => {
            if (typeof validatorFn === "function") {
              validatorFn.bind(this)(item, isPharma);
            }
          });
          this.getGroupIsInvalidFlag(drug);
        }, 0);
      } else {
        this.getGroupIsInvalidFlag(drug);
      }
    }
  }

  /**
   * Base rebate term Validation
   * @param drug {Object} drug object to validate
   * @param isPharma {boolean}
   * @param fromCalculate {boolean} recall the method to validate drug's children or parent
   */
  validateBaseRebate(drug, isPharma, fromCalculate = false) {
    const lockedState = constants.DRUG_TERM_GROUPS_STATE_VALUES.LOCKED_STATE;
    if (isPharma && (drug.renderData && drug.renderData.baseRebate.state !== lockedState.state)) {
      const term = this.getTermByName(drugTermsConstants[TermName.BASE_REBATE].title, drug.terms);
      drug.renderData.isBaseRebateInvalid = (term && this.checkTermHasValue(term)) ? false :
        !this.hasNetEffectivePrice(drug, isPharma);
      this.validateGroup(drug, isPharma, fromCalculate, this.validateBaseRebate);
    }
  }

  hasNetEffectivePrice(drug, s?) {
    const term = this.getTermByName(drugTermsConstants[TermName.NET_EFFECTIVE_PRICE].title, drug.terms);
    return (term && this.checkTermHasValue(term));
  }

  /**
   * Dependent Contract and Scenario Dates validate
   * @param drug
   */
  validateDependentDateForGroupDrug(drug) {
    if (!drug.renderData.isGroup) {
      drug.renderData.isDependentDateForGroupInvalid = false;
      return;
    }

    let isInvalid = false;
    drug.renderData.children.forEach(item => {
      if (!isInvalid && this.hasInvalidDependentDates(item)) {
        isInvalid = true;
      }
    });

    drug.renderData.isDependentDateForGroupInvalid = isInvalid;
  }

  hasInvalidDependentDates(drug) {
    if (!Array.isArray(drug.terms)) {
      return false;
    }

    return drug.terms.some(term => {
      if (term.name === constants.SUMMARY_TERMS.CONTRACT_START_DATE.label ||
        term.name === constants.SUMMARY_TERMS.CONTRACT_END_DATE.label) {

        return (term.definition && term.definition.informMessage);
      }
    });
  }

  /**
   * Validate sections of terms on 'valueChanged'
   */
  validateSections(data, perspective) {
    const isPharma = this.isPharmaViewPerspective(perspective);
    if (data.term && data.term.definition.validateSection) {
      let validateSection = data.term.definition.validateSection;
      if ((validateSection.section === constants.DrugList.PRICE_PROTECTION) && isPharma) {
        this.validatePriceProtection(data.drug, isPharma);
      }
      if (data.term && (data.term.name === drugTermsConstants[TermName.NET_EFFECTIVE_PRICE].title) && isPharma) {
        this.validateBaseRebate(data.drug, isPharma);
      }
    } else if (data.term && (data.term.name === drugTermsConstants[TermName.BASE_REBATE].title) && isPharma) {
      this.validateBaseRebate(data.drug, isPharma);
    }
  }

  /**
   * Get all checked drugs collection
   *
   * @returns {Array}
   */
  getAllCheckedDrugs() {
    return this.allCheckedDrugs;
  }

  /**
   * Set all checked drugs collection
   *
   * @param {Array} drugs
   */
  setAllCheckedDrugs(drugs) {
    this.allCheckedDrugs = drugs;
  }

  /**
   * Get/set refresh flag for all checked drugs collection
   *
   * @param {boolean} flag (optional)
   * @returns {boolean|undefined}
   */
  shouldRefreshAllCheckedDrugs(flag) {
    if (typeof flag === "undefined") {
      return this.shouldRefreshCheckedDrugsCollection;
    }
    this.shouldRefreshCheckedDrugsCollection = flag;
  }

  isTermInHiddenMarketShareSection(ndc, term) {
    const marketShareRebate = constants.DrugList.MARKET_SHARE_REBATE;
    const marketShareSection = ndc.terms.find(term => term.name === marketShareRebate);

    const isMarketShareHidden = () => {
      const hiddenState = constants.DRUG_TERM_GROUPS_STATE_VALUES.HIDDEN_STATE.state;
      return ndc.renderData.marketShare.state === hiddenState;
    };

    const termDependsOnMarketShareSection = () => {
      return marketShareSection.definition && Array.isArray(marketShareSection.definition.dependsOn) &&
        marketShareSection.definition.dependsOn.includes(term.name);
    };

    return marketShareSection && termDependsOnMarketShareSection() && isMarketShareHidden();
  }

  /**
   * Remove 'companyDisplayName' before saving the values
   * @param termValue {array} array of objects;
   * @returns {array}
   */
  removeDisplayCompanyName(termValue) {
    const values = [];
    if (!this.isNotDefined(termValue) && !this.isNotDefined(termValue.historic)) {
      termValue.historic.forEach((val) => {
        delete val.companyDisplayName;
        values.push(val);
      });
    }

    return values;
  }

  /**
   * Verifies if the given user type is the same as the bid assignee.
   *
   * @param user
   *          - the user to be checked
   * @param bidObject
   *          - the bid for which to return the check value
   * @param perspective
   *          - the user view perspective
   * @returns {boolean} true if if the given user type is the same as the bid assignee, false otherwise
   */
  isUserTypeBidAssignee(user, bidObject, perspective) {
    const loggedInUserType = user.role.type.toUpperCase();
    const assignee = bidObject.assignee.toUpperCase();
    perspective = perspective.toUpperCase();

    const bidAssigneeTypes = constants.BidAssigneeTypes;
    const roleTypes = constants.RoleTypes;

    if (bidObject.isInternal) {
      const isPayerInternalAssigned = assignee === bidAssigneeTypes.PAYER && perspective === roleTypes.PAYER;
      const isPharmaAssigned = assignee === bidAssigneeTypes.PHARMA && loggedInUserType === roleTypes.PHARMA
        && perspective === roleTypes.PHARMA;

      return isPayerInternalAssigned || isPharmaAssigned;
    } else {
      const isPayerAssigned = assignee === bidAssigneeTypes.PAYER && loggedInUserType === roleTypes.PAYER;
      const isPharmaAssigned = assignee === bidAssigneeTypes.PHARMA && loggedInUserType === roleTypes.PHARMA;

      return isPayerAssigned || isPharmaAssigned;
    }
  }

  /**
   * @deprecated Please use isCurrentUserPayer function
   *
   * Verifies if the user is of type payer.
   * @param user
   * @returns boolean true if the user is of type payer, false otherwise
   */
  isPayer(user) {
    return this.isUserType(user, constants.RoleTypes.PAYER);
  }

  /**
   * @deprecated Please use isCurrentUserPayer function
   *
   * Verifies if the user is of type pharma.
   * @param user
   * @returns boolean true if the user is of type pharma, false otherwise
   */
  isPharma(user) {
    return this.isUserType(user, constants.RoleTypes.PHARMA);
  }

  // TODO: Should be moved to the ViewPerspectiveService
  isPayerViewPerspective(perspective) {
    return perspective === constants.COMPANY_TYPES.PAYER.value;
  }

  isPharmaViewPerspective(perspective) {
    return perspective === constants.COMPANY_TYPES.PHARMA.value;
  }

  /**
   * Verifies if the user is of the provided wantedUserType.
   * @param user
   * @param wantedUserType
   * @returns boolean true if the user if of type wantedUserType, false otherwise
   */
  isUserType(user, wantedUserType) {
    if (!this.isNotDefined(user.role) && !this.isNotDefined(user.role.type)) {
      const userType = user.role.type.toLowerCase();
      if (userType === wantedUserType.toLowerCase()) {
        return true;
      }
    }

    return false;
  }

  /**
   * @deprecated please use the simplest way "if (variable) { ... }"
   *
   * Verifies if the provided object is 'undefined' or null.
   * @param toCheck object to verify
   * @returns {boolean} whether the object is undefined or null
   */
  isNotDefined(toCheck) {
    return typeof toCheck === "undefined" || toCheck === null;
  }

  /**
   * @deprecated please use the simplest way "if (!variable) { ... }"
   *
   * Verifies if the provided object is defined
   * @param toCheck object to verify
   * @returns {boolean} whether the object is undefined or null
   */
  isDefined(toCheck) {
    return !this.isNotDefined(toCheck);
  }

  isBindingTypeValue(value, isBinding) {
    return value === constants.BINDING_BID_TYPE.BINDING || value === resources.NewBid.KEEP_ORIGINAL_VALUE && isBinding;
  }

  private getCompanyId(user, userACLs: Map<number, string>, bidManufacturerId: number, bidPayerId: number): number {
    return Array.from(userACLs.keys()).find((companyId: number) =>
      (user.isPayer && companyId === bidManufacturerId) ||
      (user.isManufacturer && companyId === bidPayerId)
    );
  }
}
