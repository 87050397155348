import {
  Directive, TemplateRef, ViewContainerRef,
  Input, ComponentFactory, ComponentRef,
  ComponentFactoryResolver, Output, EventEmitter
} from '@angular/core';
import { AtNdcComponent } from '@qv-term/components/at-ndc/at-ndc.component';
import { TermUtils } from '@qv-term/utils';
import { take } from 'rxjs/operators';

@Directive({
  selector: '[qvAtNdc]'
})
export class AtNdcDirective {

  public disableClick = false;

  @Output()
  public overwriteValuesConfirmed = new EventEmitter();

  public atNdcComponent: ComponentRef<AtNdcComponent>;
  private readonly atNdcFactory: ComponentFactory<AtNdcComponent>;

  constructor(
    private view: ViewContainerRef,
    private template: TemplateRef<any>,
    componentFactoryResolver: ComponentFactoryResolver
  ) {
    this.atNdcFactory = componentFactoryResolver.resolveComponentFactory(AtNdcComponent);
  }

  @Input() set qvAtNdc(value: any) {
    if (TermUtils.isAtNdc(value)) {
      this.renderAtNdcComponent();
    } else {
      this.view.clear();
      this.view.createEmbeddedView(this.template);
    }
  }

  @Input()
  set setDisableClick(value: boolean) {
    this.disableClick = value;
    if (this.atNdcComponent) {
      this.atNdcComponent.instance.disableClick = value;
    }
  }

  private renderAtNdcComponent(): void {
    this.view.clear();
    this.atNdcComponent = this.view.createComponent(this.atNdcFactory);
    this.atNdcComponent.instance.disableClick = this.disableClick;
    this.atNdcComponent.instance.overwriteValuesConfirmed
      .pipe(take(1))
      .subscribe(() => this.overwriteValuesConfirmed.emit());
  }
}
