import { ApiUrlPrefix } from '@qv-common/enums';
import { constants } from '@qv-common/static';
import { CoreUtils } from '@qv-common/utils';
import { DateUtils } from '@qv-common/utils/date.utils';
import { drugTermsConstants } from '@qv-term/constants';
import { TermName } from '@qv-term/enums';
import { first } from 'rxjs/operators';
import { TermUtils } from '../../../utils/terms';
import { AuthService } from 'quantuvis-angular-common/auth';
import { UserService } from '@qv-common/services/auth/user.service';

export const BaselineWacService = [
  'util', 'apiService', 'userService',
  function(util, apiService, userService: UserService): any {
    const context: any = {};
    'ngInject';

    /**
     * Get Baseline WAC for NDC
     *
     * @param drug
     * @param date
     */
    context.getNDCBaselineWAC = (drug, date) => {
      context.getNDCListBaselineWAC([drug], date).then((priceMap: any) => {
        const baselineWACTerm = TermUtils.getBaselineWACTerm(drug);
        context.setValueAndUpdateParent(baselineWACTerm, drug, priceMap[drug.ndc]);
      });
    };

    context.setValueAndUpdateParent = (baselineWACTerm, drug, value?, isGroup?) => {
      baselineWACTerm.value = value;
      if (drug.renderData.parent && !isGroup) {
        drug.renderData.parent.renderData.calculateValue(false, drugTermsConstants[TermName.BASELINE_WAC].title);
        drug.renderData.parent.renderData.calculateValue(false, drugTermsConstants[TermName.BASELINE_START_DATE].title);
      }
    };

    context.getNDCListBaselineWAC = (list, date) => {
      return new Promise((resolve, reject) => {
        const ndcList = list.map(item => item.ndc);
        const parsedDate = DateUtils.getDateBasedOnFormattedString(date).getTime();

        if (!util.isEmpty(date) && util.isCorrectDateFormat(date)) {
          if (parsedDate > new Date().getTime()) {
            // just set Undefined if date is in the future
            const result = {};
            list.forEach(item => result[item.ndc] = null);
            resolve(result);
          } else {
            apiService.post(`${ApiUrlPrefix.OLD}/drugPrices/wac/byNdcs`, {
              ndcList,
              date: parsedDate
            }).pipe(first()).toPromise()
              .then(response => {
                resolve(response.body.responseObject);
              })
              .catch(error => {
                console.error(error.message);
                reject(error);
              });
          }
        } else {
          const result = {};
          list.forEach(item => result[item.ndc] = '');
          resolve(result);
        }
      });
    };

    /**
     * Get Baseline WAC for Group of NDCs
     *
     * @param drug
     * @param date
     */
    context.getGroupBaselineWAC = (drug, date) => {
      if (!util.isEmpty(date)) {
        context.getNDCListBaselineWAC(drug.renderData.children, date).then((priceMap: any) => {
          drug.renderData.children.forEach(child => {
            const baselineWACTerm = TermUtils.getBaselineWACTerm(child);
            context.setValueAndUpdateParent(baselineWACTerm, child, priceMap[child.ndc], false);
          });
          drug.renderData.calculateValue(false, drugTermsConstants[TermName.BASELINE_WAC].title);
        });
      } else {
        const baselineWACTerm = TermUtils.getBaselineWACTerm(drug);
        if (baselineWACTerm.definition && CoreUtils.isDefined(baselineWACTerm.definition.hasAtNDC)) {
          baselineWACTerm.definition.hasAtNDC = false;
        }
        baselineWACTerm.value = '';
      }
    };

    /**
     * Update Baseline WAC for List of NDCs (different drug groups)
     *
     * @param ndcList
     * @param date
     */
    context.updateBaselineWACForNDCs = (ndcList, date) => {
      if (util.isPharma(userService.user.getValue())) {
        ndcList = ndcList.filter(ndc =>
          ndc.renderData.priceProtection.state !== constants.DRUG_TERM_GROUPS_STATE_VALUES.LOCKED_STATE.state);
      }
      if (ndcList.length > 0) {
        context.getNDCListBaselineWAC(ndcList, date).then((priceMap: any) => {
          ndcList.forEach(ndc => {
            const value = priceMap[ndc.ndc];
            const baselineWACTerm = TermUtils.getBaselineWACTerm(ndc);
            context.setValueAndUpdateParent(baselineWACTerm, ndc, value, true);
          });
          ndcList.forEach(ndc => {
            ndc.renderData.parent.renderData.calculateValue(false, drugTermsConstants[TermName.BASELINE_WAC].title);
          });
        });
      }
    };

    return context;
  }];
