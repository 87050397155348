import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { tap } from 'rxjs/operators';
import { CheckboxItem } from '@qv-common/models';
import { QvCache } from '@qv-common/decorators';

@UntilDestroy()
@Component({
  selector: 'qv-checkbox-list',
  templateUrl: './checkbox-list.component.html',
  styleUrls: ['./checkbox-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckboxListComponent implements OnChanges {

  @Input()
  public items: CheckboxItem[] = [];

  @Input()
  public isSearchEnabled = false;

  @Input()
  public isSelectAllEnabled = false;

  @Input()
  public defaultCheckboxState = false;

  @Input()
  public listClass = '';

  @Output()
  public itemsChanged = new EventEmitter<CheckboxItem[]>();

  public filteredItems: CheckboxItem[] = [];

  public searchControl: FormControl;

  private internalChanges = false;

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.items) {
      this.handleItemsChanges();
    }
    if (changes.isSearchEnabled && changes.isSearchEnabled.currentValue && !this.searchControl) {
      this.searchControl = new FormControl();
      this.initSearchHandler();
    }
  }

  @QvCache()
  public getListClass(isSearchEnabled: boolean, listClass: string): string {
    return isSearchEnabled ? listClass : '';
  }

  public onChange(change: MatCheckboxChange, changedItem: CheckboxItem): void {
    this.filteredItems = this.remapItems(this.filteredItems, change.checked, [changedItem.id]);
    const changedItems = this.remapItems(this.items, change.checked, [changedItem.id]);
    this.emitChanges(changedItems);
  }

  public onSelectAll(change: MatCheckboxChange): void {
    this.filteredItems = this.remapItems(this.filteredItems, change.checked, []);
    const changedItems = this.remapItems(this.items, change.checked, this.getFilteredIds());
    this.emitChanges(changedItems);
  }

  public isIndeterminateState(): boolean {
    return this.isSomeFilteredItemChecked() && !this.isAllFilteredItemsChecked();
  }

  public isAllFilteredItemsChecked(): boolean {
    return this.isFilterResultCount() && this.filteredItems.every((item: CheckboxItem) => item.checked);
  }

  public isFilterResultCount(): boolean {
    return this.filteredItems.length > 0;
  }


  public trackById(index: number, item: CheckboxItem): string | number {
    return item.id;
  }

  private remapItems(items: CheckboxItem[], checked: boolean, changedIds: (string | number)[] = []): CheckboxItem[] {
    return items.map((item: CheckboxItem) => !changedIds.length || changedIds.includes(item.id)
      ? new CheckboxItem(item.id, item.name, item.value, checked, item.disabled)
      : item
    );
  }

  private getFilteredIds(): (string | number)[] {
    return this.filteredItems.map((item: CheckboxItem) => item.id);
  }

  private emitChanges(changedItems: CheckboxItem[]): void {
    this.internalChanges = true;
    this.itemsChanged.emit(changedItems);
  }

  private handleItemsChanges(): void {
    if (!this.internalChanges) {
      this.fixNonArrayValue();
      this.checkResetSearchControl();
      this.setFilteredItems();
    } else {
      this.internalChanges = false;
    }
  }

  private fixNonArrayValue(): void {
    if (!this.items) {
      this.items = [];
    }
  }

  private setFilteredItems(): void {
    if (this.searchControl && this.searchControl.value) {
      this.filterItems(this.searchControl.value);
    } else {
      this.filteredItems = this.items;
    }
  }

  private initSearchHandler(): void {
    this.searchControl.valueChanges
      .pipe(
        tap((query: string) => {
          this.filterItems(query);
          this.changeDetectorRef.markForCheck();
        }),
        untilDestroyed(this)
      )
      .subscribe();
  }

  private filterItems(query: string): void {
    this.filteredItems = this.items
      .filter((item: CheckboxItem) => item.name.toLowerCase().includes(query.toLowerCase()));
  }

  private checkResetSearchControl(): void {
    if (this.searchControl && this.searchControl.value && this.isAllItemsHaveDefaultState()) {
      this.searchControl.reset(null, { emitEvent: false });
    }
  }

  private isAllItemsHaveDefaultState(): boolean {
    return this.items.every((item: CheckboxItem) => item.checked === this.defaultCheckboxState);
  }

  private isSomeFilteredItemChecked(): boolean {
    return this.filteredItems.some((item: CheckboxItem) => item.checked);
  }
}
