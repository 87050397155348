import { Inject, Injectable } from '@angular/core';
import { JsonConvert } from 'json2typescript';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from '@qv-common/services/api';
import { HttpResponseWrapper } from '@qv-common/models/api';
import { JSON_CONVERT_TOKEN } from 'quantuvis-angular-common/json-converter/services';
import { BidAttachment, Summary, WebsiteLink } from '@qv-bid/entities';
import { BidAttachmentType } from '@qv-bid/enums';
import { CoreUtils, HttpUtils } from '@qv-common/utils';
import { RequestOption } from '@qv-common/services/api/request-option.interface';
import { ResponseType } from 'quantuvis-angular-common/api';
import { HttpResponse } from '@angular/common/http';
import { ViewPerspectiveService } from '@qv-common/services/auth';
import { ApiUrlPrefix } from '@qv-common/enums';
import { FileContent } from '@qv-pdf-viewer/models';

@Injectable()
export class SummaryDaoService {
  constructor(
    private apiService: ApiService,
    @Inject(JSON_CONVERT_TOKEN) private jsonConvert: JsonConvert,
    private viewPerspectiveService: ViewPerspectiveService
  ) { }

  public getSummaryInfo(bidVersion: number): Observable<Summary> {
    return this.apiService
      .get(`${ApiUrlPrefix.BIDS}/bid-versions/${bidVersion}/summaries`)
      .pipe(map((response: HttpResponseWrapper<Summary>) =>
        this.jsonConvert.deserializeObject(response.body.data, Summary)
      ));
  }

  public updateSummaryInfo(bidVersionId: number, summaryId: number, summary: Summary,
                           isBidInternal: boolean): Observable<Summary> {
    const summaryData = this.jsonConvert.serialize(summary);
    let url = `${ApiUrlPrefix.BIDS}/bid-versions/${bidVersionId}/summaries/${summaryId}`;

    const perspective = this.viewPerspectiveService.getViewPerspective();
    url = HttpUtils.appendPerspectiveQueryParamToUrlIfBidIsInternal(url, perspective, isBidInternal);

    return this.apiService
      .put(url, summaryData)
      .pipe(map((response: HttpResponseWrapper<Summary>) =>
        this.jsonConvert.deserializeObject(response.body.data, Summary)
      ));
  }

  public getWebsiteLinksBySummaryId(bidVersionId: number, summaryId: number): Observable<WebsiteLink[]> {
    return this.apiService.get(
      `${ApiUrlPrefix.BIDS}/bid-versions/${bidVersionId}/summaries/${summaryId}/website-links`
    ).pipe(
      map((response: HttpResponseWrapper<WebsiteLink>) =>
        this.jsonConvert.deserializeArray(response.body.data, WebsiteLink)
      )
    );
  }

  public createWebsiteLink(bidVersionId: number, summaryId: number, link: WebsiteLink,
                           isBidInternal: boolean): Observable<WebsiteLink> {
    let url = `${ApiUrlPrefix.BIDS}/bid-versions/${bidVersionId}/summaries/${summaryId}/website-links`;

    const perspective = this.viewPerspectiveService.getViewPerspective();
    url = HttpUtils.appendPerspectiveQueryParamToUrlIfBidIsInternal(url, perspective, isBidInternal);

    return this.apiService.post(url, link).pipe(
      map((response: HttpResponseWrapper<WebsiteLink>) =>
        this.jsonConvert.deserializeObject(response.body.data, WebsiteLink)
      )
    );
  }

  public updateWebsiteLink(bidVersionId: number, summaryId: number, id: number, link: { link: string },
                           isBidInternal: boolean): Observable<WebsiteLink> {
    let url = `${ApiUrlPrefix.BIDS}/bid-versions/${bidVersionId}/summaries/${summaryId}/website-links/${id}`;

    const perspective = this.viewPerspectiveService.getViewPerspective();
    url = HttpUtils.appendPerspectiveQueryParamToUrlIfBidIsInternal(url, perspective, isBidInternal);

    return this.apiService.put(url, link).pipe(
      map((response: HttpResponseWrapper<WebsiteLink>) =>
        this.jsonConvert.deserializeObject(response.body.data, WebsiteLink)
      ));
  }

  public deleteWebsiteLink(bidVersionId: number, summaryId: number, linkId: number,
                           isBidInternal: boolean): Observable<void> {
    let url = `${ApiUrlPrefix.BIDS}/bid-versions/${bidVersionId}/summaries/${summaryId}/website-links/${linkId}`;

    const perspective = this.viewPerspectiveService.getViewPerspective();
    url = HttpUtils.appendPerspectiveQueryParamToUrlIfBidIsInternal(url, perspective, isBidInternal);

    return this.apiService.delete(url).pipe(map(() => null));
  }

  public hasLegalAttestation(): Observable<boolean> {
    const url = `${ApiUrlPrefix.DOCUMENT_MANAGEMENT}/legal-attestation/exists`;

    return this.apiService.get(url).pipe(map((response: HttpResponseWrapper<boolean>) => response.body.data));
  }

  public getBidAttachmentsBySummaryId(summaryId: number,
                                      bidAttachmentType?: BidAttachmentType): Observable<BidAttachment[]> {
    let url = `${ApiUrlPrefix.BIDS}/summaries/${summaryId}/bid-attachments`;
    if (!CoreUtils.isNull(bidAttachmentType)) {
      url = HttpUtils.appendQueryParamToUrl(url, 'type', String(bidAttachmentType));
    }

    return this.apiService.get(url).pipe(
      map((response: HttpResponseWrapper<BidAttachment>) =>
        this.jsonConvert.deserializeArray(response.body.data, BidAttachment)
      ));
  }

  public getFile(summaryId: number, fileId: number): Observable<FileContent> {
    const options: RequestOption = {
      responseType: ResponseType.ARRAY_BUFFER
    };
    return this.apiService.get(
      `${ApiUrlPrefix.BIDS}/summaries/${summaryId}/bid-attachments/${fileId}/download`,
      options
    ).pipe(map((response: HttpResponse<FileContent>) => response.body));
  }

  public uploadBidAttachment(summaryId: number, data: FormData, isBidInternal: boolean): any {
    let url = `${ApiUrlPrefix.BIDS}/summaries/${summaryId}/bid-attachments`;

    const perspective = this.viewPerspectiveService.getViewPerspective();
    url = HttpUtils.appendPerspectiveQueryParamToUrlIfBidIsInternal(url, perspective, isBidInternal);

    return this.apiService.post(url, data)
      .pipe(
        map((response: HttpResponseWrapper<BidAttachment>) =>
          this.jsonConvert.deserialize(response.body.data, BidAttachment)
        )
      );
  }

  public deleteBidAttachment(summaryId: number, id: number, isBidInternal: boolean): Observable<void> {
    let url = `${ApiUrlPrefix.BIDS}/summaries/${summaryId}/bid-attachments/${id}`;

    const perspective = this.viewPerspectiveService.getViewPerspective();
    url = HttpUtils.appendPerspectiveQueryParamToUrlIfBidIsInternal(url, perspective, isBidInternal);

    return this.apiService.delete(url).pipe(map(() => null));
  }
}
