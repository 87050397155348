import { Injectable } from '@angular/core';
import { map, take } from 'rxjs/operators';
import { DrugGroup } from '@qv-bid/models';
import { ContractedBusinessScenarioIds, Scenario } from '@qv-bid/entities';
import { BidStateService } from '@qv-bid/services/bid-state.service';
import { Observable } from 'rxjs';
import { DrugSelectUtilsService } from '@qv-bid/services/selects';

@Injectable()
export class ScenarioService {
  constructor(
    private bidStateService: BidStateService,
    private drugSelectUtilsService: DrugSelectUtilsService,
  ) {}

  public static getScenariosIdsFromDrugGroups(drugGroups: DrugGroup[]): number[] {
    return drugGroups.reduce((scenariosIds: number[], drugGroup: DrugGroup) => {
      const scenariosIdsCurrentDrugGroup = drugGroup.scenarios
        .filter((scenario: Scenario) => !scenario.drug)
        .map((scenario: Scenario) => scenario.id);
      return scenariosIds.concat(scenariosIdsCurrentDrugGroup);
    }, []);
  }

  public static getScenarioIds(scenarios: Scenario[]): number[] {
    return scenarios.map((scenario: Scenario) => scenario.id);
  }

  public getSelectedCbScenarioIds(): Observable<ContractedBusinessScenarioIds[]> {
    return this.bidStateService.scenarios$.pipe(
      take(1),
      map((scenarios: Scenario[]) => this.drugSelectUtilsService.getSelectedScenarios(scenarios)),
      map((selectedScenarios: Scenario[]) => this.getMapCbScenarioIds(selectedScenarios)),
      map((mapCbScenarioIds: Map<number, number[]>) => this.getCbScenarioIds(mapCbScenarioIds)),
    );
  }

  private getMapCbScenarioIds(scenarios: Scenario[]): Map<number, number[]> {
    const mapCbScenarioIds = new Map<number, number[]>();

    scenarios.forEach(({ id, cbId }: Scenario) => {
      const scenarioIds = mapCbScenarioIds.get(cbId) || [];
      mapCbScenarioIds.set(cbId, [...scenarioIds, id]);
    });

    return mapCbScenarioIds;
  }

  private getCbScenarioIds(mapCbScenarioIds: Map<number, number[]>): ContractedBusinessScenarioIds[] {
    return Array.from(mapCbScenarioIds)
      .map(([cbId, scenarioIds]: [number, number[]]) => new ContractedBusinessScenarioIds(cbId, scenarioIds));
  }
}
