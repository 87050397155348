import { Injectable } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { SelectedDrug } from '@qv-bid/models';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Injectable()
export class BidSelectService {
  public readonly scenarioSelection = new SelectionModel<number>(true, [], true);
  public readonly toggleAllDrugs$ = new Subject<boolean>();
  public selectedDrugs: SelectedDrug[] = [];

  private readonly allScenariosSelected$ = new BehaviorSubject<boolean>(false);
  private readonly ndcSelected$ = new BehaviorSubject<number[]>([]);
  private readonly scenarioSelected$ = new BehaviorSubject<number[]>([]);
  private scenarioIds: number[] = [];

  constructor() {
    this.initAllScenarioSelectedHandler();
  }

  public allScenariosSelected(): Observable<boolean> {
    return this.allScenariosSelected$.asObservable();
  }

  public ndcSelected(): Observable<number[]> {
    return this.ndcSelected$.asObservable();
  }

  public scenarioSelected(): Observable<number[]> {
    return this.scenarioSelected$.asObservable();
  }

  public getSelectedScenarioIds(): number[] {
    return this.scenarioSelection.selected;
  }

  public getSelectedNdcIds(): number[] {
    return [].concat(...this.selectedDrugs.map((drug: SelectedDrug) => drug.ndcIds));
  }

  public bidToggle(event: MatCheckboxChange): void {
    event.checked ? this.scenarioSelection.select(...this.scenarioIds) : this.scenarioSelection.clear();
    this.toggleAllDrugs$.next(event.checked);
  }

  public clear(): void {
    this.scenarioSelection.clear();
    this.toggleAllDrugs$.next(false);
    this.ndcSelected$.next([]);
    this.scenarioSelected$.next([]);
    this.selectedDrugs = [];
  }

  public setScenarioIds(scenarioIds: number[]): void {
    this.scenarioIds = scenarioIds;
    this.scenarioSelection.deselect(...this.scenarioSelection.selected
      .filter((drugId: number) => !this.scenarioIds.includes(drugId)));
  }

  public updateSelectedDrugs(scenarioId: number, ndcIds: number[]): void {
    const foundedDrug: SelectedDrug = this.selectedDrugs.find((drug: SelectedDrug) => drug.scenarioId === scenarioId);

    if (foundedDrug) {
      foundedDrug.ndcIds = ndcIds;
    } else {
      this.selectedDrugs.push({ scenarioId, ndcIds });
    }

    this.ndcSelected$.next(this.getSelectedNdcIds());
    this.scenarioSelected$.next(this.getSelectedScenarioIds());
  }

  private isAllScenariosSelected(): boolean {
    return this.scenarioIds.length && (this.scenarioSelection.selected.length === this.scenarioIds.length);
  }

  private initAllScenarioSelectedHandler(): void {
    this.scenarioSelection.changed.pipe(untilDestroyed(this))
      .subscribe(() => this.allScenariosSelected$.next(this.isAllScenariosSelected()));
  }
}
