import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { from, Observable, of } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { PermissionService } from '@qv-common/services/auth/permission.service';
import { Permission } from '@qv-common/enums';
import { QuantuvisPlusFeature } from '@qv-company/enums';

@Injectable()
export class HistoricBidGuard implements CanActivate {

  constructor(private permissionService: PermissionService) {}

  public canActivate(next: ActivatedRouteSnapshot): Observable<boolean> {
    if (Boolean(next.queryParams.versionId)) {
      return this.permissionService.userPermissions$
        .pipe(
          take(1),
          switchMap((permissions: Map<string, boolean>) =>
            from(this.permissionService
              .checkPermissionInUserPermissionList(Permission.BIDS, QuantuvisPlusFeature.BID_HISTORY, permissions))
          )
        );
    } else {
      return of(true);
    }
  }
}
