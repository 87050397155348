/* tslint:disable:no-invalid-this */
import { resources } from '@qv-common/static';
import { SvgIconName } from '@qv-common/enums';
import { ArrayUtils } from '@qv-common/utils';
import { filter, take, tap } from 'rxjs/operators';
import { HttpResponse } from '@angular/common/http';
import { ApiUrlPrefix } from '@qv-common/enums';

declare let $: any;

// @ts-ignore
import templateUrl from './notifications.html';
import { UserService } from '@qv-common/services/auth/user.service';

const NotificationsComponent = {
  template: templateUrl,
  bindings: {},
  controller: class {
    public static $inject = [
      '$scope', 'standards', 'util', 'biddingUtilsService', 'router', 'userService', 'apiService', 'permissionService',
      'sharedEventBusService',
    ];
    [x: string]: any;
    constructor(
      $scope, standards, util, biddingUtilsService, router, userService: UserService, apiService, permissionService,
      sharedEventBusService
    ) {
      'ngInject';
      let groups = {};

      // Current index
      let index = 0;
      $scope.isHomePage = router.url === '/dashboard';
      // Count - on notification page calculate how many notification fit on the page and request 10 extra

      let count = getLoadItemsCount();

      // Number of notifications to get at next scroll
      const smallCount = 10;

      $scope.goToNotificationDetails = event => {
        const id = $(event.delegateTarget).attr('data-id');
        const notificationType = $(event.delegateTarget).attr('data-type');
        if (!util.isEmpty(id) && notificationType === 'BID') {
          const params = {
            bidId: id,
            planTypeIndex: 0
          };
          router.navigate(['/standard', 'bid'], { queryParams: params });
        }
      };

      $scope.goToAllNotifications = () => router.navigate(['/notifications']);

      standards.apply($scope, {

        constructor: () => {

          if (!$scope.isHomePage) {
            /**
             * Set timeout for mark as read
             */
            setTimeout(() => {
              if (userService.user.getValue()) {
                apiService.post(`${ApiUrlPrefix.OLD}/notifications/mark-read`).pipe(
                  tap(() => {
                    $scope.$emit('clearnotifications');
                    $scope.groupsArray.forEach(group => {
                      group.list.forEach(item => {
                        item.status = true;
                      });
                    });
                  })
                ).subscribe();
              }
            }, 5000);

          }

          permissionService.permissionsReady$.pipe(
            filter((isReady: boolean) => isReady),
            take(1),
          ).subscribe(() => {
            $scope.getData();
            sharedEventBusService.myAccountsFilterChangedEvent.subscribe(() => {
              index = 0;
              count = getLoadItemsCount();
              $scope.getData(true);
            });
          });

        },
        variables: {
          dataReady: false,
          groupsArray: [],
          showMore: false
        },

        public(): any {

          this.isPage = () => !$scope.isHomePage,

            this.hasRecords = () => $scope.groupsArray.length > 0,

            this.getData = (isReset) => apiService.get(`${ApiUrlPrefix.OLD}/notifications/${index}/${count}`).pipe(
              tap((response: HttpResponse<any>) => {
                if (isReset) {
                  $scope.groupsArray = [];
                  groups = [];
                }

                const data = response.body;
                $scope.dataReady = true;
                count = smallCount;
                index += (data) ? data.length : '';

                if (data && data.length > 0) {
                  if ($scope.isHomePage && data.length > 10) {
                    $scope.showMore = true;
                    data.splice(count, count + 1);
                  }
                  const newGroups = ArrayUtils.groupBy(data, (item: any) => item.timestampVO.fullFormattedDate);

                  newGroups.forEach((data, index) => {
                    const group = groups[index];
                    if (group) {
                      data.forEach(el => {
                        if (el.type === 'BID') {
                          el.notifier = biddingUtilsService.addOldName(el.notifier, el.oldNotifier);
                        } else {
                          el.notifier = resources.NOTIFICATION_TYPE.SHARED_REPORT;
                        }

                        group.list.push(el);
                      });
                    } else {
                      groups[index] = {
                        list: data,
                        index,
                        dateInfo: (data[0] && data[0].timestampVO) ? data[0].timestampVO.fullFormattedDate : ''
                      };

                      groups[index].list.forEach(item => {
                        if (item.type === 'BID') {
                          item.notifier = biddingUtilsService.addOldName(item.notifier, item.oldNotifier);
                        } else {
                          item.notifier = resources.NOTIFICATION_TYPE.SHARED_REPORT;
                        }

                      });
                      $scope.groupsArray.push(groups[index]);
                    }
                  });

                }
              })
            ).toPromise();

          this.bulletIcon = SvgIconName.BULLET;

          this.navClass = status => status ? 'bullet bullet--disabled' : 'bullet bullet--enabled';

          this.bidClass = page => page.toLowerCase() === 'contract' ? 'label label-warning' : 'label label-info';

          return {
            isPage: this.isPage,
            hasRecords: this.hasRecords,
            getData: this.getData,
            setNotifier: this.setNotifier,
            navClass: this.navClass,
            bidClass: this.bidClass,
            bulletIcon: this.bulletIcon
          };

        }
      });

      function getLoadItemsCount(): number {
        return $scope.isHomePage ? 11 : (Math.round($(window).height() / 40) + 10);
      }
    }
  }
};
export default NotificationsComponent;
