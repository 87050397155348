import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'qv-truncate-text',
  templateUrl: './truncate-text.component.html',
  styleUrls: ['./truncate-text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TruncateTextComponent {
  @Input()
  public text: string;
}
