/* tslint:disable:no-var-requires */
import SummaryController from './summary.controller';

// @ts-ignore
import templateUrl from './summary.html';

const SummaryComponent = {
  template: templateUrl,
  bindings: {
    runValidation: '<',
    onValid: '&'
  },
  controller: SummaryController
};

export default SummaryComponent;
