import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmbeddedDashboardPanelComponent } from '@qv-insights/components';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '@qv-shared/shared.module';
import { InsightsDaoService } from '@qv-insights/services/dao';

@NgModule({
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    SharedModule
  ],
  declarations: [
    EmbeddedDashboardPanelComponent
  ],
  providers: [
    InsightsDaoService,
  ],
})
export class InsightsModule {}
