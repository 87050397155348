import { DictionaryItem } from '@qv-common/entities';

export enum PriceProtectionState {
  ALLOWED = 1,
  REQUIRED = 2,
  LOCKED = 3
}

export namespace PriceProtectionState {
  export const valuesMap = new Map<PriceProtectionState, DictionaryItem>([
    [PriceProtectionState.ALLOWED, new DictionaryItem(PriceProtectionState.ALLOWED, 'Allowed')],
    [PriceProtectionState.LOCKED, new DictionaryItem(PriceProtectionState.LOCKED, 'Locked')],
    [PriceProtectionState.REQUIRED, new DictionaryItem(PriceProtectionState.REQUIRED, 'Required')]
  ]);

  export function getValue(priceProtectionState: PriceProtectionState): DictionaryItem {
    return valuesMap.get(priceProtectionState);
  }

  export function getValues(): DictionaryItem[] {
    return Array.from(valuesMap.values());
  }
}
