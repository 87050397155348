import { Scenario } from '@qv-bid/entities';

export class ManageScenarioDependencyModalData {
  public readonly isManageMode: boolean;

  constructor(
    public readonly title: string,
    public readonly scenarioName: string,
    public readonly scenarios: Scenario[],
    public readonly selectedScenarios: Scenario[] = []
  ) {
    this.isManageMode = Boolean(selectedScenarios.length);
  }
}
