export enum BidStatus {
  DRAFT = 'DRAFT',
  RFP_NOT_SENT = 'RFP_NOT_SENT',
  RESPONSE_REQUESTED = 'RESPONSE_REQUESTED',
  ACCEPTED_WITH_CHANGES = 'ACCEPTED_WITH_CHANGES',
  ACCEPTED = 'ACCEPTED',
  DECLINED = 'DECLINED',
  REVIEW = 'REVIEW'
}

export namespace BidStatus {
  export function displayValue(bidStatus: BidStatus, isAssignedToSameCompanyThatView?: boolean): string {
    switch (bidStatus) {
      case BidStatus.DRAFT:
        return 'Bid Draft';
      case BidStatus.RFP_NOT_SENT:
        return 'RFP Not Sent';
      case BidStatus.RESPONSE_REQUESTED:
        return isAssignedToSameCompanyThatView ? 'RFP Received' : 'RFP Sent';
      case BidStatus.ACCEPTED_WITH_CHANGES:
        return isAssignedToSameCompanyThatView ? 'Bid Received' : 'Bid Sent';
      case BidStatus.ACCEPTED:
        return 'Bid Accepted';
      case BidStatus.DECLINED:
        return 'Bid Declined';
      case BidStatus.REVIEW:
        return 'Bid Review';
    }
  }
}
