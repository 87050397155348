/**
 * Binds a TinyMCE widget to <textarea>
 *     https://github.com/angular-ui/ui-tinymce
 */

declare let angular: angular.IAngularStatic;
declare let $: any;

export const UiTinymce = ['$rootScope', '$compile', '$timeout', '$window', '$sce', 'uiTinymceConfig', function(
  $rootScope,
  $compile,
  $timeout,
  $window,
  $sce,
  uiTinymceConfig
): any {
  'ngInject';
  uiTinymceConfig = uiTinymceConfig || {};
  let generatedIds = 0;
  const ID_ATTR = 'ui-tinymce';

  return {
    require: ['ngModel', '^?form'],
    link: (scope, element, attrs, ctrls) => {
      if (!$window.tinymce) {
        return;
      }

      const ngModel = ctrls[0],
        form = ctrls[1] || null;

      let expression, options, tinyInstance,
        updateView = editor => {
          let content = editor.getContent({ format: options.format }).trim();
          content = $sce.trustAsHtml(content);

          ngModel.$setViewValue(content);
          if (!$rootScope.$$phase) {
            scope.$apply();
          }
        };

      function toggleDisable(disabled): void {
        ensureInstance();
        if (tinyInstance && tinyInstance.getBody()) {
          const body = tinyInstance.getBody();
          const container = tinyInstance.getContainer();
          const toolbar: any = angular.element(container).find('.mce-toolbar-grp');
          if (disabled) {
            toolbar.hide();
            tinyInstance.readonly = true;
          } else {
            toolbar.show();
            tinyInstance.readonly = false;
          }
          // set the body contenteditable attribute to prevent editing
          body.setAttribute('contenteditable', !disabled);

          // set the cursor
          const cursor = disabled ? 'not-allowed' : '';
          // @ts-ignore
          tinymce.DOM.setStyle(body, 'cursor', cursor);
          // @ts-ignore
          tinymce.DOM.setStyle(container, 'cursor', cursor);
        }
      }

      // generate an ID
      // tslint:disable-next-line:prefer-template
      attrs.$set('id', ID_ATTR + '-' + generatedIds++);

      expression = {};

      angular.extend(expression, scope.$eval(attrs.uiTinymce));

      let configSetup;
      // Fixed setup breaks model binding | https://github.com/angular-ui/ui-tinymce/issues/112
      if (expression.setup) {
        configSetup = expression.setup;
        delete expression.setup;
      }

      options = {
        // Update model when calling setContent
        // (such as from the source editor popup)
        setup: ed => {
          ed.on('init', () => {
            ngModel.$render();
            ngModel.$setPristine();
            if (form) {
              form.$setPristine();
            }
            toggleDisable(scope.$eval(attrs.ngDisabled));
          });

          // Update model on button click
          ed.on('ExecCommand', () => {
            ed.save();
            updateView(ed);
          });

          // Update model on change
          ed.on('change', e => {
            ed.save();
            updateView(ed);
          });

          ed.on('blur', () => {
            element[0].blur();
          });

          // Update model when an object has been resized (table, image)
          ed.on('ObjectResized', () => {
            ed.save();
            updateView(ed);
          });

          ed.on('remove', () => {
            element.remove();
          });

          if (expression.setup) {
            expression.setup(ed, {
              updateView
            });
          }

          // Fixed setup breaks model binding | https://github.com/angular-ui/ui-tinymce/issues/112
          // @ts-ignore
          if (configSetup) {
            // @ts-ignore
            configSetup(ed);
          }
        },
        format: 'html',
        selector: `#${attrs.id}`,
        browser_spellcheck: true
      };
      // extend options with initial uiTinymceConfig and
      // options from directive attribute value
      angular.extend(options, uiTinymceConfig, expression);
      // Wrapped in $timeout due to $tinymce:refresh implementation, requires
      // element to be present in DOM before instantiating editor when
      // re-rendering directive
      $timeout(() => {
        // @ts-ignore
        tinymce.init(options);
        toggleDisable(scope.$eval(attrs.ngDisabled));
      });

      ngModel.$formatters.unshift(modelValue => modelValue ? $sce.trustAsHtml(modelValue) : '');

      ngModel.$parsers.unshift(viewValue => viewValue ? $sce.getTrustedHtml(viewValue) : '');

      ngModel.$render = () => {
        ensureInstance();

        const viewValue = ngModel.$viewValue ?
          $sce.getTrustedHtml(ngModel.$viewValue) : '';

        // instance.getDoc() check is a guard against null value
        // when destruction & recreation of instances happen
        if (tinyInstance &&
          tinyInstance.getDoc()
        ) {
          tinyInstance.setContent(viewValue);
          // Triggering change event due to TinyMCE not firing event &
          // becoming out of sync for change callbacks
          tinyInstance.fire('change');
        }
      };

      attrs.$observe('disabled', toggleDisable);

      // This block is because of TinyMCE not playing well with removal and
      // recreation of instances, requiring instances to have different
      // selectors in order to render new instances properly
      scope.$on('$tinymce:refresh', (e, id) => {
        const eid = attrs.id;
        if (angular.isUndefined(id) || id === eid) {
          const parentElement = element.parent();
          const clonedElement = element.clone();
          clonedElement.removeAttr('id');
          clonedElement.removeAttr('style');
          clonedElement.removeAttr('aria-hidden');
          // @ts-ignore
          tinymce.execCommand('mceRemoveEditor', false, eid);
          parentElement.append($compile(clonedElement)(scope));
        }
        toggleDisable(scope.$eval(attrs.ngDisabled));
      });

      scope.$on('$destroy', () => {
        ensureInstance();

        if (tinyInstance) {
          tinyInstance.remove();
          tinyInstance = null;
        }
      });

      function ensureInstance(): void {
        if (!tinyInstance) {
          // @ts-ignore
          tinyInstance = tinymce.get(attrs.id);
        }
      }
    }
  };
}];
