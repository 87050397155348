import { JsonObject, JsonProperty } from 'json2typescript';
import { DrugScenarioStatusConverter } from '@qv-bid/serializers';
import { DrugScenarioStatus } from '@qv-bid/enums';
import { BidUtils } from '@qv-bid/utils';

@JsonObject('DrugItem')
export class DrugItem {
  @JsonProperty('id', Number)
  public id: number = null;

  @JsonProperty('name', String)
  public name: string = null;

  @JsonProperty('status', DrugScenarioStatusConverter)
  public status = DrugScenarioStatus.OPEN;

  @JsonProperty('statusChangedByCompany', String, true)
  public statusChangedByCompany?: string = null;

  public ids?: number[];
  public statuses?: string[];

  public isDismissed(): boolean {
    return BidUtils.isDismissed(this.status);
  }
}
