import { DictionaryItem } from 'quantuvis-angular-common/interfaces';

export enum TierPlacement {
  EMPTY = 8,
  PREFERRED_BRANDED,
  NON_PREFERRED_BRANDED,
  LOWEST_BRANDED_TIER,
  PREFERRED_BRANDED_CO_INSURANCE,
  NON_PREFERRED_CO_INSURANCE,
  PREFERRED_BRAND_COPAY,
  NON_PREFERRED_COPAY,
  SELECT,
  SPECIALTY_PREFERRED,
  SPECIALTY_NON_PREFERRED,
  SPECIALITY,
  INJECTABLE,
  MEDICAL,
  TIER_1,
  TIER_2,
  TIER_3,
  TIER_4,
  TIER_5,
  TIER_6,
  GENERIC,
  BRANDED,
  PREFERRED_GENERIC,
  NON_PREFERRED_GENERIC,
  ON_FORMULARY,
}

export namespace TierPlacement {
  const valuesMap = new Map<TierPlacement, DictionaryItem>([
    [TierPlacement.EMPTY, { id: TierPlacement.EMPTY, name: '' }],
    [TierPlacement.PREFERRED_BRANDED, { id: TierPlacement.PREFERRED_BRANDED, name: 'Preferred Branded' }],
    [TierPlacement.NON_PREFERRED_BRANDED, { id: TierPlacement.NON_PREFERRED_BRANDED, name: 'Non-Preferred Branded' }],
    [TierPlacement.LOWEST_BRANDED_TIER, { id: TierPlacement.LOWEST_BRANDED_TIER, name: 'Lowest Branded Tier' }],
    [
      TierPlacement.PREFERRED_BRANDED_CO_INSURANCE,
      { id: TierPlacement.PREFERRED_BRANDED_CO_INSURANCE, name: 'Preferred Branded Co-Insurance' }
    ],
    [
      TierPlacement.NON_PREFERRED_CO_INSURANCE,
      { id: TierPlacement.NON_PREFERRED_CO_INSURANCE, name: 'Non-Preferred Co-Insurance' }
    ],
    [TierPlacement.PREFERRED_BRAND_COPAY, { id: TierPlacement.PREFERRED_BRAND_COPAY, name: 'Preferred Brand Copay' }],
    [TierPlacement.NON_PREFERRED_COPAY, { id: TierPlacement.NON_PREFERRED_COPAY, name: 'Non-Preferred Copay' }],
    [TierPlacement.SELECT, { id: TierPlacement.SELECT, name: 'Select' }],
    [TierPlacement.SPECIALTY_PREFERRED, { id: TierPlacement.SPECIALTY_PREFERRED, name: 'Specialty Preferred' }],
    [
      TierPlacement.SPECIALTY_NON_PREFERRED,
      { id: TierPlacement.SPECIALTY_NON_PREFERRED, name: 'Specialty Non-Preferred' }
    ],
    [TierPlacement.SPECIALITY, { id: TierPlacement.SPECIALITY, name: 'Specialty' }],
    [TierPlacement.INJECTABLE, { id: TierPlacement.INJECTABLE, name: 'Injectable' }],
    [TierPlacement.MEDICAL, { id: TierPlacement.MEDICAL, name: 'Medical' }],
    [TierPlacement.TIER_1, { id: TierPlacement.TIER_1, name: 'Tier 1' }],
    [TierPlacement.TIER_2, { id: TierPlacement.TIER_2, name: 'Tier 2' }],
    [TierPlacement.TIER_3, { id: TierPlacement.TIER_3, name: 'Tier 3' }],
    [TierPlacement.TIER_4, { id: TierPlacement.TIER_4, name: 'Tier 4' }],
    [TierPlacement.TIER_5, { id: TierPlacement.TIER_5, name: 'Tier 5' }],
    [TierPlacement.TIER_6, { id: TierPlacement.TIER_6, name: 'Tier 6' }],
    [TierPlacement.GENERIC, { id: TierPlacement.GENERIC, name: 'Generic' }],
    [TierPlacement.BRANDED, { id: TierPlacement.BRANDED, name: 'Branded' }],
    [TierPlacement.PREFERRED_GENERIC, { id: TierPlacement.PREFERRED_GENERIC, name: 'Preferred Generic' }],
    [TierPlacement.NON_PREFERRED_GENERIC, { id: TierPlacement.NON_PREFERRED_GENERIC, name: 'Non-Preferred Generic' }],
    [TierPlacement.ON_FORMULARY, { id: TierPlacement.ON_FORMULARY, name: 'On Formulary' }],
  ]);

  export function getValue(tierCompetition: TierPlacement): DictionaryItem {
    return valuesMap.get(tierCompetition);
  }

  export function getValues(): DictionaryItem[] {
    return Array.from(valuesMap.values());
  }
}

