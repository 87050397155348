import { Injectable } from '@angular/core';
import { BidRight } from '@qv-bid/enums';
import { PharmaRightsUtil } from '@qv-bid/utils';
import { PharmaRight } from '@qv-bid/entities';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { PharmaRightDaoService } from '@qv-bid/services/dao';
import { UserService } from '@qv-common/services/auth/user.service';

@Injectable()
export class PharmaRightsService {
  public pharmaRights$: BehaviorSubject<PharmaRight[]> = new BehaviorSubject([]);
  private currentBidVersionId: number;

  constructor(
    private userService: UserService,
    private pharmaRightDaoService: PharmaRightDaoService,
  ) {}

  public isAllowManageCB(): Observable<boolean> {
    return this.pharmaRights$.pipe(
      map((pharmaRights: PharmaRight[]) => this.userService.isCurrentUserPayer()
        || PharmaRightsUtil.isEnabled(pharmaRights, BidRight.ALLOW_PHARMA_TO_CREATE_OR_MODIFY_CB))
    );
  }

  public isAllowDependencies(): Observable<boolean> {
    return this.pharmaRights$.pipe(
      map((pharmaRights: PharmaRight[]) => this.userService.isCurrentUserPayer()
        || PharmaRightsUtil.isEnabled(pharmaRights, BidRight.ALLOW_DEPENDENCIES_FROM_PHARMA))
    );
  }

  public isAllowCreateScenarios(): Observable<boolean> {
    return this.pharmaRights$.pipe(
      map((pharmaRights: PharmaRight[]) => this.userService.isCurrentUserPayer()
        || PharmaRightsUtil.isEnabled(pharmaRights, BidRight.ALLOW_PHARMA_TO_CREATE_SCENARIOS))
    );
  }

  public loadPharmaRights(bidVersionId: number): Observable<PharmaRight[]> {
    if (this.currentBidVersionId === bidVersionId) {
      return this.pharmaRights$;
    }

    return this.pharmaRightDaoService.getByBidVersion(bidVersionId).pipe(
      tap((pharmaRights: PharmaRight[]) => {
        this.pharmaRights$.next(pharmaRights);
        this.currentBidVersionId = bidVersionId;
      }),
    );
  }

  public updateSingleRightAtList(pharmaRight: PharmaRight): void {
    const pharmaRights = this.pharmaRights$.getValue();
    const index = pharmaRights.findIndex((pharmaRightOld: PharmaRight) => pharmaRightOld.id === pharmaRight.id);

    if (index >= 0) {
      pharmaRights[index] = pharmaRight;
      this.pharmaRights$.next(pharmaRights);
    }
  }
}
