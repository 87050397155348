import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { JsonConvert } from 'json2typescript';
import { HttpResponseBody, HttpUrlEncodingCodec, Page } from 'quantuvis-angular-common/api';
import { JSON_CONVERT_TOKEN } from 'quantuvis-angular-common/json-converter/services';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MarketBasketTemplate, MarketBasketTemplateBase } from '../../entities';
import {
  MarketBasketTemplateNameSearchParams,
  MarketBasketTemplateSearchParams,
  MarketBasketTemplateTradingPartnersFilterParams
} from '../../models';
import { MARKET_BASKET_TEMPLATE_URL } from '../../tokens';
import { CompanyBase } from 'quantuvis-core-entities';

@Injectable()
export class MarketBasketTemplateDaoService {

  constructor(
    private httpClient: HttpClient,
    @Inject(JSON_CONVERT_TOKEN) private jsonConvert: JsonConvert,
    @Inject(MARKET_BASKET_TEMPLATE_URL) private readonly resourceUrl: string
  ) {
  }

  public getList(
    marketBasketTemplateSearchParams: MarketBasketTemplateSearchParams
  ): Observable<Page<MarketBasketTemplate>> {
    const params = this.getMarketBasketSearchHttpParams(marketBasketTemplateSearchParams);

    return this.httpClient.get(this.resourceUrl, { params })
      .pipe(
        map(({ data: { content, totalElements, number: currentPage, totalPages } }:
               HttpResponseBody<Page<MarketBasketTemplate>>) => (
            new Page(
              this.jsonConvert.deserializeArray(content, MarketBasketTemplate), totalElements, currentPage, totalPages)
          )
        )
      );
  }

  public getSimpleList(
    marketBasketTemplateNameSearchParams?: MarketBasketTemplateNameSearchParams
  ): Observable<MarketBasketTemplateBase[]> {
    const params = this.getMarketBasketSearchHttpParams(marketBasketTemplateNameSearchParams);

    return this.httpClient.get(`${this.resourceUrl}/names`, { params })
      .pipe(
        map(({ data }: HttpResponseBody<MarketBasketTemplateBase[]>) =>
          this.jsonConvert.deserializeArray(data, MarketBasketTemplateBase)
        )
      );
  }

  public get(id: number): Observable<MarketBasketTemplate> {
    return this.httpClient.get(`${this.resourceUrl}/${id}`)
      .pipe(
        map(({ data }: HttpResponseBody<MarketBasketTemplate>) =>
          this.jsonConvert.deserializeObject(data, MarketBasketTemplate)
        )
      );
  }

  public create(template: MarketBasketTemplate): Observable<MarketBasketTemplate> {
    const serializedTemplate = this.jsonConvert.serialize(template);

    return this.httpClient.post(this.resourceUrl, serializedTemplate)
      .pipe(
        map(({ data }: HttpResponseBody<MarketBasketTemplate>) =>
          this.jsonConvert.deserializeObject(data, MarketBasketTemplate)
        )
      );
  }

  public update(template: MarketBasketTemplate): Observable<MarketBasketTemplate> {
    const serializedTemplate = this.jsonConvert.serialize(template);

    return this.httpClient.put(`${this.resourceUrl}/${template.id}`, serializedTemplate)
      .pipe(
        map(({ data }: HttpResponseBody<MarketBasketTemplate>) =>
          this.jsonConvert.deserializeObject(data, MarketBasketTemplate)
        )
      );
  }

  public delete(id: number): Observable<void> {
    const url = `${this.resourceUrl}/${id}`;

    return this.httpClient.delete<void>(url);
  }

  public getTradingPartnersList(
    filterParams: MarketBasketTemplateTradingPartnersFilterParams
  ): Observable<CompanyBase[]> {
    const params = this.getMarketBasketSearchHttpParams(filterParams);

    return this.httpClient.get(`${this.resourceUrl}/trading-partners`, { params }).pipe(
      map(({ data }: HttpResponseBody<CompanyBase[]>) =>
        this.jsonConvert.deserializeArray(data, CompanyBase)
      )
    );
  }

  private getMarketBasketSearchHttpParams(searchParams: MarketBasketTemplateNameSearchParams
      | MarketBasketTemplateSearchParams | MarketBasketTemplateTradingPartnersFilterParams): HttpParams {
    let params = new HttpParams({ encoder: new HttpUrlEncodingCodec() });

    if (!searchParams) return params;

    Object.keys(searchParams).forEach((searchParamKey: string) => {
      const value = searchParams[searchParamKey]?.toString();

      if (searchParams.hasOwnProperty(searchParamKey) && value) {
        params = params.set(searchParamKey, value);
      }
    });

    return params;
  }
}
