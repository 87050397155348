<header class="site-header">
  <div class="site-header__left">
    <div class="site-header__item site-header__item--no-padding site-header__item--desktop">
      <qv-navigation-main></qv-navigation-main>
    </div>
    <div class="site-header__item site-header__item--no-vertical-padding site-header__item--mobile">
      <qv-navigation-mobile></qv-navigation-mobile>
    </div>
    <div *ngIf="showEnterpriseButton()" class="site-header__item site-header__item--no-vertical-padding">
      <div class="horizontal-menu__item horizontal-menu__extra-item">
        <button [ngClass]="'qv-button qv-button-enterprise'" mat-raised-button routerLink="/enterprise">
          <span class="qv-button-enterprise__text">Upgrade</span>
          <span class="qv-button-enterprise__text qv-button-enterprise__text--full">&nbsp;to Quantuvis Plus</span>
        </button>
      </div>
    </div>
  </div>

  <div class="site-header__right">
    <div *ngIf="isMyAccountsSwitcherAvailable">
      <ng-template #allAccount>
        <span>All <span class="qv-toggle__option qv-toggle__option--long">Accounts</span></span>
      </ng-template>
      <qv-slide-toggle
          (checkedChange)="onToggleMyAccounts($event)"
          [checked]="isFilterByMyAccountsEnabled | async"
          [disabledTooltip]="disabledTooltip"
          [disabled]="isMyAccountsToggleDisabled()"
          [firstOption]="allAccount"
          [firstTooltip]="allAccountsTooltip"
          [secondOption]="myAccount"
          [secondTooltip]="myAccountsTooltip"
          toggleId="allMyAcc"
          toggleName="allMyAccSwitcher">
      </qv-slide-toggle>
      <ng-template #myAccount>
        <span>My <span class="qv-toggle__option qv-toggle__option--long">Accounts</span></span>
      </ng-template>
    </div>
    <div class="site-header__company-logo">
      <img [src]="companyLogoUrl" class="company-logo" onerror="this.style.display='none'">
    </div>
    <div>
      <qv-notification-bell class="site-header__notification-bell"></qv-notification-bell>
    </div>
    <div class="site-header__profile-icon">
      <qv-profile-menu [matTooltip]="tooltips.PROFILE_ICON"></qv-profile-menu>
    </div>
  </div>
</header>
