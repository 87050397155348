import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Directive, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BaseSection } from '@qv-bid/classes/sections/base-section';
import { MarketBasket, Rebate } from '@qv-bid/entities';
import { NdcValue, Note } from '@qv-term/entities';
import { MarketBasketModalOutput, MarketBasketSection, SectionChange, SectionChangeFailed } from '@qv-bid/models';
import { NotesMetadata } from '@qv-term/models';
import { TermUtils } from '@qv-term/utils';
import { BidStatus } from 'quantuvis-core-entities';
import { DrugScenarioStatus } from '@qv-bid/enums';
import { RequestObject } from '@qv-bid/models/request-object.interface';
import { RebateDaoService, ScenarioDaoService } from '@qv-bid/services/dao';
import { DrugFormService } from '@qv-bid/services/drug';
import { SectionChangeManager } from '@qv-bid/services/sections/section-change-manager.service';
import { BidUtils } from '@qv-bid/utils';
import { QvCache } from '@qv-common/decorators';
import { SvgIconName } from '@qv-common/enums';
import { ViewPerspectiveService } from '@qv-common/services/auth';
import { resources } from '@qv-common/static';
import { drugTermsConstants } from '@qv-term/constants';
import { CoreUtils } from '@qv-common/utils';
import { TermName, TermSection } from '@qv-term/enums';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { of } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { UserService } from '@qv-common/services/auth/user.service';

@UntilDestroy()
@Directive()
export class RebateSection extends BaseSection implements OnInit {
  @Input()
  public netEffectivePrice: NdcValue<number>;
  @Input()
  public isNdcLocked = false;
  @Input()
  public cbId: number;
  @Input()
  public bidStatus: BidStatus;
  @Input()
  public rebate: Rebate;
  @Input()
  public marketBasket: MarketBasket = null;
  @Input()
  public marketBasketLock = false;
  @Input()
  public scenarioId: number;
  @Input()
  public drugName: string;
  @Input()
  public drugClass: string;
  @Input()
  public drugId?: number;
  @Input()
  public drugStatus: DrugScenarioStatus;
  @Input()
  public isDismissed: boolean;
  @Input()
  public isBidInternal: boolean;
  @Input()
  public manufacturerCompanyOldName: string;
  @Input()
  public manufacturerCompanyId: number;

  @Output()
  public historicNotesDeleted = new EventEmitter<string>();
  @Output()
  public marketBasketUpdated = new EventEmitter<MarketBasketModalOutput>();
  @Output()
  public notesUpdated = new EventEmitter<void>();

  public isUserPharmaOrPharmaPerspective: boolean;

  public readonly svgIconName = SvgIconName;
  public readonly resources = resources;
  public readonly drugTermsConstants = drugTermsConstants;
  public readonly termName = TermName;
  public readonly sectionName = TermSection.REBATE;

  constructor(
    drugFormService: DrugFormService,
    private scenarioDaoService: ScenarioDaoService,
    protected changeDetectorRef: ChangeDetectorRef,
    private rebateDaoService: RebateDaoService,
    private userService: UserService,
    private viewPerspectiveService: ViewPerspectiveService,
    sectionChangeManager: SectionChangeManager,
  ) {
    super(drugFormService, changeDetectorRef, sectionChangeManager);
  }

  public ngOnInit(): void {
    this.isUserPharmaOrPharmaPerspective =
      BidUtils.isUserPharmaOrPharmaPerspective(
        this.userService.isCurrentUserPharma(),
        this.isBidInternal, this.viewPerspectiveService.isPharmaViewPerspective()
      );

    this.initSectionEditModeChangeHandler();
    this.initSectionChangeHandler();
    this.initMarketBasketSectionChangeHandler();
  }

  @QvCache()
  public isMarketBasketEditModeAvailable(isEditMode: boolean, drugId: number): boolean {
    return isEditMode && !Boolean(drugId);
  }

  @QvCache()
  public isEditModeByStateService(isEditMode: boolean, isDismissed: boolean): boolean {
    return isEditMode && !isDismissed;
  }

  public onMarketBasketUpdated(data: MarketBasketModalOutput): void {
    const sectionName = TermSection.MARKET_BASKET;
    const section = new MarketBasketSection(this.marketBasketLock, data.marketBasket);
    const sectionChange = new SectionChange(this.scenarioId, this.drugId, sectionName, section);

    this.sectionChangeManager.sectionSaveSuccessEvent$.emit(sectionChange);
    this.marketBasketUpdated.emit(data);
  }

  @QvCache()
  public isBaseRebateLow(baseRebate: number, minBaseRebate: number): boolean {
    return CoreUtils.isNumber(baseRebate) && (baseRebate < minBaseRebate);
  }

  @QvCache()
  public isBaseRebateRequired(
    baseRebate: NdcValue<number>,
    baseRebateLock: boolean,
    netEffectivePrice: NdcValue<number>,
    isUserPharmaOrPharmaPerspective: boolean
  ): boolean {
    if (isUserPharmaOrPharmaPerspective && !baseRebateLock && !baseRebate.isNdc) {
      const netEffectivePriceHasEmptyValue = !CoreUtils.isNumber(netEffectivePrice.value) && !netEffectivePrice.isNdc;
      return !CoreUtils.isNumber(baseRebate.value) && netEffectivePriceHasEmptyValue;
    }

    return false;
  }

  @QvCache()
  public isMinBaseRebateEditable(isEditMode: boolean, isUserPharmaOrPharmaPerspective: boolean): boolean {
    return isEditMode && !isUserPharmaOrPharmaPerspective;
  }

  public getMarketBasketFormControl(termName: TermName): FormControl {
    return this.drugFormService.getTermControl(termName, TermSection.MARKET_BASKET);
  }

  public updateCurrentNote(note: Note): void {
    const updatedNotes = Object.assign(new NotesMetadata(), this.rebate.notes, { getCurrent: note } as NotesMetadata);
    const updatedSection = Object.assign(new Rebate(), this.rebate, { notes: updatedNotes } as Rebate);
    const sectionChange = new SectionChange(this.scenarioId, this.drugId, this.sectionName, updatedSection);

    this.sectionChangeManager.sectionSaveSuccessEvent$.emit(sectionChange);
    this.notesUpdated.emit();
  }

  public deleteHistoricNote(): void {
    const updatedNotes = Object.assign(new NotesMetadata(), this.rebate.notes, { getHistoric: [] } as NotesMetadata);
    const updatedSection = Object.assign(new Rebate(), this.rebate, { notes: updatedNotes } as Rebate);
    const sectionChange = new SectionChange(this.scenarioId, this.drugId, this.sectionName, updatedSection);

    this.sectionChangeManager.sectionSaveSuccessEvent$.emit(sectionChange);
    this.historicNotesDeleted.next();
  }

  @QvCache()
  public isBaseRebateEditable(isEditMode: boolean, isUserPharmaOrPharmaPerspective: boolean,
                              isBaseRebateLocked: boolean): boolean {
    return TermUtils.isEditable(isEditMode, isUserPharmaOrPharmaPerspective, isBaseRebateLocked);
  }

  protected initSectionEditModeChangeHandler(): void {
    this.drugFormService.isEditMode(this.sectionName).pipe(untilDestroyed(this))
      .subscribe((isEditMode: boolean) => {
        this.isEditMode = isEditMode;
        this.changeDetectorRef.markForCheck();
        this.checkAndOpenBaseRebatePopover(this.rebate);
      });
  }

  protected initSectionChangeHandler(): void {
    this.drugFormService.sectionChangeHandler(this.sectionName)
      .pipe(
        tap(() => this.sectionChangeManager.isSectionChanging$.next(true)),
        switchMap((changes: Rebate) => this.rebateDaoService.update(changes, this.scenarioId, this.isBidInternal,
          this.drugId)
          .pipe(
            tap((updatedSection: Rebate) => {
              const sectionChange = new SectionChange(this.scenarioId, this.drugId, this.sectionName, updatedSection);

              this.sectionChangeManager.sectionSaveSuccessEvent$.emit(sectionChange);
            }),
            catchError((error: HttpErrorResponse) => {
              this.sectionChangeManager.isSectionChangeFailed$.next(new SectionChangeFailed(this.sectionName, error));

              return of(this.rebate);
            }),
          )
        ),
        this.sectionLeaveHandler(),
      ).subscribe((rebate: Rebate) => this.checkAndOpenBaseRebatePopover(rebate));
  }

  protected initMarketBasketSectionChangeHandler(): void {
    const sectionName = TermSection.MARKET_BASKET;

    this.drugFormService.sectionChangeHandler(sectionName)
      .pipe(
        tap(() => this.sectionChangeManager.isSectionChanging$.next(true)),
        switchMap((changes: RequestObject) =>
          this.scenarioDaoService.setMarketBasketLock(this.scenarioId, changes[TermName.MARKET_BASKET_LOCK])
            .pipe(
              tap((marketBasketLock: boolean) => {
                const section = new MarketBasketSection(marketBasketLock, this.marketBasket);
                const sectionChange = new SectionChange(this.scenarioId, this.drugId, sectionName, section);
                this.sectionChangeManager.sectionSaveSuccessEvent$.emit(sectionChange);
              }),
              catchError((error: HttpErrorResponse) => {
                this.sectionChangeManager.isSectionChangeFailed$.next(new SectionChangeFailed(sectionName, error));

                return of(this.marketBasketLock);
              }),
            )
        ),
        this.sectionLeaveHandler()
      )
      .subscribe();
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars,no-empty-function
  protected checkAndOpenBaseRebatePopover(rebate: Rebate): void {}
}
