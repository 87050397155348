<div
    *ngIf="isVisible(value, showOpenStatus)"
    [matTooltipDisabled]="shouldDisableTooltip(value)"
    [matTooltip]="getTooltipMessage(value, companyName)"
    [ngClass]="getClass(value)"
    class="drug-status"
>
  <mat-icon [svgIcon]="svgIconName.FLAG" class="qv-icon qv-icon--16"></mat-icon>
  <span class="drug-status__label">{{ getDisplayValue(value) }}</span>
</div>
