import { Injectable } from '@angular/core';
import { Range } from '@qv-common/entities';
import { BidListFilterState } from '@qv-bid-list/models';
import { BidListTableColumnName } from '@qv-bid-list/enums';
import { BehaviorSubject } from 'rxjs';
import { Moment } from 'moment';
import { BidListRemoteDataSource } from '@qv-bid-list/components/bid-list-table/data-source';
import { BidListEventBusService } from '@qv-bid-list/services/bid-list-event-bus.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Injectable()
export class BidListRangeFilterService {
  public filterForApply = new BidListFilterState();
  public filters = new Map<BidListTableColumnName, BehaviorSubject<Range<Moment>>>();

  constructor(
    private bidListRemoteDataSource: BidListRemoteDataSource,
    private bidListEventBusService: BidListEventBusService
  ) {
    this.initFilters();
    this.initResetFilterHandler();
  }

  public getFilterValue(name: BidListTableColumnName): Range<Moment> {
    return this.bidListRemoteDataSource.getFilterValue(BidListTableColumnName.stateValue(name)) as Range<Moment>;
  }

  public onFilterChange(name: BidListTableColumnName, filterValue: Range<Moment>): void {
    this.filters.get(name).next(filterValue);
    this.updateFilterForApplyState(name, filterValue);
  }

  public updateFilterForApplyState(name: BidListTableColumnName, filterValue: Range<Moment>): void {
    this.filterForApply = Object.assign(this.filterForApply, {
      [BidListTableColumnName.stateValue(name)]: filterValue
    });
  }

  public onFilterApply(name: BidListTableColumnName): void {
    const filterStateForUpdate = this.bidListRemoteDataSource.getFilterStateForUpdate(
      BidListTableColumnName.stateValue(name), this.filterForApply
    );
    const cleanedFilterStateForUpdate = BidListRangeFilterService.deleteEmptyRanges(filterStateForUpdate);

    this.bidListRemoteDataSource.filterChanged(cleanedFilterStateForUpdate);
  }

  private static deleteEmptyRanges(filterState: BidListFilterState): BidListFilterState {
    const clone = Object.assign(new BidListFilterState(), filterState);

    Object.keys(clone).forEach((key: string) => {
      if (clone[key] instanceof Range && clone[key].isEmpty()) {
        delete clone[key];
      }
    });

    return clone;
  }

  private initFilters(): void {
    BidListTableColumnName.getDateRangeColumnNames().forEach((name: BidListTableColumnName) => {
      this.filters.set(name, new BehaviorSubject(this.getFilterValue(name) || new Range<Moment>()));
    });
  }

  private initResetFilterHandler(): void {
    this.bidListEventBusService.resetFiltersEvent
      .pipe(untilDestroyed(this))
      .subscribe(() => this.resetFilter());
  }

  private resetFilter(): void {
    this.filters.forEach((filter: BehaviorSubject<Range<Moment>>) => filter.next(new Range()));
    this.filterForApply = new BidListFilterState();
    this.bidListRemoteDataSource.filterChanged(null);
  }
}
