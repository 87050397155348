import { ScenarioFieldName } from './scenario-field-name.enum';

export enum ScenarioFieldGroup {
  MAIN = 'MAIN',
  PRICING = 'PRICING',
  PRICE_PROTECTION = 'PRICE_PROTECTION',
  UTILIZATION_MANAGEMENT = 'UTILIZATION_MANAGEMENT',
  OTHER = 'OTHER'
}

export namespace ScenarioFieldGroup {
  export const groups = new Map<ScenarioFieldGroup, ScenarioFieldName[]>([
    [
      ScenarioFieldGroup.MAIN,
      [
        ScenarioFieldName.IS_ASSUMPTION_EXISTS,
        ScenarioFieldName.STATUS,
        ScenarioFieldName.ELIGIBILITY_STATUS,
        ScenarioFieldName.SOURCE_TYPE_ID,
        ScenarioFieldName.VOLUME,
        ScenarioFieldName.EXCLUDED_NDC_COUNT,
        ScenarioFieldName.START_DATE,
        ScenarioFieldName.END_DATE,
        ScenarioFieldName.CONTRACTED_BUSINESS_NAME,
        ScenarioFieldName.TIER_PLACEMENT,
        ScenarioFieldName.TIER_COMPETITION,
        ScenarioFieldName.COMPETITION_GROUP,
        ScenarioFieldName.COMPETITORS_TIER,
      ]
    ],
    [
      ScenarioFieldGroup.PRICING,
      [
        ScenarioFieldName.WHOLESALE_ACQUISITION_PRICE,
        ScenarioFieldName.TARGET_DISCOUNT,
        ScenarioFieldName.BASE_DISCOUNT,
        ScenarioFieldName.ADMIN_FEE,
        ScenarioFieldName.PORTAL_FEE,
        ScenarioFieldName.MAX_FORMULARY_REBATE,
        ScenarioFieldName.ACTUAL_PP_REBATE,
        ScenarioFieldName.MARKET_SHARE_TIER,
        ScenarioFieldName.NET_EFFECTIVE_PRICE,
      ]
    ],
    [
      ScenarioFieldGroup.PRICE_PROTECTION,
      [
        ScenarioFieldName.PRICE_PROTECTION_INDEX,
        ScenarioFieldName.BASELINE_START_DATE,
        ScenarioFieldName.NEW_PRICE_EFFECTIVE_DATE,
        ScenarioFieldName.PRICE_PROTECTION_RESET,
        ScenarioFieldName.PRICE_PROTECTION_THRESHOLD,
        ScenarioFieldName.THRESHOLD_HISTORY,
        ScenarioFieldName.ROLL_IN,
        ScenarioFieldName.PRICE_PROTECTION_FEE_ABOVE_THRESHOLD,
        ScenarioFieldName.PRICE_PROTECTION_FEE_BELOW_THRESHOLD,
      ]
    ],
    [
      ScenarioFieldGroup.UTILIZATION_MANAGEMENT,
      [
        ScenarioFieldName.PRIOR_AUTH_ALLOWED,
        ScenarioFieldName.STEP_THERAPY_ALLOWED,
        ScenarioFieldName.PRIOR_AUTH_REQUIRED,
        ScenarioFieldName.STEP_THERAPY_REQUIRED,
        ScenarioFieldName.QUANTITY_LIMIT,
        ScenarioFieldName.OTHER_UM_DETAILS
      ]
    ],
    [
      ScenarioFieldGroup.OTHER,
      [
        ScenarioFieldName.CHANNEL,
        ScenarioFieldName.PLAN_TYPE,
        ScenarioFieldName.PLAN_ID,
        ScenarioFieldName.IS_FORMULARY_EXISTS,
        ScenarioFieldName.LIS_FLAG,
        ScenarioFieldName.PRICE_PROTECTION_METHOD,
        ScenarioFieldName.ASP_GUARANTEE_METHOD,
        ScenarioFieldName.PAYMENT_METHOD,
        ScenarioFieldName.IS_EXCLUSION_EXISTS,
        ScenarioFieldName.NOTES,
        ScenarioFieldName.QA_COMPLETED_DATE,
        ScenarioFieldName.QA_COMPLETED_BY,
        ScenarioFieldName.DATA_ENTERED_BY,
        ScenarioFieldName.CORIUM_STATUS,
        ScenarioFieldName.DATA_FEE,
        ScenarioFieldName.ENTERPRISE_FEE,
        ScenarioFieldName.FORMULARY_COMPLIANCE_REBATE
      ]
    ]
  ]);

  export const displayValues = new Map<ScenarioFieldGroup, string>([
    [ScenarioFieldGroup.MAIN, 'Rebate'],
    [ScenarioFieldGroup.PRICING, 'Payment Terms'],
    [ScenarioFieldGroup.PRICE_PROTECTION, 'Price Protection'],
    [ScenarioFieldGroup.UTILIZATION_MANAGEMENT, 'Utilization Management'],
    [ScenarioFieldGroup.OTHER, 'Additional Fields'],
  ]);

  export const displayValue = (fieldName: ScenarioFieldGroup): string => displayValues.get(fieldName);
}
