<div class="header-notification" *ngIf="notification">
  <mat-icon class="header-notification__warning-icon validation-icon"
    [class.validation-icon--warn]="notification.id === drugAlertType.START_END_DATE_DOESNT_MATCH"
    [class.validation-icon--error]="notification.id === drugAlertType.INCOMPLETE_VALUES"
    [svgIcon]="svgIconName.WARNING">
  </mat-icon>
  <div class="header-notification__message"
    [class.header-notification__message--error]="notification.id === drugAlertType.INCOMPLETE_VALUES">
    {{notification.title}}
  </div>
</div>