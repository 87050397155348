import { Injectable } from '@angular/core';
import { ApiUrlPrefix } from '@qv-common/enums';
import { ViewPerspectiveService } from '@qv-common/services';
import { ApiService } from '@qv-common/services/api';
import { HttpUtils } from '@qv-common/utils';
import { Observable } from 'rxjs';

@Injectable()
export class ScenarioDependencyDaoService {

  constructor(private apiService: ApiService, private viewPerspectiveService: ViewPerspectiveService) { }

  public create(versionId: number, cbId: number, scenarioIds: number[], isBidInternal: boolean): Observable<null> {
    let url = this.getRestUrl(versionId);

    const perspective = this.viewPerspectiveService.getViewPerspective();
    url = HttpUtils.appendPerspectiveQueryParamToUrlIfBidIsInternal(url, perspective, isBidInternal);

    return this.apiService.post(url, { cbId, scenarioIds }) as Observable<null>;
  }

  public update(
    versionId: number,
    dependencyId: number,
    scenarioIds: number[],
    isBidInternal: boolean
  ): Observable<null> {
    let url = this.getRestUrl(versionId, dependencyId);

    const perspective = this.viewPerspectiveService.getViewPerspective();
    url = HttpUtils.appendPerspectiveQueryParamToUrlIfBidIsInternal(url, perspective, isBidInternal);

    return this.apiService.put(url, { scenarioIds }) as Observable<null>;
  }

  public delete(versionId: number, dependencyId: number, isBidInternal: boolean): Observable<null> {
    let url = this.getRestUrl(versionId, dependencyId);

    const perspective = this.viewPerspectiveService.getViewPerspective();
    url = HttpUtils.appendPerspectiveQueryParamToUrlIfBidIsInternal(url, perspective, isBidInternal);

    return this.apiService.delete(url) as Observable<null>;
  }

  private getRestUrl(versionId: number, dependencyId?: number): string {
    let url = `${ApiUrlPrefix.BIDS}/bid-versions/${versionId}/dependencies`;

    if (dependencyId) {
      url += `/${dependencyId}`;
    }

    return url;
  }
}
