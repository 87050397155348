/* tslint:disable:triple-equals */
/**
 *  Unique filter - returns unique values from the input array of elements
 *   input  - array of values
 *   key    - key to match object for unique check
 *   value  - is value object or array of elements
 */
export const UniqueFilter = (): any => (input, key, value) => {
  const unique = {};
  const uniqueList = [];
  for (let i = 0; i < input.length; i++) {
    if (typeof unique[input[i][key]] == 'undefined') {
      unique[input[i][key]] = '';
      if (value) {
        uniqueList.push(input[i][key]);
      } else {
        uniqueList.push(input[i]);
      }
    }
  }
  return uniqueList;
};
