/* tslint:disable:triple-equals */
import { constants, resources } from '@qv-common/static';
import { QuantuvisBusinessFeature, QuantuvisPlusFeature } from '@qv-company/enums';
import { ApiUrlPrefix } from '@qv-common/enums';
import { HttpResponse } from '@angular/common/http';
import { catchError, tap } from 'rxjs/operators';
import { of } from 'rxjs';

declare let angular: angular.IAngularStatic;
declare let $: any;

// @ts-ignore
import templateUrl from './productEnrollment.html';

const ProductEnrollmentComponent = {
  template: templateUrl,
  bindings: {},
  controller: class {
    public static $inject = ['$scope', '$filter', '$q', 'NgTableParams', 'apiService', 'preferences', 'permissionService',
      'util', 'spinnerService', '$window'];
    constructor($scope, $filter, $q, NgTableParams, apiService, preferences, permissionService, util, spinnerService,
                $window) {
      'ngInject';
      $scope.allow = permissionService.isAllowed.bind(permissionService);
      $scope.activePackageTab = constants.PACKAGE_TYPES.QUANTUVIS_PLUS;
      $scope.companyTypeConstants = constants.COMPANY_TYPES;

      function init(): void {
        $scope.companyTypes = [
          {
            value: constants.ALL_OPTION.toLowerCase(),
            pluralValue: constants.ALL_COMPANIES_OPTION
          },
          $scope.companyTypeConstants.PAYER,
          $scope.companyTypeConstants.PHARMA,
        ];
        const allOption = constants.ALL_OPTION.toLowerCase();
        const companyTypePreference = preferences.getUserPreference(
          constants.USER_PREFERENCES_ADMINISTRATOR_PAGE_COMPANY_TYPE_OPTION,
          allOption
        );
        $scope.requestFor = $scope.companyTypes.map(companyType => companyType.value).includes(companyTypePreference)
          ? companyTypePreference
          : allOption;
        $scope.searchName = undefined;
        $scope.errorMessage = undefined;
        $scope.quantuvisPlusFeature = QuantuvisPlusFeature;
        $scope.quantuvisBusinessFeatures = QuantuvisBusinessFeature;
        $scope.packageTypes = constants.PACKAGE_TYPES;
        $scope.featureConstants = Object.assign({},
          QuantuvisPlusFeature,
          QuantuvisBusinessFeature
        );
      }

      init();

      if (permissionService.isAllowed('quantuvis_administrator')) {
        spinnerService.start(resources.GENERAL.LOADING_LIST);

        apiService.get(`${ApiUrlPrefix.OLD}/enterpriseFeatures/get/all`).pipe(
          tap((response: HttpResponse<any>) => {
            const data = response.body;
            const idToFeatures = {};
            angular.forEach(data, feature => {
              const name = feature.company.name;
              const companyId = feature.company.id;
              const type = feature.company.type;
              const key = companyId + type;
              let featuresForId = idToFeatures[key];
              if (typeof featuresForId === 'undefined' || featuresForId == null) {
                featuresForId = {};
                featuresForId.companyId = companyId;
                featuresForId.companyName = name;
                featuresForId.companyType = type;
                idToFeatures[key] = featuresForId;
              }
              featuresForId[feature.name] = feature.allow;
            });

            const tableData = [];
            angular.forEach(idToFeatures, value => {
              tableData.push(value);
              $scope.checkFullPackagesForCompany(value);
            });

            $scope.tableParams = new NgTableParams({
              page: 1, // show first page
              count: 1e20, // count per page
              sorting: {
                companyName: 'asc' // initial sorting
              },
              filter: {
                companyName: '',
                companyType: ''
              }
            }, {
              counts: [], // hide page counts control
              total: tableData.length, // length of data
              getData: (params) => {
                const deferred = $q.defer();
                // use build-in angular filter
                let orderedData = params.sorting() ? $filter('orderBy')(tableData, params.orderBy()) : tableData;
                orderedData = params.filter() ? $filter('filter')(orderedData, params.filter()) : orderedData;
                deferred.resolve(
                  orderedData.slice((params.page() - 1) * params.count(), params.page() * params.count())
                );

                return deferred.promise;
              }
            });

            $scope.getSortClass = column => {
              if ($scope.tableParams.isSortBy(column, 'asc')) {
                return 'sort-asc';
              }
              if ($scope.tableParams.isSortBy(column, 'desc')) {
                return 'sort-desc';
              }
              return '';
            };

            $scope.doFilter();

            util.applyScope($scope);
            spinnerService.stop();
          }),
          catchError(() => {
            $scope.errorMessage = 'Error getting the enterprise features list.';
            spinnerService.stop();
            util.applyScope($scope);

            return of(null);
          })
        ).subscribe();

        $scope.$watch('requestFor', (requestFor) => {
          preferences.setUserPreference(constants.USER_PREFERENCES_ADMINISTRATOR_PAGE_COMPANY_TYPE_OPTION, requestFor);
        });

        $scope.setQPlusPackageTab = () => {
          $scope.activePackageTab = $scope.packageTypes.QUANTUVIS_PLUS;
          $scope.setDefaultCompanyType();
          $scope.doFilter();
        };

        $scope.setQBusinessPackageTab = () => {
          $scope.activePackageTab = $scope.packageTypes.QUANTUVIS_BUSINESS;
          $scope.doFilter();
        };

        $scope.save = (company, featureName) => {
          if (featureName === QuantuvisPlusFeature.BID_HISTORY && !company[featureName]
            && company[QuantuvisPlusFeature.REVISION_COMPARISON]) {
            company[QuantuvisPlusFeature.REVISION_COMPARISON] = false;
            $scope.save(company, QuantuvisPlusFeature.REVISION_COMPARISON);
          }
          if (featureName === QuantuvisPlusFeature.REVISION_COMPARISON && company[featureName]
            && !company[QuantuvisPlusFeature.BID_HISTORY]) {
            company[QuantuvisPlusFeature.BID_HISTORY] = true;
            $scope.save(company, QuantuvisPlusFeature.BID_HISTORY);
          }
          util.saving();

          const feature = {
            company: {
              id: company.companyId,
              name: company.companyName,
              type: company.companyType
            },
            name: featureName,
            allow: company[featureName]
          };

          $scope.checkFullPackagesForCompany(company);
          apiService.post(`${ApiUrlPrefix.OLD}/enterpriseFeatures/save`, feature).pipe(
            tap(() => util.saved()),
            catchError(() => {
              util.saveError();
              return of(null);
            })
          ).subscribe();
        };

        $scope.getRowClass = index => index % 2 == 0 ? 'even' : 'odd';

        $scope.doSortBy = (column, $event) => {
          const target = $($event.target);

          if (!target.hasClass('btn') && !target.hasClass('icon')) {
            const sort = {};
            sort[column] = $scope.tableParams.isSortBy(column, 'desc') ? 'asc' : 'desc';
            if (column != 'companyName') {
              // @ts-ignore
              sort.companyName = 'asc';
            }
            $scope.tableParams.sorting(sort);
          }
        };

        $scope.doFilter = () => {
          const filter = {
            companyType: $scope.requestFor,
            companyName: $scope.searchName
          };
          if ($scope.activePackageTab === constants.PACKAGE_TYPES.QUANTUVIS_BUSINESS) {
            filter.companyType = constants.CompanyTypes.PHARMA.toLowerCase();
          } else if ($scope.requestFor === 'all') {
            filter.companyType = '';
          }
          $scope.tableParams.filter(filter);
        };

        $scope.setDefaultCompanyType = () => {
          $scope.requestFor = preferences.getUserPreference(
            constants.USER_PREFERENCES_ADMINISTRATOR_PAGE_COMPANY_TYPE_OPTION,
            constants.ALL_OPTION.toLowerCase()
          );
        };

        $scope.checkFullPackagesForCompany = (company) => {
          util.forEachObjectProperty(constants.PACKAGE_TYPES, (packageName) => {
            $scope.checkFullPackageForCompany(company, packageName);
          });
        };

        $scope.checkFullPackageForCompany = (company, packageName) => {
          const packageFeatures = $scope.getFeaturesByPackage(packageName);
          company[packageName] = true;
          util.forEachObjectProperty(packageFeatures, (feature) => {
            if (company[feature] == undefined) {
              company[feature] = false;
            }

            if (!company[feature]) {
              company[packageName] = false;
            }
          });
        };

        $scope.getFeaturesByPackage = (packageName) => {
          switch (packageName) {
            case constants.PACKAGE_TYPES.QUANTUVIS_PLUS:
              return QuantuvisPlusFeature;
            case constants.PACKAGE_TYPES.QUANTUVIS_BUSINESS:
              return QuantuvisBusinessFeature;
          }
        };

        $scope.setFullPackage = (company, packageName) => {
          const packageFeatures = $scope.getFeaturesByPackage(packageName);
          const all = company[packageName];
          util.forEachObjectProperty(packageFeatures, (featureName) => {
            if (company[featureName] !== all) {
              company[featureName] = all;
              $scope.save(company, featureName);
            }
          });
        };

        $scope.isPharma = (company) => {
          return company.companyType === $scope.companyTypeConstants.PHARMA.value;
        };

      }
      $scope.capitaliseFirstLetter = str => util.capitaliseFirstLetter(str);
    }
  }
};
export default ProductEnrollmentComponent;
