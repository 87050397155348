import { Component, OnDestroy, OnInit } from '@angular/core';

import { Observable, Subscription } from 'rxjs';

import { NotificationBellService } from './notification-bell.service';
import { AssetService } from '@qv-shared/services';
import { SvgIconName } from '@qv-common/enums';


@Component({
  selector: 'qv-notification-bell',
  templateUrl: './notification-bell.component.html',
  styleUrls: ['./notification-bell.component.scss'],
  providers: []
})
export class NotificationBellComponent implements OnInit, OnDestroy {
  public count: Observable<number>;
  public readonly svgIconName = SvgIconName;

  private notificationSubscription: Subscription;

  constructor(private notificationService: NotificationBellService, private assetService: AssetService) {}

  get getAssetService(): AssetService {
    return this.assetService;
  }

  public ngOnInit(): void {
    this.notificationSubscription = this.notificationService.initCheckCountOfNotificationsHandler();
    this.count = this.notificationService.getNotificationCount;
  }

  public ngOnDestroy(): void {
    if (this.notificationSubscription && this.notificationSubscription.unsubscribe) {
      this.notificationSubscription.unsubscribe();
    }
  }
}
