/* tslint:disable:triple-equals */
declare let $: any;

export const NgRemove = (): any => ({
  restrict: 'A',
  link: (scope, element, attrs) => {
    if (scope.$eval(attrs.ngRemove) == true) {
      $(element).remove();
    }

  }
});

