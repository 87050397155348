<header class="standard-ndc-header"
  [class.standard-ndc-header--has-error]="(getNdcDrugAlertNotification() | async)?.id === drugAlertType.INCOMPLETE_VALUES"
>
  <div class="standard-ndc-header__left-block">
    <mat-checkbox class="standard-ndc-header__checker"
      *ngIf="!(bidStateService.isViewMode() | async)"
      [checked]="ndcSelected"
      (change)="$event && drugSelectService.ndcSelection.toggle(ndc.drugId)"
      [disabled]="drugSelectUtilsService.isNdcCheckboxDisabled(bidStateService.isReviewMode | async, bidStateService.isBidInvalid() | async)"
    ></mat-checkbox>
    <qv-scenario-dependency
      *ngIf="scenario.dependencyIndex"
      [dependencyIndex]="scenario.dependencyIndex"
      (click)="onOpenScenarioDependencyModal()">
    </qv-scenario-dependency>
    <qv-lock
      class="standard-ndc-header__lock"
      *ngIf="isLockVisible()"
      [isDisabled]="true"
      [locked]="ndc.isLocked"
    ></qv-lock>
  </div>
  <div class="standard-ndc-header__drug">
    <span class="standard-ndc-header__drug-name">
      {{ scenario.drugName }} {{ ndc.ndc }}
    </span>
    <span class="standard-ndc-header__drug-description">
      {{ ndc.description }}
    </span>
    <qv-wac [data]="ndc" class="standard-ndc-header__wac"></qv-wac>
    <qv-drug-status [value]="ndc.status" [companyName]="ndc.statusChangedBy" class="standard-ndc-header__status" ></qv-drug-status>
    <qv-standard-drug-alert-notification [notification]="getNdcDrugAlertNotification() | async">
    </qv-standard-drug-alert-notification>
  </div>
</header>

