<section class="qv-sidebar-section">
  <div class="section-toolbar">
    <div class="section-toolbar__title">
      <h2>Contracted businesses</h2>
    </div>
  </div>
  <mat-select (selectionChange)="onChangeContractedBusiness($event)"
              [value]="selectedContractedBusinessId"
              class="qv-dropdown">
    <mat-option *ngFor="let cb of contractedBusinesses$ | async" [value]="cb.id" class="qv-dropdown__option">
      {{cb.name}}
    </mat-option>
  </mat-select>
</section>

<section *ngIf="selectedContractedBusinessId" class="qv-sidebar-section">
  <qv-payment
      (sectionChanged)="onContractChange()"
      [contractedBusinessId]="selectedContractedBusinessId"
      [isBidInternal]="isBidInternal"
      [isBidInvalid]="isBidInvalid"
      [isEditMode]="isEditMode"
  ></qv-payment>
</section>

<section *ngIf="selectedContractedBusinessId" class="qv-sidebar-section">
  <qv-pharma-audit
      (sectionChanged)="onContractChange()"
      [contractedBusinessId]="selectedContractedBusinessId"
      [isBidInternal]="isBidInternal"
      [isBidInvalid]="isBidInvalid"
      [isEditMode]="isEditMode"
  ></qv-pharma-audit>
</section>

<section *ngIf="selectedContractedBusinessId" class="qv-sidebar-section">
  <qv-penalty
      (sectionChanged)="onContractChange()"
      [contractedBusinessId]="selectedContractedBusinessId"
      [isBidInternal]="isBidInternal"
      [isBidInvalid]="isBidInvalid"
      [isEditMode]="isEditMode"
  ></qv-penalty>
</section>

<section *ngIf="selectedContractedBusinessId" class="qv-sidebar-section">
  <qv-termination-clause
      (sectionChanged)="onContractChange()"
      [contractedBusinessId]="selectedContractedBusinessId"
      [isBidInternal]="isBidInternal"
      [isBidInvalid]="isBidInvalid"
      [isEditMode]="isEditMode"
  ></qv-termination-clause>
</section>

<section *ngIf="selectedContractedBusinessId" class="qv-sidebar-section">
  <qv-administration-fee
      (sectionChanged)="onContractChange()"
      [contractedBusinessId]="selectedContractedBusinessId"
      [isBidInternal]="isBidInternal"
      [isBidInvalid]="isBidInvalid"
      [isEditMode]="isEditMode"
  >
  </qv-administration-fee>
</section>
