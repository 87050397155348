import { SelectBoxTerm } from '@qv-term/models/types';
import { constants, resources } from '@qv-common/static';
import { PaymentFrequencyLag } from '@qv-term/enums/options';

export class PaymentFrequencyLagTerm extends SelectBoxTerm {
  constructor(name: string) {
    super(
      name,
      name,
      resources.TermsDescriptions.CONTRACT.PAYMENT_FREQUENCY,
      constants.CONTRACT_TERMS.PAYMENT_FREQUENCY.label,
      '',
      PaymentFrequencyLag.getValue(PaymentFrequencyLag.EMPTY),
      'term__payment-frequency-lag'
    );
    this.values = PaymentFrequencyLag.getValues();
  }
}
