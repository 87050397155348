<div class="standard-scenario-section"
  [class.standard-scenario-section--edit-mode]="isEditMode">
  <qv-standard-scenario-section-title
    [title]="'Price Protection'"
    [tooltip]="tooltips.PRICE_PROTECTION"
    [state]="priceProtection.state.value"
    [isValid]="isPriceProtectionEmpty(priceProtection)"
    [isAtNdc]="priceProtection.state.isNdc"
    [isEditMode]="isEditMode"
    [validationMessage]="resources.BID_DETAILS.INVALID_PRICE_PROTECTION"
    [sectionName]="sectionName">
  </qv-standard-scenario-section-title>
  <div class="standard-scenario-section__terms">
    <qv-standard-cell class="standard-scenario-section__term standard-scenario-section__term--net-effective-price"
      data-test-id="test-id-net-effective-price"
      [name]="drugTermsConstants[termName.NET_EFFECTIVE_PRICE].displayNames.NET_EFFECTIVE_PRICE"
      [termName]="termName.NET_EFFECTIVE_PRICE"
      [class.term__validation--error]="checkValidationNetEffectivePriceForPharma(isTermsEffectivePriceValid(priceProtection), isPriceProtectionRequired(priceProtection.state), hasPriceProtectionTermEmptyValue(isUserPharma, priceProtection.netEffectivePrice.isNdc, priceProtection.netEffectivePrice.value))"
      [useSeparator]="true">
      <qv-term-input
        class="term__side term__side--left"
        [isPending]="isBidInvalid"
        [termName]="termName.NET_EFFECTIVE_PRICE"
        [termValue]="priceProtection.netEffectivePrice"
        [numberOptions]="netEffectivePriceNumberOptions"
        [control]="drugFormService.getTermControl(termName.NET_EFFECTIVE_PRICE, sectionName)"
        [isEditMode]="isEditMode">
      </qv-term-input>
    </qv-standard-cell>
    <qv-standard-cell class="standard-scenario-section__term standard-scenario-section__term--index"
      data-test-id="test-id-index"
      [name]="drugTermsConstants[termName.INDEX].displayNames.INDEX"
      [class.term__validation--error]="checkValidationTermForPharma(isNetEffectivePriceValid(priceProtection.netEffectivePrice.isNdc, priceProtection.netEffectivePrice.value), isPriceProtectionRequired(priceProtection.state), hasPriceProtectionTermEmptyValue(isUserPharma, priceProtection.index.isNdc, priceProtection.index.value?.name))"
      [termName]="termName.INDEX">
      <qv-term-select
        class="term__side term__side--left"
        [isPending]="isBidInvalid"
        [appendTo]="dropDownAppendTo"
        [termName]="termName.INDEX"
        [termValue]="priceProtection.index"
        [isTooltipEnabled]="true"
        [control]="drugFormService.getTermControl(termName.INDEX, sectionName)"
        [isEditMode]="isEditMode">
      </qv-term-select>
    </qv-standard-cell>
    <qv-standard-cell class="standard-scenario-section__term standard-scenario-section__term--threshold"
      data-test-id="test-id-threshold"
      [name]="drugTermsConstants[termName.THRESHOLD].displayNames.THRESHOLD"
      [class.term__validation--error]="checkValidationTermForPharma(isNetEffectivePriceValid(priceProtection.netEffectivePrice.isNdc, priceProtection.netEffectivePrice.value), isPriceProtectionRequired(priceProtection.state), hasPriceProtectionTermEmptyValue(isUserPharma, priceProtection.threshold.isNdc, priceProtection.threshold.value))"
      [termName]="termName.THRESHOLD">
      <qv-term-input
        class="term__side term__side--left"
        [isPending]="isBidInvalid"
        [termName]="termName.THRESHOLD"
        [termValue]="priceProtection.threshold"
        [control]="drugFormService.getTermControl(termName.THRESHOLD, sectionName)"
        [isEditMode]="isEditMode">
      </qv-term-input>
    </qv-standard-cell>
    <qv-standard-cell class="standard-scenario-section__term standard-scenario-section__term--reset"
      data-test-id="test-id-reset"
      [name]="drugTermsConstants[termName.RESET].displayNames.RESET"
      [class.term__validation--error]="checkValidationTermForPharma(isNetEffectivePriceValid(priceProtection.netEffectivePrice.isNdc, priceProtection.netEffectivePrice.value), isPriceProtectionRequired(priceProtection.state), hasPriceProtectionTermEmptyValue(isUserPharma, priceProtection.reset.isNdc, priceProtection.reset.value?.name))"
      [termName]="termName.RESET">
      <qv-term-select
        class="term__side term__side--left"
        [isPending]="isBidInvalid"
        [appendTo]="dropDownAppendTo"
        [termName]="termName.RESET"
        [termValue]="priceProtection.reset"
        [isTooltipEnabled]="true"
        [control]="drugFormService.getTermControl(termName.RESET, sectionName)"
        [isEditMode]="isEditMode">
      </qv-term-select>
    </qv-standard-cell>
    <qv-standard-cell class="standard-scenario-section__term"
      data-test-id="test-id-baseline-start-date"
      [name]="drugTermsConstants[termName.BASELINE_START_DATE].displayNames.BASELINE_START_DATE"
      [class.term__validation--error]="checkValidationTermForPharma(isNetEffectivePriceValid(priceProtection.netEffectivePrice.isNdc, priceProtection.netEffectivePrice.value), isPriceProtectionRequired(priceProtection.state), hasPriceProtectionTermEmptyValue(isUserPharma, priceProtection.baselineStartDate.isNdc, priceProtection.baselineStartDate.value))"
      [termName]="termName.BASELINE_START_DATE">
      <qv-term-date
        class="term__side term__side--left"
        [isPending]="isBidInvalid"
        [termName]="termName.BASELINE_START_DATE"
        [termValue]="priceProtection.baselineStartDate"
        [isEditMode]="isEditMode"
        [control]="drugFormService.getTermControl(termName.BASELINE_START_DATE, sectionName)">
      </qv-term-date>
    </qv-standard-cell>
    <qv-standard-cell class="standard-scenario-section__term"
      data-test-id="test-id-baseline-wac"
      [name]="drugTermsConstants[termName.BASELINE_WAC].displayNames.BASELINE_WAC"
      [termName]="termName.BASELINE_WAC">
      <qv-term-currency
        class="term__side term__side--left"
        [isEditMode]="isEditMode"
        [termName]="termName.BASELINE_WAC"
        [termValue]="createTermCurrencyValueFromBaselineWac(priceProtection.baselineWac.value?.baselineWac, priceProtection.baselineWac.isNdc)"
        [isUnavailable]="isBaselineWacUnavailable(priceProtection.baselineWac)">
      </qv-term-currency>
    </qv-standard-cell>
    <qv-standard-cell class="standard-scenario-section__term standard-scenario-section__term--new-price-effective-date"
      data-test-id="test-id-new-price-effective-date"
      [name]="drugTermsConstants[termName.NEW_PRICE_EFFECTIVE_DATE].displayNames.NEW_PRICE_EFFECTIVE_DATE"
      [class.term__validation--error]="checkValidationTermForPharma(isNetEffectivePriceValid(priceProtection.netEffectivePrice.isNdc, priceProtection.netEffectivePrice.value), isPriceProtectionRequired(priceProtection.state), hasPriceProtectionTermEmptyValue(isUserPharma, priceProtection.newPriceEffectiveDate.isNdc, priceProtection.newPriceEffectiveDate.value?.name))"
      [termName]="termName.NEW_PRICE_EFFECTIVE_DATE">
      <qv-term-select
        class="term__side term_side--left"
        [isPending]="isBidInvalid"
        [appendTo]="dropDownAppendTo"
        [termName]="termName.NEW_PRICE_EFFECTIVE_DATE"
        [termValue]="priceProtection.newPriceEffectiveDate"
        [control]="drugFormService.getTermControl(termName.NEW_PRICE_EFFECTIVE_DATE, sectionName)"
        [isEditMode]="isEditMode">
      </qv-term-select>
    </qv-standard-cell>
  </div>
</div>

