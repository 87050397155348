<mat-expansion-panel *ngIf="data" [expanded]="true" [formGroup]="group" class="expansion-form-panel">
  <mat-expansion-panel-header class="expansion-form-panel__header">
    <mat-panel-title class="expansion-form-panel__title">
      {{ title }}
    </mat-panel-title>
  </mat-expansion-panel-header>

  <div class="expansion-form-panel__fields">
    <ng-container *ngFor="let item of $any(data) | keyvalue:ascOrder">
      <qv-date-picker *ngIf="isDatePickerField($any(item.key))"
                      [controlName]="$any(item.key)"
                      [group]="group"
                      [placeholder]="$any(item.value)"
                      [useFormField]="true"
                      class="expansion-form-panel__form-field">
      </qv-date-picker>

      <mat-form-field *ngIf="!isDatePickerField($any(item.key))" class="expansion-form-panel__form-field">
        <input [formControlName]="item.key"
               [matAutocompleteDisabled]="shouldDisableAutocomplete($any(item.key))"
               [matAutocomplete]="auto"
               [placeholder]="$any(item).value"
               [qvFormControlFocus]="$any(group.get($any(item.key)))"
               [required]="group.controls[item.key].errors?.required"
               class="expansion-form-panel__input"
               matInput>

        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
          <mat-option *ngFor="let option of filteredOptions[item.key] | async" [value]="option">
            {{ $any(option).name || option }}
          </mat-option>
        </mat-autocomplete>

        <acc-form-field-errors
            *ngIf="group.get($any(item.key)).touched && group.get($any(item.key)).invalid"
            [control]="group.get($any(item.key))"
            [validationMessages]="validationMessages"
            class="expansion-form-panel__error"
        ></acc-form-field-errors>
      </mat-form-field>
    </ng-container>
  </div>
</mat-expansion-panel>
