import { DateTerm } from '@qv-term/models/types';
import { Comparator, Reload, Reset, Validate } from '@qv-common/interfaces';
import { CoreUtils } from '@qv-common/utils';
import { constants, resources } from '@qv-common/static';
import { drugTermsConstants } from '@qv-term/constants';
import { TermName } from '@qv-term/enums';

export class ContractEndDateTerm extends DateTerm implements Reset, Validate, Comparator, Reload {
  public copyFromBidPath: string;
  public keepValueOnCopy: boolean; // When scenario is copied, ignore the "getValueToSave" modifier
  public reloadNeeded: boolean;

  constructor(name: string) {
    super(
      name,
      drugTermsConstants[TermName.SCENARIO_END_DATE].title,
      resources.TermsDescriptions.SUMMARY.CONTRACT_END_DATE,
      constants.DRUG_TERM_PATHS.ALLOW_CONTRACT_END_DATE[1],
      null,
      null,
      'term__contract-end-date',
      true
    );
    this.copyFromBidPath = `${constants.BID_SECTIONS.SUMMARY_TERMS}.
                                ${constants.SUMMARY_TERMS.CONTRACT_END_DATE.label}`;
    this.keepValueOnCopy = true;
    this.reloadNeeded = true;
  }

  public validate(): boolean {
    return CoreUtils.isDefined(this.value);
  }

  public resetValue(): void {
    this.value = this.defaultValue;
  }

  public getTermValueForComparison(): string {
    return this.value.getTime().toString();
  }
}
