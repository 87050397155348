import { ApiService } from '@qv-common/services/api';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { ApiUrlPrefix } from '@qv-common/enums';

@Injectable({
  providedIn: 'root'
})
export class PermissionDaoService {
  constructor(private apiService: ApiService) {}

  public getPermissionsForCurrentUser(): Observable<HttpResponse<any>> {
    return this.apiService.get(`${ApiUrlPrefix.OLD}/permissions/forCurrentUser`);
  }
}
