import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { Bid, Summary } from '@qv-bid/entities';
import { BidInfoService } from '@qv-bid/services/summary/bid-info.service';
import { BidUtils } from '@qv-bid/utils';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ViewPerspectiveService } from '@qv-common/services/auth';
import { CompanyUtils } from '@qv-company/utils';
import { CompanyType } from 'quantuvis-core-entities';

@UntilDestroy()
@Component({
  selector: 'qv-base-bid-info',
  templateUrl: './base-bid-info.component.html',
  styleUrls: ['./base-bid-info.component.scss']
})
export class BaseBidInfoComponent implements OnInit, OnChanges {

  @Input()
  public bid: Bid;

  @Input()
  public summary: Summary;

  public completeBidStatus: string;
  public assignedCompanyName: string;
  public oppositeCompanyName: string;
  public oppositeCompanyType: string;

  constructor(protected bidInfoService: BidInfoService, protected router: Router,
              protected viewPerspectiveService: ViewPerspectiveService) { }

  public ngOnInit(): void {
    this.initSubscriptions();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.bid && changes.bid.currentValue) {
      this.prepareBidDataWithStatus();
    }
  }

  protected prepareBidData(): void {
    const oppCompany = this.bidInfoService.getCompanyForNewAssignee(this.bid);
    const manufacturerCompanyDisplayName = BidUtils.getManufacturerCompanyDisplayName(this.bid);
    this.oppositeCompanyName =
      CompanyUtils.getCompanyFullName(oppCompany.name, oppCompany.isPharma(), this.bid.manufacturerCompanyOldName);
    this.oppositeCompanyType = CompanyType.displayValue(oppCompany.type);
    this.assignedCompanyName =
      BidUtils.getAssignedCompanyName(this.bid.assignee, manufacturerCompanyDisplayName, this.bid.payer.name);
  }

  private initSubscriptions(): void {
    this.viewPerspectiveService.switchPerspectiveEvent.pipe(untilDestroyed(this)).subscribe(() => {
      this.prepareBidDataWithStatus();
    });
  }

  private prepareBidDataWithStatus(): void {
    this.prepareBidData();
    this.completeBidStatus = this.bidInfoService.getCompleteStatus(this.bid);
  }
}
