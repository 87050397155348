import { createFeatureSelector, createSelector } from '@ngrx/store';
import { FieldsDropdownOptionsKey } from './fields-dropdown-options.key';
import { FieldsDropdownOptionsState } from './fields-dropdown-options.state';
import { fieldsDropdownOptionsAdapter } from './fields-dropdown-options.adapter';

const { selectEntities: selectEntitiesFields } = fieldsDropdownOptionsAdapter.getSelectors();

export namespace FieldsDropdownOptionsSelector {
  const selectState = createFeatureSelector<FieldsDropdownOptionsState>(FieldsDropdownOptionsKey);

  export const selectStatus = createSelector(
    selectState,
    state => state.status
  );

  export const selectEntities = createSelector(
    selectState,
    selectEntitiesFields
  );
}
