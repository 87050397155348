<div class="term-field-base">
  <div class="term-field-base__header">
    <span class="term-field-base__title" [class.term-field-base__title--required]="termConfig.required">
      {{ termConfig.label }}
    </span>
    <mat-icon
      class="term-field-base__info qv-icon qv-icon--16"
      [matTooltip]="termConfig.description"
      [svgIcon]="svgIconName.INFO_OUTLINE"
    ></mat-icon>
    <div class="term-field-base__top-block">
      <ng-content select="[top-block]"></ng-content>
    </div>
    <div class="term-field-base__top-right-block">
      <ng-content select="[top-right-block]"></ng-content>
    </div>
  </div>
  <div class="term-field-base__content">
    <ng-content></ng-content>
  </div>
</div>
