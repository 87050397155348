import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('DrugCb')
export class DrugCb {
  @JsonProperty('cbId', Number)
  public cbId: number = null;

  @JsonProperty('cbName', String)
  public cbName: string = null;

  @JsonProperty('drugName', String)
  public drugName: string = null;
}
