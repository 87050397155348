export enum BlockingMessage {
  LOADING = 'Loading...',
  DOWNLOADING = 'Downloading...',
  IMPORTING = 'Importing...',
  DELETING = 'Deleting...',
  LOADING_BID_DETAILS = 'Loading RFP/BID Details',
  LOADING_BIDS = 'Loading Bids...',
  LOCKING_BID = 'Locking RFP/Bid',
  UNLOCKING_BID = 'Unlocking RFP/Bid',
  COMPANY_SAVING = 'Saving Company...',
  SAVING = 'Saving ...',
  SAVED = 'Saved',
  NOT_SAVED = 'Not Saved',
  LOADING_LA = 'Loading Legal Attestation',
  UNDO_IN_PROGRESS = 'Undo is in progress...',
  REDO_IN_PROGRESS = 'Redo is in progress...',
  USER_AUTHORIZATION = 'User authorization...',
  RESETTING = 'Resetting...',
  RESENDING = 'Resending...',
  DISMISSING = 'Dismissing...',
  RECALLING = 'Recalling...'
}
