import { DateConverter } from '@qv-bid/serializers';
import { JsonObject, JsonProperty } from 'json2typescript';
import { Moment } from 'moment';
import { Company } from 'quantuvis-core-entities';

@JsonObject('BidAttachment')
export class BidAttachment {
  @JsonProperty('id', Number)
  public id: number = null;

  @JsonProperty('summaryId', Number)
  public summaryId: number = null;

  @JsonProperty('name', String)
  public name: string = null;

  @JsonProperty('isLegalAttestation', Boolean)
  public isLegalAttestation: boolean = null;

  @JsonProperty('baseCompany', Company)
  public baseCompany?: Company = null;

  @JsonProperty('createdAt', DateConverter)
  public createdAt?: Moment = null;
}
