/* tslint:disable:no-invalid-this triple-equals */
import sortBy from 'lodash.sortby';
import { constants } from '@qv-common/static';
import { CoreUtils } from '@qv-common/utils';
import { ApiUrlPrefix } from '@qv-common/enums';
import { catchError, tap } from 'rxjs/operators';
import { HttpErrorResponse, HttpParams, HttpResponse } from '@angular/common/http';
import { of } from 'rxjs';
import { UserService } from '@qv-common/services/auth/user.service';

export const PickerController = [
  '$scope', 'translations', 'standards', 'apiService', 'userService', '$timeout', 'util',
  ($scope, translations, standards, apiService, userService: UserService, $timeout, util) => {
  'ngInject';
  translations.buildTranslations($scope);

  // Timeout variable to be used in cancelling requests that are often than 400ms
  let timeout;

  if (!$scope.DRUGS_EVALUATED_ENUM) {
    $scope.DRUGS_EVALUATED_ENUM = {
      ALL_MANUFACTURERS_SOME_DRUGS: constants.DRUGS_EVALUATED_ENUM.ALL_MANUFACTURERS_SOME_DRUGS,
      SOME_MANUFACTURERS_ALL_DRUGS: constants.DRUGS_EVALUATED_ENUM.SOME_MANUFACTURERS_ALL_DRUGS,
      SOME_INTERNAL_PAYERS: constants.DRUGS_EVALUATED_ENUM.SOME_INTERNAL_PAYERS,
      MANUFACTURER_DRUGS: constants.DRUGS_EVALUATED_ENUM.MANUFACTURER_DRUGS,

      setSelectedValue(value): any {
        this.selectedValue = value;
      },
      getSelectedValue(): any {
        return this.selectedValue;
      }
    };
  }

  $scope.DRUGS_EVALUATED_ENUM.getStaticDataForSelection = function(): any {
    if (this.selectedValue === this.SOME_MANUFACTURERS_ALL_DRUGS) {
      return {
        searchValue: $scope.searchedCompany.value,
        allItemsUrl: '/manufacturers/names',
        allItems: $scope.allManufacturers,
        allItemsKey: 'allManufacturers',
        displayBy: 'name',
        localFilter: true,
        prepareHandler: prepareManufacturersHandler,
        prepareAllHandler: prepareAllCompaniesHandler,
        searchHandler: companySearchHandler,
        listFromLeft: $scope.companies,
        listFromLeftName: 'companies',
        noResults: 'noCompanyResult',
        tooManyResults: 'tooManyResults',
        listFromRight: $scope.selectedCompanies,
        listFromRightName: 'selectedCompanies',
        persistedListName: 'manufacturerList',
        isSelectedHandler: 'isCompanySelected',
        selectHandler: 'selectCompany',
        errorNotSelected: translations.i18n.NewBid.SelectManufacturerError,
        errorFetching: translations.i18n.NewBid.ErrorWhileFetchingManufacturers,
        searchPlaceholder: translations.i18n.NewBid.InfoManufacturerSearchPlaceholder,
        selectedCompaniesText: translations.i18n.NewBid.InfoManufacturerToBeEvaluated,
        searchText: translations.i18n.NewBid.SearchForManufacturer
      };
    } else if (this.selectedValue === this.ALL_MANUFACTURERS_SOME_DRUGS) {
      return {
        url: '/drugs/search/classandname',
        prepareHandler: prepareDrugsHandler,
        listFromLeft: $scope.drugs,
        listFromLeftName: 'drugs',
        noResults: 'noDrugResult',
        tooManyResults: 'tooManyResults',
        listFromRight: $scope.selectedDrugs,
        listFromRightName: 'selectedDrugs',
        persistedListName: 'drugList',
        isSelectedHandler: 'isDrugSelected',
        isDisabledHandler: 'isItemDisabled',
        selectHandler: 'selectDrug',
        errorNotSelected: translations.i18n.NewBid.SelectDrugError,
        errorFetching: translations.i18n.NewBid.ErrorWhileFetchingDrugs
      };
    } else if (this.selectedValue === this.SOME_INTERNAL_PAYERS) {
      return {
        allItemsUrl: `/companies/get/internal/${userService.user.getValue().id}`,
        allItems: $scope.allPayers,
        allItemsKey: 'allPayers',
        displayBy: 'name',
        localFilter: true,
        prepareHandler: preparePayerHandler,
        prepareAllHandler: prepareAllCompaniesHandler,
        searchHandler: companySearchHandler,
        listFromLeft: $scope.companies,
        listFromLeftName: 'companies',
        noResults: 'noCompanyResult',
        tooManyResults: 'tooManyResults',
        listFromRight: $scope.selectedCompanies,
        listFromRightName: 'selectedCompanies',
        persistedListName: 'payerList',
        isSelectedHandler: 'isCompanySelected',
        isDisabledHandler: 'isItemDisabled',
        selectHandler: 'selectCompany',
        errorNotSelected: translations.i18n.NewBid.SelectPayerError,
        errorFetching: translations.i18n.NewBid.ErrorWhileFetchingPayers,
        searchPlaceholder: translations.i18n.NewBid.InfoPayerSearchPlaceholder,
        selectedCompaniesText: translations.i18n.NewBid.INFO_SELECTED_PAYER,
        searchText: translations.i18n.NewBid.SearchForPayers
      };
    } else if (this.selectedValue === this.MANUFACTURER_DRUGS) {
      return {
        allItemsUrl: `/drugs/search/rebate/${userService.user.getValue().company.id}`,
        allItems: $scope.allCompanyRebateDrugs,
        allItemsKey: 'allCompanyRebateDrugs',
        localFilter: true,
        prepareHandler: prepareDrugsHandler,
        prepareAllHandler: prepareAllDrugsHandler,
        searchHandler: drugSearchHandler,
        listFromLeft: $scope.drugs,
        listFromLeftName: 'drugs',
        noResults: 'noDrugResult',
        tooManyResults: 'tooManyResults',
        listFromRight: $scope.selectedDrugs,
        listFromRightName: 'selectedDrugs',
        persistedListName: 'drugList',
        isSelectedHandler: 'isDrugSelected',
        isDisabledHandler: 'isItemDisabled',
        selectHandler: 'selectDrug',
        errorNotSelected: translations.i18n.NewBid.SelectDrugError,
        errorFetching: translations.i18n.NewBid.ErrorWhileFetchingDrugs
      };
    } else {
      return {};
    }
  };

  standards.apply($scope, {
    constructor: () => {
      const staticData = $scope.DRUGS_EVALUATED_ENUM.getStaticDataForSelection();

      if (staticData.localFilter && !staticData.allItems) {
        getAllItems();
      }
    },
    variables: {
      createBidError: {},
      cancelled: false
    },
    public: () => {

      /**
       * Selects / Deselects all items from the left and move / remove it to / from the right list.
       */
      function selectAll(): void {
        const staticData = $scope.DRUGS_EVALUATED_ENUM.getStaticDataForSelection();

        let unselectedList = staticData.listFromLeft.filter(item =>
          !item.isGroup && !$scope[staticData.isSelectedHandler](item));

        if (unselectedList.length === 0) {
          // reaching here means we do a deselect all
          // except drugs which are part of this market basket and have this market basket assigned.
          if (staticData.isDisabledHandler) {
            unselectedList = staticData.listFromLeft.filter(item =>
              !item.isGroup && !$scope[staticData.isDisabledHandler](item)
            );
          } else {
            unselectedList = staticData.listFromLeft.filter(item => !item.isGroup);
          }
        }

        unselectedList.forEach(item => $scope[staticData.selectHandler](item));
      }

      /**
       * Expands / collapses all drug classes
       */
      function expandAll(): void {
        const staticData = $scope.DRUGS_EVALUATED_ENUM.getStaticDataForSelection();
        const drugsList = staticData.listFromLeft;

        let groupsList = drugsList.filter(item => item.isGroup && !item.isExpanded);
        if (groupsList.length === 0) {
          // reaching here means we do an unexpand all
          groupsList = drugsList.filter(item => item.isGroup);
        }

        groupsList.forEach(item => $scope.toggle(item));
      }

      function removeIconHandler(): void {
        $scope.searchItem('');
        $scope.cancelled = true;
      }

      function isDrugNameClickable(): boolean {
        return !$scope.currentMarketBasket
          || !($scope.currentMarketBasket && $scope.marketBasket
            && ($scope.marketBasket.action == 'view' || $scope.marketBasket.action == 'attach'));
      }

      function isMarketBasketViewMode(): boolean {
        return $scope.marketBasket &&
          ($scope.marketBasket.action === 'view' || $scope.marketBasket.action === 'attach');
      }

      function clickDrugNameHandler(drug, $event): void {
        if ($event && drug.isGroup) {
          $event.stopPropagation();
        }
        $scope.searchedDrugs.value = drug.drugClass;
        $scope.searchInputChangeHandler($scope.searchedDrugs.value, expandAll);
      }

      function searchInputChangeHandler(search, callback): void {
        let drugSearchContext = '';
        if ($scope.currentMarketBasket) {
          drugSearchContext = translations.i18n.DRUG_SEARCH_CONTEXT.MARKET_BASKET;
        } else {
          drugSearchContext = translations.i18n.DRUG_SEARCH_CONTEXT.CREATE_RFP;
        }

        $scope.searchItem(search, drugSearchContext, callback);
        $scope.cancelled = !(search && search.length >= constants.PICKER_MIN_CHARACTERS_FOR_SEARCH);
        $scope.searching = false;
      }

      /**
       * Perform a search for the manufacturer/drug list.
       * search - search pattern
       */
      function searchItem(search, drugSearchContext, callback): void {
        const staticData = $scope.DRUGS_EVALUATED_ENUM.getStaticDataForSelection();

        if (staticData.localFilter) {
          staticData.searchHandler(search);
          return;
        }

        if (search && search.length >= constants.PICKER_MIN_CHARACTERS_FOR_SEARCH) {
          $scope.createBidError.visible = false;
          $timeout.cancel(timeout);

          $scope.lastSearch = search;

          timeout = $timeout(() => {
            if (!$scope.cancelled) {
              $scope.searching = true;
              $scope[staticData.noResults] = false;
              $scope[staticData.tooManyResults] = false;
              $scope[staticData.listFromLeftName].splice(0);
              const options = {
                params: new HttpParams().set('pattern', search).set('drugSearchContext', drugSearchContext)
              };

              apiService.get(ApiUrlPrefix.OLD + staticData.url, options).pipe(
                tap((response: HttpResponse<any>) => {
                  const data = response.body;
                  $scope.searching = false;
                  if ($scope.lastSearch === search && !$scope.cancelled) {

                    const searchErrors = constants.ERROR_CODES.SEARCH_ERRORS.TOO_MANY_RESULTS;
                    if (data.errorCode && data.errorCode === searchErrors) {
                      $scope[staticData.tooManyResults] = true;
                      $scope[staticData.noResults] = false;
                    } else {
                      $scope[staticData.listFromLeftName] = staticData.prepareHandler($scope, data.responseObject);

                      if ($scope[staticData.listFromLeftName].length === 0) {
                        $scope[staticData.noResults] = true;
                        $scope[staticData.tooManyResults] = false;
                      }
                    }
                    if (typeof callback === 'function') {
                      callback();
                    }
                  }
                }),
                catchError(() => {
                  $scope.searching = false;
                  if (!$scope.cancelled) {
                    $scope.createBidError.visible = true;
                    $scope.createBidError.message = staticData.errorFetching;
                  }

                  return of(null);
                })
              ).subscribe();
            } else {
              $scope.searching = false;
              $scope[staticData.noResults] = false;
              $scope[staticData.tooManyResults] = false;
            }
          }, 400);

        } else {
          $scope.cancelled = true;
          $scope[staticData.tooManyResults] = false;
          $scope[staticData.noResults] = false;
          $timeout.cancel(timeout);
          $scope.searching = false;

          $scope[staticData.noResults] = false;
          $scope[staticData.tooManyResults] = false;
          $scope[staticData.listFromLeftName] = [];

          staticData.prepareHandler();
        }
      }

      /**
       * Toggles the select of one drug / drug class from the left list
       * drug - drug that was selected
       */
      function selectDrug(drug, groupChange): void {
        if (!drug.isIncomplete || groupChange) {

          const staticData = $scope.DRUGS_EVALUATED_ENUM.getStaticDataForSelection();

          if (groupChange) {
            let foundIndex = -1;
            const groupFromTheRight = $scope[staticData.listFromRightName].find((collectionItem, index) => {
              foundIndex = index;
              return collectionItem.id == drug.id;
            });

            groupFromTheRight.selectedDrugs = [];
            drug.drugRemoveStyle = false;
            drug.removeIconGroupDisplay = false;
            $scope[staticData.listFromRightName].splice(foundIndex, 1);
          } else {
            if (drug.isGroup) {
              selectDrugGroupCase(drug, staticData.listFromRightName, staticData.listFromLeft);
            } else {
              selectDrugNotGroupCase(staticData.listFromRightName, drug);
            }
          }
          // sortSelectedDrugs();
        }
      }

      /**
       * Selects one manufacturer from the left list
       * item - item that was selected
       */
      function selectCompany(item): void {

        let index = -1;
        const searchItem = $scope.selectedCompanies.find((collectionItem, idx) => {
          index = idx;
          return collectionItem.id === item.id;
        });

        if (!searchItem) {
          item.canRemove = true;
          $scope.selectedCompanies.push(item);
        } else {
          $scope.selectedCompanies.splice(index, 1);
        }
      }

      /**
       * Expands / Collapses a drug class
       * @param item - item that was selected
       */
      function toggle(item): void {
        const staticData = $scope.DRUGS_EVALUATED_ENUM.getStaticDataForSelection();
        item.isExpanded = !item.isExpanded;

        getChildrenOfGroup(staticData.listFromLeft, item).forEach(current => current.isVisible = !current.isVisible);
      }

      /**
       * Checks if one item is selected
       * item - item to check
       */
      function isDrugSelected(item): boolean {
        const staticData = $scope.DRUGS_EVALUATED_ENUM.getStaticDataForSelection();

        const groupId = item.isGroup ? item.id : item.group.id;

        const groupAlreadySelected = $scope[staticData.listFromRightName]
          .find(collectionItem => collectionItem.id == groupId);
        const groupAlreadySelectedDrugs = groupAlreadySelected ? groupAlreadySelected.selectedDrugs : [];

        if (item.isGroup) {
          const childrenOfGroup = getChildrenOfGroup($scope[staticData.listFromLeftName], item);
          const isGroupSelected = !CoreUtils.isEmpty(childrenOfGroup)
            && childrenOfGroup.every(current =>
              groupAlreadySelectedDrugs.some(collectionItem => collectionItem.id == current.id));
          item.isGroupSelected = isGroupSelected;
          return isGroupSelected;
        }

        return CoreUtils.isDefined(groupAlreadySelectedDrugs.find(collectionItem => collectionItem.id == item.id))
          || (CoreUtils.isDefined(item.isGroupSelected) && item.isGroupSelected);
      }

      /**
       * Checks if item should be disabled
       * drug - drug or drugGroup
       */
      function isItemDisabled(drug): boolean {
        const staticData = $scope.DRUGS_EVALUATED_ENUM.getStaticDataForSelection();

        if (drug.isGroup) {
          return false;
        } else {
          return isDrugDisabled(staticData, drug);
        }
      }

      /**
       * Checks if drug can be disabled
       * drug - drug to check
       */
      function isDrugDisabled(staticData, drug): boolean {
        const drugClass = $scope[staticData.listFromRightName].find(collectionItem => {
          if (collectionItem && drug && drug.group) {
            return collectionItem.id === drug.group.id;
          }

          return false;
        });

        let isDisabled = false;
        if (drugClass && drugClass.selectedDrugs.length !== 0) {
          isDisabled = drugClass.selectedDrugs.find(currentDrug =>
            drug.name === currentDrug.name && !currentDrug.canRemove
          );
        }
        return isDisabled;
      }

      /**
       * Returns the add on class
       */
      function addOnClass(searchedCompany): 'input-group-addon' | 'input-group-addon active' {
        return $scope.lastSearch === searchedCompany
        || util.isEmpty(searchedCompany) ? 'input-group-addon' : 'input-group-addon active';
      }

      /**
       * Checks if one item is selected
       * item - item to check
       */
      function isCompanySelected(item): any {
        return $scope.selectedCompanies.find(collectionItem => {
          return collectionItem.id === item.id;
        });
      }

      function isDrugRemoveStyle(innerDrug, drug): any | true | boolean {
        return (innerDrug.innerDrugRemoveStyle
          || drug.drugRemoveStyle || $scope.clearAllHovered) && innerDrug.canRemove;
      }

      function isDrugGroupRemoveStyle(drug): any | true | boolean {
        return (drug.drugRemoveStyle || $scope.clearAllHovered) && drug.canRemove;
      }

      function isDrugSearchMatch(drug): true | false {
        return drug.searchMatch || drug.drugClassMatched ? true : false;
      }

      function clearAll(): void {
        const staticData = $scope.DRUGS_EVALUATED_ENUM.getStaticDataForSelection();
        const selectedCollection = staticData.listFromRightName;
        $scope.clearAllHovered = false;

        const drugClassesNotToBeRemoved = $scope[selectedCollection].filter(num => num.canRemove !== true);

        if (drugClassesNotToBeRemoved.length !== 0) {
          drugClassesNotToBeRemoved.forEach(drugClass => {
            drugClass.selectedDrugs =
              drugClass.selectedDrugs.filter(drug => drug.canRemove !== true);
          });

          // Show info message in case if there is at
          // least one drug which can not be removed from drug list (Right side)
          showInfoMessage();
        }

        $scope[selectedCollection].length = 0;
        drugClassesNotToBeRemoved.forEach(item => $scope[selectedCollection].push(item));
      }

      function showInfoMessage(): void {
        $scope.marketBasketNote = {
          error: -1,
          message: $scope.i18n.MarketBasketManagement
            .INFO_MESSAGES.CANNOT_REMOVE_DRUGS_WHICH_INCLUDED_IN_AN_ASSIGNED_MARKET_BASKET
        };
      }

      function set(data, value): void {
        $scope[data] = value;
      }

      return {
        selectAll,
        expandAll,
        searchItem,
        selectDrug,
        selectCompany,
        toggle,
        isDrugSelected,
        isItemDisabled,
        isCompanySelected,
        addOnClass,
        removeIconHandler,
        searchInputChangeHandler,
        isMarketBasketViewMode,
        isDrugNameClickable,
        clickDrugNameHandler,
        isDrugRemoveStyle,
        isDrugGroupRemoveStyle,
        isDrugSearchMatch,
        clearAll,
        set
      };
    }
  });

  // --------------------------------------------------------------------------------------------------------------
  // private functions
  // --------------------------------------------------------------------------------------------------------------
  function getAllItems(): void {
    const staticData = $scope.DRUGS_EVALUATED_ENUM.getStaticDataForSelection();

    apiService.get(ApiUrlPrefix.OLD + staticData.allItemsUrl).pipe(
      tap((response: HttpResponse<any>) => {
        staticData.prepareAllHandler($scope, response.body.responseObject, staticData);
        if (staticData.searchValue) {
          $scope.searchItem(staticData.searchValue);
        }
        util.refreshScope($scope);
      }),
      catchError((error: HttpErrorResponse) => {
        console.error(`Error fetching ${staticData.allItemsKey}`, error);

        return of(null);
      })
    ).subscribe();
  }

  function selectDrugNotGroupCase(listFromRightName, drug): void {
    let groupIndex = -1;
    const groupFromTheRight = $scope[listFromRightName].find((collectionItem, index) => {
      groupIndex = index;
      return collectionItem.id === drug.group.id;
    });

    if (groupFromTheRight) {
      // the group of this drug is already in the selected list on the right panel
      let foundIndex = -1;
      const drugInSelectedDrugs = groupFromTheRight.selectedDrugs.find((current, index) => {
        foundIndex = index;
        return current.id === drug.id;
      });
      if (!drugInSelectedDrugs) {
        // if drug is not already in the right panel then add it
        drug.innerDrugRemoveStyle = false;
        groupFromTheRight.selectedDrugs.push(drug);
      } else {
        // remove it from selectedDrugs array
        groupFromTheRight.selectedDrugs.splice(foundIndex, 1);
        if (groupFromTheRight.selectedDrugs.length === 0) {
          // if all drugs have been removed, then remove the whole group
          $scope[listFromRightName].splice(groupIndex, 1);
        }
      }
    } else {
      // group needs to pe pushed to the right panel
      $scope[listFromRightName].push(drug.group);
      drug.innerDrugRemoveStyle = false;
      // the drug needs to be pushed into the selectedDrugs array of that group
      drug.group.selectedDrugs = [
        drug
      ];
    }
  }

  function selectDrugGroupCase(group, listFromRightName, listFromLeft): void {
    group.isGroupSelected = !group.isGroupSelected;

    let groupIndex = -1;
    const childrenFromLeft = getChildrenOfGroup(listFromLeft, group);
    let groupFromTheRight = $scope[listFromRightName].find((collectionItem, index) => {
      groupIndex = index;
      return collectionItem.id == group.id;
    });

    if (group.isGroupSelected) {
      // group from left has it's checkbox checked
      if (!groupFromTheRight) {
        // if the group is not already in the right panel
        $scope[listFromRightName].push(group);
        groupFromTheRight = group;
      }

      groupFromTheRight.selectedDrugs = getMergedListForAGroup(listFromLeft, groupFromTheRight, group);
      groupFromTheRight.selectedDrugs.forEach(item => item.innerDrugRemoveStyle = false);
    } else {
      /**
       * If group is unchecked all drugs from this group (Right side) which have property "canRemove == true"
       * and that are also present in the current Left side list, will be removed
       */
      rejectRemovableDrugs($scope[listFromRightName][groupIndex], childrenFromLeft);

      if ($scope[listFromRightName][groupIndex].selectedDrugs.length == 0) {
        $scope[listFromRightName].splice(groupIndex, 1);
      }

    }
  }

  /**
   * Removes drugs which can be removed from drugClass based on the left side list
   * @param drugClass - class(group) of drugs from which appropriate drugs are removed
   * @param childrenFromLeft - array of drugs that match the current search criteria in the left column
   */
  function rejectRemovableDrugs(drugClass, childrenFromLeft): void {
    let presentInLeft = true;
    drugClass.selectedDrugs = drugClass.selectedDrugs.filter(drug => {
      presentInLeft = childrenFromLeft.some(inner => inner.id === drug.id);
      return !presentInLeft || !drug.canRemove;
    });
  }

  /**
   * Gets the list of all the drugs within the same group
   */
  function getChildrenOfGroup(list, group): any {
    return list.filter(current => current.drugClass == group.drugClass && !current.isGroup);
  }

  function getMergedListForAGroup(listFromLeft, listFromRight, group): any {
    const childrenOfGroup = getChildrenOfGroup(listFromLeft, group);
    childrenOfGroup.forEach(item => {
      !listFromRight.selectedDrugs.some(innerItem => innerItem.id === item.id)
        // tslint:disable-next-line:no-unused-expression
        ? listFromRight.selectedDrugs.push(item) : '';
    });
    return listFromRight.selectedDrugs;
  }

  /**
   * Prepare manufacturers for picker
   * @param $scope - work scope
   * @param list {Array} - list of items for picker
   * @returns {Array} - list of items for picker
   */
  function prepareManufacturersHandler($scope, list): any {
    return list;
  }

  /**
   * Search handler for manufacture
   * @param searchString {string} - search string
   */
  function companySearchHandler(searchString): void {
    const staticData = $scope.DRUGS_EVALUATED_ENUM.getStaticDataForSelection();

    $scope[staticData.listFromLeftName] = ($scope[staticData.allItemsKey] || [])
      .filter(item => item[staticData.displayBy].toLowerCase().includes(searchString.toLowerCase()));
  }

  /**
   * Search handler for drugs
   * @param searchString - search string
   */
  function drugSearchHandler(searchString): void {
    const staticData = $scope.DRUGS_EVALUATED_ENUM.getStaticDataForSelection();

    $scope[staticData.allItemsKey].forEach(item => {
      if (item.drugClass.toLowerCase().includes(searchString.toLowerCase())) {
        item.searchMatch = true;
      } else if (item.name.toLowerCase().includes(searchString.toLowerCase())) {
        item.searchMatch = true;
        item.isVisible = true;
        if (!item.isGroup && item.group) {
          item.group.searchMatch = true;
          item.group.isExpanded = true;
        }
      } else {
        item.searchMatch = false;
      }
    });

    $scope[staticData.listFromLeftName] = $scope[staticData.allItemsKey].filter(item => item.searchMatch);
  }

  /**
   * Prepare manufacturers data for picker
   * @param $scope - work scope
   * @param list - list of items
   * @param staticData - additional data
   */
  function prepareAllCompaniesHandler($scope, list, staticData): void {
    const sortedData = sortBy(list, staticData.displayBy);
    $scope[staticData.allItemsKey] = sortedData;
    $scope[staticData.listFromLeftName] = sortedData;
    $scope.searchPlaceholder = staticData.searchPlaceholder;
    $scope.selectedCompaniesText = staticData.selectedCompaniesText;
    $scope.searchText = staticData.searchText;
  }

  /**
   * Prepare initial drugs data for picker
   * @param $scope - work scope
   * @param list - list of items
   * @param staticData - additional data
   */
  function prepareAllDrugsHandler($scope, list, staticData): void {
    const drugs = prepareDrugsHandler($scope, list);
    $scope[staticData.allItemsKey] = drugs;
    drugs.forEach((drug) => {
      if (drug.isGroup) {
        drug.isExpanded = true;
      } else {
        drug.isVisible = true;
      }
    });
    $scope[staticData.listFromLeftName] = drugs;
  }

  /**
   * Prepare drugs for picker
   * @param $scope
   * @param list
   * @returns {Array}
   */
  function prepareDrugsHandler($scope, list): any[] {
    const finalList = [];

    for (const drugsClassName in list) {
      if (list.hasOwnProperty(drugsClassName)) {
        const drugClassMatched = !$scope.lastSearch ||
          drugsClassName.toLowerCase().indexOf($scope.lastSearch.toLowerCase()) >= 0;
        const group = {
          id: drugsClassName,
          name: drugsClassName,
          isGroup: true,
          isExpanded: !drugClassMatched, // we expand the drug class if there is no search match on it
          isIncomplete: false,
          canRemove: true,
          searchMatch: drugClassMatched,
          drugClass: drugsClassName,
          selectedDrugs: []
        };
        finalList.push(group);

        let receivedDrugsSize = 0;
        for (const drugName in list[drugsClassName].drugsInClass) {
          if (list[drugsClassName].drugsInClass.hasOwnProperty(drugName)) {
            receivedDrugsSize = receivedDrugsSize + list[drugsClassName].drugsInClass[drugName].length;

            let otherPharma = false;
            let drugIdsList = [];
            let pharmaNames = '';

            for (const pharmaName in list[drugsClassName].drugsInClass[drugName]) {
              const ndcForPharmaName = list[drugsClassName].drugsInClass[drugName][pharmaName];
              drugIdsList = drugIdsList.concat(ndcForPharmaName);
              if (pharmaName === translations.i18n.MARKET_BASKET.UNASSIGNED_LABEL) {
                otherPharma = true;
              } else {
                pharmaNames += `(${pharmaName}) `;
              }
            }

            if (pharmaNames && pharmaNames.length > 2) {
              // Remove last space from the pharma names
              pharmaNames = pharmaNames.substring(0, pharmaNames.length - 1);
            }

            let completePharmaNames;
            if (pharmaNames) {
              if (otherPharma) {
                completePharmaNames = `${pharmaNames} ${
                  translations.i18n.MARKET_BASKET.UNASSIGNED_LABEL_WITH_OPEN_ROUND_BRACKETS}`;
              } else {
                completePharmaNames = pharmaNames;
              }
            } else {
              completePharmaNames = translations.i18n.MARKET_BASKET.UNASSIGNED_LABEL_WITH_OPEN_ROUND_BRACKETS;
            }

            const searchMatch = !$scope.lastSearch ||
              drugName.toLowerCase().indexOf($scope.lastSearch.toLowerCase()) >= 0;
            finalList.push({
              id: drugName,
              name: drugName,
              isGroup: false,
              isVisibleAndSelected: true,
              canRemove: true,
              isVisible: group.isExpanded,
              group,
              searchMatch,
              drugClassMatched,
              drugClass: drugsClassName,
              pharmaNames: completePharmaNames,
              drugIds: drugIdsList
            });
          }
        }

        const drugClassSize = list[drugsClassName].drugClassSize;
        if (drugClassSize > receivedDrugsSize) {
          group.isIncomplete = true;
        } else {
          group.searchMatch = true;
        }
      }
    }

    return finalList;
  }

  function preparePayerHandler($scope, list): any {
    return list;
  }
// tslint:disable-next-line:max-file-line-count
}];
