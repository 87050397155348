import { HttpErrorResponse } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { BidAttachment } from '@qv-bid/entities';
import { BidDetailsNotificationService, BidStateService } from '@qv-bid/services';
import { ApiUrlPrefix, CommonMessage, SvgIconName } from '@qv-common/enums';
import { FileService } from '@qv-common/services/api';
import { resources } from '@qv-common/static/resources';
import { CompanyUtils } from '@qv-company/utils';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { SnackBarService } from 'quantuvis-angular-common/snack-bar';
import { catchError, finalize, tap } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { SummaryDaoService } from '@qv-bid/services/dao';
import { BidAttachmentType } from '@qv-bid/enums';
import { FileSystemFileEntry, NgxFileDropComponent, NgxFileDropEntry } from 'ngx-file-drop';
import { NumberUtils } from '@qv-common/utils';
import { SummaryService } from '@qv-bid/services/summary';
import { BaseSummarySection } from '@qv-bid/components/shared/summary-panel/base-summary-section/base-summary-section';
import { TermName } from '@qv-term/enums';
import { BidEventBusService } from '@qv-bid/services/bid-event-bus.service';
import { QvCache } from '@qv-common/decorators';
import { appConfig } from '@qv-common/configs';
import { UserService } from '@qv-common/services/auth/user.service';
import { BidErrorResponseStatusUtil } from '@qv-bid/utils/bid-error-response-status.util';

@Component({
  selector: 'qv-business-attachments',
  templateUrl: './business-attachments.component.html',
  styleUrls: ['./business-attachments.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BusinessAttachmentsComponent extends BaseSummarySection implements OnInit, OnChanges {
  @Input()
  public manufacturerCompanyOldName: string;

  @BlockUI()
  public blockUI: NgBlockUI;

  @ViewChild('fileDropZone')
  public fileDropZone: NgxFileDropComponent;

  public bidAttachments: BidAttachment[] = [];

  public readonly svgIconName = SvgIconName;
  public readonly commonMessage = CommonMessage;
  public readonly resources = resources;
  public readonly dateFormat = appConfig.dateFormat;

  constructor(
    private summaryDaoService: SummaryDaoService,
    private fileService: FileService,
    private bidDetailsNotificationService: BidDetailsNotificationService,
    private changeDetectorRef: ChangeDetectorRef,
    private bidStateService: BidStateService,
    snackBarService: SnackBarService,
    bidEventBusService: BidEventBusService,
    userService: UserService,
    summaryService: SummaryService,
  ) {
    super(summaryService, userService, snackBarService, bidEventBusService);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.subscribeOnLockChanges(TermName.BID_ATTACHMENTS_LOCK);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (this.isSummaryChanged(changes)) {
      this.loadBidAttachments();
      this.lockControl.setValue(this.summary.attachmentsLock, { emitEvent: false });
    }
  }

  public downloadAttachment(id: number): void {
    const url = `${ApiUrlPrefix.BIDS}/summaries/${this.summary.id}/bid-attachments/${id}/download`;

    this.blockUI.start(FileService.DOWNLOADING_MSG);
    this.fileService.downloadFile(url)
      .pipe(
        finalize(() => this.blockUI.stop()),
        catchError(
          (response: HttpErrorResponse) => {
            this.bidDetailsNotificationService.error(response.error.message);

            return throwError(response);
          }
        )
      )
      .subscribe();
  }

  public onFileDropHandler([fileDropEntry]: NgxFileDropEntry[]): void {
    if (!fileDropEntry) {
      return;
    }

    const fileEntry = fileDropEntry.fileEntry as FileSystemFileEntry;

    fileEntry.file((file: File) => {
      if (file.size > appConfig.maxFileSize) {
        this.bidDetailsNotificationService.error(
          `File(s) exceed ${NumberUtils.bytesToMegabytes(appConfig.maxFileSize)} MB limit`
        );
      } else {
        this.uploadFile(file);
      }
    });
  }

  public onDeleteBidAttachmentButtonHandler(deletedAttachment: BidAttachment): void {
    this.blockUI.start(`Deleting ${deletedAttachment.name}`);

    const bid = this.bidStateService.bid$.getValue();

    this.summaryDaoService.deleteBidAttachment(this.summary.id, deletedAttachment.id, bid.isInternal)
      .pipe(
        tap(() => {
          this.bidAttachments = this.bidAttachments.filter(
            (bidAttachment: BidAttachment) => bidAttachment.id !== deletedAttachment.id
          );
          this.bidEventBusService.undoRedoEvent.emit();
          this.changeDetectorRef.markForCheck();
          this.snackBarService.finish();
        }),
        catchError((error: HttpErrorResponse) => {
          this.snackBarService.error();
          return throwError(error);
        }),
        finalize(() => this.blockUI.stop()),
      ).subscribe();
  }

  @QvCache()
  public getCompanyFullName(companyName: string, isPharma: boolean, oldCompanyName?: string): string {
    return CompanyUtils.getCompanyFullName(companyName, isPharma, oldCompanyName);
  }

  private loadBidAttachments(): void {
    this.summaryDaoService.getBidAttachmentsBySummaryId(this.summary.id, BidAttachmentType.BUSINESS_ATTACHMENT)
      .subscribe((bidAttachments: BidAttachment[]) => {
        this.bidAttachments = bidAttachments;
        this.changeDetectorRef.markForCheck();
      });
  }

  private uploadFile(file: File): void {
    this.blockUI.start(`Uploading ${file.name}`);

    const formData = new FormData();
    formData.append('file', file, file.name);

    const bid = this.bidStateService.bid$.getValue();

    return this.summaryDaoService.uploadBidAttachment(this.summary.id, formData, bid.isInternal).pipe(
      tap((bidAttachment: BidAttachment) => {
        this.bidAttachments.push(bidAttachment);
        this.bidEventBusService.undoRedoEvent.emit();
        this.changeDetectorRef.markForCheck();
        this.snackBarService.finish();
      }),
      catchError((response: HttpErrorResponse) => {
        if (BidErrorResponseStatusUtil.isShowErrorInNotification(response)) {
          this.bidDetailsNotificationService.error(response.error.message);
        }
        this.snackBarService.error();

        return throwError(response);
      }),
      finalize(() => this.blockUI.stop()),
    ).subscribe();
  }
}
