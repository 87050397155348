<div class="standard-um-details">
    <qv-standard-cell
      class="standard-um-details__standard-cell"
      [name]="drugTermsConstants[termName.UM_DETAILS].displayNames.UM_DETAILS"
      [termName]="termName.UM_DETAILS">
      <qv-term-textarea class="term__side--left standard-um-details__value"
        data-test-id="test-id-um-details"
        [isEditMode]="isEditMode"
        [isPending]="isBidInvalid"
        [ngbPopover]="popoverContent"
        [popoverClass]="'popover--only-scaffold popover--width-600 popover--material'"
        [placement]="popoverPlacement"
        [disablePopover]="isEditMode || isAtNdc(umDetails) || !umDetails.value"
        [termName]="termName.UM_DETAILS"
        [termValue]="umDetails"
        [debounceTime]="umDetailsDebounceTime"
        [control]="control"
      ></qv-term-textarea>

      <ng-template #popoverContent>
        <mat-card>
          <mat-card-title>{{drugTermsConstants[termName.UM_DETAILS].displayNames.UM_DETAILS}}</mat-card-title>
          <mat-card-content>{{umDetails.value}}</mat-card-content>
        </mat-card>
      </ng-template>
    </qv-standard-cell>
</div>
