import { NgModule } from '@angular/core';
import { blobErrorInterceptorProvider, noConnectionInterceptorProvider } from '@qv-common/handlers';
import { PermissionService } from '@qv-common/services/auth/permission.service';
import { CoreDomUtilsServiceProvider, EnrollmentService, ScopeProvider, SpinnerService, } from '@qv-common/services';
import { NavigateAuthService } from '@qv-common/services/auth/navigate-auth.service';
import {
  preferencesProvider,
  restAPIProvider,
  standardsProvider,
  utilProvider
} from '@qv-common/services/ng1-upgraded-providers';
import { ViewPerspectiveService } from '@qv-common/services/auth';
import { ApiService, FileService } from '@qv-common/services/api';
import { UserService } from '@qv-common/services/auth/user.service';

@NgModule({
  providers: [
    noConnectionInterceptorProvider,
    preferencesProvider,
    restAPIProvider,
    standardsProvider,
    utilProvider,
    PermissionService,
    ApiService,
    FileService,
    SpinnerService,
    ViewPerspectiveService,
    EnrollmentService,
    blobErrorInterceptorProvider,
    CoreDomUtilsServiceProvider,
    ScopeProvider,
    NavigateAuthService,
    UserService
  ]
})
export class CommonModule {}
