import { Directive, ElementRef, Injector } from '@angular/core';
import { ConfirmNavigationUpgrade } from '@qv-ng1-wrapper/classes';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'qv-profile-wrapper'
})
export class ProfileWrapperDirective extends ConfirmNavigationUpgrade {
  constructor(elementRef: ElementRef, injector: Injector) {
    super('profileComponent', elementRef, injector);
  }
}
