import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('WebsiteLink')
export class WebsiteLink {
  @JsonProperty('id', Number)
  public id: number = null;

  @JsonProperty('companyId', Number)
  public companyId: number = null;

  @JsonProperty('summaryId', Number)
  public summaryId: number = null;

  @JsonProperty('link', String)
  public link: string = null;
}
