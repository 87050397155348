import { AbstractSummaryController } from '../../shared/wizard/abstract-summary.controller';

class SummaryController extends AbstractSummaryController {
  public static $inject = ['translations', 'util', 'CreateInternalBidService', 'BidDetailsService'];
  private translations;
  private util;
  private CreateInternalBidService;
  private BidDetailsService;
  private onValid: (arg) => {};

  constructor(translations, util, CreateInternalBidService, BidDetailsService) {
    'ngInject';
    super();
    this.translations = translations;
    this.util = util;
    this.CreateInternalBidService = CreateInternalBidService;
    this.BidDetailsService = BidDetailsService;
  }

  public $onInit(): void {
    this.bid = this.CreateInternalBidService.getBidConfig();
    this.translations.buildTranslations(this);
  }

  public $onChanges(changes): void {
    if (changes.runValidation && changes.runValidation.currentValue) {
      this.onValid({ value: this.isValid() });
    }
  }
}

export default SummaryController;
