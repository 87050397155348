import { JsonObject, JsonProperty } from 'json2typescript';
import { ValidatorFn, Validators } from '@angular/forms';
import { InternalFieldType } from '@qv-internal-fields-common/enums';
import { InternalFieldMeta } from '@qv-internal-fields-common/interfaces';
import { FormValidationError } from '@qv-common/enums';

@JsonObject('InternalField')
export class InternalField {
  @JsonProperty('id', String)
  public id: string = null;

  @JsonProperty('label', String)
  public label: string = null;

  @JsonProperty('instruction', String)
  public instruction: string = null;

  @JsonProperty('type', String)
  public type: InternalFieldType = InternalFieldType.TEXT;

  @JsonProperty('isEnabled', Boolean, true)
  public isEnabled = true;

  @JsonProperty('meta', Object, true)
  public meta?: InternalFieldMeta = null;

  @JsonProperty('value', String, true)
  public value?: string = null;

  public getValidators(): ValidatorFn[] {
    const validators = [];

    if (!this.meta) return validators;

    if (this.meta.MAX_LENGTH) {
      validators.push(Validators.maxLength(this.meta.MAX_LENGTH));
    }

    return validators;
  }

  public getErrorMessages(): Map<string, string> {
    const messages = new Map<string, string>();

    if (!this.meta) return messages;

    if (this.meta.MAX_LENGTH) {
      messages.set(
        FormValidationError.MAX_LENGTH, `Field Value should be maximum ${this.meta.MAX_LENGTH} characters long`
      );
    }

    return messages;
  }
}
