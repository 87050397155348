import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { BidService } from '@qv-bid/services';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { Bid } from '@qv-bid/entities';
import { NotificationService } from 'quantuvis-angular-common/notification';
import { UserService } from '@qv-common/services/auth/user.service';

@Injectable({
  providedIn: 'root'
})
export class PharmaCanRespondGuard implements CanActivate {
  private readonly payerEditingRfpErrorMsg =
    'is editing this RFP. When completed, you will receive an updated version of the RFP to respond to.';
  private readonly incorrectStatusForRespond = 'Respond Option has already been chosen. You can now edit this RFP';

  constructor(
    private notificationService: NotificationService,
    private userService: UserService,
    private bidService: BidService,
    private router: Router
  ) {}

  public canActivate(next: ActivatedRouteSnapshot): Observable<boolean> {
    return this.bidService.currentBid$.pipe(
      filter((bid: Bid) => Boolean(bid)),
      take(1),
      map((bid: Bid) => {
        const user = this.userService.user.getValue();

        const bidStatusResponseRequested = bid.isStatusResponseRequested();
        const userCompanyAssigned = bid.isBidToUserCompanyAssigned(user.company.id);
        const canActivate = bidStatusResponseRequested && userCompanyAssigned;

        if (!bidStatusResponseRequested) {
          this.notificationService.warning(this.incorrectStatusForRespond);
        }

        if (!userCompanyAssigned) {
          this.notificationService.error(`${bid.payer.name} ${this.payerEditingRfpErrorMsg}`);
        }

        if (!canActivate) {
          this.router.navigate(['/standard', 'bid'],
            { queryParams: { bidId: next.queryParams.bidId } }
          );
        }
        return canActivate;
      }));
  }
}
