import { AfterViewInit, Directive, ElementRef, Input, OnChanges, Renderer2, SimpleChanges } from '@angular/core';
import { DomManagerService } from '@qv-shared/services';
import { take } from 'rxjs/operators';

@Directive({
  selector: '[qvReadMore]'
})
export class ReadMoreDirective implements AfterViewInit, OnChanges {
  @Input('qvReadMoreEnabled')
  public enabled = true;

  private hiddenState = 'qv-hidden';
  private containerClassName = 'qv-read-more-container';
  private fadeClassName = 'qv-read-more-container--fade';
  private actions = { more: 'MORE', less: 'LESS' };

  private readMoreBtn: HTMLButtonElement;
  private readLessBtn: HTMLButtonElement;
  private defaultMaxHeight = 250;
  private originalHeight = this.defaultMaxHeight;

  constructor(private hostElement: ElementRef, private renderer: Renderer2, private domManager: DomManagerService) {}

  public ngAfterViewInit(): void {
    if (!this.enabled) return;
    const { elSubject, observer } = this.domManager.subscribeOnElementAppears(this.hostElement.nativeElement);
    const elSubscriber = elSubject.pipe(take(1)).subscribe((el: Element) => {
      if (el.clientHeight > this.defaultMaxHeight) {
        this.originalHeight = el.clientHeight;
        this.init();
        observer.disconnect();
        elSubscriber.unsubscribe();
      }
    });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.enabled && changes.enabled.previousValue && !changes.enabled.currentValue) {
      this.destroy();
    }
  }

  private init(): void {
    this.createButtons();
    this.renderer.appendChild(this.hostElement.nativeElement.parentElement, this.readMoreBtn);
    this.renderer.addClass(this.readLessBtn, this.hiddenState);
    this.renderer.appendChild(this.hostElement.nativeElement.parentElement, this.readLessBtn);

    this.renderer.setStyle(this.hostElement.nativeElement, 'height', `${this.defaultMaxHeight}px`);

    this.renderer.addClass(this.hostElement.nativeElement, this.containerClassName);
    this.renderer.addClass(this.hostElement.nativeElement, this.fadeClassName);
  }

  private destroy(): void {
    this.renderer.removeClass(this.hostElement.nativeElement, this.containerClassName);
    this.renderer.removeClass(this.hostElement.nativeElement, this.fadeClassName);
    this.renderer.removeStyle(this.hostElement.nativeElement, 'height');

    const { parentElement } = this.hostElement.nativeElement;

    if (!parentElement) {
      return;
    }
    if (this.readMoreBtn) {
      this.renderer.removeChild(parentElement, this.readMoreBtn);
    }
    if (this.readLessBtn) {
      this.renderer.removeChild(this.hostElement.nativeElement.parentElement, this.readLessBtn);
    }
  }

  private createButtons(): void {
    this.readMoreBtn = this.renderer.createElement('button');
    this.readMoreBtn.innerText = 'Show More';
    this.renderer.addClass(this.readMoreBtn, 'qv-read');
    this.renderer.addClass(this.readMoreBtn, 'qv-read--more');
    this.readMoreBtn.addEventListener('click', () => this.onClick(this.actions.more));

    this.readLessBtn = this.renderer.createElement('button');
    this.readLessBtn.innerText = 'Show Less';
    this.renderer.addClass(this.readLessBtn, 'qv-read');
    this.renderer.addClass(this.readLessBtn, 'qv-read--less');
    this.readLessBtn.addEventListener('click', () => this.onClick(this.actions.less));
  }

  private onClick(action: string): void {
    if (action === this.actions.more) {
      this.renderer.setStyle(this.hostElement.nativeElement, 'height', `${this.originalHeight}px`);
      this.renderer.removeClass(this.hostElement.nativeElement, this.fadeClassName);
      this.renderer.addClass(this.readMoreBtn, this.hiddenState);
      this.renderer.removeClass(this.readLessBtn, this.hiddenState);
    } else if (action === this.actions.less) {
      this.renderer.setStyle(this.hostElement.nativeElement, 'height', `${this.defaultMaxHeight}px`);
      this.renderer.addClass(this.hostElement.nativeElement, this.fadeClassName);
      this.renderer.addClass(this.readLessBtn, this.hiddenState);
      this.renderer.removeClass(this.readMoreBtn, this.hiddenState);
    }
  }

}
