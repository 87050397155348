import { FormGroup } from '@angular/forms';
import { Observable, of } from 'rxjs';

import { BaseInsideModal } from './base-inside-modal';

export class BaseInsideFormModal extends BaseInsideModal {
  public form: FormGroup;
  public isShowPrimaryActionButton = true;
  public isPrimaryActionButtonDisabled = false;
  public readonly primaryActionDisabledTooltip: string;

  public isInfoMessageCheckboxShown$: Observable<boolean | null> = of(false);
  public infoMessageCheckboxDependencyState$: Observable<boolean | null> = of(null);
  public readonly infoMessageTooltip: string;
  public readonly infoMessage: string;

  public onSubmit(): Observable<any> {
    return of(null);
  }
}
