import { Inject, Injectable } from '@angular/core';
import { PharmaAudit } from '@qv-bid/entities';
import { BaseContractDao } from '@qv-bid/services/dao/contract-terms/base-contract.dao';
import { ApiService } from '@qv-common/services/api';
import { JSON_CONVERT_TOKEN } from 'quantuvis-angular-common/json-converter/services';
import { JsonConvert } from 'json2typescript';
import { ViewPerspectiveService } from '@qv-common/services';

@Injectable()
export class PharmaAuditDaoService extends BaseContractDao<PharmaAudit> {
  protected restEntityName = 'audits';
  protected type = PharmaAudit;

  public constructor(
    apiService: ApiService,
    @Inject(JSON_CONVERT_TOKEN) jsonConvert: JsonConvert,
    viewPerspectiveService: ViewPerspectiveService) {
    super(apiService, jsonConvert, viewPerspectiveService);
  }
}
