<ng-container *ngIf="!isEditPresentation(termValue, isEditMode, isOverrideMode, isUserPharma, isLocked)">
  <div class="term-date term-date--view">
    <qv-error-icon
        *ngIf="isErrorsVisible(isDisplayErrors, !!combinedErrors.length)"
        [errors]="combinedErrors"
        class="term__validation-icon"
    ></qv-error-icon>
    <ng-template
        (overwriteValuesConfirmed)="overwriteValue()"
        [qvAtNdc]="termValue"
        [setDisableClick]="!isEditable(isEditMode, isUserPharma, isLocked, isPending && !isAtNdc(termValue))">
      <div [class.term__value--empty]="!getTermValue()" class="term__value">
        {{ getTermValue() | dateTimeUtc:dateFormat }}
      </div>
    </ng-template>
  </div>
</ng-container>

<ng-container *ngIf="isEditPresentation(termValue, isEditMode, isOverrideMode, isUserPharma, isLocked)">
  <div class="term-date-picker">
    <qv-error-icon
        *ngIf="isErrorsVisible(isDisplayErrors, !!combinedErrors.length)"
        [errors]="combinedErrors"
        class="term-date-picker__validation-icon"
    ></qv-error-icon>
    <input
      #dateInput
      (blur)="onBlur()"
      (click)="!hasPickerIcon && picker.open()"
      (dateChange)="onDateChanged($event)"
      [class.term-date-picker__field--error]="errorLevel === severityLevel.ERROR"
      [class.term-date-picker__field--has-picker-icon]="hasPickerIcon"
      [class.term-date-picker__field--warn]="errorLevel === severityLevel.WARN"
      [matDatepicker]="picker"
      [max]="maxDate"
      [min]="minDate"
      [value]="control.value"
      class="term-date-picker__field"
      matInput
    >
    <mat-datepicker
      #picker
      (closed)="onCloseDatePicker()"
      class="term-date-picker__picker"
    ></mat-datepicker>
    <mat-datepicker-toggle *ngIf="hasPickerIcon"
                           [for]="picker"
                           class="term-date-picker__toggle"
                           matSuffix
    ></mat-datepicker-toggle>
  </div>

</ng-container>
