import { ActivatedRoute, Router } from '@angular/router';
import { BID_VIEW_CONFIG, BidViewConfig } from '@qv-bid/configs/bid-view-config';
import { BidEventBusService } from '@qv-bid/services/bid-event-bus.service';
import { BlockingMessage } from '@qv-common/enums/blocking-message.enum';
import { BehaviorSubject, combineLatest, forkJoin, iif, Observable, of, Subject, throwError } from 'rxjs';
import { catchError, debounceTime, filter, finalize, map, switchMap, take, tap, } from 'rxjs/operators';
import { Bid, BidVersion, Drug, PharmaRight, Scenario } from '@qv-bid/entities';
import { BidVersionService } from '@qv-bid/services/bid-version.service';
import { SummaryService } from '@qv-bid/services/summary/summary.service';
import { IPageInfo } from 'ngx-virtual-scroller';
import { VirtualScrollUtil } from '@qv-bid/utils';
import { HttpErrorResponse, HttpParams } from '@angular/common/http';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { EventEmitter, Inject, Injectable, OnDestroy } from '@angular/core';
import { NdcManagerService } from '@qv-bid/services/ndc-manager.service';
import {
  BidFilterService,
  BidService,
  BidStateService,
  ContractedBusinessesService,
  PharmaRightsService,
  ScenarioConfigListService,
  ScenarioNameFormService,
  UndoRedoService,
  VirtualScrollerService,
} from '@qv-bid/services';
import { FirstViewportItemConfig } from '@qv-bid/models/first-viewport-item-config';
import { BidFilterName, BidVersionType } from '@qv-bid/enums';
import { BidVersionDaoService } from '@qv-bid/services/dao/bid-version.dao.service';
import { BidDaoService } from '@qv-bid/services/dao';
import { BidSelectService } from '@qv-bid/services/selects';
import { ViewPerspectiveService } from '@qv-common/services/auth';
import { QvCache } from '@qv-common/decorators';

import { ContractedBusiness } from 'quantuvis-core-entities';
import { HttpStatusCode } from 'quantuvis-angular-common/api';
import { NotificationService } from 'quantuvis-angular-common/notification';

@UntilDestroy()
@Injectable()
export class BidViewService implements OnDestroy {
  @BlockUI()
  public blockUI: NgBlockUI;

  public firstViewportItemConfig = new FirstViewportItemConfig();
  public isReady$ = new BehaviorSubject(false);
  public cbIndexChanged$ = new EventEmitter<number>();

  private virtualScrollChangeEvent: IPageInfo;
  private isScenariosLoading: boolean;
  private changesWithDebounce = new Subject();

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private bidDaoService: BidDaoService,
    private bidFilterService: BidFilterService,
    private bidVersionDaoService: BidVersionDaoService,
    private bidVersionService: BidVersionService,
    private summaryService: SummaryService,
    private notificationService: NotificationService,
    private ndcManagerService: NdcManagerService,
    private virtualScrollerService: VirtualScrollerService,
    private contractedBusinessesService: ContractedBusinessesService,
    private bidSelectService: BidSelectService,
    private bidStateService: BidStateService,
    private bidEventBusService: BidEventBusService,
    private undoRedoService: UndoRedoService,
    private bidService: BidService,
    @Inject(BID_VIEW_CONFIG) private bidViewConfig: BidViewConfig,
    private scenarioConfigListService: ScenarioConfigListService,
    private scenarioNameFormService: ScenarioNameFormService,
    private pharmaRightsService: PharmaRightsService,
    private viewPerspectiveService: ViewPerspectiveService
  ) {
    this.changesWithDebounce
      .pipe(
        debounceTime(this.bidViewConfig.scenarioLoadingDebounceInterval),
        untilDestroyed(this)
      )
      .subscribe((event: IPageInfo) => this.fetchMoreScenariosWithDrugs(event));
    this.prepareSelectsAfterScenariosChangedHandler();
    this.initEventBusSubscriptions();
  }

  public loadScenarios(sortParams?: HttpParams): Observable<any> {
    this.isReady$.next(false);
    this.blockUI.start(BlockingMessage.LOADING_BID_DETAILS);
    this.firstViewportItemConfig.lock();
    this.bidStateService.sortParams = sortParams || this.bidStateService.sortParams;

    return this.getGeneralDrugScenariosOfVersion(this.bidStateService.bidVersionId, this.bidStateService.sortParams)
      .pipe(
        tap(() => this.scenarioNameFormService.clearFormGroup()),
        switchMap((scenariosWithDrugs: Scenario[]) => this.setDrugsToScenarios(scenariosWithDrugs)),
        finalize(() => {
          this.blockUI.stop();
          this.isReady$.next(true);
          this.isScenariosLoading = false;
          this.virtualScrollerService.scrollToPosition.next();
        }),
        catchError((err) => this.notificationService.showServerError(err))
      );
  }

  public fetchMoreScenariosWithDrugs(virtualScrollChangeEvent: IPageInfo): void {
    this.virtualScrollChangeEvent = virtualScrollChangeEvent;
    this.bidStateService.scenarios$.pipe(
      take(1),
      filter(() => this.isReady$.getValue()),
    ).subscribe((scenarios: Scenario[]) => {
      const shouldFetchMoreScenariosWithDrugs = VirtualScrollUtil.shouldFetchMoreScenariosWithDrugs(
        virtualScrollChangeEvent,
        scenarios,
        this.bidViewConfig.buffer,
        this.isScenariosLoading
      );

      if (!shouldFetchMoreScenariosWithDrugs) return;

      const endScenarioBuffer = VirtualScrollUtil.getEndScenarioBuffer(
        virtualScrollChangeEvent,
        scenarios,
        this.bidViewConfig.buffer
      );

      const scenariosIds = this.getScenariosIds(endScenarioBuffer, scenarios);

      if (!scenariosIds.length) return;

      this.fetchMoreDrugScenariosOfVersion(scenariosIds);
    });
  }

  public viewPortDataUpdateHandler(virtualScrollChangeEvent: IPageInfo): void {
    this.changesWithDebounce.next(virtualScrollChangeEvent);
  }

  public loadBidData(forceLoad?: boolean): Observable<any> {
    this.blockUI.start(BlockingMessage.LOADING_BID_DETAILS);
    const bidId = parseInt(this.activatedRoute.snapshot.queryParams.bidId, 10);
    const versionId = parseInt(this.activatedRoute.snapshot.queryParams.versionId, 10);
    const isInternal = this.bidService.currentBid$.getValue().isInternal;

    return this.bidVersionService.getBidVersion(bidId, versionId, isInternal).pipe(
      tap((bidVersion: BidVersion) => {
        this.bidStateService.bidVersionId = bidVersion.id;
        this.bidStateService.bidVersionType = bidVersion.versionType;
      }),
      switchMap((bidVersion: BidVersion) => iif(
        () => bidVersion.versionType === BidVersionType.HISTORIC_VERSION,
        this.bidDaoService.getHistoricBid(bidId, bidVersion.id).pipe(
          catchError((err: HttpErrorResponse) => {
            if (err.status === HttpStatusCode.FORBIDDEN) {
              this.router.navigate([this.router.url]);

              return throwError(err);
            }
          })
        ),
        iif(() => forceLoad, this.bidService.getById(
          bidId, this.viewPerspectiveService.getViewPerspective()
        ), this.bidService.currentBid$.pipe(take(1)))
      )),
      tap((bid: Bid) => {
        this.bidStateService.bid$.next(bid);
        this.summaryService.getSummaryInfo(this.bidStateService.bidVersionId);
      }),
      switchMap(() =>
        this.pharmaRightsService.loadPharmaRights(this.bidStateService.bidVersionId).pipe(take(1))
      ),
      finalize(() => this.blockUI.stop())
    );
  }

  public load(): Observable<PharmaRight[]> {
    return this.loadBidData();
  }

  public ngOnDestroy(): void {
    this.ndcManagerService.clearNdcs();
  }

  public reloadScenarios(scenarioIds: number[]): void {
    this.fetchMoreDrugScenariosOfVersion(scenarioIds);
  }

  public reloadBidData(): Observable<any> {
    this.bidStateService.isBidReloaded$.next(false);

    return this.loadBidData(true).pipe(
      tap(() => this.bidSelectService.clear()),
      switchMap(() => this.contractedBusinessesService.loadCB(this.bidStateService.bidVersionId)),
      map((contractedBusinesses: ContractedBusiness[]) => {
        const cbIndex = contractedBusinesses
          .findIndex(cb => cb.name === this.bidStateService.selectedContractedBusinessName);
        const currentCbIndex = cbIndex > 0 || !this.bidStateService.selectedContractedBusinessName ? cbIndex : 0;

        this.cbIndexChanged$.emit(currentCbIndex);

        return contractedBusinesses[currentCbIndex];
      }),
      filter((cb: ContractedBusiness) => Boolean(cb) || !this.bidStateService.selectedContractedBusinessName),
      tap((cb: ContractedBusiness) => this.refreshContractedBusinessFilter(cb)),
      switchMap(() => this.loadScenarios())
    );
  }

  public isAllScenariosLoadedCompletely(): Observable<boolean> {
    return combineLatest([this.bidStateService.initialScenariosWithoutDrugs$, this.bidStateService.scenarios$])
      .pipe(
        map(([initialScenarios, scenarios]) =>
          (initialScenarios.length === scenarios.length)
          && scenarios.every(scenario => scenario.drug instanceof Drug)
        ));
  }

  public getBidVersionId(): number {
    return this.bidStateService.bidVersionId;
  }

  @QvCache()
  public isNoFilteredScenarios(isReady: boolean, scenariosLength: number): boolean {
    return isReady && !scenariosLength;
  }

  private refreshContractedBusinessFilter(cb: ContractedBusiness): void {
    if (cb) {
      this.bidStateService.cbId = cb.id;
      this.bidFilterService.updateFilter(BidFilterName.contractedBusiness, [cb.uuid]);
    }
  }

  private fetchMoreDrugScenariosOfVersion(scenarioIds: number[]): void {
    const { isInternal } = this.bidStateService.bid$.getValue();

    of(scenarioIds).pipe(
      tap(() => this.isScenariosLoading = true),
      switchMap(() => this.getDrugScenariosOfVersion(this.bidStateService.bidVersionId, scenarioIds, isInternal)),
      switchMap((scenariosWithDrugs: Scenario[]) =>
        forkJoin([
          this.setDrugsToInitialScenarios(scenariosWithDrugs),
          this.setDrugsToScenarios(scenariosWithDrugs)
        ])
      ),
      finalize(() => this.isScenariosLoading = false),
      catchError((err: HttpErrorResponse) => this.notificationService.showServerError(err))
    ).subscribe(() => this.fetchMoreScenariosWithDrugs(this.virtualScrollChangeEvent));
  }

  private getGeneralDrugScenariosOfVersion(bidVersionId: number, sortParams?: HttpParams): Observable<Scenario[]> {
    const { isInternal } = this.bidStateService.bid$.getValue();

    this.isScenariosLoading = true;

    return this.bidVersionDaoService.getGeneralDrugScenariosOfVersion(
      bidVersionId, this.bidFilterService.getFilterStateValue(), sortParams
    ).pipe(
      tap((scenarios: Scenario[]) => {
        this.scenarioConfigListService.updateScenarioConfigList(scenarios, bidVersionId);
        this.bidStateService.initialScenariosWithoutDrugs$.next(scenarios);
        this.bidStateService.scenarios$.next(scenarios);
        this.fetchMoreScenariosWithDrugs({ startIndex: 0, endIndex: 0 } as IPageInfo);
        this.processFirstAndLastScenarioFlags(scenarios);
      }),
      map(() => this.getScenariosIds(0)),
      switchMap((scenarioIds: number[]) => this.getDrugScenariosOfVersion(bidVersionId, scenarioIds, isInternal))
    );
  }

  private getDrugScenariosOfVersion(id: number, scenarioIds: number[], isInternal: boolean): Observable<Scenario[]> {
    const filterState = this.bidFilterService.getFilterStateValue();
    return scenarioIds.length
      ? this.bidVersionDaoService.getDrugScenariosOfVersion(id, scenarioIds, isInternal, filterState).pipe(
        tap((receivedScenarios: Scenario[]) => this.cleanFilteredScenarios(scenarioIds, receivedScenarios)))
      : of([]);
  }

  private cleanFilteredScenarios(scenarioIds: number[], receivedScenarios: Scenario[]): void {
    const receivedScenarioIds = receivedScenarios.map(({ id }: Scenario) => id);
    const filteredScenarioIds = scenarioIds
      .filter((scenarioId: number) => !receivedScenarioIds.includes(scenarioId));

    if (!filteredScenarioIds.length) return;

    const scenarios = this.bidStateService.scenarios$.getValue();
    const filteredScenarios = scenarios.filter(({ id }: Scenario) => !filteredScenarioIds.includes(id));
    this.bidStateService.scenarios$.next(filteredScenarios);
    this.processFirstAndLastScenarioFlags(filteredScenarios);
  }

  private processFirstAndLastScenarioFlags(scenarios: Scenario[]): void {
    const drugGroups = {};

    scenarios.forEach((scenario: Scenario) => {
      this.scenarioConfigListService.resetFirstAndLastScenarioFlags(scenario.uuid);
      if (!drugGroups[scenario.drugName]) {
        drugGroups[scenario.drugName] = [];
        this.scenarioConfigListService.getScenarioStateConfig(scenario.uuid).isFirstScenarioOfDrugGroup$.next(true);
      }
      drugGroups[scenario.drugName].push(scenario);
    });

    Object.values(drugGroups).forEach((drugGroupScenarios: Scenario[]) => {
      const scenario = drugGroupScenarios[drugGroupScenarios.length - 1];
      this.scenarioConfigListService.getScenarioStateConfig(scenario.uuid).isLastScenarioOfDrugGroup$.next(true);
    });
  }

  private setDrugsToInitialScenarios(scenariosWithDrugs: Scenario[]): Observable<Scenario[]> {
    return this.bidStateService.initialScenariosWithoutDrugs$.pipe(
      take(1),
      tap((scenariosWithoutDrug: Scenario[]) => {
        const updatedScenarios = this.getScenariosWithUpdatedDrug(scenariosWithoutDrug, scenariosWithDrugs);

        this.bidStateService.initialScenariosWithoutDrugs$.next(updatedScenarios);
      })
    );
  }

  private setDrugsToScenarios(scenariosWithDrugs: Scenario[]): Observable<Scenario[]> {
    return this.bidStateService.scenarios$.pipe(
      take(1),
      tap((scenariosWithoutDrug: Scenario[]) => {
        const updatedScenarios = this.getScenariosWithUpdatedDrug(scenariosWithoutDrug, scenariosWithDrugs);

        this.bidStateService.scenarios$.next(updatedScenarios);
      })
    );
  }

  private getScenariosWithUpdatedDrug(scenariosWithoutDrug: Scenario[], scenariosWithDrugs: Scenario[]): Scenario[] {
    return scenariosWithoutDrug.map((scenarioWithoutDrug: Scenario) => {
      const updatedScenarioWithDrug = scenariosWithDrugs.find(
        (scenarioWithDrug: Scenario) => scenarioWithDrug.id === scenarioWithoutDrug.id
      );

      return updatedScenarioWithDrug || scenarioWithoutDrug;
    });
  }

  private getScenariosIds(endScenarioBuffer: number, scenarios: Scenario[] = this.bidStateService.scenarios$.getValue()): number[] {
    const start = Math.max(0, endScenarioBuffer - this.bidViewConfig.limit);
    const end = endScenarioBuffer + this.bidViewConfig.limit;
    return scenarios
      .slice(start, end)
      .filter((scenario: Scenario) => !scenario.drug)
      .map((scenario: Scenario) => scenario.id);
  }

  private initEventBusSubscriptions(): void {
    this.bidEventBusService.applyFilterEvent.pipe(
      switchMap(() => this.loadScenarios()),
      untilDestroyed(this)
    ).subscribe();

    this.bidEventBusService.loadScenariosEvent.pipe(
      switchMap(() => this.loadScenarios()),
      untilDestroyed(this)
    ).subscribe();

    this.bidEventBusService.loadScenariosAndDropNdcsCacheEvent.pipe(
      tap(() => this.ndcManagerService.clearNdcs()),
      switchMap(() => this.loadScenarios()),
      untilDestroyed(this)
    ).subscribe();

    this.bidEventBusService.reloadScenariosEvent.pipe(
      untilDestroyed(this)
    ).subscribe((ids: number[]) => this.reloadScenarios(ids));

    this.bidEventBusService.reloadBidEvent.pipe(
      tap(() => this.ndcManagerService.clearNdcs()),
      switchMap(() => this.reloadBidData()),
      untilDestroyed(this)
    ).subscribe(() => this.bidStateService.isBidReloaded$.next(true));

    this.bidEventBusService.undoRedoEvent
      .pipe(untilDestroyed(this))
      .subscribe(() => this.undoRedoService.bidChange$.next(this.bidStateService.bid$.value.id));
  }

  private prepareSelectsAfterScenariosChangedHandler(): void {
    this.bidStateService.scenarios$.pipe(
      map((scenarios: Scenario[]) => (scenarios || []).map((scenario: Scenario) => scenario.id)),
      untilDestroyed(this)
    ).subscribe((scenariosIds: number[]) => this.bidSelectService.setScenarioIds(scenariosIds));
  }
}
