import { Injectable } from '@angular/core';
import { filter, map, switchMap, take } from 'rxjs/operators';
import { combineLatest, Observable, of } from 'rxjs';
import { ViewPerspectiveService } from '@qv-common/services/auth';
import { BidSelectService, DrugSelectUtilsService } from '@qv-bid/services/selects';
import { NdcManagerService } from '@qv-bid/services/ndc-manager.service';
import { Bid, Ndc, Scenario } from '@qv-bid/entities';
import { BidStateService, ContractedBusinessesService } from '@qv-bid/services';
import { BidUtils } from '@qv-bid/utils';
import { constants } from '@qv-common/static';
import { DrugCopyPasteService } from '@qv-bid/services/drug-copy-paste.service';
import { BidInfoService } from '@qv-bid/services/summary/bid-info.service';
import { DrugCountByName } from '@qv-bid/models';
import { ArrayUtils } from '@qv-common/utils';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BidStatus } from 'quantuvis-core-entities';
import { UserService } from '@qv-common/services/auth/user.service';


@UntilDestroy()
@Injectable()
export class DrugEditActionsAccessibilityService {
  private readonly maxScenariosCountByDrugName = 99;

  constructor(
    private userService: UserService,
    private bidSelectService: BidSelectService,
    private contractedBusinessesService: ContractedBusinessesService,
    private ndcManagerService: NdcManagerService,
    private bidStateService: BidStateService,
    private drugSelectUtilsService: DrugSelectUtilsService,
    private drugCopyPasteService: DrugCopyPasteService,
    private viewPerspectiveService: ViewPerspectiveService,
    private bidInfoService: BidInfoService,
  ) { }

  public isChangeLockForNdcsAvailable(): Observable<boolean> {
    return of(this.userService.isCurrentUserPayer());
  }

  public isChangeLockForNdcsDisabled(lockState: boolean): Observable<boolean> {
    return combineLatest([
      this.bidStateService.scenarios$,
      this.bidSelectService.allScenariosSelected()
    ]).pipe(
      map(([scenarios]) => {
        if (this.drugSelectUtilsService.getSelectedScenarioCount()) {
          return this.isLockIdenticalForAllSelectedScenarios(scenarios, lockState);
        } else {
          return true;
        }
      }),
      untilDestroyed(this),
    );
  }

  public isCreateScenarioActionDisabled(): Observable<boolean> {
    return this.drugSelectUtilsService.isSomeScenarioSelected().pipe(
      switchMap((isAvailable: boolean) => this.checkAbilityToCreteScenarios().pipe(
        map((abilityToCreate: boolean) => [isAvailable, abilityToCreate])
      )),
    ).pipe(map(([isAvailable, abilityToCreate]: [boolean, boolean]) => !(isAvailable && abilityToCreate)));
  }

  public isDeleteScenarioActionDisabled(): Observable<boolean> {
    return combineLatest([
      this.drugSelectUtilsService.isSomeScenarioSelected(),
      this.bidSelectService.allScenariosSelected(),
      this.isAllScenariosSelectedForAnyDrugCb(),
    ]).pipe(map(([isScenarioSelected, isAllSelected, isAllSelectedForAnyDrug]: [boolean, boolean, boolean]) =>
      !isScenarioSelected || isAllSelected || isAllSelectedForAnyDrug)
    );
  }

  public isDeleteHistoricNotesAvailable(): Observable<boolean> {
    return combineLatest([
      this.bidStateService.bid$,
      this.contractedBusinessesService.isHistoricNotesPresents$
    ]).pipe(map(([bid, isHistoricNotesPresents]: [Bid, boolean]) =>
      bid && bid.status === BidStatus.RFP_NOT_SENT && isHistoricNotesPresents
    ));
  }

  public isDeleteHistoricNotesDisabled(): Observable<boolean> {
    return combineLatest([
      this.bidStateService.isEditMode,
      this.bidSelectService.scenarioSelected(),
      this.bidSelectService.ndcSelected(),
    ]).pipe(map(
      ([isEditMode, scenarioList, ndcList]: [boolean, number[], number[]]) => !(isEditMode
        && (this.hasSelectedScenariosWithHistoricNotes(scenarioList) || this.hasSelectedNdcsWithHistoricNotes(ndcList)))
    ));
  }

  public isDragCopyExistInStorage(): Observable<boolean> {
    return this.drugCopyPasteService.isDrugCopyExistInStorage().pipe(untilDestroyed(this));
  }

  public isDeleteDrugActionAvailable(): Observable<boolean> {
    return this.bidStateService.bid$.pipe(
      filter((bid: Bid) => !!bid),
      map((bid: Bid) => {
        const allowedBidStatuses = [
          constants.BidStatusDisplayNameMap.RFP_NOT_SENT,
          `${constants.BidStatusDisplayNameMap.RFP_NOT_SENT} ${constants.BidStatusDisplayNameMap.BINDING}`
        ];
        const completedBidStatus = this.bidInfoService.getCompleteStatus(bid);

        return BidUtils.isUserPayerOrPayerPerspective(
          this.userService.isCurrentUserPayer(),
          bid.isInternal,
          this.viewPerspectiveService.isPayerViewPerspective()
        ) && (bid.editRfpSent || allowedBidStatuses.includes(completedBidStatus));
      }),
      untilDestroyed(this)
    );
  }

  private checkAbilityToCreteScenarios(): Observable<boolean> {
    return this.bidStateService.scenarios$.pipe(
      take(1),
      map((scenarios: Scenario[]) => this.mergeDrugArrays(
        this.mapDrugToNameAndLength(scenarios),
        this.mapDrugToNameAndLength(this.drugSelectUtilsService.getSelectedScenarios(scenarios))
      )),
      map((drugLengths: DrugCountByName[]) =>
        drugLengths.every((drugLength: DrugCountByName) => drugLength.count <= this.maxScenariosCountByDrugName)),
    );
  }

  private mergeDrugArrays(arr1: DrugCountByName[], arr2: DrugCountByName[]): DrugCountByName[] {
    return Object.values([...arr1, ...arr2].reduce((acc, { drugName, count }: DrugCountByName) => {
      acc[drugName] = { drugName, count: ((acc[drugName] ? acc[drugName].count : 0) as number) + count };
      return acc;
    }, {}));
  }

  private mapDrugToNameAndLength(scenarios: Scenario[]): DrugCountByName[] {
    return Array.from(ArrayUtils.groupBy(scenarios, (scenario: Scenario) => `${scenario.drugName}_${scenario.cbId}`))
      .map((drugs: [string, Scenario[]]) => ({ drugName: drugs[0], count: drugs[1].length }));
  }

  private isLockIdenticalForAllSelectedScenarios(scenarios: Scenario[], isLocked: boolean): boolean {
    return scenarios
      .filter((scenario: Scenario) => this.bidSelectService.getSelectedScenarioIds().includes(scenario.id))
      .every((scenario: Scenario) => scenario.drug && (isLocked ? scenario.drug.isLocked : !scenario.drug.isLocked));
  }

  private hasSelectedScenariosWithHistoricNotes(scenarioList: number[]): boolean {
    if (scenarioList.length === 0) {
      return false;
    }

    return this.bidStateService.scenarios$.getValue().some((scenario: Scenario) => scenarioList.includes(scenario.id)
      && scenario.drug && (!scenario.drug.rebate.notes.isEmptyHistoric() || scenario.drug.rebate.notes.isAtNdcHistoric)
    );
  }

  private hasSelectedNdcsWithHistoricNotes(ndsList: number[]): boolean {
    if (ndsList.length === 0) {
      return false;
    }

    return this.ndcManagerService.flatNdcArray
      .some((ndc: Ndc) => ndsList.includes(ndc.drugId) && !ndc.rebate.notes.isEmptyHistoric());
  }

  private isAllScenariosSelectedForAnyDrugCb(): Observable<boolean> {
    return combineLatest([
      this.bidStateService.scenarios$,
      this.bidSelectService.scenarioSelected()
    ]).pipe(map(([scenarios]: [Scenario[], number[]]) => {
      const selectedScenarios = this.drugSelectUtilsService.getSelectedScenarios(scenarios);

      let isAllScenariosSelectedForAnyDrugCb = false;
      let index = 0;

      while (index < selectedScenarios.length && !isAllScenariosSelectedForAnyDrugCb) {
        const selectedScenario = selectedScenarios[index];
        const { drugName, cbId } = selectedScenario;

        const scenariosWithGpi = scenarios
          .filter((scenario: Scenario) => scenario.drugName === drugName && scenario.cbId === cbId);
        const selectedScenariosWithGpi = selectedScenarios
          .filter((scenario: Scenario) => scenario.drugName === drugName && scenario.cbId === cbId);

        if (scenariosWithGpi.length === selectedScenariosWithGpi.length) {
          isAllScenariosSelectedForAnyDrugCb = true;
        }
        index++;
      }

      return isAllScenariosSelectedForAnyDrugCb;
    }));
  }
}
