import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { PermissionService } from '@qv-common/services/auth/permission.service';
import { Bid } from '@qv-bid/entities';
import { BidUtils } from '@qv-bid/utils';
import { Company } from 'quantuvis-core-entities';
import { ErrorNotificationService } from '@qv-common/services';
import { BidService } from '@qv-bid/services';
import { UserService } from '@qv-common/services/auth/user.service';

@Injectable({
  providedIn: 'root'
})
export class WriteAccessToBidGuard implements CanActivate {
  private redirectTo = '/dashboard';

  constructor(private userService: UserService,
              private permissionService: PermissionService,
              private router: Router,
              private bidService: BidService,
              private errorNotificationService: ErrorNotificationService
  ) {}

  public canActivate(next: ActivatedRouteSnapshot): Observable<boolean> {
    const { bidId, perspective } = next.queryParams;

    return this.bidService.getById(bidId, perspective).pipe(
      map((bid: Bid) => BidUtils.getBidOppositeCompanyByCompanyId(bid, this.userService.user.value.companyId)),
      map(({ id }: Company) => this.permissionService.hasUserWriteAccessToCompany(id)),
      tap((access: boolean) => !access && this.router.navigate([this.redirectTo])),
      this.errorNotificationService.catchServerError()
    );
  }
}
