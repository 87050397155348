import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ViewChild, } from '@angular/core';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { Rebate } from '@qv-bid/entities';
import { BidStateService } from '@qv-bid/services';
import { RebateDaoService, ScenarioDaoService } from '@qv-bid/services/dao';
import { SectionChangeManager } from '@qv-bid/services/sections/section-change-manager.service';
import { ViewPerspectiveService } from '@qv-common/services/auth';
import { RebateSection } from '@qv-bid/classes/sections';
import { StandardDrugFormService } from '@qv-bid/services/drug';
import { UserService } from '@qv-common/services/auth/user.service';

@Component({
  selector: 'qv-standard-rebate',
  templateUrl: './standard-rebate.component.html',
  styleUrls: ['./standard-rebate.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StandardRebateComponent extends RebateSection {
  @ViewChild('popoverBaseRebate')
  public baseRebatePopover: NgbPopover;

  public readonly dropDownAppendTo = 'qv-standard-scenario';

  constructor(
    public bidStateService: BidStateService,
    userService: UserService,
    rebateDaoService: RebateDaoService,
    standardDrugFormService: StandardDrugFormService,
    scenarioDaoService: ScenarioDaoService,
    changeDetectorRef: ChangeDetectorRef,
    viewPerspectiveService: ViewPerspectiveService,
    sectionChangeManager: SectionChangeManager,
  ) {
    super(standardDrugFormService, scenarioDaoService, changeDetectorRef, rebateDaoService, userService,
      viewPerspectiveService, sectionChangeManager);
  }

  protected checkAndOpenBaseRebatePopover(rebate: Rebate): void {
    if (this.isUserPharmaOrPharmaPerspective
      && this.isEditMode
      && this.isBaseRebateLow(rebate.baseRebate.value, rebate.minBaseRebate.value)
    ) {
      this.baseRebatePopover.open();
    } else if (this.baseRebatePopover) {
      this.baseRebatePopover.close();
    }
  }
}
