<div class="bid-extra-tools">
  <qv-action-button
      *ngIf="!(userFeatures$ | async).get(quantuvisPlusFeature.PRINT)"
      [queryParamsHandling]="$any(quantuvisPlusQueryParamsHandling)"
      [routerLink]="quantuvisPlusRouterLink"
      [svgIconName]="svgIconName.PRINTER"
      [tooltip]="quantuvisPlusTooltip"
      cssClass="toolbar-button--extra toolbar-button--upgrade mat-elevation-z0"
      data-test-id="test-id-bid-toolbar-extra-button-print">
  </qv-action-button>
  <qv-action-button
      *ngIf="(userFeatures$ | async).get(quantuvisPlusFeature.PRINT)"
      [matMenuTriggerFor]="printMenu"
      [svgIconName]="svgIconName.PRINTER"
      [tooltip]="resources.TOOLTIPS.BID_ACTIONS.PRINT"
      cssClass="toolbar-button--extra"
      data-test-id="test-id-bid-toolbar-extra-button-print">
  </qv-action-button>
  <mat-menu #printMenu="matMenu" [overlapTrigger]="false" yPosition="below">
    <button (click)="exportToPdf(false, false)" mat-menu-item>
      <span>{{resources.BIDS_LIST.DRUG_LEVEL}}</span>
    </button>
    <button
        (click)="exportToPdf(false, true)"
        *ngIf="isExportToPdfWithAttachmentsAvailable"
        mat-menu-item>
      <span>{{resources.BIDS_LIST.DRUG_LEVEL_WITH_ATTACHMENTS}}</span>
    </button>
    <button (click)="exportToPdf(true, false)" mat-menu-item>
      <span>{{resources.BIDS_LIST.NDC_LEVEL}}</span>
    </button>
    <button
        (click)="exportToPdf(true, true)"
        *ngIf="isExportToPdfWithAttachmentsAvailable"
        mat-menu-item>
      <span>{{resources.BIDS_LIST.NDC_LEVEL_WITH_ATTACHMENTS}}</span>
    </button>
    <button (click)="downloadLetterOfIntent()" *ngIf="isLetterOfIntentAvailable" mat-menu-item>
      <span>{{resources.BIDS_LIST.LETTER_OF_INTENT}}</span>
    </button>
    <button (click)="showScenarioSummaryModal()" *ngIf="isScenarioSummaryAvailable" mat-menu-item>
      <span>{{resources.BIDS_LIST.SCENARIO_SUMMARY}}</span>
    </button>
  </mat-menu>

  <qv-action-button
      (click)="exportToExcel()"
      *ngIf="isExportButtonVisible"
      [svgIconName]="svgIconName.DOWNLOAD"
      [tooltip]="resources.TOOLTIPS.BID_ACTIONS.EXPORT"
      cssClass="toolbar-button--extra"
      data-test-id="test-id-bid-toolbar-extra-button-export">
  </qv-action-button>
  <qv-action-button
      *ngIf="isExportUpgradeButtonVisible"
      [queryParamsHandling]="$any(quantuvisPlusQueryParamsHandling)"
      [routerLink]="quantuvisPlusRouterLink"
      [svgIconName]="svgIconName.DOWNLOAD"
      [tooltip]="quantuvisPlusTooltip"
      cssClass="toolbar-button--extra toolbar-button--upgrade mat-elevation-z0"
      data-test-id="test-id-bid-toolbar-extra-button-export">
  </qv-action-button>

  <qv-action-button
      *ngIf="isBidHistoryButtonVisible"
      [queryParams]="enrichParamsWithViewPerspective({bidId: bidId}, bid.isInternal)"
      [svgIconName]="svgIconName.BID_HISTORY"
      [tooltip]="resources.TOOLTIPS.BID_ACTIONS.BID_HISTORY"
      cssClass="toolbar-button--extra"
      data-test-id="test-id-bid-toolbar-extra-button-bid-history"
      routerLink="/bid-history">
  </qv-action-button>
  <qv-action-button
      *ngIf="isBidHistoryUpgradeButtonVisible"
      [queryParamsHandling]="$any(quantuvisPlusQueryParamsHandling)"
      [routerLink]="quantuvisPlusRouterLink"
      [svgIconName]="svgIconName.BID_HISTORY"
      [tooltip]="quantuvisPlusTooltip"
      cssClass="toolbar-button--extra toolbar-button--upgrade mat-elevation-z0"
      data-test-id="test-id-bid-toolbar-extra-button-bid-history">
  </qv-action-button>
</div>
<qv-bid-extra-tools-menu
    [bidVersionId]="bidVersionId"
    [bid]="bid"
    class="bid-extra-tools-menu">
</qv-bid-extra-tools-menu>
