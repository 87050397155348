/* tslint:disable:prefer-template triple-equals */
import { CoreUtils } from '@qv-common/utils';
/**
 * Custom AngularJS services used by the APP logic
 */
import { String } from 'typescript-string-operations';
import { constants } from '@qv-common/static';

declare let angular: angular.IAngularStatic;

export const DrugTermGroupStateManager = ['util', function(util): any {
  'ngInject';
  let changedMetadata: any = {};

  /**
   * Method that process the metadata and sets drug list level show/hide checkboxes accordingly
   * @param drug the drug group to be rendered
   * @param metadata the metadata to be processed
   * @param groupPaths the drug group path as array
   * @param drugGroupsAllowed all drug groups that need to be rendered
   * @param drugGroupStateOptionsValuesMap a map containing all possible states for drug term groups
   * @param resetValue is a flag that determines if the method will reset group path flag for a drug or not
   */
  function processDrugTermsByGroupPath(
    drug, metadata, groupPaths, drugGroupsAllowed, drugGroupStateOptionsValuesMap, resetValue): any {
    processDrugGroups(drug, metadata, groupPaths, drugGroupsAllowed, drugGroupStateOptionsValuesMap, resetValue);
    drug.renderData.children.forEach(drugChild => {
      processDrugTermGroups(
        drug, drugChild, metadata, groupPaths, drugGroupsAllowed, drugGroupStateOptionsValuesMap, resetValue);
    });
  }

  /**
   * Method that process the metadata and sets drug list level show/hide checkboxes accordingly for a drug group only
   * @param drug the drug group to be rendered
   * @param metadata the metadata to be processed
   * @param groupPaths the drug group path as array
   * @param drugGroupsAllowed all drug groups that need to be rendered
   * @param drugGroupStateOptionsValuesMap a map containing all possible states for drug term groups
   * @param resetValue is a flag that determines if the method will reset group path flag for a drug or not
   */
  function processDrugGroups(
    drug, metadata, groupPaths, drugGroupsAllowed, drugGroupStateOptionsValuesMap, resetValue): void {
    Object.keys(groupPaths).forEach(gPath => {
      const groupPath = groupPaths[gPath];
      // Continue with next group path if the current one is ALLOW_REBATE and it is excluded from processing
      if (isAllowRebatePathArray(groupPath) || gPath === 'ALLOW_NDC_OFFERS') {
        return;
      }
      if (resetValue) {
        drug.renderData[getLastElem(groupPath)] = getDefaultValueForTermGroup(
          getLastElem(groupPath), drugGroupStateOptionsValuesMap);
      }

      const termGroupPath = getTermsGroupPathForDrugsGroup(drug, groupPath, true, false);
      const initialGroupPath = util.getPathFromArray(groupPath);
      const metaPaths = getNumberOfDrugPathsInMetaByGroupPath(termGroupPath, metadata);
      if (metaPaths > 0) {
        // check metadata with state flag for a given parent path
        const termGroupPathState = (existsKeyInMeta(termGroupPath, metadata))
          ? metadata[termGroupPath] : getDefaultValueForTermGroup(
            getLastElem(groupPath), drugGroupStateOptionsValuesMap);
        if (metaPaths > 1 && hasDifferentNdcStates(termGroupPath, termGroupPathState, metadata)) {
          drug.renderData[getLastElem(groupPath)] = drugGroupStateOptionsValuesMap.atndc;
        } else if (existsKeyInMeta(termGroupPath, metadata)) {
          drug.renderData[getLastElem(groupPath)] =
            drugGroupStateOptionsValuesMap[metadata[termGroupPath].state.toLowerCase()];
        } else if (existsKeyInMeta(initialGroupPath, metadata)) {
          drug.renderData[getLastElem(groupPath)] =
            drugGroupStateOptionsValuesMap[metadata[initialGroupPath].state.toLowerCase()];
        } else {
          drug.renderData[getLastElem(groupPath)] =
            getDefaultValueForTermGroup(getLastElem(groupPath), drugGroupStateOptionsValuesMap);
        }
      } else if (existsKeyInMeta(initialGroupPath, metadata)) {
        drug.renderData[getLastElem(groupPath)] =
          drugGroupStateOptionsValuesMap[metadata[initialGroupPath].state.toLowerCase()];
      } else {
        drug.renderData[getLastElem(groupPath)] =
          getDefaultValueForTermGroup(getLastElem(groupPath), drugGroupStateOptionsValuesMap);
      }
      // set drug term groups visibility
      if (!drugGroupsAllowed[getLastElem(groupPath)]
        && isTermGroupStateAllowed(drug, getLastElem(groupPath), drugGroupStateOptionsValuesMap)) {
        drugGroupsAllowed[getLastElem(groupPath)] = true;
      }
    });
  }

  function hasDifferentNdcStates(parentKey, parentKeyState, metadata): boolean {
    const regexp = new RegExp('^' + escapeRegExp(parentKey) + '\\.');
    // for (metaPath in metadata) {
    metadata.forEach(metaPath => {
      if (regexp.test(metaPath) == true && metadata[metaPath].state != parentKeyState.state) {
        return true;
      }
    });
    return false;
  }

  /**
   * Verifies if a specific path as array is equal to constants.DRUG_TERM_PATHS.ALLOW_REBATE in order to do not
   * search for it inside meta flags and insert / delete it to / from meta.
   *
   * @param pathAsArray - the path to be checked against constants.DRUG_TERM_PATHS.ALLOW_REBATE
   * @returns {boolean}  true if the path is equal to constants.DRUG_TERM_PATHS.ALLOW_REBATE, false otherwise
   */
  function isAllowRebatePathArray(pathAsArray): boolean {
    return pathAsArray === constants.DRUG_TERM_PATHS.ALLOW_REBATE;
  }

  /**
   * Method that process the metadata and sets drug list level show/hide checkboxes accordingly for all NDCs in
   * a drug group
   * @param drug the drug group to be rendered
   * @param drugChild the drug child that needs ot be rendered
   * @param metadata the metadata to be processed
   * @param groupPaths the drug group path as array
   * @param drugGroupsAllowed all drug groups that need to be rendered
   * @param drugGroupStateOptionsValuesMap
   * @param resetValue is a flag that determines if the method will reset group path flag for a drug or not
   */
  function processDrugTermGroups(
    drug, drugChild, metadata, groupPaths, drugGroupsAllowed, drugGroupStateOptionsValuesMap, resetValue): void {
    Object.keys(groupPaths).forEach(gPath => {
      // for (gPath in groupPaths) {
      const groupPath = groupPaths[gPath];
      const groupName = getLastElem(groupPath);
      // Continue with next group path if the current one is ALLOW_REBATE and it is excluded from processing
      if (isAllowRebatePathArray(groupPath)) {
        return;
      }
      if (resetValue) {
        drugChild.renderData[groupName] = getDefaultValueForTermGroup(groupName, drugGroupStateOptionsValuesMap);
      }

      const termGroupPath = getTermsGroupPathForDrugsGroup(drugChild, groupPath, false, false);
      const termGroupPathWithScenario = getTermsGroupPathForDrugsGroup(drugChild, groupPath, true, false);
      const drugGroupTermsPath = getTermsGroupPathForDrugsGroup(drugChild, groupPath, true, true);
      const defaultGroupPath = util.getPathFromArray(groupPath);

      if (existsKeyInMeta(drugGroupTermsPath, metadata)) {
        drugChild.renderData[groupName] =
          drugGroupStateOptionsValuesMap[metadata[drugGroupTermsPath].state.toLowerCase()];
        if (!angular.equals(drugChild.renderData[groupName], drug.renderData[groupName])) {
          drug.renderData[groupName] = drugGroupStateOptionsValuesMap.atndc;
        }
      } else if (existsKeyInMeta(termGroupPath, metadata)) {
        drugChild.renderData[groupName] = drugGroupStateOptionsValuesMap[metadata[termGroupPath].state.toLowerCase()];
      } else if (existsKeyInMeta(termGroupPathWithScenario, metadata)) {
        drugChild.renderData[groupName] = drugGroupStateOptionsValuesMap[metadata[termGroupPathWithScenario].state
          .toLowerCase()];
      } else if (existsKeyInMeta(defaultGroupPath, metadata)) {
        drugChild.renderData[groupName] =
          drugGroupStateOptionsValuesMap[metadata[defaultGroupPath].state.toLowerCase()];
      }

      // set drug term groups visibility
      if (!drugGroupsAllowed[groupName]
        && isTermGroupStateAllowed(drugChild, groupName, drugGroupStateOptionsValuesMap)) {
        drugGroupsAllowed[groupName] = true;
      }
    });

    const ndcPath = constants.DRUG_TERM_PATHS.ALLOW_NDC_OFFERS.join('.');
    const ndsStatePath = ndcPath + `.${drug.name}.${drug.scenario.id}`;
    if (existsKeyInMeta(ndsStatePath, metadata)) {
      drug.renderData.ndc = drugGroupStateOptionsValuesMap[metadata[ndsStatePath].state.toLowerCase()];
    } else if (metadata[ndcPath]) {
      drug.renderData.ndc = drugGroupStateOptionsValuesMap[metadata[ndcPath].state.toLowerCase()];
    }
  }

  /**
   * Method that alters metadata with show/hide information for a group of terms corresponding to a specific drug
   * group.
   *
   * @param drug - the drug group to be processed
   * @param metadata - the metadata to be processed
   * @param groupPath - the terms group path that will be displayed or hidden
   * @param scope - the current scope used to trigger the change event in order for the metadata to be saved
   * @param newState - the new state assigned to the <code>groupPath</code> drug term group
   * @param event - marker type of operation, used for copy/paste scenario locks
   */
  function manageGroupLevelStateForTermsGroup(drug, metadata, groupPath, scope, newState, event): void {
    if (CoreUtils.isDefined(groupPath) && CoreUtils.isDefined(newState)) {
      resetChangedMetadataObject();

      if (!isAllowRebatePathArray(groupPath)) {
        const initialDrugTermsGroupPath = util.getPathFromArray(groupPath);
        const drugGroupPathString = getTermsGroupPathForDrugsGroup(drug, groupPath, true, false);
        const isExistInitialDrugTermsGroupPathInMetadata = existsKeyInMeta(initialDrugTermsGroupPath, metadata);
        const isExistDrugGroupPathStringInMetadata = existsKeyInMeta(drugGroupPathString, metadata);

        // if default group path exists (if it exists it always has state = 'locked' || 'hidden') then override
        // the metadata at group level with newState
        if (isExistInitialDrugTermsGroupPathInMetadata
          && areDrugTermGroupStatesEqual(metadata[initialDrugTermsGroupPath], newState)) {
          deleteMetaKeysForGroup(drugGroupPathString, metadata);
        } else if (isExistInitialDrugTermsGroupPathInMetadata) {
          if (isExistDrugGroupPathStringInMetadata) {
            metadata[drugGroupPathString] = getNewStateForMetadata(newState);
            addOperationToUpdateQueue('update', drugGroupPathString, metadata[drugGroupPathString]);
          } else {
            metadata[drugGroupPathString] = getNewStateForMetadata(newState);
            addOperationToUpdateQueue('insert', drugGroupPathString, metadata[drugGroupPathString]);
          }

          deleteMetaKeysForGroupExceptParentKey(drugGroupPathString, metadata);
        } else if (isNewStateDifferentThenDefaultState(newState, groupPath, scope.allowPharmaOptions)) {
          if (isExistDrugGroupPathStringInMetadata) {
            metadata[drugGroupPathString] = getNewStateForMetadata(newState);
            addOperationToUpdateQueue('update', drugGroupPathString, metadata[drugGroupPathString]);
          } else {
            metadata[drugGroupPathString] = getNewStateForMetadata(newState);
            addOperationToUpdateQueue('insert', drugGroupPathString, metadata[drugGroupPathString]);
          }

          deleteMetaKeysForGroupExceptParentKey(drugGroupPathString, metadata);
        } else {
          deleteMetaKeysForGroup(drugGroupPathString, metadata);
        }

        drug.renderData[getLastElem(groupPath)] = newState;
        renderChildrenInTermGroups(drug, groupPath);
        triggerMetaChangedEvent(scope, event);
      }
    }
  }

  /**
   * Method that alters metadata with show/hide information for a group of terms corresponding to a specific NDC.
   *
   * @param drug the drugs group to be processed
   * @param metadata the metadata to be processed
   * @param groupPath the terms group path that will be displayed or hidden
   * @param scope the current scope used to trigger the change event in order for the metadata to be saved
   * @param newState - the new state assigned to the <code>groupPath</code> drug term group
   * @param drugGroupStateOptionsValuesMap a map containing all possible states for drug term groups
   * @param event - marker type of operation, used for copy/paste scenario locks
   */
  function manageNdcLevelStateForTermsGroup(
    drug, metadata, groupPath, scope, newState, drugGroupStateOptionsValuesMap, event): void {
    if (CoreUtils.isDefined(groupPath) && CoreUtils.isDefined(newState)) {
      resetChangedMetadataObject();

      if (!isAllowRebatePathArray(groupPath)) {

        const parentMetaKey = getTermsGroupPathForDrugsGroup(drug, groupPath, true, false);
        const termsGroupPath = getTermsGroupPathForDrugsGroup(drug, groupPath, true, true);
        const numberOfDrugsInGroup = drug.renderData.parent.renderData.children.length;
        const initialGroupPath = util.getPathFromArray(groupPath);

        const parentMetaStateValue = getStateFlagValueFromMetadata(parentMetaKey, metadata);
        if ((CoreUtils.isDefined(parentMetaStateValue) && areDrugTermGroupStatesEqual(metadata[parentMetaKey], newState))
          || (CoreUtils.isNotDefined(parentMetaStateValue)
            && existsKeyInMeta(initialGroupPath, metadata) && areDrugTermGroupStatesEqual(
              metadata[initialGroupPath], newState))) {
          if (existsKeyInMeta(termsGroupPath, metadata)) {
            deleteMetaKey(metadata, termsGroupPath);
          } else {
            // nothing - the same "true" value for lock is inherited from parent
          }
        } else {
          if (existsKeyInMeta(termsGroupPath, metadata)) {

            if (!existsKeyInMeta(parentMetaKey, metadata) && !existsKeyInMeta(initialGroupPath, metadata)
              && !isNewStateDifferentThenDefaultState(newState, groupPath, scope.allowPharmaOptions)) {
              delete metadata[termsGroupPath];
              addOperationToUpdateQueue('delete', termsGroupPath, metadata[termsGroupPath]);
            } else {
              metadata[termsGroupPath] = getNewStateForMetadata(newState);
              addOperationToUpdateQueue('update', termsGroupPath, metadata[termsGroupPath]);
            }

          } else {
            metadata[termsGroupPath] = getNewStateForMetadata(newState);
            addOperationToUpdateQueue('insert', termsGroupPath, metadata[termsGroupPath]);
          }
          if (getNumberOfDrugsWithSpecifiedStateFlagValueForDrugGroup(parentMetaKey, newState.state, metadata)
            == numberOfDrugsInGroup) {
            if ((existsKeyInMeta(initialGroupPath, metadata)
              && areDrugTermGroupStatesEqual(metadata[initialGroupPath], newState))
              || (!existsKeyInMeta(initialGroupPath, metadata)
                && !isNewStateDifferentThenDefaultState(newState, groupPath, scope.allowPharmaOptions))) {
              deleteMetaKeysForGroup(parentMetaKey, metadata);
            } else {
              deleteMetaKeysForGroupExceptParentKey(parentMetaKey, metadata);
              if (CoreUtils.isDefined(parentMetaStateValue)) {
                metadata[parentMetaKey] = getNewStateForMetadata(newState);
                addOperationToUpdateQueue('update', parentMetaKey, metadata[parentMetaKey]);
              } else {
                metadata[parentMetaKey] = getNewStateForMetadata(newState);
                addOperationToUpdateQueue('insert', parentMetaKey, metadata[parentMetaKey]);
              }
            }
          }
        }

        const numOfDrugsWithSpecificState =
          getNumberOfDrugsWithSpecifiedStateFlagValueForDrugGroup(parentMetaKey, newState.state, metadata);
        if (numOfDrugsWithSpecificState == 0 || (numOfDrugsWithSpecificState == 1
          && existsKeyInMeta(initialGroupPath, metadata))) {
          drug.renderData.parent.renderData[getLastElem(groupPath)] = newState;
        }

        drug.renderData[getLastElem(groupPath)] = newState;
        renderTermGroup(drug, groupPath, newState, drugGroupStateOptionsValuesMap);
        triggerMetaChangedEvent(scope, event);
      }
    }
  }

  /**
   * Method that renders checked/unchecked values for NDCs in a group.
   *
   * @param drug the drugs group to be rendered
   * @param termGroupPath the terms group path that needs ot be rendered
   */
  function renderChildrenInTermGroups(drug, termGroupPath): void {
    drug.renderData.children.forEach(item => {
      item.renderData[getLastElem(termGroupPath)] = drug.renderData[getLastElem(termGroupPath)];
    });
  }

  /**
   * Method that renders checked/unchecked values for a drug group.
   *
   * @param drugChild - the NDC in the group that needs to be rendered
   * @param termGroupPath - the terms group path that needs ot be rendered
   * @param newState - the new state assigned to the drug term group path defined by <code>termGroupPath</code>
   * @param drugGroupStateOptionsValuesMap - option values
   */
  function renderTermGroup(drugChild, termGroupPath, newState, drugGroupStateOptionsValuesMap): void {
    const parent = drugChild.renderData.parent;
    const ndcWithDifferentState = parent.renderData.children.find(ndc =>
      ndc.renderData[getLastElem(termGroupPath)].state !== newState.state
    );
    if (ndcWithDifferentState) {
      drugChild.renderData.parent.renderData[getLastElem(termGroupPath)] = drugGroupStateOptionsValuesMap.atndc;
    } else {
      drugChild.renderData.parent.renderData[getLastElem(termGroupPath)] = newState;
    }
  }

  /**
   * Method that retrieves all meta paths for a specific drug.
   *
   * @param data meta data patch
   */
  function getAllMetaPathsForGroup(data): {} {
    const { drug, metadata, includeRootMeta } = data;
    const drugName = (drug.renderData && drug.renderData.isGroup) ? drug.renderData.groupName : drug.name;
    const groupPaths = constants.DRUG_TERM_PATHS;
    const foundMetaPaths = {};

    // for (gPath in groupPaths) {
    Object.values(groupPaths).forEach(groupPath => {
      // Continue with next group path if the current one is ALLOW_REBATE and it is excluded from processing
      if (isAllowRebatePathArray(groupPath)) {
        return;
      }
      const groupPathFromArray = util.getPathFromArray(groupPath);

      let termGroupPath = `${groupPathFromArray}.${drugName}`;

      if (drug.groupScenario && drug.groupScenario.id) {
        termGroupPath = `${termGroupPath}.${drug.groupScenario.id}`;
      }
      if (drug.ndc) {
        termGroupPath = `${termGroupPath}.${drug.ndc}`;
      }
      if (drug.scenario && drug.scenario.id) {
        termGroupPath = `${termGroupPath}.${drug.scenario.id}`;
      }

      searchForTermsGroupPath(foundMetaPaths, termGroupPath, metadata, includeRootMeta);
    });

    return foundMetaPaths;
  }

  /**
   * Method that retrieves all group level (no NDCs) meta paths for a specific drug group.
   *
   * @param drugGroup the drug group to retrieve metadata for
   * @param metadata the plan type's metadata
   * @param groupScenario group level scenario
   */
  function getGroupLevelMetaPathsForGroup(drugGroup?, metadata?, groupScenario?): {} {
    const drugName = (drugGroup.renderData && drugGroup.renderData.isGroup)
      ? drugGroup.renderData.groupName : drugGroup.name;
    const groupPaths = constants.DRUG_TERM_PATHS;
    const foundMetaPaths = {};
    // for (gPath in groupPaths) {
    Object.values(groupPaths).forEach(groupPath => {
      let termGroupPath = `${util.getPathFromArray(groupPath)}.${drugName}`;
      if (groupScenario) {
        termGroupPath = `${termGroupPath}.${groupScenario.id}`;
      } else if (drugGroup.scenario && drugGroup.scenario.id) {
        termGroupPath = `${termGroupPath}.${drugGroup.scenario.id}`;
      }
      searchForGroupPath(foundMetaPaths, termGroupPath, metadata);
    });
    return foundMetaPaths;
  }

  /**
   * Search in metadata for all paths that start with the given termsGroupPath.
   */
  function searchForTermsGroupPath(foundMetaPaths, termsGroupPath, metadata, includeRoot): boolean {
    const termsGroupPathRegexp = new RegExp('^' + escapeRegExp(termsGroupPath));
    let found = false;

    // for (metaPath in metadata) {
    Object.keys(metadata).forEach(metaPath => {
      const metaPathRegexp = new RegExp('^' + escapeRegExp(termsGroupPath));
      if (termsGroupPathRegexp.test(metaPath) || (includeRoot && metaPathRegexp.test(termsGroupPath))) {
        foundMetaPaths[metaPath] = metadata[metaPath];
        found = true;
      }
    });
    return found;
  }

  /**
   * Search in metadata for an exact match of the groupPath.
   **/
  function searchForGroupPath(foundMetaPaths, groupPath, metadata): void {
    // for (metaPath in metadata) {
    Object.keys(metadata).forEach(metaPath => {
      if (metaPath === groupPath) {
        foundMetaPaths[metaPath] = metadata[metaPath];
      }
    });
  }

  function isTermGroupStateAllowed(drug, groupPath, drugGroupStateOptionsValuesMap): boolean {
    return (drug.renderData[groupPath].state != drugGroupStateOptionsValuesMap.hidden.state);
  }

  function getDefaultValueForTermGroup(groupPath, drugGroupStateOptionsValuesMap): boolean | any {
    if (groupPath == 'priceProtection') {
      return drugGroupStateOptionsValuesMap.required;
    } else if (groupPath == 'ndc' || groupPath == 'marketBasket') {
      return drugGroupStateOptionsValuesMap.unlocked;
    } else {
      return drugGroupStateOptionsValuesMap.allowed;
    }

  }

  function getNewStateForMetadata(newState): any {
    return {
      state: newState.state
    };
  }

  function isNewStateDifferentThenDefaultState(newState, groupPath, allowPharmaOptions): false | true {
    let defaultStates;
    const states = constants.DRUG_TERM_GROUPS_STATE_VALUES;
    switch (getLastElem(groupPath)) {
      case 'priceProtection':
        defaultStates = [ states.UNLOCKED_STATE, states.REQUIRED_STATE ];
        break;
      case 'marketShare':
        defaultStates = allowPharmaOptions.allowMarketShareRebate.value ?
          [ states.VISIBLE_STATE ] : [ states.HIDDEN_STATE ];
        break;
      default:
        defaultStates = [ states.VISIBLE_STATE, states.UNLOCKED_STATE, states.REQUIRED_STATE ];
    }
    return !defaultStates.find(defaultState => defaultState.state == newState.state);
  }

  /**
   * Method that checks if two drug term group states are equal
   * @param stateOne one of the states that will be compared
   * @param stateTwo the other state
   * @returns {boolean} true if the two state values are equal
   */
  function areDrugTermGroupStatesEqual(stateOne, stateTwo): boolean {
    return stateOne.state === stateTwo.state;
  }

  /**
   * Method that checks if key <code>metaKey</code> exists in <code>metadata</code> and returns the value of the
   * 'state' flag
   * @param metaKey the key to be looked for in metadata
   * @param metadata the metadata where to search the key
   * @returns the 'state' value of the metadata key if the key is found in metadata or undefined if the key
   *      is not found in metadata
   */
  function getStateFlagValueFromMetadata(metaKey, metadata): any {
    if (existsStateFlagInMetaKeyValue(metaKey, metadata)) {
      return metadata[metaKey].state;
    }
  }

  /**
   * Method that checks if a key exists in metadata and the value of that key contains a 'state' flag
   * @param metaKey the key for be looked for in metadata
   * @param metadata the metadata where to look for the <code>metaKey</code>
   * @returns boolean if the key was found and the value of the key contains a 'state' flag
   *          false otherwise
   */
  function existsStateFlagInMetaKeyValue(metaKey, metadata): boolean {
    return (existsKeyInMeta(metaKey, metadata) && CoreUtils.isDefined(metadata[metaKey].state));
  }

  /**
   * Method that checks if a key exists in metadata
   * @param metaKey the key to be looked for
   * @param metadata the metadata where to look for the key
   * @returns boolean if the key exists
   *          false otherwise
   */
  function existsKeyInMeta(metaKey, metadata): boolean {
    return (CoreUtils.isDefined(metadata) && CoreUtils.isDefined(metadata[metaKey]));
  }

  /**
   * Method that safely deletes a key from metadata
   * @param metadata the metadata where the key might be found
   * @param metaKey the key to be deleted
   */
  function deleteMetaKey(metadata, metaKey): void {
    if (existsKeyInMeta(metaKey, metadata)) {
      addOperationToUpdateQueue('delete', metaKey, metadata[metaKey]);
      delete metadata[metaKey];
    }
  }

  /**
   * Method that returns the number of NDC paths found in a drug group (matches also the metadata at the group level)
   * @param parentKey the metadata key of the drugs group
   * @param metadata the metadata where to look for the keys
   * @returns number of NDC paths found for a specific parentKey (the drugs group key)
   */
  function getNumberOfDrugPathsInMetaByGroupPath(parentKey, metadata): number {
    let noTerms = 0;
    const regexp = new RegExp('^' + escapeRegExp(parentKey));
    // for (metaPath in metadata) {
    Object.keys(metadata).forEach(metaPath => {
      if (regexp.test(metaPath) == true) {
        noTerms++;
      }
    });
    return noTerms;
  }

  function escapeRegExp(str): string {
    return str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&');
  }

  /**
   * Method that returns the number of NDCs in a group with a specific state flag value
   * @param parentKey the metadata key of the drugs group to be processed
   * @param flagValue the flag value
   * @param metadata the metadata where to look for the keys
   * @returns number of metadata keys for the NDCs in a group of drugs that have the state flag value
   *          equal to <code>flagValue</code>
   */
  function getNumberOfDrugsWithSpecifiedStateFlagValueForDrugGroup(parentKey, flagValue, metadata): number {
    let noTerms = 0;
    const regexp = new RegExp('^' + escapeRegExp(parentKey) + '\\.');
    Object.keys(metadata).forEach(metaPath => {
      if (regexp.test(metaPath) == true && metadata[metaPath].state == flagValue) {
        noTerms++;
      }
    });
    return noTerms;
  }

  /**
   * Method that deletes all NDC keys in a specific drugs group
   * @param parentKey the key of the drugs group
   * @param metadata the metadata containing the keys
   */
  function deleteMetaKeysForGroup(parentKey, metadata): void {
    if (getNumberOfDrugPathsInMetaByGroupPath(parentKey, metadata) > 0) {
      const regexp = new RegExp('^' + escapeRegExp(parentKey));
      // for (metaPath in metadata) {
      Object.keys(metadata).forEach(metaPath => {
        if (regexp.test(metaPath) == true) {
          addOperationToUpdateQueue('delete', metaPath, metadata[metaPath]);
          delete metadata[metaPath];
        }
      });
    }
  }

  /**
   * Method that deletes all NDC keys in a specific drugs group, except for the parent key
   * @param parentKey the key of the drugs group
   * @param metadata the metadata containing the keys
   */
  function deleteMetaKeysForGroupExceptParentKey(parentKey, metadata): void {
    if (getNumberOfDrugPathsInMetaByGroupPath(parentKey, metadata) > 0) {
      const regexp = new RegExp('^' + escapeRegExp(parentKey) + '\\.');
      // for (metaPath in metadata) {
      Object.keys(metadata).forEach(metaPath => {
        if (regexp.test(metaPath) == true) {
          addOperationToUpdateQueue('delete', metaPath, metadata[metaPath]);
          delete metadata[metaPath];
        }
      });
    }
  }

  function manageStateForBasePath(basePath, metadata, scope, newState): void {
    resetChangedMetadataObject();
    deleteMetaKeysForGroup(basePath, metadata);

    if (newState.state === constants.DRUG_TERM_GROUPS_STATE_VALUES.LOCKED_STATE.state) {
      metadata[basePath] = getNewStateForMetadata(newState);
      addOperationToUpdateQueue('insert', basePath, metadata[basePath]);
    }
    triggerMetaChangedEvent(scope);
  }

  /**
   * Method that returns the metadata path for a specific terms group/section of a drugs group
   *
   * @param drug the drugs group
   * @param groupPath the path for terms group/section
   * @param withGroupScenario true/false flag that indicates with the group scenario should be taken into account
   *                          while looking for meta paths
   * @param withNDCScenario true/false flag that indicates with the NDC scenario should be taken into account
   *                          while looking for meta paths
   * @returns the path of specified <code>groupPath</code> corresponding to <code>drug group</code>
   */
  function getTermsGroupPathForDrugsGroup(drug, groupPath, withGroupScenario, withNDCScenario): any {
    let path = util.getPathFromArray(groupPath);
    if (drug.renderData.isGroup) {
      path = `${path}.${drug.renderData.groupName.trim()}`;
    } else {
      path = `${path}.${drug.name.trim()}`;
    }
    if (withGroupScenario) {
      if (drug.renderData.isGroup && drug.scenario && drug.scenario.id) {
        path = `${path}.${drug.scenario.id}`;
      } else if (drug.groupScenario && drug.groupScenario.id) {
        path = `${path}.${drug.groupScenario.id}`;
      }
    }
    if (withNDCScenario && !drug.renderData.isGroup) {
      if (drug.scenario && drug.scenario.id) {
        path = `${path}.${drug.ndc}.${drug.scenario.id}`;
      } else {
        path = `${path}.${drug.ndc}`;
      }
    }
    return path;
  }

  /**
   * Method that generates metadata for NDC scenarios.
   *
   * @param data - drug data
   */
  function generateMetadataForScenarios(data): void {
    const {
      drugGroup,
      groupScenarioForOriginalGroup,
      groupScenarioForCopyGroup,
      scenarioForCopyGroup,
      metadata,
      scope
    } = data;

    const metaPaths = getAllMetaPathsForGroup({
      drug: drugGroup,
      metadata: metadata,
      includeRootMeta: true
    });

    const drugName = (drugGroup.renderData && drugGroup.renderData.isGroup) ?
      drugGroup.renderData.groupName :
      drugGroup.name;

    let drugGroupNameForSplit = drugName;

    if (groupScenarioForOriginalGroup && groupScenarioForOriginalGroup.id) {
      drugGroupNameForSplit = `${drugGroupNameForSplit}.${groupScenarioForOriginalGroup.id}`;
    }

    Object.keys(metaPaths).forEach(mPath => {
      let splitNdc;
      let newMetaPathForCopyGroup;
      const splitMetaPath = mPath.split(drugGroupNameForSplit);

      if (splitMetaPath.length === 1) {
        return;
      }

      newMetaPathForCopyGroup = `${splitMetaPath[0]}${drugName}`;

      if (groupScenarioForOriginalGroup) {
        newMetaPathForCopyGroup = `${newMetaPathForCopyGroup}.${groupScenarioForCopyGroup.id}`;
      }

      if (splitMetaPath.length > 1 && splitMetaPath[1] !== '') {
        splitNdc = splitMetaPath[1].split(drugGroup.scenario.id);
        newMetaPathForCopyGroup = newMetaPathForCopyGroup + splitNdc[0] + scenarioForCopyGroup.id;
      }

      metadata[newMetaPathForCopyGroup] = metaPaths[mPath];
    });

    if (scope.user.isManufacturer) {
      constants.SET_LOCKS_ON_SCENARIO_CREATION_FOR_PHARMA.forEach((item: any) => {
        if (item.ndcLevel) {
          const path = String.Format(
            item.pattern,
            drugName + (groupScenarioForCopyGroup ? `.${groupScenarioForCopyGroup.id}` : ''),
            drugGroup.ndc + (scenarioForCopyGroup ? `.${scenarioForCopyGroup.id}` : '')
          );
          metadata[path] = angular.copy(constants.DRUG_TERM_GROUPS_STATE_VALUES[item.state]);
        }
      });
    }

    // unlock at ndc level
    if (constants.SET_UNLOCKS_ON_SCENARIO_CREATION) {
      constants.SET_UNLOCKS_ON_SCENARIO_CREATION.forEach(item => {
        const path = String.Format(
          `${item.pattern}.{0}.{1}`,
          drugName + (groupScenarioForCopyGroup ? `.${groupScenarioForCopyGroup.id}` : ''),
          drugGroup.ndc + (scenarioForCopyGroup ? `.${scenarioForCopyGroup.id}` : '')
        );
        if (angular.equals(metadata[path], constants.DRUG_TERM_GROUPS_STATE_VALUES.LOCKED_STATE)) {
          metadata[path] = angular.copy(constants.DRUG_TERM_GROUPS_STATE_VALUES.UNLOCKED_STATE);
        }
      });
    }
  }

  /**
   * Method that generates metadata for scenarios at group level. If the given drugGroup is not an actual drug group
   * (meaning drugGroup.renderData.isGroup == true) then this method simply returns.
   *
   * @param data - drug data
   */
  function generateMetadataForGroupScenarios(data): void {
    const drugGroup = data.drugGroup;
    const groupScenarioForOriginalGroup = data.groupScenarioForOriginalGroup;
    const groupScenarioForCopyGroup = data.groupScenarioForCopyGroup;
    const metadata = data.metadata;
    const scope = data.scope;

    if (!drugGroup.renderData ||
      !drugGroup.renderData.isGroup ||
      !groupScenarioForOriginalGroup ||
      !groupScenarioForOriginalGroup.id ||
      !groupScenarioForCopyGroup ||
      !groupScenarioForCopyGroup.id
    ) {
      return;
    }

    const metaPaths = getGroupLevelMetaPathsForGroup(drugGroup, metadata);
    const drugName = drugGroup.renderData.groupName;
    let drugGroupNameForSplit = drugName;

    if (drugGroup.scenario) {
      drugGroupNameForSplit = `${drugGroupNameForSplit}.${groupScenarioForOriginalGroup.id}`;
    }

    Object.keys(metaPaths).forEach(mPath => {
      const splitMetaPath = mPath.split(drugGroupNameForSplit);
      const newMetaPathForCopyGroup = `${splitMetaPath[0]}${drugName}.${groupScenarioForCopyGroup.id}`;

      metadata[newMetaPathForCopyGroup] = metaPaths[mPath];

      // unlock baseRebate and notes at group level
      if (constants.SET_UNLOCKS_ON_SCENARIO_CREATION) {
        constants.SET_UNLOCKS_ON_SCENARIO_CREATION.forEach(item => {
          if (mPath.indexOf(item.pattern) === 0) {
            metadata[newMetaPathForCopyGroup] = angular.copy(constants.DRUG_TERM_GROUPS_STATE_VALUES.UNLOCKED_STATE);
          }
        });
      }
    });

    if (scope.user.isManufacturer) {
      constants.SET_LOCKS_ON_SCENARIO_CREATION_FOR_PHARMA.forEach((item: any) => {
        const path = String.Format(item.pattern, drugName, groupScenarioForCopyGroup.id);
        metadata[path] = angular.copy(constants.DRUG_TERM_GROUPS_STATE_VALUES[item.state]);
      });
    }
  }

  /**
   * Method that deletes metadata for an NDC scenario of the given drug.
   * This method is called when an NDC scenario is deleted.
   *
   * @param drug - drug
   * @param metadata - the bids plan type metadata
   */
  function deleteMetadataForScenario(drug, metadata): void {
    const metaPaths = getAllMetaPathsForGroup({
      drug: drug,
      metadata: metadata
    });

    Object.keys(metaPaths).forEach(mPath => {
      delete metadata[mPath];
    });
  }

  /**
   * Method that deletes metadata for group level scenarios of the given drug group.
   * This method is called when a group level scenario is deleted.
   * Use this method solely for drug groups not for simple drugs.
   *
   * @param drugGroup - always a drug group, never should be called with a simple drug object
   * @param metadata - the plan type metadata
   */
  function deleteMetadataForGroupScenario(drugGroup, metadata): void {
    const metaPaths = getGroupLevelMetaPathsForGroup(drugGroup, metadata);

    Object.keys(metaPaths).forEach(mPath => {
      delete metadata[mPath];
    });
  }

  /**
   * Method that returns the last element from an array
   * @param arr - the array from where to get the last element
   * @returns string last element of the <code>arr</code>
   */
  function getLastElem(arr): any {
    return arr[arr.length - 1];
  }

  /**
   * Method that resets the changes for the metadata
   */
  function resetChangedMetadataObject(): void {
    changedMetadata = {
      from: 'meta',
      dataChanges: []
    };
  }

  /**
   * Method that adds an operation to the changed metadata object
   * @param operation the operation to be added
   * @param path the path of the metadata
   * @param flags the flags assigned to the <code>path</code>
   */
  function addOperationToUpdateQueue(operation, path, flags): void {
    changedMetadata.dataChanges.push({
      type: operation,
      path: [
        'meta'
      ],
      data: {
        path: path,
        flags: flags
      }
    });
  }

  /**
   * Method that triggers the "valueChanged" event which will be processed in biddetails.controller.js
   * @param scope the current scope
   * @param event - marker type of operation, used for copy/paste scenario locks
   */
  function triggerMetaChangedEvent(scope?, event?): void {
    if (CoreUtils.isDefined(scope)) {
      if (event) {
        changedMetadata.event = event;
      }
      scope.$broadcast('valueChanged', changedMetadata);
    } else {
      changedMetadata.dataChanges = [];
    }
  }

  return {
    processDrugTermsByGroupPath: processDrugTermsByGroupPath,
    manageGroupLevelStateForTermsGroup: manageGroupLevelStateForTermsGroup,
    manageNdcLevelStateForTermsGroup: manageNdcLevelStateForTermsGroup,
    getStateFlagValueFromMetadata: getStateFlagValueFromMetadata,
    generateMetadataForScenarios: generateMetadataForScenarios,
    generateMetadataForGroupScenarios: generateMetadataForGroupScenarios,
    deleteMetadataForScenario: deleteMetadataForScenario,
    deleteMetadataForGroupScenario: deleteMetadataForGroupScenario,
    manageStateForBasePath: manageStateForBasePath
  };

} ];
// tslint:disable-next-line:max-file-line-count
