import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, ValidationErrors } from '@angular/forms';
import { Range } from '@qv-common/entities';
import { FieldValidationMessage } from '@qv-common/enums';
import moment, { Moment } from 'moment';
import { DateUtils, StringUtils, ValidationUtils } from '@qv-common/utils';
import { QvCache } from '@qv-common/decorators';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { BaseRangeFilter } from '@qv-shared/classes';
import { appConfig } from '@qv-common/configs';
import isEqual from 'lodash.isequal';

@Component({
  selector: 'qv-date-range-filter',
  templateUrl: './date-range-filter.component.html',
  styleUrls: ['./date-range-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DateRangeFilterComponent extends BaseRangeFilter implements OnInit {

  public ngOnInit(): void {
    this.initForm();
    this.initFromFieldChangesHandler();
  }

  public onSubmit(): void {
    const changedRange = new Range<Moment>(
      this.rangeForm.get('from').value,
      this.rangeForm.get('to').value,
      this.rangeForm.invalid
    );
    super.onSubmit(changedRange);
  }

  public onDateChanged(event: MatDatepickerInputEvent<Moment>, controlName: string): void {
    const control = this.rangeForm.get(controlName) as FormControl;
    const convertedDate = DateUtils.convertDateToStr(event, control, appConfig.dateFormat);
    const date = moment(convertedDate, appConfig.dateFormat);
    const formattedDate = DateUtils.getFormattedDate(date, appConfig.dateFormat);

    if (formattedDate && formattedDate.isValid()) {
      control.setValue(DateRangeFilterComponent.getStartOrEndOfDate(controlName, formattedDate));
    } else if (StringUtils.isString(convertedDate)) {
      control.setErrors({ invalidFormat: true });
    }

    if (this.hideSubmitButton) {
      this.onSubmit();
    }
  }

  public setAppliedRange(appliedRange: Range<Moment>): void {
    appliedRange = appliedRange || new Range();

    if (!isEqual(appliedRange, this.range)) {
      this.range = appliedRange;
      this.setAppliedValues();
      this.rangeChanged.emit(this.range);
    }
  }

  @QvCache()
  public getValidationMessage(errors: ValidationErrors): string {
    if (!errors) return '';
    if (errors.invalidFormat) return FieldValidationMessage.DATE_FORMAT;
    if (errors.range) return FieldValidationMessage.RANGE_GTE;
  }

  private static getStartOrEndOfDate(controlName: string, formattedDate: Moment): Moment {
    return controlName === 'from' ? formattedDate.startOf('day') : formattedDate.endOf('day');
  }

  private initForm(): void {
    this.rangeForm = new FormGroup({
      from: new FormControl(
        this.range && this.range.from ? this.range.from : null,
        {
          updateOn: 'blur'
        }
      ),
      to: new FormControl(
        this.range && this.range.to ? this.range.to : null,
        {
          validators: [
            ValidationUtils.rangeGreaterOrEqualValidator('from', 'to')
          ],
          updateOn: 'blur'
        }
      )
    });
  }
}
