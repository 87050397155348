<qv-bid-toolbar
    (actionButtonClick)="handleToolbarAction($event)"
    *ngIf="!(isBidVersionHistoric() | async)"
    [bid]="getBid() | async"
></qv-bid-toolbar>

<qv-bid-revision-toolbar
    (actionButtonClick)="handleToolbarAction($event)"
    *ngIf="isBidVersionHistoric() | async"
    [bid]="getBid() | async"
></qv-bid-revision-toolbar>

<ng-content select="[sidebar-filter]"></ng-content>

<mat-sidenav-container class="sidenav-container">
  <mat-sidenav
      (opened)="onSidenavOpen()"
      [disableClose]="isBidInvalid$ | async"
      [mode]="'over'"
      [position]="'end'"
      class="sidenav">
    <qv-bid-details-sidebar
        *ngIf="sidenavWasOpened$ | async"
        [bid]="getBid() | async"
        [sideNav]="sidenav"
    ></qv-bid-details-sidebar>
    <qv-loading-ring *ngIf="!(sidenavWasOpened$ | async)"></qv-loading-ring>
  </mat-sidenav>

  <mat-sidenav-content class="sidenav-content">
    <ng-content></ng-content>
  </mat-sidenav-content>
</mat-sidenav-container>
