import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  Inject,
  OnInit
} from '@angular/core';
import { SvgIconName } from '@qv-common/enums';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  InstructionsModalData
} from '@qv-bid/components/shared/summary-panel/instructions-modal/models/instructions-modal-data';
import { HtmlEditorService } from '@qv-shared/services';
import { FormControl } from '@angular/forms';
import { constants, resources } from '@qv-common/static';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import isEqual from 'lodash.isequal';
import { filter } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { GeneralModalData, ModalService } from 'quantuvis-angular-common/modal';

@UntilDestroy()
@Component({
  selector: 'qv-instructions-modal',
  templateUrl: './instructions-modal.component.html',
  styleUrls: ['./instructions-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [HtmlEditorService]
})
export class InstructionsModalComponent implements OnInit {
  public readonly title = 'Overall RFP Instructions';
  public readonly svgIconName = SvgIconName;
  public control: FormControl;

  constructor(private dialogRef: MatDialogRef<InstructionsModalComponent>,
              @Inject(MAT_DIALOG_DATA) public modalData: InstructionsModalData,
              private htmlEditorService: HtmlEditorService,
              private changeDetectorRef: ChangeDetectorRef,
              private modalService: ModalService
  ) {}

  public ngOnInit(): void {
    this.initControl();
  }

  public onPrimaryAction(): void {
    if (this.isValueChanged()) {
      this.modalData.control.setValue(this.control.value);
    }

    this.closeModal();
  }

  public onSecondaryAction(): void {
    this.isValueChanged() ? this.openConfirmModal().subscribe(() => this.closeModal()) : this.closeModal();
  }

  @HostListener('window:resize')
  public onResize(): void {
    if (this.modalData.isEditMode) {
      const modalContentMaxHeight = window.innerHeight - 185;
      const modalContentPadding = 26;
      this.htmlEditorService.setMaxHeight(modalContentMaxHeight - modalContentPadding);
    }
  }

  private isValueChanged(): boolean {
    return !isEqual(this.control.value, this.modalData.control.value);
  }

  private closeModal(): void {
    this.dialogRef.close();
  }

  private initControl(): void {
    this.control = new FormControl(
      this.modalData.control.value,
      this.htmlEditorService.maxLengthWithTags(constants.INSTRUCTIONS_MAX_LENGTH)
    );

    this.control.valueChanges.pipe(untilDestroyed(this))
      .subscribe(() => this.changeDetectorRef.markForCheck());
  }

  private openConfirmModal(): Observable<boolean> {
    return this.modalService.openConfirmModal(new GeneralModalData(
      resources.GENERAL.CONFIRM_NAVIGATION_TITLE,
      'Are you sure you want to navigate out of the Instructions? All the changes will be lost.',
      resources.Actions.OK,
      resources.Actions.CANCEL
    )).pipe(filter((isConfirm: boolean) => isConfirm));
  }
}
