import { DictionaryItem } from 'quantuvis-angular-common/interfaces';

export enum MarketBasketTemplateType {
  COMPETITIVE = 1,
  ANALYTICAL = 2
}

export namespace MarketBasketTemplateType {
  const displayValues = new Map<MarketBasketTemplateType, DictionaryItem>([
    [MarketBasketTemplateType.COMPETITIVE, { id: MarketBasketTemplateType.COMPETITIVE, name: 'Competitive' }],
    [MarketBasketTemplateType.ANALYTICAL, { id: MarketBasketTemplateType.ANALYTICAL, name: 'Analytical' }],
  ]);

  export function getValue(id: MarketBasketTemplateType): DictionaryItem {
    return displayValues.get(id);
  }

  export function getValues(): DictionaryItem[] {
    return Array.from(displayValues.values());
  }
}
