import { Inject, Injectable } from '@angular/core';
import { Rebate } from '@qv-bid/entities';
import { HttpResponseWrapper } from '@qv-common/models/api';
import { ApiService } from '@qv-common/services/api';
import { JsonConvert } from 'json2typescript';
import { JSON_CONVERT_TOKEN } from 'quantuvis-angular-common/json-converter/services';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ViewPerspectiveService } from '@qv-common/services/auth';
import { HttpUtils } from '@qv-common/utils';
import { ApiUrlPrefix } from '@qv-common/enums';

@Injectable()
export class RebateDaoService {

  constructor(
    private apiService: ApiService,
    @Inject(JSON_CONVERT_TOKEN) private jsonConvert: JsonConvert,
    private viewPerspectiveService: ViewPerspectiveService
  ) { }

  public update(rebate: Rebate, scenarioId: number, isBidInternal: boolean, drugId?: number): Observable<Rebate> {
    const requestData = this.jsonConvert.serialize(rebate);
    let url = this.getRestUrl(scenarioId, drugId);

    const perspective = this.viewPerspectiveService.getViewPerspective();
    url = HttpUtils.appendPerspectiveQueryParamToUrlIfBidIsInternal(url, perspective, isBidInternal);

    return this.apiService.put(url, requestData)
      .pipe(
        map((response: HttpResponseWrapper<Rebate>) => this.jsonConvert.deserializeObject(response.body.data, Rebate))
      );
  }

  private getRestUrl(scenarioId: number, drugId?: number): string {
    if (drugId) {
      return `${ApiUrlPrefix.BIDS}/scenarios/${scenarioId}/drugs/${drugId}/rebate`;
    } else {
      return `${ApiUrlPrefix.BIDS}/scenarios/${scenarioId}/rebate`;
    }
  }
}
