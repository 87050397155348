import { Note } from '@qv-term/entities';
import { CoreUtils } from '@qv-common/utils';

export class NotesMetadata {
  public isAtNdcCurrent = false;
  public isAtNdcHistoric = false;
  private historic: Note[] = [];

  public get getHistoric(): Note[] {
    return this.historic;
  }

  public set getHistoric(notes: Note[]) {
    this.historic = notes;
    this.isAtNdcHistoric = false;
  }

  private current: Note = null;

  public get getCurrent(): Note {
    return this.current;
  }

  public set getCurrent(note: Note) {
    this.current = note;
    this.isAtNdcCurrent = false;
  }

  public isEmpty(): boolean {
    return this.isEmptyCurrent() && this.isEmptyHistoric();
  }

  public isEmptyHistoric(): boolean {
    return this.historic.length === 0 && !this.isAtNdcHistoric;
  }

  public isEmptyCurrent(): boolean {
    return !this.isAtNdcCurrent && (CoreUtils.isNull(this.getCurrent) || !this.getCurrent.value);
  }

  public getCurrentValue(): string {
    return this.getCurrent && this.getCurrent.value;
  }
}
