import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatTabGroup } from '@angular/material/tabs';
import { BaseView } from '@qv-bid/components/shared/base';
import { ContractedBusinessManageAction } from '@qv-bid/components/shared/cb-manage-modal/enums';
import { BID_VIEW_CONFIG, BidViewConfig } from '@qv-bid/configs/bid-view-config';
import { STANDARD_VIEW_CONFIG } from '@qv-bid/configs/standard-view-config';
import { bidViewProviders } from '@qv-bid/constants/bid-view-providers';
import { Scenario } from '@qv-bid/entities';
import { BidFilterName, DrugScenarioStatus, SectionFilter } from '@qv-bid/enums';
import { SectionFilterCheckboxes } from '@qv-bid/models';
import {
  BidDetailsNotificationService,
  BidFilterService,
  BidStateService,
  ContractedBusinessesService,
  ManageContractedBusinessesService,
  ScenarioConfigListService,
  SectionFiltersService,
  StandardDrugFilterService,
  VirtualScrollerService,
  BidLockService
} from '@qv-bid/services';
import { BidEventBusService } from '@qv-bid/services/bid-event-bus.service';
import { BidViewService } from '@qv-bid/services/bid-view.service';
import { BidSelectService } from '@qv-bid/services/selects';
import { QvCache } from '@qv-common/decorators';
import { SvgIconName } from '@qv-common/enums';
import { CoreUtils } from '@qv-common/utils';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { IPageInfo } from 'ngx-virtual-scroller';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';

import { ContractedBusiness, FieldsDropdownOptionsActions } from 'quantuvis-core-entities';
import { Store } from '@ngrx/store';

@UntilDestroy()
@Component({
  selector: 'qv-standard-view',
  templateUrl: './standard-view.component.html',
  styleUrls: ['./standard-view.component.scss'],
  providers: [
    ...bidViewProviders,
    { provide: BID_VIEW_CONFIG, useValue: STANDARD_VIEW_CONFIG },
    StandardDrugFilterService,
  ]
})
export class StandardViewComponent extends BaseView implements OnInit, OnDestroy {
  @ViewChild('tabGroup')
  public tabGroup: MatTabGroup;

  @ViewChild('virtualScroll', { read: ElementRef })
  public set virtualScrollRef(elementRef: ElementRef) {
    if (elementRef) {
      this.virtualScroll = elementRef;
    }
  }

  public selectedContractedBusinessUuid$ = new Subject<string>();
  public filter: SectionFilterCheckboxes;
  public isBidInvalid$: Observable<boolean>;

  public readonly svgIconName = SvgIconName;
  public bidVersionContractedBusinesses$ = new BehaviorSubject<ContractedBusiness[]>([]) ;

  constructor(
    private store: Store<any>,
    bidLockService: BidLockService,
    public bidStateService: BidStateService,
    private sectionFiltersService: SectionFiltersService,
    private bidDetailsNotificationService: BidDetailsNotificationService,
    private bidEventBusService: BidEventBusService,
    private bidFilterService: BidFilterService,
    virtualScrollerService: VirtualScrollerService,
    public scenarioConfigListService: ScenarioConfigListService,
    public bidViewService: BidViewService,
    private bidSelectService: BidSelectService,
    private contractedBusinessesService: ContractedBusinessesService,
    @Inject(BID_VIEW_CONFIG) public bidViewConfig: BidViewConfig,
    public manageCBService: ManageContractedBusinessesService,
  ) {
    super(bidViewService, scenarioConfigListService, virtualScrollerService);
  }

  public ngOnInit(): any {
    this.store.dispatch(FieldsDropdownOptionsActions.get());
    this.subscribeOnVirtuallScrollerSubjects();
    this.isBidInvalid$ = this.bidStateService.isBidInvalid().pipe(untilDestroyed(this));

    this.initLoadViewHandler();
    this.initContractedBusinessChangeHandler();
    this.initFilterHandler();
    this.initCbIndexChangeHandler();
  }

  public onScrollUpdate($event: Scenario[]): void {
    this.virtualScroller.viewPortItems = $event;
    this.updateScrollStartPosition();
  }

  public onScrollEnd($event: IPageInfo): void {
    this.bidViewService.viewPortDataUpdateHandler($event);
  }

  public loadContractedBusinesses(bidVersionId: number): void {
    this.contractedBusinessesService.loadCB(bidVersionId).subscribe(() => this.selectedIndexChangeHandler(0));
  }

  public selectedIndexChangeHandler(index: number): void {
    index = index < 0 ? 0 : index;
    this.contractedBusinessesService.contractedBusinesses$.pipe(take(1))
      .subscribe((contractedBusinesses: ContractedBusiness[]) => {
        this.scenarioConfigListService.scenarioConfigList.reset();
        this.bidViewService.firstViewportItemConfig.reset();
        this.bidStateService.scenarios$.next([]);
        this.selectedContractedBusinessUuid$.next(contractedBusinesses[index].uuid);
        this.bidStateService.cbId = contractedBusinesses[index].id;
        this.bidStateService.selectedContractedBusinessName = contractedBusinesses[index].name;
        this.bidSelectService.clear();
      });
  }

  public onSelectedIndexChange(index: number): void {
    this.bidViewService.isReady$.pipe(
      take(1),
      filter((isReady: boolean) => isReady),
      untilDestroyed(this)
    ).subscribe(() => this.selectedIndexChangeHandler(index));
  }

  public trackByScenarioId(index: number, scenario: Scenario): number {
    return scenario.id;
  }

  public createButtonClickHandler($event: MouseEvent): void {
    $event.stopPropagation();

    this.manageCBService.openModal(ContractedBusinessManageAction.CREATE)
      .pipe(take(1))
      .subscribe((cbList: ContractedBusiness[]) => this.onAddCB(cbList));
  }

  @QvCache()
  public isManagingCBAvailable(state: boolean, index: number, selectedIndex: number): boolean {
    return state && index === selectedIndex;
  }

  public onAddCB(cbList: ContractedBusiness[]): void {
    this.tabGroup.selectedIndex = cbList.length - 1;
    this.bidEventBusService.undoRedoEvent.emit();
  }

  public onDeleteCB(): void {
    if (this.tabGroup.selectedIndex !== 0) {
      this.tabGroup.selectedIndex--;
    }
    if (this.tabGroup.selectedIndex === 0 ) {
      this.selectedIndexChangeHandler(0);
    }
    this.bidEventBusService.undoRedoEvent.emit();
  }

  public onRenameCB(): void {
    this.bidEventBusService.undoRedoEvent.emit();
  }

  public onImportCB(): void {
    this.bidEventBusService.undoRedoEvent.emit();
    this.selectedIndexChangeHandler(this.tabGroup.selectedIndex);
  }

  public ngOnDestroy(): void {
    this.bidDetailsNotificationService.clear();
  }

  private initFilterHandler(): void {
    this.sectionFiltersService.sectionsFilters$
      .pipe(
        map((sectionsFilter: SectionFilterCheckboxes) => {
          const isDismissedFilterChanged = CoreUtils.isDefined(this.filter)
            && (this.filter[SectionFilter.DISMISSED].checked !== sectionsFilter[SectionFilter.DISMISSED].checked);

          if (isDismissedFilterChanged) {
            this.updateDismissedStatusFilter(sectionsFilter[SectionFilter.DISMISSED].checked);
          }

          this.filter = sectionsFilter;

          return isDismissedFilterChanged;
        }),
        filter((isDismissedFilterChanged: boolean) => isDismissedFilterChanged),
        switchMap(() => this.bidViewService.loadScenarios()),
        untilDestroyed(this)
      )
      .subscribe();
  }

  private updateDismissedStatusFilter(isChecked: boolean): void {
    if (isChecked) {
      this.bidFilterService.updateFilter(BidFilterName.drugStatus);
    } else {
      const notDismissedStatuses = [DrugScenarioStatus.OPEN, DrugScenarioStatus.ACCEPTED];
      this.bidFilterService.updateFilter(BidFilterName.drugStatus, notDismissedStatuses);
    }
  }

  private initContractedBusinessChangeHandler(): void {
    this.contractedBusinessesService.contractedBusinesses$.pipe(
      filter((cbs: ContractedBusiness[]) =>
        cbs.some((cb: ContractedBusiness) => cb.bidVersionId === this.bidStateService.bidVersionId)
      ),
      untilDestroyed(this)
    ).subscribe((cbs: ContractedBusiness[]) => this.bidVersionContractedBusinesses$.next(cbs));
  }

  private initCbIndexChangeHandler(): void {
    this.bidViewService.cbIndexChanged$.pipe(
      filter((index: number) => this.tabGroup.selectedIndex !== index),
      untilDestroyed(this)
    ).subscribe((index: number) => this.tabGroup.selectedIndex = index);
  }

  private initLoadViewHandler(): void {
    this.bidViewService.load().pipe(
      tap(() => this.loadContractedBusinesses(this.bidStateService.bidVersionId)),
      switchMap(() => this.selectedContractedBusinessUuid$),
      tap((uuid: string) => this.bidFilterService.updateFilter(BidFilterName.contractedBusiness, [uuid])),
      switchMap(() => this.bidViewService.loadScenarios()),
      untilDestroyed(this),
    ).subscribe();
  }
}
