import { JsonObject, JsonProperty } from 'json2typescript';
import { Moment } from 'moment';
import { BaseUser } from '@qv-user/entities';
import { MarketBasketDrug } from 'market-basket/market-basket-common';
import { MomentConverter } from 'quantuvis-angular-common/json-converter/serializers';

@JsonObject('MarketBasket')
export class MarketBasket {
  @JsonProperty('id', Number)
  public id: number = null;

  @JsonProperty('createdBy', BaseUser)
  public createdBy: BaseUser = null;

  @JsonProperty('name', String)
  public name: string = null;

  @JsonProperty('updatedAt', MomentConverter)
  public updatedAt: Moment = null;

  @JsonProperty('drugs', [MarketBasketDrug])
  public drugs: MarketBasketDrug[] = [];
}
