import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatPaginatorModule } from '@angular/material/paginator';
import { RouterModule } from '@angular/router';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import {
  MarketBasketAssignComponent,
  MarketBasketEditComponent,
  MarketBasketInfoComponent,
  MarketBasketModalComponent,
  MarketBasketPreviewComponent,
  MarketBasketTemplateInfoComponent,
  MarketBasketTemplateListComponent
} from '@qv-bid/components/market-basket';
import { BidEventBusService } from '@qv-bid/services/bid-event-bus.service';
import {
  AdminFeeDaoService,
  PaymentDaoService,
  PenaltyDaoService,
  PharmaAuditDaoService,
  TerminationClauseDaoService
} from '@qv-bid/services/dao/contract-terms';
import { SummaryService } from '@qv-bid/services/summary';
import {
  BidCommentsDaoService,
  BidDaoService,
  BidExportDaoService,
  BidFilterDaoService,
  BidLockDaoService,
  BidSignatureDaoService,
  BidVersionDaoService,
  ContractDaoService,
  ContractedBusinessDaoService,
  DrugDaoService,
  MarketBasketDaoService,
  MarketShareDaoService,
  PharmaRightDaoService,
  PriceProtectionDaoService,
  RebateDaoService,
  ScenarioDaoService,
  ScenarioDependencyDaoService,
  SummaryDaoService,
  UtilizationManagementDaoService
} from '@qv-bid/services/dao';
import { PdfViewerModule } from '@qv-pdf-viewer/pdf-viewer.module';
import { MarketBasketCommonModule } from 'market-basket/market-basket-common';
import { VirtualScrollerModule } from 'ngx-virtual-scroller';
import { OrderModule } from 'ngx-order-pipe';
import { NgxFileDropModule } from 'ngx-file-drop';
import {
  AdministrationFeeComponent,
  BidCommentFieldComponent,
  BidCommentsComponent,
  BidInfoComponent,
  BidLegalAttestationComponent,
  BidPharmaRightsComponent,
  BidSignatureComponent,
  BusinessAttachmentsComponent,
  ContractComponent,
  InstructionsComponent,
  InstructionsModalComponent,
  PaymentComponent,
  PenaltyComponent,
  PharmaAuditComponent,
  SummaryComponent,
  TableSectionRowComponent,
  TerminationClauseComponent,
  WebsiteLinksComponent,
} from '@qv-bid/components/shared/summary-panel';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { SharedModule } from '@qv-shared/shared.module';
import {
  BidDetailsNotificationService,
  BidListNotificationService,
  BidStateService,
  BidVersionService,
  BidViewStateService,
  DrugCopyPasteService,
  DrugService,
  IntendToRespondActionsService,
  NdcManagerService,
  ScenarioConfigListService,
  TermVisibilityService,
  VirtualScrollerService,
} from '@qv-bid/services';
import { TermModule } from '@qv-term/term.module';
import { ApiService } from '@qv-common/services/api';

import {
  ActionMenuComponent,
  BaseBidInfoComponent,
  BidBreadcrumbsComponent,
  BidDeclineModalComponent,
  BidDeleteDrugModalComponent,
  BidDetailsSidebarComponent,
  BidExtraToolsComponent,
  BidExtraToolsMenuComponent,
  BidRevisionComparisonToolbarComponent,
  BidRevisionToolbarComponent,
  BidSendBestAndFinalModalComponent,
  BidSendModalComponent,
  BidToolbarComponent,
  BidViewComponent,
  BidViewSwitcherComponent,
  ContractedBusinessImportModalComponent,
  ContractedBusinessManageModalComponent,
  DictionaryMenuComponent,
  DrugActionsMenuComponent,
  DrugAddModalComponent,
  DrugPasteValuesModalComponent,
  DrugsMenuComponent,
  DrugStatusComponent,
  FilterNotificationComponent,
  IntendToRespondComponent,
  InvalidContractDateModalComponent,
  ManageScenarioDependencyModalComponent,
  RangeOrUnitAndRebateComponent,
  RangeRebateInputComponent,
  ReviewDrugMenuComponent,
  RoleSwitcherComponent,
  ScenarioDependencyComponent,
  ScenarioNameComponent,
  ScenarioSummaryModalComponent,
  TermVisibilityMenuComponent,
  UpdateEditingRightsComponent,
  WacComponent
} from '@qv-bid/components/shared';
import { ScenarioDependencyModalComponent } from '@qv-bid/components/shared/scenario-dependency-modal/scenario-dependency-modal.component';
import {
  StandardHeaderComponent,
  StandardNdcHeaderComponent,
  StandardScenarioHeaderComponent,
} from '@qv-bid/components/standard-view/headers';
import {
  StandardContractedBusinessManageMenuComponent,
  StandardDrugAlertNotificationComponent,
  StandardDrugFilterComponent,
  StandardNdcComponent,
  StandardScenarioComponent,
  StandardScenarioPlaceholderComponent,
  StandardViewComponent,
} from '@qv-bid/components/standard-view';
import {
  StandardCellComponent,
  StandardMarketShareComponent,
  StandardPriceProtectionComponent,
  StandardRebateComponent,
  StandardScenarioSectionTitleComponent,
  StandardScenarioStateDropdownComponent,
  StandardScenarioTermsComponent,
  StandardUmComponent,
  StandardUmDetailsComponent,
} from '@qv-bid/components/standard-view/sections';
import { bidExistenceInterceptorProvider } from '@qv-bid/handlers/bid-existence.interceptor';
import { SnackBarModule } from 'quantuvis-angular-common/snack-bar';
import { StandardScenarioStateSelectComponent } from './components/standard-view/sections/standard-scenario-state-select/standard-scenario-state-select.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BlockUIModule } from 'ng-block-ui';
import { FileUploadModule } from 'primeng/fileupload';
import { TruncateModule } from 'ng2-truncate';
import { NotificationModule } from 'quantuvis-angular-common/notification';
import { BidListEventBusService } from '@qv-bid-list/services';
import { InternalFieldsModule } from '@qv-internal-fields/internal-fields.module';
import { LoiCommonModule } from '@qv-loi-common/loi-common.module';
import { AngularComponentsCommonModule } from 'angular-components-common';
import { lockVerificationFailedInterceptorProvider } from '@qv-bid/handlers/lock-verification-failed.interceptor';

@NgModule({
  imports: [
    AngularComponentsCommonModule,
    BlockUIModule,
    CommonModule,
    FileUploadModule,
    NgxFileDropModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCheckboxModule,
    MatDialogModule,
    MatIconModule,
    MatTooltipModule,
    MatMenuModule,
    MatSidenavModule,
    MatPaginatorModule,
    MatTabsModule,
    MatTableModule,
    MatSortModule,
    MatExpansionModule,
    MatProgressSpinnerModule,
    MatTreeModule,
    MatSortModule,
    MatDividerModule,
    MatFormFieldModule,
    MatDatepickerModule,
    OrderModule,
    SharedModule,
    TermModule,
    MatSelectModule,
    VirtualScrollerModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    MatRadioModule,
    MatSnackBarModule,
    MarketBasketCommonModule,
    TruncateModule,
    RouterModule,
    InternalFieldsModule,
    NotificationModule,
    SnackBarModule,
    NgbPopoverModule,
    MatCardModule,
    PdfViewerModule,
    LoiCommonModule
  ],
  declarations: [
    BaseBidInfoComponent,
    BidToolbarComponent,
    BidBreadcrumbsComponent,
    DictionaryMenuComponent,
    DrugAddModalComponent,
    TermVisibilityMenuComponent,
    BidExtraToolsComponent,
    RoleSwitcherComponent,
    BidExtraToolsMenuComponent,
    BidRevisionToolbarComponent,
    BidRevisionComparisonToolbarComponent,
    BidViewSwitcherComponent,
    SummaryComponent,
    ContractComponent,
    ContractedBusinessManageModalComponent,
    ContractedBusinessImportModalComponent,
    WebsiteLinksComponent,
    BidLegalAttestationComponent,
    BidInfoComponent,
    BusinessAttachmentsComponent,
    BidSignatureComponent,
    BidCommentsComponent,
    BidCommentFieldComponent,
    BidPharmaRightsComponent,
    InstructionsComponent,
    PaymentComponent,
    PharmaAuditComponent,
    PenaltyComponent,
    TerminationClauseComponent,
    AdministrationFeeComponent,
    ScenarioNameComponent,
    ScenarioDependencyComponent,
    WacComponent,
    ScenarioDependencyModalComponent,
    StandardViewComponent,
    BidViewComponent,
    TableSectionRowComponent,
    BidDetailsSidebarComponent,
    StandardContractedBusinessManageMenuComponent,
    StandardHeaderComponent,
    StandardScenarioComponent,
    StandardNdcComponent,
    StandardScenarioHeaderComponent,
    StandardNdcHeaderComponent,
    StandardScenarioTermsComponent,
    StandardRebateComponent,
    StandardUmComponent,
    StandardMarketShareComponent,
    StandardPriceProtectionComponent,
    StandardCellComponent,
    StandardMarketShareComponent,
    StandardScenarioSectionTitleComponent,
    StandardDrugFilterComponent,
    RangeOrUnitAndRebateComponent,
    StandardUmDetailsComponent,
    DrugActionsMenuComponent,
    DrugStatusComponent,
    StandardScenarioStateSelectComponent,
    StandardScenarioPlaceholderComponent,
    RangeRebateInputComponent,
    StandardScenarioStateDropdownComponent,
    StandardDrugAlertNotificationComponent,
    IntendToRespondComponent,
    InvalidContractDateModalComponent,
    BidSendModalComponent,
    BidDeclineModalComponent,
    BidSendBestAndFinalModalComponent,
    BidDeleteDrugModalComponent,
    DrugPasteValuesModalComponent,
    ManageScenarioDependencyModalComponent,
    UpdateEditingRightsComponent,
    FilterNotificationComponent,
    ReviewDrugMenuComponent,
    DrugsMenuComponent,
    ActionMenuComponent,
    InstructionsModalComponent,
    ScenarioSummaryModalComponent,
    MarketBasketModalComponent,
    MarketBasketPreviewComponent,
    MarketBasketAssignComponent,
    MarketBasketTemplateListComponent,
    MarketBasketEditComponent,
    MarketBasketInfoComponent,
    MarketBasketTemplateInfoComponent,
  ],
  exports: [
    BaseBidInfoComponent,
    BidToolbarComponent,
    BidRevisionToolbarComponent,
    BidRevisionComparisonToolbarComponent,
    BidBreadcrumbsComponent,
    BidViewComponent,
    ContractedBusinessManageModalComponent,
    DrugAddModalComponent,
    DrugActionsMenuComponent,
    BidExtraToolsComponent,
    BidExtraToolsMenuComponent,
    RoleSwitcherComponent,
    StandardScenarioComponent,
    BidSendModalComponent,
    BidDeclineModalComponent,
    BidSendBestAndFinalModalComponent,
    DrugPasteValuesModalComponent,
    StandardContractedBusinessManageMenuComponent,
    ScenarioDependencyComponent,
    ScenarioNameComponent,
    WacComponent,
    DrugStatusComponent,
    RangeOrUnitAndRebateComponent,
    DictionaryMenuComponent,
    ReviewDrugMenuComponent,
    DrugsMenuComponent,
    ActionMenuComponent,
  ],
  providers: [
    bidExistenceInterceptorProvider,
    lockVerificationFailedInterceptorProvider,
    BidExportDaoService,
    BidDaoService,
    BidSignatureDaoService,
    BidVersionDaoService,
    BidCommentsDaoService,
    BidFilterDaoService,
    BidVersionService,
    BidViewStateService,
    BidDetailsNotificationService,
    BidListNotificationService,
    SummaryDaoService,
    ScenarioDaoService,
    RebateDaoService,
    DrugCopyPasteService,
    DrugDaoService,
    DrugService,
    PharmaRightDaoService,
    ContractDaoService,
    ContractedBusinessDaoService,
    ApiService,
    VirtualScrollerService,
    PaymentDaoService,
    PenaltyDaoService,
    PharmaAuditDaoService,
    NdcManagerService,
    TerminationClauseDaoService,
    AdminFeeDaoService,
    BidEventBusService,
    BidListEventBusService,
    IntendToRespondActionsService,
    MarketShareDaoService,
    PriceProtectionDaoService,
    ScenarioDependencyDaoService,
    UtilizationManagementDaoService,
    BidLockDaoService,
    MarketBasketDaoService,

    // Note: Will be replaced in StandardView/GridView
    BidStateService,
    ScenarioConfigListService,
    SummaryService,
    TermVisibilityService,
  ],
})
export class BidModule {
}
