import { Observable, of } from 'rxjs';

export class MenuActionItem {
  constructor(
    public title: string,
    public handler: () => void,
    public isDisabled$: Observable<boolean> = of(false),
    public isAvailable$: Observable<boolean> = of(true),
  ) {}
}
