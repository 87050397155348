import { DrugScenarioStatus } from '@qv-bid/enums';
import { DrugScenarioStatusConverter } from '@qv-bid/serializers';
import { Drug } from './drug.entity';

import { JsonObject, JsonProperty } from 'json2typescript';
import { BidUtils } from '@qv-bid/utils';
import { MarketBasket } from '@qv-bid/entities/market-basket.entity';

@JsonObject('Scenario')
export class Scenario {
  @JsonProperty('id', Number)
  public id: number = null;

  @JsonProperty('cbId', Number)
  public cbId: number = null;

  @JsonProperty('cbName', String)
  public cbName: string = null;

  @JsonProperty('drug', Drug, false)
  public drug: Drug = null;

  @JsonProperty('drugName', String)
  public drugName: string = null;

  @JsonProperty('status', DrugScenarioStatusConverter)
  public status = DrugScenarioStatus.OPEN;

  @JsonProperty('isDismissed', Boolean)
  public isDismissed: boolean = null;

  @JsonProperty('marketBasket', MarketBasket)
  public marketBasket: MarketBasket = null;

  @JsonProperty('marketBasketLock', Boolean)
  public marketBasketLock: boolean = null;

  @JsonProperty('name', String)
  public name: string = null;

  @JsonProperty('dependencyId', Number)
  public dependencyId: number = null;

  @JsonProperty('dependencyIndex', Number)
  public dependencyIndex: number = null;

  @JsonProperty('uuid', String)
  public uuid: string = null;

  public get fullName(): string {
    return `${this.drugName} ${this.name}`;
  }

  public isDrugDismissed(): boolean {
    return this.drug ? BidUtils.isDismissed(this.drug.status) : this.isDismissed;
  }

  public isInCb(cbId: number): boolean {
    return this.cbId === cbId;
  }
}
