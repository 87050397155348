import { Injectable } from '@angular/core';
import { combineLatest, merge, Observable } from 'rxjs';
import { Scenario } from '@qv-bid/entities';
import { BidSelectService } from '@qv-bid/services/selects/bid-select.service';
import { SelectedDrug } from '@qv-bid/models';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { QvCache } from '@qv-common/decorators';
import { DrugScenarioStatus } from '@qv-bid/enums';
import isEqual from 'lodash.isequal';

@Injectable()
export class DrugSelectUtilsService {
  constructor(private bidSelectService: BidSelectService) {}

  public getSelectedScenarios(scenarios: Scenario[]): Scenario[] {
    return scenarios.filter((scenario: Scenario) =>
      this.bidSelectService.getSelectedScenarioIds().includes(scenario.id));
  }

  public isSomeScenarioSelected(): Observable<boolean> {
    return this.bidSelectService.scenarioSelected().pipe(map((scenarioIds: number[]) => scenarioIds.length > 0));
  }

  public isSelectedScenarioOrNdcInEditMode(activeDrugInEditMode: number): boolean {
    return this.bidSelectService.getSelectedNdcIds().includes(activeDrugInEditMode)
      || this.bidSelectService.getSelectedScenarioIds().includes(activeDrugInEditMode);
  }

  public getSelectedScenarioCount(): number {
    return this.bidSelectService.getSelectedScenarioIds().length;
  }

  public getSelectedNdcCount(): number {
    return this.bidSelectService.getSelectedNdcIds().length;
  }

  public isOneScenarioSelected(): boolean {
    return this.getSelectedScenarioCount() === 1 && this.bidSelectService.selectedDrugs
      .filter((drug: SelectedDrug) => this.bidSelectService.getSelectedScenarioIds()[0] !== drug.scenarioId)
      .every((drug: SelectedDrug) => !drug.ndcIds.length);
  }

  public isOneNdcSelected(): boolean {
    return this.getSelectedNdcCount() === 1
      && (this.getSelectedScenarioCount() === 0 || this.isOneScenarioSelected());
  }

  public getScenarioIdByNdcId(ndcId: number): number {
    return this.bidSelectService.selectedDrugs.find((drug: SelectedDrug) => drug.ndcIds.includes(ndcId)).scenarioId;
  }

  public getScenariosIdsWithSelectedNdcs(): number[] {
    let selectedScenarios = new Set();

    this.bidSelectService.selectedDrugs.filter((drug: SelectedDrug) => Boolean(drug.ndcIds.length))
      .forEach((drug: SelectedDrug) => selectedScenarios.add(drug.scenarioId));
    this.bidSelectService.scenarioSelection.selected
      .forEach((drug: number) => selectedScenarios = selectedScenarios.add(drug));

    return Array.from(selectedScenarios) as number[];
  }

  public isDrugSelected(): Observable<boolean> {
    return combineLatest([
      this.bidSelectService.scenarioSelected().pipe(
        map((scenarioSelected: number[]) => Boolean(scenarioSelected.length))
      ),
      this.bidSelectService.ndcSelected().pipe(map((ndcSelected: number[]) => Boolean(ndcSelected.length)))
    ]).pipe(map(([isScenarioSelected, isNdcSelected]: [boolean, boolean]) => !(isNdcSelected || isScenarioSelected)));
  }

  public isOneScenarioOrOneNdcSelected(): Observable<boolean> {
    return merge(
      this.bidSelectService.scenarioSelected(),
      this.bidSelectService.ndcSelected()
    ).pipe(
      map(() => !(this.isOneScenarioSelected() || this.isOneNdcSelected())),
      distinctUntilChanged()
    );
  }

  @QvCache()
  public isScenarioCheckboxDisabled(isReviewMode: boolean, isBidInvalid: boolean, status: DrugScenarioStatus): boolean {
    return isReviewMode && status === DrugScenarioStatus.DISMISSED || isBidInvalid;
  }

  @QvCache()
  public isNdcCheckboxDisabled(isReviewMode: boolean, isBidInvalid: boolean): boolean {
    return isReviewMode || isBidInvalid;
  }

  public allNdcForScenarioSelected(scenarioId: number, allNdcForScenario: number[]): boolean {
    const selectedScenario = this.bidSelectService.selectedDrugs
      .find((selectedDrug: SelectedDrug) => selectedDrug.scenarioId === scenarioId);

    return selectedScenario ? isEqual(selectedScenario.ndcIds.sort(), allNdcForScenario.sort()) : false;
  }
}
