export enum AppRoute {
  AUTH = 'auth',
  ACCESS_DENIED = 'access-denied',
  BIDS = 'bids',
  BID_HISTORY = 'bid-history',
  CREATE_BID = 'create-bid',
  GRID = 'grid',
  INTERNAL_FIELDS = 'internal-fields',
  LOGOUT = 'logout',
  LOI = 'loi',
  MARKET_BASKET_TEMPLATES = 'market-basket-templates',
}
