import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  OnInit,
  QueryList,
  ViewChildren
} from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { MatMenuTrigger } from '@angular/material/menu';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { resources } from '@qv-common/static/resources';
import { NavigationMainService } from '@qv-header/services/navigation-main.service';
import { WindowUtils } from '@qv-common/utils';
import { NotificationService } from 'quantuvis-angular-common/notification';
import { EnrollmentService } from '@qv-common/services';
import { catchError, filter, finalize } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { InsightsDaoService } from '@qv-insights/services/dao';
import { QvCache } from '@qv-common/decorators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CompanyInsightsConfig, InsightDashboard } from '@qv-insights/entities';
import { EnrollmentId } from 'quantuvis-core-entities';

@UntilDestroy()
@Component({
  selector: 'qv-navigation-main',
  templateUrl: './navigation-main.component.html',
  styleUrls: ['./navigation-main.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavigationMainComponent implements OnInit {
  public featureConstants: Map<string, string>;
  public standardDashboards$ = new BehaviorSubject<InsightDashboard[]>([]);
  public customDashboards$ = new BehaviorSubject<InsightDashboard[]>([]);
  public isStandardDashboardsLoading = false;
  public isCustomDashboardsLoading = false;

  public readonly tooltips = resources.TOOLTIPS.MAIN_MENU;
  private readonly drugSearchChildWindowUrls = [
    'standard/bid',
    'grid/bid',
    'createBid',
    'createInternalBid',
    'copy-bid'
  ];

  @ViewChildren(MatMenuTrigger)
  private menuBtnList: QueryList<MatMenuTrigger> = new QueryList();

  constructor(
    protected navigationService: NavigationMainService,
    public router: Router,
    protected enrollmentService: EnrollmentService,
    protected insightsDaoService: InsightsDaoService,
    protected notificationService: NotificationService,
    protected changeDetectorRef: ChangeDetectorRef
  ) {
    this.featureConstants = this.navigationService.getFeatureConstants;
  }

  public ngOnInit(): void {
    this.initRouterChangeHandler();
  }

  @HostListener('window:resize')
  public onResize(): void {
    this.closeAllOpenedMenus();
  }

  public isAllowed(name: string): boolean {
    return this.navigationService.isAllowed(name);
  }

  public isFeatureAllowed(name: string): boolean {
    return this.navigationService.isFeatureAllowed(name);
  }

  public isLegalAttestationSupported(): boolean {
    return this.navigationService.isUserCompanyBindingBidEnabled();
  }

  public isLoiSupported(): boolean {
    return this.navigationService.isUserCompanyCoverLetterEnabled();
  }

  public isInsightsSupported(): boolean {
    return this.enrollmentService.isEnrollmentInList(EnrollmentId.RN_IQ);
  }

  public openHelpPage(): void {
    window.open(this.navigationService.getHelpUrl(), '_blank');
  }

  public openDrugSearch(): void {
    if (this.drugSearchChildWindowUrls.some((url: string) => this.router.isActive(url, false))) {
      WindowUtils.openChildWindow('/drugSearch', 'Drug Search', 0.7, 0.6);
    } else {
      this.router.navigate(['drugSearch'], { queryParams: { noHeader: false } });
    }
  }

  public loadDashboards(): void {
    this.isStandardDashboardsLoading = true;
    this.isCustomDashboardsLoading = true;
    this.standardDashboards$.next([]);
    this.customDashboards$.next([]);

    this.insightsDaoService.getCompanyInsightsConfig()
      .pipe(catchError((error: HttpErrorResponse) => {
        this.isStandardDashboardsLoading = false;
        this.isCustomDashboardsLoading = false;

        return this.notificationService.showServerError(error);
      }))
      .subscribe((config: CompanyInsightsConfig) => {
        this.loadStandardDashboards(config.standardReportsFolderId);
        this.loadCustomDashboards(config.customReportsFolderId);
      });
  }

  @QvCache()
  public isDashboardsDisabled(dashboardsLength: number, isDashboardsLoading: boolean): boolean {
    return dashboardsLength === 0 && !isDashboardsLoading;
  }

  @QvCache()
  public getDynamicMenuItemClass(isSpinnerDisplayed: boolean): string {
    return isSpinnerDisplayed ? 'submenu-trigger-spinner' : 'submenu-trigger-arrow';
  }

  @QvCache()
  public isUrlActive(currentUrl: string, url: string): boolean {
    return currentUrl.includes(url);
  }

  public redirectToInsightsDashboard(dashboardId: string): void {
    this.router.navigate(['insights-dashboard'], { queryParams: { dashboardId } });
  }

  private loadStandardDashboards(folderId: string): void {
    this.insightsDaoService.getDashboards(folderId)
      .pipe(
        catchError((error: HttpErrorResponse) => this.notificationService.showServerError(error)),
        finalize(() => this.isStandardDashboardsLoading = false)
      )
      .subscribe((dashboards: InsightDashboard[]) => this.standardDashboards$.next(dashboards));
  }

  private loadCustomDashboards(folderId: string): void {
    this.insightsDaoService.getDashboards(folderId)
      .pipe(
        catchError((error: HttpErrorResponse) => this.notificationService.showServerError(error)),
        finalize(() => this.isCustomDashboardsLoading = false),
      )
      .subscribe((dashboards: InsightDashboard[]) => this.customDashboards$.next(dashboards));
  }

  private closeAllOpenedMenus(): void {
    this.menuBtnList.forEach((menuItem) => {
      if (menuItem.menuOpen) {
        menuItem.closeMenu();
      }
    });
  }

  private initRouterChangeHandler(): void {
    this.router.events.pipe(
      untilDestroyed(this),
      filter((event: RouterEvent) => event instanceof NavigationEnd),
    ).subscribe(() => this.changeDetectorRef.markForCheck());
  }
}
