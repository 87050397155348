import { Injectable } from '@angular/core';
import { HttpStatusCode } from 'quantuvis-angular-common/api';
import { Observable, OperatorFunction, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { NotificationService } from 'quantuvis-angular-common/notification';

@Injectable({
  providedIn: 'root'
})
export class ErrorNotificationService {
  constructor(private notificationService: NotificationService) { }

  public catchServerError<T>(message?: string): OperatorFunction<T, T> {
    return catchError((error: HttpErrorResponse) => {
      this.notificationService.showServerError(error, message);

      return throwError(error);
    });
  }

  public catchErrorExceptOfStatus(
    error: HttpErrorResponse, status: HttpStatusCode, message?: string
  ): Observable<HttpErrorResponse> {
    if (error.status !== status) {
      return this.notificationService.showServerError(error, message);
    }

    return throwError(error);
  }
}
