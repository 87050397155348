import { Component, ChangeDetectionStrategy, AfterViewInit } from '@angular/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter, finalize, tap } from 'rxjs/operators';

import { TemplateModalComponent } from '../template-modal/template-modal.component';
import { BaseInsideFormModal } from '../../models/base/base-inside-form-modal';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { BehaviorSubject, combineLatest } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'qac-template-form-modal',
  templateUrl: './template-form-modal.component.html',
  styleUrls: ['./template-form-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TemplateFormModalComponent extends TemplateModalComponent implements AfterViewInit {

  @BlockUI('template-form-modal')
  public blockUI: NgBlockUI;

  public isInfoMessageCheckboxChecked$: BehaviorSubject<boolean | null> = new BehaviorSubject<boolean | null>(null);
  public isActionButtonDisabled: boolean;
  public isInfoMessageCheckboxShown: boolean;

  public nestedCmp: BaseInsideFormModal;

  public ngAfterViewInit(): void {
    super.ngAfterViewInit();

    this.initHandlerToResetCheckboxStateWhenInfoMessageCheckboxHidden();
    this.updatePrimaryActionDisableState();
  }

  public onPrimaryAction(): void {
    this.blockUI.start();
    this.onSubmitHandler();
  }

  private onSubmitHandler(): void {
    this.nestedCmp.onSubmit().pipe(
      untilDestroyed(this),
      finalize(() => this.blockUI.stop())
    ).subscribe((data: any) => {
      this.close();
      this.primaryAction.emit(data);
    });
  }

  public onCheckBoxClick(change: MatCheckboxChange) {
    if (this.isInfoMessageCheckboxShown) {
      this.isInfoMessageCheckboxChecked$.next(change.checked);
    }
  }

  private initHandlerToResetCheckboxStateWhenInfoMessageCheckboxHidden(): void {
    this.nestedCmp?.isInfoMessageCheckboxShown$
      .pipe(
        filter((state: boolean) => this.isValueDefined(state)),
        tap((state: boolean) => this.isInfoMessageCheckboxShown = state),
        filter((state: boolean) => !state),
        untilDestroyed(this),
      )
      .subscribe(() => this.isInfoMessageCheckboxChecked$.next(null));
  }

  private updatePrimaryActionDisableState(): void {
    combineLatest([
      this.isInfoMessageCheckboxChecked$,
      this.nestedCmp?.infoMessageCheckboxDependencyState$,
      this.nestedCmp?.isInfoMessageCheckboxShown$,
    ])
      .pipe(
        tap((
          [isInfoMessageCheckboxState, infoMessageCheckboxDependencyState, isInfoMessageCheckboxShown ]:
            [boolean, boolean, boolean]) =>
          this.updateStateWhenInfoMessagePropertiesSet(isInfoMessageCheckboxState, infoMessageCheckboxDependencyState, isInfoMessageCheckboxShown)
        ),
        filter(([isInfoMessageCheckboxState, infoMessageCheckboxDependencyState, isInfoMessageCheckboxShown ]:
                  [boolean, boolean, boolean]) =>
          this.isValueDefined(isInfoMessageCheckboxState)
          && this.isValueDefined(infoMessageCheckboxDependencyState)
          && this.isValueDefined(isInfoMessageCheckboxShown)
        ),
        untilDestroyed(this),
      )
      .subscribe(([isInfoMessageCheckboxState, infoMessageCheckboxDependencyState ]:
                    [boolean, boolean, boolean]) =>
        this.isActionButtonDisabled = isInfoMessageCheckboxState !== infoMessageCheckboxDependencyState);
  }

  private updateStateWhenInfoMessagePropertiesSet(
    isInfoMessageCheckboxState: boolean | null,
    infoMessageCheckboxDependencyState: boolean | null,
    isInfoMessageCheckboxShown: boolean | null
  ): void {
    this.isActionButtonDisabled = isInfoMessageCheckboxShown &&
      this.isSomeOfTheInfoMessagePropertyChanged(isInfoMessageCheckboxState, infoMessageCheckboxDependencyState);
  }

  private isSomeOfTheInfoMessagePropertyChanged(
    isInfoMessageCheckboxState: boolean | null,
    infoMessageCheckboxDependencyState: boolean | null
  ): boolean {
    return (this.isValueDefined(infoMessageCheckboxDependencyState) && infoMessageCheckboxDependencyState)
      || (!this.isValueDefined(infoMessageCheckboxDependencyState) && !isInfoMessageCheckboxState);
  }

  private isValueDefined<T>(value: null | undefined | T): value is T {
    return value !== null && value !== undefined;
  }
}
