import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { FeedbackDaoService } from '@qv-terms-and-conditions/services';
import { BaseInsideFormModal } from 'quantuvis-angular-common/modal';

@Component({
  selector: 'qv-report-feedback',
  templateUrl: './report-feedback.component.html',
  styleUrls: ['../styles/report-modal.scss', './report-feedback.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReportFeedbackComponent extends BaseInsideFormModal {
  public readonly hasAsyncAction = true;
  public readonly feedbackParameters = {
    rebateEligible: 'Should be Rebate Eligible',
    incorrect: 'Drug Information is Incorrect',
    notUnderCorrect: 'Is Not Under The Correct Pharma',
  };

  public readonly form = new FormGroup({
    rebateEligible: new FormControl(),
    incorrect: new FormControl(),
    notUnderCorrect: new FormControl(),
    feedbackMessage: new FormControl('', {
      validators: [
        Validators.required.bind(this),
      ]
    }),
  });

  constructor(
    private readonly feedbackDaoService: FeedbackDaoService,
  ) {
    super();
  }

  public onSubmit(): Observable<any> {
    const data = {
      drug: `${this.payloadData.drug.name} ${this.payloadData.drug.ndc}`,
      feedback: this.form.get('feedbackMessage').value,
      rebateEligible: this.form.get('rebateEligible').value ? this.feedbackParameters.rebateEligible : null,
      incorrectInfo: this.form.get('incorrect').value ? this.feedbackParameters.incorrect : null,
      notUnderCorrectPharma: this.form.get('notUnderCorrect').value ? this.feedbackParameters.notUnderCorrect : null,
    };

    return this.feedbackDaoService.sendDrugFeedback(data);
  }

  public isShowError(): boolean {
    return this.form.get('feedbackMessage').invalid && this.form.get('feedbackMessage').dirty;
  }
}
