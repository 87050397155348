import { DrugAlertNotification } from '@qv-bid/models';

export enum DrugAlertType {
  INCOMPLETE_VALUES = 1,
  START_END_DATE_DOESNT_MATCH = 2
}

export namespace DrugAlertType {
  const scenarioDisplayValues = new Map<DrugAlertType, DrugAlertNotification>([
    [DrugAlertType.INCOMPLETE_VALUES, {
      id: DrugAlertType.INCOMPLETE_VALUES,
      title: 'This Drug or its NDCs have incomplete values'
    }],
    [DrugAlertType.START_END_DATE_DOESNT_MATCH, {
      id: DrugAlertType.START_END_DATE_DOESNT_MATCH,
      title: 'Scenario Start Date/End Date doesn’t match Contract Start Date/End Date'
    }]
  ]);

  const ndcDisplayValues = new Map<DrugAlertType, DrugAlertNotification>([
    [DrugAlertType.INCOMPLETE_VALUES, { id: DrugAlertType.INCOMPLETE_VALUES, title: 'This NDC has incomplete values' }],
    [DrugAlertType.START_END_DATE_DOESNT_MATCH, {
      id: DrugAlertType.START_END_DATE_DOESNT_MATCH,
      title: 'Scenario Start Date/End Date doesn’t match Contract Start Date/End Date'
    }]
  ]);

  export function displayValueForScenario(drugAlertType: DrugAlertType): DrugAlertNotification {
    return scenarioDisplayValues.get(drugAlertType);
  }

  export function displayValueForNdc(drugAlertType: DrugAlertType): DrugAlertNotification {
    return ndcDisplayValues.get(drugAlertType);
  }
}
