<div [matMenuTriggerFor]="profileMenu" class="profile-item">
  <mat-icon [svgIcon]="svgIconName.USER" class="profile-item__icon"></mat-icon>
</div>

<mat-menu #profileMenu="matMenu" [overlapTrigger]="false">
  <div class="user-name">{{ userFullName }}</div>
  <mat-divider class="profile-divider"></mat-divider>
  <button [matTooltip]="tooltips.PROFILE_SETTINGS" mat-menu-item ng-if="isAllowed('profile')"
          routerLink="/profileSettings">
    Edit Profile Settings
  </button>
  <mat-divider class="profile-divider"></mat-divider>

  <button (click)="openHelpPage()" [matTooltip]="tooltips.HELP" mat-menu-item>
    Help
  </button>

  <button (click)="openDeviceActivityPage()" [matTooltip]="tooltips.DEVICE_ACTIVITY" mat-menu-item>
    Device Activity
  </button>

  <button (click)="openFeedbackModal()" *ngIf="isAllowed('feedback')" [matTooltip]="tooltips.CONTACT_QUANTUVIS"
          mat-menu-item>
    Contact Quantuvis
  </button>

  <mat-divider *ngIf="!isAdmin" class="profile-divider"></mat-divider>

  <button (click)="openTermOfServiceModal()" *ngIf="!isAdmin" mat-menu-item>Terms of Use</button>
  <button (click)="openPrivacyPolicyModal()" *ngIf="!isAdmin" mat-menu-item>Privacy Policy</button>

  <mat-divider class="profile-divider"></mat-divider>

  <button mat-menu-item routerLink="/logout">Logout</button>
</mat-menu>
