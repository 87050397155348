import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditUserWrapperDirective } from './directives/edit-user-wrapper.directive';
import { Ng1WrapperRoutingModule } from './ng1-wrapper.routing';
import { DashboardWrapperDirective } from './directives/dashboard-wrapper.directive';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { CreateBidWrapperDirective } from './directives/create-bid-wrapper.directive';
import { CreateBidComponent } from './components/create-bid/create-bid.component';
import { CopyBidComponent } from './components/copy-bid/copy-bid.component';
import { CopyBidWrapperDirective } from './directives/copy-bid-wrapper.directive';
import { NotificationsWrapperDirective } from './directives/notifications-wrapper.directive';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { EnterpriseWrapperDirective } from './directives/enterprise-wrapper.directive';
import { EnterpriseComponent } from './components/enterprise/enterprise.component';
import { ProfileWrapperDirective } from './directives/profile-wrapper.directive';
import { ProfileComponent } from './components/profile/profile.component';
import { DrugSearchWrapperDirective } from './directives/drug-search-wrapper.directive';
import { DrugSearchComponent } from './components/drug-search/drug-search.component';
import { RevisionComparisonWrapperDirective } from './directives/revision-comparison-wrapper.directive';
import { RevisionComparisonComponent } from './components/revision-comparison/revision-comparison.component';
import { CreateInternalBidWrapperDirective } from './directives/create-internal-bid-wrapper.directive';
import { CreateInternalBidComponent } from './components/create-internal-bid/create-internal-bid.component';
import { PeopleWrapperDirective } from './directives/people-wrapper.directive';
import { PeopleComponent } from './components/people/people.component';
import { RightsWrapperDirective } from './directives/rights-wrapper.directive';
import { RightsComponent } from './components/rights/rights.component';
import { CompaniesWrapperDirective } from './directives/companies-wrapper.directive';
import { CompaniesComponent } from './components/companies/companies.component';
import { CreateCompanyWrapperDirective } from './directives/create-company-wrapper.directive';
import { CreateCompanyComponent } from './components/create-company/create-company.component';
import { EditCompanyWrapperDirective } from './directives/edit-company-wrapper.directive';
import { EditCompanyComponent } from './components/edit-company/edit-company.component';
import { DrugExceptionsWrapperDirective } from './directives/drug-exceptions-wrapper.directive';
import { DrugExceptionsComponent } from './components/drug-exceptions/drug-exceptions.component';
import { ManufacturerMappingWrapperDirective } from './directives/manufacturer-mapping-wrapper.directive';
import { ManufacturerMappingComponent } from './components/manufacturer-mapping/manufacturer-mapping.component';
import { ProductEnrollmentWrapperDirective } from './directives/product-enrollment-wrapper.directive';
import { ProductEnrollmentComponent } from './components/product-enrollment/product-enrollment.component';
import { CreateUserWrapperDirective } from './directives/create-user-wrapper.directive';
import { CreateUserComponent } from './components/create-user/create-user.component';
import { EditUserComponent } from './components/edit-user/edit-user.component';

@NgModule({
  declarations: [
    DashboardWrapperDirective,
    DashboardComponent,
    CreateBidWrapperDirective,
    CreateBidComponent,
    CopyBidComponent,
    CopyBidWrapperDirective,
    NotificationsWrapperDirective,
    NotificationsComponent,
    EnterpriseWrapperDirective,
    EnterpriseComponent,
    ProfileWrapperDirective,
    ProfileComponent,
    DrugSearchWrapperDirective,
    DrugSearchComponent,
    RevisionComparisonWrapperDirective,
    RevisionComparisonComponent,
    CreateInternalBidWrapperDirective,
    CreateInternalBidComponent,
    PeopleWrapperDirective,
    PeopleComponent,
    RightsWrapperDirective,
    RightsComponent,
    CompaniesWrapperDirective,
    CompaniesComponent,
    CreateCompanyWrapperDirective,
    CreateCompanyComponent,
    EditCompanyWrapperDirective,
    EditCompanyComponent,
    DrugExceptionsWrapperDirective,
    DrugExceptionsComponent,
    ManufacturerMappingWrapperDirective,
    ManufacturerMappingComponent,
    ProductEnrollmentWrapperDirective,
    ProductEnrollmentComponent,
    CreateUserWrapperDirective,
    EditUserWrapperDirective,
    CreateUserComponent,
    EditUserComponent,
  ],
  imports: [
    CommonModule,
    Ng1WrapperRoutingModule,
  ],
  exports: [
    DashboardComponent,
    CreateBidComponent,
    CopyBidComponent,
    NotificationsComponent,
    EnterpriseComponent,
    ProfileComponent,
    DrugSearchComponent,
    RevisionComparisonComponent,
    CreateInternalBidComponent,
    PeopleComponent,
    RightsComponent,
    CompaniesComponent,
    CreateCompanyComponent,
    EditCompanyComponent,
    DrugExceptionsComponent,
    ManufacturerMappingComponent,
    ProductEnrollmentComponent,
    CreateUserComponent,
    EditUserComponent,
  ],
})
export class Ng1WrapperModule {}
