<button
    (click)="onButtonClick($event)"
    [disabled]="disabled"
    [matTooltip]="tooltip"
    [ngClass]="cssClass"
    class="toolbar-button"
    mat-raised-button>
  <mat-icon *ngIf="icon | async" [ngClass]="svgIconName" [svgIcon]="svgIconName"></mat-icon>
  <span *ngIf="title" [class.toolbar-button__title--exclude-breakpoint]="excludeBreakpoint"
        class="toolbar-button__title">
    {{ title }}
  </span>
</button>
