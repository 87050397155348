// @ts-ignore
import drugLevelTermsTemplate from './views/bidding/create/druglevelterms.html';
// @ts-ignore
import summaryTermsTemplate from './views/bidding/create/summaryterms.html';
// @ts-ignore
import contractTermsTemplate from './views/bidding/create/contractterms.html';
// @ts-ignore
import contractedBusinessTemplate from './views/bidding/create/contractedbusiness.html';
// @ts-ignore
import rfpOptionsTemplate from './views/bidding/create/rfpoptions.html';
// @ts-ignore
import legalAttestationViewTemplate from './views/bidding/custom/legal-attestation-view.html';
// @ts-ignore
import instructionsTemplate from './views/bidding/custom/instructions.html';
// @ts-ignore
import attachmentUploadTemplate from './views/bidding/custom/attachmentupload.html';
// @ts-ignore
import linksTemplate from './views/bidding/custom/links.html';
// @ts-ignore
import commentsTemplate from './views/bidding/custom/comments.html';
// @ts-ignore
import drugPickerTemplate from './views/bidding/templates/drugpicker.html';
// @ts-ignore
import companyPickerTemplate from './views/bidding/create/companypicker.html';
// @ts-ignore
import bidHistoryBidInfoTemplate from './views/bidding/bidhistorybidinfo.html';
// @ts-ignore
import baseRebatePopupTemplate from './views/components/baserebatepopup.html';
// @ts-ignore
import accessRightsMatrixTemplate from './views/management/accessRightsMatrix.html';
// @ts-ignore
import qBusinessPackageTableTemplate from './views/management/qBusinessPackageTable.html';
// @ts-ignore
import qPlusPackageTableTemplate from './views/management/qPlusPackageTable.html';

export const putTemplateCaches = ($templateCache) => {
  $templateCache.put('/app/views/bidding/templates/drugpicker.html', drugPickerTemplate);

  $templateCache.put('/app/views/bidding/bidhistorybidinfo.html', bidHistoryBidInfoTemplate);

  $templateCache.put('/app/views/bidding/create/druglevelterms.html', drugLevelTermsTemplate);
  $templateCache.put('/app/views/bidding/create/summaryterms.html', summaryTermsTemplate);
  $templateCache.put('/app/views/bidding/create/contractterms.html', contractTermsTemplate);
  $templateCache.put('/app/views/bidding/create/contractedbusiness.html', contractedBusinessTemplate);
  $templateCache.put('/app/views/bidding/create/rfpoptions.html', rfpOptionsTemplate);
  $templateCache.put('/app/views/bidding/create/companypicker.html', companyPickerTemplate);

  $templateCache.put('/app/views/bidding/custom/legal-attestation-view.html', legalAttestationViewTemplate);
  $templateCache.put('/app/views/bidding/custom/instructions.html', instructionsTemplate);
  $templateCache.put('/app/views/bidding/custom/attachmentupload.html', attachmentUploadTemplate);
  $templateCache.put('/app/views/bidding/custom/links.html', linksTemplate);
  $templateCache.put('/app/views/bidding/custom/comments.html', commentsTemplate);

  $templateCache.put('app/views/components/baserebatepopup.html', baseRebatePopupTemplate);

  $templateCache.put('/app/views/management/accessRightsMatrix.html', accessRightsMatrixTemplate);
  $templateCache.put('/app/views/management/qBusinessPackageTable.html', qBusinessPackageTableTemplate);
  $templateCache.put('/app/views/management/qPlusPackageTable.html', qPlusPackageTableTemplate);
};
