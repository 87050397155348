import { Injectable } from '@angular/core';
import {
  BidDeleteDrugCbMeta,
  BidDeleteDrugModalConfig,
  BidDeleteDrugModalData
} from '@qv-bid/components/shared/bid-delete-drug-modal/models';
import { DrugCb, Scenario } from '@qv-bid/entities';
import { ArrayUtils } from '@qv-common/utils';
import { filter, tap } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BidEventBusService } from '@qv-bid/services/bid-event-bus.service';
import { ModalService } from 'quantuvis-angular-common/modal';

@UntilDestroy()
@Injectable()
export class DeleteDrugService {
  constructor(
    private modalService: ModalService,
    private bidEventBusService: BidEventBusService
  ) {}

  public openDeleteDrugModal(versionId: number, drugs: DrugCb[], selectedScenarios: Scenario[],
                             isBidInternal: boolean): void {
    const modalData = new BidDeleteDrugModalData(versionId, drugs, selectedScenarios, isBidInternal);
    const modalConfig = new BidDeleteDrugModalConfig(modalData);

    this.modalService
      .openModal(modalConfig)
      .afterClosed()
      .pipe(
        filter((deletedDrugs: BidDeleteDrugCbMeta[]) => !!deletedDrugs),
        tap((deletedDrugs: BidDeleteDrugCbMeta[]) => {
          const deletedDrugNames = ArrayUtils.getUniqueValuesByKey(deletedDrugs, 'drugName');

          this.bidEventBusService.undoRedoEvent.emit();
          this.bidEventBusService.deleteDrugNamesFromFilterEvent.emit(deletedDrugNames);
          this.bidEventBusService.reloadBidEvent.emit();
        }),
        untilDestroyed(this)
      )
      .subscribe();
  }
}
