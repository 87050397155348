/* tslint:disable:no-any */

import { Preferences } from './preferences';
import { RestAPI } from './restapi';
import { Standards } from './standards';
import { Util } from '@qv-common/utils/util';
import { CoreDomUtilsService } from '../../../../webapp/app/shared/services/core-dom-utils.service';

export function preferencesFactory(i: any): any {
  return i.get('preferences');
}

export const preferencesProvider = {
  provide: Preferences,
  useFactory: preferencesFactory,
  deps: ['$injector']
};

export function restAPIFactory(i: any): any {
  return i.get('restapi');
}

export const restAPIProvider = {
  provide: RestAPI,
  useFactory: restAPIFactory,
  deps: ['$injector']
};

export function standardsFactory(i: any): any {
  return i.get('standards');
}

export const standardsProvider = {
  provide: Standards,
  useFactory: standardsFactory,
  deps: ['$injector']
};

export function utilFactory(i: any): any {
  return i.get('util');
}

export const utilProvider = {
  provide: Util,
  useFactory: utilFactory,
  deps: ['$injector']
};

export function coreDomUtilsServiceFactory(i: any): any {
  return i.get('coreDomUtilsService');
}

export const CoreDomUtilsServiceProvider = {
  provide: CoreDomUtilsService,
  useFactory: coreDomUtilsServiceFactory,
  deps: ['$injector']
};

export function scopeFactory(i: any): any {
  return i.get('$rootScope');
}

export const ScopeProvider = {
  deps: ['$injector'],
  provide: '$scope',
  useFactory: scopeFactory,
};
