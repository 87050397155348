import { EventEmitter, Injectable } from '@angular/core';
import { Params, Router } from '@angular/router';
import { LocalStorageService } from 'ngx-webstorage';
import { HttpUtils } from '@qv-common/utils';
import { BidAssignee } from 'quantuvis-core-entities';
import { UserService } from '@qv-common/services/auth/user.service';

@Injectable()
export class ViewPerspectiveService {
  public switchPerspectiveEvent = new EventEmitter();

  private readonly perspectiveDependentUrls = ['standard', 'grid', 'bid-history', 'bid-revision-comparison'];

  constructor(
    private userService: UserService,
    private localStorageService: LocalStorageService,
    private router: Router
  ) {}

  public getViewPerspective(): BidAssignee {
    const storedPerspective = this.localStorageService.retrieve(HttpUtils.VIEW_PERSPECTIVE_KEY);

    return !this.isPerspectiveDependentUrl() || !storedPerspective
      ? this.getDefaultViewPerspective()
      : storedPerspective;
  }

  public setViewPerspective(perspective: BidAssignee): void {
    this.localStorageService.store(HttpUtils.VIEW_PERSPECTIVE_KEY, perspective);
  }

  public getViewPerspectiveFromStorage(): BidAssignee {
    return this.localStorageService.retrieve(HttpUtils.VIEW_PERSPECTIVE_KEY);
  }

  public getDefaultViewPerspective(): BidAssignee {
    return BidAssignee[this.userService.user.getValue().role.type.toUpperCase()];
  }

  public setDefaultViewPerspective(): void {
    this.localStorageService.store(HttpUtils.VIEW_PERSPECTIVE_KEY, this.getDefaultViewPerspective());
  }

  public appendPerspectiveViewToUrl(url: string): string {
    url = HttpUtils.appendQueryParamToUrl(url, HttpUtils.VIEW_PERSPECTIVE_KEY, this.getViewPerspective());

    return url;
  }

  public isPayerViewPerspective(): boolean {
    return this.getViewPerspective() === BidAssignee.PAYER;
  }

  public isPharmaViewPerspective(): boolean {
    return this.getViewPerspective() === BidAssignee.PHARMA;
  }

  public isCurrentUserWithPayerPerspective(): boolean {
    return this.userService.isCurrentUserPayer() || this.isPayerViewPerspective();
  }

  public enrichParamsWithViewPerspective(currentParams: Params, isInternal: boolean): Params {
    const processedParams = Object.assign({}, currentParams);
    if (isInternal) {
      processedParams.perspective = this.getViewPerspective();
    }

    return processedParams;
  }

  private isPerspectiveDependentUrl(): boolean {
    return this.perspectiveDependentUrls.some((url: string) => this.router.url.includes(url));
  }
}
