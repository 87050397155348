/* tslint:disable:no-var-requires */
import ContractController from './contract.controller';

// @ts-ignore
import templateUrl from './contract.html';

const ContractComponent = {
  template: templateUrl,
  bindings: {
    runValidation: '<',
    onValid: '&',
    bidService: '<',
    options: '<'
  },
  controller: ContractController
};

export default ContractComponent;
