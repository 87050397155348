export const NotificationDescription = ['util', (util): any => {
  'ngInject';
  return {
    restrict: 'A',
    template: '<span> ' +
      '   {{prefix}}' +
      '     <a href="{{ url }}">{{ urlDescription }}</a>' +
      '   {{suffix}}' +
      '</span> ',
    transclude: true,
    scope: {
      notificationDescription: '='
    },

    link: scope => {
      const notificationDescription = scope.notificationDescription;

      if (util.matchURLTemplatePattern(notificationDescription)) {
        const indexOfOpeningBracket = notificationDescription.indexOf('[');
        const indexOfClosingBracket = notificationDescription.indexOf(']');

        // the text before url info from notification description
        scope.prefix = notificationDescription.substring(0, indexOfOpeningBracket);

        /**
         * The URL information extracted from the notification description containing the url and the url
         * description
         */
        const urlInfo = util.getURLInformation(notificationDescription);
        scope.url = urlInfo.url;
        scope.urlDescription = urlInfo.description;

        scope.suffix = notificationDescription.substring(indexOfClosingBracket + 1, notificationDescription.length);
      } else {
        scope.prefix = notificationDescription;
      }
    }
  };
}];

