import { DictionaryItem } from 'quantuvis-angular-common/interfaces';

export enum MarketBasketTemplateState {
  ALL = 0,
  CURRENT,
  ARCHIVED
}

export class MarketBasketTemplateStateUtils {
  public static readonly displayEditValues = new Map<MarketBasketTemplateState, DictionaryItem>([
    [MarketBasketTemplateState.CURRENT, { id: MarketBasketTemplateState.CURRENT, name: 'Current' }],
    [MarketBasketTemplateState.ARCHIVED, { id: MarketBasketTemplateState.ARCHIVED, name: 'Archived' }],
  ]);

  public static readonly displayValues = new Map<MarketBasketTemplateState, DictionaryItem>([
    [MarketBasketTemplateState.ALL, { id: MarketBasketTemplateState.ALL, name: 'All' }],
      ...MarketBasketTemplateStateUtils.displayEditValues
  ]);

  public static readonly filterOptions = new Map<MarketBasketTemplateState, boolean>([
    [MarketBasketTemplateState.CURRENT, false],
    [MarketBasketTemplateState.ARCHIVED, true],
  ]);

  public static getValue(id: MarketBasketTemplateState): DictionaryItem {
    return MarketBasketTemplateStateUtils.displayValues.get(id);
  }

  public static getValues(): DictionaryItem[] {
    return Array.from(MarketBasketTemplateStateUtils.displayValues.values());
  }

  public static getEditValues(): DictionaryItem[] {
    return Array.from(MarketBasketTemplateStateUtils.displayEditValues.values());
  }

  public static getFilterOption(id: MarketBasketTemplateState): boolean {
    return MarketBasketTemplateStateUtils.filterOptions.get(id);
  }

  public static getByFilterOption(filterState: boolean): MarketBasketTemplateState {
    for (let [key, value] of MarketBasketTemplateStateUtils.filterOptions.entries()) {
      if (value === filterState)
        return key;
    }

    return null;
  }

  public static getDisplayValueByFilterOption(filterState: boolean): string {
   return MarketBasketTemplateStateUtils.displayValues.get(
     MarketBasketTemplateStateUtils.getByFilterOption(filterState))?.name;
  }
}
