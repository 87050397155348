import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { ContractFormService } from '@qv-bid/components/shared/summary-panel/contract/contract-form.service';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BidEventBusService } from '@qv-bid/services/bid-event-bus.service';
import { ContractedBusinessesService } from '@qv-bid/services';

import { ContractedBusiness } from 'quantuvis-core-entities';

@UntilDestroy()
@Component({
  selector: 'qv-contract',
  templateUrl: './contract.component.html',
  styleUrls: ['./contract.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContractComponent implements OnInit {
  @Input()
  public isEditMode = false;
  @Input()
  public isBidInvalid: boolean;
  @Input()
  public isBidInternal: boolean;

  public contractedBusinesses$: Observable<ContractedBusiness[]>;
  public selectedContractedBusinessId;

  constructor(
    private bidEventBusService: BidEventBusService,
    private contractedBusinessService: ContractedBusinessesService,
    private contractFormService: ContractFormService,
  ) {
    this.setContractedBusinesses();
  }

  public ngOnInit(): void {
    this.contractFormService.contactFormReadyHandler().pipe(untilDestroyed(this)).subscribe();
  }

  public onChangeContractedBusiness(event: MatSelectChange): void {
    this.selectedContractedBusinessId = event.value;
  }

  public onContractChange(): void {
    this.bidEventBusService.undoRedoEvent.emit();
  }

  private setContractedBusinesses(): void {
    this.contractedBusinesses$ = this.contractedBusinessService.contractedBusinesses$
      .pipe(
        tap((cbs: ContractedBusiness[]) =>
          Array.isArray(cbs) && cbs.length > 0 && this.checkResetSelectedContractedBusinessId(cbs)
        ),
      );
  }

  private checkResetSelectedContractedBusinessId(cbs: ContractedBusiness[]): void {
    const selectedCb = cbs.find((cb: ContractedBusiness) => cb.id === this.selectedContractedBusinessId);
    if (!selectedCb) {
      this.selectedContractedBusinessId = cbs[0].id;
    }
  }
}
