/* tslint:disable */
import { constants } from '@qv-common/static';
import { ArrayUtils, CoreUtils } from '@qv-common/utils';
import { catchError, tap } from 'rxjs/operators';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { of } from 'rxjs';
import { ApiUrlPrefix } from '@qv-common/enums';
import { ApiService } from '@qv-common/services/api';

export class RestAPI {
  static $inject = ['apiService'];
  private apiService;
  private locks;
  private polls = {};

  constructor(apiService: ApiService) {
    'ngInject';
    this.apiService = apiService;
    // Market basket locks state variable
    this.locks = {};
  }

  public marketBasketLock(lock: any, mbID: number, callback: any, errorCallback: any): void {
    if (!lock && CoreUtils.isNotDefined(this.locks[mbID])) {
      if (callback) {
        callback();
      }
      return;
    }
    const path = lock ? 'marketBaskets/lockMarketBasket/' : 'marketBaskets/unlockMarketBasket/';
    this.apiService.post(ApiUrlPrefix.OLD + path + mbID).pipe(
      tap((response: HttpResponse<any>) => {
        if (lock) {
          this.locks[mbID] = true;
          this.registerPoll(`lock${mbID}`, `${ApiUrlPrefix.OLD}/marketBaskets/updateRefCountTime/${mbID}`, constants.APP.BID_PING_INTERVAL);
        } else {
          delete this.locks[mbID];
          this.unregisterPoll(`lock${mbID}`);
        }
        if (callback) {
          callback(response.body);
        }
      }),
      catchError((error: HttpErrorResponse) => {
        if (errorCallback) {
          errorCallback(error);
        }

        return of(null);
      })
    ).subscribe();
  };

  getMarketBaskets(data: any, callback: any, errorCallback: any): void {

    /**
     * Groups the data by the property specified.
     *
     * @param data - the data to be grouped by "propertyToGroupBy"
     * @param propertyToGroupBy - the property to group by the data
     * @returns the grouped data
     */
    function groupData(data: any, propertyToGroupBy: string): any[] {
      const groupedData = ArrayUtils.groupBy(data, (item) => item[propertyToGroupBy]);

      const result = [];
      groupedData.forEach((drugs: any[], key: string) => {
        const drugNames = [];
        drugs.forEach((drug) => drugNames.push(drug.name));

        result.push({
          drugClass: key,
          drugNames
        });
      });

      return result;
    }

    const searchData: any = {};
    if (data.drugName && data.drugClass) {
      searchData.drugName = data.drugName;
      searchData.drugClass = data.drugClass;
    }

    this.apiService.get(`${ApiUrlPrefix.OLD}/marketBaskets/${data.requestOption}`, searchData).pipe(
      tap((response: HttpResponse<any>) => {
        const baskets = response.body.responseObject;

        baskets.forEach(marketBasket => {
          marketBasket.groupedDrugs = groupData(marketBasket.drugsList, 'drugClass');
        });

        if (typeof callback === 'function') {
          callback(baskets);
        }
      }),
      catchError((error: HttpErrorResponse) => {
        if (typeof errorCallback === 'function') {
          errorCallback(error);
        }

        return of(null);
      })
    ).subscribe();
  };

  private unregisterPoll(name: string): void {
    const interval = this.polls[name];

    if (interval) {
      window.clearInterval(interval);
    }
  }

  private registerPoll(name: string, url: string, interval: number): void {
    const sendRequest = (): void => {
      this.apiService.get(url).subscribe();
    };

    sendRequest();

    interval = window.setInterval(sendRequest, interval);

    this.polls[name] = interval;
  }
}
