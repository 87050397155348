import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { JsonConvert } from 'json2typescript';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { JSON_CONVERT_TOKEN } from 'quantuvis-angular-common/json-converter/services';
import { HttpUrlEncodingCodec } from 'quantuvis-angular-common/api';

import { Drug } from '../../entities';
import { MEDISPAN_DRUGS_WITH_PHARMA_URL } from '../../tokens';

@Injectable()
export class DrugDaoService {
  constructor(
    private httpClient: HttpClient,
    @Inject(MEDISPAN_DRUGS_WITH_PHARMA_URL) private resourceUrl: string,
    @Inject(JSON_CONVERT_TOKEN) private jsonConvert: JsonConvert
  ) { }

  public searchByDrugNameAndClass(searchText: string): Observable<Drug[]> {
    const params = new HttpParams({ encoder: new HttpUrlEncodingCodec() }).set('search', searchText);

    return this.httpClient.get(this.resourceUrl, { params }).pipe(
      map((drugs: Drug[]) => this.jsonConvert.deserializeArray(drugs, Drug))
    );
  }
}
