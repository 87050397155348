import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DrugDaoService } from '@qv-bid/services/dao';
import { DrugItem } from '@qv-bid/entities/drug-item.entity';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { finalize } from 'rxjs/operators';
import { BlockingMessage } from '@qv-common/enums';

@Injectable({
  providedIn: 'root'
})
export class IntendToRespondResolver implements Resolve<Observable<DrugItem[]>> {
  @BlockUI()
  public ngBlock: NgBlockUI;

  constructor(private drugDaoService: DrugDaoService) {}

  public resolve(next: ActivatedRouteSnapshot): Observable<DrugItem[]> {
    const { bidId } = next.queryParams;

    this.ngBlock.start(BlockingMessage.LOADING);
    return this.drugDaoService.getAllDrugs(bidId).pipe(
      finalize(() => this.ngBlock.stop()));
  }
}
