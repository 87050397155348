/* tslint:disable:no-invalid-this triple-equals */
/**
 * Custom AngularJS services used by the APP logic
 */
import { constants } from '@qv-common/static';
import { CoreUtils } from '@qv-common/utils';
import { ContractTerms, DrugListTerms, SummaryTerms } from '../../utils/terms';
import { UserService } from '@qv-common/services/auth/user.service';
import { AuthService } from 'quantuvis-angular-common/auth';

declare let angular: angular.IAngularStatic;

export const Bidding = ['$filter', 'util', 'translations', 'locking', '$timeout', 'restapi',
  'biddingUtilsService', 'viewPerspectiveService', '$sce', '$templateRequest', 'spinnerService', 'apiService', 'router',
  'legalAttestationService', 'userService', 'BaselineWacService', 'authService',
  function($filter, util, translations, locking, $timeout, restapi, biddingUtilsService,
            viewPerspectiveService, $sce, $templateRequest, spinnerService, apiService, router,
            legalAttestationService, userService: UserService, BaselineWacService, authService: AuthService): any {
  'ngInject';
  /**
   * Prepare a single term based on specific term template
   * term - term value received from the server
   * termTemplate - term definition
   * dependentTerms - rest of terms in order to create dependencies
   */
  this.prepareTerm = function(term, termTemplate, dependentTerms): any {
    const termFinal: any = {
      definition: angular.copy(termTemplate),
      name: termTemplate.name
    };

    termFinal.value = this.defineValueForTerm(
      term.value, termFinal.definition.defaultValue, termFinal.definition.defaultValueForOldBid);

    if (dependentTerms && termFinal.definition.hasDependencies && termFinal.definition.dependsOn) {
      termFinal.definition.list = {};

      termFinal.definition.dependsOn.forEach(dep => {
        const dependency = dependentTerms.find(term => term.name === dep);
        if (dependency) {
          termFinal.definition.list[dep] = dependency;
        }
      });
    }

    delete termFinal.definition.processKeepOriginalValue;
    delete termFinal.definition.keepOriginalValue;

    return termFinal;
  };

  this.getBasketByDrug = (drug, marketBaskets) => {
    if (typeof drug === 'object' && typeof marketBaskets === 'object') {
      return marketBaskets[drug.scenario.id];
    }
  };

  this.defineValueForTerm = (value, defaultValue, defaultValueForOldBid) => {
    if (typeof value === 'undefined') {
      return typeof defaultValueForOldBid === 'undefined' ? defaultValue : defaultValueForOldBid;
    }
    return angular.copy(value);
  };

  /**
   * Prepare bulk terms
   * termList - list of terms to prepare
   * termsTemplate - list of term definitions
   * settings - specific term preparation settings
   * metadata - the metadata containing locking information for terms
   */
  this.prepareTerms = function(termList, termsTemplate, settings, metadata): any {
    const newTerms = [];
    const allowKeepOriginValue = settings && settings.allowKeepOriginValue;
    termsTemplate.forEach(term => {
      if (term.allowKeepOriginValue) {
        term.processKeepOriginalValue = allowKeepOriginValue;
        term.keepOriginalValue = allowKeepOriginValue;
      }
    });

    if (settings && settings.addMissingFields) {
      termsTemplate.forEach(term => {
        const termToSearch = termList.find(dataTerm => dataTerm.name == term.name);

        if (!termToSearch) {
          termList.push({
            name: term.name
          });
        }
      });
    }

    if (termList && !CoreUtils.isNull(termList)) {
      termList.forEach(termItemFromJson => {

        const termClone = termsTemplate.find(elem => elem.name == termItemFromJson.name);

        if (termClone) {
          const termFinal: any = {
            definition: Object.assign({}, termClone),
            name: termClone.name
          };

          if (CoreUtils.isNotDefined(termItemFromJson.value) && termFinal.definition.defaultValue) {
            if (typeof termFinal.definition.defaultValue === 'object') {
              termFinal.value = Object.assign({}, termFinal.definition.defaultValue);
            } else {
              termFinal.value = termFinal.definition.defaultValue;
            }
          } else if (CoreUtils.isDefined(termItemFromJson.value)) {
            termFinal.value = termItemFromJson.value;
          }
          if (locking.isTermLocked(termFinal, metadata)) {
            // TODO must delete the line bellow
            termFinal.definition.locked = true;
            termFinal.definition.state = constants.DRUG_TERM_GROUPS_STATE_VALUES.LOCKED_STATE.state;
            termFinal.definition.editable = biddingUtilsService.isCurrentUserPayer(userService.user.getValue());
          }
          newTerms.push(termFinal);
        }

      });
    } else {
      termsTemplate.forEach(element => {
        const term: any = {
          definition: Object.assign({}, element),
          name: element.name
        };

        if (CoreUtils.isDefined(term.definition.defaultValue)) {
          if (typeof term.definition.defaultValue === 'object') {
            term.value = Object.assign({}, term.definition.defaultValue);
          } else {
            term.value = term.definition.defaultValue;
          }
        }

        if (locking.isTermLocked(term, metadata)) {
          // TODO must delete the line bellow
          term.definition.locked = true;
          term.definition.state = constants.DRUG_TERM_GROUPS_STATE_VALUES.LOCKED_STATE.state;
          term.definition.editable = biddingUtilsService.isCurrentUserPayer(userService.user.getValue());
        }

        newTerms.push(term);
      });
    }

    this.prepareTermDependencies(newTerms, settings);
    return newTerms;

  };

  this.prepareTermDependencies = (termsList, settings) => {
    termsList.forEach(term => {
      if (settings) {
        if (settings.overwriteDisplayName) {
          term.definition.displayName = term.definition.name;
        }
      }

      if (term.definition.hasDependencies && term.definition.dependsOn) {
        term.definition.list = {};

        term.definition.dependsOn.forEach(dep => {
          const dependency = termsList.find(term => term.name == dep);

          if (dependency) {
            term.definition.list[dep] = dependency;
          }
        });
      }

      if (CoreUtils.isDefined(term.definition.prepare) && typeof (term.definition.prepare) == 'function') {
        term.definition.prepare(term, settings);
      }

      if (term.definition && term.definition.prepareDependencies
        && typeof (term.definition.prepareDependencies) == 'function') {
        term.definition.prepareDependencies(term);
      }
    });
  };

  /**
   * Returns save value for specific term
   * term - term required to be saved
   */
  this.getSaveValueForTerm = term => {
    /** Extract only the terms required for save, with values specified **/
    if (term && term.definition && CoreUtils.isDefined(term.value)) {
      let value;

      // if keepValueOnCopy is set, when scenario is copied, ignore the "getValueToSave" modifier
      // Used for date fields, where we want to pass the formatted values, not the values to be saved.
      if (term.definition.getValueToSave && !term.definition.keepValueOnCopy) {
        value = term.definition.getValueToSave(term);
      } else {
        value = term.value;
      }

      return {
        name: term.name,
        value: value
      };
    }
  };

  this.getPlanTypeName = planType => {
    let result;
    if (planType.name) {
      result = planType.name;
    } else {
      const details = planType.details ? planType.details : planType;

      if (details && details.name) {
        result = details.name;
      } else {
        result = '';
      }
    }

    return result;
  };

  /**
   * Returns term value by searching in term list with specified name
   *
   * @param terms [] List of terms to search in
   * @param termName String name of term to search in list
   * @returns Object|undefined
   */
  this.getTermValueByName = (terms, termName) => {
    let term = terms.filter(term => term.name === termName);
    if (!term.length) {
      return;
    }
    term = term[0];
    if (term.definition && typeof term.definition.getValueToSave === 'function') {
      return term.definition.getValueToSave(term);
    } else {
      return term.value;
    }
  };

  this.biddingData = {
    summaryTerms: SummaryTerms(util, legalAttestationService, translations, $timeout, biddingUtilsService,
      spinnerService, apiService, router, authService, userService),
    contractTerms: ContractTerms(util, translations, biddingUtilsService),
    drugListTerms: DrugListTerms($filter, util, translations, restapi, biddingUtilsService,
      viewPerspectiveService, $sce, $templateRequest, userService, BaselineWacService)
  };

  /**
   * BiddingData is used in order to define terms and helper function used in order to render terms
   */
  this.BiddingData = function(): any {
    // Term definitions
    return this.biddingData;
  };

  return {
    prepareTerm: this.prepareTerm,
    getBasketByDrug: this.getBasketByDrug,
    defineValueForTerm: this.defineValueForTerm,
    prepareTerms: this.prepareTerms,
    prepareTermDependencies: this.prepareTermDependencies,
    getSaveValueForTerm: this.getSaveValueForTerm,
    getPlanTypeName: this.getPlanTypeName,
    getTermValueByName: this.getTermValueByName,
    biddingData: this.biddingData,
    BiddingData: this.BiddingData,
  };
}];
