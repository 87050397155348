import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EmbeddedDashboardPanelComponent } from '@qv-insights/components';
import { EnrollmentGuard } from '@qv-common/guards';
import { EnrollmentId } from 'quantuvis-core-entities';

export const routes: Routes = [
  {
    path: 'insights-dashboard',
    component: EmbeddedDashboardPanelComponent,
    data: { enrollmentId: EnrollmentId.RN_IQ },
    canActivate: [EnrollmentGuard],
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
  ]
})
export class InsightsRoutingModule {}
