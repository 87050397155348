import { HttpErrorResponse } from '@angular/common/http';
import {
  ChangeDetectorRef,
  Directive,
  Input,
  OnChanges,
  OnInit,
  QueryList,
  SimpleChanges,
  ViewChildren
} from '@angular/core';
import { FormArray, FormControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BaseSection } from '@qv-bid/classes/sections/base-section';
import { RangeOrUnitAndRebateComponent } from '@qv-bid/components/shared';
import { MarketShare } from '@qv-bid/entities/market-share.entity';
import { RangeOrUnitAndRebate } from '@qv-bid/entities/range-or-unit-and-rebates.entity';
import { MarketShareRangeOrUnitAndRebateAction, MarketShareState } from '@qv-bid/enums';
import { SectionChange, SectionChangeFailed } from '@qv-bid/models';
import { BidEventBusService } from '@qv-bid/services/bid-event-bus.service';
import { DrugFormService } from '@qv-bid/services/drug';
import { NdcManagerService } from '@qv-bid/services/ndc-manager.service';
import { ScenariosSnackBarService } from '@qv-bid/services/scenarios-snack-bar.service';
import { SectionChangeManager } from '@qv-bid/services/sections/section-change-manager.service';
import { QvCache } from '@qv-common/decorators';
import { DictionaryItem } from '@qv-common/entities';
import { SvgIconName } from '@qv-common/enums';
import { MarketShareDaoService } from '@qv-bid/services/dao';
import { constants, resources } from '@qv-common/static';
import { drugTermsConstants } from '@qv-term/constants';
import { NdcDictionaryValue } from '@qv-term/entities';
import { TermName, TermSection } from '@qv-term/enums';
import { TermUtils } from '@qv-term/utils';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, delay, filter, switchMap, take, tap } from 'rxjs/operators';
import { UserService } from '@qv-common/services/auth/user.service';
import { MarketShareTier } from 'quantuvis-core-entities';

@UntilDestroy()
@Directive()
export class MarketShareSection extends BaseSection implements OnInit, OnChanges {
  public rorUpdateDelay = 100;
  @Input()
  public marketShare: MarketShare;
  @Input()
  public drugId: number;
  @Input()
  public scenarioId: number;
  @Input()
  public isBidInvalid: boolean;
  @Input()
  public isBidInternal: boolean;

  public isEditMode = false;
  public marketShareTierTitle: string;
  public formControlRangeUnitsAndRebates: FormArray;
  public rouarChipLoadingAction$ = new BehaviorSubject<MarketShareRangeOrUnitAndRebateAction>(null);
  public isUserPharma = false;
  public readonly resources = resources;
  public readonly termName = TermName;
  public readonly drugTermsConstants = drugTermsConstants;
  public readonly sectionName = TermSection.MARKET_SHARE;
  public readonly svgIconName = SvgIconName;
  @ViewChildren(RangeOrUnitAndRebateComponent)
  public rouarComponents: QueryList<RangeOrUnitAndRebateComponent>;
  protected readonly termNameRUR = TermName.RANGE_OR_UNITS_AND_REBATES;
  protected readonly sectionMS = this.sectionName;
  protected readonly maximumRouarChips = 10;

  constructor(
    drugFormService: DrugFormService,
    protected marketShareDaoService: MarketShareDaoService,
    protected changeDetectorRef: ChangeDetectorRef,
    protected userService: UserService,
    protected ndcManagerService: NdcManagerService,
    protected scenariosSnackBarService: ScenariosSnackBarService,
    protected bidEventBusService: BidEventBusService,
    sectionChangeManager: SectionChangeManager,
  ) {
    super(drugFormService, changeDetectorRef, sectionChangeManager);
  }

  public trackByIdFn(index: number, rouar: RangeOrUnitAndRebate): number {
    return rouar.id;
  }

  public ngOnInit(): void {
    const marketShareTier = this.marketShare.marketShareTier;
    this.initSectionEditModeChangeHandler();
    this.initSectionChangeHandler();
    this.isUserPharma = this.userService.isCurrentUserPharma();

    this.marketShareTierTitle = marketShareTier.isNdc
      ? constants.AT_NDC
      : marketShareTier.value.name;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.marketShare && !changes.marketShare.firstChange) {
      const stateControl = this.drugFormService.getTermControl(TermName.STATE, TermSection.MARKET_SHARE);
      if (stateControl) {
        stateControl.markAsPristine();
        this.silentUpdateMarketShareForm();
      }
    }
  }

  @QvCache()
  public isHidden(state: NdcDictionaryValue): boolean {
    return !state.isNdc && state.value.id === MarketShareState.HIDDEN;
  }

  @QvCache()
  public isMarketShareAvailable(isPharmaUser: boolean, marketShareState: NdcDictionaryValue): boolean {
    return !isPharmaUser || marketShareState.isNdc || marketShareState.value.id !== MarketShareState.HIDDEN;
  }

  @QvCache()
  public isAddRouarDisabled(isEditMode: boolean, marketShareState: NdcDictionaryValue,
                            marketShareTier: NdcDictionaryValue, count: number): boolean {
    return !isEditMode || (!marketShareState.isNdc && marketShareState.value.id === MarketShareState.HIDDEN)
      || (!marketShareTier.isNdc && marketShareTier.value.id === MarketShareTier.EMPTY)
      || count >= this.maximumRouarChips;
  }

  @QvCache()
  public isEditable(
    isEditMode: boolean,
    isUserPharma: boolean,
    state: NdcDictionaryValue,
    isBidInvalid: boolean = false
  ): boolean {
    const isLocked = state.value
      && [MarketShareState.LOCKED, MarketShareState.HIDDEN].includes(state.value.id)
      && !state.isNdc;
    return TermUtils.isEditable(isEditMode, isUserPharma, isLocked, isBidInvalid);
  }

  @QvCache()
  public isAddRouarBtnVisible(isEditMode: boolean, isAddRouarAvailable: boolean): boolean {
    return isEditMode && isAddRouarAvailable;
  }

  @QvCache()
  public isRouarAtNdcClickable(isEditMode: boolean, isUserPharma: boolean,
                               state: NdcDictionaryValue, isBidInvalid: boolean, tierAtNdc: boolean): boolean {
    return this.isEditable(isEditMode, isUserPharma, state, isBidInvalid) && !tierAtNdc;
  }

  public overwriteRouarValue(): void {
    this.drugFormService
      .getTermControl(TermName.MARKET_SHARE_TIER, this.sectionMS).setValue(new DictionaryItem(MarketShareTier.EMPTY));
    this.changeDetectorRef.markForCheck();
    this.ndcManagerService.removeFromCache(this.scenarioId);
  }

  public onAddRouarHandler(event: Event): void {
    event.stopPropagation();

    this.rouarChipLoadingAction$.pipe(
      take(1),
      filter((action: string) => action !== MarketShareRangeOrUnitAndRebateAction.ADD)
    ).subscribe(() => {
      this.rouarChipLoadingAction$.next(MarketShareRangeOrUnitAndRebateAction.ADD);
      this.formControlRangeUnitsAndRebates.push(new FormControl(new RangeOrUnitAndRebate(null, null, null, null)));
      this.changeDetectorRef.markForCheck();
    });
  }

  public onRemoveRouarHandler(id: number): void {
    this.rouarChipLoadingAction$.pipe(
      take(1),
      filter((action: string) => action !== MarketShareRangeOrUnitAndRebateAction.REMOVE)
    ).subscribe(() => {
      if (this.formControlRangeUnitsAndRebates.length === 1) {
        this.rouarChipLoadingAction$.next(MarketShareRangeOrUnitAndRebateAction.REMOVE);
        this.formControlRangeUnitsAndRebates.controls[0].setValue(new RangeOrUnitAndRebate(null, null, null, null));
        return;
      }

      const index = this.formControlRangeUnitsAndRebates.controls
        .findIndex((formControl: FormControl) => formControl.value.id === id);

      if (index === -1) return;

      this.rouarChipLoadingAction$.next(MarketShareRangeOrUnitAndRebateAction.REMOVE);
      this.formControlRangeUnitsAndRebates.removeAt(index);
    });
  }

  public rangeUnitsAndRebateUpdateHandler(data: RangeOrUnitAndRebate): void {
    this.rouarChipLoadingAction$.pipe(
      take(1),
      filter((action: string) =>
        this.isEditMode &&
        this.formControlRangeUnitsAndRebates &&
        action !== MarketShareRangeOrUnitAndRebateAction.UPDATE
      ),
      // fix triggering onAdd and onRemove handlers together with onUpdate after the first loading
      // example: after the first chips loading set some value to a chip and without unfocus
      //          try to add or remove another chip. Without delay onAdd or onRemove handlers will not trigger
      delay(this.rorUpdateDelay)
    ).subscribe(() => {
      const index = this.marketShare.rangeOrUnitAndRebates.value.findIndex(value => value.id === data.id);
      if (index === -1) return;

      const control = this.formControlRangeUnitsAndRebates.controls[index];

      if (control) {
        this.rouarChipLoadingAction$.next(MarketShareRangeOrUnitAndRebateAction.UPDATE);
        control.setValue(data);
      }
    });
  }

  protected focusRouarComponentBaseOnAction(): void {
    switch (this.rouarChipLoadingAction$.getValue()) {
      case MarketShareRangeOrUnitAndRebateAction.ADD:
        this.rouarComponents.last.focusFirstInput();
        break;
    }
  }

  private initSectionChangeHandler(): void {
    this.drugFormService.sectionChangeHandler(this.sectionName).pipe(
      tap(() => this.sectionChangeManager.isSectionChanging$.next(true)),
      switchMap((changes: MarketShare) => this.updateSection(changes)),
      this.sectionLeaveHandler(),
      filter((changes: MarketShare) => changes && Object.keys(changes).length > 0),
    ).subscribe(() => {
      setTimeout(() => {
        this.focusRouarComponentBaseOnAction();
        this.rouarChipLoadingAction$.next(null);
      });
    });
  }

  private updateSection(marketShare: MarketShare): Observable<MarketShare> {
    return this.marketShareDaoService.update(marketShare, this.scenarioId, this.isBidInternal, this.drugId)
      .pipe(
        tap((updatedSection: MarketShare) => {
          const sectionChange = new SectionChange(this.scenarioId, this.drugId, this.sectionName, updatedSection);
          this.sectionChangeManager.sectionSaveSuccessEvent$.emit(sectionChange);
        }),
        catchError((error: HttpErrorResponse) => {
          this.sectionChangeManager.isSectionChangeFailed$.next(new SectionChangeFailed(this.sectionName, error));

          return of(null);
        }),
      );
  }

  private initSectionEditModeChangeHandler(): void {
    this.drugFormService.isEditMode(this.sectionName).pipe(untilDestroyed(this))
      .subscribe((isEditMode: boolean) => {
        this.isEditMode = isEditMode;
        if (this.isEditMode) {
          if (!this.formControlRangeUnitsAndRebates) {
            this.formControlRangeUnitsAndRebates = this.drugFormService
              .getTermFormArray(this.termNameRUR, this.sectionMS);
          }
        }
        this.changeDetectorRef.markForCheck();
      });
  }

  private silentUpdateMarketShareForm(): void {
    this.drugFormService.updateSilentMarketShareForm(this.marketShare);
    this.bidEventBusService.updateROREvent.emit(
      this.marketShare.rangeOrUnitAndRebates.isNdc ? 0 : this.marketShare.rangeOrUnitAndRebates.value.length
    );
  }
}
