import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { BidFilterService, BidStateService, SectionFiltersService, StandardDrugFilterService } from '@qv-bid/services';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { SectionFilter } from '@qv-bid/enums';
import { BidSelectService } from '@qv-bid/services/selects';
import { SvgIconName } from '@qv-common/enums';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BidEventBusService } from '@qv-bid/services/bid-event-bus.service';

@UntilDestroy()
@Component({
  selector: 'qv-standard-header',
  templateUrl: './standard-header.component.html',
  styleUrls: ['./standard-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StandardHeaderComponent {
  @Input()
  public cbId: number;
  @Input()
  public bidVersionId: number;
  @Input()
  public pharmaId: number;

  public isBidInvalid$: Observable<boolean>;
  public readonly svgIconName = SvgIconName;

  constructor(
    public standardDrugFilterService: StandardDrugFilterService,
    public sectionFiltersService: SectionFiltersService,
    public bidSelectService: BidSelectService,
    public bidStateService: BidStateService,
    public bidFilterService: BidFilterService,
    public bidEventBusService: BidEventBusService,
  ) {
    this.isBidInvalid$ = this.bidStateService.isBidInvalid().pipe(untilDestroyed(this));
  }

  public trackByFn(index: number): number {
    return index;
  }

  public filterChanged(filterKey: SectionFilter, matCheckboxChange: MatCheckboxChange): void {
    this.sectionFiltersService.filterChanged(filterKey, matCheckboxChange.checked);
  }
}
