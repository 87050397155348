/* tslint:disable:triple-equals */
/**
 * Bidding Summary Term Definitions
 */
import isEqual from 'lodash.isequal';
import { constants, resources } from '@qv-common/static';
import { SvgIconName, FormValidationError } from '@qv-common/enums';
import { CoreUtils, DateUtils, StringUtils } from '@qv-common/utils';
import { ApiUrlPrefix } from '@qv-common/enums';
import { catchError, finalize, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { RfpTitleTerm } from '@qv-term/models/summary';
import { Attachment } from '@qv-common/models';
import { UserService } from '@qv-common/services/auth/user.service';
import { AuthService } from 'quantuvis-angular-common/auth';

declare let angular: angular.IAngularStatic;
declare let $: any;
const skipChangesSymbol = Symbol.for('skipChanges');

export function SummaryTerms(util, legalAttestationService, translations, $timeout, biddingUtilsService,
                             spinnerService, apiService, router, authService: AuthService,
                             userService: UserService): {}[] {
  return [
    {
      name: constants.SUMMARY_TERMS.RFP_TITLE.label,
      type: 7,
      defaultValue: '',
      path: [
        constants.BID_SECTIONS.SUMMARY_TERMS, constants.SUMMARY_TERMS.RFP_TITLE.label
      ],
      validate: term => {
        delete term.definition.warning;
        delete term.definition.error;

        let valid = true;

        if (term.value.length > 0) {
          if (term.value.length > constants.CONTRACTED_BUSINESS_NAME_MAX_LENGTH) {
            term.definition.error = RfpTitleTerm.validationMessages.get(FormValidationError.MAX_LENGTH);
            valid = false;
          }
        } else {
          term.definition.error = RfpTitleTerm.validationMessages.get(FormValidationError.REQUIRED);
          valid = false;
        }
        return valid;
      },
      description: translations.i18n.TermsDescriptions.SUMMARY.RFP_TITLE,
      getValueToSave: term => term.value,
      resetValue: term => {
        term.value = term.definition.defaultValue;
      }
    },
    {
      name: constants.SUMMARY_TERMS.LINE_OF_BUSINESS.label,
      type: 1,
      allowKeepOriginValue: true,
      values: [
        constants.LINE_OF_BUSINESS.MEDICARE, constants.LINE_OF_BUSINESS.MEDICAID,
        constants.LINE_OF_BUSINESS.COMMERCIAL, constants.LINE_OF_BUSINESS.EXCHANGES,
        constants.LINE_OF_BUSINESS.MEDICAL
      ],
      defaultValue: constants.LINE_OF_BUSINESS.MEDICARE,
      description: translations.i18n.TermsDescriptions.SUMMARY.LINE_OF_BUSINESS,
      displayName: constants.SUMMARY_TERMS.LINE_OF_BUSINESS.label.toUpperCase(),
      hasDependencies: true,
      getValueToSave: term => term.value,
      dependsOn: [
        constants.BIDDING.PLAN_TYPE
      ],
      onChange: term => {
        if (!term.planTypeTerm) {
          term.planTypeTerm = term.definition.list[constants.BIDDING.PLAN_TYPE];
        }
        if (term.planTypeTerm) {

          // clear plan type values
          if (term.planTypeTerm.value) {
            term.planTypeTerm.value = [];
            term.planTypeTerm.selectedIndex = 0;
          } else {
            term.planTypeTerm.value = [];
          }

          // set initial plan type value
          term.planTypeTerm.value[0] = {
            name: ''
          };
          term.planTypeTerm.details = term.planTypeTerm.value[0];
          term.planTypeTerm.details.name = term.value;
        }
      },
      resetValue: util.resetValueDropdown
    },
    {
      name: constants.SUMMARY_TERMS.BINDING_BID.label,
      allowKeepOriginValue: true,
      path: [
        constants.BID_SECTIONS.SUMMARY_TERMS, constants.SUMMARY_TERMS.BINDING_BID.label
      ],
      type: 1,
      hasDependencies: true,
      dependsOn: [
        constants.BIDDING.LEGAL_ATTESTATION
      ],
      onChangeEvent: (term, scope) => {
        let legalAttestationTerm;
        if (term.definition.list) {
          legalAttestationTerm = term.definition.list[constants.BIDDING.LEGAL_ATTESTATION];
          if (legalAttestationTerm && !legalAttestationTerm.value ||
            biddingUtilsService.isBidDisplayedStatusRFPNotSent(scope.getBidStatus()) ||
            (scope.bid && scope.bid.isNew)) {
            // Fetch latest legal attestation for payer company
            legalAttestationTerm.definition.fetchData(legalAttestationTerm, term, scope.getBidPayer());
          }
        }

        if (scope.$emit) {
          scope.$emit('toggleBindingBid', term.value === constants.BINDING_BID_TYPE.BINDING);
        } else {
          scope.bid.isBinding = term.value === constants.BINDING_BID_TYPE.BINDING;
        }
      },
      values: [
        constants.BINDING_BID_TYPE.BINDING, constants.BINDING_BID_TYPE.NON_BINDING
      ],
      defaultValue: constants.BINDING_BID_TYPE.NON_BINDING,
      getValueToSave: term => {
        if (term.definition.processKeepOriginalValue && term.value === resources.NewBid.KEEP_ORIGINAL_VALUE) {
          return term.value;
        }
        return term.value === constants.BINDING_BID_TYPE.BINDING;
      },
      prepare: term => {
        term.value = (!biddingUtilsService.isNotDefined(term.value)
          && (term.value == true || term.value === constants.BINDING_BID_TYPE.BINDING))
          ? constants.BINDING_BID_TYPE.BINDING
          : constants.BINDING_BID_TYPE.NON_BINDING;
      },
      resetValue: util.resetValueDropdown
    },
    {
      name: constants.BIDDING.LEGAL_ATTESTATION,
      description: translations.i18n.TermsDescriptions.SUMMARY.LEGAL_ATTESTATION,
      path: [
        constants.BID_SECTIONS.SUMMARY_TERMS, constants.BIDDING.LEGAL_ATTESTATION
      ],
      type: 5,
      // @ts-ignore
      templateFile: `/app/views/bidding/custom/legal-attestation-view.html`,
      doNotSave: true,
      linkValue: (term, bidId) => term.value
        ? `${authService.appendSecureInfoToUrl(`service/legalAttestation/download/${term.value.docId}`)}&bidId=${bidId}`
        : '',
      getTermValueForComparison: term => term.value
        ? `<a target='_blank' href='${window.location.host}${
          term.definition.linkValue(term)}'>${term.value.docName}</a><br />` : '',
      prepareCustom: scope => {
        scope.getLinkForDocument = () => legalAttestationService.exportFile({
          name: scope.term.value.docName,
          id: scope.term.value.docId,
        }).toPromise().then();

        scope.svgIconName = SvgIconName;
      },
      fetchData: (term, bindingBidTerm, bidPayer) => {
        if (bidPayer) {
          const promise = legalAttestationService.getFileMeta().toPromise()
            .then(file => {
              const hasFile = Boolean(file && file.id && file.name);

              if (!hasFile) {
                throw new Error('Failed to retrieve legal attestation document');
              }

              return file;
            });

          promise.then(data => {
            term.value = {
              docName: data.name,
              docId: data.id
            };
          }, () => {
            term.definition.validate(term, [bindingBidTerm]);
          });
        }
      },
      validate: (term, summaryTerms) => {
        delete term.definition.error;
        let valid = true;
        const bindingBidTerm = summaryTerms.find(item => item.name === constants.SUMMARY_TERMS.BINDING_BID.label);

        if (
          !term.value &&
          biddingUtilsService.isBindingTypeValue(
            bindingBidTerm.value,
            router.routerState.snapshot.root.queryParams.bindingType)
        ) {
          term.definition.error = translations.i18n.GENERAL_TERM_ERRORS.LEGAL_ATTESTATION_MISSING;
          valid = false;
        }

        return valid;
      }
    },
    {
      name: constants.BIDDING.PLAN_TYPE,
      displayName: constants.BIDDING.PLAN_TYPE_DISPLAY_NAME,
      description: translations.i18n.TermsDescriptions.SUMMARY.CONTRACTED_BUSINESS,
      type: 5,
      populateInitial: true,
      placeholder: translations.i18n.PlanType.NAME_PLACEHOLDER,
      duplicatePlaceholder: translations.i18n.PlanType.DUPLICATE_NAME_PLACEHOLDER,
      importText: translations.i18n.PlanType.IMPORT_TEXT,
      duplicateText: translations.i18n.PlanType.DUPLICATE_TEXT,
      hasDependencies: true,
      dependsOn: [
        constants.SUMMARY_TERMS.LINE_OF_BUSINESS.label
      ],
      prepareCustom: scope => {
        scope.getTabClass = (planType, index) =>
          biddingUtilsService.getPlanTypeTabClass(planType, index, scope.planTypeTerm.selectedIndex);

        scope.isCurrentContractedBusinessNameValid = () => {
          const currentPlanType = scope.planTypeTerm.value[scope.planTypeTerm.selectedIndex];
          return scope.planTypeTerm.definition.validatePlanTypeName(scope.planTypeTerm, currentPlanType);
        };

        if (scope.term) {
          scope.getPlanTypeSelection = index => {

            let plans = '';
            scope.term.value[index].attributes.forEach((attr, key) => {
              if (attr) {
                plans += `${!CoreUtils.isEmpty(plans) ? ', ' : ' '}${key}`;
              }
            });
            if (CoreUtils.isEmpty(plans)) {
              plans = 'Select';
            }

            return plans;
          };

          scope.selectImport = () => {
            scope.term.value[0].import = true;
          };

          scope.selectDuplicate = () => {
            scope.term.value[0].duplicate = true;
          };

          if (!scope.term.definition.isPlain) {
            scope.addNew = () => {
              scope.term.value.push({
                name: ''
              });
            };

            scope.removeFromIndex = index => {
              scope.term.value.splice(index, 1);
            };

            scope.term.value = [];
            scope.addNew();
          }

          if (!scope.term.lineOfBusinessTerm) {
            scope.term.lineOfBusinessTerm = scope.term.definition.list[constants.SUMMARY_TERMS.LINE_OF_BUSINESS.label];
          }

          if (scope.term.definition.populateInitial || scope.term.isNew) {
            scope.term.value = [
              {
                name: ''
              }
            ];
          }
        }
      },
      validatePlanTypeName: (term, planType) => {
        let isValid = true;
        term.definition.verifyEmptyPlanTypeName(planType);

        if (planType.emptyNameError) {
          term.definition
            .showPlanTypeNamePopover(translations.i18n.GENERAL_TERM_ERRORS.CONTRACTED_BUSINESS_NAME_NOT_EMPTY);
          isValid = false;
        } else {
          term.definition.verifyPlanTypeNameUniqueness(planType, term.value);
          if (planType.duplicateNameError) {
            term.definition
              .showPlanTypeNamePopover(translations.i18n.GENERAL_TERM_ERRORS.CONTRACTED_BUSINESS_NAME_NOT_UNIQUE);
            isValid = false;
          } else {
            $('.planTypeEditContainer:not(.ng-hide) input').popover('dispose');
          }
        }

        return isValid;
      },
      verifyEmptyPlanTypeName: planType => {
        if (!planType.name) {
          planType.emptyNameError = true;
        } else {
          delete planType.emptyNameError;
        }
      },
      verifyPlanTypeNameUniqueness: (planType, planTypes) => {
        let isNotUnique = false;

        planTypes.forEach(currentPlanType => {
          if (planType !== currentPlanType && planType.name && planType.name === currentPlanType.name) {
            planType.duplicateNameError = true;
            isNotUnique = true;
          }
        });

        if (!isNotUnique) {
          delete planType.duplicateNameError;
        }
      },
      validateDuplicatePlanTypeName: (term, planType) => {
        if (!term.definition.verifyEmptyDuplicatePlanTypeName(planType)) {
          return !term.definition.isDuplicatePlanTypeNameExist(planType, term.value);
        }

        return false;
      },
      onChangePlanTypeName: (term, planType) => {
        if (!planType.duplicatePlanTypeName) {
          term.definition
            .showDuplicatePlanTypeNamePopover(
              translations.i18n.GENERAL_TERM_ERRORS.DUPLICATED_CONTRACTED_BUSINESS_NAME_NOT_EMPTY);
        } else if (planType.duplicatePlanTypeName
          && term.definition.isDuplicatePlanTypeNameExist(planType.duplicatePlanTypeName, term.value)) {
          term.definition
            .showDuplicatePlanTypeNamePopover(
              translations.i18n.GENERAL_TERM_ERRORS.CONTRACTED_BUSINESS_NAME_NOT_UNIQUE);
        } else {
          $('.duplicate-plan-type-name:visible').popover('dispose');
        }
      },
      verifyEmptyDuplicatePlanTypeName: name => typeof name === 'undefined',
      isDuplicatePlanTypeNameExist: (name, planTypes) => planTypes.some(
        currentPlanType => name.trim() === currentPlanType.name.trim()
      ),
      showPlanTypeNamePopover: content => {
        const popover = $(`.popover-body:contains('${content}')`);
        if (popover.length > 0) {
          return;
        }

        const $planTypeName = $('.planTypeEditContainer:not(.ng-hide) input');
        $planTypeName.popover('dispose');
        $planTypeName.popover({
          placement: 'top',
          trigger: 'manual',
          content
        });

        $planTypeName.popover('show');
        $('.popover-body').addClass('error');
      },
      showDuplicatePlanTypeNamePopover: content => {
        const $duplicatePlanTypeName = $('.duplicate-plan-type-name:visible');
        $duplicatePlanTypeName.popover('dispose');

        $duplicatePlanTypeName.popover({
          placement: 'right',
          trigger: 'manual',
          content
        });

        $duplicatePlanTypeName.popover('show');
        $('.popover-body').addClass('error');
      },
      validate: term => {
        delete term.definition.error;

        let valid = term.definition.validatePlanTypeName(term, term.value[term.selectedIndex]);

        term.value.forEach(planType => {
          if (planType.name && planType.name.length > constants.CONTRACTED_BUSINESS_NAME_MAX_LENGTH) {
            term.definition.error = translations.i18n
              .GENERAL_TERM_ERRORS.CONTRACTED_BUSINESS_NAME_MAX_LENGTH_ERROR_MESSAGE;
            valid = false;
          }
        });

        term.definition.error = !valid;
        return valid;
      }
    },
    {
      name: constants.SUMMARY_TERMS.RFP_START_DATE.label,
      path: [
        constants.BID_SECTIONS.SUMMARY_TERMS, constants.SUMMARY_TERMS.RFP_START_DATE.label
      ],
      type: 2,
      editable: false,
      defaultValue: new Date().toDateString(),
      description: translations.i18n.TermsDescriptions.SUMMARY.RFP_START_DATE,
      getValueToSave: term => StringUtils.isString(term.value)
        ? DateUtils.getDateBasedOnFormattedString(term.value).getTime()
        : term.value
    },
    {
      name: constants.SUMMARY_TERMS.RFP_DUE_DATE.label,
      path: [
        constants.BID_SECTIONS.SUMMARY_TERMS, constants.SUMMARY_TERMS.RFP_DUE_DATE.label
      ],
      type: 2,
      defaultValue: '',
      onChange: term => {
        delete term.definition.warning;
        delete term.definition.error;

        if (term.definition.reset) {
          term.definition.reset = false;
          return;
        }
        // Skip validation depending on RFP Start Date for the moment
        const valid = util.validateDate(term, null, false, false);

        if (valid) {
          if (util.isDateInThePast(term.value)) {
            term.definition.warning = `${term.name} is in the past`;
          }
        }
      },
      validate: term => {
        delete term.definition.warning;
        delete term.definition.error;

        // Skip validation depending on RFP Start Date for the moment
        const valid = util.validateDate(term, null, false, false);

        if (valid) {
          if (util.isDateInThePast(term.value)) {
            term.definition.warning = `${term.name} is in the past`;
          }
        }

        return valid;
      },
      description: translations.i18n.TermsDescriptions.SUMMARY.RFP_DUE_DATE,
      getValueToSave: term => StringUtils.isString(term.value)
        ? DateUtils.getDateBasedOnFormattedString(term.value).getTime()
        : term.value,
      resetValue: term => {
        term.definition.reset = true;
        term.value = term.definition.defaultValue;
        if (term.definition.processKeepOriginalValue) {
          term.definition.keepOriginalValue = true;
        }
      }
    },
    {
      name: constants.SUMMARY_TERMS.ATTACHMENTS.label,
      displayName: constants.BIDDING.ATTACHMENTS_LABEL,
      path: [
        constants.BID_SECTIONS.SUMMARY_TERMS, constants.SUMMARY_TERMS.ATTACHMENTS.label
      ],
      type: 5,
      allowKeepOriginValue: true,
      // @ts-ignore
      templateFile: `/app/views/bidding/custom/attachmentupload.html`,
      placeholder: 'Path',
      inputButton: 'Browse',
      description: translations.i18n.TermsDescriptions.SUMMARY.ATTACHMENTS,
      saveToBidMeta: true,
      linkValue: (index, term) =>
        authService.appendSecureInfoToUrl(`service/attachment/download/${term.value[index].attachmentId}`),
      getTermValueForComparison: term => term.value,
      statusInfo: { message: null, error: null },
      prepareCustom: (scope, term) => {
        let manuf = {};
        if (biddingUtilsService.isDefined(scope.$parent.$parent)
          && biddingUtilsService.isDefined(scope.$parent.$parent.bidInfo)) {
          manuf = scope.$parent.$parent.bidInfo.manufacturer;
        }
        // Update attachment object with correct companyDisplayName
        scope.term.value = biddingUtilsService.getDisplayCompanyName(scope.term.value, manuf);

        scope.getLoggedInUserId = () => {
          if (biddingUtilsService.isNotDefined(userService.user.getValue())) {
            return -1;
          }
          return userService.user.getValue().id;
        };

        scope.addNew = (skipChangeRequest = true) => {
          if (scope.term.definition.processKeepOriginalValue && scope.term.value.length > 0) {
            scope.term.definition.placeholder = 'Path';
            scope.term.definition.keepOriginalValue = false;
          }
          scope.term.value.push({});
          term.definition.statusInfo.message = null;
          term.definition.statusInfo.error = null;
          scope.term.value[skipChangesSymbol] = skipChangeRequest;
        };

        scope.$on(constants.EVENTS.CREATE_BID_UPLOAD_MESSAGE, (event, obj) => {
          term.definition.statusInfo.message = obj.message;
          term.definition.statusInfo.error = obj.error;
          scope.refresh();
          clearAttachmentMessageWithDelay();
        });

        scope.$on(constants.EVENTS.FILE_UPLOAD_MESSAGE, (event, obj) => {
          scope.term.value[obj.index] = {};
          term.definition.statusInfo.message = obj.message;
          term.definition.statusInfo.error = obj.error;
          scope.refresh();
          clearAttachmentMessageWithDelay();
        });

        scope.removeFromIndex = (index) => {
          if (scope.term.definition.processKeepOriginalValue) {
            scope.term.definition.placeholder = 'Path';
            term.definition.keepOriginalValue = false;
          }
          const { attachmentId } = scope.term.value[index];
          if (typeof attachmentId !== 'undefined') {
            if (scope.term.definition.canRemoveAttachment(scope.term.value[index], scope.term)) {
              removeAttachment(attachmentId, index, scope, term);
            }
          } else {
            scope.term.value.splice(index, 1);
            if (scope.term.value.length === 0) {
              scope.addNew(false);
            }
            term.definition.statusInfo.message = null;
            term.definition.statusInfo.error = null;
          }

        };

        scope.getLinkForAttachment = index => scope.term.definition.linkValue(index, scope.term);

        scope.downloadAttachment = index => {
          if (CoreUtils.isDefined(scope.term.value[index].attachmentId)) {
            const downloadURL = scope.getLinkForAttachment(index);

            window.open(downloadURL, '_blank');
          }
        };

        scope.refresh = () => {
          $timeout(() => {
            let manuf = {};
            if (biddingUtilsService.isDefined(scope.$parent.$parent) &&
              biddingUtilsService.isDefined(scope.$parent.$parent.bidInfo)) {
              manuf = scope.$parent.$parent.bidInfo.manufacturer;
            }
            // Update attachment object with correct companyDisplayName
            scope.term.value = biddingUtilsService.getDisplayCompanyName(scope.term.value, manuf);
          }, 0);
        };

        if (CoreUtils.isNotDefined(scope.term.value) || CoreUtils.isNotDefined(scope.term.value[0])) {
          scope.term.value = [];
          scope.addNew(false);
        }

        function clearAttachmentMessageWithDelay(): void {
          $timeout(() => {
            if (term && term.definition && term.definition.statusInfo) {
              term.definition.statusInfo.message = null;
              term.definition.statusInfo.error = null;
            }
          }, constants.APP.MESSAGE_TIMEOUT);
        }

        /**
         * Remove attachment from the server and client sides.
         *
         * @param {number} attachmentId - Attachment id which need to remove on the server
         * @param {number} attachmentIndex - Attachment index which need to remove on the client
         * @param {Object} scope
         * @param {Object} term
         */
        function removeAttachment(attachmentId, attachmentIndex, scope, term): void {
          spinnerService.start('Deleting...');

          const url = `${ApiUrlPrefix.BIDS}/bid-attachments/${attachmentId}`;
          apiService.delete(url).pipe(
            tap(() => {
              scope.term.value.splice(attachmentIndex, 1);
              if (scope.term.value.length === 0) {
                scope.addNew(false);
              }
              term.definition.statusInfo.message = null;
              term.definition.statusInfo.error = false;
              scope.refresh();
              clearAttachmentMessageWithDelay();
            }),
            catchError(() => {
              scope.$emit(constants.EVENTS.FILE_UPLOAD_MESSAGE, {
                error: true,
                message: 'Remove operation cannot be performed. An error occurred'
              });

              return of(null);
            }),
            finalize(() => spinnerService.stop())
          ).subscribe();
        }

      },
      getValueToSave: term => {
        const values = [];
        if (term.definition.processKeepOriginalValue && term.definition.keepOriginalValue) {
          return resources.NewBid.KEEP_ORIGINAL_VALUE;
        }
        term.value.forEach(val => {
          if (biddingUtilsService.isDefined(val.attachmentId) && val.attachmentName) {
            values.push({
              attachmentId: val.attachmentId,
              attachmentName: val.attachmentName,
              attachmentSize: val.attachmentSize
            });
          }
        });

        return values;
      },
      changed: (term, otherTerm) => {
        function getAttachmentNames(term): any {
          const attachmentNames = [];
          term.value.forEach(val => {
            if (!biddingUtilsService.isNotDefined(val.attachmentId) && val.attachmentName) {
              attachmentNames.push(val.attachmentName);
            }
          });
          return attachmentNames;
        }

        const attachmentNames = getAttachmentNames(term);
        const otherAttachmentNames = getAttachmentNames(otherTerm);
        return isEqual(attachmentNames, otherAttachmentNames);
      },
      resetValue: term => {
        removeAllAttachments(term.value);
        term.value = [{}];
        term.definition.statusInfo.message = null;
        term.definition.statusInfo.error = null;
        if (term.definition.processKeepOriginalValue) {
          term.definition.keepOriginalValue = true;
          term.definition.placeholder = resources.NewBid.KEEP_ORIGINAL_VALUE;
        }

        function removeAllAttachments(attachments): any {
          attachments.forEach(attachment => {
            if (attachment.attachmentId) {
              apiService.delete(`${ApiUrlPrefix.BIDS}/bid-attachments/${attachment.attachmentId}`);
            }
          });
        }
      },
      canRemoveAttachment: (attachment: Attachment, term) =>
        Boolean(term.definition.keepOriginalValue || attachment?.attachmentId)
    },
    {
      name: constants.SUMMARY_TERMS.WEBSITE_LINKS.label,
      path: [
        constants.BID_SECTIONS.SUMMARY_TERMS, constants.SUMMARY_TERMS.WEBSITE_LINKS.label
      ],
      type: 5,
      allowKeepOriginValue: true,
      // @ts-ignore
      templateFile: `/app/views/bidding/custom/links.html`,
      placeholder: 'http://',
      description: translations.i18n.TermsDescriptions.SUMMARY.LINKS,
      saveToBidMeta: true,
      prepareCustom: scope => {
        scope.addNewElem = (skipChangeRequest = true) => {
          if (scope.term.definition.processKeepOriginalValue && scope.term.value.length > 0) {
            scope.term.definition.placeholder = 'http://';
            scope.term.definition.keepOriginalValue = false;
          }
          scope.term.value.push({
            link: '',
            company: util.extractUserCompanyInformation(userService.user.getValue())
          });

          scope.term.value[skipChangesSymbol] = skipChangeRequest;
        };

        scope.removeElemFromIndex = index => {
          if (scope.term.definition.processKeepOriginalValue) {
            scope.term.definition.placeholder = 'http://';
            scope.term.definition.keepOriginalValue = false;
          }
          if (!biddingUtilsService.isNotDefined(scope.term.value)) {
            if (scope.isLoggedInUserAllowedToEditLink(index)) {
              scope.term.value[skipChangesSymbol] = false;
              scope.term.value.splice(index, 1);
              if (scope.term.value.length === 0) {
                scope.addNewElem(false);
              }
            }
          }
        };

        scope.isLoggedInUserAllowedToEditLink =
          index => isEqual(
            scope.term.value[index].company, util.extractUserCompanyInformation(userService.user.getValue())
          );

        if (CoreUtils.isNotDefined(scope.term.value[0])) {
          scope.term.value = [];
          scope.addNewElem(false);
        }

        /**
         * Redirects the user to the specified link. The solution enforces new tab opening instead of
         * new window for IE browser also.
         *
         * @param link the link to be visited
         */
        scope.prepareHttpUrl = link => util.prepareHttpUrl(link);

        scope.hasLinkValue = link => (!util.isNotDefined(link) && link.length > 0);

        scope.svgIconName = SvgIconName;
      },
      getValueToSave: term => {
        const values = [];
        if (term.definition.processKeepOriginalValue && term.definition.keepOriginalValue) {
          return resources.NewBid.KEEP_ORIGINAL_VALUE;
        }
        term.value.forEach(val => {
          if (!biddingUtilsService.isNotDefined(val.link) && val.link) {
            values.push({
              link: val.link,
              company: val.company
            });
          }
        });

        return values;
      },
      getTermValueForComparison: term => {
        let values = '';
        term.value.forEach(val => {
          if (!biddingUtilsService.isNotDefined(val.link) && val.link) {
            values += `<a target="_blank" href="${util.prepareHttpUrl(val.link)}"> ${val.link}</a> <br />`;
          }
        });

        return values;
      },
      defaultValue: '',
      onFocus: (event, term) => {
        term.definition.placeholder = 'http://';
        event.currentTarget.setAttribute('placeholder', 'http://');
        term.definition.keepOriginalValue = false;
      },
      resetValue: term => {
        term.value = [];
        term.value.push({
          link: '',
          company: util.extractUserCompanyInformation(userService.user.getValue())
        });
        if (term.definition.processKeepOriginalValue) {
          term.definition.placeholder = resources.NewBid.KEEP_ORIGINAL_VALUE;
          term.definition.keepOriginalValue = true;
        }
      }
    },
    {
      name: constants.SUMMARY_TERMS.CONTRACT_START_DATE.label,
      path: [
        constants.BID_SECTIONS.SUMMARY_TERMS, constants.SUMMARY_TERMS.CONTRACT_START_DATE.label
      ],
      resetDrugList: true,
      type: 2,
      allowKeepOriginValue: true,
      defaultValue: '',
      saveToBidMeta: true,
      validate: term => {
        if (term.definition.processKeepOriginalValue && term.definition.keepOriginalValue) {
          return true;
        }
        return util.validateDate(term, undefined, undefined, false);
      },
      description: translations.i18n.TermsDescriptions.SUMMARY.CONTRACT_START_DATE,
      getValueToSave: term => {
        if (term.definition.processKeepOriginalValue && term.definition.keepOriginalValue) {
          return resources.NewBid.KEEP_ORIGINAL_VALUE;
        }
        return StringUtils.isString(term.value)
          ? DateUtils.getDateBasedOnFormattedString(term.value).getTime()
          : term.value;
      },
      resetValue: util.resetValueDate
    },
    {
      name: constants.SUMMARY_TERMS.CONTRACT_END_DATE.label,
      path: [
        constants.BID_SECTIONS.SUMMARY_TERMS, constants.SUMMARY_TERMS.CONTRACT_END_DATE.label
      ],
      resetDrugList: true,
      type: 2,
      allowKeepOriginValue: true,
      defaultValue: '',
      saveToBidMeta: true,
      validate: (endDate, terms) => {
        const startDateName = constants.SUMMARY_TERMS.CONTRACT_START_DATE.label;
        const startDate = terms.find(term => term.name === startDateName);

        if (endDate.definition.processKeepOriginalValue && endDate.definition.keepOriginalValue
          && startDate.definition.keepOriginalValue) {
          return true;
        }

        return util.validateDate(endDate, terms, startDateName, false);
      },
      description: translations.i18n.TermsDescriptions.SUMMARY.CONTRACT_END_DATE,
      getValueToSave: term => {
        if (term.definition.processKeepOriginalValue && term.definition.keepOriginalValue) {
          return resources.NewBid.KEEP_ORIGINAL_VALUE;
        }
        return StringUtils.isString(term.value)
          ? DateUtils.getDateBasedOnFormattedString(term.value).getTime()
          : term.value;
      },
      resetValue: util.resetValueDate
    },
    {
      name: constants.DrugList.LOCK_NOTES,
      path: constants.BID_TERM_PATHS.LOCK_NOTES,
      type: 3,
      saveToBidMeta: true,
      defaultValue: false,
      resetDrugList: true
    },
    {
      name: constants.DrugList.ALLOW_MARKET_BASKET,
      path: constants.DRUG_TERM_PATHS.ALLOW_MARKET_BASKET,
      type: 3,
      saveToBidMeta: true,
      defaultValue: false,
      resetDrugList: true
    },
    {
      name: constants.DrugList.ALLOW_MARKET_SHARE,
      path: constants.DRUG_TERM_PATHS.ALLOW_MARKET_SHARE,
      type: 3,
      saveToBidMeta: true,
      defaultValue: false,
      resetDrugList: true
    },
    {
      name: constants.DrugList.ALLOW_OFFERS_AT_NDC_LEVEL,
      path: constants.DRUG_TERM_PATHS.ALLOW_NDC_OFFERS,
      type: 3,
      saveToBidMeta: true,
      defaultValue: false,
      resetDrugList: true
    },
    {
      name: constants.SUMMARY_TERMS.CONTRACT_EVERGREEN.label,
      path: [
        constants.BID_SECTIONS.SUMMARY_TERMS, constants.SUMMARY_TERMS.CONTRACT_EVERGREEN.label
      ],
      type: 1,
      allowKeepOriginValue: true,
      values: ['Yes', 'No'],
      defaultValue: 'No',
      saveToBidMeta: true,
      description: translations.i18n.TermsDescriptions.SUMMARY.CONTRACT_EVERGREEN,
      getTermValueForComparison: term => term.value ? 'Yes' : 'No',
      getValueToSave: term => {
        if (term.definition.processKeepOriginalValue && term.definition.keepOriginalValue) {
          return resources.NewBid.KEEP_ORIGINAL_VALUE;
        }
        return term.value === 'Yes';
      },
      getValueForView: (term) => {
        if (typeof term.value === 'string') {
          return term.value === 'Yes' ? 'Yes' : 'No';
        }
        return term.value ? 'Yes' : 'No';
      },
      resetValue: util.resetValueDropdown
    },
    {
      name: constants.SUMMARY_TERMS.INSTRUCTIONS.label,
      path: [
        constants.BID_SECTIONS.SUMMARY_TERMS, constants.SUMMARY_TERMS.INSTRUCTIONS.label
      ],
      type: 5,
      allowKeepOriginValue: true,
      // @ts-ignore
      templateFile: `/app/views/bidding/custom/instructions.html`,
      class: 'input-medium',
      tinyMceOptions: {
        statusbar: false,
        menubar: false,
        base_url: '/tinymce',
        suffix: '.min',
        format: 'html',
        trusted: 'true',
        fontsize_formats: '80%=11.2px 100%=14px 120%=16.8px 140%=19.6px 160%=22.4px 200%=28px',
        toolbar: 'undo redo | bold italic underline strikethrough | ' +
          'bullist numlist outdent indent | alignleft aligncenter alignright alignjustify | ' +
          'forecolor backcolor | link | styleselect | fontselect fontsizeselect | code ',
        plugins: ['advlist autolink lists link charmap print preview anchor',
                  'searchreplace visualblocks code',
                  'insertdatetime paste']
      },
      description: translations.i18n.TermsDescriptions.SUMMARY.INSTRUCTIONS,
      validate: (term) => {
        const errorMsg = `${term.name} are limited to ${
          constants.INSTRUCTIONS_MAX_LENGTH} characters. Please edit the text.`;

        return util.validateLength(term, constants.INSTRUCTIONS_MAX_LENGTH, errorMsg);
      },
      prepare: (term, settings) => {
        if (!util.isNotDefined(settings.manufacturerCompanyName)) {
          term.definition.displayName = translations.i18n.Instructions.PLACEHOLDER + settings.manufacturerCompanyName;
        }
      },
      prepareCustom: scope => {

        scope.addNewElem = () => {
          scope.term.value = {
            text: ''
          };
        };

        if (CoreUtils.isNotDefined(scope.term.value)) {
          scope.addNewElem();
        }

        /**
         * Validates the instructions term when editing it.
         *
         * @param term - the term to be validated
         */
        scope.validateTerm = term => {
          if (term.definition.validate && !biddingUtilsService.isNotDefined(term.value)) {
            term.definition.validate(term);
          }
        };
      },
      getValueToSave: term => {
        if (term.definition.processKeepOriginalValue && term.definition.keepOriginalValue) {
          return resources.NewBid.KEEP_ORIGINAL_VALUE;
        }
        return {
          text: term.value.text
        };
      },
      getTermValueForComparison: term =>
        (biddingUtilsService.isNotDefined(term.value)
          || biddingUtilsService.isNotDefined(term.value.text)) ? '' : term.value.text
          .trim(),
      defaultValue: {
        text: '<p></p>'
      },
      isUpdateAllowed: (viewAs) => {
        return biddingUtilsService.isCurrentUserPayer(userService.user.getValue())
          || viewAs === constants.RoleTypes.PAYER;
      },
      resetValue: term => {
        if (term.definition.processKeepOriginalValue) {
          term.definition.reset = true;
          term.value = { text: `<p>${resources.NewBid.KEEP_ORIGINAL_VALUE}</p>` };
        }
      }
    },
    {
      name: constants.SUMMARY_TERMS.COMMENTS.label,
      path: [
        constants.BID_SECTIONS.SUMMARY_TERMS, constants.SUMMARY_TERMS.COMMENTS.label
      ],
      type: 5,
      // @ts-ignore
      templateFile: `/app/views/bidding/custom/comments.html`,
      saveToBidMeta: true,
      description: translations.i18n.TermsDescriptions.SUMMARY.COMMENTS,
      validate: (term) => {
        const errorMsg = `${term.name} are limited to ${
          constants.COMMENTS_MAX_LENGTH} characters. Please edit the text.`;
        return util.validateCommentsLength(term, errorMsg);
      },
      prepare: (term, settings) => {
        const manufCompName = settings.manufacturerCompanyName;
        const payerCompName = settings.payerCompanyName;

        if (settings.viewAs === constants.COMPANY_TYPES.PAYER.value && manufCompName) {
          term.definition.displayName = translations.i18n.Comments.PLACEHOLDER + manufCompName;
        } else if (settings.viewAs === constants.COMPANY_TYPES.PHARMA.value && payerCompName) {
          term.definition.displayName = translations.i18n.Comments.PLACEHOLDER + payerCompName;
        }
      },
      prepareCustom: scope => {
        // Update comments object with correct companyDisplayName
        let manuf = {};
        if (scope.$parent.$parent && scope.$parent.$parent.bidInfo) {
          manuf = scope.$parent.$parent.bidInfo.manufacturer;
        }
        if (scope.term.value && scope.term.value.historic) {
          scope.term.value.historic = biddingUtilsService.getDisplayCompanyName(scope.term.value.historic, manuf);
        }
        if (angular.isUndefined(scope.term.value) || CoreUtils.isEmpty(scope.term.value)) {
          scope.term.value = {};
          scope.term.value.historic = [];
          scope.term.value.currentValue = '';
        }
        scope.term.definition.userCompanyName = util.getUserCompanyName(userService.user.getValue());
        scope.term.definition.isBidStatusRFPNotSent =
          biddingUtilsService.isBidDisplayedStatusRFPNotSent(scope.getBidStatus());
      },
      clearHistoric: term => {
        term.value.historic = [];
      },
      getValueToSave: term => {
        let termCopy, values;
        termCopy = { value: angular.copy(term.value) };
        // Delete 'companyDisplayName' before saving to db
        termCopy.value.historic = biddingUtilsService.removeDisplayCompanyName(termCopy.value);
        return termCopy.value;
      },
      getTermValueForComparison: term => {
        let value = '';
        if (!biddingUtilsService.isNotDefined(term.value) && !biddingUtilsService.isNotDefined(term.value.historic)
          && !CoreUtils.isEmpty(term.value.historic)) {
          value += '<div class="comments-section">';
          term.value.historic.forEach(comment => {
            value += '<span class="comments spacer bottom-margin">';
            // TODO: qu-5199: Here we get '.oldCompanyName' from backend unlike in other methods
            value += `<div class='bolded ng-binding font-size-mini purple'>${
              biddingUtilsService.addOldName(comment.companyName, comment.oldCompanyName)} - ${
              comment.formattedDate}</div>`;
            value += `<span>${comment.text}</span>`;
            value += '</span>';
          });
          value += '</div>';
        }
        return value;
      }
    }
  ];
// tslint:disable-next-line:max-file-line-count
}
