import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DrugAlertType } from '@qv-bid/enums';
import { SvgIconName } from '@qv-common/enums';
import { DrugAlertNotification } from '@qv-bid/models';

@Component({
  selector: 'qv-standard-drug-alert-notification',
  templateUrl: './standard-drug-alert-notification.component.html',
  styleUrls: ['./standard-drug-alert-notification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StandardDrugAlertNotificationComponent {
  @Input()
  public notification: DrugAlertNotification;

  public readonly drugAlertType = DrugAlertType;
  public readonly svgIconName = SvgIconName;
}
