import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ContractedBusinessManageModalComponent } from '@qv-bid/components/shared/cb-manage-modal/cb-manage-modal.component';
import { ContractBusinessModalData } from '@qv-bid/components/shared/cb-manage-modal/models/cb-manage-modal-data';
import { ModalConfig, ModalSize } from 'quantuvis-angular-common/modal';

export class ContractedBusinessManageModalConfig
  extends ModalConfig<ContractedBusinessManageModalComponent, ContractBusinessModalData> {

  constructor(data: ContractBusinessModalData) {
    super(data, ModalSize.X_SMALL);
  }

  protected createDialogRef(dialog: MatDialog): MatDialogRef<ContractedBusinessManageModalComponent> {
    return dialog.open(ContractedBusinessManageModalComponent, this);
  }
}
