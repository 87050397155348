import {
  HTTP_INTERCEPTORS,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Inject, Injectable, Provider } from '@angular/core';
import { ErrorMessage } from '@qv-common/enums';
import { LOCATION_TOKEN } from 'quantuvis-angular-common/window-utils';
import { GeneralModalData, ModalService } from 'quantuvis-angular-common/modal';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class NoConnectionInterceptor implements HttpInterceptor {
  private isDialogOpened: boolean;

  constructor(private modalService: ModalService, @Inject(LOCATION_TOKEN) private location: Location) {
  }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(catchError((err: HttpErrorResponse) => {
      if (!err.status && !this.isDialogOpened) {
        this.handleNoConnection();

        return throwError(new Error(ErrorMessage.NO_CONNECTION));
      }

      return throwError(err);
    }));
  }

  private handleNoConnection(): void {
    const modalData = new GeneralModalData(
      'Confirmation',
      ErrorMessage.NO_CONNECTION_RELOAD_CONFIRMATION,
      'Ok',
      'Cancel');

    this.isDialogOpened = true;
    this.modalService.openConfirmModal(modalData)
      .subscribe((result: boolean) => {
        this.isDialogOpened = false;
        if (result) {
          this.location.reload();
        }
      });
  }
}

export const noConnectionInterceptorProvider: Provider = {
  provide: HTTP_INTERCEPTORS,
  useClass: NoConnectionInterceptor,
  multi: true
};
