<div class="standard-scenario-section"
  *ngIf="isMarketShareAvailable(isUserPharma, marketShare.state)"
  [class.standard-scenario-section--hidden]="isHidden(marketShare?.state)"
  [class.standard-scenario-section--edit-mode]="isEditMode">
  <qv-standard-scenario-section-title
    [title]="'Market Share'"
    [state]="marketShare.state.value"
    [isAtNdc]="marketShare.state.isNdc"
    [isEditMode]="isEditMode"
    [sectionName]="sectionName"
    [tooltip]="resources.TermsDescriptions.DRUG_LIST.MARKET_SHARE">
  </qv-standard-scenario-section-title>
  <div class="standard-scenario-section__terms standard-scenario-section__terms--market-share">
    <qv-standard-cell class="standard-scenario-section__term"
      data-test-id="test-id-market-share-tier"
      [name]="drugTermsConstants[termName.MARKET_SHARE_TIER].displayNames.MARKET_SHARE_TIER"
      [termName]="termName.MARKET_SHARE_TIER">
      <qv-term-select
        class="term-select term-select--market-share-tier"
        [isPending]="isBidInvalid"
        [appendTo]="dropDownAppendTo"
        [termName]="termName.MARKET_SHARE_TIER"
        [control]="getFormControlByTermName(termName.MARKET_SHARE_TIER)"
        [isEditMode]="isEditable(isEditMode, isUserPharma, marketShare.state)"
        [termValue]="marketShare.marketShareTier">
      </qv-term-select>
    </qv-standard-cell>
    <qv-standard-cell class="standard-scenario-section__term standard-scenario-section__term--range-or-units-and-rebates"
      data-test-id="test-id-range-or-units-and-rebates"
      [name]="drugTermsConstants[termName.RANGE_OR_UNITS_AND_REBATES].displayNames.RANGE_OR_UNITS_AMPERSAND_REBATES"
      [termName]="termName.RANGE_OR_UNITS_AND_REBATES">
      <mat-icon *ngIf="rouarChipLoadingAction$ | async" class="term-loading-spinner" top-right-block [svgIcon]="svgIconName.SPINNER"></mat-icon>
      <ng-template
        [qvAtNdc]="marketShare.rangeOrUnitAndRebates"
        [setDisableClick]="!isRouarAtNdcClickable(isEditMode, isUserPharma, marketShare.state, isBidInvalid, marketShare.marketShareTier.isNdc)"
        (overwriteValuesConfirmed)="overwriteRouarValue()">
        <div *ngIf="!marketShare.marketShareTier.isNdc" class="market-share-range-or-units-and-rebates-value">
          <qv-range-or-unit-and-rebate
            *ngFor="let rangeOrUnitAndRebate of marketShare.rangeOrUnitAndRebates?.value; let i = index; trackBy: trackByIdFn"
            attr.data-test-id="test-id-market-share-range-or-units-and-rebates-value-{{i}}"
            [isEditMode]="isEditMode"
            [isBidInvalid]="isBidInvalid"
            [marketShareTier]="marketShare.marketShareTier"
            [rangeOrUnitAndRebate]="rangeOrUnitAndRebate"
            (valueUpdated)="rangeUnitsAndRebateUpdateHandler($event)"
            (valueRemoved)="onRemoveRouarHandler($event)">
          </qv-range-or-unit-and-rebate>
          <button
            mat-icon-button
            class="market-share-range-or-units-and-rebates-value__action"
            data-test-id="test-id-range-or-units-and-rebates-action"
            [disabled]="isBidInvalid"
            *ngIf="isAddRouarBtnVisible(isEditMode, !isAddRouarDisabled(isEditMode, marketShare.state, marketShare.marketShareTier, marketShare.rangeOrUnitAndRebates?.value.length))"
            (click)="onAddRouarHandler($event)">
            <mat-icon [svgIcon]="svgIconName.ADD"></mat-icon>
          </button>
        </div>
      </ng-template>
    </qv-standard-cell>
  </div>
</div>
