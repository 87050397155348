import { SelectBoxTerm } from '@qv-term/models/types';
import { constants, resources } from '@qv-common/static';
import { LineOfBusiness } from '@qv-term/enums/options';

export class LineOfBusinessTerm extends SelectBoxTerm {
  constructor(name: string) {
    super(
      name,
      constants.SUMMARY_TERMS.LINE_OF_BUSINESS.label,
      resources.TermsDescriptions.SUMMARY.LINE_OF_BUSINESS,
      constants.SUMMARY_TERMS.RFP_TITLE.label,
      '',
      LineOfBusiness.getValue(LineOfBusiness.MEDICARE),
      'term__line-of-business'
    );

    this.values = LineOfBusiness.getValues();
  }
}
