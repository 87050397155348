<div *blockUI="'general-modal'">
  <div mat-dialog-title class="qv-modal-dialog-title">
      <div class="qv-modal-dialog-title__text">{{ modalData.title }}</div>
      <div class="qv-modal-dialog-title__xbutton" (click)="onSecondaryAction()">
        <mat-icon>close</mat-icon>
      </div>
  </div>
  <div mat-dialog-content class="qv-modal-dialog-content">
    <div
      *ngIf="modalData.message"
      class="qv-modal-dialog-content__message"
      [innerHTML]="modalData.message"
    ></div>
  </div>
  <div mat-dialog-actions class="qv-modal-dialog-actions">
    <button
      *ngIf="modalData.secondaryActionText"
      mat-button
      tabindex="-1"
      class="qv-modal-dialog-actions__item"
      (click)="onSecondaryAction()"
    >{{ modalData.secondaryActionText }}</button>
    <button
      mat-button
      tabindex="-1"
      class="qv-modal-dialog-actions__item qv-modal-dialog-actions__item--primary"
      (click)="onPrimaryAction()"
    >{{ modalData.primaryActionText }}</button>
  </div>
</div>
