import { Pipe, PipeTransform } from '@angular/core';
import { ChoiceOptions } from '@qv-shared/models';

@Pipe({
  name: 'booleanChoice'
})
export class BooleanChoicePipe implements PipeTransform {

  public transform(value: boolean, { firstOption, secondOption }: ChoiceOptions): string {
    if (typeof value !== 'boolean') return '';
    return value === true ? firstOption : secondOption;
  }

}
