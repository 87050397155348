/* tslint:disable:only-arrow-functions triple-equals */
/**
 *  Range or units custom term definition
 */
import { constants } from '@qv-common/static';
import { CoreUtils, StringUtils } from '@qv-common/utils';
import { drugTermsConstants } from '@qv-term/constants';
import { TermName } from '@qv-term/enums';

declare let angular: angular.IAngularStatic;

export function RangeOrUnitsAndRebates(util, translations) {

  function getTermDisplayValue(term, asHtml): string {
    let value = asHtml ? '<table>' : '';

    const suffix = (util.isNotDefined(term.definition.suffix) || CoreUtils.isEmpty(term.definition.suffix))
      ? ''
      : ` ${term.definition.suffix}`;
    const currency = (util.isNotDefined(term.definition.currency) || CoreUtils.isEmpty(term.definition.currency))
      ? ''
      : term.definition.currency;

    term.value.forEach((termValue, index, list) => {
      const rangeStart = StringUtils.escape(termValue.range_start);
      const rangeEnd = StringUtils.escape(termValue.range_end);
      const rebate = StringUtils.escape(termValue.rebate);
      if (!util.isNotDefined(termValue) && (!util.isNotDefined(rangeStart) && !CoreUtils.isEmpty(rangeStart)
        || !util.isNotDefined(rangeEnd) && !CoreUtils.isEmpty(rangeEnd)
        || !util.isNotDefined(rebate) && !CoreUtils.isEmpty(rebate))) {
        if (!asHtml) {
          value += `${currency + rangeStart + suffix}&nbsp;&nbsp;-&nbsp;&nbsp;${
            currency}${rangeEnd}${suffix}&nbsp;&nbsp;&nbsp;&nbsp;${rebate}%`;
          value += index + 1 != list.length ? '<br/>' : '';
        } else {

          value +=
            // tslint:disable-next-line:max-line-length
            `<tr><td class="no border align right">${currency}${rangeStart}${suffix}</td><td class="no border">&nbsp;&nbsp;-&nbsp;&nbsp;</td><td class="no border align right">${currency}${rangeEnd}${suffix}</td><td class="no border align right"><span class="spacer left-padding">${rebate}%</span></td></tr>`;
        }
      }
    });

    if (asHtml) {
      value += '</table>';
    }
    return value;
  }

  function getDefaultElement(): any {
    return {
      range_start: '',
      range_end: '',
      rebate: ''
    };
  }


  return {
    name: drugTermsConstants[TermName.RANGE_OR_UNITS_AND_REBATES].title,
    groupPath: constants.DRUG_TERM_PATHS.ALLOW_MARKET_SHARE_REBATE,
    type: 5,
    // @ts-ignore
    templateFile: `/app/views/bidding/custom/rangeorunitsandrebates.html`,
    disabled: false,
    description: translations.i18n.TermsDescriptions.DRUG_LIST.RANGE_OR_UNITS,
    hasDependencies: true,
    valueDependencies: [
      drugTermsConstants[TermName.MARKET_SHARE_TIER].title
    ],
    dependsOn: [
      drugTermsConstants[TermName.MARKET_SHARE_TIER].title
    ],
    resetValue: (term, propagate) => {
      term.value = [
        getDefaultElement()
      ];

      if (propagate != false) {
        const valueTerm = term.definition.list[drugTermsConstants[TermName.MARKET_SHARE_TIER].title];

        if (valueTerm) {
          valueTerm.definition.resetValue(valueTerm, false);
        }
      }
    },
    prepareCustom: scope => {
      if (CoreUtils.isNotDefined(scope.term.value) || CoreUtils.isNull(scope.term.value)) {
        scope.term.value = [
          getDefaultElement()
        ];
      }

      scope.rebateValidation = {
        min: -999.99999,
        max: 999.99999,
        decimals: 5,
        minDecimals: 2,
        step: 0.0001
      };

      scope.addElem = () => {
        scope.term.value.push(getDefaultElement());
      };

      scope.removeElem = idx => {
        if (idx === 0 && scope.term.value.length === 1) {
          // do not remove just reset the values
          scope.term.value[0] = getDefaultElement();
        } else {
          scope.term.value.splice(idx, 1);
        }

        if (scope.term && scope.term.definition && scope.term.definition.onChange) {
          scope.term.definition.onChange(scope.term);
        }
      };
    },
    setValue: (term, newValue) => {
      term.value = angular.copy(newValue);
    },
    isEmpty: term => {
      const termValue = term.value;
      let empty = true;
      let rangeStart, rangeEnd, rebate;
      if (CoreUtils.isDefined(termValue)) {
        if (termValue instanceof Array) {
          for (let i = 0; i < termValue.length && empty; i++) {
            rangeStart = termValue[i].range_start;
            rangeEnd = termValue[i].range_end;
            rebate = termValue[i].rebate;
          }
        } else {
          rangeStart = termValue.range_start;
          rangeEnd = termValue.range_end;
          rebate = termValue.rebate;
        }

        if (rangeStart || !CoreUtils.isEmpty(rangeStart) ||
          rangeEnd || !CoreUtils.isEmpty(rangeEnd) ||
          rebate || !CoreUtils.isEmpty(rebate)) {
          empty = false;
        }
      }
      return empty;
    },
    getValueToSave: term => {
      const value = [];
      term.value.forEach(termValue => {
        value.push({
          range_start: termValue.range_start,
          range_end: termValue.range_end,
          rebate: termValue.rebate
        });
      });
      return value;
    },
    getTermValueForComparison: term => getTermDisplayValue(term, true),
    getCustomValue: term => getTermDisplayValue(term, true),
    defaultValue: [getDefaultElement()],
    groupStyle: 'not-inline'

  };

}
