import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { MarketBasketDrug, MarketBasketTemplate } from 'market-basket/market-basket-common';
import { Page } from 'quantuvis-angular-common/api';

@Component({
  selector: 'qv-market-basket-template-list',
  templateUrl: './market-basket-template-list.component.html',
  styleUrls: ['./market-basket-template-list.component.scss']
})
export class MarketBasketTemplateListComponent implements OnChanges {
  @Input()
  public excludedMarketBasketTemplate: MarketBasketTemplate;

  @Input()
  public templatePage: Page<MarketBasketTemplate>;

  @Input()
  public isPaginationDisabled: boolean;

  @Output()
  public templateSelected = new EventEmitter<MarketBasketTemplate>();

  @Output()
  public changePage = new EventEmitter<number>();

  public templateList: MarketBasketTemplate[] = [];
  public selectedTemplate: MarketBasketTemplate;

  public readonly pageSize = 25;

  public total: number;
  public pageIndex: number;
  public isPaginatorVisible = false;

  public ngOnChanges({ templatePage }: SimpleChanges): void {
    if (templatePage?.currentValue) {
      this.updatePaginator(templatePage.currentValue);
    }
  }

  public onListItemSelect(template: MarketBasketTemplate): void {
    this.selectedTemplate = template;
    this.templateSelected.emit(template);
  }

  public onChangePage({ pageIndex }: { pageIndex: number }): void {
    this.changePage.emit(pageIndex);
  }

  private updatePaginator({ content, totalElements, currentPage }: Page<MarketBasketTemplate>): void {
    this.templateList = this.excludedMarketBasketTemplate ? this.filterTemplateList(content) : content;
    this.total = totalElements;
    this.pageIndex = currentPage;
    this.isPaginatorVisible = this.total > this.pageSize;
  }

  private filterTemplateList(templateList: MarketBasketTemplate[]): MarketBasketTemplate[] {
    return templateList.filter((template: MarketBasketTemplate) =>
      this.isTemplateDifferWithCurrentMarketBasket(template));
  }

  private isTemplateDifferWithCurrentMarketBasket(template: MarketBasketTemplate): boolean {
    return template.name !== this.excludedMarketBasketTemplate.name ||
      this.isDrugListsDiffer(template.drugs, this.excludedMarketBasketTemplate.drugs);
  }

  private isDrugListsDiffer(drugList1: MarketBasketDrug[], drugList2: MarketBasketDrug[]): boolean {
    return (drugList1.length !== drugList2.length)
      || drugList1.some((mbDrug: MarketBasketDrug) => this.isDrugMissingInList(mbDrug, drugList2));
  }

  private isDrugMissingInList(mbDrug: MarketBasketDrug, drugList: MarketBasketDrug[]): boolean {
    return !drugList.find((drug: MarketBasketDrug) => drug.isMatchNameAndClass(mbDrug.name, mbDrug.drugClass));
  }
}
