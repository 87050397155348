import { Injectable } from '@angular/core';
import { CoreUtils } from '@qv-common/utils';
import { BehaviorSubject } from 'rxjs';
import isEqual from 'lodash.isequal';
import { BidEventBusService } from '@qv-bid/services/bid-event-bus.service';
import { BidFilterName } from '@qv-bid/enums';
import { FilterState } from '@qv-bid/models';
import { Range } from '@qv-common/entities';
import { Moment } from 'moment';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

type CheckboxOrRange = (number | string)[] | Range<number | Moment>;

@UntilDestroy()
@Injectable()
export class BidFilterService {
  public isFilterChanged$ = new BehaviorSubject<boolean>(false);
  public isFilterApplied$ = new BehaviorSubject<boolean>(false);
  private readonly filterState$ = new BehaviorSubject<FilterState>(new FilterState());
  private readonly appliedFilterState$ = new BehaviorSubject<FilterState>(new FilterState());

  constructor(private bidEventBusService: BidEventBusService) {
    this.initResetFilterHandler();
  }

  public updateFilter(name: BidFilterName, value?: CheckboxOrRange): void {
    this.filterState$.next(this.patchState(name, value));
    this.isFilterChanged$.next(this.isFilterChanged());
  }

  public applyFilter(): void {
    const isFilterChanged = this.isFilterChanged();

    if (isFilterChanged) {
      this.appliedFilterState$.next(this.filterState$.getValue());
      this.bidEventBusService.applyFilterEvent.emit();
    }

    this.isFilterChanged$.next(false);
    this.isFilterApplied$.next(!this.isFilterEmpty());
  }

  public resetFilter(): void {
    this.filterState$.next(new FilterState());
    this.applyFilter();
  }

  public resetToAppliedState(): void {
    this.bidEventBusService.resetFilterToAppliedStateEvent.next();
    this.filterState$.next(this.appliedFilterState$.getValue());
    this.isFilterChanged$.next(false);
    this.isFilterApplied$.next(!this.isFilterEmpty());
  }

  public getFilterStateValue(): FilterState {
    return this.filterState$.value;
  }

  public getAppliedFilterStateValue(): FilterState {
    return this.appliedFilterState$.value;
  }

  private initResetFilterHandler(): void {
    this.bidEventBusService.resetFilterEvent
      .pipe(untilDestroyed(this))
      .subscribe(() => this.resetFilter());
  }

  private patchState(name: BidFilterName, value: CheckboxOrRange): FilterState {
    const state = Object.assign(new FilterState(), this.getFilterStateValue(), {
      [name]: value
    });

    if (CoreUtils.isNotDefined(value) || (value instanceof Range && value.isEmpty())) {
      delete state[name];
    }

    return state;
  }

  private isFilterChanged(): boolean {
    return !isEqual(this.filterState$.getValue(), this.appliedFilterState$.getValue());
  }

  private isFilterEmpty(): boolean {
    return !Object.keys(this.getFilterStateValue()).length;
  }
}
