import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MarketBasketModalComponent } from '../components/market-basket/market-basket-modal/market-basket-modal.component';
import { MarketBasketModalData } from './market-basket-modal-data';
import { ModalConfig, ModalSize } from 'quantuvis-angular-common/modal';

export class MarketBasketModalConfig extends ModalConfig<MarketBasketModalComponent, MarketBasketModalData> {

  constructor(marketBasketModalData: MarketBasketModalData) {
    super(marketBasketModalData, ModalSize.MEDIUM);
  }

  protected createDialogRef(dialog: MatDialog): MatDialogRef<MarketBasketModalComponent> {
    return dialog.open(MarketBasketModalComponent, this);
  }
}
