import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  ContactUsModalComponent,
  CopyrightComponent,
  OpenSourceSoftwareComponent,
  PrivacyPolicyComponent,
  TermsOfServiceComponent
} from './components';
import { TermsAndConditionsService } from './services';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '@qv-shared/shared.module';
import { BlockUIModule } from 'ng-block-ui';
import { TermsAndConditionsEventBusService } from './services/terms-and-conditions-event-bus.service';
import { AngularComponentsCommonModule } from 'angular-components-common';

@NgModule({
  imports: [
    AngularComponentsCommonModule,
    BlockUIModule,
    CommonModule,
    FormsModule,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    ReactiveFormsModule,
    SharedModule,
  ],
  declarations: [
    ContactUsModalComponent,
    CopyrightComponent,
    OpenSourceSoftwareComponent,
    PrivacyPolicyComponent,
    TermsOfServiceComponent,
  ],
  exports: [
    ContactUsModalComponent,
    CopyrightComponent,
    OpenSourceSoftwareComponent,
    PrivacyPolicyComponent,
    TermsOfServiceComponent,
  ],
  providers: [
    TermsAndConditionsService,
    TermsAndConditionsEventBusService
  ]
})

export class TermsAndConditionsModule {}
