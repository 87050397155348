import { InjectionToken } from '@angular/core';

export interface BidViewConfig {
  limit: number;
  buffer: number;
  scenarioLoadingDebounceInterval: number;
  scenarioRenderingDebounceInterval: number;
  scenarioNameUpdateDelayTime: number;
}

export const BID_VIEW_CONFIG = new InjectionToken<BidViewConfig>('BID_VIEW_CONFIG');
