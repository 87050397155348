import { Inject, Injectable } from '@angular/core';
import { environment } from '@qv-env/environment';
import { User } from '@qv-user/entities/user.entity';
import { UserType } from '@qv-user/enums/user-type.enum';
import { NgxPendoService } from 'ngx-pendo';
import { LOCAL_STORAGE_TOKEN, LOCATION_TOKEN, SESSION_STORAGE_TOKEN } from 'quantuvis-angular-common/window-utils';

@Injectable({
  providedIn: 'root'
})
export class PendoAnalyticsService {
  public readonly pendoTabIdKey = 'pendo_tabId';
  public readonly pendoVisitorIdKey = `_pendo_visitorId.${environment.pendo.apiKey}`;
  public readonly pendoMetaKey = `_pendo_meta.${environment.pendo.apiKey}`;
  public readonly pendoAccountIdKey = `_pendo_accountId.${environment.pendo.apiKey}`;

  constructor(
    private ngxPendoService: NgxPendoService,
    @Inject(LOCATION_TOKEN) private location: Location,
    @Inject(LOCAL_STORAGE_TOKEN) private localStorage: Storage,
    @Inject(SESSION_STORAGE_TOKEN) private sessionStorage: Storage
  ) { }

  public init(user?: User): void {
    if (environment.production && environment.pendo.enabled) {
      this.checkCachedData(user);

      this.ngxPendoService.initialize({
        id: this.getVisitorId(user),
        role: this.getVisitorRole(user)
      }, {
        id: this.getAccountId(user),
        companyName: this.getAccountName(user)
      });
    }
  }

  private checkCachedData(user: User): void {
    const isAccountDataStored = this.localStorage.getItem(this.pendoAccountIdKey);

    if (!user && isAccountDataStored) {
      this.clearStorageData();
    }
  }

  private clearStorageData(): void {
    // removing those elements allows Pendo generate anonymous visitor id without accountId, so
    // only 1 guest user would be created
    this.sessionStorage.removeItem(this.pendoTabIdKey);
    this.localStorage.removeItem(this.pendoVisitorIdKey);
    this.localStorage.removeItem(this.pendoAccountIdKey);
    this.localStorage.removeItem(this.pendoMetaKey);
  }

  private getVisitorId(user?: User): string {
    return user ? `${this.location.host} (userId: ${user.id})` : '';
  }

  private getAccountId(user?: User): string {
    return user ? `${this.location.host} (companyId: ${user.companyId})` : '';
  }

  private getAccountName(user?: User): string {
    return user && user.company ? user.company.name : '';
  }

  private getVisitorRole(user?: User): string {
    return user ? UserType.displayValue(user.userType) : '';
  }
}
