import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('NdcValue')
export class NdcValue<T> {
  @JsonProperty('value')
  public value: T;

  @JsonProperty('isNdc', Boolean)
  public isNdc = false;

  constructor(value: T, isNdc?: boolean) {
    this.value = value;
    this.isNdc = isNdc;
  }
}
