import { IPageInfo } from 'ngx-virtual-scroller';
import { Scenario } from '@qv-bid/entities';

export class VirtualScrollUtil {
  public static shouldFetchMoreScenariosWithDrugs(
    event: IPageInfo,
    scenarios: Scenario[],
    buffer: number,
    isLoading: boolean): boolean {
    if (isLoading || event.endIndex === -1 || scenarios && !scenarios.length) return false;

    return !scenarios[event.endIndex].drug ||
      !scenarios[event.startIndex].drug ||
      this.isBeforeBufferScenarioHasNoDrug(event, scenarios, buffer) ||
      this.isAfterBufferScenarioHasNoDrug(event, scenarios, buffer);
  }

  public static isBeforeBufferScenarioHasNoDrug(event: IPageInfo, scenarios: Scenario[], buffer: number): boolean {
    const index = event.endIndex - buffer;
    if (index <= 0) return false;
    const firstSlicedRecord = scenarios.slice(index, event.endIndex)[0];
    return !firstSlicedRecord.drug;
  }

  public static isAfterBufferScenarioHasNoDrug(event: IPageInfo, scenarios: Scenario[], buffer: number): boolean {
    const endIndex: number = event.endIndex;
    const lastSlicedRecord = scenarios.slice(endIndex, endIndex + buffer).pop();
    return !lastSlicedRecord.drug;
  }

  public static getEndScenarioBuffer(event: IPageInfo, scenarios: Scenario[], buffer: number): number {
    let { endIndex } = event;

    if (this.isBeforeBufferScenarioHasNoDrug(event, scenarios, buffer)) {
      endIndex -= buffer;
    } else if (scenarios[event.endIndex].drug) {
      endIndex += buffer;
    }

    return endIndex;
  }
}
