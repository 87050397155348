<mat-button-toggle-group
    (change)="onChangeView($event)"
    [value]="state"
    class="qv-toggle-button">
  <mat-button-toggle
      [matTooltip]="primaryButtonConfig.tooltip"
      [value]="primaryButtonConfig.value"
      attr.data-test-id="{{primaryButtonConfig.title}}-toggle-button"
  >
    <mat-icon [svgIcon]="primaryButtonConfig.icon"></mat-icon>
    <span class="title">{{primaryButtonConfig.title}}</span>
  </mat-button-toggle>
  <mat-button-toggle
      [matTooltip]="secondaryButtonConfig.tooltip"
      [value]="secondaryButtonConfig.value"
      attr.data-test-id="{{secondaryButtonConfig.title}}-toggle-button"
  >
    <mat-icon [svgIcon]="secondaryButtonConfig.icon"></mat-icon>
    <span class="title">{{secondaryButtonConfig.title}}</span>
  </mat-button-toggle>
</mat-button-toggle-group>
