import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges, } from '@angular/core';
import { Bid, Summary } from '@qv-bid/entities';
import { BidFormService } from '@qv-bid/services';
import { BidEventBusService } from '@qv-bid/services/bid-event-bus.service';
import { SummaryFormService, SummaryService } from '@qv-bid/services/summary';
import { constants, resources } from '@qv-common/static';
import { CoreUtils, DateUtils } from '@qv-common/utils';
import { BidInfoService } from '@qv-bid/services/summary/bid-info.service';
import { BidUtils } from '@qv-bid/utils';
import { TermName, TermSection } from '@qv-term/enums';
import moment from 'moment';
import { Moment } from 'moment';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ViewPerspectiveService } from '@qv-common/services/auth';
import { SnackBarService } from 'quantuvis-angular-common/snack-bar';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, take, tap } from 'rxjs/operators';
import { User } from '@qv-user/entities/user.entity';
import { FormGroup } from '@angular/forms';
import { BidInfoFormData } from '@qv-bid/components/shared/summary-panel/bid-info/interfaces';
import { BindingBidType } from '@qv-term/enums/options';
import { BidStatus } from 'quantuvis-core-entities';
import {
  InvalidContractDateData,
  InvalidContractDateModalConfig,
  InvalidContractDateModalData
} from '@qv-bid/components/shared/invalid-contract-date-modal/models';
import { SingleNotificationService } from '@qv-shared/services';
import { HttpErrorResponse } from '@angular/common/http';
import { HttpStatusCode } from 'quantuvis-angular-common/api';
import { BidStateService } from '@qv-bid/services';
import { ValidationError } from '@qv-common/models';
import { BaseBidInfoComponent } from '@qv-bid/components/shared';
import { QvCache } from '@qv-common/decorators';
import { PermissionService } from '@qv-common/services/auth/permission.service';
import { RfpDueDateTerm, RfpTitleTerm } from '@qv-term/models/summary';
import { SingleNotification } from '@qv-shared/classes';
import { Toast } from 'ngx-toastr';
import { ModalService } from 'quantuvis-angular-common/modal';
import { NotificationService } from 'quantuvis-angular-common/notification';
import { UserService } from '@qv-common/services/auth/user.service';

@UntilDestroy()
@Component({
  selector: 'qv-bid-info',
  templateUrl: './bid-info.component.html',
  styleUrls: ['./bid-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BidInfoComponent extends BaseBidInfoComponent implements OnInit, OnChanges {
  @Input()
  public isEditMode: boolean;
  @Input()
  public isBidInvalid: boolean;

  public readonly termName = TermName;
  public readonly rfpTitleValidationMessages = RfpTitleTerm.validationMessages;
  public formGroup: FormGroup;
  public isPayerOrPayerPerspective: boolean;
  public isFormReady$ = new BehaviorSubject(false);
  public rfpDueDateErrors: ValidationError[] = [];

  private singleNotification: SingleNotification<Toast>;

  constructor(
    protected bidInfoService: BidInfoService,
    protected bidEventBusService: BidEventBusService,
    protected viewPerspectiveService: ViewPerspectiveService,
    private userService: UserService,
    private summaryFormService: SummaryFormService,
    private bidFormService: BidFormService,
    private summaryService: SummaryService,
    private modalService: ModalService,
    private notificationService: NotificationService,
    private bidStateService: BidStateService,
    private snackBarService: SnackBarService,
    private permissionService: PermissionService,
    private singleNotificationService: SingleNotificationService
  ) {
    super(bidInfoService, null, viewPerspectiveService);
    this.singleNotification = this.singleNotificationService.getInstance<Toast>();
  }

  public ngOnInit(): void {
    this.isPayerOrPayerPerspective = BidUtils.isUserPayerOrPayerPerspective(this.userService.isCurrentUserPayer(),
      this.bidStateService.bid$.getValue().isInternal, this.viewPerspectiveService.isPayerViewPerspective());
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.bid && changes.bid.currentValue) {
      this.prepareBidData();
      this.calculateCompleteStatus();
    }

    if (this.summary) {
      this.rfpDueDateErrors = RfpDueDateTerm.getRfpDueDateWarnings(this.summary.rfpDueDate);
    }

    if (changes.isEditMode && changes.isEditMode.currentValue) {
      this.initEditMode();
      this.calculateCompleteStatus();
      return;
    }

    if (changes.summary && !changes.summary.firstChange) {
      this.summaryFormService.updateSummaryForm(changes.summary.currentValue);
    }
  }

  public isVersionVisible(): Observable<boolean> {
    return this.userService.user.pipe(
      take(1),
      map((user: User) => !this.bid.isStatusRfpNotSent() && !this.bid.isStatusDraft()
        || (this.bid.isStatusDraft() && !this.bid.isBidToUserCompanyAssigned(user.company.id))
      )
    );
  }

  @QvCache()
  public isPayerInEditMode(isEditMode: boolean, isPayerOrPayerPerspective: boolean): boolean {
    return isEditMode && isPayerOrPayerPerspective;
  }

  public isPayerInEditModeWithBindingBidFeature(): boolean {
    return this.isEditMode && this.userService.isCurrentUserPayer()
      && this.permissionService.isUserCompanyBindingBidEnabled();
  }

  public isRfpTitleEditable(): boolean {
    return this.isEditMode && this.bid.status === BidStatus.RFP_NOT_SENT;
  }

  private static isDifferentDates(summaryContractDate: Moment, formContractDate: Moment): boolean {
    if (summaryContractDate instanceof moment && formContractDate instanceof moment) {
      return summaryContractDate.format() !== DateUtils.convertDateToUTC(formContractDate).format();
    }
  }

  private static isDifferentLocks(summaryContractDateLock: boolean, formContractDateLock: boolean): boolean {
    return CoreUtils.isBoolean(summaryContractDateLock) &&
      CoreUtils.isBoolean(formContractDateLock) &&
      summaryContractDateLock !== formContractDateLock;
  }

  private calculateCompleteStatus(): void {
    this.completeBidStatus = this.bidInfoService.getCompleteStatus(this.bid,
      !this.isPayerInEditModeWithBindingBidFeature());
  }

  private initEditMode(): void {
    let changes: BidInfoFormData;
    this.summaryFormService.buildSummaryForm(this.summary);
    this.summaryFormService.isFormReady$.pipe(
      filter((isReady: boolean) => isReady),
      tap(() => {
        this.formGroup = this.bidFormService.getBidForm(TermSection.SUMMARY);
        this.isFormReady$.next(true);
        this.handleUpdateNotifications();
      }),
      switchMap(() => this.formGroup.valueChanges),
      filter(() => this.bidFormService.isBidValid()),
      tap((data: BidInfoFormData) => changes = data),
      map((data: BidInfoFormData) => this.processChanges(data)),
      switchMap((summary: Summary) => this.updateSummaryHandler(summary, changes)),
      filter((summary: Summary) => Boolean(summary)),
      untilDestroyed(this),
    ).subscribe((summary: Summary) => {
      const shouldReloadScenariosAndNdcs = this.shouldReloadScenariosAndNdcs(changes);
      this.checkUpdateRfpTitle(changes.rfpTitle);
      this.summary = summary;
      this.summaryFormService.updateSummaryForm(this.summary);
      this.bidEventBusService.undoRedoEvent.emit();
      if (shouldReloadScenariosAndNdcs) {
        this.bidEventBusService.loadScenariosAndDropNdcsCacheEvent.emit();
      }
    });
  }

  private checkUpdateRfpTitle(rfpTitle: string): void {
    if (this.summary.rfpTitle !== rfpTitle) {
      this.bidStateService.bid$.next(
        Object.assign(new Bid(), this.bidStateService.bid$.getValue(), { title: rfpTitle })
      );
    }
  }

  private handleUpdateNotifications(): void {
    this.formGroup.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
      if (this.formGroup.pending) return;

      if (this.formGroup.valid) {
        this.singleNotification.clear();
      } else {
        this.singleNotification.toast(
          this.notificationService.error(resources.BID_DETAILS.INVALID_FIELDS_SAVING_ERROR)
        );
      }
    });
  }

  private openInvalidContractDateModal(formData: BidInfoFormData, invalidData: InvalidContractDateData[]): void {
    const contractDateName = this.getContractDateName(formData);
    const modalData = new InvalidContractDateModalData(contractDateName, invalidData);
    const modalConfig = new InvalidContractDateModalConfig(modalData);

    this.modalService.openModal(modalConfig).afterClosed().subscribe(() => {
      this.summaryFormService.updateSummaryForm(this.summary);
    });
  }

  private getContractDateName(formData: BidInfoFormData): string {
    if (BidInfoComponent.isDifferentDates(this.summary.contractStartDate, formData.contractStartDate)) {
      return constants.SUMMARY_TERMS.CONTRACT_START_DATE.label;
    }
    if (BidInfoComponent.isDifferentDates(this.summary.contractEndDate, formData.contractEndDate)) {
      return constants.SUMMARY_TERMS.CONTRACT_END_DATE.label;
    }
    return '';
  }

  private shouldReloadScenariosAndNdcs(formData: BidInfoFormData): boolean {
    return BidInfoComponent.isDifferentDates(this.summary.contractStartDate, formData.contractStartDate) ||
      BidInfoComponent.isDifferentDates(this.summary.contractEndDate, formData.contractEndDate) ||
      BidInfoComponent.isDifferentLocks(this.summary.contractStartDateLock, formData.contractStartDateLock) ||
      BidInfoComponent.isDifferentLocks(this.summary.contractEndDateLock, formData.contractEndDateLock);
  }

  private processChanges(data: BidInfoFormData): Summary {
    return Object.assign(new Summary(), this.summary).applyChanges(
      { ...data, bindingBid: data.bindingBid && data.bindingBid.id === BindingBidType.BINDING }
    ) as Summary;
  }

  private updateSummaryHandler(summary: Summary, changes: BidInfoFormData): Observable<Summary> {
    this.snackBarService.start();

    return this.summaryService
      .updateSummaryInfo(this.summary.bidVersionId, this.summary.id, summary).pipe(
        tap(() => this.snackBarService.finish()),
        catchError((errorResponse: HttpErrorResponse) => {
          if (errorResponse.status === HttpStatusCode.CONFLICT) {
            this.openInvalidContractDateModal(changes, errorResponse.error.data);
          }
          this.snackBarService.error();

          return of(null);
        })
      );
  }
}
