<ng-container *ngIf="summary">
  <section *ngIf="bid" class="qv-sidebar-section">
    <qv-bid-info
        [bid]="bid"
        [isBidInvalid]="isBidInvalid"
        [isEditMode]="isEditMode"
        [summary]="summary"
    ></qv-bid-info>
  </section>

  <section *ngIf="isSignatureVisible()" class="qv-sidebar-section">
    <qv-bid-signature
        [bidVersionId]="summary.bidVersionId"
        [manufacturerCompanyOldName]="bid?.manufacturerCompanyOldName"
    ></qv-bid-signature>
  </section>

  <section class="qv-sidebar-section">
    <qv-instructions
        [isBidInvalid]="isBidInvalidAndSidebarOpened(isBidInvalid, isSidebarOpened)"
        [isEditMode]="isEditMode"
        [summary]="summary"
    ></qv-instructions>
  </section>

  <section class="qv-sidebar-section">
    <qv-business-attachments
        [isBidInvalid]="isBidInvalid"
        [isEditMode]="isEditMode"
        [manufacturerCompanyOldName]="bid?.manufacturerCompanyOldName"
        [summary]="summary"
    ></qv-business-attachments>
  </section>

  <section *ngIf="summary.bindingBid" class="qv-sidebar-section">
    <qv-bid-legal-attestation [summary]="summary"></qv-bid-legal-attestation>
  </section>

  <section class="qv-sidebar-section">
    <qv-website-links
        [isBidInvalid]="isBidInvalid"
        [isEditMode]="isEditMode"
        [summary]="summary"
    ></qv-website-links>
  </section>

  <section *ngIf="isCurrentUserPayer()" class="qv-sidebar-section">
    <qv-bid-pharma-rights
        [isBidInvalid]="isBidInvalid"
        [isEditMode]="isEditMode"
    ></qv-bid-pharma-rights>
  </section>
</ng-container>

