import { Injectable } from '@angular/core';
import { BidComment } from '@qv-bid/entities/bid-comment';

@Injectable()
export class BidCommentsService {

  public static sortCommentHandler(firstItem: BidComment, secondItem: BidComment): number {
    if (!firstItem.createdAt && !secondItem.createdAt) {
      return 0;
    } else if (!firstItem.createdAt || firstItem.createdAt.isBefore(secondItem.createdAt)) {
      return -1;
    } else if (!secondItem.createdAt || firstItem.createdAt.isAfter(secondItem.createdAt)) {
      return 1;
    }

    return 0;
  }
}
