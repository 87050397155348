import { DictionaryItem } from 'quantuvis-angular-common/interfaces';

export enum LineOfBusiness {
  MEDICARE = 1,
  MEDICAID= 2,
  COMMERCIAL = 3,
  EXCHANGES = 4,
  MEDICAL = 5
}

export namespace LineOfBusiness {
  const displayValues = new Map<LineOfBusiness, DictionaryItem>([
    [LineOfBusiness.MEDICARE, { id: LineOfBusiness.MEDICARE, name: 'Medicare' }],
    [LineOfBusiness.MEDICAID, { id: LineOfBusiness.MEDICAID, name: 'Medicaid' }],
    [LineOfBusiness.COMMERCIAL, { id: LineOfBusiness.COMMERCIAL, name: 'Commercial' }],
    [LineOfBusiness.EXCHANGES, { id: LineOfBusiness.EXCHANGES, name: 'Exchanges' }],
    [LineOfBusiness.MEDICAL, { id: LineOfBusiness.MEDICAL, name: 'Medical' }]
  ]);

  export function getValue(id: LineOfBusiness): DictionaryItem {
    return displayValues.get(id);
  }

  export function getValues(): DictionaryItem[] {
    return Array.from(displayValues.values());
  }
}
