import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnChanges,
  OnInit,
  SimpleChanges
} from '@angular/core';
import { ContractManager } from '@qv-bid/components/shared/base/classes/contract.manager';
import { ContractFormService } from '@qv-bid/components/shared/summary-panel/contract/contract-form.service';
import { Payment } from '@qv-bid/entities';
import { SingleNotificationService } from '@qv-shared/services';
import { BidFormService } from '@qv-bid/services';
import { PaymentDaoService } from '@qv-bid/services/dao/contract-terms';
import { TermName, TermSection } from '@qv-term/enums';
import { SnackBarService } from 'quantuvis-angular-common/snack-bar';
import { BehaviorSubject } from 'rxjs';
import { NotificationService } from 'quantuvis-angular-common/notification';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'qv-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentComponent extends ContractManager<Payment> implements OnInit, OnChanges {
  public isSectionLocked$ = new BehaviorSubject(false);
  protected sectionName = TermSection.PAYMENT;

  constructor(
    protected daoService: PaymentDaoService,
    protected contractFormService: ContractFormService,
    protected bidFormService: BidFormService,
    protected changeDetectorRef: ChangeDetectorRef,
    protected notificationService: NotificationService,
    snackBarService: SnackBarService,
    singleNotificationService: SingleNotificationService
  ) {
    super(
      contractFormService,
      bidFormService,
      changeDetectorRef,
      notificationService,
      snackBarService,
      singleNotificationService
    );
  }

  public ngOnInit(): void {
    this.entity$
      .pipe(untilDestroyed(this))
      .subscribe((payment: Payment) => this.isSectionLocked$.next(this.calculateSectionLock(payment)));

    if (Number.isInteger(this.contractedBusinessId)) {
      this.loadEntity();
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.contractedBusinessId && !changes.contractedBusinessId.firstChange) {
      this.loadEntity();
    } else if (changes.isEditMode && changes.isEditMode.currentValue) {
      this.initEditMode();
    }
  }

  public calculateSectionLock(payment: Payment): boolean {
    return payment && payment.paymentFrequencyLock && payment.lagLock && payment.wholesalePriceLock
      && payment.rebateEligibilityLock;
  }

  public onManageSectionLock(isLocked: boolean): void {
    const emitEventFalse = { emitEvent: false };
    this.formGroup.get(TermName.getTermNameLock(TermName.REBATE_ELIGIBILITY)).setValue(isLocked, emitEventFalse);
    this.formGroup.get(TermName.getTermNameLock(TermName.WHOLESALE_ACQUISITION_PRICE))
      .setValue(isLocked, emitEventFalse);
    this.formGroup.get(TermName.getTermNameLock(TermName.PAYMENT_LAG)).setValue(isLocked, emitEventFalse);
    this.formGroup.get(TermName.getTermNameLock(TermName.PAYMENT_FREQUENCY)).setValue(isLocked, emitEventFalse);
    this.formGroup.updateValueAndValidity();
  }

  protected buildFormForSection(source: Payment): void {
    this.contractFormService.buildPaymentForm(source);
  }
}
