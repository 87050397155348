import { DictionaryItem } from 'quantuvis-angular-common/interfaces';

export enum PriceProtectionMethod {
  EMPTY = 103,
  B_C,
  D,
  E,
  F
}

export namespace PriceProtectionMethod {
  const valuesMap = new Map<PriceProtectionMethod, DictionaryItem>([
    [PriceProtectionMethod.EMPTY, { id: PriceProtectionMethod.EMPTY, name: '' }],
    [PriceProtectionMethod.B_C, { id: PriceProtectionMethod.B_C, name: 'B/C' }],
    [PriceProtectionMethod.D, { id: PriceProtectionMethod.D, name: 'D' }],
    [PriceProtectionMethod.E, { id: PriceProtectionMethod.E, name: 'E' }],
    [PriceProtectionMethod.F, { id: PriceProtectionMethod.F, name: 'F' }]
  ]);

  export function getValue(priceProtectionMethod: PriceProtectionMethod): DictionaryItem {
    return valuesMap.get(priceProtectionMethod);
  }

  export function getValues(): DictionaryItem[] {
    return Array.from(valuesMap.values());
  }
}
