import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { environment } from '@qv-env/environment';

declare let $: any;

// TODO: Move to GoogleAnalyticsTrackingService
export const trackingDirective = ['googleAnalyticsService', (googleAnalyticsService: GoogleAnalyticsService) => {
  $(document.body).on('click', '.btn', event => {
    if (environment.production) {
      const target = $(event.target);
      const content = target.text();
      if (content !== '') {
        googleAnalyticsService.event('button', 'click', content);
      }
    }
  });

  $(document.body).on('click', 'a', event => {
    if (environment.production) {
      const target = $(event.target);
      let href = target.attr('href');
      const content = target.text();
      href = (href == null || href === '') ? content : `${target.attr('href')} - ${content}`;
      googleAnalyticsService.event('link', href, window.location.href);
    }
  });
}];

