import { JsonObject, JsonProperty } from 'json2typescript';
import { BidRight } from '../enums';

@JsonObject('PharmaRight')
export class PharmaRight {
  @JsonProperty('id', Number)
  public id: number = null;

  @JsonProperty('bidId', Number)
  public bidId: number = null;

  @JsonProperty('right', String)
  public right: BidRight = null;

  @JsonProperty('isActive', Boolean)
  public isActive: boolean = null;
}
