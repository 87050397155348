import { NotesMetadata } from '@qv-term/models';
import { BidStatus } from 'quantuvis-core-entities';

export class DrugNotesModalData {
  constructor(
    public viewNotes: NotesMetadata = new NotesMetadata(),
    public isEditMode: boolean,
    public scenarioId: number,
    public cbId: number,
    public drugId: number,
    public bidVersionId: number,
    public bidStatus: BidStatus,
    public isBidInternal: boolean,
    public manufacturerCompanyOldName: string,
    public manufacturerCompanyId: number
  ) {}
}
