/* tslint:disable:no-invalid-this triple-equals */
import { ConfirmNavigationInner } from '@qv-ng1-wrapper/classes';
/* no-invalid-this triple-equals */
import { SvgIconName } from '@qv-common/enums';
import { constants, resources } from '@qv-common/static';
import { drugTermsConstants } from '@qv-term/constants';
import { CoreUtils } from '@qv-common/utils';
import { TermName } from '@qv-term/enums';
import { ApiUrlPrefix } from '@qv-common/enums';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { catchError, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import isEqual from 'lodash.isequal';

declare let angular: angular.IAngularStatic;
declare let $: any;

// @ts-ignore
import templateUrl from './createbid.html';
import { UserService } from '@qv-common/services/auth/user.service';

const CreateBidComponent = {
  template: templateUrl,
  bindings: {
    user: '<',
    initConfirmNavigation: '&'
  },
  controller: class extends ConfirmNavigationInner {

    public static $inject = ['$scope', 'bidding', '$document', 'translations', 'util', 'standards', 'locking',
      'DrugLevelService', 'spinnerService', '$window', 'router', 'userService', 'apiService'
    ];
    public confirmNavigationMessage = resources.BID_DETAILS.CONFIRM_NAVIGATION;

    constructor(private $scope, bidding, $document, translations, util, standards, locking, DrugLevelService,
                spinnerService, $window, router, userService: UserService, apiService) {
      super();
      // This is for stretching child on full height
      $document.find('.root-view create-bid-component').addClass('vertical-flex-container');
      $document.find('.root-view create-bid-component').addClass('full-height');

      $document.find('html, .root-view').addClass('full-height');
      translations.buildTranslations($scope);
      $scope.router = router;

      // Last search
      const lastSearch = '';

      const metadata = {};
      const bidMetadata = {};

      const initialScenarios = {};

      addAllowedQuestionPathsToMetadata(metadata, bidMetadata);
      const DRUGS_EVALUATED_ENUM = {
        ALL_MANUFACTURERS_SOME_DRUGS: constants.DRUGS_EVALUATED_ENUM.ALL_MANUFACTURERS_SOME_DRUGS,
        SOME_MANUFACTURERS_ALL_DRUGS: constants.DRUGS_EVALUATED_ENUM.SOME_MANUFACTURERS_ALL_DRUGS,

        setSelectedValue(value): void {
          this.selectedValue = value;
        },
        getSelectedValue(): any {
          return this.selectedValue;
        }
      };

      /**
       * Set up the metadata for Price Protection section at create RFP (in pre-populate Price Protection section).
       *
       * @param selectedState - selected state for price protection metadata entry
       */
      $scope.setMetadataForPriceProtection = selectedState => {
        const path = util.getPathFromArray(constants.DRUG_TERM_PATHS.ALLOW_PRICE_PROTECTION);

        metadata[path] = {
          state: selectedState
        };
      };

      // Set model option
      $scope.modelOptions = {};

      // Set default Price Protection Metadata
      $scope.priceProtectionMetadataStates = constants.PRICE_PROTECTION_METADATA_STATES;
      $scope.priceProtectionSelectedState = $scope.priceProtectionMetadataStates[0].state;
      $scope.setMetadataForPriceProtection($scope.priceProtectionSelectedState);

      $scope.contractTerms = constants.BID_SECTIONS.CONTRACT_TERMS;

      // Listen for Binding Bid toggling
      $scope.$on('toggleBindingBid', (event, data) => {
        $scope.bid.isBinding = data;
      });

      $scope.$on('$destroy', () => {
        $document.find('.root-view').removeClass('full-height');
      });

      $scope.$on(constants.EVENTS.LOCK_OR_UNLOCK_TERM, (event, data) => {
        $scope.lockOrUnlock([
          data
        ], false);
      });

      /**
       * Method return current bid data to track user changes
       * and show prevent changes pop-up
       *
       * @returns {Object}
       */
      $scope.getCurrentBidData = () => {
        const preparedBid = prepareBidForSave();
        const currentBidData = angular.copy(preparedBid.bid.bidCurrentVersion);
        const instructionTerm = findInstructionsTerm($scope.bid.summary);
        currentBidData.summaryTerms = setInstructionsTermValueIfEmpty(currentBidData.summaryTerms, instructionTerm);
        currentBidData.summaryTerms =
          excludeTermFromList(currentBidData.summaryTerms, constants.BIDDING.LEGAL_ATTESTATION);

        return currentBidData;
      };

      $scope.validatePlanTypeName = (planTypeTerm, planType) => {
        planTypeTerm.definition.validatePlanTypeName(planTypeTerm, planType);
      };

      standards.apply($scope, {
        constructor: () => {
          $scope.user = userService.user.getValue();
          $scope.initCreateBidSteps();
          $scope.newBid();

          // skip to the next digest cycle to get full data of the RFP
          $window.tinymce.on('addeditor', () => {
            $scope.savedData = $scope.getCurrentBidData();
          }, true);
        },
        variables: {
          DRUGS_EVALUATED_ENUM, // DRUGS_EVALUATED_ENUM enum
          focusSearchDrug: false, // flag used to focus search drug input
          drugs: [], // Drug list fetched from the server
          selectedDrugs: [], // List of selected drugs
          noDrugResult: false, // no drug result flag
          searchedDrugs: {}, // object for drug search
          searching: false, // Searching in progress flag
          companies: [], // Manufacturer list fetched from the server
          selectedCompanies: [], // List of selected manufacturers
          searchedCompany: {}, // object for manufacturer search
          focusSearchCompany: false, // flag used to focus search manufacturer input
          noCompanyResult: false,
          lastSearch,
          allowQuestions: getAllowQuestions(),
          generalScenariosLock: false,
          createBidError: { // General error message model
            visible: false,
            message: ''
          }
        },

        public: () => {
          function initCreateBidSteps(): void {
            const createBidSteps: any = constants.CREATE_BID_STEPS;

            createBidSteps.SUMMARY_TERMS.order = 1;
            createBidSteps.CONTRACT_TERMS.order = 2;
            createBidSteps.RFP_OPTIONS.order = 3;
            createBidSteps.SELECT_MANUFACTURERS.order = 4;
            createBidSteps.SELECT_DRUGS.order = 4;
            createBidSteps.DRUG_LEVEL_TERMS.order = 5;

            $scope.createBidSteps = createBidSteps;
            $scope.numberOfSteps = 5;
          }

          function addNewPlanType(): void {
            if ($scope.isCurrentContractedBusinessNameValid()) {

              $scope.planTypeTerm.value.push({
                name: $scope.planTypeTerm.value.length >= 1 ? '' : $scope.lineOfBusinessTerm.value
              });

              selectPlanType($scope.planTypeTerm.value.length - 1);
            }
          }

          function removePlanType(index): void {
            $scope.planTypeTerm.value.splice(index, 1);
            $scope.planTypeTerm.selectedIndex === $scope.planTypeTerm.value.length
              // tslint:disable-next-line:no-unused-expression
              ? $scope.planTypeTerm.selectedIndex-- : '';
            $('.planTypeEditContainer:not(.ng-hide) input').popover('hide');
          }

          function selectPlanType(index): void {
            if ($scope.isCurrentContractedBusinessNameValid()) {
              $scope.planTypeTerm.selectedIndex = index;
            }
          }

          function isRemoveIconVisible(index): boolean {
            return index == $scope.planTypeTerm.selectedIndex && $scope.planTypeTerm.value.length !== 1;
          }

          function isPlanTypeEditable(index): boolean {
            return index == $scope.planTypeTerm.selectedIndex;
          }

          /**
           * In create RPF only the payer users are allowed to enter and they
           * are allowed to perform any action on contracted businesses.
           * A similar function is implemented in bid details controller,
           * but there the implementation is different depending on user type
           * and the settings set by the payer user that created the bid.
           */
          function isOperationAllowedForUser(option): boolean {
            return true;
          }

          function getDynamicWidthInputStyle(value): any {
            if (!value) {
              return;
            }

            const charWidth = 7;
            const width = (value.length + 1) * charWidth;
            return {
              width: `${width}px`
            };
          }

          function isBidDisplayedStatusRFPNotSent(): boolean {
            // for create bid use case we don't need this flag
            // we just return 'true' as we use the same template on bid detail page
            return true;
          }

          /**
           * Method verify if next button is visible
           * Will be showed on all steps except:
           *  - step #3 - when user choose which Drugs and Pharmas to include in your RFP
           *  - step #6 - when user pre-populate Drug Scenarios
           *
           * @return {boolean}
           */
          function isNextVisible(): boolean {
            const displayedStep = $scope.currentStep.order;
            return displayedStep !== $scope.createBidSteps.RFP_OPTIONS.order
              && displayedStep !== $scope.createBidSteps.DRUG_LEVEL_TERMS.order;
          }

          /**
           * Method verify if previous button is visible.
           * Will be showed on all steps except:
           *  - step #1 - when user add Contract Terms
           *
           * @return {boolean}
           */
          function isPreviousVisible(): boolean {
            return $scope.currentStep.order !== $scope.createBidSteps.SUMMARY_TERMS.order;
          }

          function isPharma(): boolean {
            // for create bid use case we don't need this flag
            // we just return 'false' as we use the same template on bid detail page
            return false;
          }

          function drugsEvaluatedButtonHandler(evaluationCase): void {
            switch (evaluationCase) {
              case $scope.DRUGS_EVALUATED_ENUM.SOME_MANUFACTURERS_ALL_DRUGS : {
                $scope.DRUGS_EVALUATED_ENUM.setSelectedValue($scope.DRUGS_EVALUATED_ENUM.SOME_MANUFACTURERS_ALL_DRUGS);
                $scope.next($scope.createBidSteps.SELECT_MANUFACTURERS);
                $scope.focusSearchCompany = true;
                break;
              }
              case $scope.DRUGS_EVALUATED_ENUM.ALL_MANUFACTURERS_SOME_DRUGS : {
                $scope.DRUGS_EVALUATED_ENUM.setSelectedValue($scope.DRUGS_EVALUATED_ENUM.ALL_MANUFACTURERS_SOME_DRUGS);
                $scope.next($scope.createBidSteps.SELECT_DRUGS);
                $scope.focusSearchDrug = true;
                break;
              }
            }
          }

          /**
           * Method move wizard to the next step.
           */
          function next(step): void {
            if (isPageValid()) {
              $('.popover').popover('hide');
              if ($scope.currentStep.order === $scope.numberOfSteps - 1) {
                $scope.currentStep = $scope.createBidSteps.DRUG_LEVEL_TERMS;
              } else {
                $scope.currentStep = step || getCreateBidStepByIndex($scope.currentStep.order + 1);
              }
              $scope.createBidError.visible = false;
            }
          }

          function previous(): void {
            $scope.createBidError.visible = false;
            if ($scope.currentStep.order !== $scope.numberOfSteps) {
              $scope.currentStep = getCreateBidStepByIndex($scope.currentStep.order - 1);
            } else {
              switch ($scope.DRUGS_EVALUATED_ENUM.getSelectedValue()) {
                case $scope.DRUGS_EVALUATED_ENUM.SOME_MANUFACTURERS_ALL_DRUGS: {
                  $scope.currentStep = $scope.createBidSteps.SELECT_MANUFACTURERS;
                  break;
                }
                case $scope.DRUGS_EVALUATED_ENUM.ALL_MANUFACTURERS_SOME_DRUGS: {
                  $scope.currentStep = $scope.createBidSteps.SELECT_DRUGS;
                  break;
                }
              }
            }
            $scope.focusSearchDrug = false;
            $scope.focusSearchCompany = false;
          }

          function getCreateBidStepByIndex(index): any {
            let selectedBidStateEnum = $scope.createBidSteps.SUMMARY_TERMS;

            for (const key in $scope.createBidSteps) {
              if ($scope.createBidSteps.hasOwnProperty(key) && $scope.createBidSteps[key].order === index) {
                selectedBidStateEnum = $scope.createBidSteps[key];
                break;
              }
            }

            return selectedBidStateEnum;
          }

          function getCreationInfo(): any {
            if ($scope.currentStep.order === $scope.createBidSteps.SUMMARY_TERMS.order) {
              return '';
            }

            const summaryTerms = constants.SUMMARY_TERMS;
            const data = `${$scope.getTermByName(summaryTerms.CONTRACT_START_DATE.label).value} - ${
              $scope.getTermByName(summaryTerms.CONTRACT_END_DATE.label).value}`;
            return `${$scope.getTermByName(summaryTerms.RFP_TITLE.label).value} for ${
              $scope.getTermByName(summaryTerms.LINE_OF_BUSINESS.label).value} (${data})`;
          }

          /** Validate contract terms **/
          function saveBid(): void {

            if (isPageValid()) {

              spinnerService.start($scope.i18n.NewBid.SAVING_BID);

              const objectToSave = JSON.parse(angular.toJson(prepareBidForSave()));
              const option = $scope.DRUGS_EVALUATED_ENUM.getSelectedValue();

              const staticDataForSelection = $scope.DRUGS_EVALUATED_ENUM.getStaticDataForSelection();

              if (option === $scope.DRUGS_EVALUATED_ENUM.ALL_MANUFACTURERS_SOME_DRUGS) {
                objectToSave[staticDataForSelection.persistedListName] = [];
                $scope[staticDataForSelection.listFromRightName].forEach(item => {
                  item.selectedDrugs.forEach(innerItem => {
                    innerItem.drugIds.forEach(drugId => {
                      objectToSave[staticDataForSelection.persistedListName].push(drugId);
                    });
                  });
                });
              } else if (option === $scope.DRUGS_EVALUATED_ENUM.SOME_MANUFACTURERS_ALL_DRUGS) {
                objectToSave[staticDataForSelection.persistedListName] = [];
                if (Array.isArray($scope[staticDataForSelection.listFromRightName])) {
                  $scope[staticDataForSelection.listFromRightName].forEach(item => {
                    objectToSave[staticDataForSelection.persistedListName].push(item.id);
                  });
                }
              }
              // add initial scenarios information to the request
              addInitialScenarios(objectToSave);

              if (CoreUtils.isDefined(metadata)) {
                objectToSave.meta = createMetadataObjectForSave(metadata);
              }

              if (CoreUtils.isDefined(bidMetadata)) {
                objectToSave.bidMeta = createMetadataObjectForSave(bidMetadata);
              }

              // Prepare defaults for drugs list
              const defaults = {
                drugListTerms: [],
                summaryTerms: [],
                contractTerms: []
              };

              // Add default values & values from create bid (eg: step 5)
              $scope.bid.drugTerms.forEach(item => {
                if (item.value && item.value !== ''
                  && item.name !== drugTermsConstants[TermName.RANGE_OR_UNITS_AND_REBATES].title) {
                  defaults.drugListTerms.push({
                    name: item.name,
                    value: CoreUtils.isDefined(item.definition.getValueToSave)
                      ? item.definition.getValueToSave(item) : item.value
                  });
                }
              });

              // Add default values from summary; these will be propagated, but not persisted for "add new drug"
              const defaultsFromSummary = [
                constants.SUMMARY_TERMS.CONTRACT_START_DATE.label,
                constants.SUMMARY_TERMS.CONTRACT_END_DATE.label
              ];
              const defaultsFromContract = [
                constants.CONTRACT_TERMS.ADMINISTRATION_FEE.label
              ];

              setSectionDefaults(defaultsFromSummary, defaults.summaryTerms, $scope.bid.summary);
              setSectionDefaults(defaultsFromContract, defaults.contractTerms, $scope.bid.contract);

              objectToSave.defaultBidTerms = defaults;

              apiService.post(`${ApiUrlPrefix.OLD}/bids/save`, objectToSave).pipe(
                tap((response: HttpResponse<any>) => {
                  $scope.response = response.body;
                  spinnerService.stop();
                  $scope.$apply(() => {
                    resetValue();
                    $scope.allowLocationChange = true;
                    router.navigate(['/bids']);
                  });
                }),
                catchError((error: HttpErrorResponse) => {
                  $scope.response = error;
                  spinnerService.stop();
                  $scope.$apply(() => {
                    $scope.allowLocationChange = true;
                    router.navigate(['/bids']);
                  });

                  return of(null);
                })
              ).subscribe();
            }
          }

          function newBid(): void {
            displayCreateBidModalWindow();
          }

          function lockOrUnlock(termsToLockOrUnlock, addGroupPath): void {
            if (addGroupPath === true) {
              locking.lockOrUnlockAllTermsInGroup(termsToLockOrUnlock, metadata, $scope.bid.contract, $scope);
            } else {
              termsToLockOrUnlock.forEach(term => {
                if (util.isPathDefined(term, 'definition.saveToBidMeta') && term.definition.saveToBidMeta === true) {
                  locking.lockOrUnlockTerm(term, bidMetadata, $scope.bid.contract, $scope);
                } else {
                  locking.lockOrUnlockTerm(term, metadata, $scope.bid.contract, $scope);
                }
              });
            }
          }

          function getLockUnlockIconName(term): any {
            return locking.getLockUnlockIconName(term, metadata);
          }

          function getLockUnlockIconClass(term): any {
            return locking.getLockUnlockIconClass(term, metadata);
          }

          function addOrRemoveMetaInfo(termAllowed): any {
            if (termAllowed.option === 'drugTerm') {
              addOrRemoveTermFromMeta(termAllowed, metadata);
            } else if (termAllowed.option === 'bidTerm') {
              addOrRemoveTermFromMeta(termAllowed, bidMetadata);
            }
          }

          function addOrRemoveTermFromMeta(term, metaObject): any {
            if (term.path === constants.BID_SECTIONS.ALLOW_MARKET_SHARE_REBATE_PATH) {
              if (!term.checked) {
                metaObject[term.path] = locking.getHiddenStateObject();
              } else {
                delete metaObject[term.path];
              }

              return;
            }
            if (CoreUtils.isNotDefined(metaObject[term.path])) {
              metaObject[term.path] = term.flags;
            } else {
              delete metaObject[term.path];
            }
          }

          function payerCompanySupportsBindingBid(): any {
            return util.payerCompanySupportsBindingBid($scope.user);
          }

          function onDrugScenarioActionHandler(event): any {
            switch (event.action) {
              case 'add':
                addDrugScenario();
                break;
              case 'delete':
                deleteDrugScenario(event.scenarioIndex);
                break;
            }
          }

          function addDrugScenario(): any {
            if ($scope.scenariosList.length < constants.SCENARIOS_MAX_LENGTH) {
              const emptyDrugScenario = DrugLevelService.getEmptyDrugScenario($scope.generalScenariosLock);
              $scope.scenariosList.push(emptyDrugScenario);
            }
          }

          function deleteDrugScenario(scenarioIndex): any {
            $scope.scenariosList.splice(scenarioIndex, 1);
          }

          function lockDrugScenarios(): any {
            $scope.generalScenariosLock = !$scope.generalScenariosLock;
            angular.forEach($scope.scenariosList, (scenario) => {
              scenario.lockTierOptions = $scope.generalScenariosLock;
              scenario.lockUMOptions = $scope.generalScenariosLock;
            });
          }

          return {
            initCreateBidSteps,
            isBidDisplayedStatusRFPNotSent,
            drugsEvaluatedButtonHandler,
            next,
            previous,
            saveBid,
            newBid,
            isPharma,
            addNewPlanType,
            selectPlanType,
            dynamicWidthInputStyle: getDynamicWidthInputStyle,
            isOperationAllowedForUser,
            isPlanTypeEditable,
            isRemoveIconVisible,
            removePlanType,
            isNextVisible,
            isPreviousVisible,
            lockOrUnlock,
            getLockUnlockIconName,
            getLockUnlockIconClass,
            addOrRemoveMetaInfo,
            payerCompanySupportsBindingBid,
            getCreationInfo,
            getPlanTypeName: bidding.getPlanTypeName,
            onDrugScenarioActionHandler,
            lockDrugScenarios,
            svgIconName: SvgIconName
          };
        }
      });

      // --------------------------------------------------------------------------------------------------------------
      // private functions
      // --------------------------------------------------------------------------------------------------------------
      function cleanupPickersData(): any {
        $scope.companies = [];
        $scope.selectedCompanies = [];

        $scope.drugs = [];
        $scope.selectedDrugs = [];

        $scope.searchedCompany = {};
        $scope.searchedDrugs = {};

        $scope.searching = false;
        $scope.noCompanyResult = false;
        $scope.noDrugResult = false;
        $scope.tooManyResults = false;
        $scope.createBidError.visible = false;
      }

      function getAllowQuestions(): any {
        const questions = [],
          allowedQuestions = angular.extend({}, $scope.i18n.NewBid.DrugTermsAllowed, $scope.i18n.NewBid.BidTermsAllowed);
        // Switch array's indexes with values to get order values for sorting the list in UI
        const order = $scope.i18n.NewBid.AllowedOrder.reduce((prev: Object, curr: string, index: number) => {
          prev[curr] = index;
          return prev;
        }, {});

        for (const questionKey in allowedQuestions) {
          const isDrugQuestion = CoreUtils.isDefined($scope.i18n.NewBid.DrugTermsAllowed[questionKey]);
          questions.push({
            text: isDrugQuestion ? $scope.i18n.NewBid.DrugTermsAllowed[questionKey]
              : $scope.i18n.NewBid.BidTermsAllowed[questionKey],
            path: getPath(questionKey),
            flags: isDrugQuestion ? getFlagForAllowedDrugTerm(questionKey)
              : getFlagForAllowedBidTerm(questionKey),
            checked: false,
            option: isDrugQuestion ? 'drugTerm' : 'bidTerm',
            order: parseInt(order[questionKey])
          });
        }

        return questions;
      }

      function getPath(termKey): any {
        if (CoreUtils.isDefined(constants.DRUG_TERM_PATHS[termKey])) {
          return util.getPathFromArray(constants.DRUG_TERM_PATHS[termKey]);
        }

        return util.getPathFromArray(constants.BID_TERM_PATHS[termKey]);
      }

      function getFlagForAllowedDrugTerm(termKey): any {
        if ($scope.i18n.NewBid.DrugTermsAllowed[termKey]
          === $scope.i18n.NewBid.DrugTermsAllowed.ALLOW_MARKET_SHARE_REBATE) {
          return locking.getHiddenStateObject();
        }

        return locking.getLockStateObject();
      }

      function getFlagForAllowedBidTerm(termKey): any {
        if ($scope.i18n.NewBid.BidTermsAllowed[termKey] === $scope.i18n.NewBid.BidTermsAllowed.LOCK_NOTES ||
          $scope.i18n.NewBid.BidTermsAllowed[termKey] === $scope.i18n.NewBid.BidTermsAllowed.ALLOW_MARKET_BASKET ||
          $scope.i18n.NewBid.BidTermsAllowed[termKey] === $scope.i18n.NewBid.BidTermsAllowed.ALLOW_NDC_OFFERS) {
          return locking.getLockStateObject();
        }

        return locking.getHiddenStateObject();
      }

      function addAllowedQuestionPathsToMetadata(metadataObject, bidMetadataObject): any {
        if (CoreUtils.isDefined(metadataObject)) {
          getAllowQuestions().forEach(question => {
            if (question.option === 'drugTerm') {
              metadataObject[question.path] = question.flags;
            } else if (question.option === 'bidTerm' && question.text != $scope.i18n.NewBid.BidTermsAllowed.LOCK_NOTES) {
              bidMetadataObject[question.path] = question.flags;
            }
          });
        }
      }

      function createMetadataObjectForSave(metadataObject): any {
        const metadataArrayForSave = [];
        for (const metaPath in metadataObject) {
          metadataArrayForSave.push({
            path: metaPath,
            flags: metadataObject[metaPath]
          });
        }

        return metadataArrayForSave;
      }

      function displayCreateBidModalWindow(): any {
        cleanupPickersData();
        const bidTemplate = bidding.BiddingData();

        $scope.currentStep = $scope.createBidSteps.SUMMARY_TERMS;

        $scope.bid = {
          summary: [],
          contract: [],
          editable: true,
          isNew: true,
          isBinding: $scope.payerCompanySupportsBindingBid()
        };

        $scope.scenariosList = [DrugLevelService.getEmptyDrugScenario()];

        const settings = {
          payerCompanyName: userService.user.getValue().company.name
        };

        const summary = bidTemplate.summaryTerms;
        const contractTerms = bidTemplate.contractTerms;
        const drugListTerms = bidTemplate.drugListTerms;

        /** Contract term groups (Contract terms are displayed on 3 columns) */
        const contractTermGroups = [
          [
            constants.CONTRACT_TERMS.REBATE_ELIGIBILITY.label, constants.CONTRACT_TERMS.WHOLESALE_ACQUISITION_PRICE.label,
            constants.CONTRACT_TERMS.PAYMENT_LAG.label, constants.CONTRACT_TERMS.PAYMENT_FREQUENCY.label
          ],
          [
            constants.CONTRACT_TERMS.LATE_PAYMENT_PENALTY_TERMS_BASIS.label,
            constants.CONTRACT_TERMS.LATE_PAYMENT_PENALTY_TERMS_VALUE.label,
            constants.CONTRACT_TERMS.LATE_PAYMENT_PENALTY_TERMS_FREQUENCY.label,
            constants.CONTRACT_TERMS.ADMINISTRATION_FEE.label
          ],
          [
            constants.CONTRACT_TERMS.PHARMA_AUDIT_TERMS_AUDIT_FREQUENCY.label,
            constants.CONTRACT_TERMS.PHARMA_AUDIT_TERMS_PRIOR_NOTICE_REQUIRED.label,
            constants.CONTRACT_TERMS.PHARMA_AUDIT_TERMS_LOOK_BACK.label,
            constants.CONTRACT_TERMS.NO_FAULT_TERMINATION_CLAUSE_WHO_MAY_INVOKE.label,
            constants.CONTRACT_TERMS.NO_FAULT_TERMINATION_CLAUSE_PRIOR_NOTICE_REQUIRED.label
          ]
        ];

        $scope.preparedContractTerms = [
          [], [], []
        ];
        $scope.bid.contract = bidding.prepareTerms(null, contractTerms, settings);

        for (let i = 0; i < 3; i++) {
          contractTermGroups[i].forEach(elem => {
            $scope.preparedContractTerms[i].push($scope.bid.contract.find(term => term.name === elem));
          });
        }
        $scope.bid.summary = bidding.prepareTerms(null, summary, settings);

        $scope.getBidPayer = () => $scope.user.company;

        $scope.neitherPartySelected = term =>
          term.value === constants.OPTIONS.NO_FAULT_TERMINATION_CLAUSE_WHO_MAY_INVOKE.NEITHER_PARTY;

        function updateBindingBidSummaryTerm(): any {
          $scope.bid.summary.forEach(term => {
            if (term.name === constants.SUMMARY_TERMS.BINDING_BID.label
              && util.payerCompanySupportsBindingBid($scope.user)) {
              term.value = constants.BINDING_BID_TYPE.BINDING;
              term.definition.onChangeEvent(term, $scope);
            }
          });
        }

        updateBindingBidSummaryTerm();

        $scope.getTermByName = name =>
          $scope.bid.contract.concat($scope.bid.summary).find(search => search.name == name);

        $scope.preparedContractTerms[1].push($scope.getTermByName(constants.BIDDING.LATE_PAYMENT_PENALTY_TERMS));

        $scope.bid.drugTerms = bidding.prepareTerms(null, drugListTerms, settings);

        $scope.getDrugTermByName = name => $scope.bid.drugTerms.find(search => search.name == name);

        /** Start preparing a new plan type term */
        $scope.lineOfBusinessTerm = $scope.getTermByName('Line Of Business', $scope.bid.summary);
        $scope.lineOfBusinessTerm.definition.onChange($scope.lineOfBusinessTerm);
        $scope.planTypeTerm = $scope.lineOfBusinessTerm.planTypeTerm;
        $scope.planTypeTerm.definition.prepareCustom($scope);
        $scope.planTypeTerm.selectedIndex = 0;
      }

      /**
       * Checks if the current page is valid
       */
      function isPageValid(): any {
        let valid = true;
        switch ($scope.currentStep.order) {
          case $scope.createBidSteps.SUMMARY_TERMS.order:
            $scope.bid.summary.forEach(term => {
              if (term.definition.validate) {
                const testValidity = term.definition.validate(term, $scope.bid.summary);

                if (valid) {
                  valid = testValidity;
                }
              }
            });
            break;
          case $scope.createBidSteps.CONTRACT_TERMS.order:
            $scope.bid.contract.forEach(term => {
              if (term.definition.validate) {
                const testValidity = term.definition.validate(term, $scope.bid.contract);

                if (valid) {
                  valid = testValidity;
                }
              }
            });
            break;
          case $scope.createBidSteps.SELECT_MANUFACTURERS.order:
            const staticData = $scope.DRUGS_EVALUATED_ENUM.getStaticDataForSelection();
            if ($scope[staticData.listFromRightName].length === 0 || !isPageValidForDrugCase(staticData)) {
              $scope.createBidError.visible = true;
              $scope.createBidError.message = staticData.errorNotSelected;
              valid = false;
            }
            break;
          case $scope.createBidSteps.DRUG_LEVEL_TERMS.order:
            if ($scope.bid.drugTerms && $scope.bid.drugTerms.length > 0) {
              const term = $scope.bid.drugTerms
                .find(drug => drug.name === drugTermsConstants[TermName.BASELINE_START_DATE].title);

              if (term && term.definition && term.definition.validate &&
                typeof term.definition.validate === 'function') {
                valid = term.definition.validate(term);
              }
            }
            break;
        }

        return valid;
      }

      /**
       * The selected drugs list must have at least one not soft deleted drug
       */
      function isPageValidForDrugCase(staticData): any {
        return $scope.DRUGS_EVALUATED_ENUM.getSelectedValue() !== $scope.DRUGS_EVALUATED_ENUM.ALL_MANUFACTURERS_SOME_DRUGS
          || $scope[staticData.listFromRightName].length > 0;
      }

      function resetValue(): any {
        $scope.bid.summary.forEach(term => {
          if (term.definition && typeof term.definition.resetValue === 'function') {
            term.definition.resetValue(term);
          }
        });
      }

      /**
       * Method set default value into Instructions term
       *
       * @param cleanSummaryTerms
       * @param instructionTerm
       * @return cleanSummaryTerms
       */
      function setInstructionsTermValueIfEmpty(cleanSummaryTerms, instructionTerm): any {
        const instruction = cleanSummaryTerms.find((term) =>
          term && (term.name === constants.SUMMARY_TERMS.INSTRUCTIONS.label));
        if (instruction.value && (instruction.value.text === constants.COMMONS.EMPTY_STRING)) {
          instruction.value.text = instructionTerm.definition.defaultValue.text;
        }
        return cleanSummaryTerms;
      }

      /**
       * Method exclude term from term list
       *
       * @param summaryTerms {Array}
       * @param termName {String}
       * @return summaryTerms {Array}
       */
      function excludeTermFromList(summaryTerms, termName): any {
        return summaryTerms.filter((term) => term.name !== termName);
      }

      function prepareBidForSave(): any {
        let summary = cleanBidSection($scope.bid.summary);
        let contract = cleanBidSection($scope.bid.contract);
        const instructions = findInstructionsTerm(contract);

        if (instructions) {
          const summaryTerms = constants.SUMMARY_TERMS;
          contract = excludeTermFromList(contract, summaryTerms.INSTRUCTIONS.label);
          // Remove instructions term from summary area before adding it
          summary = excludeTermFromList(summary, summaryTerms.INSTRUCTIONS.label);
          summary.push(instructions);
        }

        return {
          bid: {
            bidCurrentVersion: {
              summaryTerms: summary,
              contractTerms: contract
            }
          }
        };
      }

      function cleanBidSection(section): any {
        let cleanSection = [];

        section.forEach((term) => {
          const toSave = bidding.getSaveValueForTerm(term);
          if (toSave) {
            cleanSection.push(toSave);
          }
        });

        // if the bid is created non binding, remove both BINDING_BID and LEGAL_ATTESTATION terms from summary
        const bindingBidTerm = cleanSection.find((term) => term.name === constants.SUMMARY_TERMS.BINDING_BID.label);

        if (bindingBidTerm && bindingBidTerm.value && (bindingBidTerm.value === false)) {
          cleanSection = excludeTermFromList(cleanSection, constants.BIDDING.LEGAL_ATTESTATION);
        }

        return cleanSection;
      }

      function findInstructionsTerm(terms): any {
        return terms.find((term) => term.name === constants.SUMMARY_TERMS.INSTRUCTIONS.label);
      }

      function addInitialScenarios(objectToSave): any {
        objectToSave.initialScenarios = angular.copy($scope.scenariosList);
      }

      function setSectionDefaults(sectionDefaults, section, sectionTerms): any {
        sectionDefaults.forEach(item => {
          section.push({
            name: item,
            value: bidding.getTermValueByName(sectionTerms, item)
          });
        });
      }

    }

    public $onInit(): void {
      this.emitConfirmNavigationCallback();
    }

    public isNavigationNeedConfirmation(): boolean {
      return !this.$scope.allowLocationChange && !isEqual(this.$scope.getCurrentBidData(), this.$scope.savedData);
    }
  }
};
export default CreateBidComponent;
