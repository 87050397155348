import { JsonConverter, JsonCustomConvert } from 'json2typescript';
import moment, { Moment } from 'moment';

@JsonConverter
export class MomentConverter implements JsonCustomConvert<Moment> {
  public serialize(date: Moment | string): any {
    if (moment.isMoment(date)) {
      return parseInt(date.format('x'), 10);
    }

    return null;
  }

  public deserialize(date: any): Moment {
    const momentDate = moment.utc(date);

    return momentDate.isValid() ? momentDate : null;
  }
}
