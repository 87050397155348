<div class="qv-modal-dialog delete-drug-modal">
  <header mat-dialog-title class="qv-modal-dialog-title">
    <h1 class="qv-modal-dialog-title__text">Delete Drugs</h1>
    <button class="qv-modal-dialog-title__xbutton" [mat-dialog-close]>
      <mat-icon>close</mat-icon>
    </button>
  </header>
  <div mat-dialog-content class="qv-modal-dialog-content">
    <p class="delete-drug-modal__error" *ngIf="errorMessage">
      {{ errorMessage }}
    </p>
    <div class="delete-drug-modal__search">
      <p class="delete-drug-modal__search-text">
        Select the drugs you want to delete from the RFP
      </p>
      <input
        matInput
        type="text"
        class="delete-drug-modal__search-field"
        placeholder="Search for a Drug"
        (keyup)="onFilter($any($event.target).value)"
      >
    </div>
    <div class="actions-bar">
      <button class="link-btn" (click)="onClearAll()">
        {{ resources.NewBid.CLEAR_ALL }}
      </button>
    </div>
    <div class="qv-modal-dialog-content__data">
      <div class="delete-drug-table">
        <div class="delete-drug-table__header-row" matSort>
          <div class="delete-drug-table__header-cell" [mat-sort-header]="displayedColumns[0]">Drugs</div>
          <div class="delete-drug-table__header-cell" [mat-sort-header]="displayedColumns[1]">Contracted Business</div>
        </div>
        <mat-table class="delete-drug-table__body" [dataSource]="drugs" *ngIf="hasDrugsInFilteredList()">
          <mat-row class="delete-drug-table__row"
            *matRowDef="let drug; columns: displayedColumns;"
            (click)="onToggleDrugSelection(drug, $event)"
          ></mat-row>
          <ng-container [matColumnDef]="displayedColumns[0]">
            <mat-cell class="delete-drug-table__cell" *matCellDef="let drug">
              <mat-checkbox class="qv-mat-checkbox" [checked]="drug.isSelected"></mat-checkbox>
              <span>{{ drug.drugName }}</span>
            </mat-cell>
          </ng-container>
          <ng-container [matColumnDef]="displayedColumns[1]">
            <mat-cell class="delete-drug-table__cell" *matCellDef="let drug">{{ drug.cbName }}</mat-cell>
          </ng-container>
        </mat-table>
        <div class="delete-drug-table__body--empty" *ngIf="!hasDrugsInFilteredList()">{{resources.BID_DETAILS.NO_DRUGS_FOUND}}</div>
      </div>
    </div>
  </div>
  <footer mat-dialog-actions class="qv-modal-dialog-actions qv-modal-dialog-actions--two-column">
    <div class="left-block">
      <mat-checkbox
        class="qv-mat-checkbox"
        [checked]="isDeleteDrugFromRfpSelected"
        (change)="onToggleDeleteDrugFromRfpSelection()"
      >
        Delete this drug from the entire RFP (all Contracted Businesses)
      </mat-checkbox>
    </div>
    <div class="right-block">
      <button mat-button [mat-dialog-close] class="qv-modal-dialog-actions__item">
        {{ resources.Actions.CANCEL }}
      </button>
      <button
        mat-button
        class="qv-modal-dialog-actions__item qv-modal-dialog-actions__item--primary"
        (click)="onDeleteDrugs()"
      >
        {{ resources.Actions.DELETE }}
      </button>
    </div>
  </footer>
</div>
