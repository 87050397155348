import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { EnrollmentDaoService } from '@qv-common/services/dao';
import { Enrollment, EnrollmentId } from 'quantuvis-core-entities';

@Injectable()
export class EnrollmentService {
  public readonly enrollments$ = new BehaviorSubject<Enrollment[]>([]);

  constructor(private enrollmentDaoService: EnrollmentDaoService) {}

  public loadEnrollments(): Observable<Enrollment[]> {
    return this.enrollmentDaoService.getList()
      .pipe(
        tap((enrollments: Enrollment[]) => this.enrollments$.next(enrollments))
      );
  }

  public isEnrollmentInList(enrollmentId: EnrollmentId): boolean {
    const enrollments = this.enrollments$.getValue();

    return enrollments.some(({ id }: Enrollment) => id === enrollmentId);
  }
}
