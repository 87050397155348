import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { LockInfo } from '@qv-bid/entities';
import { ApiUrlPrefix } from '@qv-common/enums';
import { HttpUtils } from '@qv-common/utils';
import { JsonConvert } from 'json2typescript';
import { HttpResponseBody } from 'quantuvis-angular-common/api';
import { JSON_CONVERT_TOKEN } from 'quantuvis-angular-common/json-converter/services';
import { BidAssignee } from 'quantuvis-core-entities';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class BidLockDaoService {
  constructor(
    private httpClient: HttpClient,
    @Inject(JSON_CONVERT_TOKEN) private jsonConvert: JsonConvert
  ) {}

  public lockBid(bidId: number, isInternalBid: boolean, perspective: BidAssignee): Observable<void> {
    let url = `${ApiUrlPrefix.BIDS}/${bidId}/lock`;

    url = HttpUtils.appendPerspectiveQueryParamToUrlIfBidIsInternal(url, perspective, isInternalBid);

    return this.httpClient.post<void>(url, {});
  }

  public prolongBidLock(bidId: number, isInternalBid: boolean, perspective: BidAssignee): Observable<LockInfo> {
    let url = `${ApiUrlPrefix.BIDS}/${bidId}/prolong-lock`;

    url = HttpUtils.appendPerspectiveQueryParamToUrlIfBidIsInternal(url, perspective, isInternalBid);

    return this.httpClient.post(url, {})
      .pipe(
        map(({ data }: HttpResponseBody<LockInfo>) => this.jsonConvert.deserializeObject(data, LockInfo))
      );
  }

  public unlockBid(bidId: number): Observable<void> {
    return this.httpClient.put<void>(`${ApiUrlPrefix.BIDS}/${bidId}/unlock`, null);
  }

  public getLockInfo(bidId: number): Observable<LockInfo> {
    return this.httpClient.get(`${ApiUrlPrefix.BIDS}/${bidId}/lock-info`)
      .pipe(
        map(({ data }: HttpResponseBody<LockInfo>) => this.jsonConvert.deserializeObject(data, LockInfo))
      );
  }
}
