<div *blockUI="'cb-import-modal'" class="qv-modal-dialog cb-import-modal">
  <form (ngSubmit)="onSubmit()" [formGroup]="importCBForm">
    <header class="qv-modal-dialog-title" mat-dialog-title>
      <div class="qv-modal-dialog-title__text">Import Contracted Business</div>
      <button [mat-dialog-close] class="qv-modal-dialog-title__xbutton">
        <mat-icon>close</mat-icon>
      </button>
    </header>
    <div class="qv-modal-dialog-content qv-modal-dialog-content--primary-padding" mat-dialog-content>
      <ng-select
          [clearable]="false"
          [formControl]="$any(importCBForm.get('cb'))"
          [items]="data.cbList"
          [searchable]="false"
          appendTo="body"
          bindLabel="name"
          bindValue="id"
          class="qv-select"
      >
      </ng-select>
    </div>
    <footer class="qv-modal-dialog-actions" mat-dialog-actions>
      <button
          [mat-dialog-close]
          class="qv-modal-dialog-actions__item"
          mat-button
          tabindex="-1"
      >Cancel
      </button>
      <button
          class="qv-modal-dialog-actions__item qv-modal-dialog-actions__item--primary"
          mat-button
          tabindex="-1"
          type="submit"
      >Overwrite
      </button>
    </footer>
  </form>
</div>
