import { JsonObject, JsonProperty } from 'json2typescript';
import { ScenarioDrugIds } from '@qv-bid/entities/scenario-drug-ids.entity';

@JsonObject('ContractedBusinessScenariosDrugsIds')
export class ContractedBusinessScenariosDrugsIds {
  @JsonProperty('cbId', Number)
  public cbId: number = null;

  @JsonProperty('scenarioDrugIds')
  public scenarioDrugIds: ScenarioDrugIds = null;

  constructor(cbId: number, scenarioDrugIds: ScenarioDrugIds) {
    this.cbId = cbId;
    this.scenarioDrugIds = scenarioDrugIds;
  }
}
