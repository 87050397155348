import { Directive, Input } from '@angular/core';
import { Ndc, Scenario } from '@qv-bid/entities';
import { UserService } from '@qv-common/services/auth/user.service';

@Directive()
export class BaseNdcHeader {
  @Input()
  public ndc: Ndc;

  @Input()
  public scenario: Scenario;

  constructor(private userService: UserService) {}

  public isLockVisible(): boolean {
    return this.ndc.isLocked && this.userService.isCurrentUserPharma()
      || !this.userService.isCurrentUserPharma();
  }
}
