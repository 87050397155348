/**
 * Create a new Attachment.
 */
export class Attachment {
  public attachmentId: number;
  public attachmentName: string;
  public attachmentSize: number;

  constructor(attachmentId: number, attachmentName: string, attachmentSize: number) {
    this.attachmentId = attachmentId;
    this.attachmentName = attachmentName;
    this.attachmentSize = attachmentSize;
  }
}
