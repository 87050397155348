import { Injectable } from '@angular/core';
import FileSaver from 'file-saver';
import { HttpResponse, HttpResponseBase } from '@angular/common/http';
import { ApiService } from './api.service';
import { RequestMethod, ResponseType } from 'quantuvis-angular-common/api';
import { Observable, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { RequestOption } from '@qv-common/services/api/request-option.interface';

@Injectable()
export class FileService {

  public static readonly PREPARING_DOWNLOAD_MSG = 'Preparing files for download...';
  public static readonly DOWNLOADING_MSG = 'Downloading...';

  constructor(private apiService: ApiService) { }

  public downloadFile(url: string, method?: RequestMethod, body?: any): Observable<HttpResponseBase> {
    return this.getDownloadFileObservable(url, method, body)
      .pipe(
        tap((response: HttpResponse<Blob>) => {
          const content: Blob = new Blob([response.body], { type: 'application/octet-stream' });
          const fileName = response.headers.get('filename');
          FileSaver.saveAs(content, fileName);
        })
      );
  }

  public loadPdfContent(url: string): Observable<HttpResponse<ArrayBuffer>> {
    const options: RequestOption = {
      responseType: ResponseType.ARRAY_BUFFER
    };

    return this.apiService.get(url, options);
  }

  private getDownloadFileObservable(url: string, method?: RequestMethod, body?: any): Observable<HttpResponseBase> {
    return of({}).pipe(
      switchMap(() => method === RequestMethod.POST
        ? this.apiService.post(url, body || null, { responseType: ResponseType.BLOB })
        : this.apiService.get(url, { responseType: ResponseType.BLOB })
      )
    );
  }

}
