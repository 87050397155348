import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { PermissionService } from '@qv-common/services/auth/permission.service';
import { map } from 'rxjs/operators';
import { UserService } from '@qv-common/services/auth/user.service';

@Injectable()
export class LegalAttestationGuard implements CanActivate {
  constructor(private authService: UserService, private permissionService: PermissionService, private router: Router) {}

  public canActivate(): Observable<boolean> {
    return this.authService.loadUserData()
      .pipe(
        map(() => {
          const canActivate = this.permissionService.isUserCompanyBindingBidEnabled();

          if (!canActivate) {
            this.router.navigate([]);
          }

          return canActivate;
        })
      );
  }
}
