import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Bid, BidVersion } from '@qv-bid/entities';
import { ViewPerspectiveService } from '@qv-common/services/auth';
import { PermissionService } from '@qv-common/services/auth/permission.service';
import { SvgIconName } from '@qv-common/enums';
import { QuantuvisPlusFeature } from '@qv-company/enums';
import { BidVersionService } from '@qv-bid/services';
import { DateUtils } from '@qv-common/utils';
import { switchMap, tap } from 'rxjs/operators';
import { BidToolbarAction } from '@qv-bid/enums/bid-toolbar-action.enum';
import { resources } from '@qv-common/static/resources';
import { QvCache } from '@qv-common/decorators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BidUtils } from '@qv-bid/utils';
import { UserService } from '@qv-common/services/auth/user.service';

@UntilDestroy()
@Component({
  selector: 'qv-bid-revision-toolbar',
  templateUrl: './bid-revision-toolbar.component.html',
  styleUrls: ['./bid-revision-toolbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BidRevisionToolbarComponent implements OnInit {
  @Input()
  public bid: Bid;

  @Output()
  public actionButtonClick: EventEmitter<any> = new EventEmitter<any>();

  public bidId: number;
  public bidHistoricVersions: BidVersion[] = [];
  public currentVersionIndex: number;
  public readonly resources = resources;
  public readonly svgIconName = SvgIconName;
  public readonly quantuvisPlusFeature = QuantuvisPlusFeature;
  public readonly bidUtils = BidUtils;

  public bidVersionId: number;

  constructor(
    private userService: UserService,
    private bidVersionService: BidVersionService,
    private permissionService: PermissionService,
    public router: Router,
    private activatedRoute: ActivatedRoute,
    private viewPerspectiveService: ViewPerspectiveService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  public ngOnInit(): void {
    this.activatedRoute.queryParams.pipe(
      tap(({ bidId, versionId }: { bidId: number, versionId: number }) => {
        this.bidId = Number(bidId);
        this.bidVersionId = Number(versionId);
      }),
      switchMap(() => this.bidVersionService.getHistoricVersions(this.bidId)),
      untilDestroyed(this)
    ).subscribe((bidVersions: BidVersion[]) => {
      this.storeLoadedBidHistoricVersions(bidVersions);
    });
  }

  @QvCache()
  public isPreviousVersionAvailable(currentVersionIndex: number): boolean {
    return currentVersionIndex > 0;
  }

  @QvCache()
  public isNextVersionAvailable(currentVersionIndex: number, amountOfHistoricVersions: number): boolean {
    return currentVersionIndex < amountOfHistoricVersions - 1;
  }

  @QvCache()
  public isFeatureAllowed(feature: string): boolean {
    return this.permissionService.isFeatureAllowed(feature);
  }

  public isPharma(): boolean {
    return this.userService.isCurrentUserPharma();
  }

  @QvCache()
  public getVersionNumber(currentVersionIndex: number): number {
    return currentVersionIndex + 1;
  }

  public getPreviousBidVersion(): number {
    return this.getBidVersionByOffset(-1);
  }

  public getCurrentBidVersion(): number {
    return this.getBidVersionByOffset(0);
  }

  public openBidDetailsPanel(): void {
    this.actionButtonClick.emit(BidToolbarAction.DETAILS);
  }

  public getBidVersionByOffset(offset: number): number {
    const bidVersion = this.bidHistoricVersions[this.currentVersionIndex + offset];

    return bidVersion ? bidVersion.id : null;
  }

  public navigateToVersion(versionId: number): void {
    this.router.navigate([],
      { queryParams: { ...this.enrichParamsWithViewPerspective({ versionId }, this.bid.isInternal) } });
  }

  public isAvailableBidViewSwitcher(): boolean {
    return this.permissionService.isFeatureAllowed(QuantuvisPlusFeature.GRID_VIEW);
  }

  public enrichParamsWithViewPerspective(currentParams: Params, isInternal: boolean): Params {
    return this.viewPerspectiveService.enrichParamsWithViewPerspective(Object.assign({},
      this.activatedRoute.snapshot.queryParams, currentParams), isInternal);
  }

  private getCurrentVersionIndex(bidHistoricVersions: BidVersion[]): number {
    return bidHistoricVersions.findIndex((bidVersion: BidVersion) => bidVersion.id === this.bidVersionId);
  }

  private storeLoadedBidHistoricVersions(bidVersions: BidVersion[]): void {
    const sortedBidVersions = bidVersions.sort((bidVersionA: BidVersion, bidVersionB: BidVersion) =>
      DateUtils.compareDatesForSort(bidVersionA.updatedAt, bidVersionB.updatedAt));

    this.bidHistoricVersions = sortedBidVersions;
    this.currentVersionIndex = this.getCurrentVersionIndex(sortedBidVersions);
    this.changeDetectorRef.markForCheck();
  }
}
