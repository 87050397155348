import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { resources } from '@qv-common/static';
import { catchError, switchMap } from 'rxjs/operators';
import { BidListNotificationService } from '@qv-bid/services';
import { BidDaoService } from '@qv-bid/services/dao';
import { BidHandleErrorService } from '@qv-bid/services/bid-handle-error.service';
import { MatDialogRef } from '@angular/material/dialog';
import {
  GeneralModalComponent,
  GeneralModalConfig,
  GeneralModalData,
  ModalService,
  ModalSize
} from 'quantuvis-angular-common/modal';

@Injectable()
export class BidListActionService {

  constructor(private bidDaoService: BidDaoService,
              private bidListNotificationService: BidListNotificationService,
              private bidHandleErrorService: BidHandleErrorService,
              private modalService: ModalService,
  ) {}

  public deleteBids(bidIds: number[]): Observable<null | HttpErrorResponse> {
    const title = resources.POPUPS.TITLES.DELETE_BIDS;
    const description = resources.BID_DETAILS.CONFIRM_DELETE_BIDS;

    const deleteConfirmModal = this.createConfirmModal(title, description);

    return deleteConfirmModal.componentInstance.primaryAction.pipe(
      switchMap(() => this.bidDaoService.deleteBids(bidIds).pipe(
        catchError((err: HttpErrorResponse) => this.catchMultiDeleteProcessError(err)),
        deleteConfirmModal.componentInstance.handleAfterAction(),
      ))
    );
  }

  public sendBids(ids: number[]): Observable<null | HttpErrorResponse> {
    const sendModal = this.createConfirmModal(resources.POPUPS.TITLES.SEND_BID, resources.BIDS_LIST.CONFIRM_SEND_BIDS);

    return sendModal.componentInstance.primaryAction.pipe(
      switchMap(() => this.bidDaoService.sendBids(ids)),
      this.bidHandleErrorService.handleSendBidError(),
      sendModal.componentInstance.handleAfterAction()
    );
  }

  private createConfirmModal(title: string, description: string): MatDialogRef<GeneralModalComponent> {
    const modalData = new GeneralModalData(
      title,
      description,
      resources.Actions.YES,
      resources.Actions.NO,
      false
    );
    const modalConfig = new GeneralModalConfig(modalData, ModalSize.X_SMALL);

    return this.modalService.openModal(modalConfig);
  }

  private catchMultiDeleteProcessError(err: HttpErrorResponse): Observable<HttpErrorResponse> {
    this.bidListNotificationService.showDeleteProcessingError(err);

    return throwError(err);
  }
}
