import { DictionaryItem } from '@qv-common/entities';

export enum PaymentFrequencyLag {
  EMPTY = 1,
  MONTHLY = 2,
  QUARTERLY = 3,
}

export namespace PaymentFrequencyLag {
  const valuesMap = new Map<PaymentFrequencyLag, DictionaryItem>([
    [PaymentFrequencyLag.EMPTY, { id: PaymentFrequencyLag.EMPTY, name: '' }],
    [PaymentFrequencyLag.MONTHLY, { id: PaymentFrequencyLag.MONTHLY, name: 'Monthly' }],
    [PaymentFrequencyLag.QUARTERLY, { id: PaymentFrequencyLag.QUARTERLY, name: 'Quarterly' }],
  ]);

  export function getValue(frequency: PaymentFrequencyLag): DictionaryItem {
    return valuesMap.get(frequency);
  }

  export function getValues(): DictionaryItem[] {
    return Array.from(valuesMap.values());
  }
}
