import { Injectable } from '@angular/core';
import { constants } from '@qv-common/static';
import { TermName } from '@qv-term/enums';
import { Observable, ReplaySubject } from 'rxjs';
import { Summary } from '@qv-bid/entities';
import { SummaryDaoService } from '@qv-bid/services/dao';
import { tap } from 'rxjs/operators';
import { BidStateService } from '@qv-bid/services';

@Injectable()
export class SummaryService {
  private summary = new ReplaySubject<Summary>(1);

  constructor(private summaryDaoService: SummaryDaoService, private bidStateService: BidStateService) { }

  get getSummary(): ReplaySubject<Summary> {
    return this.summary;
  }

  public static getContractDateName(currentTermName: string): string {
    if (currentTermName === TermName.SCENARIO_START_DATE) {
      return constants.SUMMARY_TERMS.CONTRACT_START_DATE.label;
    } else if (currentTermName === TermName.SCENARIO_END_DATE) {
      return constants.SUMMARY_TERMS.CONTRACT_END_DATE.label;
    }
  }

  public getSummaryInfo(bidVersion: number): void {
    this.summaryDaoService.getSummaryInfo(bidVersion).subscribe((summary: Summary) => this.summary.next(summary));
  }

  public updateSummaryInfo(bidVersionId: number, summaryId: number, summary: Summary): Observable<Summary> {
    const bid = this.bidStateService.bid$.getValue();

    return this.summaryDaoService
      .updateSummaryInfo(bidVersionId, summaryId, summary, bid.isInternal)
      .pipe(tap((updatedSummary: Summary) => this.summary.next(updatedSummary)));
  }
}
