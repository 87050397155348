import { DictionaryItem } from '@qv-common/entities';

export enum UtilizationManagementState {
  ALLOWED = 1,
  LOCKED = 2
}

export namespace UtilizationManagementState {
  export const valuesMap = new Map<UtilizationManagementState, DictionaryItem>([
    [UtilizationManagementState.ALLOWED, { id: UtilizationManagementState.ALLOWED, name: 'Allowed' }],
    [UtilizationManagementState.LOCKED, { id: UtilizationManagementState.LOCKED, name: 'Locked' }]
  ]);

  export function getValue(utilizationManagementState: UtilizationManagementState): DictionaryItem {
    return valuesMap.get(utilizationManagementState);
  }

  export function getValues(): DictionaryItem[] {
    return Array.from(valuesMap.values());
  }
}
