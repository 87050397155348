import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, filter, map, take } from 'rxjs/operators';
import { Bid, Scenario } from '@qv-bid/entities';
import { BidVersionType } from '@qv-bid/enums';
import { HttpParams } from '@angular/common/http';

@Injectable()
export class BidStateService {
  public isEditMode = new BehaviorSubject<boolean>(false);
  public activeDrugInEditMode = new BehaviorSubject<number>(null);
  public isReviewMode = new BehaviorSubject<boolean>(false);
  public isBidInvalid$ = new BehaviorSubject(false);
  public isBidReloaded$ = new BehaviorSubject(true);
  public bid$ = new BehaviorSubject<Bid>(null);
  public scenarios$ = new BehaviorSubject<Scenario[]>([]);
  public initialScenariosWithoutDrugs$ = new BehaviorSubject<Scenario[]>([]);
  public bidVersionId: number;
  public bidVersionType: BidVersionType;
  public cbId: number;
  public sortParams: HttpParams;
  public selectedContractedBusinessName: string;

  public isEditOrReviewMode$: Observable<boolean> = combineLatest([
    this.isEditMode,
    this.isReviewMode
  ]).pipe(
    map(([isEditMode, isReviewMode]: [boolean, boolean]) => isEditMode || isReviewMode)
  );

  public findScenarioById(scenarioId: number): Observable<Scenario> {
    return this.scenarios$
      .pipe(
        map((scenarios: Scenario[]) => scenarios.find((scenario: Scenario) => scenario.id === scenarioId))
      );
  }

  public replaceScenario(scenario: Scenario): void {
    this.scenarios$
      .pipe(
        take(1)
      )
      .subscribe((scenarios: Scenario[]) => {
        const scenarioIndex = scenarios.findIndex((storedScenario: Scenario) => storedScenario.id === scenario.id);

        if (scenarioIndex >= 0) {
          const newScenarioList = [...scenarios];
          newScenarioList.splice(scenarioIndex, 1, scenario);

          this.scenarios$.next(newScenarioList);
        }
      });
  }

  public isViewMode(): Observable<boolean> {
    return combineLatest([
      this.isEditMode,
      this.isReviewMode
    ]).pipe(
      distinctUntilChanged(),
      map(([isEditMode, isReviewMode]: [boolean, boolean]) => !isEditMode && !isReviewMode)
    );
  }

  public checkoutViewMode(): void {
    if (this.isEditMode.getValue()) {
      this.isEditMode.next(false);
    }
    if (this.isReviewMode.getValue()) {
      this.isReviewMode.next(false);
    }
  }

  public isBidInvalid(): Observable<boolean> {
    return this.isBidInvalid$.pipe(distinctUntilChanged());
  }

  public isDismissedDrugGroup(drugName: string): Observable<boolean> {
    return this.scenarios$
      .pipe(
        map((scenarios: Scenario[]) => scenarios.filter((scenario: Scenario) => scenario.drugName === drugName)),
        map((scenarios: Scenario[]) => scenarios.every((scenario: Scenario) => scenario.isDrugDismissed()))
      );
  }

  public waitForBidToBeReloaded(): Observable<boolean> {
    return this.isBidReloaded$.pipe(
      filter((isBidReloaded: boolean) => isBidReloaded),
      take(1)
    );
  }
}
