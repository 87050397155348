<div class="notes-modal" *blockUI="'notes-modal'" data-test-id="notes-modal">
  <header
    mat-dialog-title
    class="qv-modal-dialog-title"
    [class.qv-modal-dialog-title--has-warnings]="hasAtNdcWarnings()"
  >
    <div class="qv-modal-dialog-title__text">Notes</div>
    <button [mat-dialog-close] class="qv-modal-dialog-title__xbutton">
      <mat-icon>close</mat-icon>
    </button>
  </header>
  <div mat-dialog-content class="qv-modal-dialog-content qv-modal-dialog-content--notes">
    <div class="warnings" *ngIf="hasAtNdcWarnings()">
      <div class="warnings__message">
          <span *ngIf="data.viewNotes.isAtNdcHistoric">
            The Historic Notes differ at the Drug's NDC level (@NDC). To view, click each NDC's individual Notes icon.
          </span>
      </div>
      <div class="separator"></div>
      <div class="warnings__message">
          <span *ngIf="data.viewNotes.isAtNdcCurrent">
            The Current Notes differ at the Drug's NDC level (@NDC). Saving this Note will override all Current Notes at the NDC level.
          </span>
      </div>
    </div>
    <div class="notes">
      <div class="notes__historic">
        <div class="delete-historic" *ngIf="isDeleteHistoricAvailable()">
          <button class="link-btn" (click)="onDeleteHistoric()">Delete Historic Notes</button>
        </div>
        <div class="notes__title">Historic notes:</div>
        <div *ngIf="!data.viewNotes.isEmptyHistoric()" class="notes__list">
          <ng-container *ngFor="let historicNote of data.viewNotes.getHistoric">
            <div
              class="note__title">{{ getHistoricNoteCompanyName(historicNote) }} {{ historicNote.createdAt | dateTimeUserTz:dateFormat | async }}</div>
            <div class="note__text">{{ historicNote.value }}</div>
          </ng-container>
        </div>
        <div *ngIf="data.viewNotes.isEmptyHistoric()" class="notes__list notes__list--none">None</div>
      </div>
      <div class="separator"></div>
      <div class="notes__current">
        <div class="delete-historic" *ngIf="isDeleteHistoricAvailable()"></div>
        <div class="notes__title">Current notes:</div>
        <textarea
          *ngIf="!data.viewNotes.isEmptyCurrent() || data.isEditMode"
          class="qv-modal-dialog-content__field qv-modal-dialog-content__field--note"
          [formControl]="currentNote"
          [placeholder]="data.isEditMode ? 'Type here to add a note' : ''"
        ></textarea>
        <acc-form-field-errors
          *ngIf="data.isEditMode"
          [control]="currentNote"
          [validationMessages]="validationMessages"
        ></acc-form-field-errors>
        <div
          *ngIf="data.viewNotes.isEmptyCurrent() && !data.isEditMode"
          class="notes__list notes__list--none notes__list--forbidden">None
        </div>
      </div>
    </div>
  </div>
  <footer mat-dialog-actions class="qv-modal-dialog-actions qv-modal-dialog-actions--notes">
    <button
      *ngIf="!data.isEditMode"
      class="qv-modal-dialog-actions__item qv-modal-dialog-actions__item--primary"
      mat-button
      [mat-dialog-close]
    >{{resources.Actions.CLOSE}}</button>
    <button
      *ngIf="data.isEditMode"
      class="qv-modal-dialog-actions__item"
      mat-button
      [mat-dialog-close]
    >{{resources.Actions.CANCEL}}</button>
    <button
      *ngIf="data.isEditMode"
      class="qv-modal-dialog-actions__item qv-modal-dialog-actions__item--primary"
      mat-button
      (click)="onSubmit()"
      [disabled]="currentNote.invalid"
    >{{resources.Actions.SAVE}}</button>
  </footer>
</div>
