import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BidEventBusService } from '@qv-bid/services';
import { QvCache } from '@qv-common/decorators';
import { BlockingMessage, FieldValidationMessage, FormValidationError } from '@qv-common/enums';
import { ErrorNotificationService } from '@qv-common/services';
import { StringUtils } from '@qv-common/utils';
import { MarketBasketModalState } from '@qv-bid/enums';
import { MarketBasket } from '@qv-bid/entities';
import {
  MarketBasketDrug,
  MarketBasketTemplate,
  MarketBasketTemplateDaoService,
  MarketBasketTemplateType
} from 'market-basket/market-basket-common';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ModalSize } from 'quantuvis-angular-common/modal';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { catchError, finalize, map, switchMap } from 'rxjs/operators';
import { MarketBasketModalComponent } from '../market-basket-modal/market-basket-modal.component';
import { MarketBasketDaoService } from '@qv-bid/services/dao';
import { MarketBasketModal, MarketBasketModalOutput } from '@qv-bid/models';
import { NgSelectComponent } from '@ng-select/ng-select';

@UntilDestroy()
@Component({
  selector: 'qv-market-basket-edit',
  templateUrl: './market-basket-edit.component.html',
  styleUrls: ['./market-basket-edit.component.scss']
})
export class MarketBasketEditComponent extends MarketBasketModal implements OnInit {
  @Input()
  public manufacturerCompanyOldName: string;

  @BlockUI('market-basket-edit-modal')
  public blockUI: NgBlockUI;

  @ViewChild('multiSelect')
  public multiSelect: NgSelectComponent;

  public searchControl = new FormControl();

  public isSaveEnabled$ = new BehaviorSubject<boolean>(false);
  public marketBasketDrugs$: BehaviorSubject<MarketBasketDrug[]> = new BehaviorSubject([]);

  public addToOtherScenarios = false;

  public readonly nameMaxLength = 55;
  public readonly validationMessages = new Map<string, string>([
    [FormValidationError.REQUIRED, FieldValidationMessage.REQUIRED],
    [FormValidationError.MAX_LENGTH, 'Field length is limited to 55 characters']
  ]);
  public readonly clearAllDisabledTooltipText = 'You cannot remove drugs which are already included in an assigned Market Basket.';

  public marketBasketNameControl = new FormControl(
    null,
    {
      validators: [
        Validators.required.bind(this),
        Validators.maxLength(this.nameMaxLength),
      ],
      updateOn: 'change'
    }
  );
  public isClearAllDrugsDisabled$: Observable<boolean>;

  private editTitle = 'Edit Market Basket';
  private newTitle = 'New Market Basket Template';

  constructor(
    protected dialogRef: MatDialogRef<MarketBasketModalComponent>,
    protected errorNotificationService: ErrorNotificationService,
    protected bidEventBusService: BidEventBusService,
    private marketBasketDaoService: MarketBasketDaoService,
    private marketBasketTemplateDaoService: MarketBasketTemplateDaoService
  ) {
    super(dialogRef, errorNotificationService, bidEventBusService);
  }

  public ngOnInit(): void {
    this.dialogRef.updateSize(ModalSize.X_LARGE);
    this.initValidationHandler();
    this.setMarketBasketData();
    this.initIsClearAllDisabled();
  }

  public onSelectionChanged(marketBasketDrugs: MarketBasketDrug[]): void {
    this.marketBasketDrugs$.next([...marketBasketDrugs]);
  }

  public onMarketBasketNameBlur(): void {
    const value = StringUtils.trim(this.marketBasketNameControl.value);

    this.marketBasketNameControl.setValue(value);
  }

  public onSearchForDrug(query: string): void {
    this.searchControl.setValue(query);
  }

  public onSearchPartner(searchText: string, event: Event): void {
    event.preventDefault();
    this.multiSelect.filter(searchText);
  }

  @QvCache()
  public getModalTitle(state: MarketBasketModalState): string {
    switch (state) {
      case MarketBasketModalState.EDIT:
        return this.editTitle;
      case MarketBasketModalState.NEW_AND_ASSIGN:
        return this.newTitle;
    }
  }

  public onSave(): void {
    switch (this.state) {
      case MarketBasketModalState.EDIT:
        this.updateMarketBasket();
        break;
      case MarketBasketModalState.NEW_AND_ASSIGN:
        this.assignMarketBasket();
    }
  }

  private initIsClearAllDisabled(): void {
    const isMarketBasketAssignedToScenario = Boolean(this.scenarioId);

    this.isClearAllDrugsDisabled$ = this.marketBasketDrugs$
      .pipe(
        map((drugs: MarketBasketDrug[]) => drugs.length === 1 && isMarketBasketAssignedToScenario)
      );
  }

  private initValidationHandler(): void {
    combineLatest([
      this.marketBasketDrugs$,
      this.marketBasketNameControl.valueChanges
    ])
      .pipe(untilDestroyed(this))
      .subscribe(() => this.isSaveEnabled$.next(this.isSaveEnabled()));
  }

  private isSaveEnabled(): boolean {
    return this.marketBasketNameControl.valid && this.marketBasketDrugs$.getValue().length > 0;
  }

  private assignMarketBasket(): void {
    this.blockUI.start(BlockingMessage.SAVING);

    this.marketBasketTemplateDaoService.create(this.prepareNewMarketBasketTemplate())
      .pipe(
        switchMap((template: MarketBasketTemplate) =>
          this.marketBasketDaoService.assignToScenario(this.scenarioId, template.id,
            this.addToOtherScenarios, this.isBidInternal)
        ),
        catchError((errorResponse: HttpErrorResponse) => this.onSaveError(errorResponse)),
        finalize(() => this.blockUI.stop()),
      )
      .subscribe((marketBasket: MarketBasket) => this.handleSaveSuccess(marketBasket));
  }

  private updateMarketBasket(): void {
    const marketBasket = Object.assign(new MarketBasket(), this.marketBasket, {
      name: this.marketBasketNameControl.value,
      drugs: this.marketBasketDrugs$.getValue(),
    } as MarketBasket);

    this.blockUI.start(BlockingMessage.SAVING);
    this.marketBasketDaoService.update(this.scenarioId, marketBasket, this.addToOtherScenarios, this.isBidInternal)
      .pipe(
        catchError((errorResponse: HttpErrorResponse) => this.onSaveError(errorResponse)),
        finalize(() => this.blockUI.stop()),
      )
      .subscribe((updatedMarketBasket: MarketBasket) => this.handleSaveSuccess(updatedMarketBasket));
  }

  private handleSaveSuccess(marketBasket: MarketBasket): void {
    const output = new MarketBasketModalOutput(marketBasket, this.addToOtherScenarios);
    this.primaryAction.emit(output);
    this.onClose();
  }

  private setMarketBasketData(): void {
    if (this.marketBasket) {
      this.marketBasketNameControl.setValue(this.marketBasket.name);
      this.marketBasketNameControl.markAsDirty();
    }

    this.prepareMarketBasketDrugs();
  }

  private prepareMarketBasketDrugs(): void {
    if (this.marketBasket) {
      this.marketBasketDrugs$.next([...this.marketBasket.drugs]);
    } else if (this.drugClass && this.drugName) {
      const scenarioDrug = Object.assign(new MarketBasketDrug(), {
        name: this.drugName,
        drugClass: this.drugClass
      } as MarketBasketDrug);
      this.marketBasketDrugs$.next([scenarioDrug]);
    }
  }

  private prepareNewMarketBasketTemplate(): MarketBasketTemplate {
    return Object.assign(new MarketBasketTemplate(), {
      name: this.marketBasketNameControl.value,
      type: MarketBasketTemplateType.ANALYTICAL,
      drugs: this.marketBasketDrugs$.getValue()
    } as MarketBasketTemplate);
  }
}
