import { ValidatorFn } from '@angular/forms';
import { ValidateNumberOptions } from '@qv-common/models';
import { NumberUtils } from '@qv-common/utils';
import { BaseTerm } from './base.term';
import { TermTypes, Units } from '@qv-common/enums';

export class InputTerm extends BaseTerm {
  public prefix: string;
  public suffix: string;
  public placeholder: string;

  protected unitsService: Units;
  private readonly defaultNumberOptions = new ValidateNumberOptions(5, 0, 100, 0.0001, 2);

  constructor(name: string,
              label: string,
              description: string,
              path: string,
              value: string,
              defaultValue: string,
              cssClass: string) {
    super(name, label, description, path, value, defaultValue, cssClass);
    this.type = TermTypes.Input;
    this.unitsService = new Units();
  }

  public numberValidator(options: ValidateNumberOptions = this.defaultNumberOptions): ValidatorFn {
    return NumberUtils.numberValidator(options);
  }
}
