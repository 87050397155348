/* eslint-disable max-lines */

export const constants = {
  APP_CONFIG: {
    CONTACT: {
      sales: {
        phone: '877-426-1099, press 2',
        email: 'sales@quantuvis.net'
      },

      general: {
        phone: '877-426-1099',
        email: 'info@quantuvis.net'
      },

      support: {
        phone: '877-426-1099, press 3',
        email: 'support@quantuvis.net'
      },

      hq: ['Quantuvis LLC', '1775 Tysons Blvd, 6th Floor', 'Tysons, VA 22102']
    }
  },
  SCENARIO_INDICATOR_PREFIX: 'S',
  SCENARIO_INDICATOR_MAX_NUMBER: 99,
  AT_NDC: '@NDC',
  ALL_OPTION: 'All',
  ALL_COMPANIES_OPTION: 'All Company Types',
  ALL_ROLES_OPTION: 'All Role Types',
  USER_PREFERENCES: 'userPreferences',
  COPY_VALUES_STORAGE_KEY: 'copyValuesData',
  EXPANDER_STATES_STORAGE_KEY: 'expanderStates',
  UNDO_VERSIONS_STORAGE_KEY: 'undoVersions',
  REDO_VERSIONS_STORAGE_KEY: 'redoVersions',
  UNDO_REDO_VERSIONS_LIMIT: 5,
  USER_PREFERENCES_DASHBOARD_LINE_OF_BUSINESS_OPTION: 'dashboardLineOfBusinessOption',
  USER_PREFERENCES_PEOPLE_PAGE_USER_TYPE_OPTION: 'peoplePageUserTypeOption',
  USER_PREFERENCES_ADMINISTRATOR_PAGE_COMPANY_TYPE_OPTION: 'administratorPageCompanyTypeOption',
  USER_PREFERENCES_MARKET_BASKET_VIEW_MODE: 'marketBasketViewMode',
  USER_PREFERENCES_REPORTS_VIEW_MODE: 'reportsViewMode',
  USER_PREFERENCES_BIDS_LIST_FILTERS: 'bidsListFilters',
  USER_PREFERENCES_BIDS_LIST_SORTING: 'bidsListSorting',
  VIEW_MODES: {
    GRID_VIEW: 'gridView',
    LIST_VIEW: 'listView',
    WIDE_VIEW: 'wideList'
  },
  UserStates: {
    NEW: 'NEW',
    INACTIVE: 'INACTIVE',
    ACTIVE: 'ACTIVE'
  },
  UserStatus: {
    INVITED: 'Invited',
    INVITED_EXPIRED: 'Invited - Invite Expired',
    UNINVITED: 'Uninvited',
    ENROLLED: 'Enrolled',
    RESET_PASSWORD: 'Reset Password',
    FORGOT_PASSWORD: 'Forgot password',
    DISABLED: 'Disabled',
    TEMPORARILY_LOCKED: 'Temporarily Locked'
  },
  EVENTS: {
    FILE_UPLOAD_MESSAGE: 'FILE_UPLOAD_MESSAGE',
    CREATE_BID_UPLOAD_MESSAGE: 'CREATE_BID_UPLOAD_MESSAGE',
    USER_CHANGED_MESSAGE: 'USER_CHANGED_MESSAGE',
    HEADER_LOADED: 'HEADER_LOADED',
    DELAYED_UPLOAD: 'DELAYED_UPLOAD',
    FILE_SELECTED: 'FILE_SELECTED',
    SET_DEFAULT_FILENAME: 'SET_FILENAME',
    FILE_UPLOADED: 'FILE_UPLOADED',
    FORCE_REFRESH: 'FORCE_REFRESH',
    SEND_BIDS_FROM_SUMMARY_PAGE: 'SEND_BIDS_FROM_SUMMARY_PAGE',
    DELETE_BIDS_FROM_SUMMARY_PAGE: 'DELETE_BIDS_FROM_SUMMARY_PAGE',
    COPY_BIDS: 'COPY_BIDS',
    COPY_BIDS_FROM_DETAIL_PAGE: 'COPY_BIDS_FROM_DETAIL_PAGE',
    COMPARE_ALL_BIDS: 'COMPARE_ALL_BIDS',
    COMPARE_OFFERED_BIDS: 'COMPARE_OFFERED_BIDS',
    EXPORT_BID: 'EXPORT_BID',
    PRINT_BID: 'PRINT_BID',
    PRINT_BIDS: 'PRINT_BIDS',
    LOCK_OR_UNLOCK_TERM: 'LOCK_OR_UNLOCK_TERM',
    SAVE_CHANGES_IMMEDIATELY: 'SAVE_CHANGES_IMMEDIATELY',
    TERM_VALUE_UPDATE: 'TERM_VALUE_UPDATE'
  },
  IMMEDIATELY_SAVE_EVENTS: {
    LOCKS_CHANGE: 'LOCKS_CHANGE'
  },
  APP: {
    BID_SAVE_INTERVAL: 500,
    LOGOUT_INTERVAL: 100,
    BID_PING_INTERVAL: 30000,
    CHECK_NOTIFICATIONS_INTERVAL: 5000,
    MESSAGE_TIMEOUT: 3000,
    METHOD_THROTTLE_INTERVAL: 3000,
    BID_INFO_SAVE_INTERVAL: 500,
    TERM_CHANGE_DEBOUNCE_INTERVAL: 2000,
    MAT_TOOLTIP_DEFAULTS: {
      showDelay: 1000
    }
  },
  COMPANY_TYPES: {
    PAYER: {
      value: 'PAYER',
      displayValue: 'Payer',
      pluralValue: 'Payers'
    },
    PHARMA: {
      value: 'PHARMA',
      displayValue: 'Pharma',
      pluralValue: 'Pharmas'
    },
    PAYER_INTERNAL: {
      value: 'PAYER_INTERNAL',
      displayValue: 'Payer (INTERNAL)',
      pluralValue: 'Payers (INTERNAL)'
    }
  },
  PERMISSIONS: {
    MARKET_BASKET: 'market_basket'
  },

  MARKET_BASKET_CONTENT: {
    DRUGS_DISPLAY_LENGTH: 5
  },
  CURRENCIES: [
    '$'
  ],
  BID_SECTIONS: {
    SUMMARY_TERMS: 'summaryTerms',
    CONTRACT_TERMS: 'contractTerms',
    DRUG_LIST_TERMS: 'drugListTerms',
    DRUG_LIST_PATH: 'drugList',
    LOCK_NOTES_PATH: 'drugList.notes',
    ALLOW_MARKET_SHARE_REBATE_PATH: 'drugList.marketShare',
    ALLOW_MARKET_BASKET_PATH: 'drugList.marketBasket',
    ALLOW_CONTRACTED_BUSINESS_PATH: 'drugList.ContractedBusiness',
    ALLOW_DEPENDENCIES_PATH: 'drugList.Dependencies',
    ALLOW_CREATE_SCENARIOS_PATH: 'drugList.Scenarios',
    ALLOW_NDC_OFFERS: 'drugList.ndc'
  },
  BIDDING: {
    MANUFACTURER: 'Pharma',
    LEGAL_ATTESTATION: 'Legal Attestation',
    PLAN_TYPE: 'Plan Type',
    PLAN_TYPE_DISPLAY_NAME: 'Contracted Business',
    IMPORT_PLAN_TYPE_SELECTION: 'Select a tab to import',
    DRUGS_EVALUATED: 'Drugs Evaluated',
    ATTACHMENTS_LABEL: 'Business Attachments',
    PAYMENT: 'Payment',
    LATE_PAYMENT_PENALTY_TERMS: 'Late Payment Penalty Terms',
    LATE_PAYMENT_PENALTY_TERMS_BASIS_PREFIX: 'Basis',
    LATE_PAYMENT_PENALTY_TERMS_VALUE_PREFIX: 'Value',
    LATE_PAYMENT_PENALTY_TERMS_FREQUENCY_PREFIX: 'Frequency',
    AUDIT_FREQUENCY: 'Audit Frequency',
    WHO_MAY_INVOKE: 'Who May Invoke',
    PRIOR_NOTICE_REQUIRED: 'Prior Notice Required',
    LOOK_BACK: 'Look Back',
    PHARMA_AUDIT_TERMS: 'Pharma Audit Terms',
    NO_FAULT_TERMINATION_CLAUSE: 'No Fault Termination Clause',
    WHO_MAY_INVOKE_NEITHER_PARTY_VALUE: 'Neither Party',
    FEES_SECTION: 'Fees',
    INVALID_TERM_STYLE: 'invalidTerm'
  },
  LINE_OF_BUSINESS: {
    MEDICARE: 'Medicare',
    MEDICAID: 'Medicaid',
    MEDICAL: 'Medical',
    COMMERCIAL: 'Commercial',
    EXCHANGES: 'Exchanges'
  },
  WAC: {
    UNAVAILABLE: 'Unavailable'
  },
  BINDING_BID_TYPE: {
    BINDING: 'Binding',
    NON_BINDING: 'Non Binding'
  },
  BidComparisonSections: {
    BID_SUMMARY_TERMS: 'Bid Summary Terms',
    BID_CONTRACT_TERMS: 'Bid Contract Terms'
  },
  BidComparisonFields: {
    SCENARIO: 'Scenario',
    DISMISS: 'Dismiss',
    DRUG: 'DRUG',
    NDC: 'NDC',
    DEPENDENCY: 'Dependency'
  },
  BidComparisonFieldValues: {
    SCENARIO_CREATED: 'SCENARIO CREATED',
    SCENARIO_DELETED: 'SCENARIO DELETED',
    DELETED: 'DELETED',
    DISMISSED: 'DISMISSED',
    RECALLED: 'RECALLED'
  },
  RoleTypes: {
    PAYER: 'PAYER',
    PHARMA: 'PHARMA',
    ADMIN: 'ADMIN'
  },
  RoleNames: {
    PAYER: 'PAYER',
    PAYER_ADMIN: 'PAYER_ADMIN',
    PHARMA: 'PHARMA',
    PHARMA_ADMIN: 'PHARMA_ADMIN',
    QUANTUVIS_ADMIN: 'QUANTUVIS_ADMIN'
  },
  PACKAGE_TYPES: {
    QUANTUVIS_PLUS: 'QUANTUVIS_PLUS',
    QUANTUVIS_BUSINESS: 'QUANTUVIS_BUSINESS'
  },
  AccessRights: [
    {
      value: 'NONE',
      displayValue: 'None'
    },
    {
      value: 'READ',
      displayValue: 'Read'
    },
    {
      value: 'WRITE',
      displayValue: 'Write'
    }
  ],
  BidTypes: {
    BID_SUMMARY: 'BID_SUMMARY',
    BID_DETAIL: 'BID_DETAIL',
    BID_BASIC: 'BID_BASIC'
  },
  BidAssigneeTypes: {
    PAYER: 'PAYER',
    PHARMA: 'PHARMA',
    NONE: 'NONE'
  },
  CompanyTypes: {
    PAYER: 'PAYER',
    PHARMA: 'PHARMA'
  },
  BidStatuses: {
    RFP_NOT_SENT: 'RFP_NOT_SENT',
    ACCEPTED_WITH_CHANGES: 'ACCEPTED_WITH_CHANGES',
    DRAFT: 'DRAFT',
    RESPONSE_REQUESTED: 'RESPONSE_REQUESTED',
    ACCEPTED: 'ACCEPTED',
    DECLINED: 'DECLINED',
    REVIEW: 'REVIEW'
  },
  UserRights: {
    READ: 'Read',
    WRITE: 'Write',
    NONE: 'None'
  },
  BidStatusDisplayNameMap: {
    RFP_NOT_SENT: 'RFP Not Sent',
    RESPONSE_REQUESTED: [
      'RFP Sent',
      'RFP Received'
    ],
    RFP_SENT: 'RFP Sent',
    RFP_RECEIVED: 'RFP Received',
    DRAFT: 'Bid Draft',
    ACCEPTED_WITH_CHANGES: [
      'Bid Sent',
      'Bid Received'
    ],
    BID_SENT: 'Bid Sent',
    BID_RECEIVED: 'Bid Received',
    ACCEPTED: 'Bid Accepted',
    LATE: 'Late',
    DECLINED: 'Bid Declined',
    UNDER_CONTRACT: 'Under Contract',
    REVIEW: 'Bid Review',
    BINDING: '(Binding)'
  },
  BidStatusSuffix: {
    FINAL: 'Final'
  },
  DrugList: {
    BRAND_NDC: 'DRUG',
    STEP_THERAPY: 'Step Therapy',
    PRIOR_AUTHORIZATION: 'Prior Authorization',
    MARKET_SHARE_REBATE: 'Market Share Rebate',
    RANGE_OR_UNITS: 'Range or Units',
    REBATE: 'Rebate',
    RANGE_OR_UNITS_AND_REBATES: 'Range Or Units And Rebates',
    PRICE_PROTECTION: 'Price Protection',
    LOCK_NOTES: 'Lock Notes',
    ALLOW_MARKET_BASKET: 'Allow Market Basket',
    ALLOW_MARKET_SHARE: 'Allow Market Share',
    ALLOW_OFFERS_AT_NDC_LEVEL: 'Allow offers at NDC level',
    ALLOWED: 'Allowed on Product',
    REQUIRED: 'Required on Competitors Products',
    UTILIZATION_MANAGEMENT_OPTIONS: 'Utilization Management Options',
    TIER_PLACEMENT: 'Tier Placement',
    TIER_COMPETITION: 'Tier Competition',
    COMPETITION_GROUP: 'Competition Group',
    TIER_COMPETITOR: 'Competitor\'s Tie',
    TIER_OPTIONS: 'Tier options',
    QUANTITY_LIMIT: 'Quantity Limit',
    OTHER_UM: 'Other UM',
    UM_DETAILS: 'UM Details'

  },
  COMPETITION_GROUP_OPTIONS: {
    EMPTY: '',
    Manufacturer: 'Manufacturer',
    Product: 'Product'
  },
  PICKER_MIN_CHARACTERS_FOR_SEARCH: 2,
  PICKER_MAX_ITEMS: 250,
  PRINT_LIMIT_SIZE: 30,
  DRUG_NAME_MAX_LENGTH: 30,
  DRUG_NOTES_MAX_LENGTH: 1000,
  INSTRUCTIONS_MAX_LENGTH: 20000,
  COMMENTS_MAX_LENGTH: 2000,
  FEEDBACK_SUBJECT_MAX_LENGTH: 100,
  MESSAGE_MAX_LENGTH: 10000,
  CONTRACTED_BUSINESS_NAME_MAX_LENGTH: 55,
  MARKET_BASCKET_NAME_MAX_LENGTH: 55,
  RFP_TITLE_MAX_LENGTH: 55,
  PHARMA_AUDIT_MAX_NUMBER: 999,
  SIGNATURE_MAX_LENGTH: 75,
  EMAIL_NOT_VALID_ERROR_MSG: 'Email Address is invalid. Please verify spelling and try again.',
  SCENARIOS_MAX_LENGTH: 20,
  MAX_COPY_BIDS: 30,
  MAX_SEND_BIDS: 30,
  MAX_DELETE_BIDS: 30,
  USER_TYPES: [
    {
      value: 'member',
      displayValue: 'User'
    },
    {
      value: 'admin',
      displayValue: 'Administrator'
    }
  ],
  TERM_SECTIONS: {
    ALL: {
      name: 'All',
      color: 'black',
      inactiveColor: 'white',
      checked: true
    },
    CONTRACT_DATES: {
      name: 'Contract Terms',
      color: 'black',
      inactiveColor: 'white',
      checked: true
    },
    MARKET_SHARE: {
      name: 'Market Share',
      color: 'black',
      inactiveColor: 'white',
      checked: true
    },
    GENERAL: {
      name: 'Utilization Management',
      color: 'black',
      inactiveColor: 'white',
      checked: true
    },
    PRICE_PROTECTION: {
      name: 'Price Protection',
      color: 'black',
      inactiveColor: 'white',
      checked: true
    },
    DISMISSED: {
      name: 'Dismissed',
      color: 'black',
      inactiveColor: 'white',
      checked: true
    }
  },
  DRUG_TERM_PATHS: {
    ALLOW_CONTRACT_DATES: ['drugList', 'contractDates'],
    ALLOW_CONTRACT_START_DATE: ['drugList', 'Contract Start Date'],
    ALLOW_CONTRACT_END_DATE: ['drugList', 'Contract End Date'],
    ALLOW_MARKET_SHARE_REBATE: ['drugList', 'marketShare'],
    ALLOW_PRICE_PROTECTION: ['drugList', 'priceProtection'],
    ALLOW_UTILIZATION_MANAGEMENT: ['drugList', 'utilizationManagement'],
    ALLOW_NDC_OFFERS: ['drugList', 'ndc'],
    ALLOW_MARKET_BASKET: ['drugList', 'marketBasket'],
    ALLOW_MARKET_SHARE: ['drugList', 'marketShare'],
    ALLOW_REBATE: ['drugList', 'rebate'],
    ALLOW_TIERS: ['drugList', 'tiers'],
    ALLOW_BASE_REBATE: ['drugList', 'baseRebate'],
    ALLOW_NOTES: ['drugList', 'notes'],
    ALLOW_ADMINISTRATION_FEE: ['drugList', 'Administration Fee']
  },
  BID_TERM_PATHS: {
    ALLOW_DEPENDENCIES: ['drugList', 'Dependencies'],
    ALLOW_SCENARIOS: ['drugList', 'Scenarios'],
    ALLOW_CONTRACTED_BUSINESS: [
      'drugList',
      'ContractedBusiness'
    ],
    LOCK_NOTES: [
      'drugList',
      'notes'
    ]
  },
  DRUGS_TABLE: {
    INITIAL_TOTAL: 5,
    DRUGS_TO_ADD: 5,
    DRUGS_VISIBLE: 5
  },
  PRICE_PROTECTION_METADATA_STATES: [
    {
      name: 'Allowed',
      state: 'visible'
    },
    {
      name: 'Required',
      state: 'required'
    },
    {
      name: 'Locked',
      state: 'locked'
    }
  ],
  DRUG_TERM_GROUPS_STATE_VALUES: {
    LOCKED_STATE: {
      state: 'locked'
    },
    UNLOCKED_STATE: {
      state: 'unlocked'
    },
    HIDDEN_STATE: {
      state: 'hidden'
    },
    VISIBLE_STATE: {
      state: 'visible'
    },
    OPTIONAL_STATE: {
      state: 'optional'
    },
    REQUIRED_STATE: {
      state: 'required'
    },
    AT_NDC_STATE: {
      state: 'atndc'
    }
  },
  SET_LOCKS_ON_SCENARIO_CREATION_FOR_PHARMA: [
    {
      pattern: 'drugList.tiers.{0}.{1}',
      state: 'UNLOCKED_STATE',
      ndcLevel: true
    },
    {
      pattern: 'drugList.notes.{0}.{1}',
      state: 'UNLOCKED_STATE',
      ndcLevel: false
    }
  ],
  SET_UNLOCKS_ON_SCENARIO_CREATION: [
    {
      pattern: 'drugList.baseRebate'
    }
  ],
  MY_ACCOUNTS: {
    EMAIL_PREFERENCES: [
      {
        name: 'All Accounts',
        val: 'ALL'
      },
      {
        name: 'My Accounts',
        val: 'MY_ACCOUNTS'
      },
      {
        name: 'No Emails',
        val: 'NONE'
      }
    ]
  },
  REPORTS: {
    ACCESS_LEVEL: {
      USER: 'My Reports',
      COMPANY: 'Company Reports'
    },
    ACCESS_LEVEL_KEYS: {
      USER: 'USER',
      COMPANY: 'COMPANY',
      SHARED: 'SHARED'
    },
    DATA_SOURCE: {
      MOST_RECENT_VERSION: 'Most recent draft / offered version',
      OFFERED_VERSION: 'Most recent offered version'
    },
    REPORT_TYPE: {
      DRUG_LEVEL: 'Drug level report',
      DRUG_CLASS_LEVEL: 'Drug class report',
      BID_LEVEL: 'Bid level report'
    },
    TITLE_MAX_LENGTH: 55,
    DESCRIPTION_MAX_LENGTH: 200,
    DRUG_STATUS: {
      ACCEPTED: 'Accepted',
      DISMISSED: 'Dismissed',
      DISMISSED_IN_REVIEW: 'Review: Dismissed'
    }
  },
  CONTRACT_TERMS: {
    REBATE_ELIGIBILITY: {
      label: 'Rebate Eligibility',
      key: 'C1'
    },
    PAYMENT_LAG: {
      label: 'Payment Lag',
      key: 'C2'
    },
    PAYMENT_FREQUENCY: {
      label: 'Payment Frequency',
      key: 'C3'
    },
    WHOLESALE_ACQUISITION_PRICE: {
      label: 'Wholesale Acquisition Price',
      displayName: 'WAC',
      key: 'C4'
    },
    LATE_PAYMENT_PENALTY_TERMS_BASIS: {
      label: 'Late Payment Penalty Terms Basis',
      displayName: 'Basis',
      key: 'C5'
    },
    LATE_PAYMENT_PENALTY_TERMS_FREQUENCY: {
      label: 'Late Payment Penalty Terms Frequency',
      displayName: 'Frequency',
      key: 'C6'
    },
    PHARMA_AUDIT_TERMS_AUDIT_FREQUENCY: {
      label: 'Pharma Audit Terms Audit Frequency',
      displayName: 'Audit Frequency',
      key: 'C7'
    },
    NO_FAULT_TERMINATION_CLAUSE_WHO_MAY_INVOKE: {
      label: 'No Fault Termination Clause Who May Invoke',
      displayName: 'Who May Invoke',
      key: 'C8'
    },
    NO_FAULT_TERMINATION_CLAUSE_PRIOR_NOTICE_REQUIRED: {
      label: 'No Fault Termination Clause Prior Notice Required',
      displayName: 'Prior Notice Required',
      key: 'C9'
    },
    PHARMA_AUDIT_TERMS_LOOK_BACK: {
      label: 'Pharma Audit Terms Look Back',
      displayName: 'Look Back',
      key: 'C10'
    },
    PHARMA_AUDIT_TERMS_PRIOR_NOTICE_REQUIRED: {
      label: 'Pharma Audit Terms Prior Notice Required',
      displayName: 'Prior Notice Required',
      key: 'C11'
    },
    LATE_PAYMENT_PENALTY_TERMS_VALUE: {
      label: 'Late Payment Penalty Terms Value',
      displayName: 'Value',
      key: 'C12'
    },
    ADMINISTRATION_FEE: {
      label: 'Administration Fee',
      key: 'C13'
    }
  },
  SUMMARY_TERMS: {
    RFP_TITLE: {
      label: 'RFP Title',
      displayName: 'Title',
      key: 'S1'
    },
    LINE_OF_BUSINESS: {
      label: 'Line Of Business',
      key: 'S2'
    },
    RFP_START_DATE: {
      label: 'RFP Start Date',
      key: 'S3'
    },
    RFP_DUE_DATE: {
      label: 'RFP Due Date',
      key: 'S4'
    },
    ATTACHMENTS: {
      label: 'Attachments',
      key: 'S5'
    },
    WEBSITE_LINKS: {
      label: 'Website Links',
      key: 'S6'
    },
    CONTRACT_START_DATE: {
      label: 'Contract Start Date',
      key: 'S7'
    },
    CONTRACT_END_DATE: {
      label: 'Contract End Date',
      key: 'S8'
    },
    CONTRACT_EVERGREEN: {
      label: 'Contract Evergreen',
      key: 'S9'
    },
    INSTRUCTIONS: {
      label: 'Instructions',
      key: 'S10'
    },
    BINDING_BID: {
      label: 'Binding Bid',
      key: 'S11'
    },
    COMMENTS: {
      label: 'Comments',
      key: 'S12'
    }
  },
  COMPANY_CONSTANTS: {
    UPGRADE_PHONE: '877-426-1099, ext. 2',
    SALES_EMAIL: 'sales@quantuvis.net'
  },
  COMMONS: {
    EMPTY_STRING: ''
  },
  CREATE_BID_STEPS: {
    SUMMARY_TERMS: {
      name: 'SUMMARY_TERMS',
      title: 'Summary Terms'
    },
    CONTRACT_TERMS: {
      name: 'CONTRACT_TERMS',
      title: 'Add Contract Terms'
    },
    RFP_OPTIONS: {
      name: 'RFP_OPTIONS',
      title: 'Select Drugs to be Evaluated'
    },
    SELECT_MANUFACTURERS: {
      name: 'SELECT_MANUFACTURERS',
      title: 'Select Pharmas'
    },
    SELECT_DRUGS: {
      name: 'SELECT_DRUGS',
      title: 'Select Drugs'
    },
    DRUG_LEVEL_TERMS: {
      name: 'DRUG_LEVEL_TERMS',
      title: 'Set Drug Level Terms'
    }
  },
  DRUGS_EVALUATED_ENUM: {
    ALL_MANUFACTURERS_SOME_DRUGS: 1,
    SOME_MANUFACTURERS_ALL_DRUGS: 2,
    SOME_INTERNAL_PAYERS: 3,
    MANUFACTURER_DRUGS: 4
  },
  UNITS: {
    DOLLAR: '$',
    VALUE: 'Value',
    PRIOR_NOTICE_REQUIRED: 'Prior Notice Required',
    DAYS: 'Days',
    MONTHS: 'Months',
    PERCENT: '%'
  },
  OPTIONS: {
    TIER_COMPETITION_PLACEMENT: {
      EMPTY: '',
      '1_OF_1': '1 of 1',
      '1_OF_2': '1 of 2',
      '1_OF_2_WO_ML': '1 of 2 w/o market leader',
      '1_OF_3': '1 of 3',
      '1_OF_3_WO_ML': '1 of 3 w/o market leader',
      '1_OF_4': '1 of 4',
      '1_OF_5': '1 of 5',
      '1_OF_ALL': '1 of All',
      '1_OF_MANY': '1 of Many',
      BRAND_OVER_GENERIC: 'Brand over Generic'
    },
    INDEX: {
      EMPTY: '',
      ACTUAL_DRUG_WAC_PRICE: 'Actual Drug WAC Price',
      CONSUMER_PRICE_INDEX: 'Consumer Price Index (CPI)',
      CONSUMER_PRICE_INDEX_CONSUMERS: 'Consumer Price Index-Urban Consumers (CPI-U)',
      CHAINED_CONSUMER_PRICE_INDEX: 'Chained Consumer Price Index (C-CPI-U)',
      CONSUMER_PRICE_INDEX_WORKERS: 'Consumer Price Index- Urban Wage Earners and Clerical Workers (CPI-W)',
      PRESCRIPTION_PRICE_INDEX: 'Prescription Price Index (PPI)'
    },
    MARKET_SHARE_TIER: {
      EMPTY: '',
      SPECIFIC_UNIT_COUNT: 'Specific Unit Count',
      MARKET_SHARE_PERCENT: 'Market Share %',
      MARKET_SHARE_PLUS_PERCENT: 'Market Share +%',
      NAT_MARKET_SHARE_PLUS_PERCENT: 'National Market Share +%',
      SPECIFIC_RX_COUNT: 'Specific RX Count',
      SPECIFIC_SPEND: 'Specific Spend ($US)',
      PERCENT_OF_NAT_MARKET_SHARE: '% of National Market Share'
    },
    YES_NO_EMPTY: {
      EMPTY: '',
      YES: 'Yes',
      NO: 'No'
    },
    YES_NO: {
      YES: 'Yes',
      NO: 'No'
    },
    RESET: {
      EMPTY: '',
      ANNUALLY: 'Annually',
      ANNUALLY_CY: 'Annually - Contract Year',
      CUMULATIVE: 'Cumulative',
      PRICE_INCREASE_REBATE_OFFER: 'After Last Price Increase that Impacted Rebate Offer'
    },
    STEP_THREPY_PRODUCT: {
      EMPTY: '',
      NO: 'No',
      YES_SINGLE_STEP: 'Yes - Single step',
      YES_DOUBLE_STEP: 'Yes - Double step',
      YES_TRIPLE_STEP: 'Yes - Triple step',
      YES_OTHER: 'Yes - Other'
    },
    LATE_PAYMENT_PENALTY_FREQUENCY: {
      EMPTY: '',
      DAILY: 'Daily',
      MONTHLY: 'Monthly',
      QUARTERLY: 'Quarterly'
    },
    LATE_PAYMENT_PENALTY_BASIS: {
      EMPTY: '',
      PERCENT_OF_TOTAL_REBATES: '% of total rebates',
      PERCENT_OF_UNPAID_REBATES: '% of unpaid rebates',
      FLAT_AMOUNT: 'Flat Amount'
    },
    NO_FAULT_TERMINATION_CLAUSE_WHO_MAY_INVOKE: {
      EMPTY: '',
      NEITHER_PARTY: 'Neither Party',
      PHARMA: 'Pharma',
      BOTH: 'Both'
    },
    PAYMENT_FREQUENCY_LAG: {
      EMPTY: '',
      MONTHLY: 'Monthly',
      QUARTERLY: 'Quarterly'

    },
    PAYMENT_LAG: {
      EMPTY: '',
      '10_DAYS': '10 days',
      '15_DAYS': '15 days',
      '30_DAYS': '30 days',
      '45_DAYS': '45 days',
      '60_DAYS': '60 days',
      '90_DAYS': '90 days',
      '150_DAYS': '150 days',
      '180_DAYS': '180 days',
      ESTIMATE_90_PERCENTS: 'Estimate (90%) Net 30',
      ESTIMATE_80_PERCENTS: 'Estimate (80%) Net 30',
      ESTIMATE_70_PERCENTS: 'Estimate (70%) Net 30'
    },
    PHARMA_AUDIT_FREQUENCY: {
      EMPTY: '',
      ANNUALLY: 'Annually',
      '1_PER_CONTRACT': '1 per Contract Term',
      '1_EVERY_2_YEARS': '1 every 2 years',
      '1_EVERY_3_YEARS': '1 every 3 years'
    },
    REBATE_ELIGIBILITY: {
      EMPTY: '',
      '12_MONTHS': '12 months',
      '18_MONTHS': '18 months',
      '24_MONTHS': '24 months',
      '60_DAYS': '60 days',
      '90_DAYS': '90 days',
      '120_DAYS': '120 days',
      '150_DAYS': '150 days',
      '180_DAYS': '180 days',
      '210_DAYS': '210 days',
      '270_DAYS': '270 days',
      '1ST_DAY_OF_CURRENT_INVOICE': '1st Day of Current Invoice',
      CURRENT_QUARTER: 'Current Quarter',
      CALENDAR_QUARTER: 'Calendar Quarter',
      BEYOND_CALENDAR_MONTH: 'Beyond Calendar Month',
      DURING_QUARTER_PREV_4_QUARTERS: 'During the quarter + previous 4 quarters',
      DURING_QUARTER_PREV_12_MONTHS: 'During the quarter + previous 12 months',
      '180_DAYS_AFTER_QUARTER_MONTH': '180 days after calendar quarter/month',
      '45_DAYS_AFTER_CALENDAR_QUARTER_MONTH': '45 days after calendar quarter/month',
      PLAN_YEAR_PLUS_180_DAYS: 'Plan Year + 180 days',
      PRECENDING_CALENDAR_QUARTER_180_DAYS: '180 days preceding calendar quarter',
      PRECENDING_CALENDAR_MONTH_180_DAYS: '180 days preceding calendar month',
      PRECENDING_CURRENT_CALENDAR_QUARTER_45_DAYS: '45 days preceding current calendar quarter',
      PRECENDING_CURRENT_CALENDAR_MONTH_45_DAYS: '45 days preceding current calendar month'
    },
    WHOLESALE_ACQIISITION_PRICE: {
      EMPTY: '',
      DAILY_WAC: 'Daily WAC',
      DAILY_WEIGHTED_WAC_MONTH: 'Daily Weighted WAC by Month',
      DAILY_WEIGHTED_WAC_QUARTER: 'Daily Weighted WAC by Quarter',
      WAC_EFFECT_MOST_DAYS_MONTHLY: 'WAC in Effect Most Days of Monthly',
      WAC_EFFECT_MOST_DAYS_QUARTER: 'WAC in Effect Most Days of Quarter',
      '1ST_DAY_MONTH': '1st Day of Month',
      '15TH_DAY_MONTH': '15th Day of Month',
      LAST_DAY_MONTH: 'Last Day of Month',
      '1ST_DAY_QUARTER': '1st Day of Quarter',
      '45TH_DAY_QUARTER': '45th Day of Quarter',
      LAST_DAY_QUARTER: 'Last Day of Quarter',
      DAILY_MONTHLY_AVERAGE: 'Daily Monthly Average',
      DAILY_QUARTERLY_AVERAGE: 'Daily Quarterly Average'
    }
  },
  PLACEHOLDERS: {
    PER_UNIT: 'Per Unit',
    PATH: 'Path'
  },
  ERROR_CODES: {
    SEARCH_ERRORS: {
      TOO_MANY_RESULTS: -1
    }
  }
};
