import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { QvCache } from '@qv-common/decorators';
import { SvgIconName } from '@qv-common/enums';
import { TermTemplateStorageService } from '@qv-term/services';
import { resources } from '@qv-common/static';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { finalize } from 'rxjs/operators';
import { BaseTermWithLockComponent } from '@qv-term/components/base-term-with-lock/base-term-with-lock.component';
import { MarketBasket } from '@qv-bid/entities';
import { MarketBasketDaoService } from '@qv-bid/services/dao';
import {
  MarketBasketModalConfig,
  MarketBasketModalData,
  MarketBasketModalOutput
} from '@qv-bid/models';
import { MarketBasketModalState } from '@qv-bid/enums';
import { ModalService } from 'quantuvis-angular-common/modal';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { UserService } from '@qv-common/services/auth/user.service';

@UntilDestroy()
@Component({
  selector: 'qv-term-market-basket',
  templateUrl: './term-market-basket.component.html',
  styleUrls: ['./term-market-basket.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TermMarketBasketComponent extends BaseTermWithLockComponent implements OnInit, OnChanges {
  @BlockUI()
  public blockUI: NgBlockUI;
  public readonly svgIconName = SvgIconName;
  @Input()
  public marketBasket: MarketBasket;
  @Input()
  public scenarioId: number;
  @Input()
  public drugName: string;
  @Input()
  public drugClass: string;
  @Input()
  public isNdcLevel = false;
  @Input()
  public isBidInternal: boolean;
  @Input()
  public manufacturerCompanyOldName: string;

  @Output()
  public marketBasketUpdated = new EventEmitter<MarketBasketModalOutput>();

  public isEmptyMarketBasket: boolean;

  constructor(
    protected termTemplateStorage: TermTemplateStorageService,
    protected userService: UserService,
    private modalService: ModalService,
    private marketBasketDaoService: MarketBasketDaoService,
    changeDetectorRef: ChangeDetectorRef
  ) {
    super(userService, termTemplateStorage, changeDetectorRef);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.isEmptyMarketBasket = !this.marketBasket;

    this.readOnlyStateEvent.next(!this.isEditable(this.isEditMode, this.isUserPharma, this.isLocked, this.isPending));
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.marketBasket && !changes.marketBasket.firstChange) {
      this.isEmptyMarketBasket = !this.marketBasket;
    }
  }

  @QvCache()
  public getTooltipMessage(isEmptyMarketBasket: boolean): string {
    return isEmptyMarketBasket ? resources.TermsDescriptions.DRUG_LIST.EMPTY_MARKET_BASKET_TOOLTIP : '';
  }

  public openModal(): void {
    if (this.isEmptyMarketBasket) {
      this.openMarketBasketModal();
    } else {
      this.blockUI.start();
      this.marketBasketDaoService.getMarketBasket(this.scenarioId, this.marketBasket.id)
        .pipe(finalize(() => this.blockUI.stop()))
        .subscribe((marketBasket: MarketBasket) => this.openMarketBasketModal(marketBasket));
    }
  }

  public transferToEditMode(): void {
    this.openModal();
  }

  public isEditingAvailable(): boolean {
    return this.isEnabled(this.isEmptyMarketBasket, this.isEditMode, this.isUserPharma, this.isLocked, this.isPending);
  }

  private openMarketBasketModal(marketBasket?: MarketBasket): void {
    const modal = this.modalService.openModal(new MarketBasketModalConfig(new MarketBasketModalData(
      this.isEditable(this.isEditMode, this.isUserPharma, this.isLocked),
      this.isNdcLevel,
      this.isBidInternal,
      this.manufacturerCompanyOldName,
      marketBasket || this.marketBasket,
      this.scenarioId,
      this.drugName,
      this.drugClass,
      marketBasket ? MarketBasketModalState.PREVIEW : MarketBasketModalState.ASSIGN
    )));
    modal.componentInstance.primaryAction
      .pipe(untilDestroyed(this))
      .subscribe((output: MarketBasketModalOutput) => {
        this.marketBasket = output.marketBasket;
        this.marketBasketUpdated.emit(output);
      });
    modal.afterClosed().subscribe(() => this.exitFromEditModeEvent.emit());
  }
}
