<div class="standard-scenario">
  <div class="standard-scenario__sections">

    <!-- Scenario header -->
    <header class="standard-scenario-header">
      <div class="standard-scenario-header__left-block">
        <div class="standard-scenario-header__name"></div>
        <div class="standard-scenario-header__dependency"></div>
        <div class="standard-scenario-header__toggler"></div>
      </div>
      <div class="standard-scenario-header__drug">
        <span class="standard-scenario-header__drug-name"></span>
      </div>
    </header>

    <!-- Contract Terms section -->
    <div class="standard-scenario-section  standard-scenario-section--contract-terms" *ngIf="filter[sectionFilterEnum.CONTRACT_TERMS].checked">
      <div class="standard-scenario-section-container">
        <div class="standard-scenario-section-container__name"></div>
      </div>
      <div class="standard-scenario-section__terms">
        <div class="standard-cell standard-cell--contract">
          <span class="standard-cell__name"></span>
          <span class="standard-cell__value"></span>
        </div>
        <div class="standard-cell standard-cell--contract">
          <span class="standard-cell__name"></span>
          <span class="standard-cell__value"></span>
        </div>
        <div class="standard-cell standard-cell--contract">
          <span class="standard-cell__name"></span>
          <span class="standard-cell__value"></span>
        </div>
      </div>
    </div>

    <!-- Rebate section -->
    <div class="standard-scenario-section standard-scenario-section--rebate">
      <div class="standard-scenario-section-container">
        <div class="standard-scenario-section-container__name"></div>
      </div>
      <div class="standard-scenario-section__terms">
        <div class="standard-cell">
          <span class="standard-cell__name"></span>
          <span class="standard-cell__value"></span>
        </div>
        <div class="standard-cell">
          <span class="standard-cell__name"></span>
          <span class="standard-cell__value"></span>
        </div>
        <div class="standard-cell standard-cell--long-value">
          <span class="standard-cell__name"></span>
          <span class="standard-cell__value"></span>
        </div>
        <div class="standard-cell standard-cell--competition">
          <span class="standard-cell__name"></span>
          <span class="standard-cell__value"></span>
        </div>
        <div class="standard-cell standard-cell--competition">
          <span class="standard-cell__name"></span>
          <span class="standard-cell__value"></span>
        </div>
        <div class="standard-cell standard-cell--competition">
          <span class="standard-cell__name"></span>
          <span class="standard-cell__value"></span>
        </div>
        <div class="standard-cell standard-cell--icon-value">
          <span class="standard-cell__name"></span>
          <span class="standard-cell__value"></span>
        </div>
        <div class="standard-cell standard-cell--icon-value">
          <span class="standard-cell__name"></span>
          <span class="standard-cell__value"></span>
        </div>
      </div>
    </div>

    <!-- UM section -->
    <div class="standard-scenario-section standard-scenario-section--um"  *ngIf="filter[sectionFilterEnum.UM].checked">
      <div class="standard-scenario-section-container">
        <div class="standard-scenario-section-container__name"></div>
        <div class="standard-scenario-section-container__name"></div>
      </div>
      <div class="standard-scenario-section__terms">
        <div class="standard-um-terms">
          <div class="standard-um-terms__step-prior-container">
            <div class="standard-um-terms__step-therapy">
              <span class="standard-um-terms__name"></span>
              <div class="standard-cell standard-cell--allowed-on-product">
                <span class="standard-cell__name"></span>
                <span class="standard-cell__value"></span>
              </div>
              <div class="standard-cell standard-cell--required">
                <span class="standard-cell__name"></span>
                <span class="standard-cell__value"></span>
              </div>
            </div>
            <div class="standard-um-terms__prior-auth">
              <span class="standard-um-terms__name"></span>
              <div class="standard-cell standard-cell--allowed-on-product">
                <span class="standard-cell__name"></span>
                <span class="standard-cell__value"></span>
              </div>
              <div class="standard-cell standard-cell--required">
                <span class="standard-cell__name"></span>
                <span class="standard-cell__value"></span>
              </div>
            </div>
          </div>
          <div class="standard-um-terms__quantity-other-container">
            <div class="standard-um-terms__quantity">
              <div class="standard-cell standard-cell--quantity-other">
                <span class="standard-cell__name"></span>
                <span class="standard-cell__value"></span>
              </div>
            </div>
            <div class="standard-um-terms__other">
              <div class="standard-cell standard-cell--quantity-other">
                <span class="standard-cell__name"></span>
                <span class="standard-cell__value"></span>
              </div>
            </div>
          </div>
          <div class="standard-um-terms__details">
            <div class="standard-cell standard-cell--um-details">
              <div class="standard-cell__name"></div>
              <span class="standard-cell__value"></span>
              <span class="standard-cell__value"></span>
              <span class="standard-cell__value"></span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Market Share section -->
    <div class="standard-scenario-section standard-scenario-section--market-share" *ngIf="filter[sectionFilterEnum.MARKET_SHARE].checked">
      <div class="standard-scenario-section-container">
        <div class="standard-scenario-section-container__name"></div>
        <div class="standard-scenario-section-container__name"></div>
      </div>
      <div class="standard-scenario-section__terms">
        <div class="standard-cell">
          <span class="standard-cell__name"></span>
          <span class="standard-cell__value"></span>
        </div>
        <div class="standard-cell standard-cell--range-or-unit-and-rebate">
          <div class="standard-cell__name"></div>
          <div class="standard-cell__values">
            <span class="standard-cell__value"></span>
            <span class="standard-cell__value"></span>
            <span class="standard-cell__value"></span>
          </div>
        </div>
      </div>
    </div>

    <!-- Price Protection section -->
    <div class="standard-scenario-section standard-scenario-section--price-protection" *ngIf="filter[sectionFilterEnum.PRICE_PROTECTION].checked">
      <div class="standard-scenario-section-container">
        <div class="standard-scenario-section-container__name"></div>
      </div>
      <div class="standard-scenario-section__terms">
        <div class="standard-cell">
          <span class="standard-cell__name"></span>
          <span class="standard-cell__value"></span>
        </div>
        <div class="standard-cell standard-cell--long-value">
          <span class="standard-cell__name"></span>
          <span class="standard-cell__value"></span>
        </div>
        <div class="standard-cell standard-cell--long-value">
          <span class="standard-cell__name"></span>
          <span class="standard-cell__value"></span>
        </div>
        <div class="standard-cell">
          <span class="standard-cell__name"></span>
          <span class="standard-cell__value"></span>
        </div>
        <div class="standard-cell">
          <span class="standard-cell__name"></span>
          <span class="standard-cell__value"></span>
        </div>
        <div class="standard-cell">
          <span class="standard-cell__name"></span>
          <span class="standard-cell__value"></span>
        </div>
      </div>
    </div>

  </div>
</div>

