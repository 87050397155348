import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { TermTemplateStorageService } from '@qv-term/services';
import { BaseTermComponent } from '@qv-term/components/base-term/base-term.component';

@Component({
  selector: 'qv-term-currency',
  templateUrl: './term-currency.component.html',
  styleUrls: ['./term-currency.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TermCurrencyComponent extends BaseTermComponent implements OnInit {
  @Input()
  public isUnavailable = false;

  constructor(protected termTemplateStorage: TermTemplateStorageService, changeDetectorRef: ChangeDetectorRef) {
    super(termTemplateStorage, changeDetectorRef);
  }

  public ngOnInit(): void {
    super.ngOnInit();
  }
}
