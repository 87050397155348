import { DictionaryItem } from '@qv-common/entities';

export enum PaymentLag {
  EMPTY = 1,
  DAYS_10 = 2,
  DAYS_15 = 3,
  DAYS_30 = 4,
  DAYS_45 = 5,
  DAYS_60 = 6,
  DAYS_90 = 7,
  DAYS_120 = 8,
  DAYS_150 = 9,
  DAYS_180 = 10,
  ESTIMATE_90_PERCENTS = 11,
  ESTIMATE_80_PERCENTS = 12,
  ESTIMATE_70_PERCENTS = 13,
  ESTIMATE_60_PERCENTS = 14,
}

export namespace PaymentLag {
  const valuesMap = new Map<PaymentLag, DictionaryItem>([
    [PaymentLag.EMPTY, { id: PaymentLag.EMPTY, name: '' }],
    [PaymentLag.DAYS_10, { id: PaymentLag.DAYS_10, name: '10 days' }],
    [PaymentLag.DAYS_15, { id: PaymentLag.DAYS_15, name: '15 days' }],
    [PaymentLag.DAYS_30, { id: PaymentLag.DAYS_30, name: '30 days' }],
    [PaymentLag.DAYS_45, { id: PaymentLag.DAYS_45, name: '45 days' }],
    [PaymentLag.DAYS_60, { id: PaymentLag.DAYS_60, name: '60 days' }],
    [PaymentLag.DAYS_90, { id: PaymentLag.DAYS_90, name: '90 days' }],
    [PaymentLag.DAYS_120, { id: PaymentLag.DAYS_120, name: '120 days' }],
    [PaymentLag.DAYS_150, { id: PaymentLag.DAYS_150, name: '150 days' }],
    [PaymentLag.DAYS_180, { id: PaymentLag.DAYS_180, name: '180 days' }],
    [PaymentLag.ESTIMATE_90_PERCENTS, { id: PaymentLag.ESTIMATE_90_PERCENTS, name: 'Estimate (90%) Net 30' }],
    [PaymentLag.ESTIMATE_80_PERCENTS, { id: PaymentLag.ESTIMATE_80_PERCENTS, name: 'Estimate (80%) Net 30' }],
    [PaymentLag.ESTIMATE_70_PERCENTS, { id: PaymentLag.ESTIMATE_70_PERCENTS, name: 'Estimate (70%) Net 30' }],
    [PaymentLag.ESTIMATE_60_PERCENTS, { id: PaymentLag.ESTIMATE_60_PERCENTS, name: 'Estimate (60%) Net 30' }],
  ]);

  export function getValue(lag: PaymentLag): DictionaryItem {
    return valuesMap.get(lag);
  }

  export function getValues(): DictionaryItem[] {
    return Array.from(valuesMap.values());
  }
}
