import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  Inject,
  Input,
  OnInit
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NdcValue } from '@qv-term/entities';
import { UmDetailsService } from '@qv-bid/services';
import { StandardDrugFormService } from '@qv-bid/services/drug';
import { BidUtils } from '@qv-bid/utils';
import { QvCache } from '@qv-common/decorators';
import { Position } from '@qv-common/enums';
import { WINDOW_TOKEN } from 'quantuvis-angular-common/window-utils';
import { drugTermsConstants } from '@qv-term/constants';
import { TermName, TermSection } from '@qv-term/enums';
import { filter, tap } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'qv-standard-um-details',
  templateUrl: './standard-um-details.component.html',
  styleUrls: ['./standard-um-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StandardUmDetailsComponent implements OnInit {
  @Input()
  public umDetails: NdcValue<string>;
  @Input()
  public isEditMode: boolean;
  @Input()
  public isBidInvalid: boolean;
  @Input()
  public sectionName: TermSection;
  @Input()
  public scenarioId: number;
  public control: FormControl;

  public readonly termName = TermName;
  public readonly drugTermsConstants = drugTermsConstants;
  public readonly umDetailsDebounceTime = 3000;
  public popoverPlacement = Position.BOTTOM;
  private readonly umDetailsService: UmDetailsService;

  constructor(
    public standardDrugFormService: StandardDrugFormService,
    private cdRef: ChangeDetectorRef,
    @Inject(WINDOW_TOKEN) private window: Window
  ) {
    this.umDetailsService = new UmDetailsService(standardDrugFormService);
  }

  public ngOnInit(): void {
    this.initSetAccessAfterChangeHandler();
    this.initControlWithChangesHandler();
    this.popoverPlacementChange();
  }

  @QvCache()
  public isAtNdc(value: NdcValue<string>): boolean {
    return BidUtils.isAtNdc(value);
  }

  @HostListener('window:resize')
  public onResize(): void {
    this.popoverPlacementChange();
  }

  private initControlWithChangesHandler(): void {
    this.standardDrugFormService.isEditMode(this.sectionName).pipe(
      filter((isEditMode: boolean) => isEditMode),
      tap(() => {
        this.control = this.standardDrugFormService.getTermControl(this.termName.UM_DETAILS, this.sectionName);
        this.setControlAccess();
      }),
      untilDestroyed(this)
    ).subscribe();
  }

  private setControlAccess(): void {
    if (this.umDetailsService.getAccessBasedOnTerms()) {
      this.control.enable({ onlySelf: true, emitEvent: false });
    } else {
      this.control.disable({ onlySelf: true, emitEvent: false });
    }
  }

  private initSetAccessAfterChangeHandler(): void {
    this.umDetailsService.sectionUpdated().pipe(untilDestroyed(this)).subscribe(() => this.setControlAccess());
  }

  private popoverPlacementChange(): void {
    const prevPopoverPlaceholder = this.popoverPlacement;
    const enoughWidthForRightPositionOfPopover = 1750;
    this.popoverPlacement =
      this.window.innerWidth > enoughWidthForRightPositionOfPopover ? Position.BOTTOM : Position.LEFT;
    if (prevPopoverPlaceholder !== this.popoverPlacement) {
      this.cdRef.markForCheck();
    }
  }
}
