declare let $: any;

export class CoreDomUtilsService {
  public static $inject = ['$timeout'];
  private $timeout;

  constructor($timeout) {
    'ngInject';
    this.$timeout = $timeout;
  }

  public recalculateFixHeaders(): void {
    this.$timeout(() => {
      $(window).trigger('measureTable');
    }, 10);
  }
}

