import { JsonObject, JsonProperty } from 'json2typescript';
import { RangeOrUnitAndRebate } from '@qv-bid/entities/range-or-unit-and-rebates.entity';
import { NdcDictionaryValue, NdcValue } from '@qv-term/entities';

@JsonObject('MarketShare')
export class MarketShare {
  @JsonProperty('state', NdcDictionaryValue)
  public state: NdcDictionaryValue = null;

  @JsonProperty('marketShareTier', NdcDictionaryValue)
  public marketShareTier: NdcDictionaryValue = null;

  @JsonProperty('rangeOrUnitAndRebates', NdcValue)
  public rangeOrUnitAndRebates: NdcValue<RangeOrUnitAndRebate[]> = null;
}
