import { HttpErrorResponse } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  OnInit,
  Output
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { CompanyUtils } from '@qv-company/utils';
import { TermEventBusService } from '@qv-term/services';
import { Observable, of, throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { BlockingMessage, FormValidationError, SvgIconName } from '@qv-common/enums';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DrugNotesModalData } from './models/drug-notes-modal-data';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { DrugNotesDaoService } from '@qv-term/services/dao';
import { Note } from '@qv-term/entities';
import { CoreUtils, StringUtils } from '@qv-common/utils';
import { resources } from '@qv-common/static';
import { BidStatus } from 'quantuvis-core-entities';
import { appConfig } from '@qv-common/configs';

@Component({
  selector: 'qv-drug-notes-modal',
  templateUrl: './drug-notes-modal.component.html',
  styleUrls: ['./drug-notes-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DrugNotesModalComponent implements OnInit {
  @BlockUI('notes-modal')
  public blockUI: NgBlockUI;

  @Output()
  public currentNoteUpdated = new EventEmitter<Note>();

  @Output()
  public historicNotesDeleted = new EventEmitter<Note>();

  public readonly resources = resources;
  public readonly svgIconName = SvgIconName;
  public readonly dateFormat = appConfig.dateFormat;
  public readonly CURRENT_NOTE_MAX_LENGTH = 1000;

  public currentNote: FormControl;

  public readonly validationMessages = new Map<string, string>([
    [FormValidationError.MAX_LENGTH, 'Notes are limited to 1000 characters. Please edit the text and save again.'],
  ]);

  constructor(
    public dialogRef: MatDialogRef<DrugNotesModalComponent>,
    private changeDetectorRef: ChangeDetectorRef,
    private drugNotesDaoService: DrugNotesDaoService,
    private termEventBusService: TermEventBusService,
    @Inject(MAT_DIALOG_DATA) public data: DrugNotesModalData
  ) {}

  public ngOnInit(): void {
    this.initCurrentNoteControl();
  }

  public hasAtNdcWarnings(): boolean {
    return this.data.viewNotes.isAtNdcHistoric || this.data.viewNotes.isAtNdcCurrent;
  }

  public isDeleteHistoricAvailable(): boolean {
    return this.data.isEditMode
      && this.data.bidStatus === BidStatus.RFP_NOT_SENT
      && (!this.data.viewNotes.isEmptyHistoric() || this.data.viewNotes.isAtNdcHistoric);
  }

  public onDeleteHistoric(): void {
    this.blockUI.start(BlockingMessage.DELETING);
    this.drugNotesDaoService.deleteHistoricNotes(
      this.data.bidVersionId, this.data.cbId, [this.data.scenarioId], [this.data.drugId]
    ).subscribe(() => {
      this.historicNotesDeleted.next();
      this.data.viewNotes.getHistoric = [];
      this.changeDetectorRef.markForCheck();
      this.blockUI.stop();
    });
  }

  public getHistoricNoteCompanyName(historicNote: Note): string {
    return CompanyUtils.getCompanyFullName(historicNote.companyName,
      this.data.manufacturerCompanyId === historicNote.companyId, this.data.manufacturerCompanyOldName);
  }

  public onSubmit(): void {
    if (this.currentNote.invalid) {
      return;
    }

    this.blockUI.start();

    this.callNoteSubmitting()
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.termEventBusService.updateTermFailed.emit(error);

          return throwError(error);
        }),
        finalize(() => this.blockUI.stop())
      )
      .subscribe((note: Note) => {
        this.dialogRef.close();

        if (!this.isCurrentValueSameAsPrevious()) {
          this.currentNoteUpdated.next(note);
        }
      });
  }

  private initCurrentNoteControl(): void {
    this.currentNote = new FormControl(
      this.data.viewNotes.getCurrentValue(),
      [
        Validators.maxLength(this.CURRENT_NOTE_MAX_LENGTH)
      ]
    );

    if (!this.data.isEditMode) {
      this.currentNote.disable();
    }
  }

  private callNoteSubmitting(): Observable<Note> {
    const current = this.data.viewNotes.getCurrent;
    const isNullCurrent = CoreUtils.isNull(current);
    const isAtNdcCurrent = this.data.viewNotes.isAtNdcCurrent;
    const currentValue = this.getValue().value || '';

    let submit$: Observable<Note> = of();

    switch (true) {
      case this.isCurrentValueSameAsPrevious():
        submit$ = of(null);
        break;
      case (this.data.drugId && isNullCurrent):
        submit$ = this.drugNotesDaoService
          .createAtNdcLevel(this.data.scenarioId, this.data.drugId, this.getValue(), this.data.isBidInternal);
        break;
      case (this.data.drugId && !isNullCurrent && !currentValue):
        submit$ = this.drugNotesDaoService
          .deleteAtNdcLevel(this.data.scenarioId, this.data.drugId, this.getValue().id, this.data.isBidInternal);
        break;
      case (this.data.drugId && !isNullCurrent):
        submit$ = this.drugNotesDaoService
          .updateAtNdcLevel(this.data.scenarioId, this.data.drugId, this.getValue(), this.data.isBidInternal);
        break;
      case (!this.data.drugId && (!isNullCurrent || isAtNdcCurrent) && !currentValue):
        submit$ = this.drugNotesDaoService.deleteAtScenarioLevel(this.data.scenarioId, this.data.isBidInternal);
        break;
      case (!this.data.drugId && (!isNullCurrent || isAtNdcCurrent)):
        submit$ = this.drugNotesDaoService
          .updateAtScenarioLevel(this.data.scenarioId, this.getValue(), this.data.isBidInternal);
        break;
      case (!this.data.drugId && isNullCurrent):
        submit$ = this.drugNotesDaoService
          .createAtScenarioLevel(this.data.scenarioId, this.getValue(), this.data.isBidInternal);
        break;
      default:
        break;
    }

    return submit$;
  }

  private getValue(): Note {
    const value = StringUtils.trim(this.currentNote.value);
    const emptyNote = new Note();
    const preparedData = { createdAt: null, isHistoric: false, value };

    return CoreUtils.isNull(this.data.viewNotes.getCurrent)
      ? { ...emptyNote, ...preparedData }
      : { ...emptyNote, ...this.data.viewNotes.getCurrent, ...preparedData };
  }

  private isCurrentValueSameAsPrevious(): boolean {
    const current = this.data.viewNotes.getCurrent;
    const persistedValue = current && current.value ? current.value : '';
    const currentValue = this.getValue().value || '';

    return persistedValue === currentValue;
  }
}
