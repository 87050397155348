/* tslint:disable:triple-equals */
import { constants } from '@qv-common/static';
import { SvgIconName } from '@qv-common/enums';

declare let angular: angular.IAngularStatic;
declare let $: any;

class ContractedBusinessController {
  public static $inject = ['bidding', 'translations', 'CreateInternalBidService', 'biddingUtilsService'];
  private bidding;
  private translations;
  private CreateInternalBidService;
  private biddingUtilsService;
  private svgIconName;
  private modelOptions;
  private onValid;
  private bid;
  private isValid;
  private planTypeTerm;
  private lineOfBusinessTerm;

  constructor(bidding, translations, CreateInternalBidService, biddingUtilsService) {
    'ngInject';
    this.bidding = bidding;
    this.translations = translations;
    this.CreateInternalBidService = CreateInternalBidService;
    this.biddingUtilsService = biddingUtilsService;
  }

  public $onInit(): void {
    this.svgIconName = SvgIconName;
    this.translations.buildTranslations(this);
    this.modelOptions = {
      updateOn: 'default blur',
      debounce: {
        default: constants.APP.TERM_CHANGE_DEBOUNCE_INTERVAL,
        blur: 0
      }
    };

  }

  public $onChanges(changes): void {
    if (changes.runValidation && changes.runValidation.currentValue) {
      this.onValid({ value: this.isValid() });
    } else if (changes.bid && changes.bid.currentValue) {
      this.bid = changes.bid.currentValue;
      this.preparePlanType();
    }
  }

  /**
   * Prepare plan type data
   */
  public preparePlanType(): void {
    this.lineOfBusinessTerm = this.CreateInternalBidService
      .getTermByNameFromSource('Line Of Business', this.bid.summary);
    this.lineOfBusinessTerm.definition.onChange(this.lineOfBusinessTerm);
    this.planTypeTerm = this.lineOfBusinessTerm.planTypeTerm;
    this.planTypeTerm.definition.prepareCustom(this);
    this.selectPlanType(0, true);
  }

  /**
   * Get Plan type by name
   *
   * @param term
   * @returns {string}
   */
  public getPlanTypeName(term): any {
    return this.bidding.getPlanTypeName(term);
  }

  /**
   * Check edit state of plan type
   *
   * @param index
   * @returns {boolean}
   */
  public isPlanTypeEditable(index): boolean {
    return index === this.planTypeTerm.selectedIndex;
  }

  /**
   * Check if current business name is valid
   *
   * @returns {boolean}
   */
  public isCurrentContractedBusinessNameValid(): boolean {
    const currentPlanType = this.planTypeTerm.value[this.planTypeTerm.selectedIndex];
    return this.planTypeTerm.definition.validatePlanTypeName(this.planTypeTerm, currentPlanType);
  }

  /**
   * Add new plan type
   */
  public addNewPlanType(): void {
    if (this.isCurrentContractedBusinessNameValid()) {
      this.planTypeTerm.value.push({
        name: this.planTypeTerm.value.length >= 1 ? '' : this.lineOfBusinessTerm.value,
        attributes: angular.copy(this.planTypeTerm.definition.planTypeDefaultAttributeValue)
      });

      this.selectPlanType(this.planTypeTerm.value.length - 1);
    }
  }

  /**
   * Select plan type by index
   *
   * @param index
   * @param skipValidation {boolean} : flag for skipping validation, used for initialization
   */
  public selectPlanType(index, skipValidation = false): void {
    if (skipValidation || this.isCurrentContractedBusinessNameValid()) {
      this.planTypeTerm.selectedIndex = index;
    }
  }

  /**
   * Get class for tab
   *
   * @param planType
   * @param index
   * @returns {string}
   */
  public getTabClass(planType, index): string {
    // @ts-ignore
    return this.biddingUtilsService.getPlanTypeTabClass(planType, index, scope.planTypeTerm.selectedIndex);
  }

  /**
   * Calculate dynamic width style for input
   *
   * @param value
   * @returns {Object}
   */
  public getDynamicWidthInputStyle(value): null | { width: string } {
    if (!value) {
      return null;
    }

    const charWidth = 7;
    const width = (value.length + 1) * charWidth;
    return {
      width: `${width}px`
    };
  }

  /**
   * Determine if remove icon "x" should be displayed
   *
   * @param index
   * @returns {boolean}
   */
  public isRemoveIconVisible(index): boolean {
    return index == this.planTypeTerm.selectedIndex && this.planTypeTerm.value.length !== 1;
  }

  /**
   * Remove plan type
   *
   * @param index
   */
  public removePlanType(index): void {
    this.planTypeTerm.value.splice(index, 1);
    // tslint:disable-next-line:no-unused-expression
    this.planTypeTerm.selectedIndex === this.planTypeTerm.value.length ? this.planTypeTerm.selectedIndex-- : '';
    $('.planTypeEditContainer:not(.ng-hide) input').popover('hide');
  }
}

export default ContractedBusinessController;
