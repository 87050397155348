import { JsonObject, JsonProperty } from 'json2typescript';
import { RemoteState } from '@qv-table/models';
import { BidListFilterState } from '@qv-bid-list/models/bid-list-filter-state';
import { defaultPageSize } from '@qv-table/constants';
import { Sort } from '@angular/material/sort';

@JsonObject('BidListRemoteState')
export class BidListRemoteState extends RemoteState<BidListFilterState> {
  @JsonProperty('filter', BidListFilterState, true)
  public filter?: BidListFilterState = null;

  constructor(page: number = 0, size: number = defaultPageSize, sort?: Sort, filter?: BidListFilterState) {
    super(page, size, sort, filter);
    this.filter = filter;
  }
}
