import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'acc-input-list',
  templateUrl: './input-list.component.html',
  styleUrls: ['./input-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InputListComponent implements OnInit, OnChanges {
  @Input()
  public title: string;

  @Input()
  public placeholder: string;

  @Input()
  public formArray: FormArray;

  @Input()
  public inputMaxLength: number;

  @Input()
  public elementsLimit: number;

  @Input()
  public isEditMode: boolean;

  @Input()
  public validationMessages: Map<string, string> = new Map();

  public isControlsLengthLessThanElementsLimit$ = new BehaviorSubject<boolean>(false);

  public formGroup: FormGroup;

  public ngOnInit(): void {
    this.initForm();
    this.checkControlsLengthToElementsLimit();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if ((changes.formArray || changes.isEditMode) && this.formArray) {
      this.setFormArrayDisabled();
    }
  }

  public onAddItem(): void {
    this.formArray.push(new FormControl(null, {
      validators: this.formArray.controls[0]?.validator ? [this.formArray.controls[0].validator] : [],
      updateOn: 'blur'
    }));
  }

  public onRemoveItem(index: number): void {
    if (this.formArray.controls.length > 1) {
      this.formArray.removeAt(index);
      this.formArray.markAsDirty();
    } else if (this.formArray.controls[index].value) {
      this.formArray.reset();
      this.formArray.markAsDirty();
    }
  }

  private checkControlsLengthToElementsLimit(): void {
    this.updateElementsLimitState();

    this.formGroup.get('formArray').valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(() => this.updateElementsLimitState());
  }

  private updateElementsLimitState(): void {
    this.isControlsLengthLessThanElementsLimit$.next(this.formArray.controls.length < this.elementsLimit);
  }

  private initForm(): void {
    this.formGroup = new FormGroup({
      formArray: this.formArray
    });
  }

  private setFormArrayDisabled(): void {
    if (this.isEditMode) {
      this.formArray.enable();
    } else {
      this.formArray.disable();
    }
  }
}
