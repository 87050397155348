<div *ngIf="isVisible(userIsPharma, locked)"
     [class.lock--disabled]="!isEditable(userIsPharma, isEditMode, isDisabled)"
     class="lock"
>
  <button
      (click)="onLockButtonClick()"
      [disabled]="!isEditable(userIsPharma, isEditMode, isDisabled)"
      [ngClass]="btnClass"
      class="lock__button"
      mat-icon-button>
    <mat-icon
        [class.locked]="locked"
        [class.unlocked]="!locked"
        [ngClass]="iconClass"
        [svgIcon]="locked ? svgIconName.LOCK : svgIconName.UNLOCK"
    >
    </mat-icon>
  </button>
</div>

<input *ngIf="isEditable(userIsPharma, isEditMode, isDisabled) && control" [formControl]="control" type="hidden">
