<section class="qv-modal-dialog qv-modal-dialog--scenario-summary">
  <header mat-dialog-title class="qv-modal-dialog-title">
    <h1 class="qv-modal-dialog-title__text">
      Scenario Summary
    </h1>
    <button class="qv-modal-dialog-title__xbutton" (click)="onClose()">
      <mat-icon>close</mat-icon>
    </button>
  </header>
  <div mat-dialog-content class="qv-modal-dialog-content">
    <div class="qv-modal-dialog-content__message">
      Please select any Drug Scenario Status:
    </div>
    <ul class="drug-scenario-statuses">
      <li class="drug-scenario-statuses__status" *ngFor="let drugScenarioStatus of drugScenarioStatuses">
        <mat-checkbox [(ngModel)]="drugScenarioStatus.value"
                      [matTooltip]="tooltips.BID_ACTIONS.BID_NOT_CONTAIN_SCENARIO"
                      [matTooltipDisabled]="!drugScenarioStatus.disabled"
                      [disabled]="drugScenarioStatus.disabled"
                      [disableRipple]="true">
          {{ drugScenarioStatus.displayValue }}
        </mat-checkbox>
      </li>
    </ul>
  </div>
  <footer mat-dialog-actions class="qv-modal-dialog-actions">
    <button mat-button class="qv-modal-dialog-actions__item" (click)="onClose()">
      Cancel
    </button>
    <button mat-button
            class="qv-modal-dialog-actions__item qv-modal-dialog-actions__item--primary"
            (click)="onGenerateScenarioSummary()"
            [disabled]="shouldDisableGenerateButton()">
      Generate
    </button>
  </footer>
</section>
