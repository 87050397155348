import { Inject, Injectable } from '@angular/core';
import { ApiService } from '@qv-common/services/api';
import { JsonConvert } from 'json2typescript';
import { Observable } from 'rxjs';
import { JSON_CONVERT_TOKEN } from 'quantuvis-angular-common/json-converter/services';
import { ApiUrlPrefix } from '@qv-common/enums';
import { HttpResponseWrapper } from '@qv-common/models/api';
import { map } from 'rxjs/operators';
import { FilterOption } from '@qv-common/entities';
import { FilterOptionName } from '@qv-bid/enums';
import { FilterState } from '@qv-bid/models';

@Injectable()
export class BidFilterDaoService {
  constructor(private apiService: ApiService, @Inject(JSON_CONVERT_TOKEN) private jsonConvert: JsonConvert) {}

  public updateFilterOptions(id: number, name: FilterOptionName, state: FilterState): Observable<FilterOption[]> {
    return this.apiService.post(`${ApiUrlPrefix.BIDS}/bid-versions/${id}/filter-options/${name}`, state).pipe(
      map((response: HttpResponseWrapper<FilterOption[]>) =>
        this.jsonConvert.deserializeArray(response.body.data, FilterOption)
      ));
  }
}
