import { Injectable } from '@angular/core';
import { PermissionService } from '@qv-common/services/auth/permission.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, map, take } from 'rxjs/operators';
import { Bid } from '@qv-bid/entities';

@Injectable()
export class BidListSelectService {
  public selectedBids$ = new BehaviorSubject<Bid[]>([]);

  constructor(private permissionService: PermissionService) {}

  public getSelectedBidIds(): number[] {
    return this.getIds(this.getSelectedBids());
  }

  public getSelectedBids(): Bid[] {
    return this.selectedBids$.getValue();
  }

  public isBidSelected(bidId: number): Observable<boolean> {
    return this.selectedBidIds().pipe(take(1), map((ids: number[]) => ids.includes(bidId)));
  }

  public isSomeBidsSelected(bids: Bid[]): Observable<boolean> {
    return this.selectedBidIds().pipe(
      take(1),
      map((selectedBidIds: number[]) => bids.some((bid: Bid) => selectedBidIds.includes(bid.id))),
    );
  }

  public isAllBidsSelected(bids: Bid[]): Observable<boolean> {
    const selectableBidIds = this.getSelectableBids(bids).map((bid: Bid) => bid.id);
    return this.selectedBidIds().pipe(
      take(1),
      map((selectedBidIds: number[]) => selectableBidIds.every((bidId: number) => selectedBidIds.includes(bidId))),
    );
  }

  public toggleBidSelection(bid: Bid): void {
    this.isBidSelected(bid.id).pipe(take(1))
      .subscribe((isBidSelected: boolean) => isBidSelected ? this.unselectBids([bid]) : this.selectBids([bid]));
  }

  public toggleAllSelection(bids: Bid[]): void {
    const selectableBids = this.getSelectableBids(bids);
    this.isAllBidsSelected(selectableBids).pipe(take(1))
      .subscribe((isAllBidsSelected: boolean) => {
        if (isAllBidsSelected) {
          this.unselectBids(selectableBids);
        } else {
          const selectableBidsExceptAlreadySelected = selectableBids
            .filter((selectedBid: Bid) => !this.getSelectedBidIds().includes(selectedBid.id));
          this.selectBids(selectableBidsExceptAlreadySelected);
        }
      });
  }

  public resetSelection(): void {
    this.selectedBids$.next([]);
  }

  public selectedBidIds(): Observable<number[]> {
    return this.selectedBids$.pipe(
      map((bids: Bid[]) => this.getIds(bids)),
      distinctUntilChanged(),
    );
  }

  private getIds(bids: Bid[]): number[] {
    return bids.map((bid: Bid) => bid.id);
  }

  private unselectBids(bids: Bid[]): void {
    this.selectedBids$.pipe(
      take(1),
      map((selectedBids: Bid[]) => {
        const bidIds = this.getIds(bids);
        return selectedBids.filter((selectedBid: Bid) => !bidIds.includes(selectedBid.id));
      }),
    ).subscribe((unselectedBids: Bid[]) => this.selectedBids$.next(unselectedBids));
  }

  private selectBids(bids: Bid[]): void {
    this.selectedBids$.pipe(take(1))
      .subscribe((selectedBids: Bid[]) => this.selectedBids$.next(selectedBids.concat(bids)));
  }

  private getSelectableBids(bids: Bid[]): Bid[] {
    return bids.filter((bid: Bid) => this.permissionService.canAccessArchived(bid.isArchived));
  }
}
