import { SelectBoxTerm } from '@qv-term/models';
import { constants, resources } from '@qv-common/static';
import { LatePaymentPenaltyFrequency } from '@qv-term/enums/options';

export class LatePaymentPenaltyFrequencyTerm extends SelectBoxTerm {
  public allowKeepOriginValue: boolean;
  public processKeepOriginalValue: boolean;
  public keepOriginalValue: boolean;

  constructor(name: string) {
    super(
      name,
      name,
      resources.TermsDescriptions.CONTRACT.LATE_PAYMENT_PENALTY_TERMS_FREQUENCY,
      constants.CONTRACT_TERMS.LATE_PAYMENT_PENALTY_TERMS_FREQUENCY.label,
      '',
      LatePaymentPenaltyFrequency.getValue(LatePaymentPenaltyFrequency.EMPTY),
      'term__late-payment-penalty-frequency'
    );
    this.keepOriginalValue = this.allowKeepOriginValue;
    this.processKeepOriginalValue = false;
    this.values = LatePaymentPenaltyFrequency.getValues();
  }
}
