<div class="confirmation-user">
  <span *ngIf="payloadData.isEditingHimself">
    <p>
      Disabling your user account will render the account inactive and will not be accessible by you
      until
      another administrator user will reactivate it.
    </p>
    <p>You will be logged out in order to proceed with the change.</p>
  </span>
  <span *ngIf="!payloadData.isEditingHimself">
    <p>Disabling user will render the account inactive and will not be accessible by {{payloadData.user.firstName}} {{payloadData.user.lastName}}
      .</p>
    <p>All the information related to the user account will still be available to administrators. </p>
  </span>
</div>
