import { resources } from '@qv-common/static';
import { drugTermsConstants } from '@qv-term/constants';
import { TermName } from '@qv-term/enums';
import { SelectBoxTerm } from '@qv-term/models/types';
import { StepTherapyRequired } from 'quantuvis-core-entities';

export class StepTherapyCompetitorsProductTerm extends SelectBoxTerm {

  constructor(name: string) {
    const config = drugTermsConstants[TermName.STEP_THERAPY_REQUIRED_ON_COMPETITORS_PRODUCTS];

    super(
      name,
      config.displayNames.REQUIRED_ON_COMPETITORS_PRODUCTS,
      resources.TermsDescriptions.DRUG_LIST.STEP_THERAPY,
      '',
      '',
      StepTherapyRequired.getValue(StepTherapyRequired.NO),
      'term__step-threpy-competitors-product'
    );

    this.values = StepTherapyRequired.getValues();
  }
}
