import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ActiveToast } from 'ngx-toastr/toastr/toastr.service';
import { Bid } from '@qv-bid/entities';
import { ViewPerspectiveService } from '@qv-common/services/auth/view-perspective.service';
import { HttpErrorResponse } from '@angular/common/http';
import { HttpStatusCode } from 'quantuvis-angular-common/api';
import { appConfig } from '@qv-common/configs';
import { UpdateEditingRightsComponent } from '@qv-bid/components/shared/update-editing-rights/update-editing-rights.component';
import { NotificationService, ToastType } from 'quantuvis-angular-common/notification';
import { UserService } from '@qv-common/services/auth/user.service';

@Injectable()
export class BidDetailsNotificationService extends NotificationService {
  public readonly VIEW_PERSPECTIVE_MSG = 'You are viewing this Bid as';
  public readonly BID_EDITING_MSG = 'This bid is being edited by';
  public readonly BID_REVIEWING_MSG = 'This bid is being reviewed by';
  public readonly WRITE_ACCESS_TO_BID_NOTIFICATION_MSG = 'To edit this bid, please update your editing rights';
  public readonly WRITE_ACCESS_TO_BID_USER_MSG = 'Please contact your company administrator' +
    ' for editing rights to this bid';
  public readonly ERROR_WHILE_OPERATION = 'An error has occurred while performing the operation.';

  constructor(
    protected toastrService: ToastrService,
    private userService: UserService,
    private viewPerspectiveService: ViewPerspectiveService
  ) {
    super(toastrService);
  }

  public showAnotherUserEditingNotification(userName: string): ActiveToast<any> {
    const message = `${this.BID_EDITING_MSG} <b>${userName}</b>`;
    return super.warning(message);
  }

  public showAnotherUserReviewingNotification(userName: string): ActiveToast<any> {
    const message = `${this.BID_REVIEWING_MSG} <b>${userName}</b>`;
    return super.warning(message);
  }

  public showDeleteProcessingError(err: HttpErrorResponse): ActiveToast<any> {
    if (HttpStatusCode.CONFLICT === err.status) {
      return super.error(err.error.message);
    } else {
      return super.error(this.ERROR_WHILE_OPERATION);
    }
  }

  public showViewPerspectiveNotification(bid: Bid): ActiveToast<any> {
    this.clearViewPerspectiveNotification();
    if (this.viewPerspectiveService.isPayerViewPerspective()) {
      return this.showPayerViewPerspectiveNotification(bid.payer.name);
    } else {
      return this.showPharmaViewPerspectiveNotification(bid.manufacturer.name);
    }
  }

  public clearViewPerspectiveNotification(): void {
    this.toastrService.toasts.forEach((toast: ActiveToast<any>) => {
      if (toast.message.indexOf(this.VIEW_PERSPECTIVE_MSG) > -1) {
        this.toastrService.remove(toast.toastId);
      }
    });
  }

  public showWriteAccessToBidRestrictedNotification(): ActiveToast<any> {
    if (this.userService.isCurrentUserPayerAdmin() || this.userService.isCurrentUserPharmaAdmin()) {
      return super.showBasedOnComponent(
        this.WRITE_ACCESS_TO_BID_NOTIFICATION_MSG,
        UpdateEditingRightsComponent,
        ToastType.warning
      );
    } else {
      return super.warning(this.WRITE_ACCESS_TO_BID_USER_MSG);
    }
  }

  private showPayerViewPerspectiveNotification(companyName: string): ActiveToast<any> {
    const message = `${this.VIEW_PERSPECTIVE_MSG} <b>${companyName}</b>`;
    const config = Object.assign({ toastClass: 'toast toast-info-secondary' }, appConfig.toastrConfig);
    return super.info(message, '', config);
  }

  private showPharmaViewPerspectiveNotification(companyName: string): ActiveToast<any> {
    const message = `${this.VIEW_PERSPECTIVE_MSG} <b>${companyName}</b>`;
    return super.info(message);
  }
}
