import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output, SimpleChanges
} from '@angular/core';
import { TermTemplateStorageService } from '@qv-term/services';
import { QvCache } from '@qv-common/decorators';
import { BaseTermComponent } from '@qv-term/components/base-term/base-term.component';
import { TermUtils } from '@qv-term/utils';
import { UserService } from '@qv-common/services/auth/user.service';

@Component({
  selector: 'qv-base-term-with-lock',
  templateUrl: './base-term-with-lock.component.html',
  styleUrls: ['./base-term-with-lock.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BaseTermWithLockComponent extends BaseTermComponent implements OnInit, OnChanges {
  @Input()
  public isLocked: boolean;

  @Output()
  public readOnlyStateEvent = new EventEmitter();

  public isUserPharma = false;

  constructor(
    protected userService: UserService,
    protected termTemplateStorage: TermTemplateStorageService,
    protected changeDetectorRef: ChangeDetectorRef
  ) {
    super(termTemplateStorage, changeDetectorRef);
  }

  public ngOnInit(): void {
    super.ngOnInit();

    this.isUserPharma = this.userService.isCurrentUserPharma();
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public ngOnChanges(changes: SimpleChanges): void {
    this.invokeReadOnlyStateEvent();
  }

  public overwriteValue(): void {
    super.overwriteValue();

    this.invokeReadOnlyStateEvent();
  }

  @QvCache()
  public isEditable(isEditMode: boolean, isUserPharma: boolean, isLocked: boolean, isPending: boolean = false): boolean {
    return TermUtils.isEditable(isEditMode, isUserPharma, isLocked) && !isPending;
  }

  @QvCache()
  public isEnabled(
    isValueEmpty: boolean,
    isEditMode: boolean,
    userIsPharma: boolean,
    isLocked: boolean,
    isPending: boolean = false
  ): boolean {
    return (!isValueEmpty || this.isEditable(isEditMode, userIsPharma, isLocked)) && !isPending;
  }

  @QvCache()
  public isEditPresentation(
    termValue: any,
    isEditMode: boolean,
    isOverrideMode: boolean,
    isUserPharma: boolean,
    isLocked: boolean): boolean {
    return this.isEditable(isEditMode, isUserPharma, isLocked) && (!this.isAtNdc(termValue) || isOverrideMode);
  }

  public isEditingAvailable(): boolean {
    return this.isEditable(this.isEditMode, this.isUserPharma, this.isLocked);
  }

  private invokeReadOnlyStateEvent(): void {
    this.readOnlyStateEvent.next(
      !this.isEditPresentation(this.termValue, this.isEditMode, this.isOverrideMode, this.isUserPharma, this.isLocked)
    );
  }
}
