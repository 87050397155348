import { TermTypes } from '@qv-common/enums';
import { Metadata } from '@qv-common/models';

export abstract class BaseTerm {
  public name: string;
  public label: string;
  public description: string;
  public path: string;
  public type: TermTypes;
  public value: any;
  public defaultValue: any;
  public cssClass: string;
  public errorMessage: string;
  public metaData: Metadata;

  constructor(name: string,
              label: string,
              description: string,
              path: string,
              value: any,
              defaultValue: any,
              cssClass: string,
              metaData?: Metadata) {
    this.name = name;
    this.label = label;
    this.description = description;
    this.path = path;
    this.value = value;
    this.defaultValue = defaultValue;
    this.cssClass = cssClass;
    this.metaData = metaData;
  }

  public getValueToSave(): any {
    return this.value;
  }
}
