<div
  *ngIf="isNdcAvailable(!!scenario, !!ndc) && isNdcVisible(ndc.isDismissed(), filter[sectionFilterEnum.DISMISSED].checked)"
  class="standard-ndc"
  data-test-id="test-id-ndc"
  [class.standard-ndc--dismissed]="ndc.isDismissed()"
  [class.standard-ndc--selected]="ndcSelected$ | async">
  <qv-standard-ndc-header [scenario]="scenario" [ndc]="ndc" [ndcSelected]="ndcSelected$ | async"></qv-standard-ndc-header>
  <div class="standard-ndc__body" (click)="transitToEditMode()">
    <qv-standard-scenario-terms *ngIf="filter[sectionFilterEnum.CONTRACT_TERMS].checked"
      [scenarioId]="scenario.id"
      [drugId]="ndc.drugId"
      [contract]="ndc.contract"
      [summary]="summary | async"
      [isBidInvalid]="isBidInvalid"
      [isBidInternal]="isBidInternal"
    >
    </qv-standard-scenario-terms>
    <qv-standard-rebate
      [rebate]="ndc.rebate"
      [scenarioId]="scenario.id"
      [cbId]="scenario.cbId"
      [bidStatus]="bidStatus"
      [isBidInvalid]="isBidInvalid"
      [isBidInternal]="isBidInternal"
      [drugName]="scenario?.drugName"
      [drugClass]="scenario?.drug?.drugClass"
      [drugId]="ndc.drugId"
      [drugStatus]="ndc.status"
      [marketBasket]="scenario.marketBasket"
      [marketBasketLock]="scenario.marketBasketLock"
      [isNdcLocked]="ndc.isLocked"
      [isDismissed]="ndc.isDismissed()"
      (notesUpdated)="onUpdateNotes()"
      [netEffectivePrice]="ndc.priceProtection?.netEffectivePrice"
      [manufacturerCompanyOldName]="manufacturerCompanyOldName"
      [manufacturerCompanyId]="manufacturerCompanyId"
      (historicNotesDeleted)="onDeleteHistoricNotes()">
    </qv-standard-rebate>
    <qv-standard-um *ngIf="filter[sectionFilterEnum.UM].checked"
      [utilizationManagement]="ndc.utilizationManagement"
      [scenarioId]="scenario.id"
      [drugId]="ndc.drugId"
      [isBidInvalid]="isBidInvalid"
      [isBidInternal]="isBidInternal"
    ></qv-standard-um>
    <qv-standard-market-share *ngIf="filter[sectionFilterEnum.MARKET_SHARE].checked"
      [marketShare]="ndc.marketShare"
      [scenarioId]="scenario.id"
      [drugId]="ndc.drugId"
      [isBidInvalid]="isBidInvalid"
      [isBidInternal]="isBidInternal"
    ></qv-standard-market-share>
    <qv-standard-price-protection *ngIf="filter[sectionFilterEnum.PRICE_PROTECTION].checked"
      [drugId]="ndc.drugId"
      [scenarioId]="scenario.id"
      [priceProtection]="ndc.priceProtection"
      [isBidInvalid]="isBidInvalid"
      [isBidInternal]="isBidInternal"
    >
    </qv-standard-price-protection>
  </div>
</div>

