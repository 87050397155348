import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ChangeDetectionStrategy, Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { BlockingMessage, SvgIconName } from '@qv-common/enums';
import { ContractedBusinessDaoService } from '@qv-bid/services/dao';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { finalize, switchMap } from 'rxjs/operators';
import { resources } from '@qv-common/static';
import { ContractBusinessImportModalData } from '@qv-bid/components/shared/cb-import-modal/models/cb-import-modal-data';

import {
  GeneralModalComponent,
  GeneralModalConfig,
  GeneralModalData,
  ModalService,
  ModalSize
} from 'quantuvis-angular-common/modal';
import { ContractedBusiness } from 'quantuvis-core-entities';

@Component({
  selector: 'qv-cb-import-modal',
  templateUrl: './cb-import-modal.component.html',
  styleUrls: ['./cb-import-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContractedBusinessImportModalComponent implements OnInit {
  @BlockUI('cb-import-modal')
  public blockUI: NgBlockUI;

  @Output()
  public importedEvent: EventEmitter<ContractedBusiness> = new EventEmitter<ContractedBusiness>();

  public importCBForm = new FormGroup({
    cb: new FormControl()
  });

  public readonly svgIconName = SvgIconName;

  private readonly cbActions = resources.CONTRACTED_BUSINESS_ACTIONS;

  constructor(
    private dialogRef: MatDialogRef<ContractedBusinessImportModalComponent>,
    private contractedBusinessDaoService: ContractedBusinessDaoService,
    private modalService: ModalService,
    @Inject(MAT_DIALOG_DATA) public data: ContractBusinessImportModalData
  ) {}

  public ngOnInit(): void {
    this.data.cbList = this.data.cbList.filter((cb: ContractedBusiness) => cb !== this.data.cb);
    this.importCBForm.get('cb').setValue(this.data.cbList[0].id);
  }

  public onSubmit(): void {
    this.openConfirmationModal().componentInstance.primaryAction.pipe(
      switchMap(() => {
        this.blockUI.start(BlockingMessage.IMPORTING);

        return this.contractedBusinessDaoService.import(
          this.data.cb.id,
          this.importCBForm.get('cb').value,
          this.data.bidVersionId,
          this.data.isBidInternal
        ).pipe(finalize(() => this.blockUI.stop()));
      }),
    ).subscribe((cb: ContractedBusiness) => {
      this.importedEvent.emit(cb);
      this.dialogRef.close();
    });
  }

  private openConfirmationModal(): MatDialogRef<GeneralModalComponent> {
    const selectedId = this.importCBForm.get('cb').value;
    const selectedCB = this.data.cbList.find((cb: ContractedBusiness) => cb.id === selectedId);

    return this.modalService.openModal(new GeneralModalConfig(new GeneralModalData(
      'Overwrite Contracted Business',
      `${resources.PlanType.OVERWRITE_WARNING}<br/><br/>
      ${this.cbActions.OVERWRITE_THIS_CONTRACTED_BUSINESS} ${this.data.cb.name}
      ${this.cbActions.OVERWRITE_WITH_CONTRACTED_BUSINESS} ${selectedCB.name}?`,
      resources.Actions.YES,
      resources.Actions.NO,
    ), ModalSize.MEDIUM));
  }
}
