import { Injectable } from '@angular/core';
import { DrugAlertNotification } from '@qv-bid/models';
import { BidStateService } from '@qv-bid/services/bid-state.service';
import { NdcManagerService } from '@qv-bid/services/ndc-manager.service';
import { TermSection } from '@qv-term/enums';
import { BehaviorSubject, Observable } from 'rxjs';
import { DrugAlertType } from '@qv-bid/enums';
import { Drug, DrugAlert, Ndc, Scenario } from '@qv-bid/entities';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import { BidVersionDaoService } from '@qv-bid/services/dao';
import { UserService } from '@qv-common/services/auth/user.service';

@Injectable()
export class DrugAlertNotificationService {
  public notification$ = new BehaviorSubject<DrugAlertNotification>(null);
  public ndcNotifications$ = new BehaviorSubject(new Map<number, DrugAlertNotification>());

  private readonly sectionsImpactingPharmaDrugAlertNotifications = [
    TermSection.SCENARIO,
    TermSection.PRICE_PROTECTION,
    TermSection.REBATE
  ];
  private readonly sectionsImpactingPayerDrugAlertNotifications = [TermSection.SCENARIO];

  constructor(
    private userService: UserService,
    private bidStateService: BidStateService,
    private bidVersionDaoService: BidVersionDaoService,
    private ndcManagerService: NdcManagerService,
  ) { }

  public calculateScenarioNotification(drugAlerts: DrugAlert[]): void {
    const drugAlert = this.getDrugAlertByPriority(drugAlerts);
    const notification = drugAlert ? DrugAlertType.displayValueForScenario(drugAlert.id) : null;

    this.notification$.next(notification);
  }

  public calculateNdcNotification(ndc: Ndc): void {
    const drugAlert = this.getDrugAlertByPriority(ndc.drugAlerts);
    const notification = drugAlert ? DrugAlertType.displayValueForNdc(drugAlert.id) : null;
    const ndcNotifications = this.ndcNotifications$.getValue();

    ndcNotifications.set(ndc.drugId, notification);

    this.ndcNotifications$.next(ndcNotifications);
  }

  public shouldReloadDrugAlerts(sectionName: TermSection): boolean {
    return this.shouldReloadDrugAlertsForPharma(sectionName) || this.shouldReloadDrugAlertsForPayer(sectionName);
  }

  public reloadScenarioDrugAlerts(scenarioId: number): Observable<Scenario> {
    const { isInternal } = this.bidStateService.bid$.getValue();
    const { bidVersionId } = this.bidStateService;

    return this.bidVersionDaoService.getDrugScenariosOfVersion(bidVersionId, [scenarioId], isInternal)
      .pipe(
        map((scenarios: Scenario[]) => scenarios[0]),
        tap((scenario: Scenario) => this.calculateScenarioNotification(scenario.drug.drugAlerts)),
        switchMap((scenario: Scenario) => this.patchScenarioWithDrugAlerts(scenario.id, scenario.drug.drugAlerts))
      );
  }

  public reloadNdcDrugAlerts(scenarioId: number, ndcId: number): Observable<Ndc> {
    const { isInternal } = this.bidStateService.bid$.getValue();
    const { bidVersionId, sortParams } = this.bidStateService;

    return this.bidVersionDaoService.getNdcsByScenariosOfVersion(
      bidVersionId, scenarioId, isInternal, [ndcId], sortParams
    )
      .pipe(
        map((ndcs: Ndc[]) => ndcs[0]),
        tap((ndc: Ndc) => this.calculateNdcNotification(ndc)),
        switchMap((ndc: Ndc) => this.patchNdcWithDrugAlerts(scenarioId, ndcId, ndc.drugAlerts))
      );
  }

  public getNdcDrugAlertNotification(ndc: Ndc): Observable<DrugAlertNotification> {
    return this.ndcNotifications$.pipe(
      take(1),
      map((value: Map<number, DrugAlertNotification>) => value.get(ndc.drugId))
    );
  }

  private getDrugAlertByPriority(drugAlerts: DrugAlert[]): DrugAlert {
    const errorDrugAlert = drugAlerts
      .find((alert: DrugAlert) => alert.id === DrugAlertType.INCOMPLETE_VALUES);

    const warningDrugAlert = drugAlerts
      .find((alert: DrugAlert) => alert.id === DrugAlertType.START_END_DATE_DOESNT_MATCH);

    return errorDrugAlert || warningDrugAlert;
  }

  private shouldReloadDrugAlertsForPharma(sectionName: TermSection): boolean {
    return this.sectionsImpactingPharmaDrugAlertNotifications.includes(sectionName)
      && this.userService.isCurrentUserPharma();
  }

  private shouldReloadDrugAlertsForPayer(sectionName: TermSection): boolean {
    return this.sectionsImpactingPayerDrugAlertNotifications.includes(sectionName)
      && !this.userService.isCurrentUserPharma();
  }

  private patchScenarioWithDrugAlerts(scenarioId: number, drugAlerts: DrugAlert[]): Observable<Scenario> {
    return this.bidStateService.findScenarioById(scenarioId)
      .pipe(
        take(1),
        filter((scenario: Scenario) => Boolean(scenario)),
        map((scenario: Scenario) => {
          const drug = Object.assign(new Drug(), scenario.drug, { drugAlerts } as Drug);

          return Object.assign(new Scenario(), scenario, { drug } as Scenario);
        }),
        tap((scenario: Scenario) => this.bidStateService.replaceScenario(scenario))
      );
  }

  private patchNdcWithDrugAlerts(scenarioId: number, ndcId: number, drugAlerts: DrugAlert[]): Observable<Ndc> {
    return this.ndcManagerService.findNdcByScenarioId(scenarioId, ndcId)
      .pipe(
        take(1),
        filter((ndc: Ndc) => Boolean(ndc)),
        map((ndc: Ndc) => Object.assign(new Ndc(), ndc, { drugAlerts } as Ndc)),
        tap((ndc: Ndc) => this.ndcManagerService.replaceNdc(scenarioId, ndc))
      );
  }
}
