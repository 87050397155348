<div class="market-basket-general-info">
  <div class="info-item">
    <span class="info-item__label">Last update:</span>
    <span class="info-item__value">{{ marketBasket.updatedAt | dateTimeUserTz:dateFormat | async }}</span>
  </div>
  <div class="info-item">
    <span class="info-item__label">Created by:</span>
    <span class="info-item__value">{{ createdBy }}</span>
  </div>
</div>
