import { InputTerm } from '@qv-term/models/types';
import { Comparator, KeepOriginalValue, Reset } from '@qv-common/interfaces';
import { CoreUtils } from '@qv-common/utils';
import { resources } from '@qv-common/static';

export class ThresholdTerm extends InputTerm implements KeepOriginalValue, Reset, Comparator {
  public allowKeepOriginValue: boolean;
  public processKeepOriginalValue: boolean;
  public keepOriginalValue: boolean;

  constructor(name: string) {
    super(
      name,
      name,
      resources.TermsDescriptions.DRUG_LIST.THRESHOLD,
      '',
      '',
      '',
      'term_threshold'
    );

    this.suffix = this.unitsService.percent;
  }

  public resetValue(): void {
    this.value = this.defaultValue;
  }

  public getTermValueForComparison(): string {
    return CoreUtils.isDefined(this.value) ? `${this.value} ${this.suffix}` : '';
  }
}
