import { BidWizardService } from '../../shared/services/bid-wizard.service';
import { constants } from '@qv-common/static';
import { drugTermsConstants } from '@qv-term/constants';
import { TermName } from '@qv-term/enums';
import { ApiUrlPrefix } from '@qv-common/enums';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { catchError, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

declare let angular: angular.IAngularStatic;

/**
 * Service for create internal bid wizard
 */

export class CreateInternalBidService extends BidWizardService {
  public static $inject = ['bidding', 'router', 'userService', 'apiService'];
  private scenariosList;
  private _payerList;
  private _drugList;
  private _usedDrugTermNames;

  constructor(private bidding, private router, private userService, private apiService) {
    super();
    this.scenariosList = [];
    this._payerList = [];
    this._drugList = [];
    this._usedDrugTermNames = [
      drugTermsConstants[TermName.INDEX].title,
      drugTermsConstants[TermName.THRESHOLD].title,
      drugTermsConstants[TermName.RESET].title,
      drugTermsConstants[TermName.BASELINE_START_DATE].title,
      drugTermsConstants[TermName.NEW_PRICE_EFFECTIVE_DATE].title
    ];
  }

  get usedDrugTermNames(): any {
    return this._usedDrugTermNames;
  }
  get payerList(): any {
    return this._payerList;
  }
  set payerList(value) {
    this._payerList = value;
  }
  get drugList(): any {
    return this._drugList;
  }
  set drugList(value) {
    this._drugList = value;
  }
  /**
   * Prepare terms for create internal bid wizard
   */
  public prepareBidTerms(): void {
    const bidTemplate = this.bidding.BiddingData();
    const summary = bidTemplate.summaryTerms;
    const contractTerms = bidTemplate.contractTerms;
    const drugListTerms = bidTemplate.drugListTerms.filter(item => this._usedDrugTermNames.includes(item.name));
    const user = this.userService.user.getValue();
    const settings = {
      manufacturerCompanyName: user.company && user.company.name
    };
    this.bidConfig.summary = this.bidding.prepareTerms(null, summary, settings);
    this.bidConfig.drugTerms = this.bidding.prepareTerms(null, drugListTerms, settings);
    this.bidConfig.contract = this.bidding.prepareTerms(null, contractTerms, settings);
  }

  /**
   * Method for saving internal bid
   */
  public save(): Observable<any> {
    let requestObject = this.getValuesToSave();
    requestObject = angular.copy(requestObject);

    return this.apiService.post(`${ApiUrlPrefix.OLD}/bids/save/internal`, requestObject).pipe(
      tap((response: HttpResponse<any>) => {
        const data = response.body;
        if (data && !data.error) {
          this.redirectToBidsPage();
        } else if (data && data.error) {
          this.handleError(data.message);
        }
      }),
      catchError((error: HttpErrorResponse) => {
        if (error && error.error) {
          this.handleError(error.message);
        }

        return of(null);
      })
    );
  }

  public showLockUnlockIcon(): boolean {
    return false;
  }

  public getDrugTermByName(name): any {
    return this.bidConfig.drugTerms.find(search => search.name === name);
  }

  public getValuesToSave(): any {
    const selectedValues = {
      summary: [],
      drugTerms: [],
      contract: []
    };

    for (const key in selectedValues) {
      this.bidConfig[key].forEach(term => {
        let valueForSave;
        if (typeof term.definition.keepValueOnCopy !== 'undefined') {
          const savedKeepValueOnCopy = term.definition.keepValueOnCopy;
          term.definition.keepValueOnCopy = false;
          valueForSave = this.bidding.getSaveValueForTerm(term);
          term.definition.keepValueOnCopy = savedKeepValueOnCopy;
        } else {
          valueForSave = this.bidding.getSaveValueForTerm(term);
        }
        if (valueForSave && typeof valueForSave.value !== 'undefined') {
          selectedValues[key].push(valueForSave);
        }
      });
    }

    const defaults = {
      drugListTerms: [],
      summaryTerms: [],
      contractTerms: []
    };

    const defaultsFromSummary = [
      constants.SUMMARY_TERMS.CONTRACT_START_DATE.label,
      constants.SUMMARY_TERMS.CONTRACT_END_DATE.label
    ];
    const defaultsFromContract = [
      constants.CONTRACT_TERMS.ADMINISTRATION_FEE.label
    ];

    this.setSectionDefaults(defaultsFromSummary, defaults.summaryTerms, this.bidConfig.summary);
    this.setSectionDefaults(defaultsFromContract, defaults.contractTerms, this.bidConfig.contract);
    defaults.drugListTerms = selectedValues.drugTerms;

    const requestObject = {
      bid: {
        bidCurrentVersion: {
          summaryTerms: selectedValues.summary,
          contractTerms: selectedValues.contract,
        }
      },
      defaultBidTerms: defaults,
      initialScenarios: this.scenariosList,
      payerList: this.getPayerIds(this.payerList),
      drugList: this.getDrugsIds(this.drugList),
      meta: [
        {
          path: constants.DRUG_TERM_PATHS.ALLOW_PRICE_PROTECTION.join('.'),
          flags: constants.DRUG_TERM_GROUPS_STATE_VALUES.VISIBLE_STATE
        }
      ]
    };

    return requestObject;
  }

  public setSectionDefaults(sectionDefaults, section, sectionTerms): void {
    sectionDefaults.forEach(item => {
      section.push({
        name: item,
        value: this.bidding.getTermValueByName(sectionTerms, item)
      });
    });
  }

  public getPayerIds(selectedPayers): any {
    return selectedPayers.map(payer => payer.id);
  }

  public getDrugsIds(selectedDrugs): any[] {
    const drugIds = [];
    selectedDrugs.forEach(innerItem => {
      if (Array.isArray(innerItem.selectedDrugs)) {
        innerItem.selectedDrugs.forEach(drug => {
          if (Array.isArray(drug.drugIds)) {
            drug.drugIds.forEach(drugId => {
              drugIds.push(drugId);
            });
          }
        });
      }
    });

    return drugIds;
  }

  public redirectToBidsPage(): void {
    this.router.navigate(['/bids']);
  }

  public handleError(message): void {
    this.errorMessage = message;
  }

  public getLockUnlockIconName(): null {
    return null;
  }

  public getLockUnlockIconClass(): null {
    return null;
  }
}
