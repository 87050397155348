import { DictionaryItem } from '@qv-common/entities';

export enum RebateEligibility {
  EMPTY = 1,
  MONTHS_12 = 2,
  MONTHS_24 = 3,
  DAYS_60 = 4,
  DAYS_90 = 5,
  DAYS_120 = 18,
  DAYS_150 = 23,
  DAYS_180 = 6,
  DAYS_210 = 24,
  DAYS_270 = 7,
  FIRST_DAY_OF_CURRENT_INVOICE = 8,
  CURRENT_QUARTER = 9,
  CALENDAR_QUARTER = 10,
  BEYOND_CALENDAR_MONTH = 11,
  DURING_QUARTER_PREV_4_QUARTERS = 12,
  DURING_QUARTER_PREV_12_MONTHS = 13,
  N_180_DAYS_AFTER_QUARTER_MONTH = 14,
  N_45_DAYS_AFTER_CALENDAR_QUARTER_MONTH = 15,
  MONTHS_18 = 16,
  PLAN_YEAR_PLUS_180_DAYS = 17,
  PRECENDING_CALENDAR_QUARTER_180_DAYS = 19,
  PRECENDING_CALENDAR_MONTH_180_DAYS = 20,
  PRECENDING_CURRENT_CALENDAR_QUARTER_45_DAYS = 21,
  PRECENDING_CURRENT_CALENDAR_MONTH_45_DAYS = 22,
}

export namespace RebateEligibility {
  const valuesMap = new Map<RebateEligibility, DictionaryItem>([
    [RebateEligibility.EMPTY, { id: RebateEligibility.EMPTY, name: '' }],
    [RebateEligibility.MONTHS_12, { id: RebateEligibility.MONTHS_12, name: '12 months' }],
    [RebateEligibility.MONTHS_18, { id: RebateEligibility.MONTHS_18, name: '18 months' }],
    [RebateEligibility.MONTHS_24, { id: RebateEligibility.MONTHS_24, name: '24 months' }],
    [RebateEligibility.DAYS_60, { id: RebateEligibility.DAYS_60, name: '60 days' }],
    [RebateEligibility.DAYS_90, { id: RebateEligibility.DAYS_90, name: '90 days' }],
    [RebateEligibility.DAYS_120, { id: RebateEligibility.DAYS_120, name: '120 days' }],
    [RebateEligibility.DAYS_150, { id: RebateEligibility.DAYS_150, name: '150 days' }],
    [RebateEligibility.DAYS_180, { id: RebateEligibility.DAYS_180, name: '180 days' }],
    [RebateEligibility.DAYS_210, { id: RebateEligibility.DAYS_210, name: '210 days' }],
    [RebateEligibility.DAYS_270, { id: RebateEligibility.DAYS_270, name: '270 days' }],
    [
      RebateEligibility.FIRST_DAY_OF_CURRENT_INVOICE,
      { id: RebateEligibility.FIRST_DAY_OF_CURRENT_INVOICE, name: '1st Day of Current Invoice' }
    ],
    [RebateEligibility.CURRENT_QUARTER, { id: RebateEligibility.CURRENT_QUARTER, name: 'Current Quarter' }],
    [RebateEligibility.CALENDAR_QUARTER, { id: RebateEligibility.CALENDAR_QUARTER, name: 'Calendar Quarter' }],
    [
      RebateEligibility.BEYOND_CALENDAR_MONTH,
      { id: RebateEligibility.BEYOND_CALENDAR_MONTH, name: 'Beyond Calendar Month' }
    ],
    [
      RebateEligibility.DURING_QUARTER_PREV_4_QUARTERS,
      { id: RebateEligibility.DURING_QUARTER_PREV_4_QUARTERS, name: 'During the quarter + previous 4 quarters' }
    ],
    [
      RebateEligibility.DURING_QUARTER_PREV_12_MONTHS,
      { id: RebateEligibility.DURING_QUARTER_PREV_12_MONTHS, name: 'During the quarter + previous 12 months' }
    ],
    [
      RebateEligibility.N_180_DAYS_AFTER_QUARTER_MONTH,
      { id: RebateEligibility.N_180_DAYS_AFTER_QUARTER_MONTH, name: '180 days after calendar quarter/month' }
    ],
    [
      RebateEligibility.N_45_DAYS_AFTER_CALENDAR_QUARTER_MONTH,
      { id: RebateEligibility.N_45_DAYS_AFTER_CALENDAR_QUARTER_MONTH, name: '45 days after calendar quarter/month' }
    ],
    [
      RebateEligibility.PLAN_YEAR_PLUS_180_DAYS,
      { id: RebateEligibility.PLAN_YEAR_PLUS_180_DAYS, name: 'Plan Year + 180 days' }
    ],
    [
      RebateEligibility.PRECENDING_CALENDAR_QUARTER_180_DAYS,
      { id: RebateEligibility.PRECENDING_CALENDAR_QUARTER_180_DAYS, name: '180 days preceding calendar quarter' }
    ],
    [
      RebateEligibility.PRECENDING_CALENDAR_MONTH_180_DAYS,
      { id: RebateEligibility.PRECENDING_CALENDAR_MONTH_180_DAYS, name: '180 days preceding calendar month' }
    ],
    [
      RebateEligibility.PRECENDING_CURRENT_CALENDAR_QUARTER_45_DAYS,
      { id: RebateEligibility.PRECENDING_CURRENT_CALENDAR_QUARTER_45_DAYS,
        name: '45 days preceding current calendar quarter' }
    ],
    [
      RebateEligibility.PRECENDING_CURRENT_CALENDAR_MONTH_45_DAYS,
      { id: RebateEligibility.PRECENDING_CURRENT_CALENDAR_MONTH_45_DAYS,
        name: '45 days preceding current calendar month' }
    ],
  ]);

  export function getValue(eligibility: RebateEligibility): DictionaryItem {
    return valuesMap.get(eligibility);
  }

  export function getValues(): DictionaryItem[] {
    return Array.from(valuesMap.values());
  }
}
