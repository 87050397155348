import { AbstractControl, ValidatorFn } from '@angular/forms';
import { NumberOptions, ValidateNumberOptions, ValidationError } from '@qv-common/models';
import { CoreUtils } from '@qv-common/utils/core.utils';
import { StringUtils } from '@qv-common/utils/string.utils';

export class NumberUtils {
  public static readonly intNumberOptions = new NumberOptions(0, 0, 1);

  public static readonly defaultNumberOptions = new ValidateNumberOptions(5, 0, 100, 0.0001, 2);
  public static readonly defaultPercentOptions = new ValidateNumberOptions(5, 0, 100, 0.00001, 2);
  public static readonly defaultDollarOptions = new ValidateNumberOptions(2, 0, 1000000000000000, 0.01, 2);
  public static readonly defaultCalendarOptions = new ValidateNumberOptions(0, 1, 1000000000, 1, 0);

  public static bytesToMegabytes(value: number): number {
    return value / 1024 / 1024;
  }

  public static toFixedDecimals(value: number, decimals: number = 5, minDecimals: number = 2, step: number = 0.0001): number | string {
    const roundedValue = (Math.round(value / step / .1) * step * .1).toFixed(decimals);
    return NumberUtils.getDecimals(Number.parseFloat(roundedValue), minDecimals);
  }

  public static correctNumber(options: ValidateNumberOptions, value: any, prevValue: string = ''): string | number {
    if (CoreUtils.isNull(value) || StringUtils.isEmpty(value)) {
      return value;
    }

    if (isNaN(value)) {
      return prevValue;
    }

    const formattedValue = NumberUtils.toFixedDecimals(value, options.decimals, options.minDecimals, options.step);

    if (options.min <= formattedValue && formattedValue <= options.max) {
      return formattedValue;
    }

    return NumberUtils.getDecimals(
      formattedValue < options.min ? options.min : options.max,
      options.minDecimals
    );
  }

  public static getDecimals(value: number | string, decimalsNumber: number): number | string {
    if (typeof value === 'string') {
      value = parseFloat(value);
    }
    const decimals = value.toFixed(decimalsNumber);
    return Number.parseFloat(decimals) === value ? decimals : value.toString();
  }

  public static numberValidator(options: ValidateNumberOptions = this.defaultNumberOptions): ValidatorFn {
    let prevValue;

    return (control: AbstractControl): ValidationError => {
      if (control.value === prevValue) {
        return null;
      }

      const correctedNumber = NumberUtils.correctNumber(options, control.value, prevValue);

      prevValue = correctedNumber;

      control.setValue(correctedNumber, { onlySelf: true, emitEvent: false });

      return null;
    };
  }

  public static getRandomNumberFromRange(min: number, max: number): number {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min)) + min;
  }
}
