<div class="spacer bottom-margin top-margin user-input-modal" id="contact-page">
  <h1>Contact Quantuvis</h1>
  <div class="row spacer left-margin">
      <span class="col-4">
          <div class="separated">
              <h2>General Information</h2>

              <div>
                <ng-container *ngFor="let addr of contactCoordinates.hq">
                  <p>{{ addr }}</p>
                </ng-container>
              </div>
              <div>
                  <p>
                      <span class="fa fa-bell qv-icon-gray"></span>
                      &nbsp;{{ contactCoordinates.general.phone }}
                  </p>

                  <p>
                      <span class="fa fa-envelope qv-icon-gray"></span>
                      &nbsp;{{ contactCoordinates.general.email }}
                  </p>
              </div>
          </div>
      </span>
    <span class="col-4">
          <div class="separated">
              <h2>Sales</h2>

              <div>
                  <p>
                      <span class="fa fa-bell qv-icon-gray"></span>
                      &nbsp;{{ contactCoordinates.sales.phone }}
                  </p>

                  <p>
                      <span class="fa fa-envelope qv-icon-gray"></span>
                      &nbsp;{{ contactCoordinates.sales.email }}
                  </p>
              </div>
          </div>
      </span>
    <span class="col-4">
          <div>
              <h2>Customer Support</h2>

              <div>
                  <p>
                      <span class="fa fa-bell qv-icon-gray"></span>
                      &nbsp;{{ contactCoordinates.support.phone }}
                  </p>

                  <p>
                      <span class="fa fa-envelope qv-icon-gray"></span>
                      &nbsp;{{ contactCoordinates.support.email }}
                  </p>
              </div>
          </div>
      </span>
  </div>

  <form [formGroup]="form">
    <div>
      <h1>Send a Message</h1>
    </div>
    <div>
      <div>
        <div class="control-group">
          <h2>Subject</h2>
        </div>
        <div class="control-group">
          <acc-form-field-errors
              [control]="form.get('subject')"
              [validationMessages]="subjectValidationMessages"
          ></acc-form-field-errors>
        </div>
        <div class="control-group">
          <div class="controls position-relative">
            <input
                [formControl]="$any(form.get('subject'))"
                class="qv-modal-dialog-content__field"
                matInput size="50" type="text">
          </div>
          <div class="controls position-relative">
            <div class="info-text spacer bottom-margin">The subject is limited
              to {{constants.FEEDBACK_SUBJECT_MAX_LENGTH}} characters
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="control-group">
          <h2>Message</h2>
        </div>
        <div class="control-group">
          <acc-form-field-errors
              [control]="form.get('feedback')"
              [validationMessages]="feedbackValidationMessages"
          ></acc-form-field-errors>
        </div>
        <div class="control-group">
          <div class="controls position-relative">
            <textarea
                [formControl]="$any(form.get('feedback'))"
                class="textnoresize"
                cols="50"
                id="contact-feedback-text"
                rows="5"
            ></textarea>
          </div>
        </div>
        <div class="control-group">
          <div class="info-text spacer bottom-margin">The feedback is limited
            to {{constants.MESSAGE_MAX_LENGTH}}
            characters
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
