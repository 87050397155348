import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, skip, switchMap, take, tap } from 'rxjs/operators';
import { CoreUtils } from '@qv-common/utils';
import { constants } from '@qv-common/static';
import { QuantuvisBusinessFeature, QuantuvisPlusFeature } from '@qv-company/enums';
import { PermissionDaoService } from '@qv-common/services/dao';
import { OnBootstrap } from '@qv-common/interfaces';
import { UserState } from '@qv-user/entities/user-state.entity';
import { UserService } from '@qv-common/services/auth/user.service';

@Injectable()
export class PermissionService implements OnBootstrap<Map<string, boolean>> {
  public userPermissions$ = new BehaviorSubject<Map<string, boolean>>(new Map<string, boolean>());
  public userFeatures$ = new BehaviorSubject<Map<string, boolean>>(new Map<string, boolean>());
  public userAcl$ = new BehaviorSubject<Map<number, string>>(new Map<number, string>());
  public permissionsReady$ = new BehaviorSubject<boolean>(false);

  private readonly ENTERPRISE = 'enterprise';

  constructor(private permissionDaoService: PermissionDaoService,
              private userService: UserService,
              private router: Router
  ) {
    this.initDefaultUserFeatures();
    this.initSubscriptions();
  }

  public isAllowed(name: string): boolean {
    return (this.userPermissions$ && this.userPermissions$.getValue().get(name))
      ? this.userPermissions$.getValue().get(name)
      : false;
  }

  public fetchUserPermissions(): Observable<Map<string, boolean>> {
    return this.permissionDaoService.getPermissionsForCurrentUser()
      .pipe(
        map((response) => {
          const permissions = this.applyPermissions(response);
          this.setUserPermissions(permissions);
          this.permissionsReady$.next(true);
          return permissions;
        }),
      );
  }

  public onBootstrap(): Observable<Map<string, boolean>> {
    return this.userService.userState.pipe(
      take(1),
      tap((user: UserState) => this.updateUserRights(user)),
      switchMap(() => this.fetchUserPermissions())
    );
  }

  public canAccessArchived(isBidArchived: boolean): boolean {
    if (!this.userService.isCurrentUserPharma()) {
      return true;
    }

    return !isBidArchived || this.isFeatureAllowed(QuantuvisPlusFeature.ARCHIVED);
  }

  public isAllowedInternalBids(): Observable<boolean> {
    return this.userFeatures$
      .pipe(
        map(() => this.userService.isCurrentUserPharma()
          && this.isFeatureAllowed(QuantuvisBusinessFeature.INTERNAL_BIDS)
        )
      );
  }

  public checkPermissionInUserPermissionList(requiredPermission: string, feature: string,
                                             permissionList: Map<string, boolean>): Promise<boolean> {
    return new Promise((resolve, reject) => {
      if (requiredPermission === this.ENTERPRISE) {
        resolve(true);
      } else if (permissionList && permissionList.get(requiredPermission) && !feature) {
        resolve(true);
      } else if (!permissionList || typeof permissionList.get(requiredPermission) === 'undefined') {
        // clear the query string of the URL
        this.router.navigate(['/dashboard']);

        reject(`Not allowed: ${requiredPermission}`);
      } else {
        if (typeof feature === 'undefined' || this.isFeatureAllowed(feature)) {
          resolve(true);
        } else {
          this.router.navigate(['/quantuvisplus']);
          reject(`Not allowed: ${feature}`);
        }
      }
    });
  }

  public isFeatureAllowed(name: string): boolean {
    return this.userFeatures$.getValue().get(name) || false;
  }

  public updateUserRights(userState?: UserState): void {
    if (userState) {
      this.resetUserFeatures();
      this.setUserFeatures(userState.features);
      this.setUserAcl(userState.userACLs);
    }
  }

  public setUserFeatures(data: any): void {
    if (data && Array.isArray(data)) {
      const features = new Map<string, boolean>();
      data.forEach(feature => features.set(feature, true));
      this.userFeatures$.next(features);
    }
  }

  public setUserPermissions(data: Map<string, boolean>): void {
    this.userPermissions$.next(data);
  }

  public setUserAcl(data: any): void {
    if (data && Array.isArray(data)) {
      const acl = new Map<number, string>();
      data.forEach(item => acl.set(item.c, item.r));

      this.userAcl$.next(acl);
    }
  }

  public hasUserWriteAccessToCompany(companyId: number): boolean {
    const acl = this.userAcl$.getValue().get(companyId);
    return acl && acl === constants.UserRights.WRITE.toUpperCase();
  }

  public hasUserAtLeastReadAccessToCompany(companyId: number): boolean {
    const noneRight = constants.UserRights.NONE.toUpperCase();
    const acl = this.userAcl$.getValue().get(companyId);
    return acl && acl !== noneRight;
  }

  public isBusinessPackageFeaturesEnabled(): boolean {
    return Object.keys(QuantuvisBusinessFeature)
      .some((feature) => this.userFeatures$.getValue().get(feature));
  }

  public isUserCompanyBindingBidEnabled(): boolean {
    return CoreUtils.isPathDefined(this.userService.user.getValue(), 'company.bindingBidEnabled')
      && this.userService.user.getValue().company.bindingBidEnabled;
  }

  public isUserCompanyCoverLetterEnabled(): boolean {
    return CoreUtils.isPathDefined(this.userService.user.getValue(), 'company.coverLetterEnabled')
      && this.userService.user.getValue().company.coverLetterEnabled;
  }

  public isPayerAdminHasWriteAccess(): boolean {
    return this.userService.isCurrentUserPayerAdmin() && this.hasUserRight(constants.UserRights.WRITE);
  }

  public isPayerHasWriteAccess(): boolean {
    return this.userService.isCurrentUserPayer() && this.hasUserRight(constants.UserRights.WRITE);
  }

  public isUserHasNoneAccess(): boolean {
    const noneRight = constants.UserRights.NONE.toUpperCase();
    const rights = Array.from(this.userAcl$.getValue().values());

    return rights.every((right: string) => right === noneRight);
  }

  public hasUserWriteAccessToBid(payerId: number, manufacturerId: number): boolean {
    let companyId;
    if (this.userService.isCurrentUserPayer()) {
      companyId = manufacturerId;
    } else if (this.userService.isCurrentUserPharma()) {
      companyId = payerId;
    }

    return this.hasUserWriteAccessToCompany(companyId);
  }

  public reloadUserPermissions(): void {
    this.userService.loadUserData().subscribe((user: UserState) => this.updateUserRights(user));
  }

  public hasAtLeastOneWriteAccess(): boolean {
    return Array.from(this.userAcl$.getValue().values()).includes(constants.UserRights.WRITE.toUpperCase());
  }

  private initSubscriptions(): void {
    this.userService.userState.pipe(skip(1)).subscribe(userState => {
      this.fetchUserPermissions();
      this.updateUserRights(userState);
    });
  }

  private hasUserRight(userRight: string): boolean {
    const userAcl = this.userAcl$.getValue();
    const acl = userAcl.values().next().value;
    return acl === userRight.toUpperCase();
  }

  private applyPermissions(response: any): Map<string, boolean> {
    const userPermissions = new Map<string, boolean>();
    if (response && Array.isArray(response.body)) {
      response.body.forEach(permission => userPermissions.set(permission.name.toLowerCase(), true));
    }

    return userPermissions;
  }

  private resetUserFeatures(): void {
    const features = new Map<string, boolean>();
    for (const key in this.userFeatures$) {
      if (this.userFeatures$.hasOwnProperty(key)) {
        features.set(key, false);
      }
    }

    this.userFeatures$.next(features);
  }

  private initDefaultUserFeatures(): void {
    const initialFeatures = new Map<string, boolean>();
    const features = Object.assign({},
      QuantuvisPlusFeature,
      QuantuvisBusinessFeature
    );
    Object.keys(features).forEach((feature) => {
      initialFeatures.set(feature, false);
    });

    this.userFeatures$.next(initialFeatures);
  }
}
