<div class="report-modal">
  <div
      [class.report-modal__message-title--error]="isShowError()"
      class="report-modal__message-title"
  >Please add additional info regarding your request below* :
  </div>
  <textarea
      [formControl]="$any(form.get('message'))"
      class="report-modal__message"
      matInputAutofocus
  ></textarea>
</div>
