import { InputTerm } from '@qv-term/models/types';
import { Comparator, Reset } from '@qv-common/interfaces';
import { constants, resources } from '@qv-common/static';
import { NumberOptions, ValidateNumberOptions } from '@qv-common/models';
import { ValidatorFn } from '@angular/forms';

export class NetEffectivePriceTerm extends InputTerm implements Reset, Comparator {
  public static readonly numberOptions = new NumberOptions(6, 6, 0.000001);

  constructor(name: string) {
    super(
      name,
      name,
      resources.TermsDescriptions.DRUG_LIST.NET_EFFECTIVE_PRICE,
      '',
      '',
      '',
      'term__net-effective-price'
    );

    this.prefix = this.unitsService.dollar;
    this.placeholder = constants.PLACEHOLDERS.PER_UNIT;
  }

  public getTermValueForComparison(): string {
    return !this.value ? '' : `${this.prefix} ${this.value}`;
  }

  public resetValue(): void {
    this.value = this.defaultValue;
  }

  public numberValidator(): ValidatorFn {
    return super.numberValidator(new ValidateNumberOptions(6, 0, 1000000000000, 0.000001, 6));
  }

}
