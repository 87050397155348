import { Inject, Injectable } from '@angular/core';
import { JsonConvert } from 'json2typescript';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from '@qv-common/services/api';
import { ViewPerspectiveService } from '@qv-common/services/auth';
import { ApiUrlPrefix } from '@qv-common/enums';
import { HttpResponseWrapper } from '@qv-common/models/api';
import { JSON_CONVERT_TOKEN } from 'quantuvis-angular-common/json-converter/services';
import { HttpUtils } from '@qv-common/utils';
import { MarketBasket } from '@qv-bid/entities';

@Injectable()
export class MarketBasketDaoService {

  constructor(
    private apiService: ApiService,
    @Inject(JSON_CONVERT_TOKEN) private jsonConvert: JsonConvert,
    private viewPerspectiveService: ViewPerspectiveService
  ) {}

  public getMarketBasket(scenarioId: number, marketBasketId: number): Observable<MarketBasket> {
    const url = `${ApiUrlPrefix.BIDS}/scenarios/${scenarioId}/market-baskets/${marketBasketId}`;

    return this.apiService.get(url).pipe(
      map((response: HttpResponseWrapper<MarketBasket>) =>
        this.jsonConvert.deserializeObject(response.body.data, MarketBasket)
      )
    );
  }

  public update(scenarioId: number, marketBasket: MarketBasket, addToOtherScenarios: boolean = false,
                isBidInternal: boolean): Observable<MarketBasket> {
    const requestData = this.jsonConvert.serialize(marketBasket);
    let url = `${ApiUrlPrefix.BIDS}/scenarios/${scenarioId}/market-baskets/edit`;
    url = HttpUtils.appendQueryParamToUrl(url, 'addToOtherScenarios', String(addToOtherScenarios));
    const perspective = this.viewPerspectiveService.getViewPerspective();
    url = HttpUtils.appendPerspectiveQueryParamToUrlIfBidIsInternal(url, perspective, isBidInternal);

    return this.apiService.post(url, requestData).pipe(
      map((response: HttpResponseWrapper<MarketBasket>) =>
        this.jsonConvert.deserializeObject(response.body.data, MarketBasket)
      )
    );
  }

  public assignToScenario(
    scenarioId: number,
    templateId: number,
    addToOtherScenarios: boolean = false,
    isBidInternal: boolean
  ): Observable<MarketBasket> {
    const perspective = this.viewPerspectiveService.getViewPerspective();

    let url = `${ApiUrlPrefix.BIDS}/scenarios/${scenarioId}/market-baskets/assign`;
    url = HttpUtils.appendPerspectiveQueryParamToUrlIfBidIsInternal(url, perspective, isBidInternal);

    return this.apiService.post(url, { addToOtherScenarios, templateId }).pipe(
      map((response: HttpResponseWrapper<MarketBasket>) =>
        this.jsonConvert.deserializeObject(response.body.data, MarketBasket)
      )
    );
  }

  public unassignFromScenario(scenarioId: number, isBidInternal: boolean): Observable<void> {
    let url = `${ApiUrlPrefix.BIDS}/scenarios/${scenarioId}/market-baskets/unassign`;
    const perspective = this.viewPerspectiveService.getViewPerspective();
    url = HttpUtils.appendPerspectiveQueryParamToUrlIfBidIsInternal(url, perspective, isBidInternal);

    return this.apiService.post(url).pipe(map(() => null));
  }
}
