import { FormControl } from '@angular/forms';

export class InstructionsModalData {
  constructor(
    public isEditMode: boolean,
    public initialValue: string,
    public control: FormControl,
    public validationMessages: Map<string, string>
  ) {}
}
