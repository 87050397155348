import { Directive, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { InternalField } from '@qv-internal-fields-common/entities';

@Directive()
export class BaseInternalField {
  @Input()
  public internalField: InternalField;

  @Input()
  public internalFieldFormGroup: FormGroup;

  @Output()
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  public onChange = new EventEmitter<InternalField>();
}
