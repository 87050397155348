import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('FilterOption')
export class FilterOption {
  @JsonProperty('id')
  public id: number | string = null;

  @JsonProperty('value', String)
  public value: string = null;
}
