import { NgModule } from '@angular/core';
import RightsComponent from './rights/rights.component';
import PeopleComponent from './people/people.component';

declare let angular: angular.IAngularStatic;

export const ManagementNgModule = angular
  .module('quantuvis.management', [])
  .component('peopleComponent', PeopleComponent)
  .component('rightsComponent', RightsComponent);

@NgModule({})
export class ManagementModule {
}
