import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { WebsiteLink } from '@qv-bid/entities';
import { CommonMessage, RowState, SeverityLevel, SvgIconName } from '@qv-common/enums';
import { resources } from '@qv-common/static/resources';
import { SummaryDaoService } from '@qv-bid/services/dao';
import { expandAnimation } from '@qv-bid/constants';
import { FormControl, Validators } from '@angular/forms';
import { ValidationError } from '@qv-common/models';
import { QvCache } from '@qv-common/decorators';
import { WebsiteLinksEditState } from '@qv-bid/components/shared/summary-panel/website-links/enums';
import { WebsiteLinksEditData } from '@qv-bid/components/shared/summary-panel/website-links/interfaces';
import { SummaryService } from '@qv-bid/services/summary';
import { BaseSummarySection } from '@qv-bid/components/shared/summary-panel/base-summary-section/base-summary-section';
import { TermName } from '@qv-term/enums';
import sortBy from 'lodash.sortby';
import { SnackBarService } from 'quantuvis-angular-common/snack-bar';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { BidEventBusService } from '@qv-bid/services/bid-event-bus.service';
import { BidStateService } from '@qv-bid/services';
import { HttpErrorResponse } from '@angular/common/http';
import { UserService } from '@qv-common/services/auth/user.service';

@Component({
  animations: [expandAnimation],
  selector: 'qv-website-links',
  templateUrl: './website-links.component.html',
  styleUrls: ['./website-links.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WebsiteLinksComponent extends BaseSummarySection implements OnInit, OnChanges {
  @ViewChild('link')
  public linkInputFieldRef: ElementRef;

  public readonly LINK_MAX_LENGTH = 2047;
  public inputControl = new FormControl('', {
    validators: [
      Validators.required.bind(this),
      Validators.maxLength(this.LINK_MAX_LENGTH)
    ]
  });

  public websiteLinks: WebsiteLink[] = [];
  public editStateData: WebsiteLinksEditData = { state: null, websiteLinkId: null };

  public readonly rowState = RowState;
  public readonly svgIconName = SvgIconName;
  public readonly commonMessage = CommonMessage;
  public readonly resources = resources;

  public errorsMap = {
    required: new ValidationError(
      SeverityLevel.ERROR,
      'Website link value cannot be empty'
    ),
    maxlength: new ValidationError(
      SeverityLevel.ERROR,
      `Website link value cannot be greater than ${this.LINK_MAX_LENGTH} characters`
    )
  };

  constructor(
    private summaryDaoService: SummaryDaoService,
    private changeDetectorRef: ChangeDetectorRef,
    private bidStateService: BidStateService,
    snackBarService: SnackBarService,
    bidEventBusService: BidEventBusService,
    userService: UserService,
    summaryService: SummaryService
  ) {
    super(summaryService, userService, snackBarService, bidEventBusService);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.subscribeOnLockChanges(TermName.WEBSITE_LINKS_LOCK);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (this.isSummaryChanged(changes)) {
      this.loadWebsiteLinks();
      this.lockControl.setValue(this.summary.webSiteLinksLock, { emitEvent: false });
    }
  }

  public onAddWebsiteLinkButtonHandler(): void {
    if (this.editStateData.state !== WebsiteLinksEditState.ADD) {
      this.editStateData.state = WebsiteLinksEditState.ADD;
      this.inputControl.reset();
      this.changeDetectorRef.markForCheck();
    }
  }

  public onEditWebsiteLinkButtonHandler(websiteLink: WebsiteLink): void {
    if (this.editStateData.state !== WebsiteLinksEditState.EDIT
      || this.editStateData.websiteLinkId !== websiteLink.id
    ) {
      this.editStateData.state = WebsiteLinksEditState.EDIT;
      this.editStateData.websiteLinkId = websiteLink.id;
      this.inputControl.setValue(websiteLink.link);
      this.changeDetectorRef.markForCheck();
    }
  }


  public focusInput(): void {
    if (this.editStateData.state === WebsiteLinksEditState.ADD) {
      this.linkInputFieldRef.nativeElement.focus();
    }
  }

  public onCreateWebsiteLinkHandler(): void {
    if (this.inputControl.invalid) {
      return;
    }

    this.snackBarService.start();

    const bid = this.bidStateService.bid$.getValue();

    this.summaryDaoService.createWebsiteLink(this.summary.bidVersionId, this.summary.id,
      this.prepareWebsiteLink(), bid.isInternal)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.snackBarService.error();
          return throwError(error);
        })
      )
      .subscribe((link: WebsiteLink) => {
        this.websiteLinks.push(link);
        this.resetEditStateData();
        this.bidEventBusService.undoRedoEvent.emit();
        this.snackBarService.finish();
      });
  }

  public onUpdateWebsiteLinkHandler(websiteLink: WebsiteLink): void {
    if (this.inputControl.invalid) {
      return;
    }

    const updatedWebsiteLink = { link: this.inputControl.value };

    this.snackBarService.start();

    const bid = this.bidStateService.bid$.getValue();
    this.summaryDaoService
      .updateWebsiteLink(this.summary.bidVersionId, this.summary.id, websiteLink.id, updatedWebsiteLink, bid.isInternal)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.snackBarService.error();
          return throwError(error);
        })
      )
      .subscribe((updatedLink: WebsiteLink) => {
        const websiteLinkToUpdate = this.websiteLinks.find((link: WebsiteLink) => updatedLink.id === link.id);
        websiteLinkToUpdate.link = updatedLink.link;
        this.resetEditStateData();
        this.bidEventBusService.undoRedoEvent.emit();
        this.snackBarService.finish();
      });
  }

  public onDeleteWebsiteLinkButtonHandler(deletedLink: WebsiteLink): void {
    this.snackBarService.start();

    const bid = this.bidStateService.bid$.getValue();
    this.summaryDaoService.deleteWebsiteLink(this.summary.bidVersionId, this.summary.id, deletedLink.id, bid.isInternal)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.snackBarService.error();
          return throwError(error);
        })
      )
      .subscribe(() => {
        this.websiteLinks = this.websiteLinks.filter((link: WebsiteLink) => link.id !== deletedLink.id);
        this.bidEventBusService.undoRedoEvent.emit();
        this.changeDetectorRef.markForCheck();
        this.snackBarService.finish();
      });
  }

  @QvCache()
  public isEditFieldVisible(state: WebsiteLinksEditState, websiteLinkId: number, editedLinkId: number): boolean {
    return state === WebsiteLinksEditState.EDIT && websiteLinkId === editedLinkId;
  }

  @QvCache()
  public isAddFieldVisible(state: WebsiteLinksEditState): boolean {
    return state === WebsiteLinksEditState.ADD;
  }

  private loadWebsiteLinks(): void {
    this.summaryDaoService.getWebsiteLinksBySummaryId(this.summary.bidVersionId, this.summary.id)
      .subscribe((websiteLinks: WebsiteLink[]) => {
        this.websiteLinks = sortBy(websiteLinks, 'id');
        this.changeDetectorRef.markForCheck();
      });
  }

  private prepareWebsiteLink(websiteLink?: WebsiteLink): WebsiteLink {
    websiteLink = Object.assign(new WebsiteLink(), websiteLink, { link: this.inputControl.value });

    websiteLink.link = this.inputControl.value;
    websiteLink.summaryId = this.summary.id;
    websiteLink.companyId = this.currentCompanyId;

    return websiteLink;
  }

  private resetEditStateData(): void {
    this.inputControl.reset();
    this.editStateData = { state: null, websiteLinkId: null };
    this.changeDetectorRef.markForCheck();
  }
}
