import { Directive, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { statePerSection } from '@qv-bid/constants';
import { DictionaryItem } from '@qv-common/entities';
import { QvCache } from '@qv-common/decorators';
import { TermSection } from '@qv-term/enums';
import { TermUtils } from '@qv-term/utils';
import { UserService } from '@qv-common/services/auth/user.service';

@Directive()
export class BaseSectionState implements OnInit {
  @Input()
  public sectionName: TermSection;

  @Input()
  public control: FormControl;

  @Input()
  public isEditMode = false;

  public isUserPharma: boolean;
  public selectItems: DictionaryItem[] = [];

  constructor(private userService: UserService) {}

  public ngOnInit(): void {
    this.isUserPharma = this.userService.isCurrentUserPharma();
    // TODO: Delete this if when all section in edit mode was implemented
    if (this.sectionName) {
      this.setSelectOptions();
    }
  }

  @QvCache()
  public isEditable(isEditMode: boolean, isUserPharma: boolean): boolean {
    return TermUtils.isEditable(isEditMode, isUserPharma, true);
  }

  private setSelectOptions(): void {
    this.selectItems = statePerSection[this.sectionName].getValues();
  }
}
