<div class="checkbox-list-wrapper">
  <div *ngIf="!items" class="checkbox-list-wrapper__placeholder">
    <qv-checkbox-list-placeholder [count]="5"></qv-checkbox-list-placeholder>
  </div>

  <div *ngIf="isEmptyArray(items)" class="checkbox-list-wrapper__empty">
    No Available options
  </div>

  <div *ngIf="items?.length" class="checkbox-list-wrapper__content">
    <ng-content></ng-content>
  </div>
</div>
