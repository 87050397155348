export const sessionIdParamConfig = {
  sessionIdParamName: 'session_state',
  sessionIdParamRegex: /[&\?]session_state=[^&\$]*/,
  issuerParamRegex: /[&\?]iss=[^&\$]*/
};

export const authTimeoutFactorRange: { from: number, to: number } = {
  from: 0.55,
  to: 0.85
};
