import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { QvCache } from '@qv-common/decorators';

@Component({
  selector: 'qv-checkbox-list-placeholder',
  templateUrl: './checkbox-list-placeholder.component.html',
  styleUrls: ['./checkbox-list-placeholder.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CheckboxListPlaceholderComponent implements OnInit {
  public readonly radius = 2;
  public readonly margin = 16;
  public readonly primaryColor = '#DCDCDC';

  public readonly checkbox = {
    height: 16,
    width: 16,
    left: 0,
    top: 0
  };

  public readonly label = {
    height: 12,
    width: 186,
    left: 32,
    top: 1
  };

  @Input()
  public count: number;
  public items: number[];
  public fullWidth: number;
  public fullHeight: number;

  public ngOnInit(): void {
    this.items = new Array(this.count);
    this.fullWidth = this.checkbox.width + this.margin + this.label.width;
    this.fullHeight = this.calculateTop(
      this.checkbox.top,
      this.checkbox.height,
      this.margin,
      this.count - 1
    ) + this.checkbox.height;
  }

  @QvCache()
  public calculateTop(elementTop: number, elementHeight: number, margin: number, index: number): number {
    return elementTop + ((elementHeight + margin) * index);
  }
}
