import { JsonObject, JsonProperty } from 'json2typescript';
import { Company } from './company.entity';

@JsonObject('BidEditor')
export class BidEditor {
  @JsonProperty('id', Number)
  public userId: number = null;

  @JsonProperty('firstName', String)
  public firstName: string = null;

  @JsonProperty('lastName', String)
  public lastName: string = null;

  @JsonProperty('companyId', Number, true)
  public companyId?: number = null;

  @JsonProperty('company', Company)
  public company: Company = null;

  get fullName(): string {
    return `${this.firstName} ${this.lastName}`;
  }
}
