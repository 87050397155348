import { ChangeDetectionStrategy, Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl, Validators } from '@angular/forms';
import { SentBidInfo } from '@qv-bid/models';
import { BidSendBestAndFinalModalData } from '@qv-bid/components/shared';
import { BlockingMessage, FieldValidation, FieldValidationMessage, FormValidationError } from '@qv-common/enums';
import { SummaryDaoService } from '@qv-bid/services/dao';
import { BidAttachmentType } from '@qv-bid/enums';
import { catchError, filter, finalize, map, switchMap, take } from 'rxjs/operators';
import { BidAttachment } from '@qv-bid/entities';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { HttpErrorResponse } from '@angular/common/http';
import { NotificationService } from 'quantuvis-angular-common/notification';
import { of } from 'rxjs';
import { FileService } from '@qv-common/services/api';
import { DateUtils } from '@qv-common/utils';
import { appConfig } from '@qv-common/configs';
import { FileContent } from '@qv-pdf-viewer/models';
import { ApiUrlPrefix } from '@qv-common/enums';
import { GeneralModalComponent } from 'quantuvis-angular-common/modal';

const blockUiSelector = 'qv-pdf-viewer';

@Component({
  selector: 'qv-bid-send-best-and-final-modal',
  templateUrl: './bid-send-best-and-final-modal.component.html',
  styleUrls: ['./bid-send-best-and-final-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BidSendBestAndFinalModalComponent extends GeneralModalComponent implements OnInit {
  @Output()
  public primaryAction: EventEmitter<SentBidInfo> = new EventEmitter<SentBidInfo>();
  @BlockUI(blockUiSelector)
  public pdfViewerBlockUI: NgBlockUI;

  public readonly blockUiSelector = blockUiSelector;
  public readonly dateFormat = appConfig.dateFormat;
  public readonly title = 'Binding Bid Legal Attestation';
  public readonly signatureMaxLength = FieldValidation.SIGNATURE_MAX_LENGTH;
  public readonly filePrefix = 'Legal Attestation:';
  public readonly validationMessages = new Map<string, string>([
    [FormValidationError.REQUIRED, FieldValidationMessage.REQUIRED],
    [FormValidationError.MAX_LENGTH, FieldValidationMessage.SIGNATURE_MAX_LENGTH]
  ]);
  public file$ = new BehaviorSubject<BidAttachment>(null);
  public control = new FormControl(null, Validators.compose(
    [Validators.required.bind(this), Validators.maxLength(this.signatureMaxLength)]
  ));
  public currentTime = DateUtils.getUTCDate();

  constructor(public dialogRef: MatDialogRef<BidSendBestAndFinalModalComponent | GeneralModalComponent>,
              @Inject(MAT_DIALOG_DATA) public modalData: BidSendBestAndFinalModalData | any,
              private summaryDaoService: SummaryDaoService,
              private notificationService: NotificationService,
              private fileService: FileService) {
    super(dialogRef, null);
  }

  public onPrimaryAction(): void {
    this.blockUI.start();
    this.primaryAction.next(new SentBidInfo(true, this.control.value));
  }

  public ngOnInit(): void {
    this.loadFile().pipe(catchError(() => of(null)))
      .subscribe((file: BidAttachment) => this.file$.next(file));
  }

  public exportFile(): void {
    this.file$.pipe(
      take(1),
      switchMap((file: BidAttachment) => this.fileService.downloadFile(
        `${ApiUrlPrefix.BIDS}/summaries/${this.modalData.summaryId}/bid-attachments/${file.id}/download`)
      )).subscribe();
  }

  private loadFile(): Observable<BidAttachment> {
    this.pdfViewerBlockUI.start(BlockingMessage.LOADING_LA);
    return this.summaryDaoService.getBidAttachmentsBySummaryId(
      this.modalData.summaryId, BidAttachmentType.LEGAL_ATTESTATION).pipe(
      map((bidAttachments: BidAttachment[]) => bidAttachments
        .find((bidAttachment: BidAttachment) => bidAttachment.isLegalAttestation)),
      filter((bidAttachment: BidAttachment) => Boolean(bidAttachment)),
      switchMap((bidAttachment: BidAttachment) =>
        this.summaryDaoService.getFile(this.modalData.summaryId, bidAttachment.id).pipe(
          map((content: FileContent) => ({ ...bidAttachment, content })),
          catchError((err: HttpErrorResponse) => throwError(err)))),
      finalize(() => this.pdfViewerBlockUI.stop()),
      catchError((err: HttpErrorResponse) => this.notificationService.showServerError(err))
    );
  }
}
