/* tslint:disable:max-line-length triple-equals */
import isEqual from 'lodash.isequal';
import { ArrayUtils, CoreUtils } from '@qv-common/utils';

/**
 * Multiple selection directive.
 * Creates a visual element used to filter single set of data allowing multiple selection
 * Scope parameters
 *
 *  multiple - values to filter
 *  apply    - function to call when apply is clicked - the function provided to the directive will be called
 *             with the current selection
 *  key      - key to look for in the object multiple list in order to render data for filtering
 */
declare let angular: angular.IAngularStatic;
declare let $: any;


export const MultipleSelection = ['$filter', ($filter): any => {
  'ngInject';
  return {
    restrict: 'A',
    template:
      `
<div class="multifilter" ng-class="{true : \'deleteDrugsContent modal-body heightFixed\', false : \'multiFilterContent\'}[deleteType]" ng-click="preventDefault($event)">
    <div class="spacer bottom-margin"><strong ng-bind="popupTitle"></strong>
    <span ng-if="popupDescription"><br /><span ng-bind="popupDescription"></span></span>
       </div>
       <div><input type="search" ng-model="query" auto-focus autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false"/>
    </div>
    <div class="multifilterTool mass-operations">
       <span ng-if="!deleteType" class="link-item spacer right-margin" ng-click="select(true)">Select All</span>
       <span class="link-item spacer" ng-click="select(false)">Clear</span></div>
       <ul class="items spacer top-margin">
           <li  ng-click="doSelection(key)" ng-repeat="key in getKeys() | filter:query"><i class="icon fa fa-check" ng-class="selection[key] ? 'qv-icon-gray' : ''">
             </i>{{key !== "" && key || "(blanks)" }}</li>
       </ul>
    <div ng-if="!deleteType">
        <span class="btn btn-primary btn-narrow" ng-click="doApply(parsedSelection())" ng-bind="applyLabel"></span>
        <span class="btn btn-secondary btn-cancel btn-narrow" ng-click="cancel()">Cancel</span>
    </div>
</div>
<div ng-if="deleteType" class="modal-footer">
    <label class="inliner">
        <span>Delete this drug from the entire RFP (all Contracted Businesses)</span>
        <input type="checkbox" ng-model="deleteFromEntireBid.checked">
    </label>
    <span class="btn btn-secondary btn-cancel" ng-click="cancel()">Cancel</span>
    <span class="btn btn-primary"
          ng-click="doApply(parsedSelection())"
          once-text="Delete">Delete</span>
</div>`,
    scope: {
      multiple: '=',
      initial: '@',
      apply: '@',
      key: '@'
    },
    link: (scope, element, attrs) => {
      let originalSelection;
      const applyFunc = scope.$parent.$eval(attrs.apply);
      const initialSelection = scope.$parent.$eval(attrs.initial);
      let dif;
      scope.deleteFromEntireBid = {
        checked: true
      };
      scope.popupTitle = attrs.popuptitle || 'Filter';
      scope.popupDescription = attrs.popupdescription || '';
      scope.type = attrs.type || 'filter';
      scope.deleteType = scope.type === 'delete';
      scope.applyLabel = scope.type == 'add' ? 'Add' : scope.deleteType ? 'Delete' : 'Apply';
      // Make everything checked at start for filters; everything unchecked for others
      scope.defaultState = scope.type == 'filter';

      scope.doApply = applyOn => {
        if (scope.deleteType) {
          applyFunc(applyOn, scope.deleteFromEntireBid);
        } else {
          applyFunc(applyOn);
        }
      };

      scope.preventDefault = event => {
        if (!$(event.target).hasClass('btn')) {
          event.stopPropagation();
        }
      };

      scope.$watch('multiple', (values, old) => {
        if (values) {
          if (!scope.selection) {
            // Do the initial selection
            scope.selection = {};
            values.forEach(item => {
              // Manually compute selection if we have stored filters; Set default otherwise
              if (initialSelection) {
                scope.selection[item] = initialSelection.contains(item);
              } else {
                scope.selection[item] = scope.defaultState;
              }
            });
          } else {
            // When new values are added set defaultState
            dif = ArrayUtils.difference(values, old);
            dif.forEach(item => scope.selection[item] = scope.defaultState);

            // When values no longer exist in the list remove them from possible selection
            dif = ArrayUtils.difference(old, values);
            dif.forEach(item => delete scope.selection[item]);
          }

          if (!isEqual(values, old)) {
            originalSelection = angular.copy(scope.selection);
          }
        }
      });

      // When searching, reset checkboxes states and only apply it to the visible selection
      scope.$watch('query', (val, old) => {
        if (CoreUtils.isDefined(old) && !isEqual(val, old)) {
          if (val !== '') {
            const filter = $filter('filter');
            const visibleSelection = filter(scope.getKeys(), scope.query);

            // Uncheck all
            Object.keys(scope.selection).forEach(key => scope.selection[key] = false);

            // Make visible checked
            visibleSelection.forEach(item => scope.selection[item] = true);
          } else {
            // Reset selection to initial state when query is deleted
            Object.keys(scope.selection).forEach(key => scope.selection[key] = scope.defaultState);
          }
        }
      });

      // Returns the sorted list of the possible options
      scope.getKeys = () => CoreUtils.isNotDefined(scope.selection) ? [] : Object.keys(scope.selection).sort();

      // Perform selection
      scope.doSelection = key => {
        scope.selection[key] = !scope.selection[key];
      };

      // Selects/Deselects all items from the list
      scope.select = flag => {
        const filter = $filter('filter');
        const visibleSelection = filter(scope.getKeys(), scope.query);
        visibleSelection.forEach(item => scope.selection[item] = flag);
      };

      // Returns the list of the selected items
      scope.parsedSelection = () => {
        scope.query = '';
        const selection = [];
        let shouldClear = false;

        Object.keys(scope.selection).forEach(item => {
          if (scope.selection[item]) {
            selection.push(item);
          }
        });

        if (selection.length == 0 || selection.length == scope.multiple.length) {
          shouldClear = scope.defaultState;
        }

        if ((selection.length == 0) && scope.defaultState) {
          Object.keys(scope.selection).forEach(item => {
            scope.selection[item] = scope.defaultState;
            selection.push(item);
          });
        }

        originalSelection = angular.copy(scope.selection);
        return shouldClear ? undefined : selection;
      };

      // Cancel functionality
      scope.cancel = () => {
        scope.query = '';
        scope.deleteFromEntireBid.checked = true;
        scope.selection = angular.copy(originalSelection);

        // Close the delete drugs popup
        if (scope.deleteType) {
          // This is necessary for the case when an error occurs while deleting drugs and the user clicks
          // Cancel, so that the previous selected drugs are deselected. Otherwise they remain selected
          // when delete drugs popup is reopened.
          scope.select(false);
        }
      };
    }
  };
}];
