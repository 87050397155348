import { DateConverter } from '@qv-bid/serializers';
import { JsonObject, JsonProperty } from 'json2typescript';
import { Moment } from 'moment';
import { BidAssignee } from 'quantuvis-core-entities';

@JsonObject('LockInfo')
export class LockInfo {
  @JsonProperty('id', Number)
  public id: number = null;

  @JsonProperty('perspective', String)
  public perspective: BidAssignee = null;

  @JsonProperty('updatedAt', DateConverter)
  public updatedAt: Moment = null;

  @JsonProperty('ttl', Number)
  public ttl: number = null;

}
